import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_choose_power = _resolveComponent("choose-power")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Avatar')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.form.Data.Avatar,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.Avatar) = $event)),
                  path: "user/avatar",
                  accept: "image/*",
                  maxSize: 2 * 1024 * 1024
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'CompanyId')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.form.Data.CompanyId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.CompanyId) = $event)),
                  options: _ctx.companies,
                  disabled: item.Disabled,
                  labelKey: _ctx.companiesLabelKey,
                  valueKey: "Id"
                }, null, 8, ["modelValue", "options", "disabled", "labelKey"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'InviteCode')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.InviteCode) + " （首次登录后失效） ", 1)
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'UserPhoneNumber')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 3,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.form.Data.UserPhoneNumber,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.UserPhoneNumber) = $event)),
                  pattern: "[0-9]",
                  trimAll: true,
                  disabled: 
            _ctx.logier?.CompanyType != _ctx.EnumCompanyType.Platform &&
            _ctx.form.Data.User?.AuthPhoneNumber
          
                }, null, 8, ["modelValue", "disabled"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinCompanyUserRoles')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 4,
              label: item.Label,
              prop: item.Prop,
              labelWidth: "0px"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: "role",
                    key: role.Value
                  }, [
                    _createVNode(_component_el_checkbox, {
                      class: "role-checkbox",
                      modelValue: role.Selected,
                      "onUpdate:modelValue": ($event: any) => ((role.Selected) = $event),
                      onChange: (arg1) => _ctx.onRoleChanged(arg1, role.Value)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_link, {
                          class: "role-label",
                          type: "primary",
                          underline: false,
                          onClick: ($event: any) => (_ctx.handleShowPower(role.Value))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(role.Label), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]))
                }), 128)),
                _createVNode(_component_el_alert, {
                  title: "提示：点击文字可查看该角色的具体权限",
                  type: "info",
                  effect: "light",
                  "show-icon": ""
                })
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinCompanyUserGroups')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 5,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_el_cascader, {
                  ref: "groupCascader",
                  key: _ctx.form.Data.CompanyId,
                  modelValue: _ctx.groupIds,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.groupIds) = $event)),
                  options: _ctx.groupTree,
                  props: _ctx.groupProps,
                  clearable: "",
                  onChange: _ctx.onGroupChanged,
                  class: "form-item-select"
                }, null, 8, ["modelValue", "options", "props", "onChange"]))
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Level')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 6,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.form.Data.Level,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.Level) = $event)),
                  options: _ctx.levels,
                  number: true
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Status')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 7,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.form.Data.Status,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.Data.Status) = $event)),
                  options: _ctx.EnumJoinCompanyUserStatusOption,
                  clearable: false
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'UserStatus')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 8,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.form.Data.UserStatus,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.Data.UserStatus) = $event)),
                  options: _ctx.EnumUserStatusOption,
                  clearable: false
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinCompanyUserContacts')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 9,
              label: item.Label,
              prop: item.Prop,
              labelWidth: "0px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table, {
                  data: _ctx.form.Data.JoinCompanyUserContacts,
                  border: "",
                  stripe: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "Type",
                      key: "Type",
                      label: "联系方式",
                      width: "130px"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_select, {
                          modelValue: row.Type,
                          "onUpdate:modelValue": ($event: any) => ((row.Type) = $event),
                          options: _ctx.EnumContactTypeOption
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "Content",
                      key: "Content",
                      label: "号码"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Content,
                          "onUpdate:modelValue": ($event: any) => ((row.Content) = $event),
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "Remark",
                      key: "Remark",
                      label: "备注"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Remark,
                          "onUpdate:modelValue": ($event: any) => ((row.Remark) = $event),
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "操作",
                      width: "65px"
                    }, {
                      default: _withCtx(({ $index }) => [
                        _createVNode(_component_el_button, {
                          type: "default",
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleDeleteContact($index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"]),
                _createVNode(_component_miezz_button, {
                  model: _ctx.btnAddContact,
                  onHandleClick: _ctx.handleClick
                }, null, 8, ["model", "onHandleClick"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalPower,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalPower) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_choose_power, {
          modal: _ctx.modalPower,
          "onUpdate:modal": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalPower) = $event)),
          modelValue: _ctx.powerIds,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.powerIds) = $event)),
          tree: _ctx.powerTree,
          showCheck: false
        }, null, 8, ["modal", "modelValue", "tree"]),
        _createVNode(_component_el_alert, {
          style: {"margin-top":"15px"},
          title: "提示：鼠标悬停可查看权限具体说明，如需更改角色的具体权限，请联系系统客服人员",
          type: "info",
          effect: "light",
          "show-icon": ""
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}