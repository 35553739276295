
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyInvoice from "@/models.machine/DtoPageListSearchCompanyInvoice";
import DtoListItemCompanyInvoice from "@/models.machine/DtoListItemCompanyInvoice";
import CompanyInvoicePageList from "@/views/Company/CompanyInvoice/CompanyInvoicePageList.vue";
@Options({
  components: {
    CompanyInvoicePageList,
  },
})
export default class CustomerServiceProviderCompanyInvoicePageList extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchCompanyInvoice,
    DtoListItemCompanyInvoice,
    string
  >("CustomerManagement_ServiceProvider_Invoice");

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CustomerManagement_ServiceProvider_Invoice_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_ServiceProvider_Invoice_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CustomerManagement_ServiceProvider_Invoice_Delete",
      },
    ];
  }
}
