import UiSelectOption from "./UiSelectOption";

/**
 * 项目版本类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumProjectVersionType {
    /**
     * 里程碑/大规模更新
     */
    Main = 10,
    /**
     * 新增模块/模块重做/较大更新
     */
    Minor = 20,
    /**
     * 小功能开发或优化/BUG修订
     */
    Revision = 30,
}

/**
 * 项目版本类型-选项
 */
export const EnumProjectVersionTypeOption: UiSelectOption<number>[] = [
    { Label: "里程碑/大规模更新", Value: 10, Data: "Main" },
    { Label: "新增模块/模块重做/较大更新", Value: 20, Data: "Minor" },
    { Label: "小功能开发或优化/BUG修订", Value: 30, Data: "Revision" },
];
