import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamPriceFieldType, EnumParamPriceFieldTypeOption } from "./EnumParamPriceFieldType";

/**
 * 价格要素分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamPriceField extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumParamPriceFieldType.Id;
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumParamPriceFieldType;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 数据源
     */
    DataSource?: string;
    /**
     * 列表列宽
     */
    Width?: string;
    /**
     * Excel列宽
     */
    ExcelWidth?: number;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 价格要素分页列表-项-表单校验
 */
export const DtoListItemParamPriceFieldRules = {
}

/**
 * 价格要素分页列表-项-设置项
 */
export const DtoListItemParamPriceFieldMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumParamPriceFieldTypeOption,
  },
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "代码",
  },
  {
    Prop: "DataSource",
    Label: "数据源",
  },
  {
    Prop: "Width",
    Label: "列表列宽",
  },
  {
    Prop: "ExcelWidth",
    Label: "Excel列宽",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
]
