import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyCURPM from "../models.machine/DtoPageListSearchCompanyCURPM";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyCURPM from "../models.machine/DtoPageListCompanyCURPM";
import { EnumCompanyType, EnumCompanyTypeOption } from "../models.machine/EnumCompanyType";
import DtoPageListSearchCompany from "../models.machine/DtoPageListSearchCompany";
import DtoPageListCompany from "../models.machine/DtoPageListCompany";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoDetailCompany from "../models.machine/DtoDetailCompany";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoFormCompany from "../models.machine/DtoFormCompany";
import DtoRequestCheckCompanyName from "../models.machine/DtoRequestCheckCompanyName";
import DtoSelectSearchCompany from "../models.machine/DtoSelectSearchCompany";
import DtoSelectOptionCompany from "../models.machine/DtoSelectOptionCompany";
import DtoCallbackCompanyQueryCompanyBySkyEye from "../models.machine/DtoCallbackCompanyQueryCompanyBySkyEye";
import { EnumCompanyStatus, EnumCompanyStatusOption } from "../models.machine/EnumCompanyStatus";
import DtoFormCompanyOpenThreeApiService from "../models.machine/DtoFormCompanyOpenThreeApiService";
import DtoFormCompanyMaxUserCount from "../models.machine/DtoFormCompanyMaxUserCount";
import DtoRequestCompanyAuth from "../models.machine/DtoRequestCompanyAuth";
import DtoCallbackCompanyGetAuth from "../models.machine/DtoCallbackCompanyGetAuth";
import DtoRequestCompanyAuthAudit from "../models.machine/DtoRequestCompanyAuthAudit";
import DtoPageListSearchCompanyAuth from "../models.machine/DtoPageListSearchCompanyAuth";
import DtoPageListCompanyAuth from "../models.machine/DtoPageListCompanyAuth";
import DtoPageListSearchCompanyCustomerRelation from "../models.machine/DtoPageListSearchCompanyCustomerRelation";
import DtoPageListCompanyCustomerRelation from "../models.machine/DtoPageListCompanyCustomerRelation";
import DtoRequestCompanyCustomerRelationBuildSettlementBill from "../models.machine/DtoRequestCompanyCustomerRelationBuildSettlementBill";
import DtoRequestCompanyCustomerRelationSalesman from "../models.machine/DtoRequestCompanyCustomerRelationSalesman";
import DtoRequestCompanyCustomerRelationCustomerService from "../models.machine/DtoRequestCompanyCustomerRelationCustomerService";
import DtoDetailCompanyCodeRule from "../models.machine/DtoDetailCompanyCodeRule";
import DtoFormCompanyCodeRule from "../models.machine/DtoFormCompanyCodeRule";
import DtoDetailCompanyOvertimeRule from "../models.machine/DtoDetailCompanyOvertimeRule";
import DtoFormCompanyOvertimeRule from "../models.machine/DtoFormCompanyOvertimeRule";
import DtoDetailCompanyLadingBillTemplate from "../models.machine/DtoDetailCompanyLadingBillTemplate";
import DtoFormCompanyLadingBillTemplate from "../models.machine/DtoFormCompanyLadingBillTemplate";
import DtoDetailCompanyCreditAndAccountPeriod from "../models.machine/DtoDetailCompanyCreditAndAccountPeriod";
import DtoDetailCompanyCreditTemplate from "../models.machine/DtoDetailCompanyCreditTemplate";
import DtoFormCompanyCreditTemplate from "../models.machine/DtoFormCompanyCreditTemplate";
import DtoFormCompanyCreditAndAccountPeriod from "../models.machine/DtoFormCompanyCreditAndAccountPeriod";
import DtoRequestCompanyCheckContractCodeExist from "../models.machine/DtoRequestCompanyCheckContractCodeExist";
import DtoOptionCompanyAccountingPeriod from "../models.machine/DtoOptionCompanyAccountingPeriod";

/**
 * 公司
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyAppService {
    /**
     * 获取公司/用户/职能/权限/菜单联表
     * @param {DtoPageListSearchCompanyCURPM} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyCURPM>>} 
     */
    static GetCURPM(dto?: DtoPageListSearchCompanyCURPM): AxiosPromise<RESTfulResult<DtoPageListCompanyCURPM>> {
        const url = `/api/Company/GetCURPM`;
        return service.get<RESTfulResult<DtoPageListCompanyCURPM>>(url, { params: dto });
    }

    /**
     * 获取公司分页列表
     * @param {EnumCompanyType} [type] 公司类型
     * @param {DtoPageListSearchCompany} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompany>>} 分页列表
     */
    static GetPageListByType(type?: EnumCompanyType, dto?: DtoPageListSearchCompany): AxiosPromise<RESTfulResult<DtoPageListCompany>> {
        const url = `/api/Company/GetPageList/${type}`;
        return service.get<RESTfulResult<DtoPageListCompany>>(url, { params: dto });
    }

    /**
     * 获取用户下拉框数据
     * @param {EnumCompanyType} [type] 公司类型
     * @param {DtoPageListSearchCompany} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 用户下拉框数据
     */
    static GetUserSelectByType(type?: EnumCompanyType, dto?: DtoPageListSearchCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Company/GetUserSelect/${type}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取公司详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompany>>} 详细
     */
    static Get(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompany>> {
        const url = `/api/Company/Get`;
        const _querys: any = {};
        _querys.id = id
        return service.get<RESTfulResult<DtoDetailCompany>>(url, { params: _querys });
    }

    /**
     * 获取公司来源
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetSources(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Company/GetSources`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 删除公司来源
     * @param {string} [source] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteSourceBySource(source?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/DeleteSource/${source}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 添加公司
     * @param {DtoFormCompany} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompany): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Company/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除公司
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 检查公司名称
     * @param {DtoRequestCheckCompanyName} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 检查结果
     */
    static CheckCompanyName(dto?: DtoRequestCheckCompanyName): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Company/CheckCompanyName`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 查询公司选择器
     * @param {DtoSelectSearchCompany} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoSelectOptionCompany[]>>} 
     */
    static GetSelect(dto?: DtoSelectSearchCompany): AxiosPromise<RESTfulResult<DtoSelectOptionCompany[]>> {
        const url = `/api/Company/GetSelect`;
        return service.get<RESTfulResult<DtoSelectOptionCompany[]>>(url, { params: dto });
    }

    /**
     * 天眼查-查询公司信息
     * @param {number} [id] 天眼查公司Id
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCompanyQueryCompanyBySkyEye>>} 结果
     */
    static QueryCompanyBySkyEyeById(id?: number): AxiosPromise<RESTfulResult<DtoCallbackCompanyQueryCompanyBySkyEye>> {
        const url = `/api/Company/QueryCompanyBySkyEye/${id}`;
        return service.get<RESTfulResult<DtoCallbackCompanyQueryCompanyBySkyEye>>(url);
    }

    /**
     * 更新状态
     * @param {EnumCompanyStatus} [status] 状态
     * @param {string[]} [ids] 公司Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateStatusByStatus(status?: EnumCompanyStatus, ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/UpdateStatus/${status}`;
        return service.put<RESTfulResult<any>>(url, ids);
    }

    /**
     * 更新接口服务
     * @param {DtoFormCompanyOpenThreeApiService} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateOpenThreeApiService(dto?: DtoFormCompanyOpenThreeApiService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/UpdateOpenThreeApiService`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 更新最大用户数
     * @param {DtoFormCompanyMaxUserCount} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateMaxUserCount(dto?: DtoFormCompanyMaxUserCount): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/UpdateMaxUserCount`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 实名认证
     * @param {DtoRequestCompanyAuth} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Auth(dto?: DtoRequestCompanyAuth): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/Auth`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 查询实名信息
     * @param {string} [id] 公司Id
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCompanyGetAuth>>} 结果
     */
    static GetAuth(id?: string): AxiosPromise<RESTfulResult<DtoCallbackCompanyGetAuth>> {
        const url = `/api/Company/GetAuth`;
        const _querys: any = {};
        _querys.id = id
        return service.get<RESTfulResult<DtoCallbackCompanyGetAuth>>(url, { params: _querys });
    }

    /**
     * 审核认证
     * @param {DtoRequestCompanyAuthAudit} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AuthAudit(dto?: DtoRequestCompanyAuthAudit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/AuthAudit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 查询认证公司列表
     * @param {DtoPageListSearchCompanyAuth} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyAuth>>} 分页列表
     */
    static GetAuthPageList(search?: DtoPageListSearchCompanyAuth): AxiosPromise<RESTfulResult<DtoPageListCompanyAuth>> {
        const url = `/api/Company/GetAuthPageList`;
        return service.get<RESTfulResult<DtoPageListCompanyAuth>>(url, { params: search });
    }

    /**
     * 查询待办数量
     * @param {EnumCompanyType} [type] 公司类型
     * @returns {AxiosPromise<RESTfulResult<number>>} 待办数量
     */
    static GetWaitHandleCountByType(type?: EnumCompanyType): AxiosPromise<RESTfulResult<number>> {
        const url = `/api/Company/GetWaitHandleCount/${type}`;
        return service.get<RESTfulResult<number>>(url);
    }

    /**
     * 服务商托管
     * @param {boolean} [open] 开启
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ServiceProviderTrusteeshipByOpen(open?: boolean): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/ServiceProviderTrusteeship/${open}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 获取客户关联分页列表
     * @param {DtoPageListSearchCompanyCustomerRelation} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerRelation>>} 分页列表
     */
    static GetCustomerRelationPageList(search?: DtoPageListSearchCompanyCustomerRelation): AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerRelation>> {
        const url = `/api/Company/GetCustomerRelationPageList`;
        return service.get<RESTfulResult<DtoPageListCompanyCustomerRelation>>(url, { params: search });
    }

    /**
     * 客户关联-生成结算账单
     * @param {DtoRequestCompanyCustomerRelationBuildSettlementBill} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCustomerRelationBuildSettlementBill(dto?: DtoRequestCompanyCustomerRelationBuildSettlementBill): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCustomerRelationBuildSettlementBill`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 客户关联业务员
     * @param {DtoRequestCompanyCustomerRelationSalesman} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCustomerRelationSalesman(dto?: DtoRequestCompanyCustomerRelationSalesman): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCustomerRelationSalesman`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 客户关联客服
     * @param {DtoRequestCompanyCustomerRelationCustomerService} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCustomerRelationCustomerService(dto?: DtoRequestCompanyCustomerRelationCustomerService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCustomerRelationCustomerService`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取编号规则
     * @param {string} [id] 公司Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyCodeRule>>} 编号规则
     */
    static GetCodeRuleById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyCodeRule>> {
        const url = `/api/Company/GetCodeRule/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyCodeRule>>(url);
    }

    /**
     * 保存编号规则
     * @param {DtoFormCompanyCodeRule} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCodeRule(dto?: DtoFormCompanyCodeRule): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCodeRule`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取待办时效规则
     * @param {string} [id] 公司Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyOvertimeRule>>} 待办时效规则
     */
    static GetOvertimeRuleById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyOvertimeRule>> {
        const url = `/api/Company/GetOvertimeRule/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyOvertimeRule>>(url);
    }

    /**
     * 保存待办时效规则
     * @param {DtoFormCompanyOvertimeRule} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostOvertimeRule(dto?: DtoFormCompanyOvertimeRule): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostOvertimeRule`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取提单模板
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyLadingBillTemplate>>} 
     */
    static GetCompanyLadingBillTemplateById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyLadingBillTemplate>> {
        const url = `/api/Company/GetCompanyLadingBillTemplate/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyLadingBillTemplate>>(url);
    }

    /**
     * 保存提单模板
     * @param {DtoFormCompanyLadingBillTemplate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCompanyLadingBillTemplate(dto?: DtoFormCompanyLadingBillTemplate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCompanyLadingBillTemplate`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取授信与账期
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>>} 
     */
    static GetCreditAndAccountPeriodById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>> {
        const url = `/api/Company/GetCreditAndAccountPeriod/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>>(url);
    }

    /**
     * 获取授信与账期模板
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyCreditTemplate[]>>} 
     */
    static GetCreditTemplate(): AxiosPromise<RESTfulResult<DtoDetailCompanyCreditTemplate[]>> {
        const url = `/api/Company/GetCreditTemplate`;
        return service.get<RESTfulResult<DtoDetailCompanyCreditTemplate[]>>(url);
    }

    /**
     * 根据模板获取授信与账期
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>>} 
     */
    static GetTemplateCreditAndAccountPeriodById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>> {
        const url = `/api/Company/GetTemplateCreditAndAccountPeriod/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyCreditAndAccountPeriod>>(url);
    }

    /**
     * 删除授信与账期模板
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteCreditTemplateById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/DeleteCreditTemplate/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 编辑授信与账期模板
     * @param {DtoFormCompanyCreditTemplate[]} [dtoFormCompanyCreditTemplates] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCreditTemplate(dtoFormCompanyCreditTemplates?: DtoFormCompanyCreditTemplate[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCreditTemplate`;
        return service.post<RESTfulResult<any>>(url, dtoFormCompanyCreditTemplates);
    }

    /**
     * 保存授信与账期
     * @param {DtoFormCompanyCreditAndAccountPeriod} [dto] 
     * @param {boolean} [isTemplate] 
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCompanyCreditAndAccountPeriodByIsTemplateById(dto?: DtoFormCompanyCreditAndAccountPeriod, isTemplate?: boolean, id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Company/PostCompanyCreditAndAccountPeriod/${isTemplate}/${id}`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 校验合同编号是否存在
     * @param {DtoRequestCompanyCheckContractCodeExist} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckContractCodeExist(dto?: DtoRequestCompanyCheckContractCodeExist): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Company/CheckContractCodeExist`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取账期下拉接口
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoOptionCompanyAccountingPeriod[]>>} 
     */
    static GetOptionCompanyCreditAndAccountPeriodById(id?: string): AxiosPromise<RESTfulResult<DtoOptionCompanyAccountingPeriod[]>> {
        const url = `/api/Company/GetOptionCompanyCreditAndAccountPeriod/${id}`;
        return service.get<RESTfulResult<DtoOptionCompanyAccountingPeriod[]>>(url);
    }

}
