import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailCompanyAccountingPeriod from "./DtoDetailCompanyAccountingPeriod";
import DtoDetailCompanyCreditAndAccountPeriod_CompanyCreditFile from "./DtoDetailCompanyCreditAndAccountPeriod_CompanyCreditFile";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyCreditAndAccountPeriod extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Limit = 0;
        this.OverdueInterest = 0;
        this.AccountPeriods = [];
        this.Files = [];
        this.BillCount = 0;
        this.IsCredit = false;
    }

    [proName: string]: any;
    /**
     * 授信额度
     */
    Limit?: number;
    /**
     * 逾期利率
     */
    OverdueInterest?: number;
    /**
     * 账期信息
     */
    AccountPeriods?: DtoDetailCompanyAccountingPeriod[];
    /**
     * 合同编号
     */
    ContractCode?: string;
    /**
     * 附件
     */
    Files?: DtoDetailCompanyCreditAndAccountPeriod_CompanyCreditFile[];
    /**
     * 账单数
     */
    BillCount?: number;
    /**
     * 是否有账期
     */
    IsCredit?: boolean;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyCreditAndAccountPeriodRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyCreditAndAccountPeriodMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Limit",
    Label: "授信额度",
    Customize: true,
  },
  {
    Prop: "OverdueInterest",
    Label: "逾期利率",
    Customize: true,
  },
  {
    Prop: "AccountPeriods",
    Label: "账期信息",
    Customize: true,
  },
  {
    Prop: "ContractCode",
    Label: "合同编号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
]
