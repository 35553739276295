import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import DtoCallbackTemplateList from "./DtoCallbackTemplateList";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackTemplate {
    constructor() {
        //无参构造函数
        this.Item = [];
    }

    [proName: string]: any;
    /**
     * 
     */
    OrderId?: string;
    /**
     * 
     */
    PoolId?: string;
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 
     */
    Item?: DtoCallbackTemplateList[];
    /**
     * 是否可以导入结算价
     */
    IsSaler?: boolean;
    /**
     * 
     */
    ClientLabel?: string;
    /**
     * 
     */
    ClientCompanyId?: string;
    /**
     * 
     */
    ClientUserId?: string;
}

/**
 * -表单校验
 */
export const DtoCallbackTemplateRules = {
}
