import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 价格要素表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamPriceFieldChildren extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 数据源
     */
    DataSource?: string;
    /**
     * Excel列宽
     */
    ExcelWidth?: number;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 价格要素表单-表单校验
 */
export const DtoDetailParamPriceFieldChildrenRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * 价格要素表单-设置项
 */
export const DtoDetailParamPriceFieldChildrenMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "DataSource",
    Label: "数据源",
  },
  {
    Prop: "ExcelWidth",
    Label: "Excel列宽",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
]

/**
 * 价格要素表单-设置项
 */
export const DtoDetailParamPriceFieldChildrenMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "DataSource",
    Label: "数据源",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ExcelWidth",
    Label: "Excel列宽",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
