
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import DtoDetailCompanyContact, {
  DtoDetailCompanyContactMiezzDetailItems,
} from "@/models.machine/DtoDetailCompanyContact";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailCompanyContact_ContactNumber, {
  DtoDetailCompanyContact_ContactNumberMiezzListItems,
} from "@/models.machine/DtoDetailCompanyContact_ContactNumber";
import CurrentLogier from "@/apis/CurrentLogier";

export default class CompanyContactDetail extends Vue {
  @Prop() detail!: MiezzForm<DtoDetailCompanyContact, string>;
  @Prop() path!: string;
  @Prop() buttons: MiezzButton[] = [];

  list = new MiezzPageList<
    any,
    DtoDetailCompanyContact_ContactNumber,
    string
  >();
  id?: string;
  companyId?: string;

  /**创建时 */
  created(): void {
    this.companyId = this.$route.params.id as string;
    this.id = this.$route.params.cid as string;

    this.detail.DefaultSpan = 12;
    //详细-数据-初始化
    this.detail.Data = new DtoDetailCompanyContact();
    //详细-元素配置
    this.detail.Items = DtoDetailCompanyContactMiezzDetailItems;
    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items = DtoDetailCompanyContact_ContactNumberMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      CompanyContactAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          this.list.Data!.Items = r.data.Data.Numbers;
        }
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.$router.push(`${this.path}/edit/${this.companyId}/${this.id}`);
        complete();
        break;
      case "delete":
        CompanyContactAppService.Delete([this.id as string])
          .then(() => {
            CurrentLogier.Back();
            complete();
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
