import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchSpec from "../models.machine/DtoPageListSearchSpec";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListSpec from "../models.machine/DtoPageListSpec";
import DtoDetailSpec from "../models.machine/DtoDetailSpec";
import DtoFormSpec from "../models.machine/DtoFormSpec";
import DtoSearchSpec from "../models.machine/DtoSearchSpec";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoCallbackStockHistory from "../models.machine/DtoCallbackStockHistory";

/**
 * 产品的规格型号
 * @description 自动生成的代码,请勿修改
 */
export default class SpecAppService {
    /**
     * 获取产品的规格型号分页列表
     * @param {DtoPageListSearchSpec} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSpec>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchSpec): AxiosPromise<RESTfulResult<DtoPageListSpec>> {
        const url = `/api/Spec/Get`;
        return service.get<RESTfulResult<DtoPageListSpec>>(url, { params: dto });
    }

    /**
     * 获取产品的规格型号详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailSpec>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailSpec>> {
        const url = `/api/Spec/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailSpec>>(url);
    }

    /**
     * 添加产品的规格型号
     * @param {DtoFormSpec} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormSpec): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Spec/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除产品的规格型号
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Spec/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 判断规格型号名称是否存在
     * @param {DtoSearchSpec} [dto] 查询条件
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsSameName(dto?: DtoSearchSpec): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Spec/IsSameName`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取供应商
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSupplier(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Spec/GetSupplier`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 删除供应商
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteSupplierById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Spec/DeleteSupplier/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 获取原始库存的操作日志
     * @param {string} [id] 原始库存的主键
     * @param {number} [CurrentPage] 当前页
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackStockHistory[]>>} 
     */
    static GetStockHistoryByIdByCurrentPage(id?: string, CurrentPage?: number): AxiosPromise<RESTfulResult<DtoCallbackStockHistory[]>> {
        const url = `/api/Spec/GetStockHistory/${id}/${CurrentPage}`;
        return service.get<RESTfulResult<DtoCallbackStockHistory[]>>(url);
    }

}
