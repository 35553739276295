import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_payment_terms_form = _resolveComponent("payment-terms-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'IsCredit')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  disabled: true,
                  modelValue: _ctx.detail.Data.IsCredit,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.IsCredit) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_payment_terms_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}