import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_form = _resolveComponent("user-form")!

  return (_openBlock(), _createBlock(_component_user_form, {
    menuTab: _ctx.menuTab,
    form: _ctx.form,
    companyId: _ctx.companyId,
    official: _ctx.official
  }, null, 8, ["menuTab", "form", "companyId", "official"]))
}