import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailOrderTaskActive_Item from "./DtoDetailOrderTaskActive_Item";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import DtoListItemOrder_OrderMainBill from "./DtoListItemOrder_OrderMainBill";
import DtoDetailOrderSummary_Supplier from "./DtoDetailOrderSummary_Supplier";
import DtoDetailOrderSummary_DefaultSupplier from "./DtoDetailOrderSummary_DefaultSupplier";
import DtoDetailOrderSummary_OrderPool_Supplier from "./DtoDetailOrderSummary_OrderPool_Supplier";
import DtoDetailOrderSummary_OrderPool from "./DtoDetailOrderSummary_OrderPool";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderSummary extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.TaskActiveCount = 0;
        this.TaskActives = [];
        this.HasTask = false;
        this.DirectOrder = false;
        this.StowageOrder = false;
        this.PreStowageOrder = false;
        this.PoolCount = 0;
        this.HasPartBill = false;
        this.EATD = "--/--";
        this.EATA = "--/--";
        this.CreatedUserLabel = "/";
        this.UseThreeApiService = false;
        this.Suppliers = [{"ServiceContent":"\u4F9B\u5E94\u5546\u540D\u79F0","DeparturePortBooking":"\u6682\u65E0\u6570\u636E","DeparturePortContainer":"\u6682\u65E0\u6570\u636E","DeparturePortTrunk":"\u6682\u65E0\u6570\u636E","DeparturePortExpress":"\u6682\u65E0\u6570\u636E","DeparturePortWarehouse":"\u6682\u65E0\u6570\u636E","DeparturePortDeclareCustoms":"\u6682\u65E0\u6570\u636E","DestinationPortClearCustoms":"\u6682\u65E0\u6570\u636E","DestinationPortContainer":"\u6682\u65E0\u6570\u636E","DestinationPortTrunk":"\u6682\u65E0\u6570\u636E","DestinationPortExpress":"\u6682\u65E0\u6570\u636E","DestinationPortWarehouse":"\u6682\u65E0\u6570\u636E"}];
        this.DefaultSuppliers = [];
        this.ContainerTrailers = [];
        this.Customs = [];
        this.Pools = [];
    }

    [proName: string]: any;
    /**
     * 待办数量
     */
    TaskActiveCount?: number;
    /**
     * 有待办的服务
     */
    TaskActives?: DtoDetailOrderTaskActive_Item[];
    /**
     * 有本人的待办
     */
    HasTask?: boolean;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 业务类型
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 直订订单
     */
    DirectOrder?: boolean;
    /**
     * 配载订单
     */
    StowageOrder?: boolean;
    /**
     * 预配订单
     */
    PreStowageOrder?: boolean;
    /**
     * 委托书数量
     */
    PoolCount?: number;
    /**
     * 委托公司Id（当只有一个委托书时）
     */
    ClientCompanyId?: string;
    /**
     * 有无分单信息
     */
    HasPartBill?: boolean;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * ETD/ATD
     */
    EATD?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * ETA/ATA
     */
    EATA?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 承运人
     */
    Carrier?: string;
    /**
     * 承运人
     */
    CarrierLabel?: string;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 创建人
     */
    CreatedUserLabel?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 是否使用接口
     */
    UseThreeApiService?: boolean;
    /**
     * 主单
     */
    MainBill?: DtoListItemOrder_OrderMainBill;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 供应商信息
     */
    Suppliers?: DtoDetailOrderSummary_Supplier[];
    /**
     * 默认供应商
     */
    DefaultSuppliers?: DtoDetailOrderSummary_DefaultSupplier[];
    /**
     * 集装箱拖车
     */
    ContainerTrailers?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 报关
     */
    Customs?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 委托信息
     */
    Pools?: DtoDetailOrderSummary_OrderPool[];
}

/**
 * -表单校验
 */
export const DtoDetailOrderSummaryRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderSummaryMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "TransportDirection",
    Label: "业务类型",
    Customize: true,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EATD",
    Label: "ETD/ATD",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EATA",
    Label: "ETA/ATA",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Carrier",
    Label: "承运人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportChannelLabel",
    Label: "渠道",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CustomerServiceLabel",
    Label: "客服",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedUserLabel",
    Label: "创建人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "UseThreeApiService",
    Label: "是否使用接口",
    Customize: true,
  },
]
