import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import UiGroup from "./UiGroup";

/**
 * 公司类型详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyType extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyType;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 公司类型详细-表单校验
 */
export const DtoDetailCompanyTypeRules = {
}

/**
 * 公司类型详细-设置项
 */
export const DtoDetailCompanyTypeMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Name",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
