import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailProductSpecElement_Product from "./DtoDetailProductSpecElement_Product";
import DtoDetailProductSpecElement_ProductSpecElementItem from "./DtoDetailProductSpecElement_ProductSpecElementItem";
import UiGroup from "./UiGroup";

/**
 * 整个产品的规格要素详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailProductSpecElement extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.ProductSpecElementItems = [];
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 要素名称
     */
    Name?: string;
    /**
     * 产品
     */
    Product?: DtoDetailProductSpecElement_Product;
    /**
     * 子要素
     */
    ProductSpecElementItems?: DtoDetailProductSpecElement_ProductSpecElementItem[];
}

/**
 * 整个产品的规格要素详细-表单校验
 */
export const DtoDetailProductSpecElementRules = {
}

/**
 * 整个产品的规格要素详细-设置项
 */
export const DtoDetailProductSpecElementMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "要素名称",
    Group: "ProductSpecElementForm",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ProductSpecElementItems",
    Label: "子要素",
    Group: "ProductSpecElementForm",
    Span: 24,
    Customize: true,
  },
]
