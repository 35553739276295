import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EntityState {
    /**
     * 
     */
    Detached = 0,
    /**
     * 
     */
    Unchanged = 1,
    /**
     * 
     */
    Deleted = 2,
    /**
     * 
     */
    Modified = 3,
    /**
     * 
     */
    Added = 4,
}

/**
 * -选项
 */
export const EntityStateOption: UiSelectOption<number>[] = [
    { Label: "", Value: 0, Data: "Detached" },
    { Label: "", Value: 1, Data: "Unchanged" },
    { Label: "", Value: 2, Data: "Deleted" },
    { Label: "", Value: 3, Data: "Modified" },
    { Label: "", Value: 4, Data: "Added" },
];
