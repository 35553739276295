import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemParamAuditLog_ProjectSite from "./DtoListItemParamAuditLog_ProjectSite";
import { EnumCrudStatus, EnumCrudStatusOption } from "./EnumCrudStatus";
import { EnumAuditResult, EnumAuditResultOption } from "./EnumAuditResult";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 参数记录表分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamAuditLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.CrudStatus = EnumCrudStatus.Create;
        this.Status = EnumParamAuditStatus.Not;
    }

    [proName: string]: any;
    /**
     * 服务商
     */
    ProjectSite?: DtoListItemParamAuditLog_ProjectSite;
    /**
     * 参数Id
     */
    ParamId?: string;
    /**
     * 参数名称
     */
    ParamTableLabel?: string;
    /**
     * 发起人
     */
    Promoter?: string;
    /**
     * 审核人
     */
    Reviewer?: string;
    /**
     * 服务商动作
     */
    CrudStatus?: EnumCrudStatus;
    /**
     * 审核结果
     */
    AuditResult?: EnumAuditResult;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
}

/**
 * 参数记录表分页列表-项-表单校验
 */
export const DtoListItemParamAuditLogRules = {
}

/**
 * 参数记录表分页列表-项-设置项
 */
export const DtoListItemParamAuditLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ProjectSite",
    Label: "服务商",
    Customize: true,
  },
  {
    Prop: "ParamTableLabel",
    Label: "参数名称",
  },
  {
    Prop: "Promoter",
    Label: "发起人",
  },
  {
    Prop: "Reviewer",
    Label: "审核人",
  },
  {
    Prop: "CrudStatus",
    Label: "服务商动作",
    Customize: true,
  },
  {
    Prop: "AuditResult",
    Label: "审核结果",
    Pipe: EnumPipe.Enum,
    Options: EnumAuditResultOption,
  },
  {
    Prop: "Status",
    Label: "审核状态",
    Pipe: EnumPipe.Enum,
    Options: EnumParamAuditStatusOption,
  },
]
