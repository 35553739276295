import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchSupervisionMode from "../models.machine/DtoSelectSearchSupervisionMode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchSupervisionMode from "../models.machine/DtoPageListSearchSupervisionMode";
import DtoPageListSupervisionMode from "../models.machine/DtoPageListSupervisionMode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailSupervisionMode from "../models.machine/DtoDetailSupervisionMode";
import DtoFormSupervisionMode from "../models.machine/DtoFormSupervisionMode";

/**
 * 监管方式
 * @description 自动生成的代码,请勿修改
 */
export default class SupervisionModeAppService {
    /**
     * 获取航线代码选择器
     * @param {DtoSelectSearchSupervisionMode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchSupervisionMode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/SupervisionMode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取监管方式分页列表
     * @param {DtoPageListSearchSupervisionMode} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSupervisionMode>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchSupervisionMode): AxiosPromise<RESTfulResult<DtoPageListSupervisionMode>> {
        const url = `/api/SupervisionMode/Get`;
        return service.get<RESTfulResult<DtoPageListSupervisionMode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/SupervisionMode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取监管方式详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailSupervisionMode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailSupervisionMode>> {
        const url = `/api/SupervisionMode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailSupervisionMode>>(url);
    }

    /**
     * 新增/编辑监管方式
     * @param {DtoFormSupervisionMode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormSupervisionMode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/SupervisionMode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除监管方式
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/SupervisionMode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/SupervisionMode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/SupervisionMode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/SupervisionMode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/SupervisionMode/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 同步监管方式
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/SupervisionMode/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

}
