import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: item.Label,
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data.Code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Code) = $event)),
                translate: _ctx.form.Data.Name,
                pattern: "[a-zA-Z]",
                placeholder: "请填写",
                class: "form-item-input-text"
              }, null, 8, ["modelValue", "translate"])
            ]),
            _: 2
          }, 1032, ["label", "prop"]))
        : _createCommentVNode("", true),
      (item.Prop == 'Children')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: item.Label,
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table, {
                data: _ctx.form.Data.Children,
                border: "",
                stripe: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "Name",
                    key: "Name",
                    label: "名称",
                    width: "150px"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.Name,
                        "onUpdate:modelValue": ($event: any) => ((row.Name) = $event),
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "Code",
                    key: "Code",
                    label: "代码"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.Code,
                        "onUpdate:modelValue": ($event: any) => ((row.Code) = $event),
                        translate: row.Name,
                        pattern: "[a-zA-Z]",
                        placeholder: "请填写",
                        class: "form-item-input-text"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "translate"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "DataSource",
                    key: "DataSource",
                    label: "数据源"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.DataSource,
                        "onUpdate:modelValue": ($event: any) => ((row.DataSource) = $event),
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "ExcelWidth",
                    key: "ExcelWidth",
                    label: "Excel列宽",
                    width: "89px"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.ExcelWidth,
                        "onUpdate:modelValue": ($event: any) => ((row.ExcelWidth) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "Remark",
                    key: "Remark",
                    label: "备注"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.Remark,
                        "onUpdate:modelValue": ($event: any) => ((row.Remark) = $event),
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "操作",
                    width: "65px"
                  }, {
                    default: _withCtx(({ $index }) => [
                      _createVNode(_component_el_button, {
                        type: "default",
                        size: "small",
                        onClick: ($event: any) => (_ctx.handleDelete($index))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_delete)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"]),
              _createVNode(_component_el_button, {
                type: "primary",
                size: "small",
                onClick: _ctx.handleAdd
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_plus)
                    ]),
                    _: 1
                  }),
                  _createTextVNode(" 新增")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_el_alert, {
                style: {"margin-top":"10px"},
                title: "子要素名称/备注不填则继承父要素对应信息",
                type: "info",
                effect: "light",
                "show-icon": "",
                closable: ""
              })
            ]),
            _: 2
          }, 1032, ["label", "prop"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}