
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import UiSelectOption from "@/models.machine/UiSelectOption";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import {
  EnumTransportPaymentType,
  EnumTransportPaymentTypeOption,
} from "@/models.machine/EnumTransportPaymentType";
import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import OrderAppService from "@/apis.machine/OrderAppService";
import OrderFormContainer from "../../Container/OrderFormContainer.vue";
import OrderFormCost from "../../Cost/OrderFormCost.vue";
import OrderFormProductsSummary from "../../ProductSummary/OrderFormProductsSummary.vue";
import OrderFormLadingBill from "../../LadingBill/OrderFormLadingBill.vue";
import OrderFormCompanyBusinessCard from "../../CompanyBusinessCard/OrderFormCompanyBusinessCard.vue";
import DtoDetailOrder from "@/models.machine/DtoDetailOrder";
import DtoFormOrder, {
  DtoFormOrderMiezzFormItems,
  DtoFormOrderRules,
} from "@/models.machine/DtoFormOrder";
import CompanyBusinessCardForm from "@/views/Company/CompanyBusinessCard/CompanyBusinessCardForm.vue";
import ParamContainerTypeAppService from "@/apis.machine/ParamContainerTypeAppService";
import { EnumLadingBillType } from "@/models.machine/EnumLadingBillType";
import moment from "moment";
import OrderSupplier from "../../OrderSupplier.vue";

@Options({
  components: {
    OrderFormCompanyBusinessCard,
    CompanyBusinessCardForm,
    OrderFormContainer,
    OrderFormCost,
    OrderFormProductsSummary,
    OrderFormLadingBill,
    OrderSupplier,
  },
})
export default class OrderBookingForm extends Vue {
  id?: string;
  logier?: Logier;

  form = new MiezzForm<DtoFormOrder, string>();
  loading = false;
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "primary",
      Size: "small",
    },
  ];
  addDeparturePortBooking = false;

  detail?: DtoDetailOrder;

  EnumCompanyType = EnumCompanyType;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumGoodsTransportTypeOption = EnumGoodsTransportTypeOption;

  currencySystems: UiSelectOption<string>[] = [];
  chargeUnits: UiSelectOption<string>[] = [];
  hasPartBillOptions: UiSelectOption<boolean>[] = [
    {
      Label: "有分单信息",
      Value: true,
    },
    {
      Label: "无分单信息",
      Value: false,
    },
  ];
  containerTypes: UiSelectOption<string>[] = [];

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    // this.addDeparturePortBooking =
    //   this.$route.query.task == "AddDeparturePortBooking";

    this.logier = CurrentLogier.GetLogier();

    //表单-元素配置
    this.form.Items = DtoFormOrderMiezzFormItems;

    ParamChargeUnitAppService.GetSelect().then((r) => {
      this.chargeUnits = r.data.Data ?? [];
    });

    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**初始化表单校验 */
  initRules(): void {
    //表单-校验
    this.form.Rules = JSON.copy(DtoFormOrderRules);

    //目的港ETA时间
    this.form.Rules.EstimatedArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedDepartureDate &&
              moment(value) <= moment(this.form.Data.EstimatedDepartureDate)
            ) {
              this.form.Data.EstimatedArrivalDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ETD时间
    this.form.Rules.EstimatedDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedArrivalDate &&
              moment(this.form.Data.EstimatedArrivalDate) <= moment(value)
            ) {
              this.form.Data.EstimatedDepartureDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];

    const requireds = [
      "ShipperContent",
      "ConsigneeContent",
      "NotifyPartyContent",
      "TransportDirection",
      "TransportType",
      "TransportContainerLoad",
      "SupplierCompanyLabel",
      "LoadingPortId",
      "DeliveryPortId",
      "EstimatedDepartureDate",
      "MarksNotes",
      "PackagesNumber",
      "PackagesKind",
      "GoodsDescription",
      "GrossWeight",
      "Volume",
      "Containers",
    ];
    for (const required of requireds) {
      if (!this.form.Rules[required]) {
        this.form.Rules[required] = [];
      }
      this.form.Rules[required].push(
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        }
      );
    }
  }

  @Watch("form.Data.TransportType")
  onTransportTypeChanged(n?: string, o?: string): void {
    if (n == o) return;
    //第三个文本框：当第二个文本框为“海运/By Sea”、“铁路/By Railway”时，显示
    if (
      this.form.Data.TransportType != EnumTransportType.Sea &&
      this.form.Data.TransportType != EnumTransportType.Railway
    ) {
      this.form.Data.TransportContainerLoad = undefined;
    }
  }

  handleChangeHasPartBill(): void {
    this.$nextTick(() => {
      if (this.form.Data.HasPartBill) {
        const props = [
          "ShipperId",
          "ShipperName",
          "ShipperContent",
          "ConsigneeId",
          "ConsigneeName",
          "ConsigneeContent",
          "NotifyPartyId",
          "NotifyPartyName",
          "NotifyPartyContent",
          "ContractNo",
          "LadingBillNumber",
        ];
        for (const prop of props) {
          this.form.Data[prop] = undefined;
        }
        this.form.Data.MarksNotes = "N/M";
        this.form.Data.TransportPaymentType = EnumTransportPaymentType.Prepaid;
        this.form.Data.LadingBillType = EnumLadingBillType.Original;
      } else {
        const props = [
          "ShipperId",
          "ShipperName",
          "ShipperContent",
          "ConsigneeId",
          "ConsigneeName",
          "ConsigneeContent",
          "NotifyPartyId",
          "NotifyPartyName",
          "NotifyPartyContent",
          "CarrierId",
          "CarrierLabel",
          "ShipName",
          "VoyageNumber",
          "Flight",
          "RailwayNumber",
          "EstimatedDepartureDate",
          "EstimatedArrivalDate",
        ];
        const pool = this.detail?.Pools?.firstOrDefault();
        for (const prop of props) {
          this.form.Data[prop] = pool ? pool[prop] : undefined;
        }
      }
    });
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail = JSON.copy(r.data.Data);
          this.form.Data = r.data.Data;

          if (!this.form.Data?.Sure) {
            this.buttons.push({
              Code: "save",
              Label: "暂存",
              Icon: "Finished",
              Type: "primary",
              Size: "small",
            });
          }

          this.getType();

          if (
            this.form.Data.LoadingPortId &&
            this.form.Data.LoadingPortLabel &&
            this.loadingPorts.length == 0
          ) {
            this.getLoadingPorts(this.form.Data.LoadingPortLabel);
          }
          if (
            this.form.Data.DischargePortId &&
            this.form.Data.DischargePortLabel &&
            this.dischargePorts.length == 0
          ) {
            this.getDischargePorts(this.form.Data.DischargePortLabel);
          }
          if (
            this.form.Data.DeliveryPortId &&
            this.form.Data.DeliveryPortLabel &&
            this.deliveryPorts.length == 0
          ) {
            this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
          }
          if (
            this.form.Data.CarrierId &&
            this.form.Data.CarrierLabel &&
            this.carriers.length == 0
          ) {
            this.getCarriers(this.form.Data.CarrierLabel);
          }
          if (
            this.form.Data.TransportChannelId &&
            this.form.Data.TransportChannelLabel &&
            this.channels.length == 0
          ) {
            this.getChannels(this.form.Data.TransportChannelLabel);
          }

          ParamContainerTypeAppService.GetSelect().then((r) => {
            this.containerTypes = r.data.Data ?? [];
            this.onTransportContainerLoadChanged();
          });

          this.initRules();

          if (this.$route.query.task) {
            this.$nextTick(() => {
              (this.$refs["ruleForm"] as any).validate((v: boolean) => {
                if (!v) {
                  setTimeout(() => {
                    const error = document.getElementsByClassName("is-error");
                    error[0]?.querySelector("a")?.focus();
                    error[0]?.querySelector("textarea")?.focus();
                    error[0]?.querySelector("input")?.focus();
                  }, 1);
                }
              });
            });
          }
        }
      });
    }
  }

  showSupplier = false;
  handleSaveSupplier(data: any, complete: (s: boolean) => void): void {
    this.form.Data.SupplierSource = data.SupplierSource;
    this.form.Data.SupplierCompanyTypeId = data.SupplierCompanyTypeId;
    this.form.Data.SupplierCompanyTypeLabel = data.SupplierCompanyTypeLabel;
    this.form.Data.SupplierCompanyId = data.SupplierCompanyId;
    this.form.Data.SupplierCompanyLabel = data.SupplierCompanyLabel;
    this.form.Data.SupplierCompanyContactLabel =
      data.SupplierCompanyContactLabel;
    complete(true);
  }
  handleSupplierClick(): void {
    (this.$refs["ref-order-supplier"] as OrderSupplier).handleShow(
      this.form.Data
    );
  }

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  type = "暂无数据";
  /**获取业务类型 */
  getType(): void {
    const direction = this.EnumTransportDirectionOption.firstOrDefault(
      (it) => it.Value == this.detail?.TransportDirection
    )?.Label;
    this.type = `【${direction}】`;
    if (this.detail?.TransportType) {
      const type = this.EnumTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.detail?.TransportType
      )?.Label;
      this.type += `【${type}】`;
    }
    if (this.detail?.TransportContainerLoad) {
      const containerLoad =
        this.EnumTransportContainerLoadOption.firstOrDefault(
          (it) => it.Value == this.detail?.TransportContainerLoad
        )?.Label;
      this.type += `【${containerLoad}】`;
    }
  }

  @Watch("form.Data.TransportContainerLoad")
  onTransportContainerLoadChanged(): void {
    if (
      this.containerTypes.length > 0 &&
      this.form.Data.Containers &&
      this.form.Data.Containers.length == 0 &&
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
    ) {
      const type = this.containerTypes.firstOrDefault(
        (it) => it.Data.Iso == "40HQ"
      );
      this.form.Data.Containers = [
        {
          ContainerTypeId: type?.Value,
          ContainerTypeLabel: type?.Label,
          Quantity: 1,
        },
      ];
    }
  }

  loadingPorts: UiSelectOption<string>[] = [];
  /**获取装货港 */
  getLoadingPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Export
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.loadingPorts = r.data.Data ?? [];
      });
    }
  }

  dischargePorts: UiSelectOption<string>[] = [];
  /**获取卸货港 */
  getDischargePorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
        this.dischargePorts = r.data.Data ?? [];
      });
    }
  }

  deliveryPorts: UiSelectOption<string>[] = [];
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.deliveryPorts = r.data.Data ?? [];
      });
    }
  }

  handleChangePort(a?: string, b?: string): void {
    if (a && b && a != b)
      ElMessage.warning("保存后关联委托书中的信息也将同步修改，请知悉");
  }

  /**获取交货地点 */
  getDeliveryPlaces(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }

  /**选择交货地点 */
  handleSelectDeliveryPlace(item: DtoSelectOptionParamCountryArea): void {
    this.form.Data.DeliveryPlaceAddress = item?.DetailedAddress;
  }

  carriers: UiSelectOption<string>[] = [];
  /**获取承运人 */
  getCarriers(q: string): void {
    ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  channels: UiSelectOption<string>[] = [];
  /**获取渠道 */
  getChannels(q: string): void {
    ParamTransportChannelAppService.GetSelect({ Keywords: q, Take: 10 }).then(
      (r) => {
        this.channels = r.data.Data ?? [];
      }
    );
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //保存表单
      case "submit":
        if (this.detail?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("a")?.focus();
              error[0]?.querySelector("textarea")?.focus();
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.form.Data.Sure = true;
            this.loading = true;
            OrderAppService.Put(this.form.Data)
              .then(() => {
                if (
                  (this.$route.query.backUrl as string).startsWith(
                    "/order/pool/edit/"
                  )
                ) {
                  ElMessage.success("保存成功，已为您返回委托书编辑页面");
                } else {
                  ElMessage.success("保存成功");
                }
                CurrentLogier.Back();
                this.loading = false;
                complete();
              })
              .catch(complete);
          }
        });
        break;
      case "save":
        if (this.detail?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        this.loading = true;
        OrderAppService.Put(this.form.Data)
          .then(() => {
            if (
              (this.$route.query.backUrl as string).startsWith(
                "/order/pool/edit/"
              )
            ) {
              ElMessage.success("保存成功，已为您返回委托书编辑页面");
              CurrentLogier.Back();
            } else {
              ElMessage.success("保存成功");
            }
            this.loading = false;
            complete();
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
