import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate from "./DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyLadingBillTemplate extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.LadingBillTemplates = [{"TransportType":1,"Id":"00000000-0000-0000-0000-000000000000"},{"TransportType":2,"Id":"00000000-0000-0000-0000-000000000000"},{"TransportType":3,"Id":"00000000-0000-0000-0000-000000000000"}];
    }

    [proName: string]: any;
    /**
     * 提单模板
     */
    LadingBillTemplates?: DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate[];
    /**
     * 签名图片
     */
    SignImg?: string;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyLadingBillTemplateRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyLadingBillTemplateMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "LadingBillTemplates",
    Label: "提单模板",
    Customize: true,
  },
  {
    Prop: "SignImg",
    Label: "签名图片",
    Customize: true,
  },
]
