
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchOrderPoolCargo, {
  DtoPageListSearchOrderPoolCargoDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderPoolCargo";
import DtoListItemOrderPoolCargo, {
  DtoListItemOrderPoolCargoMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolCargo";
import DtoPageListOrderPoolCargo from "@/models.machine/DtoPageListOrderPoolCargo";
import OrderPoolCargoAppService from "@/apis.machine/OrderPoolCargoAppService";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoShipmentIdReferenceId, {
  DtoShipmentIdReferenceIdMiezzListItems,
} from "@/models.machine/DtoShipmentIdReferenceId";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
export default class CargoPageList extends Vue {
  @Prop() menuTab!: MiezzMenuTab;
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchOrderPoolCargo,
    DtoListItemOrderPoolCargo,
    string
  >;

  poolId?: string;
  trunkId?: string;
    expressId?: string;
    wharfId?: string;

  modalForm = new MiezzModal(MiezzModalType.Dialog);
  listForm = new MiezzPageList<any, DtoShipmentIdReferenceId, string>();

  modalFormTime = new MiezzModal(MiezzModalType.Dialog);
  modalFormTimeData: {
    TrunkId?: string;
      ExpressId?: string;
      WharfId?: string;
    Ids: string[];
    Time?: Date;
    Label?: string;
    Prop?: string;
  } = {
    Ids: [],
  };

  created(): void {
    this.poolId = this.$route.params.id as string;
    this.trunkId = this.$route.params.trunkId as string;
      this.expressId = this.$route.params.expressId as string;
      this.wharfId = this.$route.params.wharfId as string;

    this.list.FixTop = "30px";
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.Dto = new DtoPageListSearchOrderPoolCargo();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchOrderPoolCargoDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderPoolCargo();
    //列表-列配置
    this.list.Items = DtoListItemOrderPoolCargoMiezzListItems;

    this.modalForm.Title = "添加";
    this.modalForm.Top = "20px";
    this.modalForm.Width = "96%";
    this.modalForm.Height = "600px";
    this.modalForm.HandleClick = this.handleTopButtonClick;
    this.modalForm.Buttons = [
      {
        Code: "save",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listForm.Fix = false;
    this.listForm.HideCard = true;
    this.listForm.MaxHeight = '500px';
    this.listForm.EnabledDynamicSearch = false;
    this.listForm.EnabledRadioSearch = false;
    this.listForm.EnabledPagination = false;
    this.listForm.Items = DtoShipmentIdReferenceIdMiezzListItems;
    this.listForm.Data = {
      Items: [],
    };

    this.modalFormTime.Height = "100px";
    this.modalFormTime.HandleClick = this.handleTopButtonClick;
    this.modalFormTime.Buttons = [
      {
        Code: "save-time",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalFormTimeData.TrunkId = this.trunkId;
      this.modalFormTimeData.ExpressId = this.expressId;
      this.modalFormTimeData.WharfId = this.wharfId;
  }

  /**查询列表 */
  handleGet(): void {
    const dto = JSON.copy(this.list.Dto) as DtoPageListSearchOrderPoolCargo;
    dto.TrunkId = this.trunkId;
      dto.ExpressId = this.expressId;
      dto.WharfId = this.wharfId;
    OrderPoolCargoAppService.Get(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    if (this.list.Data?.Lock) {
      ElMessage.warning("订单已锁定");
      complete();
      return;
    }
    if (this.list.Data?.Status == EnumOrderPoolStatus.Canceled) {
      ElMessage.warning("委托书已取消，无法修改");
      complete();
      return;
    }
    if (this.list.Data?.OrderStatus == EnumOrderStatus.Canceled) {
      ElMessage.warning("订单已取消，无法修改");
      complete();
      return;
    }
    switch (btn.Code) {
      //新增
      case "add":
        OrderPoolCargoAppService.GetFromPoolPackage({
          PoolId: this.poolId,
            TrunkId: this.trunkId,
            WharfId: this.wharfId,
          ExpressId: this.expressId,
        })
          .then((r) => {
            if (this.listForm.Data)
              this.listForm.Data.Items = r.data.Data ?? [];
            this.modalForm.Show = true;
            complete();
          })
          .catch(complete);
        break;
      case "save":
        if (this.listForm.SelectRows.length == 0) {
          ElMessage.warning("请至少选择一条信息");
          complete();
        } else {
          OrderPoolCargoAppService.Post({
            TrunkId: this.trunkId,
            WharfId: this.wharfId,
            ExpressId: this.expressId,
            Cargos: this.listForm.SelectRows,
          })
          .then(() => {
            ElMessage.success("保存成功");
            this.handleGet();
            this.modalForm.Show = false;
            complete();
          })
          .catch(complete);
        }
        
        break;
      case "save-time":
        if (this.modalFormTimeData.Prop == "WarehousingTime") {
          OrderPoolCargoAppService.UpdateWarehousingTime(this.modalFormTimeData)
            .then(() => {
              ElMessage.success("保存成功");
              this.handleGet();
              this.modalFormTime.Show = false;
              complete();
            })
            .catch(complete);
        } else {
          OrderPoolCargoAppService.UpdateExWarehouseTime(this.modalFormTimeData)
            .then(() => {
              ElMessage.success("保存成功");
              this.handleGet();
              this.modalFormTime.Show = false;
              complete();
            })
            .catch(complete);
        }
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderPoolCargo,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "delete":
        OrderPoolCargoAppService.Delete({
            TrunkId: this.trunkId,
            WharfId: this.wharfId,
          ExpressId: this.expressId,
          Ids: [row.Id ?? ""],
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "update-warehousing-time":
        this.modalFormTimeData.Label = "入库时间";
        this.modalFormTimeData.Prop = "WarehousingTime";
        this.modalFormTimeData.Time = undefined;
        this.modalFormTimeData.Ids = this.list.SelectIds;
        this.modalFormTime.Title = `编辑${this.modalFormTimeData.Label}`;
        this.modalFormTime.Show = true;
        complete();
        break;
      //批量删除
      case "update-exwarehouse-time":
        this.modalFormTimeData.Label = "出库时间";
        this.modalFormTimeData.Prop = "ExWarehouseTime";
        this.modalFormTimeData.Time = undefined;
        this.modalFormTimeData.Ids = this.list.SelectIds;
        this.modalFormTime.Title = `编辑${this.modalFormTimeData.Label}`;
        this.modalFormTime.Show = true;
        complete();
        break;
      //批量删除
      case "batch-delete":
        OrderPoolCargoAppService.Delete({
            TrunkId: this.trunkId,
            WharfId: this.wharfId,
          ExpressId: this.expressId,
          Ids: this.list.SelectIds,
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
