import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import View403 from '../views/View403.vue'
import View404 from '../views/View404.vue'
import Index from '../views/Index.vue'
import Home from '../views/Home.vue'
import IndexTourist from '../views/IndexTourist.vue'
import LoginPassword from '../views/LoginPassword.vue'
import LoginSms from '../views/LoginSms.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import Register from '../views/Register.vue'
import Price from "../views/Price/Price"
import Sys from "../views/Sys/Sys"
import Company from "../views/Company/Company"
import Order from "../views/Order/Order"
import DeveloperTool from "../views/DeveloperTool/DeveloperTool"
import Product from "../views/Product/ProductRouter"
import Document from "../views/Document/DocumentRouter"
import OrderWarehouse from "../views/OrderWarehouse/OrderWarehouseRouter"
import Bill from "../views/Bill/BillRouter"
import Count from "../views/Count/Count"
import CompareExcelRepeatValues from '@/views/Tools/CompareExcelRepeatValues.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  {
    path: '/403',
    name: '403',
    component: View403,
    meta: {
      title: "403"
    },
  },
  {
    path: '/404',
    name: '404',
    component: View404,
    meta: {
      title: "404"
    },
  },
  {
    path: '/tools/compare-excel-repeat-values',
    name: 'CompareExcelRepeatValues',
    component: CompareExcelRepeatValues,
    meta: {
      title: "比对两个Excel重复值"
    },
  },
  {
    path: '/login/password',
    name: 'LoginPassword',
    component: LoginPassword,
    meta: {
      title: "密码登录"
    },
  },
  {
    path: '/login/sms',
    name: 'LoginSms',
    component: LoginSms,
    meta: {
      title: "短信登录"
    },
  },
  {
    path: '/login/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: "忘记密码"
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: "注册"
    },
  },
  {
    path: '/index/tourist',
    name: 'IndexTourist',
    component: IndexTourist,
    meta: {
      title: "首页"
    },
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    meta: {
      title: "首页"
    },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: Home,
        meta: {
          title: "首页"
        },
      },
      ...Price,
      ...Company,
      ...Order,
      ...Sys,
      ...DeveloperTool,
      ...Product,
      ...Document,
      ...OrderWarehouse,
      ...Bill,
      ...Count
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
