import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-账单类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumBillType {
    /**
     * 应收
     */
    Collection = 10,
    /**
     * 应付
     */
    Payment = 20,
    /**
     * 业务员结算
     */
    Saler = 30,
    /**
     * 分摊
     */
    Share = 999,
}

/**
 * 枚举-账单类型-选项
 */
export const EnumBillTypeOption: UiSelectOption<number>[] = [
    { Label: "应收", Value: 10, Data: "Collection" },
    { Label: "应付", Value: 20, Data: "Payment" },
    { Label: "业务员结算", Value: 30, Data: "Saler" },
    { Label: "分摊", Value: 999, Data: "Share" },
];
