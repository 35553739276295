
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoPageListSearchPriceQuotationSheet, {
  DtoPageListSearchPriceQuotationSheetDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchPriceQuotationSheet";
import DtoPageListPriceQuotationSheet from "@/models.machine/DtoPageListPriceQuotationSheet";
import DtoListItemPriceQuotationSheet, {
  DtoListItemPriceQuotationSheetMiezzListItems,
} from "@/models.machine/DtoListItemPriceQuotationSheet";
import moment from "moment";
import CurrentLogier from "@/apis/CurrentLogier";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumPriceQuotationSheetStatusOption } from "@/models.machine/EnumPriceQuotationSheetStatus";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import DynamicSearchBracketItem from "@/models.machine/DynamicSearchBracketItem";
import { EnumUiDynamicSearchItemType } from "@/models.machine/EnumUiDynamicSearchItemType";
import { EnumCompare } from "@/models.machine/EnumCompare";
import Logier from "@/models.machine/Logier";
import PriceVoyageChoose from "../PriceVoyageChoose.vue";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import {
  EnumPriceQuotationSheetStage,
  EnumPriceQuotationSheetStageOption,
} from "@/models.machine/EnumPriceQuotationSheetStage";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
@Options({
  components: {
    PriceVoyageChoose,
  },
})
export default class PriceQuotationSheetPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPriceQuotationSheet,
    DtoListItemPriceQuotationSheet,
    string
  >("PriceManagement_QuotationSheet");
  /**当前Id */
  id?: string;
  logier?: Logier;

  users: UiSelectOption<string>[] = [];
  EnumPriceQuotationSheetStatusOption = EnumPriceQuotationSheetStatusOption;
  EnumPriceQuotationSheetStageOption = EnumPriceQuotationSheetStageOption;
  searchs: any = {};
  serviceRemarks: UiSelectOptionWithStringValue[] = [];
  createdCompanyUsers: UiSelectOption<string>[] = [];
  powerCodes: string[] = [];

  modalWin = new MiezzModal(MiezzModalType.Dialog);
  modalDataWin: {
    Id?: string;
    ClientCompanyId?: string;
    PoolId?: string;
  } = {};
  modalRuleWin: any = {};
  pools: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powerCodes = r ?? [];
    });
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchPriceQuotationSheet();

    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchPriceQuotationSheetDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListPriceQuotationSheet();
    //列表-列配置
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.Items = DtoListItemPriceQuotationSheetMiezzListItems;
    } else {
      this.list.Items = DtoListItemPriceQuotationSheetMiezzListItems.filter(
        (it) => it.Prop != "InfoCollector"
      );
    }
    for (const item of this.list.Items) {
      switch (item.Prop) {
        case "InquiryDescription":
          item.DisabledTooltip = true;
          break;

        default:
          break;
      }
    }
    this.list.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "Company":
          return row.Company
            ? `${row.Company?.NameZh ?? "暂无数据"}${
                row.Company?.NameEn ? `/${row.Company.NameEn}` : ""
              }`
            : "暂无数据";
        case "User":
          return row.User
            ? `${row.User?.Name ?? "暂无数据"}/${
                row.User?.User?.PhoneNumber ?? "暂无数据"
              }`
            : "暂无数据";
        case "InfoCollector":
          return row.InfoCollector
            ? `${row.InfoCollector?.Name ?? "暂无数据"}/${
                row.InfoCollector?.User?.PhoneNumber ?? "暂无数据"
              }`
            : "暂无数据";
        case "CreatedUserName":
          return `${row.CreatedUserName}/${row.CreatedPhoneNumber}`;
        case "ServiceId":
          return row.Service?.Name;
        case "ValidDateStart":
          return row.ValidDateStart && row.ValidDateEnd
            ? `${
                moment(row.ValidDateStart)?.format("YYYY/MM/DD") ?? "暂无数据"
              } - ${
                moment(row.ValidDateEnd)?.format("YYYY/MM/DD") ?? "暂无数据"
              }`
            : "暂无数据";
        default:
          break;
      }
    };
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_QuotationSheet_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_QuotationSheet_Detail",
      },
      {
        Code: "copy",
        Title: "复制",
        MiezzIcon: "copy",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_QuotationSheet_Copy",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_QuotationSheet_Export",
      },
      {
        Code: "stage",
        Label: "阶段",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_QuotationSheet_ChangeStage",
        Options: EnumPriceQuotationSheetStageOption.filter(
          (it) => it.Data != "Win"
        ).map((it) => {
          return {
            Code: it.Value?.toString(),
            Label: it.Label,
            Size: "small",
          };
        }),
      },
      {
        Code: "send",
        Label: "发送",
        Icon: "share",
        Type: "text",
        PowerCode: "PriceManagement_QuotationSheet_Send",
      },
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        Message: "是否确认删除",
        PowerCode: "PriceManagement_QuotationSheet_Delete",
      },
    ];

    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["PriceManagement_QuotationSheet"],
    }).then((r) => {
      this.users = r.data.Data ?? [];
    });

    PriceQuotationSheetAppService.GetFields().then((r) => {
      this.list.DynamicSearchItems = JSON.copy(
        DtoPageListSearchPriceQuotationSheetDynamicSearchItems
      );
      for (const item of r.data.Data ?? []) {
        const field = {
          Prop: `${item.Value}|${item.Label}`,
          Label: item.Label,
          Type: EnumUiDynamicSearchItemType.Customize,
          Compares: [EnumCompare.Equal],
        };
        this.searchs[item.Value as string] = [];
        this.list.DynamicSearchItems?.push(field);
      }
    });
    OrderPoolAppService.GetServiceRemarks().then((r) => {
      this.serviceRemarks = (r.data.Data ?? []).filter(
        (it) => it.Label != "其他"
      );
    });
    PriceQuotationSheetAppService.GetCreatedCompanyUsers().then((r) => {
      this.createdCompanyUsers = r.data.Data ?? [];
    });

    this.modalWin.Title = "赢单";
    this.modalWin.Width = "800px";
    this.modalWin.Buttons = [
      {
        Code: "save-win",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalWin.HandleClick = this.handleTopButtonClick;
    this.modalRuleWin.PoolId = [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (!value) {
            return callback(new Error("信息不能为空"));
          } else {
            PriceQuotationSheetAppService.CheckExistStageWinByIdByPoolId(
              this.modalDataWin.Id,
              this.modalDataWin.PoolId
            ).then((r) => {
              if (r.data.Data) {
                return callback(
                  new Error("该booking no已有关联的报价表，请重新选择")
                );
              } else {
                callback();
              }
            });
          }
        },
        required: true,
        trigger: "change",
      },
    ];

    OrderPoolAppService.GetBookingNos();
  }

  querySearch(prop: string, q: string): void {
    const splits = prop.split("|");
    PriceQuotationSheetAppService.GetFieldValues({
      Code: splits[0],
      Name: splits[1],
      Keywords: q,
      Take: 10,
    }).then((r) => {
      this.searchs[prop] = r.data.Data ?? [];
    });
  }

  /**查询列表 */
  handleGet(): void {
    PriceQuotationSheetAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**改变条件 */
  @Emit()
  handleChangeWhere(
    bracket: DynamicSearchBracket,
    item: DynamicSearchBracketItem,
    i: number
  ): void {
    if (
      item.Where?.Prop == "DynamicSearch_Status" &&
      item.Where.Value == undefined
    ) {
      item.Where.Value = "2";
    }
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        CurrentLogier.LinkWithBack("/price/quotation-sheet/add");
        complete();
        break;
      case "save-win":
        (this.$refs["ref-form-win"] as any).validate((v: boolean) => {
          if (v) {
            PriceQuotationSheetAppService.ChangeStage({
              Ids: [this.modalDataWin.Id ?? ""],
              Stage: EnumPriceQuotationSheetStage.Win,
              PoolId: this.modalDataWin.PoolId,
            })
              .then(() => {
                ElMessage.success("操作成功");
                this.handleGet();
                this.modalWin.Show = false;
                complete();
              })
              .catch(complete);
          }
        });
        complete();
        break;
      default:
        break;
    }
  }

  handleRowDbClick(row: DtoListItemPriceQuotationSheet): void {
    this.id = row.Id;
    CurrentLogier.LinkWithBack(`/price/quotation-sheet/${this.id}`);
  }

  handleEditBookingNo(row: DtoListItemPriceQuotationSheet): void {
    this.handleRowButtonClick(
      { Code: "stage", Command: EnumPriceQuotationSheetStage.Win },
      row,
      () => {
        //
      }
    );
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceQuotationSheet,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      //详细
      case "detail":
        CurrentLogier.LinkWithBack(`/price/quotation-sheet/${this.id}`);
        complete();
        break;
      case "copy":
        PriceQuotationSheetAppService.CopyById(this.id)
          .then(() => {
            ElMessage.success("复制成功");
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "stage":
        this.modalDataWin.Id = row.Id;
        this.modalDataWin.PoolId = row.PoolId;
        this.modalDataWin.ClientCompanyId = row.CompanyId;
        if (btn.Command == EnumPriceQuotationSheetStage.Win) {
          if (this.modalDataWin.ClientCompanyId) {
            PriceQuotationSheetAppService.GetBookingNosByClientCompanyId(
              this.modalDataWin.ClientCompanyId
            ).then((r) => {
              this.pools = r.data.Data ?? [];
            });
            this.modalWin.Show = true;
          } else if (row.CompanyLabel) {
            ElMessage.warning("该客户未添加至《客户管理》中，无法修改状态");
          } else {
            ElMessage.warning("请先选择客户企业");
          }
          complete();
        } else {
          PriceQuotationSheetAppService.ChangeStage({
            Ids: [row.Id ?? ""],
            Stage: parseInt(btn.Command),
          }).then(() => {
            ElMessage.success("操作成功");
            this.handleGet();
            complete();
          });
        }
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "export":
        PriceQuotationSheetAppService.ExportZip(this.list.SelectIds)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            complete();
          })
          .catch(complete);
        break;
      case "send":
        PriceQuotationSheetAppService.SendAsync(this.list.SelectIds)
          .then(() => {
            ElMessage.success(
              "已发送，询盘人不是《客户管理-用户》列表中的用户无法收到信息"
            );
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "stage":
        PriceQuotationSheetAppService.ChangeStage({
          Ids: this.list.SelectIds,
          Stage: parseInt(btn.Command),
        }).then(() => {
          ElMessage.success("操作成功");
          this.handleGet();
          complete();
        });
        break;
      //批量删除
      case "batch-delete":
        PriceQuotationSheetAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
