
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzButton from "@/models/MiezzButton";
import MiezzPageList from "@/models/MiezzPageList";
import PricePageList from "@/views/Price/PricePageList.vue";
import { Back } from "@element-plus/icons-vue/dist/types";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PriceSearch from "../PriceSearch.vue";
@Options({
  components: {
    PriceSearch,
    PricePageList,
  },
})
export default class PriceSearchDetail extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Search_Detail"
  );
  serviceId?: string;
  amountType = EnumPriceAmountType.Agreement;
  companyId = CurrentLogier.GetLogier()?.CompanyId;

  created(): void {
    this.serviceId = this.$route.params.id as string;
    //列表-顶部按钮
    this.list.TopButtons = [];
    this.list.OperationWidth = "100px";
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail-surcharge",
        Label: "附加费",
        Type: "text",
        Size: "small",
        PowerCode: "PriceManagement_Search_Detail_Surcharge",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];
  }

  handleRefresh(id?: string): void {
    this.serviceId = id;
  }

  /**顶部按钮点击 */
  handleMenuTabClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "search":
        (
          this.$refs["ref-price-page-list"] as PricePageList
        ).handleTopButtonClick(btn, complete);
        break;
      default:
        break;
    }
  }
}
