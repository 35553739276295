import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 国家分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamCountry extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 国旗
     */
    Flag?: string;
    /**
     * 地区深度
     */
    AreaDeep?: number;
    /**
     * 地区名称1
     */
    AreaName1?: string;
    /**
     * 地区名称2
     */
    AreaName2?: string;
    /**
     * 地区名称3
     */
    AreaName3?: string;
    /**
     * 地区名称4
     */
    AreaName4?: string;
    /**
     * 地区名称5
     */
    AreaName5?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 国家分页列表-项-表单校验
 */
export const DtoListItemParamCountryRules = {
}

/**
 * 国家分页列表-项-设置项
 */
export const DtoListItemParamCountryMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "Code",
    Label: "代码",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
  {
    Prop: "Flag",
    Label: "国旗",
    Customize: true,
  },
  {
    Prop: "AreaDeep",
    Label: "地区深度",
  },
  {
    Prop: "AreaName1",
    Label: "地区名称1",
  },
  {
    Prop: "AreaName2",
    Label: "地区名称2",
  },
  {
    Prop: "AreaName3",
    Label: "地区名称3",
  },
  {
    Prop: "AreaName4",
    Label: "地区名称4",
  },
  {
    Prop: "AreaName5",
    Label: "地区名称5",
  },
]
