
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzForm from "@/models/MiezzForm";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import DtoPageListOrderPoolPackageShape from "@/models.machine/DtoPageListOrderPoolPackageShape";
    import DtoListItemOrderPoolPackageShape, {
        DtoListItemOrderPoolPackageShapeMiezzListItems,
    } from "@/models.machine/DtoListItemOrderPoolPackageShape";
    import DtoFormOrderDepartureWharf from "@/models.machine/DtoFormOrderDepartureWharf";
    import MiezzListItem from "../../models/MiezzListItem";
    import DtoRequestOrderPoolPackageShape from "@/models.machine/DtoRequestOrderPoolPackageShape";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
    import {
        EnumOrderServiceType
    } from "@/models.machine/EnumOrderServiceType";

    import DtoFormOrderDeparture, {
        DtoFormOrderDepartureMiezzFormItems,
        DtoFormOrderDepartureRules,
    } from "@/models.machine/DtoFormOrderDeparture";

    export default class MiezzWharfPlan extends Vue {
        @Prop() isDetail?: boolean;
        selectRowCount = 0;
        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        widthHeight = 600;
        clientRandom = 0;
        //委托书主键
        orderPoolId?: string;
        isOutStock = true;
        plan = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderPoolPackageShape, string>();
        //订单主键
        orderId?: string;
        //是否选择委托人
        isSelectClientCompany = false;
        /*true表示委托书或者直订订单，false表示配载订单 */
        directOrder = true;
        orderDepartureWharfId?: string;
        chargeUnits?: UiSelectOption<string>[] = [];
        packageEnHistorys?: UiSelectOption<string>[] = [];
        isLoading = false;
        //是否在访问接口
        isQuery = false;
        isQuantity = false;
        ruleForm: {
            Visible: boolean;
            Total: number;
            CurrentPage: number;
            PageSize: number;
        } = {
                Visible: false,
                Total: 0,
                CurrentPage: 1,
                PageSize: 10,
            };
        type = EnumOrderServiceType.DeparturePort;
        currentPage = 1;
        addRuleForm = new MiezzForm<DtoFormOrderDeparture, string>();
        //委托人集合
        clientCompanys: UiSelectOption<string>[] = [];
        //委托人集合
        pools: UiSelectOption<string>[] = [];
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "import",
                Label: "导入剩余",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "save",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "pool",
                Label: "查看委托书",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        form = new MiezzForm<DtoFormOrderDepartureWharf, string>();
        item = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderPoolPackageShape, string>();
        itemInit = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderPoolPackageShape, string>();
        isInit = false;
        created(): void {
            //列表-传参
            this.plan.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.plan.Data = new DtoPageListOrderPoolPackageShape();
            //列表-列配置
            this.plan.Items = DtoListItemOrderPoolPackageShapeMiezzListItems;


            //列表-传参
            this.item.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.item.Data = new DtoPageListOrderPoolPackageShape();
            this.form.Data = new DtoFormOrderDepartureWharf();
            //列表-列配置
            this.item.Items = DtoListItemOrderPoolPackageShapeMiezzListItems;

            //表单-数据-初始化
            this.addRuleForm.Data = new DtoFormOrderDeparture();
            //表单-校验
            this.addRuleForm.Rules = DtoFormOrderDepartureRules;
            //表单-元素配置
            this.addRuleForm.Items = DtoFormOrderDepartureMiezzFormItems;

            this.item.EnabledRadioSearch = false;
            this.item.EnabledDynamicSearch = false;
            this.item.EnabledSelect = false;
            this.item.FixTop = "45px";
            this.item.EnabledPagination = false;
            this.item.HideCard = true;
            this.item.Fix = false;

            //ParamChargeUnitAppService.GetSelect().then(r => {
            //    this.chargeUnits = r.data.Data;
            //})



            this.addRuleForm.Rules.ClientCompanyId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];
            this.addRuleForm.Rules.OrderPoolId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

        }

        customsPackageTableRules = {
            Length: [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && value != undefined && Number(value) < 1) {
                            callback("长度不能小于1cm");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Width: [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && Number(value) < 1) {
                            callback("宽度不能小于1cm");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Height: [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && Number(value) < 1) {
                            callback("高度不能小于1cm");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Volume: [
                {
                    required: true,
                    message: '信息不能为空',
                    trigger: 'blur',
                },
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[2]);
                        if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                            if (this.item.Data.Items[number].Length != null && this.item.Data.Items[number].Length != undefined
                                && this.item.Data.Items[number].Width != null && this.item.Data.Items[number].Width != undefined
                                && this.item.Data.Items[number].Height != null && this.item.Data.Items[number].Height != undefined
                            ) {
                                var length = this.item.Data.Items[number].Length;
                                var width = this.item.Data.Items[number].Width;
                                var height = this.item.Data.Items[number].Height;
                                if (length && width && height && length > 0 && width > 0 && height > 0) {
                                    if (value == null || value == undefined || value == "") {
                                        callback("不能小于0.0001CBM，请调整长宽高");
                                        return false;
                                    }
                                }
                            }
                        }
                        if (value != "" && Number(value) < 0.0001) {
                            callback("不能小于0.0001CBM，请调整长宽高");
                            return false;
                        }
                        callback();
                    },
                    trigger: "change",
                },],
        };

        onDepartureDetail(): void {
            window.open(
                "#/pool/departure/wharf/" + this.orderPoolId,
                "_blank"
            );
        }
        onDeletePackageEn(id: string, index: number): void {
            //PackageEnAppService.RemoveHistoryById(id).then((r) => {
            //    this.packageEnHistorys?.splice(index, 1);
            //    return;
            //})
            var value = "";
            if (this.item.Data) {
                value = this.item.Data.ChargeUnit;
            }
            PackageEnAppService.RemoveHistoryById(id).then((r) => {
                var oldValue = "";
                if (this.packageEnHistorys) {
                    oldValue = this.packageEnHistorys[index].Label as string;
                }

                this.packageEnHistorys?.splice(index, 1);
                if (this.item.Data) {
                    if (oldValue != value) {
                        this.item.Data.ChargeUnit = value;
                    }
                    else {
                        this.item.Data.ChargeUnit = "";
                    }
                }

                if (this.item.Data && this.item.Data.ChargeUnit == "" && this.packageEnHistorys && this.packageEnHistorys.length > 0) {
                    this.item.Data.ChargeUnit = this.packageEnHistorys[0].Label;
                }

                ElMessage.success("历史记录已删除");
                return;
            })
        }

        /*初始化 */
        @Emit()
        onInit(orderDepartureWharfId: string | undefined, type: EnumOrderServiceType, directOrder: boolean, poolId: string | undefined, orderId: string | undefined): void {

            //alert(window.screen.height);

            var packageEnParam = {};
            PackageEnAppService.GetSelect(packageEnParam).then(en => {
                this.packageEnHistorys = en.data.Data;

                this.orderId = orderId;
                this.orderPoolId = poolId;
                this.directOrder = directOrder;
                this.type = type;
                this.orderDepartureWharfId = orderDepartureWharfId;
                this.isLoading = true;
                this.ruleForm.CurrentPage = 1;
                this.ruleForm.Total = 0;
                this.isInit = true;
                if (this.isDetail == true || (this.orderDepartureWharfId != undefined && this.orderDepartureWharfId != "")) {
                    this.isSelectClientCompany = false;
                }
                else {
                    this.isSelectClientCompany = true;
                }
                if (orderId == undefined || orderId == "") {
                    this.isSelectClientCompany = false;
                }
                if (this.isSelectClientCompany == false) {
                    if (this.bottomButtons) {
                        this.bottomButtons[0].Hide = this.isDetail;
                        this.bottomButtons[1].Hide = this.isDetail;
                        this.bottomButtons[2].Hide = true;
                    }
                    this.onSearch();
                    this.ruleForm.Visible = true;
                }
                else {
                    if (this.bottomButtons) {
                        this.bottomButtons[0].Hide = true;
                        this.bottomButtons[1].Hide = true;
                        this.bottomButtons[2].Hide = false;

                    }
                    this.ruleForm.Visible = true;
                    this.isLoading = false;

                    if (orderId != undefined && orderId != "") {
                        this.addRuleForm.Data.ClientCompanyId = undefined;
                        this.addRuleForm.Data.OrderPoolId = undefined;
                        OrderDepartureWharfAppService.GetClientCompanyByOrderIdByType(orderId, this.type).then((company) => {
                            if (company.data.Data) this.clientCompanys = company.data.Data;
                            this.clientRandom = Math.random();
                        })
                    }


                }
            })

        }
        onSearch(): void {
            if (this.isInit == false) {
                MiezzForm.customSubmit(this.$refs, "ruleFormPlan", (v) => {
                    if (v == true) {
                        this.onData();
                    } else {
                        this.ruleForm.CurrentPage = this.currentPage;
                    }
                });
            } else {
                this.isInit = false;
                this.onData();
            }
        }
        onChangeClientCompanyId(): void {
            this.pools = [];
            for (var it of this.clientCompanys) {
                if (it.Value == this.addRuleForm.Data.ClientCompanyId) {
                    for (var it1 of it.Data.Pools) {
                        this.pools.push({
                            Value: it1.OrderPoolId,
                            Label: it1.BookingNo,
                        })
                    }
                }
            }
            if (this.pools.length == 1) {
                this.addRuleForm.Data.OrderPoolId = this.pools[0].Value;
            }
            else {
                this.addRuleForm.Data.OrderPoolId = undefined;
                MiezzForm.clearValidate(this.$refs, "$addRuleForm", "OrderPoolId");
            }
        }
        onChangeSumQuantity(): void {
            if (this.item.Data != undefined) {
                var result = setDecimal(this.item.Data.SumQuantity + "", 0, true);
                if (result != "") {
                    this.item.Data.SumQuantity = Number(result);
                } else {
                    this.item.Data.SumQuantity = undefined;
                }
            }
        }
        onChangeSumGrossWeight(): void {
            if (this.item.Data != undefined) {
                var result = setDecimal(this.item.Data.SumGrossWeight + "", 2, true);
                if (result != "") {
                    this.item.Data.SumGrossWeight = Number(result);
                } else {
                    this.item.Data.SumGrossWeight = undefined;
                }
            }
        }
        onChangeSumVolume(): void {
            if (this.item.Data != undefined) {
                var result = setDecimal(this.item.Data.SumVolume + "", 4, true);
                if (result != "") {
                    this.item.Data.SumVolume = Number(result);
                } else {
                    this.item.Data.SumVolume = undefined;
                }
            }
        }
        onData(): void {
            if (this.item && this.item.Dto) {
                this.item.Dto.CurrentPage = this.ruleForm.CurrentPage;
                this.item.Dto.PageSize = this.ruleForm.PageSize;
                this.item.Dto.OrderPoolId = this.orderPoolId;
                this.item.Dto.OrderDepartureWharfId = this.orderDepartureWharfId;
                this.item.Dto.IsDetail = this.isDetail;
                this.item.Dto.Type = this.type;
            }
            this.isQuery = true;
            OrderDepartureWharfAppService.GetPlans(this.item.Dto).then((r) => {
               
                this.item.Data = JSON.parse(JSON.stringify(r.data.Data));
                this.itemInit.Data = JSON.parse(JSON.stringify(r.data.Data));
                this.currentPage = this.ruleForm.CurrentPage;
                this.isLoading = false;
                if (this.item.Data && this.item.Data.Items) {
                    if (this.bottomButtons) {
                        this.bottomButtons[3].Hide = false;
                    }
                    //for (var i = 0; i < 100; i++) {
                    //    this.item.Data.Items.push(this.item.Data.Items[0]);
                    //}

                    for (var it of this.item.Data.Items) {
                        if (it.Length == null || it.Length == 0) {
                            it.Length = undefined;
                        }
                        if (it.Width == null || it.Width == 0) {
                            it.Width = undefined;
                        }
                        if (it.Height == null || it.Height == 0) {
                            it.Height = undefined;
                        }
                        it.ProductName = "";
                        if (it.Package && it.Package.Products && it.Package.Products.length > 0) {
                            for (var pro of it.Package.Products) {
                                if (pro.NameZh && pro.NameZh != "") {
                                    it.ProductName += "【" + pro.NameZh + "】";
                                }
                            }
                        }
                    }

                    const customsPackageTable = this.$refs["customsPackageTable"] as any;
                    customsPackageTable.clearSelection();
                    this.$nextTick(() => {
                        this.selectRowCount = 0;
                        if (this.item.Data && this.item.Data.Items) {

                            var item = [];
                            for (var it3 of this.item.Data.Items) {
                                if (it3.IsSelected == true) {
                                    this.selectRowCount++;
                                }
                                if (it3.IsSelected == true) {
                                    item.push(it3);
                                }
                            }
                            item.forEach((row) => {
                                customsPackageTable.toggleRowSelection(row, undefined);
                            });


                        }
                        this.isLoading = false;
                        this.isQuery = false;
                    });

                    if (this.item.Data.Items.length <= 0) {
                        if (this.bottomButtons) {
                            this.bottomButtons[1].Hide = true;
                        }
                    }
                }
                else {
                    if (this.bottomButtons) {
                        this.bottomButtons[1].Hide = true;
                    }
                }

                if (this.item && this.item.Data && this.item.Data.IsShiftout == true && this.bottomButtons) {
                    this.bottomButtons[0].Hide = true;
                    this.bottomButtons[1].Hide = true;
                    this.bottomButtons[2].Hide = true;
                }

                this.widthHeight = window.screen.height - 380;
                MiezzForm.clearCustomValidate(this.$refs, "ruleFormPlan", undefined);
            });

            //if (this.plan && this.plan.Dto) {
            //    this.plan.Dto.CurrentPage = this.ruleForm.CurrentPage;
            //    this.plan.Dto.PageSize = this.ruleForm.PageSize;
            //    this.plan.Dto.OrderPoolId = this.orderPoolId;
            //    this.plan.Dto.OrderDepartureWharfId = this.orderDepartureWharfId;
            //    this.plan.Dto.IsDetail = true;
            //    this.plan.Dto.Type = this.type;
            //}
            //OrderDepartureWharfAppService.GetPlans(this.plan.Dto).then((r) => {
            //    this.plan.Data = JSON.parse(JSON.stringify(r.data.Data));
            //})
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {

            switch (btn.Code) {
                case "submit":

                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {



                                //if (
                                //    this.item == undefined ||
                                //    this.item.Data == undefined ||
                                //    this.item.Data.Items == undefined ||
                                //    this.item.Data.Items.length <= 0
                                //) {
                                //    ElMessage.warning("请至少勾选一条信息");
                                //    complete();
                                //    return;
                                //}
                                //else {
                                //    var flag = false;
                                //    for (var item1 of this.item.Data.Items) {
                                //        if (item1.IsSelected == true) {
                                //            flag = true;
                                //            break;
                                //        }
                                //    }
                                //    if (flag == false) {
                                //        ElMessage.warning("请至少勾选一条信息");
                                //        complete();
                                //        return;
                                //    }
                                //}
                                MiezzForm.customSubmit(this.$refs, "ruleFormPlan", (v) => {
                                    if (v == true) {
                                        if (this.item.Data != undefined) {

                                            this.form.Data.Plans = [];
                                            this.form.Data.Id = this.orderDepartureWharfId;
                                            this.form.Data.SumQuantity = this.item.Data.SumQuantity;
                                            this.form.Data.SumGrossWeight = this.item.Data.SumGrossWeight;
                                            this.form.Data.SumVolume = this.item.Data.SumVolume;
                                            this.form.Data.ChargeUnit = this.item.Data.ChargeUnit;
                                            this.form.Data.Type = this.type;

                                            if (this.type == EnumOrderServiceType.DeparturePort) {
                                                if (this.item.Data.Items && this.item.Data.Items.length > 0) {
                                                    for (var plan of this.item.Data.Items) {
                                                        if (plan.IsSelected == true) {
                                                            this.form.Data.Plans?.push({
                                                                Length: plan.Length,
                                                                Width: plan.Width,
                                                                Height: plan.Height,
                                                                Volume: plan.Volume,
                                                                GrossWeight: plan.GrossWeight,
                                                                OrderPoolPackageShapeId: plan.Id,
                                                                SurplusQuantity: plan.SurplusQuantity,
                                                                DeliveryQuantity: plan.DeliveryQuantity,
                                                                PlanId:plan.PlanId,
                                                            });
                                                        }
                                                    }
                                                }
                                            }
                                            else if (this.type == EnumOrderServiceType.DestinationPort) {
                                                if (this.item.Data.Items && this.item.Data.Items.length > 0) {
                                                    for (var plan1 of this.item.Data.Items) {
                                                        if (plan1.IsSelected == true) {
                                                            this.form.Data.Plans?.push({
                                                                Length: plan1.Length,
                                                                Width: plan1.Width,
                                                                Height: plan1.Height,
                                                                Volume: plan1.Volume,
                                                                GrossWeight: plan1.GrossWeight,
                                                                //OrderDepartureWharfOutStockId: plan1.Id,
                                                                OrderPoolPackageShapeId: plan1.Id,
                                                                SurplusQuantity: plan1.SurplusQuantity,
                                                                DeliveryQuantity: plan1.DeliveryQuantity,
                                                                PlanId: plan1.PlanId,
                                                            });
                                                        }
                                                    }
                                                }
                                            }

                                        }
                                        this.form.Data.OrderPoolId = this.orderPoolId;
                                        this.$emit("onSelectPlan", this.form.Data);
                                        this.ruleForm.Visible = false;
                                        complete();
                                    } else {
                                        complete();
                                    }
                                });

                            }
                        }
                    })

                    break;
                case "import":

                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {


                                if (this.item.Data && this.item.Data.Items && this.itemInit.Data && this.itemInit.Data.Items) {
                                    for (var i = 0; i < this.item.Data.Items.length; i++) {
                                        var it = this.item.Data.Items[i];
                                        var it1 = this.itemInit.Data.Items[i];
                                        it.IsSelected = true;
                                        if (this.orderDepartureWharfId == undefined) {
                                            if (it1.SurplusQuantity != undefined && it1.SurplusQuantity > 0) {
                                                it.DeliveryQuantity = it1.SurplusQuantity;
                                            }

                                        }
                                        else {
                                            if (it1.CurrentSurplusQuantity != null && it1.CurrentSurplusQuantity != undefined) {
                                                if (it1.CurrentSurplusQuantity != undefined && it1.CurrentSurplusQuantity > 0) {
                                                    it.DeliveryQuantity = it1.CurrentSurplusQuantity;
                                                }
                                            }
                                            else {
                                                if (it1.SurplusQuantity != undefined && it1.SurplusQuantity > 0) {
                                                    it.DeliveryQuantity = it1.SurplusQuantity;
                                                }
                                            }

                                        }
                                        it.SurplusQuantity = 0;
                                    }
                                    const customsPackageTable = this.$refs["customsPackageTable"] as any;
                                    customsPackageTable.clearSelection();
                                    if (this.item.Data && this.item.Data.Items) {

                                        var item = this.item.Data.Items;
                                        setTimeout(function () {
                                            item.forEach((row) => {
                                                customsPackageTable.toggleRowSelection(row, undefined);
                                            });
                                        }, 100);
                                    }
                                    this.onSum();
                                }
                                complete();

                            }
                        }
                    })



                    break;
                case "save":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                        this.stateRequestParam.OrderId.push(this.orderId as string);
                    }
                    else if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                MiezzForm.submit(this.$refs, "$addRuleForm", (v) => {
                                    if (v) {

                                        OrderDepartureWharfAppService.GetFinishByPoolIdByType(this.addRuleForm.Data.OrderPoolId, this.type).then((r) => {
                                            if (r.data.Data == false) {


                                                //alert(this.orderPoolId);
                                                this.orderPoolId = this.addRuleForm.Data.OrderPoolId;
                                                //this.addRuleForm.Data.OrderPoolId = this.orderPoolId;
                                                if (this.clientCompanys) {
                                                    for (var client of this.clientCompanys) {
                                                        if (client.Data && client.Data.Pools) {
                                                            for (var pool of client.Data.Pools) {
                                                                if (pool.OrderPoolId == this.orderPoolId) {
                                                                    /*if (pool.WharfCount == 0) {
                                                                        OrderDepartureWharfAppService.AddByOrderPoolIdByType(this.orderPoolId, this.type).then((re) => {
                                                                            this.$emit("onAutoAddPlan");
                                                                            this.ruleForm.Visible = false;
                                                                            complete();
                                                                        })
                                                                    }
                                                                    else {
                                                                        if (this.bottomButtons) {
                                                                            this.bottomButtons[0].Hide = this.isDetail;
                                                                            this.bottomButtons[1].Hide = this.isDetail;
                                                                            this.bottomButtons[2].Hide = true;
                                                                        }
                                                                        this.isSelectClientCompany = false;
                                                                        this.onSearch();
                                                                    }*/
                                                                    if (this.bottomButtons) {
                                                                        this.bottomButtons[0].Hide = this.isDetail;
                                                                        this.bottomButtons[1].Hide = this.isDetail;
                                                                        this.bottomButtons[2].Hide = true;
                                                                    }
                                                                    this.isSelectClientCompany = false;
                                                                    this.onSearch();
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                            else {

                                                var url = encodeURIComponent(this.$route.fullPath);
                                                ElMessage.warning({
                                                    customClass: "testMessage",
                                                    dangerouslyUseHTMLString: true,
                                                    message: '当前清单的货物计划送货数量已全部分配完成，如需添加请先至<a href="/#/order/pool/edit/' + this.addRuleForm.Data.OrderPoolId + '?from=wharf&backUrl=' + url + '" style="font-weight:900;" >委托书</a>中修改货物数据。',
                                                })
                                                complete();
                                                return;
                                            }
                                        })



                                    } else complete();
                                });

                                complete();

                               


                            }
                        }
                    })


                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                case "pool":
                    //alert(this.orderPoolId);
                    ///order/pool/summary/bd8298a6-4078-49e4-a76e-08dbf218bf4e
                    //+ "?url=" + this.$route.fullPath
                    this.$router.push("/order/pool/detail/" + this.orderPoolId);
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleSelectionChange(val: any, index: number) {
            if (this.isQuery == false) {
                if (this.item.Data && this.item.Data.Items) {
                    for (var it of this.item.Data.Items) {
                        it.IsSelected = false;
                        for (var it2 of val) {
                            if (it.Id == it2.Id) {
                                it.IsSelected = true;
                            }
                        }
                    }
                }
                this.onSum();
            }
        }
        onChangeVolume(index: number): void {
            if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                var result = setDecimal(this.item.Data.Items[index].Volume + "", 4, false);
                if (result != "") {
                    this.item.Data.Items[index].Volume = Number(result);
                } else {
                    this.item.Data.Items[index].Volume = undefined;
                }
            }

            this.onSum();
        }
        onChangeGrossWeight(index: number): void {
            if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                var result = setDecimal(this.item.Data.Items[index].GrossWeight + "", 2, false);
                if (result != "") {
                    this.item.Data.Items[index].GrossWeight = Number(result);
                } else {
                    this.item.Data.Items[index].GrossWeight = undefined;
                }
            }

            this.onSum();
        }
        onVolume(index: number): void {

            if (this.item.Data && this.item.Data.Items && this.item.Data.Items[index].Length && this.item.Data.Items[index].Width && this.item.Data.Items[index].Height) {

                var num = Number(this.item.Data.Items[index].Length) * Number(this.item.Data.Items[index].Width) * Number(this.item.Data.Items[index].Height) / 1000000;
                if (num < 0.0001) {
                    num = 0;
                }
                var result1 = setDecimal(num.toString(), 4, false);
                if (result1 != "") {
                    this.item.Data.Items[index].Volume = Number(result1);
                }
                else {
                    this.item.Data.Items[index].Volume = undefined;
                }
            }
            this.onSum();
        }
        onChangeLength(index: number) {
            if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                var result = setDecimal(this.item.Data.Items[index].Length + "", 2, false);
                if (result != "") {
                    this.item.Data.Items[index].Length = Number(result);
                } else {
                    this.item.Data.Items[index].Length = undefined;
                }
            }
            this.onVolume(index);
        }
        onChangeWidth(index: number): void {
            if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                var result = setDecimal(this.item.Data.Items[index].Width + "", 2, false);
                if (result != "") {
                    this.item.Data.Items[index].Width = Number(result);
                } else {
                    this.item.Data.Items[index].Width = undefined;
                }
            }
            this.onVolume(index);
        }
        onChangeHeight(index: number): void {
            if (this.item.Data != undefined && this.item.Data.Items != undefined) {
                var result = setDecimal(this.item.Data.Items[index].Height + "", 2, false);
                if (result != "") {
                    this.item.Data.Items[index].Height = Number(result);
                } else {
                    this.item.Data.Items[index].Height = undefined;
                }
            }
            this.onVolume(index);
        }

        onChangeDeliveryQuantity(index: number) {
            if (
                this.item.Data &&
                this.item.Data.Items &&
                this.item.Data.Items[index] &&
                this.item.Data.Items[index].DeliveryQuantity &&
                this.itemInit.Data &&
                this.itemInit.Data.Items &&
                this.itemInit.Data.Items[index]
            ) {
                var result = setDecimal(
                    this.item.Data.Items[index].DeliveryQuantity + "",
                    0,
                    false
                );
                if (result != "") {
                    this.item.Data.Items[index].DeliveryQuantity = Number(result);
                } else {
                    this.item.Data.Items[index].DeliveryQuantity = undefined;
                }

                if (this.item.Data.Items[index].DeliveryQuantity != undefined) {
                    var Quantity = this.item.Data.Items[index].Quantity;
                    var DeliveryQuantity = this.item.Data.Items[index].DeliveryQuantity;
                    var SurplusQuantity = this.item.Data.Items[index].SurplusQuantity;
                    var SurplusQuantityInit = this.itemInit.Data.Items[index].SurplusQuantity;

                    var CurrentSurplusQuantityInit = this.itemInit.Data.Items[index].CurrentSurplusQuantity;
                    //alert(CurrentSurplusQuantityInit);

                    /*送货数量> 送货前的总剩余数量，送货数量等于送货前的总剩余数量 */
                    if (CurrentSurplusQuantityInit == null || CurrentSurplusQuantityInit == undefined) {
                        if (DeliveryQuantity != undefined && SurplusQuantityInit != undefined) {
                            if (DeliveryQuantity > SurplusQuantityInit) {
                                DeliveryQuantity = SurplusQuantityInit;
                                this.item.Data.Items[index].DeliveryQuantity = SurplusQuantityInit;
                            }
                        }
                    }
                    else {
                        if (DeliveryQuantity != undefined && CurrentSurplusQuantityInit != undefined) {
                            if (DeliveryQuantity > CurrentSurplusQuantityInit) {
                                DeliveryQuantity = CurrentSurplusQuantityInit;
                                this.item.Data.Items[index].DeliveryQuantity = CurrentSurplusQuantityInit;
                            }
                        }
                    }


                    /*剩余数量等于送货前的总剩余数量 - 当前送货数量 */
                    if (this.itemInit && this.itemInit.Data && this.itemInit.Data.Items) {
                        if (CurrentSurplusQuantityInit != null && CurrentSurplusQuantityInit != undefined) {
                            if (CurrentSurplusQuantityInit != undefined && DeliveryQuantity != undefined) {
                                this.item.Data.Items[index].SurplusQuantity = CurrentSurplusQuantityInit - DeliveryQuantity;
                            }
                        }
                        else {
                            if (SurplusQuantityInit != undefined && DeliveryQuantity != undefined) {
                                this.item.Data.Items[index].SurplusQuantity = SurplusQuantityInit - DeliveryQuantity;
                            }
                        }

                    }

                    /*var SurplusQuantityInit = this.itemInit.Data.Items[index].SurplusQuantity;
                    var DeliveryQuantityInit = this.itemInit.Data.Items[index].DeliveryQuantity;
                    if (DeliveryQuantityInit != undefined && DeliveryQuantityInit != 0) {
                        CurrentSurplusQuantityInit = this.itemInit.Data.Items[index].Quantity;
                        SurplusQuantityInit = this.itemInit.Data.Items[index].SurplusQuantity;
                    }
                    else {
                        CurrentSurplusQuantityInit = this.itemInit.Data.Items[index].CurrentSurplusQuantity;
                        SurplusQuantityInit = this.itemInit.Data.Items[index].SurplusQuantity;
                        if (CurrentSurplusQuantityInit == null && SurplusQuantityInit != undefined) {
                            CurrentSurplusQuantityInit = SurplusQuantityInit;
                        }
                    }


                    if (this.orderDepartureWharfId == undefined) {
                        if (DeliveryQuantity != undefined && SurplusQuantityInit != undefined) {
                            if (DeliveryQuantity > SurplusQuantityInit) {
                                DeliveryQuantity = SurplusQuantityInit;
                                this.item.Data.Items[index].DeliveryQuantity = DeliveryQuantity;
                            }
                        }

                        if (Quantity != undefined && DeliveryQuantity != undefined && SurplusQuantityInit != undefined) {
                            this.item.Data.Items[index].SurplusQuantity = SurplusQuantityInit - DeliveryQuantity;

                            if (DeliveryQuantity > Quantity) {
                                this.item.Data.Items[index].SurplusQuantity = 0;
                                this.item.Data.Items[index].DeliveryQuantity = Quantity;
                            }
                        }
                    }
                    else {
                        if (DeliveryQuantity != undefined && CurrentSurplusQuantityInit != undefined) {
                            if (DeliveryQuantity > CurrentSurplusQuantityInit) {
                                DeliveryQuantity = CurrentSurplusQuantityInit;
                                this.item.Data.Items[index].DeliveryQuantity = DeliveryQuantity;
                            }
                        }

                        if (Quantity != undefined && DeliveryQuantity != undefined && CurrentSurplusQuantityInit != undefined) {
                            this.item.Data.Items[index].SurplusQuantity = CurrentSurplusQuantityInit - DeliveryQuantity;

                            if (DeliveryQuantity > Quantity) {
                                this.item.Data.Items[index].SurplusQuantity = 0;
                                this.item.Data.Items[index].DeliveryQuantity = Quantity;
                            }
                        }
                    }*/
                }
                //alert(this.item.Data.Items[index].SurplusQuantity);
            }
           
            this.onSum();
        }

        onSum(): void {
            this.selectRowCount = 0;
            if (this.item.Data && this.item.Data.Items) {

                this.item.Data.SumQuantity = 0;
                this.item.Data.SumGrossWeight = 0;
                this.item.Data.SumVolume = 0;


                for (var it of this.item.Data.Items) {
                    if (it.IsSelected == true) {
                        this.selectRowCount++;
                    }
                    if (it.DeliveryQuantity != undefined && it.IsSelected == true) {
                        this.item.Data.SumQuantity += it.DeliveryQuantity;

                        if (it.GrossWeight != undefined) {
                            this.item.Data.SumGrossWeight += it.DeliveryQuantity * it.GrossWeight;
                        }
                        if (it.Volume != undefined) {
                            this.item.Data.SumVolume += it.DeliveryQuantity * it.Volume;
                        }
                    }
                }

            }


            this.isQuantity = false;
            if (this.item.Data) {
                var Quantity = 0;
                if (this.item.Data.Items) {
                    for (var it2 of this.item.Data.Items) {
                        if (it2.Quantity) {
                            Quantity += it2.Quantity;
                        }
                    }
                }
                if (this.item.Data.SumQuantity > Quantity) {
                    this.isQuantity = true;
                }
            }

            if (this.item.Data && this.item.Data.SumQuantity == 0) {
                this.item.Data.SumQuantity = undefined;
            }

            if (this.item.Data && this.item.Data.SumGrossWeight == 0) {
                this.item.Data.SumGrossWeight = undefined;
            }

            if (this.item.Data && this.item.Data.SumVolume == 0) {
                this.item.Data.SumVolume = undefined;
            }

            this.onChangeSumGrossWeight();
            this.onChangeSumVolume();
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
