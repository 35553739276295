import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderDepartureWharf_Company from "./DtoListItemOrderDepartureWharf_Company";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex";
import DtoListItemOrderDepartureWharf_ParamPort from "./DtoListItemOrderDepartureWharf_ParamPort";
import DtoListItemOrderInboundOutbound_OrderPool from "./DtoListItemOrderInboundOutbound_OrderPool";
import DtoListItemOrderDepartureWharf_ParamTransportChannel from "./DtoListItemOrderDepartureWharf_ParamTransportChannel";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";

/**
 * 出入库管理分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderInboundOutbound extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsShiftout = false;
        this.IsCheckPool = true;
    }

    [proName: string]: any;
    /**
     * 进仓编号
     */
    Code?: string;
    /**
     * 订单编号
     */
    OrderCode?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * 委托人
     */
    ClientCompany?: DtoListItemOrderDepartureWharf_Company;
    /**
     * 港口
     */
    Port?: string;
    /**
     * 计划货量
     */
    Plans?: DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan[];
    /**
     * 收货截止时间
     */
    ReceivingDeadline?: Date;
    /**
     * 入库货量
     */
    InStocks?: DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock[];
    /**
     * 全部入库时间
     */
    InStockTime?: Date;
    /**
     * 出库货量
     */
    OutStocks?: DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock[];
    /**
     * 全部出库时间
     */
    OutStockTime?: Date;
    /**
     * 供应商
     */
    Supplier?: string;
    /**
     * 货物清单
     */
    GoodsInventory?: string;
    /**
     * 附件
     */
    Annexs?: DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex[];
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 合计件数-入库
     */
    SumQuantityInStock?: number;
    /**
     * 合计毛重-入库
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积-入库
     */
    SumVolumeInStock?: number;
    /**
     * 计价单位-入库
     */
    ChargeUnitInStock?: string;
    /**
     * 合计件数-出库
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重-出库
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积-出库
     */
    SumVolumeOutStock?: number;
    /**
     * 计价单位-出库
     */
    ChargeUnitOutStock?: string;
    /**
     * 装货港
     */
    DispatchPortId?: string;
    /**
     * 装货港
     */
    DispatchPort?: DtoListItemOrderDepartureWharf_ParamPort;
    /**
     * 目的港
     */
    DestinationPortId?: string;
    /**
     * 目的港
     */
    DestinationPort?: DtoListItemOrderDepartureWharf_ParamPort;
    /**
     * 委托书
     */
    Pool?: DtoListItemOrderInboundOutbound_OrderPool;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 渠道Id
     */
    TransportChannelId?: string;
    /**
     * 渠道
     */
    TransportChannel?: DtoListItemOrderDepartureWharf_ParamTransportChannel;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 联系人
     */
    Contacter?: string;
    /**
     * 作业时间
     */
    WorkTime?: string;
    /**
     * 报关资料截止时间
     */
    CustomsDeadline?: Date;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 类型：装货港/目的港
     */
    Type?: EnumOrderServiceType;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
    /**
     * CreatedTime
     */
    CreatedTime?: string;
    /**
     * 是否库存建单
     */
    isAddStock?: boolean;
    /**
     * 货物清单是否已有数据
     */
    IsGoods?: boolean;
    /**
     * 是否能查看对应的委托书
     */
    IsCheckPool?: boolean;
}

/**
 * 出入库管理分页列表-项-表单校验
 */
export const DtoListItemOrderInboundOutboundRules = {
}

/**
 * 出入库管理分页列表-项-设置项
 */
export const DtoListItemOrderInboundOutboundMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "进仓编号",
  },
  {
    Prop: "OrderCode",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    Customize: true,
  },
  {
    Prop: "Port",
    Label: "港口",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Plans",
    Label: "计划货量",
    Customize: true,
  },
  {
    Prop: "ReceivingDeadline",
    Label: "收货截止时间",
    Customize: true,
  },
  {
    Prop: "InStocks",
    Label: "入库货量",
    Customize: true,
  },
  {
    Prop: "InStockTime",
    Label: "全部入库时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "OutStocks",
    Label: "出库货量",
    Customize: true,
  },
  {
    Prop: "OutStockTime",
    Label: "全部出库时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Supplier",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "GoodsInventory",
    Label: "货物清单",
    Customize: true,
  },
  {
    Prop: "Annexs",
    Label: "附件",
    Customize: true,
  },
]
