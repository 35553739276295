import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoFormCompany_CompanyInvoiceTaxRate from "./DtoFormCompany_CompanyInvoiceTaxRate";
import DtoFormCompany_JoinCompanyType from "./DtoFormCompany_JoinCompanyType";
import { EnumCompanyStatus, EnumCompanyStatusOption } from "./EnumCompanyStatus";
import DtoFormCompany_CompanyFile from "./DtoFormCompany_CompanyFile";
import UiGroup from "./UiGroup";

/**
 * 公司表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompany extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.CompanyInvoiceTaxRates = [];
        this.JoinCompanyTypes = [];
        this.Status = EnumCompanyStatus.Normal;
        this.CompanyFiles = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyType;
    /**
     * 服务商
     */
    ServiceProviderCompanyId?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 公司英文缩写
     */
    NameEnAbbr?: string;
    /**
     * 中文注册地址
     */
    RegisteredAddressZh?: string;
    /**
     * 英文注册地址
     */
    RegisteredAddressEn?: string;
    /**
     * 中文收件地址
     */
    ReceivingAddressZh?: string;
    /**
     * 中文收件地址-邮编
     */
    ReceivingAddressZhZipCode?: string;
    /**
     * 英文收件地址
     */
    ReceivingAddressEn?: string;
    /**
     * 企业代码类型
     */
    ParamCompanyCodeTypeId?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 注册地所属国家
     */
    ParamCountryId?: string;
    /**
     * 所属国海关编码
     */
    CustomsCode?: string;
    /**
     * 纳税人类型
     */
    ParamTaxTypeId?: string;
    /**
     * 开票税率
     */
    CompanyInvoiceTaxRates?: DtoFormCompany_CompanyInvoiceTaxRate[];
    /**
     * 电话
     */
    Tel?: string;
    /**
     * 传真
     */
    Fax?: string;
    /**
     * 邮箱
     */
    Email?: string;
    /**
     * 网址
     */
    Url?: string;
    /**
     * 公司类型
     */
    JoinCompanyTypes?: DtoFormCompany_JoinCompanyType[];
    /**
     * 公司状态
     */
    Status?: EnumCompanyStatus;
    /**
     * 业务员
     */
    SalesmanId?: string;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 客户来源
     */
    Source?: string;
    /**
     * 业务范围
     */
    BusinessScope?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 营业执照
     */
    BusinessLicense?: string;
    /**
     * logo
     */
    Logo?: string;
    /**
     * 附件
     */
    CompanyFiles?: DtoFormCompany_CompanyFile[];
}

/**
 * 公司表单-表单校验
 */
export const DtoFormCompanyRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    RegisteredAddressZh: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    RegisteredAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ReceivingAddressZh: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ReceivingAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    CustomsCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Tel: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Fax: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Email: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Url: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    JoinCompanyTypes: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
            type: "array",
        },
    ],
    Status: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Source: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    BusinessScope: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 公司表单-设置项
 */
export const DtoFormCompanyMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ServiceProviderCompanyId",
    Label: "服务商",
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "RegisteredAddressZh",
    Label: "中文注册地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "RegisteredAddressEn",
    Label: "英文注册地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ReceivingAddressZh",
    Label: "中文收件地址",
    Customize: true,
  },
  {
    Prop: "ReceivingAddressEn",
    Label: "英文收件地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ParamCompanyCodeTypeId",
    Label: "企业代码类型",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ParamCountryId",
    Label: "注册地所属国家",
    Customize: true,
  },
  {
    Prop: "CustomsCode",
    Label: "所属国海关编码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ParamTaxTypeId",
    Label: "纳税人类型",
    Customize: true,
  },
  {
    Prop: "CompanyInvoiceTaxRates",
    Label: "开票税率",
    Customize: true,
  },
  {
    Prop: "Tel",
    Label: "电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Fax",
    Label: "传真",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Email",
    Label: "邮箱",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Url",
    Label: "网址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "JoinCompanyTypes",
    Label: "公司类型",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "公司状态",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumCompanyStatusOption,
  },
  {
    Prop: "SalesmanId",
    Label: "业务员",
    Customize: true,
  },
  {
    Prop: "Source",
    Label: "客户来源",
    Customize: true,
  },
  {
    Prop: "BusinessScope",
    Label: "业务范围",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Customize: true,
  },
  {
    Prop: "BusinessLicense",
    Label: "营业执照",
    Customize: true,
  },
  {
    Prop: "Logo",
    Label: "logo",
    Customize: true,
  },
  {
    Prop: "CompanyFiles",
    Label: "附件",
    Customize: true,
  },
]
