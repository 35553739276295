import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceCalc extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Descriptions = [];
    }

    [proName: string]: any;
    /**
     * 费用Id
     */
    CostId?: string;
    /**
     * 费用名称
     */
    Cost?: string;
    /**
     * 币制Id
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: string;
    /**
     * 单价
     */
    Amount?: number;
    /**
     * 计价单位
     */
    FirstChargingUnitId?: string;
    /**
     * 数量
     */
    FirstChargingNumber?: number;
    /**
     * 第二计价单位
     */
    SecondChargingUnitId?: string;
    /**
     * 第二计价数量
     */
    SecondChargingNumber?: number;
    /**
     * 合计
     */
    Total?: number;
    /**
     * 说明
     */
    Description?: string;
    /**
     * 说明
     */
    Descriptions?: string[];
}

/**
 * -表单校验
 */
export const DtoListItemPriceCalcRules = {
}

/**
 * -设置项
 */
export const DtoListItemPriceCalcMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Cost",
    Label: "费用名称",
  },
  {
    Prop: "CurrencySystem",
    Label: "币制",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Amount",
    Label: "单价",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "计价单位",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Total",
    Label: "合计",
    MaxWidth: "120px",
  },
  {
    Prop: "Description",
    Label: "说明",
    Customize: true,
  },
]
