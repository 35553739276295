
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ParamAuditLogAppService from "@/apis.machine/ParamAuditLogAppService";
import DtoPageListSearchParamAuditLog, {
  DtoPageListSearchParamAuditLogDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchParamAuditLog";
import DtoPageListParamAuditLog from "@/models.machine/DtoPageListParamAuditLog";
import DtoListItemParamAuditLog, {
  DtoListItemParamAuditLogMiezzListItems,
} from "@/models.machine/DtoListItemParamAuditLog";
import ParamAuditLogDetail from "./ParamAuditLogDetail.vue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import ParamListAppService from "@/apis.machine/ParamListAppService";

@Options({
  components: {
    ParamAuditLogDetail,
  },
})
export default class ParamAuditLogPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchParamAuditLog,
    DtoListItemParamAuditLog,
    string
  >("System_AuditLog");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  
  optParamAuditStatus?: UiSelectOption<any>[] = [];
  optProjectSite?: UiSelectOption<string>[] = [];
  optParamAuditResult?: UiSelectOption<any>[] = [];
  optParamList?: UiSelectOption<any>[] = [];

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchParamAuditLog();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchParamAuditLogDynamicSearchItems;
    this.list.EnabledSelect = false;
    //列表-数据-初始
    this.list.Data = new DtoPageListParamAuditLog();
    //列表-列配置
    this.list.Items = DtoListItemParamAuditLogMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看审核",
        Icon: "document",
        Type: "default",
        Size: "small",
      },
    ];

    this.list.CheckRowButtonShow = this.checkRowButtonShow;
    this.optParamAuditStatus = [{
      Label: "待处理",
      Value: 0
    },{
      Label: "已处理",
      Value: 10
    }];
    this.optParamAuditResult = [{
      Label: "通过",
      Value: 0
    },{
      Label: "拒绝",
      Value: 1
    }];
    const logier = CurrentLogier.GetLogier();
    if (logier) {
      if (logier.CompanyType != EnumCompanyType.Platform) {
        this.list.DynamicSearchItems = this.list.DynamicSearchItems.filter(it => it.Prop != "DynamicSearch_ProjectSiteId");
      } else {
        //查询站点
        ProjectSiteAppService.GetSelect().then((r) => {
          this.optProjectSite = r.data.Data;
        });
      }
    } 
  }

  /**查询列表 */
  handleGet(): void {
    ParamAuditLogAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
    
    ParamListAppService.GetList().then((r) => {
      if (r.data.Data) {
        this.optParamList = r.data.Data;
        this.optParamList.forEach(element => {
          element.Label = element.Name;
          element.Value = element.Name;
        })
      }
      
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemParamAuditLog,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `审核`;
        this.modalDetail.Width = "75%";
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**校验行按钮是否显示 */
  checkRowButtonShow(btn: MiezzButton, row: DtoListItemParamAuditLog): boolean {
    if (btn.Code == "audit" && row.Status == 10) {
      return false;
    }
    return true;
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
