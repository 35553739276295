import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_code_choose_property_tag = _resolveComponent("code-choose-property-tag", true)!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
    return (_openBlock(), _createElementBlock("span", { key: i }, [
      (item.Properties.length > 0)
        ? (_openBlock(), _createBlock(_component_code_choose_property_tag, {
            key: 0,
            modelValue: item.Properties,
            "onUpdate:modelValue": ($event: any) => ((item.Properties) = $event),
            base: `${_ctx.base ?? ''}${item.Name}.`
          }, null, 8, ["modelValue", "onUpdate:modelValue", "base"]))
        : (_openBlock(), _createBlock(_component_el_tag, {
            key: 1,
            type: "primary",
            size: "small",
            effect: "dark",
            class: "tag",
            onClick: ($event: any) => (_ctx.handleClick(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${_ctx.base ?? ""}${item.Name}`), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
    ]))
  }), 128))
}