import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailParamCompanyCodeType_ParamCountry from "./DtoDetailParamCompanyCodeType_ParamCountry";
import UiGroup from "./UiGroup";

/**
 * 企业代码类型详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamCompanyCodeType extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 国家Id
     */
    ParamCountryId?: string;
    /**
     * 国家
     */
    ParamCountry?: DtoDetailParamCompanyCodeType_ParamCountry;
    /**
     * 国家代码
     */
    CountryCode?: string;
    /**
     * 企业代码类型
     */
    CompanyCode?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 企业代码类型详细-表单校验
 */
export const DtoDetailParamCompanyCodeTypeRules = {
}

/**
 * 企业代码类型详细-设置项
 */
export const DtoDetailParamCompanyCodeTypeMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountry",
    Label: "国家",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CountryCode",
    Label: "国家代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CompanyCode",
    Label: "企业代码类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
]
