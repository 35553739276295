import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_exception_log_detail_stack_trace = _resolveComponent("exception-log-detail-stack-trace")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'StackTraceItems')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.StackTraceItems, (stackTrace, stackTraceIndex) => {
                  return (_openBlock(), _createBlock(_component_el_alert, {
                    key: stackTraceIndex,
                    title: `${stackTrace.At} ${stackTrace.RowNumber}`,
                    type: stackTrace.RowNumber ? 'default' : 'info',
                    closable: false,
                    onClick: ($event: any) => (_ctx.handleDetailStackTrace(stackTraceIndex))
                  }, null, 8, ["title", "type", "onClick"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalStackTrace,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalStackTrace) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_exception_log_detail_stack_trace, {
          modelValue: _ctx.modalStackTrace,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalStackTrace) = $event)),
          file: _ctx.stackTrace?.File,
          rowNumber: _ctx.stackTrace?.RowNumber,
          createdTime: _ctx.detail.Data.CreatedTime
        }, null, 8, ["modelValue", "file", "rowNumber", "createdTime"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}