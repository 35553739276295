
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    export default class ProductMenu extends Vue {
        @Model() modelValue?: MiezzMenuTab;
        menuTab = new MiezzMenuTab();
        id = "";
        created(): void {
            this.id = this.$route.params.id as string;
            this.menuTab = this.modelValue ?? new MiezzMenuTab();
            this.menuTab.Url = `/product/info/${this.id}`;
            this.menuTab.Back = true;
            this.menuTab.Menus = [
                {
                    Label: "基础信息",
                    Url: `/product/detail/${this.id}`,
                    Power: "ProductManagement_ProductManagement_ProductInformation_BasicInformation",
                },
                {
                    Label: "规格型号",
                    Url: `/product/spec/${this.id}`,
                    Power: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel",
                },
                {
                    Label: "产品包装",
                    Url: `/product/package/${this.id}`,
                    Power: "ProductManagement_ProductManagement_ProductInformation_ProductPackaging",
                },
                //{
                //    Label: "申报记录",
                //    Url: `/product/apply/${this.id}`,
                //    Power: "ProductManagement_ProductManagement_ProductInformation_DeclarationRecord",
                //},
            ];
        }
    }
