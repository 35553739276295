
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzForm from "@/models/MiezzForm";
import DtoCallbackExceptionLogGetStackTraceCodePart, {
  DtoCallbackExceptionLogGetStackTraceCodePartMiezzDetailItems,
} from "@/models.machine/DtoCallbackExceptionLogGetStackTraceCodePart";
import ExceptionLogAppService from "@/apis.machine/ExceptionLogAppService";
import CodeMirror from "codemirror";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/mode/clike/clike";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/seti.css";
import MiezzModal from "@/models/MiezzModal";

@Options({
  components: {},
})
export default class ExceptionLogDetailStackTrace extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() file?: string;
  @Prop() rowNumber?: number;
  @Prop() createdTime?: Date;

  detail = new MiezzForm<
    DtoCallbackExceptionLogGetStackTraceCodePart,
    string
  >();

  /**创建时 */
  created(): void {
    this.modelValue.Title = "代码片段";
    this.modelValue.Width = "60%";

    //详细-数据-初始化
    this.detail.Data = new DtoCallbackExceptionLogGetStackTraceCodePart();
    this.detail.Items =
      DtoCallbackExceptionLogGetStackTraceCodePartMiezzDetailItems;
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.file && this.rowNumber) {
      ExceptionLogAppService.GetStackTraceCodePart({
        File: this.file,
        RowNumber: this.rowNumber,
        Date: this.createdTime,
      }).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          this.$nextTick(() => {
            const cm = CodeMirror.fromTextArea(
              this.$refs["codemirror"] as any,
              {
                lineNumbers: true,
                firstLineNumber: this.detail.Data.BeginRowNumber,
                matchBrackets: true,
                mode: "text/x-csharp",
                theme: "seti",
              }
            );
            cm.setSize("100%", "auto");
            const index =
              (this.rowNumber ?? 0) - (this.detail.Data.BeginRowNumber ?? 0);
            const lines = document.getElementsByClassName("CodeMirror-line");
            lines[index].classList.add("active");
          });
        }
      });
    }
  }
}
