import UiSelectOption from "./UiSelectOption";

/**
 * 核销方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumSettlementMode {
    /**
     * 对公银行
     */
    Bank = 10,
    /**
     * 现金/私人银行/其他
     */
    Cash = 20,
}

/**
 * 核销方式-选项
 */
export const EnumSettlementModeOption: UiSelectOption<number>[] = [
    { Label: "对公银行", Value: 10, Data: "Bank" },
    { Label: "现金/私人银行/其他", Value: 20, Data: "Cash" },
];
