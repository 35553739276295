import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumPipe } from "./EnumPipe";

/**
 * 列表-行
 */
export default class MiezzListItem {
    [proName: string]: any;
    /**标签 */
    Label?: string;
    /**绑定字段 */
    Prop?: string;
    /**宽度 */
    Width?: string;
    /**宽度 */
    MaxWidth?: string;
    /**隐藏 */
    Hide?: boolean;
    /**预文本 */
    Pre?: boolean;
    /**是否自定义 */
    Customize?: boolean;
    /**是否自定义表头 */
    CustomizeHeader?: boolean;
    /**管道 */
    Pipe?: EnumPipe;
    /**当true时 */
    WhenTrue?: string;
    /**当false时 */
    WhenFalse?: string;
    /**选项数据 */
    Options?: UiSelectOption<any>[];
    /**禁用title */
    DisabledTooltip?: boolean;
}