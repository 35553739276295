import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";
import DtoListItemCompanyCharge_CompanyChargeServiceRemark from "./DtoListItemCompanyCharge_CompanyChargeServiceRemark";

/**
 * 公司发票分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyCharge extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Sort = 0;
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyChargeType;
    /**
     * 材积比
     */
    VolumeRatio?: number;
    /**
     * 单件最低标准
     */
    MinStandardPiece?: number;
    /**
     * 单票最低标准
     */
    MinStandardTicket?: number;
    /**
     * 关联服务
     */
    ServiceRemarks?: DtoListItemCompanyCharge_CompanyChargeServiceRemark[];
    /**
     * 关联服务
     */
    Services?: string;
    /**
     * 所属公司主键
     */
    FromId?: string;
    /**
     * 要计费的公司主键
     */
    ToId?: string;
    /**
     * 是否默认
     */
    IsDefault?: boolean;
    /**
     * 排序
     */
    Sort?: number;
}

/**
 * 公司发票分页列表-项-表单校验
 */
export const DtoListItemCompanyChargeRules = {
}

/**
 * 公司发票分页列表-项-设置项
 */
export const DtoListItemCompanyChargeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "类型",
    Customize: true,
  },
  {
    Prop: "VolumeRatio",
    Label: "材积比",
  },
  {
    Prop: "MinStandardPiece",
    Label: "单件最低标准",
    Customize: true,
  },
  {
    Prop: "MinStandardTicket",
    Label: "单票最低标准",
    Customize: true,
  },
  {
    Prop: "Services",
    Label: "关联服务",
    Customize: true,
  },
]
