import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    action: _ctx.model.Url,
    class: "miezz-upload",
    accept: _ctx.model.Accept,
    "show-file-list": false,
    "before-upload": _ctx.handleBeforeUpload,
    limit: 1
  }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_button, {
        model: _ctx.button,
        onHandleClick: _ctx.handleClick
      }, null, 8, ["model", "onHandleClick"])
    ]),
    _: 1
  }, 8, ["action", "accept", "before-upload"]))
}