
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import OrderPoolContainerTrailerAppService from "@/apis.machine/OrderPoolContainerTrailerAppService";
import DtoPageListSearchOrderPoolContainerTrailer from "@/models.machine/DtoPageListSearchOrderPoolContainerTrailer";
import DtoPageListOrderPoolContainerTrailer from "@/models.machine/DtoPageListOrderPoolContainerTrailer";
import DtoListItemOrderPoolContainerTrailer, {
  DtoListItemOrderPoolContainerTrailerMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolContainerTrailer";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamContainerTypeAppService from "@/apis.machine/ParamContainerTypeAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoHistoryCompanyAddress_CompanyName from "@/models.machine/DtoHistoryCompanyAddress_CompanyName";
import DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress from "@/models.machine/DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress";
import DtoCallbackAMapPlaceTextPoi from "@/models.machine/DtoCallbackAMapPlaceTextPoi";
import DtoHistoryCompanyAddress_Contact from "@/models.machine/DtoHistoryCompanyAddress_Contact";
import moment from "moment";
import MiezzForm from "@/models/MiezzForm";
import DtoPutOrderPoolContainerTrailer_ArrivalTime, {
  DtoPutOrderPoolContainerTrailer_ArrivalTimeMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_ArrivalTimeRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_ArrivalTime";
import DtoPutOrderPoolContainerTrailer_Addresses, {
  DtoPutOrderPoolContainerTrailer_AddressesMiezzFormItems,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_Addresses";
import DtoPutOrderPoolContainerTrailer_ContainerType, {
  DtoPutOrderPoolContainerTrailer_ContainerTypeMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_ContainerTypeRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_ContainerType";
import { DtoPutOrderPoolContainerTrailer_Addresses_AddresseRules } from "@/models.machine/DtoPutOrderPoolContainerTrailer_Addresses_Addresse";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoPutOrderPoolContainerTrailer_Executor, {
  DtoPutOrderPoolContainerTrailer_ExecutorMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_ExecutorRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_Executor";
import DtoPutOrderPoolContainerTrailer_CarryContainerArea, {
  DtoPutOrderPoolContainerTrailer_CarryContainerAreaMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_CarryContainerAreaRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_CarryContainerArea";
import DtoPutOrderPoolContainerTrailer_CarryContainerTime, {
  DtoPutOrderPoolContainerTrailer_CarryContainerTimeMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_CarryContainerTimeRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_CarryContainerTime";
import DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime, {
  DtoPutOrderPoolContainerTrailer_PlanCarryContainerTimeMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_PlanCarryContainerTimeRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime";
import DtoPutOrderPoolContainerTrailer_IntoPortWharf, {
  DtoPutOrderPoolContainerTrailer_IntoPortWharfMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_IntoPortWharfRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_IntoPortWharf";
import DtoPutOrderPoolContainerTrailer_BackContainerTime, {
  DtoPutOrderPoolContainerTrailer_BackContainerTimeMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_BackContainerTimeRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_BackContainerTime";
import DtoPutOrderPoolContainerTrailer_ContainerNo, {
  DtoPutOrderPoolContainerTrailer_ContainerNoMiezzFormItems,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_ContainerNo";
import DtoPutOrderPoolContainerTrailer_SealNo, {
  DtoPutOrderPoolContainerTrailer_SealNoMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_SealNoRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_SealNo";
import DtoPutOrderPoolContainerTrailer_File, {
  DtoPutOrderPoolContainerTrailer_FileMiezzFormItems,
  DtoPutOrderPoolContainerTrailer_FileRules,
} from "@/models.machine/DtoPutOrderPoolContainerTrailer_File";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
import CompanyAddressAppService from "@/apis.machine/CompanyAddressAppService";
import DtoHistory from "@/models.machine/DtoHistory";
import Logier from "@/models.machine/Logier";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import { isArray } from "element-plus/es/utils";
import OrderSummaryTime from "../../OrderSummaryTime.vue";
import OrderSupplier from "../../OrderSupplier.vue";
import OrderAppService from "@/apis.machine/OrderAppService";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";

@Options({
  components: {
    OrderSummaryTime,
    OrderSupplier,
  },
})
export default class ContainerTrailerPageList extends Vue {
  @Prop() menuTab!: MiezzMenuTab;
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchOrderPoolContainerTrailer,
    DtoListItemOrderPoolContainerTrailer,
    string
  >;
  @Prop() serviceType!: EnumOrderServiceType;
  @Prop() addPower = "";
  @Prop() editPower = "";
  @Prop() deletePower = "";
  @Prop() orderId?: string;
  @Prop() poolId?: string;

  logier?: Logier;
  EnumCompanyType = EnumCompanyType;
  /**当前Id */
  id?: string;
  EnumOrderServiceType = EnumOrderServiceType;
  _orderId?: string = "";
  powers: string[] = [];
  btnAdd = new MiezzButton();
  histories: DtoHistory[] = [];
  canAdd = false;
  canEdit = false;

  addDeparturePortContainer = false;
  feedbackDeparturePortContainer = false;
  addDestinationPortContainer = false;
  feedbackDestinationPortContainer = false;

  /**创建时 */
  created(): void {
    this.addDeparturePortContainer =
      this.$route.query.task == "AddDeparturePortContainer";
    this.feedbackDeparturePortContainer =
      this.$route.query.task == "FeedbackDeparturePortContainer";
    this.addDestinationPortContainer =
      this.$route.query.task == "AddDestinationPortContainer";
    this.feedbackDestinationPortContainer =
      this.$route.query.task == "FeedbackDestinationPortContainer";

    this.logier = CurrentLogier.GetLogier();

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrderPoolContainerTrailer();
    this.list.Dto.PageSize = 99999;
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderPoolContainerTrailer();
    this.list.CheckRowButtonShow = (btn, row) => {
      return this.list.Data?.StowageOrder != true;
    };
    //列表-列配置
    this.list.Items = JSON.copy(
      DtoListItemOrderPoolContainerTrailerMiezzListItems
    );
    for (const item of this.list.Items ?? []) {
      switch (item.Prop) {
        case "Addresses":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "装柜地址"
              : "卸货地址";
          break;
        case "ArrivalTime":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "装柜时间"
              : "卸货时间";
          break;
        case "BackContainerTime":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "进港时间"
              : "还柜时间";
          break;
        case "CarryContainerAreaLabel":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "提柜地点"
              : "提柜码头";
          break;
        case "IntoPortWharfLabel":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "进港码头"
              : "还柜地点";
          break;

        default:
          break;
      }
    }
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.CellClassName = (data: any) => {
        const row = data.row as DtoListItemOrderPoolContainerTrailer;
        const add =
          this.addDeparturePortContainer || this.addDestinationPortContainer;
        const feedback =
          this.feedbackDeparturePortContainer ||
          this.feedbackDestinationPortContainer;
        switch (data.column.label) {
          case "箱型":
            if (add && !row.ContainerTypeId) {
              return "red";
            }
            break;
          case "装柜地址":
          case "卸货地址":
            if (
              add &&
              !row.Addresses?.any(
                (it) => it.PlanTime && it.CompanyAddress?.Address
              )
            ) {
              return "red";
            }
            break;
          case "供应商":
            if (add && !row.SupplierCompanyId) {
              return "red";
            }
            break;
          // case "提柜时间":
          //   if (feedback && !row.CarryContainerTime) {
          //     return "red";
          //   }
          //   break;
          case "箱号":
            if (feedback && !row.ContainerNo) {
              return "red";
            }
            break;
          case "封号":
            if (feedback && !row.SealNo) {
              return "red";
            }
            break;
          case "装柜时间":
          case "卸货时间":
            if (feedback && !row.ArrivalTime) {
              return "red";
            }
            break;

          default:
            break;
        }

        return "";
      };
    }

    this.btnAdd = {
      Code: "add",
      Label: "添加",
      Icon: "plus",
      Type: "text",
      // Size: "small",
      PowerCode: this.addPower,
    };
  }

  /**格式化时间 */
  formatTime(v?: Date): string {
    return v ? moment(v).format("YYYY/MM/DD HH:mm") : "暂无数据";
  }
  /**格式化枚举 */
  formatEnum(options: UiSelectOption<number>[], value?: number): string {
    return (
      options.firstOrDefault((it) => it.Value == value)?.Label ?? "暂无数据"
    );
  }

  /**查询列表 */
  handleGet(): void {
    const dto = JSON.copy(this.list.Dto);
    dto.PoolId = this.poolId;
    dto.OrderId = this.orderId;
    dto.ServiceType = this.serviceType;
    dto.PageSize = 9999999;
    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
      OrderPoolContainerTrailerAppService.Get(dto).then((r) => {
        this._orderId = r.data.Data?.OrderId;
        this.list.Data = r.data.Data;
        this.canAdd =
          this.list.Data?.StowageOrder != true &&
          this.powers.contains(this.addPower);
        this.canEdit = this.powers.contains(this.editPower);
        if (
          this.list.Data?.StowageOrder != true &&
          this.powers.contains(this.deletePower)
        ) {
          this.list.RowButtons = [
            {
              Code: "delete",
              Title: "删除",
              Icon: "delete",
              Type: "default",
              Size: "small",
              Message: "是否确认删除",
              PowerCode: this.deletePower,
            },
          ];
        }
        if (this.list.Data?.UseThreeApiService) {
          const props = [
            "PlanCarryContainerTime",
            "CarryContainerTime",
            "CarryContainerAreaLabel",
            "IntoPortWharfLabel",
            "BackContainerTime",
            "Tare",
          ];
          this.list.Items?.filter((it) => props.contains(it.Prop)).map((it) => {
            it.CustomizeHeader = true;
            return it;
          });
        }

        this.initContainerType();
        this.initAddresses();
        this.initArrivalTime();
        this.initExecutor();
        this.initCarryContainerArea();
        this.initPlanCarryContainerTime();
        this.initCarryContainerTime();
        this.initIntoPortWharf();
        this.initBackContainerTime();
        this.initContainerNo();
        this.initSealNo();
        this.initFile();
      });
    });
    OrderPoolContainerTrailerAppService.GetHistories(dto).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  /**箱型-选择器数据 */
  containerTypes: UiSelectOption<string>[] = [];
  /**箱型-模态框 */
  modalContainerType = new MiezzModal(MiezzModalType.Dialog);
  /**箱型-表单 */
  formContainerType = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_ContainerType,
    string
  >();
  /**箱型-初始化 */
  initContainerType(): void {
    // 获取箱型选择器
    if (this.canEdit) {
      this.modalContainerType.Title = "编辑箱型";
      ParamContainerTypeAppService.GetSelect().then((r) => {
        this.containerTypes = r.data.Data ?? [];
      });
      this.modalContainerType.Buttons = [
        {
          Code: "save-container-type",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalContainerType.Title = "查看箱型";
    }

    // 模态框-箱型
    this.modalContainerType.Height = "100px";
    this.modalContainerType.HandleClick = this.handleTopButtonClick;
    this.formContainerType.Items =
      DtoPutOrderPoolContainerTrailer_ContainerTypeMiezzFormItems;
    this.formContainerType.Rules =
      DtoPutOrderPoolContainerTrailer_ContainerTypeRules;
  }

  /**提货地址与时间-抽屉 */
  modalAddresses = new MiezzModal(MiezzModalType.Drawer);
  /**提货地址与时间-表单 */
  formAddresses = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_Addresses,
    string
  >();
  /**提货地址与时间-添加按钮 */
  btnAddAddress: MiezzButton = {
    Code: "add-address",
    Label: "添加",
    Icon: "plus",
    Type: "primary",
    Size: "small",
  };
  /**提货地址与时间-删除按钮 */
  btnDeleteAddress: MiezzButton = {
    Code: "delete-address",
    Title: "删除",
    Icon: "delete",
    Type: "text",
    Size: "small",
  };
  /**提货地址与时间-初始化 */
  initAddresses(): void {
    const label =
      this.serviceType == EnumOrderServiceType.DeparturePort
        ? "提货地址与时间"
        : "送货地址与时间";
    if (this.canEdit) {
      this.modalAddresses.Title = "编辑" + label;
      this.modalAddresses.Buttons = [
        {
          Code: "save-addresses",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalAddresses.Title = "查看" + label;
    }
    this.modalAddresses.HandleClick = this.handleTopButtonClick;
    this.formAddresses.Items =
      DtoPutOrderPoolContainerTrailer_AddressesMiezzFormItems;
    this.formAddresses.Rules =
      DtoPutOrderPoolContainerTrailer_Addresses_AddresseRules;
    this.formAddresses.Rules.Address = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
  }
  /**提货地址与时间-获取公司名称 */
  getConpanyNames(q: string, cb: any): void {
    CompanyAddressAppService.GetCompanyNames(q).then((r) => {
      cb(r.data.Data ?? []);
    });
  }
  /**提货地址与时间-选择公司名称 */
  handleSelectCompanyName(
    select: DtoHistoryCompanyAddress_CompanyName,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress
  ): void {
    if (item.CompanyAddress) {
      if (select.AreaLabel) {
        item.CompanyAddress.AreaLabel = select.AreaLabel;
      } else if (select.AreaCode) {
        CompanyAddressAppService.GetAreaByCode(
          select.AreaCode,
          select.AreaLongitude,
          select.AreaLatitude
        ).then((r) => {
          if (item.CompanyAddress) {
            item.CompanyAddress.AreaLabel = r.data.Data?.FullName;
            if (!item.CompanyAddress.Address && r.data.Data?.DetailedAddress)
              item.CompanyAddress.Address = r.data.Data?.DetailedAddress;
          }
        });
      }
      item.CompanyAddress.Address = select.Address;
      item.CompanyAddress.Contact = select.Contact;
      item.CompanyAddress.ContactNumber = select.ContactNumber;
    }
  }
  /**提货地址与时间-获取坐标 */
  getAreas(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }
  /**提货地址与时间-选择坐标 */
  handleSelectArea(
    select: DtoSelectOptionParamCountryArea,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress
  ): void {
    if (item.CompanyAddress) {
      item.CompanyAddress.Address = select?.DetailedAddress;
    }
  }
  /**提货地址与时间-获取详细地址 */
  getAddresses(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetAMapPlaceTextPoiByKeywords({
        Keywords: q,
        Offset: 5,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }
  /**提货地址与时间-选择详细地址 */
  handleSelectAddresses(
    select: DtoCallbackAMapPlaceTextPoi,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress
  ): void {
    if (select.adcode) {
      CompanyAddressAppService.GetAreaByCode(
        select.adcode,
        select.Longitude,
        select.Latitude
      ).then((r) => {
        if (item.CompanyAddress) {
          item.CompanyAddress.AreaLabel = r.data.Data?.FullName;
        }
      });
    }
  }
  /**提货地址与时间-获取联系人 */
  getContacts(
    q: string,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress,
    cb: any
  ): void {
    CompanyAddressAppService.GetContacts({
      CompanyName: item.CompanyAddress?.CompanyName,
    }).then((r) => {
      const contacts = r.data.Data ?? [];
      item.Contacts = contacts;
      cb(
        contacts.filter((it) =>
          it.Contact?.toUpperCase()?.contains(q.toUpperCase())
        )
      );
    });
  }
  /**提货地址与时间-选择联系人 */
  handleSelectContact(
    select: DtoHistoryCompanyAddress_Contact,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress
  ): void {
    if (item.CompanyAddress) {
      item.CompanyAddress.ContactNumber = select.ContactNumber;
    }
  }

  handleDeleteContact(
    select: DtoHistoryCompanyAddress_Contact,
    value: string,
    item: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress
  ): void {
    CompanyAddressAppService.DeleteContactById(select.Id).then(() => {
      if (item.CompanyAddress) {
        const old = (
          item.Contacts as DtoHistoryCompanyAddress_Contact[]
        ).firstOrDefault((it) => it.Contact == value);
        if (old?.Contact == select.Contact) {
          item.CompanyAddress.Contact = undefined;
          item.CompanyAddress.ContactNumber = undefined;
        } else {
          item.CompanyAddress.Contact = old?.Contact;
          item.CompanyAddress.ContactNumber = old?.ContactNumber;
        }
      }
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  /**提货地址与时间-删除 */
  handleDeleteAddress(i: number, complete: () => void): void {
    this.formAddresses.Data.Addresses?.splice(i, 1);
    complete();
  }

  /**抵达时间-模态框 */
  modalArrivalTime = new MiezzModal(MiezzModalType.Dialog);
  /**抵达时间-表单 */
  formArrivalTime = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_ArrivalTime,
    string
  >();
  /**抵达时间-初始化 */
  initArrivalTime(): void {
    this.modalArrivalTime.Height = "100px";
    if (this.canEdit) {
      this.modalArrivalTime.Title = "编辑抵达时间";
      this.modalArrivalTime.Buttons = [
        {
          Code: "save-arrival-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalArrivalTime.Title = "查看抵达时间";
    }
    this.modalArrivalTime.HandleClick = this.handleTopButtonClick;
    this.formArrivalTime.Items =
      DtoPutOrderPoolContainerTrailer_ArrivalTimeMiezzFormItems;
    this.formArrivalTime.Rules =
      DtoPutOrderPoolContainerTrailer_ArrivalTimeRules;
  }
  handleChangeArrivalTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formArrivalTime.Data.ArrivalTime &&
      item.CarryContainerTime &&
      moment(this.formArrivalTime.Data.ArrivalTime) <=
      moment(item.CarryContainerTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formArrivalTime.Data.ArrivalTime = undefined;
    }
    if (
      item &&
      this.formArrivalTime.Data.ArrivalTime &&
      item.BackContainerTime &&
      moment(this.formArrivalTime.Data.ArrivalTime) >=
      moment(item.BackContainerTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formArrivalTime.Data.ArrivalTime = undefined;
    }
  }

  showSupplier = false;
  handleSaveSupplier(data: any, complete: (s: boolean) => void): void {
    OrderPoolContainerTrailerAppService.PutSupplier(data)
      .then(() => {
        this.handleGet();
        complete(true);
      })
      .catch(() => complete(false));
  }

  /**执行人-模态框 */
  modalExecutor = new MiezzModal(MiezzModalType.Dialog);
  /**执行人-表单 */
  formExecutor = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_Executor,
    string
  >();
  /**执行人-初始化 */
  initExecutor(): void {
    this.modalExecutor.Height = "200px";
    if (this.editPower) {
      this.modalExecutor.Title = "编辑执行人";
      this.modalExecutor.Buttons = [
        {
          Code: "save-executor",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalExecutor.Title = "查看执行人";
    }
    this.modalExecutor.HandleClick = this.handleTopButtonClick;
    this.formExecutor.Items =
      DtoPutOrderPoolContainerTrailer_ExecutorMiezzFormItems;
    this.formExecutor.Rules = DtoPutOrderPoolContainerTrailer_ExecutorRules;
  }

  /**提柜地点-模态框 */
  modalCarryContainerArea = new MiezzModal(MiezzModalType.Dialog);
  /**提柜地点-表单 */
  formCarryContainerArea = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_CarryContainerArea,
    string
  >();
  /**提柜地点-初始化 */
  initCarryContainerArea(): void {
    const label =
      this.serviceType == EnumOrderServiceType.DeparturePort
        ? "提柜地点"
        : "提柜码头";
    this.modalCarryContainerArea.Height = "100px";
    if (this.editPower) {
      this.modalCarryContainerArea.Title = "编辑" + label;
      this.modalCarryContainerArea.Buttons = [
        {
          Code: "save-carry-container-area",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalCarryContainerArea.Title = "查看" + label;
    }
    this.modalCarryContainerArea.HandleClick = this.handleTopButtonClick;
    const items =
      DtoPutOrderPoolContainerTrailer_CarryContainerAreaMiezzFormItems;
    for (const item of items) {
      switch (item.Prop) {
        case "CarryContainerAreaLabel":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "提柜地点"
              : "提柜码头";
          break;

        default:
          break;
      }
    }
    this.formCarryContainerArea.Items = items;
    this.formCarryContainerArea.Rules =
      DtoPutOrderPoolContainerTrailer_CarryContainerAreaRules;
  }

  /**计划提柜时间-模态框 */
  modalPlanCarryContainerTime = new MiezzModal(MiezzModalType.Dialog);
  /**计划提柜时间-表单 */
  formPlanCarryContainerTime = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime,
    string
  >();
  /**计划提柜时间-初始化 */
  initPlanCarryContainerTime(): void {
    this.modalPlanCarryContainerTime.Height = "100px";
    if (this.editPower) {
      this.modalPlanCarryContainerTime.Title = "编辑计划提柜时间";
      this.modalPlanCarryContainerTime.Buttons = [
        {
          Code: "save-plan-carry-container-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalPlanCarryContainerTime.Title = "查看计划提柜时间";
    }
    this.modalPlanCarryContainerTime.HandleClick = this.handleTopButtonClick;
    this.formPlanCarryContainerTime.Items =
      DtoPutOrderPoolContainerTrailer_PlanCarryContainerTimeMiezzFormItems;
    this.formPlanCarryContainerTime.Rules =
      DtoPutOrderPoolContainerTrailer_PlanCarryContainerTimeRules;
  }

  /**提柜时间-模态框 */
  modalCarryContainerTime = new MiezzModal(MiezzModalType.Dialog);
  /**提柜时间-表单 */
  formCarryContainerTime = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_CarryContainerTime,
    string
  >();
  /**提柜时间-初始化 */
  initCarryContainerTime(): void {
    this.modalCarryContainerTime.Height = "100px";
    if (this.editPower) {
      this.modalCarryContainerTime.Title = "编辑提柜时间";
      this.modalCarryContainerTime.Buttons = [
        {
          Code: "save-carry-container-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalCarryContainerTime.Title = "查看提柜时间";
    }
    this.modalCarryContainerTime.HandleClick = this.handleTopButtonClick;
    this.formCarryContainerTime.Items =
      DtoPutOrderPoolContainerTrailer_CarryContainerTimeMiezzFormItems;
    this.formCarryContainerTime.Rules =
      DtoPutOrderPoolContainerTrailer_CarryContainerTimeRules;
  }
  handleChangeCarryContainerTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formCarryContainerTime.Data.CarryContainerTime &&
      item.ArrivalTime &&
      moment(this.formCarryContainerTime.Data.CarryContainerTime) >=
      moment(item.ArrivalTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formCarryContainerTime.Data.CarryContainerTime = undefined;
    }
    if (
      item &&
      this.formCarryContainerTime.Data.CarryContainerTime &&
      item.BackContainerTime &&
      moment(this.formCarryContainerTime.Data.CarryContainerTime) >=
      moment(item.BackContainerTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formCarryContainerTime.Data.CarryContainerTime = undefined;
    }
  }

  /**进港码头-模态框 */
  modalIntoPortWharf = new MiezzModal(MiezzModalType.Dialog);
  /**进港码头-表单 */
  formIntoPortWharf = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_IntoPortWharf,
    string
  >();
  /**进港码头-初始化 */
  initIntoPortWharf(): void {
    const label =
      this.serviceType == EnumOrderServiceType.DeparturePort
        ? "进港码头"
        : "还柜地点";
    this.modalIntoPortWharf.Height = "100px";
    if (this.canEdit) {
      this.modalIntoPortWharf.Title = "编辑" + label;
      this.modalIntoPortWharf.Buttons = [
        {
          Code: "save-into-port-wharf",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalIntoPortWharf.Title = "查看" + label;
    }
    this.modalIntoPortWharf.HandleClick = this.handleTopButtonClick;
    const items = JSON.copy(
      DtoPutOrderPoolContainerTrailer_IntoPortWharfMiezzFormItems
    );
    for (const item of items) {
      switch (item.Prop) {
        case "IntoPortWharfLabel":
          item.Label =
            this.serviceType == EnumOrderServiceType.DeparturePort
              ? "进港码头"
              : "还柜地点";
          break;

        default:
          break;
      }
    }
    this.formIntoPortWharf.Items = items;
    this.formIntoPortWharf.Rules =
      DtoPutOrderPoolContainerTrailer_IntoPortWharfRules;
  }

  /**还柜时间-模态框 */
  modalBackContainerTime = new MiezzModal(MiezzModalType.Dialog);
  /**还柜时间-表单 */
  formBackContainerTime = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_BackContainerTime,
    string
  >();
  /**还柜时间-初始化 */
  initBackContainerTime(): void {
    this.modalBackContainerTime.Height = "100px";
    if (this.canEdit) {
      this.modalBackContainerTime.Title = "编辑还柜时间";
      this.modalBackContainerTime.Buttons = [
        {
          Code: "save-back-container-time",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalBackContainerTime.Title = "查看还柜时间";
    }
    this.modalBackContainerTime.HandleClick = this.handleTopButtonClick;
    this.formBackContainerTime.Items =
      DtoPutOrderPoolContainerTrailer_BackContainerTimeMiezzFormItems;
    this.formBackContainerTime.Rules =
      DtoPutOrderPoolContainerTrailer_BackContainerTimeRules;
  }
  handleChangeBackContainerTime(): void {
    const item = this.list.Data?.Items?.firstOrDefault(
      (it) => it.Id == this.id
    );
    if (
      item &&
      this.formBackContainerTime.Data.BackContainerTime &&
      item.CarryContainerTime &&
      moment(this.formBackContainerTime.Data.BackContainerTime) <=
      moment(item.CarryContainerTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formBackContainerTime.Data.BackContainerTime = undefined;
    }
    if (
      item &&
      this.formBackContainerTime.Data.BackContainerTime &&
      item.ArrivalTime &&
      moment(this.formBackContainerTime.Data.BackContainerTime) <=
      moment(item.ArrivalTime)
    ) {
      ElMessage.warning(
        "时间应遵循如下规则：【提柜时间】早于【抵达时间】早于【还柜时间】"
      );
      this.formBackContainerTime.Data.BackContainerTime = undefined;
    }
  }

  /**箱号-模态框 */
  modalContainerNo = new MiezzModal(MiezzModalType.Dialog);
  /**箱号-表单 */
  formContainerNo = new MiezzForm<
    DtoPutOrderPoolContainerTrailer_ContainerNo,
    string
  >();
  /**箱号-初始化 */
  initContainerNo(): void {
    this.modalContainerNo.Height = "100px";
    if (this.canEdit) {
      this.modalContainerNo.Title = "编辑箱号";
      this.modalContainerNo.Buttons = [
        {
          Code: "save-container-no",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalContainerNo.Title = "查看箱号";
    }
    this.modalContainerNo.HandleClick = this.handleTopButtonClick;
    this.formContainerNo.Items =
      DtoPutOrderPoolContainerTrailer_ContainerNoMiezzFormItems;
    this.formContainerNo.Rules = {
      ContainerNo: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (!value) {
              return callback(new Error("信息不能为空"));
            } else if (value.length > 256) {
              return callback(new Error("文本长度不能超过 256 个字符"));
            } else {
              const reg = /^[A-Z]{4}[0-9]{7}$/;
              if (reg.test(value)) {
                // 箱号正则表达式
                const dic: any = {};
                dic["A"] = 10;
                dic["B"] = 12;
                dic["C"] = 13;
                dic["D"] = 14;
                dic["E"] = 15;
                dic["F"] = 16;
                dic["G"] = 17;
                dic["H"] = 18;
                dic["I"] = 19;
                dic["J"] = 20;
                dic["K"] = 21;
                dic["L"] = 23;
                dic["M"] = 24;
                dic["N"] = 25;
                dic["O"] = 26;
                dic["P"] = 27;
                dic["Q"] = 28;
                dic["R"] = 29;
                dic["S"] = 30;
                dic["T"] = 31;
                dic["U"] = 32;
                dic["V"] = 34;
                dic["W"] = 35;
                dic["X"] = 36;
                dic["Y"] = 37;
                dic["Z"] = 38;
                dic["0"] = 0;
                dic["1"] = 1;
                dic["2"] = 2;
                dic["3"] = 3;
                dic["4"] = 4;
                dic["5"] = 5;
                dic["6"] = 6;
                dic["7"] = 7;
                dic["8"] = 8;
                dic["9"] = 9;
                var number = value;
                var sum = 0;
                for (var i = 0; i < 10; i++) {
                  var char = number[i];
                  var code = dic[char];
                  sum += code * Math.pow(2, i);
                }
                var result = sum % 11;
                var end = parseInt(number[10]);
                var valid = result == end || result - end == 10;
                if (!valid) {
                  return callback(new Error("请填写正确的箱号信息"));
                } else {
                  OrderPoolContainerTrailerAppService.CheckExistContainerNo({
                    OrderId: this.orderId,
                    PoolId: this.poolId,
                    Id: this.formContainerNo.Data.Id,
                    ContainerNo: this.formContainerNo.Data.ContainerNo,
                    ServiceType: this.serviceType,
                  }).then((r) => {
                    if (r.data.Data) {
                      return callback(new Error("信息重复，请重新填写"));
                    } else {
                      return callback();
                    }
                  });
                }
              } else {
                return callback(new Error("请填写正确的箱号信息"));
              }
            }
          },
          trigger: "blur",
        },
      ],
    };
  }

  /**封号-模态框 */
  modalSealNo = new MiezzModal(MiezzModalType.Dialog);
  /**封号-表单 */
  formSealNo = new MiezzForm<DtoPutOrderPoolContainerTrailer_SealNo, string>();
  /**封号-初始化 */
  initSealNo(): void {
    this.modalSealNo.Height = "100px";
    if (this.canEdit) {
      this.modalSealNo.Title = "编辑封号";
      this.modalSealNo.Buttons = [
        {
          Code: "save-seal-no",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalSealNo.Title = "查看封号";
    }
    this.modalSealNo.HandleClick = this.handleTopButtonClick;
    this.formSealNo.Items =
      DtoPutOrderPoolContainerTrailer_SealNoMiezzFormItems;
    this.formSealNo.Rules = DtoPutOrderPoolContainerTrailer_SealNoRules;
  }

  /**附件-模态框 */
  modalFile = new MiezzModal(MiezzModalType.Dialog);
  /**附件-表单 */
  formFile = new MiezzForm<DtoPutOrderPoolContainerTrailer_File, string>();
  /**附件-初始化 */
  initFile(): void {
    if (this.canEdit) {
      this.modalFile.Title = "编辑附件";
      this.modalFile.Buttons = [
        {
          Code: "save-file",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalFile.Title = "查看附件";
    }
    this.modalFile.HandleClick = this.handleTopButtonClick;
    this.formFile.Items = DtoPutOrderPoolContainerTrailer_FileMiezzFormItems;
    this.formFile.Rules = DtoPutOrderPoolContainerTrailer_FileRules;
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    if (this.list.Data?.Lock) {
      ElMessage.warning("订单已锁定");
      complete();
      return;
    }
    if (this.list.Data?.Status == EnumOrderPoolStatus.Canceled) {
      ElMessage.warning("委托书已取消，无法修改");
      complete();
      return;
    }
    if (this.list.Data?.OrderStatus == EnumOrderStatus.Canceled) {
      ElMessage.warning("订单已取消，无法修改");
      complete();
      return;
    }
    switch (btn.Code) {
      case "add":
        OrderPoolContainerTrailerAppService.PostByPoolIdByServiceType(
          this.list.Data?.PoolId,
          this.serviceType
        )
          .then(() => {
            this.handleGet();
            ElMessage.success("添加成功");
            complete();
          })
          .catch(complete);
        break;
      case "save-container-type":
        MiezzForm.submit(this.$refs, "ref-form-container-type", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutContainerType(
              this.formContainerType.Data
            )
              .then(() => {
                this.handleGet();
                this.modalContainerType.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "add-address":
        this.formAddresses.Data.Addresses?.push({
          CompanyAddress: {
            AreaLabel: this.formAddresses.Data.DeliveryPlaceLabel,
            Address: this.formAddresses.Data.DeliveryPlaceAddress,
          },
        });
        complete();
        break;
      case "save-addresses":
        MiezzForm.submit(this.$refs, "ref-form-addresses", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutAddresses(
              this.formAddresses.Data
            )
              .then(() => {
                this.handleGet();
                this.modalAddresses.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-arrival-time":
        MiezzForm.submit(this.$refs, "ref-form-arrival-time", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutArrivalTime(
              this.formArrivalTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalArrivalTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-executor":
        MiezzForm.submit(this.$refs, "ref-form-executor", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutExecutor(
              this.formExecutor.Data
            )
              .then(() => {
                this.handleGet();
                this.modalExecutor.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-carry-container-area":
        MiezzForm.submit(this.$refs, "ref-form-carry-container-area", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutCarryContainerArea(
              this.formCarryContainerArea.Data
            )
              .then(() => {
                this.handleGet();
                this.modalCarryContainerArea.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-plan-carry-container-time":
        MiezzForm.submit(
          this.$refs,
          "ref-form-plan-carry-container-time",
          (v) => {
            if (v) {
              OrderPoolContainerTrailerAppService.PutPlanCarryContainerTime(
                this.formPlanCarryContainerTime.Data
              )
                .then(() => {
                  this.handleGet();
                  this.modalPlanCarryContainerTime.Show = false;
                  ElMessage.success("编辑成功");
                  complete();
                })
                .catch(complete);
            } else {
              complete();
            }
          }
        );
        break;
      case "save-carry-container-time":
        MiezzForm.submit(this.$refs, "ref-form-carry-container-time", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutCarryContainerTime(
              this.formCarryContainerTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalCarryContainerTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-into-port-wharf":
        MiezzForm.submit(this.$refs, "ref-form-into-port-wharf", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutIntoPortWharf(
              this.formIntoPortWharf.Data
            )
              .then(() => {
                this.handleGet();
                this.modalIntoPortWharf.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-back-container-time":
        MiezzForm.submit(this.$refs, "ref-form-back-container-time", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutBackContainerTime(
              this.formBackContainerTime.Data
            )
              .then(() => {
                this.handleGet();
                this.modalBackContainerTime.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-container-no":
        MiezzForm.submit(this.$refs, "ref-form-container-no", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutContainerNo(
              this.formContainerNo.Data
            )
              .then(() => {
                if (this.list.Data?.OrderId) {
                  OrderMainBillAppService.AutoPullThreeApiData({
                    OrderId: this.list.Data.OrderId,
                    UpdateData: true,
                  }).then((r) => {
                    if ((r.data.Data?.Errors?.length ?? 0) > 0) {
                      for (const error of r.data.Data?.Errors ?? []) {
                        ElMessage.warning(error);
                      }
                    } else {
                      const model =
                        r.data.Data?.ContainerTrailers?.firstOrDefault(
                          (it) => it.Id == this.formContainerNo.Data.Id
                        );
                      if (
                        model &&
                        (model.BindThreeApiPropList?.length ?? 0) > 0
                      ) {
                        ElMessage.success(
                          "已通过第三方为您获取到相应信息，此类信息（标题为绿色）的变化将由接口推送并禁止修改"
                        );
                      } else {
                        ElMessage.success(
                          "已向第三方接口发送请求，数据会有延迟，请稍后再来查看"
                        );
                      }

                      CurrentLogier.HubConnection?.off("RefreshContainerTrailer");
                      CurrentLogier.HubConnection?.on(
                        "RefreshContainerTrailer",
                        (
                          id: string,
                        ) => {
                          if (id == this.formContainerNo.Data.Id) {
                            this.handleGet();
                          }
                        }
                      );
                    }
                  });
                }
                this.handleGet();
                this.modalContainerNo.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-seal-no":
        MiezzForm.submit(this.$refs, "ref-form-seal-no", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutSealNo(this.formSealNo.Data)
              .then(() => {
                this.handleGet();
                this.modalSealNo.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-file":
        MiezzForm.submit(this.$refs, "ref-form-file", (v) => {
          if (v) {
            OrderPoolContainerTrailerAppService.PutFile(this.formFile.Data)
              .then(() => {
                this.handleGet();
                this.modalFile.Show = false;
                ElMessage.success("编辑成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderPoolContainerTrailer,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "delete":
        if ((this.list.Data?.Items?.length ?? 0) == 1) {
          ElMessage.warning("至少需要保留一条信息");
          complete();
          return;
        }
        if (this.list.Data?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.list.Data?.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("委托书已取消，无法修改");
          complete();
          return;
        }
        if (this.list.Data?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        OrderPoolContainerTrailerAppService.Delete([row.Id ?? ""]).then(() => {
          ElMessage.success("删除成功");
          this.handleGet();
          complete();
        });
        break;

      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowCommand(
    prop: string,
    row: DtoListItemOrderPoolContainerTrailer
  ): void {
    this.id = row.Id;
    switch (prop) {
      case "ContainerTypeLabel":
        this.formContainerType.Data =
          new DtoPutOrderPoolContainerTrailer_ContainerType();
        this.formContainerType.Data.Id = this.id;
        this.formContainerType.Data.ContainerTypeId = row.ContainerTypeId;
        this.formContainerType.Data.ContainerTypeLabel = row.ContainerTypeLabel;
        this.modalContainerType.Show = true;
        break;
      case "Addresses":
        var addresses =
          (row.Addresses?.length ?? 0) > 0
            ? (JSON.copy(
              row.Addresses
            ) as DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress[])
            : [{ CompanyAddress: {} }];
        this.formAddresses.Data =
          new DtoPutOrderPoolContainerTrailer_Addresses();
        this.formAddresses.Data.Id = this.id;
        if (this.serviceType == EnumOrderServiceType.DestinationPort) {
          const supplier = this.list.Data?.WharfSuppliers?.firstOrDefault(
            (it: any) => it.Id == (row?.PoolId ?? this.orderId)
          );
          if (supplier) {
            this.formAddresses.Data.DeliveryPlaceLabel =
              this.formAddresses.Data.DeliveryPlaceLabel ??
              (supplier.NameZh ?? "") +
              (supplier.NameZh && supplier.NameEn ? "/" : "") +
              (supplier.NameEn ?? "");
            this.formAddresses.Data.DeliveryPlaceAddress =
              this.formAddresses.Data.DeliveryPlaceAddress ??
              (supplier.ReceivingAddressZh ?? "") +
              (supplier.ReceivingAddressZh && supplier.ReceivingAddressEn
                ? "/"
                : "") +
              (supplier.ReceivingAddressEn ?? "");
          } else {
            this.formAddresses.Data.DeliveryPlaceAddress =
              "暂未获知，待后续补充";
          }
          for (const address of addresses) {
            if (!address.CompanyAddress) {
              address.CompanyAddress = {};
            }
            if (!address.CompanyAddress.Address) {
              address.CompanyAddress.CompanyName =
                this.formAddresses.Data.DeliveryPlaceLabel;
              address.CompanyAddress.Address =
                this.formAddresses.Data.DeliveryPlaceAddress;
            }
          }
          console.log(this.formAddresses.Data.DeliveryPlaceAddress);
        }
        this.formAddresses.Data.Addresses = addresses;
        this.modalAddresses.Show = true;
        break;
      case "ArrivalTime":
        this.formArrivalTime.Data =
          new DtoPutOrderPoolContainerTrailer_ArrivalTime();
        this.formArrivalTime.Data.Id = this.id;
        this.formArrivalTime.Data.ArrivalTime = row.ArrivalTime;
        this.modalArrivalTime.Show = true;
        break;
      case "SupplierLabel":
        (this.$refs["ref-order-supplier"] as OrderSupplier).handleShow(row);
        break;
      case "ExecutorLabel":
        this.formExecutor.Data = new DtoPutOrderPoolContainerTrailer_Executor();
        this.formExecutor.Data.Id = this.id;
        this.formExecutor.Data.Executor = row.Executor;
        this.formExecutor.Data.ExecutorContactNumber =
          row.ExecutorContactNumber;
        this.formExecutor.Data.ExecutorLicensePlate = row.ExecutorLicensePlate;
        this.modalExecutor.Show = true;
        break;
      case "CarryContainerAreaLabel":
        this.formCarryContainerArea.Data =
          new DtoPutOrderPoolContainerTrailer_CarryContainerArea();
        this.formCarryContainerArea.Data.Id = this.id;
        this.formCarryContainerArea.Data.CarryContainerAreaId =
          row.CarryContainerAreaId;
        this.formCarryContainerArea.Data.CarryContainerAreaLabel =
          row.CarryContainerAreaLabel;
        this.modalCarryContainerArea.Show = true;
        break;
      case "PlanCarryContainerTime":
        this.formPlanCarryContainerTime.Data =
          new DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime();
        this.formPlanCarryContainerTime.Data.Id = this.id;
        this.formPlanCarryContainerTime.Data.PlanCarryContainerTime =
          row.PlanCarryContainerTime;
        this.modalPlanCarryContainerTime.Show = true;
        break;
      case "CarryContainerTime":
        this.formCarryContainerTime.Data =
          new DtoPutOrderPoolContainerTrailer_CarryContainerTime();
        this.formCarryContainerTime.Data.Id = this.id;
        this.formCarryContainerTime.Data.CarryContainerTime =
          row.CarryContainerTime;
        if (
          this.serviceType == EnumOrderServiceType.DestinationPort &&
          !this.formCarryContainerTime.Data.CarryContainerTime
        ) {
          OrderAppService.GetCarryContainerTime(this.orderId, this.poolId).then(
            (r) => {
              this.formCarryContainerTime.Data.CarryContainerTime = r.data.Data;
            }
          );
        }
        this.modalCarryContainerTime.Show = true;
        break;
      case "IntoPortWharfLabel":
        this.formIntoPortWharf.Data =
          new DtoPutOrderPoolContainerTrailer_IntoPortWharf();
        this.formIntoPortWharf.Data.Id = this.id;
        this.formIntoPortWharf.Data.IntoPortWharfId = row.IntoPortWharfId;
        this.formIntoPortWharf.Data.IntoPortWharfLabel = row.IntoPortWharfLabel;
        this.modalIntoPortWharf.Show = true;
        break;
      case "BackContainerTime":
        this.formBackContainerTime.Data =
          new DtoPutOrderPoolContainerTrailer_BackContainerTime();
        this.formBackContainerTime.Data.Id = this.id;
        this.formBackContainerTime.Data.BackContainerTime =
          row.BackContainerTime;
        this.modalBackContainerTime.Show = true;
        break;
      case "ContainerNo":
        this.formContainerNo.Data =
          new DtoPutOrderPoolContainerTrailer_ContainerNo();
        this.formContainerNo.Data.Id = this.id;
        this.formContainerNo.Data.ContainerNo = row.ContainerNo;
        this.formContainerNo.Data.Tare = row.Tare;
        this.formContainerNo.Data.TareDisabled =
          row.BindThreeApiPropList?.contains("Tare");
        this.modalContainerNo.Show = true;
        break;
      case "SealNo":
        this.formSealNo.Data = new DtoPutOrderPoolContainerTrailer_SealNo();
        this.formSealNo.Data.Id = this.id;
        this.formSealNo.Data.SealNo = row.SealNo;
        this.modalSealNo.Show = true;
        break;
      case "Files":
        this.formFile.Data = new DtoPutOrderPoolContainerTrailer_File();
        this.formFile.Data.Id = this.id;
        this.formFile.Data.ContainerNoFile = row.ContainerNoFile;
        this.formFile.Data.SealNoFile = row.SealNoFile;
        this.formFile.Data.Files = JSON.copy(row.Files ?? []);
        this.modalFile.Show = true;
        break;
      case "Process":
        ElMessage.warning("暂无数据，请向供应商询问详细进程");
        break;
      default:
        break;
    }
  }
}
