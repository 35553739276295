import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumProjectVersionType, EnumProjectVersionTypeOption } from "./EnumProjectVersionType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormProjectSiteExecCommand extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Ids = [];
        this.Checks = [];
        this.SyncTables = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 站点Id
     */
    Ids?: string[];
    /**
     * 选项
     */
    Checks?: string[];
    /**
     * 迁移版本名称
     */
    Version?: string;
    /**
     * 源数据站点Id
     */
    SourceId?: string;
    /**
     * 同步方案
     */
    SyncSchemeName?: string;
    /**
     * 需要同步的表
     */
    SyncTables?: string[];
    /**
     * 发布类型
     */
    PublishType?: EnumProjectVersionType;
    /**
     * 发布版本号
     */
    PublishNumber?: string;
    /**
     * 主版本号
     */
    PublishMainNumber?: number;
    /**
     * 次版本号
     */
    PublishMinorNumber?: number;
    /**
     * 修订号
     */
    PublishRevisionNumber?: number;
    /**
     * 发布内容
     */
    PublishContent?: string;
}

/**
 * -表单校验
 */
export const DtoFormProjectSiteExecCommandRules = {
    Checks: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
            type: "array",
        },
    ],
    Version: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z]*$/.test(value)) {
                    return callback(new Error("请输入字母或数字"));
                } else {
                    callback();
                }
            }
        },
    ],
    SyncSchemeName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    PublishContent: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormProjectSiteExecCommandMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Checks",
    Label: "选项",
    Customize: true,
  },
  {
    Prop: "Version",
    Label: "迁移版本名称",
    Hide: true,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SourceId",
    Label: "源数据站点Id",
    Hide: true,
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "SyncSchemeName",
    Label: "同步方案",
    Hide: true,
    Type: EnumMiezzFormItemType.Select,
    AllowCreate: true,
  },
  {
    Prop: "SyncTables",
    Label: "需要同步的表",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "PublishType",
    Label: "发布类型",
    Hide: true,
    Type: EnumMiezzFormItemType.Select,
    Options: EnumProjectVersionTypeOption,
  },
  {
    Prop: "PublishNumber",
    Label: "发布版本号",
    Hide: true,
    Disabled: true,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PublishContent",
    Label: "发布内容",
    Hide: true,
    Type: EnumMiezzFormItemType.AreaText,
  },
]
