import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import { EnumOrderStowageStackStatus, EnumOrderStowageStackStatusOption } from "./EnumOrderStowageStackStatus";

/**
 * 配载栈分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderStowageStack extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.StowageStackStatus = EnumOrderStowageStackStatus.Not;
        this.TabCount = 0;
    }

    [proName: string]: any;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 订单编号
     */
    OrderCode?: string;
    /**
     * 服务商
     */
    ServiceProviderLabel?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 委托公司中文名称
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * 委托人
     */
    Client?: string;
    /**
     * 计划货量
     */
    PlanCargoQuantities?: string;
    /**
     * 入库货量
     */
    WarehousingCargoQuantities?: string;
    /**
     * 出库货量
     */
    ExWarehouseCargoQuantities?: string;
    /**
     * 入库地点
     */
    WarehousingAddress?: string;
    /**
     * 入库地点集合
     */
    WarehousingAddressList?: string[];
    /**
     * 预计货好时间
     */
    EstimatedGoodsReadyDate?: Date;
    /**
     * Port of  Loading/装货港Id
     */
    LoadingPortId?: string;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
    /**
     * 状态
     */
    StowageStackStatus?: EnumOrderStowageStackStatus;
    /**
     * 预装箱主键
     */
    OrderPrePackedBoxId?: string;
    /**
     * 方案数
     */
    TabCount?: number;
    /**
     * 所属方案名称
     */
    TabName?: string;
}

/**
 * 配载栈分页列表-项-表单校验
 */
export const DtoListItemOrderStowageStackRules = {
}

/**
 * 配载栈分页列表-项-设置项
 */
export const DtoListItemOrderStowageStackMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    Customize: true,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "OrderCode",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "Client",
    Label: "委托人",
  },
  {
    Prop: "PlanCargoQuantities",
    Label: "计划货量",
    Customize: true,
  },
  {
    Prop: "WarehousingCargoQuantities",
    Label: "入库货量",
    Customize: true,
  },
  {
    Prop: "WarehousingAddress",
    Label: "入库地点",
    Customize: true,
  },
  {
    Prop: "EstimatedGoodsReadyDate",
    Label: "预计货好时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
  },
  {
    Prop: "DeparturePortDrawback",
    Label: "是否退税",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "StowageStackStatus",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderStowageStackStatusOption,
  },
]
