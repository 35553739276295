import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamContainerType from "../models.machine/DtoSelectSearchParamContainerType";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamContainerType from "../models.machine/DtoPageListSearchParamContainerType";
import DtoPageListParamContainerType from "../models.machine/DtoPageListParamContainerType";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamContainerType from "../models.machine/DtoDetailParamContainerType";
import DtoFormParamContainerType from "../models.machine/DtoFormParamContainerType";
import DtoListItemParamContainerType_Repeat from "../models.machine/DtoListItemParamContainerType_Repeat";

/**
 * 箱型
 * @description 自动生成的代码,请勿修改
 */
export default class ParamContainerTypeAppService {
    /**
     * 获取港口选择器
     * @param {DtoSelectSearchParamContainerType} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamContainerType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamContainerType/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取箱型分页列表
     * @param {DtoPageListSearchParamContainerType} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamContainerType>>} 
     */
    static Get(dto?: DtoPageListSearchParamContainerType): AxiosPromise<RESTfulResult<DtoPageListParamContainerType>> {
        const url = `/api/ParamContainerType/Get`;
        return service.get<RESTfulResult<DtoPageListParamContainerType>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamContainerType/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取箱型详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamContainerType>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamContainerType>> {
        const url = `/api/ParamContainerType/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamContainerType>>(url);
    }

    /**
     * 新增/编辑箱型
     * @param {DtoFormParamContainerType} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamContainerType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamContainerType/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除箱型
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamContainerType/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamContainerType/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamContainerType/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamContainerType/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamContainerType_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamContainerType_Repeat[]>> {
        const url = `/api/ParamContainerType/GetList`;
        return service.get<RESTfulResult<DtoListItemParamContainerType_Repeat[]>>(url);
    }

}
