
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ContactTransportModeAppService from "@/apis.machine/ContactTransportModeAppService";
import DtoPageListSearchContactTransportMode, { DtoPageListSearchContactTransportModeDynamicSearchItems } from "@/models.machine/DtoPageListSearchContactTransportMode";
import DtoPageListContactTransportMode from "@/models.machine/DtoPageListContactTransportMode";
import DtoListItemContactTransportMode, {
    DtoListItemContactTransportModeMiezzListItems,
} from "@/models.machine/DtoListItemContactTransportMode";
import ContactTransportModeDetail from "./ContactTransportModeDetail.vue"; import ContactTransportModeForm from "./ContactTransportModeForm.vue";
import MiezzFileUpload from "@/components/MiezzFileUpload.vue";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import router from "@/router";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzAlertLog from "@/models/MiezzAlertLog";
import Logier from "@/models.machine/Logier";
@Options({
    components: {
        ContactTransportModeDetail,
        ContactTransportModeForm,
    },
})
export default class ContactTransportModePageList extends Vue {
    /**分页列表 */
    list = new MiezzPageList<DtoPageListSearchContactTransportMode, DtoListItemContactTransportMode, string>("System_ParamList_ContactTransportMode");
    /**当前Id */
    id?: string;
    /**详细模态框 */
    modalDetail = new MiezzModal();
    /**表单模态框 */
    modalForm = new MiezzModal();
    
    modalImportLog = new MiezzModal();
    importLogs: MiezzAlertLog[] = [];
    importRate = 0;
    logier?: Logier;

    /**创建时 */
    created(): void {
        this.logier = CurrentLogier.GetLogier();
        this.list.Back = true;
        this.list.Title = "国际贸易运输方式";
        //列表-操作区域宽度
        //this.list.OperationWidth = "100px";
        //列表-传参
        this.list.Dto = new DtoPageListSearchContactTransportMode();
        //列表-动态搜索-项
        this.list.DynamicSearchItems = DtoPageListSearchContactTransportModeDynamicSearchItems;
        //列表-数据-初始
        this.list.Data = new DtoPageListContactTransportMode();
        //列表-列配置
        this.list.Items = DtoListItemContactTransportModeMiezzListItems;
        
    
        //if (this.logier && this.logier.CompanyType == 10) {
        //    this.list.EnabledSelect = true;
        //} else {
        //    this.list.EnabledSelect = false;
        //}
        //列表-顶部按钮
        this.list.TopButtons = [
            {
                Code: "exportImportTemplate",
                Label: "下载模板",
                Type: "primary",
                Size: "small",
                PowerCode: "System_ParamList_TypeOfShipping_Import",
                Width: "65px"
            },
            {
                Code: "import",
                Label: "导入",
                Type: "primary",
                Size: "small",
                FileUpload: true,
                Accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                PowerCode: "System_ParamList_TypeOfShipping_Import",
                Width: "65px"
            },
            {
                Code: "export",
                Label: "导出",
                Type: "primary",
                Size: "small",
                PowerCode: "System_ParamList_TypeOfShipping_Export",
                Width: "65px"
            },
            {
                Code: "add",
                Label: "添加",
                Type: "primary",
                Size: "small",
                PowerCode: "System_ParamList_TypeOfShipping_AddEdit",
                Width: "65px"
            },
        ];
        //列表-行按钮
        this.list.RowButtons = [
            {
                Code: "edit",
                Title: "编辑",
                Icon: "edit",
                Type: "warning",
                Size: "small",
                PowerCode: "System_ParamList_TypeOfShipping_AddEdit",
            },
            {
                Code: "drag-sort",
                Title: "拖曳",
                Icon: "Rank",
                Type: "default",
                Size: "small",
            },
        ];
        //列表-底部按钮
        this.list.BottomButtons = [
            {
                Code: "batch-delete",
                Label: "删除",
                Icon: "delete",
                Type: "text",
                PowerCode: "System_ParamList_TypeOfShipping_Delete"
            },
            {
                Code: "batch-export",
                Label: "导出",
                Icon: "bottom",
                Type: "text",
                PowerCode: "System_ParamList_TypeOfShipping_Export",
            },
        ];

        this.list.HandleSelectable = (row) => {
            if (row.IsEdit) return row.IsEdit;
            else return false;
        }

        this.list.CheckRowButtonShow = (btn, row) => {
            if (btn.Code == "edit" || btn.Code == "drag-sort") {
                if (row.IsEdit == true) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }

        };
    }

    /**查询列表 */
    handleGet(): void {
        ContactTransportModeAppService.Get(this.list.Dto).then((r) => {
            this.list.Data = r.data.Data;
        });
    }
    
    handleDragSort(
        draggingId?: string,
        dropId?: string,
        type?: EnumUiDragType
    ): void {
        ContactTransportModeAppService.DragSort({
            DraggingId: draggingId,
            DropId: dropId,
            DragType: type,
        }).then(() => {
            this.handleGet();
        });
    }


    /**顶部按钮点击 */
    handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
        switch (btn.Code) {
            //新增
            case "add":
                this.id = undefined;
                this.modalForm.Show = true;
                this.modalForm.Title = `新增国际贸易运输方式`;
                complete();
                break;
            //导出
            case "export":
                ContactTransportModeAppService.PostExportExceByName("国际贸易运输方式", []).then((r) => {
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                    complete();
                });
                break;
            //导入
            case "import":
                complete();
                break;
            //导入导入模板
            case "exportImportTemplate":
                this.handleTemplate();
                complete();
                break;
            default:
                break;
        }
    }

    /**行按钮点击 */
    handleRowButtonClick(
        btn: MiezzButton,
        row: DtoListItemContactTransportMode,
        complete: () => void
    ): void {
        if (row.Id) this.id = row.Id;
        switch (btn.Code) {
            //详细
            case "detail":
                this.modalDetail.Show = true;
                this.modalDetail.Title = `国际贸易运输方式详细`;
                complete();
                break;
            //编辑
            case "edit":
                this.modalForm.Show = true;
                this.modalForm.Title = `编辑国际贸易运输方式`;
                complete();
                break;
            default:
                break;
        }
    }

    /**底部按钮点击 */
    handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
        switch (btn.Code) {
            //批量删除
            case "batch-delete":
                ElMessageBox.confirm('是否确认将该信息删除？', '提醒', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    ContactTransportModeAppService.Delete(this.list.SelectIds)
                        .then(() => {
                            this.handleGet();
                            complete();
                        })
                        .catch(complete);
                })
                .catch(() => {
                    complete();
                });
                break;
            //批量导出
            case "batch-export":
                ContactTransportModeAppService.PostExportExceByName("国际贸易运输方式", this.list.SelectIds).then((r) => {
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                    complete();
                })
                .catch(complete);
                break;
            default:
                break;
        }
    }

    /**提交表单回调 */
    handleSubmit(): void {
        this.modalForm.Show = false;
        this.handleGet();
    }

    /**删除回调 */
    handleDelete(): void {
        this.modalDetail.Show = false;
        this.handleGet();
    }

    handleTemplate(): void {
        ContactTransportModeAppService.GetExportImportTemplate().then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
        });
    }

    @Emit()
    handleTopButtonUpload(model: MiezzButton, file: any): void {
        this.modalImportLog.Show = true;
        this.importLogs = [];
        this.importRate = 0;
        CurrentLogier.HubConnection?.off("AlertLog");
        CurrentLogier.HubConnection?.off("AlertProgress");
        CurrentLogier.HubConnection?.on(
            "AlertLog",
            (
                message: string,
                type: "success" | "warning" | "info" | "error",
                effect: "dark" | "light"
            ) => {
                this.importLogs.unshift({
                    Message: message,
                    Type: type,
                    Effect: effect,
                });
            }
        );
        CurrentLogier.HubConnection?.on(
            "AlertProgress",
            (current: number, total: number) => {
                this.importRate = total == 0 ? 100 : (current / total) * 100;
            }
        );
        const formData = new FormData();
        formData.append("file", file);
        ContactTransportModeAppService.PostImport(formData).then((r) => {
            if (r.data.Data)
                ElMessage.success("导入成功");
            this.handleGet();
        });
    }
}
