import UiPageList from "./UiPageList";
import DtoListItemOrderDeparture from "./DtoListItemOrderDeparture";

/**
 * 装货港-报关分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderDeparture extends UiPageList<DtoListItemOrderDeparture, string> {
    constructor() {
        //无参构造函数
        super()
        this.AddDeparturePortDeclareCustoms = false;
        this.FeedbackDeparturePortDeclareCustoms = false;
        this.AddDestinationPortClearCustoms = false;
        this.FeedbackDestinationPortClearCustoms = false;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 添加报关资料的待办是否处于正在执行
     */
    AddDeparturePortDeclareCustoms?: boolean;
    /**
     * 反馈报关结果的待办是否处于正在执行
     */
    FeedbackDeparturePortDeclareCustoms?: boolean;
    /**
     * 添加清关资料的待办是否处于正在执行
     */
    AddDestinationPortClearCustoms?: boolean;
    /**
     * 反馈清关结果的待办是否处于正在执行
     */
    FeedbackDestinationPortClearCustoms?: boolean;
}

/**
 * 装货港-报关分页列表-表单校验
 */
export const DtoPageListOrderDepartureRules = {
}
