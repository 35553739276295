import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-正则校验类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumValidRegularType {
    /**
     * 字母加数字
     */
    ValidWordOrNumber = 36,
    /**
     * 账号
     */
    ValidAccount = 0,
    /**
     * 密码
     */
    ValidPassword = 1,
    /**
     * 验证码
     */
    ValidVerifyCode = 2,
    /**
     * 社会信用代码
     */
    ValidCertCode = 3,
    /**
     * 海关编码
     */
    ValidCustomsCode = 4,
    /**
     * 数值
     */
    ValidNumeric = 5,
    /**
     * 正数
     */
    ValidPositiveNumber = 6,
    /**
     * 负数
     */
    ValidNegativeNumber = 7,
    /**
     * 整数
     */
    ValidInteger = 8,
    /**
     * 金钱
     */
    ValidMoney = 9,
    /**
     * 日期
     */
    ValidDate = 10,
    /**
     * 时间
     */
    ValidTime = 11,
    /**
     * 身份证号码
     */
    ValidIDCard = 12,
    /**
     * 邮编
     */
    ValidPostCode = 13,
    /**
     * 手机号码
     */
    ValidPhoneNumber = 14,
    /**
     * 电话号码
     */
    ValidTelephone = 15,
    /**
     * 手机或电话号码
     */
    ValidPhoneOrTelNumber = 16,
    /**
     * 邮箱
     */
    ValidEmailAddress = 17,
    /**
     * 网址
     */
    ValidUrl = 18,
    /**
     * 颜色
     */
    ValidColor = 19,
    /**
     * 中文
     */
    ValidChinese = 20,
    /**
     * IP4
     */
    ValidIPv4 = 21,
    /**
     * IP6
     */
    ValidIPv6 = 22,
    /**
     * 年龄
     */
    ValidAge = 23,
    /**
     * 中文名
     */
    ValidChineseName = 24,
    /**
     * 英文名
     */
    ValidEnglishName = 25,
    /**
     * 大写
     */
    ValidCapital = 26,
    /**
     * 小写
     */
    ValidLowercase = 27,
    /**
     * ASCII编码
     */
    ValidAscii = 28,
    /**
     * MD5加密字符串
     */
    ValidMd5 = 29,
    /**
     * 压缩包
     */
    ValidZip = 30,
    /**
     * 图片
     */
    ValidImage = 31,
    /**
     * 文档
     */
    ValidDocument = 32,
    /**
     * MP3
     */
    ValidMp3 = 33,
    /**
     * Flash
     */
    ValidFlash = 34,
    /**
     * 视频
     */
    ValidVideo = 35,
    /**
     * 字母加数字
     */
    ValidWordWithNumber = 37,
}

/**
 * 枚举-正则校验类型-选项
 */
export const EnumValidRegularTypeOption: UiSelectOption<number>[] = [
    { Label: "字母加数字", Value: 36, Data: "ValidWordOrNumber" },
    { Label: "账号", Value: 0, Data: "ValidAccount" },
    { Label: "密码", Value: 1, Data: "ValidPassword" },
    { Label: "验证码", Value: 2, Data: "ValidVerifyCode" },
    { Label: "社会信用代码", Value: 3, Data: "ValidCertCode" },
    { Label: "海关编码", Value: 4, Data: "ValidCustomsCode" },
    { Label: "数值", Value: 5, Data: "ValidNumeric" },
    { Label: "正数", Value: 6, Data: "ValidPositiveNumber" },
    { Label: "负数", Value: 7, Data: "ValidNegativeNumber" },
    { Label: "整数", Value: 8, Data: "ValidInteger" },
    { Label: "金钱", Value: 9, Data: "ValidMoney" },
    { Label: "日期", Value: 10, Data: "ValidDate" },
    { Label: "时间", Value: 11, Data: "ValidTime" },
    { Label: "身份证号码", Value: 12, Data: "ValidIDCard" },
    { Label: "邮编", Value: 13, Data: "ValidPostCode" },
    { Label: "手机号码", Value: 14, Data: "ValidPhoneNumber" },
    { Label: "电话号码", Value: 15, Data: "ValidTelephone" },
    { Label: "手机或电话号码", Value: 16, Data: "ValidPhoneOrTelNumber" },
    { Label: "邮箱", Value: 17, Data: "ValidEmailAddress" },
    { Label: "网址", Value: 18, Data: "ValidUrl" },
    { Label: "颜色", Value: 19, Data: "ValidColor" },
    { Label: "中文", Value: 20, Data: "ValidChinese" },
    { Label: "IP4", Value: 21, Data: "ValidIPv4" },
    { Label: "IP6", Value: 22, Data: "ValidIPv6" },
    { Label: "年龄", Value: 23, Data: "ValidAge" },
    { Label: "中文名", Value: 24, Data: "ValidChineseName" },
    { Label: "英文名", Value: 25, Data: "ValidEnglishName" },
    { Label: "大写", Value: 26, Data: "ValidCapital" },
    { Label: "小写", Value: 27, Data: "ValidLowercase" },
    { Label: "ASCII编码", Value: 28, Data: "ValidAscii" },
    { Label: "MD5加密字符串", Value: 29, Data: "ValidMd5" },
    { Label: "压缩包", Value: 30, Data: "ValidZip" },
    { Label: "图片", Value: 31, Data: "ValidImage" },
    { Label: "文档", Value: 32, Data: "ValidDocument" },
    { Label: "MP3", Value: 33, Data: "ValidMp3" },
    { Label: "Flash", Value: 34, Data: "ValidFlash" },
    { Label: "视频", Value: 35, Data: "ValidVideo" },
    { Label: "字母加数字", Value: 37, Data: "ValidWordWithNumber" },
];
