import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";

/**
 * 账单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemSaler extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.BadCollection = [];
        this.BadPayment = [];
        this.Collection = [];
        this.Collectioned = [];
        this.Cost = [];
        this.Settlement = [];
        this.Interest = [];
        this.CollectionId = [];
        this.PaymentId = [];
        this.SettlementId = [];
    }

    [proName: string]: any;
    /**
     * 应收坏账
     */
    BadCollection?: DtoCallbackCountCurrency[];
    /**
     * 应收坏账
     */
    BadPayment?: DtoCallbackCountCurrency[];
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 委托书生成时间
     */
    PoolCreatedTime?: Date;
    /**
     * 委托人
     */
    ClientCompanyName?: string;
    /**
     * 委托人主键
     */
    ClientCompanyId?: string;
    /**
     * 业务员
     */
    SalerName?: string;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 应收
     */
    Collection?: DtoCallbackCountCurrency[];
    /**
     * 应收
     */
    CollectionString?: string;
    /**
     * 已收
     */
    Collectioned?: DtoCallbackCountCurrency[];
    /**
     * 已收
     */
    CollectionedString?: string;
    /**
     * 成本
     */
    Cost?: DtoCallbackCountCurrency[];
    /**
     * 成本
     */
    CostString?: string;
    /**
     * 结算价
     */
    Settlement?: DtoCallbackCountCurrency[];
    /**
     * 结算价
     */
    SettlementString?: string;
    /**
     * 逾期利息
     */
    Interest?: DtoCallbackCountCurrency[];
    /**
     * 逾期利息
     */
    InterestString?: string;
    /**
     * 实发
     */
    State?: string;
    /**
     * 应收账单主键集合
     */
    CollectionId?: string[];
    /**
     * 应付账单主键集合
     */
    PaymentId?: string[];
    /**
     * 结算价账单主键集合
     */
    SettlementId?: string[];
}

/**
 * 账单分页列表-项-表单校验
 */
export const DtoListItemSalerRules = {
}

/**
 * 账单分页列表-项-设置项
 */
export const DtoListItemSalerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "ClientCompanyName",
    Label: "委托人",
    Customize: true,
  },
  {
    Prop: "SalerName",
    Label: "业务员",
    Customize: true,
  },
  {
    Prop: "CollectionString",
    Label: "应收",
    Customize: true,
  },
  {
    Prop: "CollectionedString",
    Label: "已收",
    Customize: true,
  },
  {
    Prop: "CostString",
    Label: "成本",
    Customize: true,
  },
  {
    Prop: "SettlementString",
    Label: "结算价",
    Customize: true,
  },
  {
    Prop: "InterestString",
    Label: "逾期利息",
    Customize: true,
  },
]
