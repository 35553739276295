import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderInboundOutbound from "../models.machine/DtoPageListSearchOrderInboundOutbound";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderInboundOutbound from "../models.machine/DtoPageListOrderInboundOutbound";
import UiDrag from "../models.machine/UiDrag";
import DtoCallbackShiftout from "../models.machine/DtoCallbackShiftout";
import DtoSurplus from "../models.machine/DtoSurplus";
import DtoSelectOptionCompany from "../models.machine/DtoSelectOptionCompany";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 出入库管理
 * @description 自动生成的代码,请勿修改
 */
export default class OrderInboundOutboundAppService {
    /**
     * 获取出入库管理分页列表
     * @param {DtoPageListSearchOrderInboundOutbound} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderInboundOutbound>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderInboundOutbound): AxiosPromise<RESTfulResult<DtoPageListOrderInboundOutbound>> {
        const url = `/api/OrderInboundOutbound/Get`;
        return service.get<RESTfulResult<DtoPageListOrderInboundOutbound>>(url, { params: dto });
    }

    /**
     * 出入库管理-拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderInboundOutbound/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 移库
     * @param {DtoCallbackShiftout} [dtoCallbackShiftout] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Shiftout(dtoCallbackShiftout?: DtoCallbackShiftout): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderInboundOutbound/Shiftout`;
        return service.post<RESTfulResult<any>>(url, dtoCallbackShiftout);
    }

    /**
     * 获取剩余数据
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoSurplus>>} 
     */
    static GetSurplusById(id?: string): AxiosPromise<RESTfulResult<DtoSurplus>> {
        const url = `/api/OrderInboundOutbound/GetSurplus/${id}`;
        return service.get<RESTfulResult<DtoSurplus>>(url);
    }

    /**
     * 查询列表供应商
     * @returns {AxiosPromise<RESTfulResult<DtoSelectOptionCompany[]>>} 
     */
    static GetSelect(): AxiosPromise<RESTfulResult<DtoSelectOptionCompany[]>> {
        const url = `/api/OrderInboundOutbound/GetSelect`;
        return service.get<RESTfulResult<DtoSelectOptionCompany[]>>(url);
    }

    /**
     * 查询列表供应商委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientCompanyId(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderInboundOutbound/GetClientCompanyId`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
