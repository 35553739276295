import { RouteRecordRaw } from 'vue-router'
import CodeControllerPageList from "./CodeController/CodeControllerPageList.vue"
import CodeControllerForm from "./CodeController/CodeControllerForm.vue"
import CodeServicePageList from "./CodeService/CodeServicePageList.vue"
import CodeServiceForm from "./CodeService/CodeServiceForm.vue"
import CodeDtoCustomForm from "./CodeDto/CodeDtoCustomForm.vue"
import CodeMethodForm from "./CodeMethod/CodeMethodForm.vue"
import CodeMethodPageListForm from "./CodeMethod/CodeMethodPageListForm.vue"
import ProjectPageList from "./Project/ProjectPageList.vue"
import ProjectVersionPageList from "./ProjectVersion/ProjectVersionPageList.vue"
import ProjectSitePageList from "./ProjectSite/ProjectSitePageList.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/developer/tool/controller',
        name: 'DeveloperTools_Controller',
        component: CodeControllerPageList,
        meta: {
            title: "开发者工具 - 代码 - 控制器"
        },
    },
    {
        path: '/developer/tool/controller/new',
        name: 'DeveloperTools_Controller_New',
        component: CodeControllerForm,
        meta: {
            title: "开发者工具 - 代码 - 控制器 - 新增"
        },
    },
    {
        path: '/developer/tool/service',
        name: 'DeveloperTools_Service',
        component: CodeServicePageList,
        meta: {
            title: "开发者工具 - 代码 - 服务"
        },
    },
    {
        path: '/developer/tool/service/new',
        name: 'DeveloperTools_Service_NewService',
        component: CodeServiceForm,
        meta: {
            title: "开发者工具 - 代码 - 服务 - 新增"
        },
    },
    {
        path: '/developer/tool/dto/new',
        name: 'DeveloperTools_Controller_AddDto',
        component: CodeDtoCustomForm,
        meta: {
            title: "开发者工具 - 代码 - DTO - 新增"
        },
    },
    {
        path: '/developer/tool/method/new',
        name: 'DeveloperTools_Service_NewMethod',
        component: CodeMethodForm,
        meta: {
            title: "开发者工具 - 代码 - 方法 - 新增方法"
        },
    },
    {
        path: '/developer/tool/method/new/pagelist',
        name: 'DeveloperTools_Service_NewPagingList',
        component: CodeMethodPageListForm,
        meta: {
            title: "开发者工具 - 代码 - 方法 - 新增分页列表方法"
        },
    },
    {
        path: '/developer/tool/project',
        name: 'DeveloperTools_Porject',
        component: ProjectPageList,
        meta: {
            title: "开发者工具 - 代码 - 项目"
        },
    },
    {
        path: '/developer/tool/project/version',
        name: 'DeveloperTools_Version',
        component: ProjectVersionPageList,
        meta: {
            title: "开发者工具 - 代码 - 项目版本"
        },
    },
    {
        path: '/developer/tool/project/site',
        name: 'DeveloperTools_Site',
        component: ProjectSitePageList,
        meta: {
            title: "开发者工具 - 代码 - 项目 - 站点"
        },
    },
];

export default routes;