import UiDetail from "@/models.machine/UiDetail";
import UiForm from "@/models.machine/UiForm";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { ElMessage } from "element-plus";
import { EnumPipe } from "./EnumPipe";
import MiezzListItem from "./MiezzListItem";

/**表单 */
export default class MiezzForm<T extends UiForm<TKey> | UiDetail<TKey> | any, TKey extends string | number> {
    constructor(powerCode?: string) {
        this.PowerCode = powerCode;
    }

    /**编号 */
    Code?: string;
    /**数据 */
    Data!: T;
    /**表单校验 */
    Rules?: any;
    /**默认栅格 */
    DefaultSpan?: number;
    FixTop?: string;
    /**项 */
    Items?: MiezzFormItem[];
    /**权限编码 */
    PowerCode?: string;
    /**名称宽度 */
    LabelWidth?: string;
    LabelPosition?: string
    /*是否显示标题栏 */
    IsTitle?: boolean;

    /**
     * 提交表单
     * @param refs
     * @param name 表单的ref名称
     * @param callback 回调函数 v 校验成功
     * @param success 成功时的消息
     */
    static submit(refs: any, name: string, callback: (v: boolean) => void): void {
        (refs[name] as any).form().validate((v: boolean) => {
            if (!v) {
                setTimeout(() => {
                    const error = document.getElementsByClassName("is-error");
                    error[0]?.querySelector("input")?.focus();
                }, 1);
            }
            callback(v);
        });
    }


    /**
     * 提交表单
     * @param refs
     * @param name 表单的ref名称
     * @param callback 回调函数 v 校验成功
     * @param success 成功时的消息
     */
    static customSubmit(refs: any, name: string, callback: (v: boolean) => void): void {
        (refs[name] as any).validate((v: boolean) => {
            if (!v) {
                setTimeout(() => {
                    const error = document.getElementsByClassName("is-error");
                    error[0]?.querySelector("input")?.focus();
                }, 1);
            }
            callback(v);
        });
    }
    static resetFields(refs: any, name: string): void {
        (refs[name] as any).form().resetFields();
    }

    static customResetFields(refs: any, name: string): void {
        (refs[name] as any).resetFields();
    }

    static clearValidate(refs: any, name: string, field: string | undefined): void {
        if (field == undefined || field == "") {
            (refs[name] as any).form().clearValidate();
        }
        else {
            (refs[name] as any).form().clearValidate(field);
        }

    }
    static clearCustomValidate(refs: any, name: string, field: string | undefined): void {
        if (field == undefined || field == "") {
            (refs[name] as any).clearValidate();
        }
        else {
            (refs[name] as any).clearValidate(field);
        }

    }

    static validateField(refs: any, name: string, prop?: string, cb?: (message: string, errors: any[]) => void) {
        (refs[name] as any)?.form()?.validateField(prop, cb);
    }

    static validateFieldCustom(refs: any, name: string, prop?: string, cb?: (message: string, errors: any[]) => void) {
        (refs[name] as any)?.validateField(prop, cb);
    }
}

/**表单-项 */
export class MiezzFormItem {
    /**分组 */
    Group?: string;
    /**类型 */
    Type?: EnumMiezzFormItemType;
    /**栅格 */
    Span?: number;
    /**标签 */
    Label?: string;
    /**标签宽度 */
    LabelWidth?: string;
    /**绑定字段 */
    Prop?: string;
    /**隐藏 */
    Hide?: boolean;
    /**是否禁用 */
    Disabled?: boolean = false;
    /**是否自定义 */
    Customize?: boolean;
    /**自动完成 */
    AllowCreate?: boolean;
    /**多 */
    Multiple?: boolean;
    /**管道 */
    Pipe?: EnumPipe;
    /**当true时 */
    WhenTrue?: string;
    /**当false时 */
    WhenFalse?: string;
    /**选项数据 */
    Options?: UiSelectOption<any>[];
    /**项 */
    Items?: MiezzFormItem[];
    /**列表 */
    ListItems?: MiezzListItem[];
    Description?: string

    /**格式化 */
    HandleFormat?: () => string | undefined;
    /**值变更时 */
    HandleChange?: () => void;
    /**失去焦点时 */
    HandleBlur?: () => void;
}


/**表单-项-类型 */
export enum EnumMiezzFormItemType {
    /**卡片 */
    Card,
    /**预文本 */
    Pre,
    /**文本 */
    Text,
    /**颜色文本 */
    TextColor,
    /**文本输入框 */
    InputText,
    /**密码输入框 */
    InputPassword,
    /**数字输入框 */
    InputNumber,
    /**文本域 */
    AreaText,
    /**选择器 */
    Select,
    /**颜色选择器 */
    ColorPicker,
    /**开关 */
    Switch,
    /**日期 */
    Date
}