import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoRequestTypescriptSync from "../models.machine/DtoRequestTypescriptSync";
import RESTfulResult from "../models.machine/RESTfulResult";

/**
 * TS
 * @description 自动生成的代码,请勿修改
 */
export default class TypescriptAppService {
    /**
     * 同步
     * @param {DtoRequestTypescriptSync} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Sync(dto?: DtoRequestTypescriptSync): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Typescript/Sync`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
