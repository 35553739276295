import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamPriceFieldType, EnumParamPriceFieldTypeOption } from "./EnumParamPriceFieldType";
import DtoDetailParamPriceFieldChildren from "./DtoDetailParamPriceFieldChildren";
import UiGroup from "./UiGroup";

/**
 * 价格要素详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamPriceField extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumParamPriceFieldType.Id;
        this.Children = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumParamPriceFieldType;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 数据源
     */
    DataSource?: string;
    /**
     * 列表列宽
     */
    Width?: string;
    /**
     * Excel列宽
     */
    ExcelWidth?: number;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 子要素
     */
    Children?: DtoDetailParamPriceFieldChildren[];
}

/**
 * 价格要素详细-表单校验
 */
export const DtoDetailParamPriceFieldRules = {
}

/**
 * 价格要素详细-设置项
 */
export const DtoDetailParamPriceFieldMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumParamPriceFieldTypeOption,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DataSource",
    Label: "数据源",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Width",
    Label: "列表列宽",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ExcelWidth",
    Label: "Excel列宽",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Children",
    Label: "子要素",
    Customize: true,
  },
]
