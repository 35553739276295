import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 关联-公司/用户表单-关联-公司/用户/组
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormJoinCompanyUser_JoinCompanyUserGroup extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 组Id
     */
    GroupId?: string;
}

/**
 * 关联-公司/用户表单-关联-公司/用户/组-表单校验
 */
export const DtoFormJoinCompanyUser_JoinCompanyUserGroupRules = {
    GroupId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 关联-公司/用户表单-关联-公司/用户/组-设置项
 */
export const DtoFormJoinCompanyUser_JoinCompanyUserGroupMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "GroupId",
    Label: "组Id",
    Type: EnumMiezzFormItemType.InputText,
  },
]
