import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchExport from "../models.machine/DtoSelectSearchExport";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchExport from "../models.machine/DtoPageListSearchExport";
import DtoPageListExport from "../models.machine/DtoPageListExport";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailExport from "../models.machine/DtoDetailExport";
import DtoFormExport from "../models.machine/DtoFormExport";

/**
 * HS编码（出口享惠情况）
 * @description 自动生成的代码,请勿修改
 */
export default class ExportAppService {
    /**
     * 获取HS编码（出口享惠情况）选择器
     * @param {DtoSelectSearchExport} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchExport): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Export/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取HS编码（出口享惠情况）分页列表
     * @param {DtoPageListSearchExport} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListExport>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchExport): AxiosPromise<RESTfulResult<DtoPageListExport>> {
        const url = `/api/Export/Get`;
        return service.get<RESTfulResult<DtoPageListExport>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Export/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取HS编码（出口享惠情况）详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailExport>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailExport>> {
        const url = `/api/Export/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailExport>>(url);
    }

    /**
     * 新增/编辑HS编码（出口享惠情况）
     * @param {DtoFormExport} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormExport): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Export/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除HS编码（出口享惠情况）
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Export/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Export/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Export/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Export/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Export/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
