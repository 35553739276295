import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolExpress from "../models.machine/DtoPageListSearchOrderPoolExpress";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolExpress from "../models.machine/DtoPageListOrderPoolExpress";
import DtoFormOrderPoolExpressCodeParam from "../models.machine/DtoFormOrderPoolExpressCodeParam";
import DtoDetailOrderPoolExpressProcess from "../models.machine/DtoDetailOrderPoolExpressProcess";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "../models.machine/EnumOrderServiceType";
import DtoPutOrderPoolExpress_PlanCarryContainerTime from "../models.machine/DtoPutOrderPoolExpress_PlanCarryContainerTime";
import DtoPutOrderPoolExpress_CarryContainerTime from "../models.machine/DtoPutOrderPoolExpress_CarryContainerTime";
import DtoPutOrderPoolExpress_CarryAddress from "../models.machine/DtoPutOrderPoolExpress_CarryAddress";
import DtoPutOrderPoolExpress_CargoQuantities from "../models.machine/DtoPutOrderPoolExpress_CargoQuantities";
import DtoPutOrderPoolExpress_AllCarryTime from "../models.machine/DtoPutOrderPoolExpress_AllCarryTime";
import DtoPutOrderPoolExpress_Supplier from "../models.machine/DtoPutOrderPoolExpress_Supplier";
import DtoPutOrderPoolExpress_Codes from "../models.machine/DtoPutOrderPoolExpress_Codes";
import DtoDetailOrderPoolExpress_Codes from "../models.machine/DtoDetailOrderPoolExpress_Codes";
import DtoExistOrderPoolExpressPartCode from "../models.machine/DtoExistOrderPoolExpressPartCode";
import DtoPutOrderPoolExpress_DeliveryAddress from "../models.machine/DtoPutOrderPoolExpress_DeliveryAddress";
import DtoPutOrderPoolExpress_AllDeliveryTime from "../models.machine/DtoPutOrderPoolExpress_AllDeliveryTime";
import DtoPutOrderPoolExpress_File from "../models.machine/DtoPutOrderPoolExpress_File";
import DtoHistory from "../models.machine/DtoHistory";
import DtoRequestOrderPoolExpressChart from "../models.machine/DtoRequestOrderPoolExpressChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";
import UiSelectOption from "../models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 委托书-快递
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolExpressAppService {
    /**
     * 获取委托书-快递分页列表
     * @param {DtoPageListSearchOrderPoolExpress} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolExpress>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolExpress): AxiosPromise<RESTfulResult<DtoPageListOrderPoolExpress>> {
        const url = `/api/OrderPoolExpress/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolExpress>>(url, { params: dto });
    }

    /**
     * 编辑快递单号参数
     * @param {DtoFormOrderPoolExpressCodeParam} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCodeParam(dto?: DtoFormOrderPoolExpressCodeParam): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutCodeParam`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 根据快递单号自动填充送达时间
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AutoFillDeliveryTimeByCodeAsync(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/AutoFillDeliveryTimeByCode`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 查询进程
     * @param {string} [id] 快递Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPoolExpressProcess>>} 
     */
    static GetProcessById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPoolExpressProcess>> {
        const url = `/api/OrderPoolExpress/GetProcess/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPoolExpressProcess>>(url);
    }

    /**
     * 添加快递
     * @param {string} [poolId] 委托书Id
     * @param {EnumOrderServiceType} [serviceType] 服务类型
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostByPoolIdByServiceType(poolId?: string, serviceType?: EnumOrderServiceType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/Post/${poolId}/${serviceType}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 编辑计划提柜时间
     * @param {DtoPutOrderPoolExpress_PlanCarryContainerTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutPlanCarryContainerTime(dto?: DtoPutOrderPoolExpress_PlanCarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutPlanCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提柜时间
     * @param {DtoPutOrderPoolExpress_CarryContainerTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryContainerTime(dto?: DtoPutOrderPoolExpress_CarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提货地址与时间
     * @param {DtoPutOrderPoolExpress_CarryAddress} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryAddress(dto?: DtoPutOrderPoolExpress_CarryAddress): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutCarryAddress`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑货量
     * @param {DtoPutOrderPoolExpress_CargoQuantities} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCargoQuantities(dto?: DtoPutOrderPoolExpress_CargoQuantities): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutCargoQuantities`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑实际提取时间
     * @param {DtoPutOrderPoolExpress_AllCarryTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutAllCarryTime(dto?: DtoPutOrderPoolExpress_AllCarryTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutAllCarryTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑供应商
     * @param {DtoPutOrderPoolExpress_Supplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutSupplier(dto?: DtoPutOrderPoolExpress_Supplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutSupplier`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 导入打单系统快递编号
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoPutOrderPoolExpress_Codes>>} 
     */
    static ImportExpressCodesById(id?: string): AxiosPromise<RESTfulResult<DtoPutOrderPoolExpress_Codes>> {
        const url = `/api/OrderPoolExpress/ImportExpressCodes/${id}`;
        return service.post<RESTfulResult<DtoPutOrderPoolExpress_Codes>>(url);
    }

    /**
     * 获取快递单号
     * @param {string} [id] 快递Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPoolExpress_Codes>>} 快递单号
     */
    static GetCodesById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPoolExpress_Codes>> {
        const url = `/api/OrderPoolExpress/GetCodes/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPoolExpress_Codes>>(url);
    }

    /**
     * 校验分单号是否存在
     * @param {DtoExistOrderPoolExpressPartCode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckExistPartCode(dto?: DtoExistOrderPoolExpressPartCode): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderPoolExpress/CheckExistPartCode`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 编辑快递单号
     * @param {DtoPutOrderPoolExpress_Codes} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCodes(dto?: DtoPutOrderPoolExpress_Codes): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutCodes`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑交货地址
     * @param {DtoPutOrderPoolExpress_DeliveryAddress} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutDeliveryAddress(dto?: DtoPutOrderPoolExpress_DeliveryAddress): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutDeliveryAddress`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑实际送达时间
     * @param {DtoPutOrderPoolExpress_AllDeliveryTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutAllDeliveryTime(dto?: DtoPutOrderPoolExpress_AllDeliveryTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutAllDeliveryTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑附件
     * @param {DtoPutOrderPoolExpress_File} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutFile(dto?: DtoPutOrderPoolExpress_File): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/PutFile`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取历史记录
     * @param {DtoPageListSearchOrderPoolExpress} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistories(dto?: DtoPageListSearchOrderPoolExpress): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/OrderPoolExpress/GetHistories`;
        return service.get<RESTfulResult<DtoHistory[]>>(url, { params: dto });
    }

    /**
     * 批量删除委托书-快递
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolExpress/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderPoolExpressChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderPoolExpressChart): AxiosPromise<RESTfulResult<UiEChartsBar>> {
        const url = `/api/OrderPoolExpress/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderPoolExpressChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderPoolExpressChart): AxiosPromise<RESTfulResult<UiEChartsPie>> {
        const url = `/api/OrderPoolExpress/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie>>(url, { params: dto });
    }

    /**
     * 获取委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClients(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolExpress/GetClients`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolExpress/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取坐标选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetAreas(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPoolExpress/GetAreas`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolExpress/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取卸货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolExpress/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
