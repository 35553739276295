import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCompanyOvertimeule_CompanyOvertimeule from "./DtoFormCompanyOvertimeule_CompanyOvertimeule";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyOvertimeRule extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.OvertimeRules = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 待办时效规则
     */
    OvertimeRules?: DtoFormCompanyOvertimeule_CompanyOvertimeule[];
}

/**
 * -表单校验
 */
export const DtoFormCompanyOvertimeRuleRules = {
}

/**
 * -设置项
 */
export const DtoFormCompanyOvertimeRuleMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "OvertimeRules",
    Label: "待办时效规则",
    Customize: true,
  },
]
