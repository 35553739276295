import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemCompanyCustomerMainRelationApply_Company from "./DtoListItemCompanyCustomerMainRelationApply_Company";
import { EnumAuditStatus, EnumAuditStatusOption } from "./EnumAuditStatus";

/**
 * 关联-客户公司主体关联分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyCustomerMainRelationAudit extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.AuditStatus = EnumAuditStatus.No;
    }

    [proName: string]: any;
    /**
     * 主体公司Id
     */
    MainCompanyId?: string;
    /**
     * 主体公司
     */
    MainCompany?: DtoListItemCompanyCustomerMainRelationApply_Company;
    /**
     * 子公司Id
     */
    ChildrenCompanyId?: string;
    /**
     * 子公司
     */
    ChildrenCompany?: DtoListItemCompanyCustomerMainRelationApply_Company;
    /**
     * 审核状态
     */
    AuditStatus?: EnumAuditStatus;
    /**
     * 审核备注
     */
    AuditRemark?: string;
}

/**
 * 关联-客户公司主体关联分页列表-项-表单校验
 */
export const DtoListItemCompanyCustomerMainRelationAuditRules = {
}

/**
 * 关联-客户公司主体关联分页列表-项-设置项
 */
export const DtoListItemCompanyCustomerMainRelationAuditMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "MainCompany",
    Label: "主体公司",
  },
  {
    Prop: "ChildrenCompany",
    Label: "子公司",
  },
  {
    Prop: "AuditStatus",
    Label: "审核状态",
    Pipe: EnumPipe.Enum,
    Options: EnumAuditStatusOption,
  },
  {
    Prop: "AuditRemark",
    Label: "审核备注",
  },
]
