import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import { EnumCustomerSource, EnumCustomerSourceOption } from "./EnumCustomerSource";
import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 公司选择器-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSelectSearchCompany {
    constructor() {
        //无参构造函数
        this.SeeAll = false;
        this.QuerySkyEye = false;
        this.SearchPriceTableRelation = false;
        this.IncludeDisabled = false;
    }

    [proName: string]: any;
    /**
     * 公司类型
     */
    CompanyType?: EnumCompanyType;
    /**
     * 供应商类型
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 客户类型
     */
    CustomerSource?: EnumCustomerSource;
    /**
     * 公司类型Id
     */
    CompanyTypeId?: string;
    /**
     * 查看全部
     */
    SeeAll?: boolean;
    /**
     * 关键字
     */
    Keywords?: string;
    /**
     * 查询天眼查公司信息
     */
    QuerySkyEye?: boolean;
    /**
     * 服务商托管
     */
    Trusteeship?: boolean;
    /**
     * 价格表Id
     */
    PriceTableId?: string;
    /**
     * 查询价格表关联的公司
     */
    SearchPriceTableRelation?: boolean;
    /**
     * 价格类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 包含禁用公司
     */
    IncludeDisabled?: boolean;
}

/**
 * 公司选择器-搜索条件-表单校验
 */
export const DtoSelectSearchCompanyRules = {
}
