
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoDetailJoinCompanyUser from "@/models.machine/DtoDetailJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailJoinCompanyUser_JoinCompanyUserContact, {
  DtoDetailJoinCompanyUser_JoinCompanyUserContactMiezzListItems,
} from "@/models.machine/DtoDetailJoinCompanyUser_JoinCompanyUserContact";
import MiezzModal from "@/models/MiezzModal";
import DtoTreePower from "@/models.machine/DtoTreePower";
import RoleAppService from "@/apis.machine/RoleAppService";
import DtoTreeSearchPower from "@/models.machine/DtoTreeSearchPower";
import PowerAppService from "@/apis.machine/PowerAppService";
import ChoosePower from "@/views/Sys/Role/ChoosePower.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";

@Options({
  components: {
    ChoosePower,
  },
})
export default class UserDetail extends Vue {
  @Prop() menuTab?: MiezzMenuTab;
  @Prop() type?: EnumCompanyType;
  @Prop() detail = new MiezzForm<DtoDetailJoinCompanyUser, string>(
    "CompanyManagement_User_Detail"
  );
  @Prop() buttons: MiezzButton[] = [];

  id?: string;
  logier?: Logier;
  list = new MiezzPageList<
    any,
    DtoDetailJoinCompanyUser_JoinCompanyUserContact,
    string
  >();
  modalPower = new MiezzModal();
  powerIds: string[] = [];
  roleTree: DtoTreePower[] = [];

  /**创建时 */
  created(): void {
    this.id = this.$route.params.id as string;
    this.logier = CurrentLogier.GetLogier();
    //详细-数据-初始化
    this.detail.Data = new DtoDetailJoinCompanyUser();
    for (const item of this.detail.Items ?? []) {
      switch (item.Prop) {
        case "Company":
          if (!item.HandleFormat) {
            item.HandleFormat = () => {
              return `${this.detail.Data.Company?.NameZh ?? "暂无数据"}/${
                this.detail.Data.Company?.NameEn ?? "暂无数据"
              }`;
            };
          }
          break;
        case "InviteCode":
          item.HandleFormat = () => {
            return `${this.detail.Data.InviteCode} （首次登录后失效）`;
          };
          break;
        case "JoinCompanyUserGroups":
          item.HandleFormat = () =>
            this.detail.Data.JoinCompanyUserGroups?.map(
              (it) => it.Group?.Name
            ).join("，");
          break;
        default:
          break;
      }
    }

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items =
      DtoDetailJoinCompanyUser_JoinCompanyUserContactMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };

    this.modalPower.Title = "相关权限";

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      JoinCompanyUserAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          if (
            this.logier?.CompanyType == EnumCompanyType.ServiceProvider &&
            this.type == EnumCompanyType.Customer
          ) {
            if (this.detail.Data.UserPhoneNumber) {
              this.detail.Items = this.detail.Items?.filter(
                (it) => it.Prop != "InviteCode"
              );
            } else {
              this.detail.Items = this.detail.Items?.filter(
                (it) => it.Prop != "UserPhoneNumber"
              );
            }
            const group = this.detail.Data.UiGroups?.firstOrDefault();
            if (group && this.detail.Data.Company?.Trusteeship != true) {
              group.Description = "对方未托管用户的管理权限，仅可查看";
            }
          } else {
            this.detail.Items = this.detail.Items?.filter(
              (it) => it.Prop != "InviteCode"
            );
          }
          if (this.list.Data) {
            this.list.Data.Items = r.data.Data.JoinCompanyUserContacts;
          }
        }
      });
    }
  }

  handleShowPower(id?: string): void {
    this.modalPower.Show = true;
    this.powerIds = [];
    this.roleTree = [];
    RoleAppService.GetById(id).then((r) => {
      this.powerIds = r.data.Data?.PowerIds ?? [];
      const dto = new DtoTreeSearchPower();
      dto.CheckIds = this.powerIds;
      dto.OnlyShowCheck = true;
      PowerAppService.GetTree(dto).then((r) => {
        this.roleTree = r.data.Data ?? [];
      });
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const listUrl = this.$route.path.substring(
      0,
      this.$route.path.indexOf("/detail")
    );
    switch (btn.Code) {
      case "edit":
        if (this.id) {
          this.$router.push(`${listUrl}/edit/${this.id}`);
        } else {
          this.$router.push(`${listUrl}/edit`);
        }
        complete();
        break;
      case "delete":
        ElMessageBox.confirm("是否确认将该信息删除？", "提醒", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            JoinCompanyUserAppService.Delete([this.id as string])
              .then(() => {
                this.$router.push(listUrl);
                complete();
              })
              .catch(complete);
          })
          .catch(() => {
            complete();
          });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
