
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormSmsSendVerifyCode {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 短信模板编号 Login/Reg/RePassword/BindPhoneNumber/RePhoneNumber
     */
    Code?: string;
    /**
     * 手机号码
     */
    PhoneNumber?: string;
}

/**
 * -表单校验
 */
export const DtoFormSmsSendVerifyCodeRules = {
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    PhoneNumber: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z]{4,32}$/.test(value)) {
                    return callback(new Error("账号必须是手机号/字母和数字组合，长度在4-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
}
