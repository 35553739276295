import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceFormulaClacItem {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 价格Id
     */
    PriceId?: string;
    /**
     * 公式
     */
    Formula?: string;
    /**
     * 基准价
     */
    BenchmarkAmount?: number;
    /**
     * 成本价
     */
    GuideAmount?: number;
    /**
     * 结算价
     */
    SettlementAmount?: number;
    /**
     * 采购价
     */
    CostAmount?: number;
}

/**
 * -表单校验
 */
export const DtoFormPriceFormulaClacItemRules = {
}
