import UiSelectOption from "./UiSelectOption";

/**
 * 干线物流状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumTrunkStatus {
    /**
     * 待提货
     */
    WaitTake = 1,
    /**
     * 提货中
     */
    IngTake = 2,
    /**
     * 已提货
     */
    EdTake = 3,
    /**
     * 送达中
     */
    IngDeliver = 4,
    /**
     * 已送达
     */
    EdDeliver = 5,
}

/**
 * 干线物流状态-选项
 */
export const EnumTrunkStatusOption: UiSelectOption<number>[] = [
    { Label: "待提货", Value: 1, Data: "WaitTake" },
    { Label: "提货中", Value: 2, Data: "IngTake" },
    { Label: "已提货", Value: 3, Data: "EdTake" },
    { Label: "送达中", Value: 4, Data: "IngDeliver" },
    { Label: "已送达", Value: 5, Data: "EdDeliver" },
];
