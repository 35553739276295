
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import CurrentLogier from "@/apis/CurrentLogier";
    import moment from "moment";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import ProductSpecElementAppService from "@/apis.machine/ProductSpecElementAppService";
    import DtoDetailProduct, {
    } from "@/models.machine/DtoDetailProduct";
    import SpecAppService from "@/apis.machine/SpecAppService";
    import DtoDetailSpec, {
        DtoDetailSpecMiezzDetailItems,
    } from "@/models.machine/DtoDetailSpec";
    import SpecForm from "./SpecForm.vue";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    @Options({
        components: {
            SpecForm,
        },
    })
    export default class SpecDetail extends Vue {
        client?: OSS;
        //是否显示规格要素：Bug4767 产品管理-产品列表-规格型号-查看   8规格要素/原始库存未勾选任何信息时，显示为暂无数据，如下所示：
        IsProductSpecElement = false;
        historyRandom = -1;
        historyForm = {
            Visible: false,
            Title: "",
            CurrentPage: 1,
            IsEnd: false,
            StockId: "",
        };
        historys?: any;
        historyBottomButtons?: MiezzButton[] = [
            {
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        bottomButtonsImage?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        menuTab = new MiezzMenuTab();
        id = "";
        productId = "";
        product = new MiezzForm<DtoDetailProduct, string>();
        productSpecElements?: UiSelectOption<string>[] = [];
        //库存信息
        ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation?: boolean;
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "edit",
                Label: "编辑",
                Icon: "edit",
                Type: "text",
                Size: "small",
                PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_Details_Edit",
            },
            //{
            //    Code: "delete",
            //    Label: "删除",
            //    Icon: "delete",
            //    Type: "text",
            //    Size: "small",
            //    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_Delete",
            //},
        ];

        imageForm: {
            Visiable: boolean,
            Rows?: any[],
        } = {
                Visiable: false,
                Rows: [],
            };
        detail = new MiezzForm<DtoDetailSpec, string>();

        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.productId = this.$route.params.id as string;
            this.id = this.$route.params.specId as string;
            this.product.Data = new DtoDetailProduct();
            //详细-数据-初始化
            this.detail.Data = new DtoDetailSpec();
            //详细-元素配置
            this.detail.Items = DtoDetailSpecMiezzDetailItems;

            this.detail.IsTitle = false;

            CurrentLogier.GetPowerCodes().then((r) => {

                if (r?.contains("ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation")) {
                    this.ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation = true;
                }
            });

            var elementParam = {
                CurrentPage: 1,
                PageSize: 9999999,
                ProductId: this.productId,
            };
            ProductSpecElementAppService.Get(elementParam).then(r1 => {
                this.productSpecElements = r1.data.Data?.Items;
                if (this.productSpecElements) {
                    for (var it of this.productSpecElements) {
                        for (var it1 of it.ProductSpecElementItems) {
                            it1.IsSelected = false;
                        }
                    }
                }

                //this.random = Math.random();
                this.handleGet();
            })

            this.menuTab.Back = true;
            this.menuTab.Url = `/product/spec/detail/${this.productId}/${this.id}`;
            this.menuTab.Menus = [
                {
                    Label: "规格型号详情",
                    Url: `/product/spec/detail/${this.productId}/${this.id}`,
                    Power: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_Details",
                },
            ];
            this.detail.FixTop = "40px";
        }
        /**按钮点击 */
        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onNextHistory(): void {
            SpecAppService.GetStockHistoryByIdByCurrentPage(this.historyForm.StockId, this.historyForm.CurrentPage).then((r) => {
                if (r.data.Data) {
                    if (this.historyForm) {
                        this.historyForm.CurrentPage++;
                        this.historyForm.IsEnd = false;
                        if (r.data.Data) {
                            for (var it of r.data.Data) {
                                this.historys.push(it);
                                if (it.IsEnd == true) {
                                    this.historyForm.IsEnd = true;
                                }
                            }
                        }
                        this.historyRandom = Math.random();
                    }
                }
            })
        }
        onHistoryStock(row: any): void {
            this.historyForm.CurrentPage = 1;
            if (this.historyForm.CurrentPage <= 1) {
                this.historys = [];
            }
            this.historyForm.StockId = row.Id;
            SpecAppService.GetStockHistoryByIdByCurrentPage(row.Id, this.historyForm.CurrentPage).then((r) => {
                if (r.data.Data) {
                    if (this.historyForm) {
                        this.historyForm.CurrentPage++;
                        this.historyForm.IsEnd = false;
                        if (r.data.Data) {
                            for (var it of r.data.Data) {
                                this.historys.push(it);
                                if (it.IsEnd == true) {
                                    this.historyForm.IsEnd = true;
                                }
                            }
                        }
                        this.historyForm.Visible = true;
                    }
                }
            })
        }
        handleClickHistory(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.historyForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onImage(row: any): void {
            var rows = [];
            rows.push(row);
            this.imageForm.Rows = rows;
            this.imageForm.Visiable = true;
        }

        handleBack(): void {

            CurrentLogier.Back();
            // var url = this.$route.query.backUrl as string;
            // if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
            //     this.$router.push(url);
            // }
            // else {
            //     history.go(-1);
            // }
        }

        /**查询详细 */
        handleGet(): void {
            if (this.productId) {
                ProductAppService.GetById(this.productId).then((r) => {
                    if (r.data.Data) {
                        this.product.Data = r.data.Data;
                        this.product.Data.Name = this.product.Data.NameZh + '/' + this.product.Data.NameEn;
                    }

                });
            }
            if (this.id) {
                SpecAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.detail.Data = r.data.Data;

                    if (this.detail.Data.SpecImages != undefined && this.detail.Data.SpecImages.length > 0) {
                        for (var annex of this.detail.Data.SpecImages) {
                            if (annex.Url != undefined) {
                                annex.Name = annex.Url.split('/')[annex.Url.split('/').length - 1];
                                annex.Url = this.client?.signatureUrl(annex.Url as string);
                            }
                        }
                    }

                    if (this.detail && this.detail.Data && this.detail.Data.SpecStocks) {
                        for (var stock of this.detail.Data.SpecStocks) {
                            if (stock.CurrencyName) {
                                var name = stock.CurrencyName.split("/");
                                if (name && name.length >= 3) {
                                    stock.CurrencyName = name[2];
                                }
                            }

                            stock.DeliveryTimeString = "";
                            if (stock.DeliveryTime) {
                                stock.DeliveryTimeString = moment(stock.DeliveryTime).format("YYYY/MM/DD");
                            }

                        }
                    }
                    this.IsProductSpecElement = false;
                    if (this.productSpecElements) {
                        for (var it of this.productSpecElements) {
                            for (var it1 of it.ProductSpecElementItems) {
                                if (this.detail.Data.SpecElements) {
                                    for (var it2 of this.detail.Data.SpecElements) {
                                        if (it1.Id == it2.ProductSpecElementItemId) {
                                            it1.IsSelected = true;
                                            this.IsProductSpecElement = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "edit":
                    this.$router.push("/product/spec/edit/" + this.productId + "/" + this.id + "?backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                case "delete":

                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            var ids = [];
                            ids.push(this.id);
                            SpecAppService.Delete(ids)
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleBack();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }
