
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormCompany, {
  DtoFormCompanyMiezzFormItems,
} from "@/models.machine/DtoFormCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyForm from "../CompanyForm.vue";
@Options({
  components: {
    CompanyForm,
  },
})
export default class CurrentCompanyEdit extends Vue {
  form = new MiezzForm<DtoFormCompany, string>(
    "CompanyManagement_Company_Edit"
  );
  companyTypes?: UiSelectOption<string>[] = [];
  nameEnAbbr = false;

  created(): void {
    const logier = CurrentLogier.GetLogier();
    this.nameEnAbbr = logier?.CompanyType == EnumCompanyType.ServiceProvider;
    this.form.Items = DtoFormCompanyMiezzFormItems.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "SalesmanId" &&
        it.Prop != "Source" &&
        it.Prop != "BusinessScope" &&
        it.Prop != "Remark"
    );
    for (const item of this.form.Items) {
      if (
        item.Prop == "NameZh" ||
        item.Prop == "JoinCompanyTypes" ||
        item.Prop == "Status"
      ) {
        if (item.Prop == "NameZh") {
          item.Disabled = logier?.CompanyType != EnumCompanyType.Platform;
        } else {
          item.Disabled = true;
        }
      }
    }

    CompanyTypeAppService.GetSelect({ type: logier?.CompanyType }).then((r) => {
      this.companyTypes = r.data.Data;
    });
  }
}
