
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamInvoiceTaxRateAppService from "@/apis.machine/ParamInvoiceTaxRateAppService";
import DtoFormParamInvoiceTaxRate, {
  DtoFormParamInvoiceTaxRateMiezzFormItems,
  DtoFormParamInvoiceTaxRateRules,
} from "@/models.machine/DtoFormParamInvoiceTaxRate";
import MiezzModal from "@/models/MiezzModal";
import DtoListItemParamInvoiceTaxRate_Repeat from "@/models.machine/DtoListItemParamInvoiceTaxRate_Repeat";

export default class ParamInvoiceTaxRateForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamInvoiceTaxRate, string>(
    "System_ParamList_InvoiceTaxRate_AddEdit"
  );
  optFullName?: Array<any> = [];
  optItemListRepeat: Array<DtoListItemParamInvoiceTaxRate_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamInvoiceTaxRate();
    
    //表单-校验
    this.form.Rules = DtoFormParamInvoiceTaxRateRules;
    this.form.Rules.Type = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.Type != null && typeof (this.form.Data.Type) != "undefined" && this.form.Data.Type != "undefined" && this.form.Data.Type != ""
          && this.form.Data.Name != null && typeof (this.form.Data.Name) != "undefined") {
            var Type = this.form.Data.Type.trim();
            var Name = this.form.Data.Name;
            if (this.optItemListRepeat.some(it => it.Type == Type && it.Name == Name)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ]
    this.form.Rules.Name = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.Type != null && typeof (this.form.Data.Type) != "undefined" && this.form.Data.Type != "undefined" && this.form.Data.Type != ""
          && this.form.Data.Name != null && typeof (this.form.Data.Name) != "undefined") {
            var Type = this.form.Data.Type.trim();
            var Name = this.form.Data.Name;
            if (this.optItemListRepeat.some(it => it.Type == Type && it.Name == Name)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ]
    //表单-元素配置
    this.form.Items = DtoFormParamInvoiceTaxRateMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamInvoiceTaxRateAppService.GetList().then((r1) => {
      if (r1.data.Data) {
        this.optItemListRepeat = r1.data.Data;
      }
      if (this.id) {
        ParamInvoiceTaxRateAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;
            
            var index = this.optItemListRepeat.findIndex(it => { return it.Type == this.form.Data.Type && it.Name == this.form.Data.Name });
            this.optItemListRepeat.splice(index, 1);
          }
        });
      } else {
        this.form.Data = new DtoFormParamInvoiceTaxRate();
        this.form.Data.Name = undefined;
      }
    })
    
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamInvoiceTaxRateAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    if (this.form.Data.Type) {
      this.optFullName?.push(this.form.Data.Type);
    }
    if (this.form.Data.Name != null) {
      this.optFullName?.push(this.form.Data.Name+"%");
    }
  
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurType(): void {
    if (this.form.Data.Type) {
      this.form.Data.Type = this.form.Data.Type.toUpperCase();
    }
    this.onBlur();
  }

  onBlurName(): void {
    var result = "";
    if (this.form.Data.Name)
      result = this.setDecimal(this.form.Data.Name.toString(), 2, true);
    if (result != "") {
      this.form.Data.Name = Number(result);
    } else {
      this.form.Data.Name = undefined;
    }

    this.onBlur();
  }

  /*仅允许输入数字，其他字符都去除 */
  /*val：输入的字符串 */
  /*num：小数点后保留几位小数 */
  /*isZero：是否允许输入0 */
  setDecimal(val: string, num: number, isZero: boolean):string {
    if (val == null || val == undefined || val == '') {
      return '';
    }

    if (val != null && val != "") {
      val = val.toString();
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/\.{2,}/g, ".");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
      if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val).toString();
      }
    }

    if (val == "" || val.toString() == "NaN") {
      val = "";
      return val;
    }

    if (isZero == true) {
      if (parseFloat(val.toString()) == 0) {
        return val;
      }
    }
    else {
      if (parseFloat(val.toString()) == 0) {
        return "";
      }
    }
    if (val.toString() == "NaN") {
      val = "";
      return val;
    }

    var result = parseFloat(val).toRound(num);
    if (isZero == false && result == "0") {
      return "";
    }
    else {
      return result;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
