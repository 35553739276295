
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PricePageList from "../PricePageList.vue";
@Options({
  components: {
    PricePageList,
  },
})
export default class PriceGuidePageList extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Guide"
  );
  amountType = EnumPriceAmountType.Guide;

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "download",
        Label: "下载模板",
        Icon: "download",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Guide_Import",
      },
      {
        Code: "import",
        Label: "导入",
        Icon: "top",
        Type: "primary",
        Size: "small",
        FileUpload: true,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PowerCode: "PriceManagement_Guide_Import",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Guide_Export",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Guide_History",
      },
      {
        Code: "formula",
        Title: "公式",
        MiezzIcon: "formula",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Guide_Formula",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "amount-update",
        Label: "批量编辑",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_Guide_EditPrice",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_Guide_Export",
      },
      {
        Code: "add-formula",
        Label: "添加公式",
        MiezzIcon: "formula",
        Type: "text",
        PowerCode: "PriceManagement_Guide_Formula",
      },
      {
        Code: "delete-formula",
        Label: "删除公式",
        MiezzIcon: "formula",
        Type: "text",
        Message: "是否确认将该信息公式删除？",
        PowerCode: "PriceManagement_Guide_Formula",
      },
      {
        Code: "lock",
        Label: "锁定",
        Icon: "Lock",
        Type: "text",
        PowerCode: "PriceManagement_Guide_EditPrice",
      },
      {
        Code: "un-lock",
        Label: "解锁",
        Icon: "Unlock",
        Type: "text",
        PowerCode: "PriceManagement_Guide_EditPrice",
      },
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];
  }
}
