import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSynchronizePoolData {
    constructor() {
        //无参构造函数
        this.IsDo = true;
        this.Type = EnumOrderServiceType.DeparturePort;
        this.IsTip = false;
        this.IsDeparture = false;
        this.IsDisestion = false;
        this.SynchronizeType = 0;
    }

    [proName: string]: any;
    /**
     * 是否执行
     */
    IsDo?: boolean;
    /**
     * 
     */
    PoolId?: string;
    /**
     * 
     */
    OrderId?: string;
    /**
     * 
     */
    Type?: EnumOrderServiceType;
    /**
     * 
     */
    IsTip?: boolean;
    /**
     * 装货港
     */
    IsDeparture?: boolean;
    /**
     * 目的港
     */
    IsDisestion?: boolean;
    /**
     * 同步方向，0表示仓库的数据同步到委托书；1表示委托书的数据同步到仓库
     */
    SynchronizeType?: number;
}

/**
 * -表单校验
 */
export const DtoSynchronizePoolDataRules = {
}
