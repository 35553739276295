import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99dc0490"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "empty-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button_source = _resolveComponent("miezz-button-source")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_miezz_button_upload = _resolveComponent("miezz-button-upload")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    trigger: "click",
    class: _normalizeClass(`dropdown ${_ctx.model.Code}-dropdown`),
    onCommand: _ctx.handleBeforeCommand,
    type: _ctx.model.Type,
    size: _ctx.model.Size,
    disabled: _ctx.model.Loading,
    "popper-class": `${_ctx.model.Code}-popper`,
    placement: _ctx.model.Placement,
    "max-height": "300px"
  }, {
    dropdown: _withCtx(() => [
      ((_ctx.model.Options?.length ?? 0) > 0 && _ctx.model.IsDropdown == true)
        ? (_openBlock(), _createBlock(_component_el_dropdown_menu, {
            key: 0,
            class: "header-new-dropdown"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.Options, (item, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  (item.Hide != true)
                    ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                        key: 0,
                        command: item.Code
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Label), 1)
                        ]),
                        _: 2
                      }, 1032, ["command"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }))
        : ((_ctx.model.Options?.length ?? 0) > 0 && _ctx.model.IsDropdown != true)
          ? (_openBlock(), _createBlock(_component_el_dropdown_menu, { key: 1 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.Options, (item, j) => {
                  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: j,
                    command: item.Code
                  }, {
                    default: _withCtx(() => [
                      (item.FileUpload)
                        ? (_openBlock(), _createBlock(_component_miezz_button_upload, {
                            key: 0,
                            model: item,
                            onHandleUpload: _ctx.handleDropdowItemUpload
                          }, null, 8, ["model", "onHandleUpload"]))
                        : (_openBlock(), _createBlock(_component_miezz_button_source, {
                            key: 1,
                            model: item
                          }, null, 8, ["model"]))
                    ]),
                    _: 2
                  }, 1032, ["command"]))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("p", _hoisted_1, "暂无数据"))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_miezz_button_source, {
        model: _ctx.button,
        isDropdown: _ctx.model.IsDropdown
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["model", "isDropdown"])
    ]),
    _: 3
  }, 8, ["class", "onCommand", "type", "size", "disabled", "popper-class", "placement"]))
}