import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c1c727e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "tab-label" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "cargos-total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_common_history = _resolveComponent("miezz-common-history")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detail) = $event)),
      style: {"margin-bottom":"10px"}
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Cargos')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                (
            _ctx.detail.Data.PackagesNumber ||
            _ctx.detail.Data.PackagesKind ||
            _ctx.detail.Data.Weight ||
            _ctx.detail.Data.Volume
          )
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:void",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDetailCargos && _ctx.handleDetailCargos(...args)))
                    }, _toDisplayString(_ctx.detail.Data.PackagesNumber && _ctx.detail.Data.PackagesKind
              ? `${_ctx.detail.Data.PackagesNumber}${_ctx.detail.Data.PackagesKind}`
              : _ctx.detail.Data.PackagesNumber
              ? _ctx.detail.Data.PackagesNumber
              : _ctx.detail.Data.PackagesKind
              ? _ctx.detail.Data.PackagesKind
              : "暂无数据") + "/ " + _toDisplayString(_ctx.detail.Data.Weight ? `${_ctx.detail.Data.Weight}KGS` : "暂无数据") + "/ " + _toDisplayString(_ctx.detail.Data.Volume ? `${_ctx.detail.Data.Volume}CBM` : "暂无数据"), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, "暂无数据"))
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Files')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                ((_ctx.detail.Data.Files?.length ?? 0) > 0)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "javascript:void",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleDetailFiles && _ctx.handleDetailFiles(...args)))
                    }, " 查看详情 "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, "暂无数据"))
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : (item.Prop == 'Schemes')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                prop: item.Prop,
                labelWidth: "0px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tabs, {
                    modelValue: _ctx.schemeName,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.schemeName) = $event)),
                    type: "card",
                    "tab-position": "top"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.Schemes, (item) => {
                        return (_openBlock(), _createBlock(_component_el_tab_pane, {
                          key: item.Name,
                          label: item.Name,
                          name: item.Name
                        }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, [
                              _createElementVNode("span", {
                                title: item.Name
                              }, _toDisplayString(item.Name), 9, _hoisted_4)
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_el_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_col, { span: 12 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, {
                                      prop: "ValidDateStart",
                                      label: "价格有效期"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.ValidDate), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_el_col, { span: 12 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_form_item, {
                                      prop: "ServiceRemark",
                                      label: "服务内容"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.ServiceRemark), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.FieldValues, (field, field_index) => {
                                  return (_openBlock(), _createBlock(_component_el_col, {
                                    span: 12,
                                    key: field_index
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_form_item, {
                                        label: field.Name,
                                        labelWidth: "150px"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(field.Label), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["label"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("h1", null, [
                              _createTextVNode(" 合计： "),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totals, (total, total_index) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  style: {"margin-right":"5px","color":"red"},
                                  key: total_index
                                }, _toDisplayString(total.CurrencySystem) + " " + _toDisplayString(total.Amount?.toFixed(2)), 1))
                              }), 128))
                            ]),
                            _createVNode(_component_miezz_page_list, {
                              modelValue: _ctx.list,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.list) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 2
                        }, 1032, ["label", "name"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_common_history, {
      modelValue: _ctx.histories,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.histories) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalCargos,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalCargos) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, { inline: true }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_form_item, {
                label: "合计件数",
                prop: "PackagesNumber",
                "label-width": "80px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.PackagesNumber && _ctx.detail.Data.PackagesKind
              ? `${_ctx.detail.Data.PackagesNumber}${_ctx.detail.Data.PackagesKind}`
              : _ctx.detail.Data.PackagesNumber
              ? _ctx.detail.Data.PackagesNumber
              : _ctx.detail.Data.PackagesKind
              ? _ctx.detail.Data.PackagesKind
              : "暂无数据"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "合计毛重",
                prop: "Weight",
                "label-width": "90px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.Weight ? `${_ctx.detail.Data.Weight}KGS` : "暂无数据"), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: "合计体积",
                prop: "Volume",
                "label-width": "90px"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.detail.Data.Volume ? `${_ctx.detail.Data.Volume}CBM` : "暂无数据"), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_miezz_page_list, {
              modelValue: _ctx.listCargos,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.listCargos) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalFile,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalFile) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, { "label-width": "80px" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "文件",
              prop: "Files"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.detail.Data.Files,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.detail.Data.Files) = $event)),
                  prop: "File",
                  preview: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}