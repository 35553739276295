
import GroupAppService from "@/apis.machine/GroupAppService";
import DtoDetailGroup, {
  DtoDetailGroupMiezzDetailItems,
} from "@/models.machine/DtoDetailGroup";
import DtoListItemJoinCompanyUser, {
  DtoListItemJoinCompanyUserMiezzListItems,
} from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoFormGroup from "@/models.machine/DtoFormGroup";
import DtoPageListSearchJoinCompanyUser from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import { EnumUiApiMethod } from "@/models.machine/EnumUiApiMethod";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Model, Emit, Prop } from "vue-property-decorator";
import GroupForm from "./GroupForm.vue";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzPageList from "@/models/MiezzPageList";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoPageListJoinCompanyUser from "@/models.machine/DtoPageListJoinCompanyUser";
@Options({
  components: {
    GroupForm,
  },
})
export default class GroupDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailGroup, string>(
    "CompanyManagement_Department_Detail"
  );
  companyUserList = new MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >();

  modalForm = new MiezzModal();
  formId?: string;
  parentId?: string;
  path?: string;
  Items?: any[];

  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  /**创建时 */
  created(): void {
    this.detail.Data = new DtoDetailGroup();
    this.detail.Items = DtoDetailGroupMiezzDetailItems;

    this.companyUserList.Fix = false;
    this.companyUserList.HideCard = true;
    this.companyUserList.EnabledDynamicSearch = false;
    this.companyUserList.EnabledRadioSearch = false;
    this.companyUserList.EnabledPagination = false;
    this.companyUserList.EnabledRadioSearchAll = false;
    this.companyUserList.EnabledSelect = false;
    this.companyUserList.Items =
      DtoListItemJoinCompanyUserMiezzListItems.filter(
        (it) =>
          it.Prop != "Company" && it.Prop != "Status" && it.Prop != "UserStatus"
      );
    this.companyUserList.HandleFormat = this.handleFormat;

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Label: "编辑",
        // Icon: "edit",
        Type: "primary",
        Size: "small",
        PowerCode: "CompanyManagement_Department_AddEdit",
      },
      {
        Code: "add-children",
        Label: "添加子部门",
        // Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CompanyManagement_Department_AddEdit",
      },
      {
        Code: "delete",
        Label: "删除",
        // Icon: "delete",
        Type: "primary",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CompanyManagement_Department_Delete",
      },
    ];

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      GroupAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;

        JoinCompanyUserAppService.GetPageList(undefined, {
          PageSize: 1000,
        }).then((r) => {
          this.companyUserList.Data = r.data.Data;
          this.companyUserList.Data!.Items =
            this.companyUserList.Data?.Items?.filter(
              (it) =>
                it.Id && this.detail.Data.JoinCompanyUserIds?.contains(it.Id)
            );
        });
      });
    }
  }

  handleFormat(
    item: MiezzListItem,
    row: DtoListItemJoinCompanyUser
  ): string | undefined {
    if (item.Prop == "Company") {
      return `${row.Company?.NameZh ?? "暂无数据"}/${row.Company?.NameEn ?? "暂无数据"}`;
    } else if (item.Prop == "JoinCompanyUserRoles") {
      return (
        row.JoinCompanyUserRoles?.map((it) => it.Role?.Name)?.join("，") ?? "暂无数据"
      );
    }
    return undefined;
  }

  getMethod(value: EnumUiApiMethod): string | undefined {
    return EnumUiApiMethod[value];
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const data = this.detail.Data as DtoFormGroup;
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑部门-${this.detail.Data?.Name}`;
        this.formId = this.id;
        complete();
        break;
      case "add-children":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增部门";
        this.formId = undefined;
        this.parentId = data.Id;
        this.path = data.Path ? `${data.Path}${data.Name}/` : `${data.Name}/`;
        complete();
        break;
      case "delete":
        GroupAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
