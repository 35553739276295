import DtoListItemFinance_ParamCurrencySystem from "./DtoListItemFinance_ParamCurrencySystem";
import { EnumSettlementMode, EnumSettlementModeOption } from "./EnumSettlementMode";
import DtoSelectMonth from "./DtoSelectMonth";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormSalerSettlement {
    constructor() {
        //无参构造函数
        this.Visiable = false;
    }

    [proName: string]: any;
    /**
     * 待核销
     */
    NoSettlementPrice?: number;
    /**
     * 剩余
     */
    SurplusPrice?: number;
    /**
     * 账单币制
     */
    BillCurrencySystemId?: string;
    /**
     * 账单币制
     */
    BillCurrencySystemCode?: string;
    /**
     * 账单币制
     */
    BillCurrencySystem?: DtoListItemFinance_ParamCurrencySystem;
    /**
     * 核销主键
     */
    Id?: string;
    /**
     * 本次核销金额
     */
    Price?: number;
    /**
     * 折算金额
     */
    ConvertPrice?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoListItemFinance_ParamCurrencySystem;
    /**
     * 汇率
     */
    Rate?: number;
    /**
     * 汇率
     */
    DisPlayRate?: number;
    /**
     * 收款方发票抬头
     */
    CollectionTitle?: string;
    /**
     * 付款方发票抬头
     */
    PaymentTitle?: string;
    /**
     * 时间
     */
    SettlementDate?: Date;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 核销编号
     */
    Code?: string;
    /**
     * 核销方式
     */
    Mode?: EnumSettlementMode;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 当前时间
     */
    CurrentTime?: Date;
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 
     */
    Month?: string;
    /**
     * 
     */
    SelectMonth?: string;
    /**
     * 供选择的月份
     */
    Months?: DtoSelectMonth[];
}

/**
 * -表单校验
 */
export const DtoFormSalerSettlementRules = {
}
