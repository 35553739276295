
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";

    export default class MiezzCurrency extends Vue {
        //默认显示的币制。录入中文，比如默认显示美元，那该字段录入"美元"
        @Prop() defaultCurrency?: string;
        //需要兑成币制的货币名称。比如界面选择了美元，要显示美元兑人民币的汇率，那该字段录入“人民币”
        @Prop() defaultRate?: string;
        //是否显示汇率
        @Prop() isRate?: boolean;
        ruleForm: {
            Visible: boolean,
            SelectCurrencyId: string,
            Rate: string,
        } = {
                Visible: false,
                SelectCurrencyId: "",
                Rate: "",
            };

        rateCurrencyId = "";
        rateCurrencyCode = "";
        isSame = false;

        //币制
        currencys: UiSelectOption<string>[] = [];
        bottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                IsBlue:true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                //Icon:"CloseBold",
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //created() {
        //    let that = this;
        //    //alert(this.isRate);
        //}
        /*初始化 */
        @Emit()
        onInit(currencyId: string): void {
            this.rateCurrencyId = "";
            this.rateCurrencyCode = "";
            this.isSame = false;
            if (currencyId == undefined) {
                currencyId = "";
            }
            ParamCurrencySystemAppService.GetSelect().then(currency => {
                if (currency.data.Data) this.currencys = currency.data.Data;
                if (currencyId == "" && this.defaultCurrency != undefined && this.defaultCurrency != "") {
                    var defaultCurrency = this.defaultCurrency.toUpperCase();
                    for (var it of this.currencys) {
                        if (it.Label) {
                            if (it.Label?.toUpperCase().indexOf(defaultCurrency) >= 0 && it.Value) {
                                currencyId = it.Value as string;
                                break;
                            }
                        }
                    }
                }
                if (this.isRate == true && this.defaultRate && this.defaultRate!="") {
                    for (var it1 of this.currencys) {
                        if (it1.Label) {
                            if (it1.Label?.toUpperCase().indexOf(this.defaultRate) >= 0 && it1.Value) {
                                this.rateCurrencyId = it1.Value;
                                this.rateCurrencyCode = it1.Data.Code;
                            }
                        }
                    }
                }


                this.ruleForm.SelectCurrencyId = currencyId;
                this.ruleForm.Visible = true;

                if (this.rateCurrencyId == currencyId) {
                    this.isSame = true;
                }
                else if (this.isRate == true) {
                    this.onChangeCurrencyId();
                }

            })
        }
        onChangeCurrencyId(): void {
            this.isSame = false;
            if (this.isRate == true && this.rateCurrencyCode && this.ruleForm.SelectCurrencyId != this.rateCurrencyId) {
                var currencyCode = "";
                for (var it of this.currencys) {
                    if (it.Value == this.ruleForm.SelectCurrencyId) {
                        currencyCode = it.Data.Code;
                        break;
                    }
                }

                ParamCurrencySystemAppService.GetExchangeRateByFromByTo(currencyCode, this.rateCurrencyCode).then(cur => {
                    if (cur.data.Data) this.ruleForm.Rate = cur.data.Data;
                })
            }
            else if (this.ruleForm.SelectCurrencyId == this.rateCurrencyId) {
                this.isSame = true;
            }
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    for (var it of this.currencys) {
                        if (it.Value == this.ruleForm.SelectCurrencyId) {
                            this.$emit("onSelectCurrency", it);
                            break;
                        }
                    }
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }
