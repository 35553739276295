
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoFormCompanyBusinessCard, {
  DtoFormCompanyBusinessCardMiezzFormItems,
  DtoFormCompanyBusinessCardRules,
} from "@/models.machine/DtoFormCompanyBusinessCard";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import MiezzModal from "@/models/MiezzModal";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamCompanyCodeTypeAppService from "@/apis.machine/ParamCompanyCodeTypeAppService";
import DtoRequestCompanyBusinessCardCheckExist from "@/models.machine/DtoRequestCompanyBusinessCardCheckExist";
import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";

export default class CompanyBusinessCardForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() companyId?: string;
  @Prop() parentId?: string;
  @Prop() area?: EnumCompanyBusinessCardArea;
  @Prop() type?: EnumCompanyBusinessCardType;
  @Prop() content?: string;

  form = new MiezzForm<DtoFormCompanyBusinessCard, string>();
  companyCodeTypes: UiSelectOption<string> = [];

  created(): void {
    this.form.DefaultSpan = 12;
    this.modelValue.Width = "80%";
    this.form.Data = new DtoFormCompanyBusinessCard();
    this.form.Data.CompanyId = this.companyId;
    this.form.Data.ParentId = this.parentId;
    this.form.Data.Area = this.area;
    this.form.Data.Type = this.type;
    if (this.content) {
      this.form.Data.Content = this.content;
    }
    //表单-校验
    this.form.Rules = Object.copy(DtoFormCompanyBusinessCardRules);
    this.form.Items = DtoFormCompanyBusinessCardMiezzFormItems.copy();

    if (this.area == EnumCompanyBusinessCardArea.Domestic) {
      this.form.Rules.NameZh.push(this.getCheckExistRule("NameZh"));
    } else {
      this.form.Rules.NameEn.push(this.getCheckExistRule("NameEn"));
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [];
    this.modelValue.Buttons?.push({
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
      Width: "100px",
    });
    if (this.area == EnumCompanyBusinessCardArea.Domestic) {
      this.modelValue.Buttons?.push({
        Code: "import",
        Label: "从基础信息导入",
        Type: "primary",
        Size: "small",
        Width: "100px",
      });
    }
    this.modelValue.Buttons?.push({
      Code: "preview",
      Label: "预览",
      Type: "primary",
      Size: "small",
      Width: "100px",
    });

    ParamCompanyCodeTypeAppService.GetSelect().then((r) => {
      this.companyCodeTypes = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      CompanyBusinessCardAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          if (this.content) {
            this.form.Data.Content = this.content;
          }
        }
      });
    }
  }

  getCheckExistRule(prop: string): any {
    return {
      required: true,
      message: "信息不能为空",
      trigger: "blur",
    };
    // return {
    //   required: true,
    //   validator: (rule: any, value: string, callback: any) => {
    //     if (!value) return callback(new Error("信息不能为空"));
    //     else {
    //       const dto = new DtoRequestCompanyBusinessCardCheckExist();
    //       dto.Id = this.form.Data.Id;
    //       dto.CompanyId = this.form.Data.CompanyId;
    //       dto.Area = this.form.Data.Area;
    //       dto.Type = this.form.Data.Type;
    //       dto.ParentId = this.form.Data.ParentId;
    //       dto.Prop = prop;
    //       dto.PropValue = value;
    //       CompanyBusinessCardAppService.CheckExist(dto).then((r) => {
    //         if (r.data.Data) return callback(new Error("信息重复，请重新填写"));
    //         else callback();
    //       });
    //     }
    //   },
    //   trigger: "blur",
    // };
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyBusinessCardAppService.Post(this.form.Data)
              .then((r) => {
                this.form.Data.Id = r.data.Data;
                this.handleSubmit(this.form.Data);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "import":
        CompanyAppService.Get(this.companyId).then((r) => {
          if (r.data.Data) {
            this.form.Data.NameZh = r.data.Data.NameZh;
            this.form.Data.NameEn = r.data.Data.NameEn;
            this.form.Data.ParamCompanyCodeTypeId =
              r.data.Data.ParamCompanyCodeTypeId;
            this.form.Data.Code = r.data.Data.Code;
            this.form.Data.CustomsCode = r.data.Data.CustomsCode;
            this.form.Data.ReceivingAddressEn = r.data.Data.ReceivingAddressEn;
            this.form.Data.Tel = r.data.Data.Tel;
            this.form.Data.Fax = r.data.Data.Fax;
            this.form.Data.Email = r.data.Data.Email;
          }
          complete();
        });
        break;
      case "preview":
        this.form.Data.Content = `${this.form.Data.NameEn ?? ""}\n${
          this.form.Data.ReceivingAddressEn ?? ""
        }\nTEL：${this.form.Data.Tel ?? ""}\nFAX：${
          this.form.Data.Fax ?? ""
        }\nEMAIL：${this.form.Data.Email ?? ""}`;
        complete();
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(data: DtoFormCompanyBusinessCard): void {
    //
  }
}
