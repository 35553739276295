import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!

  return (_openBlock(), _createBlock(_component_miezz_menu_tab, {
    ref: "miezz-menu-tab",
    modelValue: _ctx.menuTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
  }, null, 8, ["modelValue"]))
}