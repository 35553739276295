import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate from "./DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyLadingBillTemplate extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.LadingBillTemplates = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 提单模板
     */
    LadingBillTemplates?: DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate[];
    /**
     * 签名图片
     */
    SignImg?: string;
}

/**
 * -表单校验
 */
export const DtoFormCompanyLadingBillTemplateRules = {
}

/**
 * -设置项
 */
export const DtoFormCompanyLadingBillTemplateMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "LadingBillTemplates",
    Label: "提单模板",
    Customize: true,
  },
  {
    Prop: "SignImg",
    Label: "签名图片",
    Customize: true,
  },
]
