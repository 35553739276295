
import { ref } from "vue";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzTextEllipsis extends Vue {
  tooltipFlag = false;
  @Prop() text?: string;
  @Prop() miezzIcon?: string;
  @Prop() className?: string;
  @Prop() tooltip?: boolean;

  visibilityChange(e:any): void {
    const ev = e.target
    const evWidth = ev.offsetWidth
    if (this.className) {
      const tempDom = document.getElementsByClassName(this.className) as any;
      if (tempDom && tempDom.length > 0) {
        const contentWidth = tempDom[0].offsetWidth;
        this.tooltipFlag = evWidth < contentWidth;
      }
    }
    
  }
}
