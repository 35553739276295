import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumUiDynamicSearchItemType, EnumUiDynamicSearchItemTypeOption } from "./EnumUiDynamicSearchItemType";
import { EnumCompare, EnumCompareOption } from "./EnumCompare";

/**
 * 分页列表搜索条件-动态搜索-项
 * @description 自动生成的代码,请勿修改
 */
export default class UiPageListSearch_DynamicSearchItem {
    constructor() {
        //无参构造函数
        this.Type = EnumUiDynamicSearchItemType.InputText;
    }

    [proName: string]: any;
    /**
     * 绑定字段
     */
    Prop?: string;
    /**
     * 标签
     */
    Label?: string;
    /**
     * 类型
     */
    Type?: EnumUiDynamicSearchItemType;
    /**
     * 比较
     */
    Compares?: EnumCompare[];
}

/**
 * 分页列表搜索条件-动态搜索-项-表单校验
 */
export const UiPageListSearch_DynamicSearchItemRules = {
    Prop: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Label: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 分页列表搜索条件-动态搜索-项-设置项
 */
export const UiPageListSearch_DynamicSearchItemMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Prop",
    Label: "绑定字段",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Label",
    Label: "标签",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumUiDynamicSearchItemTypeOption,
  },
  {
    Prop: "Compares",
    Label: "比较",
    Type: EnumMiezzFormItemType.Select,
    Multiple: true,
    Options: EnumCompareOption,
  },
]
