import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 中文包装种类表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPackageZh extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 包装类型（中）
     */
    Name?: string;
    /**
     * 包装类型（英）
     */
    PackageEnId?: string;
    /**
     * 中国海关代码
     */
    Code?: string;
}

/**
 * 中文包装种类表单-表单校验
 */
export const DtoFormPackageZhRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 中文包装种类表单-设置项
 */
export const DtoFormPackageZhMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "包装类型（中）",
    Customize: true,
  },
  {
    Prop: "PackageEnId",
    Label: "包装类型（英）",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "中国海关代码",
    Type: EnumMiezzFormItemType.InputText,
  },
]
