import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheet_Cargo extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
    }

    [proName: string]: any;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * L/φ（长度（最长边）/直径）（cm）
     */
    Length?: number;
    /**
     * W/φ（宽度（第二长边）/直径）（cm）
     */
    Width?: number;
    /**
     * H（高度/最短边）（cm）
     */
    Height?: number;
    /**
     * 单件毛重（kgs）
     */
    GrossWeight?: number;
    /**
     * 单件体积(m³)
     */
    Volume?: number;
    /**
     * 包装数量
     */
    Quantity?: number;
    /**
     * 包装单位
     */
    ChargeUnitEn?: string;
}

/**
 * -表单校验
 */
export const DtoDetailPriceQuotationSheet_CargoRules = {
}

/**
 * -设置项
 */
export const DtoDetailPriceQuotationSheet_CargoMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ProductName",
    Label: "产品名称",
  },
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重（kgs）",
  },
  {
    Prop: "Volume",
    Label: "单件体积(m³)",
  },
  {
    Prop: "Quantity",
    Label: "包装数量",
  },
  {
    Prop: "ChargeUnitEn",
    Label: "包装单位",
  },
]

/**
 * -设置项
 */
export const DtoDetailPriceQuotationSheet_CargoMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ProductName",
    Label: "产品名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重（kgs）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Volume",
    Label: "单件体积(m³)",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Quantity",
    Label: "包装数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ChargeUnitEn",
    Label: "包装单位",
    Type: EnumMiezzFormItemType.Text,
  },
]
