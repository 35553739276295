
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ProductSpecElementAppService from "@/apis.machine/ProductSpecElementAppService";
import DtoDetailProductSpecElement, {
  DtoDetailProductSpecElementMiezzDetailItems,
} from "@/models.machine/DtoDetailProductSpecElement";
import ProductSpecElementForm from "./ProductSpecElementForm.vue";

    @Options({
        components: {
            ProductSpecElementForm,
        },
    })
    export default class ProductSpecElementDetail extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() id?: string;

        detail = new MiezzForm<DtoDetailProductSpecElement, string>();
        modalForm = new MiezzModal();

        /**创建时 */
        created(): void {
            //详细-数据-初始化
            this.detail.Data = new DtoDetailProductSpecElement();
            //详细-元素配置
            this.detail.Items = DtoDetailProductSpecElementMiezzDetailItems;
            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "edit",
                    Title: "编辑",
                    Label: "编辑",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_Edit",
                },
                {
                    Code: "delete",
                    Title: "删除",
                    Label:"删除",
                    Type: "default",
                    Message: "您确定要删除选中的信息吗？删除该要素信息会影响其他规格型号的选择，是否继续?",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_Delete"
                },
                {
                    Code: "close",
                    Title: "关闭",
                    Label: "关闭",
                    Type: "default",
                    Size: "small",
                },
            ];
            this.handleGet();
        }

        /**查询详细 */
        handleGet(): void {
            if (this.id) {
                ProductSpecElementAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.detail.Data = r.data.Data;
                });
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "edit":
                    this.modalForm.Show = true;
                    this.modalForm.Title = `编辑规格要素-${this.detail.Data.Name}`;
                    complete();
                    break;
                case "delete":
                    ProductSpecElementAppService.Delete([this.id as string])
                        .then(() => {
                            this.handleDelete();
                            complete();
                        })
                        .catch(complete);
                    break;
                case "close":
                    this.modelValue.Show = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }
    }
