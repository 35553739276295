import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoHistoryCompanyAddress_CompanyName from "../models.machine/DtoHistoryCompanyAddress_CompanyName";
import DtoHistoryCompanyAddress_Area from "../models.machine/DtoHistoryCompanyAddress_Area";
import DtoRequestCompanyAddressContact from "../models.machine/DtoRequestCompanyAddressContact";
import DtoHistoryCompanyAddress_Contact from "../models.machine/DtoHistoryCompanyAddress_Contact";

/**
 * 公司地址
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyAddressAppService {
    /**
     * 获取公司名称信息
     * @param {string} [keywords] 关键字
     * @returns {AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_CompanyName[]>>} 公司名称信息
     */
    static GetCompanyNames(keywords?: string): AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_CompanyName[]>> {
        const url = `/api/CompanyAddress/GetCompanyNames`;
        const _querys: any = {};
        _querys.keywords = keywords
        return service.get<RESTfulResult<DtoHistoryCompanyAddress_CompanyName[]>>(url, { params: _querys });
    }

    /**
     * 根据地区行政代码和经纬度获取地区信息
     * @param {string} [code] 地区行政代码
     * @param {number} [longitude] 经度
     * @param {number} [latitude] 维度
     * @returns {AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_Area>>} 地区信息
     */
    static GetAreaByCode(code?: string, longitude?: number, latitude?: number): AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_Area>> {
        const url = `/api/CompanyAddress/GetArea/${code}`;
        const _querys: any = {};
        _querys.longitude = longitude
        _querys.latitude = latitude
        return service.get<RESTfulResult<DtoHistoryCompanyAddress_Area>>(url, { params: _querys });
    }

    /**
     * 获取联系人信息
     * @param {DtoRequestCompanyAddressContact} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_Contact[]>>} 联系人信息
     */
    static GetContacts(dto?: DtoRequestCompanyAddressContact): AxiosPromise<RESTfulResult<DtoHistoryCompanyAddress_Contact[]>> {
        const url = `/api/CompanyAddress/GetContacts`;
        return service.get<RESTfulResult<DtoHistoryCompanyAddress_Contact[]>>(url, { params: dto });
    }

    /**
     * 删除联系人
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteContactById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyAddress/DeleteContact/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

}
