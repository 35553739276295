import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_audit_log_detail = _resolveComponent("audit-log-detail")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleRowButtonClick: _ctx.handleRowButtonClick
    }, {
      "right-search": _withCtx(() => [
        (_ctx.list.Dto)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.list.Dto.SiteId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list.Dto.SiteId) = $event)),
                options: _ctx.sites,
                onChange: _ctx.handleGet,
                style: {"width":"140px"}
              }, null, 8, ["modelValue", "options", "onChange"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleRowButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDetail,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalDetail) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_audit_log_detail, {
          modelValue: _ctx.modalDetail,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDetail) = $event)),
          id: _ctx.id
        }, null, 8, ["modelValue", "id"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}