import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";
import DtoFormPrice_PriceFieldValue from "./DtoFormPrice_PriceFieldValue";
import UiGroup from "./UiGroup";

/**
 * 价格表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPrice extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.FieldValues = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 场景
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 供应商Id
     */
    SupplierId?: string;
    /**
     * 价格表Id
     */
    TableId?: string;
    /**
     * 要素值
     */
    FieldValues?: DtoFormPrice_PriceFieldValue[];
}

/**
 * 价格表单-表单校验
 */
export const DtoFormPriceRules = {
}

/**
 * 价格表单-设置项
 */
export const DtoFormPriceMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "AmountType",
    Label: "场景",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumPriceAmountTypeOption,
  },
  {
    Prop: "FieldValues",
    Label: "要素值",
    Type: EnumMiezzFormItemType.InputText,
  },
]
