import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchMenu from "../models.machine/DtoPageListSearchMenu";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListMenu from "../models.machine/DtoPageListMenu";
import DtoDetailMenu from "../models.machine/DtoDetailMenu";
import DtoFormMenu from "../models.machine/DtoFormMenu";
import DtoTreeSearchMenu from "../models.machine/DtoTreeSearchMenu";
import DtoTreeMenu from "../models.machine/DtoTreeMenu";
import UiTreeDrag from "../models.machine/UiTreeDrag";
import DtoListLogierMenu from "../models.machine/DtoListLogierMenu";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoListItemMenuTypeField from "../models.machine/DtoListItemMenuTypeField";
import DtoListSearchMenuField from "../models.machine/DtoListSearchMenuField";
import DtoListMenuField from "../models.machine/DtoListMenuField";
import DtoCallbackMenuGetReferencedInvalidWebApi from "../models.machine/DtoCallbackMenuGetReferencedInvalidWebApi";

/**
 * 菜单
 * @description 自动生成的代码,请勿修改
 */
export default class MenuAppService {
    /**
     * 获取菜单分页列表
     * @param {DtoPageListSearchMenu} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListMenu>>} 
     */
    static Get(dto?: DtoPageListSearchMenu): AxiosPromise<RESTfulResult<DtoPageListMenu>> {
        const url = `/api/Menu/Get`;
        return service.get<RESTfulResult<DtoPageListMenu>>(url, { params: dto });
    }

    /**
     * 获取菜单详细
     * @param {string} [id] 菜单Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailMenu>>} 菜单详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailMenu>> {
        const url = `/api/Menu/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailMenu>>(url);
    }

    /**
     * 添加菜单
     * @param {DtoFormMenu} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Post(dto?: DtoFormMenu): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Menu/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 删除菜单
     * @param {string[]} [ids] 菜单Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Menu/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取树
     * @param {DtoTreeSearchMenu} [search] 菜单树-搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoTreeMenu[]>>} 菜单树
     */
    static GetTree(search?: DtoTreeSearchMenu): AxiosPromise<RESTfulResult<DtoTreeMenu[]>> {
        const url = `/api/Menu/GetTree`;
        return service.post<RESTfulResult<DtoTreeMenu[]>>(url, search);
    }

    /**
     * 拖曳排序
     * @param {UiTreeDrag<string>} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiTreeDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Menu/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取登录用户菜单
     * @returns {AxiosPromise<RESTfulResult<DtoListLogierMenu>>} 登录用户菜单
     */
    static GetLogierMenus(): AxiosPromise<RESTfulResult<DtoListLogierMenu>> {
        const url = `/api/Menu/GetLogierMenus`;
        return service.get<RESTfulResult<DtoListLogierMenu>>(url);
    }

    /**
     * 获取类型选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetTypeSelect(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Menu/GetTypeSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 导入字段
     * @param {string} [name] 名称
     * @returns {AxiosPromise<RESTfulResult<DtoListItemMenuTypeField[]>>} 结果
     */
    static ImportFieldsByName(name?: string): AxiosPromise<RESTfulResult<DtoListItemMenuTypeField[]>> {
        const url = `/api/Menu/ImportFields/${name}`;
        return service.post<RESTfulResult<DtoListItemMenuTypeField[]>>(url);
    }

    /**
     * 获取字段
     * @param {DtoListSearchMenuField} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoListMenuField>>} 结果
     */
    static GetFields(dto?: DtoListSearchMenuField): AxiosPromise<RESTfulResult<DtoListMenuField>> {
        const url = `/api/Menu/GetFields`;
        return service.get<RESTfulResult<DtoListMenuField>>(url, { params: dto });
    }

    /**
     * 获取已引用的失效接口
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackMenuGetReferencedInvalidWebApi>>} 结果
     */
    static GetReferencedInvalidWebApi(): AxiosPromise<RESTfulResult<DtoCallbackMenuGetReferencedInvalidWebApi>> {
        const url = `/api/Menu/GetReferencedInvalidWebApi`;
        return service.get<RESTfulResult<DtoCallbackMenuGetReferencedInvalidWebApi>>(url);
    }

    /**
     * 修复失效接口
     * @param {string} [invalidId] 失效的接口Id
     * @param {string} [id] 接口Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RepairInvalidWebApiByInvalidId(invalidId?: string, id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Menu/RepairInvalidWebApi/${invalidId}`;
        const _querys: any = {};
        _querys.id = id
        return service.post<RESTfulResult<any>>(url, undefined, { params: _querys });
    }

    /**
     * 复制
     * @param {string} [id] 菜单Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CopyById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Menu/Copy/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

}
