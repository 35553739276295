
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class UiTreeNode<TDto, TKey> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * Id
     */
    Id?: TKey;
    /**
     * 父Id
     */
    ParentId?: TKey;
    /**
     * 子
     */
    Children?: TDto[];
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 勾选
     */
    Check?: boolean;
}

/**
 * -表单校验
 */
export const UiTreeNodeRules = {
}
