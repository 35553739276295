import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2715d06e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_price_search = _resolveComponent("price-search")!

  return (_openBlock(), _createBlock(_component_price_search, { onHandleRefresh: _ctx.handleRefresh }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.detail,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.detail) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'Files')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: item.Prop,
                label: item.Label,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  (_ctx.detail.Data.Files && _ctx.detail.Data.Files.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_carousel, {
                        key: 0,
                        interval: 5000,
                        arrow: "always",
                        loop: false
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data.Files, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_image, {
                                  style: {"width":"100%","height":"100%"},
                                  src: item.File,
                                  fit: _ctx.fit
                                }, null, 8, ["src", "fit"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024))
                    : (_openBlock(), _createElementBlock("span", _hoisted_1, "暂无图片"))
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (item.Prop == 'Note')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.detail.Data.Note ?? "暂无数据"), 1)
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : (item.Prop == 'TransportDirection')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_el_form_item, {
                      prop: item.Prop,
                      label: item.Label
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.type), 1)
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"])
                  ]))
                : (item.Prop == 'DeparturePortBooking')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 3,
                      prop: item.Prop,
                      label: item.Label,
                      class: "services-check-group"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.detail.Data.DeparturePortBooking,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.DeparturePortBooking) = $event)),
                          label: "订舱",
                          disabled: true
                        }, null, 8, ["modelValue"]),
                        (_ctx.detail.Data.DeparturePortBooking)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              label: "是否签发提单"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.detail.Data.DeparturePortLadingBill ? "是" : "否"), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.detail.Data.DeparturePortTake,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.DeparturePortTake) = $event)),
                          label: "提/送",
                          disabled: true
                        }, null, 8, ["modelValue"]),
                        (_ctx.detail.Data.DeparturePortTake)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 2,
                              label: "方式",
                              prop: "DeparturePortTakeMethod"
                            }, {
                              default: _withCtx(() => [
                                (
                _ctx.detail.Data.TransportContainerLoad ==
                _ctx.EnumTransportContainerLoad.FCL
              )
                                  ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                      key: 0,
                                      label: "集装箱拖车",
                                      modelValue: _ctx.detail.Data.DeparturePortContainer,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail.Data.DeparturePortContainer) = $event)),
                                      disabled: true
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_el_checkbox, {
                                  label: "干线物流",
                                  modelValue: _ctx.detail.Data.DeparturePortTrunk,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detail.Data.DeparturePortTrunk) = $event)),
                                  disabled: true
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_el_checkbox, {
                                  label: "快递",
                                  modelValue: _ctx.detail.Data.DeparturePortExpress,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detail.Data.DeparturePortExpress) = $event)),
                                  disabled: true
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, { key: 3 })),
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.detail.Data.DeparturePortWarehouse,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detail.Data.DeparturePortWarehouse) = $event)),
                          label: "仓库/堆场/码头",
                          disabled: true
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_el_form_item),
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.detail.Data.DeparturePortDeclareCustoms,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.detail.Data.DeparturePortDeclareCustoms) = $event)),
                          label: "报关",
                          disabled: true
                        }, null, 8, ["modelValue"]),
                        (
              _ctx.detail.Data.DeparturePortDeclareCustoms &&
              _ctx.detail.Data.TransportDirection != _ctx.EnumTransportDirection.Import
            )
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 4,
                              label: "是否退税"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.detail.Data.DeparturePortDrawback ? "是" : "否"), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 }))
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"]))
                  : (item.Prop == 'DestinationPortClearCustoms')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 4,
                        prop: item.Prop,
                        label: item.Label,
                        class: "services-check-group"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_checkbox, {
                            modelValue: _ctx.detail.Data.DestinationPortClearCustoms,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.detail.Data.DestinationPortClearCustoms) = $event)),
                            label: "清关",
                            disabled: true
                          }, null, 8, ["modelValue"]),
                          (_ctx.detail.Data.DestinationPortClearCustoms)
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 0,
                                label: "清关抬头"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.destinationPortCustomsClearanceHeaderProvider), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                          (
              _ctx.detail.Data.DestinationPortClearCustoms &&
              _ctx.detail.Data.DestinationPortCustomsClearanceHeaderProvider ==
                _ctx.EnumCustomsClearanceHeaderProvider.ServiceProvider
            )
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 2,
                                label: "是否出具税单",
                                style: {"margin-left":"172px","margin-top":"5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.detail.Data.DestinationPortCustomsClearanceIssueTaxReceipt
                ? "是"
                : "否"), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 3,
                                style: {"margin-left":"172px"}
                              })),
                          _createVNode(_component_el_checkbox, {
                            modelValue: _ctx.detail.Data.DestinationPortDelivery,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.detail.Data.DestinationPortDelivery) = $event)),
                            label: "提/送",
                            disabled: true
                          }, null, 8, ["modelValue"]),
                          (_ctx.detail.Data.DestinationPortDelivery)
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 4,
                                prop: "DestinationPortDeliveryMethod",
                                label: "方式"
                              }, {
                                default: _withCtx(() => [
                                  (
                _ctx.detail.Data.TransportType != _ctx.EnumTransportType.Air &&
                _ctx.detail.Data.TransportContainerLoad !=
                  _ctx.EnumTransportContainerLoad.Bulk
              )
                                    ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                        key: 0,
                                        label: "集装箱拖车",
                                        modelValue: _ctx.detail.Data.DestinationPortContainer,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.detail.Data.DestinationPortContainer) = $event)),
                                        disabled: true
                                      }, null, 8, ["modelValue"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_el_checkbox, {
                                    label: "干线物流",
                                    modelValue: _ctx.detail.Data.DestinationPortTrunk,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.detail.Data.DestinationPortTrunk) = $event)),
                                    disabled: true
                                  }, null, 8, ["modelValue"]),
                                  _createVNode(_component_el_checkbox, {
                                    label: "快递",
                                    modelValue: _ctx.detail.Data.DestinationPortExpress,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.detail.Data.DestinationPortExpress) = $event)),
                                    disabled: true
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 })),
                          _createVNode(_component_el_checkbox, {
                            modelValue: _ctx.detail.Data.DestinationPortWarehouse,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.detail.Data.DestinationPortWarehouse) = $event)),
                            label: "仓库/堆场/码头",
                            disabled: true
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop", "label"]))
                    : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["onHandleRefresh"]))
}