
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzDynamicSearchMonthRange extends Vue {
  @Model() modelValue?: string;
  range?: string[] = [];

  created(): void {
    this.range = this.modelValue?.split(",");
  }

  handleChange(): void {
    const modelValue = this.range?.join(",");
    this.$emit("update:modelValue", modelValue);
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelValue) {
      this.range = this.modelValue?.split(",");
    } else {
      this.range = [];
    }
  }
}
