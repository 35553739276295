import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailParamCountryArea_ParamCountry from "./DtoDetailParamCountryArea_ParamCountry";
import DtoDetailParamCountryArea_ParamArea from "./DtoDetailParamCountryArea_ParamArea";
import UiGroup from "./UiGroup";

/**
 * 地区详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamCountryArea extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Deep = 0;
    }

    [proName: string]: any;
    /**
     * 国家Id
     */
    ParamCountryId?: string;
    /**
     * 国家
     */
    ParamCountry?: DtoDetailParamCountryArea_ParamCountry;
    /**
     * 地区级别
     */
    AreaLevel?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 深度
     */
    Deep?: number;
    /**
     * 
     */
    AreaId1?: string;
    /**
     * 
     */
    AreaId2?: string;
    /**
     * 
     */
    AreaId3?: string;
    /**
     * 
     */
    AreaId4?: string;
    /**
     * 地区-1
     */
    Area1?: DtoDetailParamCountryArea_ParamArea;
    /**
     * 地区-2
     */
    Area2?: DtoDetailParamCountryArea_ParamArea;
    /**
     * 地区-3
     */
    Area3?: DtoDetailParamCountryArea_ParamArea;
    /**
     * 地区-4
     */
    Area4?: DtoDetailParamCountryArea_ParamArea;
    /**
     * 所属人
     */
    AffiliatedPerson?: string;
    /**
     * 详细地址
     */
    DetailedAddress?: string;
}

/**
 * 地区详细-表单校验
 */
export const DtoDetailParamCountryAreaRules = {
}

/**
 * 地区详细-设置项
 */
export const DtoDetailParamCountryAreaMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家Id",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "AreaLevel",
    Label: "地区级别",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Abridge",
    Label: "缩写",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Deep",
    Label: "深度",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AreaId1",
    Label: "",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AreaId2",
    Label: "",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AreaId3",
    Label: "",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AreaId4",
    Label: "",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Area1",
    Label: "地区-1",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Area2",
    Label: "地区-2",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Area3",
    Label: "地区-3",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Area4",
    Label: "地区-4",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AffiliatedPerson",
    Label: "所属人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DetailedAddress",
    Label: "详细地址",
    Type: EnumMiezzFormItemType.Text,
  },
]
