import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_company_business_card_bank_form = _resolveComponent("company-business-card-bank-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick
    }, {
      column: _withCtx(({ model, row }) => [
        (model.Prop == 'CompanyBusinessCardBankAccounts')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: row.CompanyBusinessCardBankAccountId,
              "onUpdate:modelValue": ($event: any) => ((row.CompanyBusinessCardBankAccountId) = $event),
              options: row.CompanyBusinessCardBankAccounts,
              clearable: false,
              labelKey: "ParamCurrencySystemCode/Account",
              valueKey: "Id",
              onHandleChange: ($event: any) => (
          _ctx.handleSetDefaultAccount(row.Id, row.CompanyBusinessCardBankAccountId)
        )
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onHandleChange"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_company_business_card_bank_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          businessCardId: _ctx.businessCardId,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "businessCardId", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}