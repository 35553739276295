import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_choose_power = _resolveComponent("choose-power")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_role_form = _resolveComponent("role-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'JoinCompanyTypeRoles')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data[item.Prop], (companyType, index) => {
                  return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(companyType.CompanyType.Name) + _toDisplayString(index != _ctx.detail.Data[item.Prop].length - 1 ? "，" : ""), 1))
                }), 128))
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true),
        (item.Prop == 'JoinRolePowers')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: "权限",
              prop: "JoinRolePowers"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_choose_power, {
                  modelValue: _ctx.detail.Data.PowerIds,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.PowerIds) = $event)),
                  tree: _ctx.tree,
                  showCheck: false
                }, null, 8, ["modelValue", "tree"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_role_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}