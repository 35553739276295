import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRquestPriceSurchargeGroupRemark extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoRquestPriceSurchargeGroupRemarkRules = {
    Remark: [
        {
            max: 10,
            message: "文本长度不能超过 10 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoRquestPriceSurchargeGroupRemarkMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.InputText,
  },
]
