import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c8eac9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "drawer-scrollbar" }
const _hoisted_3 = { style: {"margin":"0px 10px"} }
const _hoisted_4 = {
  key: 0,
  class: "buttonClass"
}
const _hoisted_5 = {
  key: 1,
  class: "buttonClass"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  style: {"margin-left":"10px","font-size":"16px","color":"#f56c6c"}
}
const _hoisted_8 = {
  key: 0,
  class: "buttonClass"
}
const _hoisted_9 = {
  key: 1,
  class: "buttonClass"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.modelValue.Type == _ctx.MiezzModalType.Drawer)
          ? (_openBlock(), _createBlock(_component_el_drawer, {
              key: 0,
              modelValue: _ctx.modelValue.Show,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.Show) = $event)),
              title: _ctx.modelValue.Title,
              "destroy-on-close": true,
              "before-close": _ctx.handleClose,
              size: _ctx.modelValue.Width
            }, {
              default: _withCtx(() => [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                      ])
                    ]),
                    _: 3
                  }),
                  (_ctx.modelValue?.Buttons && _ctx.modelValue.Buttons.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.Buttons, (item, i) => {
                          return (_openBlock(), _createBlock(_component_miezz_button, {
                            style: _normalizeStyle(`width: ${
              _ctx.modelValue.ButtonWidth
                ? _ctx.modelValue.ButtonWidth
                : item.Width
                ? item.Width
                : '65px'
            };`),
                            key: i,
                            model: item,
                            onHandleClick: _ctx.handleClick
                          }, null, 8, ["style", "model", "onHandleClick"]))
                        }), 128)),
                        (!_ctx.modelValue.DisabledCloseButton)
                          ? (_openBlock(), _createBlock(_component_miezz_button, {
                              key: 0,
                              style: _normalizeStyle(`width: ${
              _ctx.modelValue.ButtonWidth ? _ctx.modelValue.ButtonWidth : '65px'
            };`),
                              model: _ctx.closeBtn,
                              onHandleClick: _ctx.handleClick
                            }, null, 8, ["style", "model", "onHandleClick"]))
                          : _createCommentVNode("", true)
                      ]))
                    : (!_ctx.modelValue.DisabledCloseButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_miezz_button, {
                            style: {"width":"65px"},
                            model: _ctx.closeBtn,
                            onHandleClick: _ctx.handleClick
                          }, null, 8, ["model", "onHandleClick"])
                        ]))
                      : _createCommentVNode("", true)
                ])), [
                  [_directive_loading, _ctx.modelValue.Loading]
                ])
              ]),
              _: 3
            }, 8, ["modelValue", "title", "before-close", "size"]))
          : (_openBlock(), _createBlock(_component_el_dialog, {
              key: 1,
              modelValue: _ctx.modelValue.Show,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.Show) = $event)),
              title: _ctx.modelValue.Title,
              "destroy-on-close": true,
              "before-close": _ctx.handleClose,
              width: _ctx.modelValue.Width,
              "align-center": _ctx.modelValue.AlignCenter,
              top: _ctx.modelValue.Top
            }, {
              header: _withCtx(() => [
                (_ctx.$slots['header'])
                  ? _renderSlot(_ctx.$slots, "header", { key: 0 }, undefined, true)
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.Title), 1),
                      (_ctx.modelValue.Description)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.modelValue.Description), 1))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              footer: _withCtx(() => [
                (_ctx.modelValue?.Buttons && _ctx.modelValue.Buttons.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.Buttons, (item, i) => {
                        return (_openBlock(), _createBlock(_component_miezz_button, {
                          style: _normalizeStyle(`width: ${
              _ctx.modelValue.ButtonWidth
                ? _ctx.modelValue.ButtonWidth
                : item.Width
                ? item.Width
                : '65px'
            };`),
                          key: i,
                          model: item,
                          onHandleClick: _ctx.handleClick
                        }, null, 8, ["style", "model", "onHandleClick"]))
                      }), 128)),
                      (!_ctx.modelValue.DisabledCloseButton)
                        ? (_openBlock(), _createBlock(_component_miezz_button, {
                            key: 0,
                            style: _normalizeStyle(`width: ${
              _ctx.modelValue.ButtonWidth ? _ctx.modelValue.ButtonWidth : '65px'
            };`),
                            model: _ctx.closeBtn,
                            onHandleClick: _ctx.handleClick
                          }, null, 8, ["style", "model", "onHandleClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  : (!_ctx.modelValue.DisabledCloseButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_miezz_button, {
                          style: {"width":"65px"},
                          model: _ctx.closeBtn,
                          onHandleClick: _ctx.handleClick
                        }, null, 8, ["model", "onHandleClick"])
                      ]))
                    : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", {
                  style: _normalizeStyle(`height: ${_ctx.modelValue.Height ?? '300px'}`)
                }, [
                  _createVNode(_component_el_scrollbar, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]),
                    _: 3
                  })
                ], 4)
              ]),
              _: 3
            }, 8, ["modelValue", "title", "before-close", "width", "align-center", "top"]))
      ]))
    : _createCommentVNode("", true)
}