import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailJoinCompanyUser_User from "./DtoDetailJoinCompanyUser_User";
import DtoDetailJoinCompanyUser_JoinCompanyUserGroup from "./DtoDetailJoinCompanyUser_JoinCompanyUserGroup";
import DtoDetailJoinCompanyUser_Company from "./DtoDetailJoinCompanyUser_Company";
import { EnumJoinCompanyUserStatus, EnumJoinCompanyUserStatusOption } from "./EnumJoinCompanyUserStatus";
import { EnumUserStatus, EnumUserStatusOption } from "./EnumUserStatus";
import DtoDetailJoinCompanyUser_JoinCompanyUserContact from "./DtoDetailJoinCompanyUser_JoinCompanyUserContact";
import DtoDetailJoinCompanyUser_JoinCompanyUserRole from "./DtoDetailJoinCompanyUser_JoinCompanyUserRole";
import UiGroup from "./UiGroup";

/**
 * 关联-公司/用户详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailJoinCompanyUser extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.JoinCompanyUserGroups = [];
        this.Level = 0;
        this.Status = EnumJoinCompanyUserStatus.Normal;
        this.JoinCompanyUserContacts = [];
        this.JoinCompanyUserRoles = [];
        this.UiGroups = [{"Code":"UserInfo","Label":"\u7528\u6237\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ContactNumber","Label":"\u8054\u7CFB\u65B9\u5F0F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"Role","Label":"\u804C\u80FD\u4E0E\u6743\u9650","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 真实姓名
     */
    Name?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 用户
     */
    User?: DtoDetailJoinCompanyUser_User;
    /**
     * 邀请码
     */
    InviteCode?: string;
    /**
     * 登录账号（手机号）
     */
    UserPhoneNumber?: string;
    /**
     * 部门
     */
    JoinCompanyUserGroups?: DtoDetailJoinCompanyUser_JoinCompanyUserGroup[];
    /**
     * 职务
     */
    Job?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 隶属公司
     */
    Company?: DtoDetailJoinCompanyUser_Company;
    /**
     * 员工状态
     */
    Status?: EnumJoinCompanyUserStatus;
    /**
     * 用户状态
     */
    UserStatus?: EnumUserStatus;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 
     */
    JoinCompanyUserContacts?: DtoDetailJoinCompanyUser_JoinCompanyUserContact[];
    /**
     * 
     */
    JoinCompanyUserRoles?: DtoDetailJoinCompanyUser_JoinCompanyUserRole[];
}

/**
 * 关联-公司/用户详细-表单校验
 */
export const DtoDetailJoinCompanyUserRules = {
}

/**
 * 关联-公司/用户详细-设置项
 */
export const DtoDetailJoinCompanyUserMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "真实姓名",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "InviteCode",
    Label: "邀请码",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "UserPhoneNumber",
    Label: "登录账号（手机号）",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "JoinCompanyUserGroups",
    Label: "部门",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Job",
    Label: "职务",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Level",
    Label: "职级",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Company",
    Label: "隶属公司",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Status",
    Label: "员工状态",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumJoinCompanyUserStatusOption,
  },
  {
    Prop: "UserStatus",
    Label: "用户状态",
    Group: "UserInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumUserStatusOption,
  },
  {
    Prop: "Avatar",
    Label: "头像",
    Group: "UserInfo",
    Span: 8,
    Customize: true,
  },
  {
    Prop: "JoinCompanyUserContacts",
    Label: "",
    Group: "ContactNumber",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "JoinCompanyUserRoles",
    Label: "",
    Group: "Role",
    Span: 24,
    Customize: true,
  },
]
