import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolChartDataSource {
    /**
     * 票数
     */
    Number = 1,
    /**
     * 毛重
     */
    Weight = 2,
    /**
     * 体积
     */
    Volume = 3,
}

/**
 * -选项
 */
export const EnumOrderPoolChartDataSourceOption: UiSelectOption<number>[] = [
    { Label: "票数", Value: 1, Data: "Number" },
    { Label: "毛重", Value: 2, Data: "Weight" },
    { Label: "体积", Value: 3, Data: "Volume" },
];
