
import { Vue, Options } from "vue-class-component";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import DtoPageListRole from "@/models.machine/DtoPageListRole";
import DtoPageListSearchCompanyCURPM, {
  DtoPageListSearchCompanyCURPMDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyCURPM";
import DtoPageListItemCompanyCURPM, {
  DtoPageListItemCompanyCURPMMiezzListItems,
} from "@/models.machine/DtoPageListItemCompanyCURPM";
import CompanyAppService from "@/apis.machine/CompanyAppService";

export default class CURPMPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyCURPM,
    DtoPageListItemCompanyCURPM,
    string
  >("System_Power_CURPM");
  id?: string;
  modalDetail = new MiezzModal();
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.list.Dto = new DtoPageListSearchCompanyCURPM();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyCURPMDynamicSearchItems;
    this.list.Data = new DtoPageListRole();
    this.list.Items = DtoPageListItemCompanyCURPMMiezzListItems;
  }

  /**查询列表 */
  handleGet(): void {
    CompanyAppService.GetCURPM(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }
}
