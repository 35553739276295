import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!

  return (_openBlock(), _createBlock(_component_el_checkbox_group, {
    modelValue: _ctx.values,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values) = $event)),
    onChange: _ctx.handleBeforeChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_el_checkbox, {
          key: item.Value,
          label: item.Value
        }, {
          default: _withCtx(() => [
            (_ctx.$slots['default'])
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "default", { model: item })
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.Label), 1))
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["modelValue", "onChange"]))
}