import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class UiForm<TKey> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 
     */
    Id?: TKey;
    /**
     * 分组
     */
    UiGroups?: UiGroup[];
}

/**
 * -表单校验
 */
export const UiFormRules = {
}
