import UiSelectOption from "./UiSelectOption";

/**
 * 添加报关资料的方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomsAnnexMode {
    /**
     * 手动添加
     */
    Custom = 10,
    /**
     * 系统生成
     */
    System = 20,
}

/**
 * 添加报关资料的方式-选项
 */
export const EnumCustomsAnnexModeOption: UiSelectOption<number>[] = [
    { Label: "手动添加", Value: 10, Data: "Custom" },
    { Label: "系统生成", Value: 20, Data: "System" },
];
