import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemParamFbaWarehouseCode_ParamCountry from "./DtoListItemParamFbaWarehouseCode_ParamCountry";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * FBA仓库代码分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamFbaWarehouseCode extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountry?: DtoListItemParamFbaWarehouseCode_ParamCountry;
    /**
     * 城市
     */
    City?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
}

/**
 * FBA仓库代码分页列表-项-表单校验
 */
export const DtoListItemParamFbaWarehouseCodeRules = {
}

/**
 * FBA仓库代码分页列表-项-设置项
 */
export const DtoListItemParamFbaWarehouseCodeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "City",
    Label: "城市",
  },
  {
    Prop: "Address",
    Label: "地址",
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
  },
  {
    Prop: "Code",
    Label: "代码",
  },
  {
    Prop: "Status",
    Label: "审核状态",
    Pipe: EnumPipe.Enum,
    Options: EnumParamAuditStatusOption,
  },
]
