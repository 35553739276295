import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoFormOrderDepartureWharfOutStockItem from "./DtoFormOrderDepartureWharfOutStockItem";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderDepartureWharfOutStock extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 合计件数-出库
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重-出库
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积-出库
     */
    SumVolumeOutStock?: number;
    /**
     * 计价单位-出库
     */
    ChargeUnitOutStock?: string;
    /**
     * 备注
     */
    RemarkOutStock?: string;
    /**
     * 出库
     */
    OutStocks?: DtoFormOrderDepartureWharfOutStockItem[];
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoFormOrderDepartureWharfOutStockRules = {
    OrderPoolId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    OrderDepartureWharfId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoFormOrderDepartureWharfOutStockMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OrderPoolId",
    Label: "委托书主键",
  },
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
  },
  {
    Prop: "SumQuantityOutStock",
    Label: "合计件数-出库",
  },
  {
    Prop: "SumGrossWeightOutStock",
    Label: "合计毛重-出库",
  },
  {
    Prop: "SumVolumeOutStock",
    Label: "合计体积-出库",
  },
  {
    Prop: "ChargeUnitOutStock",
    Label: "计价单位-出库",
  },
  {
    Prop: "RemarkOutStock",
    Label: "备注",
  },
  {
    Prop: "OutStocks",
    Label: "出库",
  },
]
