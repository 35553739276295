
    import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import PackageEnHistoryAppService from "@/apis.machine/PackageEnHistoryAppService";
    import PackageZhAppService from "@/apis.machine/PackageZhAppService";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzForm from "@/models/MiezzForm";
    import PackageAppService from "@/apis.machine/PackageAppService";
    import DtoPageListSearchPackage, {
        DtoPageListSearchPackageDynamicSearchItems,
    } from "@/models.machine/DtoPageListSearchPackage";
    import DtoPageListPackage from "@/models.machine/DtoPageListPackage";
    import DtoListItemPackage, {
        DtoListItemPackageMiezzListItems,
    } from "@/models.machine/DtoListItemPackage";
    import MiezzListItem from "../../models/MiezzListItem";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    export default class MiezzCurrency extends Vue {
        imageForm = {
            Visiable: false,
            Rows: [],
        };
        bottomButtonsImage?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        client?: OSS;
        products: UiSelectOptionWithStringValue[] = [];
        numbers: UiSelectOptionWithStringValue[] = [];
        codes: UiSelectOptionWithStringValue[] = [];
        specs: UiSelectOptionWithStringValue[] = [];


        //是否可以选择公司
        @Prop() isSelectCompany?: boolean;
        //当前公司主键
        @Prop() currentCompanyId?: string;
        selectCompanyId?: string;
        isLoading = false;
        companys?: UiSelectOption<string>[] = [];
        searchForm = {
            Visiable: false,

        };
        packages?: UiSelectOption<string>[] = [];
        packageEnHistorys?: UiSelectOption<string>[] = [];
        ruleForm: {
            Visible: boolean;
            Total: number;
            CurrentPage: number;
            PageSize: number;
        } = {
                Visible: false,
                Total: 0,
                CurrentPage: 1,
                PageSize: 10,
            };
        currentPage = 1;
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchPackage,
            DtoListItemPackage,
            string
        >();

        listInit = new MiezzPageList<
            DtoPageListSearchPackage,
            DtoListItemPackage,
            string
        >();
        selects = new MiezzPageList<
            DtoPageListSearchPackage,
            DtoListItemPackage,
            string
        >();
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        searchButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon:"Search",
                Code: "submit",
                Label: "搜索",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "refresh",
                Code: "reset",
                Label: "重置",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //是否在访问接口
        isQuery = false;
        isInit = true;
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.list.EnabledRadioSearchAll = false;
            //列表-传参
            this.list.Dto = new DtoPageListSearchPackage();
            //列表-数据-初始
            this.list.Data = new DtoPageListPackage();
            //列表-列配置
            var columes = DtoListItemPackageMiezzListItems;
            columes = [];
            DtoListItemPackageMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop != "Image" && item.Prop != "SpecQuantity") {
                    columes.push(item);
                }
                if (item.Prop == "IsSelected") {
                    item.Width = 40;
                }
            });
            this.list.Items = columes;

            this.list.EnabledRadioSearch = false;
            this.list.EnabledDynamicSearch = false;
            this.list.EnabledSelect = true;
            this.list.FixTop = "45px";
            this.list.EnabledPagination = false;
            this.list.HideCard = true;
            this.list.Fix = false;

            this.listInit = JSON.parse(JSON.stringify(this.list));

            var packageEnParam = {
                CompanyId: undefined,
            };
            PackageEnHistoryAppService.GetSelect(packageEnParam).then(en => {
                this.packageEnHistorys = en.data.Data;
                PackageAppService.GetSelect().then(zh => {
                    this.packages = zh.data.Data;
                    //if (this.packages && this.packageEnHistorys) {
                    //    for (var pk of this.packages) {
                    //        var flag = false;
                    //        for (var pkEn of this.packageEnHistorys) {
                    //            if (pk.Data != undefined && pk.Data.PackageEn != undefined && pk.Data.PackageEn.Name != undefined && pk.Data.PackageEn.Name == pkEn.Label) {
                    //                flag = true;
                    //                break;
                    //            }
                    //        }
                    //        if (flag == false && pk.Data != undefined && pk.Data.PackageEn != undefined && pk.Data.PackageEn.Name != undefined) {
                    //            this.packageEnHistorys.push({
                    //                Label: pk.Data.PackageEn.Name,
                    //                Value: pk.Data.PackageEn.Name,
                    //            });
                    //        }
                    //    }
                    //}
                })
            })
        }

        handleSearch(): void {
            this.searchForm.Visiable = true;
        }
        handleSelectionChange(val: any, index: number) {
            if (this.isQuery == false) {
                var item = [];
                if (this.selects && this.selects.Data && this.selects.Data.Items) {
                    if (this.list.Data && this.list.Data.Items) {
                        for (var it of this.selects.Data.Items) {
                            var exist = false;
                            for (var it1 of this.list.Data.Items) {
                                if (it.Id == it1.Id) {
                                    exist = true;
                                }
                            }
                            if (exist == false) {
                                item.push(it);
                            }
                        }
                    }
                }
                if (val) {
                    for (var it2 of val) {
                        item.push(it2);
                    }
                }
                if (this.selects && this.selects.Data) {
                    this.selects.Data.Items = JSON.parse(JSON.stringify(item));
                }
                if (this.list.Data && this.list.Data.Items) {
                    if (this.selects && this.selects.Data && this.selects.Data.Items) {
                        for (var it3 of this.list.Data.Items) {
                            it3.IsSelected = false;
                            for (var it4 of this.selects.Data.Items) {
                                if (it3.Id == it4.Id) {
                                    it3.IsSelected = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        onSearch(): void {
            if (this.isInit == false) {
                //MiezzForm.customSubmit(this.$refs, "customsPackageRuleForm", (v) => {
                //    if (v == true) {
                //        this.onData();
                //    } else {
                //        this.ruleForm.CurrentPage = this.currentPage;
                //    }
                //});
                if(this.selects && this.selects.Data && this.selects.Data.Items){
                    this.selects.Data.Items = [];
                }
                this.onData();

            } else {
                this.isInit = false;
                this.onData();
            }
        }
        onData(): void {
            if (this.list && this.list.Dto) {
                this.list.Dto.IsBusiness = true;
                this.list.Dto.CurrentPage = this.ruleForm.CurrentPage;
                this.list.Dto.PageSize = this.ruleForm.PageSize;
                this.list.Dto.CompanyId = this.selectCompanyId;
            }
            this.isQuery = true;

            PackageAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                this.currentPage = this.ruleForm.CurrentPage;
                if (r.data.Data) {
                    this.ruleForm.Total = r.data.Data.TotalCount as number;
                }

                if (this.list.Data && this.list.Data.Items) {
                    for (var it of this.list.Data.Items) {
                        it.IsSelected = false;

                        if (it.PackageImages && it.PackageImages.length > 0) {
                            for (var img of it.PackageImages) {
                                img.Url = this.client?.signatureUrl(img.Url as string);
                            }
                        }
                    }
                }
                const customsPackageTable = this.$refs["customsPackageTable"] as any;
                customsPackageTable.clearSelection();
                this.$nextTick(() => {
                    this.isQuery = false;
                    if (this.list.Data && this.list.Data.Items) {
                        if (this.selects && this.selects.Data && this.selects.Data.Items) {
                            var item = [];
                            for (var it3 of this.list.Data.Items) {
                                it3.IsSelected = false;
                                for (var it4 of this.selects.Data.Items) {
                                    if (it3.Id == it4.Id) {
                                        it3.IsSelected = true;
                                        it3.Quantity = it4.Quantity;
                                        item.push(it3);
                                    }
                                }
                            }
                            item.forEach((row) => {
                                customsPackageTable.toggleRowSelection(row, undefined);
                            });
                        }
                    }
                    this.isLoading = false;
                });
            });
        }
        onPrev(): void {
            //if (this.ruleForm) {
            //    this.ruleForm.CurrentPage = this.ruleForm.CurrentPage - 1;
            //}
            if (this.ruleForm.CurrentPage < 1) {
                this.ruleForm.CurrentPage = 1;
            }
            this.onSearch();
        }
        onNext(): void {
            //if (this.ruleForm) {
            //    this.ruleForm.CurrentPage = this.ruleForm.CurrentPage + 1;
            //}
            //this.onSearch();
        }
        handleCurrentChange(page: number): void {
            if (this.ruleForm) {
                this.ruleForm.CurrentPage = page;
            }
            this.onSearch();
        }
        onChangeQuantity(index: number) {
            if (
                this.list.Data &&
                this.list.Data.Items &&
                this.list.Data.Items[index] &&
                this.list.Data.Items[index].Quantity
            ) {
                var result = setDecimal(
                    this.list.Data.Items[index].Quantity + "",
                    0,
                    false
                );
                if (result != "") {
                    this.list.Data.Items[index].Quantity = Number(result);
                } else {
                    this.list.Data.Items[index].Quantity = undefined;
                }
            }
            if (
                this.selects &&
                this.selects.Data &&
                this.selects.Data.Items &&
                this.list.Data &&
                this.list.Data.Items
            ) {
                for (var it3 of this.selects.Data.Items) {
                    for (var it4 of this.list.Data.Items) {
                        if (it3.Id == it4.Id) {
                            it3.Quantity = it4.Quantity;
                        }
                    }
                }
            }
        }

        onImage(row: any): void {
            if (row.PackageImages && row.PackageImages.length > 0) {
                if (row.PackageImages.length == 1) {
                    window.open(row.PackageImages[0].Url);
                }
                else {
                    this.imageForm.Rows = row.PackageImages;
                    this.imageForm.Visiable = true;
                }
            }
        }
        /**按钮点击 */
        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /*初始化 */
        @Emit()
        onInit(): void {
            this.isLoading = true;
            PackageAppService.GetSpecs().then((spec) => {
                if (spec.data.Data) this.specs = spec.data.Data;
                PackageAppService.GetProductName().then((pro) => {
                    if (pro.data.Data) this.products = pro.data.Data;
                    PackageAppService.GetPackageCode().then((code) => {
                        if (code.data.Data) this.codes = code.data.Data;
                        PackageAppService.GetSpecNumber().then((spec) => {
                            if (spec.data.Data) this.numbers = spec.data.Data;

                            if (this.selects) {
                                this.selects.Data = [];
                            }
                            if (this.currentCompanyId != undefined && this.currentCompanyId != "") {
                                this.selectCompanyId = this.currentCompanyId;
                            } else {
                                this.selectCompanyId = undefined;
                            }

                            this.ruleForm.CurrentPage = 1;
                            this.ruleForm.Total = 0;
                            this.isInit = true;
                            if (this.isSelectCompany == true) {
                                var param = {
                                    CompanyType: 40,
                                };
                                CompanyAppService.GetSelect(param).then((r1) => {
                                    this.companys = r1.data.Data;
                                    if (
                                        this.companys &&
                                        this.companys.length > 0 &&
                                        (this.selectCompanyId == undefined || this.selectCompanyId == "")
                                    ) {
                                        this.selectCompanyId = this.companys[0].Id;
                                    }
                                    this.onSearch();
                                    this.ruleForm.Visible = true;
                                });
                            } else {
                                this.onSearch();
                                this.ruleForm.Visible = true;
                            }
                        })
                    })
                })

            })
        }
        onChangeCompany(): void {
            this.ruleForm.CurrentPage = 1;
            this.ruleForm.Total = 0;
            this.selects.Data = [];
            this.list.Data = [];
            this.onSearch();
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (
                        this.selects == undefined ||
                        this.selects.Data == undefined ||
                        this.selects.Data.Items == undefined ||
                        this.selects.Data.Items.length <= 0
                    ) {
                        ElMessage.warning("请至少勾选一条信息");
                        complete();
                        return;
                    }
                    MiezzForm.customSubmit(this.$refs, "customsPackageRuleForm", (v) => {
                        if (v == true) {
                            this.$emit("onSelectProductPackage", this.selects.Data?.Items);
                            this.ruleForm.Visible = false;
                            complete();
                        } else {
                            complete();
                        }
                    });

                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onCloseSearch(): void {
            if (this.list && this.list.Dto && this.listInit && this.listInit.Dto) {
                this.list.Dto.SearchCode = this.listInit.Dto.SearchCode;
                this.list.Dto.SearchProductName = this.listInit.Dto.SearchProductName;
                this.list.Dto.SearchTypeZh = this.listInit.Dto.SearchTypeZh;
                this.list.Dto.SearchTypeEn = this.listInit.Dto.SearchTypeEn;
                this.list.Dto.SearchSpec = this.listInit.Dto.SearchSpec;
                this.list.Dto.SearchNumber = this.listInit.Dto.SearchNumber;
            }
            this.searchForm.Visiable = false;
        }
        handleClickSearch(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.ruleForm.CurrentPage = 1;
                    this.onData();
                    this.listInit = JSON.parse(JSON.stringify(this.list));
                    this.searchForm.Visiable = false;
                    complete();
                    break;
                case "reset":
                    if (this.list && this.list.Dto) {
                        this.list.Dto.SearchCode = undefined;
                        this.list.Dto.SearchProductName = undefined;
                        this.list.Dto.SearchTypeZh = undefined;
                        this.list.Dto.SearchTypeEn = undefined;
                        this.list.Dto.SearchSpec = undefined;
                        this.list.Dto.SearchNumber = undefined;
                    }

                    complete();
                    break;
                case "close":
                    if (this.list && this.list.Dto && this.listInit && this.listInit.Dto) {
                        this.list.Dto.SearchCode = this.listInit.Dto.SearchCode;
                        this.list.Dto.SearchProductName = this.listInit.Dto.SearchProductName;
                        this.list.Dto.SearchTypeZh = this.listInit.Dto.SearchTypeZh;
                        this.list.Dto.SearchTypeEn = this.listInit.Dto.SearchTypeEn;
                        this.list.Dto.SearchSpec = this.listInit.Dto.SearchSpec;
                        this.list.Dto.SearchNumber = this.listInit.Dto.SearchNumber;
                    }
                    this.searchForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        querySearchCodeAsync(queryString: string, cb: any) {
            var restaurants = this.codes;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.codes) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.codes) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.codes) {
                    for (var i = 0; i < this.codes.length; i++) {
                        if (i < 10) {
                            results.push(this.codes[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCode(item: UiSelectOption<string>, model: any) {
            if (this.list && this.list.Dto) {
                this.list.Dto.SearchCode = item.Label;
            }
        }

        querySearchProductNameAsync(queryString: string, cb: any) {
            var restaurants = this.products;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.products) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.products) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.products) {
                    for (var i = 0; i < this.products.length; i++) {
                        if (i < 10) {
                            results.push(this.products[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectProductName(item: UiSelectOption<string>, model: any) {
            if (this.list && this.list.Dto) {
                this.list.Dto.SearchProductName = item.Label;
            }
        }

        querySearchSpecAsync(queryString: string, cb: any) {
            var restaurants = this.specs;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.specs) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.specs) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.specs) {
                    for (var i = 0; i < this.specs.length; i++) {
                        if (i < 10) {
                            results.push(this.specs[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectSpec(item: UiSelectOption<string>, model: any) {
            if (this.list && this.list.Dto) {
                this.list.Dto.SearchSpec = item.Label;
            }
        }


        querySearchSpecNumberAsync(queryString: string, cb: any) {
            var restaurants = this.numbers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.numbers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.numbers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.numbers) {
                    for (var i = 0; i < this.numbers.length; i++) {
                        if (i < 10) {
                            results.push(this.numbers[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectSpecNumber(item: UiSelectOption<string>, model: any) {
            if (this.list && this.list.Dto) {
                this.list.Dto.SearchNumber = item.Label;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
