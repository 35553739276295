import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69ae7a64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_setting = _resolveComponent("setting")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_choose_menu_fields = _resolveComponent("choose-menu-fields")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_scrollbar, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_tree, {
          ref: "treeRef",
          "node-key": "Id",
          data: _ctx.tree,
          props: _ctx.props,
          "expand-on-click-node": false,
          "check-strictly": true,
          "show-checkbox": _ctx.showCheck,
          "default-expand-all": "",
          onCheckChange: _ctx.handleCheckChange
        }, {
          default: _withCtx(({ data, node }) => [
            _createElementVNode("div", null, [
              _createVNode(_component_el_tag, {
                class: "node-tag",
                style: _normalizeStyle(data.MarkerColor ? `color: ${data.MarkerColor}` : ''),
                type: "info"
              }, {
                default: _withCtx(() => [
                  (data.Remark)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: data.Remark,
                        placement: "bottom",
                        effect: "light"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(data.Name), 1)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(data.Name), 1)),
                  (data.Field)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 2,
                        type: "text",
                        size: "small",
                        onClick: ($event: any) => (_ctx.handleNodeSetting(data)),
                        style: _normalizeStyle(data.MarkerColor ? `color: ${data.MarkerColor}` : '')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_icon, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_setting)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick", "style"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["style"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.RightItems, (item, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: i,
                  class: "node-tag-check"
                }, [
                  (_ctx.showCheck)
                    ? (_openBlock(), _createBlock(_component_el_checkbox, {
                        key: 0,
                        modelValue: item.Check,
                        "onUpdate:modelValue": ($event: any) => ((item.Check) = $event),
                        indeterminate: false,
                        onChange: ($event: any) => (_ctx.handleMenuNodeTagChange(item, node))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_tag, {
                    class: "node-tag",
                    style: _normalizeStyle(item.MarkerColor ? `color: ${item.MarkerColor}` : ''),
                    type: "info"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(item.Name), 1),
                      (item.Field && item.Check)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 0,
                            type: "text",
                            size: "small",
                            onClick: ($event: any) => (_ctx.handleNodeSetting(item)),
                            style: _normalizeStyle(item.MarkerColor ? `color: ${item.MarkerColor}` : '')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_setting)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["onClick", "style"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["style"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["data", "props", "show-checkbox", "onCheckChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_choose_menu_fields, {
          modal: _ctx.modal,
          "onUpdate:modal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modal) = $event)),
          modelValue: _ctx.menuFieldIds,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.menuFieldIds) = $event)),
          id: _ctx.id,
          showCheck: _ctx.showCheck,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modal", "modelValue", "id", "showCheck", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}