import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 详细产地详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPlace extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 海关代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 详细产地详细-表单校验
 */
export const DtoDetailPlaceRules = {
}

/**
 * 详细产地详细-设置项
 */
export const DtoDetailPlaceMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "海关代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
]
