import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFormCodeDtoCustom from "../models.machine/DtoFormCodeDtoCustom";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoFormCodeDynamicSearchItem from "../models.machine/DtoFormCodeDynamicSearchItem";

/**
 * 数据传输对象
 * @description 自动生成的代码,请勿修改
 */
export default class CodeDtoAppService {
    /**
     * 创建自定义Dto
     * @param {DtoFormCodeDtoCustom} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CreateCustomDto(dto?: DtoFormCodeDtoCustom): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CodeDto/CreateCustomDto`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 预览动态搜索
     * @param {string} [table] 表
     * @param {DtoFormCodeDynamicSearchItem[]} [dynamicSearchItems] 动态搜索
     * @returns {AxiosPromise<RESTfulResult<string>>} 预览
     */
    static GetDynamicSearchPreviewByTable(table?: string, dynamicSearchItems?: DtoFormCodeDynamicSearchItem[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CodeDto/GetDynamicSearchPreview/${table}`;
        return service.post<RESTfulResult<string>>(url, dynamicSearchItems);
    }

}
