
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoFormOrderPool, {
  DtoFormOrderPoolMiezzFormItems,
  DtoFormOrderPoolRules,
} from "@/models.machine/DtoFormOrderPool";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoDetailOrderPool from "@/models.machine/DtoDetailOrderPool";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
import { EnumLadingBillType } from "@/models.machine/EnumLadingBillType";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormOrderPool_OrderPoolPackage, {
  DtoFormOrderPool_OrderPoolPackageMiezzListItems,
  DtoFormOrderPool_OrderPoolPackageRules,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape, {
  DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct, {
  DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzListItems,
  DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductRules,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct";
import DtoListItemPackage from "@/models.machine/DtoListItemPackage";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import OrderFormCompanyBusinessCard from "../CompanyBusinessCard/OrderFormCompanyBusinessCard.vue";
import OrderFormContainer from "../Container/OrderFormContainer.vue";
import OrderFormCost from "../Cost/OrderFormCost.vue";
import OrderFormProductsSummary from "../ProductSummary/OrderFormProductsSummary.vue";
import OrderFormLadingBill from "../LadingBill/OrderFormLadingBill.vue";
import CompanyBusinessCardForm from "@/views/Company/CompanyBusinessCard/CompanyBusinessCardForm.vue";
import OrderInboundOutboundAppService from "@/apis.machine/OrderInboundOutboundAppService";
import { h } from "vue";
import MiezzHsCode from "@/components/Business/MiezzHsCode.vue";
import OSS from "ali-oss";
import AliyunOSS from "@/apis/AliyunOSS";
import moment from "moment";
import MiezzAlertLog from "@/models/MiezzAlertLog";
import DtoSynchronizePoolData from "@/models.machine/DtoSynchronizePoolData";
import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";

@Options({
  components: {
    OrderFormCompanyBusinessCard,
    CompanyBusinessCardForm,
    OrderFormContainer,
    OrderFormCost,
    OrderFormProductsSummary,
    OrderFormLadingBill,
  },
})
export default class OrderPoolForm extends Vue {
  client?: OSS;
  cropper: any = {
    isOpen: false,
    img: "",
  };
  id?: string;
  poolId?: string;
  orderDepartureWharfId?: string;

  logier?: Logier;
  powerCodes?: string[] = [];

  form = new MiezzForm<DtoFormOrderPool, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Icon: "select",
      Label: "保存",
      Type: "text",
      Size: "small",
    },
  ];
  random = 0;
  detail?: DtoDetailOrderPool;

  EnumCompanyType = EnumCompanyType;
  EnumOrderPoolStatus = EnumOrderPoolStatus;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumGoodsTransportTypeOption = EnumGoodsTransportTypeOption;

  chargeUnits: UiSelectOption<string>[] = [];

  oldClientCompanyId?: string = "";
  clientCompanyId?: string = "";
  random2 = 0;

  // ClientCompanyId

  created(): void {
    this.client = AliyunOSS.GetClient();
    this.id = this.$route.params.id as string | undefined;
    this.poolId = this.$route.query.PoolId as string | undefined;
    this.orderDepartureWharfId = this.$route.query.OrderDepartureWharfId as
      | string
      | undefined;

    this.logier = CurrentLogier.GetLogier();
    if (this.logier?.CompanyType != EnumCompanyType.Customer) {
      this.packageTypes = this.packageTypes.filter(
        (it) => it.Value != "Import"
      );
    }
    CurrentLogier.GetPowerCodes().then((powerCodes) => {
      this.powerCodes = powerCodes ?? [];
    });

    //表单-元素配置
    this.form.Items = DtoFormOrderPoolMiezzFormItems;

    this.initPackages();

    ParamChargeUnitAppService.GetSelect().then((r) => {
      this.chargeUnits = r.data.Data ?? [];
    });

    this.handleGet();
  }
  deletePic(index: number) {
    if (this.listPackageProducts.Data && this.listPackageProducts.Data.Items) {
      this.listPackageProducts.Data.Items[index].Picture = "";
      this.listPackageProducts.Data.Items[index].PictureUrl = "";
    }
  }
  getImg(img: string) {
    if (
      this.listPackageProducts.Data &&
      this.listPackageProducts.Data.Items &&
      img
    ) {
      // this.listPackageProducts.Data.Items[this.cropper.index].isCropper = true;
      this.listPackageProducts.Data.Items[this.cropper.index].Picture = img;
      this.listPackageProducts.Data.Items[this.cropper.index].PictureUrl =
        this.client!.signatureUrl(img) as string;
    }
  }
  handleClose() {
    this.cropper.isOpen = false;
    this.cropper.img = "";
    this.cropper.index = null;

    var random2 = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
    this.random2 += random2;
  }
  handleOpen(img: string, index: number) {
    this.cropper.isOpen = true;
    this.cropper.img = img;
    this.cropper.index = index;
  }

  /**初始化表单校验 */
  initRules(): void {
    //表单-校验
    this.form.Rules = JSON.copy(DtoFormOrderPoolRules);
    //目的港ETA时间
    this.form.Rules.EstimatedArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedDepartureDate &&
              moment(value) <= moment(this.form.Data.EstimatedDepartureDate)
            ) {
              this.form.Data.EstimatedArrivalDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ETD时间
    this.form.Rules.EstimatedDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //预计货好时间不能晚于ETD时间
            if (
              this.form.Data.EstimatedGoodsReadyDate &&
              moment(value) <= moment(this.form.Data.EstimatedGoodsReadyDate)
            ) {
              this.form.Data.EstimatedDepartureDate = undefined;
              return callback(new Error("预计货好时间不能晚于ETD时间"));
            }
            //目的港ETA时间晚于装货港ETD时间
            else if (
              this.form.Data.EstimatedArrivalDate &&
              moment(this.form.Data.EstimatedArrivalDate) <= moment(value)
            ) {
              this.form.Data.EstimatedDepartureDate = undefined;
              return callback(new Error("ETA时间不能早于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //预计货好时间
    this.form.Rules.EstimatedGoodsReadyDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //预计货好时间不能晚于ETD时间
            if (
              this.form.Data.EstimatedDepartureDate &&
              moment(value) >= moment(this.form.Data.EstimatedDepartureDate)
            ) {
              this.form.Data.EstimatedGoodsReadyDate = undefined;
              return callback(new Error("预计货好时间不能晚于ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    this.form.Rules.PackageProducts = [
      {
        validator: (
          rule: any,
          value: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct[],
          callback: any
        ) => {
          if (
            value.length == 0 ||
            value.any((it) => !it.NameZh || !it.NameEn)
          ) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    if (this.detail) {
      //包含装货港任一服务时，为必填；否则为非必填
      if (
        this.detail?.DeparturePortBooking ||
        this.detail?.DeparturePortTake ||
        this.detail?.DeparturePortWarehouse ||
        this.detail?.DeparturePortDeclareCustoms
      ) {
        this.form.Rules.EstimatedGoodsReadyDate.push({
          required: true,
          message: "信息不能为空",
          trigger: "change",
        });
      }
      //至少包含一个装货港服务时，为必填，否则为非必填；
      if (
        this.detail?.DeparturePortBooking ||
        this.detail?.DeparturePortTake ||
        this.detail?.DeparturePortWarehouse ||
        this.detail?.DeparturePortDeclareCustoms
      ) {
        this.form.Rules.EstimatedDepartureDate.push({
          required: true,
          message: "信息不能为空",
          trigger: "change",
        });
      }
      //不包含任何装货港服务，并至少包含任一目的港服务时，为必填，否则未非必填；
      if (
        !this.detail?.DeparturePortBooking &&
        !this.detail?.DeparturePortTake &&
        !this.detail?.DeparturePortWarehouse &&
        !this.detail?.DeparturePortDeclareCustoms &&
        (this.detail.DestinationPortClearCustoms ||
          this.detail.DestinationPortDelivery ||
          this.detail.DestinationPortWarehouse)
      ) {
        this.form.Rules.EstimatedArrivalDate.push({
          required: true,
          message: "信息不能为空",
          trigger: "change",
        });
      }
      if (
        this.detail.DeparturePortBooking ||
        this.detail.DeparturePortTake ||
        this.detail.DeparturePortDeclareCustoms ||
        this.detail.DeparturePortWarehouse ||
        this.detail.DestinationPortClearCustoms
      ) {
        this.form.Rules.LoadingPortId = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
        ];
      }
      if (
        this.detail.DeparturePortBooking ||
        this.detail.DeparturePortDeclareCustoms ||
        this.detail.DestinationPortClearCustoms ||
        this.detail.DestinationPortDelivery ||
        this.detail.DestinationPortWarehouse
      ) {
        this.form.Rules.DeliveryPortId = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
        ];
      }
      if (this.detail.DestinationPortDelivery) {
        this.form.Rules.DeliveryPlaceLabel = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
          },
        ];
      }
      if (this.detail.TransportDirection == EnumTransportDirection.Other) {
        this.form.Rules.MarksNotes = undefined;
        this.form.Rules.PackagesNumber = undefined;
        this.form.Rules.PackagesKind = [
          {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.GoodsDescription = [
          {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.GrossWeight = undefined;
        this.form.Rules.Volume = undefined;
      } else {
        this.form.Rules.MarksNotes = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
          },
        ];
        this.form.Rules.PackagesNumber = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
          {
            validator: (rule: any, value: number, callback: any) => {
              if (value == undefined) {
                return callback(new Error("信息不能为空"));
              } else if (value <= 0) {
                return callback(new Error("仅可填入>0的数字"));
              }
              callback();
            },
            trigger: "blur",
          },
        ];
        this.form.Rules.PackagesKind = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
          },
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
          {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.PackagesNumberKind = [
          {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.GoodsDescription = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
          },
          {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.GoodsDescriptionRemark = [
          {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
          },
        ];
        this.form.Rules.GrossWeight = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
        ];
        this.form.Rules.Volume = [
          {
            required: true,
            message: "信息不能为空",
            trigger: "change",
          },
        ];
      }
    }
  }

  /**查询表单数据 */
  handleGet(): void {
    // 库存建单
    if (this.poolId) {
      OrderPoolAppService.GetById(this.poolId).then((r) => {
        OrderPoolAppService.GetById(this.id).then((r1) => {
          if (r1.data.Data) {
            // 新的委托数据
            if (r.data.Data) {
              // 老的委托数据
              this.oldClientCompanyId = r.data.Data.ClientCompanyId;

              this.detail = JSON.copy(r.data.Data) as DtoDetailOrderPool;
              this.detail.ClientCompanyId = r1.data.Data.ClientCompanyId;

              this.form.Data = r.data.Data;
              this.form.Data.Id = this.id;
              this.clientCompanyId = r1.data.Data.ClientCompanyId;

              this.form.Data.Packages = [];
              console.log(1);
              if (this.listPackage.Data)
                this.listPackage.Data.Items = this.form.Data.Packages ?? [];

              if (
                this.form.Data.LoadingPortId &&
                this.form.Data.LoadingPortLabel &&
                this.loadingPorts.length == 0
              ) {
                this.getLoadingPorts(this.form.Data.LoadingPortLabel);
              }
              if (
                this.form.Data.DischargePortId &&
                this.form.Data.DischargePortLabel &&
                this.dischargePorts.length == 0
              ) {
                this.getDischargePorts(this.form.Data.DischargePortLabel);
              }
              if (
                this.form.Data.DeliveryPortId &&
                this.form.Data.DeliveryPortLabel &&
                this.deliveryPorts.length == 0
              ) {
                this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
              }
              if (
                this.form.Data.CarrierId &&
                this.form.Data.CarrierLabel &&
                this.carriers.length == 0
              ) {
                this.getCarriers(this.form.Data.CarrierLabel);
              }
              if (
                this.form.Data.TransportChannelId &&
                this.form.Data.TransportChannelLabel &&
                this.channels.length == 0
              ) {
                this.getChannels(this.form.Data.TransportChannelLabel);
              }

              // if (this.listPackage.Data) {
              //   for (const _package of this.form.Data.Packages ?? []) {
              //     _package.ShapesLabel = "暂无数据×1";
              //     this.updatePackageShapesLabel(_package);
              //     _package.ProductsLabel = "暂无数据";
              //     this.updatePackageProductsLabel(_package);
              //     _package.ShipmentIdLabel = _package.ShipmentId ?? "暂无数据";
              //   }

              //   this.listPackage.Data.Items = this.form.Data.Packages;
              // }

              if (
                this.form.Data.Containers &&
                this.form.Data.Containers.length == 0 &&
                this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
                this.detail?.TransportContainerLoad ==
                  EnumTransportContainerLoad.FCL
              ) {
                this.form.Data.Containers.push({});
              }

              if (
                this.detail.DeparturePortBooking &&
                this.detail.DeparturePortLadingBill &&
                !this.form.Data.LadingBillType
              ) {
                this.form.Data.LadingBillType = EnumLadingBillType.Original;
              }

              ParamCurrencySystemAppService.GetSelect().then((r) => {
                this.currencySystems = r.data.Data ?? [];
                if (!this.form.Data.CurrencySystemId) {
                  this.form.Data.CurrencySystemId =
                    this.currencySystems.firstOrDefault(
                      (it) => it.Data.Code == "USD"
                    )?.Value;
                }
                this.form.Data.CurrencySystemCode =
                  this.currencySystems.firstOrDefault(
                    (it) => it.Value == this.form.Data.CurrencySystemId
                  )?.Data?.Code;

                if (
                  this.form.Data.Costs &&
                  this.form.Data.Costs.length == 0 &&
                  this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
                  this.powerCodes?.contains(
                    "OrderManagement_Pool_Summary_Detail_Costs"
                  )
                ) {
                  this.form.Data.Costs.push({
                    CurrencySystemId: this.currencySystems.firstOrDefault(
                      (it) => it.Data.Code == "CNY"
                    )?.Value,
                  });
                }
                if (
                  this.form.Data.SupplierCosts &&
                  this.form.Data.SupplierCosts.length == 0 &&
                  this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
                  this.powerCodes?.contains(
                    "OrderManagement_Pool_Summary_Detail_SupplierCosts"
                  )
                ) {
                  this.form.Data.SupplierCosts.push({
                    CurrencySystemId: this.currencySystems.firstOrDefault(
                      (it) => it.Data.Code == "CNY"
                    )?.Value,
                  });
                }
                if (
                  this.form.Data.SettlementCosts &&
                  this.form.Data.SettlementCosts.length == 0 &&
                  this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
                  this.powerCodes?.contains(
                    "OrderManagement_Pool_Summary_Detail_SettlementCosts"
                  ) &&
                  this.detail?.ClientCompanyBuildSettlementBill
                ) {
                  this.form.Data.SettlementCosts.push({
                    CurrencySystemId: this.currencySystems.firstOrDefault(
                      (it) => it.Data.Code == "CNY"
                    )?.Value,
                  });
                }
              });

              // 库存建单剩余的包装数量，毛重，体积
              if (this.orderDepartureWharfId) {
                OrderInboundOutboundAppService.GetSurplusById(
                  this.orderDepartureWharfId
                ).then((r) => {
                  this.form.Data.PackagesKind = r.data.Data?.ChargeUnit;
                  this.form.Data.PackagesNumber = Number(r.data.Data?.Quantity);
                  this.form.Data.GrossWeight = Number(r.data.Data?.GrossWeight);
                  this.form.Data.Volume = Number(r.data.Data?.Volume);
                });
              }

              // 更换了委托公司，清空收发通
              if (this.oldClientCompanyId != this.clientCompanyId) {
                this.form.Data.ShipperId = undefined;
                this.form.Data.ShipperContent = "";
                this.form.Data.ConsigneeId = undefined;
                this.form.Data.ConsigneeContent = "";
                this.form.Data.NotifyPartyId = undefined;
                this.form.Data.NotifyPartyContent = "";
              }

              var from = this.$route.query.from as string;
              if (from == "wharf") {
                setTimeout(() => {
                  const error =
                    document.getElementsByClassName("goodsDataList");
                  console.log(error);
                  error[0]?.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                  });
                }, 100);
              }

              this.initRules();
            }
          }
        });
      });
    } else if (this.id) {
      OrderPoolAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail = JSON.copy(r.data.Data) as DtoDetailOrderPool;
          this.form.Data = r.data.Data;
          this.clientCompanyId = this.detail.ClientCompanyId;

          this.getType();

          if (this.listPackage.Data)
            this.listPackage.Data.Items = this.form.Data.Packages ?? [];

          if (
            this.form.Data.LoadingPortId &&
            this.form.Data.LoadingPortLabel &&
            this.loadingPorts.length == 0
          ) {
            this.getLoadingPorts(this.form.Data.LoadingPortLabel);
          }
          if (
            this.form.Data.DischargePortId &&
            this.form.Data.DischargePortLabel &&
            this.dischargePorts.length == 0
          ) {
            this.getDischargePorts(this.form.Data.DischargePortLabel);
          }
          if (
            this.form.Data.DeliveryPortId &&
            this.form.Data.DeliveryPortLabel &&
            this.deliveryPorts.length == 0
          ) {
            this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
          }
          if (
            this.form.Data.CarrierId &&
            this.form.Data.CarrierLabel &&
            this.carriers.length == 0
          ) {
            this.getCarriers(this.form.Data.CarrierLabel);
          }
          if (
            this.form.Data.TransportChannelId &&
            this.form.Data.TransportChannelLabel &&
            this.channels.length == 0
          ) {
            this.getChannels(this.form.Data.TransportChannelLabel);
          }

          if (this.listPackage.Data) {
            for (const _package of this.form.Data.Packages ?? []) {
              _package.ShapesLabel = "暂无数据";
              this.updatePackageShapesLabel(_package);
              _package.ProductsLabel = "暂无数据";
              this.updatePackageProductsLabel(_package);
              _package.ShipmentIdLabel = _package.ShipmentId ?? "暂无数据";
            }

            this.listPackage.Data.Items = this.form.Data.Packages;
          }

          if (
            this.form.Data.Containers &&
            this.form.Data.Containers.length == 0 &&
            this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
            this.detail?.TransportContainerLoad ==
              EnumTransportContainerLoad.FCL
          ) {
            this.form.Data.Containers.push({});
          }

          if (
            this.detail.DeparturePortBooking &&
            this.detail.DeparturePortLadingBill &&
            !this.form.Data.LadingBillType
          ) {
            this.form.Data.LadingBillType = EnumLadingBillType.Original;
          }

          ParamCurrencySystemAppService.GetSelect().then((r) => {
            this.currencySystems = r.data.Data ?? [];
            if (!this.form.Data.CurrencySystemId) {
              this.form.Data.CurrencySystemId =
                this.currencySystems.firstOrDefault(
                  (it) => it.Data.Code == "USD"
                )?.Value;
            }
            this.form.Data.CurrencySystemCode =
              this.currencySystems.firstOrDefault(
                (it) => it.Value == this.form.Data.CurrencySystemId
              )?.Data?.Code;

            if (
              this.form.Data.Costs &&
              this.form.Data.Costs.length == 0 &&
              this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
              this.powerCodes?.contains(
                "OrderManagement_Pool_Summary_Detail_Costs"
              )
            ) {
              this.form.Data.Costs.push({
                CurrencySystemId: this.currencySystems.firstOrDefault(
                  (it) => it.Data.Code == "CNY"
                )?.Value,
              });
            }
            if (
              this.form.Data.SupplierCosts &&
              this.form.Data.SupplierCosts.length == 0 &&
              this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
              this.powerCodes?.contains(
                "OrderManagement_Pool_Summary_Detail_SupplierCosts"
              )
            ) {
              this.form.Data.SupplierCosts.push({
                CurrencySystemId: this.currencySystems.firstOrDefault(
                  (it) => it.Data.Code == "CNY"
                )?.Value,
              });
            }
            if (
              this.form.Data.SettlementCosts &&
              this.form.Data.SettlementCosts.length == 0 &&
              this.detail?.Status == EnumOrderPoolStatus.WaitingAdd &&
              this.powerCodes?.contains(
                "OrderManagement_Pool_Summary_Detail_SettlementCosts"
              ) &&
              this.detail?.ClientCompanyBuildSettlementBill
            ) {
              this.form.Data.SettlementCosts.push({
                CurrencySystemId: this.currencySystems.firstOrDefault(
                  (it) => it.Data.Code == "CNY"
                )?.Value,
              });
            }
          });
          if (this.orderDepartureWharfId) {
            OrderInboundOutboundAppService.GetSurplusById(
              this.orderDepartureWharfId
            ).then((r) => {
              this.form.Data.PackagesNumber = Number(r.data.Data?.Quantity);
              this.form.Data.GrossWeight = Number(r.data.Data?.GrossWeight);
              this.form.Data.Volume = Number(r.data.Data?.Volume);
            });
          }

          var from = this.$route.query.from as string;
          if (from == "wharf") {
            setTimeout(() => {
              const error = document.getElementsByClassName("goodsDataList");
              console.log(error);
              error[0]?.scrollIntoView({ block: "start", behavior: "smooth" });
            }, 100);
          }

          this.initRules();
        }
      });
    }
  }

  /**获取英文计价单位 */
  getChargeUnitEns(q: string, cb: any): void {
    const names: string[] = [];
    for (const unit of this.chargeUnits) {
      if (unit.Data.NameEn && !names.contains(unit.Data.NameEn)) {
        names.push(unit.Data.NameEn);
      }
    }
    const data = (q ? names.filter((it) => it.contains(q)) : names).map(
      (it) => {
        return {
          NameEn: it,
        };
      }
    );
    cb(data);
  }

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  type = "暂无数据";
  /**获取业务类型 */
  getType(): void {
    const direction = this.EnumTransportDirectionOption.firstOrDefault(
      (it) => it.Value == this.detail?.TransportDirection
    )?.Label;
    this.type = `【${direction}】`;
    if (this.detail?.TransportType) {
      const type = this.EnumTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.detail?.TransportType
      )?.Label;
      this.type += `【${type}】`;
    }
    if (this.detail?.TransportContainerLoad) {
      const containerLoad =
        this.EnumTransportContainerLoadOption.firstOrDefault(
          (it) => it.Value == this.detail?.TransportContainerLoad
        )?.Label;
      this.type += `【${containerLoad}】`;
    }
  }

  handleCodeBlur(): void {
    if (this.form.Data.Code == "!add" && this.logier?.IsDevelopment) {
      this.form.Data.Code = this.detail?.Code;
      this.form.Data.EstimatedGoodsReadyDate = moment().format(
        "YYYY/MM/DD"
      ) as any;
      this.form.Data.EstimatedDepartureDate = moment()
        .add(1, "d")
        .format("YYYY/MM/DD HH:mm") as any;
      this.form.Data.Packages = [
        {
          Shapes: [
            {
              Quantity: 1,
            },
          ],
          ShapesLabel: "暂无数据",
          Products: [
            {
              ChargeUnitEn: "PIECE",
              NameZh: "笔",
              NameEn: "Pen",
            },
          ],
          ProductsLabel: "暂无数据",
          ReferenceIds: [{}],
          ShipmentIdLabel: "暂无数据",
          GrossWeight: 10,
          Volume: 20,
          Quantity: 100,
        },
      ];
      if (this.listPackage.Data)
        this.listPackage.Data.Items = this.form.Data.Packages;
      this.updatePackageProductsLabel(this.form.Data.Packages[0]);
      this.handleCalcPackagesNumber();
      this.handleClacGoodsDescription();
      this.form.Data.PackagesKind = "PIECES";
      const listCosts = (this.$refs["ref-order-form-cost"] as OrderFormCost)
        .listCosts;
      this.form.Data.Costs = [];
      if (listCosts.Data) listCosts.Data.Items = this.form.Data.Costs;

      ParamPortAppService.GetSelect({
        Keywords: "宁波",
      }).then((r) => {
        const param = r.data.Data?.firstOrDefault();
        this.form.Data.LoadingPortId = param?.Value;
        this.form.Data.LoadingPortLabel = param?.Label;
      });
      ParamPortAppService.GetSelect({
        Keywords: "纽约",
      }).then((r) => {
        const param = r.data.Data?.firstOrDefault();
        this.form.Data.DeliveryPortId = param?.Value;
        this.form.Data.DeliveryPortLabel = param?.Label;
      });
      ParamCountryAreaAppService.GetSelect({
        Keywords: "ONT8",
      }).then((r) => {
        const param = r.data.Data?.firstOrDefault();
        this.form.Data.DeliveryPlaceLabel = param?.FullName;
        this.form.Data.DeliveryPlaceAddress = param?.DetailedAddress;
      });
    }
  }

  loadingPorts: UiSelectOption<string>[] = [];
  /**获取装货港 */
  getLoadingPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Export
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.loadingPorts = r.data.Data ?? [];
      });
    }
  }

  dischargePorts: UiSelectOption<string>[] = [];
  /**获取卸货港 */
  getDischargePorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
        this.dischargePorts = r.data.Data ?? [];
      });
    }
  }

  deliveryPorts: UiSelectOption<string>[] = [];
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.deliveryPorts = r.data.Data ?? [];
      });
    }
  }

  handleDisabledPortClick(): void {
    if (this.detail?.OrderId) {
      ElMessageBox({
        title: "提示",
        message: h("p", null, [
          h(
            "span",
            null,
            "委托书已关联订单，“改装货港与目的港”需与订单保持一致，如需修改请点击？” "
          ),
          h(
            "a",
            {
              href: `#/order/booking/edit/${this.detail.OrderId}?backUrl=${this.$route.fullPath}`,
            },
            "《订舱信息》"
          ),
          h("span", null, "？” "),
        ]),
      });
    }
  }

  /**获取交货地点 */
  getDeliveryPlaces(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }

  /**选择交货地点 */
  handleSelectDeliveryPlace(item: DtoSelectOptionParamCountryArea): void {
    this.form.Data.DeliveryPlaceAddress = item?.DetailedAddress;
  }

  carriers: UiSelectOption<string>[] = [];
  /**获取承运人 */
  getCarriers(q: string): void {
    ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  channels: UiSelectOption<string>[] = [];
  /**获取渠道 */
  getChannels(q: string): void {
    ParamTransportChannelAppService.GetSelect({ Keywords: q, Take: 10 }).then(
      (r) => {
        this.channels = r.data.Data ?? [];
      }
    );
  }

  /**货物清单-货物数据 */
  packageType = "Add";
  /**货物清单-货物数据-选择器数据 */
  packageTypes: UiSelectOption<string>[] = [
    {
      Label: "从产品库导入",
      Value: "Import",
    },
    {
      Label: "手动添加",
      Value: "Add",
    },
    {
      Label: "导入电商平台订单",
      Value: "ImportOrder",
      Disabled: true,
    },
  ];

  /**货物清单-币制-选择器数据 */
  currencySystems: UiSelectOption<string>[] = [];
  @Watch("form.Data.CurrencySystemId")
  onCurrencySystemIdChanged(): void {
    this.form.Data.CurrencySystemCode = this.currencySystems.firstOrDefault(
      (it) => it.Value == this.form.Data.CurrencySystemId
    )?.Data?.Code;
  }

  /**货物清单-列表配置 */
  listPackage = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage,
    string
  >();
  listPackageRules = DtoFormOrderPool_OrderPoolPackageRules;
  /**当前货物 */
  currentPackage = new DtoFormOrderPool_OrderPoolPackage();
  /**货物清单-当前货物索引 */
  packageIndex?: number;
  /**货物清单-初始化元素配置 */
  initPackages(): void {
    this.listPackage.Fix = false;
    this.listPackage.HideCard = true;
    this.listPackage.EnabledDynamicSearch = false;
    this.listPackage.EnabledRadioSearch = false;
    this.listPackage.EnabledSelect = false;
    this.listPackage.EnabledPagination = false;
    this.listPackage.Items = DtoFormOrderPool_OrderPoolPackageMiezzListItems;
    this.listPackage.Data = {
      Items: [],
    };
    this.listPackage.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.initPackageShapes();
    this.initPackageProducts();
    this.initPackageShipmentId();
  }

  /**货物清单-导入模板-下载模板按钮 */
  btnDownloadTemplate: MiezzButton = {
    Code: "download-template",
    Label: "下载模板",
    Type: "primary",
    Width: "70px",
    Size: "small",
  };
  /**货物清单-导入模板-导入模板按钮 */
  btnImportTemplate: MiezzButton = {
    Code: "import-template",
    Label: "导入模板",
    Type: "primary",
    Width: "70px",
    Size: "small",
    FileUpload: true,
    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  /**货物清单-从产品库导入-导入按钮 */
  btnImportPackages: MiezzButton = {
    Code: "import-packages",
    Label: "导入",
    Type: "primary",
    Width: "70px",
    Size: "small",
  };
  onSelectProductPackage(rows: DtoListItemPackage[]): void {
    for (const row of rows) {
      const _package = new DtoFormOrderPool_OrderPoolPackage();
      _package.Code = row.Code;

      _package.Shapes = [];
      for (const shape of row.PackageShapes ?? []) {
        const _shape =
          new DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape();
        _shape.Length = shape.Length == 0 ? undefined : shape.Length;
        _shape.Width = shape.Width == 0 ? undefined : shape.Width;
        _shape.Height = shape.Height == 0 ? undefined : shape.Height;
        _shape.GrossWeight = shape.GrossWeight;
        _shape.Volume = shape.Volume;
        _shape.Quantity = shape.Quantity;
        _package.Shapes.push(_shape);
      }
      if (_package.Shapes.length == 0) {
        _package.Shapes = [
          {
            Quantity: 1,
          },
        ];
      }
      _package.ShapesLabel = "暂无数据";
      this.updatePackageShapesLabel(_package);

      _package.GrossWeight = row.GrossWeight;
      _package.Volume = row.Volume;

      _package.Products = [];
      for (const spec of row.PackageSpecs ?? []) {
        const _product =
          new DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct();
        _product.NameZh = spec.Spec?.Product?.NameZh;
        _product.NameEn = spec.Spec?.Product?.NameEn;
        _product.SpecName = spec.Spec?.Name;
        _product.Quantity = spec.Quantity;
        _product.ChargeUnitEn = spec.Spec?.Product?.ChargeUnithEn;
        _product.HsCode = spec.Spec?.Product?.HsCode;
        _product.Picture = spec.Spec?.SpecImages?.firstOrDefault()?.Url;
        _product.Material = spec.Spec?.Product?.Material;
        _product.Purpose = spec.Spec?.Product?.Purpose;
        _package.Products.push(_product);
      }
      if (_package.Products.length == 0) {
        _package.Products = [
          {
            ChargeUnitEn: "PIECE",
          },
        ];
      }
      _package.ProductsLabel = "暂无数据";
      this.updatePackageProductsLabel(_package);

      _package.Quantity = row.Quantity;

      _package.ReferenceIds = [{}];
      _package.ShipmentIdLabel = "暂无数据";

      this.form.Data.Packages?.push(_package);
      this.handleCalcPackagesNumber();
      this.handleClacGoodsDescription();
      console.log(_package);
    }
  }
  /**货物清单-从产品库导入 */
  handlePackageImport(): void {
    const CustomsPackage = this.$refs["CustomsPackage"] as any;
    CustomsPackage.onInit();
  }

  /**货物清单-手动添加 */
  btnAddPackage: MiezzButton = {
    Code: "add-package",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    // Size: "small",
  };
  /**货物清单-手动添加 */
  handlePackageAdd(): void {
    this.form.Data.Packages?.push({
      Shapes: [
        {
          Quantity: 1,
        },
      ],
      ShapesLabel: "暂无数据",
      Products: [
        {
          ChargeUnitEn: "PIECE",
        },
      ],
      ProductsLabel: "暂无数据",
      ReferenceIds: [{}],
      ShipmentIdLabel: "暂无数据",
    });
    if (this.listPackage.Data)
      this.listPackage.Data.Items = this.form.Data.Packages;
  }
  /**货物清单-表单校验 */
  packageRules = DtoFormOrderPool_OrderPoolPackageRules;
  handleShowPackagesChanged(): void {
    if (!this.form.Data.ShowPackages) {
      this.form.Data.Packages = [];
      console.log(2);
      if (this.listPackage.Data) this.listPackage.Data.Items = [];
    }
  }
  /**货物清单-行按钮 */
  handlePackageRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderPool_OrderPoolPackage,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        this.listPackage.Data?.Items?.splice(index, 1);
        complete();
        break;
      default:
        break;
    }
  }

  /**货物清单-规格与数量-模态框 */
  modalPackageShapes = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-规格与数量-列表配置 */
  listPackageShapes = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape,
    string
  >();
  // listPackageShapesRules =
  //   DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeRules;
  /**货物清单-规格与数量-初始化元素配置 */
  initPackageShapes(): void {
    this.modalPackageShapes.Title = "尺寸与数量";
    this.modalPackageShapes.Width = "1200px";
    this.modalPackageShapes.HandleClick = this.handleClick;
    this.modalPackageShapes.Buttons = [
      {
        Code: "package-shapes-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listPackageShapes.Fix = false;
    this.listPackageShapes.HideCard = true;
    this.listPackageShapes.EnabledDynamicSearch = false;
    this.listPackageShapes.EnabledRadioSearch = false;
    this.listPackageShapes.EnabledSelect = false;
    this.listPackageShapes.EnabledPagination = false;
    this.listPackageShapes.Items =
      DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzListItems;
    this.listPackageShapes.Data = {
      Items: [],
    };
    this.listPackageShapes.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];
  }

  /**货物清单-规格与数量-打开模态框 */
  handleShowModalPackageShapes(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if (this.listPackageShapes.Data)
      this.listPackageShapes.Data.Items = (row.Shapes ?? []).copy();
    this.modalPackageShapes.Show = true;
  }

  // updatePackageShapesLabel(_package: DtoFormOrderPool_OrderPoolPackage): void {
  //   _package.ShapesLabel = _package.Shapes?.map((it) =>
  //     it.Length && it.Width && it.Height
  //       ? (_package.Shapes?.length ?? 0) > 1
  //         ? `【${it.Length}×${it.Width}×${it.Height}×${it.Quantity}】`
  //         : `${it.Length}×${it.Width}×${it.Height}×${it.Quantity}`
  //       : (_package.Shapes?.length ?? 0) > 1
  //       ? `【暂无数据×${it.Quantity}】`
  //       : `暂无数据×${it.Quantity}`
  //   ).join("");
  // }
  updatePackageShapesLabel(_package: DtoFormOrderPool_OrderPoolPackage): void {
    _package.ShapesLabel = _package.Shapes?.map((it) =>
      it.Length && it.Width && it.Height
        ? (_package.Shapes?.length ?? 0) > 1
          ? `【${it.Length}×${it.Width}×${it.Height}】`
          : `${it.Length}×${it.Width}×${it.Height}`
        : (_package.Shapes?.length ?? 0) > 1
        ? `【暂无数据】`
        : `暂无数据`
    ).join("");
  }

  /**货物清单-规格与数量-计算体积 */
  handleCalcPackageShapeVolume(index: number): void {
    if (this.listPackageShapes.Data && this.listPackageShapes.Data.Items) {
      const shape = this.listPackageShapes.Data.Items[index];
      if (shape && shape.Length && shape.Width && shape.Height) {
        shape.Volume = parseFloat(
          ((shape.Length * shape.Width * shape.Height) / 1000000).toRound(4)
        );
      }
    }
  }

  /**货物清单-规格与数量-添加按钮 */
  btnAddPackageShape: MiezzButton = {
    Code: "add-package-shape",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    // Size: "small",
  };

  /**货物清单-规格与数量-行按钮点击 */
  handlePackageShapeRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        if (this.listPackageShapes.Data?.Items?.length == 1) {
          ElMessage.warning("请至少保留一条信息");
          complete();
          return;
        }
        this.listPackageShapes.Data?.Items?.splice(index, 1);
        complete();
        break;
      default:
        break;
    }
  }

  /**货物清单-产品名称-模态框 */
  modalPackageProducts = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-产品名称-列表配置 */
  listPackageProducts = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct,
    string
  >();
  /**货物清单-产品名称-初始化元素配置 */
  initPackageProducts(): void {
    this.modalPackageProducts.Title = "产品型号/数量/货值";
    this.modalPackageProducts.Width = "95%";
    this.modalPackageProducts.HandleClick = this.handleClick;
    this.modalPackageProducts.Buttons = [
      {
        Code: "package-products-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listPackageProducts.Fix = false;
    this.listPackageProducts.HideCard = true;
    this.listPackageProducts.EnabledDynamicSearch = false;
    this.listPackageProducts.EnabledRadioSearch = false;
    this.listPackageProducts.EnabledSelect = false;
    this.listPackageProducts.EnabledPagination = false;
    this.listPackageProducts.Items =
      DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzListItems;
    for (const item of this.listPackageProducts.Items) {
      switch (item.Prop) {
        case "NameZh":
        case "NameEn":
        case "UnitPrice":
        case "TotalPrice":
          item.CustomizeHeader = true;
          break;

        default:
          break;
      }
    }
    this.listPackageProducts.Data = {
      Items: [],
    };
    this.listPackageProducts.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];
  }

  /**货物清单-产品名称-打开模态框 */
  handleShowModalProductsLabel(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if (this.listPackageProducts.Data) {
      this.listPackageProducts.Data.Items = (row.Products ?? []).copy();
      for (const key in this.listPackageProducts.Data.Items) {
        const element = this.listPackageProducts.Data.Items[key];
        // if (element.Picture && !element.isCropper)
        if (element.Picture)
          element.PictureUrl = this.client!.signatureUrl(
            element.Picture
          ) as string;
      }
    }
    this.modalPackageProducts.Show = true;
  }

  updatePackageProductsLabel(
    _package: DtoFormOrderPool_OrderPoolPackage
  ): void {
    //更新按钮文本
    _package.ProductsLabel = _package.Products?.map((it) =>
      it.NameZh || it.NameEn
        ? (_package.Products?.length ?? 0) > 1
          ? `【${it.NameZh ?? "--"}/${it.NameEn ?? "--"}】`
          : `${it.NameZh ?? "--"}/${it.NameEn ?? "--"}`
        : (_package.Products?.length ?? 0) > 1
        ? "【暂无数据】"
        : "暂无数据"
    ).join("");
  }

  /**货物清单-产品名称-添加按钮 */
  btnAddPackageProduct: MiezzButton = {
    Code: "add-package-product",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    // Size: "small",
  };
  /**货物清单-产品名称-表单校验 */
  packageProductRules =
    DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductRules;

  /**货物清单-产品名称-行按钮点击 */
  handlePackageProductRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        if (this.listPackageProducts.Data?.Items?.length == 1) {
          ElMessage.warning("请至少保留一条信息");
          complete();
          return;
        }
        this.listPackageProducts.Data?.Items?.splice(index, 1);
        complete();
        break;
      default:
        break;
    }
  }

  currentChooseHsCodeRow?: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct;
  chooseHsCode(
    row: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct
  ): void {
    this.currentChooseHsCodeRow = row;
    (this.$refs["HsCode"] as MiezzHsCode).onInit(
      this.currentChooseHsCodeRow.HsCode ?? ""
    );
  }

  selectHsCode(row: any): void {
    if (this.currentChooseHsCodeRow) {
      this.currentChooseHsCodeRow.HsCode = row.Code;
    }
  }

  /**货物清单-产品名称-计算单价总价 */
  handleCalcPackageProductPrice(index: number): void {
    if (this.listPackageProducts.Data?.Items) {
      const item = this.listPackageProducts.Data.Items[index];
      if (item && item.Quantity) {
        if (item.UnitPrice != undefined) {
          item.TotalPrice = parseFloat(
            (item.Quantity * item.UnitPrice).toRound(2)
          );
        } else if (item.TotalPrice != undefined) {
          item.UnitPrice = parseFloat(
            (item.TotalPrice / item.Quantity).toRound(2)
          );
        }
      }
    }
  }

  /**货物清单-产品名称-计算总价 */
  handleCalcPackageProductTotalPrice(index: number): void {
    if (this.listPackageProducts.Data?.Items) {
      const item = this.listPackageProducts.Data.Items[index];
      if (item && item.Quantity) {
        if (item.UnitPrice != undefined) {
          item.TotalPrice = parseFloat(
            (item.Quantity * item.UnitPrice).toRound(2)
          );
        }
      }
    }
  }

  /**货物清单-产品名称-计算单价 */
  handleCalcPackageProductUnitPrice(index: number): void {
    if (this.listPackageProducts.Data?.Items) {
      const item = this.listPackageProducts.Data.Items[index];
      if (item && item.Quantity) {
        if (item.TotalPrice != undefined) {
          item.UnitPrice = parseFloat(
            (item.TotalPrice / item.Quantity).toRound(2)
          );
        }
      }
    }
  }

  /**货物清单-Shipment ID-模态框 */
  modalPackageShipmentId = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-Shipment-初始化元素配置 */
  initPackageShipmentId(): void {
    this.modalPackageShipmentId.Title = "编辑";
    this.modalPackageShipmentId.HandleClick = this.handleClick;
    this.modalPackageShipmentId.Buttons = [
      {
        Code: "package-shipment-id-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
  }
  /**货物清单-Shipment-打开模态框 */
  handleShowModalShipmentIdLabel(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if ((this.currentPackage.ReferenceIds?.length ?? 0) == 0) {
      this.currentPackage.ReferenceIds?.push({});
    }

    this.modalPackageShipmentId.Show = true;
  }

  /**货物清单-Shipment-添加按钮 */
  btnAddPackageShipmentId: MiezzButton = {
    Code: "add-package-shipment-id",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    // Size: "small",
  };
  /**货物清单-Shipment-添加按钮 */
  btnDeletePackageShipmentId: MiezzButton = {
    Code: "delete-package-shipment-id",
    Title: "删除",
    Icon: "delete",
    Type: "text",
    Size: "small",
  };
  /**货物清单-删除ShipmentId */
  handleDeletePackageShipmentId(index: number, complete: () => void): void {
    this.currentPackage.ReferenceIds?.splice(index, 1);
    complete();
  }

  /**计算装运数量 */
  handleCalcPackagesNumber(): void {
    let quantity = 0;
    let grossWeight = 0;
    let volume = 0;
    for (const item of this.form.Data.Packages ?? []) {
      if (item.Quantity) {
        quantity += item.Quantity;
        if (item.GrossWeight) {
          grossWeight += item.GrossWeight * item.Quantity;
        }
        if (item.Volume) {
          volume += item.Volume * item.Quantity;
        }
      }
    }
    if (quantity) {
      this.form.Data.PackagesNumber = quantity;
    }
    if (grossWeight) {
      this.form.Data.GrossWeight = parseFloat(grossWeight.toRound(2));
    }
    if (volume) {
      this.form.Data.Volume = parseFloat(volume.toRound(4));
    }
  }

  /**计算品名 */
  handleClacGoodsDescription(): void {
    const nameEns: string[] = [];
    for (const item of this.form.Data.Packages ?? []) {
      for (const product of item.Products ?? []) {
        if (product.NameEn && !nameEns.contains(product.NameEn)) {
          nameEns.push(product.NameEn);
        }
      }
    }
    this.form.Data.GoodsDescription = nameEns.map((it) => `${it}\n`).join("");
  }

  modalImportLog = new MiezzModal();
  importLogs: MiezzAlertLog[] = [];

  handleFileUpload(model: MiezzButton, file: any): void {
    this.modalImportLog.Show = true;
    this.importLogs = [];
    CurrentLogier.StopHubConnection(() => {
      CurrentLogier.StartHubConnection(() => {
        CurrentLogier.HubConnection?.off("AlertLog");
        CurrentLogier.HubConnection?.off("AlertProgress");
        CurrentLogier.HubConnection?.on(
          "AlertLog",
          (
            message: string,
            type: "success" | "warning" | "info" | "error",
            effect: "dark" | "light"
          ) => {
            this.importLogs.unshift({
              Message: message,
              Type: type,
              Effect: effect,
            });
          }
        );
        const formData = new FormData();
        formData.append("file", file);
        this.form.Data.Packages = [];
        console.log(3);
        OrderPoolAppService.Import(formData).then((r) => {
          for (const _package of r.data.Data?.Packages ?? []) {
            //根据规格与数量计算体积与毛重
            for (const shape of _package.Shapes ?? []) {
              const sizes = [shape.Length, shape.Width, shape.Height].sort(
                (a, b) => {
                  return (a ?? 0) - (b ?? 0);
                }
              );
              if (shape.Length != sizes[2]) {
                shape.Length = sizes[2];
              }
              if (shape.Width != sizes[1]) {
                shape.Width = sizes[1];
              }
              if (shape.Height != sizes[0]) {
                shape.Height = sizes[0];
              }
              if (shape.Length && shape.Width && shape.Height)
                shape.Volume = parseFloat(
                  (
                    (shape.Length * shape.Width * shape.Height) /
                    1000000
                  ).toRound(4)
                );
              if (shape.Volume) {
                _package.Volume = parseFloat(shape.Volume.toRound(4));
              }
              if (shape.GrossWeight) {
                _package.GrossWeight = parseFloat(shape.GrossWeight.toRound(2));
              }
            }

            if ((_package.Shapes?.length ?? 0) == 0) {
              _package.Shapes = [
                {
                  Quantity: 1,
                },
              ];
            }
            _package.ShapesLabel = "暂无数据";
            this.updatePackageShapesLabel(_package);
            if ((_package.Products?.length ?? 0) == 0) {
              _package.Products = [
                {
                  ChargeUnitEn: "PIECE",
                },
              ];
            }
            _package.ProductsLabel = "暂无数据";
            this.updatePackageProductsLabel(_package);
            for (const item of _package.Products ?? []) {
              item.ChargeUnitEn = "PIECE";
              if (item.TotalPrice && item.Quantity) {
                item.UnitPrice = parseFloat(
                  (item.TotalPrice / item.Quantity).toRound(2)
                );
              }
            }
            _package.ShipmentIdLabel = _package.ShipmentId
              ? _package.ShipmentId
              : "暂无数据";
            this.form.Data.Packages?.push(_package);
            console.log(this.form.Data.Packages, _package);
          }
          if (this.listPackage.Data)
            this.listPackage.Data.Items = this.form.Data.Packages ?? [];
          this.form.Data.ShipperContent = r.data.Data?.ShipperContent;
          this.form.Data.ConsigneeContent = r.data.Data?.ConsigneeContent;
          this.form.Data.NotifyPartyContent = r.data.Data?.NotifyPartyContent;
          this.form.Data.LoadingPortId = r.data.Data?.LoadingPortId;
          this.form.Data.LoadingPortLabel = r.data.Data?.LoadingPortLabel;
          this.form.Data.EstimatedDepartureDate =
            r.data.Data?.EstimatedDepartureDate;
          this.form.Data.DeliveryPortId = r.data.Data?.DeliveryPortId;
          this.form.Data.DeliveryPortLabel = r.data.Data?.DeliveryPortLabel;
          this.form.Data.DeliveryPlaceLabel = r.data.Data?.DeliveryPlaceLabel;
          this.form.Data.DeliveryPlaceAddress =
            r.data.Data?.DeliveryPlaceAddress;
          this.form.Data.ShowPackages = r.data.Data?.ShowPackages;
          this.form.Data.PackagesNumber = r.data.Data?.PackagesNumber;
          this.form.Data.GrossWeight = r.data.Data?.GrossWeight;
          this.form.Data.Volume = r.data.Data?.Volume;
          this.form.Data.GoodsDescription = r.data.Data?.GoodsDescription;
          this.handleCalcPackagesNumber();
        });
      });
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    let _package = new DtoFormOrderPool_OrderPoolPackage();
    switch (btn.Code) {
      case "download-template":
        window.open("/SHIPPING LIST.xlsx", "_blank");
        complete();
        break;
      //货物清单-从产品库导入
      case "import-packages":
        this.handlePackageImport();
        complete();
        break;
      //货物清单-手动添加
      case "add-package":
        this.handlePackageAdd();
        complete();
        break;
      //货物清单-添加规格与数量
      case "add-package-shape":
        this.listPackageShapes?.Data?.Items?.push({ Quantity: 1 });
        complete();
        break;
      //货物清单-保存规格与数量
      case "package-shapes-submit":
        (this.$refs["ruleFormPackageShapes"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document
                .getElementsByClassName("rule-form-package-shapes")[0]
                .getElementsByClassName("el-table__body")[0]
                .getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            if (this.form.Data.Packages && this.packageIndex != undefined) {
              _package = this.form.Data.Packages[this.packageIndex];

              _package.Shapes = this.listPackageShapes.Data?.Items ?? [];

              //根据规格与数量计算体积与毛重
              let volume = 0;
              let grossWeight = 0;

              for (const shape of _package.Shapes) {
                const sizes = [shape.Length, shape.Width, shape.Height].sort(
                  (a, b) => {
                    return (a ?? 0) - (b ?? 0);
                  }
                );
                if (shape.Length != sizes[2]) {
                  shape.Length = sizes[2];
                }
                if (shape.Width != sizes[1]) {
                  shape.Width = sizes[1];
                }
                if (shape.Height != sizes[0]) {
                  shape.Height = sizes[0];
                }

                if (shape.Quantity) {
                  if (shape.Volume) {
                    volume += shape.Volume * shape.Quantity;
                  }
                  if (shape.GrossWeight) {
                    grossWeight += shape.GrossWeight * shape.Quantity;
                  }
                }
              }

              this.updatePackageShapesLabel(_package);
              console.log(volume);
              if (volume) {
                _package.Volume = parseFloat(volume.toRound(4));
              }
              if (grossWeight) {
                _package.GrossWeight = parseFloat(grossWeight.toRound(2));
              }

              if (_package.Shapes.length > 1) {
                const product = _package.Products?.firstOrDefault();
                if (product) {
                  product.Quantity = 1;
                }
              }
            }

            this.handleCalcPackagesNumber();

            this.modalPackageShapes.Show = false;
            complete();
          }
        });
        break;
      //货物清单-添加产品名称
      case "add-package-product":
        this.listPackageProducts?.Data?.Items?.push({
          ChargeUnitEn: "PIECE",
        });
        complete();
        break;
      //货物清单-保存产品名称
      case "package-products-submit":
        (this.$refs["ruleFormPackageProducts"] as any).validate(
          (v: boolean) => {
            if (!v) {
              setTimeout(() => {
                const error = document
                  .getElementsByClassName("rule-form-package-products")[0]
                  .getElementsByClassName("el-table__body")[0]
                  .getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            } else {
              if (this.form.Data.Packages && this.packageIndex != undefined) {
                _package = this.form.Data.Packages[this.packageIndex];

                _package.Products = this.listPackageProducts.Data?.Items ?? [];
                for (const item of _package.Products) {
                  if (item && item.Quantity) {
                    if (item.UnitPrice != undefined) {
                      item.TotalPrice = parseFloat(
                        (item.Quantity * item.UnitPrice).toRound(2)
                      );
                    } else if (item.TotalPrice != undefined) {
                      item.UnitPrice = parseFloat(
                        (item.TotalPrice / item.Quantity).toRound(2)
                      );
                    }
                  }
                }
                //更新按钮文本
                _package.ProductsLabel = _package.Products.map((it) =>
                  it.NameZh
                    ? (_package.Products?.length ?? 0) > 1
                      ? `【${it.NameZh}】`
                      : `${it.NameZh}`
                    : (_package.Products?.length ?? 0) > 1
                    ? "【暂无数据】"
                    : "暂无数据"
                ).join("");
                (this.$refs["ruleForm"] as any).validateField(
                  `Packages[${this.packageIndex}].Products`
                );
              }
              this.modalPackageProducts.Show = false;
              this.handleClacGoodsDescription();
              complete();
            }
          }
        );
        break;
      case "add-package-shipment-id":
        this.currentPackage.ReferenceIds?.push({});
        complete();
        break;
      //货物清单-保存ShipmentId
      case "package-shipment-id-submit":
        (this.$refs["ruleFormPackageShipmentId"] as any).validate(
          (v: boolean) => {
            if (!v) {
              setTimeout(() => {
                const error = document
                  .getElementsByClassName("rule-form-package-shipment-id")[0]
                  .getElementsByClassName("el-table__body")[0]
                  .getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            } else {
              if (this.form.Data.Packages && this.packageIndex != undefined) {
                _package = this.form.Data.Packages[this.packageIndex];

                _package.ShipmentId = this.currentPackage.ShipmentId;
                _package.ReferenceId = this.currentPackage.ReferenceId;
                _package.ReferenceIds =
                  this.currentPackage.ReferenceIds?.filter(
                    (it) => it.ReferenceId
                  );

                //更新规格与数量按钮文本
                _package.ShipmentIdLabel = _package.ShipmentId ?? "暂无数据";
              }
              this.modalPackageShipmentId.Show = false;
              complete();
            }
          }
        );
        break;
      //保存表单
      case "submit":
        if (this.detail?.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("委托书已取消，无法修改");
          complete();
          return;
        }
        // 出入库管理-库存建单-保存
        if (this.orderDepartureWharfId) {
          (this.$refs["ruleForm"] as any).validate((v: boolean) => {
            if (!v) {
              setTimeout(() => {
                const error = document.getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            } else {
              ElMessageBox.confirm(
                "保存后原信息的出入库管理数据将被更改，是否继续",
                "提醒",
                {
                  confirmButtonText: "确认",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  OrderPoolAppService.AddstockByOrderDepartureWharfId(
                    this.form.Data,
                    this.orderDepartureWharfId
                  )
                    .then(() => {
                      if (
                        this.logier?.CompanyType ==
                        EnumCompanyType.ServiceProvider
                      ) {
                        ElMessage.success(
                          "委托书已添加，请及时通知审核人员审核"
                        );
                      } else {
                        var p1 = new DtoSynchronizePoolData();
                        p1.PoolId = this.form.Data.Id;
                        p1.SynchronizeType = 1;
                        OrderDepartureWharfAppService.SynchronizePoolData(
                          p1
                        ).then((r1) => {
                          if (r1.data.Data?.IsDeparture == true) {
                            var url1 = encodeURIComponent(this.$route.fullPath);
                            ElMessage.warning({
                              customClass: "testMessage",
                              dangerouslyUseHTMLString: true,
                              message:
                                '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/departure/wharf/' +
                                p1.PoolId +
                                "?backUrl=" +
                                url1 +
                                '" style="font-weight:900;" >这里</a>修改',
                            });
                          }
                          if (r1.data.Data?.IsDisestion == true) {
                            var url = encodeURIComponent(this.$route.fullPath);
                            ElMessage.warning({
                              customClass: "testMessage",
                              dangerouslyUseHTMLString: true,
                              message:
                                '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/destination/wharf/' +
                                p1.PoolId +
                                "?backUrl=" +
                                url +
                                '" style="font-weight:900;" >这里</a>修改',
                            });
                          }
                          ElMessage.success(
                            "委托书已发送，如有疑问请及时与客服联系"
                          );
                        });
                      }
                      CurrentLogier.Back();
                      complete();
                    })
                    .catch(complete);
                })
                .catch(() => {
                  complete();
                });
            }
          });
        } else {
          // 普通新建委托-保存
          for (const _package of this.form.Data.Packages ?? []) {
            _package.ReferenceIds = _package.ReferenceIds?.filter(
              (it) => it.ReferenceId
            );
          }
          (this.$refs["ruleForm"] as any).validate((v: boolean) => {
            if (!v) {
              setTimeout(() => {
                const error = document.getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            } else {
              OrderPoolAppService.Post(this.form.Data)
                .then(() => {
                  if (this.detail?.Status == EnumOrderPoolStatus.WaitingAdd) {
                    if (
                      this.logier?.CompanyType ==
                      EnumCompanyType.ServiceProvider
                    ) {
                      ElMessage.success("委托书已添加，请及时通知审核人员审核");
                    } else {
                      ElMessage.success(
                        "委托书已发送，如有疑问请及时与客服联系"
                      );
                    }
                    CurrentLogier.Back();
                    complete();
                  } else {
                    if (
                      this.logier?.CompanyType ==
                      EnumCompanyType.ServiceProvider
                    ) {
                      if (this.checkChange()) {
                        //var p2 = new DtoSynchronizePoolData();
                        //p2.PoolId = this.form.Data.Id;
                        //p2.SynchronizeType = 1;
                        //OrderDepartureWharfAppService.SynchronizePoolData(p2).then((r1) => {
                        //    if (r1.data.Data?.IsDeparture == true) {
                        //        var url2 = encodeURIComponent(this.$route.fullPath);
                        //        ElMessage.warning({
                        //            customClass: "testMessage",
                        //            dangerouslyUseHTMLString: true,
                        //            message: '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/departure/wharf/' + p2.PoolId + '?backUrl=' + url2 + '" style="font-weight:900;" >这里</a>修改',
                        //        })
                        //    }
                        //    if (r1.data.Data?.IsDisestion == true) {
                        //        var url20 = encodeURIComponent(this.$route.fullPath);
                        //        ElMessage.warning({
                        //            customClass: "testMessage",
                        //            dangerouslyUseHTMLString: true,
                        //            message: '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/destination/wharf/' + p2.PoolId + '?backUrl=' + url20 + '" style="font-weight:900;" >这里</a>修改',
                        //        })
                        //    }
                        //    ElMessage.success(
                        //        "委托书已发送，如有疑问请及时与客服联系"
                        //    );
                        //})
                        ElMessage.success("编辑成功");
                      }
                    } else {
                      ElMessage.success("编辑成功");
                    }
                    CurrentLogier.Back();
                    complete();
                  }
                })
                .catch(complete);
            }
          });
        }

        break;
      default:
        break;
    }
  }

  checkArrayChange(name: string, a: any[], b: any[]): boolean {
    if (a.length != b.length) {
      console.log(name, a.length, b.length);
      return false;
    } else {
      for (let i = 0; i < a.length; i++) {
        const oldItem = a[i];
        const newItem = b[i];
        for (const prop in oldItem) {
          const oldObj = oldItem[prop];
          const newObj = newItem[prop];
          if (
            (oldObj == undefined && newObj == null) ||
            (oldObj == null && newObj == undefined)
          ) {
            continue;
          }
          const oldJson = JSON.stringify(oldObj);
          const newJson = JSON.stringify(newObj);
          if (oldJson != newJson) {
            console.log(name, prop, oldJson, newJson);
            return false;
          }
        }
      }
    }
    return true;
  }

  checkChange(): boolean {
    let equal = true;
    if (this.detail?.OrderId) {
      if (this.detail && this.form.Data) {
        for (const prop in this.form.Data) {
          const oldObj = this.detail[prop];
          const newObj = this.form.Data[prop];
          if (
            (newObj == undefined && oldObj == null) ||
            (newObj == null && oldObj == undefined)
          ) {
            continue;
          }
          if (prop == "Packages") {
            if (
              !this.checkArrayChange(
                "Packages",
                this.detail.Packages ?? [],
                this.form.Data.Packages ?? []
              )
            ) {
              equal = false;
              continue;
            }
          } else if (prop == "Costs") {
            if (
              !this.checkArrayChange(
                "Costs",
                this.detail.Costs ?? [],
                this.form.Data.Costs ?? []
              )
            ) {
              equal = false;
              continue;
            }
          } else if (prop == "SupplierCosts") {
            if (
              !this.checkArrayChange(
                "SupplierCosts",
                this.detail.SupplierCosts ?? [],
                this.form.Data.SupplierCosts ?? []
              )
            ) {
              equal = false;
              continue;
            }
          } else if (prop == "SettlementCosts") {
            if (
              !this.checkArrayChange(
                "SettlementCosts",
                this.detail.SettlementCosts ?? [],
                this.form.Data.SettlementCosts ?? []
              )
            ) {
              equal = false;
              continue;
            }
          } else {
            const oldJson = JSON.stringify(oldObj);
            const newJson = JSON.stringify(newObj);
            if (oldJson != newJson) {
              console.log(prop, oldJson, newJson);
              equal = false;
              continue;
            }
          }
        }
      }
      if (!equal) {
        var orderId = "";
        if (this.detail) {
          orderId = this.detail.OrderId;
          var p3 = new DtoSynchronizePoolData();
          p3.PoolId = this.form.Data.Id;
          p3.SynchronizeType = 1;
          OrderDepartureWharfAppService.SynchronizePoolData(p3).then((r1) => {
            if (
              r1.data.Data?.IsDeparture == true &&
              r1.data.Data?.IsDisestion != true
            ) {
              var url2 = encodeURIComponent(this.$route.fullPath);
              //ElMessage.warning({
              //    customClass: "testMessage",
              //    dangerouslyUseHTMLString: true,
              //    message: '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/departure/wharf/' + p2.PoolId + '?backUrl=' + url2 + '" style="font-weight:900;" >这里</a>修改',
              //})

              ElMessageBox({
                title: "提示",
                message: h("p", null, [
                  h(
                    "span",
                    null,
                    "保存成功，委托书信息发生了变化，如需修正关联订单的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href: `#/order/summary/${orderId}?backUrl=${this.$route.fullPath}`,
                    },
                    "这里；"
                  ),
                  h(
                    "span",
                    null,
                    "如需修改装货港仓库/堆场/码头的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href:
                        "#/pool/departure/wharf/" +
                        p3.PoolId +
                        "?backUrl=" +
                        url2,
                    },
                    "这里；"
                  ),
                ]),
              });
            } else if (
              r1.data.Data?.IsDisestion == true &&
              r1.data.Data?.IsDeparture != true
            ) {
              var url20 = encodeURIComponent(this.$route.fullPath);
              //ElMessage.warning({
              //    customClass: "testMessage",
              //    dangerouslyUseHTMLString: true,
              //    message: '委托书货物数据与目的港-仓库中的计划货量数据不一致，如需修改请点击<a href="/#/pool/destination/wharf/' + p2.PoolId + '?backUrl=' + url20 + '" style="font-weight:900;" >这里</a>修改',
              //})

              ElMessageBox({
                title: "提示",
                message: h("p", null, [
                  h(
                    "span",
                    null,
                    "保存成功，委托书信息发生了变化，如需修正关联订单的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href: `#/order/summary/${orderId}?backUrl=${this.$route.fullPath}`,
                    },
                    "这里；"
                  ),
                  h(
                    "span",
                    null,
                    "如需修改目的港仓库/堆场/码头的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href:
                        "#/pool/destination/wharf/" +
                        p3.PoolId +
                        "?backUrl=" +
                        url20 +
                        "",
                    },
                    "这里；"
                  ),
                ]),
              });
            } else if (
              r1.data.Data?.IsDeparture == true &&
              r1.data.Data?.IsDisestion == true
            ) {
              var url3 = encodeURIComponent(this.$route.fullPath);
              ElMessageBox({
                title: "提示",
                message: h("p", null, [
                  h(
                    "span",
                    null,
                    "保存成功，委托书信息发生了变化，如需修正关联订单的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href: `#/order/summary/${orderId}?backUrl=${this.$route.fullPath}`,
                    },
                    "这里；"
                  ),
                  h(
                    "span",
                    null,
                    "如需修改装货港仓库/堆场/码头的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href:
                        "#/pool/departure/wharf/" +
                        p3.PoolId +
                        "?backUrl=" +
                        url3,
                    },
                    "这里；"
                  ),
                  h(
                    "span",
                    null,
                    "如需修改目的港仓库/堆场/码头的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href:
                        "#/pool/destination/wharf/" +
                        p3.PoolId +
                        "?backUrl=" +
                        url3 +
                        "",
                    },
                    "这里；"
                  ),
                ]),
              });
            } else {
              ElMessageBox({
                title: "提示",
                message: h("p", null, [
                  h(
                    "span",
                    null,
                    "保存成功，委托书信息发生了变化，如需修正关联订单的相关信息，请点击 "
                  ),
                  h(
                    "a",
                    {
                      href: `#/order/summary/${orderId}?backUrl=${this.$route.fullPath}`,
                    },
                    "这里"
                  ),
                ]),
              });
            }
          });
        }
      }
    }
    return equal;
  }
}
