
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoFormCompany, {
  DtoFormCompanyMiezzFormItems,
} from "@/models.machine/DtoFormCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyForm from "../../CompanyForm.vue";
@Options({
  components: {
    CompanyForm,
  },
})
export default class SupplierPrivateCompanyEdit extends Vue {
  type = EnumCompanyType.Supplier;
  form = new MiezzForm<DtoFormCompany, string>(
    "SupplierManagement_Private_Edit"
  );
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    let items = DtoFormCompanyMiezzFormItems.copy() as MiezzFormItem[];
    items = items.filter(
      (it) => it.Prop != "ServiceProviderCompanyId" && it.Prop != "SalesmanId" && it.Prop != "Source"
    );
    for (const item of items) {
      switch (item.Prop) {
        case "JoinCompanyTypes":
          item.Label = "服务类型";
          item.Multiple = true;
          break;

        default:
          break;
      }
    }
    this.form.Items = items;
    CompanyTypeAppService.GetSelect({ type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });
  }
}
