
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormStartDate {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 账单主键集合
     */
    BillIds?: string[];
    /**
     * 记账起始时间
     */
    StartDate?: Date;
}

/**
 * -表单校验
 */
export const DtoFormStartDateRules = {
}
