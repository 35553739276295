import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderPoolPackage_OrderPoolPackageShape from "./DtoListItemOrderPoolPackage_OrderPoolPackageShape";
import DtoListItemOrderPoolPackage_OrderPoolPackageProduct from "./DtoListItemOrderPoolPackage_OrderPoolPackageProduct";

/**
 * 委托书-货物清单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolPackage extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
        this.AddQuantity = 0;
    }

    [proName: string]: any;
    /**
     * 包装编号
     */
    Code?: string;
    /**
     * 箱规cm×cm×cm与数量
     */
    Shapes?: DtoListItemOrderPoolPackage_OrderPoolPackageShape[];
    /**
     * 毛重KGS
     */
    GrossWeight?: number;
    /**
     * 体积CBM
     */
    Volume?: number;
    /**
     * 产品名称
     */
    Products?: DtoListItemOrderPoolPackage_OrderPoolPackageProduct[];
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * 添加数量
     */
    AddQuantity?: number;
}

/**
 * 委托书-货物清单分页列表-项-表单校验
 */
export const DtoListItemOrderPoolPackageRules = {
}

/**
 * 委托书-货物清单分页列表-项-设置项
 */
export const DtoListItemOrderPoolPackageMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "包装编号",
  },
  {
    Prop: "Shapes",
    Label: "箱规cm×cm×cm与数量",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重KGS",
  },
  {
    Prop: "Volume",
    Label: "体积CBM",
  },
  {
    Prop: "Products",
    Label: "产品名称",
  },
  {
    Prop: "Quantity",
    Label: "数量",
  },
  {
    Prop: "AddQuantity",
    Label: "添加数量",
  },
]
