import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-545c9f32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  colspan: "5",
  class: "business-card"
}
const _hoisted_2 = {
  colspan: "5",
  rowspan: "3"
}
const _hoisted_3 = {
  colspan: "5",
  class: "business-card"
}
const _hoisted_4 = {
  colspan: "5",
  class: "business-card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_1, [
        _createVNode(_component_el_form_item, { label: "Shipper（发货人）" }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChoose(_ctx.EnumCompanyBusinessCardType.Shipper)))
            }, "选择")
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "ShipperContent",
          class: "content",
          "label-width": "0px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_miezz_input, {
              modelValue: _ctx.form.Data.ShipperContent,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ShipperContent) = $event)),
              type: "textarea",
              resize: "none",
              trim: false,
              red: _ctx.red
            }, null, 8, ["modelValue", "red"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("td", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_3, [
        _createVNode(_component_el_form_item, { label: "Consignee（收货人）" }, {
          default: _withCtx(() => [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChoose(_ctx.EnumCompanyBusinessCardType.Consignee)))
            }, "选择")
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          prop: "ConsigneeContent",
          class: "content",
          "label-width": "0px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_miezz_input, {
              modelValue: _ctx.form.Data.ConsigneeContent,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.ConsigneeContent) = $event)),
              type: "textarea",
              resize: "none",
              trim: false,
              red: _ctx.red
            }, null, 8, ["modelValue", "red"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_4, [
        _createVNode(_component_el_form_item, { label: "Notify Party（通知人）" }),
        _createVNode(_component_el_form_item, {
          prop: "NotifyPartyContent",
          class: "content",
          "label-width": "0px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_miezz_input, {
              modelValue: _ctx.form.Data.NotifyPartyContent,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.NotifyPartyContent) = $event)),
              type: "textarea",
              resize: "none",
              trim: false,
              disabled: _ctx.form.Data.NotifyPartyName == 'THE SAME AS CNEE',
              red: _ctx.red
            }, null, 8, ["modelValue", "disabled", "red"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalChoose,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modalChoose) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.listChoose,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.listChoose) = $event)),
          leftHeaderWidth: "100%",
          onHandleGet: _ctx.handleGet
        }, null, 8, ["modelValue", "onHandleGet"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}