import UiSelectOption from "./UiSelectOption";

/**
 * 承运人类别
 * @description 自动生成的代码,请勿修改
 */
export enum EnumParamCarrierType {
    /**
     * 船舶运输
     */
    Ship = 1,
    /**
     * 航空运输
     */
    Air = 2,
    /**
     * 铁路运输
     */
    Train = 3,
    /**
     * 陆路运输
     */
    Truck = 4,
    /**
     * 快递运输
     */
    Delivery = 5,
}

/**
 * 承运人类别-选项
 */
export const EnumParamCarrierTypeOption: UiSelectOption<number>[] = [
    { Label: "船舶运输", Value: 1, Data: "Ship" },
    { Label: "航空运输", Value: 2, Data: "Air" },
    { Label: "铁路运输", Value: 3, Data: "Train" },
    { Label: "陆路运输", Value: 4, Data: "Truck" },
    { Label: "快递运输", Value: 5, Data: "Delivery" },
];
