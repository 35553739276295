import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-对账状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumBillCheckStatus {
    /**
     * 未核对
     */
    No = 10,
    /**
     * 有差异
     */
    Different = 20,
    /**
     * 核对一致
     */
    Same = 30,
}

/**
 * 枚举-对账状态-选项
 */
export const EnumBillCheckStatusOption: UiSelectOption<number>[] = [
    { Label: "未核对", Value: 10, Data: "No" },
    { Label: "有差异", Value: 20, Data: "Different" },
    { Label: "核对一致", Value: 30, Data: "Same" },
];
