import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";

/**
 * 账单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemOverviewSaler extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Collection = [];
        this.Collectioned = [];
        this.Cost = [];
        this.Settlement = [];
        this.Interest = [];
        this.Payment = [];
        this.Actual = [];
    }

    [proName: string]: any;
    /**
     * 业务员
     */
    SalerName?: string;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 应收
     */
    Collection?: DtoCallbackCountCurrency[];
    /**
     * 应收
     */
    CollectionString?: string;
    /**
     * 已收
     */
    Collectioned?: DtoCallbackCountCurrency[];
    /**
     * 已收
     */
    CollectionedString?: string;
    /**
     * 成本
     */
    Cost?: DtoCallbackCountCurrency[];
    /**
     * 成本
     */
    CostString?: string;
    /**
     * 结算价
     */
    Settlement?: DtoCallbackCountCurrency[];
    /**
     * 结算价
     */
    SettlementString?: string;
    /**
     * 逾期利息
     */
    Interest?: DtoCallbackCountCurrency[];
    /**
     * 逾期利息
     */
    InterestString?: string;
    /**
     * 应发
     */
    Payment?: DtoCallbackCountCurrency[];
    /**
     * 应发合计
     */
    PaymentString?: string;
    /**
     * 实发合计
     */
    Actual?: DtoCallbackCountCurrency[];
    /**
     * 实发合计
     */
    ActualString?: string;
    /**
     * 操作
     */
    OperationString?: string;
}

/**
 * 账单分页列表-项-表单校验
 */
export const DtoPageListItemOverviewSalerRules = {
}

/**
 * 账单分页列表-项-设置项
 */
export const DtoPageListItemOverviewSalerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "SalerName",
    Label: "业务员",
    Customize: true,
  },
  {
    Prop: "CollectionString",
    Label: "应收",
    Customize: true,
  },
  {
    Prop: "CollectionedString",
    Label: "已收",
    Customize: true,
  },
  {
    Prop: "CostString",
    Label: "成本",
    Customize: true,
  },
  {
    Prop: "SettlementString",
    Label: "结算价",
    Customize: true,
  },
  {
    Prop: "InterestString",
    Label: "逾期利息",
    Customize: true,
  },
  {
    Prop: "PaymentString",
    Label: "应发合计",
    Customize: true,
  },
  {
    Prop: "ActualString",
    Label: "实发合计",
    Customize: true,
  },
  {
    Prop: "OperationString",
    Label: "操作",
    Customize: true,
  },
]
