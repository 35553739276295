import DtoCallbackBLNOItem from "./DtoCallbackBLNOItem";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackBLNO {
    constructor() {
        //无参构造函数
        this.Visible = false;
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 主单的提运单号
     */
    BLNO?: string;
    /**
     * 关务的提运单号
     */
    Items?: DtoCallbackBLNOItem[];
    /**
     * 是否可见
     */
    Visible?: boolean;
    /**
     * 
     */
    Type?: EnumOrderServiceType;
}

/**
 * -表单校验
 */
export const DtoCallbackBLNORules = {
}
