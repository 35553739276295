
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import DtoPageListSearchProjectSite, {
  DtoPageListSearchProjectSiteDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchProjectSite";
import DtoPageListProjectSite from "@/models.machine/DtoPageListProjectSite";
import DtoListItemProjectSite, {
  DtoListItemProjectSiteMiezzListItems,
} from "@/models.machine/DtoListItemProjectSite";
import ProjectSiteDetail from "./ProjectSiteDetail.vue";
import ProjectSiteForm from "./ProjectSiteForm.vue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ProjectAppService from "@/apis.machine/ProjectAppService";
import ExecCommand from "./ExecCommand.vue";
import ProjectSiteExecInfo from "./ProjectSiteExecInfo";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    ProjectSiteDetail,
    ProjectSiteForm,
    ExecCommand,
  },
})
export default class ProjectSitePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchProjectSite,
    DtoListItemProjectSite,
    string
  >("DeveloperTools_Site");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();
  projects?: UiSelectOption<string>[] = [];
  modelExecCommand = new MiezzModal();
  execs: ProjectSiteExecInfo[] = [];
  execProjectId?: string;

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchProjectSite();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchProjectSiteDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListProjectSite();
    //列表-列配置
    this.list.Items = DtoListItemProjectSiteMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Project") return row.Project?.Name;
      return undefined;
    };
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "copy",
        Title: "复制",
        Icon: "documentCopy",
        Type: "default",
        Size: "small",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Type: "text",
        Message: "是否确认删除",
      },
      {
        Code: "batch-exec-command",
        Label: "执行命令",
        Type: "text",
      },
    ];

    ProjectAppService.GetSelect().then((r) => {
      this.projects = r.data.Data;
    });
  }

  /**查询列表 */
  handleGet(): void {
    ProjectSiteAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    ProjectSiteAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增项目-站点";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemProjectSite,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `项目-站点详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      case "copy":
        ProjectSiteAppService.CopyById(row.Id)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    let projectIds;
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ProjectSiteAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-exec-command":
        projectIds = Array.from(
          new Set(this.list.SelectRows.map((it) => it.ProjectId))
        );
        if (projectIds.length > 1) {
          ElMessage.warning("请选择相同项目的站点");
          complete();
        } else {
          this.execProjectId = projectIds.firstOrDefault();
          this.execs = this.list.SelectRows.map((it) => {
            const exec = new ProjectSiteExecInfo();
            exec.Id = it.Id;
            exec.Name = it.Name;
            return exec;
          });
          this.modelExecCommand.Show = true;
          const names = this.list.SelectRows.map((it) => it.Name).join(",");
          this.modelExecCommand.Title = `项目-站点-执行命令：${names}`;
          complete();
        }
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
