import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import DtoDetailOrderSummary_Company from "./DtoDetailOrderSummary_Company";
import DtoDetailOrderSummary_OrderContainer from "./DtoDetailOrderSummary_OrderContainer";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import DtoDetailOrderSummary_OrderPool_Supplier from "./DtoDetailOrderSummary_OrderPool_Supplier";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderSummary_OrderPool extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Client = "";
        this.Containers = [];
        this.CargoQuantity = "/KGS/CBM";
        this.DeparturePortBooking = false;
        this.DeparturePortTake = false;
        this.DeparturePortWarehouse = false;
        this.DeparturePortDeclareCustoms = false;
        this.DeparturePortService = "";
        this.DestinationPortClearCustoms = false;
        this.DestinationPortDelivery = false;
        this.DestinationPortWarehouse = false;
        this.DestinationPortService = "";
        this.ContainerTrailers = [];
        this.Expresses = [];
        this.Trunks = [];
        this.Customs = [];
        this.Wharfs = [];
        this.HasTask = false;
    }

    [proName: string]: any;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 委托公司
     */
    ClientCompany?: DtoDetailOrderSummary_Company;
    /**
     * 委托公司
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * 委托人
     */
    Client?: string;
    /**
     * 业务员
     */
    Salesman?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 装运包装与数量
     */
    PackagesNumberKind?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 集装箱信息
     */
    Containers?: DtoDetailOrderSummary_OrderContainer[];
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 装货港服务-订舱
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 装货港服务
     */
    DeparturePortService?: string;
    /**
     * 目的港服务-清关
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
    /**
     * 目的港服务
     */
    DestinationPortService?: string;
    /**
     * 集装箱拖车
     */
    ContainerTrailers?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 快递
     */
    Expresses?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 干线物流
     */
    Trunks?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 报关
     */
    Customs?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 仓库
     */
    Wharfs?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 有本人的待办
     */
    HasTask?: boolean;
}

/**
 * -表单校验
 */
export const DtoDetailOrderSummary_OrderPoolRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderSummary_OrderPoolMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "TransportContainerLoad",
    Label: "集装箱装载方式",
    Pipe: EnumPipe.Enum,
    Options: EnumTransportContainerLoadOption,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "Client",
    Label: "委托人",
  },
  {
    Prop: "Salesman",
    Label: "业务员",
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
  },
  {
    Prop: "DeparturePortService",
    Label: "装货港服务",
  },
  {
    Prop: "DestinationPortService",
    Label: "目的港服务",
  },
]

/**
 * -设置项
 */
export const DtoDetailOrderSummary_OrderPoolMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TransportContainerLoad",
    Label: "集装箱装载方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumTransportContainerLoadOption,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "Client",
    Label: "委托人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Salesman",
    Label: "业务员",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeparturePortService",
    Label: "装货港服务",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DestinationPortService",
    Label: "目的港服务",
    Type: EnumMiezzFormItemType.Text,
  },
]
