
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import PackageEnAppService from "@/apis.machine/PackageEnAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormOrderPool_OrderPoolPackage from "@/models.machine/DtoFormOrderPool_OrderPoolPackage";
export default class OrderFormProductsSummary extends Vue {
  @Model() form!: MiezzForm<
    {
      MarksNotes?: string;
      PackagesNumber?: number;
      PackagesKind?: string;
      PackagesNumberKind?: string;
      GoodsDescription?: string;
      GoodsDescriptionRemark?: string;
      GrossWeight?: number;
      Volume?: number;
      Packages?: DtoFormOrderPool_OrderPoolPackage[];
    },
    string
  >;
  @Prop() reds: string[] = [];

  packageKinds: UiSelectOption<string>[] = [];

  created(): void {
    const logier = CurrentLogier.GetLogier();
    PackageEnAppService.GetSelect({ CompanyId: logier?.CompanyId }).then(
      (r) => {
        this.packageKinds = r.data.Data ?? [];
      }
    );
  }

  /**获取装运类型 */
  getPackageKinds(q: string, cb: any): void {
    cb(
      this.packageKinds.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeleteContact(select: UiSelectOption<string>, value: string): void {
    PackageEnAppService.RemoveHistoryById(select.Value).then(() => {
      const old = this.packageKinds.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.form.Data.PackagesKind = undefined;
      } else {
        this.form.Data.PackagesKind = old?.Label;
      }
      this.packageKinds = this.packageKinds.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }
}
