import DtoListItemFinance_ParamCurrencySystem from "./DtoListItemFinance_ParamCurrencySystem";
import { EnumSettlementMode, EnumSettlementModeOption } from "./EnumSettlementMode";
import DtoSettlementTime from "./DtoSettlementTime";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestBatchSettlementItem {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 账单主键
     */
    BillId?: string;
    /**
     * 账单主键
     */
    BillIds?: string[];
    /**
     * 对账对象
     */
    BillObject?: string;
    /**
     * 收款方抬头
     */
    CollectionTitle?: string;
    /**
     * 付款方抬头
     */
    PaymentTitle?: string;
    /**
     * 总金额
     */
    Total?: number;
    /**
     * 待核销
     */
    NoSettlementPrice?: number;
    /**
     * 剩余
     */
    SurplusPrice?: number;
    /**
     * 本次核销金额
     */
    Price?: number;
    /**
     * 折算金额
     */
    ConvertPrice?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoListItemFinance_ParamCurrencySystem;
    /**
     * 汇率
     */
    Rate?: number;
    /**
     * 汇率
     */
    DisplayRate?: number;
    /**
     * 时间
     */
    SettlementDate?: Date;
    /**
     * 时间
     */
    SettlementDateString?: string;
    /**
     * 核销方式
     */
    Mode?: EnumSettlementMode;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 账单币制
     */
    BillCurrencySystemId?: string;
    /**
     * 账单币制
     */
    BillCurrencySystemCode?: string;
    /**
     * 账单币制
     */
    BillCurrencySystem?: DtoListItemFinance_ParamCurrencySystem;
    /**
     * 默认的收款方抬头
     */
    DefaultCollectionTitle?: string;
    /**
     * 默认的付款方抬头
     */
    DefaultPaymentTitle?: string;
    /**
     * 收款方抬头集合
     */
    CollectionTitles?: DtoSettlementTime[];
    /**
     * 付款方抬头集合
     */
    PaymentTitles?: DtoSettlementTime[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 
     */
    IsZero?: boolean;
    /**
     * 
     */
    IsBad?: boolean;
}

/**
 * -表单校验
 */
export const DtoRequestBatchSettlementItemRules = {
}
