import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e2a7aac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"-30px"} }
const _hoisted_2 = {
  key: 0,
  style: {"float":"left","width":"115px"}
}
const _hoisted_3 = { style: {"clear":"both"} }
const _hoisted_4 = { style: {"clear":"both","margin-top":"-40px"} }
const _hoisted_5 = { style: {"list-style-type":"none","margin-left":"30px"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  style: {"color":"rgb(254, 153, 91)"}
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "task" }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_summary_time = _resolveComponent("order-summary-time")!
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_miezz_common_history = _resolveComponent("miezz-common-history")!
  const _component_DArrowLeft = _resolveComponent("DArrowLeft")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_DArrowRight = _resolveComponent("DArrowRight")!
  const _component_order_task = _resolveComponent("order-task")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_order_supplier = _resolveComponent("order-supplier")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, {
      center: _withCtx(() => [
        _createVNode(_component_order_summary_time, { id: _ctx.id }, null, 8, ["id"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_card, {
      bottomButtons: _ctx.buttons,
      onHandleFooterButtonClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_button, {
          model: _ctx.btnTask,
          onHandleClick: _ctx.handleClick
        }, null, 8, ["model", "onHandleClick"]),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isShow==true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "   "))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            style: _normalizeStyle([{"float":"left"}, _ctx.isShow==true?'width: calc(100% - 120px)':'wdith:100%;'])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_miezz_form, {
                modelValue: _ctx.detail,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detail) = $event)),
                isDetail: true
              }, {
                "form-item": _withCtx(({ item }) => [
                  (item.Prop == 'Code')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: item.Label,
                        prop: item.Prop
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.detail.Data.Code) + " ", 1),
                          _createVNode(_component_miezz_button, {
                            model: _ctx.btnUpdateCode,
                            onHandleClick: _ctx.handleClick
                          }, null, 8, ["model", "onHandleClick"])
                        ]),
                        _: 2
                      }, 1032, ["label", "prop"]))
                    : _createCommentVNode("", true),
                  (item.Prop == 'UseThreeApiService')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: item.Label,
                        prop: item.Prop
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_switch, {
                            modelValue: _ctx.detail.Data.UseThreeApiService,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.UseThreeApiService) = $event)),
                            "active-value": true,
                            "inactive-value": false,
                            onChange: _ctx.onChangedUseThreeApiService
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 2
                      }, 1032, ["label", "prop"]))
                    : _createCommentVNode("", true),
                  (item.Prop == 'TransportDirection')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        label: item.Label,
                        prop: item.Prop
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.type), 1)
                        ]),
                        _: 2
                      }, 1032, ["label", "prop"]))
                    : _createCommentVNode("", true),
                  (item.Prop == 'Suppliers')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 3,
                        label: item.Label,
                        prop: item.Prop
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_page_list, {
                            modelValue: _ctx.listSupplier,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.listSupplier) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["label", "prop"]))
                    : (item.Prop == 'Pools')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 4,
                          label: item.Label,
                          prop: item.Prop,
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_miezz_page_list, {
                              modelValue: _ctx.listPool,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.listPool) = $event)),
                              style: {"width":"100%"},
                              onHandleRowButtonClick: _ctx.handleRowButtonClick
                            }, {
                              column: _withCtx(({ model, row }) => [
                                (model.Prop == 'BookingNo')
                                  ? (_openBlock(), _createBlock(_component_router_link, {
                                      key: 0,
                                      target: "_blank",
                                      to: `/order/pool/summary/${row.Id}`
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_miezz_text_ellipsis, {
                                          text: row.BookingNo
                                        }, null, 8, ["text"])
                                      ]),
                                      _: 2
                                    }, 1032, ["to"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["modelValue", "onHandleRowButtonClick"])
                          ]),
                          _: 2
                        }, 1032, ["label", "prop"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_form_item, {
                "label-width": "150px",
                label: "供应商信息",
                style: {"width":"100%"},
                prop: _ctx.listSupplier
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_page_list, {
                    modelValue: _ctx.listSupplier,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.listSupplier) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["prop"]),
              _createVNode(_component_el_form_item, {
                label: "委托信息",
                "label-width": "150px",
                prop: _ctx.listPool,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_page_list, {
                    modelValue: _ctx.listPool,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.listPool) = $event)),
                    style: {"width":"100%"},
                    onHandleRowButtonClick: _ctx.handleRowButtonClick
                  }, {
                    column: _withCtx(({ model, row }) => [
                      (model.Prop == 'BookingNo')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            target: "_blank",
                            to: `/order/pool/summary/${row.Id}`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_text_ellipsis, {
                                text: row.BookingNo
                              }, null, 8, ["text"])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onHandleRowButtonClick"])
                ]),
                _: 1
              }, 8, ["prop"])
            ])
          ], 4)
        ])
      ]),
      _: 1
    }, 8, ["bottomButtons", "onHandleFooterButtonClick"]),
    _createVNode(_component_miezz_common_history, {
      modelValue: _ctx.histories,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.histories) = $event)),
      isShow: _ctx.isShow,
      "label-width": "190px"
    }, null, 8, ["modelValue", "isShow"]),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_el_card, {
          key: 0,
          class: "more-wrapper"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              style: {"position":"absolute","height":"calc(100% - 10px)","cursor":"pointer","display":"flex"},
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.changeShow && _ctx.changeShow(...args)))
            }, [
              _createVNode(_component_el_icon, { style: {"align-self":"center"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_DArrowLeft)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btnMore.Options?.filter(
          (it) => !it.PowerCode || _ctx.powerCodes?.contains(it.PowerCode)
        ), (button, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (_ctx.onClickLi(button))
                }, [
                  _createVNode(_component_el_button, {
                    type: "text",
                    style: _normalizeStyle(
            'font-size: 12px;' +
            (_ctx.$route.query.active == button.Code
              ? ' text-decoration: underline 2px; text-underline-offset: 5px'
              : '')
          )
                  }, {
                    default: _withCtx(() => [
                      (button.Active)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(button.Label), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(button.Label), 1))
                    ]),
                    _: 2
                  }, 1032, ["style"])
                ], 8, _hoisted_6))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "dArrowRight",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.changeShow && _ctx.changeShow(...args)))
        }, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.detail.Data.TaskActiveCount), 1),
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_DArrowRight)
            ]),
            _: 1
          })
        ])),
    _createVNode(_component_order_task, {
      modelValue: _ctx.modalTask,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalTask) = $event)),
      url: "/order",
      listPower: "OrderManagement_Order_Task",
      userPower: "OrderManagement_Order",
      id: _ctx.id,
      cancel: _ctx.detail.Data.Status == _ctx.EnumOrderStatus.Canceled
    }, null, 8, ["modelValue", "id", "cancel"]),
    _createVNode(_component_order_task, {
      modelValue: _ctx.modalPoolTask,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalPoolTask) = $event)),
      url: "/order/pool",
      listPower: "OrderManagement_Pool_Task",
      userPower: "OrderManagement_Pool",
      id: _ctx.poolId
    }, null, 8, ["modelValue", "id"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalUpdateCode,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.modalUpdateCode) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "ref-form-update-code",
          model: _ctx.formUpdateCode.Data,
          rules: _ctx.formUpdateCode.Rules,
          "label-width": "200px",
          inline: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "订单编号",
              prop: "Code"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.formUpdateCode.Data.Code,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formUpdateCode.Data.Code) = $event)),
                  pattern: "[0-9a-zA-Z]",
                  upperCase: true,
                  trimAll: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDefaultSupplier,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.modalDefaultSupplier) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.listDefaultSupplier,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.listDefaultSupplier) = $event))
        }, {
          column: _withCtx(({ model, row }) => [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.handleChooseSupplier(model.Prop))
            }, [
              _createVNode(_component_miezz_text_ellipsis, {
                text: row[model.Prop],
                style: {"color":"#409eff"}
              }, null, 8, ["text"])
            ], 8, _hoisted_10)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_order_supplier, {
      ref: "ref-order-supplier",
      show: _ctx.showSupplier,
      canEdit: true,
      canSync: true,
      onHandleSave: _ctx.handleSaveSupplier
    }, null, 8, ["show", "onHandleSave"])
  ], 64))
}