
import { Vue, Options } from "vue-class-component";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MenuAppService from "@/apis.machine/MenuAppService";
import DtoPageListItemMenu, {
  DtoPageListItemMenuMiezzListItems,
} from "@/models.machine/DtoPageListItemMenu";
import { EnumMenuPowerOption } from "@/models.machine/EnumMenuPower";
import MiezzButton from "@/models/MiezzButton";
import DtoPageListSearchMenu, {
  DtoPageListSearchMenuDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchMenu";
import DtoPageListMenu from "@/models.machine/DtoPageListMenu";
import MenuForm from "./MenuForm.vue";
import MenuDetail from "./MenuDetail.vue";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import router from "@/router";
import { ElMessage } from "element-plus";
import MiezzPageListComponent from "@/components/MiezzPageList.vue";
import { EnumMenuSceneOption } from "@/models.machine/EnumMenuScene";

@Options({
  components: {
    MenuForm,
    MenuDetail,
  },
})
export default class MenuPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<DtoPageListSearchMenu, DtoPageListItemMenu, string>(
    "System_Menu_List"
  );

  modalDetail = new MiezzModal();
  modalForm = new MiezzModal();

  select = new DtoPageListItemMenu();

  id?: string;
  path?: string;

  EnumMenuPowerOption = EnumMenuPowerOption;

  listRef?: InstanceType<typeof MiezzPageListComponent>;

  /**创建时 */
  created(): void {
    this.list.Dto = new DtoPageListSearchMenu();
    this.list.Dto.RadioSearch = "0";
    this.list.RadioSearchItems = EnumMenuSceneOption;
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchMenuDynamicSearchItems;
    this.list.Data = new DtoPageListMenu();
    this.list.Items = DtoPageListItemMenuMiezzListItems;
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "switch",
        Title: "切换为树视图",
        Icon: "switch",
        Type: "primary",
        Size: "small",
      },
    ];
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "top",
        Title: "上一层",
        Icon: "top",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "list-children",
        Title: "查看子集",
        Icon: "more",
        Type: "primary",
        Size: "small",
      },
    ];
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
      },
    ];
    this.list.CheckRowButtonShow = this.checkRowButtonShow;
  }

  mounted(): void {
    this.listRef = this.$refs["page-list"] as InstanceType<
      typeof MiezzPageListComponent
    >;
  }

  /**校验行按钮是否显示 */
  checkRowButtonShow(btn: MiezzButton, row: DtoPageListItemMenu): boolean {
    if (btn.Code == "top" && !row.ParentId) {
      return false;
    }
    return true;
  }

  /**查询列表 */
  handleGet(): void {
    MenuAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增菜单";
        this.id = undefined;
        this.path = "/";
        complete();
        break;
      case "switch":
        router.push("/sys/menu/tree");
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoPageListItemMenu,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `菜单详细-${row.Name}`;
        this.id = row.Id;
        this.path = undefined;
        complete();
        break;
      case "top":
        if (row.ParentId && row.Path) {
          let path: string = row.Path;
          path = path.substring(0, path.lastIndexOf("/"));
          path = path.substring(0, path.lastIndexOf("/") + 1);
          this.searchPath(path, complete);
        } else complete();
        break;
      case "list-children":
        if (row.Path && row.Name)
          this.searchPath(`${row.Path}${row.Name}/`, complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "batch-delete":
        MenuAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**搜索路径 */
  searchPath(path: string, complete: () => void): void {
    const search = new DtoFormDynamicSearch();
    search.Code = "DtoPageListMenu";
    search.Brackets = [
      {
        Relation: EnumRelation.And,
        Items: [
          {
            Relation: EnumRelation.And,
            Where: {
              Prop: "DynamicSearch_Path",
              Compare: EnumCompare.Equal,
              Value: path,
            },
          },
        ],
      },
    ];
    DynamicSearchAppService.Post(search)
      .then((r) => {
        if (this.list.Dto) {
          this.list.Dto.RadioSearch = "DynamicSearch";
          this.list.Dto.DynamicSearchId = r.data.Data;
          this.listRef?.handleGet();
        }
        complete();
      })
      .catch(complete);
  }

  /**表单提交 */
  handleSubmit(complete: () => void): void {
    this.modalForm.Show = false;
    this.handleGet();
    complete();
  }

  /**删除 */
  handleDelete(complete: () => void): void {
    this.modalDetail.Show = false;
    this.handleGet();
    complete();
  }
}
