
import HtmlToPdf from "@/apis/HtmlToPdf";
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzCard extends Vue {
  @Prop() model?: any;
  @Prop() title?: string;
  @Prop() description?: string;
  @Prop() rightTitleWidth?: string;
  @Prop() leftHeaderWidth?: string;
  @Prop() isTitle?: boolean;
  @Prop() topButtons?: MiezzButton[] = [];
  @Prop() bottomButtons?: MiezzButton[] = [];
  @Prop() fix?: boolean;
  @Prop() fixTop?: string;
  @Prop() disabledScrollbar?: boolean;
  @Prop() hide?: boolean;
  @Prop() checkTopButtonShow?: (btn: MiezzButton) => boolean;
  @Prop() reserveEmptyHeader?: boolean;
  @Prop() toggle?: boolean;
  @Prop() down?: boolean;

  loading = false;
  _down = false;
  hideHeaderRight = false;

  created(): void {
    //
    if (this.down != undefined) {
      this._down = this.down;
    }
  }

  /**按钮点击 */
  @Emit()
  handleHeaderButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "print-pdf":
        this.handlePrintPdf(btn, complete);
        break;
      default:
        break;
    }
  }

  @Emit()
  handleHeaderButtonUpload(model: MiezzButton, file: any): void {
    //
  }

  @Emit()
  handleFooterButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "print-pdf":
        this.handlePrintPdf(btn, complete);
        break;
      default:
        break;
    }
  }

  @Emit()
  handleHeaderClick(e: any): void {
    if (this.toggle && e.target.nodeName == "DIV") {
      this._down = !this._down;
      this.handleToggle(this._down);
    }
  }

  @Emit()
  handleToggle(down: boolean): void {
    //
  }

  handlePrintPdf(btn: MiezzButton, complete: () => void): void {
    if (btn.PdfName) {
      // this.loading = true;
      this.hideHeaderRight = true;
      this._down = false;
      this.handleToggle(this._down);
      setTimeout(() => {
        HtmlToPdf.doanload(
          (this.$refs["ref-card"] as any).$el,
          btn.PdfName as string
        ).then(() => {
          this.hideHeaderRight = false;
          // this.loading = false;
          complete();
        });
      }, 1);
    }
  }
}
