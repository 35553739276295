import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_service_detail_cost_composition_surcharge = _resolveComponent("price-service-detail-cost-composition-surcharge")!
  const _component_price_page_list = _resolveComponent("price-page-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_price_service_detail_cost_composition_surcharge),
    _createVNode(_component_price_page_list, {
      ref: "ref-price-page-list",
      list: _ctx.list,
      amountType: _ctx.amountType,
      toggle: true,
      surcharge: true
    }, null, 8, ["list", "amountType"])
  ], 64))
}