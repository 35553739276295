import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EntityState, EntityStateOption } from "./EntityState";

/**
 * 审计日志分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemAuditLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.State = EntityState.Detached;
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 表名
     */
    Table?: string;
    /**
     * 主键
     */
    PrimaryKey?: string;
    /**
     * 列名
     */
    Column?: string;
    /**
     * 操作方式
     */
    State?: EntityState;
    /**
     * 旧值
     */
    OldValue?: string;
    /**
     * 新值
     */
    NewValue?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
}

/**
 * 审计日志分页列表-项-表单校验
 */
export const DtoListItemAuditLogRules = {
}

/**
 * 审计日志分页列表-项-设置项
 */
export const DtoListItemAuditLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Table",
    Label: "表名",
  },
  {
    Prop: "PrimaryKey",
    Label: "主键",
  },
  {
    Prop: "Column",
    Label: "列名",
  },
  {
    Prop: "State",
    Label: "操作方式",
    Pipe: EnumPipe.Enum,
    Options: EntityStateOption,
  },
  {
    Prop: "OldValue",
    Label: "旧值",
  },
  {
    Prop: "NewValue",
    Label: "新值",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
  },
]
