import UiSelectOption from "./UiSelectOption";

/**
 * 历史记录状态
 * @description 自动生成的代码,请勿修改
 */
export enum UiHistoryState {
    /**
     * 删除
     */
    Deleted = 2,
    /**
     * 编辑
     */
    Modified = 3,
    /**
     * 新增
     */
    Added = 4,
    /**
     * 仅显示
     */
    Display = 999,
}

/**
 * 历史记录状态-选项
 */
export const UiHistoryStateOption: UiSelectOption<number>[] = [
    { Label: "删除", Value: 2, Data: "Deleted" },
    { Label: "编辑", Value: 3, Data: "Modified" },
    { Label: "新增", Value: 4, Data: "Added" },
    { Label: "仅显示", Value: 999, Data: "Display" },
];
