import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumWarehouseType, EnumWarehouseTypeOption } from "./EnumWarehouseType";

/**
 * 进销存/出入库分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchOrderWarehouse extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumWarehouseType;
}

/**
 * 进销存/出入库分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchOrderWarehouseRules = {
}

/**
 * 进销存/出入库分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchOrderWarehouseDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Code",
    Label: "订单编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_SigningTime",
    Label: "签订时间",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
    ]
  },
  {
    Prop: "DynamicSearch_Partner",
    Label: "合作方（采购方/供应商）",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Address",
    Label: "交货地",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Requirement",
    Label: "交期要求",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_UserId",
    Label: "执行人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Status",
    Label: "状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
