import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchCompanyBusinessCard from "../models.machine/DtoSelectSearchCompanyBusinessCard";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchCompanyBusinessCard from "../models.machine/DtoPageListSearchCompanyBusinessCard";
import DtoPageListCompanyBusinessCard from "../models.machine/DtoPageListCompanyBusinessCard";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailCompanyBusinessCard from "../models.machine/DtoDetailCompanyBusinessCard";
import DtoRequestCompanyBusinessCardCheckExist from "../models.machine/DtoRequestCompanyBusinessCardCheckExist";
import DtoFormCompanyBusinessCard from "../models.machine/DtoFormCompanyBusinessCard";
import DtoCallbackCompanyBusinessCardGetElectronicSeal from "../models.machine/DtoCallbackCompanyBusinessCardGetElectronicSeal";
import DtoRequestCompanyBusinessCardEditElectronicSeal from "../models.machine/DtoRequestCompanyBusinessCardEditElectronicSeal";
import DtoPageListSearchCompanyBusinessCardUnit from "../models.machine/DtoPageListSearchCompanyBusinessCardUnit";
import DtoPageListCompanyBusinessCardUnit from "../models.machine/DtoPageListCompanyBusinessCardUnit";
import DtoDetailCompanyBusinessCardUnit from "../models.machine/DtoDetailCompanyBusinessCardUnit";
import DtoFomCompanyBusinessCardUnit from "../models.machine/DtoFomCompanyBusinessCardUnit";

/**
 * 公司名片
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyBusinessCardAppService {
    /**
     * 获取公司名片选择器
     * @param {DtoSelectSearchCompanyBusinessCard} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchCompanyBusinessCard): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/CompanyBusinessCard/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取公司名片分页列表
     * @param {DtoPageListSearchCompanyBusinessCard} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCard>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyBusinessCard): AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCard>> {
        const url = `/api/CompanyBusinessCard/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyBusinessCard>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCard/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取公司名片详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCard>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCard>> {
        const url = `/api/CompanyBusinessCard/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyBusinessCard>>(url);
    }

    /**
     * 检查存在
     * @param {DtoRequestCompanyBusinessCardCheckExist} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 是否存在
     */
    static CheckExist(dto?: DtoRequestCompanyBusinessCardCheckExist): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/CompanyBusinessCard/CheckExist`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 添加公司名片
     * @param {DtoFormCompanyBusinessCard} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyBusinessCard): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyBusinessCard/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 设置申报币制
     * @param {string} [id] 公司名片Id
     * @param {string} [currencySystemId] 币制Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetCurrencySystemById(id?: string, currencySystemId?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCard/SetCurrencySystem/${id}`;
        const _querys: any = {};
        _querys.currencySystemId = currencySystemId
        return service.post<RESTfulResult<any>>(url, undefined, { params: _querys });
    }

    /**
     * 批量删除公司名片
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCard/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取电子章
     * @param {string} [id] 公司名片Id
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackCompanyBusinessCardGetElectronicSeal>>} 结果
     */
    static GetElectronicSealById(id?: string): AxiosPromise<RESTfulResult<DtoCallbackCompanyBusinessCardGetElectronicSeal>> {
        const url = `/api/CompanyBusinessCard/GetElectronicSeal/${id}`;
        return service.get<RESTfulResult<DtoCallbackCompanyBusinessCardGetElectronicSeal>>(url);
    }

    /**
     * 编辑电子章
     * @param {DtoRequestCompanyBusinessCardEditElectronicSeal} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditElectronicSeal(dto?: DtoRequestCompanyBusinessCardEditElectronicSeal): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCard/EditElectronicSeal`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取单位分页列表
     * @param {DtoPageListSearchCompanyBusinessCardUnit} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCardUnit>>} 分页列表
     */
    static GetUnit(dto?: DtoPageListSearchCompanyBusinessCardUnit): AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCardUnit>> {
        const url = `/api/CompanyBusinessCard/GetUnit`;
        return service.get<RESTfulResult<DtoPageListCompanyBusinessCardUnit>>(url, { params: dto });
    }

    /**
     * 获取单位详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCardUnit>>} 详细
     */
    static GetUnitById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCardUnit>> {
        const url = `/api/CompanyBusinessCard/GetUnit/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyBusinessCardUnit>>(url);
    }

    /**
     * 添加单位
     * @param {DtoFomCompanyBusinessCardUnit} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostUnit(dto?: DtoFomCompanyBusinessCardUnit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCard/PostUnit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取企业信息
     * @param {DtoPageListSearchCompanyBusinessCard} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCards(dto?: DtoPageListSearchCompanyBusinessCard): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/CompanyBusinessCard/GetCards`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

}
