import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchBrand from "../models.machine/DtoSelectSearchBrand";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchBrand from "../models.machine/DtoPageListSearchBrand";
import DtoPageListBrand from "../models.machine/DtoPageListBrand";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailBrand from "../models.machine/DtoDetailBrand";
import DtoFormBrand from "../models.machine/DtoFormBrand";

/**
 * HS编码（品牌类型）
 * @description 自动生成的代码,请勿修改
 */
export default class BrandAppService {
    /**
     * 获取HS编码（品牌类型）选择器
     * @param {DtoSelectSearchBrand} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchBrand): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Brand/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取HS编码（品牌类型）分页列表
     * @param {DtoPageListSearchBrand} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBrand>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchBrand): AxiosPromise<RESTfulResult<DtoPageListBrand>> {
        const url = `/api/Brand/Get`;
        return service.get<RESTfulResult<DtoPageListBrand>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Brand/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取HS编码（品牌类型）详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailBrand>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailBrand>> {
        const url = `/api/Brand/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailBrand>>(url);
    }

    /**
     * 新增/编辑HS编码（品牌类型）
     * @param {DtoFormBrand} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormBrand): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Brand/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除HS编码（品牌类型）
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Brand/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Brand/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Brand/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Brand/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Brand/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
