import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemCompany_JoinCompanyType from "./DtoListItemCompany_JoinCompanyType";
import DtoListItemCompany_CompanyCredit from "./DtoListItemCompany_CompanyCredit";
import { EnumCompanyStatus, EnumCompanyStatusOption } from "./EnumCompanyStatus";

/**
 * 公司分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompany extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Name = "--/--";
        this.JoinCompanyTypes = [];
        this.UserCount = 0;
        this.InquiryHistory = false;
        this.Cooperate = false;
        this.OpenThreeApiService = false;
    }

    [proName: string]: any;
    /**
     * 公司名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 公司类型
     */
    JoinCompanyTypes?: DtoListItemCompany_JoinCompanyType[];
    /**
     * 服务商
     */
    ServiceProviderCompanyId?: string;
    /**
     * 所属服务商中文名称
     */
    ServiceProviderCompanyNameZh?: string;
    /**
     * 所属服务商英文名称
     */
    ServiceProviderCompanyNameEn?: string;
    /**
     * 用户数
     */
    UserCount?: number;
    /**
     * 最大用户数
     */
    MaxUserCount?: number;
    /**
     * 授信与账期
     */
    CompanyCredit?: DtoListItemCompany_CompanyCredit;
    /**
     * 业务范围
     */
    BusinessScope?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 询价记录
     */
    InquiryHistory?: boolean;
    /**
     * 合同编号
     */
    ContractCode?: string;
    /**
     * 是否已合作
     */
    Cooperate?: boolean;
    /**
     * 客户来源
     */
    Source?: string;
    /**
     * 状态
     */
    Status?: EnumCompanyStatus;
    /**
     * 接口服务
     */
    OpenThreeApiService?: boolean;
}

/**
 * 公司分页列表-项-表单校验
 */
export const DtoListItemCompanyRules = {
}

/**
 * 公司分页列表-项-设置项
 */
export const DtoListItemCompanyMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "公司名称",
  },
  {
    Prop: "JoinCompanyTypes",
    Label: "公司类型",
  },
  {
    Prop: "ServiceProviderCompanyId",
    Label: "服务商",
  },
  {
    Prop: "UserCount",
    Label: "用户数",
  },
  {
    Prop: "BusinessScope",
    Label: "业务范围",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
  {
    Prop: "InquiryHistory",
    Label: "询价记录",
    Customize: true,
  },
  {
    Prop: "ContractCode",
    Label: "合同编号",
  },
  {
    Prop: "Cooperate",
    Label: "是否已合作",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "Source",
    Label: "客户来源",
  },
  {
    Prop: "Status",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyStatusOption,
  },
  {
    Prop: "OpenThreeApiService",
    Label: "接口服务",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "启用",
    WhenFalse: "禁用",
  },
]
