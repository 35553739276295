import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchSalerSettlementHistory from "../models.machine/DtoPageListSearchSalerSettlementHistory";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListSalerSettlementHistory from "../models.machine/DtoPageListSalerSettlementHistory";

/**
 * 账单历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class SalerSettlementHistoryAppService {
    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchSalerSettlementHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSalerSettlementHistory>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchSalerSettlementHistory): AxiosPromise<RESTfulResult<DtoPageListSalerSettlementHistory>> {
        const url = `/api/SalerSettlementHistory/Get`;
        return service.get<RESTfulResult<DtoPageListSalerSettlementHistory>>(url, { params: dto });
    }

    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchSalerSettlementHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSalerSettlementHistory>>} 分页列表
     */
    static All(dto?: DtoPageListSearchSalerSettlementHistory): AxiosPromise<RESTfulResult<DtoPageListSalerSettlementHistory>> {
        const url = `/api/SalerSettlementHistory/All`;
        return service.post<RESTfulResult<DtoPageListSalerSettlementHistory>>(url, dto);
    }

}
