
import MiezzForm from "@/models/MiezzForm";
import { Options, Vue } from "vue-class-component";
import { ElMessage } from "element-plus";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButton from "@/models/MiezzButton";
import UiSelectOption from "@/models.machine/UiSelectOption";
import {
    EnumSupplierSource,
    EnumSupplierSourceOption,
} from "@/models.machine/EnumSupplierSource";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import DtoSelectSearchCompanyType from "@/models.machine/DtoSelectSearchCompanyType";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import DtoCallbackShiftout, { DtoCallbackShiftoutMiezzFormItems, DtoCallbackShiftoutRules } from "@/models.machine/DtoCallbackShiftout";
import { forEach } from "lodash";
import DtoListItemOrderInboundOutbound from "@/models.machine/DtoListItemOrderInboundOutbound";
import { __param } from "tslib";

export default class MiezzShiftout extends Vue {
    formSupplier = new MiezzForm<DtoCallbackShiftout, string>();
    /**供应商-来源选择器数据 */
    EnumSupplierSourceOption = EnumSupplierSourceOption;
    /**供应商-类型选择器数据 */
    supplierRandom = 0;
    supplierTypes: UiSelectOption<string>[] = [];
    /**供应商-供应商选择器数据 */
    suppliers: DtoSelectOptionCompany[] = [];
    /**供应商-联系人选择器数据 */
    supplierContacts: UiSelectOption<string>[] = [];
    ruleForm: {
        Visible: boolean,
        Title: string,
    } = {
            Visible: false,
            Title: "迁移",
        };
    isInit = false;
    bottomButtons?: MiezzButton[] = [
        {
            Code: "submit",
            Label: "确定",
            Type: "primary",
            Size: "small",
        },
        {
            Code: "close",
            Label: "关闭",
            Type: "default",
            Size: "small",
        },
    ];
    supplierCompanyId?: string;
    supplierCompanyIds: string[] = [];
    isBatchShiftout = false;
    /**格式化枚举 */
    formatEnum(options: UiSelectOption<number>[], value?: number): string {
        return (
            options.firstOrDefault((it) => it.Value == value)?.Label ?? "暂无数据"
        );
    }
    onSupplierCompanyIdChanged(): void {
        if (this.formSupplier.Data.SupplierCompanyId) {
            CompanyContactAppService.GetSelect({
                CompanyId: this.formSupplier.Data.SupplierCompanyId,
            }).then((r) => {
                this.supplierContacts = r.data.Data ?? [];
            });
        } else {
            this.supplierContacts = [];
            this.formSupplier.Data.SupplierCompanyContactLabel = undefined;
        }
    }
    /**供应商-获取联系人 */
    getSupplierCompanyContact(q: string, cb: any): void {
        cb(this.supplierContacts.filter((it) => it.Label?.contains(q)) ?? []);
    }


    /**供应商-来源变更时 */
    onSupplierSourceChanged(): void {
        this.supplierTypes = [];
        this.formSupplier.Data.SupplierCompanyTypeId = undefined;

        if (this.formSupplier.Data.SupplierSource) {
            this.formSupplier.Data.SupplierCompanyTypeId = undefined;
            MiezzForm.validateField(this.$refs, "ref-form-supplier", "SupplierCompanyTypeId");

            const dto = new DtoSelectSearchCompanyType();
            if (this.formSupplier.Data.SupplierSource == EnumSupplierSource.ServiceProvider) {
                dto.Type = EnumCompanyType.ServiceProvider;
            } else {
                dto.Type = EnumCompanyType.Supplier;
            }
            if (this.isInit == false) {
                this.formSupplier.Data.SupplierCompanyId = undefined;
            }

            CompanyTypeAppService.GetSelect(dto).then((r) => {
                this.supplierTypes = r.data.Data ?? [];
                this.onSupplierTypeIdChanged();
                this.supplierRandom = Math.random();
            });
        }
        
    }
    onSupplierTypeIdChanged(): void {
        CompanyAppService.GetSelect({
            CompanyType: EnumCompanyType.Supplier,
            SupplierSource: this.formSupplier.Data.SupplierSource,
            CompanyTypeId: this.formSupplier.Data.SupplierCompanyTypeId,
        }).then((r) => {
            this.suppliers = r.data.Data ?? [];
            if (this.suppliers.all((it) => it.Id != this.formSupplier.Data.SupplierCompanyId)) {
                if (this.isInit == false) {
                    this.formSupplier.Data.SupplierCompanyId = undefined;
                }
                else {
                    MiezzForm.clearValidate(this.$refs, "ref-form-supplier", undefined);
                }
                this.isInit = false;
            }
            if (this.isBatchShiftout) {
                this.supplierCompanyIds.forEach(ele => {
                    this.suppliers = this.suppliers.filter(it => it.Id != ele);
                })
            } else {
                this.suppliers = this.suppliers.filter(it => it.Id != this.supplierCompanyId);
            }
            
        });
            
        // if (this.formSupplier.Data.SupplierCompanyTypeId) {
            
        // } else {
        //     this.suppliers = [];
        //     this.formSupplier.Data.SupplierCompanyId = undefined;
        // }
    }

    created(): void {
        this.formSupplier.Items = DtoCallbackShiftoutMiezzFormItems;
        this.formSupplier.Rules = DtoCallbackShiftoutRules;
        this.formSupplier.Data = new DtoCallbackShiftout();
    }

    /*初始化 */
    @Emit()
    onInit(param: any, isBatchShiftout: boolean): void {
        this.isBatchShiftout = isBatchShiftout;
        this.supplierCompanyId = undefined;
        this.supplierCompanyIds = [];
        if (this.isBatchShiftout) {
            const _param = param as Array<DtoListItemOrderInboundOutbound>;
            _param.forEach(ele => {
                if (ele.SupplierCompanyId) this.supplierCompanyIds.push(ele.SupplierCompanyId);
            })
        } else {
            this.supplierCompanyId = param.SupplierCompanyId;
        }

        this.ruleForm.Title = "移库";
        this.ruleForm.Visible = true;
    }

    /**按钮点击 */
    handleClick(btn: MiezzButton, complete: () => void): void {
        switch (btn.Code) {
            case "submit":
                this.suppliers.forEach(ele => {
                    if (ele.Id == this.formSupplier.Data.SupplierCompanyId) {
                        this.formSupplier.Data.SupplierCompanyLabel = ele.NameZh;
                    }
                })
                
                MiezzForm.submit(this.$refs, "ref-form-supplier", (v) => {
                    if (v == true) {
                        this.$emit("onSelectShiftout", this.formSupplier.Data);
                        this.ruleForm.Visible = false;
                        this.formSupplier.Data = new DtoCallbackShiftout();
                        setTimeout(() => {
                            MiezzForm.clearValidate(this.$refs, "ref-form-supplier", undefined);
                        }, 50)
                        complete();
                    } else {
                        complete();
                    }
                });
                break;
            case "close":
                this.handleClose();
                complete();
                break;
            default:
                break;
        }
    }

    handleClose(): void{
        this.ruleForm.Visible = false;
        this.formSupplier.Data = new DtoCallbackShiftout();
        setTimeout(() => {
            MiezzForm.clearValidate(this.$refs, "ref-form-supplier", undefined);
        }, 50)
    }
}
