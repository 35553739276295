import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumBillShareType, EnumBillShareTypeOption } from "./EnumBillShareType";
import DtoCallbackFromShare from "./DtoCallbackFromShare";
import DtoCallbackToShare from "./DtoCallbackToShare";
import UiGroup from "./UiGroup";

/**
 * 分摊账单信息
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackShare extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Step = 1;
        this.Visiable = false;
        this.Type = EnumBillShareType.Number;
        this.Froms = [];
        this.Tos = [];
    }

    [proName: string]: any;
    /**
     * 步骤
     */
    Step?: number;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 分摊方式
     */
    Type?: EnumBillShareType;
    /**
     * 哪些账单分摊
     */
    Froms?: DtoCallbackFromShare[];
    /**
     * 分摊到哪些账单
     */
    Tos?: DtoCallbackToShare[];
    /**
     * 
     */
    OrderId?: string;
    /**
     * 材积比
     */
    VolumeRatio?: number;
    /**
     * 单件最低计费标准
     */
    OneMiniStandard?: number;
    /**
     * 单票最低计费标准
     */
    MiniStandard?: number;
    /**
     * 单票最低计费标准单位
     */
    MiniStandardUnit?: string;
}

/**
 * 分摊账单信息-表单校验
 */
export const DtoCallbackShareRules = {
}

/**
 * 分摊账单信息-设置项
 */
export const DtoCallbackShareMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Step",
    Label: "步骤",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Visiable",
    Label: "弹出框是否显示",
    Customize: true,
  },
  {
    Prop: "Type",
    Label: "分摊方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumBillShareTypeOption,
  },
  {
    Prop: "Froms",
    Label: "哪些账单分摊",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Tos",
    Label: "分摊到哪些账单",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "OrderId",
    Label: "",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "VolumeRatio",
    Label: "材积比",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "OneMiniStandard",
    Label: "单件最低计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MiniStandard",
    Label: "单票最低计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MiniStandardUnit",
    Label: "单票最低计费标准单位",
    Type: EnumMiezzFormItemType.Text,
  },
]
