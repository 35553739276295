
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import DtoFormParamPort, {
  DtoFormParamPortMiezzFormItems,
  DtoFormParamPortRules,
} from "@/models.machine/DtoFormParamPort";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import MiezzModal from "@/models/MiezzModal";
import DtoListItemParamPort_Repeat from "@/models.machine/DtoListItemParamPort_Repeat";

export default class ParamPortForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  Country?: UiSelectOption<string>[] = [];
  freighTowerPorts?: UiSelectOption<string>[] = [];
  form = new MiezzForm<DtoFormParamPort, string>(
    "System_ParamList_Port_AddEdit"
  );
  optItemListRepeat: Array<DtoListItemParamPort_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamPort();
    //表单-校验
    this.form.Rules = DtoFormParamPortRules;
    this.form.Rules.NameZh = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (
            this.form.Data.NameZh != null &&
            typeof this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "" &&
            this.form.Data.NameEn != null &&
            typeof this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != ""
          ) {
            var NameZh = this.form.Data.NameZh.trim();
            var NameEn = this.form.Data.NameEn.toUpperCase().trim();
            if (
              this.optItemListRepeat.some(
                (it) =>
                  it.NameZh?.trim() == NameZh &&
                  it.NameEn?.toUpperCase().trim() == NameEn
              )
            ) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameEn = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (
            this.form.Data.NameZh != null &&
            typeof this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "" &&
            this.form.Data.NameEn != null &&
            typeof this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != ""
          ) {
            var NameZh = this.form.Data.NameZh.trim();
            var NameEn = this.form.Data.NameEn.toUpperCase().trim();
            if (
              this.optItemListRepeat.some(
                (it) =>
                  it.NameZh?.trim() == NameZh &&
                  it.NameEn?.toUpperCase().trim() == NameEn
              )
            ) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamPortMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    ParamPortAppService.GetFreighTowerPorts().then((r) => {
      this.freighTowerPorts = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamPortAppService.GetList().then((r1) => {
      if (r1.data.Data) this.optItemListRepeat = r1.data.Data;

      if (this.id) {
        ParamPortAppService.GetById(this.id).then((r) => {
          if (r.data.Data) this.form.Data = r.data.Data;
          this.getCountry();

          var index = this.optItemListRepeat.findIndex((it) => {
            return (
              it.NameZh == this.form.Data.NameZh &&
              it.NameEn == this.form.Data.NameEn
            );
          });
          this.optItemListRepeat.splice(index, 1);
        });
      } else {
        this.form.Data = new DtoFormParamPort();
        this.getCountry();
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamPortAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**查询国家 */
  getCountry(): void {
    ParamCountryAppService.GetSelect().then((r) => {
      this.Country = r.data.Data;
    });
  }

  onChangeCountry(): void {
    this.form.Data.NameZh = "";
    this.form.Data.NameEn = "";
    this.form.Data.FullName = "";
  }

  onBlur(): void {
    var optFullName: Array<string> = [];

    if (this.form.Data.NameZh) {
      optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      optFullName?.push(this.form.Data.NameEn);
    }
    if (this.form.Data.Code) {
      optFullName?.push(this.form.Data.Code);
    }

    this.form.Data.FullName = optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
      if (
        this.form.Data.ParamCountryId &&
        this.form.Data.NameZh.indexOf("（") == -1 &&
        this.form.Data.NameZh.indexOf(")") == -1
      ) {
        let country = this.Country?.find(
          (it) => it.Value == this.form.Data.ParamCountryId
        );
        this.form.Data.NameZh =
          this.form.Data.NameZh + `（${country?.Data.NameZh.toUpperCase()}）`;
      }
      MiezzForm.validateField(this.$refs, "ruleForm", "NameEn");
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
      if (
        this.form.Data.ParamCountryId &&
        this.form.Data.NameEn.indexOf(",") == -1
      ) {
        let country = this.Country?.find(
          (it) => it.Value == this.form.Data.ParamCountryId
        );
        this.form.Data.NameEn =
          this.form.Data.NameEn +
          `,${country?.Data.NameEn.toUpperCase().trim()}`;
      }
      MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
    }
    this.onBlur();
  }

  onBlurCode(): void {
    if (this.form.Data.Code) {
      this.form.Data.Code = this.form.Data.Code.toUpperCase();
      this.form.Data.Code = this.form.Data.Code.trim();
    }
  }

  onBlurChinaCustomsCode(): void {
    if (this.form.Data.ChinaCustomsCode) {
      this.form.Data.ChinaCustomsCode =
        this.form.Data.ChinaCustomsCode.toUpperCase();
      this.form.Data.ChinaCustomsCode = this.form.Data.ChinaCustomsCode.trim();
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
