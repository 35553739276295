
import OSS from "ali-oss";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import AliyunOSS from "../apis/AliyunOSS";
export default class MiezzThumbnail extends Vue {
  @Model() modelValue?: string;
  @Prop() size?: number;
  @Prop() type?: string;
  @Prop() icon?: string;
  client?: OSS;

  url?: string = "";
  created(): void {
    this.client = AliyunOSS.GetClient();
    this.onModelValueChanged(this.modelValue, undefined);
    }

    onDetail(): void {
        if (this.url) {
            window.open(this.url, '_blank');
        }
    }

  @Watch("modelValue")
  onModelValueChanged(nv?: string, ov?: string): void {
    if (nv != ov) {
      if (this.modelValue) {
        this.url = this.client?.signatureUrl(this.modelValue);
      } else {
          this.url = undefined;
      }
    }
  }
}
