
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import DtoFormParamTransportChannel, {
  DtoFormParamTransportChannelMiezzFormItems,
  DtoFormParamTransportChannelRules,
} from "@/models.machine/DtoFormParamTransportChannel";

export default class ParamTransportChannelForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamTransportChannel, string>();
  optFullName?: Array<string> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamTransportChannel();
    //表单-校验
    this.form.Rules = DtoFormParamTransportChannelRules;
    //表单-元素配置
    this.form.Items = DtoFormParamTransportChannelMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      ParamTransportChannelAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        if (!this.form.Data.NameZh && !this.form.Data.NameEn) {
          ElMessage.warning("中文名称和英文名称不可同时为空！");
          complete();
        } else {
          MiezzForm.submit(this.$refs, "ruleForm", (v) => {
            if (v) {
              ParamTransportChannelAppService.Post(this.form.Data)
                .then(() => {
                  this.handleSubmit();
                  complete();
                })
                .catch(complete);
            } else complete();
          });
        }
        
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    
    if (this.form.Data.Abridge) {
      this.optFullName?.push(this.form.Data.Abridge);
    }
    if (this.form.Data.NameZh) {
      this.optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.onBlur();
  }


  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
