import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 征免性质详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailExemptionNature extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Abbr?: string;
    /**
     * 海关代码
     */
    Code?: string;
    /**
     * 全名
     */
    Name?: string;
}

/**
 * 征免性质详细-表单校验
 */
export const DtoDetailExemptionNatureRules = {
}

/**
 * 征免性质详细-设置项
 */
export const DtoDetailExemptionNatureMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Abbr",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "海关代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Name",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
]
