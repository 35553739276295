
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzMenuTabComponent, {
  MiezzMenuTab,
} from "@/components/MiezzMenuTab.vue";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";

export default class PriceServiceDetailCostCompositionSurcharge extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge"
  );
  amountType = EnumPriceAmountType.Benchmark;
  menuTab = new MiezzMenuTab();
  disabledCatalogues = false;

  created(): void {
    const id = this.$route.params.id;
    const surchargeGroupId = this.$route.query.SurchargeGroupId as string;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Url = `/price/setup/service/detail/${id}/cost-composition/surcharge`;
    this.onSurchargeGroupIdChanged(undefined, surchargeGroupId);
  }

  @Watch("$route.query.SurchargeGroupId")
  onSurchargeGroupIdChanged(nv?: string, ov?: string): void {
    if (nv == ov) return;
    const id = this.$route.params.id;
    const searchServiceTableId = this.$route.query.SearchServiceTableId;
    const surchargeGroupId = this.$route.query.SurchargeGroupId;
    const priceId = this.$route.query.PriceId;
    this.menuTab.Menus = [
      {
        Label: "已添加",
        Url: `/price/setup/service/detail/${id}/cost-composition/surcharge/added?SearchServiceTableId=${searchServiceTableId}&SurchargeGroupId=${surchargeGroupId}&AddSurcharge=false${
          priceId ? `&PriceId=${priceId}` : ""
        }`,
        Power: "PriceManagement_SetUp_Service_Detail_Remark",
      },
      {
        Label: "未添加",
        Url: `/price/setup/service/detail/${id}/cost-composition/surcharge/not-added?SearchServiceTableId=${searchServiceTableId}&SurchargeGroupId=${surchargeGroupId}&AddSurcharge=true${
          priceId ? `&PriceId=${priceId}` : ""
        }`,
        Power: "PriceManagement_SetUp_Service_Detail_CostComposition",
      },
    ];
    (this.$refs["miezz-menu-tab"] as MiezzMenuTabComponent)?.init();
  }
}
