
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoCallbackCompanyBusinessCardGetElectronicSeal from "@/models.machine/DtoCallbackCompanyBusinessCardGetElectronicSeal";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyBusinessCardSealForm from "./CompanyBusinessCardSealForm.vue";
@Options({
  components: {
    CompanyBusinessCardSealForm,
  },
})
export default class CompanyBusinessCardSeal extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new DtoCallbackCompanyBusinessCardGetElectronicSeal();
  modalForm = new MiezzModal(MiezzModalType.Dialog);

  created(): void {
    this.detail = new DtoCallbackCompanyBusinessCardGetElectronicSeal();

    this.modelValue.Title = "电子章";
    this.modelValue.Width = "800px";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Label: "编辑",
        Type: "primary",
        Size: "small",
      },
    ];
    this.handleGet();
  }

  handleGet(): void {
    if (this.id) {
      CompanyBusinessCardAppService.GetElectronicSealById(this.id).then((r) => {
        if (r.data.Data) this.detail = r.data.Data;
      });
    }
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        complete();
        break;
      default:
        break;
    }
  }

  handleSubmit(): void {
    this.handleGet();
  }
}
