
import AuthAppService from "@/apis.machine/AuthAppService";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import SmsAppService from "@/apis.machine/SmsAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoDetailJoinCompanyUserPersonalProfile, {
  DtoDetailJoinCompanyUserPersonalProfileMiezzDetailItems,
} from "@/models.machine/DtoDetailJoinCompanyUserPersonalProfile";
import DtoFormJoinCompanyUserPassword, {
  DtoFormJoinCompanyUserPasswordMiezzFormItems,
  DtoFormJoinCompanyUserPasswordRules,
} from "@/models.machine/DtoFormJoinCompanyUserPassword";
import DtoFormJoinCompanyUserPersonalProfile, {
  DtoFormJoinCompanyUserPersonalProfileMiezzFormItems,
  DtoFormJoinCompanyUserPersonalProfileRules,
} from "@/models.machine/DtoFormJoinCompanyUserPersonalProfile";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class IndexUser extends Vue {
  logier = CurrentLogier.GetLogier();

  modalPersonalProfile = new MiezzModal();
  formPersonalProfile = new MiezzForm<
    DtoDetailJoinCompanyUserPersonalProfile,
    string
  >();
  modalUpdatePersonalProfile = new MiezzModal();
  formUpdatePersonalProfile = new MiezzForm<
    DtoFormJoinCompanyUserPersonalProfile,
    string
  >();
  btnUpdatePhoneNumber: MiezzButton = {
    Code: "update-phone-number",
    Label: "[更换手机]",
    Type: "text",
    Size: "small",
  };
  btnSendSms: MiezzButton = {
    Code: "send-sms",
    Label: "发送验证码",
    Type: "primary",
    Size: "small",
  };
  modalUpdatePassword = new MiezzModal();
  formUpdatePassword = new MiezzForm<DtoFormJoinCompanyUserPassword, string>();

  created(): void {
    this.modalPersonalProfile.Title = "个人档案";
    this.modalPersonalProfile.Width = "600px";
    this.modalPersonalProfile.HandleClick = this.handleClick;
    this.modalPersonalProfile.Buttons = [
      {
        Code: "update-personal-profile",
        Label: "编辑",
        Type: "primary",
        Size: "small",
      },
    ];

    this.formPersonalProfile.Items =
      DtoDetailJoinCompanyUserPersonalProfileMiezzDetailItems;

    this.modalUpdatePersonalProfile.Title = "编辑档案";
    this.modalUpdatePersonalProfile.Width = "600px";
    this.modalUpdatePersonalProfile.HandleClick = this.handleClick;
    this.modalUpdatePersonalProfile.Buttons = [
      {
        Code: "save-personal-profile",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.formUpdatePersonalProfile.Rules =
      DtoFormJoinCompanyUserPersonalProfileRules;
    this.formUpdatePersonalProfile.Rules.NewPhoneNumber = [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (value) {
            if (!/^1[3456789][0-9]{9}$/.test(value)) {
              return callback(new Error("请输入正确的手机号码"));
            } else if (
              this.formUpdatePersonalProfile.Data.PhoneNumber == value
            ) {
              return callback(new Error("信息重复，请重新填写"));
            } else {
              AuthAppService.CheckUserByAccount(value)
                .then((r) => {
                  if (r.data.Data && r.data.Data.Id != this.logier?.UserId) {
                    return callback(new Error("手机号已被其他用户占用"));
                  } else {
                    callback();
                  }
                })
                .catch(callback);
            }
          } else {
            return callback(new Error("信息不能为空"));
          }
        },
        trigger: "blur",
      },
    ];
    this.formUpdatePersonalProfile.Rules.VerifyCode = [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (value) {
            if (!/^[0-9]{6}$/.test(value)) {
              return callback(new Error("验证码错误"));
            } else {
              callback();
            }
          } else {
            return callback(new Error("信息不能为空"));
          }
        },
        trigger: "blur",
      },
    ];
    this.formUpdatePersonalProfile.Items =
      DtoFormJoinCompanyUserPersonalProfileMiezzFormItems;

    this.modalUpdatePassword.Title = "更改密码";
    this.modalUpdatePassword.Width = "600px";
    this.modalUpdatePassword.HandleClick = this.handleClick;
    this.modalUpdatePassword.Buttons = [
      {
        Code: "save-password",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.formUpdatePassword.Rules = DtoFormJoinCompanyUserPasswordRules;
    this.formUpdatePassword.Rules.NewPassword = [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (value) {
            if (!/^[0-9A-Za-z_]{8,32}$/.test(value)) {
              return callback(
                new Error("密码必须由字母、数字、下划线组合，长度在8-32之间")
              );
            } else if (this.formUpdatePassword.Data.Password == value) {
              return callback(new Error("新密码不能和原密码一致"));
            } else {
              callback();
            }
          } else {
            return callback(new Error("信息不能为空"));
          }
        },
        trigger: "blur",
      },
    ];
    this.formUpdatePassword.Rules.SurePassword = [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (value) {
            if (!/^[0-9A-Za-z_]{8,32}$/.test(value)) {
              return callback(
                new Error("密码必须由字母、数字、下划线组合，长度在8-32之间")
              );
            } else if (this.formUpdatePassword.Data.NewPassword != value) {
              return callback(new Error("确认密码和新密码不一致"));
            } else {
              callback();
            }
          } else {
            return callback(new Error("信息不能为空"));
          }
        },
        trigger: "blur",
      },
    ];
    this.formUpdatePassword.Items =
      DtoFormJoinCompanyUserPasswordMiezzFormItems;
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "update-personal-profile":
        this.btnUpdatePhoneNumber.Label = "[更换手机]";
        this.formUpdatePersonalProfile.Data = JSON.copy(
          this.formPersonalProfile.Data
        );
        this.modalUpdatePersonalProfile.Show = true;
        complete();
        break;
      case "update-phone-number":
        if (model.Label == "[更换手机]") {
          model.Label = "[重置]";
        } else {
          model.Label = "[更换手机]";
        }
        complete();
        break;
      case "send-sms":
        this.countdown = 5;
        this.handleCountdown();
        SmsAppService.SendVerifyCodeAsync({
          Code: "RePassword",
          PhoneNumber: this.formUpdatePersonalProfile.Data.NewPhoneNumber,
        })
          .then((r) => {
            if (r.data.Extras) {
              const param = JSON.parse(r.data.Extras);
              if (param && param.code) {
                this.formUpdatePersonalProfile.Data.VerifyCode = param.code;
              }
            }
          })
          .catch(() => {
            this.countdown = 0;
          });
        complete();
        break;
      case "save-personal-profile":
        MiezzForm.submit(this.$refs, "form-update-personal-profile", (v) => {
          if (v) {
            JoinCompanyUserAppService.UpdatePersonalProfile(
              this.formUpdatePersonalProfile.Data
            )
              .then(() => {
                this.handleGetPersonalProfile(() => {
                  this.modalUpdatePersonalProfile.Show = false;
                  if (this.logier) {
                    this.logier.UserName =
                      this.formUpdatePersonalProfile.Data.Name;
                  }
                  ElMessage.success("操作成功");
                  complete();
                });
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "save-password":
        MiezzForm.submit(this.$refs, "form-update-password", (v) => {
          if (v) {
            JoinCompanyUserAppService.UpdatePassword(
              this.formUpdatePassword.Data
            )
              .then(() => {
                this.modalUpdatePassword.Show = false;
                ElMessage.success("操作成功");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;

      default:
        break;
    }
  }

  countdown = 0;
  handleCountdown(): void {
    if (this.countdown > 0) {
      this.btnSendSms.Label = `${this.countdown}秒`;
      this.btnSendSms.Disabled = true;
      setTimeout(() => {
        this.countdown--;
        this.handleCountdown();
      }, 1000);
    } else {
      this.btnSendSms.Label = `发送验证码`;
      this.btnSendSms.Disabled = false;
    }
  }

  handleGetPersonalProfile(callback?: () => void): void {
    JoinCompanyUserAppService.GetPersonalProfile().then((r) => {
      if (r.data.Data) {
        this.formPersonalProfile.Data = r.data.Data;
        if (callback) {
          callback();
        }
      }
    });
  }

  handleCommand(val: string): void {
    switch (val) {
      case "personal-profile":
        this.handleGetPersonalProfile(() => {
          this.modalPersonalProfile.Show = true;
        });
        break;
      case "update-password":
        this.formUpdatePassword.Data = new DtoFormJoinCompanyUserPassword();
        this.modalUpdatePassword.Show = true;
        break;
      case "logout":
        CurrentLogier.Logout();
        sessionStorage.removeItem("pathHistory");
        sessionStorage.removeItem("activeOne");
        sessionStorage.removeItem("activeTwo");
        sessionStorage.removeItem("activeThree");
        break;

      default:
        break;
    }
  }
}
