
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../CompanyDetail.vue";
import { CompanySetting } from "../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class ServiceProviderCompanyDetail extends Vue {
  type = EnumCompanyType.ServiceProvider;
  detail = new MiezzForm<DtoDetailCompany, string>(
    "ServiceProviderManagement_Company_Detail"
  );
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/service-provider/company/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/service-provider/company/detail/${this.$route.params.id}?page=BasicInfo`,
        Label: "公司信息",
        Power: "ServiceProviderManagement_Company_Detail",
      },
    ];
    this.detail.Items = DtoDetailCompanyMiezzDetailItems.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "SalesmanLabel" &&
        it.Prop != "Source" &&
        it.Prop != "BusinessScope" &&
        it.Prop != "Remark"
    );

    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "default",
        Size: "small",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_Company_Edit",
      },
    ];
  }
}
