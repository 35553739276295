import MiezzAlertLog from "@/models/MiezzAlertLog";
import MiezzStep from "@/models/MiezzStep";

/**
 * 站点执行信息
 */
export default class ProjectSiteExecInfo {
    /**
     * 站点Id
     */
    Id?: string;
    /**
     * 站点名称
     */
    Name?: string;
    /**
     * 步骤
     */
    Steps: MiezzStep[] = [];
    /**
     * 当前步骤
     */
    ActiveStep = 0;
    /**
     * 默认弹出日志
     */
    DefaultAlertLogs: MiezzAlertLog[] = [];
    /**
     * 命令弹出日志
     */
    CommandAlertLogs: MiezzAlertLog[] = [];

    handleStep(title: string) {
        this.ActiveStep = this.Steps.findIndex((it) => it.Title == title) + 1;
    }
}
