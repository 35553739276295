import UiSelectOption from "./UiSelectOption";

/**
 * 舱务状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumBookingStatus {
    /**
     * 装货港待订舱
     */
    WaitBooking = -3,
    /**
     * 装货港待放舱
     */
    WaitRelease = -2,
    /**
     * 装货港已放舱
     */
    EdRelease = -1,
    /**
     * 装货港待启航
     */
    Wait = 1,
    /**
     * 装货港已启航
     */
    Ing = 2,
    /**
     * 目的港已抵达
     */
    Ed = 3,
}

/**
 * 舱务状态-选项
 */
export const EnumBookingStatusOption: UiSelectOption<number>[] = [
    { Label: "装货港待订舱", Value: -3, Data: "WaitBooking" },
    { Label: "装货港待放舱", Value: -2, Data: "WaitRelease" },
    { Label: "装货港已放舱", Value: -1, Data: "EdRelease" },
    { Label: "装货港待启航", Value: 1, Data: "Wait" },
    { Label: "装货港已启航", Value: 2, Data: "Ing" },
    { Label: "目的港已抵达", Value: 3, Data: "Ed" },
];
