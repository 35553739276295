import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { shadow: "always" }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'Name')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data.Name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Name) = $event)),
                    translate: _ctx.form.Data.Summary,
                    pattern: "[a-zA-Z_]",
                    placeholder: "请填写",
                    class: "form-item-input-text"
                  }, null, 8, ["modelValue", "translate"])
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'Properties')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: "属性",
                prop: "Properties",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_property_tree, {
                    modelValue: _ctx.form.Data.Properties,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.Properties) = $event)),
                    table: _ctx.form.Data.Table
                  }, null, 8, ["modelValue", "table"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_miezz_button_bar, {
        modelValue: _ctx.buttons,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.buttons) = $event)),
        onHandleClick: _ctx.handleSubmit
      }, null, 8, ["modelValue", "onHandleClick"])
    ]),
    _: 1
  }))
}