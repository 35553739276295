import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumMenuScene, EnumMenuSceneOption } from "./EnumMenuScene";
import { EnumMenuType, EnumMenuTypeOption } from "./EnumMenuType";
import { EnumMenuPower, EnumMenuPowerOption } from "./EnumMenuPower";
import DtoFormMenu_JoinMenuWebApi from "./DtoFormMenu_JoinMenuWebApi";
import DtoFormMenu_MenuField from "./DtoFormMenu_MenuField";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormMenu extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = EnumMenuScene.Platform;
        this.Type = EnumMenuType.Page;
        this.Power = EnumMenuPower.NeedPower;
        this.Sort = 0;
        this.Disabled = false;
        this.Visabled = true;
        this.JoinMenuWebApis = [];
        this.MenuFields = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 场景
     */
    Scene?: EnumMenuScene;
    /**
     * 类型
     */
    Type?: EnumMenuType;
    /**
     * 权限
     */
    Power?: EnumMenuPower;
    /**
     * 链接地址
     */
    Url?: string;
    /**
     * 图标
     */
    Icon?: string;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 排序
     */
    Sort?: number;
    /**
     * 禁用
     */
    Disabled?: boolean;
    /**
     * 显示
     */
    Visabled?: boolean;
    /**
     * 接口
     */
    JoinMenuWebApis?: DtoFormMenu_JoinMenuWebApi[];
    /**
     * 字段
     */
    MenuFields?: DtoFormMenu_MenuField[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoFormMenuRules = {
    Path: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Scene: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Power: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Url: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Icon: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    MarkerColor: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormMenuMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "编号",
    Customize: true,
  },
  {
    Prop: "Scene",
    Label: "场景",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumMenuSceneOption,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumMenuTypeOption,
  },
  {
    Prop: "Power",
    Label: "权限",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumMenuPowerOption,
  },
  {
    Prop: "Url",
    Label: "链接地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Icon",
    Label: "图标",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MarkerColor",
    Label: "标记色",
    Type: EnumMiezzFormItemType.ColorPicker,
  },
  {
    Prop: "Sort",
    Label: "排序",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Disabled",
    Label: "禁用",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Visabled",
    Label: "显示",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "JoinMenuWebApis",
    Label: "接口",
    Customize: true,
  },
  {
    Prop: "MenuFields",
    Label: "字段",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
