import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchPackage from "../models.machine/DtoPageListSearchPackage";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListPackage from "../models.machine/DtoPageListPackage";
import DtoDetailPackage from "../models.machine/DtoDetailPackage";
import DtoFormPackage from "../models.machine/DtoFormPackage";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 产品包装
 * @description 自动生成的代码,请勿修改
 */
export default class PackageAppService {
    /**
     * 获取产品包装分页列表
     * @param {DtoPageListSearchPackage} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPackage>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<DtoPageListPackage>> {
        const url = `/api/Package/Get`;
        return service.get<RESTfulResult<DtoPageListPackage>>(url, { params: dto });
    }

    /**
     * 获取产品包装详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPackage>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPackage>> {
        const url = `/api/Package/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPackage>>(url);
    }

    /**
     * 添加产品包装
     * @param {DtoFormPackage} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPackage): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Package/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除产品包装
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Package/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取产品名称
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetProductName(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetProductName`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取包装编号
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetPackageCode(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetPackageCode`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取货号
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetSpecNumber(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetSpecNumber`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取型号规格
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetSpecs(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetSpecs`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取已使用的中文包装种类
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetSelect(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取包装编号选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetCodes(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetCodes`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取英文包装选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetPackageEns(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetPackageEns`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取中文包装选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetPackageZhs(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetPackageZhs`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取货号选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetNumbers(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetNumbers`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取规格型号选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetPackageSpecs(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetPackageSpecs`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取产品选择器
     * @param {DtoPageListSearchPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetProducts(dto?: DtoPageListSearchPackage): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Package/GetProducts`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

}
