
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Emit, Model } from "vue-property-decorator";
export default class MiezzModalComponent extends Vue {
  @Model() modelValue?: MiezzModal;
  MiezzModalType = MiezzModalType;
  closeBtn: MiezzButton = {
    Code: "close",
    Label: "关闭",
    Type: "default",
    Size: "small",
  };

  @Emit()
  handleClose(): void {
    console.log("handleClose");
    if (this.modelValue) {
      this.modelValue.Show = false;
      var query = router.currentRoute.value.query;
      if (query.Drawer) query.Drawer = null;
      router.push({
        query: query,
        force: true,
      });
    }
  }

  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    if (btn.Code == "close") {
      this.handleClose();
      complete();
    }
    if (this.modelValue?.HandleClick) {
      this.modelValue.HandleClick(btn, complete);
    }
  }
}
