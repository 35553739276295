
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPriceSyncTarget {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 价格Id
     */
    Id?: string;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 金额
     */
    Amount?: number;
    /**
     * 锁定金额
     */
    LockAmount?: number;
    /**
     * 公式
     */
    Formula?: string;
}

/**
 * -表单校验
 */
export const DtoRequestPriceSyncTargetRules = {
}
