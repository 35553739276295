import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_price_page_list = _resolveComponent("price-page-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      ref: "miezz-menu-tab",
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_price_page_list, {
      ref: "ref-price-page-list",
      list: _ctx.list,
      amountType: _ctx.amountType,
      companyId: _ctx.companyId,
      toggle: true,
      disabledCatalogues: true,
      surcharge: true
    }, null, 8, ["list", "amountType", "companyId"])
  ], 64))
}