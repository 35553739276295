import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as Icons from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'
import App from './App.vue'
import router from './router'
import store from './store'
import print from 'vue3-print-nb'
import VueWeChatTitle from 'vue-wechat-title'
import MiezzCard from "./components/MiezzCard.vue"
import MiezzSelect from "./components/MiezzSelect.vue"
import MiezzInput from "./components/MiezzInput.vue"
import MiezzButton from "./components/MiezzButton.vue"
import MiezzButtonBar from "./components/MiezzButtonBar.vue"
import MiezzPagination from "./components/MiezzPagination.vue"
import MiezzDynamicSearch from "./components/MiezzDynamicSearch.vue"
import MiezzRadioSearch from "./components/MiezzRadioSearch.vue"
import MiezzPageList from './components/MiezzPageList.vue'
import MiezzForm from './components/MiezzForm.vue'
import MiezzFormItem from './components/MiezzFormItem.vue'
import MiezzForeignMultipleSelect from './components/MiezzForeignMultipleSelect.vue'
import MiezzModal from './components/MiezzModal.vue'
import MiezzThumbnail from './components/MiezzThumbnail.vue'
import MiezzTextEllipsis from './components/MiezzTextEllipsis.vue'
import MiezzFileUpload from './components/MiezzFileUpload.vue'
import MiezzUpload from './components/MiezzUpload.vue'
import MiezzUploadCropper from './components/MiezzUploadCropper.vue'
import MiezzMenuTab from './components/MiezzMenuTab.vue'
import MiezzDateTimeRange from './components/MiezzDateTimeRange.vue'
import MiezzForeignMultipleCheck from './components/MiezzForeignMultipleCheck.vue'
import MiezzHistory from './components/MiezzHistory.vue'
import MiezzCommonHistory from './components/MiezzCommonHistory.vue'
import MiezzHsCode from './components/Business/MiezzHsCode.vue'
import MiezzCurrency from './components/Business/MiezzCurrency.vue'
import MiezzCustomsProduct from './components/Business/MiezzCustomsProduct.vue'
import MiezzCustomsProductDetail from './components/Business/MiezzCustomsProductDetail.vue'
import MiezzCustomsPackage from './components/Business/MiezzCustomsPackage.vue'
import MiezzGoods from './components/Business/MiezzGoods.vue'
import MiezzWharfPlan from './components/Business/MiezzWharfPlan.vue'
import MiezzWharfInStock from './components/Business/MiezzWharfInStock.vue'
import MiezzWharfOutStock from './components/Business/MiezzWharfOutStock.vue'
import MiezzSupplier from './components/Business/MiezzSupplier.vue'
import MiezzShiftout from './components/Business/MiezzShiftout.vue'
import MiezzWharfWarehousing from "./components/Business/MiezzWharfWarehousing.vue"
import MiezzWharfBoxSticker from "./components/Business/MiezzWharfBoxSticker.vue"
const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(print)
app.use(store)
app.use(router)
app.use(VueWeChatTitle);
app.component("miezz-card", MiezzCard)
app.component("miezz-select", MiezzSelect)
app.component("miezz-input", MiezzInput)
app.component("miezz-button", MiezzButton)
app.component("miezz-button-bar", MiezzButtonBar)
app.component("miezz-pagination", MiezzPagination)
app.component("miezz-dynamic-search", MiezzDynamicSearch)
app.component("miezz-radio-search", MiezzRadioSearch)
app.component("miezz-page-list", MiezzPageList)
app.component("miezz-form", MiezzForm)
app.component("miezz-form-item", MiezzFormItem)
app.component("miezz-foreign-multiple-select", MiezzForeignMultipleSelect)
app.component("miezz-foreign-multiple-check", MiezzForeignMultipleCheck)
app.component("miezz-modal", MiezzModal)
app.component("miezz-thumbnail", MiezzThumbnail)
app.component("miezz-text-ellipsis", MiezzTextEllipsis)
app.component("miezz-file-upload", MiezzFileUpload)
app.component("miezz-upload", MiezzUpload)
app.component("miezz-upload-cropper", MiezzUploadCropper)
app.component("miezz-menu-tab", MiezzMenuTab)
app.component("miezz-date-time-range", MiezzDateTimeRange)
app.component("miezz-history", MiezzHistory)
app.component("miezz-common-history", MiezzCommonHistory)
app.component("miezz-hscode", MiezzHsCode)
app.component("miezz-currency", MiezzCurrency)
app.component("miezz-customs-product", MiezzCustomsProduct)
app.component("miezz-customs-product-detail", MiezzCustomsProductDetail)
app.component("miezz-customs-package", MiezzCustomsPackage)
app.component("miezz-goods", MiezzGoods)
app.component("miezz-wharf-plan", MiezzWharfPlan)
app.component("miezz-wharf-in-stock", MiezzWharfInStock)
app.component("miezz-wharf-out-stock", MiezzWharfOutStock)
app.component("miezz-supplier", MiezzSupplier)
app.component("miezz-shiftout", MiezzShiftout)
app.component("miezz-wharf-warehousing", MiezzWharfWarehousing)
app.component("miezz-wharf-box-sticker", MiezzWharfBoxSticker)
app.mount('#app')



Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons])
})

declare global {
    interface Number {
        toRound(pow: number): string;
        toRoundNumber(pow: number): number;
    }
    interface Array<T> {
        all(predicate: (value: T, index: number, array: T[]) => unknown): boolean;
        any(predicate: (value: T, index: number, array: T[]) => unknown): boolean;
        remove(predicate: (value: T, index: number, array: T[]) => unknown): T[];
        firstOrDefault(predicate?: (value: T, index: number, array: T[]) => unknown): T | undefined;
        contains(value?: T): boolean;
        distinct(): T[];
        copy(): T[];
    }
    interface String {
        contains(value: string): boolean;
    }
    interface JSON {
        copy(obj?: any): any;
    }
    interface Object {
        copy(obj?: any): any;
    }
}
Number.prototype.toRoundNumber = function (pow: number): number {
    const tenPow = Math.pow(10, pow)
    const num = Math.round((this as number + Number.EPSILON) * tenPow) / tenPow;
    return num;
}
Number.prototype.toRound = function (pow: number): string {
    return this.toRoundNumber(pow).toString();
}
String.prototype.contains = function (value: string): boolean {
    if (value)
        return this.trim().toLowerCase().indexOf(value.trim().toLowerCase()) > -1;
    return true;
}
Array.prototype.all = function <T>(predicate: (value: T, index: number, array: T[]) => unknown): boolean {
    return this.filter(predicate).length == this.length;
}
Array.prototype.any = function <T>(predicate: (value: T, index: number, array: T[]) => unknown): boolean {
    return this.filter(predicate).length > 0;
}
Array.prototype.remove = function <T>(predicate: (value: T, index: number, array: T[]) => unknown): T[] {
    const items = this.filter(predicate);
    if (items.length > 0) {
        for (const item of items) {
            const index = this.indexOf(item);
            this.splice(index, 1);
        }
    }
    return this;
}
Array.prototype.distinct = function <T>(): T[] {
    return this.filter((it, i) => this.indexOf(it) === i);
}
Array.prototype.contains = function <T>(value: T): boolean {
    return value && this.indexOf(value) > -1;
}
Array.prototype.firstOrDefault = function <T>(predicate?: (value: T, index: number, array: T[]) => unknown): T | undefined {
    if (predicate) {
        const array = this.filter(predicate);
        if (array.length > 0) return array[0];
    } else if (this.length > 0) return this[0];
    return undefined;
}
Array.prototype.copy = function <T>(): T[] {
    const json = JSON.stringify(this ?? []);
    return JSON.parse(json) as T[];
}
JSON.copy = function (obj?: any): any {
    if (obj) {
        const json = JSON.stringify(obj);
        return JSON.parse(json) as any
    } return obj;
}
Object.copy = function (obj: any): any {
    let newObj: any = {};
    if (obj instanceof Array) {
        newObj = [];
    }
    for (const key in obj) {
        const val = obj[key];
        newObj[key] = typeof val === 'object' ? Object.copy(val) : val;
    }
    return newObj;
}

