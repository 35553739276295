import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceServiceRemark_File from "./DtoFormPriceServiceRemark_File";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceServiceRemark extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Files = [];
        this.UiGroups = [{"Code":"Summary","Label":"\u7B80\u4ECB","Toggle":true,"Close":false,"Buttons":[]},{"Code":"Remark","Label":"\u6CE8\u610F\u4E8B\u9879","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 价格有效期
     */
    ValidDateStart?: Date;
    /**
     * 有效期-截止
     */
    ValidDateEnd?: Date;
    /**
     * 摘要
     */
    Files?: DtoFormPriceServiceRemark_File[];
    /**
     * 
     */
    Note?: string;
}

/**
 * -表单校验
 */
export const DtoFormPriceServiceRemarkRules = {
    Note: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormPriceServiceRemarkMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ValidDateStart",
    Label: "价格有效期",
    Group: "Summary",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "摘要",
    Group: "Summary",
    Customize: true,
  },
  {
    Prop: "Note",
    Label: "",
    Group: "Remark",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
