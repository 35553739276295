import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 国际贸易运输方式详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailContactTransportMode extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    Name?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 国际贸易运输方式详细-表单校验
 */
export const DtoDetailContactTransportModeRules = {
}

/**
 * 国际贸易运输方式详细-设置项
 */
export const DtoDetailContactTransportModeMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
]
