
import DtoFormCodeDto, {
  DtoFormCodeDtoMiezzFormItems,
  DtoFormCodeDtoRules,
} from "@/models.machine/DtoFormCodeDto";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop } from "vue-property-decorator";
import CodePropertyTree from "./CodePropertyTree.vue";
@Options({
  components: {
    CodePropertyTree,
  },
})
export default class CodeDtoForm extends Vue {
  @Model() modelValue = new DtoFormCodeDto();
  @Prop() table?: string;
  @Prop() show: string[] = [];
  items = DtoFormCodeDtoMiezzFormItems;
  rules = DtoFormCodeDtoRules;

  created(): void {
    //
  }
}
