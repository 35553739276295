import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchTransactionMode from "../models.machine/DtoSelectSearchTransactionMode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchTransactionMode from "../models.machine/DtoPageListSearchTransactionMode";
import DtoPageListTransactionMode from "../models.machine/DtoPageListTransactionMode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailTransactionMode from "../models.machine/DtoDetailTransactionMode";
import DtoFormTransactionMode from "../models.machine/DtoFormTransactionMode";

/**
 * 成交方式
 * @description 自动生成的代码,请勿修改
 */
export default class TransactionModeAppService {
    /**
     * 获取成交方式选择器
     * @param {DtoSelectSearchTransactionMode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchTransactionMode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/TransactionMode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取成交方式分页列表
     * @param {DtoPageListSearchTransactionMode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListTransactionMode>>} 
     */
    static Get(dto?: DtoPageListSearchTransactionMode): AxiosPromise<RESTfulResult<DtoPageListTransactionMode>> {
        const url = `/api/TransactionMode/Get`;
        return service.get<RESTfulResult<DtoPageListTransactionMode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TransactionMode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取成交方式详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailTransactionMode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailTransactionMode>> {
        const url = `/api/TransactionMode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailTransactionMode>>(url);
    }

    /**
     * 新增/编辑成交方式
     * @param {DtoFormTransactionMode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormTransactionMode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TransactionMode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除成交方式
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TransactionMode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/TransactionMode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/TransactionMode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/TransactionMode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/TransactionMode/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
