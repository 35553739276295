import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_project_site_form = _resolveComponent("project-site-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_exec_command = _resolveComponent("exec-command")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Project')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.detail.Data.Project?.Name), 1)
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_project_site_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modelExecCommand,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelExecCommand) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_exec_command, {
          modelValue: _ctx.modelExecCommand,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelExecCommand) = $event)),
          execs: [_ctx.exec],
          projectId: _ctx.detail.Data.ProjectId
        }, null, 8, ["modelValue", "execs", "projectId"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}