import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchDomesticGoodsPlace from "../models.machine/DtoSelectSearchDomesticGoodsPlace";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchDomesticGoodsPlace from "../models.machine/DtoPageListSearchDomesticGoodsPlace";
import DtoPageListDomesticGoodsPlace from "../models.machine/DtoPageListDomesticGoodsPlace";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailDomesticGoodsPlace from "../models.machine/DtoDetailDomesticGoodsPlace";
import DtoFormDomesticGoodsPlace from "../models.machine/DtoFormDomesticGoodsPlace";

/**
 * 境内货源地
 * @description 自动生成的代码,请勿修改
 */
export default class DomesticGoodsPlaceAppService {
    /**
     * 获取境内货源地选择器
     * @param {DtoSelectSearchDomesticGoodsPlace} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchDomesticGoodsPlace): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/DomesticGoodsPlace/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取境内货源地分页列表
     * @param {DtoPageListSearchDomesticGoodsPlace} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListDomesticGoodsPlace>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchDomesticGoodsPlace): AxiosPromise<RESTfulResult<DtoPageListDomesticGoodsPlace>> {
        const url = `/api/DomesticGoodsPlace/Get`;
        return service.get<RESTfulResult<DtoPageListDomesticGoodsPlace>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DomesticGoodsPlace/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取境内货源地详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailDomesticGoodsPlace>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailDomesticGoodsPlace>> {
        const url = `/api/DomesticGoodsPlace/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailDomesticGoodsPlace>>(url);
    }

    /**
     * 新增/编辑境内货源地
     * @param {DtoFormDomesticGoodsPlace} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormDomesticGoodsPlace): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DomesticGoodsPlace/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除境内货源地
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DomesticGoodsPlace/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/DomesticGoodsPlace/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/DomesticGoodsPlace/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/DomesticGoodsPlace/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/DomesticGoodsPlace/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 同步境内货源地
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DomesticGoodsPlace/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

}
