
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyAuth from "../CompanyAuth.vue";
@Options({
  components: {
    CompanyAuth,
  },
})
export default class CurrentCompanyAuth extends Vue {
  type = CurrentLogier.GetLogier()?.CompanyType;

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/org/company/auth`;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: `/org/company/auth`,
        Label: "公司信息",
        Power: "CompanyManagement_Company",
      },
    ];
  }
}
