import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamCarrier from "../models.machine/DtoSelectSearchParamCarrier";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamCarrier from "../models.machine/DtoPageListSearchParamCarrier";
import DtoPageListParamCarrier from "../models.machine/DtoPageListParamCarrier";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamCarrier from "../models.machine/DtoDetailParamCarrier";
import DtoFormParamCarrier from "../models.machine/DtoFormParamCarrier";
import DtoListItemParamCarrier_Repeat from "../models.machine/DtoListItemParamCarrier_Repeat";
import { EnumParamCarrierType, EnumParamCarrierTypeOption } from "../models.machine/EnumParamCarrierType";

/**
 * 承运人
 * @description 自动生成的代码,请勿修改
 */
export default class ParamCarrierAppService {
    /**
     * 获取承运人选择器
     * @param {DtoSelectSearchParamCarrier} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamCarrier): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCarrier/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取承运人分页列表
     * @param {DtoPageListSearchParamCarrier} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamCarrier>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamCarrier): AxiosPromise<RESTfulResult<DtoPageListParamCarrier>> {
        const url = `/api/ParamCarrier/Get`;
        return service.get<RESTfulResult<DtoPageListParamCarrier>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCarrier/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取承运人详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamCarrier>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamCarrier>> {
        const url = `/api/ParamCarrier/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamCarrier>>(url);
    }

    /**
     * 新增/编辑承运人
     * @param {DtoFormParamCarrier} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamCarrier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCarrier/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除承运人
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCarrier/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCarrier/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCarrier/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamCarrier/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamCarrier_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamCarrier_Repeat[]>> {
        const url = `/api/ParamCarrier/GetList`;
        return service.get<RESTfulResult<DtoListItemParamCarrier_Repeat[]>>(url);
    }

    /**
     * 获取飞鸵名称
     * @param {EnumParamCarrierType} [type] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetFreighTowerCompanies(type?: EnumParamCarrierType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCarrier/GetFreighTowerCompanies`;
        const _querys: any = {};
        _querys.type = type
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: _querys });
    }

}
