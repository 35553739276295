import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumCustomsType, EnumCustomsTypeOption } from "./EnumCustomsType";
import { EnumCustomsAnnexMode, EnumCustomsAnnexModeOption } from "./EnumCustomsAnnexMode";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoFormOrderDeparture_OrderDepartureAnnex from "./DtoFormOrderDeparture_OrderDepartureAnnex";
import UiGroup from "./UiGroup";

/**
 * 装货港-报关表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderDeparture extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 是否退税
     */
    IsTax?: boolean;
    /**
     * 清关抬头
     */
    ClearCustomsHeader?: EnumCustomsClearanceHeaderProvider;
    /**
     * 服务
     */
    Service?: EnumOrderServiceType;
    /**
     * 报关类型
     */
    Type?: EnumCustomsType;
    /**
     * 添加附件的方式
     */
    Mode?: EnumCustomsAnnexMode;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * Booking No
     */
    OrderPoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 提运单号
     */
    BLNO?: string;
    /**
     * 海关放行时间
     */
    ReleaseTime?: Date;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 客服Id
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 附件
     */
    OrderDepartureAnnexs?: DtoFormOrderDeparture_OrderDepartureAnnex[];
    /**
     * 执行人姓名
     */
    ExecutorName?: string;
    /**
     * 执行人联系方式
     */
    ExecutorPhone?: string;
    /**
     * 执行人其他信息
     */
    ExecutorInfo?: string;
}

/**
 * 装货港-报关表单-表单校验
 */
export const DtoFormOrderDepartureRules = {
}

/**
 * 装货港-报关表单-设置项
 */
export const DtoFormOrderDepartureMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "IsTax",
    Label: "是否退税",
    Customize: true,
  },
  {
    Prop: "ClearCustomsHeader",
    Label: "清关抬头",
    Customize: true,
  },
  {
    Prop: "Service",
    Label: "服务",
    Customize: true,
  },
  {
    Prop: "Type",
    Label: "报关类型",
    Customize: true,
  },
  {
    Prop: "Mode",
    Label: "添加附件的方式",
    Customize: true,
  },
  {
    Prop: "ClientCompanyId",
    Label: "委托人",
    Customize: true,
  },
  {
    Prop: "OrderPoolId",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "OrderId",
    Label: "订单主键",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "编号",
    Customize: true,
  },
  {
    Prop: "BLNO",
    Label: "提运单号",
    Customize: true,
  },
  {
    Prop: "ReleaseTime",
    Label: "海关放行时间",
    Customize: true,
  },
  {
    Prop: "SupplierSource",
    Label: "供应商来源",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "供应商类型Id",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyTypeLabel",
    Label: "供应商类型",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商Id",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "供应商联系人",
    Customize: true,
  },
  {
    Prop: "CustomerServiceId",
    Label: "客服Id",
    Customize: true,
  },
  {
    Prop: "CustomerServiceLabel",
    Label: "客服",
    Customize: true,
  },
  {
    Prop: "OrderDepartureAnnexs",
    Label: "附件",
    Customize: true,
  },
]
