
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import HttpLogAppService from "@/apis.machine/HttpLogAppService";
import DtoDetailHttpLog, {
  DtoDetailHttpLogMiezzDetailItems,
} from "@/models.machine/DtoDetailHttpLog";
import MiezzModal from "@/models/MiezzModal";

@Options({
  components: {},
})
export default class HttpLogDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() siteId?: string;

  detail = new MiezzForm<DtoDetailHttpLog, string>();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailHttpLog();
    //详细-元素配置
    this.detail.Items = DtoDetailHttpLogMiezzDetailItems;
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      HttpLogAppService.GetById(this.id, this.siteId).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }
}
