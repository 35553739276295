
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailJoinCompanyUser, {
  DtoDetailJoinCompanyUserMiezzDetailItems,
} from "@/models.machine/DtoDetailJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserDetail from "../UserDetail.vue";
@Options({
  components: {
    UserDetail,
  },
})
export default class CurrentUserDetail extends Vue {
  detail = new MiezzForm<DtoDetailJoinCompanyUser, string>(
    "CompanyManagement_User_Detail"
  );
  buttons: MiezzButton[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/org/user/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: `/org/user/detail/${this.$route.params.id}`,
        Label: "用户信息",
        Power: "CompanyManagement_User_Detail",
      },
    ];
    
    this.detail.DefaultSpan = 12;
    const logier = CurrentLogier.GetLogier();
    if (logier?.CompanyType == EnumCompanyType.Platform) {
      this.detail.Items = DtoDetailJoinCompanyUserMiezzDetailItems.filter(
        (it) => it.Prop != "Company" && it.Prop != "ServiceProviderCompanyName"
      );
    } else {
      this.detail.Items = DtoDetailJoinCompanyUserMiezzDetailItems.filter(
        (it) =>
          it.Prop != "Company" &&
          it.Prop != "ServiceProviderCompanyName" &&
          it.Prop != "UserStatus"
      );
    }
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "edit",
        Label: "编辑",
        Icon: "edit",
        Type: "text",
        Size: "small",
        PowerCode: "CompanyManagement_User_Edit",
      },
    ];
  }
}
