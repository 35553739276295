import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67034046"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  colspan: "5",
  class: "business-card"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  colspan: "5",
  rowspan: "3"
}
const _hoisted_4 = {
  colspan: "5",
  class: "business-card"
}
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  colspan: "5",
  class: "business-card"
}
const _hoisted_7 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_1, [
        _createVNode(_component_el_form_item, { label: "Shipper（发货人）" }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.form.Data.ShipperContent), 1)
      ]),
      _createElementVNode("td", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_4, [
        _createVNode(_component_el_form_item, { label: "Consignee（收货人）" }),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.form.Data.ConsigneeContent), 1)
      ])
    ]),
    _createElementVNode("tr", null, [
      _createElementVNode("td", _hoisted_6, [
        _createVNode(_component_el_form_item, { label: "Notify Party（通知人）" }),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.form.Data.NotifyPartyContent), 1)
      ])
    ])
  ], 64))
}