import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"width":"100%","word-break":"break-all","text-align":"left"} }
const _hoisted_2 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_currency = _resolveComponent("miezz-currency")!
  const _component_miezz_supplier = _resolveComponent("miezz-supplier")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'PoolId')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.form.Data.PoolId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.PoolId) = $event)),
                  placeholder: "请选择",
                  filterable: "",
                  style: {"width":"100% !important"},
                  onChange: _ctx.handleSelectPoolId
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pools, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.Value,
                        label: item.Label,
                        disabled: item.Data.IsLock,
                        value: item.Value
                      }, null, 8, ["label", "disabled", "value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onChange"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'ClientCompanyLabel' && _ctx.form.Data.Type == 10)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.isString(_ctx.form.Data.ClientCompanyLabel)), 1)
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'SupplierCompanyId' && _ctx.form.Data.Type == 20)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Data.SupplierCompanyId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.SupplierCompanyId) = $event)),
                  style: {"display":"none"}
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSupplier())),
                  style: {"width":"100%","word-break":"break-all","text-align":"left","cursor":"pointer","color":"#fe995b"}
                }, _toDisplayString(_ctx.isString(_ctx.form.Data.SupplierCompanyContactLabel)), 1)
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'CostId')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 3,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_autocomplete, {
                  modelValue: _ctx.form.Data.CostName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.CostName) = $event)),
                  clearable: false,
                  "fetch-suggestions": _ctx.querySearchCost,
                  "popper-class": "my-autocomplete",
                  placeholder: "请填写关键词",
                  onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeCostId())),
                  onSelect: ((item)=>{_ctx.handleSelectCost(item)})
                }, {
                  default: _withCtx(({ item }) => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(item.Label), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "fetch-suggestions", "onSelect"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'CurrencySystemId')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 4,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Data.CurrencySystemId,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.CurrencySystemId) = $event)),
                  style: {"display":"none"}
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onCurrency(_ctx.form.Data.CurrencySystemId))),
                  style: {"width":"100%","word-break":"break-all","text-align":"left","cursor":"pointer","color":"#fe995b"}
                }, _toDisplayString(_ctx.isString(_ctx.form.Data.CurrencySystemName)), 1)
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'UnitPrice')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 5,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  placeholder: "请填写",
                  disabled: false,
                  onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onChangeUnitPrice())),
                  modelValue: _ctx.form.Data.UnitPrice,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.Data.UnitPrice) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'FirstChargeUnitNumber')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 6,
              prop: "FirstChargeUnitValue",
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Data.FirstChargeUnitValue,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.Data.FirstChargeUnitValue) = $event)),
                  style: {"display":"none"}
                }, null, 8, ["modelValue"]),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.Data.FirstChargeUnitNumber,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.Data.FirstChargeUnitNumber) = $event)),
                  onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onChangeFirstChargeUnitNumber())),
                  placeholder: "请填写",
                  style: {"width":"100% !important"}
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.form.Data.FirstChargeUnitId,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.Data.FirstChargeUnitId) = $event)),
                      placeholder: "请选择",
                      style: {"width":"150px"},
                      onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onChangeFirstChargeUnitNumber()))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chargeUnits, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.Value,
                            label: item.Label,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'TotalPrice')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 7,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isString(_ctx.form.Data.TotalPrice)), 1)
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Remark')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 8,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  placeholder: "请填写",
                  disabled: false,
                  modelValue: _ctx.form.Data.Remark,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.Data.Remark) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'StartDate')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 9,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.form.Data.StartDate,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.Data.StartDate) = $event)),
                  style: {"width":"100% !important"},
                  placeholder: "请选择",
                  format: "YYYY/MM/DD"
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'AccountPeriod')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 10,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  disabled: _ctx.form.Data.Type==20&&(_ctx.form.Data.SupplierCompanyId==null || _ctx.form.Data.SupplierCompanyId==undefined)?true:false,
                  modelValue: _ctx.form.Data.AccountPeriod,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.Data.AccountPeriod) = $event)),
                  onChange: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onChangeAccountPeriod())),
                  placeholder: "请填写"
                }, null, 8, ["disabled", "modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_currency, {
      defaultRate: '美元',
      isRate: true,
      defaultCurrency: '美元',
      ref: "Currency",
      onOnSelectCurrency: _ctx.onSelectCurrency
    }, null, 8, ["onOnSelectCurrency"]),
    _createVNode(_component_miezz_supplier, {
      ref: "Supplier",
      isDetail: false,
      onOnSelectSupplier: _ctx.onSelectSupplier
    }, null, 8, ["onOnSelectSupplier"])
  ], 64))
}