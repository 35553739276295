import { EnumBillCheckStatus, EnumBillCheckStatusOption } from "./EnumBillCheckStatus";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormBillCheckStatus {
    constructor() {
        //无参构造函数
        this.Visiable = false;
    }

    [proName: string]: any;
    /**
     * 
     */
    BillId?: string[];
    /**
     * 
     */
    Status?: EnumBillCheckStatus;
    /**
     * 
     */
    Visiable?: boolean;
}

/**
 * -表单校验
 */
export const DtoFormBillCheckStatusRules = {
}
