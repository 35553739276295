
import AuthAppService from "@/apis.machine/AuthAppService";
import SmsAppService from "@/apis.machine/SmsAppService";
import SysAppService from "@/apis.machine/SysAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoCallbackAuthCheckUser from "@/models.machine/DtoCallbackAuthCheckUser";
import DtoFormSmsLogin, {
  DtoFormSmsLoginMiezzFormItems,
  DtoFormSmsLoginRules,
} from "@/models.machine/DtoFormSmsLogin";
import DtoFormSmsSendVerifyCode from "@/models.machine/DtoFormSmsSendVerifyCode";
import { EnumUserStatus } from "@/models.machine/EnumUserStatus";
import Logier from "@/models.machine/Logier";
import WebOptions from "@/models.machine/WebOptions";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class LoginSms extends Vue {
  form = new MiezzForm<DtoFormSmsLogin, string>();
  check: DtoCallbackAuthCheckUser | null | undefined = null;
  countdown = 0;
  buttons: MiezzButton[] = [
    {
      Code: "sms-login",
      Label: "登录",
      Type: "primary",
    },
  ];
  btnPasswordLogin: MiezzButton = {
    Code: "password-login",
    Label: "密码登录",
    Type: "text",
    Size: "small",
  };
  btnRegister: MiezzButton = {
    Code: "register",
    Label: "注册公司",
    Type: "text",
    Size: "small",
  };
  height = 280;
  web?: WebOptions = {};

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormSmsLogin();
    this.form.Data.PhoneNumber = this.$route.query.phoneNumber as string;
    //表单-校验
    this.form.Rules = DtoFormSmsLoginRules;
    this.form.Rules.PhoneNumber[1] = this.getRulePhoneNumber();
    this.form.Rules.SurePassword[0] = this.getRuleSurePassword();
    //表单-元素配置
    this.form.Items = JSON.copy(DtoFormSmsLoginMiezzFormItems);
    this.form.Data.InviteCode = this.$route.query.yqm as string;
    if (this.form.Data.InviteCode) {
      this.form.Items?.unshift({
        Prop: "InviteCode",
        Label: "邀请码",
        Type: EnumMiezzFormItemType.Text,
      });
      this.height = 330;
    } else {
      this.height = 280;
    }

    SysAppService.GetWebInfo().then((r) => {
      this.web = r.data.Data;
    });
  }

  mounted(): void {
    if (this.form.Data.PhoneNumber)
      MiezzForm.validateField(this.$refs, "ruleForm", "PhoneNumber");
  }

  getRulePhoneNumber(): any {
    return {
      validator: (rule: any, value: any, callback: any) => {
        if (value) {
          if (!/^1[3456789][0-9]{9}$/.test(value)) {
            this.check = undefined;
            return callback(new Error("请输入正确的手机号码"));
          } else {
            AuthAppService.CheckUserByAccount(value, this.form.Data.InviteCode)
              .then((r) => {
                const check = r.data.Data;
                if (r.data.Extras?.Message)
                  return callback(new Error(r.data.Extras.Message));
                if (check) {
                  if (check.Status == EnumUserStatus.Disabled)
                    return callback(new Error("您的账号已禁用，无法登陆"));
                  if (check.CompanyIds?.length == 0)
                    return callback(new Error("您的账号未入驻公司，无法登陆"));
                }
                this.check = check;
                for (const item of this.form.Items ?? []) {
                  switch (item.Prop) {
                    case "Password":
                    case "SurePassword":
                      item.Hide = check && check.SetPassword;
                      if (item.Hide) {
                        this.form.Data.Password = undefined;
                        this.form.Data.SurePassword = undefined;
                        this.height = this.form.Data.InviteCode ? 330 : 280;
                      } else {
                        this.height = this.form.Data.InviteCode ? 476 : 426;
                      }
                      break;
                    default:
                      break;
                  }
                }
                callback();
              })
              .catch(callback);
          }
        } else {
          this.check = undefined;
          callback();
        }
      },
    };
  }

  getRuleSurePassword(): any {
    return {
      validator: (rule: any, value: any, callback: any) => {
        if (this.form.Data.Password && !value) {
          return callback(new Error("请确认密码"));
        } else if (
          value &&
          this.form.Data.Password &&
          value != this.form.Data.Password
        ) {
          return callback(new Error("密码不一致"));
        } else {
          callback();
        }
      },
    };
  }

  handleCountdown(): void {
    if (this.countdown > 0) {
      setTimeout(() => {
        this.countdown--;
        this.handleCountdown();
      }, 1000);
    }
  }

  handleSendSMS(): void {
    this.countdown = 60;
    this.handleCountdown();
    const dto = new DtoFormSmsSendVerifyCode();
    dto.Code = "Login";
    dto.PhoneNumber = this.form.Data.PhoneNumber;
    SmsAppService.SendVerifyCodeAsync(dto)
      .then((r) => {
        if (r.data.Extras) {
          const param = JSON.parse(r.data.Extras);
          if (param && param.code) {
            this.form.Data.VerifyCode = param.code;
          }
        }
      })
      .catch(() => {
        this.countdown = 0;
      });
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "sms-login":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            AuthAppService.SmsLogin(this.form.Data)
              .then(async (r) => {
                this.login(r.data.Data);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "password-login":
        this.$router.push("/login/password");
        break;
      case "register":
        this.$router.push("/register");
        break;
      default:
        break;
    }
  }

  async login(logier?: Logier): Promise<void> {
    if (logier) {
      CurrentLogier.SetLogier(logier);
      CurrentLogier.SetBreadcrumbs([]);
      await CurrentLogier.SetMenus();
      this.$router.push("/");
    }
  }
}
