
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceBatchUpdateItem {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * Id
     */
    Id?: string;
    /**
     * 金额
     */
    Amount?: number;
}

/**
 * -表单校验
 */
export const DtoFormPriceBatchUpdateItemRules = {
}
