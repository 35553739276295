
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import MiezzButton from "@/models/MiezzButton";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";

export default class PriceServiceDetail extends Vue {
  @Model() modelValue?: MiezzMenuTab;
  menuTab = new MiezzMenuTab();
  title?: string = "";

  created(): void {
    const id = this.$route.params.id as string;
    this.menuTab = this.modelValue ?? new MiezzMenuTab();
    this.menuTab.Url = `/price/setup/service/detail/${id}`;
    this.menuTab.Menus = [
      {
        Label: "费用组成",
        Url: `/price/setup/service/detail/${id}/cost-composition`,
        Power: "PriceManagement_SetUp_Service_Detail_CostComposition",
      },
      {
        Label: "摘要与注意事项",
        Url: `/price/setup/service/detail/${id}/remark`,
        Power: "PriceManagement_SetUp_Service_Detail_Remark",
      },
    ];

    const services = JSON.parse(localStorage.getItem("services") ?? "[]") as {
      id: string;
      name: string;
    }[];
    this.title = services.firstOrDefault((it) => it.id == id)?.name;
    PriceServiceAppService.GetById(id).then((r) => {
      this.title = r.data.Data?.Name;
      services.push({ id: id, name: this.title ?? "" });
      localStorage.setItem("services", JSON.stringify(services));
    });
  }

  /**按钮点击 */
  @Emit()
  handleMenuTabClick(btn: MiezzButton, complete: () => void): void {
    //
  }
}
