import UiSelectOption from "./UiSelectOption";

/**
 * 参数类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumParamType {
    /**
     * HS编码（品牌类型）
     */
    Brand = 0,
    /**
     * 集装箱箱型
     */
    ParamContainerType = 1,
    /**
     * 承运人
     */
    ParamCarrier = 2,
    /**
     * 国家
     */
    ParamCountry = 3,
    /**
     * 世界港口
     */
    ParamPort = 4,
    /**
     * 费用名称
     */
    ParamPriceCost = 5,
    /**
     * 物流计价单位
     */
    ParamTransportChargeUnit = 6,
    /**
     * 币制
     */
    ParamCurrencySystem = 7,
    /**
     * 渠道
     */
    ParamTransportChannel = 8,
    /**
     * 航线代码
     */
    ParamTransportRouteCode = 9,
    /**
     * 监管方式
     */
    SupervisionMode = 10,
    /**
     * 征免方式
     */
    ExemptingMode = 11,
    /**
     * 征免性质
     */
    ExemptionNature = 12,
    /**
     * 境内货源地
     */
    DomesticGoodsPlace = 13,
    /**
     * 详细产地
     */
    Place = 14,
    /**
     * 海关运输方式
     */
    TransportMode = 15,
    /**
     * HS编码（出口享惠情况）
     */
    Export = 16,
    /**
     * 成交方式
     */
    TransactionMode = 17,
    /**
     * 贸易条款
     */
    TradeTerms = 18,
    /**
     * 付款条款
     */
    PaymentTerms = 19,
    /**
     * 企业代码类型
     */
    ParamCompanyCodeType = 20,
    /**
     * 申报地海关
     */
    Customhouse = 21,
    /**
     * 国际贸易运输方式
     */
    ContactTransportMode = 22,
    /**
     * 纳税人类型
     */
    ParamTaxType = 23,
    /**
     * 世界地区
     */
    ParamCountryArea = 24,
}

/**
 * 参数类型-选项
 */
export const EnumParamTypeOption: UiSelectOption<number>[] = [
    { Label: "HS编码（品牌类型）", Value: 0, Data: "Brand" },
    { Label: "集装箱箱型", Value: 1, Data: "ParamContainerType" },
    { Label: "承运人", Value: 2, Data: "ParamCarrier" },
    { Label: "国家", Value: 3, Data: "ParamCountry" },
    { Label: "世界港口", Value: 4, Data: "ParamPort" },
    { Label: "费用名称", Value: 5, Data: "ParamPriceCost" },
    { Label: "物流计价单位", Value: 6, Data: "ParamTransportChargeUnit" },
    { Label: "币制", Value: 7, Data: "ParamCurrencySystem" },
    { Label: "渠道", Value: 8, Data: "ParamTransportChannel" },
    { Label: "航线代码", Value: 9, Data: "ParamTransportRouteCode" },
    { Label: "监管方式", Value: 10, Data: "SupervisionMode" },
    { Label: "征免方式", Value: 11, Data: "ExemptingMode" },
    { Label: "征免性质", Value: 12, Data: "ExemptionNature" },
    { Label: "境内货源地", Value: 13, Data: "DomesticGoodsPlace" },
    { Label: "详细产地", Value: 14, Data: "Place" },
    { Label: "海关运输方式", Value: 15, Data: "TransportMode" },
    { Label: "HS编码（出口享惠情况）", Value: 16, Data: "Export" },
    { Label: "成交方式", Value: 17, Data: "TransactionMode" },
    { Label: "贸易条款", Value: 18, Data: "TradeTerms" },
    { Label: "付款条款", Value: 19, Data: "PaymentTerms" },
    { Label: "企业代码类型", Value: 20, Data: "ParamCompanyCodeType" },
    { Label: "申报地海关", Value: 21, Data: "Customhouse" },
    { Label: "国际贸易运输方式", Value: 22, Data: "ContactTransportMode" },
    { Label: "纳税人类型", Value: 23, Data: "ParamTaxType" },
    { Label: "世界地区", Value: 24, Data: "ParamCountryArea" },
];
