import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRquestPriceSurchargeGroup from "../models.machine/DtoRquestPriceSurchargeGroup";
import DtoRquestPriceSurchargeGroupRemark from "../models.machine/DtoRquestPriceSurchargeGroupRemark";
import DtoRquestPriceSurchargeGroupItem from "../models.machine/DtoRquestPriceSurchargeGroupItem";

/**
 * 价格附加费分组
 * @description 自动生成的代码,请勿修改
 */
export default class PriceSurchargeGroupAppService {
    /**
     * 获取下拉框数据
     * @param {string} [serviceTableId] 服务价格表Id
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 下拉框数据
     */
    static GetSelectByServiceTableId(serviceTableId?: string): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PriceSurchargeGroup/GetSelect/${serviceTableId}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 添加关联附加费分组
     * @param {DtoRquestPriceSurchargeGroup} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Add(dto?: DtoRquestPriceSurchargeGroup): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceSurchargeGroup/Add`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 设置备注
     * @param {DtoRquestPriceSurchargeGroupRemark} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} Id
     */
    static PostRemark(dto?: DtoRquestPriceSurchargeGroupRemark): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceSurchargeGroup/PostRemark`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 解除关联附加费分组
     * @param {DtoRquestPriceSurchargeGroup} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(dto?: DtoRquestPriceSurchargeGroup): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceSurchargeGroup/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 校验是否只有一个价格信息关联了该组附加费
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckSingleItemById(id?: string): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PriceSurchargeGroup/CheckSingleItem/${id}`;
        return service.post<RESTfulResult<boolean>>(url);
    }

    /**
     * 添加附加费
     * @param {DtoRquestPriceSurchargeGroupItem} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static AddItems(dto?: DtoRquestPriceSurchargeGroupItem): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceSurchargeGroup/AddItems`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 删除附加费
     * @param {DtoRquestPriceSurchargeGroupItem} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static DeleteItems(dto?: DtoRquestPriceSurchargeGroupItem): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceSurchargeGroup/DeleteItems`;
        return service.delete<RESTfulResult<string>>(url, { data: dto });
    }

}
