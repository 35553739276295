import UiFormWithStringId from "./UiFormWithStringId";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodeProperty from "./DtoFormCodeProperty";
import DtoFormCodeDynamicSearchItem from "./DtoFormCodeDynamicSearchItem";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeController extends UiFormWithStringId {
    constructor() {
        //无参构造函数
        super()
        this.GetSelectApi = false;
        this.SelectOption = [];
        this.GetPageListApi = false;
        this.DragSort = false;
        this.DynamicSearchItems = [];
        this.ListItem = [];
        this.GetDetailApi = false;
        this.Detail = [];
        this.PostApi = false;
        this.Form = [];
        this.DeleteApi = false;
    }

    [proName: string]: any;
    /**
     * 基服务
     */
    BaseService?: string;
    /**
     * 表
     */
    Table?: string;
    /**
     * Id类型
     */
    IdType?: string;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 创建Vue代码目录
     */
    ClientPath?: string;
    /**
     * 选择器接口
     */
    GetSelectApi?: boolean;
    /**
     * 选择器标签模板
     */
    SelectTemplate?: string;
    /**
     * 选择器-项数据
     */
    SelectOption?: DtoFormCodeProperty[];
    /**
     * 分页列表接口
     */
    GetPageListApi?: boolean;
    /**
     * 拖曳排序
     */
    DragSort?: boolean;
    /**
     * 动态搜索-项
     */
    DynamicSearchItems?: DtoFormCodeDynamicSearchItem[];
    /**
     * 分页列表-行
     */
    ListItem?: DtoFormCodeProperty[];
    /**
     * 详细接口
     */
    GetDetailApi?: boolean;
    /**
     * 详细
     */
    Detail?: DtoFormCodeProperty[];
    /**
     * 保存接口
     */
    PostApi?: boolean;
    /**
     * 表单
     */
    Form?: DtoFormCodeProperty[];
    /**
     * 删除接口
     */
    DeleteApi?: boolean;
}

/**
 * -表单校验
 */
export const DtoFormCodeControllerRules = {
    IdType: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Group: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Summary: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    App: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ClientPath: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCodeControllerMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "BaseService",
    Label: "基服务",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Table",
    Label: "表",
    Hide: true,
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "IdType",
    Label: "Id类型",
    Hide: true,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Group",
    Label: "分组",
    Type: EnumMiezzFormItemType.Select,
    AllowCreate: true,
  },
  {
    Prop: "Summary",
    Label: "注释",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "App",
    Label: "应用",
    Customize: true,
  },
  {
    Prop: "ClientPath",
    Label: "创建Vue代码目录",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "GetSelectApi",
    Label: "选择器接口",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "SelectOption",
    Label: "选择器-项数据",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "GetPageListApi",
    Label: "分页列表接口",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "DragSort",
    Label: "拖曳排序",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "DynamicSearchItems",
    Label: "动态搜索-项",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "ListItem",
    Label: "分页列表-行",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "GetDetailApi",
    Label: "详细接口",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Detail",
    Label: "详细",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "PostApi",
    Label: "保存接口",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Form",
    Label: "表单",
    Hide: true,
    Customize: true,
  },
  {
    Prop: "DeleteApi",
    Label: "删除接口",
    Hide: true,
    Type: EnumMiezzFormItemType.Switch,
  },
]
