import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderWarehouse from "../models.machine/DtoPageListSearchOrderWarehouse";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderWarehouse from "../models.machine/DtoPageListOrderWarehouse";
import DtoDetailOrderWarehouse from "../models.machine/DtoDetailOrderWarehouse";
import DtoFormOrderWarehouse from "../models.machine/DtoFormOrderWarehouse";
import DtoRequestOrderWarehouseHistory from "../models.machine/DtoRequestOrderWarehouseHistory";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRequestCreater from "../models.machine/DtoRequestCreater";
import DtoRequestOperation from "../models.machine/DtoRequestOperation";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 进销存/出入库
 * @description 自动生成的代码,请勿修改
 */
export default class OrderWarehouseAppService {
    /**
     * 获取进销存/出入库分页列表
     * @param {DtoPageListSearchOrderWarehouse} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderWarehouse>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderWarehouse): AxiosPromise<RESTfulResult<DtoPageListOrderWarehouse>> {
        const url = `/api/OrderWarehouse/Get`;
        return service.get<RESTfulResult<DtoPageListOrderWarehouse>>(url, { params: dto });
    }

    /**
     * 获取进销存/出入库详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderWarehouse>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderWarehouse>> {
        const url = `/api/OrderWarehouse/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderWarehouse>>(url);
    }

    /**
     * 添加进销存/出入库
     * @param {DtoFormOrderWarehouse} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderWarehouse): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderWarehouse/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除进销存/出入库
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderWarehouse/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取合作方的历史记录
     * @param {DtoRequestOrderWarehouseHistory} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetPartnerHistory(dto?: DtoRequestOrderWarehouseHistory): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderWarehouse/GetPartnerHistory`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取交货地的历史记录
     * @param {DtoRequestOrderWarehouseHistory} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetAddressHistory(dto?: DtoRequestOrderWarehouseHistory): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderWarehouse/GetAddressHistory`;
        return service.get<RESTfulResult<string[]>>(url, { params: dto });
    }

    /**
     * 获取交期要求的历史记录
     * @param {DtoRequestOrderWarehouseHistory} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetRequirementHistory(dto?: DtoRequestOrderWarehouseHistory): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderWarehouse/GetRequirementHistory`;
        return service.get<RESTfulResult<string[]>>(url, { params: dto });
    }

    /**
     * 获取执行人集合
     * @param {DtoRequestCreater} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCreater(dto?: DtoRequestCreater): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderWarehouse/GetCreater`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取可指派人集合
     * @param {DtoRequestCreater} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetAssigner(dto?: DtoRequestCreater): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderWarehouse/GetAssigner`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 指派执行人
     * @param {DtoRequestOperation} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignUser(dto?: DtoRequestOperation): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderWarehouse/AssignUser`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量交货
     * @param {DtoRequestOperation} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delivery(dto?: DtoRequestOperation): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderWarehouse/Delivery`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 删除合作方的历史记录
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeletePartnerById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderWarehouse/DeletePartner/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 获取合作方
     * @param {DtoRequestCreater} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetPartner(dto?: DtoRequestCreater): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderWarehouse/GetPartner`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取交货地
     * @param {DtoRequestCreater} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetAddress(dto?: DtoRequestCreater): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderWarehouse/GetAddress`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取交期要求
     * @param {DtoRequestCreater} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetRequirement(dto?: DtoRequestCreater): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderWarehouse/GetRequirement`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

}
