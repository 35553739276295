
import MiezzButton from "@/models/MiezzButton";
import { ElMessageBox } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButtonSource from "./MiezzButtonSource.vue";
@Options({
  components: {
    MiezzButtonSource,
  },
})
export default class MiezzButtonConfirm extends Vue {
  @Prop() model = new MiezzButton();

  button = new MiezzButton();

  created(): void {
    this.onModelChanged();
  }

  @Watch("model")
  onModelChanged(): void {
    const btn = Object.copy(this.model) as MiezzButton;
    btn.Message = undefined;
    btn.MessageBox = undefined;
    btn.MessageWidth = undefined;
    // btn.CancelClickEvent = this.model.MessageBox ? false : true;
    this.button = btn;
  }

  handleMessageBoxClick(model: MiezzButton, complete: () => void): void {
    if (model.DisabledConfirm) {
      this.handleConfirm(this.model, () => {
        this.button.Loading = false;
      });
    } else {
      ElMessageBox.confirm(this.model.Message, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleConfirm(this.model, () => {
            this.button.Loading = false;
          });
        })
        .catch(() => {
          this.button.Loading = false;
        });
    }
  }

  handlePopConfirmClick(): void {
    if (this.model.Loading != true) {
      this.model.Loading = true;
      this.handleConfirm(this.model, () => {
        this.model.Loading = false;
      });
    }
  }

  @Emit()
  handleConfirm(model: MiezzButton, complete: () => void): void {
    //
  }
}
