import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 航线代码表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamTransportRouteCode extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 承运人
     */
    ParamCarrierId?: string;
    /**
     * 备注
     */
    Remarks?: string;
}

/**
 * 航线代码表单-表单校验
 */
export const DtoFormParamTransportRouteCodeRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Remarks: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 航线代码表单-设置项
 */
export const DtoFormParamTransportRouteCodeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
  {
    Prop: "ParamCarrierId",
    Label: "承运人",
    Customize: true,
  },
  {
    Prop: "Remarks",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
