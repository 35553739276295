import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 获取电子章-结果
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackCompanyBusinessCardGetElectronicSeal extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 电子章-公司中文名称
     */
    SealNameZh?: string;
    /**
     * 电子章-公司英文名称
     */
    SealNameEn?: string;
    /**
     * 电子章-法人姓名
     */
    SealLeaderName?: string;
}

/**
 * 获取电子章-结果-表单校验
 */
export const DtoCallbackCompanyBusinessCardGetElectronicSealRules = {
}

/**
 * 获取电子章-结果-设置项
 */
export const DtoCallbackCompanyBusinessCardGetElectronicSealMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "SealNameZh",
    Label: "电子章-公司中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SealNameEn",
    Label: "电子章-公司英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SealLeaderName",
    Label: "电子章-法人姓名",
    Type: EnumMiezzFormItemType.Text,
  },
]
