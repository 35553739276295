import UiSelectOption from "./UiSelectOption";

/**
 * 用户状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumUserStatus {
    /**
     * 启用
     */
    Normal = 0,
    /**
     * 禁用
     */
    Disabled = 20,
}

/**
 * 用户状态-选项
 */
export const EnumUserStatusOption: UiSelectOption<number>[] = [
    { Label: "启用", Value: 0, Data: "Normal" },
    { Label: "禁用", Value: 20, Data: "Disabled" },
];
