
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderChooseService, {
  DtoFormOrderChooseServiceMiezzFormItems,
  DtoFormOrderChooseServiceRules,
} from "@/models.machine/DtoFormOrderChooseService";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import UiSelectOption from "@/models.machine/UiSelectOption";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import {
  EnumCustomsClearanceHeaderProvider,
  EnumCustomsClearanceHeaderProviderOption,
} from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import Logier from "@/models.machine/Logier";
import PriceServiceShortcutAppService from "@/apis.machine/PriceServiceShortcutAppService";
import DtoFormPriceServiceShortcutName, {
  DtoFormPriceServiceShortcutNameMiezzFormItems,
} from "@/models.machine/DtoFormPriceServiceShortcutName";
import DtoFormPriceServiceShortcut from "@/models.machine/DtoFormPriceServiceShortcut";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import vuedraggable from "vuedraggable";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import DtoDeleteServiceRemark from "@/models.machine/DtoDeleteServiceRemark";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
@Options({
  components: {
    vuedraggable,
  },
})
export default class OrderPoolChooseService extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() code?: string;
  @Prop() clientCompanyId?: string;
  @Prop() clientUserId?: string;
  @Prop() orderWarehouseId?: string;
  @Prop() orderServiceType?: number;
  @Prop() stock?: boolean;

  logier?: Logier;
  powers: string[] = [];
  form = new MiezzForm<DtoFormOrderChooseService, string>();
  companies: DtoSelectOptionCompany[] = [];
  filterCompanies: DtoSelectOptionCompany[] = [];
  users: UiSelectOption<string>[] = [];
  services: UiSelectOption<string>[] = [];
  EnumCompanyType = EnumCompanyType;

  EnumOrderPoolStatus = EnumOrderPoolStatus;
  EnumTransportDirection = EnumTransportDirection;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportType = EnumTransportType;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  departurePortServiceIds: string[] = [];
  departurePortServices: UiSelectOption<string>[] = [];
  destinationPortServiceIds: string[] = [];
  destinationPortServices: UiSelectOption<string>[] = [];
  EnumCustomsClearanceHeaderProviderOption =
    EnumCustomsClearanceHeaderProviderOption;
  EnumCustomsClearanceHeaderProvider = EnumCustomsClearanceHeaderProvider;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  yesno: UiSelectOption<boolean>[] = [
    {
      Label: "是",
      Value: true,
    },
    {
      Label: "否",
      Value: false,
    },
  ];
  loading = false;

  modalShortcut = new MiezzModal(MiezzModalType.Dialog);
  formShortcut = new MiezzForm<DtoFormPriceServiceShortcutName, string>();
  shortcutButtons: MiezzButton[] = [
    {
      Code: "show-rename-shortcut",
      Title: "编辑",
      Icon: "edit",
      Type: "text",
      Size: "small",
    },
    {
      Code: "delete-shortcut",
      Title: "删除",
      Icon: "delete",
      Type: "text",
      Size: "small",
    },
    {
      Code: "drag-sort-shortcut",
      Title: "拖曳",
      Icon: "Rank",
      Type: "text",
      Size: "small",
    },
  ];
  serviceRemarks: UiSelectOptionWithStringValue[] = [];

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();

    //表单-校验
    this.form.Rules = Object.copy(DtoFormOrderChooseServiceRules);
    this.form.Rules.TransportType = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.TransportContainerLoad = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.DeparturePortTakeMethod = [
      {
        validator: (rule: any, value: any, callback: any) => {
          //勾选了提货时，后方提货方式至少勾选一项，否则保存时下方出现红色字体提示“提货方式至少选择一种”
          if (
            this.form.Data.DeparturePortTake &&
            !this.form.Data.DeparturePortContainer &&
            !this.form.Data.DeparturePortTrunk &&
            !this.form.Data.DeparturePortExpress
          ) {
            return callback(new Error("提货方式至少选择一种"));
          }
          callback();
        },
        trigger: "change",
      },
    ];
    this.form.Rules.DestinationPortDeliveryMethod = [
      {
        validator: (rule: any, value: any, callback: any) => {
          //勾选了提货时，后方提货方式至少勾选一项，否则保存时下方出现红色字体提示“提货方式至少选择一种”
          if (
            this.form.Data.DestinationPortDelivery &&
            !this.form.Data.DestinationPortContainer &&
            !this.form.Data.DestinationPortTrunk &&
            !this.form.Data.DestinationPortExpress
          ) {
            return callback(new Error("送货方式至少选择一种"));
          }
          callback();
        },
        trigger: "change",
      },
    ];
    this.form.Rules.ServiceRemark = [
      {
        max: 10240,
        message: "文本长度不能超过 10240 个字符",
        trigger: "blur",
      },
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
      {
        validator: (rule: any, value: any, callback: any) => {
          //勾选了提货时，后方提货方式至少勾选一项，否则保存时下方出现红色字体提示“提货方式至少选择一种”
          if (value == "其他") {
            return callback(new Error("信息重复，请重新填写"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = JSON.copy(
      DtoFormOrderChooseServiceMiezzFormItems
    ) as MiezzFormItem[];
    if (this.logier?.CompanyType != EnumCompanyType.ServiceProvider) {
      this.form.Items = this.form.Items.filter(
        (it) => it.Prop != "ServiceRemark"
      );
    }
    for (const item of this.form.Items ?? []) {
      switch (item.Prop) {
        case "ClientCompanyId":
          item.Disabled =
            this.id != undefined ||
            this.logier?.CompanyType != EnumCompanyType.ServiceProvider;
          break;
        case "ClientUserId":
          item.Disabled =
            this.id != undefined ||
            this.logier?.CompanyType != EnumCompanyType.ServiceProvider;
          break;

        default:
          break;
      }
    }

    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
      this.modelValue.Title = this.stock
        ? "选择服务内容-库存建单"
        : "选择服务内容";
      this.modelValue.Width = "1208px";
      this.modelValue.Height = "500px";
      this.modelValue.HandleClick = this.handleClick;

      this.modelValue.Buttons = [
        {
          Code: "edit",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
      if (
        this.powers.contains("OrderManagement_Pool_Add_SaveShortcut") &&
        !this.id
      ) {
        this.modelValue.ButtonWidth = "120px";
        this.modelValue.Buttons.push({
          Code: "show-save-shortcut",
          Label: "保存为快捷方式",
          Type: "primary",
          Size: "small",
          PowerCode: "OrderManagement_Pool_Add_SaveShortcut",
        });
      }
    });

    this.modalShortcut.Title = "快捷方式";
    this.modalShortcut.Width = "600px";
    this.modalShortcut.Height = "50px";
    this.modalShortcut.HandleClick = this.handleClick;
    this.modalShortcut.Buttons = [
      {
        Code: "save-shortcut",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.formShortcut.Items = DtoFormPriceServiceShortcutNameMiezzFormItems;
    this.formShortcut.LabelWidth = "70px";
    this.formShortcut.Rules = {
      Name: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (this.formShortcut.Data.Name == "其他")
              return callback(new Error("重复信息，请重新填写"));
            else if (this.formShortcut.Data.Name) {
              PriceServiceShortcutAppService.CheckExist(this.formShortcut.Data)
                .then((r) => {
                  if (r.data.Data) {
                    return callback(new Error("重复信息，请重新填写"));
                  } else {
                    callback();
                  }
                })
                .catch(() => {
                  callback();
                });
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };

    OrderPoolAppService.GetServiceRemarks().then((r) => {
      this.serviceRemarks = (r.data.Data ?? []).filter(
        (it) => it.Label != "其他"
      );
    });

    this.handleGet();
  }

  handleGetServices(): void {
    PriceServiceShortcutAppService.GetSelect().then((r) => {
      this.services = r.data.Data ?? [];
    });
  }

  handleGet(): void {
    Promise.all([
      PriceServiceShortcutAppService.GetSelect().then((r) => {
        this.services = r.data.Data ?? [];
      }),
      CompanyAppService.GetSelect({
        CompanyType: EnumCompanyType.Customer,
        Subordinate: true,
        SeeAll: true,
      }).then((r) => {
        this.companies = r.data.Data ?? [];
      }),
    ]).then(() => {
      if (this.id) {
        OrderPoolAppService.GetChooseServiceById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;
            this.initWatch();
            this._code = this.form.Data.Code;
            this.onCompanyIdChanged(this.form.Data.ClientCompanyId, undefined);
            this.filterClientCompanies();
          }
        });
      } else {
        this.form.Data = new DtoFormOrderChooseService();
        this.initWatch();
        this.form.Data.Code = this.code;
        this._code = this.form.Data.Code;
        this.form.Data.ClientCompanyId = this.clientCompanyId;
        this.form.Data.ClientUserId = this.clientUserId;
        if (
          !this.form.Data.ClientCompanyId &&
          this.logier?.CompanyType == EnumCompanyType.Customer
        ) {
          this.form.Data.ClientCompanyId = this.logier.CompanyId;
          this.form.Data.ClientUserId = this.logier.CompanyUserId;
        }
        this.filterClientCompanies();
      }
    });
  }

  _code?: string;
  codes: string[] = [];
  handleCodeBlur(): void {
    console.log(this.form.Data.Code , this._code);
    if (this.form.Data.Code && this.form.Data.Code != this._code && !this.codes.contains(this.form.Data.Code)) {
      OrderPoolAppService.CheckExistCode({
        Id: this.id,
        ClientCompanyId: this.form.Data.ClientCompanyId,
        Code: this.form.Data.Code,
      }).then((r) => {
        if (r.data.Data) {
          ElMessageBox.confirm(
            "已存在相同的“委托方编号”，是否继续使用该编号？",
            "提示",
            {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.codes.push(this.form.Data.Code ?? "");
            })
            .catch(() => {
              this.form.Data.Code = undefined;
            });
        }
      });
    }
  }

  getServiceRemarks(q: string, cb: any): void {
    cb(
      this.serviceRemarks.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeleteServiceRemark(
    select: UiSelectOption<string>,
    value: string
  ): void {
    var param = new DtoDeleteServiceRemark();
    param.Value = select.Value;
    OrderPoolAppService.DeleteServiceRemark(param).then(() => {
      const old = this.serviceRemarks.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.form.Data.ServiceRemark = undefined;
      } else {
        this.form.Data.ServiceRemark = old?.Label;
      }
      this.serviceRemarks = this.serviceRemarks.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  watchs: (() => void)[] = [];

  initWatch(): void {
    this.watchs = [
      this.$watch("form.Data.ClientCompanyId", this.onCompanyIdChanged),
      this.$watch(
        "form.Data.TransportDirection",
        this.onTransportDirectionChanged
      ),
      this.$watch("form.Data.TransportType", this.onTransportTypeChanged),
      this.$watch(
        "form.Data.TransportContainerLoad",
        this.onTransportContainerLoadChanged
      ),
      this.$watch(
        "form.Data.DeparturePortBooking",
        this.onDeparturePortBookingChanged
      ),
      this.$watch(
        "form.Data.DeparturePortTake",
        this.onDeparturePortTakeChanged
      ),
      this.$watch(
        "form.Data.DeparturePortTrunk",
        this.onDeparturePortTrunkChanged
      ),
      this.$watch(
        "form.Data.DeparturePortWarehouse",
        this.onDeparturePortWarehouseChanged
      ),
      this.$watch(
        "form.Data.DeparturePortDeclareCustoms",
        this.onDeparturePortDeclareCustomsChanged
      ),
      this.$watch(
        "form.Data.DestinationPortClearCustoms",
        this.onDestinationPortClearCustomsChanged
      ),
      this.$watch(
        "form.Data.DestinationPortCustomsClearanceHeaderProvider",
        this.onDestinationPortCustomsClearanceHeaderProviderChanged
      ),
      this.$watch(
        "form.Data.DestinationPortDelivery",
        this.onDestinationPortDeliveryChanged
      ),
      this.$watch(
        "form.Data.DestinationPortContainer",
        this.onDestinationPortContainerChanged
      ),
      this.$watch(
        "form.Data.DestinationPortTrunk",
        this.onDestinationPortTrunkChanged
      ),
      this.$watch(
        "form.Data.DestinationPortExpress",
        this.onDestinationPortExpressChanged
      ),
      this.$watch(
        "form.Data.DestinationPortWarehouse",
        this.onDestinationPortWarehouseChanged
      ),
    ];
  }

  clearWatch(): void {
    for (const watch of this.watchs) {
      watch();
    }
  }

  onCompanyIdChanged(n?: string, o?: string): void {
    if (n == o) return;
    const itemClientUserId = this.form.Items?.firstOrDefault(
      (it) => it.Prop == "ClientUserId"
    );
    if (itemClientUserId) {
      itemClientUserId.Disabled =
        this.form.Data.ClientCompanyId == undefined ||
        this.id != undefined ||
        this.logier?.CompanyType == EnumCompanyType.Customer;
    }
    // const company = this.companies.firstOrDefault(
    //   (it) => it.Id == this.form.Data.ClientCompanyId
    // );
    // if (company && !company.SalesmanId) {
    //   setTimeout(() => {
    //     ElMessage.warning("该客户企业未关联业务员，无法创建委托书");
    //     this.form.Data.ClientCompanyId = undefined;
    //     this.filterClientCompanies("");
    //   }, 100);
    // }
    if (this.form.Data.ClientCompanyId) {
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.form.Data.ClientCompanyId,
      }).then((r) => {
        this.users = r.data.Data ?? [];
        if (this.users.length == 0) {
          if (itemClientUserId) {
            itemClientUserId.Disabled = true;
          }
          this.form.Data.ClientUserId = undefined;
          MiezzForm.validateField(this.$refs, "ruleForm", "ClientUserId");
        }
        if (this.users.all((it) => it.Value != this.form.Data.ClientUserId)) {
          this.form.Data.ClientUserId = undefined;
        }
      });
    } else {
      this.users = [];
      if (this.users.all((it) => it.Value != this.form.Data.ClientUserId)) {
        this.form.Data.ClientUserId = undefined;
      }
    }
    this.handleCodeBlur();
  }

  filterClientCompanies(queryString?: string): void {
    if (this.companies && this.companies.length > 0) {
      if (queryString) {
        this.filterCompanies = this.companies.filter((it) =>
          (it.NameZh + (it.NameEn ? "/" + it.NameEn : ""))?.contains(
            queryString
          )
        );
        if (this.filterCompanies && this.filterCompanies.length > 10)
          this.filterCompanies = this.filterCompanies.slice(0, 10);
      } else if (this.form.Data.ClientCompanyId) {
        this.filterCompanies = this.companies.filter(
          (it) => it.Id == this.form.Data.ClientCompanyId
        );
      } else {
        this.filterCompanies = JSON.copy(this.companies).slice(0, 10);
      }
    }
  }

  onTransportDirectionChanged(n?: string, o?: string): void {
    if (n == o) return;
    //当业务类型选择进口时，如勾选了装货港服务“报关”，不出现是否退税的信息，但是按照是否退税选择了“否”来执行流程；
    if (
      this.form.Data.TransportDirection == EnumTransportDirection.Import &&
      this.form.Data.DeparturePortDeclareCustoms
    ) {
      this.form.Data.DeparturePortDrawback = false;
    }

    if (this.form.Data.TransportDirection == EnumTransportDirection.Other) {
      this.form.Rules.ServiceRemark = [
        {
          max: 10240,
          message: "文本长度不能超过 10240 个字符",
          trigger: "blur",
        },
      ];
      this.form.Data.ServiceRemark = "其他";
    } else {
      this.form.Rules.ServiceRemark = [
        {
          max: 10240,
          message: "文本长度不能超过 10240 个字符",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
      if (this.form.Data.ServiceRemark == "其他") {
        this.form.Data.ServiceRemark = undefined;
      }
    }
  }

  onTransportTypeChanged(n?: string, o?: string): void {
    if (n == o) return;

    //第三个文本框：当第二个文本框为“海运/By Sea”、“铁路/By Railway”时，显示
    if (
      this.form.Data.TransportType != EnumTransportType.Sea &&
      this.form.Data.TransportType != EnumTransportType.Railway
    ) {
      this.form.Data.TransportContainerLoad = undefined;
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   this.form.Data.TransportType == EnumTransportType.Air ||
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    // ) {
    //   if (this.form.Data.DestinationPortTrunk) {
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    //   if (this.form.Data.DestinationPortExpress) {
    //     this.form.Data.DestinationPortTrunk = false;
    //   }
    // }
  }

  onTransportContainerLoadChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortTake) {
        this.form.Data.DeparturePortContainer = true;
      }
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = true;
      }
    }
    //当业务类型不是XX/XX/“整柜/FCL”时，无集装箱拖车项
    else {
      this.form.Data.DeparturePortContainer = undefined;
    }
    MiezzForm.validateField(this.$refs, "ruleForm", "DeparturePortTakeMethod");

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }
    MiezzForm.validateField(
      this.$refs,
      "ruleForm",
      "DestinationPortDeliveryMethod"
    );

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      const check =
        this.form.Data.DeparturePortBooking ||
        this.form.Data.DeparturePortWarehouse ||
        this.form.Data.DeparturePortDeclareCustoms;
      if (check) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortWarehouse = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        if (this.form.Data.DeparturePortBooking != true) {
          // this.form.Data.DeparturePortDeclareCustoms = false;
        }
        if (this.form.Data.DeparturePortDeclareCustoms != true) {
          //this.form.Data.DeparturePortBooking = false;
        }
        if (this.form.Data.DeparturePortWarehouse != true) {
          this.form.Data.DeparturePortWarehouse = false;
          //this.form.Data.DeparturePortDeclareCustoms = false;
        }
      }
    }

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.Bulk
    ) {
      //勾选集装箱拖车，则“干线物流”、“快递”置灰；
      //勾选集装箱拖车，则“仓库/堆场/码头”置灰；
      if (this.form.Data.DestinationPortContainer) {
        this.form.Data.DestinationPortTrunk = false;
        this.form.Data.DestinationPortExpress = false;
        this.form.Data.DestinationPortWarehouse = false;
      }
      //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
      //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
      if (
        this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse
      ) {
        if (this.form.Data.DestinationPortDelivery) {
          this.form.Data.DestinationPortContainer = false;
        } else {
          this.form.Data.DestinationPortContainer = undefined;
        }
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如取消勾选“仓库/堆场/码头”，则同时取消勾选“快递”；
      if (
        !this.form.Data.DestinationPortWarehouse &&
        this.form.Data.DestinationPortExpress
      ) {
        this.form.Data.DestinationPortExpress = false;
      }
      //如勾选了送货方式“快递”时，同时勾选“仓库/堆场/码头”；
      if (this.form.Data.DestinationPortExpress) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   this.form.Data.TransportType == EnumTransportType.Air ||
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    // ) {
    //   if (this.form.Data.DestinationPortTrunk) {
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    //   if (this.form.Data.DestinationPortExpress) {
    //     this.form.Data.DestinationPortTrunk = false;
    //   }
    // }
  }

  onDeparturePortBookingChanged(n?: string, o?: string): void {
    if (n == o) return;

    //订舱：勾选后，后方是否签发提单默认显示为“是”
    if (this.form.Data.DeparturePortBooking) {
      this.form.Data.DeparturePortLadingBill = true;
    } else {
      this.form.Data.DeparturePortLadingBill = undefined;
    }

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DeparturePortWarehouse = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        // this.form.Data.DeparturePortDeclareCustoms = false;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      //勾选了装货港订舱时，自动勾选目的港-仓库；
      if (this.form.Data.DeparturePortBooking) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }
  }

  onDeparturePortTakeChanged(n?: string, o?: string): void {
    if (n == o) return;

    if (this.form.Data.DeparturePortTake) {
      //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
      if (
        this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
      ) {
        this.form.Data.DeparturePortContainer = true;
      }
      //当业务类型不是XX/XX/“整柜/FCL”时，无集装箱拖车项
      else {
        this.form.Data.DeparturePortContainer = undefined;
      }
      if (this.form.Data.DeparturePortExpress == undefined) {
        this.form.Data.DeparturePortExpress = false;
      }
      if (this.form.Data.DeparturePortTrunk == undefined) {
        this.form.Data.DeparturePortTrunk = false;
      }
    } else {
      this.form.Data.DeparturePortContainer = undefined;
      this.form.Data.DeparturePortExpress = undefined;
      this.form.Data.DeparturePortTrunk = undefined;
    }

    MiezzForm.validateField(this.$refs, "ruleForm", "DeparturePortTakeMethod");
  }

  onDeparturePortTrunkChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }
  }

  onDeparturePortExpressChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX.XX./“整柜/FCL”时 如勾选了提货方式“干线物流”或者“快递”时，同时勾选“仓库/堆场/码头”；
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      (this.form.Data.DeparturePortTrunk || this.form.Data.DeparturePortExpress)
    ) {
      this.form.Data.DeparturePortWarehouse = true;
    }
  }

  onDeparturePortWarehouseChanged(n?: string, o?: string): void {
    if (n == o) return;

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortWarehouse) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortDeclareCustoms = true;
      } else {
        this.form.Data.DeparturePortBooking = false;
        //this.form.Data.DeparturePortDeclareCustoms = false;
      }
    }

    // //当业务类型为XX.XX./“整柜/FCL”时 如取消勾选“仓库/堆场/码头”，则同时取消勾选“干线物流”、“快递”；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
    //   !this.form.Data.DeparturePortWarehouse
    // ) {
    //   if (this.form.Data.DeparturePortTake) {
    //     this.form.Data.DeparturePortTrunk = false;
    //     this.form.Data.DeparturePortExpress = false;
    //   } else {
    //     this.form.Data.DeparturePortTrunk = undefined;
    //     this.form.Data.DeparturePortExpress = undefined;
    //   }
    // }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }
  }

  onDeparturePortDeclareCustomsChanged(n?: string, o?: string): void {
    if (n == o) return;

    //报关：勾选后，后方是否退税默认显示为是
    if (this.form.Data.DeparturePortDeclareCustoms) {
      this.form.Data.DeparturePortDrawback = true;
    } else {
      this.form.Data.DeparturePortDrawback = undefined;
    }

    //当业务类型选择进口时，如勾选了装货港服务“报关”，不出现是否退税的信息，但是按照是否退税选择了“否”来执行流程；
    if (
      this.form.Data.TransportDirection == EnumTransportDirection.Import &&
      this.form.Data.DeparturePortDeclareCustoms
    ) {
      this.form.Data.DeparturePortDrawback = false;
    }

    //修订-----优化为：当业务类型不是XX/XX/“整柜/FCL”时
    //7-1-1订舱、仓库/堆场/码头、报关勾选其中任一，则同时选中其余项；
    //7-1-2订舱取消勾选，则同时取消勾选报关；
    //7-1-3报关取消勾选，则同时取消勾选订舱；
    //7-1-4仓库/堆场/码头取消勾选，则同时取消订舱与报关；
    //当业务类型不是XX/XX/“整柜/FCL”时，订舱、仓库/堆场/码头、报关勾选或取消勾选其中任一，则同时选中或取消其余项
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL
    ) {
      if (this.form.Data.DeparturePortDeclareCustoms) {
        this.form.Data.DeparturePortBooking = true;
        this.form.Data.DeparturePortWarehouse = true;
      } else {
        //this.form.Data.DeparturePortBooking = false;
      }
    }
  }

  onDestinationPortClearCustomsChanged(n?: string, o?: string): void {
    if (n == o) return;

    //清关：勾选后，后方清关抬头默认显示为委托人提供
    if (this.form.Data.DestinationPortClearCustoms) {
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider =
        EnumCustomsClearanceHeaderProvider.Client;
    } else {
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider = undefined;
    }
  }

  onDestinationPortCustomsClearanceHeaderProviderChanged(
    n?: string,
    o?: string
  ): void {
    if (n == o) return;

    if (
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider ==
        EnumCustomsClearanceHeaderProvider.ServiceProvider &&
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt == undefined
    ) {
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt = false;
    } else if (
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider == undefined
    ) {
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt = undefined;
    }
  }

  onDestinationPortDeliveryChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型为XX/“空运/By Air”时，或者XX/XX/“散货/BULK”时，无集装箱拖车项
    //LCL时，不勾选订舱，不显示
    if (
      !this.form.Data.DestinationPortDelivery ||
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      (this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.LCL &&
        !this.form.Data.DeparturePortBooking)
    ) {
      this.form.Data.DestinationPortContainer = undefined;
    }

    if (this.form.Data.DestinationPortDelivery) {
      //当业务类型为XX/XX/“整柜/FCL”时，默认勾选集装箱拖车
      if (
        this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL
      ) {
        this.form.Data.DestinationPortContainer = true;
      }
      if (this.form.Data.DestinationPortExpress == undefined) {
        this.form.Data.DestinationPortExpress = false;
      }
      if (this.form.Data.DestinationPortTrunk == undefined) {
        this.form.Data.DestinationPortTrunk = false;
      }
    } else {
      this.form.Data.DestinationPortExpress = undefined;
      this.form.Data.DestinationPortTrunk = undefined;
    }

    MiezzForm.validateField(
      this.$refs,
      "ruleForm",
      "DestinationPortDeliveryMethod"
    );
  }

  onDestinationPortContainerChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选集装箱拖车，则“干线物流”、“快递”置灰；
    //勾选集装箱拖车，则“仓库/堆场/码头”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      this.form.Data.DestinationPortContainer
    ) {
      this.form.Data.DestinationPortTrunk = false;
      this.form.Data.DestinationPortExpress = false;
      this.form.Data.DestinationPortWarehouse = false;
    }
  }

  onDestinationPortTrunkChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   (this.form.Data.TransportType == EnumTransportType.Air ||
    //     this.form.Data.TransportContainerLoad ==
    //       EnumTransportContainerLoad.LCL) &&
    //   this.form.Data.DestinationPortTrunk
    // ) {
    //   this.form.Data.DestinationPortExpress = false;
    // }
  }

  onDestinationPortExpressChanged(n?: string, o?: string): void {
    if (n == o) return;

    //当业务类型不是XX/XX/“散货/BULK”时 如勾选了送货方式“干线物流”、“快递”时，同时勾选“仓库/堆场/码头”
    if (
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress)
    ) {
      this.form.Data.DestinationPortWarehouse = true;
    }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如勾选了送货方式“快递”时，同时勾选“仓库/堆场/码头”；
      if (this.form.Data.DestinationPortExpress) {
        this.form.Data.DestinationPortWarehouse = true;
      }
    }

    //修订-----优化为：可以同时选择；
    // //当业务类型为XX/XX/“拼柜/LCL”或者“XX/空运”时，送货方式“快递”、“干线物流”仅能选择其一，选择一个则另一个取消勾选；
    // if (
    //   (this.form.Data.TransportType == EnumTransportType.Air ||
    //     this.form.Data.TransportContainerLoad ==
    //       EnumTransportContainerLoad.LCL) &&
    //   this.form.Data.DestinationPortExpress
    // ) {
    //   this.form.Data.DestinationPortTrunk = false;
    // }
  }

  onDestinationPortWarehouseChanged(n?: string, o?: string): void {
    if (n == o) return;

    // //当业务类型不是XX/XX/“散货/BULK”时 如取消勾选“仓库/堆场/码头”，则同时取消勾选“干线物流”、“快递”；
    // if (
    //   this.form.Data.TransportContainerLoad !=
    //     EnumTransportContainerLoad.Bulk &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   if (this.form.Data.DestinationPortDelivery) {
    //     this.form.Data.DestinationPortTrunk = false;
    //     this.form.Data.DestinationPortExpress = false;
    //   } else {
    //     this.form.Data.DestinationPortTrunk = false;
    //     this.form.Data.DestinationPortExpress = false;
    //   }
    // }

    //当业务类型不是XX/XX/“散货/BULK”时，并且业务类型不是XX/XX/“整柜/FCL”时
    //勾选“干线物流”、“快递”任一，则“集装箱拖车”置灰
    //勾选“仓库/堆场/码头”，则“集装箱拖车”置灰；
    if (
      this.form.Data.TransportContainerLoad != EnumTransportContainerLoad.FCL &&
      this.form.Data.TransportContainerLoad !=
        EnumTransportContainerLoad.Bulk &&
      (this.form.Data.DestinationPortTrunk ||
        this.form.Data.DestinationPortExpress ||
        this.form.Data.DestinationPortWarehouse)
    ) {
      if (this.form.Data.DestinationPortDelivery) {
        this.form.Data.DestinationPortContainer = false;
      } else {
        this.form.Data.DestinationPortContainer = undefined;
      }
    }

    //当业务类型是XX/XX/“散货/BULK”时：
    if (
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.Bulk
    ) {
      //如取消勾选“仓库/堆场/码头”，则同时取消勾选“快递”；
      if (
        !this.form.Data.DestinationPortWarehouse &&
        this.form.Data.DestinationPortExpress
      ) {
        this.form.Data.DestinationPortExpress = false;
      }
    }

    //当业务类型是XX/XX/拼柜/LCL，或者XX/空运/By Air时：
    if (
      this.form.Data.TransportType == EnumTransportType.Air ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL
    ) {
      // //取消勾选目的港-仓库，则取消勾选装货港订舱；
      // if (this.form.Data.DestinationPortWarehouse != true) {
      //   this.form.Data.DeparturePortBooking = false;
      // }
    }
    // //业务类型为XX/XX/拼箱时，如勾选了装货港订舱，未勾选目的港仓库，则默认勾选目的港送货，以及送货方式勾选集装箱拖车；
    // if (
    //   this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
    //   this.form.Data.DeparturePortBooking &&
    //   !this.form.Data.DestinationPortWarehouse
    // ) {
    //   this.form.Data.DestinationPortDelivery = true;
    //   this.form.Data.DestinationPortContainer = true;
    // }
  }

  hnadleDrop(e: any): void {
    if (e.oldIndex != e.newIndex && this.services && this.services.length > 1) {
      const draggingIndex = e.newIndex;
      let dropIndex;
      let type: EnumUiDragType;
      if (draggingIndex == 0) {
        type = EnumUiDragType.Before;
        dropIndex = 1;
      } else {
        type = EnumUiDragType.After;
        dropIndex = draggingIndex - 1;
      }
      const dragging = this.services[draggingIndex];
      const drop = this.services[dropIndex];
      PriceServiceShortcutAppService.DragSort({
        DraggingId: dragging.Value,
        DropId: drop.Value,
        DragType: type,
      }).then(() => {
        this.handleGetServices();
      });
    }
  }

  @Watch("form.Data", {
    deep: true,
  })
  onDataChanged(): void {
    if (this.form.Data.PriceServiceShortcutId) {
      const service = this.services.firstOrDefault(
        (it) => it.Value == this.form.Data.PriceServiceShortcutId
      )?.Data;
      if (service) {
        const numberEquals = [
          "TransportDirection",
          "TransportType",
          "TransportContainerLoad",
          "DestinationPortCustomsClearanceHeaderProvider",
        ];
        const boolEquals = [
          "DeparturePortBooking",
          "DeparturePortLadingBill",
          "DeparturePortTake",
          "DeparturePortContainer",
          "DeparturePortTrunk",
          "DeparturePortExpress",
          "DeparturePortWarehouse",
          "DeparturePortDeclareCustoms",
          "DeparturePortDrawback",
          "DestinationPortClearCustoms",
          "DestinationPortCustomsClearanceIssueTaxReceipt",
          "DestinationPortDelivery",
          "DestinationPortContainer",
          "DestinationPortTrunk",
          "DestinationPortExpress",
          "DestinationPortWarehouse",
        ];
        const checks: string[] = [];
        for (const numberEqual of numberEquals) {
          const a = this.form.Data[numberEqual] ?? false;
          const b = service[numberEqual] ?? false;
          if (a != b) {
            checks.push(`字段：${numberEqual}由${a}改为${b}`);
          }
        }
        for (const boolEqual of boolEquals) {
          const a = this.form.Data[boolEqual] ?? false;
          const b = service[boolEqual] ?? false;
          if (a != b) {
            checks.push(`字段：${boolEqual}由${a}改为${b}`);
          }
        }
        if (checks.length > 0) {
          console.log(
            `快捷方式${this.form.Data.PriceServiceShortcutLabel}取消`,
            this.form.Data,
            service
          );
          for (const check of checks) {
            console.log(check);
          }
          this.form.Data.PriceServiceId = undefined;
          this.form.Data.PriceServiceLabel = undefined;
          this.form.Data.PriceServiceShortcutId = undefined;
          this.form.Data.PriceServiceShortcutLabel = undefined;
          if (!this.id) {
            this.form.Data.ServiceRemark = undefined;
          }
        }
      } else {
        this.form.Data.PriceServiceId = undefined;
        this.form.Data.PriceServiceLabel = undefined;
        this.form.Data.PriceServiceShortcutId = undefined;
        this.form.Data.PriceServiceShortcutLabel = undefined;
        if (!this.id) {
          this.form.Data.ServiceRemark = undefined;
        }
      }
    }
  }

  handleServiceClick(e: any, item: UiSelectOption<string>): void {
    if (e.target.nodeName == "DIV" || e.target.nodeName == "SPAN") {
      this.clearWatch();
      this.form.Data.PriceServiceId = item.Data.ServiceId;
      this.form.Data.PriceServiceLabel = item.Label;
      this.form.Data.PriceServiceShortcutId = item.Value;
      this.form.Data.PriceServiceShortcutLabel = item.Label;
      this.form.Data.ServiceRemark = item.Label;

      this.form.Data.TransportDirection = item.Data.TransportDirection;
      this.form.Data.TransportType = item.Data.TransportType;
      this.form.Data.TransportContainerLoad = item.Data.TransportContainerLoad;
      this.form.Data.DeparturePortBooking =
        item.Data.DeparturePortBooking ?? false;
      this.form.Data.DeparturePortLadingBill =
        item.Data.DeparturePortLadingBill;
      this.form.Data.DeparturePortTake = item.Data.DeparturePortTake ?? false;
      this.form.Data.DeparturePortContainer = item.Data.DeparturePortContainer;
      this.form.Data.DeparturePortTrunk = item.Data.DeparturePortTrunk;
      this.form.Data.DeparturePortExpress = item.Data.DeparturePortExpress;
      this.form.Data.DeparturePortWarehouse =
        item.Data.DeparturePortWarehouse ?? false;
      this.form.Data.DeparturePortDeclareCustoms =
        item.Data.DeparturePortDeclareCustoms ?? false;
      this.form.Data.DeparturePortDrawback = item.Data.DeparturePortDrawback;
      this.form.Data.DestinationPortClearCustoms =
        item.Data.DestinationPortClearCustoms ?? false;
      this.form.Data.DestinationPortCustomsClearanceHeaderProvider =
        item.Data.DestinationPortCustomsClearanceHeaderProvider;
      this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt =
        item.Data.DestinationPortCustomsClearanceIssueTaxReceipt;
      this.form.Data.DestinationPortDelivery =
        item.Data.DestinationPortDelivery ?? false;
      this.form.Data.DestinationPortContainer =
        item.Data.DestinationPortContainer;
      this.form.Data.DestinationPortTrunk = item.Data.DestinationPortTrunk;
      this.form.Data.DestinationPortExpress = item.Data.DestinationPortExpress;
      this.form.Data.DestinationPortWarehouse =
        item.Data.DestinationPortWarehouse ?? false;
      this.initWatch();
    }
  }

  /**行按钮点击 */
  @Emit()
  handleRowButtonClick(btn: MiezzButton, row: any, complete: () => void): void {
    switch (btn.Code) {
      case "show-rename-shortcut":
        this.formShortcut.Data = new DtoFormPriceServiceShortcutName();
        this.formShortcut.Data.Id = row.Value;
        this.formShortcut.Data.Name = row.Label;
        this.modalShortcut.Show = true;
        complete();
        break;
      case "delete-shortcut":
        PriceServiceShortcutAppService.Delete([row.Value])
          .then(() => {
            this.handleGetServices();
            this.modalShortcut.Show = false;
            complete();
          })
          .catch(complete);
        break;

      default:
        break;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    this.form.Data.OrderWarehouseId = this.orderWarehouseId;
    if (
      (btn.Code == "edit" || btn.Code == "show-save-shortcut") &&
      this.form.Data.TransportDirection != EnumTransportDirection.Other &&
      !(
        this.form.Data.DeparturePortBooking ||
        this.form.Data.DeparturePortTake ||
        this.form.Data.DeparturePortWarehouse ||
        this.form.Data.DeparturePortDeclareCustoms ||
        this.form.Data.DestinationPortClearCustoms ||
        this.form.Data.DestinationPortDelivery ||
        this.form.Data.DestinationPortWarehouse
      )
    ) {
      ElMessage.warning("请至少选择一个服务");
      complete();
      return;
    }
    switch (btn.Code) {
      case "show-save-shortcut":
        (this.$refs["ruleForm"] as any)
          ?.form()
          ?.validateField(
            ["TransportDirection", "TransportType", "TransportContainerLoad"],
            (v: boolean) => {
              if (v) {
                const data = new DtoFormPriceServiceShortcut();
                data.TransportDirection = this.form.Data.TransportDirection;
                data.TransportType = this.form.Data.TransportType;
                data.TransportContainerLoad =
                  this.form.Data.TransportContainerLoad;
                data.DeparturePortBooking = this.form.Data.DeparturePortBooking;
                data.DeparturePortLadingBill =
                  this.form.Data.DeparturePortLadingBill;
                data.DeparturePortTake = this.form.Data.DeparturePortTake;
                data.DeparturePortContainer =
                  this.form.Data.DeparturePortContainer;
                data.DeparturePortTrunk = this.form.Data.DeparturePortTrunk;
                data.DeparturePortExpress = this.form.Data.DeparturePortExpress;
                data.DeparturePortWarehouse =
                  this.form.Data.DeparturePortWarehouse;
                data.DeparturePortDeclareCustoms =
                  this.form.Data.DeparturePortDeclareCustoms;
                data.DeparturePortDrawback =
                  this.form.Data.DeparturePortDrawback;
                data.DestinationPortClearCustoms =
                  this.form.Data.DestinationPortClearCustoms;
                data.DestinationPortCustomsClearanceHeaderProvider =
                  this.form.Data.DestinationPortCustomsClearanceHeaderProvider;
                data.DestinationPortCustomsClearanceIssueTaxReceipt =
                  this.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt;
                data.DestinationPortDelivery =
                  this.form.Data.DestinationPortDelivery;
                data.DestinationPortContainer =
                  this.form.Data.DestinationPortContainer;
                data.DestinationPortTrunk = this.form.Data.DestinationPortTrunk;
                data.DestinationPortExpress =
                  this.form.Data.DestinationPortExpress;
                data.DestinationPortWarehouse =
                  this.form.Data.DestinationPortWarehouse;
                this.formShortcut.Data = data;
                this.modalShortcut.Show = true;
                complete();
              } else {
                complete();
              }
            }
          );
        break;
      case "save-shortcut":
        MiezzForm.submit(this.$refs, "form-shortcut", (v) => {
          if (v) {
            if (this.formShortcut.Data.Id) {
              PriceServiceShortcutAppService.PostName(this.formShortcut.Data)
                .then(() => {
                  this.handleGetServices();
                  this.modalShortcut.Show = false;
                  complete();
                })
                .catch(complete);
            } else {
              PriceServiceShortcutAppService.Post(this.formShortcut.Data)
                .then((r) => {
                  this.form.Data.PriceServiceId = undefined;
                  this.form.Data.PriceServiceLabel = undefined;
                  this.form.Data.PriceServiceShortcutId = r.data.Data;
                  this.form.Data.PriceServiceShortcutLabel =
                    this.formShortcut.Data.Name;
                  this.form.Data.ServiceRemark = this.formShortcut.Data.Name;
                  this.handleGetServices();
                  this.modalShortcut.Show = false;
                  complete();
                })
                .catch(complete);
            }
          } else {
            complete();
          }
        });
        break;
      case "edit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            this.loading = true;
            this.form.Data.ClientCompanyNameZh = this.companies.firstOrDefault(
              (it) => it.Id == this.form.Data.ClientCompanyId
            )?.NameZh;

            this.form.Data.IsStock = false;
            if (this.stock == true) {
              this.form.Data.Id = undefined;
              this.form.Data.OrderWarehouseId = undefined;
              this.form.Data.Wharfs = [];
              this.form.Data.IsStock = true;
            }

            OrderPoolAppService.PostChooseService(this.form.Data)
              .then((r) => {
                this.loading = false;
                this.handleSubmit(r.data.Data);
                this.modelValue.Show = false;
                complete();
              })
              .catch(() => {
                this.loading = false;
                complete();
              });
          } else {
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  @Emit()
  handleSubmit(id?: string): void {
    //
  }
}
