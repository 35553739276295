
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailJoinCompanyUser, {
  DtoDetailJoinCompanyUserMiezzDetailItems,
} from "@/models.machine/DtoDetailJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserDetail from "../UserDetail.vue";
@Options({
  components: {
    UserDetail,
  },
})
export default class CustomerUserDetail extends Vue {
  type = EnumCompanyType.Customer;
  detail = new MiezzForm<DtoDetailJoinCompanyUser, string>(
    "CustomerManagement_User_Detail"
  );
  buttons: MiezzButton[] = [];
  logier?: Logier;

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/customer/user/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: `/customer/user/detail/${this.$route.params.id}`,
        Label: "用户信息",
        Power: "CustomerManagement_User_Detail",
      },
    ];

    this.logier = CurrentLogier.GetLogier();
    this.detail.DefaultSpan = 12;
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.detail.Items =
        DtoDetailJoinCompanyUserMiezzDetailItems.copy().filter(
          (it) =>
            it.Prop != "JoinCompanyUserGroups" &&
            it.Prop != "Job" &&
            it.Prop != "Level" &&
            it.Prop != "Status" &&
            it.Prop != "UserStatus" &&
            it.Prop != "Avatar" &&
            it.Prop != "JoinCompanyUserContacts"
        );
    } else {
      this.detail.Items = DtoDetailJoinCompanyUserMiezzDetailItems;
    }
    if (this.logier?.CompanyType == EnumCompanyType.Platform) {
      const company = this.detail.Items.firstOrDefault(
        (it) => it.Prop == "Company"
      );
      if (company) {
        company.HandleFormat = () => {
          return `${
            this.detail.Data.Company?.ServiceProviderCompanyNameZh ?? "暂无数据"
          }/${this.detail.Data.Company?.NameZh ?? "暂无数据"}`;
        };
      }
    }
  }

  @Watch("detail.Data")
  onDetailDataChanged(): void {
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.detail.Data.UiGroups?.remove((it) => it.Code == "ContactNumber");
    }
    if (this.detail.Data.Company) {
      if (
        this.logier?.CompanyType == EnumCompanyType.ServiceProvider &&
        this.type == EnumCompanyType.Customer &&
        !this.detail.Data.Company.Trusteeship
      ) {
        this.buttons = [];
      } else {
        this.buttons = [
          {
            Code: "back",
            Label: "返回",
            MiezzIcon: "back",
            Type: "text",
            Size: "small",
          },
          {
            Code: "edit",
            Label: "编辑",
            Icon: "edit",
            Type: "text",
            Size: "small",
            PowerCode: "CustomerManagement_User_Edit",
          },
        ];
      }
    }
  }
}
