import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 组表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormGroup extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.JoinCompanyUserIds = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 用户
     */
    JoinCompanyUserIds?: string[];
}

/**
 * 组表单-表单校验
 */
export const DtoFormGroupRules = {
    Path: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 组表单-设置项
 */
export const DtoFormGroupMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "JoinCompanyUserIds",
    Label: "用户",
    Customize: true,
  },
]
