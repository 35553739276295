import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPlanBulletinBoard extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.ClientCompanies = [];
    }

    [proName: string]: any;
    /**
     * 服务
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 计划编号
     */
    Code?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 计划类型
     */
    Type?: string;
    /**
     * 港口
     */
    Port?: string;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 委托人
     */
    ClientCompanies?: string[];
    /**
     * 计划执行时间
     */
    PlanTime?: Date;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 描述
     */
    Description?: string;
    /**
     * 集装箱箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 提货地址Id
     */
    CarryAddressId?: string;
    /**
     * 合计件数
     */
    TotalQuantity?: number;
    /**
     * 合计件数-英文计价单位
     */
    TotalQuantityChargeUnitEn?: string;
    /**
     * 收货截止时间
     */
    ReceivingDeadline?: Date;
    /**
     * 链接地址
     */
    Link?: string;
    /**
     * 行样式
     */
    Class?: string;
    /**
     * 待办事件
     */
    Task?: string;
}

/**
 * -表单校验
 */
export const DtoListItemOrderPlanBulletinBoardRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPlanBulletinBoardMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "计划编号",
    MaxWidth: "150px",
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
    MaxWidth: "150px",
  },
  {
    Prop: "Type",
    Label: "计划类型",
    MaxWidth: "130px",
  },
  {
    Prop: "Port",
    Label: "港口",
    MaxWidth: "150px",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    MaxWidth: "200px",
    Customize: true,
  },
  {
    Prop: "PlanTime",
    Label: "计划执行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
    MaxWidth: "200px",
  },
  {
    Prop: "Description",
    Label: "描述",
  },
  {
    Prop: "Task",
    Label: "待办事件",
  },
]
