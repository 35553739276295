import DtoDetailOrderProcess_Tab from "./DtoDetailOrderProcess_Tab";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderProcess_OrderPoolCustoms extends DtoDetailOrderProcess_Tab {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 服务
     */
    Service?: EnumOrderServiceType;
    /**
     * 海关放行时间
     */
    ReleaseTime?: Date;
}

/**
 * -表单校验
 */
export const DtoDetailOrderProcess_OrderPoolCustomsRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderProcess_OrderPoolCustomsMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ReleaseTime",
    Label: "海关放行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
