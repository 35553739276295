import { RouteRecordRaw } from 'vue-router'

import DirectOrderPageList from "./DirectOrderPageList.vue"
import OrderPageList from "./OrderPageList.vue"

import OrderSummary from "./OrderSummary.vue"
import OrderFile from "./OrderFile.vue"

import OrderBookingDetail from "./Booking/OrderBookingDetail.vue"
import OrderBookingForm from "./Booking/OrderBookingForm.vue"
import OrderMainBillForm from "./MainBill/OrderMainBillForm.vue"
import OrderPartBillForm from "./PartBill/OrderPartBillForm.vue"
import OrderManifestVgmForm from "./ManifestVgm/OrderManifestVgmForm.vue"
import OrderBillPrint from "./PrintBill/OrderBillPrint.vue"

import DeparturePortContainerTrailerPageList from "./ContainerTrailer/DeparturePortContainerTrailerPageList.vue"
import DestinationPortContainerTrailerPageList from "./ContainerTrailer/DestinationPortContainerTrailerPageList.vue"

import DeparturePortTrunkPageList from "./Trunk/DeparturePortTrunkPageList.vue"
import DeparturePortTrunkCargoPageList from "./Trunk/DeparturePortTrunkCargoPageList.vue"
import DestinationPortTrunkPageList from "./Trunk/DestinationPortTrunkPageList.vue"
import DestinationPortTrunkCargoPageList from "./Trunk/DestinationPortTrunkCargoPageList.vue"

import DeparturePortExpressPageList from "./ExpressPage/DeparturePortExpressPageList.vue"
import DeparturePortExpressCargoPageList from "./ExpressPage/DeparturePortExpressCargoPageList.vue"
import DeparturePortWharfCargoPageList from "./DeparturePortWharfCargoPageList.vue"
import DestinationPortExpressPageList from "./ExpressPage/DestinationPortExpressPageList.vue"
import DestinationPortExpressCargoPageList from "./ExpressPage/DestinationPortExpressCargoPageList.vue"
import DestinationPortWharfCargoPageList from "./DestinationPortWharfCargoPageList.vue"


const routes: Array<RouteRecordRaw> = [
    {
        path: '/order',
        name: 'OrderManagement_Order',
        component: OrderPageList,
        meta: {
            title: "订单管理 - 订单列表"
        },
    },
    {
        path: '/order/summary/:id',
        name: 'OrderManagement_Order_Summary',
        component: OrderSummary,
        meta: {
            title: "订单管理 - 订单摘要"
        },
    },
    {
        path: '/order/file/:id',
        name: 'OrderManagement_Order_Summary_File',
        component: OrderFile,
        meta: {
            title: "订单管理 - 订单摘要 - 附件"
        },
    },

    {
        path: '/order/booking/:id',
        name: 'OrderManagement_Order_Summary_Booking',
        component: OrderBookingDetail,
        meta: {
            title: "订单管理 - 舱务信息"
        },
    },
    {
        path: '/order/booking/edit/:id',
        name: 'OrderManagement_Order_Summary_Booking_Edit',
        component: OrderBookingForm,
        meta: {
            title: "订单管理 - 订舱信息-编辑"
        },
    },
    {
        path: '/order/main-bill/edit/:id',
        name: 'OrderManagement_Order_Summary_Booking_MainBill_Edit',
        component: OrderMainBillForm,
        meta: {
            title: "订单管理 - 主单信息-编辑"
        },
    },
    {
        path: '/order/part-bill/edit/:id',
        name: 'OrderManagement_Order_Summary_Booking_PartBill_Edit',
        component: OrderPartBillForm,
        meta: {
            title: "订单管理 - 分单信息-编辑"
        },
    },
    {
        path: '/order/manifest-vgm/edit/:id',
        name: 'OrderManagement_Order_Summary_Booking_ManifestVgm_Edit',
        component: OrderManifestVgmForm,
        meta: {
            title: "订单管理 - 舱单&VGM-编辑"
        },
    },
    {
        path: '/order/bill/print/:id',
        name: 'OrderManagement_Order_Summary_Booking_Bill_Print',
        component: OrderBillPrint,
        meta: {
            title: "订单管理 - 打印提单"
        },
    },
    {
        path: '/order/departure-port-container/:id',
        name: 'OrderManagement_Order_Summary_DeparturePortContainer',
        component: DeparturePortContainerTrailerPageList,
        meta: {
            title: "订单管理 - 装货港-集装箱拖车"
        },
    },
    {
        path: '/order/destination-port-container/:id',
        name: 'OrderManagement_Order_Summary_DestinationPortContainer',
        component: DestinationPortContainerTrailerPageList,
        meta: {
            title: "订单管理 - 目的港-集装箱拖车"
        },
    },

    {
        path: '/order/departure-port-trunk/:id',
        name: 'OrderManagement_Order_Summary_DeparturePortTrunk',
        component: DeparturePortTrunkPageList,
        meta: {
            title: "订单管理 - 装货港-干线物流"
        },
    },
    {
        path: '/order/departure-port-trunk/:id/cargos/:trunkId',
        name: 'OrderManagement_Order_Summary_DeparturePortTrunk_Cargos',
        component: DeparturePortTrunkCargoPageList,
        meta: {
            title: "订单管理 - 装货港-干线物流 - 货物清单"
        },
    },
    {
        path: '/order/destination-port-trunk/:id',
        name: 'OrderManagement_Order_Summary_DestinationPortTrunk',
        component: DestinationPortTrunkPageList,
        meta: {
            title: "订单管理 - 目的港-干线物流"
        },
    },
    {
        path: '/order/destination-port-trunk/:id/cargos/:trunkId',
        name: 'OrderManagement_Order_Summary_DestinationPortTrunk_Cargos',
        component: DestinationPortTrunkCargoPageList,
        meta: {
            title: "订单管理 - 目的港-干线物流 - 货物清单"
        },
    },

    {
        path: '/order/departure-port-express/:id',
        name: 'OrderManagement_Order_Summary_DeparturePortExpress',
        component: DeparturePortExpressPageList,
        meta: {
            title: "订单管理 - 装货港-快递"
        },
    },
    {
        path: '/order/departure-port-express/:id/cargos/:expressId',
        name: 'OrderManagement_Order_Summary_DeparturePortExpress_Cargos',
        component: DeparturePortExpressCargoPageList,
        meta: {
            title: "订单管理 - 装货港-快递 - 货物清单"
        },
    },
    {
        path: '/order/departure-port-wharf/:id/cargos/:wharfId',
        name: 'OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods',
        component: DeparturePortWharfCargoPageList,
        meta: {
            title: "订单管理 - 装货港-仓库/堆场/码头 - 货物清单"
        },
    },
    {
        path: '/order/destination-port-express/:id',
        name: 'OrderManagement_Order_Summary_DestinationPortExpress',
        component: DestinationPortExpressPageList,
        meta: {
            title: "订单管理 - 目的港-快递"
        },
    },
    {
        path: '/order/destination-port-express/:id/cargos/:expressId',
        name: 'OrderManagement_Order_Summary_DestinationPortExpress_Cargos',
        component: DestinationPortExpressCargoPageList,
        meta: {
            title: "订单管理 - 目的港-快递 - 货物清单"
        },
    },
    {
        path: '/order/destination-port-wharf/:id/cargos/:wharfId',
        name: 'OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf_ListOfGoods',
        component: DestinationPortWharfCargoPageList,
        meta: {
            title: "订单管理 - 目的港-仓库/码头/堆场 - 货物清单"
        },
    },
]

export default routes;