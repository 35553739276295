import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 公司发票表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyInvoice extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Default = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 公司名称
     */
    Name?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 公司注册地址
     */
    RegisteredAddress?: string;
    /**
     * 电话号码
     */
    Tel?: string;
    /**
     * 开户银行
     */
    Bank?: string;
    /**
     * 银行账号
     */
    BankAccount?: string;
    /**
     * 寄送地址
     */
    SendAddress?: string;
    /**
     * 联系人
     */
    Sender?: string;
    /**
     * 默认
     */
    Default?: boolean;
}

/**
 * 公司发票表单-表单校验
 */
export const DtoFormCompanyInvoiceRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    RegisteredAddress: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Tel: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Bank: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    BankAccount: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SendAddress: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Sender: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 公司发票表单-设置项
 */
export const DtoFormCompanyInvoiceMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "公司名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "RegisteredAddress",
    Label: "公司注册地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Tel",
    Label: "电话号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Bank",
    Label: "开户银行",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "BankAccount",
    Label: "银行账号",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SendAddress",
    Label: "寄送地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Sender",
    Label: "联系人",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Default",
    Label: "默认",
    Type: EnumMiezzFormItemType.Switch,
  },
]
