import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createBlock(_component_miezz_card, {
    title: "编号规则",
    fix: true,
    bottomButtons: _ctx.buttons,
    onHandleFooterButtonClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'NameEnAbbr')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data.NameEnAbbr,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.NameEnAbbr) = $event)),
                    pattern: "[a-z]",
                    upperCase: true
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'CodeRules')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_page_list, {
                    modelValue: _ctx.list,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event))
                  }, {
                    column: _withCtx(({ model, row, index }) => [
                      (index == -1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                        : (model.Prop == 'Code')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(_component_el_form_item, {
                                prop: `CodeRules[${index}].Code`,
                                rules: _ctx.rules.Code,
                                class: "code"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_miezz_input, {
                                    modelValue: row.Code,
                                    "onUpdate:modelValue": ($event: any) => ((row.Code) = $event),
                                    pattern: "[a-z]",
                                    upperCase: true
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1032, ["prop", "rules"])
                            ]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'Remark')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Booking No.的编号规则为公司名称英文缩写+年份数字（2位）+月份（2位）+4位顺序数字，订单编号的规则为公司名称英文缩写+业务类型英文缩写+年份数字（2位）+月份（2位）+4位顺序数字；取消的委托书与订单的编号依然保留 ")
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["bottomButtons", "onHandleFooterButtonClick"]))
}