
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchOrderPoolContainerTrailer from "@/models.machine/DtoPageListSearchOrderPoolContainerTrailer";
import DtoListItemOrderPoolContainerTrailer from "@/models.machine/DtoListItemOrderPoolContainerTrailer";
import ContainerTrailerPageList from "./ContainerTrailerPageList.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
@Options({
  components: {
    ContainerTrailerPageList,
  },
})
export default class DestinationPortContainerTrailerPageList extends Vue {
  menuTab = new MiezzMenuTab();
  list = new MiezzPageList<
    DtoPageListSearchOrderPoolContainerTrailer,
    DtoListItemOrderPoolContainerTrailer,
    string
  >("OrderManagement_Pool_Summary_DestinationPortContainer");
  powers: string[] = [];
  serviceType = EnumOrderServiceType.DestinationPort;

  created(): void {
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/destination-port-container/${this.$route.params.id}`;
    this.menuTab.Menus = [
      {
        Label: "目的港-集装箱拖车",
        Url: `/order/pool/destination-port-container/${this.$route.params.id}`,
        Power: "OrderManagement_Pool_Summary_DestinationPortContainer",
      },
    ];
  }
}
