
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class UiFormWithStringId {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * Id
     */
    Id?: string;
}

/**
 * -表单校验
 */
export const UiFormWithStringIdRules = {
}
