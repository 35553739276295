import DtoFromInvoiceItem from "./DtoFromInvoiceItem";
import DtoRequestInvoiceItem from "./DtoRequestInvoiceItem";
import { EnumInvoiceStatus, EnumInvoiceStatusOption } from "./EnumInvoiceStatus";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestInvoice {
    constructor() {
        //无参构造函数
        this.Visiable = false;
        this.Bills = [];
        this.Invoices = [];
        this.IsSame = false;
    }

    [proName: string]: any;
    /**
     * 账单主键
     */
    BillIds?: string[];
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 发票明细
     */
    InvoiceItems?: DtoFromInvoiceItem[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 收款方抬头
     */
    CollectionTitle?: string;
    /**
     * 付款方抬头
     */
    PaymentTitle?: string;
    /**
     * 金额
     */
    Price?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 账单合计
     */
    Bills?: DtoRequestInvoiceItem[];
    /**
     * 账单合计
     */
    BillString?: string;
    /**
     * 发票合计
     */
    Invoices?: DtoRequestInvoiceItem[];
    /**
     * 发票合计
     */
    InvoiceString?: string;
    /**
     * 发票兑换合计
     */
    SettlementString?: string;
    /**
     * 账单金额和发票金额是否一致
     */
    IsSame?: boolean;
    /**
     * 
     */
    Status?: EnumInvoiceStatus;
    /**
     * 当前时间
     */
    CurrentTime?: Date;
}

/**
 * -表单校验
 */
export const DtoRequestInvoiceRules = {
}
