
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ExceptionLogAppService from "@/apis.machine/ExceptionLogAppService";
import DtoDetailExceptionLog, {
  DtoDetailExceptionLogMiezzDetailItems,
} from "@/models.machine/DtoDetailExceptionLog";
import DtoDetailExceptionLog_StackTrace from "@/models.machine/DtoDetailExceptionLog_StackTrace";
import ExceptionLogDetailStackTrace from "./ExceptionLogDetailStackTrace.vue";

@Options({
  components: {
    ExceptionLogDetailStackTrace,
  },
})
export default class ExceptionLogDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() siteId?: string;

  detail = new MiezzForm<DtoDetailExceptionLog, string>();
  modalStackTrace = new MiezzModal();
  stackTrace?: DtoDetailExceptionLog_StackTrace;

  /**创建时 */
  created(): void {
    this.modelValue.Width = "80%";

    //详细-数据-初始化
    this.detail.Data = new DtoDetailExceptionLog();
    //详细-元素配置
    this.detail.Items = DtoDetailExceptionLogMiezzDetailItems;

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      ExceptionLogAppService.GetById(this.id, this.siteId).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }

  handleDetailStackTrace(index?: number): void {
    if (index != undefined) {
      this.stackTrace = this.detail.Data.StackTraceItems![index];
      this.modalStackTrace.Show = true;
    }
  }
}
