import DtoDetailOrderProcess_Tab from "./DtoDetailOrderProcess_Tab";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderProcess_DeparturePortBooking extends DtoDetailOrderProcess_Tab {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 装货港ATD
     */
    ActualDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 目的港ATA
     */
    ActualArrivalDate?: Date;
}

/**
 * -表单校验
 */
export const DtoDetailOrderProcess_DeparturePortBookingRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderProcess_DeparturePortBookingMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualDepartureDate",
    Label: "装货港ATD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualArrivalDate",
    Label: "目的港ATA",
    Pipe: EnumPipe.Date,
  },
]
