import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";
import UiGroup from "./UiGroup";

/**
 * 计费重量表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyCharge extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Sort = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyChargeType;
    /**
     * 材积比
     */
    VolumeRatio?: number;
    /**
     * 单件最低标准
     */
    MinStandardPiece?: number;
    /**
     * 单票最低标准
     */
    MinStandardTicket?: number;
    /**
     * 所属公司主键
     */
    FromId?: string;
    /**
     * 要计费的公司主键
     */
    ToId?: string;
    /**
     * 是否默认
     */
    IsDefault?: boolean;
    /**
     * 排序
     */
    Sort?: number;
}

/**
 * 计费重量表单-表单校验
 */
export const DtoFormCompanyChargeRules = {
}

/**
 * 计费重量表单-设置项
 */
export const DtoFormCompanyChargeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Customize: true,
  },
  {
    Prop: "VolumeRatio",
    Label: "材积比",
    Customize: true,
  },
  {
    Prop: "MinStandardPiece",
    Label: "单件最低标准",
    Customize: true,
  },
  {
    Prop: "MinStandardTicket",
    Label: "单票最低标准",
    Customize: true,
  },
]
