import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolTrunk from "../models.machine/DtoPageListSearchOrderPoolTrunk";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolTrunk from "../models.machine/DtoPageListOrderPoolTrunk";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "../models.machine/EnumOrderServiceType";
import DtoPutOrderPoolTrunk_PlanCarryContainerTime from "../models.machine/DtoPutOrderPoolTrunk_PlanCarryContainerTime";
import DtoPutOrderPoolTrunk_CarryContainerTime from "../models.machine/DtoPutOrderPoolTrunk_CarryContainerTime";
import DtoPutOrderPoolTrunk_CarryAddress from "../models.machine/DtoPutOrderPoolTrunk_CarryAddress";
import DtoPutOrderPoolTrunk_CargoQuantities from "../models.machine/DtoPutOrderPoolTrunk_CargoQuantities";
import DtoPutOrderPoolTrunk_AllCarryTime from "../models.machine/DtoPutOrderPoolTrunk_AllCarryTime";
import DtoPutOrderPoolTrunk_Supplier from "../models.machine/DtoPutOrderPoolTrunk_Supplier";
import DtoPutOrderPoolTrunk_Executor from "../models.machine/DtoPutOrderPoolTrunk_Executor";
import DtoPutOrderPoolTrunk_DeliveryAddress from "../models.machine/DtoPutOrderPoolTrunk_DeliveryAddress";
import DtoPutOrderPoolTrunk_AllDeliveryTime from "../models.machine/DtoPutOrderPoolTrunk_AllDeliveryTime";
import DtoPutOrderPoolTrunk_File from "../models.machine/DtoPutOrderPoolTrunk_File";
import DtoHistory from "../models.machine/DtoHistory";
import DtoRequestOrderPoolTrunkChart from "../models.machine/DtoRequestOrderPoolTrunkChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";
import UiSelectOption from "../models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 委托书-干线物流
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolTrunkAppService {
    /**
     * 获取委托书-干线物流分页列表
     * @param {DtoPageListSearchOrderPoolTrunk} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolTrunk>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolTrunk): AxiosPromise<RESTfulResult<DtoPageListOrderPoolTrunk>> {
        const url = `/api/OrderPoolTrunk/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolTrunk>>(url, { params: dto });
    }

    /**
     * 添加干线物流
     * @param {string} [poolId] 委托书Id
     * @param {EnumOrderServiceType} [serviceType] 服务类型
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostByPoolIdByServiceType(poolId?: string, serviceType?: EnumOrderServiceType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/Post/${poolId}/${serviceType}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 编辑计划提柜时间
     * @param {DtoPutOrderPoolTrunk_PlanCarryContainerTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutPlanCarryContainerTime(dto?: DtoPutOrderPoolTrunk_PlanCarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutPlanCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提柜时间
     * @param {DtoPutOrderPoolTrunk_CarryContainerTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryContainerTime(dto?: DtoPutOrderPoolTrunk_CarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提货地址与时间
     * @param {DtoPutOrderPoolTrunk_CarryAddress} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryAddress(dto?: DtoPutOrderPoolTrunk_CarryAddress): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutCarryAddress`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑货量
     * @param {DtoPutOrderPoolTrunk_CargoQuantities} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCargoQuantities(dto?: DtoPutOrderPoolTrunk_CargoQuantities): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutCargoQuantities`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑实际提取时间
     * @param {DtoPutOrderPoolTrunk_AllCarryTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutAllCarryTime(dto?: DtoPutOrderPoolTrunk_AllCarryTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutAllCarryTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑供应商
     * @param {DtoPutOrderPoolTrunk_Supplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutSupplier(dto?: DtoPutOrderPoolTrunk_Supplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutSupplier`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑执行人
     * @param {DtoPutOrderPoolTrunk_Executor} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutExecutor(dto?: DtoPutOrderPoolTrunk_Executor): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutExecutor`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑交货地址
     * @param {DtoPutOrderPoolTrunk_DeliveryAddress} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutDeliveryAddress(dto?: DtoPutOrderPoolTrunk_DeliveryAddress): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutDeliveryAddress`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑实际送达时间
     * @param {DtoPutOrderPoolTrunk_AllDeliveryTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutAllDeliveryTime(dto?: DtoPutOrderPoolTrunk_AllDeliveryTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutAllDeliveryTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑附件
     * @param {DtoPutOrderPoolTrunk_File} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutFile(dto?: DtoPutOrderPoolTrunk_File): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/PutFile`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取历史记录
     * @param {DtoPageListSearchOrderPoolTrunk} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistories(dto?: DtoPageListSearchOrderPoolTrunk): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/OrderPoolTrunk/GetHistories`;
        return service.get<RESTfulResult<DtoHistory[]>>(url, { params: dto });
    }

    /**
     * 批量删除委托书-干线物流
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolTrunk/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderPoolTrunkChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderPoolTrunkChart): AxiosPromise<RESTfulResult<UiEChartsBar>> {
        const url = `/api/OrderPoolTrunk/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderPoolTrunkChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderPoolTrunkChart): AxiosPromise<RESTfulResult<UiEChartsPie>> {
        const url = `/api/OrderPoolTrunk/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie>>(url, { params: dto });
    }

    /**
     * 获取委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClients(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolTrunk/GetClients`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolTrunk/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取坐标选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetAreas(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPoolTrunk/GetAreas`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolTrunk/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取卸货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolTrunk/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
