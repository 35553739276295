
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import {
  EnumLadingBillType,
  EnumLadingBillTypeOption,
} from "@/models.machine/EnumLadingBillType";
import {
  EnumLadingBillNumber,
  EnumLadingBillNumberOption,
} from "@/models.machine/EnumLadingBillNumber";
export default class OrderDetailLadingBill extends Vue {
  @Model() form!: MiezzForm<
    {
      LadingBillType?: EnumLadingBillType;
      LadingBillNumber?: EnumLadingBillNumber;
      LadingBillRemark?: string;
    },
    string
  >;

  EnumLadingBillTypeOption = EnumLadingBillTypeOption;
  EnumLadingBillNumberOption = EnumLadingBillNumberOption;
  ladingBillType = "暂无数据";
  ladingBillNumber = "暂无数据";

  created(): void {
    this.ladingBillType =
      EnumLadingBillTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.LadingBillType
      )?.Label ?? "暂无数据";
    this.ladingBillNumber =
      EnumLadingBillNumberOption.firstOrDefault(
        (it) => it.Value == this.form.Data.LadingBillNumber
      )?.Label ?? "暂无数据";
  }
}
