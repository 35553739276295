import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderPoolCopyBookingNoBuildType, EnumOrderPoolCopyBookingNoBuildTypeOption } from "./EnumOrderPoolCopyBookingNoBuildType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrderPoolCopy extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托公司
     */
    ClientCompanyId?: string;
    /**
     * 委托公司中文名称
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托公司
     */
    ClientCompanyLabel?: string;
    /**
     * 委托人
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * Booking No.
     */
    BookingNoBuildType?: EnumOrderPoolCopyBookingNoBuildType;
}

/**
 * -表单校验
 */
export const DtoRequestOrderPoolCopyRules = {
    ClientCompanyId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoRequestOrderPoolCopyMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ClientCompanyId",
    Label: "委托公司",
    Customize: true,
  },
  {
    Prop: "ClientUserId",
    Label: "委托人",
    Customize: true,
  },
  {
    Prop: "BookingNoBuildType",
    Label: "Booking No.",
    Customize: true,
  },
]
