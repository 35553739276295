import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolProcessCount_Warehouse extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.PlanCargoQuantity = "";
        this.InStockCargoQuantity = "";
        this.TimeRed = false;
        this.Process = "查看详情";
    }

    [proName: string]: any;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 类型：装货港/目的港
     */
    Type?: EnumOrderServiceType;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 链接
     */
    Link?: string;
    /**
     * 类型
     */
    TypeText?: string;
    /**
     * 计划货量
     */
    PlanCargoQuantity?: string;
    /**
     * 合计件数-计划
     */
    SumQuantity?: number;
    /**
     * 计价单位-计划
     */
    ChargeUnit?: string;
    /**
     * 合计毛重-计划
     */
    SumGrossWeight?: number;
    /**
     * 合计体积-计划
     */
    SumVolume?: number;
    /**
     * 实际货量
     */
    InStockCargoQuantity?: string;
    /**
     * 合计件数-入库
     */
    SumQuantityInStock?: number;
    /**
     * 计价单位-入库
     */
    ChargeUnitInStock?: string;
    /**
     * 合计毛重-入库
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积-入库
     */
    SumVolumeInStock?: number;
    /**
     * 计划时间
     */
    PlanTime?: Date;
    /**
     * 执行时间
     */
    ExecTime?: Date;
    /**
     * 标记红色
     */
    TimeRed?: boolean;
    /**
     * 计划时间
     */
    ReceivingDeadline?: Date;
    /**
     * 执行时间
     */
    InStockTime?: Date;
    /**
     * 计划入货时间
     */
    PlanInStockTime?: Date;
    /**
     * 进程
     */
    Process?: string;
}

/**
 * -表单校验
 */
export const DtoListItemOrderPoolProcessCount_WarehouseRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPoolProcessCount_WarehouseMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "TypeText",
    Label: "类型",
  },
  {
    Prop: "PlanCargoQuantity",
    Label: "计划货量",
  },
  {
    Prop: "InStockCargoQuantity",
    Label: "实际货量",
  },
  {
    Prop: "PlanTime",
    Label: "计划时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "ExecTime",
    Label: "执行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]
