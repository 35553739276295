
import MiezzDynamicSearchMonthRange from "@/components/MiezzDynamicSearchMonthRange.vue";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
@Options({
  components: {
    MiezzDynamicSearchMonthRange,
  },
})
export default class MiezzMonthChoose extends Vue {
  @Model() modelValue?: string;
  months: string[] = [];
  month?: string = "";
  monthRange?: string = "";

  created(): void {
    if (this.modelValue) {
      console.log("传值：", this.modelValue);
      this.handleChoose(this.modelValue);
    } else {
      const month = this.$route.query.month as string;
      if (month) {
        console.log("来自路由", month);
        this.handleChoose(month);
      } else {
        const now = moment();
        const month = now.format("YYYY-MM");
        console.log("来自当前时间", month);
        this.handleChooseMonth(month);
      }
    }
  }

  @Watch("modelValue")
  onModelValueChanged(nv: any, ov: any): void {
    if (nv != ov) {
      console.log("值变更：", this.modelValue);
      this.handleChoose();
    }
  }

  handlePrevYear(): void {
    const month = moment(this.month).add(-1, "y").format("YYYY-MM");
    this.handleChooseMonth(month);
  }

  handleNextYear(): void {
    const month = moment(this.month).add(1, "y").format("YYYY-MM");
    this.handleChooseMonth(month);
  }

  handlePrevMonth(): void {
    const month = moment(this.month).add(-1, "M").format("YYYY-MM");
    this.handleChooseMonth(month);
  }

  handleNextMonth(): void {
    const month = moment(this.month).add(1, "M").format("YYYY-MM");
    this.handleChooseMonth(month);
  }

  handleChoose(month?: string): void {
    if (month) {
      if (month.contains(",")) {
        console.log("解析为月份范围");
        this.monthRange = month;
        this.handleChooseMonthRange();
      } else {
        this.handleChooseMonth(month);
      }
    }
  }

  buildMonths(month?: string): void {
    const d = moment(month);
    const year = d.format("YYYY");
    let months: string[] = [];
    if (d.month() + 1 > 6) {
      months = ["07", "08", "09", "10", "11", "12"];
    } else {
      months = ["01", "02", "03", "04", "05", "06"];
    }
    this.months = [];
    for (let item of months) {
      const str = `${year}-${item}`;
      this.months.push(str);
    }
  }

  handleChooseMonth(month?: string): void {
    if (month) {
      this.monthRange = undefined;
      this.month = month;
      this.buildMonths(this.month);
      this.$route.query["month"] = this.month ?? "";
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.$emit("update:modelValue", this.month);
    }
  }

  @Watch("monthRange")
  handleChooseMonthRange(): void {
    if (this.monthRange) {
      this.month = undefined;
      if (this.months.length == 0) {
        this.buildMonths(moment().format("YYYY-MM"));
      }
      this.$route.query["month"] = this.monthRange ?? "";
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.$emit("update:modelValue", this.monthRange);
    }
  }
}
