import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchProduct from "../models.machine/DtoSelectSearchProduct";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchProduct from "../models.machine/DtoPageListSearchProduct";
import DtoPageListProduct from "../models.machine/DtoPageListProduct";
import DtoDetailProduct from "../models.machine/DtoDetailProduct";
import DtoFormProduct from "../models.machine/DtoFormProduct";
import DtoSearchCode from "../models.machine/DtoSearchCode";
import { EnumProductAliasType, EnumProductAliasTypeOption } from "../models.machine/EnumProductAliasType";
import DtoRequestAlias from "../models.machine/DtoRequestAlias";
import DtoCallbackAlias from "../models.machine/DtoCallbackAlias";

/**
 * 产品基础信息
 * @description 自动生成的代码,请勿修改
 */
export default class ProductAppService {
    /**
     * 获取产品基础信息选择器
     * @param {DtoSelectSearchProduct} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchProduct): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Product/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取产品基础信息分页列表
     * @param {DtoPageListSearchProduct} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListProduct>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchProduct): AxiosPromise<RESTfulResult<DtoPageListProduct>> {
        const url = `/api/Product/Get`;
        return service.get<RESTfulResult<DtoPageListProduct>>(url, { params: dto });
    }

    /**
     * 获取产品基础信息详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailProduct>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailProduct>> {
        const url = `/api/Product/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailProduct>>(url);
    }

    /**
     * 添加产品基础信息
     * @param {DtoFormProduct} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormProduct): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Product/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 上架
     * @param {string[]} [ids] 产品主键集合
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Up(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Product/Up`;
        return service.post<RESTfulResult<any>>(url, ids);
    }

    /**
     * 下架
     * @param {string[]} [ids] 产品主键集合
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Down(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Product/Down`;
        return service.post<RESTfulResult<any>>(url, ids);
    }

    /**
     * 判断产品的自定义编号是否重复
     * @param {DtoSearchCode} [dto] 查询条件
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsSameCode(dto?: DtoSearchCode): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Product/IsSameCode`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 判断产品是否存在
     * @param {DtoSearchCode} [dto] 查询条件
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsSameProduct(dto?: DtoSearchCode): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Product/IsSameProduct`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取产品的别名
     * @param {string} [ProductId] 产品主键
     * @param {EnumProductAliasType} [Type] 别名类型
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetAliasByProductIdByType(ProductId?: string, Type?: EnumProductAliasType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Product/GetAlias/${ProductId}/${Type}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取产品的所有别名
     * @param {DtoRequestAlias} [dto] 查询条件
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackAlias[]>>} 选择器
     */
    static GetAllAlias(dto?: DtoRequestAlias): AxiosPromise<RESTfulResult<DtoCallbackAlias[]>> {
        const url = `/api/Product/GetAllAlias`;
        return service.get<RESTfulResult<DtoCallbackAlias[]>>(url, { params: dto });
    }

}
