import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumRelation {
    /**
     * 并且
     */
    And = 1,
    /**
     * 或者
     */
    Or = 2,
}

/**
 * -选项
 */
export const EnumRelationOption: UiSelectOption<number>[] = [
    { Label: "并且", Value: 1, Data: "And" },
    { Label: "或者", Value: 2, Data: "Or" },
];
