import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchBillHistory from "../models.machine/DtoPageListSearchBillHistory";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListBillHistory from "../models.machine/DtoPageListBillHistory";

/**
 * 账单历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class BillHistoryAppService {
    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchBillHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBillHistory>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchBillHistory): AxiosPromise<RESTfulResult<DtoPageListBillHistory>> {
        const url = `/api/BillHistory/Get`;
        return service.get<RESTfulResult<DtoPageListBillHistory>>(url, { params: dto });
    }

    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchBillHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBillHistory>>} 分页列表
     */
    static All(dto?: DtoPageListSearchBillHistory): AxiosPromise<RESTfulResult<DtoPageListBillHistory>> {
        const url = `/api/BillHistory/All`;
        return service.post<RESTfulResult<DtoPageListBillHistory>>(url, dto);
    }

}
