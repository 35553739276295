
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzButton from "@/models/MiezzButton";
import MiezzPageList from "@/models/MiezzPageList";
import PricePageList from "@/views/Price/PricePageList.vue";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PriceSearch from "../PriceSearch.vue";
@Options({
  components: {
    PriceSearch,
    PricePageList,
  },
})
export default class PriceServiceDetailCostCompositionSurcharge extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Search_Detail_Surcharge"
  );
  amountType = EnumPriceAmountType.Agreement;
  companyId = CurrentLogier.GetLogier()?.CompanyId;
  menuTab = new MiezzMenuTab();

  created(): void {
    const id = this.$route.params.id;
    this.menuTab.Back = true;
    this.menuTab.Url = `/price/search/detail/${id}/surcharge`;
    this.menuTab.Menus = [
      {
        Label: "已添加",
        Url: `/price/search/detail/${id}/surcharge`,
        Power: "PriceManagement_Search_Detail_Surcharge",
      },
    ];
  }
}
