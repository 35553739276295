
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import DtoFormDocumentProduct, {
        DtoFormDocumentProductMiezzFormItems,
        DtoFormDocumentProductRules,
    } from "@/models.machine/DtoFormDocumentProduct";

    export default class MiezzCurrency extends Vue {

        //是否显示海关代码
        @Prop() isCustomsCode?: boolean;

        id = "";

        visible = false;
        form = new MiezzForm<DtoFormDocumentProduct, string>();
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        created(): void {

            //表单-数据-初始化
            this.form.Data = new DtoFormDocumentProduct();
            //表单-校验
            this.form.Rules = DtoFormDocumentProductRules;
            //表单-元素配置
            this.form.Items = DtoFormDocumentProductMiezzFormItems;
        }

        /*初始化 */
        @Emit()
        onInit(product: DtoFormDocumentProduct | undefined): void {
            if (product != undefined) {
                this.form.Data = product;
            }
            this.visible = true;
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }

