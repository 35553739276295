import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";

/**
 * 公司分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchCompanyAuth extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
}

/**
 * 公司分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchCompanyAuthRules = {
}

/**
 * 公司分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchCompanyAuthDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
