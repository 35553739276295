
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
import DtoCallbackAdd from "@/models.machine/DtoCallbackAdd";
import DtoRequestAdd from "@/models.machine/DtoRequestAdd";
import DtoRequestBLNO from "@/models.machine/DtoRequestBLNO";
import DtoCallbackBLNO from "@/models.machine/DtoCallbackBLNO";
import UiSelectOption from "@/models.machine/UiSelectOption";
import OSS from "ali-oss";
import AliyunOSS from "@/apis/AliyunOSS";
import CurrentLogier from "@/apis/CurrentLogier";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
import DtoPageListSearchOrderDeparture, {
  DtoPageListSearchOrderDepartureDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderDeparture";
import DtoPageListOrderDeparture from "@/models.machine/DtoPageListOrderDeparture";
import DtoRequestClilentCompany from "@/models.machine/DtoRequestClilentCompany";
import DtoRequestAutoAdd from "@/models.machine/DtoRequestAutoAdd";
import DtoCheckBLNO from "@/models.machine/DtoCheckBLNO";
import DtoListItemOrderDeparture, {
  DtoListItemOrderDepartureMiezzListItems,
} from "@/models.machine/DtoListItemOrderDeparture";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { ElMessageBox } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoPutSupplier, {
  DtoPutSupplierMiezzFormItems,
  DtoPutSupplierRules,
} from "@/models.machine/DtoPutSupplier";
import DtoPutExecutor, {
  DtoPutExecutorMiezzFormItems,
  DtoPutExecutorRules,
} from "@/models.machine/DtoPutExecutor";
import DtoPutBLNO, {
  DtoPutBLNOMiezzFormItems,
  DtoPutBLNORules,
} from "@/models.machine/DtoPutBLNO";
import DtoFormOrderDeparture, {
  DtoFormOrderDepartureMiezzFormItems,
  DtoFormOrderDepartureRules,
} from "@/models.machine/DtoFormOrderDeparture";

import DtoPutReleaseTime, {
  DtoPutReleaseTimeMiezzFormItems,
  DtoPutReleaseTimeRules,
} from "@/models.machine/DtoPutReleaseTime";

import DtoPutExportAnnex, {
  DtoPutExportAnnexMiezzFormItems,
  DtoPutExportAnnexRules,
} from "@/models.machine/DtoPutExportAnnex";

import DtoPutImportAnnex, {
  DtoPutImportAnnexMiezzFormItems,
  DtoPutImportAnnexRules,
} from "@/models.machine/DtoPutImportAnnex";

import { EnumCustomsAnnexMode } from "@/models.machine/EnumCustomsAnnexMode";
import { EnumCustomsAnnexModeOption } from "@/models.machine/EnumCustomsAnnexMode";
import { EnumCustomsAnnexType } from "@/models.machine/EnumCustomsAnnexType";
import { EnumCustomsType } from "@/models.machine/EnumCustomsType";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
import { EnumCustomsClearanceHeaderProvider } from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import OrderAppService from "@/apis.machine/OrderAppService";
import { color } from "echarts";

import DtoPageListSearchOrderDepartureHistory, {
  DtoPageListSearchOrderDepartureHistoryDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderDepartureHistory";
import DtoPageListOrderDepartureHistory from "@/models.machine/DtoPageListOrderDepartureHistory";
import DtoListItemOrderDepartureHistory, {
  DtoListItemOrderDepartureHistoryMiezzListItems,
} from "@/models.machine/DtoListItemOrderDepartureHistory";
import OrderDepartureHistoryAppService from "@/apis.machine/OrderDepartureHistoryAppService";
import OrderSummaryTime from "../OrderSummaryTime.vue";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import DtoCallbackOrderMainBillAutoPullThreeApiData_CustomsProcess from "@/models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData_CustomsProcess";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
@Options({
  components: {
    OrderSummaryTime,
  },
})
export default class OrderDeparturePageList extends Vue {
  EnumCompanyType = EnumCompanyType;
  isHistory = true;
  /**分页列表 */
  historys = new MiezzPageList<
    DtoPageListSearchOrderDepartureHistory,
    DtoListItemOrderDepartureHistory,
    string
  >();
  logier?: Logier;
  customerServices: UiSelectOption<string>[] = [];
  client?: OSS;
  menuTab = new MiezzMenuTab();
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchOrderDeparture,
    DtoListItemOrderDeparture,
    string
  >();
  /**委托书主键 */
  id = "";
  /*订单主键 */
  orderId = "";
  //报关资料主键
  orderDepartureId = "";
  EnumOrderServiceType = EnumOrderServiceType;
  _orderId?: string = "";

  clientRandom = 0;
  /*类型：0表示委托书的关务/清关；1表示订单的关务/清关 */
  type = 0;
  //委托人集合
  clientCompanys: UiSelectOption<string>[] = [];
  //委托人集合
  pools: UiSelectOption<string>[] = [];

  stateRequestParam = new DtoRequestOrderState();
  stateCallbback = new DtoCallbackOrderState();

  BLNOForm = new DtoPutBLNO();
  isLock = false;
  BatchBLNOForm = new DtoCallbackBLNO();

  //是否具有编辑权限
  isAdd = false;
  isEdit = false;
  isDelete = false;
  isDetail = true;
  isSupplier = true;

  isLoading = false;
  selectOrderDepartureId?: string;
  service = EnumOrderServiceType.DeparturePort;
  task = "";
  EnumCustomsAnnexModeOption = EnumCustomsAnnexModeOption;
  orderDepartureAnnexs: any;
  importAnnexRuleFormVisible = false;
  exportAnnexRuleFormVisible = false;
  executorRuleFormVisible = false;
  releaseTimeRuleFormVisible = false;
  addRuleFormVisible = false;
  executorRuleForm = new MiezzForm<DtoPutExecutor, string>();
  releaseTimeRuleForm = new MiezzForm<DtoPutReleaseTime, string>();
  exportAnnexRuleForm = new MiezzForm<DtoPutExportAnnex, string>();
  importAnnexRuleForm = new MiezzForm<DtoPutImportAnnex, string>();
  addRuleForm = new MiezzForm<DtoFormOrderDeparture, string>();
  selectRow: any;
  supplierBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];
  executorBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];

  releaseTimeBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];

  BLNOBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];

  BatchBLNOBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];

  exportAnnexBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];
  importAnnexBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];
  addBottomButtons?: MiezzButton[] = [
    {
      IsBlue: true,
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
    {
      IsBlue: true,
      Code: "close",
      Label: "关闭",
      Type: "default",
      Size: "small",
    },
  ];
  options = [
    {
      value: true,
      label: "是",
    },
    {
      value: false,
      label: "否",
    },
  ];
  options1 = [
    {
      value: 1,
      label: "委托人提供",
    },
    {
      value: 2,
      label: "服务商提供",
    },
  ];
  rules = {
    BLNO: [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          var fields = rule.field
            .replace("[", ".")
            .replace("]", ".")
            .split(".");
          var number = Number(fields[1]);
          if (
            this.BatchBLNOForm &&
            this.BatchBLNOForm.Items &&
            this.BatchBLNOForm.Items.length > 0
          ) {
            if (
              this.BatchBLNOForm.Items[number].BLNO == null ||
              this.BatchBLNOForm.Items[number].BLNO == undefined ||
              this.BatchBLNOForm.Items[number].BLNO == ""
            ) {
              callback("信息不能为空");
              return false;
            } else {
              for (var it of this.BatchBLNOForm.Items) {
                if (
                  it.OrderDepartureId !=
                  this.BatchBLNOForm.Items[number].OrderDepartureId &&
                  it.BLNO == this.BatchBLNOForm.Items[number].BLNO
                ) {
                  callback("重复信息，请重新填写");
                  return false;
                }
              }
            }
          }
          callback();
        },
        trigger: "blur",
      },
    ],
  };
  onMoreHistyory(): void {
    if (this.historys.Dto) {
      this.historys.Dto.CurrentPage = 1;
      this.historys.Dto.PageSize = 99999999;
    }
    this.isLoading = true;
    OrderDepartureHistoryAppService.All(this.historys.Dto).then((r) => {
      this.isLoading = false;
      this.historys.Data = r.data.Data;
    });
  }
  onChangeExportAnnexMode(): void {
    if (this.exportAnnexRuleForm.Data.Mode == EnumCustomsAnnexMode.Custom) {
      if (
        this.selectRow.OrderDepartureCustomss != null &&
        this.selectRow.OrderDepartureCustomss != undefined &&
        this.selectRow.OrderDepartureCustomss.length > 0
      ) {
        ElMessageBox.confirm(
          "更改添加方式将删除原有信息，信息将无法恢复，是否继续？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "关闭",
            type: "warning",
          }
        )
          .then(() => {
            this.selectRow.OrderDepartureCustomss = [];
          })
          .catch(() => {
            this.exportAnnexRuleForm.Data.Mode = EnumCustomsAnnexMode.System;
          });
      } else {
        this.selectRow.OrderDepartureCustomss = [];
      }
    } else {
      if (
        this.selectRow.OrderDepartureAnnexs != null &&
        this.selectRow.OrderDepartureAnnexs != undefined &&
        this.selectRow.OrderDepartureAnnexs.length > 0
      ) {
        ElMessageBox.confirm(
          "更改添加方式将删除原有信息，信息将无法恢复，是否继续？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "关闭",
            type: "warning",
          }
        )
          .then(() => {
            this.selectRow.OrderDepartureAnnexs = [];
            this.$router.push(
              "/pool/departure/customs/update/" +
              this.selectRow.Id +
              `?backUrl=` +
              this.$route.fullPath
            );
          })
          .catch(() => {
            this.exportAnnexRuleForm.Data.Mode = EnumCustomsAnnexMode.Custom;
          });
      } else {
        this.selectRow.OrderDepartureAnnexs = [];
        this.$router.push(
          "/pool/departure/customs/update/" +
          this.selectRow.Id +
          `?backUrl=` +
          this.$route.fullPath
        );
      }
    }
  }

  modalProcess = new MiezzModal();
  processList: DtoCallbackOrderMainBillAutoPullThreeApiData_CustomsProcess[] =
    [];
  onProcess(row: any): void {
    OrderMainBillAppService.AutoPullThreeApiData({
      OrderId: this._orderId,
      UpdateData: true,
    }).then((r) => {
      this.processList =
        r.data.Data?.CustomsList?.firstOrDefault((it) => it.Id == row.Id)
          ?.ProcessList ?? [];
      if (this.processList.length > 0) {
        this.modalProcess.Title = "进程";
        this.modalProcess.Width = "700px";
        this.modalProcess.Show = true;
      } else {
        ElMessage.warning("暂无数据，请向供应商询问详细进程");
      }
    });
  }
  onCustoms(customsId: string): void {
    this.$router.push(
      "/pool/departure/customs/detail/" +
      customsId +
      `?backUrl=` +
      this.$route.fullPath
    );
  }
  onEditCustoms(customsId: string): void {
    this.$router.push(
      "/pool/departure/customs/update/" +
      customsId +
      `?backUrl=` +
      this.$route.fullPath
    );
  }
  onUrl(url: any): string {
    if (url != null && url != undefined && url != "") {
      var newUrl = this.client?.signatureUrl(url as string);
      return newUrl as string;
    } else {
      return "";
    }
  }
  onAnnex(row: any): void {
    this.selectRow = row;
    this.orderDepartureAnnexs = [];
    if (row.OrderDepartureAnnexs && row.OrderDepartureAnnexs.length > 0) {
      for (var annex1 of row.OrderDepartureAnnexs) {
        var url1 = this.client?.signatureUrl(annex1.Url as string);
        var names = annex1.Url.split("/");
        var name = names[names.length - 1];
        var file = {
          name: name,
          url: url1,
        };
        this.orderDepartureAnnexs.push(file);
      }
    }
    if (row.Type == EnumCustomsType.Export) {
      this.exportAnnexRuleForm.Data = new DtoPutExportAnnex();
      this.exportAnnexRuleForm.Data.OrderDepartureId = row.Id;
      this.exportAnnexRuleForm.Data.Mode = row.Mode;
      this.exportAnnexRuleForm.Data.IsTax = row.IsTax;
      this.exportAnnexRuleForm.Data.Data = [];
      this.exportAnnexRuleForm.Data.Prerecording = [];
      this.exportAnnexRuleForm.Data.Release = [];
      this.exportAnnexRuleForm.Data.Other = [];
      if (row.OrderDepartureAnnexs && row.OrderDepartureAnnexs.length > 0) {
        for (var annex2 of row.OrderDepartureAnnexs) {
          if (annex2.Type == EnumCustomsAnnexType.Prerecording) {
            this.exportAnnexRuleForm.Data.Prerecording.push(annex2);
          } else if (annex2.Type == EnumCustomsAnnexType.Release) {
            this.exportAnnexRuleForm.Data.Release.push(annex2);
          } else if (annex2.Type == EnumCustomsAnnexType.Other) {
            this.exportAnnexRuleForm.Data.Other.push(annex2);
          } else if (annex2.Type == EnumCustomsAnnexType.Data) {
            this.exportAnnexRuleForm.Data.Data.push(annex2);
          }
        }
      }

      this.exportAnnexRuleFormVisible = true;
      this.$nextTick(() => {
        if (
          this.exportAnnexRuleForm.Data.Mode == undefined ||
          this.exportAnnexRuleForm.Data.Mode == null
        ) {
          this.exportAnnexRuleForm.Data.Mode = EnumCustomsAnnexMode.Custom;
        }
      });
    } else {
      this.importAnnexRuleForm.Data = new DtoPutImportAnnex();
      this.importAnnexRuleForm.Data.OrderDepartureId = row.Id;
      this.importAnnexRuleForm.Data.IsTax = row.IsTax;
      this.importAnnexRuleForm.Data.Data = [];
      this.importAnnexRuleForm.Data.Prerecording = [];
      this.importAnnexRuleForm.Data.Release = [];
      this.importAnnexRuleForm.Data.Other = [];
      if (row.OrderDepartureAnnexs && row.OrderDepartureAnnexs.length > 0) {
        for (var annex of row.OrderDepartureAnnexs) {
          if (annex.Type == EnumCustomsAnnexType.Data) {
            this.importAnnexRuleForm.Data.Data.push(annex);
          } else if (annex.Type == EnumCustomsAnnexType.Prerecording) {
            this.importAnnexRuleForm.Data.Prerecording.push(annex);
          } else if (annex.Type == EnumCustomsAnnexType.Release) {
            this.importAnnexRuleForm.Data.Release.push(annex);
          } else if (annex.Type == EnumCustomsAnnexType.Other) {
            this.importAnnexRuleForm.Data.Other.push(annex);
          } else if (annex.Type == EnumCustomsAnnexType.Data) {
            this.importAnnexRuleForm.Data.Data.push(annex);
          }
        }
      }
      this.importAnnexRuleFormVisible = true;
    }
  }

  onChangeBLNO(): void {
    if (this.BLNOForm.Data) {
      this.BLNOForm.Data.BLNO = TrimSpace(
        this.BLNOForm.Data.BLNO
      ).toUpperCase();
    }
  }
  onChangeBatchBLNO(index: number): void {
    if (this.BatchBLNOForm && this.BatchBLNOForm.Items) {
      if (this.BatchBLNOForm.Items[index].BLNO) {
        this.BatchBLNOForm.Items[index].BLNO = TrimSpace(
          this.BatchBLNOForm.Items[index].BLNO + ""
        ).toUpperCase();
      }
    }
  }
  onBLNO(row: any): void {
    var param = new DtoRequestBLNO();
    param.PoolId = row.OrderPoolId;
    param.OrderId = this.orderId;
    param.Type = this.service;
    param.OrderDepartureId = row.Id;
    OrderDepartureAppService.GetBLNOs(param).then((r) => {
      if (r.data.Data) {
        this.BatchBLNOForm = r.data.Data;
        //console.log(this.$el.querySelector("." + row.Id));
        //this.$el.querySelector("." + row.Id).scrollIntoView({ block: "start", behavior: "smooth" });

        setTimeout(() => {
          const error = document.getElementsByClassName(row.Id);
          error[0]?.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 100);

        //MiezzForm.customResetFields(this.$refs, "ruleFormBatchBLNO");

        setTimeout(() => {
          if (this.BatchBLNOForm && this.BatchBLNOForm.Items) {
            for (var i = 0; i < this.BatchBLNOForm.Items.length; i++) {
              var name = "Items." + i + ".BLNO";
              MiezzForm.clearCustomValidate(
                this.$refs,
                "ruleFormBatchBLNO",
                name
              );
            }
          }
        }, 200);
      }
    });

    //OrderDepartureAppService.GetBLNOById(row.Id).then((r) => {
    //    if (r.data.Data) {
    //        this.BLNOForm.Data.BLNO = r.data.Data.BLNO;
    //        this.BLNOForm.Data.OrderDepartureId = row.Id;
    //        this.BLNOForm.Data.Visible = true;
    //        this.BLNOForm.Rules.BLNO = [
    //            {
    //                required: true,
    //                message: "信息不能为空",
    //                trigger: "change",
    //            },
    //            {
    //                required: true,
    //                validator: (rule: any, value: string, callback: any) => {
    //                    if (value == null || value == undefined || value == "") {
    //                        callback();
    //                        return false;
    //                    }

    //                    var param = new DtoCheckBLNO();
    //                    param.OrderId = this.orderId;
    //                    if (param.OrderId == "") {
    //                        param.OrderId = undefined;
    //                    }
    //                    param.PoolId = this.id;
    //                    if (param.PoolId == "") {
    //                        param.PoolId = undefined;
    //                    }
    //                    param.OrderDepartureId = row.Id;
    //                    param.BLNO = value;
    //                    param.Service = this.service;
    //                    OrderDepartureAppService.IsBLNO(param).then((r) => {
    //                        if (r.data.Data == true) {
    //                            callback(new Error("重复信息，请重新填写"));
    //                        }
    //                        else {
    //                            callback();
    //                        }
    //                    });
    //                },
    //                trigger: "blur",
    //            },
    //        ];
    //    }
    //})
  }
  onSupplier(row: any): void {
    this.stateRequestParam = new DtoRequestOrderState();
    this.stateRequestParam.OrderId = [];
    this.stateRequestParam.PoolId = [];
    if (
      this.orderId != null &&
      this.orderId != undefined &&
      this.orderId != ""
    ) {
      this.stateRequestParam.OrderId.push(this.orderId as string);
    } else if (this.id != null && this.id != undefined && this.id != "") {
      this.stateRequestParam.PoolId.push(this.id as string);
    }
    this.stateRequestParam.Service = this.service;
    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
      if (state.data.Data) {
        this.stateCallbback = state.data.Data;

        this.isLock = false;
        if (this.stateCallbback && this.stateCallbback.IsLock) {
          this.isLock = this.stateCallbback.IsLock;
        }

        this.selectOrderDepartureId = row.Id;
        if (this.id == null || this.id == undefined || this.id == "") {
          this.id = row.OrderPoolId;
        }
        const Supplier = this.$refs["Supplier"] as any;
        Supplier.onInit(row);
      }
    });
  }
  onExecutor(row: any): void {
    this.executorRuleForm.Data.ExecutorName = row.ExecutorName;
    this.executorRuleForm.Data.ExecutorPhone = row.ExecutorPhone;
    this.executorRuleForm.Data.ExecutorInfo = row.ExecutorInfo;
    this.executorRuleForm.Data.Service = this.service;
    //this.executorRuleForm.Data.CustomerServiceLabel = name;
    //this.executorRuleForm.Rules.CustomerServiceId = [
    //    {
    //        required: true,
    //        message: "信息不能为空",
    //        trigger: "blur",
    //    },
    //];
    this.executorRuleFormVisible = true;
  }
  onReleaseTime(row: any): void {
    if (this.id == null || this.id == undefined || this.id == "") {
      this.id = row.OrderPoolId;
    }
    this.orderDepartureId = row.Id;
    this.releaseTimeRuleForm.Data.ReleaseTime = row.ReleaseTime;
    this.releaseTimeRuleFormVisible = true;
  }
  onTax(isTax: boolean): void {
    this.addRuleForm.Data.IsTax = isTax;
  }
  /**创建时 */
  created(): void {
    this.task = this.$route.query.task as string;
    this.client = AliyunOSS.GetClient();
    this.logier = CurrentLogier.GetLogier();

    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.logier?.CompanyId,
        PowerCodes: ["IdentityPermission_CustomerService"],
      }).then((r) => {
        this.customerServices = r.data.Data ?? [];
      });
    }

    //列表-传参
    this.historys.Dto = new DtoPageListSearchOrderDepartureHistory();
    //列表-动态搜索-项
    this.historys.DynamicSearchItems =
      DtoPageListSearchOrderDepartureHistoryDynamicSearchItems;
    //列表-数据-初始
    this.historys.Data = new DtoPageListOrderDepartureHistory();

    if (this.$route && this.$route.matched) {
      for (var matched of this.$route.matched) {
        if (matched.path.toLowerCase() == "/pool/departure/customs/:id") {
          this.service = EnumOrderServiceType.DeparturePort;
          this.id = this.$route.params.id as string;
          this.type = 0;

          if (this.historys.Dto) {
            this.historys.Dto.PoolId = this.id;
            this.historys.Dto.OrderId = undefined;
            this.historys.Dto.Service = this.service;
          }
        } else if (
          matched.path.toLowerCase() == "/order/departure/customs/:id"
        ) {
          this.service = EnumOrderServiceType.DeparturePort;
          this.orderId = this.$route.params.id as string;
          this.type = 1;

          if (this.historys.Dto) {
            this.historys.Dto.PoolId = undefined;
            this.historys.Dto.OrderId = this.orderId;
            this.historys.Dto.Service = this.service;
          }
        } else if (
          matched.path.toLowerCase() == "/pool/destination/customs/:id"
        ) {
          this.service = EnumOrderServiceType.DestinationPort;
          this.id = this.$route.params.id as string;
          this.type = 0;

          if (this.historys.Dto) {
            this.historys.Dto.PoolId = this.id;
            this.historys.Dto.OrderId = undefined;
            this.historys.Dto.Service = this.service;
          }
        } else if (
          matched.path.toLowerCase() == "/order/destination/customs/:id"
        ) {
          this.service = EnumOrderServiceType.DestinationPort;
          this.orderId = this.$route.params.id as string;
          this.type = 1;

          if (this.historys.Dto) {
            this.historys.Dto.PoolId = undefined;
            this.historys.Dto.OrderId = this.orderId;
            this.historys.Dto.Service = this.service;
          }
        }
      }
    }

    //表单-数据-初始化
    this.executorRuleForm.Data = new DtoPutExecutor();
    //表单-校验
    this.executorRuleForm.Rules = DtoPutExecutorRules;
    //表单-元素配置
    this.executorRuleForm.Items = DtoPutExecutorMiezzFormItems;

    //表单-数据-初始化
    this.releaseTimeRuleForm.Data = new DtoPutReleaseTime();
    //表单-校验
    this.releaseTimeRuleForm.Rules = DtoPutReleaseTimeRules;
    //表单-元素配置
    this.releaseTimeRuleForm.Items = DtoPutReleaseTimeMiezzFormItems;

    //表单-数据-初始化
    this.exportAnnexRuleForm.Data = new DtoPutExportAnnex();
    //表单-校验
    this.exportAnnexRuleForm.Rules = DtoPutExportAnnexRules;
    //表单-元素配置
    this.exportAnnexRuleForm.Items = DtoPutExportAnnexMiezzFormItems;

    //表单-数据-初始化
    this.importAnnexRuleForm.Data = new DtoPutImportAnnex();
    //表单-校验
    this.importAnnexRuleForm.Rules = DtoPutImportAnnexRules;
    //表单-元素配置
    this.importAnnexRuleForm.Items = DtoPutImportAnnexMiezzFormItems;

    //表单-数据-初始化
    this.addRuleForm.Data = new DtoFormOrderDeparture();
    //表单-校验
    this.addRuleForm.Rules = DtoFormOrderDepartureRules;
    //表单-元素配置
    this.addRuleForm.Items = DtoFormOrderDepartureMiezzFormItems;

    //表单-数据-初始化
    this.BLNOForm.Data = new DtoPutBLNO();
    //表单-校验
    this.BLNOForm.Rules = DtoPutBLNORules;
    //表单-元素配置
    this.BLNOForm.Items = DtoPutBLNOMiezzFormItems;

    if (this.service == EnumOrderServiceType.DeparturePort) {
      if (this.type == 0) {
        this.menuTab.Back = true;
        this.menuTab.Url = `/pool/departure/customs/${this.id}`;
        this.menuTab.Menus = [
          {
            Label: "装货港-报关",
            Url: `/pool/departure/customs/${this.id}`,
            Power: "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs",
          },
        ];
        CurrentLogier.GetPowerCodes().then((r) => {
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs_Edit"
            )
          ) {
            this.isEdit = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs_AddTo"
            )
          ) {
            this.isAdd = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs_Delete"
            )
          ) {
            this.isDelete = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs_See"
            )
          ) {
            this.isDetail = true;
          }

          this.isSupplier = true;
          if (this.isEdit == true) {
            this.isSupplier = false;
          }

          if (this.supplierBottomButtons) {
            this.supplierBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.executorBottomButtons) {
            this.executorBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.releaseTimeBottomButtons) {
            this.releaseTimeBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.exportAnnexBottomButtons) {
            this.exportAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.importAnnexBottomButtons) {
            this.importAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          //if (this.service == EnumOrderServiceType.DeparturePort && this.isEdit == true) {
          //    if (this.type == 0) {
          //        this.isLoading = true;
          //        OrderDepartureAppService.AutoAddByPoolId(this.id).then((r2) => {
          //            this.handleGet();
          //        })
          //    }
          //    else {
          //        this.handleGet();
          //    }
          //}
          //else {
          //    this.handleGet();
          //}
          this.handleGet();
        });
      } else {
        this.menuTab.Back = true;
        this.menuTab.Url = `/order/departure/customs/${this.id}`;
        this.menuTab.Menus = [
          {
            Label: "装货港-报关",
            Url: `/order/departure/customs/${this.id}`,
            Power:
              "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs",
          },
        ];

        CurrentLogier.GetPowerCodes().then((r) => {
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs_Edit"
            )
          ) {
            this.isEdit = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance_AddTo"
            )
          ) {
            this.isAdd = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs_Delete"
            )
          ) {
            this.isDelete = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs_See"
            )
          ) {
            this.isDetail = true;
          }

          this.isSupplier = true;
          if (this.isEdit == true) {
            this.isSupplier = false;
          }

          if (this.supplierBottomButtons) {
            this.supplierBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.executorBottomButtons) {
            this.executorBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.releaseTimeBottomButtons) {
            this.releaseTimeBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.exportAnnexBottomButtons) {
            this.exportAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.importAnnexBottomButtons) {
            this.importAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          //if (this.service == EnumOrderServiceType.DeparturePort && this.isEdit == true) {
          //    if (this.type == 1) {
          //        this.isLoading = true;
          //        var p1 = new DtoRequestAutoAdd();
          //        p1.OrderId = this.orderId;
          //        p1.Type = this.service;
          //        OrderDepartureAppService.AutoAddOrder(p1).then((r2) => {
          //            this.handleGet();
          //        })
          //    }
          //    else {
          //        this.handleGet();
          //    }
          //}
          //else {
          //    this.handleGet();
          //}
          this.handleGet();
        });
      }
    } else if (this.service == EnumOrderServiceType.DestinationPort) {
      if (this.type == 0) {
        this.menuTab.Back = true;
        this.menuTab.Url = `/pool/destination/customs/${this.id}`;
        this.menuTab.Menus = [
          {
            Label: "目的港-清关",
            Url: `/pool/destination/customs/${this.id}`,
            Power: "OrderManagement_Pool_Summary_DestinationPortClearCustoms",
          },
        ];

        CurrentLogier.GetPowerCodes().then((r) => {
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_DestinationPortClearCustoms_Edit"
            )
          ) {
            this.isEdit = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_DestinationPortClearCustoms_AddTo"
            )
          ) {
            this.isAdd = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs_Delete"
            )
          ) {
            this.isDelete = true;
          }
          if (
            r?.contains(
              "OrderManagement_Pool_Summary_DestinationPortClearCustoms_See"
            )
          ) {
            this.isDetail = true;
          }

          this.isSupplier = true;
          if (this.isEdit == true) {
            this.isSupplier = false;
          }

          if (this.supplierBottomButtons) {
            this.supplierBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.executorBottomButtons) {
            this.executorBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.releaseTimeBottomButtons) {
            this.releaseTimeBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.exportAnnexBottomButtons) {
            this.exportAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.importAnnexBottomButtons) {
            this.importAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          //if (this.service == EnumOrderServiceType.DestinationPort && this.isEdit == true) {
          //    if (this.type == 0) {
          //        OrderDepartureAppService.AutoAddByPoolId(this.id).then((r2) => {
          //            this.handleGet();
          //        })
          //    }
          //    else {
          //        this.handleGet();
          //    }
          //}
          //else {
          //    this.handleGet();
          //}
          this.handleGet();
        });
      } else {
        this.menuTab.Back = true;
        this.menuTab.Url = `/order/destination/customs/${this.id}`;
        this.menuTab.Menus = [
          {
            Label: "目的港-清关",
            Url: `/order/destination/customs/${this.id}`,
            Power:
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance",
          },
        ];

        CurrentLogier.GetPowerCodes().then((r) => {
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance_Edit"
            )
          ) {
            this.isEdit = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance_AddTo"
            )
          ) {
            this.isAdd = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance_Delete"
            )
          ) {
            this.isDelete = true;
          }
          if (
            r?.contains(
              "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance_See"
            )
          ) {
            this.isDetail = true;
          }

          this.isSupplier = true;
          if (this.isEdit == true) {
            this.isSupplier = false;
          }

          if (this.supplierBottomButtons) {
            this.supplierBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.executorBottomButtons) {
            this.executorBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.releaseTimeBottomButtons) {
            this.releaseTimeBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.exportAnnexBottomButtons) {
            this.exportAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          if (this.importAnnexBottomButtons) {
            this.importAnnexBottomButtons[0].Hide = !this.isEdit;
          }
          //if (this.service == EnumOrderServiceType.DestinationPort && this.isEdit == true) {
          //    if (this.type == 1) {
          //        this.isLoading = true;
          //        var p2 = new DtoRequestAutoAdd();
          //        p2.OrderId = this.orderId;
          //        p2.Type = this.service;
          //        OrderDepartureAppService.AutoAddOrder(p2).then((r2) => {
          //            this.handleGet();
          //        })
          //    }
          //}
          //else {
          //    this.handleGet();
          //}
          this.handleGet();
        });
      }
    }

    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrderDeparture();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchOrderDepartureDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListOrderDeparture();
    //列表-列配置
    this.list.Items = DtoListItemOrderDepartureMiezzListItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      //{
      //    Code: "add",
      //    Label: "添加",
      //    Icon: "plus",
      //    Type: "primary",
      //    Size: "small",
      //},
    ];
    //列表-行按钮
    this.list.RowButtons = [];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        Message: "是否确认删除",
      },
    ];

    this.list.FixTop = "40px";

    //if (this.historys.Dto) {
    //    this.historys.Dto.CurrentPage = 1;
    //    this.historys.Dto.PageSize = 999999999;
    //}
    //OrderDepartureHistoryAppService.Get(this.historys.Dto).then((r) => {
    //    this.historys.Data = r.data.Data;
    //});
  }
  rowStyle(row: any, rowIndex: number): any {
    return {
      height: "50px",
    };
  }

  isString(str: string): string {
    if (str != null && str != undefined) {
      str = str + "";
    }
    if (str == null || str == undefined || str == "") {
      return "暂无数据";
    } else {
      return str;
    }
  }

  onSelectSupplier(row: any): void {
    this.stateRequestParam = new DtoRequestOrderState();
    this.stateRequestParam.OrderId = [];
    this.stateRequestParam.PoolId = [];
    if (
      this.orderId != null &&
      this.orderId != undefined &&
      this.orderId != ""
    ) {
      this.stateRequestParam.OrderId.push(this.orderId as string);
    } else if (this.id != null && this.id != undefined && this.id != "") {
      this.stateRequestParam.PoolId.push(this.id as string);
    }
    this.stateRequestParam.Service = this.service;
    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
      if (state.data.Data) {
        this.stateCallbback = state.data.Data;
        if (this.stateCallbback.OrderCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.PoolCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.IsLock == true) {
          ElMessage.warning("订单已锁定");
          return;
        } else {
          row.Service = this.service;
          if (
            this.orderId != null &&
            this.orderId != undefined &&
            this.orderId != ""
          ) {
            row.OrderId = this.orderId;
            row.OrderPoolId = undefined;
          } else if (this.id != null && this.id != undefined && this.id != "") {
            row.OrderPoolId = this.id;
            row.OrderId = undefined;
          }
          this.isLoading = true;
          OrderDepartureAppService.UpdateSupplier(row)
            .then(() => {
              ElMessage.success("修改供应商成功");
              this.handleGet();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    });
  }

  handleClickExecutor(btn: MiezzButton, complete: () => void): void {
    this.executorRuleForm.Data.OrderPoolId = this.id;
    switch (btn.Code) {
      case "submit":
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.submit(this.$refs, "$executorRuleForm", (v) => {
                  if (v) {
                    if (
                      this.orderId != null &&
                      this.orderId != undefined &&
                      this.orderId != ""
                    ) {
                      this.executorRuleForm.Data.OrderId = this.orderId;
                      this.executorRuleForm.Data.OrderPoolId = undefined;
                    } else if (
                      this.id != null &&
                      this.id != undefined &&
                      this.id != ""
                    ) {
                      this.executorRuleForm.Data.OrderPoolId = this.id;
                      this.executorRuleForm.Data.OrderId = undefined;
                    }
                    this.executorRuleForm.Data.Service = this.service;
                    OrderDepartureAppService.UpdateExecutor(
                      this.executorRuleForm.Data
                    )
                      .then(() => {
                        ElMessage.success("编辑成功");
                        this.handleGet();
                        this.executorRuleFormVisible = false;
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });
                complete();
              }
            }
          }
        );
        break;
      case "close":
        this.executorRuleFormVisible = false;
        complete();
        break;
      default:
        break;
    }
  }

  handleClickAdd(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.submit(this.$refs, "$addRuleForm", (v) => {
                  if (v) {
                    var param = {
                      OrderId: this.orderId,
                      Service: this.service,
                      IsTax: this.addRuleForm.Data.IsTax,
                      ClearCustomsHeader:
                        this.addRuleForm.Data.ClearCustomsHeader,
                      ClientCompanyId: this.addRuleForm.Data.ClientCompanyId,
                      OrderPoolId: this.addRuleForm.Data.OrderPoolId,
                    };
                    OrderDepartureAppService.Post(param)
                      .then(() => {
                        ElMessage.success("新增成功");
                        this.handleGet();
                        this.addRuleFormVisible = false;
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });

                complete();
              }
            }
          }
        );

        break;
      case "close":
        this.addRuleFormVisible = false;
        complete();
        break;
      default:
        break;
    }
  }

  handleClickBatchBLNO(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        //OrderDepartureAppService.UpdateBLNo(this.BLNOForm.Data).then((r) => {
        //    this.BLNOForm.Data.Visible = false;
        //    ElMessage.success("编辑成功");
        //    this.handleGet();
        //    complete();
        //});
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.customSubmit(this.$refs, "ruleFormBatchBLNO", (v) => {
                  if (v) {
                    console.log(v);
                    OrderDepartureAppService.SetBLNOs(this.BatchBLNOForm)
                      .then((r) => {
                        if (this.list.Data?.OrderId) {
                          OrderMainBillAppService.AutoPullThreeApiData({
                            OrderId: this.list.Data!.OrderId,
                            UpdateData: true,
                          }).then((r) => {
                            if ((r.data.Data?.Errors?.length ?? 0) > 0) {
                              for (const error of r.data.Data?.Errors ?? []) {
                                ElMessage.warning(error);
                              }
                            } else {
                              if (
                                this.BatchBLNOForm.Items?.filter(
                                  (i) => i.BLNO
                                )?.all((i) =>
                                  r.data.Data?.CustomsList?.any(
                                    (it) => i.OrderDepartureId == it.Id
                                  )
                                )
                              ) {
                                ElMessage.success(
                                  "已通过第三方为您获取到相应信息，此类信息（标题为绿色）的变化将由接口推送并禁止修改"
                                );
                              } else {
                                ElMessage.success(
                                  "已向第三方接口发送请求，数据会有延迟，请稍后再来查看"
                                );
                              }

                              let ids: string[] = [];
                              CurrentLogier.HubConnection?.off("RefreshCustoms");
                              CurrentLogier.HubConnection?.on(
                                "RefreshCustoms",
                                (
                                  id: string,
                                ) => {
                                  ids.push(id);
                                  if (this.BatchBLNOForm.Items?.filter(
                                    (i) => i.BLNO
                                  )?.all((i) =>
                                    ids.contains(i.OrderDepartureId)
                                  )) {
                                    this.handleGet();
                                  }
                                }
                              );
                            }
                          });
                        }
                        this.BatchBLNOForm.Visible = false;
                        ElMessage.success("编辑成功");
                        this.handleGet();
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });
                complete();
              }
            }
          }
        );
        break;
      case "close":
        this.BatchBLNOForm.Visible = false;
        complete();
        break;
      default:
        break;
    }
  }

  handleClickBLNO(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        //OrderDepartureAppService.UpdateBLNo(this.BLNOForm.Data).then((r) => {
        //    this.BLNOForm.Data.Visible = false;
        //    ElMessage.success("编辑成功");
        //    this.handleGet();
        //    complete();
        //});
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.submit(this.$refs, "$BLNOForm", (v) => {
                  if (v) {
                    OrderDepartureAppService.UpdateBLNo(this.BLNOForm.Data)
                      .then((r) => {
                        this.BLNOForm.Data.Visible = false;
                        ElMessage.success("编辑成功");
                        this.handleGet();
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });
                complete();
              }
            }
          }
        );
        break;
      case "close":
        this.BLNOForm.Data.Visible = false;
        complete();
        break;
      default:
        break;
    }
  }

  handleClickReleaseTime(btn: MiezzButton, complete: () => void): void {
    this.releaseTimeRuleForm.Data.OrderPoolId = this.id;
    switch (btn.Code) {
      case "submit":
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.submit(this.$refs, "$releaseTimeRuleForm", (v) => {
                  if (v) {
                    this.releaseTimeRuleForm.Data.Service = this.service;
                    //if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                    //    this.releaseTimeRuleForm.Data.OrderId = this.orderId;
                    //    this.releaseTimeRuleForm.Data.OrderPoolId = undefined;
                    //}
                    //else if (this.id != null && this.id != undefined && this.id != "") {
                    //    this.releaseTimeRuleForm.Data.OrderPoolId = this.id;
                    //    this.releaseTimeRuleForm.Data.OrderId = undefined;
                    //}
                    //this.releaseTimeRuleForm.Data.OrderPoolId = this.id;
                    this.releaseTimeRuleForm.Data.OrderDepartureId =
                      this.orderDepartureId;
                    if (
                      this.orderId != undefined &&
                      this.orderId != null &&
                      this.orderId != ""
                    ) {
                      this.releaseTimeRuleForm.Data.OrderId = this.orderId;
                    }
                    if (
                      this.id != undefined &&
                      this.id != null &&
                      this.id != ""
                    ) {
                      this.releaseTimeRuleForm.Data.OrderPoolId = this.id;
                    }
                    this.isLoading = true;
                    OrderDepartureAppService.UpdateReleaseTime(
                      this.releaseTimeRuleForm.Data
                    )
                      .then(() => {
                        this.handleGet();
                        this.releaseTimeRuleFormVisible = false;
                        ElMessage.success("编辑成功");
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });

                complete();
              }
            }
          }
        );

        break;
      case "close":
        this.releaseTimeRuleFormVisible = false;
        complete();
        break;
      default:
        break;
    }
  }

  handleClickExportAnnex(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        this.stateRequestParam = new DtoRequestOrderState();
        this.stateRequestParam.OrderId = [];
        this.stateRequestParam.PoolId = [];
        if (
          this.orderId != null &&
          this.orderId != undefined &&
          this.orderId != ""
        ) {
          this.stateRequestParam.OrderId.push(this.orderId as string);
        } else if (this.id != null && this.id != undefined && this.id != "") {
          this.stateRequestParam.PoolId.push(this.id as string);
        }
        OrderDepartureAppService.GetState(this.stateRequestParam).then(
          (state) => {
            if (state.data.Data) {
              this.stateCallbback = state.data.Data;
              if (this.stateCallbback.OrderCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.PoolCancel == true) {
                ElMessage.warning("订单已取消");
                complete();
                return;
              } else if (this.stateCallbback.IsLock == true) {
                ElMessage.warning("订单已锁定");
                complete();
                return;
              } else {
                MiezzForm.submit(this.$refs, "$exportAnnexRuleForm", (v) => {
                  if (v) {
                    OrderDepartureAppService.UpdateExportAnnex(
                      this.exportAnnexRuleForm.Data
                    )
                      .then(() => {
                        ElMessage.success("编辑成功");
                        this.handleGet();
                        this.exportAnnexRuleFormVisible = false;
                        complete();
                      })
                      .catch(complete);
                  } else complete();
                });
                complete();
              }
            }
          }
        );

        break;
      case "close":
        this.exportAnnexRuleFormVisible = false;
        complete();
        break;
      default:
        break;
    }
  }
  handleClickImportAnnex(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "$importAnnexRuleForm", (v) => {
          if (v) {
            this.isLoading = true;
            OrderDepartureAppService.UpdateImportAnnex(
              this.importAnnexRuleForm.Data
            )
              .then(() => {
                ElMessage.success("编辑成功");
                this.handleGet();
                this.importAnnexRuleFormVisible = false;
                complete();
              })
              .catch(complete);
          } else complete();
        });
        complete();
        break;
      case "close":
        this.importAnnexRuleFormVisible = false;
        complete();
        break;
      default:
        break;
    }
  }

  /**查询列表 */
  handleGet(): void {
    if (this.list && this.list.Dto) {
      this.list.Dto.CurrentPage = 1;
      this.list.Dto.PageSize = 9999999;
      if (this.type == 0) {
        this.list.Dto.OrderPoolId = this.id;
      } else {
        this.list.Dto.OrderId = this.orderId;
      }

      this.list.Dto.Service = this.service;
    }
    this.isLoading = true;
    OrderDepartureAppService.Get(this.list.Dto).then((r) => {
      console.log(2);
      this._orderId = r.data.Data?.OrderId;
      this.list.Data = r.data.Data;

      if (this.list.Data && this.list.Data.Items) {
        for (var it of this.list.Data.Items) {
          it.ReleaseTimeString = "";
          it.AnnexString = "暂无数据";
          if (it.ReleaseTime) {
            it.ReleaseTimeString = moment(it.ReleaseTime).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          if (
            (it.OrderDepartureAnnexs != undefined &&
              it.OrderDepartureAnnexs.length > 0) ||
            (it.OrderDepartureCustomss != undefined &&
              it.OrderDepartureCustomss.length > 0)
          ) {
            it.AnnexString = "查看详情";
          }
          it.Supplier = "";
          if (this.logier && this.logier.DisabledReadSupplier == true) {
            if (
              this.logier.ServiceProviderCompanyNameZh != null &&
              this.logier.ServiceProviderCompanyNameZh != undefined &&
              this.logier.ServiceProviderCompanyNameZh != ""
            ) {
              it.Supplier = this.logier.ServiceProviderCompanyNameZh;
            } else {
              it.Supplier = this.logier.ServiceProviderCompanyNameEn;
            }
          } else {
            if (
              it.SupplierCompanyLabel != undefined &&
              it.SupplierCompanyLabel != "" &&
              it.SupplierCompanyContactLabel != undefined &&
              it.SupplierCompanyContactLabel != ""
            ) {
              it.Supplier =
                it.SupplierCompanyLabel + "/" + it.SupplierCompanyContactLabel;
            } else {
              if (
                it.SupplierCompanyLabel != undefined &&
                it.SupplierCompanyLabel != ""
              )
                it.Supplier = it.SupplierCompanyLabel;
              else if (
                it.SupplierCompanyContactLabel != undefined &&
                it.SupplierCompanyContactLabel != ""
              )
                it.Supplier = it.SupplierCompanyContactLabel;
            }
          }
        }
      }

      if (this.historys.Dto) {
        this.historys.Dto.CurrentPage = 1;
        this.historys.Dto.PageSize = 5;
      }
      OrderDepartureHistoryAppService.Get(this.historys.Dto).then((r) => {
        this.historys.Data = r.data.Data;
        this.isLoading = false;
      });
    });
  }

  onDelete(row: any): void {
    if (
      this.list &&
      this.list.Data &&
      this.list.Data.Items &&
      this.list.Data.Items.length == 1
    ) {
      ElMessage.warning("至少需要保留一条信息");
      return;
    }
    this.stateRequestParam = new DtoRequestOrderState();
    this.stateRequestParam.OrderId = [];
    this.stateRequestParam.PoolId = [];
    if (
      this.orderId != null &&
      this.orderId != undefined &&
      this.orderId != ""
    ) {
      this.stateRequestParam.OrderId.push(this.orderId as string);
    } else if (this.id != null && this.id != undefined && this.id != "") {
      this.stateRequestParam.PoolId.push(this.id as string);
    }
    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
      if (state.data.Data) {
        this.stateCallbback = state.data.Data;
        if (this.stateCallbback.OrderCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.PoolCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.IsLock == true) {
          ElMessage.warning("订单已锁定");
          return;
        } else {
          if (
            this.list &&
            this.list.Data &&
            this.list.Data.Items &&
            this.list.Data.Items.length <= 1
          ) {
            ElMessage.warning("至少需要保留一条信息");
            return;
          }

          ElMessageBox.confirm(
            "您确定要删除选中的信息吗？删除后信息将无法恢复，是否继续",
            "提示",
            {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              OrderDepartureAppService.Delete([row.Id as string])
                .then(() => {
                  ElMessage.success("删除成功");
                  this.handleGet();
                })
                .catch();
            })
            .catch(() => {
              console.log("取消删除");
            });
        }
      }
    });
  }
  onChangeClientCompanyId(): void {
    this.pools = [];
    for (var it of this.clientCompanys) {
      if (it.Value == this.addRuleForm.Data.ClientCompanyId) {
        for (var it1 of it.Data.Pools) {
          this.pools.push({
            Value: it1.OrderPoolId,
            Label: it1.BookingNo,
          });
        }
      }
    }
    if (this.pools.length == 1) {
      this.addRuleForm.Data.OrderPoolId = this.pools[0].Value;
    }
  }

  onAdd(): void {
    this.stateRequestParam = new DtoRequestOrderState();
    this.stateRequestParam.OrderId = [];
    this.stateRequestParam.PoolId = [];
    if (
      this.orderId != null &&
      this.orderId != undefined &&
      this.orderId != ""
    ) {
      this.stateRequestParam.OrderId.push(this.orderId as string);
    } else if (this.id != null && this.id != undefined && this.id != "") {
      this.stateRequestParam.PoolId.push(this.id as string);
    }
    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
      if (state.data.Data) {
        this.stateCallbback = state.data.Data;
        if (this.stateCallbback.OrderCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.PoolCancel == true) {
          ElMessage.warning("订单已取消");
          return;
        } else if (this.stateCallbback.IsLock == true) {
          ElMessage.warning("订单已锁定");
          return;
        } else {
          if (this.type == 0) {
            var param = {
              OrderPoolId: this.id,
              Service: this.service,
            };
            OrderDepartureAppService.Post(param)
              .then(() => {
                this.handleGet();
              })
              .catch();
          } else {
            if (this.service == EnumOrderServiceType.DeparturePort) {
              var pa = new DtoRequestAdd();
              pa.OrderId = this.orderId;
              pa.Service = this.service;
              this.isLoading = true;
              OrderDepartureAppService.GetAdd(pa).then((add) => {
                if (add.data.Data && add.data.Data.IsTax == false) {
                  var c1 = new DtoRequestClilentCompany();
                  c1.OrderId = this.orderId;
                  c1.Service = this.service;
                  OrderDepartureAppService.GetClientCompany(c1).then(
                    (company) => {
                      this.isLoading = false;
                      if (company.data.Data)
                        this.clientCompanys = company.data.Data;
                      this.addRuleFormVisible = true;
                      this.addRuleForm.Data.IsTax = undefined;
                      this.addRuleForm.Rules.ClientCompanyId = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "blur",
                        },
                      ];
                      this.addRuleForm.Rules.OrderPoolId = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "change",
                        },
                      ];
                      this.addRuleForm.Rules.IsTax = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "change",
                        },
                      ];
                    }
                  );
                } else if (add.data.Data && add.data.Data.IsTax == true) {
                  var param = {
                    OrderId: this.orderId,
                    Service: this.service,
                    IsTax: false,
                    ClientCompanyId: undefined,
                    OrderPoolId: undefined,
                  };
                  OrderDepartureAppService.Post(param).then(() => {
                    this.isLoading = false;
                    ElMessage.success("添加成功");
                    this.handleGet();
                  });
                }
              });
            } else {
              var pa1 = new DtoRequestAdd();
              pa1.OrderId = this.orderId;
              pa1.Service = this.service;
              this.isLoading = true;
              OrderDepartureAppService.GetAdd(pa1).then((add) => {
                if (
                  add.data.Data &&
                  add.data.Data.ClearCustomsHeader ==
                  EnumCustomsClearanceHeaderProvider.Client
                ) {
                  var c2 = new DtoRequestClilentCompany();
                  c2.OrderId = this.orderId;
                  c2.Service = this.service;
                  OrderDepartureAppService.GetClientCompany(c2).then(
                    (company) => {
                      this.isLoading = false;
                      if (company.data.Data)
                        this.clientCompanys = company.data.Data;
                      this.addRuleFormVisible = true;
                      this.addRuleForm.Data.IsTax = undefined;
                      this.addRuleForm.Rules.ClientCompanyId = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "blur",
                        },
                      ];
                      this.addRuleForm.Rules.OrderPoolId = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "change",
                        },
                      ];
                      this.addRuleForm.Rules.ClearCustomsHeader = [
                        {
                          required: true,
                          message: "信息不能为空",
                          trigger: "change",
                        },
                      ];
                    }
                  );
                } else if (
                  add.data.Data &&
                  add.data.Data.ClearCustomsHeader ==
                  EnumCustomsClearanceHeaderProvider.ServiceProvider
                ) {
                  var param = {
                    OrderId: this.orderId,
                    Service: this.service,
                    ClearCustomsHeader:
                      EnumCustomsClearanceHeaderProvider.ServiceProvider,
                    ClientCompanyId: undefined,
                    OrderPoolId: undefined,
                  };
                  OrderDepartureAppService.Post(param).then(() => {
                    this.isLoading = false;
                    ElMessage.success("添加成功");
                    this.handleGet();
                  });
                }
              });
            }
          }
        }
      }
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderDeparture,
    complete: () => void
  ): void {
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        OrderDepartureAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.handleGet();
  }

  cellClassName(row: any) {
    if (
      this.list.Data &&
      this.list.Data.AddDeparturePortDeclareCustoms == true &&
      this.task == "AddDeparturePortDeclareCustoms"
    ) {
      if (
        row.column.property == "Supplier" &&
        (row.row.Supplier == null ||
          row.row.Supplier == undefined ||
          row.row.Supplier == "")
      ) {
        return {
          "text-align": "center",
          border: "1px solid red",
          "z-index": "1000",
        };
      }
      if (row.column.property == "AnnexString" && row.row.IsTax == true) {
        var flag = false;
        if (this.list.Data && this.list.Data.Items) {
          for (var it of this.list.Data.Items) {
            if (it.Id == row.row.Id) {
              if (
                it.OrderDepartureAnnexs != null &&
                it.OrderDepartureAnnexs != undefined &&
                it.OrderDepartureAnnexs.length > 0
              ) {
                for (var an of it.OrderDepartureAnnexs) {
                  if (an.Type == 1) {
                    flag = true;
                  }
                }
              }
            }
          }
        }
        if (flag == false) {
          return {
            "text-align": "center",
            border: "1px solid red",
            "z-index": "1000",
          };
        }
      }
    }

    if (
      this.list.Data &&
      this.list.Data.FeedbackDeparturePortDeclareCustoms == true &&
      this.task == "FeedbackDeparturePortDeclareCustoms"
    ) {
      if (
        row.column.property == "ReleaseTimeString" &&
        (row.row.ReleaseTimeString == null ||
          row.row.ReleaseTimeString == undefined ||
          row.row.ReleaseTimeString == "")
      ) {
        return {
          "text-align": "center",
          border: "1px solid red",
          "z-index": "1000",
        };
      }

      if (row.column.property == "AnnexString" && row.row.IsTax == true) {
        //var flag1 = false;
        //if (this.list.Data && this.list.Data.Items) {
        //    for (var it1 of this.list.Data.Items) {
        //        if (it1.Id == row.row.Id) {
        //            if (it1.OrderDepartureAnnexs != null && it1.OrderDepartureAnnexs != undefined && it1.OrderDepartureAnnexs.length > 0) {
        //                var count1 = 0;
        //                var count2 = 0;
        //                for (var an1 of it1.OrderDepartureAnnexs) {
        //                    if (an1.Type == 20) {
        //                        count2++;
        //                    }
        //                    else if (an1.Type == 10) {
        //                        count1++;
        //                    }
        //                }
        //                if (count1 > 0 && count2 > 0) {
        //                    flag1 = true;
        //                }
        //            }
        //        }
        //    }
        //}
        //if (flag1 == false) {
        //    return {
        //        "text-align": 'center',
        //        "border": "1px solid red",
        //        "z-index": "1000",
        //    }
        //}

        var flag11 = false;
        if (this.list.Data && this.list.Data.Items) {
          for (var it11 of this.list.Data.Items) {
            if (it11.Id == row.row.Id && it11.Type == 10) {
              if (
                it11.OrderDepartureAnnexs != null &&
                it11.OrderDepartureAnnexs != undefined &&
                it11.OrderDepartureAnnexs.length > 0
              ) {
                var count11 = 0;
                var count21 = 0;
                for (var an11 of it11.OrderDepartureAnnexs) {
                  if (an11.Type == 20) {
                    count21++;
                  } else if (an11.Type == 10) {
                    count11++;
                  }
                }
                if (count11 > 0 && count21 > 0) {
                  flag11 = true;
                }
              }
            }
          }
        }
        if (flag11 == false && row.row.Type == 10) {
          return {
            "text-align": "center",
            border: "1px solid red",
            "z-index": "1000",
          };
        }
      }
    }

    if (
      this.list.Data &&
      this.list.Data.AddDestinationPortClearCustoms == true &&
      this.task == "AddDestinationPortClearCustoms"
    ) {
      if (
        row.column.property == "Supplier" &&
        (row.row.Supplier == null ||
          row.row.Supplier == undefined ||
          row.row.Supplier == "")
      ) {
        return {
          "text-align": "center",
          border: "1px solid red",
          "z-index": "1000",
        };
      }

      if (row.column.property == "AnnexString") {
        var flag2 = false;
        if (this.list.Data && this.list.Data.Items) {
          for (var it2 of this.list.Data.Items) {
            if (it2.Id == row.row.Id) {
              if (
                it2.OrderDepartureAnnexs != null &&
                it2.OrderDepartureAnnexs != undefined &&
                it2.OrderDepartureAnnexs.length > 0
              ) {
                for (var an2 of it2.OrderDepartureAnnexs) {
                  if (an2.Type == 1) {
                    flag2 = true;
                  }
                }
              }
            }
          }
        }
        if (flag2 == false) {
          return {
            "text-align": "center",
            border: "1px solid red",
            "z-index": "1000",
          };
        }
      }
    }

    if (
      this.list.Data &&
      this.list.Data.FeedbackDestinationPortClearCustoms == true &&
      this.task == "FeedbackDestinationPortClearCustoms"
    ) {
      if (
        row.column.property == "ReleaseTimeString" &&
        (row.row.ReleaseTimeString == null ||
          row.row.ReleaseTimeString == undefined ||
          row.row.ReleaseTimeString == "")
      ) {
        return {
          "text-align": "center",
          border: "1px solid red",
          "z-index": "1000",
        };
      }

      if (row.column.property == "AnnexString") {
        var flag10 = false;
        if (this.list.Data && this.list.Data.Items) {
          for (var it10 of this.list.Data.Items) {
            if (it10.Id == row.row.Id && it10.Type == 20) {
              if (
                it10.OrderDepartureAnnexs != null &&
                it10.OrderDepartureAnnexs != undefined &&
                it10.OrderDepartureAnnexs.length > 0
              ) {
                var count10 = 0;
                var count20 = 0;
                for (var an10 of it10.OrderDepartureAnnexs) {
                  if (an10.Type == 20) {
                    count20++;
                  } else if (an10.Type == 10) {
                    count10++;
                  }
                }
                if (count10 > 0 && count20 > 0) {
                  flag10 = true;
                }
              }
            }
          }
        }
        if (flag10 == false && row.row.Type == 20) {
          return {
            "text-align": "center",
            border: "1px solid red",
            "z-index": "1000",
          };
        }
      }

      //if (row.column.property == "AnnexString") {
      //    var flag3 = false;
      //    if (this.list.Data && this.list.Data.Items) {
      //        for (var it3 of this.list.Data.Items) {
      //            if (it3.Id == row.row.Id) {
      //                if (it3.OrderDepartureAnnexs != null && it3.OrderDepartureAnnexs != undefined && it3.OrderDepartureAnnexs.length > 0) {
      //                    var count3 = 0;
      //                    var count4 = 0;
      //                    for (var an3 of it3.OrderDepartureAnnexs) {
      //                        if (an3.Type == 20) {
      //                            count3++;
      //                        }
      //                        else if (an3.Type == 10) {
      //                            count4++;
      //                        }
      //                    }
      //                    if (count3 > 0 && count4 > 0) {
      //                        flag3 = true;
      //                    }
      //                }
      //            }
      //        }
      //    }
      //    if (flag3 == false) {
      //        return {
      //            "text-align": 'center',
      //            "border": "1px solid red",
      //            "z-index": "1000",
      //        }
      //    }

      //}
    }

    return {
      "text-align": "center",
    };
  }
}

/*去除字符串中的所有空格 */
function TrimSpace(str: string) {
  var name = "";
  if (str != undefined && str != "") {
    for (var i = 0; i < str.length; i++) {
      if (str[i] == "　") {
        name += " ";
      } else {
        name += str[i];
      }
    }
    //全角空格转换成半角空格
    str = name;
    name = "";

    for (var j = 0; j < str.length; j++) {
      if (str[j] != " ") {
        name += str[j];
      }
    }
  }
  return name;
}
