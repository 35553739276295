
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import CompanyBusinessCardPageList from "../../CompanyBusinessCard/CompanyBusinessCardPageList.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyBusinessCard from "@/models.machine/DtoPageListSearchCompanyBusinessCard";
import DtoListItemCompanyBusinessCard from "@/models.machine/DtoListItemCompanyBusinessCard";
import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";

@Options({
  components: {
    CompanyBusinessCardPageList,
  },
})
export default class CustomerCompanyDomesticNotifier extends Vue {
  title = "境内通知人";
  area = EnumCompanyBusinessCardArea.Domestic;
  type = EnumCompanyBusinessCardType.Notifier;
  list = new MiezzPageList<
    DtoPageListSearchCompanyBusinessCard,
    DtoListItemCompanyBusinessCard,
    string
  >("CustomerManagement_Company_DomesticNotifier");

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CustomerManagement_Company_DomesticNotifier_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_Company_DomesticNotifier_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CustomerManagement_Company_DomesticNotifier_Delete",
      },
    ];
  }
}
