
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoFormJoinCompanyUser, {
  DtoFormJoinCompanyUserMiezzFormItems,
} from "@/models.machine/DtoFormJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserForm from "../UserForm.vue";
@Options({
  components: {
    UserForm,
  },
})
export default class CustomerUserAdd extends Vue {
  type = EnumCompanyType.Customer;
  form = new MiezzForm<DtoFormJoinCompanyUser, string>(
    "CustomerManagement_User_Add"
  );
  companiesLabelKey = "NameZh/NameEn";
  trusteeship?: boolean;
  logier?: Logier;

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "添加用户",
        Power: "CustomerManagement_User_Add",
      },
    ];
    
    this.form.DefaultSpan = 12;
    this.logier = CurrentLogier.GetLogier();
    if (this.logier?.CompanyType == EnumCompanyType.Platform) {
      this.companiesLabelKey = "ServiceProviderCompanyNameZh/NameZh";
    }
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.trusteeship = true;
      this.form.Items = DtoFormJoinCompanyUserMiezzFormItems.copy().filter(
        (it) =>
          it.Prop != "JoinCompanyUserGroups" &&
          it.Prop != "Job" &&
          it.Prop != "Level" &&
          it.Prop != "Status" &&
          it.Prop != "UserStatus" &&
          it.Prop != "Avatar" &&
          it.Prop != "JoinCompanyUserContacts"
      );
    } else {
      this.form.Items = DtoFormJoinCompanyUserMiezzFormItems.copy();
    }
  }

  @Watch("form.Data")
  onFormDataChanged(): void {
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.form.Data.UiGroups?.remove((it) => it.Code == "ContactNumber");
    }
  }
}
