
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ParamTaxTypeAppService from "@/apis.machine/ParamTaxTypeAppService";
import DtoPageListSearchParamTaxType, {
  DtoPageListSearchParamTaxTypeDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchParamTaxType";
import DtoPageListParamTaxType from "@/models.machine/DtoPageListParamTaxType";
import DtoListItemParamTaxType, {
  DtoListItemParamTaxTypeMiezzListItems,
} from "@/models.machine/DtoListItemParamTaxType";
import ParamTaxTypeDetail from "./ParamTaxTypeDetail.vue";
import ParamTaxTypeForm from "./ParamTaxTypeForm.vue";
import router from "@/router";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import MiezzFileUpload from "@/components/MiezzFileUpload.vue";
import MiezzAlertLog from "@/models/MiezzAlertLog";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";

@Options({
  components: {
    ParamTaxTypeDetail,
    ParamTaxTypeForm,
  },
})
export default class ParamTaxTypePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchParamTaxType,
    DtoListItemParamTaxType,
    string
  >("System_ParamList_TaxType");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();
  
  modalImportLog = new MiezzModal();
  importLogs: MiezzAlertLog[] = [];
  importRate = 0;
  logier?: Logier;

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    this.list.Back = true;
    this.list.Title = "纳税人类型";
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchParamTaxType();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchParamTaxTypeDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListParamTaxType();
    //列表-列配置
    this.list.Items = DtoListItemParamTaxTypeMiezzListItems;
    

    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "ParamCountry") {
        return `${row.ParamCountry?.NameZh}/${row.ParamCountry?.NameEn}`;
      }
      return undefined;
    };
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "exportImportTemplate",
        Label: "下载模板",
        Type: "primary",
        Size: "small",
        PowerCode: "System_ParamList_TaxType_Import",
        Width: "65px"
      },
      {
        Code: "import",
        Label: "导入",
        Type: "primary",
        Size: "small",
        FileUpload: true,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PowerCode: "System_ParamList_TaxType_Import",
        Width: "65px"
      },
      {
        Code: "export",
        Label: "导出",
        Type: "primary",
        Size: "small",
        PowerCode: "System_ParamList_TaxType_Export",
        Width: "65px"
      },
      {
        Code: "add",
        Label: "添加",
        Type: "primary",
        Size: "small",
        PowerCode: "System_ParamList_TaxType_AddEdit",
        Width: "65px"
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "warning",
        Size: "small",
        PowerCode: "System_ParamList_TaxType_AddEdit",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        PowerCode: "System_ParamList_TaxType_Delete",
      },
      {
        Code: "batch-export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "System_ParamList_TaxType_Export",
      },
    ];

     this.list.HandleSelectable = (row) => {
            if (row.IsEdit) return row.IsEdit;
            else return false;
        }

        this.list.CheckRowButtonShow = (btn, row) => {
            if (btn.Code == "edit" || btn.Code == "drag-sort") {
                if (row.IsEdit == true) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }

        };
  }

  /**查询列表 */
  handleGet(): void {
    ParamTaxTypeAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    ParamTaxTypeAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.id = undefined;
        this.modalForm.Show = true;
        this.modalForm.Title = `新增纳税人类型`;
        complete();
        break;
      //导出
      case "export":
        ParamTaxTypeAppService.PostExportExceByName("纳税人类型", []).then((r) => {
          window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
          complete();
        });
        break;
      //导入
      case "import":
        complete();
        break;
      //导入导入模板
      case "exportImportTemplate":
        this.handleTemplate();
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemParamTaxType,
    complete: () => void
  ): void {
    if (row.Id) this.id = row.Id;
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `纳税人类型详细`;
        complete();
        break;
      //编辑
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑纳税人类型`;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ElMessageBox.confirm('是否确认将该信息删除？', '提醒', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          ParamTaxTypeAppService.Delete(this.list.SelectIds)
            .then(() => {
              this.handleGet();
              complete();
            })
            .catch(complete);
        })
        .catch(() => {
          complete();
        });
        break;
      //批量导出
      case "batch-export":
        ParamTaxTypeAppService.PostExportExceByName("纳税人类型", this.list.SelectIds).then((r) => {
          window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
          complete();
        })
        .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }

  handleTemplate(): void {
    ParamTaxTypeAppService.GetExportImportTemplate().then((r) => {
      window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
    });
  }

  @Emit()
  handleTopButtonUpload(model: MiezzButton, file: any): void {
    this.modalImportLog.Show = true;
    this.importLogs = [];
    this.importRate = 0;
    CurrentLogier.HubConnection?.off("AlertLog");
    CurrentLogier.HubConnection?.off("AlertProgress");
    CurrentLogier.HubConnection?.on(
      "AlertLog",
      (
        message: string,
        type: "success" | "warning" | "info" | "error",
        effect: "dark" | "light"
      ) => {
        this.importLogs.unshift({
          Message: message,
          Type: type,
          Effect: effect,
        });
      }
    );
    CurrentLogier.HubConnection?.on(
      "AlertProgress",
      (current: number, total: number) => {
        this.importRate = total == 0 ? 100 : (current / total) * 100;
      }
    );
    const formData = new FormData();
    formData.append("file", file);
    ParamTaxTypeAppService.PostImport(formData).then((r) => {
      if (r.data.Data) 
        ElMessage.success("导入成功");
      this.handleGet();
    });
  }
}
