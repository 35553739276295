import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumGender, EnumGenderOption } from "./EnumGender";
import UiGroup from "./UiGroup";

/**
 * 平台管理表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPlatformCompany extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 平台中文名称
     */
    CompanyNameZh?: string;
    /**
     * 平台英文名称
     */
    CompanyNameEn?: string;
    /**
     * 管理员手机号码
     */
    UserPhoneNumber?: string;
    /**
     * 管理员姓名
     */
    UserName?: string;
    /**
     * 管理员性别
     */
    UserGender?: EnumGender;
}

/**
 * 平台管理表单-表单校验
 */
export const DtoFormPlatformCompanyRules = {
    CompanyNameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    CompanyNameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    UserPhoneNumber: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
                    return callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            }
        },
    ],
    UserName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 平台管理表单-设置项
 */
export const DtoFormPlatformCompanyMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CompanyNameZh",
    Label: "平台中文名称",
    Customize: true,
  },
  {
    Prop: "CompanyNameEn",
    Label: "平台英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "UserPhoneNumber",
    Label: "管理员手机号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "UserName",
    Label: "管理员姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "UserGender",
    Label: "管理员性别",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumGenderOption,
  },
]
