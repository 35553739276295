
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import PriceServiceDetail from "../PriceServiceDetail.vue";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailPriceServiceRemark, {
  DtoDetailPriceServiceRemarkMiezzDetailItems,
  DtoDetailPriceServiceRemarkRules,
} from "@/models.machine/DtoDetailPriceServiceRemark";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import MiezzButton from "@/models/MiezzButton";
import CurrentLogier from "@/apis/CurrentLogier";
import Pipe from "@/apis/Pipe";
import { EnumTransportDirection, EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import OSS from "ali-oss";
import AliyunOSS from "@/apis/AliyunOSS";
@Options({
  components: {
    PriceServiceDetail,
  },
})
export default class PriceServiceDetailRemark extends Vue {
  client?: OSS;
  id?: string;
  detail = new MiezzForm<DtoDetailPriceServiceRemark, string>(
    "PriceManagement_SetUp_Service_Detail_Remark"
  );
  menuTab: MiezzMenuTab = {
    Back: true,
  };
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "edit",
      Label: "编辑",
      Icon: "edit",
      Type: "text",
      Size: "small",
      PowerCode: "PriceManagement_SetUp_Service_Detail_Remark_EditRemark",
    },
  ];
  type = "";
  EnumTransportDirection = EnumTransportDirection;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportType = EnumTransportType;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  EnumCustomsClearanceHeaderProvider = EnumCustomsClearanceHeaderProvider;
  destinationPortCustomsClearanceHeaderProvider?: string = "";

  created(): void {
    this.id = this.$route.params.id as string;
    //表单-数据-初始化
    this.detail.Data = new DtoDetailPriceServiceRemark();
    //表单-校验
    this.detail.Rules = DtoDetailPriceServiceRemarkRules;
    //表单-元素配置
    this.detail.Items = DtoDetailPriceServiceRemarkMiezzDetailItems;
    for (const item of this.detail.Items) {
      switch (item.Prop) {
        case "ValidDateStart":
          item.HandleFormat = () => {
            return Pipe.GetDateRange(
              this.detail.Data.ValidDateStart,
              this.detail.Data.ValidDateEnd
            );
          };
          break;
        default:
          break;
      }
    }
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    this.client = AliyunOSS.GetClient();
    if (this.id && this.id != "undefined") {
      PriceServiceAppService.GetRemarkById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;

          if (this.detail.Data.Files) {
            for (var img of this.detail.Data.Files) {
              if (img.File)
                img.File = this.client?.signatureUrl(img.File) as string;
            }
          }

          if (
            this.detail.Data.TransportDirection &&
            this.detail.Data.TransportType
          ) {
            const direction = this.EnumTransportDirectionOption.firstOrDefault(
              (it) => it.Value == this.detail.Data.TransportDirection
            )?.Label;
            const type = this.EnumTransportTypeOption.firstOrDefault(
              (it) => it.Value == this.detail.Data.TransportType
            )?.Label;
            this.type = `【${direction}】【${type}】`;
            if (this.detail.Data.TransportContainerLoad) {
              const containerLoad =
                this.EnumTransportContainerLoadOption.firstOrDefault(
                  (it) => it.Value == this.detail.Data.TransportContainerLoad
                )?.Label;
              this.type += `【${containerLoad}】`;
            }
          } else {
            this.type = "暂无数据";
          }

          this.destinationPortCustomsClearanceHeaderProvider =
            EnumCustomsClearanceHeaderProviderOption.firstOrDefault(
              (it) =>
                it.Value ==
                this.detail.Data.DestinationPortCustomsClearanceHeaderProvider
            )?.Label;
        }
      });
    }
  }

  /**按钮点击 */
  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      case "edit":
        CurrentLogier.LinkWithBack(
          `/price/setup/service/detail/${this.id}/remark/edit`
        );
        complete();
        break;
      default:
        break;
    }
  }
}
