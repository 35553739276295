import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoFormOrder_Supplier from "./DtoFormOrder_Supplier";
import { EnumTransportPaymentType, EnumTransportPaymentTypeOption } from "./EnumTransportPaymentType";
import { EnumGoodsTransportType, EnumGoodsTransportTypeOption } from "./EnumGoodsTransportType";
import DtoFormOrderContainer from "./DtoFormOrderContainer";
import DtoFormOrderCost from "./DtoFormOrderCost";
import { EnumLadingBillType, EnumLadingBillTypeOption } from "./EnumLadingBillType";
import { EnumLadingBillNumber, EnumLadingBillNumberOption } from "./EnumLadingBillNumber";
import DtoFormOrder_OrderFile from "./DtoFormOrder_OrderFile";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrder extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Sure = false;
        this.Files = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 确认订舱信息
     */
    Sure?: boolean;
    /**
     * 有无分单信息
     */
    HasPartBill?: boolean;
    /**
     * 发货人Id
     */
    ShipperId?: string;
    /**
     * 发货人名称
     */
    ShipperName?: string;
    /**
     * 发货人内容
     */
    ShipperContent?: string;
    /**
     * 收货人Id
     */
    ConsigneeId?: string;
    /**
     * 收货人名称
     */
    ConsigneeName?: string;
    /**
     * 收货人内容
     */
    ConsigneeContent?: string;
    /**
     * 通知人Id
     */
    NotifyPartyId?: string;
    /**
     * 通知人名称
     */
    NotifyPartyName?: string;
    /**
     * 通知人内容
     */
    NotifyPartyContent?: string;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 类型
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商名称
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 供应商模态框数据
     */
    SupplierModalData?: DtoFormOrder_Supplier;
    /**
     * Port of  Loading/装货港Id
     */
    LoadingPortId?: string;
    /**
     * Port of  Loading/装货港
     */
    LoadingPortLabel?: string;
    /**
     * Port of Discharge/卸货港Id
     */
    DischargePortId?: string;
    /**
     * Port of Discharge/卸货港
     */
    DischargePortLabel?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * Port of Delivery/目的港
     */
    DeliveryPortLabel?: string;
    /**
     * Place of Delivery/交货地点
     */
    DeliveryPlaceLabel?: string;
    /**
     * 详细地址
     */
    DeliveryPlaceAddress?: string;
    /**
     * 承运人Id
     */
    CarrierId?: string;
    /**
     * 承运人
     */
    CarrierLabel?: string;
    /**
     * 渠道Id
     */
    TransportChannelId?: string;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 合约号
     */
    ContractNo?: string;
    /**
     * 船名
     */
    ShipName?: string;
    /**
     * 航次
     */
    VoyageNumber?: string;
    /**
     * 航班
     */
    Flight?: string;
    /**
     * 班次
     */
    RailwayNumber?: string;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 运费支付方式
     */
    TransportPaymentType?: EnumTransportPaymentType;
    /**
     * 运输类型
     */
    GoodsTransportType?: EnumGoodsTransportType;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 唛头
     */
    MarksNotes?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 装运包装与数量
     */
    PackagesNumberKind?: string;
    /**
     * 品名
     */
    GoodsDescription?: string;
    /**
     * 品名备注
     */
    GoodsDescriptionRemark?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 集装箱信息
     */
    Containers?: DtoFormOrderContainer[];
    /**
     * 费用信息
     */
    Costs?: DtoFormOrderCost[];
    /**
     * 提单类型
     */
    LadingBillType?: EnumLadingBillType;
    /**
     * 提单份数
     */
    LadingBillNumber?: EnumLadingBillNumber;
    /**
     * 备注
     */
    LadingBillRemark?: string;
    /**
     * 附件
     */
    Files?: DtoFormOrder_OrderFile[];
}

/**
 * -表单校验
 */
export const DtoFormOrderRules = {
    ShipperName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ShipperContent: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeContent: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyContent: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    TransportDirection: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    SupplierCompanyTypeLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    SupplierCompanyLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    SupplierCompanyContactLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    LoadingPortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DischargePortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DeliveryPortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DeliveryPlaceLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DeliveryPlaceAddress: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    CarrierLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    TransportChannelLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    ContractNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ShipName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    VoyageNumber: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Flight: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    RailwayNumber: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    PackagesKind: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    PackagesNumberKind: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    GoodsDescription: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    GoodsDescriptionRemark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Sure",
    Label: "确认订舱信息",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "HasPartBill",
    Label: "有无分单信息",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperId",
    Label: "发货人Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperName",
    Label: "发货人名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperContent",
    Label: "发货人内容",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "ConsigneeId",
    Label: "收货人Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeName",
    Label: "收货人名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeContent",
    Label: "收货人内容",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "NotifyPartyId",
    Label: "通知人Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyName",
    Label: "通知人名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyContent",
    Label: "通知人内容",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "TransportDirection",
    Label: "运输方向",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportDirectionOption,
  },
  {
    Prop: "TransportType",
    Label: "运输类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportTypeOption,
  },
  {
    Prop: "TransportContainerLoad",
    Label: "集装箱装载方式",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportContainerLoadOption,
  },
  {
    Prop: "SupplierSource",
    Label: "来源",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumSupplierSourceOption,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "类型",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SupplierCompanyTypeLabel",
    Label: "供应商类型",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "联系人",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SupplierModalData",
    Label: "供应商模态框数据",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LoadingPortId",
    Label: "Port of  Loading/装货港Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "Port of  Loading/装货港",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "DischargePortId",
    Label: "Port of Discharge/卸货港Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DischargePortLabel",
    Label: "Port of Discharge/卸货港",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "DeliveryPortId",
    Label: "Port of Delivery/目的港Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "Port of Delivery/目的港",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "Place of Delivery/交货地点",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "DeliveryPlaceAddress",
    Label: "详细地址",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "CarrierId",
    Label: "承运人Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CarrierLabel",
    Label: "承运人",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "TransportChannelId",
    Label: "渠道Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TransportChannelLabel",
    Label: "渠道",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "ContractNo",
    Label: "合约号",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipName",
    Label: "船名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "VoyageNumber",
    Label: "航次",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Flight",
    Label: "航班",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "RailwayNumber",
    Label: "班次",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TransportPaymentType",
    Label: "运费支付方式",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportPaymentTypeOption,
  },
  {
    Prop: "GoodsTransportType",
    Label: "运输类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumGoodsTransportTypeOption,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PackagesNumber",
    Label: "装运数量",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PackagesKind",
    Label: "装运包装",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "PackagesNumberKind",
    Label: "装运包装与数量",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "GoodsDescription",
    Label: "品名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GoodsDescriptionRemark",
    Label: "品名备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Volume",
    Label: "尺码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Containers",
    Label: "集装箱信息",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Costs",
    Label: "费用信息",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LadingBillType",
    Label: "提单类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumLadingBillTypeOption,
  },
  {
    Prop: "LadingBillNumber",
    Label: "提单份数",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumLadingBillNumberOption,
  },
  {
    Prop: "LadingBillRemark",
    Label: "备注",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Files",
    Label: "附件",
    Type: EnumMiezzFormItemType.InputText,
  },
]
