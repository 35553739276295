import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemParamCompanyCodeType_ParamCountry from "./DtoListItemParamCompanyCodeType_ParamCountry";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 企业代码类型分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamCompanyCodeType extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 国家Id
     */
    ParamCountryId?: string;
    /**
     * 国家
     */
    ParamCountry?: DtoListItemParamCompanyCodeType_ParamCountry;
    /**
     * 国家代码
     */
    CountryCode?: string;
    /**
     * 企业代码类型
     */
    CompanyCode?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 企业代码类型分页列表-项-表单校验
 */
export const DtoListItemParamCompanyCodeTypeRules = {
}

/**
 * 企业代码类型分页列表-项-设置项
 */
export const DtoListItemParamCompanyCodeTypeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ParamCountry",
    Label: "国家",
  },
  {
    Prop: "CountryCode",
    Label: "国家代码",
  },
  {
    Prop: "CompanyCode",
    Label: "企业代码类型",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
]
