
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumAuditStatus } from "@/models.machine/EnumAuditStatus";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../CompanyDetail.vue";
import { CompanySetting } from "../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class CurrentCompanyDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompany, string>("CompanyManagement_Company");
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];
  nameEnAbbr = false;

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/org/company/detail`;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/org/company/detail?page=BasicInfo`,
        Label: "公司信息",
        Power: "CompanyManagement_Company",
      },
    ];

    this.detail.Items = DtoDetailCompanyMiezzDetailItems.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "SalesmanLabel" &&
        it.Prop != "Source" &&
        it.Prop != "BusinessScope" &&
        it.Prop != "Remark"
    );

    const logier = CurrentLogier.GetLogier();
    if (logier) {
      this.nameEnAbbr = logier.CompanyType == EnumCompanyType.ServiceProvider;
      // if (
      //   logier.CompanyType != EnumCompanyType.Customer ||
      //   !logier.ServiceProviderCompanyId
      // ) {
      //   this.buttons = [
      //     {
      //       Code: "edit",
      //       Label: "编辑",
      //       Icon: "edit",
      //       Type: "text",
      //       Size: "small",
      //       PowerCode: "CompanyManagement_Company_Edit",
      //     },
      //   ];
      // }
      CurrentLogier.GetMenus().then((r) => {
        const powers = r?.Menus?.map((it) => it.Code) ?? [];
        if (
          (powers.contains("CompanyManagement_Company_Edit") &&
            logier.CompanyType != EnumCompanyType.Customer) ||
          !logier.ServiceProviderCompanyId
        ) {
          this.buttons = [
            {
              Code: "edit",
              Label: "编辑",
              Icon: "edit",
              Type: "text",
              Size: "small",
              // PowerCode: "CompanyManagement_Company_Edit",
            },
          ];
        }
        if (
          powers.contains("CompanyManagement_Company_Auth") &&
          (logier.CompanyType == EnumCompanyType.ServiceProvider ||
            (logier.CompanyType == EnumCompanyType.Customer &&
              !logier.ServiceProviderCompanyId))
        ) {
          this.settings.push({ Name: "企业认证", Url: "/org/company/auth" });
          if (logier.AuthStatus == EnumAuditStatus.Success) {
            this.settings.push({
              Name: "主体关联",
              Url: "/org/company/main-relation",
            });
          }
        }
        if (powers.contains("CompanyManagement_Company_DomesticShipper")) {
          this.settings.push({
            Name: "境内发货人",
            Url: "/org/company/domestic/shipper/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_DomesticConsignee")) {
          this.settings.push({
            Name: "境内收货人",
            Url: "/org/company/domestic/consignee/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_OverseasShipper")) {
          this.settings.push({
            Name: "境外发货人",
            Url: "/org/company/overseas/shipper/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_OverseasConsignee")) {
          this.settings.push({
            Name: "境外收货人",
            Url: "/org/company/overseas/consignee/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_ProductionSaleUnit")) {
          this.settings.push({
            Name: "生产销售单位",
            Url: "/org/company/product-sale-unit/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_ConsumptionUseUnit")) {
          this.settings.push({
            Name: "消费使用单位",
            Url: "/org/company/consumption-use-unit/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_Invoice")) {
          this.settings.push({
            Name: "开票资料",
            Url: "/org/company/invoice/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_CodeRule")) {
          this.settings.push({
            Name: "编号规则",
            Url: "/org/company/code-rule/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_OvertimeRule")) {
          this.settings.push({
            Name: "待办时效规则",
            Url: "/org/company/overtime-rule/${id}",
          });
        }
        if (powers.contains("CompanyManagement_Company_BillOfLadingTemplate")) {
          this.settings.push({
            Name: "提单模板",
            Url: "/org/company/bill-of-lading-template/${id}",
          });
        }
        if (
          powers.contains(
            "CompanyManagement_Company_RulesForBillingWeightvolume"
          )
        ) {
          this.settings.push({
            Name: "计费重量/体积规则",
            Url: "/org/company/charge/${id}",
          });
        }
        if (this.settings.length > 0) {
          this.menuTab.Menus?.push({
            Url: `/org/company/detail?page=Setting`,
            Label: "相关参数",
            Power: "CompanyManagement_Company",
          });
        }
      });
    }
  }
}
