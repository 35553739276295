
import MenuAppService from "@/apis.machine/MenuAppService";
import PowerAppService from "@/apis.machine/PowerAppService";
import TranslateAppService from "@/apis.machine/TranslateAppService";
import WebApiAppService from "@/apis.machine/WebApiAppService";
import DtoFormPower, {
  DtoFormPowerMiezzFormItems,
  DtoFormPowerRules,
} from "@/models.machine/DtoFormPower";
import DtoTreeMenu from "@/models.machine/DtoTreeMenu";
import DtoTreeSearchMenu from "@/models.machine/DtoTreeSearchMenu";
import { EnumMenuScene } from "@/models.machine/EnumMenuScene";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import router from "@/router";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import ChooseMenu from "./ChooseMenu.vue";
@Options({
  components: {
    ChooseMenu,
  },
})
export default class PowerForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() parentId?: string;
  @Prop() path?: string;

  form = new MiezzForm<DtoFormPower, string>();
  webApiSelectOptions: UiSelectOption<string>[] = [];

  menuTree: DtoTreeMenu[] = [];

  created(): void {
    this.form.Data = new DtoFormPower();
    this.form.Rules = DtoFormPowerRules;
    this.form.Items = DtoFormPowerMiezzFormItems;

    if (process.env.NODE_ENV == "production") {
      this.form.Items = this.form.Items.filter((it) => it.Prop != "IsTest");
    }

    for (const item of this.form.Items) {
      if (item.Prop == "Path") {
        item.Type = EnumMiezzFormItemType.Text;
      }
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    WebApiAppService.GetSelect().then((r) => {
      if (r.data.Data) this.webApiSelectOptions = r.data.Data;
    });

    this.initData(this.getMenuTree);
  }

  initData(cb: () => void): void {
    if (this.id) {
      PowerAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
        else ElMessage.warning("权限不存在");
        cb();
      });
    } else {
      this.form.Data.ParentId = this.parentId;
      this.form.Data.Path = this.path;

      const radioSearch = router.currentRoute.value.query.Scene;
      if (radioSearch) {
        if (radioSearch == "1") {
          this.form.Data.Scene = EnumMenuScene.Platform;
        } else if (radioSearch == "2") {
          this.form.Data.Scene = EnumMenuScene.DeveloperAdmin;
        }
      }
      cb();
    }
  }

  getMenuTree(): void {
    const dto = new DtoTreeSearchMenu();
    dto.Scene = this.form.Data.Scene;
    dto.CheckIds = this.form.Data.MenuIds;
    MenuAppService.GetTree(dto).then((r) => {
      if (r.data.Data) {
        this.menuTree = r.data.Data;
      } else {
        this.menuTree = [];
      }
    });
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            PowerAppService.Post(this.form.Data)
              .then((r) => {
                this.handleSubmit();
                complete();
              })
              .catch(() => complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
