import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-账单分摊方式类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumBillShareType {
    /**
     * 按票数分摊
     */
    Number = 10,
    /**
     * 按入库毛重分摊
     */
    GrossWeight = 20,
    /**
     * 按入库体积分摊
     */
    Volume = 30,
    /**
     * 按入库计费重量分摊
     */
    Weight = 40,
}

/**
 * 枚举-账单分摊方式类型-选项
 */
export const EnumBillShareTypeOption: UiSelectOption<number>[] = [
    { Label: "按票数分摊", Value: 10, Data: "Number" },
    { Label: "按入库毛重分摊", Value: 20, Data: "GrossWeight" },
    { Label: "按入库体积分摊", Value: 30, Data: "Volume" },
    { Label: "按入库计费重量分摊", Value: 40, Data: "Weight" },
];
