
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import DtoDetailCompanyCreditTemplate from "@/models.machine/DtoDetailCompanyCreditTemplate";
    import DtoFormCompanyCreditAndAccountPeriod, {
        DtoFormCompanyCreditAndAccountPeriodMiezzFormItems,
        DtoFormCompanyCreditAndAccountPeriodRules,
    } from "@/models.machine/DtoFormCompanyCreditAndAccountPeriod";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import ElMessage from "element-plus/es/components/message";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import UiSelectOption from "../../../../models.machine/UiSelectOption";
    import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
    export default class CustomerCompanyCreditAndAccountingPeriodForm extends Vue {
        form = new MiezzForm<DtoFormCompanyCreditAndAccountPeriod, string>();
        id?: string;
        tabsIndex?: number = 0;
        dialogVisible?: boolean = false;
        dialogVisibleTitle?: string = "编辑记账起始时间";
        dialogVisibleAccountPeriod?: boolean = false;
        dialogVisibleTemplateName?: boolean = false;
        dialogVisibleTemplate?: boolean = false;

        /**添加按钮 */
        btnAdd: MiezzButton = {
            Code: "add",
            Label: "添加",
            Icon: "plus",
            Type: "text",
        };
        buttons: MiezzButton[] = [
            {
                Code: "back",
                MiezzIcon: "back",
                Label: "返回",
                Type: "text",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "保存",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
            {
                Code: "submit-template",
                Label: "保存为模板",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
            {
                Code: "template",
                Label: "模板",
                Icon: "document",
                Type: "text",
                Size: "small",
            },
        ];
        // 记账起始时间表单对象
        accountingStartDateForm: any = {
            isBatch: false,
            Index: null,
            AccountingStartDate: null,
            NextFewDays: null,
        };
        templatesFormRules: any = {
            Name: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var index = rule.field.split(".")[1];
                        var templatesJson = JSON.parse(
                            JSON.stringify(this.templatesForm.templates)
                        );
                        templatesJson.splice(index, 1);
                        var flag = false;
                        for (let i = 0; i < templatesJson.length; i++) {
                            const Name = templatesJson[i].Name;
                            if (Name == value.trim()) {
                                flag = true;
                            }
                        }
                        if (flag) {
                            callback(new Error("信息重复，请重新填写"));
                            return false;
                        } else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        };
        accountingStartDateFormRules: any = {
            NextFewDays: [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.accountingStartDateForm.AccountingStartDate == 2) {
                            if (value == null || value == "" || typeof value == "undefined") {
                                callback(new Error("选择业务发生后，天数不能为空"));
                                return false;
                            } else {
                                return callback();
                            }
                        } else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
        };
        // 账期表单对象
        accountPeriodDayForm: any = {
            accountPeriodDay: 0,
        };
        templatesForm: any = {
            templates: [],
        };
        // templates: Array<DtoDetailCompanyCreditTemplate> = [];
        menuTab = new MiezzMenuTab();
        loading = true;
        //费用名称
        costs: UiSelectOption<string>[] = [];
        allCosts: UiSelectOption<string>[] = [];
        EnumBusinessDate: any = [{
            Value: 0,
            Label: "ETD"
        }, {
            Value: 1,
            Label: "ATD"
        }, {
            Value: 2,
            Label: "ETA"
        }, {
            Value: 3,
            Label: "ATA"
        }]

        created(): void {
            this.menuTab.Url = this.$route.path;
            this.menuTab.Back = true;
            this.menuTab.Menus = [
                {
                    Url: this.menuTab.Url,
                    Label: "授信与账期",
                    Power: "CustomerManagement_Company_CreditAndAccountingPeriod_Edit",
                },
            ];
            this.id = this.$route.params.id as string;
            this.form.Data = new DtoFormCompanyCreditAndAccountPeriod();
            this.form.Items = DtoFormCompanyCreditAndAccountPeriodMiezzFormItems;
            this.form.Rules = DtoFormCompanyCreditAndAccountPeriodRules;
            this.form.Rules.Limit = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.OverdueInterest = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.TemplateName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        CompanyAppService.GetCreditTemplate().then((r) => {
                            if (r.data.Data) {
                                if (r.data.Data.some((it) => it.Name == value)) {
                                    callback(new Error("信息重复，请重新填写"));
                                    return false;
                                } else {
                                    return callback();
                                }
                            } else {
                                return callback();
                            }
                        });
                    },
                    trigger: "blur",
                },
            ];
            this.form.Rules.ContractCode = [
                {
                    max: 1024,
                    message: "文本长度不能超过 1024 个字符",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.form.Data.ContractCode) {
                            CompanyAppService.CheckContractCodeExist(this.form.Data)
                                .then((r) => {
                                    if (r.data.Data) {
                                        return callback(new Error("重复信息，请重新填写"));
                                    } else {
                                        callback();
                                    }
                                })
                                .catch(() => {
                                    callback();
                                });
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            this.handleGet();
        }
        //删除账期行
        onRowDelete(index: number): void {
            if (this.form.Data.AccountPeriods && this.form.Data.AccountPeriods.length > 0) {
                this.form.Data.AccountPeriods.splice(index, 1);
            }
        }
        // 添加账期行
        handleAdd(btn: MiezzButton, complete: () => void): void {
            if (this.form.Data.AccountPeriods && this.form.Data.AccountPeriods.length > 0) {
                this.form.Data.AccountPeriods.push({
                    AccountPeriodDay: undefined,
                    AccountingStartDate: undefined,
                    AccountingStartDateStr: "",
                    CostName: "",
                    Id: undefined,
                    NextFewDays: undefined,
                    Remarks: ""
                })
            }
            complete();
        }
        onSelectCosts(index: number): void {
            this.costs = [];
            if (this.form.Data.AccountPeriods && this.allCosts) {
                for (var cost of this.allCosts) {
                    var flag = false;
                    for (var i = 0; i < this.form.Data.AccountPeriods.length; i++) {
                        if (i != index) {
                            if (cost.Value == this.form.Data.AccountPeriods[i].CostId) {
                                flag = true;
                            }
                        }
                    }
                    if (flag == false) {
                        this.costs.push(JSON.parse(JSON.stringify(cost)));
                    }
                }

            }
        }
        onChangeCost(index: number): void {
            if (this.costs && this.form && this.form.Data && this.form.Data.AccountPeriods) {
                for (var it of this.costs) {
                    if (it.Value == this.form.Data.AccountPeriods[index].CostId) {
                        this.form.Data.AccountPeriods[index].CostName = it.Label;
                    }
                }
            }
        }
        handleGet(): void {
            this.loading = true;
            CompanyAppService.GetCreditAndAccountPeriodById(this.id).then((r) => {
                if (r.data.Data) {
                    this.form.Data = r.data.Data;
                    this.loading = false;
                }
            });
            ParamPriceCostAppService.GetSelect().then((cost) => {
                if (cost.data.Data) {
                    this.costs = cost.data.Data;
                    this.allCosts = JSON.parse(JSON.stringify(this.costs));
                }
            })
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            // console.log("this.form.Data", this.form.Data);
                            // complete();
                            this.loading = true;
                            CompanyAppService.PostCompanyCreditAndAccountPeriodByIsTemplateById(
                                this.form.Data,
                                false,
                                this.id
                            )
                                .then(() => {
                                    this.loading = false;
                                    CurrentLogier.Back();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "submit-template":
                    this.dialogVisibleTemplateName = true;
                    complete();
                    break;
                case "template":
                    CompanyAppService.GetCreditTemplate().then((r) => {
                        if (r.data.Data) {
                            this.templatesForm.templates = r.data.Data;
                            this.dialogVisibleTemplate = true;
                            complete();
                        }
                    });
                    break;
                case "back":
                    CurrentLogier.Back();
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangeAccountPeriodDayRow(index: number): void {
            if (this.form.Data) {
                var AccountPeriods = this.form.Data.AccountPeriods;

                if (
                    AccountPeriods &&
                    AccountPeriods.length > 0 &&
                    index != null
                ) {
                    var AccountPeriodDay = AccountPeriods[index].AccountPeriodDay;
                    var result = "";
                    if (AccountPeriodDay)
                        result = this.setDecimal(AccountPeriodDay.toString(), 0, false);
                    if (result != "") {
                        AccountPeriods[index].AccountPeriodDay = Number(result);
                    } else {
                        AccountPeriods[index].AccountPeriodDay = 0;
                    }
                }
            }
        }

        onChangeLimit(): void {
            var result = "";
            if (this.form.Data.Limit)
                result = this.setDecimal(this.form.Data.Limit.toString(), 2, false);
            if (result != "") {
                this.form.Data.Limit = Number(result);
            } else {
                this.form.Data.Limit = 0;
            }
        }

        onChangeOverdueInterest(): void {
            var result = "";
            if (this.form.Data.OverdueInterest)
                result = this.setDecimal(
                    this.form.Data.OverdueInterest.toString(),
                    2,
                    false
                );
            if (result != "") {
                this.form.Data.OverdueInterest = Number(result);
            } else {
                this.form.Data.OverdueInterest = 0;
            }
        }

        onChangeAccountPeriodDay(): void {
            var result = "";
            if (this.accountPeriodDayForm.AccountPeriodDay)
                result = this.setDecimal(
                    this.accountPeriodDayForm.AccountPeriodDay.toString(),
                    0,
                    false
                );
            if (result != "") {
                this.accountPeriodDayForm.AccountPeriodDay = Number(result);
            } else {
                this.accountPeriodDayForm.AccountPeriodDay = 0;
            }
        }

        onChangeNextFewDays(): void {
            var result = "";
            if (this.accountingStartDateForm.NextFewDays)
                result = this.setDecimal(
                    this.accountingStartDateForm.NextFewDays.toString(),
                    0,
                    false
                );
            if (result != "") {
                this.accountingStartDateForm.NextFewDays = Number(result);
            } else {
                this.accountingStartDateForm.NextFewDays = null;
            }
        }

        /**记账起始时间按下弹窗触发 */
        onFocus(index: number): void {
            this.accountingStartDateForm.Index = index;
            this.accountPeriodDayForm.isBatch = false;

            var AccountingStartDate = null;
            var NextFewDays = null;
            if (
                this.form.Data.AccountPeriods &&
                this.form.Data.AccountPeriods.length > 0 &&
                index != null
            ) {
                AccountingStartDate = this.form.Data.AccountPeriods[index].AccountingStartDate;
                NextFewDays = this.form.Data.AccountPeriods[index].NextFewDays;
            }

            if (AccountingStartDate) {
                this.accountingStartDateForm.AccountingStartDate = AccountingStartDate;
                if (AccountingStartDate == 2)
                    this.accountingStartDateForm.NextFewDays = NextFewDays;
            } else {
                // this.accountingStartDateForm.AccountingStartDate = 0;
            }
            this.dialogVisibleTitle = "编辑记账起始时间";
            this.dialogVisible = true;
        }

        /**打开批量编辑记账起始时间弹窗 */
        onBatchEditAccountingStartDate(): void {
            this.dialogVisibleTitle = "批量编辑记账起始时间";
            this.dialogVisible = true;
            this.accountPeriodDayForm.isBatch = true;
            // this.accountingStartDateForm.AccountingStartDate = 0;
        }

        /**选择记账起始时间 */
        onChangeAccountingStartDate(): void {
            this.$nextTick(() => {
                (this.$refs.refaccountingStartDateForm as any).clearValidate();
            });
            if (this.accountingStartDateForm.AccountingStartDate != 2) {
                this.accountingStartDateForm.NextFewDays = null;
            }
        }

        /**关闭记账起始时间弹窗 */
        handleClose(): void {
            this.dialogVisible = false;
            this.accountingStartDateForm = {
                isBatch: false,
                Index: null,
                AccountingStartDate: null,
                NextFewDays: null,
            };

            (this.$refs.refaccountingStartDateForm as any).clearValidate();
        }

        /**保存记账起始时间（批量编辑保存记账起始时间） */
        onSaveAccountingStartDate(): void {
            const refaccountingStartDateForm = this.$refs.refaccountingStartDateForm as HTMLFormElement;
            refaccountingStartDateForm.validate((v: any) => {
                if (v) {
                    var flag = true;
                    var index = this.accountingStartDateForm.Index;
                    var AccountingStartDateStr = "";
                    if (this.accountingStartDateForm.AccountingStartDate == 0) {
                        AccountingStartDateStr = "业务发生当日";
                    } else if (this.accountingStartDateForm.AccountingStartDate == 1) {
                        AccountingStartDateStr = "业务发生当月1号";
                    } else if (this.accountingStartDateForm.AccountingStartDate == 2) {
                        if (this.accountingStartDateForm.NextFewDays)
                            AccountingStartDateStr = "业务发生后" + this.accountingStartDateForm.NextFewDays + "天";
                        else {
                            ElMessage.warning("请输入业务发生后天数！");
                            flag = false;
                        }
                    }
                    if (flag) {
                        if (this.form.Data) {
                            var AccountPeriods = this.form.Data.AccountPeriods;
                            // 不是批量编辑记账起始时间
                            if (this.accountPeriodDayForm.isBatch == false) {
                                if (
                                    AccountPeriods &&
                                    AccountPeriods.length > 0 &&
                                    index != null
                                ) {
                                    AccountPeriods[index].NextFewDays = undefined;
                                    AccountPeriods[index].AccountingStartDate = this.accountingStartDateForm.AccountingStartDate;
                                    AccountPeriods[index].AccountingStartDateStr = AccountingStartDateStr;
                                    if (this.accountingStartDateForm.AccountingStartDate == 2) {
                                        AccountPeriods[index].NextFewDays = this.accountingStartDateForm.NextFewDays;
                                    }
                                }
                            }
                            // 批量编辑记账起始时间
                            else {
                                if (AccountPeriods && AccountPeriods.length > 0) {
                                    for (
                                        let i = 0;
                                        i < AccountPeriods.length;
                                        i++
                                    ) {
                                        const element = AccountPeriods[i];
                                        element.NextFewDays = undefined;
                                        element.AccountingStartDate = this.accountingStartDateForm.AccountingStartDate;
                                        element.AccountingStartDateStr = AccountingStartDateStr;
                                        if (this.accountingStartDateForm.AccountingStartDate == 2) {
                                            element.NextFewDays = this.accountingStartDateForm.NextFewDays;
                                        }
                                    }
                                }
                            }
                        }

                        this.handleClose();
                    }
                }
            });
        }

        /**打开批量编辑账期弹窗 */
        onBatchEditAccountPeriodDay(): void {
            this.dialogVisibleAccountPeriod = true;
        }

        /**关闭批量编辑账期弹窗 */
        handleCloseAccountPeriod(): void {
            this.dialogVisibleAccountPeriod = false;
        }

        /**保存批量编辑账期 */
        onSaveAccountPeriodDay(): void {
            if (this.form.Data) {
                var AccountPeriods = this.form.Data.AccountPeriods;
                if (AccountPeriods && AccountPeriods.length > 0) {
                    for (let index = 0; index < AccountPeriods.length; index++) {
                        const element = AccountPeriods[index];
                        element.AccountPeriodDay = this.accountPeriodDayForm.AccountPeriodDay;
                    }
                }
            }
            this.dialogVisibleAccountPeriod = false;
        }

        /**提交模板保存 */
        onSaveTemplateName(): void {
            const templateForm = this.$refs.templateForm as HTMLFormElement;
            if (this.form.Data && (this.form.Data.AccountPeriods == undefined || this.form.Data.AccountPeriods.length == 0)) {
                ElMessage.warning("账期信息不能为空！");
                return;
            }
            if (this.form.Data && this.form.Data.AccountPeriods) {
                var isFlag = false;
                for (var t of this.form.Data.AccountPeriods) {
                    if ((t.AccountingStartDate != null && t.AccountingStartDate != undefined)
                        || (t.AccountPeriodDay != null && t.AccountPeriodDay != undefined)
                        || (t.BusinessDate != null && t.BusinessDate != undefined)
                    ) {
                        isFlag = true;
                    }
                }
                if (isFlag == false) {
                    ElMessage.warning("账期信息不能为空！");
                    return;
                }
            }
            templateForm.validate((v: any) => {
                if (v) {
                    CompanyAppService.PostCompanyCreditAndAccountPeriodByIsTemplateById(
                        this.form.Data,
                        true,
                        this.id
                    )
                        .then(() => {
                            ElMessage.success("模板保存成功！");
                            this.handleCloseTemplateName();
                            CompanyAppService.GetCreditTemplate().then((r) => {
                                if (r.data.Data) {
                                    this.templatesForm.templates = r.data.Data;
                                }
                            });
                        })
                        .catch();
                }
            });
        }

        /**关闭模板保存弹窗 */
        handleCloseTemplateName(): void {
            this.form.Data.TemplateName = "";
            const templateForm = this.$refs.templateForm as HTMLFormElement;
            templateForm.resetFields();
            this.dialogVisibleTemplateName = false;
        }

        /**关闭模板弹窗 */
        handleCloseTemplate(): void {
            this.dialogVisibleTemplate = false;

            (this.$refs.reftemplatesForm as any).clearValidate();
        }

        /**导入模板 */
        onImport(index: number): void {
            var CompanyCreditId = this.templatesForm.templates[index].CompanyCreditId;
            CompanyAppService.GetTemplateCreditAndAccountPeriodById(
                CompanyCreditId
            ).then((r) => {
                if (r.data.Data) {
                    this.form.Data = r.data.Data;
                    this.handleCloseTemplate();
                }
            });
        }

        /**删除模板 */
        onDelete(index: number): void {
            var Id = this.templatesForm.templates[index].Id;
            CompanyAppService.DeleteCreditTemplateById(Id).then(() => {
                CompanyAppService.GetCreditTemplate().then((r) => {
                    if (r.data.Data) {
                        this.templatesForm.templates = r.data.Data;
                    }
                });
            });
        }

        /**保存模板 */
        onSaveTemplate(): void {
            const reftemplatesForm = this.$refs.reftemplatesForm as HTMLFormElement;
            reftemplatesForm.validate((v: any) => {
                if (v) {
                    CompanyAppService.PostCreditTemplate(this.templatesForm.templates).then(
                        () => {
                            ElMessage.success("模板编辑成功！");
                            this.dialogVisibleTemplate = false;
                        }
                    );
                }
            });
        }

        /*仅允许输入数字，其他字符都去除 */
        /*val：输入的字符串 */
        /*num：小数点后保留几位小数 */
        /*isZero：是否允许输入0 */
        setDecimal(val: string, num: number, isZero: boolean): string {
            if (val == null || val == undefined || val == "") {
                return "";
            }

            if (val != null && val != "") {
                val = val.toString();
                val = val.replace(/[^\d.]/g, "");
                val = val.replace(/\.{2,}/g, ".");
                val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
                if (val.indexOf(".") < 0 && val != "") {
                    //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                    val = parseFloat(val).toString();
                }
            }

            if (val == "" || val.toString() == "NaN") {
                val = "";
                return val;
            }

            if (isZero == true) {
                if (parseFloat(val.toString()) == 0) {
                    return val;
                }
            } else {
                if (parseFloat(val.toString()) == 0) {
                    return "";
                }
            }
            if (val.toString() == "NaN") {
                val = "";
                return val;
            }

            var point = "1";
            for (var i = 0; i < num; i++) {
                point += "0";
            }
            var result =
                Math.round((Number(val) + Number.EPSILON) * Number(point)) /
                Number(point) +
                "";
            if (isZero == false && result == "0") {
                return "";
            } else {
                return result;
            }
        }
    }
