import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolChartGroupBy {
    /**
     * 业务所属周期
     */
    BusinessCycle = 1,
    /**
     * 客户公司
     */
    ClientCompany = 2,
    /**
     * 服务内容
     */
    ServiceRemark = 3,
}

/**
 * -选项
 */
export const EnumOrderPoolChartGroupByOption: UiSelectOption<number>[] = [
    { Label: "业务所属周期", Value: 1, Data: "BusinessCycle" },
    { Label: "客户公司", Value: 2, Data: "ClientCompany" },
    { Label: "服务内容", Value: 3, Data: "ServiceRemark" },
];
