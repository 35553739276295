import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1abce3b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.disabledRouter ? '' : 'pagination')
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (item, i) => {
        return (_openBlock(), _createBlock(_component_miezz_button, {
          key: i,
          model: item,
          onHandleClick: _ctx.handleBottomButtonClick
        }, null, 8, ["model", "onHandleClick"]))
      }), 128))
    ]),
    _createVNode(_component_el_pagination, {
      class: "page",
      small: "",
      background: "",
      layout: _ctx.layout,
      "page-sizes": _ctx.pageSizes,
      total: _ctx.total,
      "current-page": _ctx.currentPage,
      "page-size": _ctx.pageSize,
      onCurrentChange: _ctx.handleCurrentChange,
      onSizeChange: _ctx.handleSizeChange
    }, null, 8, ["layout", "page-sizes", "total", "current-page", "page-size", "onCurrentChange", "onSizeChange"])
  ], 2))
}