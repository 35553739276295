
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import DtoFormParamCarrier, {
  DtoFormParamCarrierMiezzFormItems,
  DtoFormParamCarrierRules,
} from "@/models.machine/DtoFormParamCarrier";
import DtoListItemParamCarrier_Repeat from "@/models.machine/DtoListItemParamCarrier_Repeat";
import UiSelectOption from "@/models.machine/UiSelectOption";

export default class ParamCarrierForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamCarrier, string>();
  optFullName?: Array<string> = [];
  List: Array<DtoListItemParamCarrier_Repeat> = [];
  freighTowers: UiSelectOption<string>[] = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamCarrier();
    //表单-校验
    this.form.Rules = DtoFormParamCarrierRules;
    this.form.Rules.NameZh = [
      {
        validator: (rule: any, value: string, callback: any) => {
          let NameZh: any = null;
          let NameEn: any = null;
          if (
            this.form.Data.NameZh != null &&
            typeof this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != ""
          ) {
            NameZh = this.form.Data.NameZh.trim();
          }
          if (
            this.form.Data.NameEn != null &&
            typeof this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != ""
          ) {
            NameEn = this.form.Data.NameEn.toUpperCase().trim();
          }
          if (
            this.List.some((it) => it.NameZh == NameZh && it.NameEn == NameEn)
          ) {
            callback(new Error("信息重复，请重新填写"));
            return false;
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameEn = [
      {
        validator: (rule: any, value: string, callback: any) => {
          let NameZh: any = null;
          let NameEn: any = null;
          if (
            this.form.Data.NameZh != null &&
            typeof this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != "undefined" &&
            this.form.Data.NameZh != ""
          ) {
            NameZh = this.form.Data.NameZh.trim();
          }
          if (
            this.form.Data.NameEn != null &&
            typeof this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != "undefined" &&
            this.form.Data.NameEn != ""
          ) {
            NameEn = this.form.Data.NameEn.toUpperCase().trim();
          }
          if (
            this.List.some((it) => it.NameZh == NameZh && it.NameEn == NameEn)
          ) {
            callback(new Error("信息重复，请重新填写"));
            return false;
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamCarrierMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamCarrierAppService.GetList().then((r1) => {
      if (r1.data.Data) this.List = r1.data.Data;

      if (this.id) {
        ParamCarrierAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;

            var index = this.List.findIndex((it) => {
              return (
                it.NameZh == this.form.Data.NameZh &&
                it.NameEn == this.form.Data.NameEn
              );
            });
            this.List.splice(index, 1);
            ParamCarrierAppService.GetFreighTowerCompanies(
              this.form.Data.Type
            ).then((r) => {
              this.freighTowers = r.data.Data ?? [];
            });
          }
        });
      }
    });
  }

  @Watch("form.Data.Type")
  onTypeChanged(): void {
    ParamCarrierAppService.GetFreighTowerCompanies(this.form.Data.Type).then(
      (r) => {
        this.freighTowers = r.data.Data ?? [];
      }
    );
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        if (!this.form.Data.NameZh && !this.form.Data.NameEn) {
          ElMessage.warning("中文名称和英文名称不可同时为空！");
          complete();
        } else {
          MiezzForm.submit(this.$refs, "ruleForm", (v) => {
            if (v) {
              ParamCarrierAppService.Post(this.form.Data)
                .then(() => {
                  this.handleSubmit();
                  complete();
                })
                .catch(complete);
            } else complete();
          });
        }
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    if (this.form.Data.Abridge) {
      this.optFullName?.push(this.form.Data.Abridge);
    }
    if (this.form.Data.NameZh) {
      this.optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }

    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.replace(/\s*/g, "");
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
