
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoPageListCompanyAuth from "@/models.machine/DtoPageListCompanyAuth";
import DtoListItemCompanyAuth from "@/models.machine/DtoListItemCompanyAuth";

export default class CompanyAuthPageList extends Vue {
  @Prop() type?: EnumCompanyType;
  @Prop() list!: MiezzPageList<
    DtoPageListCompanyAuth,
    DtoListItemCompanyAuth,
    string
  >;

  /**创建时 */
  created(): void {
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyAuth();
  }

  /**查询列表 */
  handleGet(): void {
    CompanyAppService.GetAuthPageList(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyAuth,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "audit":
        this.$router.push(`${this.$route.path}/audit/${row.Id}`);
        complete();
        break;
      default:
        break;
    }
  }
}
