import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode from "./DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolExpress_Codes extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Codes = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 快递单号
     */
    Codes?: DtoPutOrderPoolExpress_Codes_OrderPoolExpressCode[];
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolExpress_CodesRules = {
}

/**
 * -设置项
 */
export const DtoPutOrderPoolExpress_CodesMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Codes",
    Label: "快递单号",
    Customize: true,
  },
]
