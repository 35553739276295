import UiPageList from "./UiPageList";
import DtoListItemRole from "./DtoListItemRole";

/**
 * 角色分页列表分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListRole extends UiPageList<DtoListItemRole, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
}

/**
 * 角色分页列表分页列表-表单校验
 */
export const DtoPageListRoleRules = {
}
