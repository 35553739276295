
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzButtonCheck extends Vue {
  @Prop() model = new MiezzButton();
  @Prop() check?: boolean;

  value: {
    check: boolean;
  } = {
    check: false,
  };

  created(): void {
    this.onCheckChanged();
  }

  @Watch("check")
  onCheckChanged(): void {
    if (this.check != undefined) {
      this.value.check = this.check;
    }
  }

  handleBeforeCheck(v?: boolean): void {
    this.model.Check = v;
    this.handleCheck(this.model, () => {
      //
    });
  }

  @Emit()
  handleCheck(model: MiezzButton, complete: () => void): void {
    //
  }
}
