import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DynamicSearchBracket from "./DynamicSearchBracket";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormDynamicSearch extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Open = false;
        this.ShowTab = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 搜索括号
     */
    Brackets?: DynamicSearchBracket[];
    /**
     * 公开
     */
    Open?: boolean;
    /**
     * Tab显示
     */
    ShowTab?: boolean;
}

/**
 * -表单校验
 */
export const DtoFormDynamicSearchRules = {
    Name: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormDynamicSearchMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Open",
    Label: "公开",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "ShowTab",
    Label: "Tab显示",
    Type: EnumMiezzFormItemType.Switch,
  },
]
