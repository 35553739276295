import UiSelectOption from "./UiSelectOption";

/**
 * 配载栈状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderStowageStackStatus {
    /**
     * 未配载
     */
    Not = 1,
    /**
     * 已配载
     */
    Already = 2,
    /**
     * 已取消
     */
    Cancel = 3,
}

/**
 * 配载栈状态-选项
 */
export const EnumOrderStowageStackStatusOption: UiSelectOption<number>[] = [
    { Label: "未配载", Value: 1, Data: "Not" },
    { Label: "已配载", Value: 2, Data: "Already" },
    { Label: "已取消", Value: 3, Data: "Cancel" },
];
