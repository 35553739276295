import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d509cf44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topHeaderCenter" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_menu, {
      class: "el-menu-demo",
      mode: "horizontal",
      "default-active": _ctx.active,
      "background-color": "rgba(0, 0, 0, 0.2)",
      "active-text-color": "#ffd04b",
      "text-color": "#fff"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuTree, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_menu_item, {
            key: i,
            onClick: ($event: any) => (_ctx.handleSelect(item.Id)),
            index: item.Id
          }, {
            default: _withCtx(() => [
              (_ctx.redCodes?.findIndex((it) => it == item.Code) != -1)
                ? (_openBlock(), _createBlock(_component_el_badge, {
                    key: 0,
                    "is-dot": "",
                    class: "item"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.Name), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.Name), 1))
            ]),
            _: 2
          }, 1032, ["onClick", "index"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active"])
  ]))
}