import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_page_list = _resolveComponent("price-page-list")!

  return (_openBlock(), _createBlock(_component_price_page_list, {
    ref: "ref-price-page-list",
    list: _ctx.list,
    amountType: _ctx.amountType,
    companies: _ctx.companies,
    companyId: _ctx.companyId,
    toggle: true,
    onHandleRefreshCompaniesOrUsers: _ctx.handleRefreshCompaniesOrUsers
  }, null, 8, ["list", "amountType", "companies", "companyId", "onHandleRefreshCompaniesOrUsers"]))
}