import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 委托书表单-货物清单-产品名称
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 产品名称
     */
    NameZh?: string;
    /**
     * 产品英文名称
     */
    NameEn?: string;
    /**
     * HS CODE
     */
    HsCode?: string;
    /**
     * 贸易国海关编码
     */
    TradingCountryHsCode?: string;
    /**
     * 规格/型号名称
     */
    SpecName?: string;
    /**
     * 产品图片
     */
    Picture?: string;
    /**
     * 材质
     */
    Material?: string;
    /**
     * 用途
     */
    Purpose?: string;
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * 单位
     */
    ChargeUnitEn?: string;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 总价
     */
    TotalPrice?: number;
}

/**
 * 委托书表单-货物清单-产品名称-表单校验
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    HsCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    TradingCountryHsCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SpecName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Picture: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Material: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Purpose: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 委托书表单-货物清单-产品名称-设置项
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "产品名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "产品英文名称",
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "HS CODE",
    Customize: true,
  },
  {
    Prop: "TradingCountryHsCode",
    Label: "贸易国海关编码",
    Customize: true,
  },
  {
    Prop: "SpecName",
    Label: "规格/型号名称",
    Customize: true,
  },
  {
    Prop: "Picture",
    Label: "产品图片",
    Customize: true,
  },
  {
    Prop: "Material",
    Label: "材质",
    Customize: true,
  },
  {
    Prop: "Purpose",
    Label: "用途",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "ChargeUnitEn",
    Label: "单位",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "总价",
    Customize: true,
  },
]

/**
 * 委托书表单-货物清单-产品名称-设置项
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "产品名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "产品英文名称",
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "HS CODE",
    Customize: true,
  },
  {
    Prop: "TradingCountryHsCode",
    Label: "贸易国海关编码",
    Customize: true,
  },
  {
    Prop: "SpecName",
    Label: "规格/型号名称",
    Customize: true,
  },
  {
    Prop: "Picture",
    Label: "产品图片",
    Customize: true,
  },
  {
    Prop: "Material",
    Label: "材质",
    Customize: true,
  },
  {
    Prop: "Purpose",
    Label: "用途",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "ChargeUnitEn",
    Label: "单位",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "总价",
    Customize: true,
  },
]
