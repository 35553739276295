import UiSelectOption from "./UiSelectOption";

/**
 * 委托书编号来源
 * @description 自动生成的代码,请勿修改
 */
export enum EnumPoolCodeSource {
    /**
     * 委托人
     */
    Client = 10,
    /**
     * 服务商
     */
    Service = 20,
}

/**
 * 委托书编号来源-选项
 */
export const EnumPoolCodeSourceOption: UiSelectOption<number>[] = [
    { Label: "委托人", Value: 10, Data: "Client" },
    { Label: "服务商", Value: 20, Data: "Service" },
];
