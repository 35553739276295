import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPackageEn from "../models.machine/DtoSelectSearchPackageEn";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchPackageEn from "../models.machine/DtoPageListSearchPackageEn";
import DtoPageListPackageEn from "../models.machine/DtoPageListPackageEn";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPackageEn from "../models.machine/DtoDetailPackageEn";
import DtoFormPackageEn from "../models.machine/DtoFormPackageEn";

/**
 * 英文包装种类
 * @description 自动生成的代码,请勿修改
 */
export default class PackageEnAppService {
    /**
     * 获取英文包装种类选择器
     * @param {DtoSelectSearchPackageEn} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPackageEn): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PackageEn/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取英文包装种类分页列表
     * @param {DtoPageListSearchPackageEn} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPackageEn>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPackageEn): AxiosPromise<RESTfulResult<DtoPageListPackageEn>> {
        const url = `/api/PackageEn/Get`;
        return service.get<RESTfulResult<DtoPageListPackageEn>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageEn/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取英文包装种类详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPackageEn>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPackageEn>> {
        const url = `/api/PackageEn/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPackageEn>>(url);
    }

    /**
     * 新增/编辑英文包装种类
     * @param {DtoFormPackageEn} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormPackageEn): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageEn/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除英文包装种类
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageEn/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PackageEn/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PackageEn/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PackageEn/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/PackageEn/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 删除英文包装
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveHistoryById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageEn/RemoveHistory/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

}
