import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchExemptionNature from "../models.machine/DtoSelectSearchExemptionNature";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchExemptionNature from "../models.machine/DtoPageListSearchExemptionNature";
import DtoPageListExemptionNature from "../models.machine/DtoPageListExemptionNature";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailExemptionNature from "../models.machine/DtoDetailExemptionNature";
import DtoFormExemptionNature from "../models.machine/DtoFormExemptionNature";

/**
 * 征免性质
 * @description 自动生成的代码,请勿修改
 */
export default class ExemptionNatureAppService {
    /**
     * 获取征免性质选择器
     * @param {DtoSelectSearchExemptionNature} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchExemptionNature): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ExemptionNature/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取征免性质分页列表
     * @param {DtoPageListSearchExemptionNature} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListExemptionNature>>} 
     */
    static Get(dto?: DtoPageListSearchExemptionNature): AxiosPromise<RESTfulResult<DtoPageListExemptionNature>> {
        const url = `/api/ExemptionNature/Get`;
        return service.get<RESTfulResult<DtoPageListExemptionNature>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptionNature/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取征免性质详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailExemptionNature>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailExemptionNature>> {
        const url = `/api/ExemptionNature/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailExemptionNature>>(url);
    }

    /**
     * 新增/编辑征免性质
     * @param {DtoFormExemptionNature} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormExemptionNature): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptionNature/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除征免性质
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptionNature/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ExemptionNature/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ExemptionNature/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ExemptionNature/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ExemptionNature/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
