import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailTime {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 
     */
    BLNO?: string;
    /**
     * 
     */
    ETD?: string;
    /**
     * 
     */
    ATD?: string;
    /**
     * 
     */
    ETA?: string;
    /**
     * 
     */
    ATA?: string;
    /**
     * 
     */
    OrderId?: string;
    /**
     * 
     */
    PoolId?: string;
    /**
     * 
     */
    Type?: EnumBillType;
}

/**
 * -表单校验
 */
export const DtoDetailTimeRules = {
}
