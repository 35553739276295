
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamListAppService from "@/apis.machine/ParamListAppService";
import DtoDetailParamList, {
  DtoDetailParamListMiezzDetailItems,
} from "@/models.machine/DtoDetailParamList";
import ParamListForm from "./ParamListForm.vue";

@Options({
  components: {
    ParamListForm,
  },
})
export default class ParamListDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailParamList, string>();
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailParamList();
    //详细-元素配置
    this.detail.Items = DtoDetailParamListMiezzDetailItems;
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
      },
    ];
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      ParamListAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "delete":
        ParamListAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
