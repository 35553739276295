
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DtoPageListSearchCompany from "@/models.machine/DtoPageListSearchCompany";
import DtoPageListCompany from "@/models.machine/DtoPageListCompany";
import DtoListItemCompany from "@/models.machine/DtoListItemCompany";
import router from "@/router";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import {
  EnumCompanyStatus,
  EnumCompanyStatusOption,
} from "@/models.machine/EnumCompanyStatus";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import UiSelectOption from "@/models.machine/UiSelectOption";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import SysAppService from "@/apis.machine/SysAppService";

export default class CompanyPageList extends Vue {
  @Prop() type?: EnumCompanyType;
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompany,
    DtoListItemCompany,
    string
  >;
  /**公司类型选择器 */
  @Prop() companyTypes?: UiSelectOption<string>[] = [];
  /**服务商选择器 */
  @Prop() serviceProviders?: DtoSelectOptionCompany[] = [];

  status?: EnumCompanyStatus;
  EnumCompanyStatusOption = (
    JSON.copy(EnumCompanyStatusOption) as UiSelectOption<number>[]
  ).filter((it) => it.Data != "Deleted");
  users: UiSelectOption<string>[] = [];
  sources: UiSelectOption<string>[] = [];
  tooltipFlag = false;
  btnUpdateMaxUserCount: MiezzButton = {
    Code: "update-max-user-count",
    Label: "编辑",
    Type: "text",
    Size: "small",
  };

  modalUpdateMaxUserCount = new MiezzModal(MiezzModalType.Dialog);
  modalUpdateMaxUserCountData: {
    Id?: string;
    Status: boolean;
    MaxUserCount?: number;
  } = {
    Status: false,
  };
  modalUpdateMaxUserCountRules = {};

  /**创建时 */
  created(): void {
    //列表-数据-初始
    this.list.Data = new DtoPageListCompany();
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "ServiceProviderCompanyId") {
        return row.ServiceProviderCompanyId
          ? `${row.ServiceProviderCompanyNameZh ?? "暂无数据"}/${
              row.ServiceProviderCompanyNameEn ?? "暂无数据"
            }`
          : "暂无数据";
      } else if (item.Prop == "JoinCompanyTypes")
        return row.JoinCompanyTypes?.map(
          (it) => `【${it.CompanyType?.Name}】`
        ).join("");
      return undefined;
    };
    this.list.Items?.forEach((ele) => {
      if (ele.Prop == "JoinCompanyTypes") {
        ele.DisabledTooltip = true;
        ele.Customize = true;
      }
    });
    CompanyAppService.GetUserSelectByType(this.type, this.list.Dto).then(
      (r) => {
        this.users = r.data.Data ?? [];
      }
    );
    CompanyAppService.GetSources().then((r) => {
      this.sources = r.data.Data ?? [];
    });

    this.modalUpdateMaxUserCount.Title = "用户数上限设置";
    this.modalUpdateMaxUserCount.Height = "100px";
    this.modalUpdateMaxUserCount.HandleClick = this.handleTopButtonClick;
    this.modalUpdateMaxUserCount.Buttons = [
      {
        Code: "save-max-user-count",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalUpdateMaxUserCountRules = {
      Status: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      MaxUserCount: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: number, callback: any) => {
            console.log(this.row);
            if (value && value < (this.row?.UserCount ?? 0)) {
              return callback(
                new Error("仅可录入大于当前服务商已有用户数的自然数")
              );
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };
  }

  handleClickInquiryHistory(row: DtoListItemCompany): void {
    DynamicSearchAppService.Post({
      Code: "PriceManagement_QuotationSheet",
      Brackets: [
        {
          Relation: EnumRelation.And,
          Items: [
            {
              Relation: EnumRelation.And,
              Where: {
                Prop: "DynamicSearch_SupplierName",
                Compare: EnumCompare.Contain,
                Value: row.Name,
              },
            },
          ],
        },
      ],
    }).then((r) => {
      setTimeout(() => {
        window.open(
          `#/price/quotation-sheet?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
          "_blank"
        );
      }, 500);
    });
  }

  visibilityChange(e: any, row: any): void {
    const ev = e.target;
    const evHeight = ev.offsetHeight;
    if (evHeight <= 19) {
      row.tooltipFlag = true;
    } else {
      row.tooltipFlag = false;
      const tooltip = this.$refs["tooltip" + row.Id] as any;
      tooltip.onOpen();
    }
  }

  /**查询列表 */
  handleGet(): void {
    CompanyAppService.GetPageListByType(this.type, this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
      if (this.list.Data) {
        this.list.Data.Items?.forEach((ele) => {
          // ele.tooltipFlag = true;
          ele.JoinCompanyTypesLabel = ele.JoinCompanyTypes?.map(
            (it) => `【${it.CompanyType?.Name}】`
          ).join("");
        });
      }
    });
  }

  handleMaxUserCountStatusChange(): void {
    if (this.modalUpdateMaxUserCountData.Status) {
      this.modalUpdateMaxUserCountData.MaxUserCount =
        this.row?.MaxUserCount ?? this.row?.UserCount;
    } else {
      this.modalUpdateMaxUserCountData.MaxUserCount = undefined;
    }
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        if (this.type == EnumCompanyType.ServiceProvider) {
          SysAppService.CanRegServiceProvider().then((r) => {
            if (!r.data.Data) {
              ElMessage.warning("当前平台所能添加的服务商已达上限！");
              complete();
            } else {
              this.$router.push(`${this.$route.path}/add`);
              complete();
            }
          });
        } else {
          this.$router.push(`${this.$route.path}/add`);
          complete();
        }
        break;
      case "save-max-user-count":
        (this.$refs["ref-form-max-user-count"] as any).validate(
          (v: boolean) => {
            if (v) {
              CompanyAppService.UpdateMaxUserCount(
                this.modalUpdateMaxUserCountData
              )
                .then(() => {
                  ElMessage.success("操作成功");
                  this.handleGet();
                  this.modalUpdateMaxUserCount.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      default:
        break;
    }
  }

  handleRowDbClick(row: DtoListItemCompany): void {
    const listUrl = this.$route.path;
    this.$router.push(`${listUrl}/detail/${row.Id}`);
  }

  row?: DtoListItemCompany;
  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompany,
    complete: () => void
  ): void {
    const listUrl = this.$route.path;
    this.row = row;
    switch (btn.Code) {
      case "update-max-user-count":
        this.modalUpdateMaxUserCountData.Id = row.Id;
        this.modalUpdateMaxUserCountData.Status = row.MaxUserCount != undefined;
        if (this.modalUpdateMaxUserCountData.Status) {
          this.modalUpdateMaxUserCountData.MaxUserCount = row.MaxUserCount;
        }
        this.modalUpdateMaxUserCount.Show = true;
        complete();
        break;
      //详细
      case "detail":
        this.$router.push(`${listUrl}/detail/${row.Id}`);
        complete();
        break;
      case "users":
        DynamicSearchAppService.Post({
          Code: "CustomerManagement_User",
          Brackets: [
            {
              Relation: EnumRelation.And,
              Items: [
                {
                  Relation: EnumRelation.And,
                  Where: {
                    Prop: "DynamicSearch_CompanyId",
                    Compare: EnumCompare.Equal,
                    Value: row.Id,
                  },
                },
              ],
            },
          ],
        })
          .then((r) => {
            this.$router.push({
              path: listUrl.replace("/company", "/user"),
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
              },
            });
            complete();
          })
          .catch(complete);
        break;
      case "open-supplier":
        CurrentLogier.LinkWithBack(
          `/service-provider/company/open-supplier/${row.Id}`
        );
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        CompanyAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-update-status":
        CompanyAppService.UpdateStatusByStatus(btn.Command, this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-update-three-api-service":
        CompanyAppService.UpdateOpenThreeApiService({
          Ids: this.list.SelectIds,
          OpenThreeApiService: btn.Command == "True",
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
