
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoRequestCompanyBusinessCardEditElectronicSeal, {
  DtoRequestCompanyBusinessCardEditElectronicSealMiezzFormItems,
  DtoRequestCompanyBusinessCardEditElectronicSealRules,
} from "@/models.machine/DtoRequestCompanyBusinessCardEditElectronicSeal";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CompanyBusinessCardSealForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoRequestCompanyBusinessCardEditElectronicSeal, string>();

  created(): void {
    this.modelValue.Title = "编辑电子章";
    this.modelValue.Width = "800px";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.form.Data = new DtoRequestCompanyBusinessCardEditElectronicSeal();
    this.form.Rules = DtoRequestCompanyBusinessCardEditElectronicSealRules;
    this.form.Items = DtoRequestCompanyBusinessCardEditElectronicSealMiezzFormItems;

    this.handleGet();
  }

  handleGet(): void {
    if (this.id) {
      CompanyBusinessCardAppService.GetElectronicSealById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyBusinessCardAppService.EditElectronicSeal(this.form.Data).then(
              () => {
                this.handleSubmit();
                complete();
              }
            );
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  @Emit()
  handleSubmit(): void {
    this.modelValue.Show = false;
  }
}
