import UiSelectOption from "./UiSelectOption";

/**
 * 审核结果
 * @description 自动生成的代码,请勿修改
 */
export enum EnumAuditResult {
    /**
     * 通过
     */
    Agree = 0,
    /**
     * 拒绝
     */
    Refuse = 1,
}

/**
 * 审核结果-选项
 */
export const EnumAuditResultOption: UiSelectOption<number>[] = [
    { Label: "通过", Value: 0, Data: "Agree" },
    { Label: "拒绝", Value: 1, Data: "Refuse" },
];
