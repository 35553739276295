import UiPageList from "./UiPageList";
import DtoListItemOrderPoolPackageShape from "./DtoListItemOrderPoolPackageShape";
import DtoDetailDepartureOutStock from "./DtoDetailDepartureOutStock";

/**
 * 装货港-仓库/堆场/码头分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderPoolPackageShape extends UiPageList<DtoListItemOrderPoolPackageShape, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 出库数量
     */
    OutStocks?: DtoDetailDepartureOutStock[];
    /**
     * 合计件数
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积
     */
    SumVolumeOutStock?: number;
    /**
     * 计价单位
     */
    ChargeUnitOutStock?: string;
    /**
     * 是否显示装货港出库数量
     */
    IsDepartureOutStock?: boolean;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
}

/**
 * 装货港-仓库/堆场/码头分页列表-表单校验
 */
export const DtoPageListOrderPoolPackageShapeRules = {
}
