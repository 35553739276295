import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 参数记录表分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchParamAuditLog extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * 参数记录表分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchParamAuditLogRules = {
}

/**
 * 参数记录表分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchParamAuditLogDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_ParamTableLabel",
    Label: "参数名称",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_ProjectSiteId",
    Label: "站点",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Promoter",
    Label: "发起人",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Reviewer",
    Label: "审核人",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Status",
    Label: "审核状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_AuditResult",
    Label: "审核结果",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
