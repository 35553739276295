import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 参数列表分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamList extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 参数名称
     */
    Name?: string;
    /**
     * 参数地址
     */
    Url?: string;
    /**
     * 权限代码
     */
    Code?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 全名组成配置
     */
    FullName?: string;
    /**
     * 是否需要全名组成配置
     */
    IsNeedFullName?: boolean;
}

/**
 * 参数列表分页列表-项-表单校验
 */
export const DtoListItemParamListRules = {
}

/**
 * 参数列表分页列表-项-设置项
 */
export const DtoListItemParamListMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "参数名称",
  },
  {
    Prop: "Url",
    Label: "参数地址",
  },
  {
    Prop: "Code",
    Label: "权限代码",
    Hide: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
  {
    Prop: "FullName",
    Label: "全名组成配置",
  },
]
