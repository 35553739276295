
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import DtoDetailOrderManifestVgm from "@/models.machine/DtoDetailOrderManifestVgm";
import MiezzForm from "@/models/MiezzForm";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import OrderAppService from "@/apis.machine/OrderAppService";
import MiezzButton from "@/models/MiezzButton";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailOrderManifestVgm_OrderManifestVgmDetailProduct, {
  DtoDetailOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems,
} from "@/models.machine/DtoDetailOrderManifestVgm_OrderManifestVgmDetailProduct";
import DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgm, {
  DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems,
} from "@/models.machine/DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgm";
import DtoDetailOrderManifestVgm_ByTotal, {
  DtoDetailOrderManifestVgm_ByTotalMiezzListItems,
} from "@/models.machine/DtoDetailOrderManifestVgm_ByTotal";
import DtoDetailOrderManifestVgm_ByContainerTrailer, {
  DtoDetailOrderManifestVgm_ByContainerTrailerMiezzListItems,
} from "@/models.machine/DtoDetailOrderManifestVgm_ByContainerTrailer";
import DtoDetailOrderManifestVgm_ByCustoms, {
  DtoDetailOrderManifestVgm_ByCustomsMiezzListItems,
} from "@/models.machine/DtoDetailOrderManifestVgm_ByCustoms";
export default class OrderManifestVgmDetail extends Vue {
  @Prop() id?: string;
  logier?: Logier;
  form = new MiezzForm<DtoDetailOrderManifestVgm, string>();
  EnumTransportType = EnumTransportType;
  listProductByCustomsList = new MiezzPageList<
    any,
    DtoDetailOrderManifestVgm_ByCustoms,
    string
  >();
  listProductByContainerTrailerList = new MiezzPageList<
    any,
    DtoDetailOrderManifestVgm_ByContainerTrailer,
    string
  >();
  listProductByTotalList = new MiezzPageList<
    any,
    DtoDetailOrderManifestVgm_ByTotal,
    string
  >();
  listProduct = new MiezzPageList<
    any,
    DtoDetailOrderManifestVgm_OrderManifestVgmDetailProduct,
    string
  >();
  listVgm = new MiezzPageList<
    any,
    DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgm,
    string
  >();
  topButtons: MiezzButton[] = [];

  created(): void {
    this.logier = CurrentLogier.GetLogier();

    this.listProductByCustomsList.Fix = false;
    this.listProductByCustomsList.HideCard = true;
    this.listProductByCustomsList.EnabledDynamicSearch = false;
    this.listProductByCustomsList.EnabledRadioSearch = false;
    this.listProductByCustomsList.EnabledSelect = false;
    this.listProductByCustomsList.EnabledPagination = false;
    this.listProductByCustomsList.Items =
      DtoDetailOrderManifestVgm_ByCustomsMiezzListItems;
    this.listProductByCustomsList.Data = {
      Items: [],
    };
    for (const item of this.listProductByCustomsList.Items) {
      switch (item.Prop) {
        case "NameEn":
        case "MarksNotes":
          item.Customize = true;
          break;

        default:
          break;
      }
    }

    this.listProductByContainerTrailerList.Fix = false;
    this.listProductByContainerTrailerList.HideCard = true;
    this.listProductByContainerTrailerList.EnabledDynamicSearch = false;
    this.listProductByContainerTrailerList.EnabledRadioSearch = false;
    this.listProductByContainerTrailerList.EnabledSelect = false;
    this.listProductByContainerTrailerList.EnabledPagination = false;
    this.listProductByContainerTrailerList.Items =
      DtoDetailOrderManifestVgm_ByContainerTrailerMiezzListItems;
    this.listProductByContainerTrailerList.Data = {
      Items: [],
    };
    this.listProductByContainerTrailerList.SpanMethod = (e: any) => {
      if (e.columnIndex < 3 || e.columnIndex > 6) {
        if (e.row.RowSpan > 1) {
          return { rowspan: e.row.RowSpan, colspan: 1 };
        } else if (e.row.RowSpan == 0) {
          return { rowspan: 0, colspan: 0 };
        }
      }
    };
    this.listProductByContainerTrailerList.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "ContainerTypeLabel":
          if (row.ContainerTypeLabel?.contains("/")) {
            return row.ContainerTypeLabel?.substring(
              0,
              row.ContainerTypeLabel.indexOf("/")
            );
          } else {
            return row.ContainerTypeLabel;
          }

        default:
          return undefined;
      }
    };

    this.listProductByTotalList.Fix = false;
    this.listProductByTotalList.HideCard = true;
    this.listProductByTotalList.EnabledDynamicSearch = false;
    this.listProductByTotalList.EnabledRadioSearch = false;
    this.listProductByTotalList.EnabledSelect = false;
    this.listProductByTotalList.EnabledPagination = false;
    this.listProductByTotalList.Items =
      DtoDetailOrderManifestVgm_ByTotalMiezzListItems;
    this.listProductByTotalList.Data = {
      Items: [],
    };
    for (const item of this.listProductByTotalList.Items) {
      switch (item.Prop) {
        case "Containers":
        case "NameEn":
        case "MarksNotes":
          item.Customize = true;
          break;

        default:
          break;
      }
    }

    this.listProduct.Fix = false;
    this.listProduct.HideCard = true;
    this.listProduct.EnabledDynamicSearch = false;
    this.listProduct.EnabledRadioSearch = false;
    this.listProduct.EnabledSelect = false;
    this.listProduct.EnabledPagination = false;
    this.listProduct.Items =
      DtoDetailOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems;
    this.listProduct.Data = {
      Items: [],
    };
    this.listProduct.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "ContainerTypeLabel":
          if (row.ContainerTypeLabel?.contains("/")) {
            return row.ContainerTypeLabel?.substring(
              0,
              row.ContainerTypeLabel.indexOf("/")
            );
          } else {
            return row.ContainerTypeLabel;
          }

        default:
          return undefined;
      }
    };

    this.listVgm.Fix = false;
    this.listVgm.HideCard = true;
    this.listVgm.EnabledDynamicSearch = false;
    this.listVgm.EnabledRadioSearch = false;
    this.listVgm.EnabledSelect = false;
    this.listVgm.EnabledPagination = false;
    this.listVgm.Items =
      DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems;
    this.listVgm.Data = {
      Items: [],
    };
    this.listVgm.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "ContainerTypeLabel":
          if (row.ContainerTypeLabel?.contains("/")) {
            return row.ContainerTypeLabel?.substring(
              0,
              row.ContainerTypeLabel.indexOf("/")
            );
          } else {
            return row.ContainerTypeLabel;
          }

        default:
          return undefined;
      }
    };
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetManifestVgmById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;

          this.topButtons = [
            {
              Code: "print-pdf",
              Label: "导出",
              PdfName: `舱单&VGM信息-${this.form.Data.Code}`,
              Type: "primary",
              Size: "small",
            },
          ];

          this.listProductByCustomsList.Data = {
            Items: r.data.Data.ByCustomsList ?? [],
          };
          this.listProductByContainerTrailerList.Data = {
            Items: r.data.Data.ByContainerTrailerList ?? [],
          };
          this.listProductByTotalList.Data = {
            Items: r.data.Data.ByTotalList ?? [],
          };
          this.listProduct.Data = {
            Items: r.data.Data.DetailProducts ?? [],
          };
          this.listVgm.Data = {
            Items: r.data.Data.DetailVgms ?? [],
          };
        }
      });
    }
  }
}
