import UiSelectOption from "./UiSelectOption";

/**
 * 提单类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumLadingBillType {
    /**
     * 正本提单/Original B/L
     */
    Original = 1,
    /**
     * 电放提单/Telex B/L
     */
    Telex = 2,
    /**
     * 其他（详见备注信息）
     */
    Other = 3,
}

/**
 * 提单类型-选项
 */
export const EnumLadingBillTypeOption: UiSelectOption<number>[] = [
    { Label: "正本提单/Original B/L", Value: 1, Data: "Original" },
    { Label: "电放提单/Telex B/L", Value: 2, Data: "Telex" },
    { Label: "其他（详见备注信息）", Value: 3, Data: "Other" },
];
