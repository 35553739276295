import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemSpec_Product from "./DtoListItemSpec_Product";
import DtoDetailSpec_SpecImage from "./DtoDetailSpec_SpecImage";
import DtoDetailSpec_SpecNumber_CompanyBusinessCard from "./DtoDetailSpec_SpecNumber_CompanyBusinessCard";
import DtoListItemSpec_SpecNumber from "./DtoListItemSpec_SpecNumber";
import DtoListItemSpec_SpecElement from "./DtoListItemSpec_SpecElement";

/**
 * 某个产品的规格分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemSpec extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.SpecNumbers = [];
        this.SpecElements = [];
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 产品
     */
    Product?: DtoListItemSpec_Product;
    /**
     * 规格型号名称
     */
    Name?: string;
    /**
     * 规格图片
     */
    Image?: string;
    /**
     * 规格图片
     */
    SpecImages?: DtoDetailSpec_SpecImage[];
    /**
     * 公司名片
     */
    CompanyBusinessCard?: DtoDetailSpec_SpecNumber_CompanyBusinessCard;
    /**
     * 常用货号
     */
    Number?: string;
    /**
     * 常用货号
     */
    SpecNumbers?: DtoListItemSpec_SpecNumber[];
    /**
     * 常用货号
     */
    NumberString?: string;
    /**
     * 重量（KGS）
     */
    Weight?: number;
    /**
     * 原始库存
     */
    StockQuantity?: number;
    /**
     * 销售数量
     */
    SalesQuantity?: number;
    /**
     * 描述
     */
    SpecElements?: DtoListItemSpec_SpecElement[];
    /**
     * 描述
     */
    SysRemark?: string;
}

/**
 * 某个产品的规格分页列表-项-表单校验
 */
export const DtoListItemSpecRules = {
}

/**
 * 某个产品的规格分页列表-项-设置项
 */
export const DtoListItemSpecMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "规格型号名称",
  },
  {
    Prop: "SpecImages",
    Label: "规格图片",
    Customize: true,
  },
  {
    Prop: "NumberString",
    Label: "常用货号",
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "重量（KGS）",
    Customize: true,
  },
  {
    Prop: "StockQuantity",
    Label: "原始库存",
    Customize: true,
  },
  {
    Prop: "SysRemark",
    Label: "描述",
    Customize: true,
  },
]
