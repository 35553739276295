import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheet_TrackRecord extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 时间
     */
    Date?: Date;
    /**
     * 内容
     */
    Content?: string;
}

/**
 * -表单校验
 */
export const DtoDetailPriceQuotationSheet_TrackRecordRules = {
}

/**
 * -设置项
 */
export const DtoDetailPriceQuotationSheet_TrackRecordMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Date",
    Label: "时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "Content",
    Label: "内容",
  },
]
