import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumSalerInputMode {
    /**
     * 手动录入
     */
    Hand = 10,
    /**
     * 公式录入
     */
    Formula = 20,
}

/**
 * -选项
 */
export const EnumSalerInputModeOption: UiSelectOption<number>[] = [
    { Label: "手动录入", Value: 10, Data: "Hand" },
    { Label: "公式录入", Value: 20, Data: "Formula" },
];
