
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import moment from "moment";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import BillAppService from "@/apis.machine/BillAppService";
    import BillHistoryAppService from "@/apis.machine/BillHistoryAppService";
    import DtoCallbackBill from "@/models.machine/DtoCallbackBill";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import DtoPageListSearchBillHistory, { DtoPageListSearchBillHistoryDynamicSearchItems } from "@/models.machine/DtoPageListSearchBillHistory";
    import DtoPageListBillHistory from "@/models.machine/DtoPageListBillHistory";
    import DtoListItemBillHistory, {
        DtoListItemBillHistoryMiezzListItems,
    } from "@/models.machine/DtoListItemBillHistory";
    import DtoRequestInvoice from "@/models.machine/DtoRequestInvoice";
    import DtoRequestFinance from "@/models.machine/DtoRequestFinance";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoRequestSettlement from "@/models.machine/DtoRequestSettlement";

    export default class BillPageList extends Vue {
        client?: OSS;
        /**分页列表 */
        dto = new DtoCallbackBill();
        init = new DtoCallbackBill();
        menuTab = new MiezzMenuTab();
        /*委托书主键 */
        id?: string;
        /*订单主键 */
        orderId?: string;
        /*0表示委托书账单，1表示订单账单 */
        type = 0;
        isCollection = false;
        isPayment = false;
        isSaler = false;
        isHistory = true;
        isLoading = false;
        /**分页列表 */
        historys = new MiezzPageList<DtoPageListSearchBillHistory, DtoListItemBillHistory, string>();
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
        ];
        /**创建时 */
        created(): void {
            //列表-传参
            this.historys.Dto = new DtoPageListSearchBillHistory();
            //列表-动态搜索-项
            this.historys.DynamicSearchItems = DtoPageListSearchBillHistoryDynamicSearchItems;
            //列表-数据-初始
            this.historys.Data = new DtoPageListBillHistory();
            if (this.$route && this.$route.matched) {
                for (var matched of this.$route.matched) {
                    if (matched.path.toLowerCase() == "/pool/bill/client/:id") {
                        this.id = this.$route.params.id as string;
                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = this.id;
                            this.historys.Dto.OrderId = undefined;
                        }
                        this.type = 0;
                        this.menuTab.Back = true;
                        this.menuTab.Url = `/pool/bill/client/${this.id}`;
                        this.menuTab.Menus = [
                            {
                                Label: "账单信息",
                                Url: `/pool/bill/client/${this.id}`,
                                Power: "OrderManagement_Pool_Summary_BillingInformation_Client",
                            },
                        ];
                    }
                    else if (matched.path.toLowerCase() == "/order/bill/client/:id") {
                        this.orderId = this.$route.params.id as string;
                        if (this.historys.Dto) {
                            this.historys.Dto.PoolId = undefined;
                            this.historys.Dto.OrderId = this.orderId;
                        }
                        this.type = 1;
                        this.menuTab.Back = true;
                        this.menuTab.Url = `/order/bill/client/${this.orderId}`;
                        this.menuTab.Menus = [
                            {
                                Label: "账单信息",
                                Url: `/order/bill/client/${this.orderId}`,
                                Power: "OrderManagement_Order_Summary_BillingInformation_Client",
                            },
                        ];
                    }
                }
            }
            this.handleGet();

            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 999999999;
            }
        }
        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }

        invoiceForm = new DtoRequestInvoice();
        invoiceFormInit = new DtoRequestInvoice();
        invoiceBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        onAnnex(row: any) {
            var url = this.client?.signatureUrl(row.Url as string);
            window.open(
                url,
                "_blank"
            );
        }
        onInvoice(row: any) {


            var param = new DtoRequestFinance();
            param.BillIds = [];
            param.BillIds.push(row.Id);
            FinanceAppService.GetInvoice(param).then((r) => {
                if (r.data.Data) this.invoiceForm = r.data.Data;
                this.invoiceFormInit = JSON.parse(JSON.stringify(this.invoiceForm));
                this.$nextTick(() => {
                    if (this.invoiceForm && this.invoiceForm.InvoiceItems) {
                        this.onInvoiceSum();
                    }
                });

            })
        }
        async onInvoiceSum(): Promise<void> {
            var bill = 0;
            var invoice = 0;


            if (this.invoiceForm) {

                if (this.invoiceForm.Bills && this.invoiceForm.Bills.length > 0) {
                    for (var b of this.invoiceForm.Bills) {
                        if (b.CurrencySystemCode != undefined && b.CurrencySystemCode != null && b.CurrencySystemCode != "") {
                            if (b.CurrencySystemCode.toLocaleUpperCase() == "CNY") {
                                if (b.Price) {
                                    bill += b.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(b.CurrencySystemCode, "CNY").then((r1) => {
                                    var rate1 = r1.data.Data;
                                    if (rate1 != undefined && rate1 != "") {
                                        if (b.Price) {
                                            bill += b.Price * Number(rate1);
                                        }
                                    }
                                })
                            }
                        }
                    }
                }

                this.invoiceForm.InvoiceString = "CNY0";
                var priceString = "";
                if (this.invoiceForm.InvoiceItems && this.invoiceForm.InvoiceItems.length > 0) {
                    var codes = [];
                    for (var it of this.invoiceForm.InvoiceItems) {
                        if (it.CurrencySystem != undefined && it.CurrencySystem != null) {
                            var exist = false;
                            for (var code of codes) {
                                if (code == it.CurrencySystem.Code) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (exist == false) {
                                codes.push(it.CurrencySystem.Code);
                            }

                            if (it.CurrencySystem.Code?.toLocaleUpperCase() == "CNY") {
                                if (it.Price) {
                                    invoice += it.Price;
                                }
                            }
                            else {
                                await ParamCurrencySystemAppService.GetExchangeRateByFromByTo(it.CurrencySystem.Code, "CNY").then((r) => {
                                    var rate = r.data.Data;
                                    if (rate != undefined && rate != "") {
                                        if (it.Price) {
                                            invoice += it.Price * Number(rate);
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (codes.length > 0) {
                        for (var c of codes) {
                            var price = 0;
                            for (var it1 of this.invoiceForm.InvoiceItems) {
                                if (it1.CurrencySystem != null && it1.CurrencySystem != undefined) {
                                    if (it1.CurrencySystem.Code == c) {
                                        if (it1.Price) {
                                            price += it1.Price;
                                        }
                                    }
                                }
                            }
                            if (price > 0 && c) {

                                var result1 = setDecimal(price.toString(), 4, false, true);
                                if (result1 != "") {
                                    price = Number(result1);
                                }

                                priceString += c + price + " ";
                            }
                        }
                    }
                }
                if (priceString != "") {
                    this.invoiceForm.InvoiceString = priceString;
                }
                this.invoiceForm.IsSame = false;
                if (bill == invoice && invoice > 0) {
                    this.invoiceForm.IsSame = true;
                }
            }
        }
        handleClickSettlement(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.settlementForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        handleClickInvoice(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.invoiceForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        settlementForm = new DtoRequestSettlement();
        settlementBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            //{
            //    //Icon: "select",
            //    Code: "import",
            //    Label: "核销",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        onSettlement(row: any) {

            FinanceAppService.GetSettlementByBillId(row.Id).then((r) => {
                if (r.data.Data) this.settlementForm = r.data.Data;
            })

            if (this.historys.Dto) {
                this.historys.Dto.CurrentPage = 1;
                this.historys.Dto.PageSize = 999999999;
                this.historys.Dto.BillId = row.Id;
            }
            this.isHistory = true;
            BillHistoryAppService.Get(this.historys.Dto).then((r) => {
                this.historys.Data = r.data.Data;
            });

        }

        /**查询列表 */
        handleGet(): void {
            this.client = AliyunOSS.GetClient();
            var param = {
                OrderId: "",
                PoolId: "",
            };
            if (this.type == 0 && this.id != undefined) {
                param.PoolId = this.id as string;
            }
            else if (this.type == 1 && this.orderId != undefined) {
                param.OrderId = this.orderId as string;
            }
            this.isLoading = true;
            BillAppService.GetClientBill(param).then((r) => {
                if (r.data.Data != undefined) {
                    this.dto = JSON.parse(JSON.stringify(r.data.Data));
                    this.init = JSON.parse(JSON.stringify(r.data.Data));
                }

                var count = 1;
                var preCount = 1;
                var billId = null;
                if (this.dto && this.dto.Collections) {
                    for (var collection of this.dto.Collections) {
                        collection.StartDateString = "";
                        if (collection.StartDate != undefined) {
                            collection.StartDateString = moment(collection.StartDate).format("YYYY/MM/DD");
                        }

                        collection.colorType = 0;
                        if (collection.PoolId != billId) {
                            collection.colorType = count;
                            billId = collection.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            collection.colorType = preCount;
                        }
                    }
                }

                count = 1;
                preCount = 1;
                billId = null;
                if (this.dto && this.dto.Payments) {
                    for (var payment of this.dto.Payments) {
                        payment.StartDateString = "";
                        if (payment.StartDate != undefined) {
                            payment.StartDateString = moment(payment.StartDate).format("YYYY/MM/DD");
                        }

                        payment.colorType = 0;
                        if (payment.PoolId != billId || (billId == null && count==1)) {
                            payment.colorType = count;
                            billId = payment.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            payment.colorType = preCount;
                        }
                    }
                }

                count = 1;
                preCount = 1;
                billId = null;
                if (this.dto && this.dto.Salers) {
                    for (var saler of this.dto.Salers) {
                        saler.StartDateString = "";
                        if (saler.StartDate != undefined) {
                            saler.StartDateString = moment(saler.StartDate).format("YYYY/MM/DD");
                        }

                        saler.colorType = 0;
                        if (saler.PoolId != billId) {
                            saler.colorType = count;
                            billId = saler.PoolId;
                            preCount = count;
                            count++;
                        }
                        else {
                            saler.colorType = preCount;
                        }
                    }
                }
                console.log(this.dto.Payments)
                this.isLoading = false;
            });
        }
        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                this.$router.push(url);
            }
            else {
                history.go(-1);
            }
        }
        tableRowClassName(row: any): string {
            var result = "";
            if (row.row.colorType % 2 == 0) {
                result = "chooseColor";
            }
            else {
                result = "cursor";
            }
            return result;
        }
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "collection":
                    if (this.type == 0) {
                        //this.$router.push(
                        //    `/pool/bill/collection/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/collection/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });

                    }
                    else if (this.type == 1) {
                        //this.$router.push(
                        //    `/order/bill/collection/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);
                        this.$router.push({
                            path: `/order/bill/collection/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "payment":
                    if (this.type == 0) {
                        //this.$router.push(
                        //    `/pool/bill/payment/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/payment/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {
                        //this.$router.push(
                        //    `/order/bill/payment/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/payment/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "saler":
                    if (this.type == 0) {
                        //this.$router.push(
                        //    `/pool/bill/saler/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/saler/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {
                        //this.$router.push(
                        //    `/order/bill/saler/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/order/bill/saler/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "share":
                    if (this.type == 0) {
                        //this.$router.push(
                        //    `/pool/bill/share/${this.id}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);

                        this.$router.push({
                            path: `/pool/bill/share/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else if (this.type == 1) {
                        //this.$router.push(
                        //    `/order/bill/share/${this.orderId}` +
                        //    "?backUrl=" +
                        //    this.$route.fullPath
                        //);


                        this.$router.push({
                            path: `/order/bill/share/${this.orderId}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    complete();
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
            if (isLessZero == true) {
                if (str[0] == "-") {
                    val = "-" + val;
                }
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };
