import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMenuScene, EnumMenuSceneOption } from "./EnumMenuScene";
import { EnumMenuType, EnumMenuTypeOption } from "./EnumMenuType";
import { EnumMenuPower, EnumMenuPowerOption } from "./EnumMenuPower";
import DtoDetailMenu_JoinMenuWebApi from "./DtoDetailMenu_JoinMenuWebApi";
import DtoDetailMenu_MenuField from "./DtoDetailMenu_MenuField";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailMenu extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = EnumMenuScene.Platform;
        this.Type = EnumMenuType.Menu;
        this.Power = EnumMenuPower.Everyone;
        this.Sort = 0;
        this.Disabled = false;
        this.Visabled = false;
        this.JoinMenuWebApis = [];
        this.MenuFields = [];
    }

    [proName: string]: any;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 场景
     */
    Scene?: EnumMenuScene;
    /**
     * 类型
     */
    Type?: EnumMenuType;
    /**
     * 权限
     */
    Power?: EnumMenuPower;
    /**
     * 链接地址
     */
    Url?: string;
    /**
     * 图标
     */
    Icon?: string;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 排序
     */
    Sort?: number;
    /**
     * 禁用
     */
    Disabled?: boolean;
    /**
     * 显示
     */
    Visabled?: boolean;
    /**
     * 接口
     */
    JoinMenuWebApis?: DtoDetailMenu_JoinMenuWebApi[];
    /**
     * 字段
     */
    MenuFields?: DtoDetailMenu_MenuField[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoDetailMenuRules = {
}

/**
 * -设置项
 */
export const DtoDetailMenuMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "编号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Scene",
    Label: "场景",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumMenuSceneOption,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumMenuTypeOption,
  },
  {
    Prop: "Power",
    Label: "权限",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumMenuPowerOption,
  },
  {
    Prop: "Url",
    Label: "链接地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Icon",
    Label: "图标",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MarkerColor",
    Label: "标记色",
    Type: EnumMiezzFormItemType.TextColor,
  },
  {
    Prop: "Sort",
    Label: "排序",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Disabled",
    Label: "禁用",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "禁用",
    WhenFalse: "启用",
  },
  {
    Prop: "Visabled",
    Label: "显示",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "显示",
    WhenFalse: "隐藏",
  },
  {
    Prop: "JoinMenuWebApis",
    Label: "接口",
    Customize: true,
  },
  {
    Prop: "MenuFields",
    Label: "字段",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
