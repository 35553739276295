import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createBlock(_component_miezz_modal, {
    modelValue: _ctx.modalSupplier,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalSupplier) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        modelValue: _ctx.formSupplier,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formSupplier) = $event)),
        ref: "ref-form-supplier"
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'SupplierSource')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  (_ctx.canEdit)
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 0,
                        modelValue: _ctx.formSupplier.Data.SupplierSource,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formSupplier.Data.SupplierSource) = $event)),
                        options: _ctx.EnumSupplierSourceOption,
                        clearable: false
                      }, null, 8, ["modelValue", "options"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.formatEnum(
                _ctx.EnumSupplierSourceOption,
                _ctx.formSupplier.Data.SupplierSource
              )), 1))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'SupplierCompanyTypeId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  (_ctx.canEdit)
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 0,
                        modelValue: _ctx.formSupplier.Data.SupplierCompanyTypeId,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formSupplier.Data.SupplierCompanyTypeId) = $event)),
                        label: _ctx.formSupplier.Data.SupplierCompanyTypeLabel,
                        "onUpdate:label": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formSupplier.Data.SupplierCompanyTypeLabel) = $event)),
                        options: _ctx.supplierTypes
                      }, null, 8, ["modelValue", "label", "options"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.formSupplier.Data.SupplierCompanyTypeLabel ?? "暂无数据"), 1))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'SupplierCompanyId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  (_ctx.canEdit)
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 0,
                        modelValue: _ctx.formSupplier.Data.SupplierCompanyId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formSupplier.Data.SupplierCompanyId) = $event)),
                        label: _ctx.formSupplier.Data.SupplierCompanyLabel,
                        "onUpdate:label": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formSupplier.Data.SupplierCompanyLabel) = $event)),
                        options: _ctx.filterCompanies,
                        "filter-Method": _ctx.filterClientCompanies,
                        "label-key": "NameZh",
                        "value-key": "Id"
                      }, null, 8, ["modelValue", "label", "options", "filter-Method"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.formSupplier.Data.SupplierCompanyLabel ?? "暂无数据"), 1))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'SupplierCompanyContactLabel')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 3,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  (_ctx.canEdit)
                    ? (_openBlock(), _createBlock(_component_miezz_input, {
                        key: 0,
                        modelValue: _ctx.formSupplier.Data.SupplierCompanyContactLabel,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formSupplier.Data.SupplierCompanyContactLabel) = $event)),
                        "fetch-suggestions": _ctx.getSupplierCompanyContact,
                        "trigger-on-focus": true,
                        clearable: "",
                        "value-key": "Label",
                        disabled: !_ctx.formSupplier.Data.SupplierCompanyId
                      }, null, 8, ["modelValue", "fetch-suggestions", "disabled"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.formSupplier.Data.SupplierCompanyContactLabel ?? "暂无数据"), 1))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}