
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import CompanyContactForm from "../../CompanyContact/CompanyContactForm.vue";
import MiezzForm from "@/models/MiezzForm";
import DtoFormCompanyContact from "@/models.machine/DtoFormCompanyContact";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
@Options({
  components: {
    CompanyContactForm,
  },
})
export default class CustomerCompanyContactForm extends Vue {
  form = new MiezzForm<DtoFormCompanyContact, string>();
  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "详细信息",
        Power: "CustomerManagement_Company_Contact_Edit",
      },
    ];
  }
}
