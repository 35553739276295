import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamTransportChannel from "../models.machine/DtoSelectSearchParamTransportChannel";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamTransportChannel from "../models.machine/DtoPageListSearchParamTransportChannel";
import DtoPageListParamTransportChannel from "../models.machine/DtoPageListParamTransportChannel";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamTransportChannel from "../models.machine/DtoDetailParamTransportChannel";
import DtoFormParamTransportChannel from "../models.machine/DtoFormParamTransportChannel";

/**
 * 渠道
 * @description 自动生成的代码,请勿修改
 */
export default class ParamTransportChannelAppService {
    /**
     * 获取渠道选择器
     * @param {DtoSelectSearchParamTransportChannel} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamTransportChannel): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamTransportChannel/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取渠道分页列表
     * @param {DtoPageListSearchParamTransportChannel} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamTransportChannel>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamTransportChannel): AxiosPromise<RESTfulResult<DtoPageListParamTransportChannel>> {
        const url = `/api/ParamTransportChannel/Get`;
        return service.get<RESTfulResult<DtoPageListParamTransportChannel>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChannel/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取渠道详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamTransportChannel>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamTransportChannel>> {
        const url = `/api/ParamTransportChannel/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamTransportChannel>>(url);
    }

    /**
     * 新增/编辑渠道
     * @param {DtoFormParamTransportChannel} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamTransportChannel): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChannel/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除渠道
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChannel/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportChannel/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportChannel/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamTransportChannel/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

}
