
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemJoinCompanyUser, {
  DtoListItemJoinCompanyUserMiezzListItems,
} from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoPageListSearchJoinCompanyUser, {
  DtoPageListSearchJoinCompanyUserDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserPageList from "../UserPageList.vue";
@Options({
  components: {
    UserPageList,
  },
})
export default class CustomerUserPageList extends Vue {
  type = EnumCompanyType.Customer;
  list = new MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >("CustomerManagement_User");

  logier?: Logier;

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    let items = JSON.copy(
      DtoListItemJoinCompanyUserMiezzListItems
    ) as MiezzListItem[];
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      items = items.filter((it) => it.Prop != "UserStatus");
      const statusItem = items.firstOrDefault(
        (it) => it.Prop == "Status"
      ) as MiezzListItem;
      items.splice(items.indexOf(statusItem), 1);
      const trusteeshipItem = items.firstOrDefault(
        (it) => it.Prop == "Trusteeship"
      ) as MiezzListItem;
      items.splice(items.indexOf(trusteeshipItem) + 1, 0, statusItem);
      this.list.DynamicSearchItems =
        DtoPageListSearchJoinCompanyUserDynamicSearchItems.filter(
          (it) =>
            it.Prop == "DynamicSearch_Name" ||
            it.Prop == "DynamicSearch_Account" ||
            it.Prop == "DynamicSearch_CompanyId"
        );
    } else {
      this.list.DynamicSearchItems =
        DtoPageListSearchJoinCompanyUserDynamicSearchItems;
    }
    this.list.Items = items;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Company") {
        return `${row.Company?.NameZh ?? "暂无数据"}/${
          row.Company?.ServiceProviderCompanyNameZh ?? "暂无数据"
        }`;
      } else if (item.Prop == "JoinCompanyUserRoles") {
        return (
          row.JoinCompanyUserRoles?.map((it) => it.Role?.Name)?.join("，") ??
          "暂无数据"
        );
      }
      return undefined;
    };
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CustomerManagement_User_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_User_Detail",
      },
    ];
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.list.HandleSelectable = function (
        row: DtoListItemJoinCompanyUser
      ): boolean {
        return row.Trusteeship == true;
      };
    }
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "辞退",
        Icon: "delete",
        Type: "text",
        Message: "是否确认辞退",
        PowerCode: "CustomerManagement_User_Delete",
      },
      {
        Code: "batch-update-company-user-status",
        Label: "员工状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "CustomerManagement_User_UpdateCompanyUserStatus",
        Options: [
          {
            Code: "0",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
      {
        Code: "batch-update-user-status",
        Label: "用户状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "CustomerManagement_User_UpdateUserStatus",
        Options: [
          {
            Code: "0",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
    ];
  }
}
