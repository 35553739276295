import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCustomsAnnexMode, EnumCustomsAnnexModeOption } from "./EnumCustomsAnnexMode";
import DtoListItemOrderDeparture_Company from "./DtoListItemOrderDeparture_Company";
import DtoListItemOrderDeparture_OrderDepartureAnnex from "./DtoListItemOrderDeparture_OrderDepartureAnnex";
import DtoListItemOrderDeparture_OrderDepartureCustoms from "./DtoListItemOrderDeparture_OrderDepartureCustoms";
import { EnumCustomsType, EnumCustomsTypeOption } from "./EnumCustomsType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";

/**
 * 装货港-报关分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderDeparture extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.BindThreeApiPropList = [];
    }

    [proName: string]: any;
    /**
     * 添加附件的方式
     */
    Mode?: EnumCustomsAnnexMode;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 提运单号
     */
    BLNO?: string;
    /**
     * 委托人
     */
    ClientCompany?: DtoListItemOrderDeparture_Company;
    /**
     * 客服Id
     */
    CustomerServiceId?: string;
    /**
     * 
     */
    CustomerServiceLabel?: string;
    /**
     * 海关放行时间
     */
    ReleaseTime?: Date;
    /**
     * 附件
     */
    OrderDepartureAnnexs?: DtoListItemOrderDeparture_OrderDepartureAnnex[];
    /**
     * 报关资料
     */
    OrderDepartureCustomss?: DtoListItemOrderDeparture_OrderDepartureCustoms[];
    /**
     * 报关类型
     */
    Type?: EnumCustomsType;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 是否退税
     */
    IsTax?: boolean;
    /**
     * 执行人
     */
    Executor?: string;
    /**
     * 执行人
     */
    ExecutorName?: string;
    /**
     * 执行人联系方式
     */
    ExecutorPhone?: string;
    /**
     * 执行人其他信息
     */
    ExecutorInfo?: string;
    /**
     * 清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: string;
    /**
     * 清关抬头
     */
    ClearCustomsHeader?: EnumCustomsClearanceHeaderProvider;
    /**
     * 绑定字段
     */
    BindThreeApiPropList?: string[];
    /**
     * 绑定第三方接口字段
     */
    BindThreeApiProps?: string;
}

/**
 * 装货港-报关分页列表-项-表单校验
 */
export const DtoListItemOrderDepartureRules = {
}

/**
 * 装货港-报关分页列表-项-设置项
 */
export const DtoListItemOrderDepartureMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Mode",
    Label: "添加附件的方式",
    Pipe: EnumPipe.Enum,
    Options: EnumCustomsAnnexModeOption,
  },
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "BLNO",
    Label: "提运单号",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
  },
  {
    Prop: "CustomerServiceLabel",
    Label: "",
  },
  {
    Prop: "ReleaseTime",
    Label: "海关放行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "OrderDepartureAnnexs",
    Label: "附件",
  },
  {
    Prop: "OrderDepartureCustomss",
    Label: "报关资料",
  },
  {
    Prop: "Type",
    Label: "报关类型",
    Customize: true,
  },
  {
    Prop: "SupplierSource",
    Label: "供应商来源",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "供应商类型Id",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyTypeLabel",
    Label: "供应商类型",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商Id",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "供应商联系人",
    Customize: true,
  },
  {
    Prop: "IsTax",
    Label: "是否退税",
    Customize: true,
  },
  {
    Prop: "Executor",
    Label: "执行人",
    Customize: true,
  },
  {
    Prop: "DestinationPortCustomsClearanceHeaderProvider",
    Label: "清关抬头",
    Customize: true,
  },
  {
    Prop: "ClearCustomsHeader",
    Label: "清关抬头",
    Customize: true,
  },
  {
    Prop: "BindThreeApiPropList",
    Label: "绑定字段",
  },
  {
    Prop: "BindThreeApiProps",
    Label: "绑定第三方接口字段",
  },
]
