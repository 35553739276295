import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35df8298"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remark" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_choose_menu_fields = _resolveComponent("choose-menu-fields")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tree, {
      ref: "treeRef",
      "node-key": "Id",
      data: _ctx.tree,
      props: _ctx.props,
      "expand-on-click-node": false,
      "check-strictly": true,
      "show-checkbox": _ctx.showCheck,
      "default-expand-all": "",
      draggable: "",
      onCheckChange: _ctx.handleCheckChange
    }, {
      default: _withCtx(({ data, node }) => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_tag, {
            class: "node-tag",
            style: _normalizeStyle(data.MarkerColor ? `color: ${data.MarkerColor}` : ''),
            type: "info"
          }, {
            default: _withCtx(() => [
              (data.Remark)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    placement: "bottom-start",
                    effect: "light"
                  }, {
                    content: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString(data.Remark), 1)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(data.Name), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.Name), 1))
            ]),
            _: 2
          }, 1032, ["style"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.RightItems, (item, i) => {
            return (_openBlock(), _createElementBlock("span", {
              key: i,
              class: "node-tag-check"
            }, [
              (_ctx.showCheck)
                ? (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: 0,
                    modelValue: item.Check,
                    "onUpdate:modelValue": ($event: any) => ((item.Check) = $event),
                    indeterminate: false,
                    onChange: ($event: any) => (_ctx.handleNodeTagChange(item, node))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_tag, {
                class: "node-tag",
                style: _normalizeStyle(item.MarkerColor ? `color: ${item.MarkerColor}` : ''),
                type: "info"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item.Name), 1)
                ]),
                _: 2
              }, 1032, ["style"])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["data", "props", "show-checkbox", "onCheckChange"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalField,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalField) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_choose_menu_fields, {
          modal: _ctx.modalField,
          "onUpdate:modal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalField) = $event)),
          id: _ctx.id,
          showCheck: _ctx.showCheck,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modal", "id", "showCheck", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}