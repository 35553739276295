
import { Vue, Options } from "vue-class-component";
import IndexMenu from "./IndexMenu.vue";
import IndexCompany from "./IndexCompany.vue";
import IndexUser from "./IndexUser.vue";
import IndexBreadcrumb from "./IndexBreadcrumb.vue";
import IndexHeaderChil from "./IndexHeaderChil.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzMenuItem from "@/models/MiezzMenuItem";
import MenuRedDotAppService from "@/apis.machine/MenuRedDotAppService";
import FreighTowerAppService from "@/apis.machine/FreighTowerAppService";
@Options({
  components: {
    IndexCompany,
    IndexMenu,
    IndexUser,
    IndexBreadcrumb,
    IndexHeaderChil,
  },
})
export default class Index extends Vue {
  random = 0;
  menus: MiezzMenuItem[] = [];
  redCodes?: Array<string> = [];

  created(): void {
    CurrentLogier.SetHubConnection();
    CurrentLogier.HubConnection?.on("RefreshMenuRedDot", () => {
      MenuRedDotAppService.Get().then((r) => {
        this.redCodes = r.data.Data;
        console.log("小红点刷新", this.redCodes);
      });
      
    });
    MenuRedDotAppService.Get().then((r) => {
      this.redCodes = r.data.Data;
    });
  }

  HandleMenu(params: MiezzMenuItem[]): void {
    this.menus = params;

    var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
    this.random += random;
  }
}
