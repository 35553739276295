
import { Vue } from "vue-class-component";
import SysAppService from "./apis.machine/SysAppService";
export default class App extends Vue {
  title = "";
  created(): void {
    SysAppService.GetWebInfo().then((r) => {
      this.title = r.data.Data?.Title ?? "";
    });
  }
}
