
    import CurrentLogier from "@/apis/CurrentLogier";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import DtoPageListSearchProduct, {
        DtoPageListSearchProductDynamicSearchItems,
    } from "@/models.machine/DtoPageListSearchProduct";
    import DtoPageListProduct from "@/models.machine/DtoPageListProduct";
    import DtoListItemProduct, {
        DtoListItemProductMiezzListItems,
    } from "@/models.machine/DtoListItemProduct";

    export default class ProductPageList extends Vue {
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchProduct,
            DtoListItemProduct,
            string
        >();
        /**当前Id */
        id?: string;
        /**权限编码 */
        powerCodes?: string[] = [];
        /*是否有上架的权限*/
        isUp?: boolean;
        /*是否有下架的权限*/
        isDown?: boolean;
        /*是否具有管理其他公司产品的权限 */
        isManageOtherCompany?: boolean;
        /**
         * 菜单场景-选项
         */
        EnumMenuSceneOption: UiSelectOption<number>[] = [
            { Label: "上架", Value: 0, Data: "Platform" },
            { Label: "下架", Value: 1, Data: "DeveloperAdmin" },
        ];

        selectCompanyId?: string;
        companys?: UiSelectOption<string>[] = [];
        companyRandom = 0;
        statuss?: UiSelectOption<string>[] = [];

        /**创建时 */
        created(): void {
            if (this.statuss) {
                this.statuss.push({
                    Label: "上架",
                    Value: "true",
                });

                this.statuss.push({
                    Label: "下架",
                    Value: "false",
                });
            }

            this.list.RadioSearchItems = this.EnumMenuSceneOption;
            //列表-操作区域宽度
            this.list.OperationWidth = "110px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchProduct();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchProductDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListProduct();
            this.list.PowerCode = "ProductManagement_ProductManagement";
            //列表-列配置
            //this.list.Items = DtoListItemProductMiezzListItems;
            this.list.Items = DtoListItemProductMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "IfShelf") {
                    item.Width = 30;
                } else if (
                    item.Prop == "HsCode" ||
                    item.Prop == "SpecQuantity" ||
                    item.Prop == "PackageQuantity" ||
                    item.Prop == "StockQuantity" ||
                    item.Prop == "SalesQuantity" ||
                    item.Prop == "ApplyNumber"
                ) {
                    item.Width = 60;
                }
                return item;
            });
            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "add",
                    Label: "添加产品",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_NewlyAdded",
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "detail",
                    Title: "查看详细",
                    Icon: "search",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation",
                },
            ];

            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-down",
                    Label: "下架",
                    Icon: "bottom",
                    Type: "text",
                    //Message: "是否确定将所选产品下架？",
                    PowerCode: "ProductManagement_ProductManagement_PutOnShelves",
                    //Hide: true,
                    MessageBox: true,
                    Size: "small",
                },
                {
                    Code: "batch-up",
                    Label: "上架",
                    Icon: "top",
                    Type: "text",
                    //Message: "是否确定将所选产品上架？",
                    PowerCode: "ProductManagement_ProductManagement_LowerShelf",
                    MessageBox: true,
                    Size: "small",
                    //Hide: true,
                },
            ];

            this.onPower();
        }
        onChangeCompany(): void {
            //this.companyRandom = Math.random();
            //this.list.Dto = new DtoPageListSearchProduct();
            //if (this.list.Dto) {
            //    this.list.Dto.CompanyId = this.selectCompanyId;
            //}

            //ProductAppService.Get(this.list.Dto).then((r) => {
            //    this.list.Data = r.data.Data;
            //});
            this.$router.push("/product?CompanyId=" + this.selectCompanyId);
        }

        async onPower(): Promise<void> {
            this.powerCodes = await CurrentLogier.GetPowerCodes();
            this.isUp =
                this.powerCodes &&
                this.powerCodes.contains(this.list.BottomButtons[0].PowerCode);
            this.isDown =
                this.powerCodes &&
                this.powerCodes.contains(this.list.BottomButtons[1].PowerCode);
            this.isManageOtherCompany =
                this.powerCodes &&
                this.powerCodes.contains(
                    "ProductManagement_ProductManagement_CanManageOtherEnterpriseProducts"
                );
        }

        /**查询列表 */
        handleGet(): void {
            var RadioSearch = this.$route.query.RadioSearch;
            this.selectCompanyId = this.$route.query.CompanyId as string;
            this.list.DynamicSearchCode = "";
            if (this.isManageOtherCompany == true) {
                if (this.selectCompanyId != undefined && this.selectCompanyId != "") {
                    this.list.DynamicSearchCode =
                        this.list.PowerCode + "-" + this.selectCompanyId;
                } else {
                    this.list.DynamicSearchCode = this.list.PowerCode + "-Foreign";
                }
            } else {
                this.list.DynamicSearchCode = this.list.PowerCode + "-Foreign";
            }

            this.list.BottomButtons[0].Hide = true;
            this.list.BottomButtons[1].Hide = true;
            if (RadioSearch == "0" || RadioSearch == "1") {
                RadioSearch = RadioSearch + "";
                if (RadioSearch == "0") {
                    this.list.BottomButtons[1].Hide = true;
                    if (this.isUp == true) {
                        this.list.BottomButtons[0].Hide = false;
                    }
                } else if (RadioSearch == "1") {
                    this.list.BottomButtons[0].Hide = true;
                    if (this.isDown == true) {
                        this.list.BottomButtons[1].Hide = false;
                    }
                }
            } else {
                if (this.isUp == true) {
                    this.list.BottomButtons[0].Hide = false;
                }
                if (this.isDown == true) {
                    this.list.BottomButtons[1].Hide = false;
                }
            }

            if (this.isManageOtherCompany == true) {
                var param = {
                    CompanyType: 40,
                };
                CompanyAppService.GetSelect(param).then((r1) => {
                    this.companys = r1.data.Data;
                    if (
                        this.companys &&
                        this.companys.length > 0 &&
                        (this.selectCompanyId == undefined || this.selectCompanyId == "")
                    ) {
                        this.selectCompanyId = this.companys[0].Id;
                    }
                    if (this.list.Dto) {
                        this.list.Dto.CompanyId = this.selectCompanyId;
                    }
                    ProductAppService.Get(this.list.Dto).then((r) => {
                        this.list.Data = r.data.Data;
                        if (this.list.Data && this.list.Data.Items) {
                            for (var t of this.list.Data.Items) {
                                t.SpecName = "";
                                if (t.Specs) {
                                    for (var s of t.Specs) {
                                        t.SpecName += "【" + s.Name + "】";
                                    }
                                }
                            }
                        }
                    });
                });
            } else {
                ProductAppService.Get(this.list.Dto).then((r) => {
                    this.list.Data = r.data.Data;
                    if (this.list.Data && this.list.Data.Items) {
                        for (var t1 of this.list.Data.Items) {
                            t1.SpecName = "";
                            if (t1.Specs) {
                                for (var s1 of t1.Specs) {
                                    t1.SpecName += "【" + s1.Name + "】";
                                }
                            }
                        }
                    }
                });
            }
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.handleGet();
        }

        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    this.$router.push(
                        `${this.$route.path}/add?CompanyId=` +
                        this.selectCompanyId +
                        `&backUrl=` +
                        this.$route.fullPath
                    );
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemProduct,
            complete: () => void
        ): void {
            this.id = row.Id;
            const listUrl = this.$route.path;
            //console.log(this.$route);
            //console.log(this.$router);
            switch (btn.Code) {
                //详细
                case "detail":
                    //this.$router.push(
                    //    `/product/detail/${row.Id}?backUrl=` + this.$route.fullPath
                    //);
                    this.$router.push({
                        path: `/product/detail/${row.Id}`,
                        query: { backUrl: this.$route.fullPath },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            var number = 0;
            switch (btn.Code) {
                case "batch-down":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个产品");
                        complete();
                        return;
                    }

                    number = 0;
                    for (var it of this.list.SelectRows) {
                        if (it.IfShelf == true) {
                            number++;
                        }
                    }
                    if (number <= 0) {
                        ElMessage.warning("选中的产品没有上架产品，不能执行下架操作。");
                        complete();
                        return;
                    }

                    ElMessageBox.confirm("是否确定将所选产品下架？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            ProductAppService.Down(this.list.SelectIds)
                                .then(() => {
                                    ElMessage.success("下架成功");
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                case "batch-up":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个产品");
                        complete();
                        return;
                    }

                    number = 0;
                    for (var pro of this.list.SelectRows) {
                        if (pro.IfShelf != true) {
                            number++;
                        }
                    }
                    if (number <= 0) {
                        ElMessage.warning("选中的产品没有下架产品，不能执行上架操作。");
                        complete();
                        return;
                    }

                    ElMessageBox.confirm("是否确定将所选产品上架？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            ProductAppService.Up(this.list.SelectIds)
                                .then(() => {
                                    ElMessage.success("上架成功");
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }
    }
