import UiFormWithStringId from "./UiFormWithStringId";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodeDynamicSearchItem from "./DtoFormCodeDynamicSearchItem";
import DtoFormCodeDto from "./DtoFormCodeDto";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeMethodGetPageList extends UiFormWithStringId {
    constructor() {
        //无参构造函数
        super()
        this.Summary = "获取分页列表";
        this.Name = "GetPageList";
        this.SyncController = true;
        this.IdType = "Guid";
        this.DynamicSearchItems = [];
        this.ListItem = {"Properties":[]};
        this.PageListSearch = {"BaseType":"UiPageListSearch","Properties":[]};
        this.PageList = {"Properties":[]};
    }

    [proName: string]: any;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 服务
     */
    Service?: string;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 方法名称
     */
    Name?: string;
    /**
     * 同步至控制器
     */
    SyncController?: boolean;
    /**
     * 获取泛型类型
     */
    QueryableT?: string;
    /**
     * Id类型
     */
    IdType?: string;
    /**
     * 动态搜索-项
     */
    DynamicSearchItems?: DtoFormCodeDynamicSearchItem[];
    /**
     * 分页列表-项
     */
    ListItem?: DtoFormCodeDto;
    /**
     * 分页列表-搜索条件
     */
    PageListSearch?: DtoFormCodeDto;
    /**
     * 分页列表
     */
    PageList?: DtoFormCodeDto;
}

/**
 * -表单校验
 */
export const DtoFormCodeMethodGetPageListRules = {
    Group: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    App: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Service: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Summary: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SyncController: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    QueryableT: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCodeMethodGetPageListMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Group",
    Label: "分组",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "App",
    Label: "应用",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Service",
    Label: "服务",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Summary",
    Label: "注释",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "方法名称",
    Customize: true,
  },
  {
    Prop: "SyncController",
    Label: "同步至控制器",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "QueryableT",
    Label: "获取泛型类型",
    Type: EnumMiezzFormItemType.Select,
    AllowCreate: true,
  },
  {
    Prop: "IdType",
    Label: "Id类型",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "DynamicSearchItems",
    Label: "动态搜索-项",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ListItem",
    Label: "分页列表-项",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PageListSearch",
    Label: "分页列表-搜索条件",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PageList",
    Label: "分页列表",
    Span: 24,
    Customize: true,
  },
]
