
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import OrderInboundOutboundAppService from "@/apis.machine/OrderInboundOutboundAppService";
    import DtoPageListSearchOrderInboundOutbound, {
        DtoPageListSearchOrderInboundOutboundDynamicSearchItems,
    } from "@/models.machine/DtoPageListSearchOrderInboundOutbound";
    import DtoPageListOrderInboundOutbound from "@/models.machine/DtoPageListOrderInboundOutbound";
    import DtoListItemOrderInboundOutbound, {
        DtoListItemOrderInboundOutboundMiezzListItems,
    } from "@/models.machine/DtoListItemOrderInboundOutbound";
    import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
    import DtoPutOrderDepartureWharfAnnex, {
        DtoPutOrderDepartureWharfAnnexRules,
        DtoPutOrderDepartureWharfAnnexMiezzFormItems,
    } from "@/models.machine/DtoPutOrderDepartureWharfAnnex";
    import DtoPutStockTime, { DtoPutStockTimeMiezzFormItems, DtoPutStockTimeRules } from "@/models.machine/DtoPutStockTime";
    import MiezzForm from "@/models/MiezzForm";
    import CurrentLogier from "@/apis/CurrentLogier";
    import OSS from "ali-oss";
    import OrderPoolProcess from "./../Pool/OrderPoolProcess.vue";
    import { el } from "element-plus/es/locale";
    import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
    import { EnumSupplierSource } from "@/models.machine/EnumSupplierSource";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
    import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
    import Logier from "@/models.machine/Logier";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import { EStockType } from "@/models.machine/EStockType";
    import DtoRequestBoxSticker from "@/models.machine/DtoRequestBoxSticker";
    import DtoRequestTime from "@/models.machine/DtoRequestTime";
    import moment from "moment";
    import OrderPoolChooseService from "../Pool/OrderPoolChooseService.vue";
import { EnumIncomingGoodsStatusOption } from "@/models.machine/EnumIncomingGoodsStatus";
    @Options({
        components: {
            OrderPoolProcess,
            OrderPoolChooseService,
        },
    })
    export default class OrderInboundOutboundPageList extends Vue {
        isLoading = false;
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchOrderInboundOutbound,
            DtoListItemOrderInboundOutbound,
            string
        >("OrderInboundOutboundPageList");
        /**当前Id */
        id?: string;
        /**当前委托Id */
        poolId?: string;
        /**当前订单Id */
        orderId?: string;
        /**当前是装货港目的港 */
        orderServiceType?: number;
        inStockKey = 0;

        modalStatistics = new MiezzModal(MiezzModalType.Dialog);
        modalChooseService = new MiezzModal(MiezzModalType.Dialog);

        logier?: Logier;
        serviceProviders: DtoSelectOptionCompany[] = [];
        clientUsers: UiSelectOption<string>[] = [];

        //loadingPorts: UiSelectOption<string>[] = [];
        //deliveryPorts: UiSelectOption<string>[] = [];
        ports: UiSelectOption<string>[] = [];
        selectWharfId?: string;
        client?: OSS;
        /*具有的权限 */
        isEdit = false;
        isDelete = false;
        isDetail = true;
        isAddStock = true;
        //是否有箱贴
        isBoxSticker = false;
        type = EnumOrderServiceType.DeparturePort;
        timeRuleForm = new MiezzForm<DtoPutStockTime, string>();
        timeBottomButtons?: MiezzButton[] = [
            {
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        annexRuleForm = new MiezzForm<DtoPutOrderDepartureWharfAnnex, string>();
        annexBottomButtons?: MiezzButton[] = [
            {
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        tableStatisticsData: Array<any> = [];
        isBatchShiftout = false;


        /*true表示委托书或者直订订单，false表示配载订单 */
        directOrder = true;

        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        currentAnnex = {};
        EnumIncomingGoodsStatusOption = EnumIncomingGoodsStatusOption
        
        /**创建时 */
        created(): void {
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchOrderInboundOutbound();
            //列表-动态搜索-项
            this.list.DynamicSearchItems =
                DtoPageListSearchOrderInboundOutboundDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderInboundOutbound();
            //列表-列配置
            this.list.Items = DtoListItemOrderInboundOutboundMiezzListItems;
            this.list.Items = DtoListItemOrderInboundOutboundMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "ReceivingDeadline") {
                    item.Width = 100;
                }
                return item;
            });
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "shiftout",
                    Title: "移库",
                    Icon: "Right",
                    Type: "default",
                    Size: "small",
                    PowerCode: "InbounDoutbound_Shiftout",
                },
                {
                    Code: "addstock",
                    Title: "库存建单",
                    Icon: "Tickets",
                    Type: "default",
                    Size: "small",
                    PowerCode: "InbounDoutbound_Addstock",
                },
            ];
            this.list.CheckRowButtonShow = this.checkRowButtonShow;
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-statistics",
                    Label: "统计",
                    Icon: "Histogram",
                    Type: "text",
                },
                {
                    Code: "batch-shiftout",
                    Label: "移库",
                    Icon: "Right",
                    Type: "text",
                },
            ];

            this.list.RadioSearchItems = [{ Label: "待调整", Value: 1 }];

            this.modalStatistics.Title = "统计";
            this.modalStatistics.Height = "130px";
            this.modalStatistics.Width = "60%";

            this.isEdit = false;
            this.isDelete = false;
            this.isDetail = true;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (this.type == EnumOrderServiceType.DeparturePort) {
                    if (
                        r?.contains(
                            "OrderManagement_Pool_Summary_DeparturePortWarehouse_AddTo"
                        )
                    ) {
                        this.isEdit = true;
                        this.isDetail = false;
                    }
                    if (
                        r?.contains(
                            "OrderManagement_Pool_Summary_DeparturePortWarehouse_Delete"
                        )
                    ) {
                        this.isDelete = true;
                    }
                } else if (this.type == EnumOrderServiceType.DestinationPort) {
                    if (
                        r?.contains(
                            "OrderManagement_Pool_Summary_DestinationPortWarehouse_AddTo"
                        )
                    ) {
                        this.isEdit = true;
                        this.isDetail = false;
                    }
                    if (
                        r?.contains(
                            "OrderManagement_Pool_Summary_DestinationPortWarehouse_Delete"
                        )
                    ) {
                        this.isDelete = true;
                    }
                }
            });

            this.logier = CurrentLogier.GetLogier();
            OrderInboundOutboundAppService.GetSelect().then((r) => {
                this.serviceProviders = r.data.Data ?? [];
            });

            if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
                OrderInboundOutboundAppService.GetClientCompanyId().then((r) => {
                    this.clientUsers = r.data.Data ?? [];
                });
            } else {
                JoinCompanyUserAppService.GetSelect({
                    CompanyId: this.logier?.CompanyId,
                    PowerCodes: ["OrderManagement_Pool"],
                }).then((r) => {
                    this.clientUsers = r.data.Data ?? [];
                });
            }
        }

        /**选择服务完成回调 */
        handleChooseServiceSubmit(id: string): void {
            this.$router.push({
                path: `/order/pool/add/${id}`,
                query: {
                    PoolId: this.poolId,
                    OrderDepartureWharfId: this.id,
                },
            });
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        formatTime(v?: Date): string {
            return v ? moment(v).format("YYYY/MM/DD HH:mm") : "暂无数据";
        }

        onInStockTime(row: any): void {
            var param = new DtoRequestTime();
            param.IsInStock = true;
            param.OrderDepartureWharfId = row.Id;
            OrderDepartureWharfAppService.GetTime(param).then((r) => {
                if (r.data.Data) {
                    this.timeRuleForm.Data = new DtoPutStockTime();
                    //表单-校验
                    this.timeRuleForm.Rules = DtoPutStockTimeRules;
                    //表单-元素配置
                    this.timeRuleForm.Items = DtoPutStockTimeMiezzFormItems;

                    this.timeRuleForm.Data.OrderDepartureWharfId = row.Id;
                    this.timeRuleForm.Data.Label = "入库日期";
                    this.timeRuleForm.Data.Title = "全部入库日期";
                    this.timeRuleForm.Data.Time = r.data.Data.CallbackTime;
                    this.timeRuleForm.Data.CheckTime = r.data.Data.CheckTime;
                    this.timeRuleForm.Data.Type = EStockType.In;
                    this.timeRuleForm.Data.Visiable = true;

                    this.timeRuleForm.Rules.Time = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.timeRuleForm.Data.CheckTime == null || this.timeRuleForm.Data.CheckTime == undefined || typeof (this.timeRuleForm.Data.CheckTime) == "undefined" || moment(this.timeRuleForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(this.timeRuleForm.Data.CheckTime).isAfter(value)) {
                                    callback("入库时间不能晚于出库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];
                }
            })
        }


        onOutStockTime(row: any): void {
            if (row.SumQuantityOutStock == null || row.SumQuantityOutStock == undefined) {
                ElMessage.warning("请先完善出库货量");
                return;
            }

            var param = new DtoRequestTime();
            param.IsInStock = false;
            param.OrderDepartureWharfId = row.Id;
            OrderDepartureWharfAppService.GetTime(param).then((r) => {
                if (r.data.Data) {
                    this.timeRuleForm.Data = new DtoPutStockTime();
                    //表单-校验
                    this.timeRuleForm.Rules = DtoPutStockTimeRules;
                    //表单-元素配置
                    this.timeRuleForm.Items = DtoPutStockTimeMiezzFormItems;

                    this.timeRuleForm.Data.OrderDepartureWharfId = row.Id;
                    this.timeRuleForm.Data.Label = "出库日期";
                    this.timeRuleForm.Data.Title = "全部出库日期";
                    this.timeRuleForm.Data.Time = r.data.Data.CallbackTime;
                    this.timeRuleForm.Data.Type = EStockType.Out;
                    this.timeRuleForm.Data.CheckTime = r.data.Data.CheckTime;

                    this.timeRuleForm.Rules.Time = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                        {
                            required: true,
                            validator: (rule: any, value: string, callback: any) => {
                                if (this.timeRuleForm.Data.CheckTime == null || this.timeRuleForm.Data.CheckTime == undefined || typeof (this.timeRuleForm.Data.CheckTime) == "undefined" || moment(this.timeRuleForm.Data.CheckTime).isSame(value)) {
                                    callback();
                                    return false;
                                }
                                else if (!moment(value).isAfter(this.timeRuleForm.Data.CheckTime)) {
                                    callback("出库时间不能早于入库时间！");
                                    return false;
                                }
                                else {
                                    callback();
                                }
                            },
                            trigger: "change",
                        },
                    ];

                    this.timeRuleForm.Data.Visiable = true;
                }
            })
        }

        handleClickTime(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "refTimeRuleForm", (v) => {
                        if (v == true) {

                            OrderDepartureWharfAppService.EditTime(this.timeRuleForm.Data).then((r) => {
                                this.timeRuleForm.Data.Visiable = false;
                                if (this.timeRuleForm.Data.Type == EStockType.In) {
                                    ElMessage.success("编辑全部入库日期成功");
                                }
                                else {
                                    ElMessage.success("编辑全部出库日期成功");
                                }
                                this.handleGet();
                                complete();
                            })

                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.timeRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onAutoAddPlan(): void {
            this.handleGet();
        }

        onSelectPlan(row: any): void {
            row.OrderPoolId = this.poolId;
            this.isLoading = true;
            OrderDepartureWharfAppService.Post(row)
                .then(() => {
                    this.isLoading = false;
                    if (row.Id != undefined) {
                        ElMessage.success("编辑成功");
                    }
                    else {
                        ElMessage.success("新增成功");
                    }
                    this.handleSubmit();
                })
                .catch((err) => {
                    this.isLoading = false;
                });
        }

        onPlan(row: any): void {
            this.poolId = row.PoolId
            const WharfPlan = this.$refs["WharfPlan"] as any;
            WharfPlan.onInit(row.Id, row.Type, this.directOrder, row.PoolId, row.OrderId);
        }


        onInStock(row: any): void {
            this.id = row.Id;
            this.poolId = row.PoolId;
            this.orderId = row.OrderId;
            this.inStockKey = Math.random();
            this.$nextTick(() => {
                const WharfInStock = this.$refs["WharfInStock"] as any;
                WharfInStock.onInit(row.Id, row.Type);
            });
        }

        onSelectInStock(row: any): void {
            row.OrderPoolId = this.poolId;
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.poolId != null && this.poolId != undefined && this.poolId != "") {
                this.stateRequestParam.PoolId.push(this.poolId as string);
            }
            this.isLoading = true;
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        this.isLoading = false;
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        OrderDepartureWharfAppService.InStock(row)
                            .then((r) => {
                                this.isLoading = false;
                                if (r.data.Data == true) {
                                    ElMessage.success("入库成功");
                                    this.handleSubmit();
                                }
                                else if (r.data.Data == false) {
                                    ElMessage.warning("入库失败");
                                }
                            })
                            .catch((err) => {
                                this.isLoading = false;
                            });
                    }
                }
            })
        }

        onOutStock(row: any): void {
            this.id = row.Id;
            this.poolId = row.PoolId;
            this.orderId = row.OrderId;
            if (row.SumQuantityInStock == null || row.SumQuantityInStock == undefined) {
                ElMessage.warning("请先完善入库货量");
                return;
            }
            this.$nextTick(() => {
                const WharfOutStock = this.$refs["WharfOutStock"] as any;
                WharfOutStock.onInit(row.Id);
            });
        }

        onSelectOutStock(row: any): void {
            row.OrderPoolId = this.poolId;
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.stateRequestParam.OrderId.push(this.orderId as string);
            }
            else if (this.poolId != null && this.poolId != undefined && this.poolId != "") {
                this.stateRequestParam.PoolId.push(this.poolId as string);
            }
            this.isLoading = true;
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {
                        OrderDepartureWharfAppService.OutStock(row)
                            .then((r) => {
                                this.isLoading = false;
                                if (r.data.Data == true) {
                                    ElMessage.success("出库成功");
                                    this.handleSubmit();
                                }
                                else if (r.data.Data == false) {
                                    ElMessage.warning("出库失败");
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }
            })
        }

        checkRowButtonShow(
            btn: MiezzButton,
            row: DtoListItemOrderInboundOutbound
        ): boolean {
            if (btn.Code == "shiftout" || btn.Code == "addstock") {
                if (row.IsShiftout == true) {
                    return false;
                }
                if (!row.SumQuantityInStock) {
                    return false;
                }
                if (
                    row.SumQuantityOutStock &&
                    row.SumQuantityInStock &&
                    row.SumQuantityInStock == row.SumQuantityOutStock
                ) {
                    return false;
                }
                if (btn.Code == "addstock" && !row.SumQuantityOutStock) {
                    return false;
                }

                if (btn.Code == "addstock" && !row.IsCheckPool) {
                    return false;
                }
            }
            return true;
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.handleGet();
        }

        /**查询列表 */
        handleGet(): void {
        
           
            this.ports = [];
            if (this.list && this.list.Dto) {
                this.list.Dto.Type = undefined;
                if (this.$route && this.$route.matched) {
                    for (var matched of this.$route.matched) {
                        if (matched.path.toLowerCase() == "/order/inboundoutbound/departure" && this.list && this.list.Dto) {
                            this.list.Dto.Type = EnumOrderServiceType.DeparturePort;
            this.list.DynamicSearchItems = DtoPageListSearchOrderInboundOutboundDynamicSearchItems.filter(it=>it.Prop!="DynamicSearch_DestinationPortIncomingGoodsStatus");
                        }
                        else if (matched.path.toLowerCase() == "/order/inboundoutbound/destination" && this.list && this.list.Dto) {
                            this.list.Dto.Type = EnumOrderServiceType.DestinationPort;
            this.list.DynamicSearchItems = DtoPageListSearchOrderInboundOutboundDynamicSearchItems.filter(it=>it.Prop!="DynamicSearch_DeparturePortIncomingGoodsStatus");
                        }
                    }
                }
            }
            if (this.list && this.list.Dto && this.list.Dto.Type == EnumOrderServiceType.DeparturePort) {
                OrderPoolAppService.GetLoadingPorts().then((r) => {
                    this.ports = r.data.Data ?? [];
                });
            }
            if (this.list && this.list.Dto && this.list.Dto.Type == EnumOrderServiceType.DestinationPort) {
                OrderPoolAppService.GetDeliveryPorts().then((r) => {
                    this.ports = r.data.Data ?? [];
                });
            }

            this.isLoading = true;
            OrderInboundOutboundAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                if (this.list.Data && this.list.Data.Items) {
                    for (var it of this.list.Data.Items) {
                        if (it.SupplierCompanyLabel != null && it.SupplierCompanyLabel != "" && it.SupplierCompanyContactLabel != null && it.SupplierCompanyContactLabel != "")
                            it.Supplier = it.SupplierCompanyLabel + "/" + it.SupplierCompanyContactLabel;
                        else {
                            if (it.SupplierCompanyLabel != null && it.SupplierCompanyLabel != "")
                                it.Supplier = it.SupplierCompanyLabel;
                            else if (it.SupplierCompanyContactLabel != null && it.SupplierCompanyContactLabel != "")
                                it.Supplier = it.SupplierCompanyContactLabel;
                        }
                    }
                }

                this.isLoading = false;
            });
        }

        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemOrderInboundOutbound,
            complete: () => void
        ): void {
            this.id = row.Id;
            this.poolId = row.PoolId;
            this.orderServiceType = row.Type;
            switch (btn.Code) {
                case "shiftout":
                    this.isBatchShiftout = false;
                    this.onShiftout(row);
                    complete();
                    break;
                case "addstock":
                    this.modalChooseService.Show = true;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "batch-statistics":
                    this.tableStatisticsData = [];
                    var it = {
                        SumVolume: 0,
                        SumQuantity: 0,
                        SumGrossWeight: 0,
                        ChargeUnit: "",
                        SumVolumeInStock: 0,
                        SumQuantityInStock: 0,
                        SumGrossWeightInStock: 0,
                        ChargeUnitInStock: "",
                        SumVolumeOutStock: 0,
                        SumQuantityOutStock: 0,
                        SumGrossWeightOutStock: 0,
                        ChargeUnitOutStock: "",
                    };
                    if (this.list && this.list.SelectRows.length > 0) {
                        this.list.SelectRows.forEach((ele) => {
                            if (ele.SumVolume) it.SumVolume += ele.SumVolume;
                            if (ele.SumQuantity) it.SumQuantity += ele.SumQuantity;
                            if (ele.SumGrossWeight) it.SumGrossWeight += ele.SumGrossWeight;
                            if (ele.SumVolumeInStock) it.SumVolumeInStock += ele.SumVolumeInStock;
                            if (ele.SumQuantityInStock) it.SumQuantityInStock += ele.SumQuantityInStock;
                            if (ele.SumGrossWeightInStock) it.SumGrossWeightInStock += ele.SumGrossWeightInStock;
                            if (ele.SumVolumeOutStock) it.SumVolumeOutStock += ele.SumVolumeOutStock;
                            if (ele.SumQuantityOutStock) it.SumQuantityOutStock += ele.SumQuantityOutStock;
                            if (ele.SumGrossWeightOutStock) it.SumGrossWeightOutStock += ele.SumGrossWeightOutStock;
                        });
                        const res1 = new Map();
                        var chargeUnitList = this.list.SelectRows.filter((a) => !res1.has(a.ChargeUnit) && res1.set(a.ChargeUnit, 1)).map(it => it.ChargeUnit).filter(it => it != null);
                        if (chargeUnitList.length > 1) {
                            it.ChargeUnit = "PKGS";
                        } else if (chargeUnitList.length == 1) {
                            var ChargeUnit = chargeUnitList[0];
                            if (ChargeUnit) it.ChargeUnit = ChargeUnit;
                        }

                        const res2 = new Map();
                        var chargeUnitInStockList = this.list.SelectRows.filter((a) => !res2.has(a.ChargeUnitInStock) && res2.set(a.ChargeUnitInStock, 1)).map(it => it.ChargeUnitInStock).filter(it => it != null);
                        if (chargeUnitInStockList.length > 1) {
                            it.ChargeUnitInStock = "PKGS";
                        } else if (chargeUnitInStockList.length == 1) {
                            var ChargeUnitInStock = chargeUnitInStockList[0];
                            if (ChargeUnitInStock) it.ChargeUnitInStock = ChargeUnitInStock;
                        }

                        const res3 = new Map();
                        var chargeUnitOutStockList = this.list.SelectRows.filter((a) => !res3.has(a.ChargeUnitOutStock) && res3.set(a.ChargeUnitOutStock, 1)).map(it => it.ChargeUnitOutStock).filter(it => it != null);
                        if (chargeUnitOutStockList.length > 1) {
                            it.ChargeUnitOutStock = "PKGS";
                        } else if (chargeUnitOutStockList.length == 1) {
                            var ChargeUnitOutStock = chargeUnitOutStockList[0];
                            if (ChargeUnitOutStock) it.ChargeUnitOutStock = ChargeUnitOutStock;
                        }

                        this.tableStatisticsData.push(it);
                    }
                    this.modalStatistics.Show = true;
                    complete();
                    break;
                case "batch-shiftout":
                    this.isBatchShiftout = true;
                    this.onShiftout(this.list.SelectRows);
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**拖曳排序 */
        handleDragSort(
            draggingId?: string,
            dropId?: string,
            type?: EnumUiDragType
        ): void {
            OrderInboundOutboundAppService.DragSort({
                DraggingId: draggingId,
                DropId: dropId,
                DragType: type,
            }).then(() => {
                this.handleGet();
            });
        }
        onSelectShiftout(row: any): void {
            if (this.isBatchShiftout) {
                row.Ids = this.list.SelectIds;
                OrderInboundOutboundAppService.Shiftout(row).then(() => {
                    ElMessage.success("迁移成功");
                    this.handleGet();
                });
            } else {
                if (this.id) {
                    var ids = [this.id];
                    row.Ids = ids;
                    OrderInboundOutboundAppService.Shiftout(row).then(() => {
                        ElMessage.success("迁移成功");
                        this.handleGet();
                    });
                }
            }
        }

        onSelectSupplier(row: any): void {
            OrderDepartureWharfAppService.EditSupplierByOrderDepartureWharfId(
                this.selectWharfId,
                row
            )
                .then(() => {
                    ElMessage.success("修改供应商成功");
                    this.handleGet();
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        onSupplier(row: any): void {
            this.selectWharfId = row.Id;
            const Supplier = this.$refs["Supplier"] as any;
            Supplier.onInit(row);
        }

        onShiftout(row: any): void {
            this.selectWharfId = row.Id;
            const Shiftout = this.$refs["Shiftout"] as any;
            Shiftout.onInit(row, this.isBatchShiftout);
        }

        onBoxSticker(id: string): void {
            const BoxSticker = this.$refs["BoxSticker"] as any;
            BoxSticker.onInit(id);
        }

        onBoxStickerExport(row: any): void {
            this.isLoading = true;
            var param = new DtoRequestBoxSticker();
            param.OrderPoolId = this.poolId;
            param.OrderDepartureWharfId = row.OrderDepartureWharfId;
            OrderDepartureWharfAppService.BoxStickerExport(param)
                .then((r) => {
                    this.isLoading = false;
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");

                    if (this.isAddStock) {
                        ElMessage.warning("当前委托书通过“库存建单”生成，如已在之前使用过箱贴，请勿重复生成");
                    }
                }).catch((err) => {
                    console.log(err);
                });
        }

        onWarehousing(id: string): void {
            const Warehousing = this.$refs["Warehousing"] as any;
            Warehousing.onInit(id);
        }

        onWarehousingExport(row: any): void {
            this.isLoading = true;
            OrderDepartureWharfAppService.ExportWarehousingByOrderDepartureWharfId(row.OrderDepartureWharfId)
                .then((r) => {
                    this.isLoading = false;
                    window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                }).catch((err) => {
                    console.log(err);
                });
        }

        onGoods(row: any): void {
            if (row.Type == EnumOrderServiceType.DeparturePort) {
                let routeUrl = this.$router.resolve({
                    path: "/order/pool/departure-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                    query: { backUrl: this.$route.fullPath },
                });
                //window.open(routeUrl.href);
                window.location.href = routeUrl.href;
            }
            else if (row.Type == EnumOrderServiceType.DestinationPort) {
                let routeUrl = this.$router.resolve({
                    path: "/order/pool/destination-port-wharf/" + row.PoolId + "/cargos/" + row.Id,
                    query: { backUrl: this.$route.fullPath },
                });
                //window.open(routeUrl.href);
                window.location.href = routeUrl.href;
            }

        }

        onInitAnnex() {
            this.onAnnex(this.currentAnnex);
        }
        onAnnex(row: any): void {
            this.poolId = row.PoolId;
            this.currentAnnex = row;
            OrderDepartureWharfAppService.IsBoxStickerByOrderPoolId(this.poolId).then((box) => {
                if (box.data.Data) this.isBoxSticker = box.data.Data;
                OrderDepartureWharfAppService.GetAnnexsByOrderDepartureWharfId(row.Id).then((r) => {

                    if (r.data.Data != undefined) this.annexRuleForm.Data = r.data.Data;

                    this.annexRuleForm.Data.IsDetail = this.isDetail;

                    //表单-校验
                    this.annexRuleForm.Rules = DtoPutOrderDepartureWharfAnnexRules;
                    //表单-元素配置
                    this.annexRuleForm.Items = DtoPutOrderDepartureWharfAnnexMiezzFormItems;


                    if (this.isDetail == true && this.annexBottomButtons) {

                        this.annexBottomButtons[0].Hide = true;
                    }

                    if (this.isDetail == true) {
                        if (this.annexRuleForm.Data.Annexs != undefined && this.annexRuleForm.Data.Annexs.length > 0) {
                            for (var it of this.annexRuleForm.Data.Annexs) {
                                it.Url = this.client?.signatureUrl(it.Url as string);
                            }
                        }
                    }

                    this.annexRuleForm.Data.Visiable = true;


                })

            })

        }
        // onAnnex(row: any): void {
        //   this.poolId = row.PoolId;
        //   this.isAddStock = row.isAddStock;
        //   OrderDepartureWharfAppService.IsBoxStickerByOrderPoolId(this.poolId).then((box) => {
        //       if (box.data.Data) this.isBoxSticker = box.data.Data;
        //       OrderDepartureWharfAppService.GetAnnexsByOrderDepartureWharfId(row.Id).then((r) => {
        //           if (r.data.Data != undefined) this.annexRuleForm.Data = r.data.Data;
        //           this.annexRuleForm.Data.IsDetail = this.isDetail;
        //           //表单-校验
        //           this.annexRuleForm.Rules = DtoPutOrderDepartureWharfAnnexRules;
        //           //表单-元素配置
        //           this.annexRuleForm.Items = DtoPutOrderDepartureWharfAnnexMiezzFormItems;
        //           if (this.isDetail == true && this.annexBottomButtons) {
        //             this.annexBottomButtons[0].Hide = true;
        //           }
        //           if (this.isDetail == true) {
        //               if (this.annexRuleForm.Data.Annexs != undefined && this.annexRuleForm.Data.Annexs.length > 0) {
        //                   for (var it of this.annexRuleForm.Data.Annexs) {
        //                       it.Url = this.client?.signatureUrl(it.Url as string);
        //                   }
        //               }
        //           }
        //           this.annexRuleForm.Data.Visiable = true;
        //           console.log("this.annexRuleForm.Data", this.annexRuleForm.Data)
        //       })
        //   })
        // }

        handleClickAnnex(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "refAnnexRuleForm", (v) => {
                        if (v == true) {
                            OrderDepartureWharfAppService.EditAnnex(
                                this.annexRuleForm.Data
                            ).then((r) => {
                                this.annexRuleForm.Data.Visiable = false;
                                ElMessage.success("编辑附件成功");
                                this.handleGet();
                                complete();
                            });
                        } else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.annexRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }
