import UiSelectOption from "./UiSelectOption";

/**
 * 价格金额类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumPriceAmountType {
    /**
     * 基准价
     */
    Benchmark = 1,
    /**
     * 采购价
     */
    Cost = 2,
    /**
     * 指导价
     */
    Guide = 3,
    /**
     * 结算价
     */
    Settlement = 4,
    /**
     * 协议价
     */
    Agreement = 5,
}

/**
 * 价格金额类型-选项
 */
export const EnumPriceAmountTypeOption: UiSelectOption<number>[] = [
    { Label: "基准价", Value: 1, Data: "Benchmark" },
    { Label: "采购价", Value: 2, Data: "Cost" },
    { Label: "指导价", Value: 3, Data: "Guide" },
    { Label: "结算价", Value: 4, Data: "Settlement" },
    { Label: "协议价", Value: 5, Data: "Agreement" },
];
