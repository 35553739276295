import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumWeighingMethod, EnumWeighingMethodOption } from "./EnumWeighingMethod";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgm extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 集装箱拖车Id
     */
    ContainerTrailerId?: string;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 箱型
     */
    ContainerTypeId?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 称重方式
     */
    WeighingMethod?: EnumWeighingMethod;
    /**
     * VGM重量
     */
    Weight?: number;
    /**
     * VGM责任方
     */
    ResponsibleParty?: string;
    /**
     * 责任人签名
     */
    Sign?: string;
    /**
     * VGM电话
     */
    Tel?: string;
    /**
     * 称重地点
     */
    WeighingAddress?: string;
}

/**
 * -表单校验
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgmRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ContainerNo",
    Label: "箱号",
  },
  {
    Prop: "SealNo",
    Label: "封号",
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
  },
  {
    Prop: "WeighingMethod",
    Label: "称重方式",
    Pipe: EnumPipe.Enum,
    Options: EnumWeighingMethodOption,
  },
  {
    Prop: "Weight",
    Label: "VGM重量",
  },
  {
    Prop: "ResponsibleParty",
    Label: "VGM责任方",
  },
  {
    Prop: "Sign",
    Label: "责任人签名",
  },
  {
    Prop: "Tel",
    Label: "VGM电话",
  },
  {
    Prop: "WeighingAddress",
    Label: "称重地点",
  },
]

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailVgmMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "WeighingMethod",
    Label: "称重方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumWeighingMethodOption,
  },
  {
    Prop: "Weight",
    Label: "VGM重量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ResponsibleParty",
    Label: "VGM责任方",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Sign",
    Label: "责任人签名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Tel",
    Label: "VGM电话",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "WeighingAddress",
    Label: "称重地点",
    Type: EnumMiezzFormItemType.Text,
  },
]
