import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_SealNo extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 封号
     */
    SealNo?: string;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_SealNoRules = {
    SealNo: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_SealNoMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
]
