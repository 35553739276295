
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoListItemCompany, {
  DtoListItemCompanyMiezzListItems,
} from "@/models.machine/DtoListItemCompany";
import DtoPageListSearchCompany, {
  DtoPageListSearchCompanyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumCustomerSource } from "@/models.machine/EnumCustomerSource";
import { EnumSupplierSource } from "@/models.machine/EnumSupplierSource";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyPageList from "../../CompanyPageList.vue";
@Options({
  components: {
    CompanyPageList,
  },
})
export default class CustomerServiceProviderCompanyPageList extends Vue {
  type = EnumCompanyType.Customer;
  list = new MiezzPageList<
    DtoPageListSearchCompany,
    DtoListItemCompany,
    string
  >("CustomerManagement_ServiceProvider");
  /**公司类型选择器 */
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    this.list.Items = DtoListItemCompanyMiezzListItems.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "UserCount" &&
        it.Prop != "BusinessScope" &&
        it.Prop != "Remark" &&
        it.Prop != "InquiryHistory"
    );
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyDynamicSearchItems.filter(
        (it) =>
          it.Prop != "DynamicSearch_ServiceProviderCompanyId" &&
          it.Prop != "DynamicSearch_JoinCompanyUsers" &&
          it.Prop != "DynamicSearch_BusinessScope" &&
          it.Prop != "DynamicSearch_Remark"
      );
      console.log(this.list.DynamicSearchItems)

    CompanyTypeAppService.GetSelect({ Type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });

    //列表-传参
    this.list.Dto = new DtoPageListSearchCompany();
    this.list.Dto.CustomerSource = EnumCustomerSource.ServiceProvider;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_ServiceProvider_Detail",
      },
    ];
  }
}
