import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoFormCompany_JoinCompanyType from "./DtoFormCompany_JoinCompanyType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormRegister {
    constructor() {
        //无参构造函数
        this.JoinCompanyTypes = [];
    }

    [proName: string]: any;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 公司类型
     */
    JoinCompanyTypes?: DtoFormCompany_JoinCompanyType[];
    /**
     * 中文名称
     */
    CompanyNameZh?: string;
    /**
     * 公司英文缩写
     */
    CompanyNameEnAbbr?: string;
    /**
     * 企业代码
     */
    CompanyCode?: string;
    /**
     * 姓名
     */
    UserName?: string;
    /**
     * 手机号码
     */
    PhoneNumber?: string;
    /**
     * 验证码
     */
    VerifyCode?: string;
}

/**
 * -表单校验
 */
export const DtoFormRegisterRules = {
    JoinCompanyTypes: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
            type: "array",
        },
    ],
    CompanyNameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    CompanyNameEnAbbr: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    CompanyCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    UserName: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    PhoneNumber: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
                    return callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            }
        },
    ],
    VerifyCode: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9]{6}$/.test(value)) {
                    return callback(new Error("验证码错误"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormRegisterMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "JoinCompanyTypes",
    Label: "公司类型",
    Customize: true,
  },
  {
    Prop: "CompanyNameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "CompanyNameEnAbbr",
    Label: "公司英文缩写",
    Customize: true,
  },
  {
    Prop: "CompanyCode",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "UserName",
    Label: "姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PhoneNumber",
    Label: "手机号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "VerifyCode",
    Label: "验证码",
    Customize: true,
  },
]
