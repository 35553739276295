
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoFormCompanyLadingBillTemplate, {
  DtoFormCompanyLadingBillTemplateMiezzFormItems,
} from "@/models.machine/DtoFormCompanyLadingBillTemplate";
import DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate, {
  DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplateMiezzListItems,
} from "@/models.machine/DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CurrentCompanyBillOfLadingTemplateForm extends Vue {
  form = new MiezzForm<DtoFormCompanyLadingBillTemplate, string>();
  id?: string;
  list = new MiezzPageList<
    any,
    DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplate,
    string
  >();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      MiezzIcon: "back",
      Label: "返回",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "text",
      Size: "small",
    },
  ];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "提单模板",
        Power: "CompanyManagement_Company_BillOfLadingTemplate_Edit",
      },
    ];

    this.id = this.$route.params.id as string;
    this.form.Data = new DtoFormCompanyLadingBillTemplate();
    this.form.Items = DtoFormCompanyLadingBillTemplateMiezzFormItems;

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items =
      DtoFormCompanyLadingBillTemplate_CompanyLadingBillTemplateMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };

    this.handleGet();
  }

  handleGet(): void {
    CompanyAppService.GetCompanyLadingBillTemplateById(this.id).then((r) => {
      if (r.data.Data) {
        this.form.Data = r.data.Data;
        if (this.list.Data)
          this.list.Data.Items = r.data.Data.LadingBillTemplates;
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.PostCompanyLadingBillTemplate(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
