
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoFormJoinCompanyUser, {
  DtoFormJoinCompanyUserMiezzFormItems,
} from "@/models.machine/DtoFormJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserForm from "../UserForm.vue";
@Options({
  components: {
    UserForm,
  },
})
export default class ServiceProviderUserEdit extends Vue {
  type = EnumCompanyType.ServiceProvider;
  form = new MiezzForm<DtoFormJoinCompanyUser, string>(
    "ServiceProviderManagement_User_Edit"
  );

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "编辑用户",
        Power: "ServiceProviderManagement_User_Edit",
      },
    ];

    this.form.DefaultSpan = 12;
    this.form.Items = DtoFormJoinCompanyUserMiezzFormItems.copy().filter(
      (it) => it.Prop != "ServiceProviderCompanyName"
    );
  }
}
