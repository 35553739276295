
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import PriceAppService from "@/apis.machine/PriceAppService";
import DtoFormPrice from "@/models.machine/DtoFormPrice";
import PriceTableColumnAppService from "@/apis.machine/PriceTableColumnAppService";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoFormPrice_PriceFieldValue from "@/models.machine/DtoFormPrice_PriceFieldValue";
import PriceTableColumnCrud from "./SetUp/PriceTable/PriceTableColumnCrud.vue";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import ParamTransportRouteCodeAppService from "@/apis.machine/ParamTransportRouteCodeAppService";
import {
  EnumSailingTimeType,
  EnumSailingTimeTypeOption,
} from "@/models.machine/EnumSailingTimeType";
import {
  EnumVoyageUnit,
  EnumVoyageUnitOption,
} from "@/models.machine/EnumVoyageUnit";
import { EnumWeek, EnumWeekOption } from "@/models.machine/EnumWeek";
import moment from "moment";
import { EnumParamPriceFieldType } from "@/models.machine/EnumParamPriceFieldType";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";

@Options({
  components: {
    PriceTableColumnCrud,
  },
})
export default class PriceForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() tableId?: string;
  @Prop() supplierId?: string;
  @Prop() amountType?: EnumPriceAmountType;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormPrice, string>();
  formId?: string;

  // btnAdd: MiezzButton = {
  //   Code: "add",
  //   Icon: "plus",
  //   Label: "添加",
  //   Type: "text",
  // };
  btnDelete: MiezzButton = {
    Code: "delete",
    Icon: "delete",
    Title: "删除",
    Type: "default",
    Size: "small",
  };

  columns: UiSelectOption<string>[] = [];
  modalSetting = new MiezzModal();
  columnIds: string[] = [];

  EnumWeekOption = EnumWeekOption;
  EnumSailingTimeType = EnumSailingTimeType;
  EnumParamPriceFieldType = EnumParamPriceFieldType;

  paramUnits: UiSelectOption<string>[] = [];
  paramCurrencySystems: UiSelectOption<string>[] = [];

  created(): void {
    this.formId = this.id;
    this.modelValue.Width = "60%";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.ButtonWidth = "85px";
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "continue-add",
        Label: "继续添加",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "add",
        Label: "选择描述要素",
        Type: "primary",
        Size: "small",
      },
    ];

    ParamTransportChargeUnitAppService.GetSelect().then((r) => {
      this.paramUnits = r.data.Data ?? [];
    });
    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.paramCurrencySystems = r.data.Data ?? [];
    });

    this.handleGet();
  }

  onChargingRangeChanged(index: number): void {
    if (this.form.Data.FieldValues && this.form.Data.FieldValues.length > 0) {
      if (
        (!this.form.Data.FieldValues[index].ChargingRangeMin ||
          this.form.Data.FieldValues[index].ChargingRangeMin == 0) &&
        !this.form.Data.FieldValues[index].ChargingRangeMax
      ) {
        this.form.Data.FieldValues[index].ChargingRangeUnitId = undefined;
      }
    }
  }

  buildColumns(columns: UiSelectOption<string>[]): void {
    this.form.Rules = {};
    const fieldValues: DtoFormPrice_PriceFieldValue[] = [];
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      let item = this.form.Data.FieldValues?.firstOrDefault(
        (f) => f.ColumnId == column.Value
      );
      if (!item) {
        item = new DtoFormPrice_PriceFieldValue();
        item.ColumnId = column.Value;
        switch (column.Data.FieldCode) {
          case "SailingDate":
            item.SailingTimeType = EnumSailingTimeType.Week;
            item.SailingWeeks = [];
            item.SailingWeekArray = [];
            break;
          case "Voyage":
            item.VoyageUnit = EnumVoyageUnit.Day;
            break;
          default:
            break;
        }
      }
      item.Name = column.Label;
      item.ColumnFieldType = column.Data.FieldType;
      item.ColumnFieldCode = column.Data.FieldCode;
      item.Options = item.Options ?? [];

      switch (item.ColumnFieldCode) {
        case "CostId":
          this.form.Rules.CostId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            if (q) {
              ParamPriceCostAppService.GetSelect({ Keywords: q }).then((r) => {
                this.setOptions(i, r.data.Data ?? []);
              });
            }
          };
          if (item && item.CostId && item.Label && item.Options.length == 0) {
            item.Query(item.Label);
          }
          break;
        case "VolumeRatio":
          this.form.Rules[item.ColumnFieldCode] = [
            {
              validator: (rule: any, value: number, callback: any) => {
                if (value != undefined) {
                  if (value <= 0) {
                    return callback(new Error("仅可填入>0的数字"));
                  } else {
                    const unit = this.form.Data.FieldValues?.firstOrDefault(
                      (it) => it.ColumnFieldCode == "MinChargingStandardUnitId"
                    );
                    if (unit) {
                      if (!unit.MinChargingStandardUnitId) {
                        unit.MinChargingStandardUnitId =
                          this.paramUnits.firstOrDefault(
                            (it) => it.Label == "KGS"
                          )?.Value;
                      } else {
                        const paramUnit = this.paramUnits.firstOrDefault(
                          (it) => it.Value == unit.MinChargingStandardUnitId
                        );
                        if (
                          paramUnit?.Label != "KGS" &&
                          paramUnit?.Label != "CBM"
                        ) {
                          unit.MinChargingStandardUnitId =
                            this.paramUnits.firstOrDefault(
                              (it) => it.Label == "KGS"
                            )?.Value;
                        }
                      }
                    }
                  }
                }
                callback();
              },
              trigger: "blur",
            },
          ];
          break;
        case "MinChargingStandardUnitId":
          this.form.Rules.MinChargingStandard = [
            {
              validator: (rule: any, value: number, callback: any) => {
                if (value != undefined) {
                  if (value <= 0) {
                    return callback(new Error("仅可填入>0的数字"));
                  }
                }
                callback();
              },
              trigger: "blur",
            },
          ];
          break;
        case "ChargingRange":
          this.form.Rules.ChargingRangeMin = [
            {
              validator: (rule: any, value: number, callback: any) => {
                if (value != undefined) {
                  if (value < 0) {
                    return callback(new Error("仅可填入≥0的数字"));
                  }
                } else {
                  item!.ChargingRangeMin = 0;
                }
                callback();
              },
              trigger: "blur",
            },
          ];
          this.form.Rules.ChargingRangeMax = [
            {
              validator: (rule: any, value: number, callback: any) => {
                if (value != undefined) {
                  if (value <= 0) {
                    return callback(new Error("仅可填入>0的数字"));
                  } else if (
                    item &&
                    item.ChargingRangeMin &&
                    value <= item.ChargingRangeMin
                  ) {
                    return callback(new Error("左侧数值应<右侧数值"));
                  }
                }
                callback();
              },
              trigger: "blur",
            },
          ];
          this.form.Rules.ChargingRangeUnitId = [
            {
              validator: (rule: any, value: number, callback: any) => {
                if (
                  item &&
                  ((item.ChargingRangeMin && item.ChargingRangeMin > 0) ||
                    item.ChargingRangeMax)
                ) {
                  if (!value) {
                    return callback(new Error("信息不能为空"));
                  }
                }
                callback();
              },
              trigger: "change",
            },
          ];
          break;
        case "CurrencySystemId":
          this.form.Rules.CurrencySystemId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          break;
        case "SecondChargingUnitId":
          this.form.Rules.SecondChargingUnitId = [
            {
              validator: (rule: any, value: string, callback: any) => {
                if (value) {
                  const first = this.form.Data.FieldValues?.firstOrDefault(
                    (it) => it.ColumnFieldCode == "FirstChargingUnitId"
                  );
                  if (first && first.FirstChargingUnitId == value) {
                    return callback(
                      new Error("第二计价单位不能与第一计价单位重复")
                    );
                  }
                }
                callback();
              },
              trigger: "change",
            },
          ];
          break;
        case "CountryId":
          this.form.Rules.CountryId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            if (q) {
              ParamCountryAppService.GetSelect({ Keywords: q }).then((r) => {
                this.setOptions(i, r.data.Data ?? []);
              });
            }
          };
          if (
            item &&
            item.CountryId &&
            item.Label &&
            item.Options.length == 0
          ) {
            item.Query(item.Label);
          }
          break;
        case "PortId":
          this.form.Rules.PortId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            if (q) {
              ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
                this.setOptions(i, r.data.Data ?? []);
              });
            }
          };
          if (item && item.PortId && item.Label && item.Options.length == 0) {
            item.Query(item.Label);
          }
          break;
        case "AreaId":
          this.form.Rules.AreaId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            if (q) {
              ParamCountryAreaAppService.GetSelect({ Keywords: q }).then(
                (r) => {
                  this.setOptions(i, r.data.Data ?? []);
                }
              );
            }
          };
          if (item && item.AreaId && item.Label && item.Options.length == 0) {
            item.Query(item.Label);
          }
          break;
        case "CarrierId":
          this.form.Rules.CarrierId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then(
              (r) => {
                this.setOptions(i, r.data.Data ?? []);
              }
            );
          };
          if (
            item &&
            item.CarrierId &&
            item.Label &&
            item.Options.length == 0
          ) {
            item.Query(item.Label);
          }
          break;
        case "ChannelId":
          this.form.Rules.ChannelId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            ParamTransportChannelAppService.GetSelect({
              Keywords: q,
              Take: 10,
            }).then((r) => {
              this.setOptions(i, r.data.Data ?? []);
            });
          };
          if (
            item &&
            item.ChannelId &&
            item.Label &&
            item.Options.length == 0
          ) {
            item.Query(item.Label);
          }
          break;
        case "RouteCodeId":
          this.form.Rules.RouteCodeId = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          item.Query = (q: string) => {
            ParamTransportRouteCodeAppService.GetSelect({
              Keywords: q,
              Take: 10,
            }).then((r) => {
              this.setOptions(i, r.data.Data ?? []);
            });
          };
          if (
            item &&
            item.RouteCodeId &&
            item.Label &&
            item.Options.length == 0
          ) {
            item.Query(item.Label);
          }
          break;
        case "Voyage":
          item.Options = EnumVoyageUnitOption;
          this.form.Rules.Voyage = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
          ];
          this.form.Rules.VoyageUnit = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          break;
        case "SailingDate":
          item.Options = EnumSailingTimeTypeOption;
          this.form.Rules.SailingTimeType = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          this.form.Rules.SailingWeekArray = [
            {
              validator: (rule: any, value: number, callback: any) => {
                const type = this.form.Data.FieldValues?.firstOrDefault(
                  (it) => it.ColumnFieldCode == "SailingDate"
                )?.SailingTimeType;
                if (type == EnumSailingTimeType.Week && !value) {
                  return callback(new Error("信息不能为空"));
                }
                callback();
              },
              trigger: "change",
            },
          ];
          this.form.Rules.SailingDate = [
            {
              validator: (rule: any, value: number, callback: any) => {
                const type = this.form.Data.FieldValues?.firstOrDefault(
                  (it) => it.ColumnFieldCode == "SailingDate"
                )?.SailingTimeType;
                if (type == EnumSailingTimeType.Day && !value) {
                  return callback(new Error("信息不能为空"));
                }
                callback();
              },
              trigger: "change",
            },
          ];
          if (item.SailingTimeType == EnumSailingTimeType.Week) {
            item.SailingWeekArray = item.SailingWeeks?.map(
              (it) => it.SailingWeek
            );
          }
          break;

        default:
          break;
      }
      fieldValues.push(item);
    }
    this.$nextTick(() => {
      this.form.Data.FieldValues = fieldValues;
    });
  }

  /**查询表单数据 */
  handleGet(): void {
    if (!this.form.Data && this.columns.length == 0) {
      PriceTableColumnAppService.GetSelect({
        TableId: this.tableId,
        AmountType: this.amountType,
        // ShowBSGA:
        //   this.amountType == EnumPriceAmountType.Agreement ||
        //   this.amountType == EnumPriceAmountType.Benchmark ||
        //   this.amountType == EnumPriceAmountType.Settlement ||
        //   this.amountType == EnumPriceAmountType.Guide
        //     ? true
        //     : undefined,
        // ShowCost:
        //   this.amountType == EnumPriceAmountType.Cost ? true : undefined,
      }).then((r) => {
        this.columns = r.data.Data ?? [];
        this.handleGetFormData();
      });
    } else {
      this.handleGetFormData();
    }
  }

  handleGetFormData(): void {
    if (this.formId) {
      PriceAppService.GetById(this.formId).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          this.form.Data.AmountType = this.amountType;
          const columns = this.columns.filter((it) =>
            this.form.Data.FieldValues?.any((f) => f.ColumnId == it.Value)
          );
          this.buildColumns(columns);
          this.onAmountChanged(
            this.form.Data.FieldValues?.firstOrDefault(
              (it) => it.ColumnFieldCode == "Amount"
            )
          );
        }
      });
    } else {
      if (!this.form.Data) {
        //表单-数据-初始化
        this.form.Data = new DtoFormPrice();
        this.form.Data.AmountType = this.amountType;
        this.form.Data.TableId = this.tableId;
        this.form.Data.SupplierId = this.supplierId;
        this.buildColumns(this.columns);
      } else {
        const columns = this.columns.filter((it) =>
          this.form.Data.FieldValues?.any((f) => f.ColumnId == it.Value)
        );
        this.buildColumns(columns);
      }
    }
  }

  setOptions(i: number, options: UiSelectOption<string>[]): void {
    const fields = this.form.Data.FieldValues;
    if (fields) {
      fields[i].Options = options;
    }
  }

  onAmountChanged(item?: DtoFormPrice_PriceFieldValue): void {
    if (!item || (item.Amount == undefined && !item.AmountFormula)) {
      this.form.Rules.FirstChargingUnitId = [];
    } else {
      this.form.Rules.FirstChargingUnitId = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }
  }

  handleDeleteField(
    btn: MiezzButton,
    complete: () => void,
    columnId?: string
  ): void {
    const columns = this.columns.filter(
      (it) =>
        this.form.Data.FieldValues?.any((f) => f.ColumnId == it.Value) &&
        it.Value != columnId
    );
    this.buildColumns(columns);
  }

  getNumberText(n?: number): string | undefined {
    if (n != undefined) {
      const str = n.toRound(2);
      if (str) {
        return parseFloat(str).toString();
      }
    }
    return undefined;
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.columnIds =
          this.form.Data.FieldValues?.map((it) => it.ColumnId as string) ?? [];
        this.modalSetting.Show = true;
        complete();
        break;
      case "continue-add":
      case "submit":
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (v) {
            for (const item of this.form.Data.FieldValues ?? []) {
              switch (item.ColumnFieldCode) {
                case "VolumeRatio":
                  item.Label = this.getNumberText(item.VolumeRatio);
                  break;
                case "Amount":
                  item.Label = item.AmountFormula
                    ? item.AmountFormula
                    : item.Amount != undefined
                    ? this.getNumberText(item.Amount)
                    : "电询";
                  break;
                case "MinChargingStandardUnitId":
                  if (
                    item.MinChargingStandard &&
                    item.MinChargingStandardUnitId
                  ) {
                    item.Label = `${
                      this.getNumberText(item.MinChargingStandard) ?? ""
                    }${item.MinChargingStandardUnitLabel}`;
                  } else if (item.MinChargingStandard) {
                    item.Label =
                      this.getNumberText(item.MinChargingStandard) ?? "";
                  } else if (item.MinChargingStandardUnitId) {
                    item.Label = item.MinChargingStandardUnitLabel;
                  } else {
                    item.Label = undefined;
                  }
                  break;
                case "ChargingRange":
                  if (item.ChargingRangeUnitId) {
                    if (item.ChargingRangeMax) {
                      item.Label = `${
                        this.getNumberText(item.ChargingRangeMin) ?? ""
                      }-${this.getNumberText(item.ChargingRangeMax) ?? ""} ${
                        item.ChargingRangeUnitLabel
                      }`;
                    } else if (item.ChargingRangeMin) {
                      item.Label = `＞${
                        this.getNumberText(item.ChargingRangeMin) ?? ""
                      } ${item.ChargingRangeUnitLabel}`;
                    }
                  } else {
                    item.Label = undefined;
                  }
                  break;
                case "Voyage":
                  if (item.Voyage && item.VoyageUnit) {
                    item.Label = `${item.Voyage ?? ""}${item.VoyageUnitLabel}`;
                  } else {
                    item.Label = undefined;
                  }
                  break;
                case "SailingDate":
                  if (item.SailingTimeType == EnumSailingTimeType.Week) {
                    item.SailingDate = undefined;
                    item.SailingWeeks = item.SailingWeekArray?.map(
                      (it: EnumWeek) => {
                        const week = item.SailingWeeks?.firstOrDefault(
                          (w) => w.SailingWeek == it
                        );
                        return week ? week : { SailingWeek: it };
                      }
                    );
                    item.Label = item.SailingWeeks?.sort(
                      (a, b) => (a.SailingWeek ?? 0) - (b.SailingWeek ?? 0)
                    )
                      ?.map((it) => {
                        return EnumWeekOption.firstOrDefault(
                          (o) => o.Value == it.SailingWeek
                        )?.Label;
                      })
                      .join("/");
                  } else if (item.SailingTimeType == EnumSailingTimeType.Day) {
                    item.SailingWeeks = [];
                    item.SailingWeekArray = [];
                    item.Label = item.SailingDate
                      ? moment(item.SailingDate).format("YYYY/MM/DD")
                      : undefined;
                  } else {
                    item.Label = undefined;
                  }
                  break;
                case "Remark":
                  item.Label = item.Remark;
                  break;
                default:
                  break;
              }
            }
            PriceAppService.Post(this.form.Data)
              .then(() => {
                if (btn.Code == "continue-add") {
                  this.formId = undefined;
                  const formData = new DtoFormPrice();
                  formData.TableId = this.tableId;
                  formData.SupplierId = this.supplierId;
                  formData.AmountType = this.amountType;
                  formData.FieldValues = [];
                  for (const item of this.form.Data.FieldValues ?? []) {
                    const field = new DtoFormPrice_PriceFieldValue();
                    field.ColumnFieldCode = item.ColumnFieldCode;
                    field.ColumnId = item.ColumnId;
                    if (
                      item.ColumnFieldCode == "CostId" ||
                      item.ColumnFieldCode == "VolumeRatio" ||
                      item.ColumnFieldCode == "MinChargingStandardUnitId" ||
                      item.ColumnFieldCode == "CurrencySystemId" ||
                      item.ColumnFieldCode == "FirstChargingUnitId"
                    ) {
                      field.Label = item.Label;
                      field.CostId = item.CostId;
                      field.VolumeRatio = item.VolumeRatio;
                      field.MinChargingStandard = item.MinChargingStandard;
                      field.MinChargingStandardUnitId =
                        item.MinChargingStandardUnitId;
                      field.MinChargingStandardUnitLabel =
                        item.MinChargingStandardUnitLabel;
                      field.CurrencySystemId = item.CurrencySystemId;
                      field.FirstChargingUnitId = item.FirstChargingUnitId;
                    }
                    formData.FieldValues.push(field);
                  }
                  this.form.Data = new DtoFormPrice_PriceFieldValue();
                  this.$nextTick(() => {
                    this.form.Data = formData;
                    this.handleGet();
                  });
                } else {
                  this.modelValue.Show = false;
                }
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }
  handleChooseColumn(ids: string[]): void {
    const ids2 =
      this.form.Data.FieldValues?.filter(
        (it) => it.ColumnFieldType == 1 || it.ColumnFieldType == 2
      )?.map((it) => it.ColumnId as string) ?? [];
    const ids3 = [...ids, ...ids2];
    const columns = this.columns.filter((it) => ids3.contains(it.Value));
    this.buildColumns(columns);
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
