import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceTableSupplierFile_File from "./DtoFormPriceTableSupplierFile_File";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceTableSupplierFile extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Files = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 附件
     */
    Files?: DtoFormPriceTableSupplierFile_File[];
}

/**
 * -表单校验
 */
export const DtoFormPriceTableSupplierFileRules = {
}

/**
 * -设置项
 */
export const DtoFormPriceTableSupplierFileMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
]
