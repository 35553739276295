import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'Type')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "发票种类",
            prop: "Type",
            rules: _ctx.form.Rules.Type
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入发票种类",
                onHandleBlur: _ctx.onBlurType,
                pattern: '([\u4e00-\u9fa5])|([\u3000-\u301e\ufe10-\ufe19\ufe30-\ufe44\ufe50-\ufe6b\uff01-\uffee])'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "pattern"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true),
      (item.Prop == 'Name')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "税率",
            prop: "Name",
            rules: _ctx.form.Rules.Name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入税率",
                onHandleBlur: _ctx.onBlurName,
                trim: true
              }, {
                append: _withCtx(() => [
                  _createTextVNode("%")
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}