import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolContainerTrailer from "../models.machine/DtoPageListSearchOrderPoolContainerTrailer";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolContainerTrailer from "../models.machine/DtoPageListOrderPoolContainerTrailer";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "../models.machine/EnumOrderServiceType";
import DtoPutOrderPoolContainerTrailer_ContainerType from "../models.machine/DtoPutOrderPoolContainerTrailer_ContainerType";
import DtoPutOrderPoolContainerTrailer_Addresses from "../models.machine/DtoPutOrderPoolContainerTrailer_Addresses";
import DtoPutOrderPoolContainerTrailer_ArrivalTime from "../models.machine/DtoPutOrderPoolContainerTrailer_ArrivalTime";
import DtoPutOrderPoolContainerTrailer_Supplier from "../models.machine/DtoPutOrderPoolContainerTrailer_Supplier";
import DtoPutOrderPoolContainerTrailer_Executor from "../models.machine/DtoPutOrderPoolContainerTrailer_Executor";
import DtoPutOrderPoolContainerTrailer_CarryContainerArea from "../models.machine/DtoPutOrderPoolContainerTrailer_CarryContainerArea";
import DtoPutOrderPoolContainerTrailer_CarryContainerTime from "../models.machine/DtoPutOrderPoolContainerTrailer_CarryContainerTime";
import DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime from "../models.machine/DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime";
import DtoPutOrderPoolContainerTrailer_IntoPortWharf from "../models.machine/DtoPutOrderPoolContainerTrailer_IntoPortWharf";
import DtoPutOrderPoolContainerTrailer_BackContainerTime from "../models.machine/DtoPutOrderPoolContainerTrailer_BackContainerTime";
import DtoCheckExistOrderPoolContainerTrailerContainerNo from "../models.machine/DtoCheckExistOrderPoolContainerTrailerContainerNo";
import DtoPutOrderPoolContainerTrailer_ContainerNo from "../models.machine/DtoPutOrderPoolContainerTrailer_ContainerNo";
import DtoPutOrderPoolContainerTrailer_SealNo from "../models.machine/DtoPutOrderPoolContainerTrailer_SealNo";
import DtoPutOrderPoolContainerTrailer_File from "../models.machine/DtoPutOrderPoolContainerTrailer_File";
import DtoHistory from "../models.machine/DtoHistory";
import DtoRequestOrderPoolContainerTrailerChart from "../models.machine/DtoRequestOrderPoolContainerTrailerChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";
import UiSelectOption from "../models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 委托书-集装箱拖车
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolContainerTrailerAppService {
    /**
     * 获取委托书-集装箱拖车分页列表
     * @param {DtoPageListSearchOrderPoolContainerTrailer} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolContainerTrailer>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolContainerTrailer): AxiosPromise<RESTfulResult<DtoPageListOrderPoolContainerTrailer>> {
        const url = `/api/OrderPoolContainerTrailer/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolContainerTrailer>>(url, { params: dto });
    }

    /**
     * 添加集装箱拖车
     * @param {string} [poolId] 委托书Id
     * @param {EnumOrderServiceType} [serviceType] 服务类型
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostByPoolIdByServiceType(poolId?: string, serviceType?: EnumOrderServiceType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/Post/${poolId}/${serviceType}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 更新箱型
     * @param {DtoPutOrderPoolContainerTrailer_ContainerType} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutContainerType(dto?: DtoPutOrderPoolContainerTrailer_ContainerType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutContainerType`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提货地址与时间
     * @param {DtoPutOrderPoolContainerTrailer_Addresses} [dto] 表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutAddresses(dto?: DtoPutOrderPoolContainerTrailer_Addresses): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutAddresses`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑抵达时间
     * @param {DtoPutOrderPoolContainerTrailer_ArrivalTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutArrivalTime(dto?: DtoPutOrderPoolContainerTrailer_ArrivalTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutArrivalTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑供应商
     * @param {DtoPutOrderPoolContainerTrailer_Supplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutSupplier(dto?: DtoPutOrderPoolContainerTrailer_Supplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutSupplier`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑执行人
     * @param {DtoPutOrderPoolContainerTrailer_Executor} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutExecutor(dto?: DtoPutOrderPoolContainerTrailer_Executor): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutExecutor`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑提柜地点
     * @param {DtoPutOrderPoolContainerTrailer_CarryContainerArea} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryContainerArea(dto?: DtoPutOrderPoolContainerTrailer_CarryContainerArea): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutCarryContainerArea`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑实际提柜时间
     * @param {DtoPutOrderPoolContainerTrailer_CarryContainerTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutCarryContainerTime(dto?: DtoPutOrderPoolContainerTrailer_CarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑计划提柜时间
     * @param {DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutPlanCarryContainerTime(dto?: DtoPutOrderPoolContainerTrailer_PlanCarryContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutPlanCarryContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑进港码头
     * @param {DtoPutOrderPoolContainerTrailer_IntoPortWharf} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutIntoPortWharf(dto?: DtoPutOrderPoolContainerTrailer_IntoPortWharf): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutIntoPortWharf`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑还柜时间
     * @param {DtoPutOrderPoolContainerTrailer_BackContainerTime} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutBackContainerTime(dto?: DtoPutOrderPoolContainerTrailer_BackContainerTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutBackContainerTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 校验箱号是否存在
     * @param {DtoCheckExistOrderPoolContainerTrailerContainerNo} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckExistContainerNo(dto?: DtoCheckExistOrderPoolContainerTrailerContainerNo): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderPoolContainerTrailer/CheckExistContainerNo`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 编辑箱号
     * @param {DtoPutOrderPoolContainerTrailer_ContainerNo} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutContainerNo(dto?: DtoPutOrderPoolContainerTrailer_ContainerNo): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutContainerNo`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑封号
     * @param {DtoPutOrderPoolContainerTrailer_SealNo} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutSealNo(dto?: DtoPutOrderPoolContainerTrailer_SealNo): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutSealNo`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑附件
     * @param {DtoPutOrderPoolContainerTrailer_File} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutFile(dto?: DtoPutOrderPoolContainerTrailer_File): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/PutFile`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取历史记录
     * @param {DtoPageListSearchOrderPoolContainerTrailer} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistories(dto?: DtoPageListSearchOrderPoolContainerTrailer): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetHistories`;
        return service.get<RESTfulResult<DtoHistory[]>>(url, { params: dto });
    }

    /**
     * 批量删除委托书-集装箱拖车
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolContainerTrailer/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderPoolContainerTrailerChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar[]>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderPoolContainerTrailerChart): AxiosPromise<RESTfulResult<UiEChartsBar[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar[]>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderPoolContainerTrailerChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie[]>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderPoolContainerTrailerChart): AxiosPromise<RESTfulResult<UiEChartsPie[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie[]>>(url, { params: dto });
    }

    /**
     * 获取委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClients(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetClients`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取箱型选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetContainerTypes(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetContainerTypes`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取坐标选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetAreas(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetAreas`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取卸货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPoolContainerTrailer/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
