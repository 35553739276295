
import MiezzButtonSource from "./MiezzButtonSource.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
@Options({
  components: {
    MiezzButtonSource,
  },
})
export default class MiezzButtonBar extends Vue {
  @Model() modelValue: MiezzButton[] = [];
  @Prop() isBackground?: boolean;
  @Prop() isFix?: boolean;
  @Prop() right?: string;
  @Prop() close?: boolean;
  closeBtn: MiezzButton = {
    Code: "close",
    Label: "关闭",
    Type: "default",
    Size: "small",
  };
  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "back":
        CurrentLogier.Back();
        complete();
        break;

      default:
        break;
    }
  }

  @Watch("modelValue", {
    deep: true,
  })
  onModelValueChanged(): void {
    for (const button of this.modelValue ?? []) {
      if (button.IsBlue != true) {
        button.Type = "text";
      }

      if (button.Title && !button.Label) {
        button.Label = button.Title;
        button.Title = undefined;
      }
    }
  }
}
