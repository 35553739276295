import DtoCallbackBillSum from "./DtoCallbackBillSum";
import DtoListItemFinance from "./DtoListItemFinance";
import DtoStatInvoiceItem from "./DtoStatInvoiceItem";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackStat {
    constructor() {
        //无参构造函数
        this.Items = [];
        this.InvoiceItems = [];
        this.CollectionCount = 0;
        this.PaymentCount = 0;
    }

    [proName: string]: any;
    /**
     * 合计
     */
    Sum?: DtoCallbackBillSum;
    /**
     * 清单
     */
    Items?: DtoListItemFinance[];
    /**
     * 发票明细
     */
    InvoiceItems?: DtoStatInvoiceItem[];
    /**
     * 是否显示弹出框
     */
    Visiable?: boolean;
    /**
     * 合计
     */
    SumString?: string;
    /**
     * 应收账单数
     */
    CollectionCount?: number;
    /**
     * 应付账单数
     */
    PaymentCount?: number;
}

/**
 * -表单校验
 */
export const DtoCallbackStatRules = {
}
