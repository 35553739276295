import UiSelectOption from "./UiSelectOption";

/**
 * 开航日期类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumSailingTimeType {
    /**
     * 周
     */
    Week = 7,
    /**
     * 日
     */
    Day = 1,
}

/**
 * 开航日期类型-选项
 */
export const EnumSailingTimeTypeOption: UiSelectOption<number>[] = [
    { Label: "周", Value: 7, Data: "Week" },
    { Label: "日", Value: 1, Data: "Day" },
];
