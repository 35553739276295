import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createBlock(_component_miezz_card, {
    title: "待办时效规则",
    fix: true,
    bottomButtons: _ctx.buttons,
    onHandleFooterButtonClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'OvertimeRules')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                "label-width": "0px",
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_page_list, {
                    modelValue: _ctx.list,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event))
                  }, {
                    column: _withCtx(({ model, row, index }) => [
                      (index == -1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                        : (model.Prop == 'Type')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createVNode(_component_el_form_item, {
                                prop: `OvertimeRules[${index}]`,
                                rules: _ctx.form.Rules.Type
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_miezz_select, {
                                    modelValue: row.Type,
                                    "onUpdate:modelValue": ($event: any) => ((row.Type) = $event),
                                    options: 
                      row.Types ??
                      _ctx.EnumCompanyOvertimeRuleTypeOption.filter(
                        (it) => it.Data != 'CreatedTime'
                      )
                    
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                                ]),
                                _: 2
                              }, 1032, ["prop", "rules"])
                            ]))
                          : (model.Prop == 'Overtime')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createVNode(_component_el_form_item, {
                                  prop: `OvertimeRules[${index}]`,
                                  rules: _ctx.form.Rules.Overtime
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_miezz_input, {
                                      modelValue: row.Overtime,
                                      "onUpdate:modelValue": ($event: any) => ((row.Overtime) = $event),
                                      number: true,
                                      pattern: "[0-9-]"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1032, ["prop", "rules"])
                              ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["bottomButtons", "onHandleFooterButtonClick"]))
}