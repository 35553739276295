import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMenuPower, EnumMenuPowerOption } from "./EnumMenuPower";
import { EnumMenuType, EnumMenuTypeOption } from "./EnumMenuType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemMenu extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Power = EnumMenuPower.Everyone;
        this.Type = EnumMenuType.Menu;
    }

    [proName: string]: any;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 父名称
     */
    ParentName?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 菜单权限
     */
    Power?: EnumMenuPower;
    /**
     * 类型
     */
    Type?: EnumMenuType;
    /**
     * 链接地址
     */
    Url?: string;
}

/**
 * -表单校验
 */
export const DtoPageListItemMenuRules = {
}

/**
 * -设置项
 */
export const DtoPageListItemMenuMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Path",
    Label: "路径",
  },
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "Power",
    Label: "菜单权限",
    Pipe: EnumPipe.Enum,
    Options: EnumMenuPowerOption,
  },
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumMenuTypeOption,
  },
  {
    Prop: "Url",
    Label: "链接地址",
  },
]
