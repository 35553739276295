import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 船公司分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamShippingCompany extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 英文缩写
     */
    AbbrEn?: string;
    /**
     * 查询网址
     */
    Url?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 船公司分页列表-项-表单校验
 */
export const DtoListItemParamShippingCompanyRules = {
}

/**
 * 船公司分页列表-项-设置项
 */
export const DtoListItemParamShippingCompanyMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "AbbrEn",
    Label: "英文缩写",
  },
  {
    Prop: "Url",
    Label: "查询网址",
  },
]
