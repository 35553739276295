import UiSelectOption from "./UiSelectOption";

/**
 * 单证类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumDocumentType {
    /**
     * 出口
     */
    Export = 10,
    /**
     * 进口
     */
    Import = 20,
}

/**
 * 单证类型-选项
 */
export const EnumDocumentTypeOption: UiSelectOption<number>[] = [
    { Label: "出口", Value: 10, Data: "Export" },
    { Label: "进口", Value: 20, Data: "Import" },
];
