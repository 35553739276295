import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompare {
    /**
     * 相等
     */
    Equal = 1,
    /**
     * 不等
     */
    NoEqual = 2,
    /**
     * 大于
     */
    GreaterThan = 3,
    /**
     * 大于等于
     */
    GreaterThanEqual = 4,
    /**
     * 小于
     */
    LessThan = 5,
    /**
     * 小于等于
     */
    LessThanEqual = 6,
    /**
     * 包含
     */
    Contain = 7,
    /**
     * 不包含
     */
    NoContain = 8,
    /**
     * 介于
     */
    Between = 9,
    /**
     * 不介于
     */
    NoBetween = 10,
}

/**
 * -选项
 */
export const EnumCompareOption: UiSelectOption<number>[] = [
    { Label: "相等", Value: 1, Data: "Equal" },
    { Label: "不等", Value: 2, Data: "NoEqual" },
    { Label: "大于", Value: 3, Data: "GreaterThan" },
    { Label: "大于等于", Value: 4, Data: "GreaterThanEqual" },
    { Label: "小于", Value: 5, Data: "LessThan" },
    { Label: "小于等于", Value: 6, Data: "LessThanEqual" },
    { Label: "包含", Value: 7, Data: "Contain" },
    { Label: "不包含", Value: 8, Data: "NoContain" },
    { Label: "介于", Value: 9, Data: "Between" },
    { Label: "不介于", Value: 10, Data: "NoBetween" },
];
