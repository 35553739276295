import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumParamCarrierType, EnumParamCarrierTypeOption } from "./EnumParamCarrierType";
import UiGroup from "./UiGroup";

/**
 * 承运人表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamCarrier extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 查询网址
     */
    Url?: string;
    /**
     * 承运人类别
     */
    Type?: EnumParamCarrierType;
    /**
     * 鸭嘴兽代码
     */
    YaZuiShouCode?: string;
    /**
     * 鸭嘴兽名称
     */
    YaZuiShouName?: string;
    /**
     * 飞鸵名称
     */
    FreighTowerId?: string;
    /**
     * 飞鸵接口-航空公司Id
     */
    FreighTowerAirlineCompanyId?: string;
    /**
     * 飞鸵接口-船公司Id
     */
    FreighTowerShippingCompanyId?: string;
}

/**
 * 承运人表单-表单校验
 */
export const DtoFormParamCarrierRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Abridge: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    Url: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    YaZuiShouCode: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    YaZuiShouName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 承运人表单-设置项
 */
export const DtoFormParamCarrierMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Abridge",
    Label: "缩写",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
  {
    Prop: "Url",
    Label: "查询网址",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Type",
    Label: "承运人类别",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumParamCarrierTypeOption,
  },
  {
    Prop: "YaZuiShouCode",
    Label: "鸭嘴兽代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "YaZuiShouName",
    Label: "鸭嘴兽名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "FreighTowerId",
    Label: "飞鸵名称",
    Customize: true,
  },
]
