
import CodeControllerAppService from "@/apis.machine/CodeControllerAppService";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
import router from "@/router";
import CodeMethodAppService from "@/apis.machine/CodeMethodAppService";
import { ElMessage } from "element-plus";
import TranslateAppService from "@/apis.machine/TranslateAppService";
import DtoFormCodeDtoCustom, {
  DtoFormCodeDtoCustomMiezzFormItems,
  DtoFormCodeDtoCustomRules,
} from "@/models.machine/DtoFormCodeDtoCustom";
import CodeDtoAppService from "@/apis.machine/CodeDtoAppService";

@Options({
  components: {
    CodePropertyTree,
  },
})
export default class CodeDtoCustomForm extends Vue {
  @Prop() id?: string;

  form = new MiezzForm<DtoFormCodeDtoCustom, string>();
  buttons = [
    {
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
  ];
  modal = new MiezzModal();
  drawerCode?: string;
  drawerFormData?: any;

  created(): void {
    this.form.Data = new DtoFormCodeDtoCustom();
    this.form.DefaultSpan = 12;
    this.form.Rules = DtoFormCodeDtoCustomRules;
    this.form.Items = DtoFormCodeDtoCustomMiezzFormItems;

    const query = router.currentRoute.value.query;
    if (query.Group) {
      this.form.Data.Group = query.Group as string;
      if (query.App) {
        this.form.Data.App = query.App as string;
      }
    }

    for (const item of this.form.Items) {
      switch (item.Prop) {
        case "Group":
          CodeControllerAppService.GetGroupSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        case "Table":
          CodeControllerAppService.GetTableSelect().then((r) => {
            item.Options = r.data.Data;
            const appTable = item.Options?.firstOrDefault(
              (it) => it.Value == this.form.Data.App
            );
            if (appTable) {
              this.form.Data.Table = appTable.Value;
            }
          });
          break;
        case "IdType":
          item.AllowCreate = true;
          item.Options = [
            { Label: "Guid", Value: "Guid" },
            { Label: "string", Value: "string" },
            { Label: "int", Value: "int" },
            { Label: "long", Value: "long" },
          ];
          break;
        default:
          break;
      }
    }
  }

  @Watch("form.Data.Group")
  onGroupChanged(): void {
    const app = this.form.Items?.find((it) => it.Prop == "App");
    CodeControllerAppService.GetAppSelectByGroup(this.form.Data.Group).then(
      (r) => {
        if (app && r.data.Data) {
          app.Options = r.data.Data;
        }
      }
    );
  }

  @Watch("form.Data.Table")
  onTableChanged(): void {
    if (this.form.Data.Table) {
      const table = this.form.Items?.find((it) => it.Prop == "Table");
      const option = table?.Options?.firstOrDefault(
        (it) => it.Value == this.form.Data.Table
      );
      this.form.Data.IdType = option?.Data.IdType;
    }
  }

  handleSubmit(): void {
    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
      if (v) {
        CodeDtoAppService.CreateCustomDto(this.form.Data).then(() => {
          ElMessage.success("添加成功");
          router.push("/developer/tool/controller");
        });
      }
    });
  }
}
