
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import CurrentLogier from "@/apis/CurrentLogier";
    import moment from "moment";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import OrderWarehouseAppService from "@/apis.machine/OrderWarehouseAppService";
    import DtoDetailOrderWarehouse, {
        DtoDetailOrderWarehouseMiezzDetailItems,
    } from "@/models.machine/DtoDetailOrderWarehouse";
    import DtoFormOrderWarehousePackageShape, {
        DtoFormOrderWarehousePackageShareMiezzFormItems,
        DtoFormOrderWarehousePackageShareRules,
    } from "@/models.machine/DtoFormOrderWarehousePackageShare";
    import DtoFormOrderWarehousePackageSpec, {
        DtoFormOrderWarehousePackageSpecMiezzFormItems,
        DtoFormOrderWarehousePackageSpecRules,
    } from "@/models.machine/DtoFormOrderWarehousePackageSpec";

    export default class OrderWarehouseDetail extends Vue {
        client?: OSS;
        id?: string;
        isLoading = false;
        //类型：20销售，10采购
        type = "";
        detail = new MiezzForm<DtoDetailOrderWarehouse, string>();
        detailTemp = new MiezzForm<DtoDetailOrderWarehouse, string>();
        /**权限编码 */
        powerCodes?: string[] = [];
        /*销售订单的编辑权限 */
        isSaleEdit?: boolean;
        /*采购i订单的编辑权限*/
        isPurchaseEdit?: boolean;

        /*销售订单的删除权限*/
        isSaleDelete?: boolean;
        /*采购i订单的删除权限*/
        isPurchaseDelete?: boolean;

        shapeRuleForm = new MiezzForm<DtoFormOrderWarehousePackageShape, string>();
        specRuleForm = new MiezzForm<DtoFormOrderWarehousePackageSpec, string>();
        imageRuleForm = {
            visiable: false,
            row: undefined,
        };
        shipmentRuleForm = {
            visiable: false,
            number: 1,
            rows: [{ Type: 0, Value: "" }],
        };

        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "edit",
                Label: "编辑",
                Icon: "edit",
                Type: "text",
                Size: "small",
                Hide: true,
            },
            {
                Code: "delete",
                Label: "删除",
                Icon: "delete",
                Type: "text",
                Size: "small",
                Hide: true,
            },
        ];

        shapeBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        shipmentBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        specBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        imageBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.id = this.$route.params.id as string;

            //详细-数据-初始化
            this.detail.Data = new DtoDetailOrderWarehouse();
            //详细-元素配置
            this.detail.Items = DtoDetailOrderWarehouseMiezzDetailItems;

            this.shapeRuleForm.Data = new DtoFormOrderWarehousePackageShape();
            this.shapeRuleForm.Data.visible = false;

            //表单-校验
            this.shapeRuleForm.Rules = DtoFormOrderWarehousePackageShareRules;
            //表单-元素配置
            this.shapeRuleForm.Items = DtoFormOrderWarehousePackageShareMiezzFormItems;

            this.specRuleForm.Data = new DtoFormOrderWarehousePackageSpec();
            this.specRuleForm.Data.visible = false;
            //表单-校验
            this.specRuleForm.Rules = DtoFormOrderWarehousePackageSpecRules;
            //表单-元素配置
            this.specRuleForm.Items = DtoFormOrderWarehousePackageSpecMiezzFormItems;
            this.specRuleForm.Data.ChargeUnit = "";

            this.handleGet();
        }

        /**查询详细 */
        async handleGet(): Promise<void> {
            if (this.id) {
                this.isLoading = true;
                OrderWarehouseAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.detail.Data = r.data.Data;
                    if (this.detail.Data.Type) {
                        this.type = this.detail.Data.Type.toString();
                    }

                    if (this.detail.Data.OrderWarehouseAnnexs != null && this.detail.Data.OrderWarehouseAnnexs != undefined && this.detail.Data.OrderWarehouseAnnexs.length > 0) {
                        for (var annex of this.detail.Data.OrderWarehouseAnnexs) {
                            annex.Name = "";
                            if (annex.Url) {
                                var urls = annex.Url.split('/');
                                annex.Name = urls[urls.length - 1];
                                annex.Url = this.client?.signatureUrl(annex.Url) as string;
                            }
                        }
                    }

                    this.detail.Data.SigningTimeString = "";
                    if (this.detail.Data.SigningTime) {
                        this.detail.Data.SigningTimeString = moment(
                            this.detail.Data.SigningTime
                        ).format("YYYY/MM/DD");
                    }
                    if (
                        this.detail.Data &&
                        this.detail.Data.Packages &&
                        this.detail.Data.Packages.length > 0
                    ) {
                        for (var pk of this.detail.Data.Packages) {
                            pk.PackageShapes = pk.PackageShares;
                        }
                    }

                    if (this.detail.Data != null && this.detail.Data != undefined) {
                        this.detail.Data.CurrencySystemName = "";
                        if (
                            this.detail.Data.CurrencySystem != null &&
                            this.detail.Data.CurrencySystem != undefined
                        ) {
                            this.detail.Data.CurrencySystemName =
                                this.detail.Data.CurrencySystem.Code;
                        }
                    }
                    this.detailTemp = JSON.parse(JSON.stringify(this.detail)); //这个在前，以免部分原始数据在计算后被覆盖
                    this.onCountPackage(); //这个在后，以免部分原始数据在计算后被覆盖
                    if (
                        this.detailTemp.Data &&
                        this.detailTemp.Data.Packages &&
                        this.detailTemp.Data.Packages.length > 0 &&
                        this.detail.Data &&
                        this.detail.Data.Packages &&
                        this.detail.Data.Packages.length > 0
                    ) {
                        for (var i = 0; i < this.detailTemp.Data.Packages.length; i++) {
                            var pak = this.detailTemp.Data.Packages[i];
                            if (pak.Volume != null && pak.Volume != undefined) {
                                this.detail.Data.Packages[i].Volume = pak.Volume;
                            }
                            if (pak.GrossWeight != null && pak.GrossWeight != undefined) {
                                this.detail.Data.Packages[i].GrossWeight = pak.GrossWeight;
                            }


                        }
                    }

                    this.onPower();
                    this.isLoading = false;
                });
            }
        }

        onImage(row: any): void {
            this.imageRuleForm.row = row;
            this.imageRuleForm.visiable = true;
        }

        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (
                url != null &&
                typeof url != "undefined" &&
                url != "undefined" &&
                url != ""
            ) {
                this.$router.push(url);
            } else {
                history.go(-1);
            }
        }

        onShipment(index: any) {
            if (
                this.shipmentRuleForm &&
                this.detail.Data &&
                this.detail.Data.Packages
            ) {
                this.shipmentRuleForm.rows = [];
                var pack = this.detail.Data.Packages[index];
                var value = "";
                if (
                    pack &&
                    pack.ShipmentIDs &&
                    pack.ShipmentIDs.length > 0 &&
                    pack.ShipmentIDs[0].Value
                ) {
                    value = pack.ShipmentIDs[0].Value as string;
                }
                var p = {
                    Value: value,
                    Type: 10,
                };
                this.shipmentRuleForm.rows.push(p);
                var number = 0;
                for (var it of pack.PackageShapes) {
                    number += it.Quantity;
                }
                var total = 0;
                if (pack.Quantity) {
                    total = pack.Quantity * number;
                }
                this.shipmentRuleForm.number = total;
                if (this.detail.Data.Packages[index].ShipmentIDs == undefined) {
                    this.detail.Data.Packages[index].ShipmentIDs = [];
                }
                for (var i = 0; i < total; i++) {
                    value = "";
                    if (
                        pack &&
                        pack.ShipmentIDs &&
                        pack.ShipmentIDs.length > i &&
                        pack.ShipmentIDs[i + 1].Value
                    ) {
                        value = pack.ShipmentIDs[i + 1].Value as string;
                    }
                    var p1 = {
                        Value: value,
                        Type: 20,
                    };
                    this.shipmentRuleForm.rows.push(p1);
                }
                this.shipmentRuleForm.visiable = true;
            }
        }

        onSpec(index: number): void {
            this.specRuleForm.Data.ChargeUnit = "";
            if (
                this.detail.Data &&
                this.detail.Data.Packages &&
                this.detail.Data.Packages.length > 0 &&
                this.detail.Data.Packages[index].PackageSpecs
            ) {
                this.specRuleForm.Data.Items = [];
                var t = this.detail.Data.Packages[index];
                if (t.PackageSpecs && t.PackageSpecs.length > 0) {
                    var chargeUnit = t.PackageSpecs[0].Spec?.Product?.ChargeUnithEn;
                    var count = 0;

                    var shapeCount = 0;
                    if (
                        this.detail.Data &&
                        this.detail.Data.Packages &&
                        this.detail.Data.Packages[index] &&
                        this.detail.Data.Packages[index].PackageShapes
                    ) {
                        shapeCount = this.detail.Data.Packages[index].PackageShapes.length;
                    }
                    for (var it of t.PackageSpecs) {
                        var row = {
                            NameZh:
                                it.NameZh == null || it.NameZh == undefined || it.NameZh == ""
                                    ? it.Spec?.Product?.NameZh
                                    : it.NameZh,
                            NameEn:
                                it.NameEn == null || it.NameEn == undefined || it.NameEn == ""
                                    ? it.Spec?.Product?.NameEn
                                    : it.NameEn,
                            Quantity: it.Quantity,
                            Spec: it.Spec,
                            SpecId:
                                it.NameEn == null || it.NameEn == undefined || it.NameEn == ""
                                    ? it.Spec?.Id
                                    : it.SpecId,
                            UnitPrice: it.UnitPrice,
                            TotalPrice: it.TotalPrice,
                            ShapeCount: shapeCount,
                            En: [],
                            Zh: [],
                            Images: [""],
                            FirstImage: "",
                            ChargeUnithEn: it.ChargeUnithEn == null || it.ChargeUnithEn == undefined || it.ChargeUnithEn == "" ? it.Spec?.Product?.ChargeUnithEn : it.ChargeUnithEn,
                        };
                        if (shapeCount > 1) {
                            row.Quantity = 1;
                        }
                        if (
                            row.Spec != null &&
                            row.Spec != undefined &&
                            row.Spec.SpecImages != null &&
                            row.Spec.SpecImages != undefined &&
                            row.Spec.SpecImages.length > 0
                        ) {
                            if (row.Spec && row.Spec.SpecImages && row.Spec.SpecImages[0].Url) {
                                row.FirstImage = this.client?.signatureUrl(
                                    row.Spec.SpecImages[0].Url
                                ) as string;
                            }

                            row.Images = [];
                            for (var img of row.Spec.SpecImages) {
                                if (img.Url) {
                                    var url = this.client?.signatureUrl(img.Url) as string;
                                    row.Images.push(url);
                                }
                            }
                        }

                        this.specRuleForm.Data.Items.push(row);

                        if (chargeUnit != it.Spec?.Product?.ChargeUnithEn) {
                            count++;
                        }
                    }
                    if (count > 0) {
                        chargeUnit = "PCS";
                    }
                    this.specRuleForm.Data.ChargeUnit = chargeUnit;
                }
            }
            this.specRuleForm.Data.visible = true;
        }

        onShape(index: number): void {
            if (this.detail.Data.Packages && this.detail.Data.Packages.length > 0) {
                if (this.shapeRuleForm.Data.Items == undefined) {
                    this.shapeRuleForm.Data.Items = [];
                }
                if (this.detail.Data.Packages[index].PackageShapes != undefined) {
                    this.shapeRuleForm.Data.Items = JSON.parse(
                        JSON.stringify(this.detail.Data.Packages[index].PackageShapes)
                    );
                }
                this.shapeRuleForm.Data.visible = true;
            }
        }

        onCountPackage(): void {
            if (this.detail.Data && this.detail.Data.Packages) {
                for (var pack of this.detail.Data.Packages) {
                    pack.SizeQuantity = "";
                    pack.IsVolume = true;
                    pack.IsGrossWeight = true;
                    pack.ShipmentId = "暂无数据";
                    pack.IsShipment = false;
                    if (pack.ShipmentIDs && pack.ShipmentIDs.length > 0) {
                        for (var sp of pack.ShipmentIDs) {
                            if (sp.Value && sp.Value != "") {
                                pack.IsShipment = true;
                                break;
                            }
                        }
                    }
                    if (pack.PackageShapes && pack.Quantity) {
                        var isVolume = true;
                        var isGrossWeight = true;
                        var volume = 0;
                        var grossWeight = 0;
                        for (var it of pack.PackageShapes) {
                            if (it.GrossWeight == null || it.GrossWeight == undefined) {
                                isGrossWeight = false;
                            } else {
                                if (it.GrossWeight) {
                                    grossWeight += it.GrossWeight * it.Quantity;
                                }
                            }
                            if (it.Volume == null || it.Volume == undefined) {
                                isVolume = false;
                            } else {
                                if (it.Volume) {
                                    volume += it.Volume * it.Quantity;
                                }
                            }
                            if (
                                it.Length != null &&
                                it.Length != undefined &&
                                it.Length != 0 &&
                                it.Width != null &&
                                it.Width != undefined &&
                                it.Width != 0 &&
                                it.Height != null &&
                                it.Height != undefined &&
                                it.Height != 0
                            ) {
                                pack.SizeQuantity +=
                                    "【" +
                                    it.Length +
                                    "cm*" +
                                    it.Width +
                                    "cm*" +
                                    it.Height +
                                    "cm*" +
                                    it.Quantity +
                                    "】";
                            } else {
                                pack.SizeQuantity += "【暂无数据*" + it.Quantity + "】";
                            }
                        }
                        pack.IsVolume = isVolume;
                        pack.IsGrossWeight = isGrossWeight;
                        var result = "";
                        if (isVolume == true) {
                            volume = volume * pack.Quantity;
                            result = setDecimal(volume.toString(), 2, false);
                            if (result != "") {
                                pack.Volume = Number(result);
                            } else {
                                pack.Volume = undefined;
                            }
                        } else {
                            pack.Volume = undefined;
                        }
                        if (isGrossWeight == true) {
                            grossWeight = grossWeight * pack.Quantity;
                            result = setDecimal(grossWeight.toString(), 2, false);
                            if (result != "") {
                                pack.GrossWeight = Number(result);
                            } else {
                                pack.GrossWeight = undefined;
                            }
                        } else {
                            pack.GrossWeight = undefined;
                        }
                    }
                    if (pack.ShipmentIDs && pack.ShipmentIDs.length > 0) {
                        for (var shipment of pack.ShipmentIDs) {
                            if (shipment.Type == 10) {
                                pack.ShipmentId = shipment.Value;
                            }
                        }
                    }
                    //pack.SizeQuantity = pack.SizeQuantity + "【100cm*100cm*100cm*1】【100cm*100cm*200cm*2】【100cm*100cm*100cm*1】【100cm*100cm*200cm*2】";
                    //pack.ProductName = pack.ProductName + "【100cm*100cm*100cm*1】【100cm*100cm*200cm*2】【100cm*100cm*100cm*1】【100cm*100cm*200cm*2】";
                }
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "back":
                    this.handleBack();
                    complete();
                    break;
                case "edit":
                    if (this.type == "20") {
                        this.$router.push(
                            "/orderwarehouse/sale/edit/" +
                            this.id +
                            "?backUrl=" +
                            this.$route.fullPath
                        );
                    } else {
                        this.$router.push(
                            "/orderwarehouse/purchase/edit/" +
                            this.id +
                            "?backUrl=" +
                            this.$route.fullPath
                        );
                    }

                    complete();
                    break;
                case "delete":
                    ElMessageBox.confirm(
                        "您确定要删除选中的信息吗？删除后信息将无法恢复，是否继续",
                        "提示",
                        {
                            confirmButtonText: "确认",
                            cancelButtonText: "取消",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            OrderWarehouseAppService.Delete([this.id as string])
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleBack();
                                    complete();
                                })
                                .catch(complete);
                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                default:
                    break;
            }
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        async onPower(): Promise<void> {
            this.powerCodes = await CurrentLogier.GetPowerCodes();
            this.isPurchaseEdit =
                this.powerCodes &&
                this.powerCodes.contains("OrderManagement_PurchaseOrder_Detail_Edit");
            this.isSaleEdit =
                this.powerCodes &&
                this.powerCodes.contains("OrderManagement_SalesOrder_Detail_Edit");

            this.isPurchaseDelete =
                this.powerCodes &&
                this.powerCodes.contains("OrderManagement_PurchaseOrder_Delete");
            this.isSaleDelete =
                this.powerCodes &&
                this.powerCodes.contains("OrderManagement_SalesOrder_Delete");

            if (this.bottomButtons) {
                if (this.type == "10") {
                    if (this.isPurchaseEdit == true) {
                        this.bottomButtons[0].Hide = false;
                    }
                    if (this.isPurchaseDelete == true) {
                        this.bottomButtons[1].Hide = false;
                    }
                } else if (this.type == "20") {
                    if (this.isSaleEdit == true) {
                        this.bottomButtons[0].Hide = false;
                    }
                    if (this.isSaleDelete == true) {
                        this.bottomButtons[1].Hide = false;
                    }
                }
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }

        handleClickShape(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.shapeRuleForm.Data.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickSpec(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.specRuleForm.Data.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickShipment(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.shipmentRuleForm.visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageRuleForm.visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
