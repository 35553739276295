import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * HSCode
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemHsCode extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsSelected = false;
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 选择
     */
    IsSelected?: boolean;
    /**
     * 商品编码
     */
    Code?: string;
    /**
     * 商品名称
     */
    Name?: string;
    /**
     * 第一法定单位
     */
    StatutoryFirstUnit?: string;
    /**
     * 第二法定单位
     */
    StatutorySecondUnit?: string;
    /**
     * 最惠国进口税率
     */
    MfnImportTariff?: string;
    /**
     * 普通进口税率
     */
    GeneralImportTariff?: string;
    /**
     * 暂定进口税率
     */
    ProvisionalImportTariff?: string;
    /**
     * 消费税率
     */
    ConsumptionTariff?: string;
    /**
     * 出口关税率
     */
    ExportTariff?: string;
    /**
     * 出口退税率
     */
    ExportRebateRate?: string;
    /**
     * 增值税率
     */
    EducationalTariff?: string;
    /**
     * 海关监管条件
     */
    CustomsSupervisionConditions?: string;
    /**
     * 检验检疫类别
     */
    InspectionQuarantineCategory?: string;
    /**
     * 描述
     */
    Description?: string;
    /**
     * 申报要素
     */
    Element?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * HSCode-表单校验
 */
export const DtoListItemHsCodeRules = {
}

/**
 * HSCode-设置项
 */
export const DtoListItemHsCodeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "IsSelected",
    Label: "选择",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "商品编码",
  },
  {
    Prop: "Name",
    Label: "商品名称",
  },
  {
    Prop: "StatutoryFirstUnit",
    Label: "第一法定单位",
  },
  {
    Prop: "StatutorySecondUnit",
    Label: "第二法定单位",
  },
  {
    Prop: "MfnImportTariff",
    Label: "最惠国进口税率",
  },
  {
    Prop: "GeneralImportTariff",
    Label: "普通进口税率",
  },
  {
    Prop: "ProvisionalImportTariff",
    Label: "暂定进口税率",
  },
  {
    Prop: "ConsumptionTariff",
    Label: "消费税率",
  },
  {
    Prop: "ExportTariff",
    Label: "出口关税率",
  },
  {
    Prop: "ExportRebateRate",
    Label: "出口退税率",
  },
  {
    Prop: "EducationalTariff",
    Label: "增值税率",
  },
  {
    Prop: "CustomsSupervisionConditions",
    Label: "海关监管条件",
  },
  {
    Prop: "InspectionQuarantineCategory",
    Label: "检验检疫类别",
  },
  {
    Prop: "Description",
    Label: "描述",
  },
  {
    Prop: "Element",
    Label: "申报要素",
  },
]
