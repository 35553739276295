
import DtoFormCodeMethodCallback, {
  DtoFormCodeMethodCallbackMiezzFormItems,
  DtoFormCodeMethodCallbackRules,
} from "@/models.machine/DtoFormCodeMethodCallback";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
@Options({
  components: {
    CodePropertyTree,
  },
})
export default class CodeMethodCallbackForm extends Vue {
  @Model() modelValue?: DtoFormCodeMethodCallback;
  @Prop() table?: string;
  @Prop() tKey?: string;
  @Prop() show: string[] = [];
  items = DtoFormCodeMethodCallbackRules;
  rules = DtoFormCodeMethodCallbackMiezzFormItems;

  enabled = false;
  leafTypes: string[] = [
    "string",
    "Guid",
    "Guid?",
    "int",
    "int?",
    "long",
    "long?",
    "decimal",
    "decimal?",
    "DateTime",
    "DateTime?",
  ];
  baseTypes: string[] = [];

  created(): void {
    this.onTKeyChanged();
    if (this.modelValue) {
      this.enabled = true;
    }
  }

  @Watch("tKey")
  onTKeyChanged(): void {
    this.baseTypes = [
      `IPrimaryKey<${this.tKey}>`,
      `UiDetail<${this.tKey}>`,
      `UiForm<${this.tKey}>`,
      `UiFormWithStringId`,
      `UiListItem<${this.tKey}>`,
    ];
  }

  onEnabledChanged(): void {
    let model = undefined;
    if (this.enabled) model = new DtoFormCodeMethodCallback();
    this.$emit("update:modelValue", model);
  }

  onIsLeafChanged(): void {
    if (this.modelValue && this.modelValue.IsLeaf) {
      if (this.modelValue.Name && this.modelValue.Name.indexOf("Dto") == 0) {
        this.modelValue.Name = undefined;
      }
      if (this.modelValue.Summary == "结果") {
        this.modelValue.Summary = undefined;
      }
      this.modelValue.Properties = [];
      this.modelValue.BaseType = undefined;
    }
  }
}
