import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 国家表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamCountry extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 国旗
     */
    Flag?: string;
    /**
     * 地区深度
     */
    AreaDeep?: number;
    /**
     * 地区名称1
     */
    AreaName1?: string;
    /**
     * 地区名称2
     */
    AreaName2?: string;
    /**
     * 地区名称3
     */
    AreaName3?: string;
    /**
     * 地区名称4
     */
    AreaName4?: string;
    /**
     * 地区名称5
     */
    AreaName5?: string;
}

/**
 * 国家表单-表单校验
 */
export const DtoFormParamCountryRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 国家表单-设置项
 */
export const DtoFormParamCountryMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
  {
    Prop: "Flag",
    Label: "国旗",
    Customize: true,
  },
  {
    Prop: "AreaDeep",
    Label: "地区深度",
    Customize: true,
  },
  {
    Prop: "AreaName1",
    Label: "地区名称1",
    Customize: true,
  },
  {
    Prop: "AreaName2",
    Label: "地区名称2",
    Customize: true,
  },
  {
    Prop: "AreaName3",
    Label: "地区名称3",
    Customize: true,
  },
  {
    Prop: "AreaName4",
    Label: "地区名称4",
    Customize: true,
  },
  {
    Prop: "AreaName5",
    Label: "地区名称5",
    Customize: true,
  },
]
