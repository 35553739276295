import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormSpec_SpecNumber from "./DtoFormSpec_SpecNumber";
import DtoFormSpec_SpecImage from "./DtoFormSpec_SpecImage";
import DtoFormSpec_SpecElement from "./DtoFormSpec_SpecElement";
import DtoFormSpec_SpecStock from "./DtoFormSpec_SpecStock";
import UiGroup from "./UiGroup";

/**
 * 某个产品的规格表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormSpec extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.SpecNumbers = [];
        this.SpecImages = [];
        this.SpecElements = [];
        this.SpecStocks = [];
        this.UiGroups = [{"Code":"ProductSpecForm","Label":"\u89C4\u683C\u578B\u53F7","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * 规格型号名称
     */
    Name?: string;
    /**
     * 常用货号
     */
    Number?: string;
    /**
     * 公司名片主键
     */
    CompanyBusinessCardId?: string;
    /**
     * 重量
     */
    Weight?: number;
    /**
     * 常用货号
     */
    SpecNumbers?: DtoFormSpec_SpecNumber[];
    /**
     * 型号图片
     */
    SpecImages?: DtoFormSpec_SpecImage[];
    /**
     * 描述
     */
    SpecElements?: DtoFormSpec_SpecElement[];
    /**
     * 原始库存
     */
    SpecStocks?: DtoFormSpec_SpecStock[];
    /**
     * 默认规格图片：同步规格图片表的第一条数据
     */
    Image?: string;
    /**
     * 库存数量
     */
    StockQuantity?: number;
    /**
     * 描述
     */
    SysRemark?: string;
}

/**
 * 某个产品的规格表单-表单校验
 */
export const DtoFormSpecRules = {
    ProductName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 某个产品的规格表单-设置项
 */
export const DtoFormSpecMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ProductName",
    Label: "产品名称",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "规格型号名称",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Number",
    Label: "常用货号",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyBusinessCardId",
    Label: "公司名片主键",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "重量",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecNumbers",
    Label: "常用货号",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecImages",
    Label: "型号图片",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecElements",
    Label: "描述",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecStocks",
    Label: "原始库存",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
]
