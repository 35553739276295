import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPriceQuotationSheet_Cargo from "./DtoFormPriceQuotationSheet_Cargo";
import DtoFormPriceQuotationSheet_PriceQuotationSheetFile from "./DtoFormPriceQuotationSheet_PriceQuotationSheetFile";
import DtoFormPriceQuotationSheet_Scheme from "./DtoFormPriceQuotationSheet_Scheme";
import DtoFormPriceQuotationSheet_TrackRecord from "./DtoFormPriceQuotationSheet_TrackRecord";
import DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier from "./DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier";
import UiGroup from "./UiGroup";

/**
 * 报价表表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheet extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Cargos = [];
        this.Files = [];
        this.Schemes = [];
        this.TrackRecords = [];
        this.Suppliers = [];
        this.UiGroups = [{"Code":"BasicInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ReviewDescription","Label":"\u590D\u76D8\u63CF\u8FF0","Toggle":true,"Close":false,"Buttons":[]},{"Code":"TrackRecord","Label":"\u8FFD\u8E2A\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]},{"Code":"CostRecord","Label":"\u6210\u672C\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 报价人
     */
    CreatedCompanyUserId?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 客户企业
     */
    CompanyId?: string;
    /**
     * 询盘人
     */
    UserId?: string;
    /**
     * 客户企业
     */
    CompanyLabel?: string;
    /**
     * 询盘人
     */
    UserLabel?: string;
    /**
     * 货物数据
     */
    Cargos?: DtoFormPriceQuotationSheet_Cargo[];
    /**
     * 合计毛重
     */
    Weight?: number;
    /**
     * 合计体积
     */
    Volume?: number;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 附件
     */
    Files?: DtoFormPriceQuotationSheet_PriceQuotationSheetFile[];
    /**
     * 询盘描述
     */
    InquiryDescription?: string;
    /**
     * 复盘描述
     */
    Schemes?: DtoFormPriceQuotationSheet_Scheme[];
    /**
     * 追踪记录
     */
    TrackRecords?: DtoFormPriceQuotationSheet_TrackRecord[];
    /**
     * 成本维护人
     */
    InfoCollectorId?: string;
    /**
     * 供应商
     */
    Suppliers?: DtoFormPriceQuotationSheet_PriceQuotationSheetSupplier[];
}

/**
 * 报价表表单-表单校验
 */
export const DtoFormPriceQuotationSheetRules = {
    CreatedCompanyUserId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    InquiryDescription: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 报价表表单-设置项
 */
export const DtoFormPriceQuotationSheetMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CreatedCompanyUserId",
    Label: "报价人",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "CompanyLabel",
    Label: "客户企业",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "UserLabel",
    Label: "询盘人",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Cargos",
    Label: "货物数据",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "附件",
    Group: "BasicInfo",
    Customize: true,
  },
  {
    Prop: "InquiryDescription",
    Label: "询盘描述",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Schemes",
    Label: "复盘描述",
    Group: "ReviewDescription",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "TrackRecords",
    Label: "追踪记录",
    Group: "TrackRecord",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "InfoCollectorId",
    Label: "成本维护人",
    Group: "CostRecord",
    Customize: true,
  },
  {
    Prop: "Suppliers",
    Label: "供应商",
    Group: "CostRecord",
    Span: 24,
    Customize: true,
  },
]
