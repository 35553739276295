import UiSelectOption from "./UiSelectOption";

/**
 * 运输方向
 * @description 自动生成的代码,请勿修改
 */
export enum EnumTransportDirection {
    /**
     * 出口/Export
     */
    Export = 1,
    /**
     * 进口/Import
     */
    Import = 2,
    /**
     * 其他（无操作流程业务）
     */
    Other = 999,
}

/**
 * 运输方向-选项
 */
export const EnumTransportDirectionOption: UiSelectOption<number>[] = [
    { Label: "出口/Export", Value: 1, Data: "Export" },
    { Label: "进口/Import", Value: 2, Data: "Import" },
    { Label: "其他（无操作流程业务）", Value: 999, Data: "Other" },
];
