import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 企业代码类型表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamCompanyCodeType extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountryId?: string;
    /**
     * 国家代码
     */
    CountryCode?: string;
    /**
     * 企业代码类型
     */
    CompanyCode?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 企业代码类型表单-表单校验
 */
export const DtoFormParamCompanyCodeTypeRules = {
    ParamCountryId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    CountryCode: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    CompanyCode: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 企业代码类型表单-设置项
 */
export const DtoFormParamCompanyCodeTypeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "CountryCode",
    Label: "国家代码",
    Customize: true,
  },
  {
    Prop: "CompanyCode",
    Label: "企业代码类型",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
]
