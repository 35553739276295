import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_business_card_page_list = _resolveComponent("company-business-card-page-list")!

  return (_openBlock(), _createBlock(_component_company_business_card_page_list, {
    title: _ctx.title,
    area: _ctx.area,
    type: _ctx.type,
    list: _ctx.list,
    bankLink: "/org/company/bank",
    notifierLink: "/org/company/domestic/notifier"
  }, null, 8, ["title", "area", "type", "list"]))
}