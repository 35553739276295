import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailCompanyCodeRule_CompanyCodeRule from "./DtoDetailCompanyCodeRule_CompanyCodeRule";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyCodeRule extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.CodeRules = [{"TransportDirection":1,"TransportType":1,"TransportContainerLoad":1,"Code":"ESF","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":1,"TransportContainerLoad":2,"Code":"ESL","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":1,"TransportContainerLoad":3,"Code":"ESB","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":2,"Code":"EA","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":3,"TransportContainerLoad":1,"Code":"ERF","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":3,"TransportContainerLoad":2,"Code":"ERL","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":1,"TransportType":3,"TransportContainerLoad":3,"Code":"ERB","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":1,"TransportContainerLoad":1,"Code":"ISF","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":1,"TransportContainerLoad":2,"Code":"ISL","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":1,"TransportContainerLoad":3,"Code":"ISB","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":2,"Code":"IA","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":3,"TransportContainerLoad":1,"Code":"IRF","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":3,"TransportContainerLoad":2,"Code":"IRL","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":2,"TransportType":3,"TransportContainerLoad":3,"Code":"IRB","Id":"00000000-0000-0000-0000-000000000000"},{"TransportDirection":999,"Code":"O","Id":"00000000-0000-0000-0000-000000000000"}];
    }

    [proName: string]: any;
    /**
     * 公司英文缩写
     */
    NameEnAbbr?: string;
    /**
     * 业务类型缩写
     */
    CodeRules?: DtoDetailCompanyCodeRule_CompanyCodeRule[];
    /**
     * 编号规则
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyCodeRuleRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyCodeRuleMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "NameEnAbbr",
    Label: "公司英文缩写",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CodeRules",
    Label: "业务类型缩写",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "编号规则",
    Customize: true,
  },
]
