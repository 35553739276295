import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolCargoQuantity from "../models.machine/DtoPageListSearchOrderPoolCargoQuantity";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolCargoQuantity from "../models.machine/DtoPageListOrderPoolCargoQuantity";

/**
 * 委托书-货量
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolCargoQuantityAppService {
    /**
     * 获取委托书-货量分页列表
     * @param {DtoPageListSearchOrderPoolCargoQuantity} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolCargoQuantity>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolCargoQuantity): AxiosPromise<RESTfulResult<DtoPageListOrderPoolCargoQuantity>> {
        const url = `/api/OrderPoolCargoQuantity/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolCargoQuantity>>(url, { params: dto });
    }

}
