
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import DtoHistory from "@/models.machine/DtoHistory";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import MiezzForm from "@/models/MiezzForm";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import { EnumLadingBillTypeOption } from "@/models.machine/EnumLadingBillType";
import MiezzButton from "@/models/MiezzButton";
import Logier from "@/models.machine/Logier";
import { EnumLadingBillNumberOption } from "@/models.machine/EnumLadingBillNumber";
import CurrentLogier from "@/apis/CurrentLogier";
import OrderAppService from "@/apis.machine/OrderAppService";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import moment from "moment";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import DtoDetailOrder from "@/models.machine/DtoDetailOrder";
import OrderDetailCompanyBusinessCard from "../../CompanyBusinessCard/OrderDetailCompanyBusinessCard.vue";
import OrderDetailProductsSummary from "../../ProductSummary/OrderDetailProductsSummary.vue";
import OrderDetailContainer from "../../Container/OrderDetailContainer.vue";
import OrderDetailCost from "../../Cost/OrderDetailCost.vue";
import OrderDetailLadingBill from "../../LadingBill/OrderDetailLadingBill.vue";
import OrderMainBillDetail from "../MainBill/OrderMainBillDetail.vue";
import OrderPartBillDetail from "../PartBill/OrderPartBillDetail.vue";
import OrderManifestVgmDetail from "../ManifestVgm/OrderManifestVgmDetail.vue";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import { isArray } from "element-plus/es/utils";
import OrderSummaryTime from "../../OrderSummaryTime.vue";

@Options({
  components: {
    OrderDetailCompanyBusinessCard,
    OrderDetailProductsSummary,
    OrderDetailContainer,
    OrderDetailCost,
    OrderDetailLadingBill,
    OrderMainBillDetail,
    OrderPartBillDetail,
    OrderManifestVgmDetail,
    OrderSummaryTime,
  },
})
export default class OrderBookingDetail extends Vue {
  id?: string;
  logier?: Logier;
  menuTab = new MiezzMenuTab();
  form = new MiezzForm<DtoDetailOrder, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
  ];
  topButtons: MiezzButton[] = [];

  EnumTransportDirection = EnumTransportDirection;
  EnumCompanyType = EnumCompanyType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumLadingBillTypeOption = EnumLadingBillTypeOption;
  EnumLadingBillNumberOption = EnumLadingBillNumberOption;

  histories: DtoHistory[] = [];

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    this.logier = CurrentLogier.GetLogier();
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/booking/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "舱务信息",
        Url: `/order/booking/${this.id}`,
        Power: "OrderManagement_Order_Summary_Booking",
      },
    ];

    this.handleGet();

    if (this.id) {
      OrderAppService.GetBookingHistoriesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  transportPaymentType = "暂无数据";
  goodsTransportType = "暂无数据";
  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;

          this.topButtons = [
            {
              Code: "print-pdf",
              Label: "导出",
              //PdfName: `订舱信息-${this.form.Data.Code}`,
              Type: "primary",
              Size: "small",
            },
          ];

          this.getType();
          this.transportPaymentType =
            EnumTransportPaymentTypeOption.firstOrDefault(
              (it) => it.Value == this.form.Data.TransportPaymentType
            )?.Label ?? "暂无数据";
          this.goodsTransportType =
            EnumGoodsTransportTypeOption.firstOrDefault(
              (it) => it.Value == this.form.Data.GoodsTransportType
            )?.Label ?? "暂无数据";

          const tasks = r.data.Data?.Types ?? [];
          this.buttons.push({
            Code: "edit-booking",
            Label: "编辑订舱信息",
            Icon: "edit",
            Type: "text",
            Size: "small",
            PowerCode: "OrderManagement_Order_Summary_Booking_Edit",
            Breathe: tasks.contains("AddDeparturePortBooking"),
          });
          this.buttons.push({
            Code: "edit-main-bill",
            Label: "编辑主单信息",
            Icon: "edit",
            Type: "text",
            Size: "small",
            PowerCode: "OrderManagement_Order_Summary_Booking_MainBill_Edit",
            Breathe:
              tasks.contains("AddDeparturePortBookingMainBill") ||
              tasks.contains("AddDeparturePortBookingMainBillAtd") ||
              tasks.contains("AddDeparturePortBookingMainBillAta"),
          });
          if (this.form.Data.HasPartBill) {
            this.buttons.push({
              Code: "edit-part-bill",
              Label: "编辑分单信息",
              Icon: "edit",
              Type: "text",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_Booking_PartBill_Edit",
              Breathe: tasks.contains("AddDeparturePortBookingPartBill"),
            });
          }
          if (
            //@bug5687
            // this.form.Data.Pools?.any((it) => it.DeparturePortBooking) &&
            this.form.Data.TransportDirection ==
              EnumTransportDirection.Export &&
            this.form.Data.TransportContainerLoad ==
              EnumTransportContainerLoad.FCL
          ) {
            this.buttons.push({
              Code: "edit-vgm",
              Label: "编辑舱单&VGM",
              Icon: "edit",
              Type: "text",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_Booking_ManifestVgm_Edit",
              Breathe: tasks.contains("AddDeparturePortBookingManifestVgm"),
            });
          }
        }
      });
    }
  }

  handleHeaderButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "print-pdf":
        OrderAppService.ExportById(this.id)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  formatDate(v?: Date): string {
    return v ? moment(v).format("YYYY-MM-DD HH:mm") : "暂无数据";
  }

  EnumTransportType = EnumTransportType;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  type = "暂无数据";

  /**获取业务类型 */
  getType(): void {
    const direction = this.EnumTransportDirectionOption.firstOrDefault(
      (it) => it.Value == this.form.Data.TransportDirection
    )?.Label;
    this.type = `【${direction}】`;
    if (this.form.Data.TransportType) {
      const type = this.EnumTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.TransportType
      )?.Label;
      this.type += `【${type}】`;
    }
    if (this.form.Data.TransportContainerLoad) {
      const containerLoad =
        this.EnumTransportContainerLoadOption.firstOrDefault(
          (it) => it.Value == this.form.Data.TransportContainerLoad
        )?.Label;
      this.type += `【${containerLoad}】`;
    }
  }

  getTasks(): string[] {
    let tasks: string[] = [];
    if (this.$route.query.task) {
      if (isArray(this.$route.query.task)) {
        tasks = this.$route.query.task as string[];
      } else {
        tasks.push(this.$route.query.task as string);
      }
    }
    return tasks;
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    if (btn.Code != "back") {
      if (this.form.Data.Lock) {
        ElMessage.warning("订单已锁定");
        complete();
        return;
      }
      if (this.form.Data.Status == EnumOrderStatus.Canceled) {
        ElMessage.warning("订单已取消，无法修改");
        complete();
        return;
      }
    }
    const tasks = this.getTasks();

    switch (btn.Code) {
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      case "edit-booking":
        CurrentLogier.LinkWithBack({
          path: `/order/booking/edit/${this.id}`,
          query: {
            task: tasks.filter((it) => it == "AddDeparturePortBooking"),
          },
        });
        complete();
        break;
      case "edit-main-bill":
        CurrentLogier.LinkWithBack({
          path: `/order/main-bill/edit/${this.id}`,
          query: {
            task: tasks.filter(
              (it) =>
                it == "AddDeparturePortBookingMainBill" ||
                it == "AddDeparturePortBookingMainBillAtd" ||
                it == "AddDeparturePortBookingMainBillAta"
            ),
          },
        });
        complete();
        break;
      case "edit-part-bill":
        if ((this.form.Data.PoolCount ?? 0) > 0) {
          CurrentLogier.LinkWithBack({
            path: `/order/part-bill/edit/${this.id}`,
            query: {
              task: tasks.filter(
                (it) => it == "AddDeparturePortBookingPartBill"
              ),
            },
          });
        } else {
          ElMessage.warning("未关联委托书无法编辑");
        }
        complete();
        break;
      case "edit-vgm":
        CurrentLogier.LinkWithBack({
          path: `/order/manifest-vgm/edit/${this.id}`,
          query: {
            task: tasks.filter(
              (it) => it == "AddDeparturePortBookingManifestVgm"
            ),
          },
        });
        complete();
        break;
      default:
        break;
    }
  }
}
