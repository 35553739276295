
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyBusinessCardBankAppService from "@/apis.machine/CompanyBusinessCardBankAppService";
import DtoFormCompanyBusinessCardBank, {
  DtoFormCompanyBusinessCardBankMiezzFormItems,
  DtoFormCompanyBusinessCardBankRules,
} from "@/models.machine/DtoFormCompanyBusinessCardBank";
import MiezzModal from "@/models/MiezzModal";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";

export default class CompanyBusinessCardBankForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() businessCardId?: string;
  @Prop() content?: string;

  form = new MiezzForm<DtoFormCompanyBusinessCardBank, string>();
  currencySystems: UiSelectOption<string>[] = [];

  btnDelete: MiezzButton = {
    Code: "delete",
    Title: "删除",
    Icon: "delete",
    Type: "default",
    Size: "small",
  };
  btnAdd: MiezzButton = {
    Code: "add",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    Size: "small",
  };

  created(): void {
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    //表单-数据-初始化
    this.form.Data = new DtoFormCompanyBusinessCardBank();
    this.form.Data.CompanyBusinessCardId = this.businessCardId;
    //表单-校验
    this.form.Rules = DtoFormCompanyBusinessCardBankRules;

    this.form.Rules.NameZh = this.getRuleName();
    this.form.Rules.NameEn = this.getRuleName();
    this.form.Rules.CompanyBusinessCardBankAccounts_ParamCurrencySystemId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.CompanyBusinessCardBankAccounts_Account = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        max: 256,
        message: "文本长度不能超过 256 个字符",
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormCompanyBusinessCardBankMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    ParamCurrencySystemAppService.GetSelect().then((r) => {
      if (r.data.Data) this.currencySystems = r.data.Data;
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      CompanyBusinessCardBankAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;

        if (this.content) {
          var contents = this.content
            .replace(/\r\n/g, "|")
            .replace(/\r/g, "|")
            .replace(/\n/g, "|")
            .split("|");
          if (contents != undefined) {
            if (contents.length > 0 && contents[0] != "—") {
              this.form.Data.NameZh = contents[0];
            }
            if (contents.length > 1 && contents[1] != "—") {
              this.form.Data.NameEn = contents[1];
            }
            if (contents.length > 2 && contents[2] != "—") {
              this.form.Data.BeneficiaryNameZh = contents[2];
            }
            if (contents.length > 3 && contents[3] != "—") {
              this.form.Data.BeneficiaryNameEn = contents[3];
            }
            if (contents.length > 4 && contents[4] != "—") {
              this.form.Data.SwiftCode = contents[4];
            }
          }
        }
      });
    } else {
      if (this.content) {
        var contents = this.content
          .replace(/\r\n/g, "|")
          .replace(/\r/g, "|")
          .replace(/\n/g, "|")
          .split("|");
        if (contents != undefined) {
          if (contents.length > 0 && contents[0] != "—") {
            this.form.Data.NameZh = contents[0];
          }
          if (contents.length > 1 && contents[1] != "—") {
            this.form.Data.NameEn = contents[1];
          }
          if (contents.length > 2 && contents[2] != "—") {
            this.form.Data.BeneficiaryNameZh = contents[2];
          }
          if (contents.length > 3 && contents[3] != "—") {
            this.form.Data.BeneficiaryNameEn = contents[3];
          }
          if (contents.length > 4 && contents[4] != "—") {
            this.form.Data.SwiftCode = contents[4];
          }
        }
      }
    }
  }

  getRuleName(): any {
    return [
      {
        max: 256,
        message: "文本长度不能超过 256 个字符",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: string, callback: any) => {
          if (!this.form.Data.NameZh && !this.form.Data.NameEn)
            return callback(
              new Error("银行中文名称/银行英文名称二者不能同时为空，请填写")
            );
          return callback();
        },
        trigger: "blur",
      },
    ];
  }

  /**行按钮点击 */
  handleDeleteAccount(
    btn: MiezzButton,
    ri: number,
    complete: () => void
  ): void {
    this.form.Data.CompanyBusinessCardBankAccounts?.splice(ri, 1);
    complete();
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.form.Data.CompanyBusinessCardBankAccounts?.push({});
        complete();
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyBusinessCardBankAppService.Post(this.form.Data)
              .then((r) => {
                this.form.Data.Id = r.data.Data;
                this.handleSubmit(this.form.Data);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(data: DtoFormCompanyBusinessCardBank): void {
    //
  }
}
