import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolProcessCount_Booking extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.TimeRed = false;
        this.Process = "查看详情";
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 链接
     */
    Link?: string;
    /**
     * 类型
     */
    TypeText?: string;
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 计划时间
     */
    PlanTime?: Date;
    /**
     * 执行时间
     */
    ExecTime?: Date;
    /**
     * 标记红色
     */
    TimeRed?: boolean;
    /**
     * 进程
     */
    Process?: string;
}

/**
 * -表单校验
 */
export const DtoListItemOrderPoolProcessCount_BookingRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPoolProcessCount_BookingMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "TypeText",
    Label: "类型",
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
  },
  {
    Prop: "PlanTime",
    Label: "计划时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "ExecTime",
    Label: "执行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]
