import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 进销存的产品包装的尺寸和数量表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderWarehousePackageShare extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Length = 0;
        this.Width = 0;
        this.Height = 0;
        this.Volume = 0;
        this.NetWeight = 0;
        this.GrossWeight = 0;
        this.Quantity = 0;
        this.visible = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 长度/直径（cm）
     */
    Length?: number;
    /**
     * 宽度/直径（cm）
     */
    Width?: number;
    /**
     * 高度（cm）
     */
    Height?: number;
    /**
     * 体积（CBM）
     */
    Volume?: number;
    /**
     * 净重（KGS）
     */
    NetWeight?: number;
    /**
     * 毛重（KGS）
     */
    GrossWeight?: number;
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * 是否i显示
     */
    visible?: boolean;
}

/**
 * 进销存的产品包装的尺寸和数量表单-表单校验
 */
export const DtoFormOrderWarehousePackageShareRules = {
    Length: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Width: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Height: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Volume: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    NetWeight: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    GrossWeight: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Quantity: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 进销存的产品包装的尺寸和数量表单-设置项
 */
export const DtoFormOrderWarehousePackageShareMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Length",
    Label: "长度/直径（cm）",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Width",
    Label: "宽度/直径（cm）",
    Customize: true,
  },
  {
    Prop: "Height",
    Label: "高度（cm）",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积（CBM）",
    Customize: true,
  },
  {
    Prop: "NetWeight",
    Label: "净重（KGS）",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重（KGS）",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "visible",
    Label: "是否i显示",
    Customize: true,
  },
]
