
import PowerAppService from "@/apis.machine/PowerAppService";
import RoleAppService from "@/apis.machine/RoleAppService";
import DtoDetailRole, {
  DtoDetailRoleMiezzDetailItems,
} from "@/models.machine/DtoDetailRole";
import DtoFormRole from "@/models.machine/DtoFormRole";
import DtoTreePower from "@/models.machine/DtoTreePower";
import DtoTreeSearchPower from "@/models.machine/DtoTreeSearchPower";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop } from "vue-property-decorator";
import ChoosePower from "./ChoosePower.vue";
import RoleForm from "./RoleForm.vue";
@Options({
  components: {
    RoleForm,
    ChoosePower,
  },
})
export default class RoleDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailRole, string>(
    "System_Role_Detail"
  );

  tree: DtoTreePower[] = [];

  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.detail.Data = new DtoDetailRole();
    this.detail.Items = DtoDetailRoleMiezzDetailItems;

    if (process.env.NODE_ENV == "production") {
      this.detail.Items = this.detail.Items.filter((it) => it.Prop != "IsTest");
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "System_Role_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "System_Role_Delete",
      },
    ];
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      RoleAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
        this.getMenuTree();
      });
    }
  }

  getMenuTree(): void {
    const detailData = this.detail.Data as DtoFormRole;
    const dto = new DtoTreeSearchPower();
    dto.CheckIds = detailData.PowerIds;
    dto.OnlyShowCheck = true;
    PowerAppService.GetTree(dto).then((r) => {
      if (r.data.Data) {
        this.tree = r.data.Data;
      } else {
        this.tree = [];
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑角色-${this.detail.Data?.Name}`;
        complete();
        break;
      case "delete":
        RoleAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
