
import { Options, Vue } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzForeignMultipleSelect extends Vue {
  @Model() modelValue?: any[];
  @Prop() options: any[] = [];
  @Prop() prop = "";
  @Prop() disabled = false;

  values: any[] = [];

  created(): void {
    this.onModelValueChanged();
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelValue) {
      this.values = this.modelValue.map((it) => it[this.prop]);
    } else {
      this.$emit("update:modelValue", []);
    }
    
  }

  handleBeforeChange(): void {
    const modelValue = [];
    for (const value of this.values) {
      var item = this.modelValue?.filter((it) => it[this.prop] == value);
      if (!item || item.length == 0) {
        const data: any = {};
        data[this.prop] = value;
        modelValue.push(data);
      } else {
        modelValue.push(item[0]);
      }
    }
    this.$emit("update:modelValue", modelValue);
    this.handleChange(
      this.values,
      this.options.filter((it) => {
        return this.values.indexOf(it.Value) != -1;
      })
    );
  }

  @Emit()
  handleChange(values: any[], options: any[]): void {
    //
  }
}
