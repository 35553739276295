import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";
import { EnumOrderPrePackedBoxMode, EnumOrderPrePackedBoxModeOption } from "./EnumOrderPrePackedBoxMode";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPrePackedBox {
    constructor() {
        //无参构造函数
        this.Ids = [];
        this.SelectIds = [];
        this.DeleteIds = [];
    }

    [proName: string]: any;
    /**
     * 
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 委托书主键
     */
    Ids?: string[];
    /**
     * 委托书主键
     */
    SelectIds?: string[];
    /**
     * 删除的委托书主键
     */
    DeleteIds?: string[];
    /**
     * 材积比-重量
     */
    VolumeRatioWeight?: number;
    /**
     * 单件最低计费重
     */
    MinimumChargeableWeight?: number;
    /**
     * 单票最低计费重
     */
    OneMinimumChargeableWeight?: number;
    /**
     * 类型
     */
    Type?: EnumCompanyChargeType;
    /**
     * 计费重量
     */
    CompanyChargeId?: string;
    /**
     * 类型
     */
    Mode?: EnumOrderPrePackedBoxMode;
    /**
     * 
     */
    OrderPrePackedBoxId?: string;
    /**
     * 名称
     */
    Name?: string;
}

/**
 * -表单校验
 */
export const DtoRequestPrePackedBoxRules = {
}
