import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 委托书-货物清单分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchOrderPoolCargo extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 干线物流Id
     */
    TrunkId?: string;
    /**
     * 快递Id
     */
    ExpressId?: string;
    /**
     * 仓库主键
     */
    WharfId?: string;
}

/**
 * 委托书-货物清单分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchOrderPoolCargoRules = {
}

/**
 * 委托书-货物清单分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchOrderPoolCargoDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
