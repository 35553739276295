import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 关联-公司/用户-首页工作台
 * @description 自动生成的代码,请勿修改
 */
export default class JoinCompanyUserHomeWorkAppService {
    /**
     * 获取工作台数据
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 工作台数据
     */
    static Get(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/JoinCompanyUserHomeWork/Get`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取已勾选的代码
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 代码
     */
    static GetCheckCodes(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/JoinCompanyUserHomeWork/GetCheckCodes`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 保存
     * @param {string[]} [codes] 代码
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(codes?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUserHomeWork/Post`;
        return service.post<RESTfulResult<any>>(url, codes);
    }

}
