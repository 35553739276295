
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailCompanyContact from "@/models.machine/DtoDetailCompanyContact";
import MiezzButton from "@/models/MiezzButton";
import CompanyContactDetail from "@/views/Company/CompanyContact/CompanyContactDetail.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
@Options({
  components: {
    CompanyContactDetail,
  },
})
export default class CustomerServiceProviderCompanyContactDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompanyContact, string>("CustomerManagement_ServiceProvider_Contact_Detail");
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "edit",
      Title: "编辑",
      Icon: "edit",
      Type: "default",
      Size: "small",
      PowerCode: "CustomerManagement_ServiceProvider_Contact_Edit",
    },
  ];
  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "详细信息",
        Power: "CustomerManagement_ServiceProvider_Contact_Detail",
      },
    ];
  }
}
