import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackShiftout extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * Ids
     */
    Ids?: string[];
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商名称
     */
    SupplierCompanyId?: string;
    /**
     * 供应商名称
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 全部出库时间
     */
    OutStockTime?: Date;
}

/**
 * -表单校验
 */
export const DtoCallbackShiftoutRules = {
    SupplierSource: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    SupplierCompanyId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    SupplierCompanyContactLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    OutStockTime: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoCallbackShiftoutMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Ids",
    Label: "Ids",
    Customize: true,
  },
  {
    Prop: "SupplierSource",
    Label: "供应商来源",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "供应商类型",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商名称",
    Customize: true,
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "供应商联系人",
    Customize: true,
  },
  {
    Prop: "OutStockTime",
    Label: "全部出库时间",
    Customize: true,
  },
]
