import UiPageList from "./UiPageList";
import DtoListItemJoinCompanyUser from "./DtoListItemJoinCompanyUser";

/**
 * 关联-公司/用户分页列表分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListJoinCompanyUser extends UiPageList<DtoListItemJoinCompanyUser, string> {
    constructor() {
        //无参构造函数
        super()
        this.Trusteeship = false;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 服务商托管
     */
    Trusteeship?: boolean;
}

/**
 * 关联-公司/用户分页列表分页列表-表单校验
 */
export const DtoPageListJoinCompanyUserRules = {
}
