
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import OSS from "ali-oss";
import moment from "moment";
import { ElMessage } from "element-plus";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzModal from "@/models/MiezzModal";
import MiezzAlertLog from "@/models/MiezzAlertLog";
export default class MiezzFileUpload extends Vue {
  @Prop() url?: string;
  @Prop() modelList?: any = [];
  fileList?: any = [];
  headers: any = null;

  modalImportLog = new MiezzModal();
  importLogs: MiezzAlertLog[] = [];
  importRate = 0;

  created(): void {
    this.modalImportLog.Title = "从模板导入";
    var logier = CurrentLogier.GetLogier();
    if (logier) {
      this.headers = {
        Authorization: `Bearer ${logier.Token}`,
      };
    }
  }

  @Emit()
  handleTemplate(): void {
    //
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelList) {
      this.fileList = this.modelList;
    } else {
      this.fileList = [];
    }
  }

  beforeUpload(file: any): boolean {
    var FileExt = file.name.replace(/.+\./, "");
    if (["xls", "xlsx"].indexOf(FileExt.toLowerCase()) === -1) {
      ElMessage.warning("仅支持xls和xlsx格式的文件上传!");
      return false;
    }

    this.modalImportLog.Show = true;
    this.importLogs = [];
    this.importRate = 0;
    CurrentLogier.HubConnection?.off("AlertLog");
    CurrentLogier.HubConnection?.off("AlertCmdLog");
    CurrentLogier.HubConnection?.on(
      "AlertLog",
      (
        message: string,
        type: "success" | "warning" | "info" | "error",
        effect: "dark" | "light"
      ) => {
        this.importLogs.unshift({
          Message: message,
          Type: type,
          Effect: effect,
        });
      }
    );
    CurrentLogier.HubConnection?.on(
      "AlertProgress",
      (current: number, total: number) => {
        this.importRate = total == 0 ? 100 : (current / total) * 100;
      }
    );
    return true;
  }

  handleRemove(): void {
    this.$emit("update:modelValue", []);
    this.fileList = [];
  }

  handleExceed(files: any, fileList: any): void {
    ElMessage.warning(
      `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
        files.length + fileList.length
      } 个文件`
    );
  }

  handleSuccess(response: any): void {
    if (response.Succeeded) {
      this.importLogs.unshift({
        Message: "导入结束",
        Type: "success",
        Effect: "dark",
      });
      // ElMessage.success("导入成功");
    } else {
      this.importLogs.unshift({
        Message: "导入失败：" + response.Errors,
        Type: "error",
        Effect: "dark",
      });
      this.$emit("update:modelValue", []);
      this.fileList = [];
    }
  }
}
