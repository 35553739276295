import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchHsCode from "../models.machine/DtoSelectSearchHsCode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchHsCode from "../models.machine/DtoPageListSearchHsCode";
import DtoPageListHsCode from "../models.machine/DtoPageListHsCode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailHsCode from "../models.machine/DtoDetailHsCode";
import DtoFormHsCode from "../models.machine/DtoFormHsCode";

/**
 * HsCod
 * @description 自动生成的代码,请勿修改
 */
export default class HsCodeAppService {
    /**
     * 获取HsCode选择器
     * @param {DtoSelectSearchHsCode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchHsCode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/HsCode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取HsCode分页列表
     * @param {DtoPageListSearchHsCode} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListHsCode>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchHsCode): AxiosPromise<RESTfulResult<DtoPageListHsCode>> {
        const url = `/api/HsCode/Get`;
        return service.get<RESTfulResult<DtoPageListHsCode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/HsCode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取HsCode详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailHsCode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailHsCode>> {
        const url = `/api/HsCode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailHsCode>>(url);
    }

    /**
     * 新增/编辑HsCode
     * @param {DtoFormHsCode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} Id
     */
    static Post(dto?: DtoFormHsCode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/HsCode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除HsCode
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/HsCode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/HsCode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/HsCode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/HsCode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 采集第三方的数据
     * @param {number} [start] 
     * @param {number} [end] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 是否成功
     */
    static PullByStartByEnd(start?: number, end?: number): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/HsCode/Pull/${start}/${end}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 获取详情
     * @param {string} [code] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailHsCode>>} 
     */
    static GetByCodeByCode(code?: string): AxiosPromise<RESTfulResult<DtoDetailHsCode>> {
        const url = `/api/HsCode/GetByCode/${code}`;
        return service.get<RESTfulResult<DtoDetailHsCode>>(url);
    }

}
