import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderPoolContainerTrailer_Addresses_Addresse_CompanyAddress from "./DtoPutOrderPoolContainerTrailer_Addresses_Addresse_CompanyAddress";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_Addresses_Addresse extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司地址Id
     */
    CompanyAddressId?: string;
    /**
     * 公司地址
     */
    CompanyAddress?: DtoPutOrderPoolContainerTrailer_Addresses_Addresse_CompanyAddress;
    /**
     * 计划时间
     */
    PlanTime?: Date;
    /**
     * 货量
     */
    GoodsQuantity?: number;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_Addresses_AddresseRules = {
    PlanTime: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_Addresses_AddresseMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CompanyAddressId",
    Label: "公司地址Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CompanyAddress",
    Label: "公司地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PlanTime",
    Label: "计划时间",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GoodsQuantity",
    Label: "货量",
    Type: EnumMiezzFormItemType.InputText,
  },
]
