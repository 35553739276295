import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPriceService from "../models.machine/DtoSelectSearchPriceService";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchPriceService from "../models.machine/DtoPageListSearchPriceService";
import DtoPageListPriceService from "../models.machine/DtoPageListPriceService";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPriceService from "../models.machine/DtoDetailPriceService";
import DtoFormPriceService from "../models.machine/DtoFormPriceService";
import DtoDetailPriceServiceRemark from "../models.machine/DtoDetailPriceServiceRemark";
import DtoFormPriceServiceRemark from "../models.machine/DtoFormPriceServiceRemark";
import DtoPriceServiceCompanyShow from "../models.machine/DtoPriceServiceCompanyShow";
import DtoFormPriceServiceCompanyShow from "../models.machine/DtoFormPriceServiceCompanyShow";

/**
 * 价格服务
 * @description 自动生成的代码,请勿修改
 */
export default class PriceServiceAppService {
    /**
     * 获取价格服务选择器
     * @param {DtoSelectSearchPriceService} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPriceService): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PriceService/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取价格服务分页列表
     * @param {DtoPageListSearchPriceService} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPriceService>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPriceService): AxiosPromise<RESTfulResult<DtoPageListPriceService>> {
        const url = `/api/PriceService/Get`;
        return service.get<RESTfulResult<DtoPageListPriceService>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceService/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取价格服务详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceService>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceService>> {
        const url = `/api/PriceService/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceService>>(url);
    }

    /**
     * 校验是否存在
     * @param {string} [id] 价格服务Id
     * @param {string} [name] 名称
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 是否存在
     */
    static CheckExist(id?: string, name?: string): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PriceService/CheckExist`;
        const _querys: any = {};
        _querys.id = id
        _querys.name = name
        return service.post<RESTfulResult<boolean>>(url, undefined, { params: _querys });
    }

    /**
     * 添加价格服务
     * @param {DtoFormPriceService} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPriceService): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceService/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 开启/关闭
     * @param {string} [id] 价格服务Id
     * @param {boolean} [open] 开启/关闭
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static OpenByIdByOpen(id?: string, open?: boolean): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceService/Open/${id}/${open}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 批量删除价格服务
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceService/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取价格服务-摘要与注意事项
     * @param {string} [id] 价格服务Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceServiceRemark>>} 详细
     */
    static GetRemarkById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceServiceRemark>> {
        const url = `/api/PriceService/GetRemark/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceServiceRemark>>(url);
    }

    /**
     * 添加价格服务-摘要与注意事项
     * @param {DtoFormPriceServiceRemark} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostRemark(dto?: DtoFormPriceServiceRemark): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceService/PostRemark`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取公司服务显示信息
     * @param {string} [companyId] 公司Id
     * @returns {AxiosPromise<RESTfulResult<DtoPriceServiceCompanyShow[]>>} 服务显示信息
     */
    static GetCompanyShowByCompanyId(companyId?: string): AxiosPromise<RESTfulResult<DtoPriceServiceCompanyShow[]>> {
        const url = `/api/PriceService/GetCompanyShow/${companyId}`;
        return service.get<RESTfulResult<DtoPriceServiceCompanyShow[]>>(url);
    }

    /**
     * 保存公司服务显示信息
     * @param {DtoFormPriceServiceCompanyShow} [dto] 服务显示信息
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostCompanyShow(dto?: DtoFormPriceServiceCompanyShow): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceService/PostCompanyShow`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
