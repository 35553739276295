import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 地区表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamCountryArea extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Deep = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountryId?: string;
    /**
     * 地区级别
     */
    AreaLevel?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 深度
     */
    Deep?: number;
    /**
     * 地区-1
     */
    AreaId1?: string;
    /**
     * 地区-2
     */
    AreaId2?: string;
    /**
     * 地区-3
     */
    AreaId3?: string;
    /**
     * 地区-4
     */
    AreaId4?: string;
    /**
     * 所属人
     */
    AffiliatedPerson?: string;
    /**
     * 详细地址
     */
    DetailedAddress?: string;
}

/**
 * 地区表单-表单校验
 */
export const DtoFormParamCountryAreaRules = {
    ParamCountryId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    AreaLevel: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameZh: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Abridge: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    PostalCode: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Deep: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    AffiliatedPerson: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DetailedAddress: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 地区表单-设置项
 */
export const DtoFormParamCountryAreaMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "AreaLevel",
    Label: "地区级别",
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Abridge",
    Label: "缩写",
    Customize: true,
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Disabled: true,
    Customize: true,
  },
  {
    Prop: "Deep",
    Label: "深度",
    Hide: true,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AreaId1",
    Label: "地区-1",
    Customize: true,
  },
  {
    Prop: "AreaId2",
    Label: "地区-2",
    Customize: true,
  },
  {
    Prop: "AreaId3",
    Label: "地区-3",
    Customize: true,
  },
  {
    Prop: "AreaId4",
    Label: "地区-4",
    Customize: true,
  },
  {
    Prop: "AffiliatedPerson",
    Label: "所属人",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "DetailedAddress",
    Label: "详细地址",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
