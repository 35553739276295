import UiSelectOption from '@/models.machine/UiSelectOption';
import moment from 'moment';

export default class Pipe {
    static GetEnumLabel(options: UiSelectOption<number>[], v?: number): string {
        return options?.firstOrDefault((it) => it.Value == v)?.Label ?? "暂无数据";
    }
    
    static GetDate(v?: Date): string {
        return v ? moment(v).format("YYYY/MM/DD") : "暂无数据";
    }
    static GetDateRange(start?: Date, end?: Date): string {
        return start && end ? moment(start).format("YYYY/MM/DD") + " - " + moment(end).format("YYYY/MM/DD") : "暂无数据";
    }
}