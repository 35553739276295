import { RouteRecordRaw } from 'vue-router'
import MenuPageList from "./Menu/MenuPageList.vue"
import MenuTree from "./Menu/MenuTree.vue"
import PowerTree from "./Power/PowerTree.vue"
import RolePageList from "./Role/RolePageList.vue"
import CompanyTypePageList from "./CompanyType/CompanyTypePageList.vue"
import AuditLogPageList from "./LogRecord/AuditLog/AuditLogPageList.vue"
import ParamList from "./Parameter/ParamList/ParamListPageList.vue"
import ParamCountryPageList from "./Parameter/ParamCountry/ParamCountryPageList.vue"
import ParamCurrencySystemPageList from "./Parameter/ParamCurrency/ParamCurrencySystemPageList.vue"
import ParamCountryAreaPageList from "./Parameter/ParamCountryArea/ParamCountryAreaPageList.vue"
import ParamPortPageList from "./Parameter/ParamPort/ParamPortPageList.vue"
import ParamAuditLogPageList from "./Parameter/ParamAudit/ParamAuditLogPageList.vue"
import ParamTaxTypePageList from "./Parameter/ParamTaxType/ParamTaxTypePageList.vue"
import ParamCompanyCodeTypePageList from "./Parameter/ParamCompanyCodeType/ParamCompanyCodeTypePageList.vue"
import ParamInvoiceTaxRatePageList from "./Parameter/ParamInvoiceTaxRate/ParamInvoiceTaxRatePageList.vue"
import ParamFbaWarehouseCodePageList from "./Parameter/ParamFbaWarehouseCode/ParamFbaWarehouseCodePageList.vue"
import ParamAirlineCompanyPageList from "./Parameter/ParamAirlineCompany/ParamAirlineCompanyPageList.vue"
import ParamShippingCompanyPageList from "./Parameter/ParamShippingCompany/ParamShippingCompanyPageList.vue"
import ParamPriceCostPageList from "./Parameter/ParamPriceCost/ParamPriceCostPageList.vue"
import ParamPriceFieldPageList from "./Parameter/ParamPriceField/ParamPriceFieldPageList.vue"
import ParamCarrierPageList from "./Parameter/ParamCarrier/ParamCarrierPageList.vue"
import ParamTransportChannelPageList from "./Parameter/ParamTransportChannel/ParamTransportChannelPageList.vue"
import ParamTransportChargeUnitPageList from "./Parameter/ParamTransportChargeUnit/ParamTransportChargeUnitPageList.vue"
import ParamTransportRouteCodePageList from "./Parameter/ParamTransportRouteCode/ParamTransportRouteCodePageList.vue"
import HttpLogPageList from "./LogRecord/HttpLog//HttpLogPageList.vue"
import ExceptionLogPageList from "./LogRecord/ExceptionLog/ExceptionLogPageList.vue"
import SmsLogPageList from "./LogRecord/SmsLog/SmsLogPageList.vue"
import ServerLogPageList from "./LogRecord/ServerLog/ServerLogPageList.vue"
import ParamChargeUnitPageList from "./Parameter/ParamChargeUnit/ParamChargeUnitPageList.vue"
import ParamHsCodePageList from "./Parameter/HsCode/HsCodePageList.vue"
import PackageZhPageList from "./Parameter/PackageZh/PackageZhPageList.vue"
import PackageEnPageList from "./Parameter/PackageEn/PackageEnPageList.vue"
import CustomhousePageList from "./Parameter/Customhouse/CustomhousePageList.vue"
import ContactTransportModePageList from "./Parameter/ContactTransportMode/ContactTransportModePageList.vue"
import TransportModePageList from "./Parameter/TransportMode/TransportModePageList.vue"
import DeliveryRequirementPageList from "./Parameter/DeliveryRequirement/DeliveryRequirementPageList.vue"
import DomesticGoodsPlacePageList from "./Parameter/DomesticGoodsPlace/DomesticGoodsPlacePageList.vue"
import ExemptingModePageList from "./Parameter/ExemptingMode/ExemptingModePageList.vue"
import ExemptionNaturePageList from "./Parameter/ExemptionNature/ExemptionNaturePageList.vue"
import PaymentTermsPageList from "./Parameter/PaymentTerms/PaymentTermsPageList.vue"
import PaymentTermsContentPageList from "./Parameter/PaymentTermsContent/PaymentTermsContentPageList.vue"
import PlacePageList from "./Parameter/Place/PlacePageList.vue"
import SupervisionModePageList from "./Parameter/SupervisionMode/SupervisionModePageList.vue"
import TransactionModePageList from "./Parameter/TransactionMode/TransactionModePageList.vue"
import TradeTermsPageList from "./Parameter/TradeTerms/TradeTermsPageList.vue"
import ParamContainerTypePageList from "./Parameter/ParamContainerType/ParamContainerTypePageList.vue"
import BrandPageList from "./Parameter/Brand/BrandPageList.vue"
import ExportPageList from "./Parameter/Export/ExportPageList.vue"
import CURPMPageList from './Power/CURPMPageList.vue'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/sys/menu',
        name: 'System_Menu',
        component: MenuPageList,
        meta: {
            title: "系统 - 菜单"
        },
    },
    {
        path: '/sys/menu/tree',
        name: 'System_Menu_Tree',
        component: MenuTree,
        meta: {
            title: "系统 - 菜单树"
        },
    },
    {
        path: '/sys/power/tree',
        name: 'System_Power_Tree',
        component: PowerTree,
        meta: {
            title: "系统 - 权限树"
        },
    },
    {
        path: '/sys/power/curpm',
        name: 'System_Power_CURPM',
        component: CURPMPageList,
        meta: {
            title: "系统 - 公司/用户/职能/权限/菜单查询"
        },
    },
    {
        path: '/sys/role',
        name: 'Sys_Role',
        component: RolePageList,
        meta: {
            title: "系统 - 角色"
        },
    },
    {
        path: '/sys/company-type',
        name: 'System_CompanyType',
        component: CompanyTypePageList,
        meta: {
            title: "系统 - 公司类型"
        },
    },
    {
        path: '/sys/param/param-list',
        name: 'System_ParamList_List',
        component: ParamList,
        meta: {
            title: "系统 - 参数管理 - 参数列表"
        },
    },
    {
        path: '/sys/param/param-country',
        name: 'System_ParamList_Country',
        component: ParamCountryPageList,
        meta: {
            title: "系统 - 参数管理 - 国家"
        },
    },
    {
        path: '/sys/param/param-currency',
        name: 'System_ParamList_Currency',
        component: ParamCurrencySystemPageList,
        meta: {
            title: "系统 - 参数管理 - 币制"
        },
    },
    {
        path: '/sys/param/param-area',
        name: 'System_ParamList_Area',
        component: ParamCountryAreaPageList,
        meta: {
            title: "系统 - 参数管理 - 地区"
        },
    },
    {
        path: '/sys/param/param-port',
        name: 'System_ParamList_Port',
        component: ParamPortPageList,
        meta: {
            title: "系统 - 参数管理 - 港口"
        },
    },
    {
        path: '/sys/param/param-company-code-type',
        name: 'System_ParamList_CompanyCodeType',
        component: ParamCompanyCodeTypePageList,
        meta: {
            title: "系统 - 参数管理 - 企业代码类型"
        },
    },
    {
        path: '/sys/param/param-tax-type',
        name: 'System_ParamList_TaxType',
        component: ParamTaxTypePageList,
        meta: {
            title: "系统 - 参数管理 - 纳税人类型"
        },
    },
    {
        path: '/sys/param/param-invoice-tax-rate',
        name: 'System_ParamList_InvoiceTaxRate',
        component: ParamInvoiceTaxRatePageList,
        meta: {
            title: "系统 - 参数管理 - 开票税率"
        },
    },
    {
        path: '/sys/param/param_fba_warehouse_code',
        name: 'System_ParamList_FbaWarehouseCode',
        component: ParamFbaWarehouseCodePageList,
        meta: {
            title: "系统 - 参数管理 - FBA仓库代码"
        },
    },
    {
        path: '/sys/param/param_airline_company',
        name: 'System_ParamList_AirlineCompany',
        component: ParamAirlineCompanyPageList,
        meta: {
            title: "系统 - 参数管理 - 航空公司"
        },
    },
    {
        path: '/sys/param/param_shipping_company',
        name: 'System_ParamList_ShippingCompany',
        component: ParamShippingCompanyPageList,
        meta: {
            title: "系统 - 参数管理 - 船公司"
        },
    },
    {
        path: '/sys/param/param-price-cost',
        name: 'System_ParamList_PriceCost',
        component: ParamPriceCostPageList,
        meta: {
            title: "系统 - 参数管理 - 价格费用"
        },
    },
    {
        path: '/sys/param/param-price-field',
        name: 'System_ParamList_PriceField',
        component: ParamPriceFieldPageList,
        meta: {
            title: "系统 - 参数管理 - 价格要素"
        },
    },
    {
        path: '/sys/param/carrier',
        name: 'System_ParamList_Carrier',
        component: ParamCarrierPageList,
        meta: {
            title: "系统 - 参数管理 - 承运人"
        },
    },
    {
        path: '/sys/param/transport-channel',
        name: 'System_ParamList_TransportChannel',
        component: ParamTransportChannelPageList,
        meta: {
            title: "系统 - 参数管理 - 渠道"
        },
    },
    {
        path: '/sys/param/transport-route-code',
        name: 'System_ParamList_TransportRouteCode',
        component: ParamTransportRouteCodePageList,
        meta: {
            title: "系统 - 参数管理 - 航线代码"
        },
    },
    {
        path: '/sys/param/transport-charge-unit',
        name: 'System_ParamList_TransportChargeUnit',
        component: ParamTransportChargeUnitPageList,
        meta: {
            title: "系统 - 参数管理 - 物流计价单位"
        },
    },
    {
        path: '/sys/param/param-auditlog',
        name: 'System_ParamList_AuditLog',
        component: ParamAuditLogPageList,
        meta: {
            title: "系统 - 参数管理 - 审核记录"
        },
    },
    {
        path: '/sys/param/charge-unit',
        name: 'System_ParamList_ChargeUnit',
        component: ParamChargeUnitPageList,
        meta: {
            title: "系统 - 参数管理 - 计价单位"
        },
    },
    {
        path: '/sys/param/hscode',
        name: 'System_ParamList_HsCode',
        component: ParamHsCodePageList,
        meta: {
            title: "系统 - 参数管理 - HsCode"
        },
    },
    {
        path: '/sys/param/packagezh',
        name: 'System_ParamList_PackageZh',
        component: PackageZhPageList,
        meta: {
            title: "系统 - 参数管理 - 中文包装种类"
        },
    },
    {
        path: '/sys/param/packageen',
        name: 'System_ParamList_PackageEn',
        component: PackageEnPageList,
        meta: {
            title: "系统 - 参数管理 - 英文包装种类"
        },
    },
    {
        path: '/sys/param/customhouse',
        name: 'System_ParamList_Customhouse',
        component: CustomhousePageList,
        meta: {
            title: "系统 - 参数管理 - 申报地海关"
        },
    },
    {
        path: '/sys/param/contacttransportmode',
        name: 'System_ParamList_ContactTransportMode',
        component: ContactTransportModePageList,
        meta: {
            title: "系统 - 参数管理 - 运输方式"
        },
    },
    {
        path: '/sys/param/transportmode',
        name: 'System_ParamList_TransportMode',
        component: TransportModePageList,
        meta: {
            title: "系统 - 参数管理 - 运输方式(报关单)"
        },
    },
    {
        path: '/sys/param/deliveryrequirement',
        name: 'System_ParamList_DeliveryRequirement',
        component: DeliveryRequirementPageList,
        meta: {
            title: "系统 - 参数管理 - 交期要求"
        },
    },
    {
        path: '/sys/param/domesticgoodsplace',
        name: 'System_ParamList_DomesticGoodsPlace',
        component: DomesticGoodsPlacePageList,
        meta: {
            title: "系统 - 参数管理 - 境内货源地"
        },
    },
    {
        path: '/sys/param/exemptingmode',
        name: 'System_ParamList_ExemptingMode',
        component: ExemptingModePageList,
        meta: {
            title: "系统 - 参数管理 - 征免方式"
        },
    },
    {
        path: '/sys/param/exemptionnature',
        name: 'System_ParamList_ExemptionNature',
        component: ExemptionNaturePageList,
        meta: {
            title: "系统 - 参数管理 - 征免性质"
        },
    },
    {
        path: '/sys/param/paymentterms',
        name: 'System_ParamList_PaymentTerms',
        component: PaymentTermsPageList,
        meta: {
            title: "系统 - 参数管理 - 付款条款"
        },
    },
    {
        path: '/sys/param/paymenttermscontnet',
        name: 'System_ParamList_PaymentTermsContent',
        component: PaymentTermsContentPageList,
        meta: {
            title: "系统 - 参数管理 - 付款条款内容"
        },
    },
    {
        path: '/sys/param/place',
        name: 'System_ParamList_Place',
        component: PlacePageList,
        meta: {
            title: "系统 - 参数管理 - 详细产地"
        },
    },
    {
        path: '/sys/param/supervisionmode',
        name: 'System_ParamList_SupervisionMode',
        component: SupervisionModePageList,
        meta: {
            title: "系统 - 参数管理 - 监管方式"
        },
    },
    {
        path: '/sys/param/transactionmode',
        name: 'System_ParamList_TransactionMode',
        component: TransactionModePageList,
        meta: {
            title: "系统 - 参数管理 - 成交方式"
        },
    },
    {
        path: '/sys/param/tradeterms',
        name: 'System_ParamList_TradeTerms',
        component: TradeTermsPageList,
        meta: {
            title: "系统 - 参数管理 - 贸易条款"
        },
    },
    {
        path: '/sys/param/brand',
        name: 'System_ParamList_Brand',
        component: BrandPageList,
        meta: {
            title: "系统 - 参数管理 - 品牌类型"
        },
    },
    {
        path: '/sys/param/export',
        name: 'System_ParamList_Export',
        component: ExportPageList,
        meta: {
            title: "系统 - 参数管理 - 出口享惠情况"
        },
    },
    {
        path: '/sys/param/param_container_type',
        name: 'System_ParamList_ParamContainerType',
        component: ParamContainerTypePageList,
        meta: {
            title: "系统 - 参数管理 - 箱型"
        },
    },
    {
        path: '/sys/log/http',
        name: 'System_Log_HttpLog',
        component: HttpLogPageList,
        meta: {
            title: "系统 - 日志 - Http日志"
        },
    },
    {
        path: '/sys/log/exception',
        name: 'System_Log_ExceptionLog',
        component: ExceptionLogPageList,
        meta: {
            title: "系统 - 日志 - 异常日志"
        },
    },
    {
        path: '/sys/log/audit',
        name: 'System_Log_AuditLog',
        component: AuditLogPageList,
        meta: {
            title: "系统 - 日志 - 审计日志"
        },
    },
    {
        path: '/sys/log/sms',
        name: 'System_Log_SMSLog',
        component: SmsLogPageList,
        meta: {
            title: "系统 - 日志 - 短信日志"
        },
    },
    {
        path: '/sys/log/server',
        name: 'System_Log_ServerLog',
        component: ServerLogPageList,
        meta: {
            title: "系统 - 日志 - 服务器日志"
        },
    },
];

export default routes;