import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSelectSearchOrdePoolCommon {
    constructor() {
        //无参构造函数
        this.CountSearch = false;
    }

    [proName: string]: any;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 我的编号
     */
    Code?: string;
    /**
     * 统计搜索
     */
    CountSearch?: boolean;
}

/**
 * -表单校验
 */
export const DtoSelectSearchOrdePoolCommonRules = {
}
