
import DtoFormCodeProperty from "@/models.machine/DtoFormCodeProperty";
import DtoFormCodePropertyBaseInfo from "@/models.machine/DtoFormCodePropertyBaseInfo";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Model } from "vue-property-decorator";
import CodeChoosePropertyTag from "./CodeChoosePropertyTag.vue";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
import MiezzButton from "@/models/MiezzButton";

@Options({
  components: {
    CodePropertyTree,
    CodeChoosePropertyTag,
  },
})
export default class CodeSelectForm extends Vue {
  @Prop() template = "";
  @Prop() properties: DtoFormCodeProperty[] = [];
  @Prop() table?: string;

  _template = "";
  _properties: DtoFormCodeProperty[] = [];

  modal = new MiezzModal();

  handleQuote(): void {
    this.modal.Show = true;
  }

  handleClose(): void {
    this.modal.Show = false;
  }

  /**按钮点击 */
  handleModalButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        this.handleClose();
        this.$emit("update:properties", this._properties);
        complete();
        break;

      default:
        break;
    }
  }

  handleClick(item: DtoFormCodePropertyBaseInfo): void {
    if (this._template == undefined) this._template = "";
    this._template += `{option.${item.Name}}`;
    this.handleChange();
  }

  handleChange(): void {
    this.$emit("update:template", this._template);
  }
}
