import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b428a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scrollbar-wrap" }
const _hoisted_2 = { class: "view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_index_company = _resolveComponent("index-company")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_index_menu = _resolveComponent("index-menu")!
  const _component_index_user = _resolveComponent("index-user")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_index_header_chil = _resolveComponent("index-header-chil")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_index_breadcrumb = _resolveComponent("index-breadcrumb")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_header, { class: "header" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 20,
            style: {"height":"60px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 4,
                style: {"height":"60px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_index_company)
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 16,
                style: {"text-align":"center","height":"60px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_index_menu, {
                    onHandleMenu: _ctx.HandleMenu,
                    redCodes: _ctx.redCodes
                  }, null, 8, ["onHandleMenu", "redCodes"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 4,
                style: {"height":"60px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_index_user)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(), _createBlock(_component_index_header_chil, {
        menus: _ctx.menus,
        key: _ctx.random,
        redCodes: _ctx.redCodes
      }, null, 8, ["menus", "redCodes"])),
      _createVNode(_component_el_main, { class: "main" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_scrollbar, { class: "scrollbar" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_router_view)
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_footer, { class: "footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_index_breadcrumb)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}