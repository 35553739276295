
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrderState {
    constructor() {
        //无参构造函数
        this.OrderId = [];
        this.PoolId = [];
    }

    [proName: string]: any;
    /**
     * 订单主键
     */
    OrderId?: string[];
    /**
     * 委托书主键
     */
    PoolId?: string[];
}

/**
 * -表单校验
 */
export const DtoRequestOrderStateRules = {
}
