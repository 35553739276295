import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 参数列表表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamList extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 参数名称
     */
    Name?: string;
    /**
     * 参数地址
     */
    Url?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 参数列表表单-表单校验
 */
export const DtoFormParamListRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Url: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 参数列表表单-设置项
 */
export const DtoFormParamListMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "参数名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Url",
    Label: "参数地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
