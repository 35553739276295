import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 付款条款分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPaymentTerms extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    Name?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 英文简写
     */
    Abbr?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 是否信用证
     */
    IsCredit?: boolean;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 付款条款分页列表-项-表单校验
 */
export const DtoListItemPaymentTermsRules = {
}

/**
 * 付款条款分页列表-项-设置项
 */
export const DtoListItemPaymentTermsMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "Abbr",
    Label: "英文简写",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
  {
    Prop: "IsCredit",
    Label: "是否信用证",
    Customize: true,
  },
]
