import UiSelectOption from "./UiSelectOption";

/**
 * 订单服务类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderServiceType {
    /**
     * 装货港服务
     */
    DeparturePort = 10,
    /**
     * 目的港服务
     */
    DestinationPort = 20,
}

/**
 * 订单服务类型-选项
 */
export const EnumOrderServiceTypeOption: UiSelectOption<number>[] = [
    { Label: "装货港服务", Value: 10, Data: "DeparturePort" },
    { Label: "目的港服务", Value: 20, Data: "DestinationPort" },
];
