
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
import DtoFormParamTransportChargeUnit, {
  DtoFormParamTransportChargeUnitMiezzFormItems,
  DtoFormParamTransportChargeUnitRules,
} from "@/models.machine/DtoFormParamTransportChargeUnit";

export default class ParamTransportChargeUnitForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamTransportChargeUnit, string>();
  optFullName?: Array<string> = [];
  optCodeList: Array<string> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamTransportChargeUnit();
    //表单-校验
    this.form.Rules = DtoFormParamTransportChargeUnitRules;
    this.form.Rules.Code = [
      { required: true, message: "信息不能为空", trigger: "blur" },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.Code!= null && typeof (this.form.Data.Code) != "undefined" && this.form.Data.Code != "undefined" && this.form.Data.Code != "") {
            var Code = this.form.Data.Code.toUpperCase().trim();
            if (this.optCodeList.some(it => it == Code)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      }
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamTransportChargeUnitMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamTransportChargeUnitAppService.GetCodeList().then((r1) => {
      if (r1.data.Data) this.optCodeList = r1.data.Data;

      if (this.id) {
        ParamTransportChargeUnitAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;

            var index = this.optCodeList.findIndex(it => { return it == this.form.Data.Code });
            this.optCodeList.splice(index, 1);
          }
        });
      }
    })
    
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamTransportChargeUnitAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  

  onBlur(): void {
    this.optFullName = [];
    
    if (this.form.Data.Code) {
      this.optFullName?.push(this.form.Data.Code);
    }
    if (this.form.Data.NameZh) {
      this.optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.onBlur();
  }

  onBlurCode(): void {
    if (this.form.Data.Code) {
      this.form.Data.Code = this.form.Data.Code.toUpperCase();
      this.form.Data.Code = this.form.Data.Code.trim();
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
