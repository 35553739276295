import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamPriceCost from "../models.machine/DtoSelectSearchParamPriceCost";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamPriceCost from "../models.machine/DtoPageListSearchParamPriceCost";
import DtoPageListParamPriceCost from "../models.machine/DtoPageListParamPriceCost";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamPriceCost from "../models.machine/DtoDetailParamPriceCost";
import DtoFormParamPriceCost from "../models.machine/DtoFormParamPriceCost";
import DtoListItemParamPriceCost_Repeat from "../models.machine/DtoListItemParamPriceCost_Repeat";

/**
 * 费用名称
 * @description 自动生成的代码,请勿修改
 */
export default class ParamPriceCostAppService {
    /**
     * 获取费用名称选择器
     * @param {DtoSelectSearchParamPriceCost} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamPriceCost): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamPriceCost/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取费用名称分页列表
     * @param {DtoPageListSearchParamPriceCost} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamPriceCost>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamPriceCost): AxiosPromise<RESTfulResult<DtoPageListParamPriceCost>> {
        const url = `/api/ParamPriceCost/Get`;
        return service.get<RESTfulResult<DtoPageListParamPriceCost>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamPriceCost/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取费用名称详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamPriceCost>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamPriceCost>> {
        const url = `/api/ParamPriceCost/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamPriceCost>>(url);
    }

    /**
     * 新增/编辑费用名称
     * @param {DtoFormParamPriceCost} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamPriceCost): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamPriceCost/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除费用名称
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamPriceCost/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamPriceCost/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamPriceCost/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamPriceCost/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamPriceCost_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamPriceCost_Repeat[]>> {
        const url = `/api/ParamPriceCost/GetList`;
        return service.get<RESTfulResult<DtoListItemParamPriceCost_Repeat[]>>(url);
    }

}
