
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSelectSearchParamCountryArea {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 关键字
     */
    Keywords?: string;
    /**
     * 国家
     */
    ParamCountryId?: string;
    /**
     * 上级Id
     */
    PreviousId?: string;
    /**
     * 最后一个Id
     */
    LastId?: string;
    /**
     * 深度
     */
    Deep?: number;
}

/**
 * -表单校验
 */
export const DtoSelectSearchParamCountryAreaRules = {
}
