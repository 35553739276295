import UiSelectOption from "./UiSelectOption";

/**
 * 订单状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderStatus {
    /**
     * 执行中
     */
    Executing = 20,
    /**
     * 已完成
     */
    Completed = 30,
    /**
     * 已取消
     */
    Canceled = 40,
}

/**
 * 订单状态-选项
 */
export const EnumOrderStatusOption: UiSelectOption<number>[] = [
    { Label: "执行中", Value: 20, Data: "Executing" },
    { Label: "已完成", Value: 30, Data: "Completed" },
    { Label: "已取消", Value: 40, Data: "Canceled" },
];
