import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderPoolAuditAction, EnumOrderPoolAuditActionOption } from "./EnumOrderPoolAuditAction";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderPoolAudit extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 动作
     */
    AuditAction?: EnumOrderPoolAuditAction;
    /**
     * 订单编号
     */
    OrderCode?: string;
    /**
     * 订单客服
     */
    ManagerId?: string;
    /**
     * 订单客服
     */
    ManagerLabel?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderPoolAuditRules = {
    AuditAction: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    OrderCode: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    ManagerId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderPoolAuditMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "AuditAction",
    Label: "动作",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumOrderPoolAuditActionOption,
  },
  {
    Prop: "OrderCode",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "ManagerId",
    Label: "订单客服",
    Customize: true,
  },
]
