import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createBlock(_component_el_tree, {
    ref: "treeRef",
    "node-key": "Id",
    data: _ctx.tree,
    props: _ctx.props,
    "expand-on-click-node": false,
    "check-strictly": true,
    "show-checkbox": _ctx.showCheck,
    "default-expand-all": "",
    draggable: "",
    onCheckChange: _ctx.handleCheckChange
  }, {
    default: _withCtx(({ data }) => [
      _createTextVNode(_toDisplayString(data.Name), 1)
    ]),
    _: 1
  }, 8, ["data", "props", "show-checkbox", "onCheckChange"]))
}