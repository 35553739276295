import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import { EnumAuditStatus, EnumAuditStatusOption } from "./EnumAuditStatus";

/**
 * 公司分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyAuth extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.AuthStatus = EnumAuditStatus.No;
    }

    [proName: string]: any;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 法人姓名
     */
    LeaderName?: string;
    /**
     * 法人手机号码
     */
    LeaderPhoneNumber?: string;
    /**
     * 认证状态
     */
    AuthStatus?: EnumAuditStatus;
    /**
     * 认证备注
     */
    AuthRemark?: string;
}

/**
 * 公司分页列表-项-表单校验
 */
export const DtoListItemCompanyAuthRules = {
}

/**
 * 公司分页列表-项-设置项
 */
export const DtoListItemCompanyAuthMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "公司类型",
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "Code",
    Label: "企业代码",
  },
  {
    Prop: "LeaderName",
    Label: "法人姓名",
  },
  {
    Prop: "LeaderPhoneNumber",
    Label: "法人手机号码",
  },
  {
    Prop: "AuthStatus",
    Label: "认证状态",
    Pipe: EnumPipe.Enum,
    Options: EnumAuditStatusOption,
  },
  {
    Prop: "AuthRemark",
    Label: "认证备注",
  },
]
