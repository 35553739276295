
import JoinPriceServiceTableAppService from "@/apis.machine/JoinPriceServiceTableAppService";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import PriceTableAppService from "@/apis.machine/PriceTableAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormJoinPriceServiceTable, {
  DtoFormJoinPriceServiceTableMiezzFormItems,
  DtoFormJoinPriceServiceTableRules,
} from "@/models.machine/DtoFormJoinPriceServiceTable";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class PriceServiceTableForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() serviceId?: string;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormJoinPriceServiceTable, string>();
  tables: UiSelectOption<string>[] = [];
  costs: UiSelectOption<string>[] = [];
  costsCheckAll = false;
  costsCheckAllIndeterminate = false;

  created(): void {
    //表单-校验
    this.form.Rules = DtoFormJoinPriceServiceTableRules;
    var logier = CurrentLogier.GetLogier();
    if (logier?.CompanyType != EnumCompanyType.Platform) {
      this.form.Rules.Costs = [
        {
          required: true,
          message: "请至少选择一条信息",
          trigger: "change",
          type: "array",
        },
      ];
    }
    this.form.Rules.Name = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        max: 256,
        message: "文本长度不能超过 256 个字符",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value) {
            JoinPriceServiceTableAppService.CheckExist(
              this.form.Data.ServiceId,
              this.form.Data.Id,
              this.form.Data.Name
            ).then((r) => {
              if (r.data.Data) {
                return callback(new Error("信息重复，请重新填写"));
              } else {
                callback();
              }
            });
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormJoinPriceServiceTableMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    PriceTableAppService.GetSelect({
      ServiceTableId: this.id,
      AmountType: EnumPriceAmountType.Benchmark,
    }).then((r) => {
      this.tables = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      JoinPriceServiceTableAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          this.handleGetCosts();
        }
      });
    } else {
      //表单-数据-初始化
      this.form.Data = new DtoFormJoinPriceServiceTable();
      this.form.Data.ServiceId = this.serviceId;
    }
  }

  handleCostCheckAll(): void {
    this.form.Data.Costs = this.costsCheckAll
      ? this.costs.map((it) => {
          return {
            Id: this.form.Data.Costs?.firstOrDefault(
              (c) => c.CostId == it.Value
            )?.Id,
            CostId: it.Value,
          };
        }) ?? []
      : [];
    this.handleCostChange();
  }

  handleCostChange(): void {
    const total = this.costs.length;
    const len = this.form.Data.Costs?.length ?? 0;
    this.costsCheckAll = total > 0 && len == total;
    this.costsCheckAllIndeterminate = len > 0 && len < total;
  }

  onTableIdChanged(): void {
    const table = this.tables.firstOrDefault(
      (it) => it.Value == this.form.Data.TableId
    );
    this.form.Data.Name = table?.Label;
    this.handleGetCosts();
  }

  handleGetCosts(): void {
    ParamPriceCostAppService.GetSelect({
      TableId: this.form.Data.TableId,
    }).then((r) => {
      this.costs = r.data.Data ?? [];
      this.form.Data.Costs = this.form.Data.Costs?.filter((it) =>
        this.costs.any((c) => c.Value == it.CostId)
      );
      this.handleCostChange();
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            JoinPriceServiceTableAppService.Post(this.form.Data)
              .then(() => {
                this.modelValue.Show = false;
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
