
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import ProductSpecElementAppService from "@/apis.machine/ProductSpecElementAppService";
    import DtoPageListSearchProductSpecElement, { DtoPageListSearchProductSpecElementDynamicSearchItems } from "@/models.machine/DtoPageListSearchProductSpecElement";
    import DtoPageListProductSpecElement from "@/models.machine/DtoPageListProductSpecElement";
    import DtoListItemProductSpecElement, {
        DtoListItemProductSpecElementMiezzListItems,
    } from "@/models.machine/DtoListItemProductSpecElement";
    import ProductSpecElementDetail from "./ProductSpecElementDetail.vue"; import ProductSpecElementForm from "./ProductSpecElementForm.vue";

    import MiezzForm from "@/models/MiezzForm";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoDetailProduct, {
        DtoDetailProductMiezzDetailItems,
    } from "@/models.machine/DtoDetailProduct";
    @Options({
        components: {
            ProductSpecElementDetail,
            ProductSpecElementForm,
        },
    })
    export default class ProductSpecElementPageList extends Vue {
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchProductSpecElement, DtoListItemProductSpecElement, string>();
        /**详细模态框 */
        modalDetail = new MiezzModal();
        /**表单模态框 */
        modalForm = new MiezzModal();

        /**当前Id */
        id?: string;

        isAll = false;
        product = new MiezzForm<DtoDetailProduct, string>();
        tip = "";
        productId = "";

        /**创建时 */
        created(): void {
            this.productId = this.$route.params.id as string;
            this.product.Data = new DtoDetailProduct();
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchProductSpecElement();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchProductSpecElementDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListProductSpecElement();
            //列表-列配置
            //this.list.Items = DtoListItemProductSpecElementMiezzListItems;
            this.list.EnabledSelect = false;


            this.list.Items = DtoListItemProductSpecElementMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "IsSelected") {
                    item.Customize = true;
                    //item.CustomizeHeader = true;
                    item.Width = 60;
                    item.MaxWidth = 60;
                    item.CustomizeHeader = true;
                }
                return item;
            });
            this.list.Back = true;
            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "add",
                    Title: "添加要素",
                    Label: "添加要素",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_NewlyAdded"
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                //{
                //    Code: "detail",
                //    Title: "查看详细",
                //    Icon: "document",
                //    Type: "default",
                //    Size: "small",
                //    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_Edit",
                //},
                {
                    Code: "edit",
                    Title: "编辑",
                    Icon: "edit",
                    Type: "default",
                    Size: "small",
                    IsBlue:true,
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_Edit",
                }
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "是否确认删除",
                    //MessageBox: true,
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_Delete"
                },
            ];

            this.list.FixTop = "0px";
        }

        onAll(): void {
            this.list.SelectIds = [];
            this.list.SelectRows = [];
            if (this.list && this.list.Data && this.list.Data.Items) {
                var isAll = true;
                for (var it of this.list.Data.Items) {
                    if (it.IsSelected != true) {
                        isAll = false;
                    }
                }
                if (isAll == false) {
                    for (var it1 of this.list.Data.Items) {
                        it1.IsSelected = true;
                        this.list.SelectIds.push(it1.Id + "");
                        this.list.SelectRows.push(it1);
                    }
                }
                else {
                    for (var it2 of this.list.Data.Items) {
                        it2.IsSelected = false;
                    }
                }
            }
        }

        handleRowClick(row: any) {
            this.list.SelectIds = [];
            this.list.SelectRows = [];
            if (this.list && this.list.Data && this.list.Data.Items) {
                for (var it of this.list.Data.Items) {
                    if (it.Id == row.Id) {
                        it.IsSelected = !it.IsSelected;
                    }

                    if (it.IsSelected == true) {
                        this.list.SelectIds.push(it.Id + "");
                        this.list.SelectRows.push(it);
                    }
                }
            }
        }

        /**查询列表 */
        handleGet(): void {
            if (!this.list.Dto) {
                this.list.Dto = new DtoPageListSearchProductSpecElement();
            }
            this.list.Dto.ProductId = this.productId;
            ProductSpecElementAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                if (this.list.Data && this.list.Data.Items) {
                    for (var it of this.list.Data.Items) {
                        it.IsSelected = false;
                        it.ProductSpecElementItemString = "";
                        if (it.ProductSpecElementItems) {
                            for (var it1 of it.ProductSpecElementItems) {
                                it.ProductSpecElementItemString += "【" + it1.Name + "】";
                            }
                        }
                    }
                }
            });


            if (this.productId) {
                ProductAppService.GetById(this.productId).then((r) => {
                    if (r.data.Data) this.product.Data = r.data.Data;
                    this.tip = "以下规格要素适用于产品『" + this.product.Data.NameZh + "/" + this.product.Data.NameEn + "』的所有规格型号";
                });
            }
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    this.modalForm.Show = true;
                    this.modalForm.Title = "新增";
                    this.id = undefined;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemProductSpecElement,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    this.modalDetail.Show = true;
                    this.modalDetail.Title = `规格要素详细-${row.Name}`;
                    complete();
                    break;
                case "edit":
                    this.modalForm.Show = true;
                    this.modalForm.Title = `编辑规格要素-${row.Name}`;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个要素");
                        return;
                    }

                    ProductSpecElementAppService.Delete(this.list.SelectIds)
                        .then(() => {
                            this.handleGet();
                            complete();
                        })
                        .catch(complete);

                    //ElMessageBox.confirm("您确定要删除选中的信息吗？删除该要素信息会影响其他规格型号的选择，是否继续？", "提示", {
                    //    confirmButtonText: "确认",
                    //    cancelButtonText: "取消",
                    //    type: "warning",
                    //})
                    //    .then(() => {

                    //        ProductSpecElementAppService.Delete(this.list.SelectIds)
                    //            .then(() => {
                    //                this.handleGet();
                    //                complete();
                    //            })
                    //            .catch(complete);

                    //    })
                    //    .catch(() => {
                    //        complete();
                    //    });



                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.modalDetail.Show = false;
            this.handleGet();
        }
    }
