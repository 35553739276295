
import { Vue, Options } from "vue-class-component";
import MiezzPageList from "@/models/MiezzPageList";
import CodeControllerAppService from "@/apis.machine/CodeControllerAppService";
import DtoPageListItemCodeController, {
  DtoPageListItemCodeControllerMiezzListItems,
} from "@/models.machine/DtoPageListItemCodeController";
import router from "@/router";
import DtoPageListSearchCodeController, {
  DtoPageListSearchCodeControllerDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCodeController";
import DtoPageListCodeController from "@/models.machine/DtoPageListCodeController";
import MiezzButton from "@/models/MiezzButton";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import TypesciptSync from "./TypesciptSync.vue";

@Options({
  components: {
    TypesciptSync,
  },
})
export default class CodeControllerPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCodeController,
    DtoPageListItemCodeController,
    string
  >("DeveloperTools_Controller");

  modal = new MiezzModal();

  /**创建时 */
  created(): void {
    this.list.OperationWidth = "280px";
    this.list.Dto = new DtoPageListSearchCodeController();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCodeControllerDynamicSearchItems;
    this.list.Data = new DtoPageListCodeController();
    this.list.Items = DtoPageListItemCodeControllerMiezzListItems;
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];
    this.list.BottomButtons = [
      {
        Code: "sync",
        Label: "同步",
        Icon: "refresh",
        Type: "text",
        Size: "default",
        NoNeedBatchSelect: true,
      },
    ];
    this.list.RowButtons = [
      {
        Code: "add-service",
        Label: "服务",
        Title: "添加服务",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "add-dto",
        Label: "DTO",
        Title: "添加DTO",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "detail-service",
        Label: "服务",
        Title: "查看服务",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
    ];
  }

  handleGet(): void {
    CodeControllerAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        router.push("/developer/tool/controller/new");
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "sync":
        this.modal.Show = true;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoPageListItemCodeController,
    complete: () => void
  ): void {
    let search;
    switch (btn.Code) {
      case "add-service":
        router.push({
          path: "/developer/tool/service/new",
          query: {
            Group: row.Group,
            App: row.App,
          },
        });
        complete();
        break;
      case "add-dto":
        router.push({
          path: "/developer/tool/dto/new",
          query: {
            Group: row.Group,
            App: row.App,
          },
        });
        complete();
        break;
      case "detail-service":
        search = new DtoFormDynamicSearch();
        search.Code = "DtoPageListCodeService";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Group",
                  Compare: EnumCompare.Equal,
                  Value: row.Group,
                },
              },
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_App",
                  Compare: EnumCompare.Equal,
                  Value: row.App,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/developer/tool/service",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
              },
            });
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
