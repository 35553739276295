import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape from "./DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct from "./DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageReferenceId from "./DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageReferenceId";
import UiGroup from "./UiGroup";

/**
 * 委托书表单-货物清单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderPool_OrderPoolPackage extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Shapes = [];
        this.Products = [];
        this.Quantity = 0;
        this.ReferenceIds = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 包装编号
     */
    Code?: string;
    /**
     * 箱规cm×cm×cm
     */
    Shapes?: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape[];
    /**
     * 单件毛重KGS
     */
    GrossWeight?: number;
    /**
     * 单件体积CBM
     */
    Volume?: number;
    /**
     * 单件毛重KGS
     */
    TotalGrossWeight?: number;
    /**
     * 单件体积CBM
     */
    TotalVolume?: number;
    /**
     * 产品名称
     */
    Products?: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct[];
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * Shipment ID
     */
    ShipmentId?: string;
    /**
     * Reference ID
     */
    ReferenceId?: string;
    /**
     * FBA No.
     */
    ReferenceIds?: DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageReferenceId[];
}

/**
 * 委托书表单-货物清单-表单校验
 */
export const DtoFormOrderPool_OrderPoolPackageRules = {
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    GrossWeight: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Volume: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Quantity: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    ShipmentId: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 委托书表单-货物清单-设置项
 */
export const DtoFormOrderPool_OrderPoolPackageMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "包装编号",
    Customize: true,
  },
  {
    Prop: "Shapes",
    Label: "箱规cm×cm×cm",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重KGS",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "单件体积CBM",
    Customize: true,
  },
  {
    Prop: "Products",
    Label: "产品名称",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "ShipmentId",
    Label: "Shipment ID",
    Customize: true,
  },
]

/**
 * 委托书表单-货物清单-设置项
 */
export const DtoFormOrderPool_OrderPoolPackageMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "包装编号",
    Customize: true,
  },
  {
    Prop: "Shapes",
    Label: "箱规cm×cm×cm",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重KGS",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "单件体积CBM",
    Customize: true,
  },
  {
    Prop: "Products",
    Label: "产品名称",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "ShipmentId",
    Label: "Shipment ID",
    Customize: true,
  },
]
