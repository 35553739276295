
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoFormJoinCompanyUser, {
  DtoFormJoinCompanyUserMiezzFormItems,
} from "@/models.machine/DtoFormJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserForm from "../UserForm.vue";
@Options({
  components: {
    UserForm,
  },
})
export default class CurrentUserAdd extends Vue {
  form = new MiezzForm<DtoFormJoinCompanyUser, string>(
    "CompanyManagement_User_Add"
  );
  companyId?: string;
  official?: boolean;

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "添加用户",
        Power: "CompanyManagement_User_Add",
      },
    ];
    
    const logier = CurrentLogier.GetLogier();
    this.companyId = logier?.CompanyId;
    this.official = logier?.Official;
    this.form.DefaultSpan = 12;
    if (logier?.CompanyType == EnumCompanyType.Platform) {
      this.form.Items = DtoFormJoinCompanyUserMiezzFormItems.copy().filter(
        (it) =>
          it.Prop != "CompanyId" && it.Prop != "ServiceProviderCompanyName"
      );
    } else {
      this.form.Items = DtoFormJoinCompanyUserMiezzFormItems.copy().filter(
        (it) =>
          it.Prop != "CompanyId" &&
          it.Prop != "ServiceProviderCompanyName" &&
          it.Prop != "UserStatus"
      );
    }
  }
}
