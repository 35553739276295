import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 境内货源地分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemDomesticGoodsPlace extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 海关代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 境内货源地分页列表-项-表单校验
 */
export const DtoListItemDomesticGoodsPlaceRules = {
}

/**
 * 境内货源地分页列表-项-设置项
 */
export const DtoListItemDomesticGoodsPlaceMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "海关代码",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
]
