import UiSelectOption from "./UiSelectOption";

/**
 * 提单份数
 * @description 自动生成的代码,请勿修改
 */
export enum EnumLadingBillNumber {
    /**
     * 三正三副
     */
    ThreePrincipalThreeDeputy = 1,
    /**
     * 三正一副
     */
    ThreePrincipalOneDeputy = 2,
    /**
     * 其他（详见备注信息）
     */
    Other = 3,
}

/**
 * 提单份数-选项
 */
export const EnumLadingBillNumberOption: UiSelectOption<number>[] = [
    { Label: "三正三副", Value: 1, Data: "ThreePrincipalThreeDeputy" },
    { Label: "三正一副", Value: 2, Data: "ThreePrincipalOneDeputy" },
    { Label: "其他（详见备注信息）", Value: 3, Data: "Other" },
];
