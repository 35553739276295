
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoFormPriceQuotationSheet from "@/models.machine/DtoFormPriceQuotationSheet";
import MiezzForm from "@/models/MiezzForm";
import MiezzButton from "@/models/MiezzButton";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoDeleteServiceRemark from "@/models.machine/DtoDeleteServiceRemark";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import DtoFormPriceQuotationSheet_PriceQuotationSheetItem from "@/models.machine/DtoFormPriceQuotationSheet_PriceQuotationSheetItem";
import ParamPriceFieldAppService from "@/apis.machine/ParamPriceFieldAppService";
import { EnumParamPriceFieldType } from "@/models.machine/EnumParamPriceFieldType";
import {
  EnumVoyageUnit,
  EnumVoyageUnitOption,
} from "@/models.machine/EnumVoyageUnit";
import {
  EnumSailingTimeType,
  EnumSailingTimeTypeOption,
} from "@/models.machine/EnumSailingTimeType";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import ParamTransportRouteCodeAppService from "@/apis.machine/ParamTransportRouteCodeAppService";
import { EnumWeekOption } from "@/models.machine/EnumWeek";
import vuedraggable from "vuedraggable";
import DtoDetailPriceQuotationSheetSourceItem from "@/models.machine/DtoDetailPriceQuotationSheetSourceItem";

@Options({
  components: {
    vuedraggable,
  },
})
export default class FormSchemes extends Vue {
  @Model() form!: MiezzForm<DtoFormPriceQuotationSheet, string>;
  @Prop() powers: string[] = [];
  @Prop() serviceRemarks: UiSelectOptionWithStringValue[] = [];
  @Prop() costs: UiSelectOption<string>[] = [];
  @Prop() currencySystems: UiSelectOption<string>[] = [];
  @Prop() units: UiSelectOption<string>[] = [];
  rules: any = {};
  schemeName?: string = "";
  modalScheme = new MiezzModal(MiezzModalType.Dialog);
  modalDataScheme: {
    Name?: string;
  } = {
    Name: undefined,
  };
  deleteItemBtn: MiezzButton = {
    Code: "delete-item",
    Icon: "delete",
    Title: "删除",
    Type: "danger",
    Size: "small",
  };
  addItemBtn: MiezzButton = {
    Code: "add-item",
    Icon: "plus",
    Label: "添加",
    Type: "text",
    Size: "small",
  };

  fields: UiSelectOption<string>[] = [];
  addFieldBtn: MiezzButton = {
    Code: "add-field",
    Icon: "plus",
    Label: "添加要素",
    Type: "text",
    Size: "small",
  };
  fieldRowBtns: MiezzButton[] = [
    {
      Code: "delete-field",
      Icon: "delete",
      Title: "删除",
      Type: "default",
      Size: "small",
    },
    {
      Code: "drag-sort-field",
      Title: "拖曳",
      Icon: "Rank",
      Type: "default",
      Size: "small",
    },
  ];
  modalField = new MiezzModal(MiezzModalType.Dialog);
  modalFieldForm: {
    CheckAll: boolean;
    CheckAllIndeterminate: boolean;
    Ids: string[];
  } = {
    CheckAll: false,
    CheckAllIndeterminate: false,
    Ids: [],
  };
  modalFieldFormRule = {
    Ids: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
  };
  EnumSailingTimeType = EnumSailingTimeType;
  EnumWeekOption = EnumWeekOption;

  created(): void {
    this.modalScheme.Title = "添加方案";
    this.modalScheme.Width = "700px";
    this.modalScheme.Height = "120px";
    this.modalScheme.HandleClick = this.handleClick;
    this.modalScheme.Buttons = [
      {
        Code: "add-scheme-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalField.Title = "添加";
    this.modalField.Width = "700px";
    this.modalField.Height = "100px";
    this.modalField.Buttons = [
      {
        Code: "field-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalField.HandleClick = this.handleClick;

    this.rules.SchemeName = [
      {
        validator: (rule: any, value: string[], callback: any) => {
          if (!value || value.length == 0) {
            return callback(new Error("信息不能为空"));
          } else if (
            this.modalDataScheme.Name != this.schemeName &&
            this.form.Data.Schemes?.any(
              (it) => it.Name == this.modalDataScheme.Name
            )
          ) {
            callback(new Error("信息重复，请重新填写"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.FiledName = [
      {
        validator: (rule: any, value: any, callback: any) => {
          const regex = /\[(\w+)\]/g;
          let matches = [];
          let match;
          while ((match = regex.exec(rule.field)) !== null) {
            matches.push(match[1]);
          }
          const i = parseInt(matches[0]);
          console.log(rule.field, i, matches);
          if (!value) {
            callback(new Error("信息不能为空"));
          } else if (value.length > 256) {
            callback(new Error("文本长度不能超过 256 个字符"));
          } else if (
            (this.form.Data.Schemes![i].FieldValues?.filter(
              (it: any) => it.Name == value
            ).length ?? 0) > 1
          ) {
            callback(new Error("信息重复，请重新填写"));
          } else {
            callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.rules.FieldId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.rules.SupplierCostId = [
      {
        validator: (rule: any, value: any, callback: any) => {
          const i = rule.field.split(".")[0];
          const j = rule.field.split(".")[1];
          const k = rule.field.split(".")[2];
          value = this.form.Data.Schemes![i].Items![j][k];
          if (!value) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.CurrencySystemId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.rules.SupplierCurrencySystemId = [
      {
        validator: (rule: any, value: any, callback: any) => {
          const i = rule.field.split(".")[0];
          const j = rule.field.split(".")[1];
          const k = rule.field.split(".")[2];
          value = this.form.Data.Schemes![i].Items![j][k];
          if (!value) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.FirstChargingNumber = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.rules.SecondChargingNumber = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
  }

  handleInit(): void {
    ParamPriceFieldAppService.GetSelect({
      Types: [EnumParamPriceFieldType.Description],
    }).then((r) => {
      if ((this.form.Data.Schemes?.length ?? 0) == 0) {
        this.form.Data.Schemes?.push({
          Name: "方案1",
          FieldValues: [],
          Items: [],
          Totals: [],
        });
        this.schemeName = "方案1";
      } else {
        this.schemeName = this.form.Data.Schemes![0].Name;
      }
      this.fields = r.data.Data ?? [];
      this.buildFields();
    });
  }

  handleEditSchemes(schemeName?: string, action?: string): void {
    this.schemeName = schemeName;
    if (action == "add") {
      this.modalDataScheme.Name = undefined;
      this.modalScheme.Title = "添加方案";
      this.modalScheme.Show = true;
    } else if (action == "edit") {
      const scheme = this.form.Data.Schemes?.firstOrDefault(
        (it) => it.Name == this.schemeName
      );
      this.modalDataScheme.Name = scheme?.Name;
      this.modalScheme.Title = "编辑名称";
      this.modalScheme.Show = true;
    } else if (action == "delete") {
      let index = this.form.Data.Schemes?.findIndex(
        (it) => it.Name == schemeName
      );
      if (index != undefined && this.form.Data.Schemes) {
        this.form.Data.Schemes?.splice(index, 1);
        setTimeout(() => {
          if (
            index != undefined &&
            this.form.Data.Schemes &&
            this.form.Data.Schemes.length != 0
          ) {
            if (index >= 0 && index < this.form.Data.Schemes.length) {
              this.schemeName = this.form.Data.Schemes[index].Name;
            } else if (index == this.form.Data.Schemes.length) {
              this.schemeName = this.form.Data.Schemes[index - 1].Name;
            }
          }
        }, 100);
      }
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-scheme-submit":
        (this.$refs["ruleFormScheme"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document
                .getElementsByClassName("rule-form-scheme")[0]
                .getElementsByClassName("el-table__body")[0]
                .getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            const scheme = this.form.Data.Schemes?.firstOrDefault(
              (it) => it.Name == this.schemeName
            );
            if (scheme) {
              scheme.Name = this.modalDataScheme.Name;
              this.schemeName = scheme.Name;
            } else {
              this.form.Data.Schemes?.push({
                Name: this.modalDataScheme.Name,
                FieldValues: [],
                Items: [],
                Totals: [],
              });
              this.schemeName = this.modalDataScheme.Name;
            }
            this.modalScheme.Show = false;
            complete();
          }
        });
        complete();
        break;
      case "add-field":
        this.modalFieldForm.Ids =
          this.form.Data.Schemes?.firstOrDefault(
            (it) => it.Name == this.schemeName
          )?.FieldValues?.map((it) => it.FieldId as string) ?? [];
        this.handleFieldCheck();
        this.modalField.Show = true;
        complete();
        break;
      case "field-submit":
        (this.$refs["field-form"] as any).validate((v: boolean) => {
          if (v) {
            for (const id of this.modalFieldForm.Ids) {
              const field = this.fields.firstOrDefault((it) => it.Value == id);
              const item: any = {
                FieldId: id,
                Name: field?.Label,
              };
              if (field?.Data.Code == "SailingDate") {
                item.SailingTimeType = EnumSailingTimeType.Week;
                item.SailingWeeks = [];
                item.SailingWeekArray = [];
              } else if (field?.Data.Code == "Voyage") {
                item.VoyageUnit = EnumVoyageUnit.Day;
              }
              const scheme = this.form.Data.Schemes?.firstOrDefault(
                (it) => it.Name == this.schemeName
              );
              if (scheme && scheme.FieldValues) {
                scheme.FieldValues.push(item);
                console.log(scheme.FieldValues);
                this.buildFields();
              }
            }
            this.modalField.Show = false;
            complete();
          } else {
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  handleFieldRowButtonClick(
    btn: MiezzButton,
    index: number,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "delete-field":
        this.form.Data.Schemes?.firstOrDefault(
          (it) => it.Name == this.schemeName
        )?.FieldValues?.splice(index, 1);
        complete();
        break;

      default:
        break;
    }
    complete();
  }

  handleFieldCheckAll(): void {
    this.modalFieldForm.Ids = this.modalFieldForm.CheckAll
      ? this.fields.map((it) => it.Value as string)
      : [];
    this.handleFieldCheck();
  }

  handleFieldCheck(): void {
    let total = this.fields.length;
    const len = this.modalFieldForm.Ids.length;
    this.modalFieldForm.CheckAll = len == total;
    this.modalFieldForm.CheckAllIndeterminate = len > 0 && len < total;
  }

  setOptions(i: number, options: UiSelectOption<string>[]): void {
    const fields = this.form.Data.Schemes?.firstOrDefault(
      (it) => it.Name == this.schemeName
    )?.FieldValues;
    if (fields) {
      fields[i].Options = options;
    }
  }

  @Watch("schemeName")
  onSchemeNameChanged(): void {
    this.buildFields();
  }

  buildFields(): void {
    const scheme = this.form.Data.Schemes?.firstOrDefault(
      (it) => it.Name == this.schemeName
    );
    if (scheme && scheme.FieldValues) {
      for (let i = 0; i < scheme.FieldValues.length; i++) {
        const item = scheme?.FieldValues[i];
        item.Code = this.fields.firstOrDefault(
          (it) => it.Value == item.FieldId
        )?.Data.Code;
        item.Options = item.Options ?? [];
        switch (item.Code) {
          case "CountryId":
            this.rules.CountryId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              if (q) {
                ParamCountryAppService.GetSelect({ Keywords: q }).then((r) => {
                  this.setOptions(i, r.data.Data ?? []);
                });
              }
            };
            if (
              item &&
              item.CountryId &&
              item.Label &&
              item.Options.length == 0
            ) {
              item.Query(item.Label);
            }
            break;
          case "PortId":
            this.rules.PortId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              if (q) {
                ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
                  this.setOptions(i, r.data.Data ?? []);
                });
              }
            };
            if (item && item.PortId && item.Label && item.Options.length == 0) {
              item.Query(item.Label);
            }
            break;
          case "AreaId":
            this.rules.AreaId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              if (q) {
                ParamCountryAreaAppService.GetSelect({ Keywords: q }).then(
                  (r) => {
                    this.setOptions(i, r.data.Data ?? []);
                  }
                );
              }
            };
            if (item && item.AreaId && item.Label && item.Options.length == 0) {
              item.Query(item.Label);
            }
            break;
          case "CarrierId":
            this.rules.CarrierId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then(
                (r) => {
                  this.setOptions(i, r.data.Data ?? []);
                }
              );
            };
            if (
              item &&
              item.CarrierId &&
              item.Label &&
              item.Options.length == 0
            ) {
              item.Query(item.Label);
            }
            break;
          case "ChannelId":
            this.rules.ChannelId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              ParamTransportChannelAppService.GetSelect({
                Keywords: q,
                Take: 10,
              }).then((r) => {
                this.setOptions(i, r.data.Data ?? []);
              });
            };
            if (
              item &&
              item.ChannelId &&
              item.Label &&
              item.Options.length == 0
            ) {
              item.Query(item.Label);
            }
            break;
          case "RouteCodeId":
            this.rules.RouteCodeId = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            item.Query = (q: string) => {
              if (q) {
                ParamTransportRouteCodeAppService.GetSelect({
                  Keywords: q,
                }).then((r) => {
                  this.setOptions(i, r.data.Data ?? []);
                });
              }
            };
            if (
              item &&
              item.RouteCodeId &&
              item.Label &&
              item.Options.length == 0
            ) {
              item.Query(item.Label);
            }
            break;
          case "Voyage":
            item.Options = EnumVoyageUnitOption;
            this.rules.Voyage = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "blur",
              },
            ];
            this.rules.VoyageUnit = [
              {
                required: true,
                message: "信息不能为空",
                trigger: "change",
              },
            ];
            break;
          case "SailingDate":
            item.Options = EnumSailingTimeTypeOption;
            if (item.SailingTimeType == EnumSailingTimeType.Week) {
              item.SailingWeekArray = item.SailingWeeks?.map(
                (it: any) => it.SailingWeek
              );
            }
            break;

          default:
            break;
        }
      }
    }
  }

  handleCalc(): void {
    this.$nextTick(() => {
      for (const scheme of this.form.Data.Schemes ?? []) {
        scheme.Totals = [];
        for (const item of scheme.Items ?? []) {
          item.Total = undefined;
          if (
            item.Amount != undefined &&
            (item.FirstChargingNumber != undefined ||
              item.SecondChargingNumber != undefined)
          ) {
            if (
              item.FirstChargingNumber != undefined &&
              item.SecondChargingNumber != undefined
            ) {
              item.Total =
                item.Amount *
                item.FirstChargingNumber *
                item.SecondChargingNumber;
            } else if (item.FirstChargingNumber != undefined) {
              item.Total = item.Amount * item.FirstChargingNumber;
            } else if (item.SecondChargingNumber != undefined) {
              item.Total = item.Amount * item.SecondChargingNumber;
            }
          }
          if (item.Total) {
            let total = scheme.Totals.firstOrDefault(
              (it: any) => it.CurrencySystem == item.CurrencySystem.Code
            );
            if (total) {
              total.Amount += item.Total;
            } else {
              total = {
                CurrencySystem: item.CurrencySystem.Code,
                Amount: item.Total,
              };
              scheme.Totals.push(total);
            }
          }
        }
      }
    });
  }

  handleDeleteItem(
    btn: MiezzButton,
    scheme: any,
    index: number,
    complete: () => void
  ): void {
    scheme.Items?.splice(index, 1);
    this.handleCalc();
    complete();
  }

  handleAddItem(btn: MiezzButton, scheme: any, complete: () => void): void {
    const item = new DtoFormPriceQuotationSheet_PriceQuotationSheetItem();
    item.Cost = {};
    item.CurrencySystem = {};
    scheme.Items?.push(item);
    complete();
  }

  handleImport(items: DtoDetailPriceQuotationSheetSourceItem[]): void {
    for (const item of items) {
      this.form.Data.Schemes?.firstOrDefault(
        (it) => it.Name == this.schemeName
      )?.Items?.push(item);
    }
    this.handleCalc();
  }

  getServiceRemarks(q: string, cb: any): void {
    cb(
      this.serviceRemarks.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeleteServiceRemark(
    select: UiSelectOption<string>,
    value: string
  ): void {
    var param = new DtoDeleteServiceRemark();
    param.Value = select.Value;
    OrderPoolAppService.DeleteServiceRemark(param).then(() => {
      const old = this.serviceRemarks.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.form.Data.ServiceRemark = undefined;
      } else {
        this.form.Data.ServiceRemark = old?.Label;
      }
      this.serviceRemarks = this.serviceRemarks.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  filterCosts: UiSelectOption<string>[] = [];
  filterMethod(queryString?: string): void {
    if (this.costs && this.costs.length > 0) {
      if (queryString) {
        this.filterCosts = this.costs.filter((it) =>
          it.Label?.contains(queryString)
        );
        if (this.filterCosts && this.filterCosts.length > 10)
          this.filterCosts = this.filterCosts.slice(0, 10);
      } else {
        this.filterCosts = JSON.parse(JSON.stringify(this.costs)).slice(0, 10);
      }
    }
  }

  handleVisibleChange(show: boolean): void {
    if (show) {
      this.filterMethod();
    }
  }
}
