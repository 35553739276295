
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackOrderState {
    constructor() {
        //无参构造函数
        this.IsLock = false;
        this.OrderCancel = false;
        this.PoolCancel = false;
    }

    [proName: string]: any;
    /**
     * 订单是否锁定
     */
    IsLock?: boolean;
    /**
     * 订单是否取消
     */
    OrderCancel?: boolean;
    /**
     * 委托书是否取消
     */
    PoolCancel?: boolean;
}

/**
 * -表单校验
 */
export const DtoCallbackOrderStateRules = {
}
