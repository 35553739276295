import UiSelectOption from "./UiSelectOption";

/**
 * 运费支付类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumTransportPaymentType {
    /**
     * 预付/Freight Prepaid
     */
    Prepaid = 1,
    /**
     * 到付/Freight Collect
     */
    Collect = 2,
}

/**
 * 运费支付类型-选项
 */
export const EnumTransportPaymentTypeOption: UiSelectOption<number>[] = [
    { Label: "预付/Freight Prepaid", Value: 1, Data: "Prepaid" },
    { Label: "到付/Freight Collect", Value: 2, Data: "Collect" },
];
