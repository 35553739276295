
import GroupAppService from "@/apis.machine/GroupAppService";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoFormGroup, {
  DtoFormGroupMiezzFormItems,
  DtoFormGroupRules,
} from "@/models.machine/DtoFormGroup";
import DtoListItemJoinCompanyUser, {
  DtoListItemJoinCompanyUserMiezzListItems,
} from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoPageListSearchJoinCompanyUser from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzModal from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class GroupForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() parentId?: string;
  @Prop() path?: string;

  form = new MiezzForm<DtoFormGroup, string>(
    "CompanyManagement_Department_AddEdit"
  );
  companyUserList = new MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >();

  created(): void {
    this.form.Data = new DtoFormGroup();
    this.form.Rules = DtoFormGroupRules;
    this.form.Items = DtoFormGroupMiezzFormItems;

    this.companyUserList.Fix = false;
    this.companyUserList.HideCard = true;
    this.companyUserList.EnabledDynamicSearch = false;
    this.companyUserList.EnabledRadioSearch = false;
    this.companyUserList.EnabledPagination = false;
    this.companyUserList.Items =
      DtoListItemJoinCompanyUserMiezzListItems.filter(
        (it) =>
          it.Prop != "Company" && it.Prop != "Status" && it.Prop != "UserStatus"
      );
    this.companyUserList.HandleFormat = this.handleFormat;

    for (const item of this.form.Items) {
      if (item.Prop == "Path") {
        item.Type = EnumMiezzFormItemType.Text;
      }
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    JoinCompanyUserAppService.GetPageList(undefined, {
      PageSize: 1000,
    }).then((r) => {
      this.companyUserList.Data = r.data.Data;
      this.initData();
    });
  }

  initData(): void {
    if (this.id) {
      GroupAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
        if (this.form.Data.JoinCompanyUserIds) {
          this.companyUserList.Data = {
            Items: (
              this.companyUserList.Data?.Items as DtoListItemJoinCompanyUser[]
            ).sort(
              (a, b) =>
                this.form.Data.JoinCompanyUserIds!.indexOf(b.Id as string) -
                this.form.Data.JoinCompanyUserIds!.indexOf(a.Id as string)
            ),
          };
          this.companyUserList.DefaultSelectIds =
            this.form.Data.JoinCompanyUserIds;
        }
      });
    } else {
      this.form.Data.ParentId = this.parentId;
      this.form.Data.Path = this.path;
    }
  }

  handleFormat(
    item: MiezzListItem,
    row: DtoListItemJoinCompanyUser
  ): string | undefined {
    if (item.Prop == "Company") {
      return `${row.Company?.NameZh ?? "暂无数据"}/${row.Company?.NameEn ?? "暂无数据"}`;
    } else if (item.Prop == "JoinCompanyUserRoles") {
      return (
        row.JoinCompanyUserRoles?.map((it) => it.Role?.Name)?.join("，") ?? "暂无数据"
      );
    }
    return undefined;
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            this.form.Data.JoinCompanyUserIds = this.companyUserList.SelectIds;
            GroupAppService.Post(this.form.Data)
              .then((r) => {
                this.handleSubmit();
                complete();
              })
              .catch(() => complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
