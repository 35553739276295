
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzButtonColor extends Vue {
  @Prop() model = new MiezzButton();
  @Prop() color = "#FFFFFF";

  predefineColors = [
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#90ee90",
    "#00ced1",
    "#1e90ff",
    "#c71585",
  ];

  value: {
    color: string;
  } = {
    color: "#FFFFFF",
  };

  created(): void {
    this.onColorChanged();
  }

  @Watch("color")
  onColorChanged(): void {
    this.value.color = this.color ?? "#FFFFFF";
  }

  handleBeforePicker(v?: string): void {
    this.value.color = v ?? "#FFFFFF";
    this.model.Color = this.value.color;
    this.handlePicker(this.model, () => {
      //
    });
  }

  @Emit()
  handlePicker(model: MiezzButton, complete: () => void): void {
    //
  }
}
