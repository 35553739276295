
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompany, {
  DtoDetailCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyDetail from "../CompanyDetail.vue";
import { CompanySetting } from "../CompanySetting";
@Options({
  components: {
    CompanyDetail,
  },
})
export default class CustomerCompanyDetail extends Vue {
  type = EnumCompanyType.Customer;
  detail = new MiezzForm<DtoDetailCompany, string>(
    "CustomerManagement_Company_Detail"
  );
  buttons: MiezzButton[] = [];
  settings: CompanySetting[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/customer/company/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/customer/company/detail/${this.$route.params.id}?page=BasicInfo`,
        Label: "公司信息",
        Power: "CustomerManagement_Company_Detail",
      },
    ];
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "edit",
        Label: "编辑",
        Icon: "edit",
        Type: "text",
        Size: "small",
        PowerCode: "CustomerManagement_Company_Edit",
      },
    ];

    const logier = CurrentLogier.GetLogier();
    let items = DtoDetailCompanyMiezzDetailItems.filter(
      (it) => it.Prop != "BusinessScope" && it.Prop != "Remark"
    );
    if (logier) {
      if (logier.CompanyType == EnumCompanyType.ServiceProvider) {
        items = items.filter((it) => it.Prop != "ServiceProviderCompanyId");
      } else {
        for (const item of items) {
          switch (item.Prop) {
            case "ServiceProviderCompanyId":
              item.Label = "关联服务商";
              break;
            default:
              break;
          }
        }
      }
      this.detail.Items = items;
      CurrentLogier.GetMenus().then((r) => {
        const powers = r?.Menus?.map((it) => it.Code) ?? [];
        if (powers.contains("CustomerManagement_Company_Contact")) {
          this.settings.push({
            Name: "通讯录",
            Url: "/customer/company/contact/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_DomesticShipper")) {
          this.settings.push({
            Name: "境内发货人",
            Url: "/customer/company/domestic/shipper/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_DomesticConsignee")) {
          this.settings.push({
            Name: "境内收货人",
            Url: "/customer/company/domestic/consignee/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_OverseasShipper")) {
          this.settings.push({
            Name: "境外发货人",
            Url: "/customer/company/overseas/shipper/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_OverseasConsignee")) {
          this.settings.push({
            Name: "境外收货人",
            Url: "/customer/company/overseas/consignee/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_ProductionSaleUnit")) {
          this.settings.push({
            Name: "生产销售单位",
            Url: "/customer/company/product-sale-unit/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_OverseasConsignee")) {
          this.settings.push({
            Name: "消费使用单位",
            Url: "/customer/company/consumption-use-unit/${id}",
          });
        }
        if (powers.contains("CustomerManagement_Company_Invoice")) {
          this.settings.push({
            Name: "开票资料",
            Url: "/customer/company/invoice/${id}",
          });
        }
        if (
          powers.contains(
            "CustomerManagement_Company_CreditAndAccountingPeriod"
          )
        ) {
          this.settings.push({
            Name: "授信与账期",
            Url: "/customer/company/credit-and-accounting-period/${id}",
          });
        }
        if (this.settings.length > 0) {
          this.menuTab.Menus?.push({
            Url: `/customer/company/detail/${this.$route.params.id}?page=Setting`,
            Label: "相关参数",
            Power: "CustomerManagement_Company_Detail",
          });
        }
      });
    }
  }
}
