import UiSelectOption from "./UiSelectOption";

/**
 * 发票到账状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumInvoiceSettlementStatus {
    /**
     * 未标记
     */
    NoFlag = -1,
    /**
     * 未到账
     */
    No = 0,
    /**
     * 部分到账
     */
    Part = 10,
    /**
     * 已到账
     */
    Did = 20,
}

/**
 * 发票到账状态-选项
 */
export const EnumInvoiceSettlementStatusOption: UiSelectOption<number>[] = [
    { Label: "未标记", Value: -1, Data: "NoFlag" },
    { Label: "未到账", Value: 0, Data: "No" },
    { Label: "部分到账", Value: 10, Data: "Part" },
    { Label: "已到账", Value: 20, Data: "Did" },
];
