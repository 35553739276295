import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import DtoCallbackPrePackedBox_Pool from "./DtoCallbackPrePackedBox_Pool";
import UiSelectOption from "./UiSelectOption";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";
import DtoSelectOrderPrePackedBox from "./DtoSelectOrderPrePackedBox";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackPrePackedBox {
    constructor() {
        //无参构造函数
        this.Pools = [];
        this.IsNewCharge = false;
        this.Weights = [];
        this.Volumes = [];
        this.Tabs = [];
        this.PrePackedBoxCount = 1;
    }

    [proName: string]: any;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 委托书主键
     */
    Ids?: string[];
    /**
     * 材积比-重量
     */
    VolumeRatioWeight?: number;
    /**
     * 单件最低计费重
     */
    MinimumChargeableWeight?: number;
    /**
     * 单票最低计费重
     */
    OneMinimumChargeableWeight?: number;
    /**
     * 详情
     */
    Pools?: DtoCallbackPrePackedBox_Pool[];
    /**
     * 是否启用新的计费重量
     */
    IsNewCharge?: boolean;
    /**
     * 按重量的选择器
     */
    Weights?: UiSelectOption<string>[];
    /**
     * 按体积的选择器
     */
    Volumes?: UiSelectOption<string>[];
    /**
     * 类型
     */
    Type?: EnumCompanyChargeType;
    /**
     * 计费重量
     */
    CompanyChargeId?: string;
    /**
     * 拼单主键
     */
    OrderPrePackedBoxId?: string;
    /**
     * 
     */
    Tabs?: DtoSelectOrderPrePackedBox[];
    /**
     * 
     */
    ActiveName?: string;
    /**
     * 方案数量
     */
    PrePackedBoxCount?: number;
}

/**
 * -表单校验
 */
export const DtoCallbackPrePackedBoxRules = {
}
