import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_contact_detail = _resolveComponent("company-contact-detail")!

  return (_openBlock(), _createBlock(_component_company_contact_detail, {
    detail: _ctx.detail,
    buttons: _ctx.buttons,
    path: "/supplier/company/contact"
  }, null, 8, ["detail", "buttons"]))
}