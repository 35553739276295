import UiSelectOption from "./UiSelectOption";

/**
 * 运输集装箱装载方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumTransportContainerLoad {
    /**
     * 整柜/FCL
     */
    FCL = 1,
    /**
     * 拼柜/LCL
     */
    LCL = 2,
    /**
     * 散杂货/BULK
     */
    Bulk = 3,
}

/**
 * 运输集装箱装载方式-选项
 */
export const EnumTransportContainerLoadOption: UiSelectOption<number>[] = [
    { Label: "整柜/FCL", Value: 1, Data: "FCL" },
    { Label: "拼柜/LCL", Value: 2, Data: "LCL" },
    { Label: "散杂货/BULK", Value: 3, Data: "Bulk" },
];
