import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"margin":"0 0 20px 80px"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_card, {
      title: "提单模板",
      fix: true,
      fixTop: "30px",
      bottomButtons: _ctx.buttons,
      onHandleFooterButtonClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_miezz_page_list, {
            modelValue: _ctx.list,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event))
          }, {
            column: _withCtx((scoped) => [
              (scoped.model.Prop == 'TransportType')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (scoped.row.TransportType == 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " 海运/By Sea "))
                      : _createCommentVNode("", true),
                    (scoped.row.TransportType == 2)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, " 空运/By Air "))
                      : _createCommentVNode("", true),
                    (scoped.row.TransportType == 3)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, " 铁路/By Railway "))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (scoped.model.Prop == 'FileA')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (scoped.row.FileA)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                          _createVNode(_component_miezz_upload, {
                            style: {"width":"250px"},
                            modelValue: scoped.row.FileA,
                            "onUpdate:modelValue": ($event: any) => ((scoped.row.FileA) = $event),
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, "暂无数据"))
                  ]))
                : _createCommentVNode("", true),
              (scoped.model.Prop == 'FileB')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (scoped.row.FileB)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                          _createVNode(_component_miezz_upload, {
                            style: {"width":"250px"},
                            modelValue: scoped.row.FileB,
                            "onUpdate:modelValue": ($event: any) => ((scoped.row.FileB) = $event),
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_11, "暂无数据"))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createVNode(_component_miezz_form, {
          modelValue: _ctx.detail,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detail) = $event))
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'SignImg')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: item.Label,
                  prop: item.Prop
                }, {
                  default: _withCtx(() => [
                    (_ctx.detail.Data.SignImg)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          _createVNode(_component_miezz_upload, {
                            style: {"width":"250px"},
                            modelValue: _ctx.detail.Data.SignImg,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail.Data.SignImg) = $event)),
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_13, "暂无数据"))
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["bottomButtons", "onHandleFooterButtonClick"])
  ], 64))
}