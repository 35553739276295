import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "miezz-check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.model.Label) + " ", 1),
    _createVNode(_component_el_switch, {
      modelValue: _ctx.value.check,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.check) = $event)),
      "active-value": true,
      "inactive-value": false,
      onChange: _ctx.handleBeforeCheck
    }, null, 8, ["modelValue", "onChange"])
  ]))
}