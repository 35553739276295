import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPriceImportFromCost {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 价格表Id
     */
    TableId?: string;
    /**
     * 供应商
     */
    SupplierId?: string;
    /**
     * 基准价 =
     */
    Formula?: string;
    /**
     * 结果 =
     */
    Result?: string;
}

/**
 * -表单校验
 */
export const DtoRequestPriceImportFromCostRules = {
    SupplierId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Formula: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoRequestPriceImportFromCostMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "SupplierId",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "Formula",
    Label: "基准价 =",
    Customize: true,
  },
  {
    Prop: "Result",
    Label: "结果 =",
    Customize: true,
  },
]
