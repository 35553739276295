import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPriceCalc {
    constructor() {
        //无参构造函数
        this.Ids = [];
    }

    [proName: string]: any;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 服务Id
     */
    ServiceId?: string;
    /**
     * 外贸企业Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 价格Id
     */
    Ids?: string[];
}

/**
 * -表单校验
 */
export const DtoRequestPriceCalcRules = {
}
