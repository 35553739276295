
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class UiTreeSearch<TKey> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 仅显示选中权限
     */
    OnlyShowCheck?: boolean;
    /**
     * 选中权限Id
     */
    CheckIds?: TKey[];
}

/**
 * -表单校验
 */
export const UiTreeSearchRules = {
}
