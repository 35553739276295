import { EnumParamType, EnumParamTypeOption } from "./EnumParamType";
import DtoFullNameField from "./DtoFullNameField";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutParamField {
    constructor() {
        //无参构造函数
        this.Type = EnumParamType.Brand;
    }

    [proName: string]: any;
    /**
     * 
     */
    Type?: EnumParamType;
    /**
     * 
     */
    Fields?: DtoFullNameField[];
}

/**
 * -表单校验
 */
export const DtoPutParamFieldRules = {
}
