import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * HSCode
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormHsCode extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 商品编码
     */
    Code?: string;
    /**
     * 商品名称
     */
    Name?: string;
    /**
     * 第一法定单位
     */
    StatutoryFirstUnit?: string;
    /**
     * 第二法定单位
     */
    StatutorySecondUnit?: string;
    /**
     * 最惠国进口税率
     */
    MfnImportTariff?: string;
    /**
     * 普通进口税率
     */
    GeneralImportTariff?: string;
    /**
     * 暂定进口税率
     */
    ProvisionalImportTariff?: string;
    /**
     * 消费税率
     */
    ConsumptionTariff?: string;
    /**
     * 出口关税率
     */
    ExportTariff?: string;
    /**
     * 出口退税率
     */
    ExportRebateRate?: string;
    /**
     * 增值税率
     */
    EducationalTariff?: string;
    /**
     * 海关监管条件
     */
    CustomsSupervisionConditions?: string;
    /**
     * 检验检疫类别
     */
    InspectionQuarantineCategory?: string;
    /**
     * 描述
     */
    Description?: string;
    /**
     * 申报要素
     */
    Element?: string;
}

/**
 * HSCode-表单校验
 */
export const DtoFormHsCodeRules = {
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * HSCode-设置项
 */
export const DtoFormHsCodeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "商品编码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "商品名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "StatutoryFirstUnit",
    Label: "第一法定单位",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "StatutorySecondUnit",
    Label: "第二法定单位",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MfnImportTariff",
    Label: "最惠国进口税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GeneralImportTariff",
    Label: "普通进口税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ProvisionalImportTariff",
    Label: "暂定进口税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsumptionTariff",
    Label: "消费税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ExportTariff",
    Label: "出口关税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ExportRebateRate",
    Label: "出口退税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "EducationalTariff",
    Label: "增值税率",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CustomsSupervisionConditions",
    Label: "海关监管条件",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "InspectionQuarantineCategory",
    Label: "检验检疫类别",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Description",
    Label: "描述",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Element",
    Label: "申报要素",
    Type: EnumMiezzFormItemType.InputText,
  },
]
