
import JoinPriceServiceTableAppService from "@/apis.machine/JoinPriceServiceTableAppService";
import JoinPriceServiceTableSearchAppService from "@/apis.machine/JoinPriceServiceTableSearchAppService";
import PriceTableColumnAppService from "@/apis.machine/PriceTableColumnAppService";
import DtoFormJoinPriceServiceTable from "@/models.machine/DtoFormJoinPriceServiceTable";
import DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch from "@/models.machine/DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class PriceServiceTableForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() serviceId?: string;
  @Prop() id?: string;
  rules = {
    FromColumnId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
    ToSearchId: [
      {
        validator: (
          rule: any,
          row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch,
          callback: any
        ) => {
          if (row.ToServiceTableId && !row.ToSearchId) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        trigger: "change",
      },
    ],
  };

  form = new MiezzForm<DtoFormJoinPriceServiceTable, string>();
  columns: UiSelectOption<string>[] = [];
  fromColumns: UiSelectOption<string>[] = [];
  toTables: UiSelectOption<string>[] = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormJoinPriceServiceTable();
    this.form.Data.ServiceId = this.serviceId;
    //表单-元素配置
    this.form.Items = [
      {
        Prop: "FromSearchs",
        Customize: true,
      },
    ];

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    JoinPriceServiceTableAppService.GetSelect({
      ServiceId: this.serviceId,
    }).then((r) => {
      this.toTables = r.data.Data?.filter((it) => it.Value != this.id) ?? [];
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      JoinPriceServiceTableAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          PriceTableColumnAppService.GetSelect({
            TableId: r.data.Data.TableId,
            Show: true,
          }).then((rr) => {
            this.columns = (rr.data.Data ?? []).filter(
              (it) => it.Data.FieldCode != "Amount"
            );
            this.fromColumns = (rr.data.Data ?? []).filter(
              (it) => it.Data.FieldCode != "Amount"
            );

            if (r.data.Data) {
              this.form.Data = r.data.Data;
              if (this.form.Data.FromSearchs?.length == 0) {
                this.handleAdd();
              }
              for (const item of this.form.Data.FromSearchs ?? []) {
                this.handleFromColumnIdChange(item);
              }
            }
          });
        }
      });
    }
  }

  handleFromColumnIdVisibleChange(
    show: boolean,
    row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch
  ): void {
    if (show) {
      this.fromColumns = this.columns.filter(
        (it) =>
          it.Value == row.FromColumnId ||
          this.form.Data.FromSearchs?.all((v) => v.FromColumnId != it.Value)
      );
    }
  }

  handleFromColumnIdChange(
    row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch
  ): void {
    row.FromColumn = row.FromColumnId
      ? {
          FieldId: this.columns.firstOrDefault(
            (it) => it.Value == row.FromColumnId
          )?.Data.FieldId,
        }
      : undefined;
    this.handleToServiceTableIdChange(row);
    this.buildDescription(row);
  }

  handleToServiceTableIdChange(
    row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch
  ): void {
    const serviceTable = this.toTables.firstOrDefault(
      (it) => it.Value == row.ToServiceTableId
    );
    if (row.ToServiceTableId && serviceTable) {
      JoinPriceServiceTableSearchAppService.GetSelect({
        FromServiceTableId: row.ToServiceTableId,
        FieldId: row.FromColumn.FieldId,
      }).then((r) => {
        const data = r.data.Data ?? [];
        row.ToSearchs = data;
        row.ToSearchId = data.any((it) => it.Value == row.ToSearchId)
          ? row.ToSearchId
          : undefined;
      });
    } else {
      row.ToSearchs = [];
      row.ToSearchId = undefined;
    }
    this.buildDescription(row);
  }

  handleToSearchIdChange(
    row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch
  ): void {
    this.buildDescription(row);
  }

  buildDescription(
    row: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch
  ): void {
    this.$nextTick(() => {
      if (
        row.FromColumnId &&
        row.FromColumnLabel &&
        row.ToServiceTableId &&
        row.ToServiceTableLabel &&
        row.ToSearchId &&
        row.ToSearchLabel
      ) {
        row.Description = `搜索条件“${row.FromColumnLabel}”与《${row.ToServiceTableLabel}》中的搜索条件“${row.ToSearchLabel}”联动`;
      } else {
        row.Description = undefined;
      }
    });
  }

  handleAdd(): void {
    this.form.Data.FromSearchs?.push({ ToSearchs: [] });
  }

  handleDelete(index: number): void {
    this.form.Data.FromSearchs?.splice(index, 1);
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        (this.$refs["list-form"] as any).validate((v: boolean) => {
          if (v) {
            JoinPriceServiceTableAppService.Post(this.form.Data)
              .then(() => {
                this.modelValue.Show = false;
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
