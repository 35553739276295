import UiSelectOption from "./UiSelectOption";

/**
 * 复制委托书的Booking No.生成类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolCopyBookingNoBuildType {
    /**
     * 生成相同的Booking No.
     */
    FromTarget = 1,
    /**
     * 生成新的Booking No.
     */
    New = 2,
}

/**
 * 复制委托书的Booking No.生成类型-选项
 */
export const EnumOrderPoolCopyBookingNoBuildTypeOption: UiSelectOption<number>[] = [
    { Label: "生成相同的Booking No.", Value: 1, Data: "FromTarget" },
    { Label: "生成新的Booking No.", Value: 2, Data: "New" },
];
