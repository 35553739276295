
/**
 * 财务管理的请求参数
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestFinance {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 账单主键集合
     */
    BillIds?: string[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 发票号码
     */
    Number?: string;
    /**
     * 
     */
    IsBad?: boolean;
}

/**
 * 财务管理的请求参数-表单校验
 */
export const DtoRequestFinanceRules = {
}
