
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailOrderCost from "@/models.machine/DtoDetailOrderCost";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormOrderCost, {
  DtoFormOrderCostMiezzListItems,
} from "@/models.machine/DtoFormOrderCost";
import DtoFormOrderPool_OrderPoolSupplierCost, {
  DtoFormOrderPool_OrderPoolSupplierCostMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolSupplierCost";
import DtoDetailOrderPool_OrderPoolSupplierCost from "@/models.machine/DtoDetailOrderPool_OrderPoolSupplierCost";
import DtoDetailOrderPool_OrderPoolCost from "@/models.machine/DtoDetailOrderPool_OrderPoolCost";
export default class OrderDetailCost extends Vue {
  @Model() form!: MiezzForm<
    {
      Id?: string;
      Costs: DtoDetailOrderPool_OrderPoolCost[];
      SupplierCosts: DtoDetailOrderPool_OrderPoolSupplierCost[];
      SettlementCosts: DtoDetailOrderPool_OrderPoolCost[];
    },
    string
  >;
  @Prop() hideCosts?: boolean;
  @Prop() showSupplierCosts?: boolean;
  @Prop() showSettlementCosts?: boolean;

  /**费用-列表配置 */
  listCosts = new MiezzPageList<any, DtoFormOrderCost, string>();
  /**费用-列表配置 */
  listSupplierCosts = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolSupplierCost,
    string
  >();
  listSettlementCosts = new MiezzPageList<any, DtoFormOrderCost, string>();

  /**费用-初始化元素配置 */
  created(): void {
    this.listCosts.Fix = false;
    this.listCosts.HideCard = true;
    this.listCosts.EnabledDynamicSearch = false;
    this.listCosts.EnabledRadioSearch = false;
    this.listCosts.EnabledSelect = false;
    this.listCosts.EnabledPagination = false;
    this.listCosts.Items = DtoFormOrderCostMiezzListItems;
    this.listCosts.Data = {
      Items: [],
    };

    this.listSupplierCosts.Fix = false;
    this.listSupplierCosts.HideCard = true;
    this.listSupplierCosts.EnabledDynamicSearch = false;
    this.listSupplierCosts.EnabledRadioSearch = false;
    this.listSupplierCosts.EnabledSelect = false;
    this.listSupplierCosts.EnabledPagination = false;
    this.listSupplierCosts.Items =
      DtoFormOrderPool_OrderPoolSupplierCostMiezzListItems;
    this.listSupplierCosts.Data = {
      Items: [],
    };

    this.listSettlementCosts.Fix = false;
    this.listSettlementCosts.HideCard = true;
    this.listSettlementCosts.EnabledDynamicSearch = false;
    this.listSettlementCosts.EnabledRadioSearch = false;
    this.listSettlementCosts.EnabledSelect = false;
    this.listSettlementCosts.EnabledPagination = false;
    this.listSettlementCosts.Items = DtoFormOrderCostMiezzListItems;
    this.listSettlementCosts.Data = {
      Items: [],
    };

    if (this.listCosts.Data) this.listCosts.Data.Items = this.form.Data.Costs;
    if (this.listSupplierCosts.Data)
      this.listSupplierCosts.Data.Items = this.form.Data.SupplierCosts;
    if (this.listSettlementCosts.Data)
      this.listSettlementCosts.Data.Items = this.form.Data.SettlementCosts;
  }
}
