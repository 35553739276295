
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
    import SpecAppService from "@/apis.machine/SpecAppService";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import ProductSpecElementAppService from "@/apis.machine/ProductSpecElementAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoDetailProduct from "@/models.machine/DtoDetailProduct";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import DtoFormSpec, {
        DtoFormSpecMiezzFormItems,
        DtoFormSpecRules,
    } from "@/models.machine/DtoFormSpec";

    export default class SpecForm extends Vue {
        supplierIndex = -1;
        @Model() modelValue!: MiezzModal;
        id = "";
        productId = "";
        /**权限编码 */
        powerCodes?: string[] = [];
        /*是否具有添加产品要素的权限*/
        isSpecElementAdd?: boolean;
        /*是否具有添加公司名片的权限*/
        isCompany?: boolean;
        /*是否具有添加币制的权限*/
        isCurrency?: boolean;
        //在货号中供选择的发货人、收货人等集合
        companys?: UiSelectOption<string>[] = [];
        //在库存中供选择的发货人、收货人等集合
        stockCompanys?: UiSelectOption<string>[] = [];
        //供选择的币制集合
        currencys?: UiSelectOption<string>[] = [];
        productSpecElements?: UiSelectOption<string>[] = [];
        product = new MiezzForm<DtoDetailProduct, string>();
        random = 0;
        isInit = true;
        num?: number;
        //库存信息
        ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation?: boolean;
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "default",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "保存",
                Icon: "edit",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "continue",
                Label: "继续添加",
                Icon: "plus",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                Code: "reset",
                Label: "重置",
                Icon: "refresh",
                Type: "primary",
                Size: "small",
            },
        ];

        //rules = {
        //    Number: [
        //        {
        //            required: true,
        //            message: "信息不能为空",
        //            trigger: "blur",
        //        },
        //    ],
        //};

        form = new MiezzForm<DtoFormSpec, string>();

        created(): void {
            this.id = this.$route.params.specId as string;
            if (
                this.id == null ||
                typeof this.id == "undefined" ||
                this.id == "undefined" ||
                this.id == ""
            ) {
                if (this.bottomButtons != null) {
                    this.bottomButtons[2].Hide = false;
                }
            }
            this.productId = this.$route.params.id as string;
            this.product.Data = new DtoDetailProduct();
            //表单-数据-初始化
            this.form.Data = new DtoFormSpec();
            //表单-校验
            this.form.Rules = DtoFormSpecRules;
            //表单-元素配置
            this.form.Items = DtoFormSpecMiezzFormItems;

            if (this.productId) {
                ProductAppService.GetById(this.productId).then((pro) => {
                    if (pro.data.Data) {
                        this.product.Data = pro.data.Data;
                        this.product.Data.Name =
                            this.product.Data.NameZh + "/" + this.product.Data.NameEn;
                    }

                    ParamCurrencySystemAppService.GetSelect().then((c) => {
                        if (c.data.Data) this.currencys = c.data.Data;

                        var param = {
                            CurrentPage: 1,
                            PageSize: 9999999,
                            IsSpecNumber: true,
                            CompanyId: this.product.Data.CompanyId,
                        };
                        CompanyBusinessCardAppService.GetCards(param).then((r) => {
                            if (r.data.Data) {
                                this.companys = r.data.Data;
                            }
                            //if (r.data.Data && r.data.Data.Items) {
                            //    for (var it of r.data.Data.Items) {
                            //        var name = "";
                            //        if (
                            //            it.NameZh != null &&
                            //            it.NameZh != undefined &&
                            //            it.NameZh != ""
                            //        ) {
                            //            name = it.NameZh;
                            //        } else if (
                            //            it.NameEn != null &&
                            //            it.NameEn != undefined &&
                            //            it.NameEn != ""
                            //        ) {
                            //            name = it.NameEn;
                            //        }
                            //        if (it.Code != null && it.Code != undefined && it.Code != "") {
                            //            name += "（" + it.Code + "）";
                            //        }

                            //        this.companys?.push({
                            //            Id: it.Id,
                            //            Name: name,
                            //        });
                            //    }
                            //}

                            SpecAppService.GetSupplier().then((r2) => {
                                if (r2.data.Data) {
                                    this.stockCompanys = r2.data.Data;
                                }

                                var elementParam = {
                                    CurrentPage: 1,
                                    PageSize: 9999999,
                                    ProductId: this.productId,
                                };
                                ProductSpecElementAppService.Get(elementParam).then((r1) => {
                                    this.productSpecElements = r1.data.Data?.Items;
                                    if (this.productSpecElements) {
                                        for (var it of this.productSpecElements) {
                                            for (var it1 of it.ProductSpecElementItems) {
                                                it1.IsSelected = false;
                                            }
                                        }
                                    }

                                    this.random = Math.random();
                                    this.handleGet();
                                });
                            });
                        });
                    });
                });
            }

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            //this.modelValue.HandleClick = this.handleClick;
            //this.modelValue.Buttons = [
            //    {
            //        Code: "submit",
            //        Label: "保存",
            //        Type: "primary",
            //        Size: "small",
            //    },
            //];

            this.onPower();
        }
        onChangeSpecNumberCompanyBusinessCardId(index: number): void {
            MiezzForm.validateField(
                this.$refs,
                "ruleForm",
                "SpecNumbers[" + index + "].Name"
            );
        }
        onChangeCompanyBusinessCardId(): void {
            MiezzForm.validateField(this.$refs, "ruleForm", "Number");
        }
        onChangeNumber(): void {
            if (this.form.Data) {
                this.form.Data.Number = TrimSpace(this.form.Data.Number as string);
            }
        }
        onChangeSpecNumber(row: any): void {
            if (row) {
                row.Name = TrimSpace(row.Name as string);
            }
        }
        onChangeName(): void {
            if (this.form.Data) {
                this.form.Data.Name = TrimSpace(this.form.Data.Name as string);
            }
        }
        async onPower(): Promise<void> {
            this.powerCodes = await CurrentLogier.GetPowerCodes();
            this.isSpecElementAdd =
                this.powerCodes &&
                this.powerCodes.contains(
                    "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements_NewlyAdded"
                );
            //this.isCompany = this.powerCodes && this.powerCodes.contains("CompanyManagement_Company");
            this.isCurrency =
                this.powerCodes &&
                this.powerCodes.contains("System_ParamList_ParamCurrency_AddEdit");
            this.ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation =
                this.powerCodes &&
                this.powerCodes.contains(
                    "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation"
                );

            this.isCompany = false;
            var CompanyManagement_Company_OverseasShipper =
                this.powerCodes &&
                this.powerCodes.contains("CompanyManagement_Company_OverseasShipper");
            var CompanyManagement_Company_OverseasConsignee =
                this.powerCodes &&
                this.powerCodes.contains("CompanyManagement_Company_OverseasConsignee");
            var CompanyManagement_Company_ProductionSaleUnit =
                this.powerCodes &&
                this.powerCodes.contains("CompanyManagement_Company_ProductionSaleUnit");
            var CompanyManagement_Company_ConsumptionUseUnit =
                this.powerCodes &&
                this.powerCodes.contains("CompanyManagement_Company_ConsumptionUseUnit");
            if (
                CompanyManagement_Company_OverseasShipper == true ||
                CompanyManagement_Company_OverseasConsignee == true ||
                CompanyManagement_Company_ProductionSaleUnit == true ||
                CompanyManagement_Company_ConsumptionUseUnit == true
            ) {
                this.isCompany = true;
            }
        }
        onDeleteStock(index: number): void {
            if (!this.form.Data.SpecStocks) {
                this.form.Data.SpecStocks = [];
            }

            this.form.Data.SpecStocks.splice(index, 1);
        }
        onAddStock(): void {
            if (!this.form.Data.SpecStocks) {
                this.form.Data.SpecStocks = [];
            }
            var currencyId = undefined;
            if (!this.currencys) {
                this.currencys = [];
            } else if (this.currencys.length > 1) {
                currencyId = this.currencys[1].Value;
            }
            this.form.Data.SpecStocks.push({
                Supplier: "",
                Quantity: undefined,
                Price: undefined,
                Address: undefined,
                CurrencyId: currencyId,
                CurrencyName: undefined,
                DeliveryTime: undefined,
                CompanyBusinessCardId: undefined,
            });
        }
        onCurrency(): void {
            this.$router.push(
                "/sys/param/param-currency?CurrentPage=1&PageSize=10&backUrl=" +
                this.$route.fullPath
            );
        }
        onCompany(): void {
            this.$router.push("/org/company/detail?backUrl=" + this.$route.fullPath);
        }
        onSpec(): void {
            this.$router.push(
                "/product/spec/element/" +
                this.productId +
                "?backUrl=" +
                this.$route.fullPath
            );
        }
        onChangeWeight(): void {
            if (this.form.Data && this.form.Data.Weight) {
                var result = setDecimal(this.form.Data.Weight.toString(), 2, false);
                if (result != "") {
                    this.form.Data.Weight = Number(result);
                } else {
                    this.form.Data.Weight = undefined;
                }
            }
        }
        onChangePrice(index: number) {
            this.num = undefined;
            if (this.form.Data && this.form.Data.SpecStocks) {
                this.num = this.form.Data.SpecStocks[index].Price;
            }
            if (this.num && this.form.Data && this.form.Data.SpecStocks) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.SpecStocks[index].Price = Number(result);
                } else {
                    this.form.Data.SpecStocks[index].Price = undefined;
                }
            }
        }
        onChangeQuantity(index: number) {
            this.num = undefined;
            if (this.form.Data && this.form.Data.SpecStocks) {
                this.num = this.form.Data.SpecStocks[index].Quantity;
            }
            if (this.num && this.form.Data && this.form.Data.SpecStocks) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.SpecStocks[index].Quantity = Number(result);
                } else {
                    this.form.Data.SpecStocks[index].Quantity = undefined;
                }
            }
        }
        onRule(): void {
            this.form.Rules.SpecStocks_Supplier = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.SpecStocks_Quantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.SpecStocks_Price = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (
                            this.form.Data.Name != null &&
                            typeof this.form.Data.Name != "undefined" &&
                            this.form.Data.Name != "undefined" &&
                            this.form.Data.Name != ""
                        ) {
                            var param = {
                                Name: value,
                                ProductId: this.productId,
                                SpecId: this.id,
                            };
                            SpecAppService.IsSameName(param).then((r) => {
                                if (r.data.Data == true) {
                                    callback(new Error("信息重复，请重新填写"));
                                    return false;
                                } else {
                                    return callback();
                                }
                            });
                        } else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.Number = [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (
                            this.form.Data.Number != null &&
                            this.form.Data.Number != undefined &&
                            this.form.Data.Number != "" &&
                            this.form.Data.CompanyBusinessCardId != null &&
                            this.form.Data.CompanyBusinessCardId != undefined &&
                            this.form.Data.CompanyBusinessCardId != ""
                        ) {
                            if (
                                this.form.Data.SpecNumbers != null &&
                                typeof this.form.Data.SpecNumbers != "undefined" &&
                                this.form.Data.SpecNumbers != undefined &&
                                this.form.Data.SpecNumbers.length > 0
                            ) {
                                for (var it of this.form.Data.SpecNumbers) {
                                    if (
                                        it.CompanyBusinessCardId ==
                                        this.form.Data.CompanyBusinessCardId &&
                                        this.form.Data.Number == it.Name
                                    ) {
                                        callback(new Error("信息重复，请重新填写"));
                                        return false;
                                    }
                                }
                                return callback();
                            } else {
                                return callback();
                            }
                        } else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.SpecNumbers_Name = [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field
                            .replace("[", ".")
                            .replace("]", ".")
                            .split(".");
                        var number = Number(fields[1]);

                        if (
                            this.form.Data.SpecNumbers != null &&
                            typeof this.form.Data.SpecNumbers != "undefined" &&
                            this.form.Data.SpecNumbers != undefined &&
                            this.form.Data.SpecNumbers.length > 0
                        ) {
                            if (
                                this.form.Data.SpecNumbers[number].Name != null &&
                                this.form.Data.SpecNumbers[number].Name != undefined &&
                                this.form.Data.SpecNumbers[number].Name != "" &&
                                this.form.Data.SpecNumbers[number].CompanyBusinessCardId !=
                                null &&
                                this.form.Data.SpecNumbers[number].CompanyBusinessCardId !=
                                undefined &&
                                this.form.Data.SpecNumbers[number].CompanyBusinessCardId != ""
                            ) {
                                for (var i = 0; i < this.form.Data.SpecNumbers.length; i++) {
                                    if (i != number) {
                                        if (
                                            this.form.Data.SpecNumbers[i].CompanyBusinessCardId ==
                                            this.form.Data.SpecNumbers[number]
                                                .CompanyBusinessCardId &&
                                            this.form.Data.SpecNumbers[i].Name ==
                                            this.form.Data.SpecNumbers[number].Name
                                        ) {
                                            callback(new Error("信息重复，请重新填写"));
                                            return false;
                                        }
                                    }
                                }

                                if (
                                    this.form.Data.SpecNumbers[number].CompanyBusinessCardId ==
                                    this.form.Data.CompanyBusinessCardId &&
                                    this.form.Data.Number == this.form.Data.SpecNumbers[number].Name
                                ) {
                                    callback(new Error("信息重复，请重新填写"));
                                    return false;
                                }
                            }

                            return callback();
                        } else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.SpecNumbers_CompanyBusinessCardId = [
                //{
                //    required: true,
                //    message: "信息不能为空",
                //    trigger: "change",
                //},
            ];
        }

        onSelectCategory(parentId: string, Id: string): void {
            var i = 0;
            var j = 0;
            if (this.productSpecElements) {
                for (i = 0; i < this.productSpecElements.length; i++) {
                    var category = this.productSpecElements[i];
                    if (category.Id == parentId) {
                        for (j = 0; j < category.ProductSpecElementItems.length; j++) {
                            var t = category.ProductSpecElementItems[j];

                            if (t.Id == Id) {
                                if (t.IsSelected == false) {
                                    t.IsSelected = true;
                                } else {
                                    t.IsSelected = false;
                                }
                            } else {
                                t.IsSelected = false;
                            }
                        }
                    }
                }
            }
        }

        onDeleteCompany(index: number): void {
            if (
                this.form.Data.SpecNumbers == null ||
                this.form.Data.SpecNumbers == undefined
            ) {
                this.form.Data.SpecNumbers = [];
            }
            this.form.Data.SpecNumbers.splice(index, 1);

            MiezzForm.clearValidate(this.$refs, "ruleForm",undefined);
        }
        onAddCompany(): void {
            if (!this.form.Data.SpecNumbers) {
                this.form.Data.SpecNumbers = [];
            }
            this.form.Data.SpecNumbers.push({
                CompanyBusinessCardId: undefined,
                Name: undefined,
            });
        }

        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (
                url != null &&
                typeof url != "undefined" &&
                url != "undefined" &&
                url != ""
            ) {
                this.$router.push(url);
            } else {
                history.go(-1);
            }
        }

        /**查询表单数据 */
        handleGet(): void {
            if (this.id) {
                SpecAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                    this.isInit = false;
                    this.onRule();

                    if (this.productSpecElements) {
                        for (var it of this.productSpecElements) {
                            for (var it1 of it.ProductSpecElementItems) {
                                if (this.form.Data.SpecElements) {
                                    for (var it2 of this.form.Data.SpecElements) {
                                        if (it1.Id == it2.ProductSpecElementItemId) {
                                            it1.IsSelected = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                });
            } else {
                //this.form.Data = new DtoFormSpec();
                if (
                    this.currencys != null &&
                    this.currencys != undefined &&
                    this.currencys.length > 0
                ) {
                    this.onAddStock();
                    this.isInit = false;
                    this.onRule();
                }
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            this.form.Data.SpecElements = [];
            if (this.productSpecElements) {
                for (var it of this.productSpecElements) {
                    for (var it1 of it.ProductSpecElementItems) {
                        if (it1.IsSelected == true) {
                            this.form.Data.SpecElements.push({
                                ProductSpecElementItemId: it1.Id,
                                ElementItemName: it1.Name,
                            });
                        }
                    }
                }
            }

            if (this.currencys) {
                if (this.form.Data.SpecStocks) {
                    for (var stock of this.form.Data.SpecStocks) {
                        for (var currency of this.currencys) {
                            if (stock.CurrencyId == currency.Value) {
                                stock.CurrencyName = currency.Label;
                            }
                        }
                    }
                }
            }

            //if(this.stockCompanys)
            //{
            //  if (this.form.Data.SpecStocks) {
            //        for (var it2 of this.form.Data.SpecStocks) {
            //            for (var stockCompany of this.stockCompanys) {
            //                if (it2.CompanyBusinessCardId == stockCompany.Id) {
            //                    it2.Supplier = stockCompany.Name;
            //                }
            //            }
            //        }
            //    }
            //}

            this.form.Data.ProductId = this.productId;

            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            SpecAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    } else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.handleSubmit();
                                    complete();
                                    this.handleBack();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "continue":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            SpecAppService.Post(this.form.Data)
                                .then(() => {
                                    this.handleSubmit();
                                    this.form.Data = new DtoFormSpec();
                                    MiezzForm.resetFields(this.$refs, "ruleForm");
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    } else {
                                        ElMessage.success("新增成功");
                                    }

                                    if (this.productSpecElements) {
                                        for (var it of this.productSpecElements) {
                                            for (var it1 of it.ProductSpecElementItems) {
                                                it1.IsSelected = false;
                                            }
                                        }
                                    }
                                    this.onAddStock();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "back":
                    this.handleBack();
                    break;
                case "reset":
                    if (this.productSpecElements) {
                        for (var it3 of this.productSpecElements) {
                            for (var it4 of it3.ProductSpecElementItems) {
                                it4.IsSelected = false;
                            }
                        }
                    }
                    this.handleGet();

                    MiezzForm.resetFields(this.$refs, "ruleForm");
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }

        onChangeSupplier(index: number): void {
            if (this.stockCompanys && this.form.Data && this.form.Data.SpecStocks) {
                this.form.Data.SpecStocks[index].CompanyBusinessCardId = undefined;
                for (var it of this.stockCompanys) {
                    if (it.Label == this.form.Data.SpecStocks[index].Supplier) {
                        if (it.Data.IsCompany == true) {
                            this.form.Data.SpecStocks[index].CompanyBusinessCardId = it.Value;
                        }
                    }
                }
            }
        }
        onDeleteSupplier(row: any, item: any,index:number): void {
            var value = row.Supplier;
            SpecAppService.DeleteSupplierById(item.Value).then((r) => {
                SpecAppService.GetSupplier().then((r2) => {

                    var oldValue = "";
                    if (this.stockCompanys) {
                        oldValue = this.stockCompanys[index].Label as string;
                    }

                    this.stockCompanys?.splice(index, 1);
                    if (oldValue != value) {
                        this.form.Data.PackageEn = value;
                    }
                    else {
                        this.form.Data.PackageEn = "";
                    }



                    ElMessage.success("历史记录已删除");
                    //if (r2.data.Data) {
                    //    this.stockCompanys = r2.data.Data;
                    //}
                    //if (row.Supplier == item.Label) {
                    //    row.Supplier = undefined;
                    //    row.CompanyBusinessCardId = undefined;
                    //}
                });
            });
        }
    }

    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                } else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
