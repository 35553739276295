import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyCodeRule_CompanyCodeRule extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 缩写
     */
    Code?: string;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyCodeRule_CompanyCodeRuleRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyCodeRule_CompanyCodeRuleMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "缩写",
  },
]

/**
 * -设置项
 */
export const DtoDetailCompanyCodeRule_CompanyCodeRuleMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "缩写",
    Type: EnumMiezzFormItemType.Text,
  },
]
