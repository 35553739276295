import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EStockType {
    /**
     * 入库
     */
    In = 10,
    /**
     * 出库
     */
    Out = 20,
}

/**
 * -选项
 */
export const EStockTypeOption: UiSelectOption<number>[] = [
    { Label: "入库", Value: 10, Data: "In" },
    { Label: "出库", Value: 20, Data: "Out" },
];
