import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderManifestVgm_ByContainerTrailer {
    constructor() {
        //无参构造函数
        this.RowSpan = 0;
    }

    [proName: string]: any;
    /**
     * 跨行
     */
    RowSpan?: number;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 提单号
     */
    BLNO?: string;
    /**
     * 拼入件数
     */
    SpellQuantity?: number;
    /**
     * 拼入毛重
     */
    SpellGrossWeight?: number;
    /**
     * 拼入体积
     */
    SpellVolume?: number;
    /**
     * 单箱件数
     */
    Quantity?: number;
    /**
     * 单箱毛重
     */
    GrossWeight?: number;
    /**
     * 单箱体积
     */
    Volume?: number;
}

/**
 * -表单校验
 */
export const DtoDetailOrderManifestVgm_ByContainerTrailerRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_ByContainerTrailerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ContainerNo",
    Label: "箱号",
  },
  {
    Prop: "SealNo",
    Label: "封号",
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
  },
  {
    Prop: "BLNO",
    Label: "提单号",
  },
  {
    Prop: "SpellQuantity",
    Label: "拼入件数",
  },
  {
    Prop: "SpellGrossWeight",
    Label: "拼入毛重",
  },
  {
    Prop: "SpellVolume",
    Label: "拼入体积",
  },
  {
    Prop: "Quantity",
    Label: "单箱件数",
  },
  {
    Prop: "GrossWeight",
    Label: "单箱毛重",
  },
  {
    Prop: "Volume",
    Label: "单箱体积",
  },
]
