import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemCodeController extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 注释
     */
    Summary?: string;
}

/**
 * -表单校验
 */
export const DtoPageListItemCodeControllerRules = {
}

/**
 * -设置项
 */
export const DtoPageListItemCodeControllerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Group",
    Label: "分组",
  },
  {
    Prop: "App",
    Label: "应用",
  },
  {
    Prop: "Summary",
    Label: "注释",
  },
]
