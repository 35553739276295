import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 公司名片-银行分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchCompanyBusinessCardBank extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 公司名片Id
     */
    CompanyBusinessCardId?: string;
}

/**
 * 公司名片-银行分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchCompanyBusinessCardBankRules = {
}

/**
 * 公司名片-银行分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchCompanyBusinessCardBankDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
