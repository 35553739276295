import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock(_component_el_date_picker, {
    modelValue: _ctx.range,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.range) = $event)),
    type: "daterange",
    "range-separator": "-",
    "start-placeholder": "开始日期",
    "end-placeholder": "结束日期",
    format: "YYYY/MM/DD",
    "value-format": "YYYY/MM/DD",
    onChange: _ctx.handleChange
  }, null, 8, ["modelValue", "onChange"]))
}