import UiSelectOption from "./UiSelectOption";

/**
 * 菜单场景
 * @description 自动生成的代码,请勿修改
 */
export enum EnumMenuScene {
    /**
     * 平台
     */
    Platform = 0,
    /**
     * 开发者管理端
     */
    DeveloperAdmin = 1,
}

/**
 * 菜单场景-选项
 */
export const EnumMenuSceneOption: UiSelectOption<number>[] = [
    { Label: "平台", Value: 0, Data: "Platform" },
    { Label: "开发者管理端", Value: 1, Data: "DeveloperAdmin" },
];
