import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFromLogin from "../models.machine/DtoFromLogin";
import RESTfulResult from "../models.machine/RESTfulResult";
import Logier from "../models.machine/Logier";
import DtoFormSmsLogin from "../models.machine/DtoFormSmsLogin";
import DtoFormForgetPassword from "../models.machine/DtoFormForgetPassword";
import DtoFormRegister from "../models.machine/DtoFormRegister";
import DtoCallbackAuthCheckUser from "../models.machine/DtoCallbackAuthCheckUser";
import DtoListLogierCompany from "../models.machine/DtoListLogierCompany";

/**
 * 认证
 * @description 自动生成的代码,请勿修改
 */
export default class AuthAppService {
    /**
     * 登录
     * @param {DtoFromLogin} [dto] 
     * @returns {AxiosPromise<RESTfulResult<Logier>>} 
     */
    static PasswordLogin(dto?: DtoFromLogin): AxiosPromise<RESTfulResult<Logier>> {
        const url = `/api/Auth/PasswordLogin`;
        return service.post<RESTfulResult<Logier>>(url, dto);
    }

    /**
     * 短信登录
     * @param {DtoFormSmsLogin} [dto] 
     * @returns {AxiosPromise<RESTfulResult<Logier>>} 
     */
    static SmsLogin(dto?: DtoFormSmsLogin): AxiosPromise<RESTfulResult<Logier>> {
        const url = `/api/Auth/SmsLogin`;
        return service.post<RESTfulResult<Logier>>(url, dto);
    }

    /**
     * 忘记密码
     * @param {DtoFormForgetPassword} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ForgetPassword(dto?: DtoFormForgetPassword): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Auth/ForgetPassword`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 注册
     * @param {DtoFormRegister} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<Logier>>} 
     */
    static Register(dto?: DtoFormRegister): AxiosPromise<RESTfulResult<Logier>> {
        const url = `/api/Auth/Register`;
        return service.post<RESTfulResult<Logier>>(url, dto);
    }

    /**
     * 检查用户
     * @param {string} [account] 账号
     * @param {string} [inviteCode] 邀请码
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackAuthCheckUser>>} 结果
     */
    static CheckUserByAccount(account?: string, inviteCode?: string): AxiosPromise<RESTfulResult<DtoCallbackAuthCheckUser>> {
        const url = `/api/Auth/CheckUser/${account}`;
        const _querys: any = {};
        _querys.inviteCode = inviteCode
        return service.post<RESTfulResult<DtoCallbackAuthCheckUser>>(url, undefined, { params: _querys });
    }

    /**
     * 获取当前登录用户的公司列表
     * @returns {AxiosPromise<RESTfulResult<DtoListLogierCompany>>} 当前登录用户的公司列表
     */
    static GetLogierCompanies(): AxiosPromise<RESTfulResult<DtoListLogierCompany>> {
        const url = `/api/Auth/GetLogierCompanies`;
        return service.get<RESTfulResult<DtoListLogierCompany>>(url);
    }

    /**
     * 切换公司
     * @param {string} [companyId] 公司Id
     * @returns {AxiosPromise<RESTfulResult<Logier>>} 当前登录用户
     */
    static SwitchCompanyByCompanyId(companyId?: string): AxiosPromise<RESTfulResult<Logier>> {
        const url = `/api/Auth/SwitchCompany/${companyId}`;
        return service.post<RESTfulResult<Logier>>(url);
    }

}
