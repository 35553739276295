import { RouteRecordRaw } from "vue-router";
import ProductPageList from "./Product/ProductPageList.vue"
import ProductForm from "./Product/ProductForm.vue"
import ProductDetail from "./Product/ProductDetail.vue"
import ProductMenu from "./ProductMenu.vue"
import SpecPageList from "./Spec/SpecPageList.vue"
import SpecForm from "./Spec/SpecForm.vue"
import SpecDetail from "./Spec/SpecDetail.vue"
import ProductSpecElementPageList from "./SpecElement/ProductSpecElementPageList.vue"
import PackagePageList from "./Package/ProductPackagePageList.vue"
import PackageForm from "./Package/PackageForm.vue"
import PackageDetail from "./Package/PackageDetail.vue"
import Packages from "./Package/PackagePageList.vue"
import PackagesForm from "./Package/PackageForm.vue"
import PackagesDetail from "./Package/PackageDetail.vue"
const routes: Array<RouteRecordRaw> = [
    {
        path: '/product',
        name: 'ProductManagement',
        component: ProductPageList,
        meta: {
            title: "列表 - 产品管理"
        }
    },
    {
        path: '/product/add',
        name: 'ProductManagementAdd',
        component: ProductForm,
        meta: {
            title: "新增 - 产品管理"
        }
    },
    {
        path: '/product/info/:id',
        name: 'ProductManagementMenu',
        component: ProductMenu,
        meta: {
            title: "产品信息 - 产品管理"
        }
    },
    {
        path: '/product/detail/:id',
        name: 'ProductManagementDetail',
        component: ProductDetail,
        meta: {
            title: "详情 - 产品信息 - 产品管理"
        }
    },
    {
        path: '/product/edit/:id',
        name: 'ProductManagementEdit',
        component: ProductForm,
        meta: {
            title: "编辑 - 详情 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/product/spec/:id',
        name: 'SpecPageList',
        component: SpecPageList,
        meta: {
            title: "规格型号 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/product/spec/add/:id',
        name: 'SpecAdd',
        component: SpecForm,
        meta: {
            title: "新增 - 规格型号 - 产品信息 - 产品管理"
        }
    },
    {
        path: '/product/spec/edit/:id/:specId',
        name: 'SpecEdit',
        component: SpecForm,
        meta: {
            title: "编辑 - 规格型号 - 产品信息 - 产品管理"
        }
    },
    {
        path: '/product/spec/detail/:id/:specId',
        name: 'SpecDetail',
        component: SpecDetail,
        meta: {
            title: "详细 - 规格型号 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/product/spec/element/:id',
        name: 'SpecElementPageList',
        component: ProductSpecElementPageList,
        meta: {
            title: "规格要素 - 规格型号 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/product/package/:id',
        name: 'PackagePageList',
        component: PackagePageList,
        meta: {
            title: "产品包装 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/product/package/add/:id',
        name: 'PackageAdd',
        component: PackageForm,
        meta: {
            title: "新增 - 产品包装 - 产品信息 - 产品管理"
        }
    },
    {
        path: '/product/package/edit/:id/:packageId',
        name: 'PackageEdit',
        component: PackageForm,
        meta: {
            title: "编辑 - 产品包装 - 产品信息 - 产品管理"
        }
    },
    {
        path: '/product/package/detail/:id/:packageId',
        name: 'PackageDetail',
        component: PackageDetail,
        meta: {
            title: "详细 - 产品包装 - 产品信息 - 产品管理"
        }
    },

    {
        path: '/packages',
        name: 'Packages',
        component: Packages,
        meta: {
            title: "包装列表 - 产品管理"
        }
    },
    {
        path: '/packages/add',
        name: 'PackagesAdd',
        component: PackagesForm,
        meta: {
            title: "新增 - 包装列表 - 产品管理"
        }
    },
    {
        path: '/packages/edit/:packageId',
        name: 'PackagesEdit',
        component: PackagesForm,
        meta: {
            title: "编辑 - 包装列表 - 产品管理"
        }
    },
    {
        path: '/packages/detail/:packageId',
        name: 'PackagesDetail',
        component: PackagesDetail,
        meta: {
            title: "详细 - 包装列表 - 产品管理"
        }
    },
]

export default routes;