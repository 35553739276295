
import DtoFormJoinCompanyUser_JoinCompanyUserGroup from "@/models.machine/DtoFormJoinCompanyUser_JoinCompanyUserGroup";
import DtoTreeGroup from "@/models.machine/DtoTreeGroup";
import { ElTree } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class ChooseGroup extends Vue {
  @Model() modelValue: DtoFormJoinCompanyUser_JoinCompanyUserGroup[] = [];
  @Prop() tree: DtoTreeGroup[] = [];
  @Prop() showCheck?: boolean;
  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };
  treeRef?: InstanceType<typeof ElTree>;

  mounted(): void {
    this.treeRef = this.$refs["treeRef"] as InstanceType<typeof ElTree>;
  }

  @Watch("tree")
  onChanged(): void {
    const ids = this.modelValue.map((it) => it.GroupId as string);
    this.treeRef?.setCheckedKeys(ids, false);
  }

  /**菜单选择变化时 */
  handleCheckChange(
    data: DtoTreeGroup,
    checked: boolean,
    indeterminate: boolean
  ): void {
    if (!data.Id) return;
    data.Check = checked || indeterminate;
    let item = this.modelValue.firstOrDefault(
      (it) => it.GroupId == data.Id
    ) as any;
    if (data.Check && !item) {
      item = { GroupId: data.Id, Group: { Name: data.Name } };
      this.modelValue.push(item);
    } else if (!data.Check && item) {
      const index = this.modelValue.indexOf(item);
      this.modelValue.splice(index, 1);
    }
  }
}
