
import AuthAppService from "@/apis.machine/AuthAppService";
import SmsAppService from "@/apis.machine/SmsAppService";
import DtoCallbackAuthCheckUser from "@/models.machine/DtoCallbackAuthCheckUser";
import DtoFormForgetPassword, {
  DtoFormForgetPasswordMiezzFormItems,
  DtoFormForgetPasswordRules,
} from "@/models.machine/DtoFormForgetPassword";
import DtoFormSmsSendVerifyCode from "@/models.machine/DtoFormSmsSendVerifyCode";
import { EnumUserStatus } from "@/models.machine/EnumUserStatus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class ForgetPassword extends Vue {
  form = new MiezzForm<DtoFormForgetPassword, string>();
  check: DtoCallbackAuthCheckUser | null | undefined = null;
  countdown = 0;
  buttons: MiezzButton[] = [
    {
      Code: "forget-password",
      Label: "提交",
      Type: "primary",
      Size: "small",
    },
  ];
  btnPasswordLogin: MiezzButton = {
    Code: "password-login",
    Label: "密码登录",
    Type: "text",
    Size: "small",
  };
  btnRegister: MiezzButton = {
    Code: "register",
    Label: "注册公司",
    Type: "text",
    Size: "small",
  };

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormForgetPassword();
    this.form.Data.PhoneNumber = this.$route.query.phoneNumber as string;
    //表单-校验
    this.form.Rules = DtoFormForgetPasswordRules;
    this.form.Rules.PhoneNumber[1] = this.getRulePhoneNumber();
    this.form.Rules.SurePassword[0] = this.getRuleSurePassword();
    //表单-元素配置
    this.form.Items = DtoFormForgetPasswordMiezzFormItems;
  }

  mounted(): void {
    if (this.form.Data.PhoneNumber)
      MiezzForm.validateField(this.$refs, "ruleForm", "PhoneNumber");
  }

  getRulePhoneNumber(): any {
    return {
      validator: (rule: any, value: any, callback: any) => {
        if (value) {
          if (!/^1[3456789][0-9]{9}$/.test(value)) {
            this.check = undefined;
            return callback(new Error("请输入正确的手机号码"));
          } else {
            AuthAppService.CheckUserByAccount(value)
              .then((r) => {
                const check = r.data.Data;
                if (!check) return callback(new Error("账号不存在"));
                if (check.Status == EnumUserStatus.Disabled)
                  return callback(new Error("您的账号已禁用，无法登陆"));
                if (check.CompanyIds?.length == 0)
                  return callback(new Error("您的账号未入驻公司，无法登陆"));
                this.check = check;
                callback();
              })
              .catch(() => callback());
          }
        } else {
          this.check = undefined;
          callback();
        }
      },
    };
  }

  getRuleSurePassword(): any {
    return {
      validator: (rule: any, value: any, callback: any) => {
        if (this.form.Data.Password && !value) {
          return callback(new Error("请确认密码"));
        } else if (
          value &&
          this.form.Data.Password &&
          value != this.form.Data.Password
        ) {
          return callback(new Error("密码不一致"));
        } else {
          callback();
        }
      },
    };
  }

  handleCountdown(): void {
    if (this.countdown > 0) {
      setTimeout(() => {
        this.countdown--;
        this.handleCountdown();
      }, 1000);
    }
  }

  handleSendSMS(): void {
    this.countdown = 60;
    this.handleCountdown();
    const dto = new DtoFormSmsSendVerifyCode();
    dto.Code = "RePassword";
    dto.PhoneNumber = this.form.Data.PhoneNumber;
    SmsAppService.SendVerifyCodeAsync(dto)
      .then((r) => {
        if (r.data.Extras) {
          const param = JSON.parse(r.data.Extras);
          if (param && param.code) {
            this.form.Data.VerifyCode = param.code;
          }
        }
      })
      .catch(() => {
        this.countdown = 0;
      });
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "forget-password":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            AuthAppService.ForgetPassword(this.form.Data)
              .then(async (r) => {
                this.$router.push({
                  path: "/login/password",
                  query: {
                    phoneNumber: this.form.Data.PhoneNumber,
                  },
                });
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "password-login":
        this.$router.push("/login/password");
        break;
      case "register":
        this.$router.push("/register");
        break;
      default:
        break;
    }
  }
}
