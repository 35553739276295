import UiSelectOption from "./UiSelectOption";

/**
 * 报价表状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumPriceQuotationSheetStatus {
    /**
     * 未发送
     */
    NotSend = 1,
    /**
     * 已发送
     */
    Send = 2,
}

/**
 * 报价表状态-选项
 */
export const EnumPriceQuotationSheetStatusOption: UiSelectOption<number>[] = [
    { Label: "未发送", Value: 1, Data: "NotSend" },
    { Label: "已发送", Value: 2, Data: "Send" },
];
