
import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { init, EChartsOption } from "echarts";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoRequestOrderPoolChart, {
  DtoRequestOrderPoolChartDynamicSearchItems,
} from "@/models.machine/DtoRequestOrderPoolChart";
import { EnumOrderPoolChartDataSource } from "@/models.machine/EnumOrderPoolChartDataSource";
import { EnumOrderPoolChartGroupBy } from "@/models.machine/EnumOrderPoolChartGroupBy";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
@Options({
  components: {
    MiezzMonthChoose,
  },
})
export default class CountPool extends Vue {
  types: {
    code?: string;
    title: string;
    type: "success" | "info";
  }[] = [
    {
      code: "CountTotalNumber",
      title: "票数统计",
      type: "success",
    },
    {
      code: "CountTotalNumber",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerNumber",
      title: "客户",
      type: "info",
    },
    {
      code: "CountServiceNumber",
      title: "服务内容",
      type: "info",
    },
    {
      code: "CountCustomNumber",
      title: "自定义",
      type: "info",
    },
    {
      code: "CountTotalGoods",
      title: "货量统计",
      type: "success",
    },
    {
      code: "CountTotalGoods",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerGoods",
      title: "客户",
      type: "info",
    },
    {
      code: "CountServiceGoods",
      title: "服务内容",
      type: "info",
    },
    {
      code: "CountCustomGoods",
      title: "自定义",
      type: "info",
    },
  ];
  type?: string = "";
  month?: string = "";
  tab?: string = "Number";

  companyId?: string;
  companies: DtoSelectOptionCompany[] = [];
  service?: string;
  services?: UiSelectOptionWithStringValue[] = [];
  dto = new DtoRequestOrderPoolChart();
  searchs = DtoRequestOrderPoolChartDynamicSearchItems;
  histories: UiSelectOption<string>[] = [];
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];
  deliveryPlaces: UiSelectOptionWithStringValue[] = [];
  shipNames: UiSelectOption<string>[] = [];
  voyageNumbers: UiSelectOption<string>[] = [];
  flights: UiSelectOption<string>[] = [];
  railwayNumbers: UiSelectOption<string>[] = [];
  carriers: UiSelectOption<string>[] = [];
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  containerTypes: UiSelectOption<string>[] = [];
  clientUsers: UiSelectOption<string>[] = [];
  customerServices: UiSelectOption<string>[] = [];
  searchSalesmans: UiSelectOption<string>[] = [];
  sources: UiSelectOption<string>[] = [];

  created(): void {
    this.type = this.$route.query.type as string;
    if (!this.type) {
      this.type = this.types.filter((it) => it.code).firstOrDefault()?.code;
    }
    this.handleChooseType(this.type);

    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Customer }).then(
      (r) => {
        this.companies = r.data.Data ?? [];
        this.companyId = this.companies.firstOrDefault()?.Id;
      }
    );
    OrderPoolAppService.GetServiceRemarkSelect({ CountSearch: true }).then((r) => {
      this.services = r.data.Data ?? [];
      this.service = this.services.firstOrDefault()?.Value;
    });

    OrderPoolAppService.GetLoadingPorts({ CountSearch: true }).then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPorts({ CountSearch: true }).then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    OrderPoolAppService.GetDeliveryPlaces({ CountSearch: true }).then((r) => {
      this.deliveryPlaces = r.data.Data ?? [];
    });
    OrderPoolAppService.GetShipNames({ CountSearch: true }).then((r) => {
      this.shipNames = r.data.Data ?? [];
    });
    OrderPoolAppService.GetVoyageNumbers({ CountSearch: true }).then((r) => {
      this.voyageNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetFlights({ CountSearch: true }).then((r) => {
      this.flights = r.data.Data ?? [];
    });
    OrderPoolAppService.GetRailwayNumbers({ CountSearch: true }).then((r) => {
      this.railwayNumbers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetCarriers({ CountSearch: true }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetClientUsers({ CountSearch: true }).then((r) => {
      this.clientUsers = r.data.Data ?? [];
    });
    OrderPoolAppService.GetCustomerServices({ CountSearch: true }).then((r) => {
      this.customerServices = r.data.Data ?? [];
    });
    OrderPoolAppService.GetContainerTypeSelect({ CountSearch: true }).then((r) => {
      this.containerTypes = r.data.Data ?? [];
    });
    CompanyAppService.GetSources().then((r) => {
      this.sources = r.data.Data ?? [];
    });
    OrderPoolAppService.GetSalesmans({ CountSearch: true }).then((r) => {
      this.searchSalesmans = r.data.Data ?? [];
    });
    this.handleGetHistories();
  }

  handleGetHistories(): void {
    DynamicSearchAppService.Get({
      Code: "Count_Pool",
    }).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  handleChooseType(type?: string): void {
    if (this.type != type) {
      this.type = type;
      this.tab = "Number";
      this.$route.query["type"] = this.type ?? "";
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.handleChart();
    }
  }

  @Watch("month")
  onMonthChanged(nv: any, ov: any): void {
    if (nv != ov) {
      this.handleChart();
    }
  }

  handleChart(): void {
    if (this.type && this.month) {
      switch (this.type) {
        case "CountTotalNumber":
          this.getChartBar("CountTotalNumber", "票数总计", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          break;
        case "CountCustomerNumber":
          this.getChartPie("CountCustomerNumberPie", "客户组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          this.getChartBar("CountCustomerNumberBar", "票数合计", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          this.getChartPie("CountCustomerServiceNumberPie", "服务组成", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          break;
        case "CountServiceNumber":
          this.getChartPie("CountServiceNumberPie", "服务组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          this.getChartBar("CountServiceNumberBar", "票数合计", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          this.getChartPie("CountServiceCustomerNumberPie", "客户组成", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Number,
          });
          break;
        case "CountCustomNumber":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderPoolChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderPoolChartDataSource.Number;
          this.getChartBar("CountCustomNumber", "票数总计", this.dto);
          break;
        case "CountTotalGoods":
          this.getChartBar("CountTotalGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartBar("CountTotalGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          break;
        case "CountCustomerGoods":
          this.getChartPie("CountCustomerGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartPie("CountCustomerGoodsVolumePie", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          this.getChartBar("CountCustomerGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartBar("CountCustomerGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          this.getChartPie("CountCustomerServiceGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartPie("CountCustomerServiceGoodsVolumePie", "体积CBM", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          break;
        case "CountServiceGoods":
          this.getChartPie("CountServiceGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartPie("CountServiceGoodsVolumePie", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolChartGroupBy.ServiceRemark,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          this.getChartBar("CountServiceGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartBar("CountServiceGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          this.getChartPie("CountServiceCustomerGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Weight,
          });
          this.getChartPie("CountServiceCustomerGoodsVolumePie", "体积CBM", {
            Month: this.month,
            ServiceRemark: this.service,
            GroupBy: EnumOrderPoolChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolChartDataSource.Volume,
          });
          break;
        case "CountCustomGoods":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderPoolChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderPoolChartDataSource.Weight;
          this.getChartBar("CountCustomGoodsWeightBar", "毛重KGS", this.dto);
          var dto = JSON.copy(this.dto) as DtoRequestOrderPoolChart;
          dto.DataSource = EnumOrderPoolChartDataSource.Volume;
          this.getChartBar("CountCustomGoodsVolumeBar", "体积CBM", dto);
          break;
        default:
          break;
      }
    }
  }

  getChartBar(ref: string, title: string, dto: DtoRequestOrderPoolChart): void {
    OrderPoolAppService.GetChartBar(dto).then((r) => {
      const option: EChartsOption = {
        title: {
          text: title,
          left: "center",
          textStyle: {
            color: "#666",
          },
        },
        xAxis: {
          type: "category",
          data: r.data.Data?.XAxisData,
          axisLabel: { interval: 0 },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: r.data.Data?.SeriesData,
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            barMaxWidth: 70,
          },
        ],
      };
      this.initChart(ref, option);
    });
  }

  getChartPie(ref: string, title: string, dto: DtoRequestOrderPoolChart): void {
    OrderPoolAppService.GetChartPie(dto).then((r) => {
      const option: EChartsOption = {
        title: {
          text: title,
          left: "center",
          textStyle: {
            color: "#666",
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            label: {
              show: false,
            },
            data: r.data.Data?.SeriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.initChart(ref, option);
    });
  }

  initChart(ref: string, option: EChartsOption): void {
    this.$nextTick(() => {
      const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
      if (element) {
        const echarts = init(element);
        echarts.setOption(option);
      }
    });
  }
}
