import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * Http日志分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchHttpLog extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 站点
     */
    SiteId?: string;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
}

/**
 * Http日志分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchHttpLogRules = {
}

/**
 * Http日志分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchHttpLogDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Keywords",
    Label: "关键字",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Method",
    Label: "请求方式",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Milliseconds",
    Label: "耗时毫秒数",
    Type: EnumUiDynamicSearchItemType.InputNumber,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.GreaterThan,
      EnumCompare.GreaterThanEqual,
      EnumCompare.LessThan,
      EnumCompare.LessThanEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Success",
    Label: "是否成功",
    Type: EnumUiDynamicSearchItemType.Switch,
    Compares: [
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_CreatedTime",
    Label: "创建时间",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
    ]
  },
]
