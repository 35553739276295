import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 委托书表单-货物清单-箱规cm×cm×cm与数量
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * L/φ（长度（最长边）/直径）（cm）
     */
    Length?: number;
    /**
     * W/φ（宽度（第二长边）/直径）（cm）
     */
    Width?: number;
    /**
     * H（高度/最短边）（cm）
     */
    Height?: number;
    /**
     * 毛重(KGS)
     */
    GrossWeight?: number;
    /**
     * 体积(CBM)
     */
    Volume?: number;
    /**
     * 数量
     */
    Quantity?: number;
}

/**
 * 委托书表单-货物清单-箱规cm×cm×cm与数量-表单校验
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeRules = {
}

/**
 * 委托书表单-货物清单-箱规cm×cm×cm与数量-设置项
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
    MaxWidth: "280px",
    Customize: true,
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
    MaxWidth: "280px",
    Customize: true,
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
    MaxWidth: "280px",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
    MaxWidth: "100px",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
    MaxWidth: "100px",
    Customize: true,
  },
]

/**
 * 委托书表单-货物清单-箱规cm×cm×cm与数量-设置项
 */
export const DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
    Customize: true,
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
    Customize: true,
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
    Customize: true,
  },
]
