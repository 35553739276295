
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";
import PriceServiceDetailCostCompositionSurcharge from "./PriceServiceDetailCostCompositionSurcharge.vue";
import PricePageList from "@/views/Price/PricePageList.vue";

@Options({
  components: {
    PriceServiceDetailCostCompositionSurcharge,
    PricePageList
  },
})
export default class PriceServiceDetailCostCompositionSurchargeNotAdded extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge_NotAdded"
  );
  amountType = EnumPriceAmountType.Benchmark;

  created(): void {
    this.list.BottomButtons = [
      {
        Code: "add-surcharge",
        Label: "添加",
        Icon: "plus",
        Type: "text",
        Size: "small",
        PowerCode:
          "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge_NotAdded_Add",
      },
    ];
  }
}
