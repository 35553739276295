import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumDocumentType, EnumDocumentTypeOption } from "./EnumDocumentType";
import DtoListItemDocument_CompanyBusinessCard from "./DtoListItemDocument_CompanyBusinessCard";
import DtoFormOrderDeparture from "./DtoFormOrderDeparture";

/**
 * 单证分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemDocument extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 订单编号
     */
    OrderNumber?: string;
    /**
     * 类型
     */
    Type?: EnumDocumentType;
    /**
     * 发货人
     */
    FirstConsignor?: DtoListItemDocument_CompanyBusinessCard;
    /**
     * 收货人
     */
    FirstConsignee?: DtoListItemDocument_CompanyBusinessCard;
    /**
     * 创建人
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 装货港
     */
    OrderDeparture?: DtoFormOrderDeparture;
}

/**
 * 单证分页列表-项-表单校验
 */
export const DtoListItemDocumentRules = {
}

/**
 * 单证分页列表-项-设置项
 */
export const DtoListItemDocumentMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OrderNumber",
    Label: "订单编号",
  },
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumDocumentTypeOption,
  },
  {
    Prop: "FirstConsignor",
    Label: "发货人",
    Customize: true,
  },
  {
    Prop: "FirstConsignee",
    Label: "收货人",
    Customize: true,
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人",
    Customize: true,
  },
]
