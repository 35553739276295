import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderDepartureWharfWarehousing_Plan from "./DtoPutOrderDepartureWharfWarehousing_Plan";
import UiSelectOption from "./UiSelectOption";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 装货港-仓库/堆场/码头-附件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderDepartureWharfWarehousing extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 公司名称
     */
    CompanyName?: string;
    /**
     * 公司信息
     */
    CompanyInfo?: string;
    /**
     * 中文地址
     */
    AddressZh?: string;
    /**
     * 英文地址
     */
    AddressEn?: string;
    /**
     * 联系方式
     */
    Contact?: string;
    /**
     * 进仓编号
     */
    WarehousingCode?: string;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 装货港
     */
    DispatchPortId?: string;
    /**
     * 装货港
     */
    DispatchPortName?: string;
    /**
     * 目的港
     */
    DestinationPortId?: string;
    /**
     * 目的港
     */
    DestinationPortName?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 渠道
     */
    TransportChannelName?: string;
    /**
     * 渠道
     */
    TransportChannelId?: string;
    /**
     * 服务类型
     */
    ServiceName?: string;
    /**
     * 提单号码
     */
    BLNO?: string;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 计划货量
     */
    Plans?: DtoPutOrderDepartureWharfWarehousing_Plan[];
    /**
     * 名称
     */
    Supplier?: string;
    /**
     * 供应商主键
     */
    SupplierCompanyId?: string;
    /**
     * 仓库名称
     */
    WarehouseName?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 路线图
     */
    Road?: string;
    /**
     * 联系人
     */
    Contacter?: string;
    /**
     * 作业时间
     */
    WorkTime?: string;
    /**
     * 收货截止时间
     */
    ReceivingDeadline?: Date;
    /**
     * 收货截止时间
     */
    ReceivingDeadlineString?: string;
    /**
     * 收货截止时间
     */
    ReceivingDate?: Date;
    /**
     * 收货截止时间
     */
    ReceivingTime?: string;
    /**
     * 报关资料截止时间
     */
    CustomsDeadline?: Date;
    /**
     * 报关资料截止时间
     */
    CustomsDeadlineString?: string;
    /**
     * 报关资料截止时间
     */
    CustomsDate?: Date;
    /**
     * 报关资料截止时间
     */
    CustomsTime?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 作业时间
     */
    WorkTimes?: UiSelectOption<string>[];
    /**
     * 地址
     */
    Addresss?: UiSelectOption<string>[];
    /**
     * 联系人
     */
    Contacters?: UiSelectOption<string>[];
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 类型：装货港/目的港
     */
    Type?: EnumOrderServiceType;
}

/**
 * 装货港-仓库/堆场/码头-附件-表单校验
 */
export const DtoPutOrderDepartureWharfWarehousingRules = {
}

/**
 * 装货港-仓库/堆场/码头-附件-设置项
 */
export const DtoPutOrderDepartureWharfWarehousingMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyName",
    Label: "公司名称",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyInfo",
    Label: "公司信息",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "WarehousingCode",
    Label: "进仓编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DispatchPortId",
    Label: "装货港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DestinationPortId",
    Label: "目的港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ServiceName",
    Label: "服务类型",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BLNO",
    Label: "提单号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SumQuantity",
    Label: "合计件数",
    Customize: true,
  },
  {
    Prop: "SumGrossWeight",
    Label: "合计毛重",
    Customize: true,
  },
  {
    Prop: "SumVolume",
    Label: "合计体积",
    Customize: true,
  },
  {
    Prop: "ChargeUnit",
    Label: "计价单位",
    Customize: true,
  },
  {
    Prop: "Plans",
    Label: "计划货量",
    Customize: true,
  },
  {
    Prop: "Supplier",
    Label: "名称",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "WarehouseName",
    Label: "仓库名称",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Address",
    Label: "地址",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Road",
    Label: "路线图",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Contacter",
    Label: "联系人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "WorkTime",
    Label: "作业时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ReceivingDeadline",
    Label: "收货截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ReceivingDeadlineString",
    Label: "收货截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ReceivingDate",
    Label: "收货截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ReceivingTime",
    Label: "收货截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomsDeadline",
    Label: "报关资料截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomsDeadlineString",
    Label: "报关资料截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomsDate",
    Label: "报关资料截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomsTime",
    Label: "报关资料截止时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "WorkTimes",
    Label: "作业时间",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Addresss",
    Label: "地址",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Contacters",
    Label: "联系人",
    Span: 24,
    Customize: true,
  },
]
