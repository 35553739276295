import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyBusinessCardType, EnumCompanyBusinessCardTypeOption } from "./EnumCompanyBusinessCardType";
import UiGroup from "./UiGroup";

/**
 * 添加单位-传参
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFomCompanyBusinessCardUnit extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 类型
     */
    Type?: EnumCompanyBusinessCardType;
    /**
     * 公司中文名称
     */
    NameZh?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 所属国海关编码
     */
    CustomsCode?: string;
}

/**
 * 添加单位-传参-表单校验
 */
export const DtoFomCompanyBusinessCardUnitRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/.test(value)) {
                    return callback(new Error("请输入正确的社会信用代码"));
                } else {
                    callback();
                }
            }
        },
    ],
    CustomsCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Z]{10}$/.test(value)) {
                    return callback(new Error("请输入正确的海关编码"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * 添加单位-传参-设置项
 */
export const DtoFomCompanyBusinessCardUnitMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "公司中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CustomsCode",
    Label: "所属国海关编码",
    Type: EnumMiezzFormItemType.InputText,
  },
]
