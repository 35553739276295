import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamCarrierType, EnumParamCarrierTypeOption } from "./EnumParamCarrierType";
import DtoDetailParamCarrier_FreighTowerAirlineCompany from "./DtoDetailParamCarrier_FreighTowerAirlineCompany";
import DtoDetailParamCarrier_FreighTowerShippingCompany from "./DtoDetailParamCarrier_FreighTowerShippingCompany";
import UiGroup from "./UiGroup";

/**
 * 承运人详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamCarrier extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 查询网址
     */
    Url?: string;
    /**
     * 承运人类别
     */
    Type?: EnumParamCarrierType;
    /**
     * 鸭嘴兽代码
     */
    YaZuiShouCode?: string;
    /**
     * 鸭嘴兽名称
     */
    YaZuiShouName?: string;
    /**
     * 飞鸵接口-航空公司Id
     */
    FreighTowerAirlineCompanyId?: string;
    /**
     * 飞鸵接口-航空公司
     */
    FreighTowerAirlineCompany?: DtoDetailParamCarrier_FreighTowerAirlineCompany;
    /**
     * 飞鸵接口-船公司Id
     */
    FreighTowerShippingCompanyId?: string;
    /**
     * 飞鸵接口-船公司
     */
    FreighTowerShippingCompany?: DtoDetailParamCarrier_FreighTowerShippingCompany;
    /**
     * 飞鸵Id
     */
    FreighTowerId?: string;
    /**
     * 
     */
    FreighTowerName?: string;
}

/**
 * 承运人详细-表单校验
 */
export const DtoDetailParamCarrierRules = {
}

/**
 * 承运人详细-设置项
 */
export const DtoDetailParamCarrierMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Abridge",
    Label: "缩写",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Url",
    Label: "查询网址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Type",
    Label: "承运人类别",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumParamCarrierTypeOption,
  },
  {
    Prop: "YaZuiShouCode",
    Label: "鸭嘴兽代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "YaZuiShouName",
    Label: "鸭嘴兽名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FreighTowerName",
    Label: "",
    Type: EnumMiezzFormItemType.Text,
  },
]
