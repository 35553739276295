
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumMenuType } from "@/models.machine/EnumMenuType";
import MiezzMenuItemComponent from "@/components/MiezzMenuItem.vue";
import MiezzMenuItem from "@/models/MiezzMenuItem";
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MenuRedDotAppService from "@/apis.machine/MenuRedDotAppService";
@Options({
  components: {
    MiezzMenuItemComponent,
  },
})
export default class IndexMenu extends Vue {
  @Prop() redCodes?: Array<string> = [];
  /**活跃菜单 */
  path = "";
  active = "";
  menus: MiezzMenuItem[] = [];
  menuTree?: MiezzMenuItem[] = [];

  created(): void {
    CurrentLogier.GetMenus().then((menus) => {
      this.menus = menus?.Menus ?? [];
      this.getMenuTree();
      this.setMenuActive();
    });
    MenuRedDotAppService.Get().then((r) => {
      // this.redCodes = r.data.Data;
      this.$emit("update:redCodes", r.data.Data);
    });
  }

  /**根据路由设置菜单活跃 */
  setMenuActive(): void {
    let route = this.$route;
    //当前路由菜单
    const menu = this.menus?.firstOrDefault(
      (it) =>
        it.Url == route.path ||
        (route.matched &&
          route.matched.length > 0 &&
          route.matched.any((m) => m.path != "/" && m.path == it.Url))
    );
    if (menu) {
      var item = this.menus?.find((it) => it.Url == menu.Url);
      if (item && item.ParentId && item.Code) {
        this.setMenuIdActive(item.ParentId);
      }
    }
  }

  /**根据路由Id设置菜单活跃 */
  setMenuIdActive(Id: any): void {
    var item = this.menus?.find((it) => it.Id == Id);
    let activeTwo = sessionStorage.getItem("activeTwo");
    if (!activeTwo && item?.Visabled) {
      sessionStorage.setItem("activeTwo", item.Id ?? "");
    }
    if (item && item.ParentId) {
      this.setMenuIdActive(item.ParentId);
    } else {
      if (item && item.Id) {
        this.active = item.Id;
        const menusChil = this.menuTree?.firstOrDefault(
          (it) => it.Id == this.active
        )?.Children;
        if (menusChil) this.$emit("HandleMenu", menusChil);
      }
    }
  }

  /**获取菜单树 */
  getMenuTree(): void {
    this.menuTree = this.menus?.filter(
      (it) =>
        !it.ParentId &&
        it.Visabled &&
        (it.Type == EnumMenuType.Menu || it.Type == EnumMenuType.Page)
    );
    this.loopMenuTree(this.menus);
  }

  /**递归菜单树 */
  loopMenuTree(menus?: MiezzMenuItem[]): void {
    if (menus) {
      for (const item of menus) {
        item.Children = this.menus?.filter(
          (it) =>
            it.ParentId == item.Id &&
            it.Visabled &&
            (it.Type == EnumMenuType.Menu || it.Type == EnumMenuType.Page)
        );
        this.loopMenuTree(item.Children);
      }
    }
  }

  /**选中菜单时 */
  handleSelect(index: any): void {
    sessionStorage.removeItem("activeThree");
    this.active = index;
    CurrentLogier.SetBreadcrumbs([]);
    const menu = this.menuTree?.firstOrDefault((it) => it.Id == index);
    if (menu) {
      if (menu.Children && menu.Children?.length > 0) {
        if (menu.Children[0].Url) {
          this.$router.push(menu.Children[0].Url);
        } else {
          var chil = menu.Children[0];
          if (
            chil.Children &&
            chil.Children.length > 0 &&
            chil.Children[0].Url
          ) {
            this.$router.push(chil.Children[0].Url);
          }
        }
      } else if (menu.Url) {
        this.$router.push(menu.Url);
      }
    }
  }

  IsParent(Id: any, activeList: Array<any>): void {
    this.menus.forEach((ele) => {
      if (ele.Id == Id) {
        activeList.push(ele.Id);
        if (ele.ParentId) this.IsParent(ele.ParentId, activeList);
      }
    });
  }

  @Watch("$route")
  WatchRoute(): void {
    const activeOne = sessionStorage.getItem("activeOne");
    if (activeOne) this.active = activeOne;

    const activeList: Array<any> = [];
    const path = router.currentRoute.value.path;
    const pathHistory = sessionStorage.getItem("pathHistory");
    if (pathHistory != path) {
      sessionStorage.setItem("pathHistory", path);
      this.menus.forEach((ele) => {
        if (ele.Url == path) {
          activeList.push(ele.Id);
          if (ele.ParentId) this.IsParent(ele.ParentId, activeList);
        }
      });

      const menusChil = this.menuTree?.firstOrDefault(
        (it) => it.Id == this.active
      )?.Children;
      if (menusChil) this.$emit("HandleMenu", menusChil);
    }
  }
}
