import UiSelectOption from "./UiSelectOption";

/**
 * 公司类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyType {
    /**
     * 平台
     */
    Platform = 10,
    /**
     * 服务商
     */
    ServiceProvider = 20,
    /**
     * 供应商
     */
    Supplier = 30,
    /**
     * 客户
     */
    Customer = 40,
    /**
     * 通讯录
     */
    ContactRecord = 50,
}

/**
 * 公司类型-选项
 */
export const EnumCompanyTypeOption: UiSelectOption<number>[] = [
    { Label: "平台", Value: 10, Data: "Platform" },
    { Label: "服务商", Value: 20, Data: "ServiceProvider" },
    { Label: "供应商", Value: 30, Data: "Supplier" },
    { Label: "客户", Value: 40, Data: "Customer" },
    { Label: "通讯录", Value: 50, Data: "ContactRecord" },
];
