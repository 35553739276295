
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import CompanyContactPageList from "../../CompanyContact/CompanyContactPageList.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyContact from "@/models.machine/DtoPageListSearchCompanyContact";
import DtoListItemCompanyContact from "@/models.machine/DtoListItemCompanyContact";
@Options({
  components: {
    CompanyContactPageList,
  },
})
export default class CustomerCompanyContactPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyContact,
    DtoListItemCompanyContact,
    string
  >();

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
      },
    ];
  }
}
