
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import CompanyContactDetail from "../../CompanyContact/CompanyContactDetail.vue";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailCompanyContact from "@/models.machine/DtoDetailCompanyContact";
import MiezzButton from "@/models/MiezzButton";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
@Options({
  components: {
    CompanyContactDetail,
  },
})
export default class CustomerCompanyContactDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompanyContact, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "edit",
      Title: "编辑",
      Icon: "edit",
      Type: "default",
      Size: "small",
    },
    // {
    //   Code: "delete",
    //   Title: "删除",
    //   Icon: "delete",
    //   Type: "default",
    //   Message: "是否确认删除",
    //   Size: "small",
    // },
  ];
  menuTab = new MiezzMenuTab();

  
  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "详细信息",
        Power: "CustomerManagement_Company_Contact_Detail",
      },
    ];
  }
  // CustomerManagement_Company_Contact_Add
  // CustomerManagement_Company_Contact_Edit
}
