import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolAuditAction {
    /**
     * 根据委托书直接生成订单
     */
    CreateOrder = 1,
    /**
     * 将委托书转入《拼单配载》与其他委托书拼单
     */
    Stowage = 2,
}

/**
 * -选项
 */
export const EnumOrderPoolAuditActionOption: UiSelectOption<number>[] = [
    { Label: "根据委托书直接生成订单", Value: 1, Data: "CreateOrder" },
    { Label: "将委托书转入《拼单配载》与其他委托书拼单", Value: 2, Data: "Stowage" },
];
