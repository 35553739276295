
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoFormOrderManifestVgm, {
  DtoFormOrderManifestVgmRules,
} from "@/models.machine/DtoFormOrderManifestVgm";
import DtoDetailOrderManifestVgm from "@/models.machine/DtoDetailOrderManifestVgm";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import OrderAppService from "@/apis.machine/OrderAppService";
import MiezzButton from "@/models/MiezzButton";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct, {
  DtoFormOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems,
} from "@/models.machine/DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct";
import ParamContainerTypeAppService from "@/apis.machine/ParamContainerTypeAppService";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm, {
  DtoFormOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems,
} from "@/models.machine/DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm";
import {
  EnumWeighingMethod,
  EnumWeighingMethodOption,
} from "@/models.machine/EnumWeighingMethod";
import DtoSelectOptionOrderManifestVgmCountryCode from "@/models.machine/DtoSelectOptionOrderManifestVgmCountryCode";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import PackageEnAppService from "@/apis.machine/PackageEnAppService";
import { DtoFormOrderManifestVgm_MainBillRules } from "@/models.machine/DtoFormOrderManifestVgm_MainBill";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import { h } from "vue";
export default class OrderManifestVgmForm extends Vue {
  id?: string;
  logier?: Logier;
  form = new MiezzForm<DtoFormOrderManifestVgm, string>();
  loading = false;
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "primary",
      Size: "small",
    },
  ];
  detail?: DtoDetailOrderManifestVgm;
  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;

  listProduct = new MiezzPageList<
    any,
    DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct,
    string
  >();
  currentProduct?: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct;
  btnAddProduct: MiezzButton = {
    Code: "add-product",
    Label: "添加",
    Icon: "plus",
    Type: "primary",
    Size: "small",
  };
  customsPartBlNos: UiSelectOption<string>[] = [];
  containerNos: UiSelectOption<string>[] = [];
  vgmContainerNos: UiSelectOption<string>[] = [];
  containerTypes: UiSelectOption<string>[] = [];
  packageKinds: UiSelectOption<string>[] = [];

  listVgm = new MiezzPageList<
    any,
    DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm,
    string
  >();
  btnAddVgm: MiezzButton = {
    Code: "add-vgm",
    Label: "添加",
    Icon: "plus",
    Type: "primary",
    Size: "small",
  };
  EnumWeighingMethodOption = EnumWeighingMethodOption;
  modalWeighingMethod = new MiezzModal(MiezzModalType.Dialog);

  countryCodes: DtoSelectOptionOrderManifestVgmCountryCode[] = [];
  btnCopyToNotifier: MiezzButton = {
    Code: "copy-to-notifier",
    Label: "复制↓↓",
    Type: "primary",
    Size: "small",
  };

  modalCrossMixing = new MiezzModal(MiezzModalType.Dialog);
  crossMixing = false;

  addDeparturePortBookingManifestVgm = false;

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    // this.addDeparturePortBookingManifestVgm =
    //   this.$route.query.task == "AddDeparturePortBookingManifestVgm";
    this.logier = CurrentLogier.GetLogier();

    this.form.Rules = DtoFormOrderManifestVgmRules;
    const mainBillRules = JSON.copy(DtoFormOrderManifestVgm_MainBillRules);
    const mainBillRequireds = [
      "ShipName",
      "VoyageNumber",
      "Flight",
      "RailwayNumber",
      "DeliveryPortId",
      "MasterBillNo",
    ];
    for (const required of mainBillRequireds) {
      if (!mainBillRules[required]) {
        mainBillRules[required] = [];
      }
      mainBillRules[required].push(
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        }
      );
    }
    this.form.Rules.MainBill = mainBillRules;

    this.listProduct.Fix = false;
    this.listProduct.HideCard = true;
    this.listProduct.EnabledDynamicSearch = false;
    this.listProduct.EnabledRadioSearch = false;
    this.listProduct.EnabledSelect = false;
    this.listProduct.EnabledPagination = false;
    this.listProduct.Items =
      DtoFormOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems;
    for (const item of this.listProduct.Items) {
      switch (item.Prop) {
        case "ContainerTypeId":
        case "HsCode":
          item.CustomizeHeader = true;
          this.form.Rules["DetailProducts_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          break;
        case "BLNO":
        case "NameEn":
        case "PackagesKind":
          item.CustomizeHeader = true;
          this.form.Rules["DetailProducts_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
          ];
          break;
        case "ContainerNo":
          item.CustomizeHeader = true;
          this.form.Rules["DetailProducts_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
            {
              validator: (rule: any, value: string, callback: any) => {
                if (!value) {
                  return callback(new Error("信息不能为空"));
                } else if (value.length > 256) {
                  return callback(new Error("文本长度不能超过 256 个字符"));
                } else {
                  const reg = /^[A-Z]{4}[0-9]{7}$/;
                  if (reg.test(value)) {
                    // 箱号正则表达式
                    const dic: any = {};
                    dic["A"] = 10;
                    dic["B"] = 12;
                    dic["C"] = 13;
                    dic["D"] = 14;
                    dic["E"] = 15;
                    dic["F"] = 16;
                    dic["G"] = 17;
                    dic["H"] = 18;
                    dic["I"] = 19;
                    dic["J"] = 20;
                    dic["K"] = 21;
                    dic["L"] = 23;
                    dic["M"] = 24;
                    dic["N"] = 25;
                    dic["O"] = 26;
                    dic["P"] = 27;
                    dic["Q"] = 28;
                    dic["R"] = 29;
                    dic["S"] = 30;
                    dic["T"] = 31;
                    dic["U"] = 32;
                    dic["V"] = 34;
                    dic["W"] = 35;
                    dic["X"] = 36;
                    dic["Y"] = 37;
                    dic["Z"] = 38;
                    dic["0"] = 0;
                    dic["1"] = 1;
                    dic["2"] = 2;
                    dic["3"] = 3;
                    dic["4"] = 4;
                    dic["5"] = 5;
                    dic["6"] = 6;
                    dic["7"] = 7;
                    dic["8"] = 8;
                    dic["9"] = 9;
                    var number = value;
                    var sum = 0;
                    for (var i = 0; i < 10; i++) {
                      var char = number[i];
                      var code = dic[char];
                      sum += code * Math.pow(2, i);
                    }
                    var result = sum % 11;
                    var end = parseInt(number[10]);
                    var valid = result == end || result - end == 10;
                    if (!valid) {
                      return callback(new Error("请填写正确的箱号信息"));
                    } else {
                      return callback();
                    }
                  } else {
                    return callback(new Error("请填写正确的箱号信息"));
                  }
                }
              },
              trigger: "blur",
            },
          ];
          break;
        case "SealNo":
        case "Quantity":
        case "GrossWeight":
        case "Volume":
          item.CustomizeHeader = true;
          this.form.Rules["DetailProducts_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
          ];
          break;

        default:
          break;
      }
    }
    this.listProduct.Data = {
      Items: [],
    };
    this.listProduct.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.listVgm.Fix = false;
    this.listVgm.HideCard = true;
    this.listVgm.EnabledDynamicSearch = false;
    this.listVgm.EnabledRadioSearch = false;
    this.listVgm.EnabledSelect = false;
    this.listVgm.EnabledPagination = false;
    this.listVgm.Items =
      DtoFormOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems;
    this.form.Rules["DetailVgms_Weight"] = [
      {
        validator: (rule: any, value: number, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可填入>0的数值，保留2位小数"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    for (const item of this.listVgm.Items) {
      switch (item.Prop) {
        case "ContainerNo":
          item.CustomizeHeader = true;
          this.form.Rules["DetailVgms_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
            {
              validator: (rule: any, value: string, callback: any) => {
                if (!value) {
                  return callback(new Error("信息不能为空"));
                } else if (value.length > 256) {
                  return callback(new Error("文本长度不能超过 256 个字符"));
                } else {
                  const reg = /^[A-Z]{4}[0-9]{7}$/;
                  if (reg.test(value)) {
                    // 箱号正则表达式
                    const dic: any = {};
                    dic["A"] = 10;
                    dic["B"] = 12;
                    dic["C"] = 13;
                    dic["D"] = 14;
                    dic["E"] = 15;
                    dic["F"] = 16;
                    dic["G"] = 17;
                    dic["H"] = 18;
                    dic["I"] = 19;
                    dic["J"] = 20;
                    dic["K"] = 21;
                    dic["L"] = 23;
                    dic["M"] = 24;
                    dic["N"] = 25;
                    dic["O"] = 26;
                    dic["P"] = 27;
                    dic["Q"] = 28;
                    dic["R"] = 29;
                    dic["S"] = 30;
                    dic["T"] = 31;
                    dic["U"] = 32;
                    dic["V"] = 34;
                    dic["W"] = 35;
                    dic["X"] = 36;
                    dic["Y"] = 37;
                    dic["Z"] = 38;
                    dic["0"] = 0;
                    dic["1"] = 1;
                    dic["2"] = 2;
                    dic["3"] = 3;
                    dic["4"] = 4;
                    dic["5"] = 5;
                    dic["6"] = 6;
                    dic["7"] = 7;
                    dic["8"] = 8;
                    dic["9"] = 9;
                    var number = value;
                    var sum = 0;
                    for (var i = 0; i < 10; i++) {
                      var char = number[i];
                      var code = dic[char];
                      sum += code * Math.pow(2, i);
                    }
                    var result = sum % 11;
                    var end = parseInt(number[10]);
                    var valid = result == end || result - end == 10;
                    if (!valid) {
                      return callback(new Error("请填写正确的箱号信息"));
                    } else if (
                      (this.form.Data.DetailVgms?.filter(
                        (it) => it.ContainerNo == value
                      ).length ?? 0) > 1
                    ) {
                      return callback(new Error("信息重复，请重新填写"));
                    } else {
                      return callback();
                    }
                  } else {
                    return callback(new Error("请填写正确的箱号信息"));
                  }
                }
              },
              trigger: "blur",
            },
          ];
          break;
        case "ContainerTypeId":
          item.CustomizeHeader = true;
          this.form.Rules["DetailVgms_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "change",
            },
          ];
          break;
        case "SealNo":
          item.CustomizeHeader = true;
          this.form.Rules["DetailVgms_" + item.Prop] = [
            {
              required: true,
              message: "信息不能为空",
              trigger: "blur",
            },
            {
              validator: (rule: any, value: any, callback: any) => {
                if (
                  value != undefined &&
                  (this.form.Data.DetailVgms?.filter(
                    (it) =>
                      it.ContainerNo == value.ContainerNo &&
                      it.SealNo == value.SealNo
                  ).length ?? 0) > 1
                ) {
                  return callback(new Error("信息重复，请重新填写"));
                }
                callback();
              },
              trigger: "blur",
            },
          ];
          break;
        case "WeighingMethod":
          item.CustomizeHeader = true;
          break;

        default:
          break;
      }
    }

    this.listVgm.Data = {
      Items: [],
    };
    this.listVgm.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];
    this.modalWeighingMethod.Title = "称重方式";
    this.modalWeighingMethod.Width = "1100px";
    this.modalWeighingMethod.Height = "180px";

    this.modalCrossMixing.Title = "交叉混拼";
    this.modalCrossMixing.Width = "1320px";
    this.modalCrossMixing.Height = "620px";

    OrderAppService.GetContainerNosById(this.id).then((r) => {
      this.containerNos = r.data.Data ?? [];
      this.vgmContainerNos = r.data.Data ?? [];
    });
    ParamContainerTypeAppService.GetSelect().then((r) => {
      this.containerTypes = r.data.Data ?? [];
    });
    PackageEnAppService.GetSelect().then((r) => {
      this.packageKinds = r.data.Data ?? [];
    });
    OrderAppService.GetCountryCodes().then((r) => {
      this.countryCodes = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetManifestVgmById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail = r.data.Data;
          this.form.Data = r.data.Data;
          if (this.form.Data.DetailProducts?.length == 0) {
            this.form.Data.DetailProducts.push({});
          }
          if (!this.form.Data.SureManifestVgm) {
            this.buttons.push({
              Code: "save",
              Label: "暂存",
              Icon: "Finished",
              Type: "default",
              Size: "small",
            });
          }
          this.listProduct.Data = {
            Items: this.form.Data.DetailProducts ?? [],
          };
          if (this.form.Data.DetailVgms?.length == 0) {
            this.form.Data.DetailVgms.push({
              WeighingMethod: EnumWeighingMethod.SM2,
            });
          }
          this.listVgm.Data = {
            Items: this.form.Data.DetailVgms ?? [],
          };
          if (
            this.form.Data.DeliveryPortId &&
            this.form.Data.DeliveryPortLabel &&
            this.deliveryPorts.length == 0
          ) {
            this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
          }

          //装货港-集装箱拖车
          this.departurePortContainer =
            //直订订单 并且 委托书FCL 并且有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortContainer
              )) ||
            //直订订单 并且 委托书FCL 并且有仓库没集装箱
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortWarehouse &&
                  !it.DeparturePortContainer
              )) ||
            //配载订单 订单FCL
            (this.detail.StowageOrder &&
              this.detail.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL);

          //装货港-报关 当订单中包含的任一委托书中勾选了装货港-报关时
          this.departurePortDeclareCustoms = this.detail.Pools?.any(
            (it) => it.DeparturePortDeclareCustoms
          );

          if (this.departurePortDeclareCustoms) {
            OrderAppService.GetCustomsBlNosById(this.id).then((r) => {
              this.customsPartBlNos = r.data.Data ?? [];
            });
          }
        }
      });
    }
  }
  departurePortContainer?: boolean = false;
  departurePortDeclareCustoms?: boolean = false;

  deliveryPorts: UiSelectOption<string>[] = [];
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.deliveryPorts = r.data.Data ?? [];
      });
    }
  }

  handleCustomsIdChange(
    row: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct
  ): void {
    if (row.CustomsId) {
      const data = this.customsPartBlNos.firstOrDefault(
        (it) => it.Value == row.CustomsId
      )?.Data;
      row.BLNO = data.BLNO;
      row.PoolId = data.OrderPoolId;
      row.PackagesKind = data.PackagesKind;
    } else {
      row.BLNO = undefined;
      row.PoolId = undefined;
      row.PackagesKind = undefined;
    }
    if (this.crossMixing) {
      this.checkCrossMixing();
    }
  }

  handleCustomsIdFocus(e: any): void {
    if (
      this.customsPartBlNos.length == 0 ||
      !this.detail?.MainBill?.MasterBillNo
    ) {
      e.target.blur();
      ElMessageBox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "请先至"),
          h(
            "a",
            {
              href: `#/order/main-bill/edit/${this.id}?backUrl=${this.$route.fullPath}`,
            },
            "《舱务信息-主单信息》"
          ),
          h("span", null, "中完善Master Bill No.，以及” "),
          h(
            "a",
            {
              href: `#/order/departure/customs/${this.id}?backUrl=${this.$route.fullPath}`,
            },
            "《装货港-报关》"
          ),
          h("span", null, "中添加报关资料” "),
        ]),
      });
    }
  }

  handleSyncContainer(
    row: {
      ContainerNo?: string;
      SealNo?: string;
      ContainerTypeId?: string;
      ContainerTypeLabel?: string;
    },
    type: string,
    index: number
  ): void {
    const models =
      this.form.Data.DetailProducts?.filter((it, i) =>
        type == "Product" ? i != index : true
      )
        ?.concat(
          this.form.Data.DetailVgms?.filter((it, i) =>
            type == "Vgm" ? i != index : true
          ) ?? []
        )
        .filter((it) => it.ContainerNo == row.ContainerNo) ?? [];
    for (const model of models) {
      if (row.SealNo) model.SealNo = row.SealNo;
      if (row.ContainerTypeId) {
        model.ContainerTypeId = row.ContainerTypeId;
        model.ContainerTypeLabel = row.ContainerTypeLabel;
      }
    }
  }

  handleContainerTrailerIdVisibleChange(show: boolean, row: any): void {
    if (show) {
      this.vgmContainerNos = this.containerNos.filter(
        (it) =>
          it.Value == row.ContainerTrailerId ||
          this.form.Data.DetailVgms?.all(
            (v) => v.ContainerTrailerId != it.Value
          )
      );
    }
  }

  handleContainerTrailerChange(
    row: {
      ContainerNo?: string;
      SealNo?: string;
      ContainerTypeId?: string;
      ContainerTypeLabel?: string;
    },
    type: string,
    index: number
  ): void {
    if (
      this.detail?.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
      this.containerNos.length > 0
    ) {
      const containerNo = this.containerNos.firstOrDefault(
        (v) => v.Label == row.ContainerNo
      );
      if (containerNo) {
        row.SealNo = containerNo.Data.SealNo;
        row.ContainerTypeId = containerNo.Data.ContainerTypeId;
        row.ContainerTypeLabel = containerNo.Data.ContainerTypeLabel;
      }
    }
    this.calcVgmWeight();
    if (type == "Product") {
      if (this.crossMixing) {
        this.checkCrossMixing();
      }
    }

    const models =
      this.form.Data.DetailProducts?.filter((it, i) =>
        type == "Product" ? i != index : true
      )?.concat(
        this.form.Data.DetailVgms?.filter((it, i) =>
          type == "Vgm" ? i != index : true
        ) ?? []
      ) ?? [];
    if (row.ContainerNo) {
      let model = models.firstOrDefault(
        (it) => it.ContainerNo == row.ContainerNo && it.SealNo
      );
      if (model) {
        row.SealNo = model.SealNo;
      }
      model = models.firstOrDefault(
        (it) => it.ContainerNo == row.ContainerNo && it.ContainerTypeId
      );
      if (model) {
        row.ContainerTypeId = model.ContainerTypeId;
        row.ContainerTypeLabel = model.ContainerTypeLabel;
      }
    }
  }

  handleContainerTrailerIdChange(
    row: {
      ContainerTrailerId?: string;
      ContainerNo?: string;
      SealNo?: string;
      ContainerTypeId?: string;
      ContainerTypeLabel?: string;
    },
    type: string,
    index: number
  ): void {
    if (row.ContainerTrailerId) {
      const data = this.containerNos.firstOrDefault(
        (it) => it.Value == row.ContainerTrailerId
      )?.Data;
      row.ContainerNo = data.ContainerNo;
      row.SealNo = data.SealNo;
      row.ContainerTypeId = data.ContainerTypeId;
      row.ContainerTypeLabel = data.ContainerTypeLabel;
    } else {
      row.ContainerNo = undefined;
      row.SealNo = undefined;
      row.ContainerTypeId = undefined;
      row.ContainerTypeLabel = undefined;
    }
    this.handleContainerTrailerChange(row, type, index);
  }

  handleContainerTrailerIdFocus(e: any): void {
    if (this.containerNos.length == 0) {
      e.target.blur();
      ElMessageBox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "请先至"),
          h(
            "a",
            {
              href: `#/order/departure-port-container/${this.id}?backUrl=${this.$route.fullPath}`,
            },
            "《装货港-集装箱拖车》"
          ),
          h("span", null, "中完善相应信息"),
        ]),
      });
    }
  }

  /**获取装运类型 */
  getNameEns(
    row: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct,
    q: string,
    cb: any
  ): void {
    const customs = this.customsPartBlNos.firstOrDefault(
      (it) => it.Value == row.CustomsId
    )?.Data;
    if (customs?.OrderPoolId) {
      OrderAppService.GetProductNameEnsByPoolId(customs.OrderPoolId).then(
        (r) => {
          cb(
            (r.data.Data ?? [])
              .filter((it) => it?.contains(q))
              .map((it) => {
                return {
                  Name: it,
                };
              })
          );
        }
      );
    } else {
      cb([]);
    }
  }

  onHsCode(row: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct): void {
    this.currentProduct = row;
    const HsCode = this.$refs["HsCode"] as any;
    HsCode.onInit(row.HsCode);
  }

  onSelectHsCode(data: any): void {
    if (this.currentProduct) {
      this.currentProduct.HsCode = data?.Code;
    }
  }
  getPackageKinds(q: string, cb: any): void {
    cb(
      this.packageKinds.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeleteContact(
    select: UiSelectOption<string>,
    value: string,
    item: any
  ): void {
    PackageEnAppService.RemoveHistoryById(select.Value).then(() => {
      const old = this.packageKinds.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        item.PackagesKind = undefined;
      } else {
        item.PackagesKind = old?.Label;
      }
      this.packageKinds = this.packageKinds.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  /**费用-行按钮点击 */
  handleProductRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        if (this.form.Data.DetailProducts?.length == 1) {
          ElMessage.warning("请至少保留一条信息");
        } else {
          this.form.Data?.DetailProducts?.splice(index, 1);
        }
        if (this.crossMixing) {
          this.checkCrossMixing();
        }
        complete();
        break;
      default:
        break;
    }
  }

  handleVgmRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        if (this.form.Data.DetailVgms?.length == 1) {
          ElMessage.warning("请至少保留一条信息");
        } else {
          this.form.Data?.DetailVgms?.splice(index, 1);
        }
        complete();
        break;
      default:
        break;
    }
  }

  calcVgmWeight(): void {
    if (this.listVgm.Data) {
      const vgms =
        this.form.Data.DetailVgms?.filter(
          (it) => it.WeighingMethod == EnumWeighingMethod.SM2
        ) ?? [];
      for (const vgm of vgms) {
        if (vgm.ContainerNo) {
          let weight =
            this.containerNos.firstOrDefault(
              (it) => it.Label == vgm.ContainerNo
            )?.Data?.Tare ?? 0;
          const products =
            this.form.Data.DetailProducts?.filter(
              (it) => it.ContainerNo == vgm.ContainerNo
            ) ?? [];
          for (const product of products) {
            weight += product.GrossWeight ?? 0;
          }
          if (weight) {
            vgm.Weight = weight;
          } else {
            vgm.Weight = undefined;
          }
        } else {
          vgm.Weight = undefined;
        }
      }
      this.listVgm.Data.Items = this.form.Data.DetailVgms;
    }
  }

  getCountryCodes(q: string, cb: any): void {
    cb(
      this.countryCodes
        .filter((it) => it.FullNames?.any((f: string) => f.contains(q)))
        .map((it) => {
          return {
            CountryCode: it.CountryCode,
          };
        })
    );
  }

  checkCrossMixing(): void {
    const customs: {
      BLNO?: string;
      ContainerNos?: (string | undefined)[];
    }[] = [];
    for (const item of this.form.Data.DetailProducts ?? []) {
      const custom = customs.firstOrDefault((it) => it.BLNO == item.BLNO);
      if (custom == undefined) {
        customs.push({
          BLNO: item.BLNO,
          ContainerNos: [item.ContainerNo],
        });
      } else if (!custom.ContainerNos?.contains(item.ContainerNo)) {
        custom.ContainerNos?.push(item.ContainerNo);
      }
    }

    if (
      customs.all((a) =>
        customs
          .filter((b) => b.BLNO != a.BLNO)
          .all(
            (b) =>
              a.ContainerNos?.all((ac) => b.ContainerNos?.contains(ac)) ||
              a.ContainerNos?.all((ac) => !b.ContainerNos?.contains(ac))
          )
      )
    ) {
      this.crossMixing = false;
    } else {
      this.crossMixing = true;
      this.$nextTick(() => {
        console.log(
          (document.getElementsByClassName("cross-mixing")[0] as any).focus()
        );
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-product":
        this.form.Data.DetailProducts?.push({});
        complete();
        break;
      case "add-vgm":
        this.form.Data.DetailVgms?.push({
          WeighingMethod: EnumWeighingMethod.SM2,
        });
        complete();
        break;
      case "copy-to-notifier":
        this.form.Data.NotifyPartyCode = this.form.Data.ConsigneeCode;
        this.form.Data.NotifyPartyNameEn = this.form.Data.ConsigneeNameEn;
        this.form.Data.NotifyPartyAddressEn = this.form.Data.ConsigneeAddressEn;
        this.form.Data.NotifyPartyCountryCode =
          this.form.Data.ConsigneeCountryCode;
        this.form.Data.NotifyPartyTel = this.form.Data.ConsigneeTel;
        this.form.Data.NotifyPartyAeoCode = this.form.Data.ConsigneeAeoCode;
        complete();
        break;
      //保存表单
      case "submit":
        if (this.detail?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.Status == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.checkCrossMixing();
            if (this.crossMixing) {
              complete();
            } else if (
              !this.customsPartBlNos.all((it) =>
                this.form.Data.DetailProducts?.any(
                  (p) => p.CustomsId == it.Value
                )
              )
            ) {
              ElMessage.warning("存在报关分提单号未填入信息的情况，请完善");
              complete();
            } else if (
              !this.containerNos.all((it) =>
                this.form.Data.DetailProducts?.any(
                  (p) => p.ContainerTrailerId == it.Value
                )
              ) ||
              !this.containerNos.all((it) =>
                this.form.Data.DetailVgms?.any(
                  (p) => p.ContainerTrailerId == it.Value
                )
              ) ||
              !this.form.Data.DetailProducts?.all((it) =>
                this.form.Data.DetailVgms?.any(
                  (p) => p.ContainerNo == it.ContainerNo
                )
              ) ||
              !this.form.Data.DetailVgms?.all((it) =>
                this.form.Data.DetailProducts?.any(
                  (p) => p.ContainerNo == it.ContainerNo
                )
              )
            ) {
              ElMessage.warning("存在箱号未填入信息的情况，请完善");
              complete();
            } else {
              let packagesNumber = 0;
              let grossWeight = 0;
              let volume = 0;
              const containerQuantities: {
                ContainerTypeId?: string;
                ContainerTypeLabel?: string;
                ContainerNos?: string[];
                Quantity?: number;
              }[] = [];
              for (const item of this.form.Data.DetailProducts ?? []) {
                packagesNumber += item.Quantity ?? 0;
                grossWeight += item.GrossWeight ?? 0;
                volume += item.Volume ?? 0;
                const cntainerQuantity = containerQuantities.firstOrDefault(
                  (it) => it.ContainerTypeId == item.ContainerTypeId
                );
                if (cntainerQuantity) {
                  if (
                    !cntainerQuantity.ContainerNos?.contains(
                      item.ContainerNo as string
                    )
                  ) {
                    cntainerQuantity.Quantity =
                      (cntainerQuantity.Quantity ?? 0) + 1;
                  }
                } else {
                  containerQuantities.push({
                    ContainerTypeId: item.ContainerTypeId,
                    ContainerTypeLabel: item.ContainerTypeLabel,
                    ContainerNos: [item.ContainerNo as string],
                    Quantity: 1,
                  });
                }
              }
              const goodsDescription = this.form.Data.DetailProducts?.map(
                (it) => it.NameEn
              )
                .distinct()
                .join("\n");

              const checkContainerQuantities =
                containerQuantities.length !=
                  this.detail?.MainBill?.Containers?.length ||
                !containerQuantities.all((it) =>
                  this.detail?.MainBill?.Containers?.any(
                    (c) =>
                      c.ContainerTypeId == it.ContainerTypeId &&
                      c.Quantity == it.Quantity
                  )
                );
              if (checkContainerQuantities) {
                console.log(
                  "条件1不满足：如明细品名及数据中的箱型及箱量与主单信息中不一致:；（注意：不包含VGM中的箱型与箱量）",
                  containerQuantities,
                  this.detail?.MainBill?.Containers
                );
              }
              const packagesKinds = this.form.Data.DetailProducts?.map(
                (it) => it.PackagesKind
              ).distinct();
              const packagesKind =
                (packagesKinds?.length ?? 0) > 1
                  ? "PKGS"
                  : packagesKinds?.firstOrDefault();
              const checkPackages =
                packagesNumber != this.detail?.MainBill?.PackagesNumber ||
                grossWeight != this.detail?.MainBill?.GrossWeight ||
                volume != this.detail?.MainBill?.Volume ||
                packagesKind != this.detail.MainBill.PackagesKind;
              if (checkPackages) {
                console.log(
                  "条件2不满足：如报关分单号中所有信息的件数/毛重/体积的合计值与主单信息的对应信息不一致；",
                  `packagesNumber:${packagesNumber},grossWeight:${grossWeight},volume:${volume},packagesKind:${packagesKind}`,
                  `PackagesNumber:${this.detail?.MainBill?.PackagesNumber},GrossWeight:${this.detail?.MainBill?.GrossWeight},Volume:${this.detail?.MainBill?.Volume},PackagesKind:${this.detail?.MainBill?.PackagesKind}`
                );
              }
              const checkGoodsDescription =
                goodsDescription?.toUpperCase() !=
                this.detail?.MainBill?.GoodsDescription?.replace(
                  "\r\n",
                  "\n"
                ).toUpperCase();
              if (checkGoodsDescription) {
                console.log(
                  "条件3不满足：如明细品名及数据中的品名（去重后）与主单信息中的品名不一致；（相关BUG4992舱单&VGM信息自动填充规则优化及相关修改）",
                  goodsDescription,
                  this.detail?.MainBill?.GoodsDescription
                );
              }
              if (
                checkContainerQuantities ||
                checkPackages ||
                checkGoodsDescription
              ) {
                const errorPackages = checkPackages
                  ? [
                      packagesNumber != this.detail?.MainBill?.PackagesNumber
                        ? "件数"
                        : "",
                      packagesKind != this.detail?.MainBill?.PackagesKind
                        ? "单位"
                        : "",
                      grossWeight != this.detail?.MainBill?.GrossWeight
                        ? "毛重"
                        : "",
                      volume != this.detail?.MainBill?.Volume ? "体积" : "",
                    ]
                      .filter((it) => it != "")
                      .join("/")
                  : "";
                const errors = [
                  checkContainerQuantities ? "集装箱信息" : "",
                  errorPackages,
                  checkGoodsDescription ? "品名" : "",
                ]
                  .filter((it) => it != "")
                  .join("、");
                ElMessageBox.confirm(
                  `舱单中的${errors}与主单信息中的信息不一致，是否调整主单信息？`,
                  "提示",
                  {
                    confirmButtonText: "是，调整",
                    cancelButtonText: "否，不调整",
                    distinguishCancelAndClose: true,
                    showClose: true,
                    type: "warning",
                  }
                )
                  .then(() => {
                    if (this.form.Data.MainBill) {
                      this.form.Data.MainBill.GoodsDescription =
                        goodsDescription;
                      this.form.Data.MainBill.PackagesNumber = packagesNumber;
                      this.form.Data.MainBill.PackagesKind = packagesKind;
                      this.form.Data.MainBill.GrossWeight = grossWeight;
                      this.form.Data.MainBill.Volume = volume;
                      this.form.Data.MainBill.Containers = containerQuantities;
                    }
                    this.loading = true;
                    this.form.Data.SureManifestVgm = true;
                    OrderAppService.PostManifestVgm(this.form.Data)
                      .then(() => {
                        ElMessage.success("保存成功");
                        CurrentLogier.Back();
                        this.loading = false;
                        complete();
                      })
                      .catch(complete);
                  })
                  .catch((action: string) => {
                    if (action == "cancel") {
                      this.loading = true;
                      this.form.Data.SureManifestVgm = true;
                      OrderAppService.PostManifestVgm(this.form.Data)
                        .then(() => {
                          ElMessage.success("保存成功");
                          CurrentLogier.Back();
                          this.loading = false;
                          complete();
                        })
                        .catch(complete);
                    } else {
                      complete();
                    }
                  });
              } else {
                this.loading = true;
                this.form.Data.SureManifestVgm = true;
                OrderAppService.PostManifestVgm(this.form.Data)
                  .then(() => {
                    ElMessage.success("保存成功");
                    CurrentLogier.Back();
                    this.loading = false;
                    complete();
                  })
                  .catch(complete);
              }
            }
          }
        });
        break;
      case "save":
        if (this.detail?.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.Status == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        this.loading = true;
        OrderAppService.PostManifestVgm(this.form.Data)
          .then(() => {
            ElMessage.success("已暂存");
            this.loading = false;
            complete();
          })
          .catch(complete);
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
