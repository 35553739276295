import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumWeighingMethod, EnumWeighingMethodOption } from "./EnumWeighingMethod";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 集装箱拖车Id
     */
    ContainerTrailerId?: string;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 箱型
     */
    ContainerTypeId?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 称重方式
     */
    WeighingMethod?: EnumWeighingMethod;
    /**
     * VGM重量
     */
    Weight?: number;
    /**
     * VGM责任方
     */
    ResponsibleParty?: string;
    /**
     * 责任人签名
     */
    Sign?: string;
    /**
     * VGM电话
     */
    Tel?: string;
    /**
     * 称重地点
     */
    WeighingAddress?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailVgmRules = {
    ContainerNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SealNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ResponsibleParty: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Sign: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Tel: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    WeighingAddress: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailVgmMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Customize: true,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
  {
    Prop: "ContainerTypeId",
    Label: "箱型",
    Customize: true,
  },
  {
    Prop: "WeighingMethod",
    Label: "称重方式",
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "VGM重量",
    Customize: true,
  },
  {
    Prop: "ResponsibleParty",
    Label: "VGM责任方",
    Customize: true,
  },
  {
    Prop: "Sign",
    Label: "责任人签名",
    Customize: true,
  },
  {
    Prop: "Tel",
    Label: "VGM电话",
    Customize: true,
  },
  {
    Prop: "WeighingAddress",
    Label: "称重地点",
    Customize: true,
  },
]

/**
 * -设置项
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailVgmMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Customize: true,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
  {
    Prop: "ContainerTypeId",
    Label: "箱型",
    Customize: true,
  },
  {
    Prop: "WeighingMethod",
    Label: "称重方式",
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "VGM重量",
    Customize: true,
  },
  {
    Prop: "ResponsibleParty",
    Label: "VGM责任方",
    Customize: true,
  },
  {
    Prop: "Sign",
    Label: "责任人签名",
    Customize: true,
  },
  {
    Prop: "Tel",
    Label: "VGM电话",
    Customize: true,
  },
  {
    Prop: "WeighingAddress",
    Label: "称重地点",
    Customize: true,
  },
]
