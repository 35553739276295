import UiSelectOption from "./UiSelectOption";

/**
 * 联系方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumContactType {
    /**
     * 手机号码
     */
    PhoneNumber = 0,
    /**
     * 座机
     */
    Tel = 1,
    /**
     * 邮箱
     */
    Email = 2,
    /**
     * 微信
     */
    WeChat = 3,
    /**
     * QQ
     */
    QQ = 4,
    /**
     * 其他
     */
    Other = 999,
}

/**
 * 联系方式-选项
 */
export const EnumContactTypeOption: UiSelectOption<number>[] = [
    { Label: "手机号码", Value: 0, Data: "PhoneNumber" },
    { Label: "座机", Value: 1, Data: "Tel" },
    { Label: "邮箱", Value: 2, Data: "Email" },
    { Label: "微信", Value: 3, Data: "WeChat" },
    { Label: "QQ", Value: 4, Data: "QQ" },
    { Label: "其他", Value: 999, Data: "Other" },
];
