import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 费用名称详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamPriceCost extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 费用名称详细-表单校验
 */
export const DtoDetailParamPriceCostRules = {
}

/**
 * 费用名称详细-设置项
 */
export const DtoDetailParamPriceCostMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
]
