
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestTime {
    constructor() {
        //无参构造函数
        this.IsInStock = false;
    }

    [proName: string]: any;
    /**
     * 仓库主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 是否入库
     */
    IsInStock?: boolean;
    /**
     * 出入库时间
     */
    CallbackTime?: Date;
    /**
     * 校验的时间
     */
    CheckTime?: Date;
}

/**
 * -表单校验
 */
export const DtoRequestTimeRules = {
}
