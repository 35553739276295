import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_page_list = _resolveComponent("price-page-list")!
  const _component_price_search = _resolveComponent("price-search")!

  return (_openBlock(), _createBlock(_component_price_search, { onHandleRefresh: _ctx.handleRefresh }, {
    default: _withCtx(() => [
      _createVNode(_component_price_page_list, {
        ref: "ref-price-page-list",
        list: _ctx.list,
        serviceId: _ctx.serviceId,
        companyId: _ctx.companyId,
        amountType: _ctx.amountType,
        toggle: true,
        search: true
      }, null, 8, ["list", "serviceId", "companyId", "amountType"])
    ]),
    _: 1
  }, 8, ["onHandleRefresh"]))
}