
import JoinCompanyServiceProviderSupplierAppService from "@/apis.machine/JoinCompanyServiceProviderSupplierAppService";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_Company, {
  DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_CompanyMiezzListItems,
} from "@/models.machine/DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_Company";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class ServiceProviderCompanyOpenSupplier extends Vue {
  menuTab = new MiezzMenuTab();
  tab = "Supplier";
  companyId?: string;
  list = new MiezzPageList<
    any,
    DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_Company,
    string
  >();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Type: "text",
      Size: "small",
    },
  ];

  created(): void {
    this.companyId = this.$route.params.id as string;
    this.tab = this.$route.query.page as string;
    const backUrl = (this.$route.query.backUrl as string).replace("&", "%26");
    this.menuTab.Url = `/service-provider/company/open-supplier/${this.companyId}?backUrl=${backUrl}`;
    this.menuTab.Back = true;
    this.menuTab.ToFirstUrlWhenSomeUrlFullPath = true;
    this.menuTab.Menus = [
      {
        Url: `/service-provider/company/open-supplier/${this.companyId}?page=Supplier&backUrl=${backUrl}`,
        Label: "关联供应商",
        Power: "ServiceProviderManagement_Company_OpenSupplier",
      },
      {
        Url: `/service-provider/company/open-supplier/${this.companyId}?page=ServiceProvider&backUrl=${backUrl}`,
        Label: "关联服务商",
        Power: "ServiceProviderManagement_Company_OpenSupplier",
      },
    ];

    this.list.Fix = true;
    this.list.FixTop = "30px";
    this.list.Data = { Items: [] };
    this.list.DefaultSelectIds = [];
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledPagination = false;
    this.list.EnabledRadioSearchAll = false;
    this.list.Items =
      DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_CompanyMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "JoinCompanyTypes")
        return row.JoinCompanyTypes?.map((it) => it.CompanyType?.Name).join(
          "，"
        );
      return undefined;
    };
    this.handleGet();
  }

  handleLinkAfter(route: any): void {
    this.tab = route.query.page as string;
    this.handleGet();
  }

  handleGet(): void {
    this.$nextTick(() => {
      JoinCompanyServiceProviderSupplierAppService.GetSupplierListByCompanyIdByCompanyType(
        this.companyId,
        this.tab == "Supplier"
          ? EnumCompanyType.Supplier
          : EnumCompanyType.ServiceProvider
      ).then((r) => {
        if (r.data.Data && this.list.Data) {
          this.list.Data.Items = r.data.Data.Companies;
          this.list.DefaultSelectIds =
            this.list.Data.Items?.filter((it) => it.Open).map(
              (it) => it.Id ?? ""
            ) ?? [];
        }
      });
    });
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        JoinCompanyServiceProviderSupplierAppService.Open({
          CompanyType:
            this.tab == "Supplier"
              ? EnumCompanyType.Supplier
              : EnumCompanyType.ServiceProvider,
          ServiceProviderCompanyId: this.companyId,
          SupplierCompanyIds: this.list.SelectIds,
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;

      default:
        break;
    }
  }
}
