
import UiSelectOption from "@/models.machine/UiSelectOption";
import router from "@/router";
import { Vue } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzRadioSearchComponent extends Vue {
  @Model() radioSearch?: any;
  @Model() dynamicSearchId?: any;
  @Model() search?: string;
  @Prop() radioOptions?: UiSelectOption<number>[];
  @Prop() dynamicOptions?: UiSelectOption<number>[];
  @Prop() all = true;
  @Prop() allTitle = "";
  @Prop() disabledSearch = false;
  @Prop() prop?: string;
  @Prop() int?: boolean;

  value?: any;
  type?: string;
  allTip?: string;

  @Emit("handleSearch")
  handleSearch(type?: string): void {
    this.type = type;
    if (this.value != "search") {
      if (type == "radioSearch") {
        this.$emit("update:radioSearch", this.value);
        this.$emit("update:dynamicSearchId", undefined);
      } else {
        this.$emit("update:radioSearch", undefined);
        this.$emit("update:dynamicSearchId", this.value);
      }
    } else {
      this.$emit("update:radioSearch", undefined);
      // this.$emit("update:dynamicSearchId", undefined);
    }
    this.$emit("update:search", this.value == "search" ? "true" : "false");
  }

  @Emit()
  handleClick(): void {
    //
  }

  @Watch("radioSearch")
  onRadioSearchChanged(): void {
    this.onModelValueChanged();
  }

  @Watch("dynamicSearchId")
  onDynamicSearchIdChanged(): void {
    this.onModelValueChanged();
  }

  @Watch("search")
  onDynamicSearcChanged(): void {
    this.onModelValueChanged();
  }

  onModelValueChanged(): void {
    if (this.search == "true") {
      this.value = "search";
    } else if (this.type == "radioSearch" || this.radioSearch) {
      this.type = "radioSearch";
      if (this.radioSearch == undefined) {
        this.value = undefined;
      } else {
        this.value = this.radioSearch;
      }
    } else if (this.type == "dynamicSearchId" || this.dynamicSearchId) {
      this.type = "dynamicSearchId";
      if (this.dynamicSearchId == undefined) {
        this.value = undefined;
      } else {
        this.value = this.dynamicSearchId;
      }
    } else {
      this.value = undefined;
    }
    if (this.value == undefined) {
      var RadioSearch = this.$route.query.RadioSearch as string;
      if (
        RadioSearch != null &&
        RadioSearch != undefined &&
        RadioSearch != ""
      ) {
        this.value = RadioSearch;
      }
    }
  }

  /**创建时 加载路由参数 */
  created(): void {
    const query: any = router.currentRoute.value.query;
    if (this.prop) {
      this.type = "radioSearch";
      const value = query[this.prop]?.toString();
      if (value) {
        if (this.int) {
          this.$emit("update:radioSearch", parseInt(value));
        } else {
          this.$emit("update:radioSearch", value);
        }
      }
    }

    if (this.allTitle == undefined || this.allTitle == "") {
      this.allTip = "所有";
    } else {
      this.allTip = this.allTitle;
    }

    this.onModelValueChanged();
  }
}
