import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 计价单位分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchParamChargeUnit extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * 计价单位分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchParamChargeUnitRules = {
}

/**
 * 计价单位分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchParamChargeUnitDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
