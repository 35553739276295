import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPackage_Product from "./DtoDetailPackage_Product";
import DtoDetailPackage_PackageZh from "./DtoDetailPackage_PackageZh";
import DtoDetailPackage_PackageImage from "./DtoDetailPackage_PackageImage";
import DtoDetailPackage_PackageShape from "./DtoDetailPackage_PackageShape";
import DtoDetailPackage_PackageSpec from "./DtoDetailPackage_PackageSpec";
import UiGroup from "./UiGroup";

/**
 * 产品包装详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPackage extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.PackageImages = [];
        this.PackageShapes = [];
        this.PackageSpecs = [];
        this.UiGroups = [{"Code":"ProductPackageForm","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 所属产品
     */
    ProductId?: string;
    /**
     * 产品名称
     */
    Product?: DtoDetailPackage_Product;
    /**
     * 包装编号
     */
    Code?: string;
    /**
     * 包装种类（中）
     */
    PackageZhId?: string;
    /**
     * 包装种类（中文）
     */
    PackageZh?: DtoDetailPackage_PackageZh;
    /**
     * 包装种类（英）
     */
    PackageEn?: string;
    /**
     * 包装的图片
     */
    PackageImages?: DtoDetailPackage_PackageImage[];
    /**
     * 尺寸/毛重/数量
     */
    PackageShapes?: DtoDetailPackage_PackageShape[];
    /**
     * 包装内的型号与数量
     */
    PackageSpecs?: DtoDetailPackage_PackageSpec[];
    /**
     * 公司主键
     */
    CompanyId?: string;
}

/**
 * 产品包装详细-表单校验
 */
export const DtoDetailPackageRules = {
}

/**
 * 产品包装详细-设置项
 */
export const DtoDetailPackageMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ProductId",
    Label: "所属产品",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "包装编号",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageZhId",
    Label: "包装种类（中）",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageZh",
    Label: "包装种类（中文）",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageEn",
    Label: "包装种类（英）",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageImages",
    Label: "包装的图片",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageShapes",
    Label: "尺寸/毛重/数量",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageSpecs",
    Label: "包装内的型号与数量",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "公司主键",
    Type: EnumMiezzFormItemType.Text,
  },
]
