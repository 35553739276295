
import MiezzMenuItem from "@/models/MiezzMenuItem";
import IndexBreadcrumb from "./IndexBreadcrumb.vue";
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CurrentLogier from "@/apis/CurrentLogier";
import MenuRedDotAppService from "@/apis.machine/MenuRedDotAppService";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
@Options({
  components: {
    IndexBreadcrumb,
  },
})
export default class IndexHeaderChil extends Vue {
  @Prop() menus?: MiezzMenuItem[] = [];
  @Prop() redCodes?: Array<string> = [];
  activeTwo = "";
  activeThree = "";
  path = "";
  WaitingAudit = 0;
  PoolExecuting = 0;
  OrderExecuting = 0;
  WaitingStowage = 0;
  InboundoutboundAdjust = 0;
  powers: string[] = [];

  created(): void {
    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
    });
    this.path = this.$route.path;
    const activeTwo = sessionStorage.getItem("activeTwo");
    if (activeTwo) {
      this.activeTwo = activeTwo;
      const menu = this.menus?.firstOrDefault((it) => it.Id == activeTwo);
      if (menu && menu.Children) {
        const activeThree = sessionStorage.getItem("activeThree");
        if (activeThree) this.activeThree = activeThree;
      } else {
        this.activeThree = "";
      }
    } else if (this.menus && this.menus.length > 0 && this.menus[0].Id)
      this.activeTwo = this.menus[0].Id;

    if (this.path == "/") {
      OrderPoolAppService.GetHomeCount().then((r) => {
        if (r.data.Data) {
          if (r.data.Data.WaitingAudit)
            this.WaitingAudit = r.data.Data.WaitingAudit;

          if (r.data.Data.PoolExecuting)
            this.PoolExecuting = r.data.Data.PoolExecuting;

          if (r.data.Data.OrderExecuting)
            this.OrderExecuting = r.data.Data.OrderExecuting;

          if (r.data.Data.WaitingStowage)
            this.WaitingStowage = r.data.Data.WaitingStowage;

          if (r.data.Data.InboundoutboundAdjust)
            this.InboundoutboundAdjust = r.data.Data.InboundoutboundAdjust;
        }
      });
    }
  }

  HandleMenu(item: any): void {
    sessionStorage.removeItem("activeThree");

    CurrentLogier.SetBreadcrumbs([]);

    if (item && item.Url) {
      this.$router.push(item.Url);
    }
  }

  HandleMenuChil(it: any, item: any) {
    CurrentLogier.SetBreadcrumbs([]);

    if (it && it.Url) {
      this.$router.push(it.Url);
    }
  }
}
