import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          ref: "ruleForm",
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'BusinessLicense')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_upload, {
                      modelValue: _ctx.form.Data.BusinessLicense,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.BusinessLicense) = $event)),
                      path: "company/businessLicense",
                      accept: "image/*",
                      maxSize: 2 * 1024 * 1024
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true),
            (item.Prop == 'AuthStatus')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.EnumAuditStatusOption.firstOrDefault(
                (it) => it.Value == _ctx.form.Data.AuthStatus
              )?.Label), 1),
                    (_ctx.form.Data.AuthRemark)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " / " + _toDisplayString(_ctx.form.Data.AuthRemark), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_miezz_button_bar, {
          modelValue: _ctx.buttons,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.buttons) = $event)),
          onHandleClick: _ctx.handleClick
        }, null, 8, ["modelValue", "onHandleClick"])
      ]),
      _: 1
    })
  ], 64))
}