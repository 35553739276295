import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyCustomerMainRelationApply from "../models.machine/DtoPageListSearchCompanyCustomerMainRelationApply";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyCustomerMainRelationApply from "../models.machine/DtoPageListCompanyCustomerMainRelationApply";
import DtoPageListSearchCompanyCustomerMainRelationAudit from "../models.machine/DtoPageListSearchCompanyCustomerMainRelationAudit";
import DtoPageListCompanyCustomerMainRelationAudit from "../models.machine/DtoPageListCompanyCustomerMainRelationAudit";
import DtoRequestCompanyCustomerMainRelationAudit from "../models.machine/DtoRequestCompanyCustomerMainRelationAudit";

/**
 * 关联-客户公司主体关联
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyCustomerMainRelationAppService {
    /**
     * 获取申请主体关联分页列表
     * @param {DtoPageListSearchCompanyCustomerMainRelationApply} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerMainRelationApply>>} 分页列表
     */
    static GetApplyPageList(dto?: DtoPageListSearchCompanyCustomerMainRelationApply): AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerMainRelationApply>> {
        const url = `/api/CompanyCustomerMainRelation/GetApplyPageList`;
        return service.get<RESTfulResult<DtoPageListCompanyCustomerMainRelationApply>>(url, { params: dto });
    }

    /**
     * 获取审核主体关联分页列表
     * @param {DtoPageListSearchCompanyCustomerMainRelationAudit} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerMainRelationAudit>>} 分页列表
     */
    static GetAuditPageList(dto?: DtoPageListSearchCompanyCustomerMainRelationAudit): AxiosPromise<RESTfulResult<DtoPageListCompanyCustomerMainRelationAudit>> {
        const url = `/api/CompanyCustomerMainRelation/GetAuditPageList`;
        return service.get<RESTfulResult<DtoPageListCompanyCustomerMainRelationAudit>>(url, { params: dto });
    }

    /**
     * 申请主体关联
     * @param {string[]} [serviceProviderCompanyIds] 服务商Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Form(serviceProviderCompanyIds?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyCustomerMainRelation/Form`;
        return service.post<RESTfulResult<any>>(url, serviceProviderCompanyIds);
    }

    /**
     * 取消主体关联
     * @param {string[]} [serviceProviderCompanyIds] 服务商Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Cancel(serviceProviderCompanyIds?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyCustomerMainRelation/Cancel`;
        return service.post<RESTfulResult<any>>(url, serviceProviderCompanyIds);
    }

    /**
     * 审核主体关联
     * @param {DtoRequestCompanyCustomerMainRelationAudit} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Audit(dto?: DtoRequestCompanyCustomerMainRelationAudit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyCustomerMainRelation/Audit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 查询待办数量
     * @returns {AxiosPromise<RESTfulResult<number>>} 待办数量
     */
    static GetWaitHandleCount(): AxiosPromise<RESTfulResult<number>> {
        const url = `/api/CompanyCustomerMainRelation/GetWaitHandleCount`;
        return service.get<RESTfulResult<number>>(url);
    }

}
