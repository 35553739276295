import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchProject from "../models.machine/DtoSelectSearchProject";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchProject from "../models.machine/DtoPageListSearchProject";
import DtoPageListProject from "../models.machine/DtoPageListProject";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailProject from "../models.machine/DtoDetailProject";
import DtoFormProject from "../models.machine/DtoFormProject";
import DtoPageListSearchProjectVersion from "../models.machine/DtoPageListSearchProjectVersion";
import DtoPageListProjectVersion from "../models.machine/DtoPageListProjectVersion";
import DtoDetailProjectVersion from "../models.machine/DtoDetailProjectVersion";

/**
 * 项目
 * @description 自动生成的代码,请勿修改
 */
export default class ProjectAppService {
    /**
     * 获取项目选择器
     * @param {DtoSelectSearchProject} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchProject): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Project/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取项目分页列表
     * @param {DtoPageListSearchProject} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListProject>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchProject): AxiosPromise<RESTfulResult<DtoPageListProject>> {
        const url = `/api/Project/Get`;
        return service.get<RESTfulResult<DtoPageListProject>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Project/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取项目详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailProject>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailProject>> {
        const url = `/api/Project/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailProject>>(url);
    }

    /**
     * 添加项目
     * @param {DtoFormProject} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormProject): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Project/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除项目
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Project/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取系统版本分页列表
     * @param {DtoPageListSearchProjectVersion} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListProjectVersion>>} 分页列表
     */
    static GetVersion(dto?: DtoPageListSearchProjectVersion): AxiosPromise<RESTfulResult<DtoPageListProjectVersion>> {
        const url = `/api/Project/GetVersion`;
        return service.get<RESTfulResult<DtoPageListProjectVersion>>(url, { params: dto });
    }

    /**
     * 获取系统版本详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailProjectVersion>>} 详细
     */
    static GetVersionById(id?: string): AxiosPromise<RESTfulResult<DtoDetailProjectVersion>> {
        const url = `/api/Project/GetVersion/${id}`;
        return service.get<RESTfulResult<DtoDetailProjectVersion>>(url);
    }

}
