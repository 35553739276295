import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumGender, EnumGenderOption } from "./EnumGender";
import DtoFormCompanyContact_ContactNumber from "./DtoFormCompanyContact_ContactNumber";
import UiGroup from "./UiGroup";

/**
 * 通讯录表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyContact extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Numbers = [{"Type":0,"UiGroups":[]},{"Type":1,"UiGroups":[]}];
        this.UiGroups = [{"Code":"BaseInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ContactNumber","Label":"\u8054\u7CFB\u65B9\u5F0F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"Remark","Label":"\u5907\u6CE8","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 姓名
     */
    Name?: string;
    /**
     * 性别
     */
    Gender?: EnumGender;
    /**
     * 生日
     */
    Birthday?: Date;
    /**
     * 籍贯
     */
    NativePlace?: string;
    /**
     * 部门
     */
    Department?: string;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 
     */
    Numbers?: DtoFormCompanyContact_ContactNumber[];
    /**
     * 
     */
    Remark?: string;
}

/**
 * 通讯录表单-表单校验
 */
export const DtoFormCompanyContactRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NativePlace: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Department: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Job: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Avatar: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 通讯录表单-设置项
 */
export const DtoFormCompanyContactMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "姓名",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Gender",
    Label: "性别",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumGenderOption,
  },
  {
    Prop: "Birthday",
    Label: "生日",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.Date,
  },
  {
    Prop: "NativePlace",
    Label: "籍贯",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Department",
    Label: "部门",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Job",
    Label: "职务",
    Group: "BaseInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Avatar",
    Label: "头像",
    Group: "BaseInfo",
    Customize: true,
  },
  {
    Prop: "Numbers",
    Label: "",
    Group: "ContactNumber",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "",
    Group: "Remark",
    Span: 24,
    Type: EnumMiezzFormItemType.AreaText,
  },
]
