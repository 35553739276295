import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyCharge from "../models.machine/DtoPageListSearchCompanyCharge";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyCharge from "../models.machine/DtoPageListCompanyCharge";
import DtoDetailCompanyCharge from "../models.machine/DtoDetailCompanyCharge";
import DtoFormCompanyCharge from "../models.machine/DtoFormCompanyCharge";
import DtoDeleteMessage from "../models.machine/DtoDeleteMessage";
import UiDrag from "../models.machine/UiDrag";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "../models.machine/EnumCompanyChargeType";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoSelectCompanyChargeService from "../models.machine/DtoSelectCompanyChargeService";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoFormCompanyChargeService from "../models.machine/DtoFormCompanyChargeService";

/**
 * 计费重量
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyChargeAppService {
    /**
     * 获取计费重量分页列表
     * @param {DtoPageListSearchCompanyCharge} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyCharge>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyCharge): AxiosPromise<RESTfulResult<DtoPageListCompanyCharge>> {
        const url = `/api/CompanyCharge/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyCharge>>(url, { params: dto });
    }

    /**
     * 获取公司发票详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyCharge>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyCharge>> {
        const url = `/api/CompanyCharge/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyCharge>>(url);
    }

    /**
     * 添加公司发票
     * @param {DtoFormCompanyCharge} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyCharge): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyCharge/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除计费重量
     * @param {string} [companyId] 公司Id
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDeleteMessage>>} 
     */
    static DeleteByCompanyId(companyId?: string, ids?: string[]): AxiosPromise<RESTfulResult<DtoDeleteMessage>> {
        const url = `/api/CompanyCharge/Delete/${companyId}`;
        return service.delete<RESTfulResult<DtoDeleteMessage>>(url, { data: ids });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyCharge/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 判断是否重复
     * @param {DtoFormCompanyCharge} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsSame(dto?: DtoFormCompanyCharge): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/CompanyCharge/IsSame`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 选择器
     * @param {EnumCompanyChargeType} [Type] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelectByType(Type?: EnumCompanyChargeType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/CompanyCharge/GetSelect/${Type}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取服务备注
     * @param {DtoSelectCompanyChargeService} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetServiceRemarks(dto?: DtoSelectCompanyChargeService): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CompanyCharge/GetServiceRemarks`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 关联服务
     * @param {DtoFormCompanyChargeService} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SerServiceRemark(dto?: DtoFormCompanyChargeService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyCharge/SerServiceRemark`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
