import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_foreign_multiple_select = _resolveComponent("miezz-foreign-multiple-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_choose_power = _resolveComponent("choose-power")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'JoinCompanyTypeRoles')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: item.Label,
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_foreign_multiple_select, {
                modelValue: _ctx.form.Data.JoinCompanyTypeRoles,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.JoinCompanyTypeRoles) = $event)),
                options: _ctx.companyTypes,
                prop: "CompanyTypeId"
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 2
          }, 1032, ["label", "prop"]))
        : _createCommentVNode("", true),
      (item.Prop == 'JoinRolePowers')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "权限",
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_choose_power, {
                modelValue: _ctx.form.Data.PowerIds,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.PowerIds) = $event)),
                tree: _ctx.tree,
                showCheck: true
              }, null, 8, ["modelValue", "tree"])
            ]),
            _: 2
          }, 1032, ["prop"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}