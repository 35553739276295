import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemProjectVersion_Project from "./DtoListItemProjectVersion_Project";
import { EnumProjectVersionType, EnumProjectVersionTypeOption } from "./EnumProjectVersionType";

/**
 * 系统版本分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemProjectVersion extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Number = "0.0.0";
        this.MainNumber = 0;
        this.MinorNumber = 0;
        this.RevisionNumber = 0;
    }

    [proName: string]: any;
    /**
     * 项目
     */
    Project?: DtoListItemProjectVersion_Project;
    /**
     * 类型
     */
    Type?: EnumProjectVersionType;
    /**
     * 版本号
     */
    Number?: string;
    /**
     * 主版本号
     */
    MainNumber?: number;
    /**
     * 次版本号
     */
    MinorNumber?: number;
    /**
     * 修订号
     */
    RevisionNumber?: number;
    /**
     * 发布者
     */
    CreatedUserName?: string;
    /**
     * 发布时间
     */
    CreatedTime?: Date;
}

/**
 * 系统版本分页列表-项-表单校验
 */
export const DtoListItemProjectVersionRules = {
}

/**
 * 系统版本分页列表-项-设置项
 */
export const DtoListItemProjectVersionMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Project",
    Label: "项目",
  },
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumProjectVersionTypeOption,
  },
  {
    Prop: "Number",
    Label: "版本号",
  },
  {
    Prop: "CreatedUserName",
    Label: "发布者",
  },
  {
    Prop: "CreatedTime",
    Label: "发布时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
