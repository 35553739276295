
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyBusinessCardUnit from "@/models.machine/DtoPageListSearchCompanyBusinessCardUnit";
import DtoListItemCompanyBusinessCardUnit from "@/models.machine/DtoListItemCompanyBusinessCardUnit";
import CompanyBusinessCardUnitPageList from "../../CompanyBusinessCard/CompanyBusinessCardUnitPageList.vue";

@Options({
  components: {
    CompanyBusinessCardUnitPageList,
  },
})
export default class CurrentCompanyProductionSaleUnit extends Vue {
  title = "生产销售单位";
  type = EnumCompanyBusinessCardType.ProductionSaleUnit;
  list = new MiezzPageList<
    DtoPageListSearchCompanyBusinessCardUnit,
    DtoListItemCompanyBusinessCardUnit,
    string
  >("CompanyManagement_Company_ProductionSaleUnit");

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CompanyManagement_Company_ProductionSaleUnit_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "bank",
        Title: "银行",
        Icon: "CreditCard",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_Bank",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_ProductionSaleUnit_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CompanyManagement_Company_ProductionSaleUnit_Delete",
      },
    ];
  }
}
