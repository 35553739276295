import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderChartDataSource {
    /**
     * 票数
     */
    Number = 1,
    /**
     * 箱型
     */
    ContainerType = 2,
    /**
     * 毛重
     */
    Weight = 3,
    /**
     * 体积
     */
    Volume = 4,
}

/**
 * -选项
 */
export const EnumOrderChartDataSourceOption: UiSelectOption<number>[] = [
    { Label: "票数", Value: 1, Data: "Number" },
    { Label: "箱型", Value: 2, Data: "ContainerType" },
    { Label: "毛重", Value: 3, Data: "Weight" },
    { Label: "体积", Value: 4, Data: "Volume" },
];
