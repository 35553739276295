
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import PlatformCompanyAppService from "@/apis.machine/PlatformCompanyAppService";
import DtoPageListSearchPlatformCompany, {
  DtoPageListSearchPlatformCompanyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchPlatformCompany";
import DtoPageListPlatformCompany from "@/models.machine/DtoPageListPlatformCompany";
import DtoListItemPlatformCompany, {
  DtoListItemPlatformCompanyMiezzListItems,
} from "@/models.machine/DtoListItemPlatformCompany";
import PlatformCompanyDetail from "./PlatformCompanyDetail.vue";
import PlatformCompanyForm from "./PlatformCompanyForm.vue";

@Options({
  components: {
    PlatformCompanyDetail,
    PlatformCompanyForm,
  },
})
export default class PlatformCompanyPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPlatformCompany,
    DtoListItemPlatformCompany,
    string
  >();
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchPlatformCompany();
    //列表-动态搜索-项
    // this.list.DynamicSearchItems =
    //   DtoPageListSearchPlatformCompanyDynamicSearchItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearchAll = false;
    //列表-数据-初始
    this.list.Data = new DtoPageListPlatformCompany();
    //列表-列配置
    this.list.Items = DtoListItemPlatformCompanyMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Company") {
        return row.Company
          ? `${row.Company.NameZh ?? "暂无数据"}/${row.Company.NameEn ?? "暂无数据"}`
          : "暂无数据";
      } else if (item.Prop == "ProjectVersion") {
        return row.ProjectVersion
          ? `${row.ProjectVersion.MainNumber}.${row.ProjectVersion.MinorNumber}.${row.ProjectVersion.RevisionNumber}`
          : "暂无数据";
      }
      return undefined;
    };
    //列表-顶部按钮
    this.list.TopButtons = [];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [];
  }

  /**查询列表 */
  handleGet(): void {
    PlatformCompanyAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增平台管理";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPlatformCompany,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `平台管理详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }
}
