import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_service_detail = _resolveComponent("price-service-detail")!
  const _component_miezz_date_time_range = _resolveComponent("miezz-date-time-range")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_price_service_detail, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'ValidDateStart')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label,
              style: {"width":"610px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_date_time_range, {
                  start: _ctx.form.Data.ValidDateStart,
                  "onUpdate:start": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ValidDateStart) = $event)),
                  end: _ctx.form.Data.ValidDateEnd,
                  "onUpdate:end": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.ValidDateEnd) = $event))
                }, null, 8, ["start", "end"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : (item.Prop == 'Files')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                prop: item.Prop,
                label: item.Label,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_upload, {
                    modelValue: _ctx.form.Data.Files,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.Files) = $event)),
                    path: "price/service",
                    accept: "image/*",
                    prop: "File",
                    isPhoto: true,
                    maxSize: 2 * 1024 * 1024
                  }, null, 8, ["modelValue"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (item.Prop == 'TransportDirection')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: item.Label,
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      prop: "TransportDirection",
                      class: "direction"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_select, {
                          modelValue: _ctx.form.Data.TransportDirection,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.TransportDirection) = $event)),
                          options: _ctx.EnumTransportDirectionOption
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      prop: "TransportType",
                      class: "type"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_select, {
                          modelValue: _ctx.form.Data.TransportType,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.TransportType) = $event)),
                          options: _ctx.EnumTransportTypeOption
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _: 1
                    }),
                    (
            _ctx.form.Data.TransportType == _ctx.EnumTransportType.Sea ||
            _ctx.form.Data.TransportType == _ctx.EnumTransportType.Railway
          )
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 0,
                          prop: "TransportContainerLoad",
                          class: "containerLoad"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_miezz_select, {
                              modelValue: _ctx.form.Data.TransportContainerLoad,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.Data.TransportContainerLoad) = $event)),
                              options: _ctx.EnumTransportContainerLoadOption
                            }, null, 8, ["modelValue", "options"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              : (item.Prop == 'DeparturePortBooking')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 3,
                    prop: item.Prop,
                    label: item.Label,
                    class: "services-check-group"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_checkbox, {
                        modelValue: _ctx.form.Data.DeparturePortBooking,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.Data.DeparturePortBooking) = $event)),
                        label: "订舱"
                      }, null, 8, ["modelValue"]),
                      (_ctx.form.Data.DeparturePortBooking)
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 0,
                            label: "是否签发提单"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_select, {
                                modelValue: _ctx.form.Data.DeparturePortLadingBill,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.Data.DeparturePortLadingBill) = $event)),
                                options: _ctx.yesno,
                                boolean: true,
                                clearable: false
                              }, null, 8, ["modelValue", "options"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                      _createVNode(_component_el_checkbox, {
                        modelValue: _ctx.form.Data.DeparturePortTake,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.Data.DeparturePortTake) = $event)),
                        label: "提/送"
                      }, null, 8, ["modelValue"]),
                      (_ctx.form.Data.DeparturePortTake)
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 2,
                            label: "方式",
                            prop: "DeparturePortTakeMethod",
                            rules: _ctx.form.Rules.DeparturePortTakeMethod
                          }, {
                            default: _withCtx(() => [
                              (
              _ctx.form.Data.TransportContainerLoad == _ctx.EnumTransportContainerLoad.FCL
            )
                                ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                    key: 0,
                                    label: "集装箱拖车",
                                    modelValue: _ctx.form.Data.DeparturePortContainer,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.Data.DeparturePortContainer) = $event))
                                  }, null, 8, ["modelValue"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_el_checkbox, {
                                label: "干线物流",
                                modelValue: _ctx.form.Data.DeparturePortTrunk,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.Data.DeparturePortTrunk) = $event))
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_el_checkbox, {
                                label: "快递",
                                modelValue: _ctx.form.Data.DeparturePortExpress,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.Data.DeparturePortExpress) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }, 8, ["rules"]))
                        : (_openBlock(), _createBlock(_component_el_form_item, { key: 3 })),
                      _createVNode(_component_el_checkbox, {
                        modelValue: _ctx.form.Data.DeparturePortWarehouse,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.Data.DeparturePortWarehouse) = $event)),
                        label: "仓库/堆场/码头"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_el_form_item),
                      _createVNode(_component_el_checkbox, {
                        modelValue: _ctx.form.Data.DeparturePortDeclareCustoms,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.Data.DeparturePortDeclareCustoms) = $event)),
                        label: "报关"
                      }, null, 8, ["modelValue"]),
                      (
            _ctx.form.Data.DeparturePortDeclareCustoms &&
            _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Import
          )
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 4,
                            label: "是否退税"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_select, {
                                modelValue: _ctx.form.Data.DeparturePortDrawback,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.Data.DeparturePortDrawback) = $event)),
                                options: _ctx.yesno,
                                boolean: true,
                                clearable: false
                              }, null, 8, ["modelValue", "options"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 }))
                    ]),
                    _: 2
                  }, 1032, ["prop", "label"]))
                : (item.Prop == 'DestinationPortClearCustoms')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 4,
                      prop: item.Prop,
                      label: item.Label,
                      class: "services-check-group"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.form.Data.DestinationPortClearCustoms,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.Data.DestinationPortClearCustoms) = $event)),
                          label: "清关"
                        }, null, 8, ["modelValue"]),
                        (_ctx.form.Data.DestinationPortClearCustoms)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 0,
                              label: "清关抬头"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_select, {
                                  modelValue: _ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider) = $event)),
                                  options: _ctx.EnumCustomsClearanceHeaderProviderOption,
                                  clearable: false
                                }, null, 8, ["modelValue", "options"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                        (
            _ctx.form.Data.DestinationPortClearCustoms &&
            _ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider ==
              _ctx.EnumCustomsClearanceHeaderProvider.ServiceProvider
          )
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 2,
                              label: "是否出具税单",
                              style: {"margin-left":"172px","margin-top":"5px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_select, {
                                  modelValue: _ctx.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt) = $event)),
                                  options: _ctx.yesno,
                                  boolean: true,
                                  clearable: false,
                                  disabled: _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer
                                }, null, 8, ["modelValue", "options", "disabled"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 3,
                              style: {"margin-left":"172px"}
                            })),
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.form.Data.DestinationPortDelivery,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.Data.DestinationPortDelivery) = $event)),
                          label: "提/送"
                        }, null, 8, ["modelValue"]),
                        (_ctx.form.Data.DestinationPortDelivery)
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 4,
                              prop: "DestinationPortDeliveryMethod",
                              label: "方式"
                            }, {
                              default: _withCtx(() => [
                                (
              _ctx.form.Data.TransportType != _ctx.EnumTransportType.Air &&
              _ctx.form.Data.TransportContainerLoad !=
                _ctx.EnumTransportContainerLoad.Bulk &&
              (_ctx.form.Data.TransportContainerLoad !=
                _ctx.EnumTransportContainerLoad.LCL ||
                _ctx.form.Data.DeparturePortBooking)
            )
                                  ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                      key: 0,
                                      label: "集装箱拖车",
                                      modelValue: _ctx.form.Data.DestinationPortContainer,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.Data.DestinationPortContainer) = $event))
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_el_checkbox, {
                                  label: "干线物流",
                                  modelValue: _ctx.form.Data.DestinationPortTrunk,
                                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.Data.DestinationPortTrunk) = $event))
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_el_checkbox, {
                                  label: "快递",
                                  modelValue: _ctx.form.Data.DestinationPortExpress,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.Data.DestinationPortExpress) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 })),
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.form.Data.DestinationPortWarehouse,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.Data.DestinationPortWarehouse) = $event)),
                          label: "仓库/堆场/码头"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"]))
                  : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"])
  ], 64))
}