import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailOrderPoolPackage from "./DtoDetailOrderPoolPackage";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolPackageShape extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
        this.IsSelected = false;
    }

    [proName: string]: any;
    /**
     * 货物清单Id
     */
    PackageId?: string;
    /**
     * 货物清单
     */
    Package?: DtoDetailOrderPoolPackage;
    /**
     * L/Φ(长度/直径)(cm)
     */
    Length?: number;
    /**
     * W/Φ(宽度/直径)(cm)
     */
    Width?: number;
    /**
     * H(高度)(cm)
     */
    Height?: number;
    /**
     * 毛重(KGS)
     */
    GrossWeight?: number;
    /**
     * 体积(CBM)
     */
    Volume?: number;
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * 剩余数量
     */
    SurplusQuantity?: number;
    /**
     * 当前剩余数量
     */
    CurrentSurplusQuantity?: number;
    /**
     * 送货数量
     */
    DeliveryQuantity?: number;
    /**
     * 是否选中
     */
    IsSelected?: boolean;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
    /**
     * 计划货量主键
     */
    OrderDepartureWharfPlanId?: string;
    /**
     * 
     */
    PlanId?: string;
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoListItemOrderPoolPackageShapeRules = {
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoListItemOrderPoolPackageShapeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "PackageId",
    Label: "货物清单Id",
  },
  {
    Prop: "Package",
    Label: "货物清单",
  },
  {
    Prop: "Length",
    Label: "L/Φ(长度/直径)(cm)",
  },
  {
    Prop: "Width",
    Label: "W/Φ(宽度/直径)(cm)",
  },
  {
    Prop: "Height",
    Label: "H(高度)(cm)",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
  },
  {
    Prop: "Quantity",
    Label: "数量",
  },
  {
    Prop: "SurplusQuantity",
    Label: "剩余数量",
  },
  {
    Prop: "CurrentSurplusQuantity",
    Label: "当前剩余数量",
  },
  {
    Prop: "DeliveryQuantity",
    Label: "送货数量",
  },
  {
    Prop: "IsSelected",
    Label: "是否选中",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsShiftout",
    Label: "是否移库",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "OrderDepartureWharfPlanId",
    Label: "计划货量主键",
  },
  {
    Prop: "PlanId",
    Label: "",
  },
]
