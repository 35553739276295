import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMenuScene, EnumMenuSceneOption } from "./EnumMenuScene";
import DtoDetailPower_JoinPowerMenu from "./DtoDetailPower_JoinPowerMenu";
import DtoDetailPower_JoinPowerMenuField from "./DtoDetailPower_JoinPowerMenuField";
import UiGroup from "./UiGroup";

/**
 * 权限详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPower extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = EnumMenuScene.Platform;
        this.IsTest = false;
        this.Right = false;
        this.Sort = 0;
        this.JoinPowerMenus = [];
        this.JoinPowerMenuFields = [];
        this.MenuIds = [];
        this.MenuFieldIds = [];
    }

    [proName: string]: any;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 场景
     */
    Scene?: EnumMenuScene;
    /**
     * 测试
     */
    IsTest?: boolean;
    /**
     * 右侧
     */
    Right?: boolean;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 排序
     */
    Sort?: number;
    /**
     * 关联-权限/菜单
     */
    JoinPowerMenus?: DtoDetailPower_JoinPowerMenu[];
    /**
     * 关联-权限/菜单/字段
     */
    JoinPowerMenuFields?: DtoDetailPower_JoinPowerMenuField[];
    /**
     * 菜单Id
     */
    MenuIds?: string[];
    /**
     * 菜单字段Id
     */
    MenuFieldIds?: string[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 权限详细-表单校验
 */
export const DtoDetailPowerRules = {
}

/**
 * 权限详细-设置项
 */
export const DtoDetailPowerMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Scene",
    Label: "场景",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumMenuSceneOption,
  },
  {
    Prop: "IsTest",
    Label: "测试",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "Right",
    Label: "右侧",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "MarkerColor",
    Label: "标记色",
    Type: EnumMiezzFormItemType.TextColor,
  },
  {
    Prop: "Sort",
    Label: "排序",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "JoinPowerMenus",
    Label: "关联-权限/菜单",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
