import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderMainBillTemplate_OrderLadingBill extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 元素代码
     */
    Code?: string;
    /**
     * X轴坐标
     */
    X?: number;
    /**
     * Y轴坐标
     */
    Y?: number;
    /**
     * 宽度
     */
    W?: number;
    /**
     * 高度
     */
    H?: number;
    /**
     * 透明度
     */
    O?: number;
    /**
     * 内容
     */
    Content?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderMainBillTemplate_OrderLadingBillRules = {
}

/**
 * -设置项
 */
export const DtoFormOrderMainBillTemplate_OrderLadingBillMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "元素代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "X",
    Label: "X轴坐标",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Y",
    Label: "Y轴坐标",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "W",
    Label: "宽度",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "H",
    Label: "高度",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "O",
    Label: "透明度",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Content",
    Label: "内容",
    Type: EnumMiezzFormItemType.InputText,
  },
]
