
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import MiezzPageList from "@/models/MiezzPageList";
    import DtoPageListSearchOrderPoolCargo from "@/models.machine/DtoPageListSearchOrderPoolCargo";
    import DtoListItemOrderPoolCargo from "@/models.machine/DtoListItemOrderPoolCargo";
    import CargoPageList from "../Pool/Cargo/CargoPageList.vue";
    @Options({
        components: {
            CargoPageList,
        },
    })

    export default class DeparturePortWharfCargoPageList extends Vue {
        menuTab = new MiezzMenuTab();
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchOrderPoolCargo,
            DtoListItemOrderPoolCargo,
            string
        >("OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods");

        created(): void {
            this.menuTab.Back = true;
            this.menuTab.Url = `/order/departure-port-wharf/${this.$route.params.id}/cargos/${this.$route.params.wharfId}`;
            this.menuTab.Menus = [
                {
                    Label: "装货港-仓库/堆场/码头-货物清单",
                    Url: `/order/departure-port-wharf/${this.$route.params.id}/cargos/${this.$route.params.wharfId}`,
                    Power: "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods",
                },
            ];

            //列表-顶部按钮
            this.menuTab.RightButtons = [
                {
                    Code: "add",
                    Label: "添加",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods_AddTo",
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "delete",
                    Title: "删除",
                    Icon: "delete",
                    Type: "default",
                    Size: "small",
                    Message: "是否确认删除",
                    PowerCode:
                        "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods_Delete",
                },
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "update-warehousing-time",
                    Label: "入库时间",
                    Icon: "edit",
                    Type: "text",
                    PowerCode:
                        "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods_Edit",
                },
                {
                    Code: "update-exwarehouse-time",
                    Label: "出库时间",
                    Icon: "edit",
                    Type: "text",
                    PowerCode:
                        "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods_Edit",
                },
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "是否确认删除",
                    PowerCode:
                        "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf_ListOfGoods_Delete",
                },
            ];
        }
    }
