import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolContainerTrailerChartGroupBy {
    /**
     * 业务所属周期
     */
    BusinessCycle = 1,
    /**
     * 客户公司
     */
    ClientCompany = 2,
    /**
     * 供应商
     */
    SupplierCompany = 3,
}

/**
 * -选项
 */
export const EnumOrderPoolContainerTrailerChartGroupByOption: UiSelectOption<number>[] = [
    { Label: "业务所属周期", Value: 1, Data: "BusinessCycle" },
    { Label: "客户公司", Value: 2, Data: "ClientCompany" },
    { Label: "供应商", Value: 3, Data: "SupplierCompany" },
];
