import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_ContainerType extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 箱型
     */
    ContainerTypeId?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_ContainerTypeRules = {
    ContainerTypeId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_ContainerTypeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ContainerTypeId",
    Label: "箱型",
    Customize: true,
  },
]
