import DtoRequestSettlementItem from "./DtoRequestSettlementItem";
import DtoSettlementTime from "./DtoSettlementTime";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestSettlement {
    constructor() {
        //无参构造函数
        this.Visiable = false;
        this.IsImportSurplus = false;
        this.CollectionTitles = [];
        this.PaymentTitles = [];
    }

    [proName: string]: any;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 总金额
     */
    Total?: number;
    /**
     * 币制主键
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystemCode?: string;
    /**
     * 币制
     */
    CurrencySystemFullName?: string;
    /**
     * 待核销
     */
    NoSettlementPrice?: number;
    /**
     * 已核销
     */
    SettlementPrice?: string;
    /**
     * 核销明细
     */
    Items?: DtoRequestSettlementItem[];
    /**
     * 是否导入剩余：导入剩余的话表示已核销
     */
    IsImportSurplus?: boolean;
    /**
     * 账单主键
     */
    BillId?: string;
    /**
     * 默认的收款方抬头
     */
    CollectionTitle?: string;
    /**
     * 默认的付款方抬头
     */
    PaymentTitle?: string;
    /**
     * 创建人
     */
    CreatedUserName?: string;
    /**
     * 当前时间
     */
    CurrentTime?: string;
    /**
     * 收款方抬头集合
     */
    CollectionTitles?: DtoSettlementTime[];
    /**
     * 付款方抬头集合
     */
    PaymentTitles?: DtoSettlementTime[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
}

/**
 * -表单校验
 */
export const DtoRequestSettlementRules = {
}
