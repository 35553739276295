import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamCountry from "../models.machine/DtoSelectSearchParamCountry";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamCountry from "../models.machine/DtoPageListSearchParamCountry";
import DtoPageListParamCountry from "../models.machine/DtoPageListParamCountry";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamCountry from "../models.machine/DtoDetailParamCountry";
import DtoFormParamCountry from "../models.machine/DtoFormParamCountry";

/**
 * 国家
 * @description 自动生成的代码,请勿修改
 */
export default class ParamCountryAppService {
    /**
     * 获取国家选择器
     * @param {DtoSelectSearchParamCountry} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamCountry): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCountry/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取国家分页列表
     * @param {DtoPageListSearchParamCountry} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamCountry>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamCountry): AxiosPromise<RESTfulResult<DtoPageListParamCountry>> {
        const url = `/api/ParamCountry/Get`;
        return service.get<RESTfulResult<DtoPageListParamCountry>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountry/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取国家详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamCountry>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamCountry>> {
        const url = `/api/ParamCountry/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamCountry>>(url);
    }

    /**
     * 新增/编辑国家
     * @param {DtoFormParamCountry} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} Id
     */
    static Post(dto?: DtoFormParamCountry): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountry/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除国家
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountry/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountry/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCountry/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCountry/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamCountry/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取NameZh数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameZhList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ParamCountry/GetNameZhList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 获取国家选择器(不带Code)
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSelectNotCode(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCountry/GetSelectNotCode`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 根据国家获取地区名称
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetCountryAreaNamesById(id?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ParamCountry/GetCountryAreaNames/${id}`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
