import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import DtoListItemOrder_OrderPool from "./DtoListItemOrder_OrderPool";
import DtoListItemOrder_OrderMainBill from "./DtoListItemOrder_OrderMainBill";
import DtoListItemOrder_OrderContainer from "./DtoListItemOrder_OrderContainer";
import { EnumOrderStatus, EnumOrderStatusOption } from "./EnumOrderStatus";

/**
 * 订单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrder extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.StowageOrder = false;
        this.Lock = false;
        this.ClientInfo = "0";
        this.EATD = "--/--";
        this.EATA = "--/--";
        this.CargoQuantity = "/KGS/CBM";
        this.Pools = [];
        this.Containers = [];
        this.HasTask = false;
    }

    [proName: string]: any;
    /**
     * 标记
     */
    Color?: string;
    /**
     * 配载订单
     */
    StowageOrder?: boolean;
    /**
     * 锁定
     */
    Lock?: boolean;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 委托人
     */
    ClientInfo?: string;
    /**
     * 委托人
     */
    Clients?: string[];
    /**
     * ETD/ATD
     */
    EATD?: string;
    /**
     * ETA/ATA
     */
    EATA?: string;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 订舱代理
     */
    SupplierCompanyLabel?: string;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 委托书
     */
    Pools?: DtoListItemOrder_OrderPool[];
    /**
     * 主单
     */
    MainBill?: DtoListItemOrder_OrderMainBill;
    /**
     * 集装箱信息
     */
    Containers?: DtoListItemOrder_OrderContainer[];
    /**
     * 客服
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 有待办
     */
    HasTask?: boolean;
    /**
     * 状态
     */
    Status?: EnumOrderStatus;
}

/**
 * 订单分页列表-项-表单校验
 */
export const DtoListItemOrderRules = {
}

/**
 * 订单分页列表-项-设置项
 */
export const DtoListItemOrderMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Color",
    Label: "标记",
    MaxWidth: "60px",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
  },
  {
    Prop: "ClientInfo",
    Label: "委托人",
    Customize: true,
  },
  {
    Prop: "EATD",
    Label: "ETD/ATD",
  },
  {
    Prop: "EATA",
    Label: "ETA/ATA",
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "订舱代理",
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
  },
  {
    Prop: "CustomerServiceId",
    Label: "客服",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderStatusOption,
  },
]
