import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPaymentTerms from "../models.machine/DtoSelectSearchPaymentTerms";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchPaymentTerms from "../models.machine/DtoPageListSearchPaymentTerms";
import DtoPageListPaymentTerms from "../models.machine/DtoPageListPaymentTerms";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPaymentTerms from "../models.machine/DtoDetailPaymentTerms";
import DtoFormPaymentTerms from "../models.machine/DtoFormPaymentTerms";

/**
 * 付款条款
 * @description 自动生成的代码,请勿修改
 */
export default class PaymentTermsAppService {
    /**
     * 获取航线代码选择器
     * @param {DtoSelectSearchPaymentTerms} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPaymentTerms): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PaymentTerms/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取付款条款分页列表
     * @param {DtoPageListSearchPaymentTerms} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPaymentTerms>>} 
     */
    static Get(dto?: DtoPageListSearchPaymentTerms): AxiosPromise<RESTfulResult<DtoPageListPaymentTerms>> {
        const url = `/api/PaymentTerms/Get`;
        return service.get<RESTfulResult<DtoPageListPaymentTerms>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTerms/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取付款条款详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPaymentTerms>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPaymentTerms>> {
        const url = `/api/PaymentTerms/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPaymentTerms>>(url);
    }

    /**
     * 新增/编辑付款条款
     * @param {DtoFormPaymentTerms} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormPaymentTerms): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTerms/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除付款条款
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTerms/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PaymentTerms/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PaymentTerms/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PaymentTerms/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/PaymentTerms/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
