
    import CurrentLogier from "@/apis/CurrentLogier";
    import MiezzForm from "@/models/MiezzForm";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import {
        EnumSupplierSource,
        EnumSupplierSourceOption,
    } from "@/models.machine/EnumSupplierSource";
    import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
    import DtoSelectSearchCompanyType from "@/models.machine/DtoSelectSearchCompanyType";
    import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
    import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import DtoCallbackSupplier, {
        DtoCallbackSupplierMiezzFormItems,
        DtoCallbackSupplierRules,
    } from "@/models.machine/DtoCallbackSupplier";
    import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";

    export default class MiezzSupplier extends Vue {
        @Prop() isDetail?: boolean;    //是否查看
        @Prop() isRelocation?: boolean; //是否移库
        @Prop() isLock?: boolean; //订单是否锁定
        formSupplier = new MiezzForm<DtoCallbackSupplier, string>();
        /**供应商-来源选择器数据 */
        EnumSupplierSourceOption = EnumSupplierSourceOption;
        EnumSupplierSourceOptionNew = EnumSupplierSourceOption;
        /**供应商-类型选择器数据 */
        supplierRandom = 0;
        supplierTypes: UiSelectOption<string>[] = [];
        /**供应商-供应商选择器数据 */
        suppliers: DtoSelectOptionCompany[] = [];
        /**供应商-联系人选择器数据 */
        supplierContacts: UiSelectOption<string>[] = [];
        ruleForm: {
            Visible: boolean,
            Title: string,
        } = {
                Visible: false,
                Title: "选择供应商",
            };
        isInit = false;
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        /**格式化枚举 */
        formatEnum(options: UiSelectOption<number>[], value?: number): string {
            return (
                options.firstOrDefault((it) => it.Value == value)?.Label ?? "暂无数据"
            );
        }
        onSupplierCompanyIdChanged(): void {
            if (this.formSupplier.Data.SupplierCompanyId) {
                CompanyContactAppService.GetSelect({
                    CompanyId: this.formSupplier.Data.SupplierCompanyId,
                }).then((r) => {
                    this.supplierContacts = r.data.Data ?? [];
                });
            } else {
                this.supplierContacts = [];
                this.formSupplier.Data.SupplierCompanyContactLabel = undefined;
            }

            this.onChangeSupplier();
        }
        /**供应商-获取联系人 */
        getSupplierCompanyContact(q: string, cb: any): void {
            cb(this.supplierContacts.filter((it) => it.Label?.contains(q)) ?? []);
        }
        onChangeSupplierCompanyContactLabel(item: any): void {
            if (this.formSupplier.Data.SupplierCompanyContactLabel != null
                && this.formSupplier.Data.SupplierCompanyContactLabel != undefined
                && this.formSupplier.Data.SupplierCompanyContactLabel != '') {
                MiezzForm.clearValidate(this.$refs, "ref-form-supplier", "SupplierCompanyContactLabel");
            }
        }
        querySearchSupplier(queryString: string, cb: any) {
            var restaurants = this.suppliers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.suppliers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.suppliers) {
                    if (it.NameZh) {
                        var Name = it.NameZh.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 10) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.suppliers) {
                    var number1 = 0;
                    for (var i = 0; i < this.suppliers.length; i++) {
                        if (number1 < 10) {
                            results.push(this.suppliers[i]);
                        }
                        number1++;
                    }
                }
            }
            cb(results);
        }
        handleSelectSupplier(item: UiSelectOption<string>) {
            this.formSupplier.Data.SupplierCompanyId = item.Id;
            this.formSupplier.Data.SupplierCompanyLabel = item.NameZh;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductNameEn");
        }


        /**供应商-来源变更时 */
        // onSupplierSourceChanged(): void {
        //     const dto = new DtoSelectSearchCompanyType();
        //     if (this.formSupplier.Data.SupplierSource == EnumSupplierSource.ServiceProvider) {
        //         dto.Type = EnumCompanyType.ServiceProvider;
        //     } else {
        //         dto.Type = EnumCompanyType.Supplier;
        //     }
        //     if (this.isInit == false) {
        //         this.formSupplier.Data.SupplierCompanyId = undefined;
        //     }

        //     CompanyTypeAppService.GetSelect(dto).then((r) => {
        //         this.supplierTypes = r.data.Data ?? [];
        //         if (
        //             !this.formSupplier.Data.SupplierCompanyTypeId ||
        //             this.supplierTypes.all(
        //                 (it) => it.Value != this.formSupplier.Data.SupplierCompanyTypeId
        //             )
        //         ) {
        //             this.formSupplier.Data.SupplierCompanyTypeId = this.supplierTypes.firstOrDefault()?.Value;
        //             this.onSupplierTypeIdChanged();
        //         } else {
        //             this.onSupplierTypeIdChanged();
        //         }
        //         this.supplierRandom = Math.random();
        //     });
        // }
        onChangeSupplier():void{
            if(this.suppliers){
                var flag = false;
                for(var it of this.suppliers){
                    if(it.NameZh == this.formSupplier.Data.SupplierCompanyLabel){
                        flag = true;
                    }
                }
                if(flag == false){
                    this.formSupplier.Data.SupplierCompanyId = undefined;
                    this.formSupplier.Data.SupplierCompanyLabel = undefined;
                }
            }
        }
        onSupplierSourceChanged(): void {
            this.supplierTypes = [];
            this.formSupplier.Data.SupplierCompanyTypeId = undefined;

            if (this.formSupplier.Data.SupplierSource) {
                const dto = new DtoSelectSearchCompanyType();
                if (this.formSupplier.Data.SupplierSource == EnumSupplierSource.ServiceProvider) {
                    dto.Type = EnumCompanyType.ServiceProvider;
                } else {
                    dto.Type = EnumCompanyType.Supplier;
                }
                if (this.isInit == false) {
                    this.formSupplier.Data.SupplierCompanyId = undefined;
                }

                CompanyTypeAppService.GetSelect(dto).then((r) => {
                    this.supplierTypes = r.data.Data ?? [];
                    this.onSupplierTypeIdChanged();
                    this.supplierRandom = Math.random();
                });
            }
        }
        onSupplierTypeIdChanged(): void {
            CompanyAppService.GetSelect({
                CompanyType: EnumCompanyType.Supplier,
                SupplierSource: this.formSupplier.Data.SupplierSource,
                CompanyTypeId: this.formSupplier.Data.SupplierCompanyTypeId,
            }).then((r) => {
                this.suppliers = r.data.Data ?? [];
                if (
                    this.suppliers.all(
                        (it) => it.Id != this.formSupplier.Data.SupplierCompanyId
                    )
                ) {
                    if (this.isInit == false) {
                        this.formSupplier.Data.SupplierCompanyId = undefined;
                    }
                    else {
                        MiezzForm.clearValidate(this.$refs, "ref-form-supplier", undefined);
                    }
                    this.isInit = false;
                }
            });
        }

        created(): void {
            this.formSupplier.Items = DtoCallbackSupplierMiezzFormItems;
            this.formSupplier.Rules = DtoCallbackSupplierRules;
            this.formSupplier.Data = new DtoCallbackSupplier();
        }

        /*初始化 */
        @Emit()
        onInit(param: any): void {
            var supplierSource = JSON.parse(JSON.stringify(this.EnumSupplierSourceOption));
            this.EnumSupplierSourceOptionNew = [];
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("SupplierManagement_Public") == false) {
                    for (var s of supplierSource) {
                        if (s.Value != 2 && s.Value != 3) {
                            this.EnumSupplierSourceOptionNew.push(s);
                        }
                    }
                }
                else {
                    for (var s1 of supplierSource) {
                        if (s1.Value != 3) {
                            this.EnumSupplierSourceOptionNew.push(s1);
                        }
                    }
                }
            });


            if (param != undefined) {
                this.formSupplier.Data.SupplierSource = param.SupplierSource;
                this.formSupplier.Data.SupplierCompanyTypeId = param.SupplierCompanyTypeId;
                this.formSupplier.Data.SupplierCompanyTypeLabel = param.SupplierCompanyTypeLabel;
                this.formSupplier.Data.SupplierCompanyId = param.SupplierCompanyId;
                this.formSupplier.Data.SupplierCompanyLabel = param.SupplierCompanyLabel;
                this.formSupplier.Data.SupplierCompanyContactLabel = param.SupplierCompanyContactLabel;
            }
            if (this.formSupplier.Data.SupplierSource == undefined) {
                this.formSupplier.Data.SupplierSource = this.EnumSupplierSourceOption[0].Value;
            }
            this.isInit = true;
            this.onSupplierSourceChanged();

            if (this.isDetail == true) {
                this.ruleForm.Title = "查看供应商";
            }
            else {
                this.ruleForm.Title = "选择供应商";
            }
            if (this.isRelocation == true) {
                // this.isDetail = false;
                this.ruleForm.Title = "移库";
            }

            this.ruleForm.Visible = true;
            if (this.bottomButtons) {
                this.bottomButtons[0].Hide = this.isDetail;
            }
        }

        onClearSupplier():void{
            this.formSupplier.Data.SupplierCompanyId = undefined;
            this.formSupplier.Data.SupplierCompanyLabel = undefined;
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            this.onChangeSupplier();
            switch (btn.Code) {
                case "submit":
                    if (this.isLock == true) {
                        ElMessage.warning("订单已锁定");
                        complete();
                        return;
                    }
                    MiezzForm.submit(this.$refs, "ref-form-supplier", (v) => {
                        if (v == true) {
                            this.$emit("onSelectSupplier", this.formSupplier.Data);
                            this.ruleForm.Visible = false;
                            complete();
                        } else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }
