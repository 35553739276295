
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import SpecAppService from "@/apis.machine/SpecAppService";
    import DtoPageListSearchSpec, { DtoPageListSearchSpecDynamicSearchItems } from "@/models.machine/DtoPageListSearchSpec";
    import DtoPageListSpec from "@/models.machine/DtoPageListSpec";
    import DtoListItemSpec, {
        DtoListItemSpecMiezzListItems,
    } from "@/models.machine/DtoListItemSpec";
    import ProductMenu from "../ProductMenu.vue"

    import MiezzForm from "@/models/MiezzForm";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoDetailProduct, {
        DtoDetailProductMiezzDetailItems,
    } from "@/models.machine/DtoDetailProduct";

    import SpecDetail from "./SpecDetail.vue"; import SpecForm from "./SpecForm.vue";
    @Options({
        components: {
            SpecDetail,
            SpecForm,
            ProductMenu
        },
    })
    export default class SpecPageList extends Vue {
        imageForm = {
            Visiable: false,
            Rows: [],
        };
        bottomButtonsImage?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        //库存信息
        ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation = false;
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchSpec, DtoListItemSpec, string>();
        /**当前Id */
        id?: string;
        productId = "";
        product = new MiezzForm<DtoDetailProduct, string>();
        random = 0;
        client?: OSS;
        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();

            this.productId = this.$route.params.id as string;
            this.product.Data = new DtoDetailProduct();
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchSpec();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchSpecDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListSpec();
            //列表-列配置
            this.list.Items = [];

            CurrentLogier.GetPowerCodes().then((r) => {

                if (r?.contains("ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation")) {
                    this.ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation = true;
                }

                var items = DtoListItemSpecMiezzListItems?.map((it) => {
                    const item = Object.copy(it);
                    if (item.Prop == "StockQuantity") {
                        if (this.ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_InventoryInformation == true) {
                            item.CustomizeHeader = true;
                            return item;
                        }
                    }
                    else if (item.Prop == "SpecImages") {
                        item.Width = 40;
                        return item;
                    }
                    else if (item.Prop == "Weight") {
                        item.Width = 40;
                        return item;
                    }
                    else {
                        return item;
                    }

                });
                if (items && this.list.Items) {
                    for (var it of items) {
                        if (it != undefined) {
                            this.list.Items.push(it);
                        }
                    }
                }
            });




            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "element",
                    Label: "要素信息",
                    Icon: "operation",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_SpecificationElements"
                },
                {
                    Code: "add",
                    Label: "添加规格",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_NewlyAdded"
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "detail",
                    Title: "查看详细",
                    Icon: "search",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_Details",
                },
            ];
            this.list.OperationWidth = "100px";
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    /*Message: "是否确认删除",*/
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_SpecificationAndModel_Delete"
                },
            ];
            this.list.FixTop = "40px";
        }

        /**查询列表 */
        handleGet(): void {
            if (this.productId) {
                ProductAppService.GetById(this.productId).then((r) => {
                    if (r.data.Data) this.product.Data = r.data.Data;
                });
            }

            if (!this.list.Dto) {
                this.list.Dto = new DtoPageListSearchSpec();
            }
            this.list.Dto.ProductId = this.productId;
            SpecAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                if (this.list.Data && this.list.Data.Items) {
                    for (var it of this.list.Data.Items) {
                        if (it.SpecImages && it.SpecImages.length > 0) {
                            for (var img of it.SpecImages) {
                                if (img.Url) {
                                    img.Name = img.Url?.split('/')[img.Url.split('/').length - 1];
                                    img.Url = this.client?.signatureUrl(img.Url as string);
                                }
                            }
                        }
                    }
                }
            });
        }
        onImage(row: any): void {
            if (row.SpecImages && row.SpecImages.length > 0) {
                if (row.SpecImages.length == 1) {
                    window.open(row.SpecImages[0].Url);
                }
                else {
                    this.imageForm.Rows = row.SpecImages;
                    this.imageForm.Visiable = true;
                }
            }
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                ////新增
                //case "add":
                //    this.modalForm.Show = true;
                //    this.modalForm.Title = "新增某个产品的规格";
                //    this.id = undefined;
                //    complete();
                //    break;
                //新增
                case "add":
                    this.$router.push("/product/spec/add/" + this.productId + "?backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                case "element"://规格要素
                    this.$router.push("/product/spec/element/" + this.productId + "?backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemSpec,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    //this.modalDetail.Show = true;
                    //this.modalDetail.Title = `某个产品的规格详细-${row.Name}`;
                    // console.log("this.$route", this.$route)
                    this.$router.push("/product/spec/detail/" + this.productId + "/" + this.id);
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":

                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个规格型号");
                        return;
                    }

                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            SpecAppService.Delete(this.list.SelectIds)
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });




                    break;
                default:
                    break;
            }
        }

        /**按钮点击 */
        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
    }
