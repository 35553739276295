import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-width": "80px",
    inline: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "字段" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tree, {
            ref: "treeRef",
            "node-key": "Id",
            data: _ctx.tree,
            props: _ctx.props,
            "expand-on-click-node": false,
            "check-strictly": true,
            "show-checkbox": _ctx.showCheck,
            "default-expand-all": "",
            onCheckChange: _ctx.handleCheckChange
          }, {
            default: _withCtx(({ data }) => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_tag, {
                  class: "node-tag",
                  type: "info"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(data.Name) + " / " + _toDisplayString(data.Code), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]),
            _: 1
          }, 8, ["data", "props", "show-checkbox", "onCheckChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}