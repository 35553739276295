
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyChargeService {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 
     */
    CompanyChargeId?: string;
    /**
     * 
     */
    ServiceRemarks?: string[];
    /**
     * 
     */
    CompanyId?: string;
}

/**
 * -表单校验
 */
export const DtoFormCompanyChargeServiceRules = {
}
