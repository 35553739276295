
import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";
import { ElMessage } from 'element-plus';
import { Options, Vue } from 'vue-class-component';
import { Emit, Model, Watch } from 'vue-property-decorator';
import moment from 'moment';
import OSS from 'ali-oss';

@Options({
    components: {
        VueCropper
    },
})
export default class MiezzUploadCropper extends Vue {
    @Model() modelValue: any;

    dialogVisible?: boolean = false;
    name = "";
    previews:any = {};
    option: any = {
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
        info: true,          //图片大小信息
        canScale: true,      //图片是否允许滚轮缩放
        autoCrop: true,      //是否默认生成截图框
        autoCropWidth: 230,  //默认生成截图框宽度
        autoCropHeight: 150, //默认生成截图框高度
        fixed: false,         //是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1], //截图框的宽高比例
        full: false,         //false按原比例裁切图片，不失真
        fixedBox: false,      //固定截图框大小，不允许改变
        canMove: false,      //上传图片是否可以移动
        canMoveBox: true,    //截图框能否拖动
        original: false,     //上传图片按照原始比例渲染
        centerBox: false,    //截图框是否被限制在图片里面
        height: true,        //是否按照设备的dpr 输出等比例图片
        infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000,    //限制图片最大宽度和高度
        enlarge: 1,          //图片根据截图框输出比例倍数
        mode: '230px 150px'  //图片默认渲染方式
    };
    random = 0;
client: any;
files: any;
    created() {
        this.client = new OSS({
            region: "oss-cn-beijing",
            accessKeyId: "LTAIuFDB1NiUNXPQ",
            accessKeySecret: "ArIn2kiTFr53bdKEVDfuWjTfA65b9C",
            bucket: "test-eqicheng",
            cname: true,
            endpoint: "testfile.eqicheng.cn",
        });
        this.onModelValueIsOpenChanged();
    }
    //初始化函数
    imgLoad(msg: any): void {
    //   console.log("工具初始化函数====="+msg);
    }
    //图片缩放
    changeScale (num: any): void {
      num = num || 1;
      const cropper = this.$refs.cropper as any;
      console.log("cropper", cropper)
      cropper.changeScale(num);
    }
    //向左旋转
    rotateLeft(): void {
      const cropper = this.$refs.cropper as any;
      cropper.rotateLeft();
    }
    //向右旋转
    rotateRight(): void {
      const cropper = this.$refs.cropper as any;
      cropper.rotateRight();
    }
    //实时预览函数
    realTime(data: any): void {
      this.previews = data;
    }
    //选择图片
    selectImg(e: any) {
        let file = e.target.files[0];
        if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
            ElMessage({
                message: '图片类型要求：jpeg、jpg、png',
                type: 'warning',
            });
            return false;
        }
        //转化为blob
        let reader = new FileReader();
        reader.onload = (e) => {
            let data;
            if (e.target) {
                if (e.target && typeof e.target.result === 'object') {
                    if (e.target.result) 
                        data = window.URL.createObjectURL(new Blob([e.target.result]));
                } else {
                    data = e.target.result;
                }
            }
            
            this.option.img = data
        }
        //转化为base64
        reader.readAsDataURL(file)
    }
    //上传图片
    uploadImg(type: any) {
        // let _this = this;
        if (type === 'blob') {
            //获取截图的blob数据
            const cropper = this.$refs.cropper as any;
            cropper.getCropBlob((data: any) => {
                let aTime = new Date().getTime(); //取时间戳，给文件命名
                let fileName = aTime + "." + data.type.substr(6); //给文件名加后缀
                let file = new window.File([data], fileName, { type: data.type }); //blob转file
                const time = moment().format("YYYY/MM/DD/h/mm/ss");
                const random = Math.random();
                // const path = `/files/${this.option.img}/${time}/${random}/${file.name}`;
                const path = `/files/${time}/${random}/${file.name}`;
                this.client?.put(path, file).then((r: any) => {
                    if (r.res.status == 200) {
                        ElMessage({
                            message: "上传成功！",
                            type: 'success',
                        });
                        this.getImg(path);
                        // this.$emit("update:modelValue", r.name);
                        this.handleClose();
                    } else {
                        ElMessage({
                            message: '上传失败！',
                            type: 'error',
                        });
                    }
                });
            })
        }
    }
    
    @Emit()
    handleClose(): void {
        this.option.img = "";
        // this.previews = {};
        //
    }
    @Emit()
    getImg(img: string): void {
        //
    }
    @Watch("modelValue.isOpen")
    onModelValueIsOpenChanged(nv?: string, ov?: string): void {
        if (nv != ov) {
            this.dialogVisible = this.modelValue.isOpen;
            // var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            // this.random += random;

        }
    }
}
