import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodePropertyBaseInfo from "./DtoFormCodePropertyBaseInfo";
import { EnumUiDynamicSearchItemType, EnumUiDynamicSearchItemTypeOption } from "./EnumUiDynamicSearchItemType";
import { EnumCompare, EnumCompareOption } from "./EnumCompare";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeDynamicSearchItem extends UiPageListSearch_DynamicSearchItem {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 引用
     */
    Quotes?: DtoFormCodePropertyBaseInfo[];
}

/**
 * -表单校验
 */
export const DtoFormCodeDynamicSearchItemRules = {
}

/**
 * -设置项
 */
export const DtoFormCodeDynamicSearchItemMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Quotes",
    Label: "引用",
    Type: EnumMiezzFormItemType.InputText,
  },
]
