import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchPaymentTermsContent from "../models.machine/DtoPageListSearchPaymentTermsContent";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListPaymentTermsContent from "../models.machine/DtoPageListPaymentTermsContent";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPaymentTermsContent from "../models.machine/DtoDetailPaymentTermsContent";
import DtoFormPaymentTermsContent from "../models.machine/DtoFormPaymentTermsContent";

/**
 * 付款条款内容
 * @description 自动生成的代码,请勿修改
 */
export default class PaymentTermsContentAppService {
    /**
     * 获取付款条款内容分页列表
     * @param {DtoPageListSearchPaymentTermsContent} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPaymentTermsContent>>} 
     */
    static Get(dto?: DtoPageListSearchPaymentTermsContent): AxiosPromise<RESTfulResult<DtoPageListPaymentTermsContent>> {
        const url = `/api/PaymentTermsContent/Get`;
        return service.get<RESTfulResult<DtoPageListPaymentTermsContent>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTermsContent/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取付款条款内容详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPaymentTermsContent>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPaymentTermsContent>> {
        const url = `/api/PaymentTermsContent/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPaymentTermsContent>>(url);
    }

    /**
     * 新增/编辑付款条款内容
     * @param {DtoFormPaymentTermsContent} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormPaymentTermsContent): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTermsContent/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除付款条款内容
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PaymentTermsContent/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PaymentTermsContent/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PaymentTermsContent/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PaymentTermsContent/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

}
