import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemProduct_CustomsCode from "./DtoListItemProduct_CustomsCode";
import DtoListItemProduct_Spec from "./DtoListItemProduct_Spec";

/**
 * 产品基础信息分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemProduct extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.SpecQuantity = 0;
        this.PackageQuantity = 0;
        this.StockQuantity = 0;
        this.SalesQuantity = 0;
        this.IfShelf = false;
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 海关编码
     */
    HsCode?: string;
    /**
     * 规格型号数量（种）
     */
    SpecQuantity?: number;
    /**
     * 包装（种）
     */
    PackageQuantity?: number;
    /**
     * 库存数量
     */
    StockQuantity?: number;
    /**
     * 销售数量
     */
    SalesQuantity?: number;
    /**
     * 状态
     */
    IfShelf?: boolean;
    /**
     * 中文计价单位
     */
    ChargeUnithZh?: string;
    /**
     * 英文计价单位
     */
    ChargeUnithEn?: string;
    /**
     * 其他国家的海关编码集合
     */
    CustomsCodes?: DtoListItemProduct_CustomsCode[];
    /**
     * 其他国家的海关编码集合
     */
    Specs?: DtoListItemProduct_Spec[];
}

/**
 * 产品基础信息分页列表-项-表单校验
 */
export const DtoListItemProductRules = {
}

/**
 * 产品基础信息分页列表-项-设置项
 */
export const DtoListItemProductMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "HsCode",
    Label: "海关编码",
    Customize: true,
  },
  {
    Prop: "SpecQuantity",
    Label: "规格型号数量（种）",
    Customize: true,
  },
  {
    Prop: "PackageQuantity",
    Label: "包装（种）",
  },
  {
    Prop: "StockQuantity",
    Label: "库存数量",
  },
  {
    Prop: "SalesQuantity",
    Label: "销售数量",
  },
  {
    Prop: "IfShelf",
    Label: "状态",
    Customize: true,
  },
]
