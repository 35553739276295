import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f55058fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 3 }
const _hoisted_3 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleGet: _ctx.handleGet
  }, {
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_TaskUserId')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.taskUsers,
            clearable: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : (model.Where.Prop == 'DynamicSearch_TypeName')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 1,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.EnumOrderTaskTypeOption,
              valueKey: "Data",
              clearable: ""
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : (model.Where.Prop == 'DynamicSearch_Status')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 2,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.EnumOrderTaskStatusOption,
                number: true,
                clearable: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : _createCommentVNode("", true)
    ]),
    column: _withCtx(({ model, row }) => [
      (model.Prop == 'BookingNo' && row.BookingNo)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            target: "_blank",
            to: `/order/pool/summary/${row.PoolId}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_text_ellipsis, {
                text: row.BookingNo
              }, null, 8, ["text"])
            ]),
            _: 2
          }, 1032, ["to"]))
        : (model.Prop == 'BookingNo')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "——"))
          : (model.Prop == 'Code' && row.Code)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                target: "_blank",
                to: `/order/summary/${row.OrderId}`
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_text_ellipsis, {
                    text: row.Code
                  }, null, 8, ["text"])
                ]),
                _: 2
              }, 1032, ["to"]))
            : (model.Prop == 'Code')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "——"))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "暂无数据"))
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleGet"]))
}