import OSS from "ali-oss";

export default class AliyunOSS {
    private static client?: OSS;
    static GetClient(): OSS {
        return this.client ?? new OSS({
            region: "oss-cn-beijing",
            accessKeyId: "LTAIuFDB1NiUNXPQ",
            accessKeySecret: "ArIn2kiTFr53bdKEVDfuWjTfA65b9C",
            bucket: "test-eqicheng",
            cname: true,
            endpoint: "testfile.eqicheng.cn",
        });
    }
}