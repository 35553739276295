import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheet_Cargo extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * L/φ（长度（最长边）/直径）（cm）
     */
    Length?: number;
    /**
     * W/φ（宽度（第二长边）/直径）（cm）
     */
    Width?: number;
    /**
     * H（高度/最短边）（cm）
     */
    Height?: number;
    /**
     * 单件毛重（kgs）
     */
    GrossWeight?: number;
    /**
     * 单件体积(m³)
     */
    Volume?: number;
    /**
     * 包装数量
     */
    Quantity?: number;
    /**
     * 包装单位
     */
    ChargeUnitEn?: string;
}

/**
 * -表单校验
 */
export const DtoFormPriceQuotationSheet_CargoRules = {
    ProductName: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ChargeUnitEn: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormPriceQuotationSheet_CargoMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ProductName",
    Label: "产品名称",
  },
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重（kgs）",
  },
  {
    Prop: "Volume",
    Label: "单件体积(m³)",
  },
  {
    Prop: "Quantity",
    Label: "包装数量",
  },
  {
    Prop: "ChargeUnitEn",
    Label: "包装单位",
  },
]

/**
 * -设置项
 */
export const DtoFormPriceQuotationSheet_CargoMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ProductName",
    Label: "产品名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Length",
    Label: "L/φ（长度（最长边）/直径）（cm）",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Width",
    Label: "W/φ（宽度（第二长边）/直径）（cm）",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Height",
    Label: "H（高度/最短边）（cm）",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重（kgs）",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Volume",
    Label: "单件体积(m³)",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Quantity",
    Label: "包装数量",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargeUnitEn",
    Label: "包装单位",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
