import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    model: _ctx.modelValue,
    rules: _ctx.rules,
    "label-width": "51px",
    size: "small"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "名称",
                prop: "Name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.modelValue.Name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue.Name) = $event)),
                    class: "input-text",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "注释",
                prop: "Summary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.modelValue.Summary,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.Summary) = $event)),
                    class: "input-text",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "基类",
                prop: "BaseType"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.modelValue.BaseType,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.BaseType) = $event)),
                    class: "input-text",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "属性",
                prop: "Properties"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_property_tree, {
                    modelValue: _ctx.modelValue.Properties,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.Properties) = $event)),
                    table: _ctx.table,
                    show: _ctx.show
                  }, null, 8, ["modelValue", "table", "show"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}