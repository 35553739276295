
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailOrderSummary, {
  DtoDetailOrderSummaryMiezzDetailItems,
} from "@/models.machine/DtoDetailOrderSummary";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoHistory from "@/models.machine/DtoHistory";
import OrderAppService from "@/apis.machine/OrderAppService";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailOrderSummary_Supplier, {
  DtoDetailOrderSummary_SupplierMiezzListItems,
} from "@/models.machine/DtoDetailOrderSummary_Supplier";
import DtoDetailOrderSummary_OrderPool, {
  DtoDetailOrderSummary_OrderPoolMiezzListItems,
} from "@/models.machine/DtoDetailOrderSummary_OrderPool";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import OrderSummaryTime from "../OrderSummaryTime.vue";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import OrderTask from "../OrderTask.vue";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoFormOrderCode from "@/models.machine/DtoFormOrderCode";
import OrderSupplier from "../OrderSupplier.vue";
import DtoDetailOrderPoolSummary_Supplier, {
  DtoDetailOrderPoolSummary_SupplierMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolSummary_Supplier";
import MiezzListItem from "@/models/MiezzListItem";

@Options({
  components: {
    OrderSummaryTime,
    OrderTask,
    OrderSupplier,
  },
})
export default class OrderSummary extends Vue {
  id = "";
  logier?: Logier;
  powerCodes?: string[] = [];
  menuTab = new MiezzMenuTab();
  detail = new MiezzForm<DtoDetailOrderSummary, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "edit-supplier",
      Label: "供应商",
      Icon: "edit",
      Type: "text",
      Size: "small",
      PowerCode: "OrderManagement_Order_Summary_DefaultSupplier",
    },
  ];
  btnMore: MiezzButton = {
    Code: "more",
    Title: "展开子目录",
    Label: " ",
    Icon: "DArrowRight",
    Type: "primary",
    Size: "small",
    Placement: "right",
    Options: [],
  };
  btnUpdateCode: MiezzButton = {
    Code: "update-code",
    Label: "编辑",
    Type: "text",
    Size: "small",
    PowerCode: "OrderManagement_Order_Summary_UpdateCode",
  };

  _codes: string[] = [];
  /**供应商信息-列表配置 */
  listSupplier = new MiezzPageList<
    any,
    DtoDetailOrderSummary_Supplier,
    string
  >();
  /**委托信息-列表配置 */
  listPool = new MiezzPageList<any, DtoDetailOrderSummary_OrderPool, string>();
  isShow?: boolean = true;
  histories: DtoHistory[] = [];
  EnumOrderStatus = EnumOrderStatus;
  btnTask: MiezzButton = {
    Code: "show-task",
    Label: "待办清单",
    Type: "text",
    Size: "small",
    PowerCode: "OrderManagement_Order_Task",
  };
  modalTask = new MiezzModal(MiezzModalType.Dialog);
  modalPoolTask = new MiezzModal(MiezzModalType.Dialog);
  poolId?: string = "";
  modalUpdateCode = new MiezzModal(MiezzModalType.Dialog);
  formUpdateCode = new MiezzForm<DtoFormOrderCode, string>();

  modalDefaultSupplier = new MiezzModal(MiezzModalType.Dialog);
  listDefaultSupplier = new MiezzPageList<
    any,
    DtoDetailOrderPoolSummary_Supplier,
    string
  >();
  showSupplier = false;
  scene?: string;

  /**创建时 */
  created(): void {
    this.id = this.$route.params.id as string;
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powerCodes = r ?? [];
    });

    this.menuTab.Back = true;
    this.menuTab.Url = `/order/summary/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "订单摘要",
        Url: `/order/summary/${this.id}`,
        Power: "OrderManagement_Order_Summary",
      },
    ];

    this.detail.DefaultSpan = 12;
    //详细-数据-初始化
    this.detail.Data = new DtoDetailOrderSummary();
    //详细-元素配置
    this.detail.Items = DtoDetailOrderSummaryMiezzDetailItems.filter(
      (it) => !this.logier?.DisabledReadSupplier || it.Prop != "Suppliers"
    );

    this.listSupplier.Fix = false;
    this.listSupplier.HideCard = true;
    this.listSupplier.EnabledDynamicSearch = false;
    this.listSupplier.EnabledRadioSearch = false;
    this.listSupplier.EnabledSelect = false;
    this.listSupplier.EnabledPagination = false;
    this.listSupplier.Items = JSON.copy(
      DtoDetailOrderPoolSummary_SupplierMiezzListItems
    );
    this.listSupplier.Data = {
      Items: [],
    };

    this.listDefaultSupplier.Fix = false;
    this.listDefaultSupplier.HideCard = true;
    this.listDefaultSupplier.EnabledDynamicSearch = false;
    this.listDefaultSupplier.EnabledRadioSearch = false;
    this.listDefaultSupplier.EnabledSelect = false;
    this.listDefaultSupplier.EnabledPagination = false;
    this.listDefaultSupplier.Items = (
      JSON.copy(
        DtoDetailOrderPoolSummary_SupplierMiezzListItems
      ) as MiezzListItem[]
    ).map((it) => {
      if (it.Prop != "ServiceContent") {
        it.Customize = true;
      }
      return it;
    });
    this.listDefaultSupplier.Data = {
      Items: [],
    };
    this.modalDefaultSupplier.Title = "供应商";
    this.modalDefaultSupplier.AlignCenter = true;
    this.modalDefaultSupplier.Height = "200px";
    this.modalDefaultSupplier.HandleClick = this.handleClick;
    this.modalDefaultSupplier.ButtonWidth = "100px";
    this.modalDefaultSupplier.Buttons = [
      {
        Code: "save-supplier",
        Label: "保存并更新",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listPool.Fix = false;
    this.listPool.HideCard = true;
    this.listPool.EnableIndex = true;
    this.listPool.EnabledDynamicSearch = false;
    this.listPool.EnabledRadioSearch = false;
    this.listPool.EnabledSelect = false;
    this.listPool.EnabledPagination = false;
    this.listPool.OperationWidth = "100px";
    this.listPool.Items = DtoDetailOrderSummary_OrderPoolMiezzListItems;
    this.listPool.Data = {
      Items: [],
    };
    this.listPool.RowButtons = [
      {
        Code: "show-pool-task",
        Label: "待办清单",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Pool_Task",
      },
    ];
    this.listPool.HandleBuildRowButton = (btn, row) => {
      if (btn.Code == "show-pool-task") {
        const model = JSON.copy(btn) as MiezzButton;
        model.Color = row.HasTask ? "rgb(254, 153, 91)" : "#409eff";
        return model;
      }
      return btn;
    };
    this.formUpdateCode.Rules = {
      Code: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          max: 64,
          message: "文本长度不能超过 64 个字符",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (value) {
              OrderAppService.CheckCodeExist({
                Id: this.id,
                Code: value,
              }).then((r) => {
                if (r.data.Data) {
                  return callback(new Error("该编号已存在，请重新填写"));
                } else {
                  callback();
                }
              });
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };
    this.modalUpdateCode.Title = "编辑";
    this.modalUpdateCode.AlignCenter = true;
    this.modalUpdateCode.Width = "700px";
    this.modalUpdateCode.Height = "50px";
    this.modalUpdateCode.HandleClick = this.handleClick;
    this.modalUpdateCode.Buttons = [
      {
        Code: "update-code-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetSummaryById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          if (this.listSupplier.Data)
            this.listSupplier.Data.Items = this.detail.Data.Suppliers ?? [];
          if (this.listPool.Data)
            this.listPool.Data.Items = this.detail.Data.Pools ?? [];
          this.getType();

          this.btnMore.Options = [
            {
              Code: "DeparturePortBooking",
              Label: "舱务信息",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_Booking",
            },
          ];
          this.btnTask.Color = this.detail.Data.HasTask
            ? "rgb(254, 153, 91)"
            : "#409eff";
          this._codes.push("DeparturePortBooking");
          //装货港-集装箱拖车
          if (
            //直订订单 并且 委托书FCL 并且有集装箱拖车
            (this.detail.Data.DirectOrder &&
              this.detail.Data.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortContainer
              )) ||
            //直订订单 并且 委托书FCL 并且有仓库没集装箱
            (this.detail.Data.DirectOrder &&
              this.detail.Data.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortWarehouse &&
                  !it.DeparturePortContainer
              )) ||
            //配载订单 订单FCL
            (this.detail.Data.StowageOrder &&
              this.detail.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL)
          ) {
            this._codes.push("DeparturePortContainer");
            this.btnMore.Options?.push({
              Code: "DeparturePortContainer",
              Label: "装货港-集装箱拖车",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_DeparturePortContainer",
            });
          }
          //装货港-快递 当订单中包含的任一委托书中勾选了装货港-提货-快递时
          if (this.detail.Data.Pools?.any((it) => it.DeparturePortExpress)) {
            this._codes.push("DeparturePortExpress");
            this.btnMore.Options?.push({
              Code: "DeparturePortExpress",
              Label: "装货港-快递",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_DeparturePortExpress",
            });
          }
          //装货港-干线物流 当订单中包含的任一委托书中勾选了装货港-提货-干线物流时
          if (this.detail.Data.Pools?.any((it) => it.DeparturePortTrunk)) {
            this._codes.push("DeparturePortTrunk");
            this.btnMore.Options?.push({
              Code: "DeparturePortTrunk",
              Label: "装货港-干线物流",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_DeparturePortTrunk",
            });
          }
          //装货港-报关 当订单中包含的任一委托书中勾选了装货港-报关时
          if (
            this.detail.Data.Pools?.any((it) => it.DeparturePortDeclareCustoms)
          ) {
            this._codes.push("DeparturePortDeclareCustoms");
            this.btnMore.Options?.push({
              Code: "DeparturePortDeclareCustoms",
              Label: "装货港-报关",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs",
            });
          }
          //装货港-仓库/堆场/码头 当订单中包含的任一委托书中勾选了装货港-提货-仓库/堆场/码头时
          if (this.detail.Data.Pools?.any((it) => it.DeparturePortWarehouse)) {
            this._codes.push("DeparturePortWarehouse");
            this.btnMore.Options?.push({
              Code: "DeparturePortWarehouse",
              Label: "装货港-仓库/堆场/码头",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf",
            });
          }
          //目的港-清关 当订单中包含的任一委托书中勾选了目的港-清关时
          if (
            this.detail.Data.Pools?.any((it) => it.DestinationPortClearCustoms)
          ) {
            this._codes.push("DestinationPortClearCustoms");
            this.btnMore.Options?.push({
              Code: "DestinationPortClearCustoms",
              Label: "目的港-清关",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance",
            });
          }
          //目的港-集装箱拖车
          if (
            //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
            (this.detail.Data.StowageOrder &&
              this.detail.Data.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortContainer
              )) ||
            //配载订单 并且 订单FCL 并且 委托书任意(LCL有仓库)
            (this.detail.Data.StowageOrder &&
              this.detail.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL &&
              this.detail.Data.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortWarehouse
              )) ||
            //直订订单 并且 有集装箱拖车
            (this.detail.Data.DirectOrder &&
              this.detail.Data.Pools?.any((it) => it.DestinationPortContainer))
          ) {
            this._codes.push("DestinationPortContainer");
            this.btnMore.Options?.push({
              Code: "DestinationPortContainer",
              Label: "目的港-集装箱拖车",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_DestinationPortContainer",
            });
          }
          //目的港-干线物流 当订单中包含的任一委托书中勾选了目的港-干线物流时
          if (this.detail.Data.Pools?.any((it) => it.DestinationPortTrunk)) {
            this._codes.push("DestinationPortTrunk");
            this.btnMore.Options?.push({
              Code: "DestinationPortTrunk",
              Label: "目的港-干线物流",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_DestinationPortTrunk",
            });
          }
          //目的港-快递 当订单中包含的任一委托书中勾选了目的港-快递时
          if (this.detail.Data.Pools?.any((it) => it.DestinationPortExpress)) {
            this._codes.push("DestinationPortExpress");
            this.btnMore.Options?.push({
              Code: "DestinationPortExpress",
              Label: "目的港-快递",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_DestinationPortExpress",
            });
          }
          //目的港-仓库/堆场/码头 当订单中包含的任一委托书中勾选了目的港-仓库/堆场/码头时
          if (
            this.detail.Data.Pools?.any((it) => it.DestinationPortWarehouse)
          ) {
            this._codes.push("DestinationPortWarehouse");
            this.btnMore.Options?.push({
              Code: "DestinationPortWarehouse",
              Label: "目的港-仓库/堆场/码头",
              Size: "small",
              PowerCode:
                "OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf",
            });
          }

          /*账单信息始终在最后 */
          this.btnMore.Options?.push({
            Code: "Bill",
            Label: "账单信息",
            Size: "small",
            PowerCode: "OrderManagement_Order_Summary_BillingInformation",
          });

          this.btnMore.Options?.push({
            Code: "File",
            Label: "附件",
            Size: "small",
            PowerCode: "OrderManagement_Order_Summary_File",
          });

          this.btnMore.Options.filter((it) =>
            this.detail.Data.TaskActives?.any((a) => a.Service == it.Code)
          ).forEach((it) => {
            it.Active = true;
          });

          const codes = this.btnMore.Options.map((it) => it.Code);
          this.listSupplier.Items = this.listSupplier.Items?.filter(
            (it) =>
              it.Prop == "ServiceContent" ||
              it.Prop == "DeparturePortBooking" ||
              codes.contains(it.Prop)
          );
          this.listDefaultSupplier.Items =
            this.listDefaultSupplier.Items?.filter(
              (it) =>
                it.Prop == "ServiceContent" ||
                it.Prop == "DeparturePortBooking" ||
                this._codes.contains(it.Prop)
            );
        }
      });
      OrderAppService.GetHistoriesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  type = "暂无数据";

  /**获取业务类型 */
  getType(): void {
    const direction = this.EnumTransportDirectionOption.firstOrDefault(
      (it) => it.Value == this.detail.Data.TransportDirection
    )?.Label;
    this.type = `【${direction}】`;
    if (this.detail.Data.TransportType) {
      const type = this.EnumTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.detail.Data.TransportType
      )?.Label;
      this.type += `【${type}】`;
    }
    if (this.detail.Data.TransportContainerLoad) {
      const containerLoad =
        this.EnumTransportContainerLoadOption.firstOrDefault(
          (it) => it.Value == this.detail.Data.TransportContainerLoad
        )?.Label;
      this.type += `【${containerLoad}】`;
    }
  }

  changeShow(): void {
    this.isShow = !this.isShow;
  }

  onClickLi(btn: MiezzButton): void {
    var taskNames = this.detail.Data.TaskActives?.firstOrDefault(
      (it) => it.Service == btn.Code
    )?.Types;
    switch (btn.Code) {
      //舱务信息
      case "DeparturePortBooking":
        this.$router.push({
          path: `/order/booking/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //装货港-集装箱拖车
      case "DeparturePortContainer":
        this.$router.push({
          path: `/order/departure-port-container/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //装货港-快递
      case "DeparturePortExpress":
        this.$router.push({
          path: `/order/departure-port-express/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //装货港-干线物流
      case "DeparturePortTrunk":
        this.$router.push({
          path: `/order/departure-port-trunk/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //装货港-报关
      case "DeparturePortDeclareCustoms":
        this.$router.push({
          path: `/order/departure/customs/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //装货港-仓库/堆场/码头
      case "DeparturePortWarehouse":
        this.$router.push({
          path: `/order/departure/wharf/${this.id}`,
          query: { task: taskNames, backUrl: this.$route.fullPath },
        });
        break;
      //目的港-清关
      case "DestinationPortClearCustoms":
        this.$router.push({
          path: `/order/destination/customs/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //目的港-集装箱拖车
      case "DestinationPortContainer":
        this.$router.push({
          path: `/order/destination-port-container/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //目的港-干线物流
      case "DestinationPortTrunk":
        this.$router.push({
          path: `/order/destination-port-trunk/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //目的港-快递
      case "DestinationPortExpress":
        this.$router.push({
          path: `/order/destination-port-express/${this.id}`,
          query: { task: taskNames },
        });
        break;
      //目的港-仓库/堆场/码头
      case "DestinationPortWarehouse":
        this.$router.push({
          path: `/order/destination/wharf/${this.id}`,
          query: { task: taskNames, backUrl: this.$route.fullPath },
        });
        break;
      case "Bill":
        this.$router.push({
          path: `/order/bill/${this.id}`,
          query: { task: taskNames, backUrl: this.$route.fullPath },
        });
        //window.open(
        //  `/#/order/bill/${this.id}` +
        //    "?task=" +
        //    taskNames +
        //    "&backUrl=" +
        //    this.$route.fullPath,
        //  "_blank"
        //);
        break;
      case "File":
        this.$router.push({
          path: `/order/file/${this.id}`,
        });
        break;
      default:
        break;
    }
  }

  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoDetailOrderSummary_OrderPool,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "show-pool-task":
        this.modalPoolTask.Show = true;
        this.poolId = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  onChangedUseThreeApiService(): void {
    OrderAppService.UseThreeApiService({
      Id: this.id,
      UseThreeApiService: this.detail.Data.UseThreeApiService,
    })
      .then(() => {
        this.handleGet();
        if (this.detail.Data.UseThreeApiService) {
          ElMessage.info("开通了接口服务后，由接口获取的信息将不可修改");
        } else {
          ElMessage.info("您关闭了该票信息的接口服务，由接口获取的信息请手动添加或修改");
        }
      })
      .catch(() => {
        this.handleGet();
      });
  }

  handleChooseSupplier(scene?: string): void {
    this.scene = scene;
    var supplierData =
      this.detail.Data.DefaultSuppliers?.firstOrDefault(
        (it) => it.Scene == scene
      ) ?? {};
    (this.$refs["ref-order-supplier"] as OrderSupplier).handleShow(
      supplierData
    );
  }

  handleSaveSupplier(data: any, complete: (s: boolean) => void): void {
    for (const code of this._codes) {
      if (data.Sync || code == this.scene) {
        let supplier: any = this.detail.Data.DefaultSuppliers?.firstOrDefault(
          (it) => it.Scene == code
        );
        if (!supplier) {
          supplier = { PoolId: this.id, Scene: code };
          supplier.SupplierSource = data.SupplierSource;
          supplier.SupplierCompanyTypeId = data.SupplierCompanyTypeId;
          supplier.SupplierCompanyTypeLabel = data.SupplierCompanyTypeLabel;
          supplier.SupplierCompanyId = data.SupplierCompanyId;
          supplier.SupplierCompanyLabel = data.SupplierCompanyLabel;
          supplier.SupplierCompanyContactLabel =
            data.SupplierCompanyContactLabel;
          this.detail.Data.DefaultSuppliers?.push(supplier);
        } else {
          supplier.SupplierSource = data.SupplierSource;
          supplier.SupplierCompanyTypeId = data.SupplierCompanyTypeId;
          supplier.SupplierCompanyTypeLabel = data.SupplierCompanyTypeLabel;
          supplier.SupplierCompanyId = data.SupplierCompanyId;
          supplier.SupplierCompanyLabel = data.SupplierCompanyLabel;
          supplier.SupplierCompanyContactLabel =
            data.SupplierCompanyContactLabel;
        }
      }
    }
    this.handleUpdateSupplier();
    complete(true);
  }

  handleUpdateSupplier(): void {
    var d: any = {
      ServiceContent: "供应商名称",
    };
    for (const item of this.listDefaultSupplier.Items ?? []) {
      if (item.Prop != "ServiceContent") {
        const supplier = this.detail.Data.DefaultSuppliers?.firstOrDefault(
          (it) => it.Scene == item.Prop
        );
        const value = [
          supplier?.SupplierCompanyLabel,
          supplier?.SupplierCompanyContactLabel,
        ]
          .filter((it) => it)
          .join("/");
        d[item.Prop ?? ""] = value ? value : "暂无数据";
      }
    }
    this.listDefaultSupplier.Data = {
      Items: [d],
    };
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit-supplier":
        if (this.detail.Data.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        this.handleUpdateSupplier();
        this.modalDefaultSupplier.Show = true;
        complete();
        break;
      case "save-supplier":
        if (this.detail.Data.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        OrderAppService.PostDefaultSupplier({
          Id: this.id,
          Update: true,
          DefaultSuppliers: this.detail.Data.DefaultSuppliers,
        })
          .then(() => {
            this.modalDefaultSupplier.Show = false;
            this.handleGet();
            ElMessage.success("操作成功");
            complete();
          })
          .catch(complete);
        break;
      case "update-code-submit":
        (this.$refs["ref-form-update-code"] as any).validate((v: boolean) => {
          if (v) {
            OrderAppService.UpdateCode(this.formUpdateCode.Data)
              .then(() => {
                ElMessage.success("编辑成功");
                this.handleGet();
                this.modalUpdateCode.Show = false;
                complete();
              })
              .catch(complete);
          }
        });
        complete();
        break;
      case "update-code":
        this.formUpdateCode.Data = {
          Id: this.id,
          Code: this.detail.Data.Code,
        };
        this.modalUpdateCode.Show = true;
        complete();
        break;
      case "show-task":
        this.modalTask.Show = true;
        complete();
        break;
      case "more":
        var taskNames = this.detail.Data.TaskActives?.firstOrDefault(
          (it) => it.Service == btn.Command
        )?.Types;
        switch (btn.Command) {
          //舱务信息
          case "DeparturePortBooking":
            this.$router.push({
              path: `/order/booking/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //装货港-集装箱拖车
          case "DeparturePortContainer":
            this.$router.push({
              path: `/order/departure-port-container/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //装货港-快递
          case "DeparturePortExpress":
            this.$router.push({
              path: `/order/departure-port-express/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //装货港-干线物流
          case "DeparturePortTrunk":
            this.$router.push({
              path: `/order/departure-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //装货港-报关
          case "DeparturePortDeclareCustoms":
            this.$router.push({
              path: `/order/departure/customs/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //装货港-仓库/堆场/码头
          case "DeparturePortWarehouse":
            this.$router.push({
              path: `/order/departure/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            complete();
            break;
          //目的港-清关
          case "DestinationPortClearCustoms":
            this.$router.push({
              path: `/order/destination/customs/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //目的港-集装箱拖车
          case "DestinationPortContainer":
            this.$router.push({
              path: `/order/destination-port-container/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //目的港-干线物流
          case "DestinationPortTrunk":
            this.$router.push({
              path: `/order/destination-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //目的港-快递
          case "DestinationPortExpress":
            this.$router.push({
              path: `/order/destination-port-express/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          //目的港-仓库/堆场/码头
          case "DestinationPortWarehouse":
            this.$router.push({
              path: `/order/destination/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            complete();
            break;
          case "Bill":
            //this.$router.push({
            //  path: `/order/bill/${this.id}`,
            //  query: { task: taskNames, backUrl: this.$route.fullPath },
            //});

            window.open(
              `/#/order/bill/${this.id}` +
                "?task=" +
                taskNames +
                "&backUrl=" +
                this.$route.fullPath,
              "_blank"
            );
            break;
          case "File":
            this.$router.push({
              path: `/order/file/${this.id}`,
            });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
