
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import moment from "moment";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import OrderDetailCompanyBusinessCard from "../../CompanyBusinessCard/OrderDetailCompanyBusinessCard.vue";
import OrderDetailProductsSummary from "../../ProductSummary/OrderDetailProductsSummary.vue";
import OrderDetailContainer from "../../Container/OrderDetailContainer.vue";
import OrderDetailCost from "../../Cost/OrderDetailCost.vue";
import OrderDetailLadingBill from "../../LadingBill/OrderDetailLadingBill.vue";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import DtoDetailOrderMainBill from "@/models.machine/DtoDetailOrderMainBill";
import MiezzButton from "@/models/MiezzButton";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import OrderAppService from "@/apis.machine/OrderAppService";
@Options({
  components: {
    OrderDetailCompanyBusinessCard,
    OrderDetailProductsSummary,
    OrderDetailContainer,
    OrderDetailCost,
    OrderDetailLadingBill,
  },
})
export default class OrderMainBillDetail extends Vue {
  logier?: Logier;
  id?: string;
  form = new MiezzForm<DtoDetailOrderMainBill, string>();
  topButtons: MiezzButton[] = [];

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    this.logier = CurrentLogier.GetLogier();
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderMainBillAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;

          this.topButtons = [
            {
              Code: "print-pdf",
              Label: "导出",
              // PdfName: `主单信息-${this.form.Data.OrderCode}`,
              Type: "primary",
              Size: "small",
            },
            {
              Code: "print",
              Label: "打印提单",
              Type: "primary",
              Size: "small",
              PowerCode: "OrderManagement_Order_Summary_Booking_Bill_Print",
            },
          ];
        }
      });
    }
  }

  formatTransportPaymentType(): string {
    return (
      EnumTransportPaymentTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.TransportPaymentType
      )?.Label ?? "暂无数据"
    );
  }

  formatGoodsTransportType(): string {
    return (
      EnumGoodsTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.GoodsTransportType
      )?.Label ?? "暂无数据"
    );
  }

  formatMonth(v?: Date): string {
    return v ? moment(v).format("YYYY-MM") : "暂无数据";
  }

  formatDate(v?: Date): string {
    return v ? moment(v).format("YYYY-MM-DD HH:mm") : "暂无数据";
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //打印提单
      case "print":
        this.$router.push({
          path: "/order/bill/print/" + this.id,
          query: {
            backUrl: this.$route.fullPath,
          },
        });
        complete();
        break;
      case "print-pdf":
        OrderAppService.ExportMainBillById(this.id)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
