import DtoFromInvoiceAnnex from "./DtoFromInvoiceAnnex";
import DtoFromInvoiceItem_CurrencySystem from "./DtoFromInvoiceItem_CurrencySystem";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailInvoice {
    constructor() {
        //无参构造函数
        this.BillObjectCount = 0;
    }

    [proName: string]: any;
    /**
     * 发票号码
     */
    Number?: string;
    /**
     * 
     */
    CollectionTitle?: string;
    /**
     * 
     */
    PaymentTitle?: string;
    /**
     * 
     */
    Remark?: string;
    /**
     * 
     */
    CurrencySystemId?: string;
    /**
     * 
     */
    Price?: number;
    /**
     * 开票日期
     */
    InvoiceDate?: Date;
    /**
     * 附件
     */
    Annexs?: DtoFromInvoiceAnnex[];
    /**
     * 发票号码是否已存在
     */
    IsInvoice?: boolean;
    /**
     * 账单主键
     */
    BillId?: string[];
    /**
     * 对账对象
     */
    BillObjectCount?: number;
    /**
     * 
     */
    CurrencySystem?: DtoFromInvoiceItem_CurrencySystem;
}

/**
 * -表单校验
 */
export const DtoDetailInvoiceRules = {
}
