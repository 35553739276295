import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchPlatformCompany from "../models.machine/DtoPageListSearchPlatformCompany";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListPlatformCompany from "../models.machine/DtoPageListPlatformCompany";
import DtoDetailPlatformCompany from "../models.machine/DtoDetailPlatformCompany";
import DtoFormPlatformCompany from "../models.machine/DtoFormPlatformCompany";

/**
 * 平台管理
 * @description 自动生成的代码,请勿修改
 */
export default class PlatformCompanyAppService {
    /**
     * 获取平台管理分页列表
     * @param {DtoPageListSearchPlatformCompany} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPlatformCompany>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPlatformCompany): AxiosPromise<RESTfulResult<DtoPageListPlatformCompany>> {
        const url = `/api/PlatformCompany/Get`;
        return service.get<RESTfulResult<DtoPageListPlatformCompany>>(url, { params: dto });
    }

    /**
     * 获取平台管理详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPlatformCompany>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPlatformCompany>> {
        const url = `/api/PlatformCompany/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPlatformCompany>>(url);
    }

    /**
     * 添加平台管理
     * @param {DtoFormPlatformCompany} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} Id
     */
    static Post(dto?: DtoFormPlatformCompany): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PlatformCompany/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
