import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchJoinPriceServiceTable from "../models.machine/DtoSelectSearchJoinPriceServiceTable";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoDetailJoinPriceServiceTable from "../models.machine/DtoDetailJoinPriceServiceTable";
import DtoFormJoinPriceServiceTable from "../models.machine/DtoFormJoinPriceServiceTable";
import UiDrag from "../models.machine/UiDrag";
import DtoListItemJoinPriceServiceTableSearchRelation from "../models.machine/DtoListItemJoinPriceServiceTableSearchRelation";

/**
 * 关联-价格服务/价格表
 * @description 自动生成的代码,请勿修改
 */
export default class JoinPriceServiceTableAppService {
    /**
     * 获取关联-价格服务/价格表选择器
     * @param {DtoSelectSearchJoinPriceServiceTable} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchJoinPriceServiceTable): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/JoinPriceServiceTable/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取关联-价格服务/价格表详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailJoinPriceServiceTable>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailJoinPriceServiceTable>> {
        const url = `/api/JoinPriceServiceTable/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailJoinPriceServiceTable>>(url);
    }

    /**
     * 校验是否存在
     * @param {string} [serviceId] 服务Id
     * @param {string} [id] 价格表Id
     * @param {string} [name] 名称
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 是否存在
     */
    static CheckExist(serviceId?: string, id?: string, name?: string): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/JoinPriceServiceTable/CheckExist`;
        const _querys: any = {};
        _querys.serviceId = serviceId
        _querys.id = id
        _querys.name = name
        return service.post<RESTfulResult<boolean>>(url, undefined, { params: _querys });
    }

    /**
     * 添加关联-价格服务/价格表
     * @param {DtoFormJoinPriceServiceTable} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormJoinPriceServiceTable): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/JoinPriceServiceTable/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinPriceServiceTable/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除关联-价格服务/价格表
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinPriceServiceTable/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取搜索关联
     * @param {string} [fromId] 价格表Id
     * @param {string} [toId] 关联的价格表Id
     * @returns {AxiosPromise<RESTfulResult<DtoListItemJoinPriceServiceTableSearchRelation[]>>} 搜索关联
     */
    static GetSearchRelationsByFromIdByToId(fromId?: string, toId?: string): AxiosPromise<RESTfulResult<DtoListItemJoinPriceServiceTableSearchRelation[]>> {
        const url = `/api/JoinPriceServiceTable/GetSearchRelations/${fromId}/${toId}`;
        return service.get<RESTfulResult<DtoListItemJoinPriceServiceTableSearchRelation[]>>(url);
    }

}
