
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import moment from "moment";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import OrderDetailCompanyBusinessCard from "../../CompanyBusinessCard/OrderDetailCompanyBusinessCard.vue";
import OrderDetailProductsSummary from "../../ProductSummary/OrderDetailProductsSummary.vue";
import OrderDetailContainer from "../../Container/OrderDetailContainer.vue";
import OrderDetailCost from "../../Cost/OrderDetailCost.vue";
import OrderDetailLadingBill from "../../LadingBill/OrderDetailLadingBill.vue";
import DtoDetailOrderMainBill from "@/models.machine/DtoDetailOrderMainBill";
import MiezzButton from "@/models/MiezzButton";
import DtoTabOrderPartBill from "@/models.machine/DtoTabOrderPartBill";
import OrderPartBillAppService from "@/apis.machine/OrderPartBillAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import HtmlToPdf from "@/apis/HtmlToPdf";
import MiezzCard from "@/components/MiezzCard.vue";
import OrderAppService from "@/apis.machine/OrderAppService";
@Options({
  components: {
    OrderDetailCompanyBusinessCard,
    OrderDetailProductsSummary,
    OrderDetailContainer,
    OrderDetailCost,
    OrderDetailLadingBill,
  },
})
export default class OrderPartBillDetail extends Vue {
  isOrderPartBillOrint?: boolean = true;
  logier?: Logier;
  orderId = "";
  id = "";
  tabs: DtoTabOrderPartBill[] = [];
  form = new MiezzForm<DtoDetailOrderMainBill, string>();
  topButtons: MiezzButton[] = [];

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    this.handleGet();
  }

  handleGet(): void {
    this.orderId = this.$route.params.id as string;
    OrderPartBillAppService.GetTabsById(this.orderId).then((r) => {
      this.tabs = r.data.Data ?? [];

      this.topButtons = [
        {
          Code: "print-pdf",
          Label: "导出",
          Type: "primary",
          Size: "small",
        },
        {
          Code: "print",
          Label: "打印提单",
          Type: "primary",
          Size: "small",
          PowerCode: "OrderManagement_Order_Summary_Booking_Bill_Print",
        },
      ];

      this.id = this.tabs.firstOrDefault()?.Id ?? "";
      this.handleGetTab();
    });
  }

  handleTabClick(): void {
    this.$nextTick(() => {
      this.handleGetTab();
    });
  }

  handleGetTab(): Promise<void> | undefined {
    if (this.id) {
      return OrderPartBillAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          return new Promise<void>((resolve, reject) => {
            resolve();
          });
        }
      });
    } else {
      return undefined;
    }
  }

  /**按钮点击 */
  async handleClick(btn: MiezzButton, complete: () => void): Promise<void> {
    switch (btn.Code) {
      case "print-pdf":
        OrderAppService.ExportPartBillsById(this.orderId)
          .then((r) => {
            for (const path of r.data.Data ?? []) {
              window.open(process.env.VUE_APP_BASE_URL + path, "_blank");
            }
            complete();
          })
          .catch(complete);
        break;
      //打印提单
      case "print":
        var id = this.$route.params.id as string | undefined;
        this.$router.push({
          path: "/order/bill/print/" + id,
          query: {
            orderPartBillId: this.id,
            backUrl: this.$route.fullPath,
          },
        });
        complete();
        break;
      default:
        break;
    }
  }

  formatTransportPaymentType(): string {
    return (
      EnumTransportPaymentTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.TransportPaymentType
      )?.Label ?? "暂无数据"
    );
  }

  formatGoodsTransportType(): string {
    return (
      EnumGoodsTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.GoodsTransportType
      )?.Label ?? "暂无数据"
    );
  }

  formatDate(v?: Date): string {
    return v ? moment(v).format("YYYY-MM-DD HH:mm") : "暂无数据";
  }
}
