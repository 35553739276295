import UiSelectOption from "./UiSelectOption";

/**
 * 供应商来源
 * @description 自动生成的代码,请勿修改
 */
export enum EnumSupplierSource {
    /**
     * 我的供应商
     */
    My = 1,
    /**
     * 公共供应商
     */
    Platform = 2,
    /**
     * 其他服务商
     */
    ServiceProvider = 3,
}

/**
 * 供应商来源-选项
 */
export const EnumSupplierSourceOption: UiSelectOption<number>[] = [
    { Label: "我的供应商", Value: 1, Data: "My" },
    { Label: "公共供应商", Value: 2, Data: "Platform" },
    { Label: "其他服务商", Value: 3, Data: "ServiceProvider" },
];
