import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "element-loading-background": "rgba(0, 0, 0, 0.5)",
  style: {"padding-top":"0px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
    [
      _directive_loading,
      _ctx.isLoading,
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}