import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrder_CreatePreStowageStackOrder {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * Port of  Loading/装货港Id
     */
    LoadingPortId?: string;
    /**
     * Port of  Loading/装货港
     */
    LoadingPortLabel?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * Port of Delivery/目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 客服Id
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
}

/**
 * -表单校验
 */
export const DtoRequestOrder_CreatePreStowageStackOrderRules = {
    Code: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    LoadingPortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DeliveryPortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    CustomerServiceLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}
