import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import DtoListItemOrder_OrderMainBill from "./DtoListItemOrder_OrderMainBill";
import DtoListItemOrder_OrderContainer from "./DtoListItemOrder_OrderContainer";

/**
 * 订单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderStowageStackAllocate extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.CargoQuantity = "/KGS/CBM";
        this.Containers = [];
    }

    [proName: string]: any;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 装货港ATD
     */
    ActualDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 目的港ATA
     */
    ActualArrivalDate?: Date;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 主单
     */
    MainBill?: DtoListItemOrder_OrderMainBill;
    /**
     * 集装箱信息
     */
    Containers?: DtoListItemOrder_OrderContainer[];
    /**
     * 客服Id
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceName?: string;
}

/**
 * 订单分页列表-项-表单校验
 */
export const DtoListItemOrderStowageStackAllocateRules = {
}

/**
 * 订单分页列表-项-设置项
 */
export const DtoListItemOrderStowageStackAllocateMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "订单编号",
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualDepartureDate",
    Label: "装货港ATD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualArrivalDate",
    Label: "目的港ATA",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
  },
  {
    Prop: "CustomerServiceName",
    Label: "客服",
  },
]
