import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_company_contact_detail = _resolveComponent("company-contact-detail")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_company_contact_detail, {
      detail: _ctx.detail,
      buttons: _ctx.buttons,
      path: "/supplier/company/private/contact"
    }, null, 8, ["detail", "buttons"])
  ]))
}