import { RouteRecordRaw } from 'vue-router'

import OrderStowageStackESLPageList from "./OrderStowageStackESLPageList.vue"
import OrderStowageStackEAPageList from "./OrderStowageStackEAPageList.vue"
import OrderStowageStackERLPageList from "./OrderStowageStackERLPageList.vue"
import OrderStowageStackISLPageList from "./OrderStowageStackISLPageList.vue"
import OrderStowageStackIAPageList from "./OrderStowageStackIAPageList.vue"
import OrderStowageStackIRLPageList from "./OrderStowageStackIRLPageList.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/order/stowage-stack/esl',
        name: 'OrderStowageStack_ESL',
        component: OrderStowageStackESLPageList,
        meta: {
            title: "配载栈 - 出口-海运-LCL"
        },
    },
    {
        path: '/order/stowage-stack/ea',
        name: 'OrderStowageStack_EA',
        component: OrderStowageStackEAPageList,
        meta: {
            title: "配载栈 - 出口-空运"
        },
    },
    {
        path: '/order/stowage-stack/erl',
        name: 'OrderStowageStack_ERL',
        component: OrderStowageStackERLPageList,
        meta: {
            title: "配载栈 - 出口-铁路-LCL"
        },
    },
    {
        path: '/order/stowage-stack/isl',
        name: 'OrderStowageStack_ISL',
        component: OrderStowageStackISLPageList,
        meta: {
            title: "配载栈 - 进口-海运-LCL"
        },
    },
    {
        path: '/order/stowage-stack/ia',
        name: 'OrderStowageStack_IA',
        component: OrderStowageStackIAPageList,
        meta: {
            title: "配载栈 - 进口-空运"
        },
    },
    {
        path: '/order/stowage-stack/irl',
        name: 'OrderStowageStack_IRL',
        component: OrderStowageStackIRLPageList,
        meta: {
            title: "配载栈 - 进口-铁路-LCL"
        },
    },
]

export default routes;