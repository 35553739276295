import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 服务分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCodeService extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 基类
     */
    BaseType?: string;
}

/**
 * 服务分页列表-项-表单校验
 */
export const DtoListItemCodeServiceRules = {
}

/**
 * 服务分页列表-项-设置项
 */
export const DtoListItemCodeServiceMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Group",
    Label: "分组",
  },
  {
    Prop: "App",
    Label: "应用",
  },
  {
    Prop: "Summary",
    Label: "注释",
  },
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "BaseType",
    Label: "基类",
  },
]
