import UiSelectOption from "./UiSelectOption";

/**
 * 菜单权限
 * @description 自动生成的代码,请勿修改
 */
export enum EnumMenuPower {
    /**
     * 所有人
     */
    Everyone = 0,
    /**
     * 需要登录
     */
    NeedLogin = 1,
    /**
     * 需要权限
     */
    NeedPower = 2,
    /**
     * 需要开发者权限
     */
    NeedDeveloper = 3,
}

/**
 * 菜单权限-选项
 */
export const EnumMenuPowerOption: UiSelectOption<number>[] = [
    { Label: "所有人", Value: 0, Data: "Everyone" },
    { Label: "需要登录", Value: 1, Data: "NeedLogin" },
    { Label: "需要权限", Value: 2, Data: "NeedPower" },
    { Label: "需要开发者权限", Value: 3, Data: "NeedDeveloper" },
];
