
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import DtoDetailProjectSite, {
  DtoDetailProjectSiteMiezzDetailItems,
} from "@/models.machine/DtoDetailProjectSite";
import ProjectSiteForm from "./ProjectSiteForm.vue";
import ExecCommand from "./ExecCommand.vue";
import ProjectSiteExecInfo from "./ProjectSiteExecInfo";

@Options({
  components: {
    ProjectSiteForm,
    ExecCommand,
  },
})
export default class ProjectSiteDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailProjectSite, string>();
  modalForm = new MiezzModal();
  modelExecCommand = new MiezzModal();
  exec = new ProjectSiteExecInfo();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailProjectSite();
    //详细-元素配置
    this.detail.Items = DtoDetailProjectSiteMiezzDetailItems;
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "exec-command",
        Label: "执行命令",
        Type: "default",
        Size: "small",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
      },
    ];
    this.handleGet();

    this.modelExecCommand.Width = "80%";
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      ProjectSiteAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
        this.exec.Id = this.id;
        this.exec.Name = this.detail.Data.Name;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑项目-站点-${this.detail.Data.Name}`;
        complete();
        break;
      case "exec-command":
        this.modelExecCommand.Show = true;
        this.modelExecCommand.Title = `项目-站点-${this.detail.Data.Name}-执行命令`;
        complete();
        break;
      case "delete":
        ProjectSiteAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
