import UiSelectOption from "./UiSelectOption";

/**
 * 仓库批量操作的类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumWharfBatchType {
    /**
     * 入库货量
     */
    Instock = 10,
    /**
     * 全部入库时间
     */
    AllInstock = 20,
    /**
     * 出库货量
     */
    Outstock = 30,
    /**
     * 全部出库时间
     */
    AllOutstock = 40,
    /**
     * 供应商
     */
    Supplier = 50,
}

/**
 * 仓库批量操作的类型-选项
 */
export const EnumWharfBatchTypeOption: UiSelectOption<number>[] = [
    { Label: "入库货量", Value: 10, Data: "Instock" },
    { Label: "全部入库时间", Value: 20, Data: "AllInstock" },
    { Label: "出库货量", Value: 30, Data: "Outstock" },
    { Label: "全部出库时间", Value: 40, Data: "AllOutstock" },
    { Label: "供应商", Value: 50, Data: "Supplier" },
];
