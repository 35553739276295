import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit";
import DtoDetailPriceQuotationSheetSourceItem_PriceQuotationSheetSupplier from "./DtoDetailPriceQuotationSheetSourceItem_PriceQuotationSheetSupplier";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheetSourceItem extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 费用Id
     */
    CostId?: string;
    /**
     * 费用名称
     */
    Cost?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost;
    /**
     * 币制Id
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem;
    /**
     * 单价
     */
    Amount?: number;
    /**
     * 单价
     */
    AmountLabel?: string;
    /**
     * 第一计价单位Id
     */
    FirstChargingUnitId?: string;
    /**
     * 计价单位
     */
    FirstChargingUnit?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit;
    /**
     * 数量
     */
    FirstChargingNumber?: number;
    /**
     * 第二计价单位Id
     */
    SecondChargingUnitId?: string;
    /**
     * 第二计价单位
     */
    SecondChargingUnit?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit;
    /**
     * 第二计价数量
     */
    SecondChargingNumber?: number;
    /**
     * 合计
     */
    Total?: number;
    /**
     * 说明
     */
    Description?: string;
    /**
     * 供应商
     */
    QuotationSheetSupplier?: DtoDetailPriceQuotationSheetSourceItem_PriceQuotationSheetSupplier;
}

/**
 * -表单校验
 */
export const DtoDetailPriceQuotationSheetSourceItemRules = {
}

/**
 * -设置项
 */
export const DtoDetailPriceQuotationSheetSourceItemMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Cost",
    Label: "费用名称",
  },
  {
    Prop: "CurrencySystem",
    Label: "币制",
    MaxWidth: "120px",
  },
  {
    Prop: "AmountLabel",
    Label: "单价",
    MaxWidth: "120px",
  },
  {
    Prop: "FirstChargingUnit",
    Label: "计价单位",
    MaxWidth: "120px",
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    MaxWidth: "120px",
  },
  {
    Prop: "Total",
    Label: "合计",
    MaxWidth: "120px",
  },
  {
    Prop: "Description",
    Label: "说明",
  },
  {
    Prop: "QuotationSheetSupplier",
    Label: "供应商",
  },
]
