
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import DtoFormFinance, {
        DtoFormFinanceMiezzFormItems,
        DtoFormFinanceRules,
    } from "@/models.machine/DtoFormFinance";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
    import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
    import UiSelectOption from "@/models.machine/UiSelectOption";


    export default class FiinanceForm extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() id?: string;
        @Prop() type?: number;

        //物流计价单位
        chargeUnits: UiSelectOption<string>[] = [];
        //费用名称
        costs: UiSelectOption<string>[] = [];
        //币制
        currencys: UiSelectOption<string>[] = [];
        //委托书
        pools: UiSelectOption<string>[] = [];

        form = new MiezzForm<DtoFormFinance, string>();

        created(): void {
            //表单-数据-初始化
            this.form.Data = new DtoFormFinance();
            //表单-校验
            this.form.Rules = DtoFormFinanceRules;
            //表单-元素配置
            this.form.Items = DtoFormFinanceMiezzFormItems;

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "submit",
                    Label: "提交",
                    Type: "primary",
                    Size: "small",
                },
            ];

            this.onRule();
            FinanceAppService.GetPools().then((pool) => {
                if (pool.data.Data) this.pools = pool.data.Data;
                ParamCurrencySystemAppService.GetSelect().then(currency => {
                    if (currency.data.Data) this.currencys = currency.data.Data;
                    ParamPriceCostAppService.GetSelect().then((cost) => {
                        if (cost.data.Data) this.costs = cost.data.Data;

                        ParamTransportChargeUnitAppService.GetSelect().then((unit) => {
                            if (unit.data.Data) this.chargeUnits = unit.data.Data;

                            if (this.form && this.form.Data) {
                                this.form.Data.Type = Number(this.type?.toString());
                                this.form.Data.FirstChargeUnit = undefined;
                                this.form.Data.CostName = undefined;
                                this.form.Data.CurrencySystemName = undefined;
                                this.form.Data.CurrencySystem = undefined;
                                this.form.Data.BookingNo = undefined;

                                for (var cur of this.currencys) {
                                    if (cur.Label && cur.Value) {
                                        if (cur.Label?.indexOf("人民币") >= 0) {
                                            this.form.Data.CurrencySystemId = cur.Value;
                                            this.form.Data.CurrencySystemName = cur.Data.Code;
                                            break;
                                        }
                                    }
                                }
                            }
                            this.handleGet();
                        })
                    })
                })
            })

        }

        onCurrency(currencyId: string): void {
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(currencyId);
        }
        onSelectCurrency(row: any): void {
            if (this.form && this.form.Data) {
                this.form.Data.CurrencySystemId = row.Value;
                this.form.Data.CurrencySystemName = row.Data.Code;
                this.form.Data.CurrencySystem = row.Data;
                MiezzForm.clearValidate(this.$refs, "ruleForm", "CurrencySystemId");
            }
        }

        onSupplier(row: any): void {
            const Supplier = this.$refs["Supplier"] as any;
            Supplier.onInit(row);
        }
        onSelectSupplier(row: any): void {
            if (this.form && this.form.Data) {
                this.form.Data.SupplierCompanyContactLabel = row.SupplierCompanyContactLabel;
                this.form.Data.SupplierCompanyId = row.SupplierCompanyId;
                this.form.Data.SupplierCompanyLabel = row.SupplierCompanyLabel;
                this.form.Data.SupplierCompanyTypeId = row.SupplierCompanyTypeId;
                this.form.Data.SupplierCompanyTypeLabel = row.SupplierCompanyTypeLabel;
                this.form.Data.SupplierSource = row.SupplierSource;
            }
        }

        querySearchCost(queryString: string, cb: any) {
            var restaurants = this.costs;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.costs) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.costs) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.costs) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.costs.length; i++) {
                            if (i < 10) {
                                results.push(this.costs[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCost(item: UiSelectOption<string>) {
            if (this.form && this.form.Data) {
                this.form.Data.CostId = item.Value;
                this.form.Data.CostName = item.Label;
                MiezzForm.clearValidate(this.$refs, "ruleForm", "CostId");
            }
        }
        onChangeCostId(): void {
            if (this.form && this.form.Data) {
                if (this.form.Data.CostName == undefined || this.form.Data.CostName == "") {
                    this.form.Data.CostId = undefined;
                }
                else {
                    if (this.form.Data.CostId == undefined) {
                        this.form.Data.CostName = undefined;
                    }
                    else if (this.form.Data.CostName != undefined) {
                        var flag = false;
                        if (this.costs) {
                            for (var it of this.costs) {
                                if (it.Label == this.form.Data.CostName) {
                                    this.form.Data.CostId = it.Value;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.form.Data.CostName = undefined;
                            }
                        }
                    }
                }

            }
        }

        querySearchPool(queryString: string, cb: any) {
            var restaurants = this.pools;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.pools) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.pools) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.pools) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.pools.length; i++) {
                            if (i < 30) {
                                results.push(this.pools[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectPoolId(): void {
            if (this.form && this.form.Data) {
                for (var item of this.pools) {
                    if (item.Value == this.form.Data.PoolId) {
                        this.form.Data.PoolId = item.Value;
                        this.form.Data.BookingNo = item.Label;
                        this.form.Data.ClientCompanyLabel = item.Data.ClientCompany;
                        this.form.Data.ClientCompanyId = item.Data.ClientCompanyId;
                        this.form.Data.OrderId = item.Data.OrderId;
                        MiezzForm.clearValidate(this.$refs, "ruleForm", "PoolId");
                    }
                }
            }
        }
        handleSelectPool(item: UiSelectOption<string>) {
            if (this.form && this.form.Data) {
                this.form.Data.PoolId = item.Value;
                this.form.Data.BookingNo = item.Label;
                this.form.Data.ClientCompanyLabel = item.Data.ClientCompany;
                this.form.Data.ClientCompanyId = item.Data.ClientCompanyId;
                this.form.Data.OrderId = item.Data.OrderId;
                MiezzForm.clearValidate(this.$refs, "ruleForm", "PoolId");
            }
        }
        onChangePool(): void {
            if (this.form && this.form.Data) {
                if (this.form.Data.BookingNo == undefined || this.form.Data.BookingNo == "") {
                    this.form.Data.PoolId = undefined;
                    this.form.Data.ClientCompanyLabel = undefined;
                    this.form.Data.ClientCompanyId = undefined;
                    this.form.Data.OrderId = undefined;
                }
                else {
                    if (this.form.Data.PoolId == undefined) {
                        this.form.Data.BookingNo = undefined;
                        this.form.Data.ClientCompanyLabel = undefined;
                        this.form.Data.ClientCompanyId = undefined;
                        this.form.Data.OrderId = undefined;
                    }
                    else if (this.form.Data.BookingNo != undefined) {
                        var flag = false;
                        if (this.pools) {
                            for (var it of this.pools) {
                                if (it.Label == this.form.Data.BookingNo) {
                                    this.form.Data.PoolId = it.Value;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.form.Data.BookingNo = undefined;
                            }
                        }
                    }
                }

            }
        }

        onChangeFirstChargeUnitNumber(index: number): void {
            if (this.form && this.form.Data) {
                var firstChargeUnitNumber = this.form.Data.FirstChargeUnitNumber;
                if (firstChargeUnitNumber != undefined) {
                    var result = setDecimal(firstChargeUnitNumber.toString(), 2, false, false);
                    if (result != "") {
                        this.form.Data.FirstChargeUnitNumber = Number(result);

                    }
                    else {
                        this.form.Data.FirstChargeUnitNumber = undefined;
                    }
                }
                this.form.Data.FirstChargeUnitValue = undefined;
                if (this.form.Data.FirstChargeUnitNumber != undefined && this.form.Data.FirstChargeUnitId != undefined) {
                    this.form.Data.FirstChargeUnitValue = this.form.Data.FirstChargeUnitNumber;
                    MiezzForm.clearValidate(this.$refs, "ruleForm", "FirstChargeUnitValue");
                }
            }
            this.onSum();
        }

        onChangeAccountPeriod(): void {
            if (this.form && this.form.Items) {
                var accountPeriod = this.form.Data.AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod.toString(), 0, true, false);
                    if (result != "") {
                        this.form.Data.AccountPeriod = Number(result);
                    }
                    else {
                        this.form.Data.AccountPeriod = undefined;
                    }
                }
            }
        }

        onChangeUnitPrice(): void {
            if (this.form && this.form.Data) {
                var unitPirce = this.form.Data.UnitPrice;
                if (unitPirce != undefined) {
                    var result = setDecimal(unitPirce.toString(), 2, true, true);
                    if (result != "") {
                        this.form.Data.UnitPrice = Number(result);
                        MiezzForm.clearValidate(this.$refs, "ruleForm", "UnitPrice");
                    }
                    else {
                        this.form.Data.UnitPrice = undefined;
                    }
                }
            }
            this.onSum();
        }
        onSum() {
            if (this.form && this.form.Data) {
                this.form.Data.TotalPrice = undefined;
                if (this.form.Data.UnitPrice != undefined && this.form.Data.FirstChargeUnitNumber != undefined) {
                    this.form.Data.TotalPrice = this.form.Data.UnitPrice * this.form.Data.FirstChargeUnitNumber;

                    var result = setDecimal(this.form.Data.TotalPrice.toString(), 2, true, true);
                    if (result != "") {
                        this.form.Data.TotalPrice = Number(result);
                    }
                    else {
                        this.form.Data.TotalPrice = undefined;
                    }
                }
            }
        }
        /*添加自定义验证 */
        onRule(): void {
            this.form.Rules.PoolId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.SupplierCompanyId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.CostId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.CurrencySystemId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.UnitPrice = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.FirstChargeUnitValue = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

        }

        /**查询表单数据 */
        handleGet(): void {
            if (this.id) {
                FinanceAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                });
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            FinanceAppService.Post(this.form.Data)
                                .then(() => {
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }


    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            if (isLessZero == true) {
                if (str[0] == "-") {
                    result = "-" + result;
                }
            }
            return result;
        }
    };
