import UiPageList from "./UiPageList";
import DtoPageListItemOverviewSaler from "./DtoPageListItemOverviewSaler";
import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";

/**
 * 账单分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOverviewSaler extends UiPageList<DtoPageListItemOverviewSaler, string> {
    constructor() {
        //无参构造函数
        super()
        this.Collection = [];
        this.Collectioned = [];
        this.Cost = [];
        this.Settlement = [];
        this.Interest = [];
        this.Payment = [];
        this.Actual = [];
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 应收合计
     */
    Collection?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CollectionString?: string;
    /**
     * 已收合计
     */
    Collectioned?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CollectionedString?: string;
    /**
     * 成本合计
     */
    Cost?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CostString?: string;
    /**
     * 结算价合计
     */
    Settlement?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    SettlementString?: string;
    /**
     * 利息合计
     */
    Interest?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    InterestString?: string;
    /**
     * 应发合计
     */
    Payment?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    PaymentString?: string;
    /**
     * 实发合计
     */
    Actual?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    ActualString?: string;
}

/**
 * 账单分页列表-表单校验
 */
export const DtoPageListOverviewSalerRules = {
}
