import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock("div", { key: _ctx.type }, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
      onHandleRowDbClick: _ctx.handleRowDbClick,
      onHandleBracketsBuild: _ctx.handleBracketsBuild
    }, {
      "dynamic-search-customize": _withCtx(({ model }) => [
        (model.Where.Prop == 'DynamicSearch_Online')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              boolean: true,
              options: [
            { Label: '在线', Value: true },
            { Label: '离线', Value: false },
          ]
            }, null, 8, ["modelValue", "onUpdate:modelValue"]))
          : (model.Where.Prop == 'DynamicSearch_CompanyUserStatus')
            ? (_openBlock(), _createBlock(_component_miezz_select, {
                key: 1,
                modelValue: model.Where.Value,
                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                options: _ctx.EnumJoinCompanyUserStatusOption
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
            : (model.Where.Prop == 'DynamicSearch_UserStatus')
              ? (_openBlock(), _createBlock(_component_miezz_select, {
                  key: 2,
                  modelValue: model.Where.Value,
                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                  options: _ctx.EnumUserStatusOption
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
              : (model.Where.Prop == 'DynamicSearch_CompanyId')
                ? (_openBlock(), _createBlock(_component_miezz_select, {
                    key: 3,
                    modelValue: model.Where.Value,
                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                    options: _ctx.companies,
                    labelKey: "NameZh",
                    valueKey: "Id"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                : (
            model.Where.Prop == 'DynamicSearch_ServiceProviderCompanyId'
          )
                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                      key: 4,
                      modelValue: model.Where.Value,
                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                      options: _ctx.serviceProviders,
                      labelKey: "NameZh",
                      valueKey: "Id"
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                  : (model.Where.Prop == 'DynamicSearch_Role')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 5,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.roles
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : _createCommentVNode("", true)
      ]),
      column: _withCtx((scoped) => [
        (scoped.model.Prop == 'UserPhoneNumber')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(scoped.row.UserPhoneNumber), 1),
              _createVNode(_component_miezz_button, {
                model: _ctx.btnLoginLog,
                onHandleClick: 
              (arg1, arg2) => _ctx.handleRowButtonClick(arg1, scoped.row, arg2)
            
              }, null, 8, ["model", "onHandleClick"])
            ]))
          : _createCommentVNode("", true),
        (scoped.model.Prop == 'JoinCompanyUserGroups')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (
              scoped.row.JoinCompanyUserGroups &&
              scoped.row.JoinCompanyUserGroups.length > 0
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scoped.row.JoinCompanyUserGroups.map((it) => it.Group?.Name).join(
                "，"
              )), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleRowDbClick", "onHandleBracketsBuild"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalLoginLog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalLoginLog) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.listLoginLog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listLoginLog) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}