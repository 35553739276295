
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzMenuItem from "@/models/MiezzMenuItem";
import MiezzBreadcrumb from "@/models/MiezzBreadcrumb";
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { onBeforeRouteUpdate, RouteLocationNormalizedLoaded } from "vue-router";
export default class IndexBreadcrumb extends Vue {
  /**菜单 */
  menus?: MiezzMenuItem[];
  /**面包屑 */
  breadcrumbs = CurrentLogier.GetBreadcrumbs();

  /**创建时 */
  created(): void {
    this.onBeforeRouteUpdate(this.$route);
    onBeforeRouteUpdate(this.onBeforeRouteUpdate);
  }

  /**
   * 路由更新时
   */
  async onBeforeRouteUpdate(to: RouteLocationNormalizedLoaded): Promise<void> {
    const menus = await CurrentLogier.GetMenus();
    this.menus = menus?.Menus;

    this.breadcrumbs = CurrentLogier.GetBreadcrumbs();
    //当前路由菜单
    const menu = this.menus?.firstOrDefault(
      (it) =>
        it.Url == to.path ||
        (to.matched &&
          to.matched.length > 0 &&
          to.matched.any((m) => m.path != "/" && m.path == it.Url))
    );
    if (menu) {
      //当前面包屑
      let breadcrumb = this.breadcrumbs.firstOrDefault(
        (it) => it.id == menu.Id
      );
      if (breadcrumb) {
        //删除当前面包屑之后的面包屑
        const index = this.breadcrumbs.indexOf(breadcrumb);
        if (this.breadcrumbs.length > 1) this.breadcrumbs.splice(index + 1);
      } else {
        breadcrumb = new MiezzBreadcrumb();
        breadcrumb.id = menu.Id;
        breadcrumb.label = menu.Name;
        this.loopAddMenuParent(menu);
        this.breadcrumbs.push(breadcrumb);
      }
      breadcrumb.to = to;
      CurrentLogier.SetBreadcrumbs(this.breadcrumbs);
    } else {
      console.log(
        403,
        to,
        this.menus?.filter((it) => it.Url?.contains("summary"))
      );
      router.push("/403");
    }

    if (this.breadcrumbs && this.breadcrumbs.length > 0) {
      const id = this.breadcrumbs[this.breadcrumbs.length - 1].id;
      let menus: MiezzMenuItem[] = [];
      this.loopFullMenus(menus, id);
      menus = menus.reverse();
      menus.forEach((ele, index) => {
        if (ele && ele.Id) {
          if (index === 0) sessionStorage.setItem("activeOne", ele.Id);
          else if (index === 1) sessionStorage.setItem("activeTwo", ele.Id);
          else if (index === 2) sessionStorage.setItem("activeThree", ele.Id);
        }
      });
    }
  }

  loopFullMenus(menus: MiezzMenuItem[], id?: string): void {
    if (id) {
      const menu = this.menus?.firstOrDefault((it) => it.Id == id);
      if (menu) {
        menus.push(menu);
        this.loopFullMenus(menus, menu.ParentId);
      }
    }
  }

  /**
   * 自动追加父菜单
   */
  loopAddMenuParent(menu: MiezzMenuItem): void {
    if (menu.ParentId) {
      const parent = this.menus?.firstOrDefault((it) => it.Id == menu.ParentId);
      if (parent) {
        var breadcrumb = this.breadcrumbs.firstOrDefault(
          (it) => it.id == parent.Id
        );
        if (!breadcrumb) {
          this.loopAddMenuParent(parent);
          const breadcrumb = new MiezzBreadcrumb();
          breadcrumb.id = parent.Id;
          breadcrumb.label = parent.Name;
          if (parent.Url) {
            let url = parent.Url;
            for (const key in this.$route.params) {
              url = url?.replace(`:${key}`, this.$route.params[key] as string);
            }
            breadcrumb.to = { path: url };
          }

          this.breadcrumbs.push(breadcrumb);
        }
      }
    }
  }
}
