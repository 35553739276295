import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodeProperty from "./DtoFormCodeProperty";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeDtoCustom {
    constructor() {
        //无参构造函数
        this.Properties = [];
    }

    [proName: string]: any;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 表
     */
    Table?: string;
    /**
     * Id类型
     */
    IdType?: string;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 父类
     */
    BaseType?: string;
    /**
     * 属性
     */
    Properties?: DtoFormCodeProperty[];
}

/**
 * -表单校验
 */
export const DtoFormCodeDtoCustomRules = {
    Group: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    App: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    IdType: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Summary: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    BaseType: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCodeDtoCustomMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Group",
    Label: "分组",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "App",
    Label: "应用",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Table",
    Label: "表",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "IdType",
    Label: "Id类型",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Summary",
    Label: "注释",
    Span: 8,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "名称",
    Span: 8,
    Customize: true,
  },
  {
    Prop: "BaseType",
    Label: "父类",
    Span: 8,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Properties",
    Label: "属性",
    Span: 24,
    Customize: true,
  },
]
