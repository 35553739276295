
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import OrderAppService from "@/apis.machine/OrderAppService";
import DtoPageListSearchOrder, {
  DtoPageListSearchOrderDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrder";
import DtoPageListOrder from "@/models.machine/DtoPageListOrder";
import DtoListItemOrder, {
  DtoListItemOrderMiezzListItems,
} from "@/models.machine/DtoListItemOrder";
import MiezzForm from "@/models/MiezzForm";
import DtoRequestOrder_CreatePreStowageStackOrder from "@/models.machine/DtoRequestOrder_CreatePreStowageStackOrder";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import {
  EnumOrderStatus,
  EnumOrderStatusOption,
} from "@/models.machine/EnumOrderStatus";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DtoRequestOrderAssignCustomerService from "@/models.machine/DtoRequestOrderAssignCustomerService";
import DtoDeleteOrder from "@/models.machine/DtoDeleteOrder";
import DtoPutOrderMainBillBusinessCycle from "@/models.machine/DtoPutOrderMainBillBusinessCycle";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import OrderProcess from "./OrderProcess.vue";
import OrderTask from "../OrderTask.vue";
import OrderPoolChooseService from "../Pool/OrderPoolChooseService.vue";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import ListMarkColorAppService from "@/apis.machine/ListMarkColorAppService";
import { EnumContainerTrailerStatusOption } from "@/models.machine/EnumContainerTrailerStatus";
import { EnumIncomingGoodsStatusOption } from "@/models.machine/EnumIncomingGoodsStatus";
import { EnumBookingStatusOption } from "@/models.machine/EnumBookingStatus";
import { EnumCustomsStatusOption } from "@/models.machine/EnumCustomsStatus";
import { EnumExpressStatusOption } from "@/models.machine/EnumExpressStatus";
import { EnumTrunkStatusOption } from "@/models.machine/EnumTrunkStatus";

@Options({
  components: {
    OrderPoolChooseService,
    OrderProcess,
    OrderTask,
  },
})
export default class OrderPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<DtoPageListSearchOrder, DtoListItemOrder, string>(
    "OrderManagement_Order"
  );

  /**当前Id */
  id?: string = "";
  cancel = false;
  logier?: Logier;
  powers: string[] = [];
  colors: UiSelectOptionWithStringValue[] = [];
  clients: UiSelectOption<string>[] = [];
  supplierCompanies: UiSelectOption<string>[] = [];
  _supplierCompanies: UiSelectOption<string>[] = [];
  serviceRemarks: UiSelectOptionWithStringValue[] = [];
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];
  deliveryPlaces: UiSelectOptionWithStringValue[] = [];
  shipNames: UiSelectOption<string>[] = [];
  voyageNumbers: UiSelectOption<string>[] = [];
  flights: UiSelectOption<string>[] = [];
  railwayNumbers: UiSelectOption<string>[] = [];
  carriers: UiSelectOption<string>[] = [];
  customerServices: UiSelectOption<string>[] = [];
  taskUsers: UiSelectOption<string>[] = [];
  searchSalesmans: UiSelectOption<string>[] = [];
  EnumOrderStatus = EnumOrderStatus;
  EnumOrderStatusOption = EnumOrderStatusOption;
  types: UiSelectOptionWithStringValue[] = [
    {
      Label: "配载订单",
      Value: "True",
    },
    {
      Label: "直订订单",
      Value: "False",
    },
  ];

  EnumSubmitStatusOption = [
    {
      Value: "1",
      Label: "未录入",
    },
    {
      Value: "10",
      Label: "已全部提交",
    },
    {
      Value: "20",
      Label: "未全部提交",
    },
    {
      Value: "30",
      Label: "已全部核销",
    },
    {
      Value: "40",
      Label: "未全部核销",
    },
  ];

  EnumSubmitStatusOptionSaler = [
    {
      Value: "1",
      Label: "未录入",
    },
    {
      Value: "10",
      Label: "已全部提交",
    },
    {
      Value: "20",
      Label: "未全部提交",
    },
  ];

  EnumSettlementStatusOption = [
    {
      Value: "10",
      Label: "已全部核销",
    },
    {
      Value: "20",
      Label: "未全部核销",
    },
  ];

  modalBeforeAdd = new MiezzModal(MiezzModalType.Dialog);
  modalBeforeAddData: {
    Type?: string;
  } = {};
  modalBeforeAddRules = {
    Type: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
  };
  modalChooseService = new MiezzModal(MiezzModalType.Dialog);
  modalAdd = new MiezzModal(MiezzModalType.Dialog);
  form = new MiezzForm<DtoRequestOrder_CreatePreStowageStackOrder, string>();
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  formLoadingPorts: UiSelectOption<string>[] = [];
  formDeliveryPorts: UiSelectOption<string>[] = [];

  modalAssignCustomerService = new MiezzModal(MiezzModalType.Dialog);
  modalAssignCustomerServiceData = new DtoRequestOrderAssignCustomerService();
  modalAssignCustomerServiceRules = {
    CustomerServiceId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };
  putCustomerServices: UiSelectOption<string>[] = [];

  modalPutBusinessCycle = new MiezzModal(MiezzModalType.Dialog);
  modalPutBusinessCycleData = new DtoPutOrderMainBillBusinessCycle();
  modalPutBusinessCycleRules = {
    BusinessCycle: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };

  modalProcess = new MiezzModal();
  modalTask = new MiezzModal(MiezzModalType.Dialog);
  containerTypes: UiSelectOption<string>[] = [];
  containerNos: UiSelectOptionWithStringValue[] = [];
  companySources: UiSelectOptionWithStringValue[] = [];

  EnumBookingStatusOption = EnumBookingStatusOption;
  EnumCustomsStatusOption = EnumCustomsStatusOption;
  EnumDeparturePortContainerTrailerStatusOption =
    EnumContainerTrailerStatusOption;
  EnumDestinationPortContainerTrailerStatusOption: UiSelectOption<number>[] = [
    { Label: "待提柜", Value: 1, Data: "WaitTake" },
    { Label: "提柜中", Value: 2, Data: "IngTake" },
    { Label: "已提柜", Value: 3, Data: "EdTake" },
    { Label: "卸货中", Value: 4, Data: "IngLoad" },
    { Label: "已卸货", Value: 5, Data: "EdLoad" },
    { Label: "还柜中", Value: 6, Data: "IngPort" },
    { Label: "已还柜", Value: 7, Data: "EdPort" },
  ];
  EnumExpressStatusOption = EnumExpressStatusOption;
  EnumTrunkStatusOption = EnumTrunkStatusOption;
  EnumIncomingGoodsStatusOption = EnumIncomingGoodsStatusOption;

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
    });
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchOrder();
    this.list.DisabledRadioSearchRefresh = true;
    this.list.RadioSearchItems = EnumOrderStatusOption;

    //列表-动态搜索-项
    const dynamicSearchItems = DtoPageListSearchOrderDynamicSearchItems.filter(
      (it) => {
        if (this.logier?.CompanyType == EnumCompanyType.Customer) {
          return (
            it.Prop != "DynamicSearch_CollectionSubmitStatus" &&
            it.Prop != "DynamicSearch_PaymentSubmitStatus" &&
            it.Prop != "DynamicSearch_SalerSubmitStatus"
          );
        } else {
          return it;
        }
      }
    );
    //列表-动态搜索-项
    this.list.DynamicSearchItems = dynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListOrder();
    //列表-列配置
    this.list.Items = DtoListItemOrderMiezzListItems;
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "OrderManagement_Order_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Order_Summary",
      },
      {
        Code: "task",
        Title: "待办",
        Icon: "document",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Order_Summary",
      },
      {
        Code: "process",
        Title: "进程",
        Icon: "location",
        Type: "default",
        Size: "small",
        PowerCode: "OrderManagement_Order_Process",
      },
    ];
    //列表-底部按钮
    this.list["_BottomButtons"] = [
      {
        Code: "mark",
        Label: "标记",
        Type: "color",
        Size: "small",
      },
      {
        Code: "assign-customer-service",
        Label: "指派",
        MiezzIcon: "assign",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Order_CustomerService",
      },
      {
        Code: "delete",
        Label: "取消",
        Icon: "delete",
        Type: "text",
        Size: "small",
        Message: "是否确认取消",
        PowerCode: "OrderManagement_Order_Delete",
      },
      {
        Code: "put-business-cycle",
        Label: "业务周期",
        Icon: "edit",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Order_PutBusinessCycle",
      },
      {
        Code: "lock",
        Label: "锁定",
        Icon: "lock",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Order_Lock",
      },
      {
        Code: "unlock",
        Label: "解锁",
        Icon: "unlock",
        Type: "text",
        Size: "small",
        PowerCode: "OrderManagement_Order_Lock",
      },
    ];
    this.list.CheckRowButtonShow = (btn, row) => {
      switch (btn.Code) {
        case "delete":
          return (
            row.Status != EnumOrderStatus.Canceled &&
            row.Status != EnumOrderStatus.Completed
          );
        default:
          break;
      }
      return true;
    };
    this.list.HandleBuildRowButton = (btn, row) => {
      if (btn.Code == "task") {
        const model = JSON.copy(btn) as MiezzButton;
        model.Type = row.HasTask ? "warning" : "default";
        model.Plain = row.HasTask;
        return model;
      }
      return btn;
    };
    this.onRadioSearchChanged();

    this.modalAssignCustomerService.Title = "指派客服";
    this.modalAssignCustomerService.Height = "100px";
    this.modalAssignCustomerService.HandleClick = this.handleBottomButtonClick;
    this.modalAssignCustomerService.Buttons = [
      {
        Code: "assign-customer-service-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalPutBusinessCycle.Title = "编辑业务所属周期";
    this.modalPutBusinessCycle.Height = "100px";
    this.modalPutBusinessCycle.HandleClick = this.handleBottomButtonClick;
    this.modalPutBusinessCycle.Buttons = [
      {
        Code: "assign-put-business-cycle-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalBeforeAdd.Title = "添加";
    this.modalBeforeAdd.Width = "700px";
    this.modalBeforeAdd.Height = "150px";
    this.modalBeforeAdd.HandleClick = this.handleTopButtonClick;
    this.modalBeforeAdd.Buttons = [
      {
        Code: "choose-add-type",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalAdd.Title = "添加";
    this.modalAdd.Width = "700px";
    this.modalAdd.Height = "250px";
    this.modalAdd.HandleClick = this.handleTopButtonClick;
    this.modalAdd.Buttons = [
      {
        Code: "add-pre",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalProcess.Title = "进程";
    this.modalProcess.Width = "100%";
    this.modalProcess.DisabledCloseButton = true;

    this.form.Data = new DtoRequestOrder_CreatePreStowageStackOrder();
    this.form.Rules = {
      Code: [
        {
          max: 64,
          message: "文本长度不能超过 64 个字符",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (value) {
              OrderAppService.CheckCodeExist({
                Id: this.id,
                Code: value,
              }).then((r) => {
                if (r.data.Data) {
                  return callback(new Error("该编号已存在，请重新填写"));
                } else {
                  callback();
                }
              });
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      TransportDirection: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      TransportType: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      TransportContainerLoad: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      LoadingPortId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      DeliveryPortId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      CustomerServiceId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
    };

    OrderAppService.GetColors().then((r) => {
      this.colors = r.data.Data ?? [];
    });
    OrderAppService.GetClientUsers().then((r) => {
      this.clients = r.data.Data ?? [];
    });
    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Supplier }).then(
      (r) => {
        this.supplierCompanies = r.data.Data ?? [];
        this.filterSupplierCompanies();
      }
    );
    OrderPoolAppService.GetServiceRemarkSelect().then((r) => {
      this.serviceRemarks = r.data.Data ?? [];
    });
    OrderAppService.GetDeliveryPorts().then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    OrderAppService.GetDeliveryPlaces().then((r) => {
      this.deliveryPlaces = r.data.Data ?? [];
    });
    OrderAppService.GetShipNames().then((r) => {
      this.shipNames = r.data.Data ?? [];
    });
    OrderAppService.GetVoyageNumbers().then((r) => {
      this.voyageNumbers = r.data.Data ?? [];
    });
    OrderAppService.GetFlights().then((r) => {
      this.flights = r.data.Data ?? [];
    });
    OrderAppService.GetRailwayNumbers().then((r) => {
      this.railwayNumbers = r.data.Data ?? [];
    });
    OrderAppService.GetCarriers().then((r) => {
      this.carriers = r.data.Data ?? [];
    });
    OrderAppService.GetCustomerServices().then((r) => {
      this.customerServices = r.data.Data ?? [];
    });
    OrderAppService.GetTaskUsers(this.list.Dto).then((r) => {
      this.taskUsers = r.data.Data ?? [];
    });
    OrderAppService.GetSalesmans().then((r) => {
      this.searchSalesmans = r.data.Data ?? [];
    });
    JoinCompanyUserAppService.GetSelect({
      CompanyId: this.logier?.CompanyId,
      PowerCodes: ["IdentityPermission_CustomerService"],
    }).then((r) => {
      this.putCustomerServices = r.data.Data ?? [];
    });
    OrderAppService.GetContainerTypeSelect().then((r) => {
      this.containerTypes = r.data.Data ?? [];
    });
    OrderAppService.GetContainerNoSelect().then((r) => {
      this.containerNos = r.data.Data ?? [];
    });
    CompanyAppService.GetSources().then((r) => {
      this.companySources = r.data.Data ?? [];
    });

    this.modalTask.ButtonWidth = "100px";
  }

  /**查询列表 */
  handleGet(): void {
    OrderAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
      if (r.data.Extras?.NoPowerMessage) {
        ElMessage.warning(r.data.Extras?.NoPowerMessage);
      }
    });
  }

  beforeRadioSearch?: string;
  handleRadioSearch(): void {
    const pageList = this.$refs["ref-miezz-page-list"] as any;
    if (
      this.list.Dto?.RadioSearch == undefined &&
      this.list.Dto?.OpenSearch == "true" &&
      this.beforeRadioSearch != undefined
    ) {
      DynamicSearchAppService.Post({
        Code: "OrderManagement_Order",
        Brackets: [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Status",
                  Compare: EnumCompare.Equal,
                  Value: this.beforeRadioSearch.toString(),
                },
              },
            ],
          },
        ],
      }).then((r) => {
        if (this.list.Dto) {
          this.list.Dto.DynamicSearchId = r.data.Data;
          pageList.handleGet();
        }
      });
    } else {
      pageList.handleGet();
    }
    this.beforeRadioSearch = this.list.Dto?.RadioSearch;
  }

  filterSupplierCompanies(queryString?: string, value?: string): void {
    console.log(queryString);

    if (this.supplierCompanies && this.supplierCompanies.length > 0) {
      if (queryString) {
        this._supplierCompanies = this.supplierCompanies.filter((it) =>
          (it.NameZh + (it.NameEn ? "/" + it.NameEn : ""))?.contains(
            queryString
          )
        );
        if (this._supplierCompanies && this._supplierCompanies.length > 10)
          this._supplierCompanies = this._supplierCompanies.slice(0, 10);
      } else if (value) {
        this._supplierCompanies = this.supplierCompanies.filter(
          (it) => it.Id == value
        );
      } else {
        this._supplierCompanies = JSON.copy(this.supplierCompanies).slice(
          0,
          10
        );
      }
    }
  }

  @Watch("list.Dto.RadioSearch")
  onRadioSearchChanged(): void {
    let btns = JSON.copy(this.list["_BottomButtons"]) as MiezzButton[];
    if (this.list.Dto?.RadioSearch) {
      const status = parseInt(this.list.Dto.RadioSearch) as EnumOrderStatus;
      switch (status) {
        case EnumOrderStatus.Executing:
          //
          break;
        case EnumOrderStatus.Completed:
          //
          break;
        case EnumOrderStatus.Canceled:
          btns = btns.filter((it) => it.Code != "delete");
          break;

        default:
          break;
      }
    }
    this.list.BottomButtons = btns;
  }

  /**获取装货港 */
  getLoadingPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.form.Data?.TransportDirection == EnumTransportDirection.Export
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.formLoadingPorts = r.data.Data ?? [];
      });
    }
  }
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.form.Data?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.formDeliveryPorts = r.data.Data ?? [];
      });
    }
  }

  @Watch("form.Data.TransportType")
  onTransportTypeChanged(n?: string, o?: string): void {
    if (n == o) return;
    //第三个文本框：当第二个文本框为“海运/By Sea”、“铁路/By Railway”时，显示
    if (
      this.form.Data.TransportType != EnumTransportType.Sea &&
      this.form.Data.TransportType != EnumTransportType.Railway
    ) {
      this.form.Data.TransportContainerLoad = undefined;
    }
  }

  /**选择服务完成回调 */
  handleChooseServiceSubmit(id: string): void {
    this.$router.push(`/order/pool/add/${id}`);
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalBeforeAddData = {};
        this.modalBeforeAdd.Show = true;
        complete();
        break;
      case "choose-add-type":
        (this.$refs["ref-form-before-add"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.modalBeforeAdd.Show = false;
            if (this.modalBeforeAddData.Type == "add") {
              this.modalChooseService.Show = true;
            } else {
              this.form.Data = {};
              this.modalAdd.Show = true;
            }
            complete();
          }
        });
        break;
      case "add-pre":
        (this.$refs["ruleForm"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            OrderAppService.CreatePreStowageStackOrder(this.form.Data)
              .then(() => {
                ElMessage.success("保存成功");
                this.handleGet();
                if (
                  this.loadingPorts.all(
                    (it) => it.Value != this.form.Data.LoadingPortId
                  )
                ) {
                  OrderAppService.GetLoadingPorts().then((r) => {
                    this.loadingPorts = r.data.Data ?? [];
                  });
                }
                if (
                  this.deliveryPorts.all(
                    (it) => it.Value != this.form.Data.DeliveryPortId
                  )
                ) {
                  OrderAppService.GetDeliveryPorts().then((r) => {
                    this.deliveryPorts = r.data.Data ?? [];
                  });
                }
                if (
                  this.customerServices.all(
                    (it) => it.Value != this.logier?.CompanyUserId
                  )
                ) {
                  OrderAppService.GetCustomerServices().then((r) => {
                    this.customerServices = r.data.Data ?? [];
                  });
                }
                this.modalAdd.Show = false;
                complete();
              })
              .catch(complete);
          }
        });
        break;
      default:
        break;
    }
  }

  handleRowCommand(prop: string, row: DtoListItemOrder): void {
    switch (prop) {
      case "ClientInfo":
        DynamicSearchAppService.Post({
          Code: "OrderManagement_Pool",
          Brackets: [
            {
              Relation: EnumRelation.And,
              Items: [
                {
                  Relation: EnumRelation.And,
                  Where: {
                    Prop: "DynamicSearch_OrderCode",
                    Compare: EnumCompare.Equal,
                    Value: row.Code,
                  },
                },
              ],
            },
          ],
        }).then((r) => {
          window.open(
            `#/order/pool?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
            "_blank"
          );
        });
        break;
      case "CustomerServiceId":
        this.modalAssignCustomerServiceData.Ids = this.list.SelectIds;
        this.modalAssignCustomerServiceData.CustomerServiceId =
          row.CustomerServiceId;
        this.modalAssignCustomerServiceData.CustomerServiceLabel =
          row.CustomerServiceLabel;
        this.modalAssignCustomerServiceData.Ids = [row.Id ?? ""];
        this.modalAssignCustomerService.Show = true;
        break;

      default:
        break;
    }
  }

  handleRowDbClick(row: DtoListItemOrder): void {
    if (this.powers.contains("OrderManagement_Order_Summary")) {
      this.$router.push(`/order/summary/${row.Id}`);
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrder,
    complete: () => void
  ): void {
    this.id = row.Id;
    this.cancel = row.Status == EnumOrderStatus.Canceled;
    switch (btn.Code) {
      case "detail":
        this.$router.push(`/order/summary/${row.Id}`);
        complete();
        break;
      case "mark":
        ListMarkColorAppService.Post({
          TableName: "Order",
          TableIds: [row.Id ?? ""],
          Color: btn.Color,
        }).then(() => {
          this.handleGet();
          complete();
        });
        break;
      case "task":
        if (row.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        this.modalTask.Show = true;
        complete();
        break;
      case "process":
        this.modalProcess.Show = true;
        complete();
        break;
      case "delete":
        if (row.Lock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        this.delete({ Ids: [row.Id as string] }, () => {
          this.handleGet();
          ElMessage.success("已取消");
          complete();
        });
        break;
      default:
        break;
    }
  }

  delete(dto: DtoDeleteOrder, callback: () => void): void {
    OrderAppService.Delete(dto).then(() => {
      callback();
    });
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    if (
      btn.Code != "lock" &&
      btn.Code != "unlock" &&
      btn.Code != "mark" &&
      this.list.SelectRows.any((it) => it.Lock)
    ) {
      ElMessage.warning("订单已锁定");
      complete();
      return;
    }
    if (
      btn.Code != "assign-customer-service" &&
      btn.Code != "assign-customer-service-submit" &&
      btn.Code != "delete" &&
      btn.Code != "mark" &&
      this.list.SelectRows.any((it) => it.Status == EnumOrderStatus.Canceled)
    ) {
      ElMessage.warning("订单已取消，无法修改");
      complete();
      return;
    }
    switch (btn.Code) {
      case "mark":
        ListMarkColorAppService.Post({
          TableName: "Order",
          TableIds: this.list.SelectIds,
          Color: btn.Color,
        }).then(() => {
          this.handleGet();
          complete();
        });
        break;
      // 指派客服
      case "assign-customer-service":
        this.modalAssignCustomerServiceData.Ids = this.list.SelectIds;
        this.modalAssignCustomerServiceData.CustomerServiceId = undefined;
        this.modalAssignCustomerServiceData.CustomerServiceLabel = undefined;
        if (
          this.list.SelectRows.any((it) =>
            this.list.SelectRows.all(
              (t) =>
                t.CustomerServiceId == it.CustomerServiceId &&
                t.CustomerServiceLabel == it.CustomerServiceLabel
            )
          )
        ) {
          this.modalAssignCustomerServiceData.CustomerServiceId =
            this.list.SelectRows[0].CustomerServiceId;
          this.modalAssignCustomerServiceData.CustomerServiceLabel =
            this.list.SelectRows[0].CustomerServiceLabel;
        }
        this.modalAssignCustomerService.Show = true;
        complete();
        break;
      // 保存客服
      case "assign-customer-service-submit":
        (this.$refs["ref-form-assign-customer-service"] as any).validate(
          (v: boolean) => {
            if (v) {
              OrderAppService.AssignCustomerService(
                this.modalAssignCustomerServiceData
              )
                .then(() => {
                  ElMessage.success("指派成功");
                  this.handleGet();
                  this.modalAssignCustomerService.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      case "delete":
        this.delete({ Ids: this.list.SelectIds }, () => {
          this.handleGet();
          ElMessage.success("已取消");
          complete();
        });
        break;
      case "put-business-cycle":
        this.modalPutBusinessCycleData.Ids = this.list.SelectIds;
        if (
          this.list.SelectRows.all((it) =>
            this.list.SelectRows.all(
              (r) =>
                r.Id == it.Id ||
                r.MainBill?.BusinessCycle == it.MainBill?.BusinessCycle
            )
          )
        ) {
          this.modalPutBusinessCycleData.BusinessCycle =
            this.list.SelectRows[0].MainBill?.BusinessCycle;
        }
        this.modalPutBusinessCycle.Show = true;
        complete();
        break;
      case "assign-put-business-cycle-submit":
        (this.$refs["ref-form-put-business-cycle"] as any).validate(
          (v: boolean) => {
            if (v) {
              OrderMainBillAppService.PutBusinessCycle(
                this.modalPutBusinessCycleData
              )
                .then(() => {
                  ElMessage.success("编辑成功");
                  this.handleGet();
                  this.modalPutBusinessCycle.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      case "lock":
      case "unlock":
        ElMessageBox.confirm(
          btn.Code == "lock"
            ? "锁定订单后将无法对订单进行信息相关操作，是否继续?"
            : "是否确定解锁所选的订单?",
          btn.Code == "lock" ? "锁定" : "解锁",
          {
            confirmButtonText: "确定",
            cancelButtonText: "关闭",
            distinguishCancelAndClose: true,
            showClose: true,
            type: "warning",
          }
        )
          .then(() => {
            OrderAppService.Lock({
              Ids: this.list.SelectIds,
              Lock: btn.Code == "lock",
            }).then(() => {
              this.handleGet();
              ElMessage.success(btn.Code == "lock" ? "已锁定" : "已解锁");
              complete();
            });
            complete();
          })
          .catch(() => {
            complete();
          });
        break;
      default:
        break;
    }
  }
}
