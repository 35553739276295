import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderTaskStatus, EnumOrderTaskStatusOption } from "./EnumOrderTaskStatus";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemOrderTask extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Overtime = false;
        this.OvertimeDay = 0;
    }

    [proName: string]: any;
    /**
     * 类型
     */
    TypeName?: string;
    /**
     * 待办名称
     */
    TypeLabel?: string;
    /**
     * 执行人
     */
    UserLabel?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 指标时间
     */
    RuleDate?: Date;
    /**
     * 完成时间
     */
    CompletedTime?: Date;
    /**
     * 完成时间
     */
    CompletedTimeText?: string;
    /**
     * 指派时间
     */
    AssignTime?: Date;
    /**
     * ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * ATD
     */
    ActualDepartureDate?: Date;
    /**
     * ATA
     */
    ActualArrivalDate?: Date;
    /**
     * 舱单截止时间
     */
    ManifestEndDate?: Date;
    /**
     * 截关时间
     */
    CustomsEndDate?: Date;
    /**
     * 是否超时
     */
    Overtime?: boolean;
    /**
     * 超时天数
     */
    OvertimeDay?: number;
    /**
     * 状态
     */
    Status?: EnumOrderTaskStatus;
}

/**
 * -表单校验
 */
export const DtoPageListItemOrderTaskRules = {
}

/**
 * -设置项
 */
export const DtoPageListItemOrderTaskMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "TypeLabel",
    Label: "待办名称",
  },
  {
    Prop: "UserLabel",
    Label: "执行人",
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "RuleDate",
    Label: "指标时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "CompletedTimeText",
    Label: "完成时间",
  },
  {
    Prop: "AssignTime",
    Label: "指派时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "ETD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "ETA",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualDepartureDate",
    Label: "ATD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ActualArrivalDate",
    Label: "ATA",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "ManifestEndDate",
    Label: "舱单截止时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "CustomsEndDate",
    Label: "截关时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "Status",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderTaskStatusOption,
  },
]
