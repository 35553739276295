import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoRequestPriceFieldValue from "../models.machine/DtoRequestPriceFieldValue";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoPageListSearchPrice from "../models.machine/DtoPageListSearchPrice";
import DtoPageListPrice from "../models.machine/DtoPageListPrice";
import DtoDetailPrice from "../models.machine/DtoDetailPrice";
import DtoFormPrice from "../models.machine/DtoFormPrice";
import DtoFormPriceBatchUpdate from "../models.machine/DtoFormPriceBatchUpdate";
import DtoRequestPriceHistory from "../models.machine/DtoRequestPriceHistory";
import UiHistory from "../models.machine/UiHistory";
import DtoRequestPriceImportFromCost from "../models.machine/DtoRequestPriceImportFromCost";
import DtoRequestPriceSync from "../models.machine/DtoRequestPriceSync";
import DtoRequestPriceLock from "../models.machine/DtoRequestPriceLock";
import DtoRequestPriceCalc from "../models.machine/DtoRequestPriceCalc";
import DtoListPriceCalc from "../models.machine/DtoListPriceCalc";
import DtoFormPriceFormula from "../models.machine/DtoFormPriceFormula";
import DtoRequestPriceFormulaDelete from "../models.machine/DtoRequestPriceFormulaDelete";
import DtoFormPriceFormulaClac from "../models.machine/DtoFormPriceFormulaClac";
import DtoListItemPriceFormulaCalcResult from "../models.machine/DtoListItemPriceFormulaCalcResult";
import DtoFormPriceExport from "../models.machine/DtoFormPriceExport";
import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "../models.machine/EnumPriceAmountType";

/**
 * 价格
 * @description 自动生成的代码,请勿修改
 */
export default class PriceAppService {
    /**
     * 查询要素值下拉框数据
     * @param {DtoRequestPriceFieldValue} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 要素值下拉框数据
     */
    static GetFieldValues(dto?: DtoRequestPriceFieldValue): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Price/GetFieldValues`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取价格分页列表
     * @param {DtoPageListSearchPrice} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPrice>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPrice): AxiosPromise<RESTfulResult<DtoPageListPrice>> {
        const url = `/api/Price/Get`;
        return service.get<RESTfulResult<DtoPageListPrice>>(url, { params: dto });
    }

    /**
     * 获取价格详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPrice>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPrice>> {
        const url = `/api/Price/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPrice>>(url);
    }

    /**
     * 添加价格
     * @param {DtoFormPrice} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPrice): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Price/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量更新
     * @param {DtoFormPriceBatchUpdate} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static BatchUpdate(dto?: DtoFormPriceBatchUpdate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/BatchUpdate`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除价格
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 删除价格
     * @param {string} [tableId] 
     * @param {string} [supplierId] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteByTableId(tableId?: string, supplierId?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/Delete/${tableId}`;
        const _querys: any = {};
        _querys.supplierId = supplierId
        return service.delete<RESTfulResult<any>>(url, { params: _querys });
    }

    /**
     * 获取历史记录
     * @param {DtoRequestPriceHistory} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<UiHistory[]>>} 历史记录
     */
    static GetHistories(dto?: DtoRequestPriceHistory): AxiosPromise<RESTfulResult<UiHistory[]>> {
        const url = `/api/Price/GetHistories`;
        return service.get<RESTfulResult<UiHistory[]>>(url, { params: dto });
    }

    /**
     * 导入成本价格
     * @param {DtoRequestPriceImportFromCost} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ImportFromCost(dto?: DtoRequestPriceImportFromCost): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/ImportFromCost`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 同步
     * @param {DtoRequestPriceSync} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Sync(dto?: DtoRequestPriceSync): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/Sync`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 锁定
     * @param {DtoRequestPriceLock} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Lock(dto?: DtoRequestPriceLock): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/Lock`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取计算器列表
     * @param {DtoRequestPriceCalc} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoListPriceCalc>>} 计算器列表
     */
    static GetCalcList(dto?: DtoRequestPriceCalc): AxiosPromise<RESTfulResult<DtoListPriceCalc>> {
        const url = `/api/Price/GetCalcList`;
        return service.post<RESTfulResult<DtoListPriceCalc>>(url, dto);
    }

    /**
     * 设置公式
     * @param {DtoFormPriceFormula} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetFormula(dto?: DtoFormPriceFormula): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/SetFormula`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 删除公式
     * @param {DtoRequestPriceFormulaDelete} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteFormula(dto?: DtoRequestPriceFormulaDelete): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/DeleteFormula`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 计算公式结果
     * @param {DtoFormPriceFormulaClac} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<DtoListItemPriceFormulaCalcResult[]>>} 结果
     */
    static CalcFormulaResult(dto?: DtoFormPriceFormulaClac): AxiosPromise<RESTfulResult<DtoListItemPriceFormulaCalcResult[]>> {
        const url = `/api/Price/CalcFormulaResult`;
        return service.post<RESTfulResult<DtoListItemPriceFormulaCalcResult[]>>(url, dto);
    }

    /**
     * 导出
     * @param {DtoFormPriceExport} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string>>} 地址
     */
    static Export(dto?: DtoFormPriceExport): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Price/Export`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 导入
     * @param {string} [tableId] 价格表Id
     * @param {EnumPriceAmountType} [amountType] 金额类型
     * @param {string} [companyId] 
     * @param {string} [userId] 
     * @param {string} [salesmanId] 
     * @param {any} [file] 文件
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ImportByTableIdByAmountType(tableId?: string, amountType?: EnumPriceAmountType, companyId?: string, userId?: string, salesmanId?: string, file?: any): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Price/Import/${tableId}/${amountType}`;
        const _querys: any = {};
        _querys.companyId = companyId
        _querys.userId = userId
        _querys.salesmanId = salesmanId
        return service.post<RESTfulResult<any>>(url, file, { params: _querys });
    }

}
