
import TranslateAppService from "@/apis.machine/TranslateAppService";
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzInput extends Vue {
  @Model() modelValue?: any;
  /**input-类型 */
  @Prop() type?: string;
  /**预设文本 */
  @Prop() placeholder = "请填写";
  /**禁用 */
  @Prop() disabled?: boolean;
  /**input-textarea-行数 */
  @Prop() rows?: number;
  @Prop() text?: boolean;
  /**input-显示密码 */
  @Prop() showPassword?: boolean;
  /**清空值 */
  @Prop() clearable?: boolean;
  /**大小 */
  @Prop() size?: string;
  /**
   * 自动填充
   * 目前用于防止浏览器自动填充账号密码
   */
  @Prop() autocomplete?: string;
  /**去除首尾空格 */
  @Prop() trim?: boolean;
  @Prop() trimWhenBlur?: boolean;
  /**剔除所有空格 */
  @Prop() trimAll?: boolean;
  /**剔除连续空格 */
  @Prop() trimManySpace?: boolean;
  @Prop() trimManySpaceWhenChange?: boolean;
  /**
   * 正则 赋值输入文本中满足正则的字符
   * 举例：
   *    正则="[0-9]"
   *    输入文本="1a2b3d"
   *    显示文本="123"
   */
  @Prop() pattern?: string;
  @Prop() upperCase?: boolean;
  @Prop() number?: boolean;
  @Prop() numberFixed?: number;
  @Prop() greaterThanNumber?: number;
  @Prop() greaterEqualThanNumber?: number;
  /**
   * auto-complete-获取下拉数据
   * @queryString 搜索条件
   * @cb 回调数据
   */
  @Prop() fetchSuggestions?: (queryString: string, cb: any) => void;
  @Prop() triggerOnFocus?: boolean;
  /**auto-complete-值名称 */
  @Prop() valueKey?: string;
  /**翻译 */
  @Prop() translate?: string;
  @Prop() handleTranslate?: (result?: string) => void;
  @Prop() red?: boolean;
  @Prop() deleteOption?: boolean;
  @Prop() checkDeleteOption?: (item: any) => boolean;

  input: {
    /**值 */
    value?: string;
  } = {};

  btnTranslate: MiezzButton = {
    Code: "translate",
    Title: "翻译",
    MiezzIcon: "translate",
    Type: "default",
    Size: "small",
    Height: "32px",
  };

  created(): void {
    this.onModelValueChanged(this.modelValue, undefined);
  }

  /**model改变时 */
  @Watch("modelValue")
  onModelValueChanged(nv?: string, ov?: string): void {
    if (nv !== this.input.value) {
      this.input.value = nv?.toString();
    }
  }

  /**去除首尾空格 */
  handleTrim(cancelTrim?: boolean, event?: string): void {
    if (
      !cancelTrim &&
      ((this.trim ?? true) || (event == "blur" && this.trimWhenBlur))
    ) {
      if (this.type == "textarea") {
        this.input.value = this.input.value
          ?.replace("\r\n", "\n")
          ?.split("\n")
          ?.map((it) => it.trim())
          ?.join("\n");
      } else {
        this.input.value = this.input.value?.trim();
      }
    }
    if (!cancelTrim && this.trimAll)
      this.input.value = this.input.value?.replaceAll(" ", "");
    if (
      (!cancelTrim && this.trimManySpace) ||
      ((event == "change" || event == "keyup") && this.trimManySpaceWhenChange)
    ) {
      const regExp = /\s{2,}/;
      const index = this.input.value?.search(regExp);
      if (index != null && index > -1) {
        this.input.value = this.input.value?.replace(regExp, " ");
        if (
          (event == "change" || event == "keyup") &&
          this.trimManySpaceWhenChange
        ) {
          setTimeout(() => {
            (this.$refs["ref-miezz-input"] as any).input.setSelectionRange(
              index + 1,
              index + 1
            );
          }, 0);
        }
      }
    }
    if (this.upperCase && this.input.value) {
      this.input.value = this.input.value.toUpperCase();
    }
    if (this.pattern && this.input.value) {
      const regExp = new RegExp(this.pattern, "ig");
      this.input.value = this.input.value.match(regExp)?.join("");
    }
  }

  /**model赋值 */
  setModelValue(): void {
    if (this.input.value === "") {
      this.input.value = undefined;
    }
    if (this.input.value && this.number) {
      let number: number | undefined = parseFloat(this.input.value);
      if (isNaN(number)) {
        number = undefined;
      }
      if (number == undefined) {
        this.input.value = "";
      } else {
        number = number.toRoundNumber(this.numberFixed ?? 2);
        const str = number.toString();
        if (str != this.input.value) {
          this.input.value = str;
        }
      }
      if (
        this.greaterThanNumber != undefined &&
        number != undefined &&
        number <= this.greaterThanNumber
      ) {
        number = undefined;
        this.input.value = "";
      }
      if (
        this.greaterEqualThanNumber != undefined &&
        number != undefined &&
        number < this.greaterEqualThanNumber
      ) {
        number = undefined;
        this.input.value = "";
      }
      this.$emit("update:modelValue", number);
    } else {
      this.$emit("update:modelValue", this.input.value);
    }
  }

  /**值变更时 */
  @Emit()
  handleChange(): void {
    this.handleTrim(this.number != true, "change");
    this.setModelValue();
  }

  @Emit()
  handleInput(): void {
    this.$emit("update:modelValue", this.input.value);
  }

  /**失去焦点时 */
  @Emit()
  handleBlur(): void {
    this.handleTrim(undefined, "blur");
    this.setModelValue();
  }

  @Emit()
  handleFocus(): void {
    this.handleTrim();
  }

  /**键抬起时 */
  @Emit()
  handleKeyup(): void {
    this.handleTrim(this.number != true, "keyup");
  }

  @Emit()
  handleClear(): void {
    this.input.value = undefined;
    this.$emit("update:modelValue", this.input.value);
  }

  /**auto-complete 选中时 */
  @Emit()
  handleSelect(item: any): void {
    if (this.valueKey) {
      this.input.value = item[this.valueKey];
      this.setModelValue();
    }
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "translate":
        if (this.translate) {
          TranslateAppService.GetHumpNaming(this.translate).then((r) => {
            if (this.handleTranslate) this.handleTranslate(r.data.Data);
            else {
              this.input.value = r.data.Data;
              this.setModelValue();
            }
            complete();
          });
        } else {
          complete();
        }
        break;

      default:
        break;
    }
  }

  @Emit()
  handleDeleteOption(item: any, value: string): void {
    (this.$refs["ref-miezz-input"] as any).blur();
  }
}
