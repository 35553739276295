
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamPriceFieldAppService from "@/apis.machine/ParamPriceFieldAppService";
import DtoDetailParamPriceField, {
  DtoDetailParamPriceFieldMiezzDetailItems,
} from "@/models.machine/DtoDetailParamPriceField";
import ParamPriceFieldForm from "./ParamPriceFieldForm.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailParamPriceFieldChildren, {
  DtoDetailParamPriceFieldChildrenMiezzListItems,
} from "@/models.machine/DtoDetailParamPriceFieldChildren";

@Options({
  components: {
    ParamPriceFieldForm,
  },
})
export default class ParamPriceFieldDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailParamPriceField, string>();
  modalForm = new MiezzModal();
  list = new MiezzPageList<any, DtoDetailParamPriceFieldChildren, string>();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailParamPriceField();
    //详细-元素配置
    this.detail.Items = DtoDetailParamPriceFieldMiezzDetailItems;
    this.modelValue.Width = "70%";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      // {
      //   Code: "edit",
      //   Title: "编辑",
      //   Icon: "edit",
      //   Type: "default",
      //   Size: "small",
      // },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "danger",
        Message: "是否确认删除",
        Size: "small",
      },
    ];
    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items = DtoDetailParamPriceFieldChildrenMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      ParamPriceFieldAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          this.list.Data!.Items = this.detail.Data.Children;
        }
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      // case "edit":
      //   this.modalForm.Show = true;
      //   this.modalForm.Title = `编辑价格管理要素-${this.detail.Data.Name}`;
      //   complete();
      //   break;
      case "delete":
        ParamPriceFieldAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
