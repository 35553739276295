import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutBLNO extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Visible = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 报关/清关主键
     */
    OrderDepartureId?: string;
    /**
     * 提运单号
     */
    BLNO?: string;
    /**
     * 是否可见
     */
    Visible?: boolean;
}

/**
 * -表单校验
 */
export const DtoPutBLNORules = {
}

/**
 * -设置项
 */
export const DtoPutBLNOMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "BLNO",
    Label: "提运单号",
    Customize: true,
  },
]
