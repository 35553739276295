import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyOvertimeRuleType, EnumCompanyOvertimeRuleTypeOption } from "./EnumCompanyOvertimeRuleType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyOvertimeule_CompanyOvertimeule extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 待办名称
     */
    TaskName?: string;
    /**
     * 待办名称
     */
    TaskLabel?: string;
    /**
     * 基准时间
     */
    Type?: EnumCompanyOvertimeRuleType;
    /**
     * 超期标准（天）
     */
    Overtime?: number;
}

/**
 * -表单校验
 */
export const DtoFormCompanyOvertimeule_CompanyOvertimeuleRules = {
}

/**
 * -设置项
 */
export const DtoFormCompanyOvertimeule_CompanyOvertimeuleMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "TaskLabel",
    Label: "待办名称",
  },
  {
    Prop: "Type",
    Label: "基准时间",
    Customize: true,
  },
  {
    Prop: "Overtime",
    Label: "超期标准（天）",
    Customize: true,
  },
]

/**
 * -设置项
 */
export const DtoFormCompanyOvertimeule_CompanyOvertimeuleMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "TaskLabel",
    Label: "待办名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Type",
    Label: "基准时间",
    Customize: true,
  },
  {
    Prop: "Overtime",
    Label: "超期标准（天）",
    Customize: true,
  },
]
