
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ExceptionLogAppService from "@/apis.machine/ExceptionLogAppService";
import DtoPageListSearchExceptionLog, {
  DtoPageListSearchExceptionLogDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchExceptionLog";
import DtoPageListExceptionLog from "@/models.machine/DtoPageListExceptionLog";
import DtoListItemExceptionLog, {
  DtoListItemExceptionLogMiezzListItems,
} from "@/models.machine/DtoListItemExceptionLog";
import ExceptionLogDetail from "./ExceptionLogDetail.vue";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import router from "@/router";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";

@Options({
  components: {
    ExceptionLogDetail,
  },
})
export default class ExceptionLogPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchExceptionLog,
    DtoListItemExceptionLog,
    string
  >();
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  sites?: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchExceptionLog();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchExceptionLogDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListExceptionLog();
    //列表-列配置
    this.list.Items = DtoListItemExceptionLogMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-http",
        Title: "请求",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
    ];
    ProjectSiteAppService.GetSelect().then((r) => {
      this.sites = r.data.Data;
    });
  }

  /**查询列表 */
  handleGet(): void {
    ExceptionLogAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemExceptionLog,
    complete: () => void
  ): void {
    let search: DtoFormDynamicSearch;
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `异常日志详细`;
        this.id = row.Id;
        complete();
        break;
      case "link-http":
        search = new DtoFormDynamicSearch();
        search.Code = "System_Log_HttpLog";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Keywords",
                  Compare: EnumCompare.Equal,
                  Value: row.TraceIdentifier,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/sys/log/http",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
                SiteId: this.list.Dto?.SiteId,
              },
            });
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
