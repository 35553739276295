import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderMainBillTemplate_OrderMainBillTemplate from "./DtoFormOrderMainBillTemplate_OrderMainBillTemplate";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderMainBillTemplate extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Templates = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 主单Id
     */
    OrderMainBillId?: string;
    /**
     * 模板信息
     */
    Templates?: DtoFormOrderMainBillTemplate_OrderMainBillTemplate[];
}

/**
 * -表单校验
 */
export const DtoFormOrderMainBillTemplateRules = {
}

/**
 * -设置项
 */
export const DtoFormOrderMainBillTemplateMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "OrderMainBillId",
    Label: "主单Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Templates",
    Label: "模板信息",
    Type: EnumMiezzFormItemType.InputText,
  },
]
