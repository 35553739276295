import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemSms extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsUsed = false;
    }

    [proName: string]: any;
    /**
     * 手机号码
     */
    PhoneNumber?: string;
    /**
     * 模板
     */
    Code?: string;
    /**
     * 内容
     */
    Text?: string;
    /**
     * 发送时间
     */
    CreatedTime?: Date;
    /**
     * 有效期
     */
    Expiry?: Date;
    /**
     * 发送人
     */
    CreatedUserName?: string;
    /**
     * 发送人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 使用情况
     */
    IsUsed?: boolean;
    /**
     * 使用时间
     */
    LastModifiedTime?: Date;
}

/**
 * -表单校验
 */
export const DtoPageListItemSmsRules = {
}

/**
 * -设置项
 */
export const DtoPageListItemSmsMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "PhoneNumber",
    Label: "手机号码",
    MaxWidth: "115px",
  },
  {
    Prop: "Code",
    Label: "模板",
    MaxWidth: "100px",
  },
  {
    Prop: "Text",
    Label: "内容",
  },
  {
    Prop: "CreatedTime",
    Label: "发送时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Expiry",
    Label: "有效期",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedUserName",
    Label: "发送人",
    MaxWidth: "180px",
  },
  {
    Prop: "IsUsed",
    Label: "使用情况",
    MaxWidth: "80px",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "已使用",
    WhenFalse: "未使用",
  },
  {
    Prop: "LastModifiedTime",
    Label: "使用时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
