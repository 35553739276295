import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 渠道表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamTransportChannel extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 备注
     */
    Remarks?: string;
}

/**
 * 渠道表单-表单校验
 */
export const DtoFormParamTransportChannelRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Abridge: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Remarks: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 渠道表单-设置项
 */
export const DtoFormParamTransportChannelMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Abridge",
    Label: "缩写",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
  {
    Prop: "Remarks",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
