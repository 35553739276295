import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";
import DtoRequestPriceSyncTarget from "./DtoRequestPriceSyncTarget";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestPriceSync {
    constructor() {
        //无参构造函数
        this.Targets = [];
    }

    [proName: string]: any;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 目标
     */
    Targets?: DtoRequestPriceSyncTarget[];
}

/**
 * -表单校验
 */
export const DtoRequestPriceSyncRules = {
}
