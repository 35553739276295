import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoAutoAddWharf {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 类型
     */
    Type?: EnumOrderServiceType;
    /**
     * 委托书主键集合
     */
    PoolIds?: string[];
}

/**
 * -表单校验
 */
export const DtoAutoAddWharfRules = {
}
