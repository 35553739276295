import UiSelectOption from "./UiSelectOption";

/**
 * 操作状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCrudStatus {
    /**
     * 增
     */
    Create = 0,
    /**
     * 改
     */
    Update = 1,
    /**
     * 删
     */
    Delete = 2,
}

/**
 * 操作状态-选项
 */
export const EnumCrudStatusOption: UiSelectOption<number>[] = [
    { Label: "增", Value: 0, Data: "Create" },
    { Label: "改", Value: 1, Data: "Update" },
    { Label: "删", Value: 2, Data: "Delete" },
];
