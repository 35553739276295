import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 公司分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyCustomerRelation extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.BuildSettlementBill = false;
    }

    [proName: string]: any;
    /**
     * 客户企业
     */
    NameZh?: string;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 生成结算账单
     */
    BuildSettlementBill?: boolean;
    /**
     * 客服Id
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
}

/**
 * 公司分页列表-项-表单校验
 */
export const DtoListItemCompanyCustomerRelationRules = {
}

/**
 * 公司分页列表-项-设置项
 */
export const DtoListItemCompanyCustomerRelationMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "客户企业",
  },
  {
    Prop: "SalesmanLabel",
    Label: "业务员",
  },
  {
    Prop: "BuildSettlementBill",
    Label: "生成结算账单",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "CustomerServiceLabel",
    Label: "客服",
  },
]
