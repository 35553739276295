import UiSelectOption from "./UiSelectOption";

/**
 * 字段名称
            取名时，_的最后一个必须是对应的字段名称
 * @description 自动生成的代码,请勿修改
 */
export enum EnumParamField {
    /**
     * 海关代码
     */
    Brand_Code = 0,
    /**
     * 名称
     */
    Brand_Name = 1,
    /**
     * ISO
     */
    ParamContainerType_ISO = 10,
    /**
     * 95码
     */
    ParamContainerType_Code = 11,
    /**
     * 中文名称
     */
    ParamContainerType_NameZh = 12,
    /**
     * 英文名称
     */
    ParamContainerType_NameEn = 13,
    /**
     * 缩写
     */
    ParamCarrier_Abridge = 20,
    /**
     * 中文名称
     */
    ParamCarrier_NameZh = 21,
    /**
     * 英文名称
     */
    ParamCarrier_NameEn = 22,
    /**
     * 中文名称
     */
    ParamCountry_NameZh = 30,
    /**
     * 英文名称
     */
    ParamCountry_NameEn = 31,
    /**
     * 代码
     */
    ParamCountry_Code = 32,
    /**
     * 中文名称
     */
    ParamPort_NameZh = 40,
    /**
     * 英文名称
     */
    ParamPort_NameEn = 41,
    /**
     * 代码
     */
    ParamPort_Code = 42,
    /**
     * 中文名称
     */
    ParamPriceCost_NameZh = 50,
    /**
     * 英文名称
     */
    ParamPriceCost_NameEn = 51,
    /**
     * 中文名称
     */
    ParamTransportChargeUnit_NameZh = 60,
    /**
     * 英文名称
     */
    ParamTransportChargeUnit_NameEn = 61,
    /**
     * 代码
     */
    ParamTransportChargeUnit_Code = 62,
    /**
     * 中文名称
     */
    ParamCurrencySystem_NameZh = 70,
    /**
     * 英文名称
     */
    ParamCurrencySystem_NameEn = 71,
    /**
     * 代码
     */
    ParamCurrencySystem_Code = 72,
    /**
     * 中文名称
     */
    ParamTransportChannel_NameZh = 80,
    /**
     * 英文名称
     */
    ParamTransportChannel_NameEn = 81,
    /**
     * 缩写
     */
    ParamTransportChannel_Abridge = 82,
    /**
     * 中文名称
     */
    ParamTransportRouteCode_NameZh = 90,
    /**
     * 英文名称
     */
    ParamTransportRouteCode_NameEn = 91,
    /**
     * 代码
     */
    ParamTransportRouteCode_Code = 92,
    /**
     * 名称
     */
    SupervisionMode_Name = 100,
    /**
     * 海关代码
     */
    SupervisionMode_Code = 101,
    /**
     * 名称
     */
    ExemptingMode_Name = 110,
    /**
     * 海关代码
     */
    ExemptingMode_Code = 111,
    /**
     * 名称
     */
    ExemptionNature_Abbr = 120,
    /**
     * 海关代码
     */
    ExemptionNature_Code = 121,
    /**
     * 名称
     */
    DomesticGoodsPlace_Name = 130,
    /**
     * 海关代码
     */
    DomesticGoodsPlace_Code = 131,
    /**
     * 名称
     */
    Place_Name = 140,
    /**
     * 海关代码
     */
    Place_Code = 141,
    /**
     * 名称
     */
    TransportMode_Name = 150,
    /**
     * 海关代码
     */
    TransportMode_Code = 151,
    /**
     * 名称
     */
    Export_Name = 160,
    /**
     * 海关代码
     */
    Export_Code = 161,
    /**
     * 名称
     */
    TransactionMode_Name = 170,
    /**
     * 海关代码
     */
    TransactionMode_Code = 171,
    /**
     * 缩写
     */
    TradeTerms_Code = 180,
    /**
     * 中文名称
     */
    TradeTerms_Name = 181,
    /**
     * 英文名称
     */
    TradeTerms_NameEn = 182,
    /**
     * 缩写
     */
    PaymentTerms_Abbr = 190,
    /**
     * 中文名称
     */
    PaymentTerms_Name = 191,
    /**
     * 英文名称
     */
    PaymentTerms_NameEn = 192,
    /**
     * 国家
     */
    ParamCompanyCodeType_ParamCountryId = 200,
    /**
     * 国家代码
     */
    ParamCompanyCodeType_CountryCode = 201,
    /**
     * 企业代码类型
     */
    ParamCompanyCodeType_CompanyCode = 202,
    /**
     * 关区名称
     */
    Customhouse_Name = 210,
    /**
     * 关区代码
     */
    Customhouse_Code = 211,
    /**
     * 中文名称
     */
    ContactTransportMode_Name = 220,
    /**
     * 英文名称
     */
    ContactTransportMode_NameEn = 221,
    /**
     * 中文名称
     */
    ParamTaxType_NameZh = 230,
    /**
     * 英文名称
     */
    ParamTaxType_NameEn = 231,
    /**
     * 国家
     */
    ParamCountryArea_ParamCountryId = 240,
    /**
     * 中文名称
     */
    ParamCountryArea_NameZh = 241,
    /**
     * 英文名称
     */
    ParamCountryArea_NameEn = 242,
    /**
     * 邮编
     */
    ParamCountryArea_PostalCode = 243,
    /**
     * 缩写
     */
    ParamCountryArea_Abridge = 244,
    /**
     * 一级地区
     */
    ParamCountryArea_AreaId1 = 245,
    /**
     * 二级地区
     */
    ParamCountryArea_AreaId2 = 246,
    /**
     * 三级地区
     */
    ParamCountryArea_AreaId3 = 247,
    /**
     * 四级地区
     */
    ParamCountryArea_AreaId4 = 248,
    /**
     * 所属人
     */
    ParamCountryArea_AffiliatedPerson = 249,
}

/**
 * 字段名称
            取名时，_的最后一个必须是对应的字段名称-选项
 */
export const EnumParamFieldOption: UiSelectOption<number>[] = [
    { Label: "海关代码", Value: 0, Data: "Brand_Code" },
    { Label: "名称", Value: 1, Data: "Brand_Name" },
    { Label: "ISO", Value: 10, Data: "ParamContainerType_ISO" },
    { Label: "95码", Value: 11, Data: "ParamContainerType_Code" },
    { Label: "中文名称", Value: 12, Data: "ParamContainerType_NameZh" },
    { Label: "英文名称", Value: 13, Data: "ParamContainerType_NameEn" },
    { Label: "缩写", Value: 20, Data: "ParamCarrier_Abridge" },
    { Label: "中文名称", Value: 21, Data: "ParamCarrier_NameZh" },
    { Label: "英文名称", Value: 22, Data: "ParamCarrier_NameEn" },
    { Label: "中文名称", Value: 30, Data: "ParamCountry_NameZh" },
    { Label: "英文名称", Value: 31, Data: "ParamCountry_NameEn" },
    { Label: "代码", Value: 32, Data: "ParamCountry_Code" },
    { Label: "中文名称", Value: 40, Data: "ParamPort_NameZh" },
    { Label: "英文名称", Value: 41, Data: "ParamPort_NameEn" },
    { Label: "代码", Value: 42, Data: "ParamPort_Code" },
    { Label: "中文名称", Value: 50, Data: "ParamPriceCost_NameZh" },
    { Label: "英文名称", Value: 51, Data: "ParamPriceCost_NameEn" },
    { Label: "中文名称", Value: 60, Data: "ParamTransportChargeUnit_NameZh" },
    { Label: "英文名称", Value: 61, Data: "ParamTransportChargeUnit_NameEn" },
    { Label: "代码", Value: 62, Data: "ParamTransportChargeUnit_Code" },
    { Label: "中文名称", Value: 70, Data: "ParamCurrencySystem_NameZh" },
    { Label: "英文名称", Value: 71, Data: "ParamCurrencySystem_NameEn" },
    { Label: "代码", Value: 72, Data: "ParamCurrencySystem_Code" },
    { Label: "中文名称", Value: 80, Data: "ParamTransportChannel_NameZh" },
    { Label: "英文名称", Value: 81, Data: "ParamTransportChannel_NameEn" },
    { Label: "缩写", Value: 82, Data: "ParamTransportChannel_Abridge" },
    { Label: "中文名称", Value: 90, Data: "ParamTransportRouteCode_NameZh" },
    { Label: "英文名称", Value: 91, Data: "ParamTransportRouteCode_NameEn" },
    { Label: "代码", Value: 92, Data: "ParamTransportRouteCode_Code" },
    { Label: "名称", Value: 100, Data: "SupervisionMode_Name" },
    { Label: "海关代码", Value: 101, Data: "SupervisionMode_Code" },
    { Label: "名称", Value: 110, Data: "ExemptingMode_Name" },
    { Label: "海关代码", Value: 111, Data: "ExemptingMode_Code" },
    { Label: "名称", Value: 120, Data: "ExemptionNature_Abbr" },
    { Label: "海关代码", Value: 121, Data: "ExemptionNature_Code" },
    { Label: "名称", Value: 130, Data: "DomesticGoodsPlace_Name" },
    { Label: "海关代码", Value: 131, Data: "DomesticGoodsPlace_Code" },
    { Label: "名称", Value: 140, Data: "Place_Name" },
    { Label: "海关代码", Value: 141, Data: "Place_Code" },
    { Label: "名称", Value: 150, Data: "TransportMode_Name" },
    { Label: "海关代码", Value: 151, Data: "TransportMode_Code" },
    { Label: "名称", Value: 160, Data: "Export_Name" },
    { Label: "海关代码", Value: 161, Data: "Export_Code" },
    { Label: "名称", Value: 170, Data: "TransactionMode_Name" },
    { Label: "海关代码", Value: 171, Data: "TransactionMode_Code" },
    { Label: "缩写", Value: 180, Data: "TradeTerms_Code" },
    { Label: "中文名称", Value: 181, Data: "TradeTerms_Name" },
    { Label: "英文名称", Value: 182, Data: "TradeTerms_NameEn" },
    { Label: "缩写", Value: 190, Data: "PaymentTerms_Abbr" },
    { Label: "中文名称", Value: 191, Data: "PaymentTerms_Name" },
    { Label: "英文名称", Value: 192, Data: "PaymentTerms_NameEn" },
    { Label: "国家", Value: 200, Data: "ParamCompanyCodeType_ParamCountryId" },
    { Label: "国家代码", Value: 201, Data: "ParamCompanyCodeType_CountryCode" },
    { Label: "企业代码类型", Value: 202, Data: "ParamCompanyCodeType_CompanyCode" },
    { Label: "关区名称", Value: 210, Data: "Customhouse_Name" },
    { Label: "关区代码", Value: 211, Data: "Customhouse_Code" },
    { Label: "中文名称", Value: 220, Data: "ContactTransportMode_Name" },
    { Label: "英文名称", Value: 221, Data: "ContactTransportMode_NameEn" },
    { Label: "中文名称", Value: 230, Data: "ParamTaxType_NameZh" },
    { Label: "英文名称", Value: 231, Data: "ParamTaxType_NameEn" },
    { Label: "国家", Value: 240, Data: "ParamCountryArea_ParamCountryId" },
    { Label: "中文名称", Value: 241, Data: "ParamCountryArea_NameZh" },
    { Label: "英文名称", Value: 242, Data: "ParamCountryArea_NameEn" },
    { Label: "邮编", Value: 243, Data: "ParamCountryArea_PostalCode" },
    { Label: "缩写", Value: 244, Data: "ParamCountryArea_Abridge" },
    { Label: "一级地区", Value: 245, Data: "ParamCountryArea_AreaId1" },
    { Label: "二级地区", Value: 246, Data: "ParamCountryArea_AreaId2" },
    { Label: "三级地区", Value: 247, Data: "ParamCountryArea_AreaId3" },
    { Label: "四级地区", Value: 248, Data: "ParamCountryArea_AreaId4" },
    { Label: "所属人", Value: 249, Data: "ParamCountryArea_AffiliatedPerson" },
];
