
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzMenuTabComponent, {
  MiezzMenuTab,
} from "@/components/MiezzMenuTab.vue";
import MiezzButton from "@/models/MiezzButton";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import CurrentLogier from "@/apis/CurrentLogier";

export default class PriceSearch extends Vue {
  @Model() modelValue?: MiezzMenuTab;
  menuTab = new MiezzMenuTab();
  tab?: string = "";
  tabs: UiSelectOption<string>[] = [];
  back = false;
  btnBack: MiezzButton = {
    Code: "back",
    Label: "返回",
    Type: "text",
    Size: "small",
    MiezzIcon: "back",
  };

  created(): void {
    this.back = this.$route.query.backUrl != undefined;
    this.menuTab = this.modelValue ?? new MiezzMenuTab();
    PriceServiceAppService.GetSelect({ Open: true }).then((r) => {
      this.tabs = r.data.Data ?? [];
      if (this.$route.params.id) {
        this.tab = this.$route.params.id as string;
      } else {
        this.tab = this.tabs.firstOrDefault()?.Value;
      }
      this.menuTab.Url = "/price/search";
      this.menuTab.Menus = [
        {
          Label: "费用组成",
          Url: `/price/search/detail/${this.tab}`,
          Power: "PriceManagement_Search_Detail",
        },
        {
          Label: "摘要与注意事项",
          Url: `/price/search/remark/${this.tab}`,
          Power: "PriceManagement_Search_Remark",
        },
      ];
      (this.$refs["miezz-menu-tab"] as MiezzMenuTabComponent)?.init();
    });
  }

  handleTabClick(): void {
    this.$nextTick(() => {
      const path =
        this.$route.path.substring(0, this.$route.path.lastIndexOf("/") + 1) +
        this.tab;
      this.$router.push({
        path: path,
        force: true,
      });
      this.menuTab.Menus = [
        {
          Label: "费用组成",
          Url: `/price/search/detail/${this.tab}`,
          Power: "PriceManagement_Search_Detail",
        },
        {
          Label: "摘要与注意事项",
          Url: `/price/search/remark/${this.tab}`,
          Power: "PriceManagement_Search_Remark",
        },
      ];
      (this.$refs["miezz-menu-tab"] as MiezzMenuTabComponent).init(path);
      this.handleRefresh(this.tab);
    });
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    CurrentLogier.Back();
    complete();
  }

  /**刷新 */
  @Emit()
  handleRefresh(id?: string): void {
    //
  }

  /**按钮点击 */
  @Emit()
  handleMenuTabClick(btn: MiezzButton, complete: () => void): void {
    //
  }
}
