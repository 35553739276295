
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ProjectAppService from "@/apis.machine/ProjectAppService";
import DtoPageListSearchProjectVersion, {
  DtoPageListSearchProjectVersionDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchProjectVersion";
import DtoPageListProjectVersion from "@/models.machine/DtoPageListProjectVersion";
import DtoListItemProjectVersion, {
  DtoListItemProjectVersionMiezzListItems,
} from "@/models.machine/DtoListItemProjectVersion";
import ProjectVersionDetail from "./ProjectVersionDetail.vue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import {
  EnumProjectVersionType,
  EnumProjectVersionTypeOption,
} from "@/models.machine/EnumProjectVersionType";
import MiezzListItem from "@/models/MiezzListItem";

@Options({
  components: {
    ProjectVersionDetail,
  },
})
export default class ProjectVersionPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchProjectVersion,
    DtoListItemProjectVersion,
    string
  >("DeveloperTools_Version");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  projects?: UiSelectOption<string>[] = [];
  types = EnumProjectVersionTypeOption;

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchProjectVersion();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchProjectVersionDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListProjectVersion();
    //列表-列配置
    this.list.Items = DtoListItemProjectVersionMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [];

    this.list.HandleFormat = (
      item: MiezzListItem,
      row: DtoListItemProjectVersion
    ) => {
      if (item.Prop == "Project") return row.Project?.Name;
      return undefined;
    };

    ProjectAppService.GetSelect().then((r) => {
      this.projects = r.data.Data;
    });
  }

  /**查询列表 */
  handleGet(): void {
    ProjectAppService.GetVersion(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemProjectVersion,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `项目版本详细`;
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }
}
