import UiEChartsSeriesData from "./UiEChartsSeriesData";
import DtoCallbackCompositionItem from "./DtoCallbackCompositionItem";
import DtoCallbackSearch from "./DtoCallbackSearch";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackComposition {
    constructor() {
        //无参构造函数
        this.SeriesData = [];
        this.Items = [];
        this.Details = [];
        this.Search = {};
    }

    [proName: string]: any;
    /**
     * 
     */
    Title?: string;
    /**
     * 
     */
    SeriesData?: UiEChartsSeriesData[];
    /**
     * 
     */
    Items?: DtoCallbackCompositionItem[];
    /**
     * 
     */
    Details?: DtoCallbackCompositionItem[];
    /**
     * 
     */
    Search?: DtoCallbackSearch;
    /**
     * 币制
     */
    CurrencySystemId?: string;
}

/**
 * -表单校验
 */
export const DtoCallbackCompositionRules = {
}
