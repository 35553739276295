
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzForm from "@/models/MiezzForm";
import DtoFormPriceQuotationSheet_Cargo, {
  DtoFormPriceQuotationSheet_CargoMiezzListItems,
} from "@/models.machine/DtoFormPriceQuotationSheet_Cargo";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormPriceQuotationSheet from "@/models.machine/DtoFormPriceQuotationSheet";
import UiSelectOption from "@/models.machine/UiSelectOption";
import PackageEnAppService from "@/apis.machine/PackageEnAppService";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzButton from "@/models/MiezzButton";
import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";

export default class FormCargosModal extends Vue {
  @Model() modelValue!: MiezzForm<DtoFormPriceQuotationSheet, string>;
  form = new MiezzForm<DtoFormPriceQuotationSheet, string>();
  logier?: Logier;
  modal = new MiezzModal(MiezzModalType.Dialog);
  list = new MiezzPageList<any, DtoFormPriceQuotationSheet_Cargo, string>();
  kgs = "kgs";
  cbm = "m³";
  chargeUnits: UiSelectOption<string>[] = [];
  /**货物清单-规格与数量-添加按钮 */
  btnAdd: MiezzButton = {
    Code: "add",
    Label: "添加",
    Icon: "plus",
    Type: "text",
    // Size: "small",
  };

  created(): void {
    this.logier = CurrentLogier.GetLogier();

    this.modal.Title = "货物数据";
    this.modal.Top = "20px";
    this.modal.Width = "96%";
    this.modal.Height = "500px";
    this.modal.ButtonWidth = "100px";
    this.modal.HandleClick = this.handleClick;
    this.modal.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.MaxHeight = "400px";
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledPagination = false;
    this.list.EnabledSelect = false;
    this.list.Items = DtoFormPriceQuotationSheet_CargoMiezzListItems;
    for (const item of this.list.Items) {
      item.Customize = true;
    }
    this.list.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    PackageEnAppService.GetSelect({ CompanyId: this.logier?.CompanyId }).then(
      (r) => {
        this.packageKinds = r.data.Data ?? [];
      }
    );
  }

  handleShow(): void {
    this.form.Data = {
      Cargos: this.modelValue.Data.Cargos?.copy() ?? [],
      PackagesNumber: this.modelValue.Data.PackagesNumber,
      PackagesKind: this.modelValue.Data.PackagesKind,
      Weight: this.modelValue.Data.Weight,
      Volume: this.modelValue.Data.Volume,
    };
    this.list.Data = {
      Items: this.form.Data.Cargos,
    };
    this.handleCalcTotal();
    this.modal.Show = true;
  }

  handleCalcTotal(): void {
    let totalQuantity = 0;
    let totalGrossWeight = 0;
    let totalVolume = 0;
    for (const row of this.list.Data?.Items ?? []) {
      totalQuantity += row.Quantity ?? 0;
      totalGrossWeight += (row.Quantity ?? 0) * (row.GrossWeight ?? 0);
      totalVolume += (row.Quantity ?? 0) * (row.Volume ?? 0);
    }
    this.form.Data.PackagesNumber = totalQuantity;
    const kinds = this.list.Data?.Items?.filter((it) => it.ChargeUnitEn)
      .map((it) => it.ChargeUnitEn)
      .distinct();
    const kindLen = kinds?.length ?? 0;
    this.form.Data.PackagesKind = kinds && kindLen == 1 ? kinds[0] : "PKG";
    this.form.Data.Weight = parseFloat(totalGrossWeight.toRound(2));
    this.form.Data.Volume = parseFloat(totalVolume.toRound(4));
  }

  handleCalcVolume(index: number): void {
    if (this.list.Data && this.list.Data.Items) {
      const shape = this.list.Data.Items[index];
      if (shape && shape.Length && shape.Width && shape.Height) {
        shape.Volume = parseFloat(
          ((shape.Length * shape.Width * shape.Height) / 1000000).toRound(4)
        );
      }
    }
    this.handleCalcTotal();
  }

  /**货物清单-规格与数量-行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoFormPriceQuotationSheet_Cargo,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        this.list.Data?.Items?.splice(index, 1);
        this.handleCalcTotal();
        complete();
        break;
      default:
        break;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.list?.Data?.Items?.push({});
        this.handleCalcTotal();
        complete();
        break;
      case "submit":
        (this.$refs["ref-form-cargos"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document
                .getElementsByClassName("ref-form-cargos")[0]
                .getElementsByClassName("el-table__body")[0]
                .getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            this.handleCalcTotal();
            this.modelValue.Data.Cargos = this.list.Data?.Items?.copy() ?? [];
            this.modelValue.Data.PackagesNumber = this.form.Data.PackagesNumber;
            this.modelValue.Data.PackagesKind = this.form.Data.PackagesKind;
            this.modelValue.Data.Weight = this.form.Data.Weight;
            this.modelValue.Data.Volume = this.form.Data.Volume;
            this.modal.Show = false;
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  packageKinds: UiSelectOption<string>[] = [];
  /**获取装运类型 */
  getPackageKinds(q: string, cb: any): void {
    cb(
      this.packageKinds.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }

  handleDeleteContact(select: UiSelectOption<string>, value: string): void {
    PackageEnAppService.RemoveHistoryById(select.Value).then(() => {
      const old = this.packageKinds.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.form.Data.PackagesKind = undefined;
      } else {
        this.form.Data.PackagesKind = old?.Label;
      }
      this.packageKinds = this.packageKinds.filter(
        (it) => it.Label != select.Label
      );
      ElMessage.success("历史记录已删除");
      return;
    });
  }
}
