
/**
 * 分页列表搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class UiPageListSearch {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 当前页数
     */
    CurrentPage?: number;
    /**
     * 每页数量
     */
    PageSize?: number;
    /**
     * 动态搜索Id
     */
    DynamicSearchId?: string;
    /**
     * 单选搜索
     */
    RadioSearch?: string;
}

/**
 * 分页列表搜索条件-表单校验
 */
export const UiPageListSearchRules = {
}
