
import MenuAppService from "@/apis.machine/MenuAppService";
import DtoListItemMenuField from "@/models.machine/DtoListItemMenuField";
import DtoListSearchMenuField from "@/models.machine/DtoListSearchMenuField";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal from "@/models/MiezzModal";
import { ElTree } from "element-plus";
import { TreeOptionProps } from "element-plus/es/components/tree/src/tree.type";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class ChooseMenuFields extends Vue {
  @Model() modal!: MiezzModal;
  @Model() modelValue: string[] = [];
  @Prop() id?: string;
  @Prop() showCheck?: boolean;

  ids: string[] = [];
  tree: DtoListItemMenuField[] = [];
  props: TreeOptionProps = {
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };
  treeRef?: InstanceType<typeof ElTree>;

  created(): void {
    if (this.showCheck) {
      this.modal.HandleClick = this.handleSubmit;
      this.modal.Buttons = [
        {
          Code: "submit",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    }
    const dto = new DtoListSearchMenuField();
    dto.MenuId = this.id;
    dto.OnlyShowCheck = !this.showCheck;
    dto.CheckIds = this.modelValue;
    MenuAppService.GetFields(dto).then((r) => {
      if (r.data.Data) {
        if (r.data.Data.Ids) this.ids = r.data.Data.Ids;
        if (r.data.Data.Items) this.tree = r.data.Data.Items;
      }
      this.treeRef = this.$refs["treeRef"] as InstanceType<typeof ElTree>;
      const checkIds = this.ids.filter((it) => !this.modelValue.contains(it));
      this.treeRef.setCheckedKeys(checkIds, false);
    });
  }

  check(id: string, check?: boolean): void {
    const index = this.modelValue.indexOf(id);
    if (check && index == -1) {
      this.modelValue.push(id);
    } else if (!check && index > -1) {
      this.modelValue.splice(index, 1);
    }
  }

  /**菜单选择变化时 */
  handleCheckChange(
    data: DtoListItemMenuField,
    checked: boolean,
    indeterminate: boolean
  ): void {
    if (!data.Id) return;
    data.Check = checked || indeterminate;
    this.check(data.Id, data.Check);

    if (
      data.Children &&
      data.Children.length > 0 &&
      // 菜单选中时 子菜单全部未选中时 则子菜单全选
      (!data.Check || data.Children.all((it) => !it.Check))
    ) {
      for (const item of data.Children) {
        this.treeRef?.setChecked(item.Id as string, data.Check, false);
      }
    }
    const node = this.treeRef?.getNode(data.Id);
    if (node?.parent) {
      // 菜单选中时 选中父菜单
      if (data.Check) {
        if (!node.parent.checked) {
          this.treeRef?.setChecked(node.parent.data.Id, true, false);
        }
      } else {
        if (node.parent.childNodes.all((it) => !it.checked)) {
          this.treeRef?.setChecked(node.parent.data.Id, false, false);
        }
      }
    }
  }

  @Emit()
  handleSubmit(btn: MiezzButton, complete: () => void): void {
    let unCheckIds = (
      this.treeRef
        ? (this.treeRef.getCheckedNodes(false, true) as DtoListItemMenuField[])
        : []
    ).map((it) => it.Id as string);
    const checkIds = this.ids.filter((it) => unCheckIds.indexOf(it) == -1);
    switch (btn.Code) {
      case "submit":
        for (const unCheckId of unCheckIds) {
          this.check(unCheckId, false);
        }
        for (const checkId of checkIds) {
          this.check(checkId, true);
        }
        break;

      default:
        break;
    }
    complete();
  }
}
