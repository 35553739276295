import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSearchListMarkColor from "../models.machine/DtoSearchListMarkColor";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoListItemListMarkColor from "../models.machine/DtoListItemListMarkColor";
import DtoPostListMarkColor from "../models.machine/DtoPostListMarkColor";

/**
 * 列表标记色
 * @description 自动生成的代码,请勿修改
 */
export default class ListMarkColorAppService {
    /**
     * 查询列表
     * @param {DtoSearchListMarkColor} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoListItemListMarkColor[]>>} 
     */
    static Get(dto?: DtoSearchListMarkColor): AxiosPromise<RESTfulResult<DtoListItemListMarkColor[]>> {
        const url = `/api/ListMarkColor/Get`;
        return service.get<RESTfulResult<DtoListItemListMarkColor[]>>(url, { params: dto });
    }

    /**
     * 批量编辑
     * @param {DtoPostListMarkColor} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoPostListMarkColor): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ListMarkColor/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
