import { EnumUiDragType, EnumUiDragTypeOption } from "./EnumUiDragType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class UiDrag<TKey> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 拖拽节点Id
     */
    DraggingId?: TKey;
    /**
     * 放置节点Id
     */
    DropId?: TKey;
    /**
     * 拖曳类型
     */
    DragType?: EnumUiDragType;
}

/**
 * -表单校验
 */
export const UiDragRules = {
}
