
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ParamFbaWarehouseCodeAppService from "@/apis.machine/ParamFbaWarehouseCodeAppService";
import DtoPageListSearchParamFbaWarehouseCode, {
  DtoPageListSearchParamFbaWarehouseCodeDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchParamFbaWarehouseCode";
import DtoPageListParamFbaWarehouseCode from "@/models.machine/DtoPageListParamFbaWarehouseCode";
import DtoListItemParamFbaWarehouseCode, {
  DtoListItemParamFbaWarehouseCodeMiezzListItems,
} from "@/models.machine/DtoListItemParamFbaWarehouseCode";
import ParamFbaWarehouseCodeDetail from "./ParamFbaWarehouseCodeDetail.vue";
import ParamFbaWarehouseCodeForm from "./ParamFbaWarehouseCodeForm.vue";
import router from "@/router";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    ParamFbaWarehouseCodeDetail,
    ParamFbaWarehouseCodeForm,
  },
})
export default class ParamFbaWarehouseCodePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchParamFbaWarehouseCode,
    DtoListItemParamFbaWarehouseCode,
    string
  >("System_ParamList_FBAWarehouseCode");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchParamFbaWarehouseCode();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchParamFbaWarehouseCodeDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListParamFbaWarehouseCode();
    //列表-列配置
    this.list.Items = DtoListItemParamFbaWarehouseCodeMiezzListItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "export",
        Title: "导出",
        Type: "primary",
        Size: "small",
        PowerCode: "System_ParamList_FBAWarehouseCode_Export",
        Width: "65px"
      },
      {
        Code: "synchronization",
        Title: "同步",
        Type: "primary",
        Size: "small",
        PowerCode:
          "System_ParamList_FBAWarehouseCode_Synchronization",
        Width: "65px"
      },
      {
        Code: "add",
        Label: "添加",
        Type: "primary",
        Size: "small",
        PowerCode: "System_ParamList_FBAWarehouseCode_AddEdit",
        Width: "65px"
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
        PowerCode: "System_ParamList_FBAWarehouseCode_Delete",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    ParamFbaWarehouseCodeAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    ParamFbaWarehouseCodeAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        var query = router.currentRoute.value.query;
        query.Drawer = "add";
        query.id = null;
        router.push({
          query: query,
          force: true,
        });
        complete();
        break;
      //同步
      case "synchronization":
        ParamFbaWarehouseCodeAppService.SyncData()
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      //导出
      case "export":
        ParamFbaWarehouseCodeAppService.GetExportExceByName("FBA仓库代码").then(
          (r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
          }
        );
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemParamFbaWarehouseCode,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        var query = router.currentRoute.value.query;
        query.Drawer = "detail";
        if (row.Id) query.id = row.Id;
        router.push({
          query: query,
          force: true,
        });
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ParamFbaWarehouseCodeAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }

  @Watch("$route")
  WatchRoute(): void {
    const query = router.currentRoute.value.query;
    if (query.id) this.id = query.id as string;
    if (query.Drawer == "detail") {
      this.modalDetail.Show = true;
      this.modalDetail.Title = `FBA仓库代码详细`;
    } else if (query.Drawer == "edit") {
      this.modalForm.Show = true;
      this.modalForm.Title = `编辑FBA仓库代码`;
    } else if (query.Drawer == "add") {
      this.modalForm.Show = true;
      this.modalForm.Title = `新增FBA仓库代码`;
      this.id = undefined;
    }
  }
}
