import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormJoinPriceServiceTable_JoinPriceServiceTableCost from "./DtoFormJoinPriceServiceTable_JoinPriceServiceTableCost";
import DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch from "./DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch";
import UiGroup from "./UiGroup";

/**
 * 关联-价格服务/价格表表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormJoinPriceServiceTable extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Costs = [];
        this.FromSearchs = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 价格服务Id
     */
    ServiceId?: string;
    /**
     * 基础价格表
     */
    TableId?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 费用
     */
    Costs?: DtoFormJoinPriceServiceTable_JoinPriceServiceTableCost[];
    /**
     * 搜索
     */
    FromSearchs?: DtoFormJoinPriceServiceTable_JoinPriceServiceTableSearch[];
}

/**
 * 关联-价格服务/价格表表单-表单校验
 */
export const DtoFormJoinPriceServiceTableRules = {
    TableId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 关联-价格服务/价格表表单-设置项
 */
export const DtoFormJoinPriceServiceTableMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "TableId",
    Label: "基础价格表",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Costs",
    Label: "费用",
    Customize: true,
  },
]
