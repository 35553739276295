import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamTransportRouteCode from "../models.machine/DtoSelectSearchParamTransportRouteCode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamTransportRouteCode from "../models.machine/DtoPageListSearchParamTransportRouteCode";
import DtoPageListParamTransportRouteCode from "../models.machine/DtoPageListParamTransportRouteCode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamTransportRouteCode from "../models.machine/DtoDetailParamTransportRouteCode";
import DtoFormParamTransportRouteCode from "../models.machine/DtoFormParamTransportRouteCode";

/**
 * 航线代码
 * @description 自动生成的代码,请勿修改
 */
export default class ParamTransportRouteCodeAppService {
    /**
     * 获取航线代码选择器
     * @param {DtoSelectSearchParamTransportRouteCode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamTransportRouteCode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamTransportRouteCode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取航线代码选分页列表
     * @param {DtoPageListSearchParamTransportRouteCode} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamTransportRouteCode>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamTransportRouteCode): AxiosPromise<RESTfulResult<DtoPageListParamTransportRouteCode>> {
        const url = `/api/ParamTransportRouteCode/Get`;
        return service.get<RESTfulResult<DtoPageListParamTransportRouteCode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportRouteCode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取航线代码详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamTransportRouteCode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamTransportRouteCode>> {
        const url = `/api/ParamTransportRouteCode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamTransportRouteCode>>(url);
    }

    /**
     * 新增/编辑航线代码
     * @param {DtoFormParamTransportRouteCode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} Id
     */
    static Post(dto?: DtoFormParamTransportRouteCode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportRouteCode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除航线代码
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportRouteCode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportRouteCode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportRouteCode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamTransportRouteCode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

}
