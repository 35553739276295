
import UiPageListSearch from "@/models.machine/UiPageListSearch";
import MiezzButton from "@/models/MiezzButton";
import { Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";

export default class MiezzPaginationComponent extends Vue {
  @Prop() modelValue?: UiPageListSearch;
  /**总数量 */
  @Prop() total = 0;
  @Prop() buttons: MiezzButton[] = [];
  @Prop() disabledRouter?: boolean;
  /**布局 */
  layout = "total, sizes, prev, pager, next, jumper, slot";
  /**分页数 */
  pageSizes = [10, 50, 100, 200, 500,1000];
  /**当前页数 */
  currentPage = 1;
  /**每页数量 */
  pageSize = 10;

  /**每页数量变更时 */
  handleSizeChange(val: number): void {
    if (this.modelValue) {
      this.pageSize = val;
      this.hanlePageChange(this.currentPage, this.pageSize);
    }
  }
  /**当前页数变更时 */
  handleCurrentChange(val: number): void {
    if (this.modelValue) {
      this.currentPage = val;
      this.hanlePageChange(this.currentPage, this.pageSize);
    }
  }

  /**分页参数变更时 */
  @Emit("hanlePageChange")
  hanlePageChange(currentPage?: number, pageSize?: number): void {
    if (this.modelValue) {
      this.modelValue.CurrentPage = currentPage;
      this.modelValue.PageSize = pageSize;
    }
    const page = JSON.parse(localStorage.getItem(this.$route.path) ?? "{}");
    page.pageSize = pageSize;
    localStorage.setItem(this.$route.path, JSON.stringify(page));
  }
  /**底部按钮点击 */
  @Emit()
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    //
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    this.currentPage = parseInt((this.modelValue?.CurrentPage ?? 1).toString());
    this.pageSize = parseInt((this.modelValue?.PageSize ?? 10).toString());
  }

  /**创建时 加载路由参数 */
  created(): void {
    this.onModelValueChanged();
  }
}

export class MiezzPagination {}
