import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumUiApiMethod, EnumUiApiMethodOption } from "./EnumUiApiMethod";
import DtoCallbackMenuGetReferencedInvalidWebApiItem_JoinMenuWebApi from "./DtoCallbackMenuGetReferencedInvalidWebApiItem_JoinMenuWebApi";

/**
 * 获取已引用的失效接口-结果
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackMenuGetReferencedInvalidWebApiItem {
    constructor() {
        //无参构造函数
        this.Method = EnumUiApiMethod.Get;
        this.JoinMenuWebApis = [];
    }

    [proName: string]: any;
    /**
     * Id
     */
    Id?: string;
    /**
     * 请求方式
     */
    Method?: EnumUiApiMethod;
    /**
     * 控制器注释
     */
    ControllerSummary?: string;
    /**
     * 请求注释
     */
    Summary?: string;
    /**
     * 请求名称
     */
    Name?: string;
    /**
     * 菜单
     */
    JoinMenuWebApis?: DtoCallbackMenuGetReferencedInvalidWebApiItem_JoinMenuWebApi[];
}

/**
 * 获取已引用的失效接口-结果-表单校验
 */
export const DtoCallbackMenuGetReferencedInvalidWebApiItemRules = {
}

/**
 * 获取已引用的失效接口-结果-设置项
 */
export const DtoCallbackMenuGetReferencedInvalidWebApiItemMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Method",
    Label: "请求方式",
    Pipe: EnumPipe.Enum,
    Options: EnumUiApiMethodOption,
  },
  {
    Prop: "ControllerSummary",
    Label: "控制器注释",
  },
  {
    Prop: "Summary",
    Label: "请求注释",
  },
  {
    Prop: "Name",
    Label: "请求名称",
  },
]
