import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchOrdePoolCommon from "../models.machine/DtoSelectSearchOrdePoolCommon";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoDetailOrderPoolHomeCount from "../models.machine/DtoDetailOrderPoolHomeCount";
import DtoPageListSearchOrderPool from "../models.machine/DtoPageListSearchOrderPool";
import DtoPageListOrderPool from "../models.machine/DtoPageListOrderPool";
import DtoListItemOrderPoolFile from "../models.machine/DtoListItemOrderPoolFile";
import DtoDetailOrderChooseService from "../models.machine/DtoDetailOrderChooseService";
import DtoRequestOrderPoolServiceRemark from "../models.machine/DtoRequestOrderPoolServiceRemark";
import DtoDeleteServiceRemark from "../models.machine/DtoDeleteServiceRemark";
import DtoRequestOrderPoolCheckExistCode from "../models.machine/DtoRequestOrderPoolCheckExistCode";
import DtoFormOrderChooseService from "../models.machine/DtoFormOrderChooseService";
import DtoFormOrderPoolDefaultSupplier from "../models.machine/DtoFormOrderPoolDefaultSupplier";
import DtoDetailOrderPool from "../models.machine/DtoDetailOrderPool";
import DtoImportOrderPool from "../models.machine/DtoImportOrderPool";
import DtoFormOrderPool from "../models.machine/DtoFormOrderPool";
import DtoCallbackOrderPoolPost from "../models.machine/DtoCallbackOrderPoolPost";
import DtoFormOrderPoolAudit from "../models.machine/DtoFormOrderPoolAudit";
import DtoRequestOrderPoolAssignClientUser from "../models.machine/DtoRequestOrderPoolAssignClientUser";
import DtoRequestOrderPoolAssignCustomerService from "../models.machine/DtoRequestOrderPoolAssignCustomerService";
import DtoRequestOrderPoolAssignSalesman from "../models.machine/DtoRequestOrderPoolAssignSalesman";
import DtoRequestOrderPoolCopy from "../models.machine/DtoRequestOrderPoolCopy";
import DtoHistory from "../models.machine/DtoHistory";
import DtoDetailOrderPoolBill from "../models.machine/DtoDetailOrderPoolBill";
import DtoDetailOrderPoolProcess from "../models.machine/DtoDetailOrderPoolProcess";
import DtoPutOrderPoolLock from "../models.machine/DtoPutOrderPoolLock";
import DtoPageListSearchOrderPoolProcessCount from "../models.machine/DtoPageListSearchOrderPoolProcessCount";
import DtoPageListOrderPoolProcessCount from "../models.machine/DtoPageListOrderPoolProcessCount";
import DtoListItemOrderPoolProcessCount from "../models.machine/DtoListItemOrderPoolProcessCount";
import DtoRequestOrderPoolChart from "../models.machine/DtoRequestOrderPoolChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";

/**
 * 委托书
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolAppService {
    /**
     * 获取委托书Booking No.选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetBookingNos(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetBookingNos`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取委托书订单编号选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetCodes(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetCodes`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取标记颜色选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetColors(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetColors`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取委托书装货港选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书目的港选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书最终目的地选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetDeliveryPlaces(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetDeliveryPlaces`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取船名选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetShipNames(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetShipNames`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取航次选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetVoyageNumbers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetVoyageNumbers`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取航班选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetFlights(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetFlights`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取班次选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetRailwayNumbers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetRailwayNumbers`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取承运人选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCarriers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetCarriers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取箱型选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetContainerTypeSelect(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetContainerTypeSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取箱号选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetContainerNoSelect(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetContainerNoSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取服务备注选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetServiceRemarkSelect(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetServiceRemarkSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取委托书执行人选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientUsers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetClientUsers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书客服选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCustomerServices(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetCustomerServices`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书待办执行人选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetTaskUsers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetTaskUsers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书业务员选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSalesmans(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetSalesmans`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书供应商选择器
     * @param {DtoSelectSearchOrdePoolCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(dto?: DtoSelectSearchOrdePoolCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderPool/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取首页计数
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPoolHomeCount>>} 
     */
    static GetHomeCount(): AxiosPromise<RESTfulResult<DtoDetailOrderPoolHomeCount>> {
        const url = `/api/OrderPool/GetHomeCount`;
        return service.get<RESTfulResult<DtoDetailOrderPoolHomeCount>>(url);
    }

    /**
     * 获取委托书分页列表
     * @param {DtoPageListSearchOrderPool} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPool>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPool): AxiosPromise<RESTfulResult<DtoPageListOrderPool>> {
        const url = `/api/OrderPool/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPool>>(url, { params: dto });
    }

    /**
     * 获取附件
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoListItemOrderPoolFile[]>>} 
     */
    static GetFilesById(id?: string): AxiosPromise<RESTfulResult<DtoListItemOrderPoolFile[]>> {
        const url = `/api/OrderPool/GetFiles/${id}`;
        return service.get<RESTfulResult<DtoListItemOrderPoolFile[]>>(url);
    }

    /**
     * 获取选择服务详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderChooseService>>} 详细
     */
    static GetChooseServiceById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderChooseService>> {
        const url = `/api/OrderPool/GetChooseService/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderChooseService>>(url);
    }

    /**
     * 获取服务备注
     * @param {DtoRequestOrderPoolServiceRemark} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetServiceRemarks(dto?: DtoRequestOrderPoolServiceRemark): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderPool/GetServiceRemarks`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 删除服务备注
     * @param {DtoDeleteServiceRemark} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteServiceRemark(dto?: DtoDeleteServiceRemark): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/DeleteServiceRemark`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 校验编号是否存在
     * @param {DtoRequestOrderPoolCheckExistCode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckExistCode(dto?: DtoRequestOrderPoolCheckExistCode): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderPool/CheckExistCode`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 选择服务
     * @param {DtoFormOrderChooseService} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static PostChooseService(dto?: DtoFormOrderChooseService): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderPool/PostChooseService`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 保存默认供应商
     * @param {DtoFormOrderPoolDefaultSupplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostDefaultSupplier(dto?: DtoFormOrderPoolDefaultSupplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/PostDefaultSupplier`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取委托书详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPool>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPool>> {
        const url = `/api/OrderPool/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPool>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<DtoImportOrderPool>>} 
     */
    static Import(file?: any): AxiosPromise<RESTfulResult<DtoImportOrderPool>> {
        const url = `/api/OrderPool/Import`;
        return service.post<RESTfulResult<DtoImportOrderPool>>(url, file);
    }

    /**
     * 添加委托书
     * @param {DtoFormOrderPool} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>>} Id
     */
    static Post(dto?: DtoFormOrderPool): AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>> {
        const url = `/api/OrderPool/Post`;
        return service.post<RESTfulResult<DtoCallbackOrderPoolPost>>(url, dto);
    }

    /**
     * 审核
     * @param {DtoFormOrderPoolAudit} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Audit(dto?: DtoFormOrderPoolAudit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/Audit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 指派执行人
     * @param {DtoRequestOrderPoolAssignClientUser} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignClientUser(dto?: DtoRequestOrderPoolAssignClientUser): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/AssignClientUser`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 指派客服
     * @param {DtoRequestOrderPoolAssignCustomerService} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignCustomerService(dto?: DtoRequestOrderPoolAssignCustomerService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/AssignCustomerService`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 指派业务员
     * @param {DtoRequestOrderPoolAssignSalesman} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignSalesman(dto?: DtoRequestOrderPoolAssignSalesman): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/AssignSalesman`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 复制
     * @param {DtoRequestOrderPoolCopy} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>>} 
     */
    static Copy(dto?: DtoRequestOrderPoolCopy): AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>> {
        const url = `/api/OrderPool/Copy`;
        return service.post<RESTfulResult<DtoCallbackOrderPoolPost>>(url, dto);
    }

    /**
     * 获取历史记录
     * @param {string} [id] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistoriesById(id?: string): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/OrderPool/GetHistories/${id}`;
        return service.get<RESTfulResult<DtoHistory[]>>(url);
    }

    /**
     * 获取委托摘要历史记录
     * @param {string} [id] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetSummaryHistoriesById(id?: string): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/OrderPool/GetSummaryHistories/${id}`;
        return service.get<RESTfulResult<DtoHistory[]>>(url);
    }

    /**
     * 获取订舱信息详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPoolBill>>} 详细
     */
    static GetBookingById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPoolBill>> {
        const url = `/api/OrderPool/GetBooking/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPoolBill>>(url);
    }

    /**
     * 获取进程信息
     * @param {string} [id] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPoolProcess>>} 进程信息
     */
    static GetProcessById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPoolProcess>> {
        const url = `/api/OrderPool/GetProcess/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPoolProcess>>(url);
    }

    /**
     * 库存建单
     * @param {DtoFormOrderPool} [dto] 
     * @param {string} [orderDepartureWharfId] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>>} 
     */
    static AddstockByOrderDepartureWharfId(dto?: DtoFormOrderPool, orderDepartureWharfId?: string): AxiosPromise<RESTfulResult<DtoCallbackOrderPoolPost>> {
        const url = `/api/OrderPool/Addstock/${orderDepartureWharfId}`;
        return service.post<RESTfulResult<DtoCallbackOrderPoolPost>>(url, dto);
    }

    /**
     * 导出货物清单
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static ExportGoods(ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderPool/ExportGoods`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出委托书
     * @param {string} [id] 
     * @param {string} [name] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ExportOrderPoolByIdByName(id?: string, name?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/ExportOrderPool/${id}/${name}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 锁定/解锁
     * @param {DtoPutOrderPoolLock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Lock(dto?: DtoPutOrderPoolLock): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPool/Lock`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 进程统计
     * @param {DtoPageListSearchOrderPoolProcessCount} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolProcessCount>>} 
     */
    static GetProcessCount(dto?: DtoPageListSearchOrderPoolProcessCount): AxiosPromise<RESTfulResult<DtoPageListOrderPoolProcessCount>> {
        const url = `/api/OrderPool/GetProcessCount`;
        return service.get<RESTfulResult<DtoPageListOrderPoolProcessCount>>(url, { params: dto });
    }

    /**
     * 导出
     * @param {DtoListItemOrderPoolProcessCount[]} [items] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(items?: DtoListItemOrderPoolProcessCount[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderPool/Export`;
        return service.post<RESTfulResult<string>>(url, items);
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderPoolChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderPoolChart): AxiosPromise<RESTfulResult<UiEChartsBar>> {
        const url = `/api/OrderPool/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderPoolChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderPoolChart): AxiosPromise<RESTfulResult<UiEChartsPie>> {
        const url = `/api/OrderPool/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie>>(url, { params: dto });
    }

}
