import UiPageList from "./UiPageList";
import DtoListItemFinance from "./DtoListItemFinance";
import DtoCallbackBillSum from "./DtoCallbackBillSum";

/**
 * 账单分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListFinance extends UiPageList<DtoListItemFinance, string> {
    constructor() {
        //无参构造函数
        super()
        this.CollectionCount = 0;
        this.PaymentCount = 0;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 合计
     */
    Sum?: DtoCallbackBillSum;
    /**
     * 
     */
    AllIds?: string[];
    /**
     * 应收账单数
     */
    CollectionCount?: number;
    /**
     * 应付账单数
     */
    PaymentCount?: number;
}

/**
 * 账单分页列表-表单校验
 */
export const DtoPageListFinanceRules = {
}
