import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EStockType, EStockTypeOption } from "./EStockType";
import UiGroup from "./UiGroup";

/**
 * 装货港-堆场/仓库/码头-修改出入库时间
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutStockTime extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EStockType;
    /**
     * 时间
     */
    Time?: Date;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 谈出框是否可见
     */
    Visiable?: boolean;
    /**
     * 弹出框标题
     */
    Title?: string;
    /**
     * 字段名称
     */
    Label?: string;
    /**
     * 校验的时间
     */
    CheckTime?: Date;
}

/**
 * 装货港-堆场/仓库/码头-修改出入库时间-表单校验
 */
export const DtoPutStockTimeRules = {
    Time: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 装货港-堆场/仓库/码头-修改出入库时间-设置项
 */
export const DtoPutStockTimeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Time",
    Label: "时间",
    Customize: true,
  },
  {
    Prop: "Visiable",
    Label: "谈出框是否可见",
    Customize: true,
  },
  {
    Prop: "Title",
    Label: "弹出框标题",
    Customize: true,
  },
  {
    Prop: "Label",
    Label: "字段名称",
    Customize: true,
  },
  {
    Prop: "CheckTime",
    Label: "校验的时间",
    Customize: true,
  },
]
