import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleRowButtonClick: _ctx.handleRowButtonClick
    }, null, 8, ["modelValue", "onHandleGet", "onHandleRowButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          ref: "ruleForm",
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'ChildrenCompanyId')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_select, {
                      modelValue: _ctx.form.Data.ChildrenCompanyId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ChildrenCompanyId) = $event)),
                      options: _ctx.companies,
                      labelKey: "NameZh/NameEn",
                      valueKey: "Id"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}