import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchCompanyType from "../models.machine/DtoSelectSearchCompanyType";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchCompanyType from "../models.machine/DtoPageListSearchCompanyType";
import DtoPageListCompanyType from "../models.machine/DtoPageListCompanyType";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailCompanyType from "../models.machine/DtoDetailCompanyType";
import DtoFormCompanyType from "../models.machine/DtoFormCompanyType";

/**
 * 公司类型
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyTypeAppService {
    /**
     * 获取公司类型选择器
     * @param {DtoSelectSearchCompanyType} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchCompanyType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/CompanyType/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取公司类型分页列表
     * @param {DtoPageListSearchCompanyType} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyType>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyType): AxiosPromise<RESTfulResult<DtoPageListCompanyType>> {
        const url = `/api/CompanyType/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyType>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyType/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取公司类型详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyType>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyType>> {
        const url = `/api/CompanyType/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyType>>(url);
    }

    /**
     * 添加公司类型
     * @param {DtoFormCompanyType} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyType): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyType/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除公司类型
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyType/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

}
