import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 进销存的产品包装的规格型号和数量表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderWarehousePackageSpec extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 规格型号主键
     */
    SpecId?: string;
    /**
     * 产品中文名称
     */
    NameZh?: string;
    /**
     * 产品英文名称
     */
    NameEn?: string;
    /**
     * 英文计价单位
     */
    ChargeUnithEn?: string;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 总价
     */
    TotalPrice?: number;
    /**
     * 数量
     */
    Quantity?: number;
}

/**
 * 进销存的产品包装的规格型号和数量表单-表单校验
 */
export const DtoFormOrderWarehousePackageSpecRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ChargeUnithEn: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 进销存的产品包装的规格型号和数量表单-设置项
 */
export const DtoFormOrderWarehousePackageSpecMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "产品中文名称",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "产品英文名称",
    Customize: true,
  },
  {
    Prop: "ChargeUnithEn",
    Label: "英文计价单位",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "总价",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
]
