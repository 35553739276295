import UiPageList from "./UiPageList";
import DtoListItemSaler from "./DtoListItemSaler";
import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";

/**
 * 账单分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSaler extends UiPageList<DtoListItemSaler, string> {
    constructor() {
        //无参构造函数
        super()
        this.Collection = [];
        this.Collectioned = [];
        this.Cost = [];
        this.Settlement = [];
        this.Interest = [];
        this.Payment = [];
        this.Actual = [];
        this.IsPerformance = false;
        this.IsOneMonth = false;
        this.CollectionId = [];
        this.PaymentId = [];
        this.SalerId = [];
        this.IsShare = false;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 应收合计
     */
    Collection?: DtoCallbackCountCurrency[];
    /**
     * 应收坏账
     */
    BadCollection?: DtoCallbackCountCurrency[];
    /**
     * 应付坏账
     */
    BadPayment?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CollectionString?: string;
    /**
     * 已收合计
     */
    Collectioned?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CollectionedString?: string;
    /**
     * 成本合计
     */
    Cost?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    CostString?: string;
    /**
     * 结算价合计
     */
    Settlement?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    SettlementString?: string;
    /**
     * 利息合计
     */
    Interest?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    InterestString?: string;
    /**
     * 应发合计
     */
    Payment?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    PaymentString?: string;
    /**
     * 实发合计
     */
    Actual?: DtoCallbackCountCurrency[];
    /**
     * 
     */
    ActualString?: string;
    /**
     * 是否可以编辑绩效
     */
    IsPerformance?: boolean;
    /**
     * 查询的是否单月
     */
    IsOneMonth?: boolean;
    /**
     * 应收账单主键集合
     */
    CollectionId?: string[];
    /**
     * 应付账单主键集合
     */
    PaymentId?: string[];
    /**
     * 结算价账单主键集合
     */
    SalerId?: string[];
    /**
     * 是否存在未分摊的费用
     */
    IsShare?: boolean;
}

/**
 * 账单分页列表-表单校验
 */
export const DtoPageListSalerRules = {
}
