
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoFormCompanyContact from "@/models.machine/DtoFormCompanyContact";
import CompanyContactForm from "@/views/Company/CompanyContact/CompanyContactForm.vue";
@Options({
  components: {
    CompanyContactForm,
  },
})
export default class SupplierCompanyContactForm extends Vue {
  form = new MiezzForm<DtoFormCompanyContact, string>();
}
