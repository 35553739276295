import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPackage_PackageZh from "./DtoListItemPackage_PackageZh";
import DtoDetailPackage_PackageImage from "./DtoDetailPackage_PackageImage";
import DtoListItemPackage_PackageShape from "./DtoListItemPackage_PackageShape";
import DtoListItemPackage_PackageSpec from "./DtoListItemPackage_PackageSpec";

/**
 * 产品包装分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPackage extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.PackageShapes = [];
        this.PackageSpecs = [];
    }

    [proName: string]: any;
    /**
     * 产品主键：如果该字段不为null，表示非混装
     */
    ProductId?: string;
    /**
     * 包装编号
     */
    Code?: string;
    /**
     * 包装种类（中）
     */
    PackageZh?: DtoListItemPackage_PackageZh;
    /**
     * 包装种类（英）
     */
    PackageEn?: string;
    /**
     * 图片
     */
    Image?: string;
    /**
     * 包装的图片
     */
    PackageImages?: DtoDetailPackage_PackageImage[];
    /**
     * 尺寸与数量
     */
    SizeQuantity?: string;
    /**
     * 净重（KGS）
     */
    NetWeight?: number;
    /**
     * 毛重（KGS）
     */
    GrossWeight?: number;
    /**
     * 体积（CBM）
     */
    Volume?: number;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * 包装内的型号与数量
     */
    SpecQuantity?: string;
    /**
     * 尺寸与数量
     */
    PackageShapes?: DtoListItemPackage_PackageShape[];
    /**
     * 数量
     */
    Quantity?: number;
    /**
     * 包装内的规格型号与数量
     */
    PackageSpecs?: DtoListItemPackage_PackageSpec[];
}

/**
 * 产品包装分页列表-项-表单校验
 */
export const DtoListItemPackageRules = {
}

/**
 * 产品包装分页列表-项-设置项
 */
export const DtoListItemPackageMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "包装编号",
  },
  {
    Prop: "PackageZh",
    Label: "包装种类（中）",
    Customize: true,
  },
  {
    Prop: "PackageEn",
    Label: "包装种类（英）",
  },
  {
    Prop: "PackageImages",
    Label: "包装的图片",
    Customize: true,
  },
  {
    Prop: "SizeQuantity",
    Label: "尺寸与数量",
  },
  {
    Prop: "NetWeight",
    Label: "净重（KGS）",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重（KGS）",
  },
  {
    Prop: "Volume",
    Label: "体积（CBM）",
  },
  {
    Prop: "ProductName",
    Label: "产品名称",
  },
  {
    Prop: "SpecQuantity",
    Label: "包装内的型号与数量",
  },
  {
    Prop: "Quantity",
    Label: "数量",
    Customize: true,
  },
]
