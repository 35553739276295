import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 装货港-报关表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutExecutor extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 
     */
    Service?: EnumOrderServiceType;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 执行人姓名
     */
    ExecutorName?: string;
    /**
     * 执行人联系方式
     */
    ExecutorPhone?: string;
    /**
     * 执行人车牌号码
     */
    ExecutorInfo?: string;
}

/**
 * 装货港-报关表单-表单校验
 */
export const DtoPutExecutorRules = {
}

/**
 * 装货港-报关表单-设置项
 */
export const DtoPutExecutorMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Service",
    Label: "",
    Customize: true,
  },
  {
    Prop: "ExecutorName",
    Label: "执行人姓名",
    Customize: true,
  },
  {
    Prop: "ExecutorPhone",
    Label: "执行人联系方式",
    Customize: true,
  },
  {
    Prop: "ExecutorInfo",
    Label: "执行人车牌号码",
    Customize: true,
  },
]
