import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPriceTable from "../models.machine/DtoSelectSearchPriceTable";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchPriceTable from "../models.machine/DtoPageListSearchPriceTable";
import DtoPageListPriceTable from "../models.machine/DtoPageListPriceTable";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPriceTable from "../models.machine/DtoDetailPriceTable";
import DtoFormPriceTable from "../models.machine/DtoFormPriceTable";
import DtoFormPriceTableRelationSupplier from "../models.machine/DtoFormPriceTableRelationSupplier";
import DtoDetailPriceTableSupplierFile from "../models.machine/DtoDetailPriceTableSupplierFile";
import DtoFormPriceTableSupplierFile from "../models.machine/DtoFormPriceTableSupplierFile";
import DtoFormPriceTableOpen from "../models.machine/DtoFormPriceTableOpen";

/**
 * 基础价格表
 * @description 自动生成的代码,请勿修改
 */
export default class PriceTableAppService {
    /**
     * 获取基础价格表选择器
     * @param {DtoSelectSearchPriceTable} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPriceTable): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PriceTable/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取基础价格表分页列表
     * @param {DtoPageListSearchPriceTable} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPriceTable>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPriceTable): AxiosPromise<RESTfulResult<DtoPageListPriceTable>> {
        const url = `/api/PriceTable/Get`;
        return service.get<RESTfulResult<DtoPageListPriceTable>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTable/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取基础价格表详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceTable>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceTable>> {
        const url = `/api/PriceTable/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceTable>>(url);
    }

    /**
     * 校验是否存在
     * @param {string} [id] 价格表Id
     * @param {string} [name] 名称
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 是否存在
     */
    static CheckExist(id?: string, name?: string): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PriceTable/CheckExist`;
        const _querys: any = {};
        _querys.id = id
        _querys.name = name
        return service.post<RESTfulResult<boolean>>(url, undefined, { params: _querys });
    }

    /**
     * 添加基础价格表
     * @param {DtoFormPriceTable} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPriceTable): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceTable/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除基础价格表
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTable/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 关联供应商
     * @param {DtoFormPriceTableRelationSupplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RelationSupplier(dto?: DtoFormPriceTableRelationSupplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTable/RelationSupplier`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 查询关联供应商附件
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPriceTableSupplierFile>>} 
     */
    static GetSupplierFilesById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPriceTableSupplierFile>> {
        const url = `/api/PriceTable/GetSupplierFiles/${id}`;
        return service.get<RESTfulResult<DtoDetailPriceTableSupplierFile>>(url);
    }

    /**
     * 保存关联供应商附件
     * @param {DtoFormPriceTableSupplierFile} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostSupplierFile(dto?: DtoFormPriceTableSupplierFile): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTable/PostSupplierFile`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 开通服务/成本
     * @param {DtoFormPriceTableOpen} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Open(dto?: DtoFormPriceTableOpen): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTable/Open`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
