import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trunk_page_list = _resolveComponent("trunk-page-list")!

  return (_openBlock(), _createBlock(_component_trunk_page_list, {
    list: _ctx.list,
    menuTab: _ctx.menuTab,
    addPower: "OrderManagement_Order_Summary_DestinationPortTrunk_Add",
    editPower: "OrderManagement_Order_Summary_DestinationPortTrunk_Edit",
    serviceType: _ctx.serviceType,
    orderId: _ctx.$route.params.id
  }, null, 8, ["list", "menuTab", "serviceType", "orderId"]))
}