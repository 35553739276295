import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchServerLog from "../models.machine/DtoPageListSearchServerLog";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListServerLog from "../models.machine/DtoPageListServerLog";

/**
 * 服务器日志
 * @description 自动生成的代码,请勿修改
 */
export default class ServerLogAppService {
    /**
     * 查询服务器日志列表
     * @param {DtoPageListSearchServerLog} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListServerLog>>} 服务器日志列表
     */
    static Get(search?: DtoPageListSearchServerLog): AxiosPromise<RESTfulResult<DtoPageListServerLog>> {
        const url = `/api/ServerLog/Get`;
        return service.get<RESTfulResult<DtoPageListServerLog>>(url, { params: search });
    }

    /**
     * 查询日期
     * @param {string} [siteId] 站点Id
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 日期
     */
    static GetDatesBySiteId(siteId?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ServerLog/GetDates/${siteId}`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
