import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchJoinCompanyUser from "../models.machine/DtoSelectSearchJoinCompanyUser";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import { EnumCompanyType, EnumCompanyTypeOption } from "../models.machine/EnumCompanyType";
import DtoPageListSearchJoinCompanyUser from "../models.machine/DtoPageListSearchJoinCompanyUser";
import DtoPageListJoinCompanyUser from "../models.machine/DtoPageListJoinCompanyUser";
import DtoDetailJoinCompanyUser from "../models.machine/DtoDetailJoinCompanyUser";
import DtoFormJoinCompanyUser from "../models.machine/DtoFormJoinCompanyUser";
import { EnumJoinCompanyUserStatus, EnumJoinCompanyUserStatusOption } from "../models.machine/EnumJoinCompanyUserStatus";
import DtoDetailJoinCompanyUserPersonalProfile from "../models.machine/DtoDetailJoinCompanyUserPersonalProfile";
import DtoFormJoinCompanyUserPersonalProfile from "../models.machine/DtoFormJoinCompanyUserPersonalProfile";
import DtoFormJoinCompanyUserPassword from "../models.machine/DtoFormJoinCompanyUserPassword";
import DtoPageListSearchLoginLog from "../models.machine/DtoPageListSearchLoginLog";
import DtoPageListLoginLog from "../models.machine/DtoPageListLoginLog";

/**
 * 关联-公司/用户
 * @description 自动生成的代码,请勿修改
 */
export default class JoinCompanyUserAppService {
    /**
     * 查询关联-公司/用户选择器
     * @param {DtoSelectSearchJoinCompanyUser} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSelect(dto?: DtoSelectSearchJoinCompanyUser): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/JoinCompanyUser/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取关联-公司/用户分页列表
     * @param {EnumCompanyType} [companyType] 公司类型
     * @param {DtoPageListSearchJoinCompanyUser} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListJoinCompanyUser>>} 分页列表
     */
    static GetPageList(companyType?: EnumCompanyType, dto?: DtoPageListSearchJoinCompanyUser): AxiosPromise<RESTfulResult<DtoPageListJoinCompanyUser>> {
        const url = `/api/JoinCompanyUser/GetPageList`;
        const _querys: any = {};
        _querys.companyType = companyType
        _querys.CurrentPage = dto?.CurrentPage
        _querys.PageSize = dto?.PageSize
        _querys.DynamicSearchId = dto?.DynamicSearchId
        _querys.RadioSearch = dto?.RadioSearch
        return service.get<RESTfulResult<DtoPageListJoinCompanyUser>>(url, { params: _querys });
    }

    /**
     * 获取关联-公司/用户详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailJoinCompanyUser>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailJoinCompanyUser>> {
        const url = `/api/JoinCompanyUser/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailJoinCompanyUser>>(url);
    }

    /**
     * 获取邀请码
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetInviteCode(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/JoinCompanyUser/GetInviteCode`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 校验用户数是否已达上限
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CheckMaxUserCountById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/CheckMaxUserCount/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 添加关联-公司/用户
     * @param {DtoFormJoinCompanyUser} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormJoinCompanyUser): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/JoinCompanyUser/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除关联-公司/用户
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 更新公司用户状态
     * @param {EnumJoinCompanyUserStatus} [status] 状态
     * @param {string[]} [ids] 公司用户Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateStatusByStatus(status?: EnumJoinCompanyUserStatus, ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/UpdateStatus/${status}`;
        return service.put<RESTfulResult<any>>(url, ids);
    }

    /**
     * 更新公司用户是否可见供应商信息
     * @param {boolean} [read] 状态
     * @param {string[]} [ids] 公司用户Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateDisabledReadSupplierByRead(read?: boolean, ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/UpdateDisabledReadSupplier/${read}`;
        return service.put<RESTfulResult<any>>(url, ids);
    }

    /**
     * 获取个人档案
     * @returns {AxiosPromise<RESTfulResult<DtoDetailJoinCompanyUserPersonalProfile>>} 
     */
    static GetPersonalProfile(): AxiosPromise<RESTfulResult<DtoDetailJoinCompanyUserPersonalProfile>> {
        const url = `/api/JoinCompanyUser/GetPersonalProfile`;
        return service.get<RESTfulResult<DtoDetailJoinCompanyUserPersonalProfile>>(url);
    }

    /**
     * 编辑个人档案
     * @param {DtoFormJoinCompanyUserPersonalProfile} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdatePersonalProfile(dto?: DtoFormJoinCompanyUserPersonalProfile): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/UpdatePersonalProfile`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 更新密码
     * @param {DtoFormJoinCompanyUserPassword} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdatePassword(dto?: DtoFormJoinCompanyUserPassword): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyUser/UpdatePassword`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取关联-公司/用户分页列表
     * @param {DtoPageListSearchLoginLog} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListLoginLog>>} 分页列表
     */
    static GetLoginLogPageList(search?: DtoPageListSearchLoginLog): AxiosPromise<RESTfulResult<DtoPageListLoginLog>> {
        const url = `/api/JoinCompanyUser/GetLoginLogPageList`;
        return service.get<RESTfulResult<DtoPageListLoginLog>>(url, { params: search });
    }

}
