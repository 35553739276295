
import { EnumVoyageUnitOption } from "@/models.machine/EnumVoyageUnit";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class PriceVoyageChoose extends Vue {
  @Model() modelValue?: string;

  modal = new MiezzModal(MiezzModalType.Dialog);

  voyage: {
    min?: number;
    max?: number;
    unit?: number;
    unitLabel?: string;
  } = {};

  EnumVoyageUnitOption = EnumVoyageUnitOption;
  label = "";
  rules = {
    max: [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (
            this.voyage.max != undefined &&
            this.voyage.min != undefined &&
            this.voyage.max < this.voyage.min
          ) {
            return callback(new Error("最大值不能小于最小值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ],
  };

  created(): void {
    this.modal.Title = "航程选择";
    this.modal.Width = "500px";
    this.modal.Height = "50px";
    this.modal.HandleClick = this.handleClick;
    this.modal.Buttons = [
      {
        Code: "submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "reset",
        Label: "重置",
        Type: "primary",
        Size: "small",
      },
    ];
    this.onModelValueChanged();
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelValue) {
      const splits = this.modelValue.replace("Voyage:", "").split(",");
      if (splits[0]) this.voyage.min = parseInt(splits[0]);
      if (splits[1]) this.voyage.max = parseInt(splits[1]);
      if (splits[2]) this.voyage.unit = parseInt(splits[2]);
    } else {
      this.voyage.min = undefined;
      this.voyage.max = undefined;
      this.voyage.unit = 24;
    }
    this.voyage.unitLabel = EnumVoyageUnitOption.firstOrDefault(
      (it) => it.Value == this.voyage.unit
    )?.Label;
    if (this.voyage.min != undefined && this.voyage.max != undefined) {
      this.label = `${this.voyage.min} - ${this.voyage.max} ${this.voyage.unitLabel}`;
    } else if (this.voyage.min != undefined) {
      this.label = `≥ ${this.voyage.min} ${this.voyage.unitLabel}`;
    } else if (this.voyage.max != undefined) {
      this.label = `≤ ${this.voyage.max} ${this.voyage.unitLabel}`;
    } else {
      this.label = "";
    }
  }

  showModal(): void {
    this.onModelValueChanged();
    this.modal.Show = true;
    console.log(this.modal);
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    this.$nextTick(() => {
      switch (btn.Code) {
        case "submit":
          var modelValue = `Voyage:${this.voyage.min ?? ""},${
            this.voyage.max ?? ""
          },${this.voyage.unit ?? ""}`;
          this.$emit("update:modelValue", modelValue);
          this.modal.Show = false;
          complete();
          break;
        case "reset":
          this.voyage.min = undefined;
          this.voyage.max = undefined;
          complete();
          break;
        default:
          break;
      }
      this.handleChoose();
    });
  }

  @Emit()
  handleChoose(): void {
    //
  }
}
