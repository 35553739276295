import { EnumWarehouseType, EnumWarehouseTypeOption } from "./EnumWarehouseType";

/**
 * 查询创建人的条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCreater {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 
     */
    Type?: EnumWarehouseType;
    /**
     * 
     */
    CompanyId?: string;
    /**
     * 权限编码
     */
    PowerCode?: string;
}

/**
 * 查询创建人的条件-表单校验
 */
export const DtoRequestCreaterRules = {
}
