
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import DtoDetailOrderPool from "@/models.machine/DtoDetailOrderPool";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import { EnumLadingBillTypeOption } from "@/models.machine/EnumLadingBillType";
import { EnumLadingBillNumberOption } from "@/models.machine/EnumLadingBillNumber";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormOrderPool_OrderPoolPackage, {
  DtoFormOrderPool_OrderPoolPackageMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape, {
  DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape";
import DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct, {
  DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import moment from "moment";
import DtoHistory from "@/models.machine/DtoHistory";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import OrderDetailCompanyBusinessCard from "../CompanyBusinessCard/OrderDetailCompanyBusinessCard.vue";
import OrderDetailProductsSummary from "../ProductSummary/OrderDetailProductsSummary.vue";
import OrderDetailContainer from "../Container/OrderDetailContainer.vue";
import OrderDetailCost from "../Cost/OrderDetailCost.vue";
import OrderDetailLadingBill from "../LadingBill/OrderDetailLadingBill.vue";
import DtoFormOrderPoolAudit, {
  DtoFormOrderPoolAuditMiezzFormItems,
  DtoFormOrderPoolAuditRules,
} from "@/models.machine/DtoFormOrderPoolAudit";
import UiSelectOption from "@/models.machine/UiSelectOption";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import { EnumOrderPoolAuditAction } from "@/models.machine/EnumOrderPoolAuditAction";
import OrderAppService from "@/apis.machine/OrderAppService";

@Options({
  components: {
    OrderDetailCompanyBusinessCard,
    OrderDetailProductsSummary,
    OrderDetailContainer,
    OrderDetailCost,
    OrderDetailLadingBill,
  },
})
export default class OrderPoolDetail extends Vue {
  id?: string;
  logier?: Logier;
  powerCodes?: string[] = [];
  menuTab = new MiezzMenuTab();
  form = new MiezzForm<DtoDetailOrderPool, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
  ];

  EnumCompanyType = EnumCompanyType;
  EnumOrderPoolStatus = EnumOrderPoolStatus;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumLadingBillTypeOption = EnumLadingBillTypeOption;
  EnumLadingBillNumberOption = EnumLadingBillNumberOption;

  histories: DtoHistory[] = [];

  modalAudit = new MiezzModal(MiezzModalType.Dialog);
  formAudit = new MiezzForm<DtoFormOrderPoolAudit, string>();
  EnumOrderPoolAuditAction = EnumOrderPoolAuditAction;
  managers: UiSelectOption<string>[] = [];

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((powerCodes) => {
      this.powerCodes = powerCodes ?? [];
    });
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/detail/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "委托书",
        Url: `/order/pool/detail/${this.id}`,
        Power: "OrderManagement_Pool_Summary_Detail",
      },
    ];

    this.initPackages();

    this.modalAudit.Title = "审核";
    this.modalAudit.Width = "700px";
    this.modalAudit.Height = "200px";
    this.modalAudit.HandleClick = this.handleClick;
    this.modalAudit.Buttons = [
      {
        Code: "submit-audit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.formAudit.Data = new DtoFormOrderPoolAudit();
    this.formAudit.Data.Id = this.id;
    this.formAudit.Rules = {
      AuditAction: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      OrderCode: [
        {
          max: 64,
          message: "文本长度不能超过 64 个字符",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (value) {
              OrderAppService.CheckCodeExist({
                Id: this.id,
                Code: value,
              }).then((r) => {
                if (r.data.Data) {
                  return callback(new Error("该编号已存在，请重新填写"));
                } else {
                  callback();
                }
              });
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      ManagerId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
    };
    this.formAudit.Items = JSON.copy(DtoFormOrderPoolAuditMiezzFormItems);

    this.handleGet();
  }

  @Watch("formAudit.Data.AuditAction")
  onAuditActionChanged(): void {
    if (
      this.formAudit.Data.AuditAction == EnumOrderPoolAuditAction.CreateOrder
    ) {
      this.formAudit.Items = JSON.copy(DtoFormOrderPoolAuditMiezzFormItems);
    } else {
      this.formAudit.Items = JSON.copy(
        DtoFormOrderPoolAuditMiezzFormItems.filter(
          (it) => it.Prop != "OrderCode"
        )
      );
    }
    if (
      this.form.Data.DeparturePortBooking != true ||
      this.form.Data.TransportContainerLoad == EnumTransportContainerLoad.FCL ||
      this.form.Data.TransportContainerLoad ==
        EnumTransportContainerLoad.Bulk ||
      this.form.Data.TransportDirection == EnumTransportDirection.Other
    ) {
      this.formAudit.Data.AuditAction = EnumOrderPoolAuditAction.CreateOrder;
      const auditAction = this.formAudit.Items?.firstOrDefault(
        (it) => it.Prop == "AuditAction"
      );
      if (auditAction) {
        auditAction.Disabled = true;
      }
    }
    const managerItem = this.formAudit.Items?.firstOrDefault(
      (it) => it.Prop == "ManagerId"
    );
    if (managerItem) {
      managerItem.Label =
        this.formAudit.Data.AuditAction == EnumOrderPoolAuditAction.CreateOrder
          ? "订单客服"
          : "配载人员";
    }
    const power =
      this.formAudit.Data.AuditAction == EnumOrderPoolAuditAction.CreateOrder
        ? "IdentityPermission_CustomerService"
        : this.form.Data.TransportDirection == EnumTransportDirection.Export &&
          this.form.Data.TransportType == EnumTransportType.Sea &&
          this.form.Data.TransportContainerLoad ==
            EnumTransportContainerLoad.LCL
        ? "OrderStowageStack_ESL"
        : this.form.Data.TransportDirection == EnumTransportDirection.Export &&
          this.form.Data.TransportType == EnumTransportType.Air
        ? "OrderStowageStack_EA"
        : this.form.Data.TransportDirection == EnumTransportDirection.Export &&
          this.form.Data.TransportType == EnumTransportType.Railway &&
          this.form.Data.TransportContainerLoad ==
            EnumTransportContainerLoad.LCL
        ? "OrderStowageStack_ERL"
        : this.form.Data.TransportDirection == EnumTransportDirection.Import &&
          this.form.Data.TransportType == EnumTransportType.Sea &&
          this.form.Data.TransportContainerLoad ==
            EnumTransportContainerLoad.LCL
        ? "OrderStowageStack_ISL"
        : this.form.Data.TransportDirection == EnumTransportDirection.Import &&
          this.form.Data.TransportType == EnumTransportType.Air
        ? "OrderStowageStack_IA"
        : "OrderStowageStack_IRL";
    JoinCompanyUserAppService.GetSelect({
      CompanyId: this.logier?.CompanyId,
      PowerCodes: [power],
    }).then((r) => {
      this.managers = r.data.Data ?? [];
      if (this.formAudit.Data.AuditAction == EnumOrderPoolAuditAction.Stowage) {
        const random = Math.round(Math.random() * (this.managers.length - 1));
        const manger = this.managers[random];
        this.formAudit.Data.ManagerId = manger.Value;
        this.formAudit.Data.ManagerLabel = manger.Label;
      } else {
        this.formAudit.Data.ManagerId = undefined;
        this.formAudit.Data.ManagerLabel = undefined;
      }
    });
  }

  transportPaymentType = "暂无数据";
  goodsTransportType = "暂无数据";
  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      OrderPoolAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;

          this.buttons.push(
            {
              Code: "edit",
              Label: "编辑",
              Icon: "edit",
              Type: "primary",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Summary_Detail_Edit",
            },
            {
              Code: "print-pdf",
              Label: "导出",
              PdfName: `委托书-${this.form.Data.BookingNo}`,
              Icon: "Download",
              Type: "primary",
              Size: "small",
            }
          );

          if (this.form.Data.Status == EnumOrderPoolStatus.WaitingAudit) {
            this.buttons.push({
              Code: "audit",
              Label: "审核",
              Icon: "edit",
              Type: "primary",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Audit",
              Breathe: true,
            });
            if (
              this.form.Data.DeparturePortBooking != true ||
              this.form.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL ||
              this.form.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.Bulk ||
              this.form.Data.TransportDirection == EnumTransportDirection.Other
            ) {
              this.formAudit.Data.AuditAction =
                EnumOrderPoolAuditAction.CreateOrder;
              const auditAction = this.formAudit.Items?.firstOrDefault(
                (it) => it.Prop == "AuditAction"
              );
              if (auditAction) {
                auditAction.Disabled = true;
              }
            }
          }

          if (this.listPackage.Data)
            this.listPackage.Data.Items = this.form.Data.Packages ?? [];

          this.getType();
          this.transportPaymentType =
            EnumTransportPaymentTypeOption.firstOrDefault(
              (it) => it.Value == this.form.Data.TransportPaymentType
            )?.Label ?? "暂无数据";
          this.goodsTransportType =
            EnumGoodsTransportTypeOption.firstOrDefault(
              (it) => it.Value == this.form.Data.GoodsTransportType
            )?.Label ?? "暂无数据";

          if (this.listPackage.Data) {
            for (const _package of this.form.Data.Packages ?? []) {
              _package.ShapesLabel = "暂无数据×1";
              this.updatePackageShapesLabel(_package);
              _package.ProductsLabel = "暂无数据";
              this.updatePackageProductsLabel(_package);
              _package.ShipmentIdLabel = _package.ShipmentId ?? "暂无数据";
            }

            this.listPackage.Data.Items = this.form.Data.Packages;
          }
          this.onAuditActionChanged();
        }
      });

      OrderPoolAppService.GetHistoriesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  formatDate(v?: Date): string {
    return v ? moment(v).format("YYYY-MM-DD HH:mm") : "暂无数据";
  }

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  type = "暂无数据";

  /**获取业务类型 */
  getType(): void {
    const direction = this.EnumTransportDirectionOption.firstOrDefault(
      (it) => it.Value == this.form.Data.TransportDirection
    )?.Label;
    this.type = `【${direction}】`;
    if (this.form.Data.TransportType) {
      const type = this.EnumTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.TransportType
      )?.Label;
      this.type += `【${type}】`;
    }
    if (this.form.Data.TransportContainerLoad) {
      const containerLoad =
        this.EnumTransportContainerLoadOption.firstOrDefault(
          (it) => it.Value == this.form.Data.TransportContainerLoad
        )?.Label;
      this.type += `【${containerLoad}】`;
    }
  }

  /**货物清单-列表配置 */
  listPackage = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage,
    string
  >();
  /**当前货物 */
  currentPackage = new DtoFormOrderPool_OrderPoolPackage();
  /**货物清单-当前货物索引 */
  packageIndex?: number;
  /**货物清单-初始化元素配置 */
  initPackages(): void {
    this.listPackage.Fix = false;
    this.listPackage.HideCard = true;
    this.listPackage.EnabledDynamicSearch = false;
    this.listPackage.EnabledRadioSearch = false;
    this.listPackage.EnabledSelect = false;
    this.listPackage.EnabledPagination = false;
    this.listPackage.Items = DtoFormOrderPool_OrderPoolPackageMiezzListItems;
    this.listPackage.Data = {
      Items: [],
    };

    this.initPackageShapes();
    this.initPackageProducts();
    this.initPackageShipmentId();
  }

  /**货物清单-规格与数量-模态框 */
  modalPackageShapes = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-规格与数量-列表配置 */
  listPackageShapes = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShape,
    string
  >();
  /**货物清单-规格与数量-初始化元素配置 */
  initPackageShapes(): void {
    this.modalPackageShapes.Title = "尺寸与数量";
    this.modalPackageShapes.Width = "1200px";
    this.modalPackageShapes.HandleClick = this.handleClick;

    this.listPackageShapes.Fix = false;
    this.listPackageShapes.HideCard = true;
    this.listPackageShapes.EnabledDynamicSearch = false;
    this.listPackageShapes.EnabledRadioSearch = false;
    this.listPackageShapes.EnabledSelect = false;
    this.listPackageShapes.EnabledPagination = false;
    this.listPackageShapes.Items =
      DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageShapeMiezzListItems;
    this.listPackageShapes.Data = {
      Items: [],
    };
    this.listPackageShapes.CheckRowButtonShow = () => {
      return (this.listPackageShapes.Data?.Items?.length ?? 0) > 1;
    };
  }

  /**货物清单-规格与数量-打开模态框 */
  handleShowModalPackageShapes(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if (this.listPackageShapes.Data)
      this.listPackageShapes.Data.Items = (row.Shapes ?? []).copy();
    this.modalPackageShapes.Show = true;
  }

  // updatePackageShapesLabel(_package: DtoFormOrderPool_OrderPoolPackage): void {
  //   _package.ShapesLabel = _package.Shapes?.map((it) =>
  //     it.Length && it.Width && it.Height
  //       ? (_package.Shapes?.length ?? 0) > 1
  //         ? `【${it.Length}×${it.Width}×${it.Height}×${it.Quantity}】`
  //         : `${it.Length}×${it.Width}×${it.Height}×${it.Quantity}`
  //       : (_package.Shapes?.length ?? 0) > 1
  //       ? `【暂无数据×${it.Quantity}】`
  //       : `暂无数据×${it.Quantity}`
  //   ).join("");
  // }
  updatePackageShapesLabel(_package: DtoFormOrderPool_OrderPoolPackage): void {
    _package.ShapesLabel = _package.Shapes?.map((it) =>
      it.Length && it.Width && it.Height
        ? (_package.Shapes?.length ?? 0) > 1
          ? `【${it.Length}×${it.Width}×${it.Height}】`
          : `${it.Length}×${it.Width}×${it.Height}`
        : (_package.Shapes?.length ?? 0) > 1
        ? `【暂无数据】`
        : `暂无数据`
    ).join("");
  }

  /**货物清单-产品名称-模态框 */
  modalPackageProducts = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-产品名称-列表配置 */
  listPackageProducts = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProduct,
    string
  >();
  /**货物清单-产品名称-初始化元素配置 */
  initPackageProducts(): void {
    this.modalPackageProducts.Title = "产品型号/数量/货值";
    this.modalPackageProducts.Width = "95%";
    this.modalPackageProducts.HandleClick = this.handleClick;

    this.listPackageProducts.Fix = false;
    this.listPackageProducts.HideCard = true;
    this.listPackageProducts.EnabledDynamicSearch = false;
    this.listPackageProducts.EnabledRadioSearch = false;
    this.listPackageProducts.EnabledSelect = false;
    this.listPackageProducts.EnabledPagination = false;
    this.listPackageProducts.Items =
      DtoFormOrderPool_OrderPoolPackage_OrderPoolPackageProductMiezzListItems;
    for (const item of this.listPackageProducts.Items) {
      switch (item.Prop) {
        case "NameZh":
        case "NameEn":
        case "UnitPrice":
        case "TotalPrice":
          item.CustomizeHeader = true;
          break;

        default:
          break;
      }
    }
    this.listPackageProducts.Data = {
      Items: [],
    };
    this.listPackageProducts.CheckRowButtonShow = () => {
      return (this.listPackageProducts.Data?.Items?.length ?? 0) > 1;
    };
  }

  /**货物清单-产品名称-打开模态框 */
  handleShowModalProductsLabel(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if (this.listPackageProducts.Data)
      this.listPackageProducts.Data.Items = (row.Products ?? []).copy();
    this.modalPackageProducts.Show = true;
  }

  updatePackageProductsLabel(
    _package: DtoFormOrderPool_OrderPoolPackage
  ): void {
    //更新按钮文本
    _package.ProductsLabel = _package.Products?.map((it) =>
      it.NameZh
        ? (_package.Products?.length ?? 0) > 1
          ? `【${it.NameZh}】`
          : `${it.NameZh}`
        : (_package.Products?.length ?? 0) > 1
        ? "【暂无数据】"
        : "暂无数据"
    ).join("");
  }

  /**货物清单-Shipment ID-模态框 */
  modalPackageShipmentId = new MiezzModal(MiezzModalType.Dialog);
  /**货物清单-Shipment-初始化元素配置 */
  initPackageShipmentId(): void {
    this.modalPackageShipmentId.Title = "编辑";
    this.modalPackageShipmentId.HandleClick = this.handleClick;
  }
  /**货物清单-Shipment-打开模态框 */
  handleShowModalShipmentIdLabel(
    row: DtoFormOrderPool_OrderPoolPackage,
    index: number
  ): void {
    this.packageIndex = index;
    this.currentPackage = JSON.copy(row);
    if ((this.currentPackage.ReferenceIds?.length ?? 0) == 0) {
      this.currentPackage.ReferenceIds?.push({});
    }
    this.modalPackageShipmentId.Show = true;
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
        case "back":
            var url = this.$route.query.url as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                this.$router.push(url);
            }
            else {
                CurrentLogier.Back();
            }
        
        complete();
        break;
      case "edit":
        if (this.form.Data.OrderLock) {
          ElMessage.warning("订单已锁定");
        } else if (this.form.Data.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("委托书已取消，无法修改");
        } else if (
          this.logier?.CompanyType == EnumCompanyType.Customer &&
          this.form.Data.OrderId
        ) {
          ElMessage.warning(
            "您的委托正在执行中，如需修改信息，请通知客服人员进行相应操作"
          );
        } else {
          this.$router.push(`/order/pool/edit/${this.id}`);
        }
        complete();
        break;
      case "audit":
        this.modalAudit.Show = true;
        complete();
        break;
      case "submit-audit":
        MiezzForm.submit(this.$refs, "form-audit", (v) => {
          if (v) {
            OrderPoolAppService.Audit(this.formAudit.Data).then(() => {
              if (
                this.form.Data.DeparturePortBooking != true ||
                this.form.Data.TransportContainerLoad ==
                  EnumTransportContainerLoad.FCL ||
                this.form.Data.TransportContainerLoad ==
                  EnumTransportContainerLoad.Bulk
              ) {
                ElMessage.success("审核通过，订单已添加");
              } else {
                if (
                  this.formAudit.Data.AuditAction ==
                  EnumOrderPoolAuditAction.CreateOrder
                ) {
                  ElMessage.success("审核通过，委托书已添加到《拼单配载》中");
                } else {
                  ElMessage.success("审核通过");
                }
              }
              this.$router.push("/order/pool?RadioSearch=5");
            });
          }
        });
        break;
      default:
        break;
    }
  }
}
