import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchDocument from "../models.machine/DtoPageListSearchDocument";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListDocument from "../models.machine/DtoPageListDocument";
import DtoDetailDocument from "../models.machine/DtoDetailDocument";
import DtoFormDocument from "../models.machine/DtoFormDocument";
import DtoSearchHsCodeRecommended from "../models.machine/DtoSearchHsCodeRecommended";
import DtoRequestHsCodeRecommended from "../models.machine/DtoRequestHsCodeRecommended";
import DtoRequestDocumentProduct from "../models.machine/DtoRequestDocumentProduct";
import DtoFormDocumentProduct from "../models.machine/DtoFormDocumentProduct";
import DtoRequestExport from "../models.machine/DtoRequestExport";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 单证
 * @description 自动生成的代码,请勿修改
 */
export default class DocumentAppService {
    /**
     * 获取单证分页列表
     * @param {DtoPageListSearchDocument} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListDocument>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchDocument): AxiosPromise<RESTfulResult<DtoPageListDocument>> {
        const url = `/api/Document/Get`;
        return service.get<RESTfulResult<DtoPageListDocument>>(url, { params: dto });
    }

    /**
     * 获取单证详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailDocument>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailDocument>> {
        const url = `/api/Document/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailDocument>>(url);
    }

    /**
     * 添加单证
     * @param {DtoFormDocument} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormDocument): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Document/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除单证
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Document/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取HsCode的推荐值
     * @param {DtoSearchHsCodeRecommended} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoRequestHsCodeRecommended[]>>} 选择器
     */
    static GetHsCodeRecommended(search?: DtoSearchHsCodeRecommended): AxiosPromise<RESTfulResult<DtoRequestHsCodeRecommended[]>> {
        const url = `/api/Document/GetHsCodeRecommended`;
        return service.get<RESTfulResult<DtoRequestHsCodeRecommended[]>>(url, { params: search });
    }

    /**
     * 通过包装获得单证产品
     * @param {DtoRequestDocumentProduct} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoFormDocumentProduct[]>>} 
     */
    static PostProduct(dto?: DtoRequestDocumentProduct): AxiosPromise<RESTfulResult<DtoFormDocumentProduct[]>> {
        const url = `/api/Document/PostProduct`;
        return service.post<RESTfulResult<DtoFormDocumentProduct[]>>(url, dto);
    }

    /**
     * 导出单证的报关资料
     * @param {DtoRequestExport} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(dto?: DtoRequestExport): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Document/Export`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取发货人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetConsignor(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetConsignor`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取收货人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetConsignee(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetConsignee`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取创建人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetCreater(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetCreater`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取单证中已有的启运港选项
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeparturePort(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetDeparturePort`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取单证中已有的目的港选项
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDestinationPort(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetDestinationPort`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取单证中已有的贸易国选项
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetTradingCountry(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetTradingCountry`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取单证中已有的运抵国选项
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetArrivalCountry(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Document/GetArrivalCountry`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
