import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPackageEnHistory from "../models.machine/DtoSelectSearchPackageEnHistory";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";

/**
 * 英文包装历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class PackageEnHistoryAppService {
    /**
     * 获取英文包装历史记录选择器
     * @param {DtoSelectSearchPackageEnHistory} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPackageEnHistory): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/PackageEnHistory/GetSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: search });
    }

}
