import UiPageList from "./UiPageList";
import DtoListItemServerLog from "./DtoListItemServerLog";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListServerLog extends UiPageList<DtoListItemServerLog, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
}

/**
 * -表单校验
 */
export const DtoPageListServerLogRules = {
}
