import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumFinanceStatChannel {
    /**
     * 总览
     */
    Overview = 0,
    /**
     * 业务员相关
     */
    Saler = 1,
    /**
     * 客户相关
     */
    Custom = 2,
}

/**
 * -选项
 */
export const EnumFinanceStatChannelOption: UiSelectOption<number>[] = [
    { Label: "总览", Value: 0, Data: "Overview" },
    { Label: "业务员相关", Value: 1, Data: "Saler" },
    { Label: "客户相关", Value: 2, Data: "Custom" },
];
