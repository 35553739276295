import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumContactType, EnumContactTypeOption } from "./EnumContactType";
import UiGroup from "./UiGroup";

/**
 * 关联-公司/用户详细-关联-公司/用户/联系方式
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailJoinCompanyUser_JoinCompanyUserContact extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumContactType;
    /**
     * 内容
     */
    Content?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 关联-公司/用户详细-关联-公司/用户/联系方式-表单校验
 */
export const DtoDetailJoinCompanyUser_JoinCompanyUserContactRules = {
}

/**
 * 关联-公司/用户详细-关联-公司/用户/联系方式-设置项
 */
export const DtoDetailJoinCompanyUser_JoinCompanyUserContactMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumContactTypeOption,
  },
  {
    Prop: "Content",
    Label: "内容",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
]

/**
 * 关联-公司/用户详细-关联-公司/用户/联系方式-设置项
 */
export const DtoDetailJoinCompanyUser_JoinCompanyUserContactMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumContactTypeOption,
  },
  {
    Prop: "Content",
    Label: "内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
