import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 项目-站点分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchProjectSite extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * 项目-站点分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchProjectSiteRules = {
}

/**
 * 项目-站点分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchProjectSiteDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_ProjectId",
    Label: "项目",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_Name",
    Label: "名称",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.Equal,
      EnumCompare.Equal,
      EnumCompare.Equal,
    ]
  },
]
