
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ServerLogDetail from "./ServerLogDetail.vue";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import router from "@/router";
import DtoPageListSearchServerLog, {
  DtoPageListSearchServerLogDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchServerLog";
import DtoListItemServerLog, {
  DtoListItemServerLogMiezzListItems,
} from "@/models.machine/DtoListItemServerLog";
import DtoPageListServerLog from "@/models.machine/DtoPageListServerLog";
import ServerLogAppService from "@/apis.machine/ServerLogAppService";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzPageListComponent from "@/components/MiezzPageList.vue";
import moment from "moment";

@Options({
  components: {
    ServerLogDetail,
  },
})
export default class ServerLogPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchServerLog,
    DtoListItemServerLog,
    string
  >();
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  sites?: UiSelectOption<string>[] = [];
  dates?: UiSelectOption<string>[] = [];
  detail?: DtoListItemServerLog;

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchServerLog();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchServerLogDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListServerLog();
    //列表-列配置
    this.list.Items = DtoListItemServerLogMiezzListItems;
    this.list.HandleRowClass = (row) => {
      let className = "";
      if (row.Http) {
        if (row.Http.Method) {
          className += ` api_color_${row.Http.Method}`;
        }
        if (row.Http.Success == false) {
          className += " http-error";
        } else if (
          row.Http.ElapsedMilliseconds &&
          row.Http.ElapsedMilliseconds >= 1000
        ) {
          className += " http-slow";
        }
      }
      return className;
    };
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-http",
        Title: "请求",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
    ];
    this.list.CheckRowButtonShow = (btn, row) => {
      if (btn.Code == "link-http") {
        return row.Http ? true : false;
      }
      return true;
    };

    ProjectSiteAppService.GetSelect().then((r) => {
      this.sites = r.data.Data;
    });
  }

  /**查询列表 */
  handleGet(): void {
    ServerLogAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleChangeSiteId(): void {
    if (this.list.Dto?.SiteId) {
      ServerLogAppService.GetDatesBySiteId(this.list.Dto.SiteId).then((r) => {
        this.dates = r.data.Data?.map((it) => {
          return {
            Label: it,
            Value: it,
          };
        });
        if (this.list.Dto?.Path && !r.data.Data?.contains(this.list.Dto.Path)) {
          this.list.Dto.Path = undefined;
        }
        this.handleRefresh();
      });
    } else {
      this.dates = [];
    }
  }

  /**事件-获取 */
  handleRefresh(): void {
    (this.$refs["miezz-page-list"] as MiezzPageListComponent).handleGet();
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemServerLog,
    complete: () => void
  ): void {
    let search: DtoFormDynamicSearch;
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `服务器日志详细`;
        this.detail = row;
        complete();
        break;
      case "link-http":
        search = new DtoFormDynamicSearch();
        search.Code = "System_Log_HttpLog";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Keywords",
                  Compare: EnumCompare.Equal,
                  Value: row.Http?.RequestId,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/sys/log/http",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
              },
            });
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
