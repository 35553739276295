import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumFinanceStatChannel, EnumFinanceStatChannelOption } from "./EnumFinanceStatChannel";
import { EnumFinanceStatType, EnumFinanceStatTypeOption } from "./EnumFinanceStatType";
import { EnumCompositionType, EnumCompositionTypeOption } from "./EnumCompositionType";

/**
 * 账单分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchSaler extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
        this.Channel = EnumFinanceStatChannel.Overview;
        this.Type = EnumFinanceStatType.Collection;
    }

    [proName: string]: any;
    /**
     * 栏目
     */
    Channel?: EnumFinanceStatChannel;
    /**
     * 类型
     */
    Type?: EnumFinanceStatType;
    /**
     * 月份
     */
    Month?: string;
    /**
     * 服务商下的企业
     */
    CompanyId?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 组成类型
     */
    CompositionType?: EnumCompositionType;
}

/**
 * 账单分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchSalerRules = {
}

/**
 * 账单分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchSalerDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
