import UiSelectOption from "./UiSelectOption";

/**
 * 订单待办类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderTaskType {
    /**
     * 关联委托书
     */
    AddPool = 0,
    /**
     * 制定装货港进仓计划
     */
    AddDeparturePortWarehouse = 1,
    /**
     * 制定装货港提/送计划-快递
     */
    AddDeparturePortExpress = 2,
    /**
     * 制定装货港提/送计划-干线物流
     */
    AddDeparturePortTrunk = 3,
    /**
     * 审核委托书
     */
    AuditPool = 4,
    /**
     * 确认订舱信息
     */
    AddDeparturePortBooking = 5,
    /**
     * 制定装货港提/送计划-集装箱拖车
     */
    AddDeparturePortContainer = 6,
    /**
     * 反馈装货港进仓结果
     */
    FeedbackDeparturePortWarehouseWarehousing = 7,
    /**
     * 反馈装货港提/送结果-快递
     */
    FeedbackDeparturePortExpress = 8,
    /**
     * 反馈装货港提/送结果-干线物流
     */
    FeedbackDeparturePortTrunk = 9,
    /**
     * 反馈装货港提/送结果-集装箱拖车
     */
    FeedbackDeparturePortContainer = 10,
    /**
     * 制定报关计划
     */
    AddDeparturePortDeclareCustoms = 11,
    /**
     * 创建订单
     */
    AddOrder = 12,
    /**
     * 反馈装货港出仓结果
     */
    FeedbackDeparturePortWarehouseExWarehouse = 13,
    /**
     * 确认主单信息
     */
    AddDeparturePortBookingMainBill = 14,
    /**
     * 确认舱单VGM
     */
    AddDeparturePortBookingManifestVgm = 15,
    /**
     * 确认分单信息
     */
    AddDeparturePortBookingPartBill = 16,
    /**
     * 反馈报关结果
     */
    FeedbackDeparturePortDeclareCustoms = 17,
    /**
     * 确认离港信息
     */
    AddDeparturePortBookingMainBillAtd = 18,
    /**
     * 确认到港信息
     */
    AddDeparturePortBookingMainBillAta = 19,
    /**
     * 制定清关计划
     */
    AddDestinationPortClearCustoms = 20,
    /**
     * 制定目的港提/送计划-集装箱拖车
     */
    AddDestinationPortContainer = 21,
    /**
     * 制定目的港进仓计划
     */
    AddDestinationPortWarehouse = 22,
    /**
     * 反馈清关结果
     */
    FeedbackDestinationPortClearCustoms = 23,
    /**
     * 反馈目的港提/送结果-集装箱拖车
     */
    FeedbackDestinationPortContainer = 24,
    /**
     * 制定目的港提/送计划-快递
     */
    AddDestinationPortExpress = 25,
    /**
     * 制定目的港提/送计划-干线物流
     */
    AddDestinationPortTrunk = 26,
    /**
     * 反馈目的港进仓结果
     */
    FeedbackDestinationPortWarehouseWarehousing = 27,
    /**
     * 反馈目的港出仓结果
     */
    FeedbackDestinationPortWarehouseExWarehouse = 28,
    /**
     * 反馈目的港提/送结果-快递
     */
    FeedbackDestinationPortExpress = 29,
    /**
     * 反馈目的港提/送结果-干线物流
     */
    FeedbackDestinationPortTrunk = 30,
    /**
     * 制作应收账单
     */
    AddCollectionBill = 31,
    /**
     * 制作应付账单
     */
    AddPaymentBill = 32,
    /**
     * 确认业务员结算价
     */
    ConfirmSettlementPrice = 33,
}

/**
 * 订单待办类型-选项
 */
export const EnumOrderTaskTypeOption: UiSelectOption<number>[] = [
    { Label: "关联委托书", Value: 0, Data: "AddPool" },
    { Label: "制定装货港进仓计划", Value: 1, Data: "AddDeparturePortWarehouse" },
    { Label: "制定装货港提/送计划-快递", Value: 2, Data: "AddDeparturePortExpress" },
    { Label: "制定装货港提/送计划-干线物流", Value: 3, Data: "AddDeparturePortTrunk" },
    { Label: "审核委托书", Value: 4, Data: "AuditPool" },
    { Label: "确认订舱信息", Value: 5, Data: "AddDeparturePortBooking" },
    { Label: "制定装货港提/送计划-集装箱拖车", Value: 6, Data: "AddDeparturePortContainer" },
    { Label: "反馈装货港进仓结果", Value: 7, Data: "FeedbackDeparturePortWarehouseWarehousing" },
    { Label: "反馈装货港提/送结果-快递", Value: 8, Data: "FeedbackDeparturePortExpress" },
    { Label: "反馈装货港提/送结果-干线物流", Value: 9, Data: "FeedbackDeparturePortTrunk" },
    { Label: "反馈装货港提/送结果-集装箱拖车", Value: 10, Data: "FeedbackDeparturePortContainer" },
    { Label: "制定报关计划", Value: 11, Data: "AddDeparturePortDeclareCustoms" },
    { Label: "创建订单", Value: 12, Data: "AddOrder" },
    { Label: "反馈装货港出仓结果", Value: 13, Data: "FeedbackDeparturePortWarehouseExWarehouse" },
    { Label: "确认主单信息", Value: 14, Data: "AddDeparturePortBookingMainBill" },
    { Label: "确认舱单VGM", Value: 15, Data: "AddDeparturePortBookingManifestVgm" },
    { Label: "确认分单信息", Value: 16, Data: "AddDeparturePortBookingPartBill" },
    { Label: "反馈报关结果", Value: 17, Data: "FeedbackDeparturePortDeclareCustoms" },
    { Label: "确认离港信息", Value: 18, Data: "AddDeparturePortBookingMainBillAtd" },
    { Label: "确认到港信息", Value: 19, Data: "AddDeparturePortBookingMainBillAta" },
    { Label: "制定清关计划", Value: 20, Data: "AddDestinationPortClearCustoms" },
    { Label: "制定目的港提/送计划-集装箱拖车", Value: 21, Data: "AddDestinationPortContainer" },
    { Label: "制定目的港进仓计划", Value: 22, Data: "AddDestinationPortWarehouse" },
    { Label: "反馈清关结果", Value: 23, Data: "FeedbackDestinationPortClearCustoms" },
    { Label: "反馈目的港提/送结果-集装箱拖车", Value: 24, Data: "FeedbackDestinationPortContainer" },
    { Label: "制定目的港提/送计划-快递", Value: 25, Data: "AddDestinationPortExpress" },
    { Label: "制定目的港提/送计划-干线物流", Value: 26, Data: "AddDestinationPortTrunk" },
    { Label: "反馈目的港进仓结果", Value: 27, Data: "FeedbackDestinationPortWarehouseWarehousing" },
    { Label: "反馈目的港出仓结果", Value: 28, Data: "FeedbackDestinationPortWarehouseExWarehouse" },
    { Label: "反馈目的港提/送结果-快递", Value: 29, Data: "FeedbackDestinationPortExpress" },
    { Label: "反馈目的港提/送结果-干线物流", Value: 30, Data: "FeedbackDestinationPortTrunk" },
    { Label: "制作应收账单", Value: 31, Data: "AddCollectionBill" },
    { Label: "制作应付账单", Value: 32, Data: "AddPaymentBill" },
    { Label: "确认业务员结算价", Value: 33, Data: "ConfirmSettlementPrice" },
];
