
/**
 * 关联发票
 * @description 自动生成的代码,请勿修改
 */
export default class DtoAssociationInvoice {
    constructor() {
        //无参构造函数
        this.Visiable = false;
    }

    [proName: string]: any;
    /**
     * 账单主键
     */
    BillId?: string[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
}

/**
 * 关联发票-表单校验
 */
export const DtoAssociationInvoiceRules = {
}
