import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchProjectSite from "../models.machine/DtoSelectSearchProjectSite";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchProjectSite from "../models.machine/DtoPageListSearchProjectSite";
import DtoPageListProjectSite from "../models.machine/DtoPageListProjectSite";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailProjectSite from "../models.machine/DtoDetailProjectSite";
import DtoFormProjectSite from "../models.machine/DtoFormProjectSite";
import DtoFormProjectSiteExecCommand from "../models.machine/DtoFormProjectSiteExecCommand";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoCallbackProjectSiteGetPublishVersion from "../models.machine/DtoCallbackProjectSiteGetPublishVersion";

/**
 * 项目-站点
 * @description 自动生成的代码,请勿修改
 */
export default class ProjectSiteAppService {
    /**
     * 获取项目-站点选择器
     * @param {DtoSelectSearchProjectSite} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchProjectSite): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ProjectSite/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取项目-站点分页列表
     * @param {DtoPageListSearchProjectSite} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListProjectSite>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchProjectSite): AxiosPromise<RESTfulResult<DtoPageListProjectSite>> {
        const url = `/api/ProjectSite/Get`;
        return service.get<RESTfulResult<DtoPageListProjectSite>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ProjectSite/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取项目-站点详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailProjectSite>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailProjectSite>> {
        const url = `/api/ProjectSite/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailProjectSite>>(url);
    }

    /**
     * 添加项目-站点
     * @param {DtoFormProjectSite} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormProjectSite): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ProjectSite/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除项目-站点
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ProjectSite/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 执行命令
     * @param {DtoFormProjectSiteExecCommand} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ExecCommand(dto?: DtoFormProjectSiteExecCommand): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ProjectSite/ExecCommand`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 查询数据库表名
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 数据库表名
     */
    static GetTables(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/ProjectSite/GetTables`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 复制
     * @param {string} [id] 站点Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CopyById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ProjectSite/Copy/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 获取同步方案
     * @param {string} [projectId] 项目Id
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 同步方案
     */
    static GetSyncSchemeByProjectId(projectId?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ProjectSite/GetSyncScheme/${projectId}`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 获取同步方案表
     * @param {string} [projectId] 项目Id
     * @param {string} [name] 方案名称
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 表
     */
    static GetSyncSchemeTableByProjectIdByName(projectId?: string, name?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ProjectSite/GetSyncSchemeTable/${projectId}/${name}`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 获取发布版本
     * @param {string} [projectId] 项目Id
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackProjectSiteGetPublishVersion>>} 结果
     */
    static GetPublishVersionByProjectId(projectId?: string): AxiosPromise<RESTfulResult<DtoCallbackProjectSiteGetPublishVersion>> {
        const url = `/api/ProjectSite/GetPublishVersion/${projectId}`;
        return service.get<RESTfulResult<DtoCallbackProjectSiteGetPublishVersion>>(url);
    }

    /**
     * 上传压缩包
     * @param {any} [file] 压缩包
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static UploadCompression(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ProjectSite/UploadCompression`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

}
