import { RouteRecordRaw } from "vue-router";
import SalePageList from "./SalePageList.vue";
import PurchasePageList from "./PurchasePageList.vue";
import OrderWarehouseForm from "./OrderWarehouseForm.vue";
import OrderWarehouseDetail from "./OrderWarehouseDetail.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/orderwarehouse/sale/:type',
        name: 'OrderWarehouseSale',
        component: SalePageList,
        meta: {
            title: "销售列表 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/sale/add/:type',
        name: 'OrderWarehouseSaleAdd',
        component: OrderWarehouseForm,
        meta: {
            title: "新增 - 销售 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/sale/edit/:id',
        name: 'OrderWarehouseSaleEdit',
        component: OrderWarehouseForm,
        meta: {
            title: "编辑 - 销售 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/sale/detail/:id',
        name: 'OrderWarehouseSaleDetail',
        component: OrderWarehouseDetail,
        meta: {
            title: "详细 - 销售 - 进销存管理"
        }
    },


    {
        path: '/orderwarehouse/purchase/:type',
        name: 'OrderWarehousePurchase',
        component: PurchasePageList,
        meta: {
            title: "采购列表 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/purchase/add/:type',
        name: 'OrderWarehousePurchaseAdd',
        component: OrderWarehouseForm,
        meta: {
            title: "新增 - 采购 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/purchase/edit/:id',
        name: 'OrderWarehousePurchaseEdit',
        component: OrderWarehouseForm,
        meta: {
            title: "编辑 - 采购 - 进销存管理"
        }
    },
    {
        path: '/orderwarehouse/purchase/detail/:id',
        name: 'OrderWarehousePurchaseDetail',
        component: OrderWarehouseDetail,
        meta: {
            title: "详细 - 采购 - 进销存管理"
        }
    },
]

export default routes;