
import MenuAppService from "@/apis.machine/MenuAppService";
import DtoDetailMenu, {
  DtoDetailMenuMiezzDetailItems,
} from "@/models.machine/DtoDetailMenu";
import DtoFormMenu from "@/models.machine/DtoFormMenu";
import { EnumUiApiMethod } from "@/models.machine/EnumUiApiMethod";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop } from "vue-property-decorator";
import MenuForm from "./MenuForm.vue";
@Options({
  components: {
    MenuForm,
  },
})
export default class MenuDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailMenu, string>();

  modalForm = new MiezzModal();
  formId?: string;
  parentId?: string;
  path?: string;
  code?: string;

  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  /**创建时 */
  created(): void {
    this.detail.Data = new DtoDetailMenu();
    this.detail.Items = DtoDetailMenuMiezzDetailItems;

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
      },
      {
        Code: "add-children",
        Title: "添加子菜单",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
      },
      {
        Code: "copy",
        Title: "复制",
        MiezzIcon: "copy",
        Type: "default",
        Size: "small",
      },
    ];
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      MenuAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }

  getMethod(value: EnumUiApiMethod): string | undefined {
    return EnumUiApiMethod[value];
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const data = this.detail.Data as DtoFormMenu;
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑菜单-${this.detail.Data.Name}`;
        this.formId = this.id;
        complete();
        break;
      case "add-children":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增菜单";
        this.formId = undefined;
        this.parentId = data.Id;
        this.path = data.Path ? `${data.Path}${data.Name}/` : `${data.Name}/`;
        this.code = `${data.Code}_`;
        complete();
        break;
      case "delete":
        MenuAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete(complete);
          })
          .catch(complete);
        break;
      case "copy":
        MenuAppService.CopyById(this.id)
          .then(() => {
            this.handleDelete(complete);
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(complete: () => void): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(complete: () => void): void {
    //
  }
}
