import UiSelectOption from "./UiSelectOption";

/**
 * 委托书状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderPoolStatus {
    /**
     * 待添加
     */
    WaitingAdd = 1,
    /**
     * 待审核
     */
    WaitingAudit = 5,
    /**
     * 执行中
     */
    Executing = 20,
    /**
     * 已完成
     */
    Completed = 30,
    /**
     * 已取消
     */
    Canceled = 40,
}

/**
 * 委托书状态-选项
 */
export const EnumOrderPoolStatusOption: UiSelectOption<number>[] = [
    { Label: "待添加", Value: 1, Data: "WaitingAdd" },
    { Label: "待审核", Value: 5, Data: "WaitingAudit" },
    { Label: "执行中", Value: 20, Data: "Executing" },
    { Label: "已完成", Value: 30, Data: "Completed" },
    { Label: "已取消", Value: 40, Data: "Canceled" },
];
