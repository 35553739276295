
import OrderTaskAppService from "@/apis.machine/OrderTaskAppService";
import DtoPageListItemOrderTask, {
  DtoPageListItemOrderTaskMiezzListItems,
} from "@/models.machine/DtoPageListItemOrderTask";
import DtoPageListOrderTask from "@/models.machine/DtoPageListOrderTask";
import DtoPageListSearchOrderTask, {
  DtoPageListSearchOrderTaskDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderTask";
import { EnumOrderTaskStatusOption } from "@/models.machine/EnumOrderTaskStatus";
import { EnumOrderTaskTypeOption } from "@/models.machine/EnumOrderTaskType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CountTask extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchOrderTask,
    DtoPageListItemOrderTask,
    string
  >("Count_Task");
  taskUsers: UiSelectOption<string>[] = [];
  EnumOrderTaskTypeOption = EnumOrderTaskTypeOption;
  EnumOrderTaskStatusOption: UiSelectOption<number>[] = [
    { Label: "待处理", Value: 20, Data: "Executing" },
    { Label: "已完成", Value: 30, Data: "Completed" },
    { Label: "暂未开始", Value: 40, Data: "Canceled" },
  ];

  created(): void {
    this.list.Dto = new DtoPageListSearchOrderTask();
    this.list.Data = new DtoPageListOrderTask();
    this.list.Items = DtoPageListItemOrderTaskMiezzListItems;
    this.list.DynamicSearchItems = DtoPageListSearchOrderTaskDynamicSearchItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Status") {
        return this.EnumOrderTaskStatusOption.firstOrDefault(
          (it) => it.Value == row.Status
        )?.Label;
      }
      return undefined;
    };
    // this.list.HandleRowClass = (row) => {
    //   return row.Overtime ? "red" : "";
    // };

    OrderTaskAppService.GetTaskUsers().then((r) => {
      this.taskUsers = r.data.Data ?? [];
    });
  }

  handleGet(): void {
    OrderTaskAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }
}
