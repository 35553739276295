import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumWarehouseType, EnumWarehouseTypeOption } from "./EnumWarehouseType";
import DtoListItemOrderWarehouse_JoinCompanyUser from "./DtoListItemOrderWarehouse_JoinCompanyUser";
import { EnumOrderWarehouseStatus, EnumOrderWarehouseStatusOption } from "./EnumOrderWarehouseStatus";

/**
 * 进销存/出入库分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderWarehouse extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumWarehouseType;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 签订时间
     */
    SigningTime?: Date;
    /**
     * 合作方（采购方/供应商）
     */
    Partner?: string;
    /**
     * 金额
     */
    Price?: number;
    /**
     * 交货地
     */
    Address?: string;
    /**
     * 交期要求
     */
    Requirement?: string;
    /**
     * 执行人
     */
    JoinCompanyUser?: DtoListItemOrderWarehouse_JoinCompanyUser;
    /**
     * 执行人
     */
    JoinCompanyUserId?: string;
    /**
     * 状态
     */
    Status?: EnumOrderWarehouseStatus;
}

/**
 * 进销存/出入库分页列表-项-表单校验
 */
export const DtoListItemOrderWarehouseRules = {
}

/**
 * 进销存/出入库分页列表-项-设置项
 */
export const DtoListItemOrderWarehouseMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "订单编号",
  },
  {
    Prop: "SigningTime",
    Label: "签订时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Partner",
    Label: "合作方（采购方/供应商）",
  },
  {
    Prop: "Price",
    Label: "金额",
  },
  {
    Prop: "Address",
    Label: "交货地",
  },
  {
    Prop: "Requirement",
    Label: "交期要求",
  },
  {
    Prop: "JoinCompanyUser",
    Label: "执行人",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "状态",
    Customize: true,
  },
]
