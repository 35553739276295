
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import router from "@/router";
import DtoPageListSearchSms, {
  DtoPageListSearchSmsDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchSms";
import DtoPageListItemSms, {
  DtoPageListItemSmsMiezzListItems,
} from "@/models.machine/DtoPageListItemSms";
import DtoPageListSms from "@/models.machine/DtoPageListSms";
import SmsAppService from "@/apis.machine/SmsAppService";

export default class AuditLogPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<DtoPageListSearchSms, DtoPageListItemSms, string>(
    "System_Log_ServerLog"
  );
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchSms();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchSmsDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListSms();
    //列表-列配置
    this.list.Items = DtoPageListItemSmsMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "CreatedUserName") {
        return row.CreatedUserName
          ? `${row.CreatedUserName ?? "暂无数据"}/${row.CreatedPhoneNumber ?? "暂无数据"}`
          : "暂无数据";
      }
      return undefined;
    };
    //列表-行按钮
    this.list.RowButtons = [];
  }

  /**查询列表 */
  handleGet(): void {
    SmsAppService.GetPageList(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }
}
