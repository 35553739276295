
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import DtoRequestCompanyAuth, {
  DtoRequestCompanyAuthMiezzFormItems,
  DtoRequestCompanyAuthRules,
} from "@/models.machine/DtoRequestCompanyAuth";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import {
  EnumAuditStatus,
  EnumAuditStatusOption,
} from "@/models.machine/EnumAuditStatus";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";

export default class CompanyAuth extends Vue {
  @Prop() menuTab?: MiezzMenuTab;
  @Prop() type?: EnumCompanyType;

  logier?: Logier;
  form = new MiezzForm<DtoRequestCompanyAuth, string>();
  buttons: MiezzButton[] = [];
  EnumAuditStatusOption = EnumAuditStatusOption;

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    //表单-数据-初始化
    this.form.Data = new DtoRequestCompanyAuth();
    //表单-校验
    this.form.Rules = JSON.copy(DtoRequestCompanyAuthRules);
    //表单-元素配置
    this.form.Items = DtoRequestCompanyAuthMiezzFormItems;

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    CompanyAppService.GetAuth().then((r) => {
      if (r.data.Data) {
        this.form.Data = r.data.Data;
        const foreignTradeIndividual = r.data.Data.JoinCompanyTypes?.any(
          (it) => it.CompanyType?.Code == "Customer_ForeignTradeIndividual"
        );
        this.form.Rules.BusinessLicense = foreignTradeIndividual
          ? []
          : [
              {
                required: true,
                message: "信息不能为空",
                trigger: "blur",
              },
            ];
        this.form.Rules.Code = foreignTradeIndividual
          ? []
          : [
              {
                required: true,
                message: "信息不能为空",
                trigger: "blur",
              },
            ];
        if (
          this.form.Data.AuthStatus != EnumAuditStatus.Ing &&
          this.form.Data.AuthStatus != EnumAuditStatus.Success
        ) {
          this.buttons.push(
            {
              Code: "back",
              Label: "返回",
              MiezzIcon: "back",
              Type: "text",
              Size: "small",
            },
            {
              Code: "submit",
              Label: "提交",
              Type: "text",
              Size: "small",
            }
          );
        }
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.Auth(this.form.Data)
              .then(() => {
                this.$router.push("/org/company/detail?page=Setting");
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }
}
