
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyContact from "@/models.machine/DtoPageListSearchCompanyContact";
import DtoListItemCompanyContact from "@/models.machine/DtoListItemCompanyContact";
import CompanyContactPageList from "@/views/Company/CompanyContact/CompanyContactPageList.vue";
@Options({
  components: {
    CompanyContactPageList,
  },
})
export default class SupplierPublicCompanyContactPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyContact,
    DtoListItemCompanyContact,
    string
  >("SupplierManagement_Public_Contact");

  created(): void {
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "SupplierManagement_Public_Contact_Detail",
      },
    ];
  }
}
