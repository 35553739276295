import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPriceTableColumn from "../models.machine/DtoSelectSearchPriceTableColumn";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoPageListSearchPriceTableColumn from "../models.machine/DtoPageListSearchPriceTableColumn";
import DtoPageListPriceTableColumn from "../models.machine/DtoPageListPriceTableColumn";
import DtoFormPriceTableColumn from "../models.machine/DtoFormPriceTableColumn";

/**
 * 基础价格表-列
 * @description 自动生成的代码,请勿修改
 */
export default class PriceTableColumnAppService {
    /**
     * 获取基础价格表-列选择器
     * @param {DtoSelectSearchPriceTableColumn} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPriceTableColumn): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/PriceTableColumn/GetSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: search });
    }

    /**
     * 获取基础价格表-列分页列表
     * @param {DtoPageListSearchPriceTableColumn} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPriceTableColumn>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPriceTableColumn): AxiosPromise<RESTfulResult<DtoPageListPriceTableColumn>> {
        const url = `/api/PriceTableColumn/Get`;
        return service.get<RESTfulResult<DtoPageListPriceTableColumn>>(url, { params: dto });
    }

    /**
     * 保存
     * @param {string} [tableId] 基础价格表Id
     * @param {DtoFormPriceTableColumn[]} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostByTableId(tableId?: string, dto?: DtoFormPriceTableColumn[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceTableColumn/Post/${tableId}`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
