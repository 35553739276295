
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import PowerAppService from "@/apis.machine/PowerAppService";
import RoleAppService from "@/apis.machine/RoleAppService";
import DtoFormRole, {
  DtoFormRoleMiezzFormItems,
  DtoFormRoleRules,
} from "@/models.machine/DtoFormRole";
import DtoSelectSearchCompanyType from "@/models.machine/DtoSelectSearchCompanyType";
import DtoTreePower from "@/models.machine/DtoTreePower";
import DtoTreeSearchPower from "@/models.machine/DtoTreeSearchPower";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import ChoosePower from "./ChoosePower.vue";
@Options({
  components: {
    ChoosePower,
  },
})
export default class RoleForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormRole, string>("System_Role_AddEdit");

  tree: DtoTreePower[] = [];
  companyTypes: UiSelectOption<string>[] = [];

  created(): void {
    this.form.Data = new DtoFormRole();
    this.form.Rules = DtoFormRoleRules;
    this.form.Items = DtoFormRoleMiezzFormItems;

    if (process.env.NODE_ENV == "production") {
      this.form.Items = this.form.Items.filter((it) => it.Prop != "IsTest");
    }

    for (const item of this.form.Items) {
      switch (item.Prop) {
        case "Type":
          item.Options = item.Options?.filter(
            (it) =>
              it.Value != EnumCompanyType.Supplier &&
              it.Value != EnumCompanyType.ContactRecord
          );
          item.HandleChange = () => {
            this.getCompanyTypes({ Type: this.form.Data.Type });
          };
          break;

        default:
          break;
      }
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    if (this.id) {
      RoleAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          this.getCompanyTypes({ Type: this.form.Data.Type });
        }
        this.getTree();
      });
    } else {
      this.form.Data = new DtoFormRole();
      this.getTree();
    }
  }

  getCompanyTypes(search?: DtoSelectSearchCompanyType): void {
    CompanyTypeAppService.GetSelect(search).then((r) => {
      if (r.data.Data) {
        this.companyTypes = r.data.Data;
        this.form.Data.JoinCompanyTypeRoles =
          this.form.Data.JoinCompanyTypeRoles?.filter((it) =>
            this.companyTypes.any((t) => t.Value == it.CompanyTypeId)
          ) ?? [];
      }
    });
  }

  getTree(): void {
    const dto = new DtoTreeSearchPower();
    dto.CheckIds = this.form.Data.PowerIds;
    PowerAppService.GetTree(dto).then((r) => {
      if (r.data.Data) {
        this.tree = r.data.Data;
      } else {
        this.tree = [];
      }
    });
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            RoleAppService.Post(this.form.Data)
              .then((r) => {
                this.handleSubmit();
                complete();
              })
              .catch(() => complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
