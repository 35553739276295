
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzDynamicSearchDateTimeRange extends Vue {
  @Model() modelValue?: string;
  range?: string[] = [];

  created(): void {
    this.onModelValueChanged();
  }

  handleChange(): void {
    const modelValue = this.range?.join(",");
    this.$emit("update:modelValue", modelValue);
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelValue) {
      const splits = this.modelValue?.split(",");
      if (splits && splits.length == 2) {
        this.range = splits;
      } else {
        this.$emit("update:modelValue", undefined);
      }
    } else {
      this.range = [];
    }
  }
}
