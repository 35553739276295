import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderMainBillTemplate_OrderLadingBill from "./DtoFormOrderMainBillTemplate_OrderLadingBill";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderMainBillTemplate_OrderMainBillTemplate extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.PageNumber = 0;
        this.Props = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 页面类型
     */
    PageType?: string;
    /**
     * 页面页码
     */
    PageNumber?: number;
    /**
     * 属性
     */
    Props?: DtoFormOrderMainBillTemplate_OrderLadingBill[];
}

/**
 * -表单校验
 */
export const DtoFormOrderMainBillTemplate_OrderMainBillTemplateRules = {
}

/**
 * -设置项
 */
export const DtoFormOrderMainBillTemplate_OrderMainBillTemplateMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "PageType",
    Label: "页面类型",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PageNumber",
    Label: "页面页码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Props",
    Label: "属性",
    Type: EnumMiezzFormItemType.InputText,
  },
]
