import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c8cee92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_badge = _resolveComponent("el-badge")!

  return (
      _ctx.model.BadgeNumber != null &&
      _ctx.model.BadgeNumber != undefined &&
      _ctx.model.BadgeNumber > 0
    )
    ? (_openBlock(), _createBlock(_component_el_badge, {
        key: 0,
        size: "small",
        value: _ctx.model.BadgeNumber,
        class: "item"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            style: _normalizeStyle(
        (_ctx.model.Height ? ` height:${_ctx.model.Height};` : '') +
        (_ctx.model.Width ? ` width:${_ctx.model.Width};` : '') +
        (_ctx.model.CancelPadding ? ' padding: 0px;' : '') +
        (_ctx.model.Active ? ' color: #fe995b;' : '') +
        (_ctx.model.Color ? ' color: ' + _ctx.model.Color + ';' : '')
      ),
            class: _normalizeClass(_ctx.model.Code + (_ctx.model.Breathe ? ' breathe' : '')),
            plain: _ctx.model.Plain,
            type: _ctx.model.Type,
            size: _ctx.model.Size,
            icon: _ctx.model.Icon,
            disabled: _ctx.model.Disabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBeforeClick()))
          }, _createSlots({ _: 2 }, [
            (_ctx.model.Label || _ctx.model.MiezzIcon)
              ? {
                  name: "default",
                  fn: _withCtx(() => [
                    (_ctx.model.MiezzIcon && !_ctx.model.RightIcon)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: _normalizeClass(`icon iconfont icon-miezz${_ctx.model.MiezzIcon}`)
                        }, null, 2))
                      : _createCommentVNode("", true),
                    (_ctx.model.Label)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          style: _normalizeStyle(_ctx.model.MiezzIcon ? 'margin-left:6px' : '')
                        }, [
                          (_ctx.model.Icon)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.model.Label), 1))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                style: _normalizeStyle(_ctx.model.IsBlue == true ? 'color: #1890ff;' : '')
                              }, _toDisplayString(_ctx.model.Label), 5)),
                          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                        ], 4))
                      : _createCommentVNode("", true),
                    (_ctx.model.MiezzIcon && _ctx.model.RightIcon)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          class: _normalizeClass(`icon iconfont icon-miezz${_ctx.model.MiezzIcon}`)
                        }, null, 2))
                      : _createCommentVNode("", true),
                    (_ctx.isDropdown == true)
                      ? (_openBlock(), _createBlock(_component_el_icon, {
                          key: 3,
                          class: "el-icon--right"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_arrow_down)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["style", "class", "plain", "type", "size", "icon", "disabled"])
        ]),
        _: 3
      }, 8, ["value"]))
    : (_ctx.model.Hide != true)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 1,
          style: _normalizeStyle(
      (_ctx.model.Height ? ` height:${_ctx.model.Height};` : '') +
      (_ctx.model.Width ? ` width:${_ctx.model.Width};` : '') +
      (_ctx.model.CancelPadding ? ' padding: 0px;' : '') +
      (_ctx.model.Active ? ' color: #fe995b;' : '') +
      (_ctx.model.Color ? ' color: ' + _ctx.model.Color + ';' : '')
    ),
          class: _normalizeClass(_ctx.model.Code + (_ctx.model.Breathe ? ' breathe' : '')),
          plain: _ctx.model.Plain,
          type: _ctx.model.Type,
          size: _ctx.model.Size,
          icon: _ctx.model.Icon,
          disabled: _ctx.model.Disabled,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleBeforeClick()))
        }, _createSlots({ _: 2 }, [
          (_ctx.model.Label || _ctx.model.MiezzIcon)
            ? {
                name: "default",
                fn: _withCtx(() => [
                  (_ctx.model.MiezzIcon && !_ctx.model.RightIcon)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(`icon iconfont icon-miezz${_ctx.model.MiezzIcon}`)
                      }, null, 2))
                    : _createCommentVNode("", true),
                  (_ctx.model.Label)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        style: _normalizeStyle(_ctx.model.MiezzIcon ? 'margin-left:6px' : '')
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.model.Label), 1),
                        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                      ], 4))
                    : _createCommentVNode("", true),
                  (_ctx.model.MiezzIcon && _ctx.model.RightIcon)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 2,
                        class: _normalizeClass(`icon iconfont icon-miezz${_ctx.model.MiezzIcon}`)
                      }, null, 2))
                    : _createCommentVNode("", true),
                  (_ctx.isDropdown == true)
                    ? (_openBlock(), _createBlock(_component_el_icon, {
                        key: 3,
                        class: "el-icon--right"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_arrow_down)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["style", "class", "plain", "type", "size", "icon", "disabled"]))
      : _createCommentVNode("", true)
}