
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrderPoolAssignClientUser {
    constructor() {
        //无参构造函数
        this.Ids = [];
    }

    [proName: string]: any;
    /**
     * 委托书Id
     */
    Ids?: string[];
    /**
     * 委托人Id
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
}

/**
 * -表单校验
 */
export const DtoRequestOrderPoolAssignClientUserRules = {
    ClientUserLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}
