import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_page_list = _resolveComponent("company-page-list")!

  return (_openBlock(), _createBlock(_component_company_page_list, {
    type: _ctx.type,
    list: _ctx.list,
    companyTypes: [],
    serviceProviders: []
  }, null, 8, ["type", "list"]))
}