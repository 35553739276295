import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormJoinCompanyUserPassword extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 原密码
     */
    Password?: string;
    /**
     * 新密码
     */
    NewPassword?: string;
    /**
     * 确认新密码
     */
    SurePassword?: string;
}

/**
 * -表单校验
 */
export const DtoFormJoinCompanyUserPasswordRules = {
    Password: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
    NewPassword: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
    SurePassword: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormJoinCompanyUserPasswordMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Password",
    Label: "原密码",
    Type: EnumMiezzFormItemType.InputPassword,
  },
  {
    Prop: "NewPassword",
    Label: "新密码",
    Type: EnumMiezzFormItemType.InputPassword,
  },
  {
    Prop: "SurePassword",
    Label: "确认新密码",
    Type: EnumMiezzFormItemType.InputPassword,
  },
]
