
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import PackageAppService from "@/apis.machine/PackageAppService";
    import DtoDetailPackage, {
        DtoDetailPackageMiezzDetailItems,
    } from "@/models.machine/DtoDetailPackage";
    import PackageForm from "./PackageForm.vue";

    @Options({
        components: {
            PackageForm,
        },
    })
    export default class PackageDetail extends Vue {
        client?: OSS;
        @Model() modelValue!: MiezzModal;
        id = "";
        detail = new MiezzForm<DtoDetailPackage, string>();
        random = 0;
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "edit",
                Label: "编辑",
                Icon: "edit",
                Type: "text",
                Size: "small",
                Hide: true,
            },
            //{
            //    Code: "delete",
            //    Label: "删除",
            //    Icon: "delete",
            //    Type: "text",
            //    Size: "small",
            //    Hide: true,
            //},
        ];
        menuTab = new MiezzMenuTab();
        /*不为空表示是从包装列表中点击进入*/
        /*10表示非混装 */
        /*20表示混装 */
        type = "";

        /*产品主键*/
        /*如果是空的，表示是从包装列表中进入，查询所有产品 */
        productId = "";

        /**权限编码 */
        powerCodes?: string[] = [];
        /*在产品包装处是否具有编辑的权限*/
        isProductPackageEdit?: boolean;
        /*在包装列表处是否具有编辑的权限*/
        isPackageEdit?: boolean;

        /*在产品包装处是否具有编辑的权限*/
        isProductPackageDelete?: boolean;
        /*在包装列表处是否具有删除的权限*/
        isPackageDelete?: boolean;
        imageForm: {
            Visiable: boolean,
            Rows?: any[],
        } = {
                Visiable: false,
                Rows: [],
            };

        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.productId = this.$route.params.id as string;
            this.id = this.$route.params.packageId as string;
            //详细-数据-初始化
            this.detail.Data = new DtoDetailPackage();
            //详细-元素配置
            this.detail.Items = DtoDetailPackageMiezzDetailItems;

            this.handleGet();
            this.onPower();

            this.menuTab.Back = true;
            this.menuTab.Url = `/product/package/detail/${this.productId}/${this.id}`;
            
            var fullPath = this.$route.fullPath;
            if (fullPath.indexOf("/product/package/detail/") != -1) {
                this.menuTab.Menus = [
                    {
                        Label: "产品包装详情",
                        Url: `/product/package/detail/${this.productId}/${this.id}`,
                        Power: "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_Detail",
                    },
                ];
            }
            if (fullPath.indexOf("/packages/detail/") != -1) {
                this.menuTab.Menus = [
                    {
                        Label: "产品包装详情",
                        Url: `/packages/detail/${this.id}`,
                        Power: "ProductManagement_PackingList_Detail",
                    },
                ];
            }
            
            this.detail.FixTop = "40px";
        }

        async onPower(): Promise<void> {
            this.powerCodes = await CurrentLogier.GetPowerCodes();
            this.isProductPackageEdit =
                this.powerCodes &&
                this.powerCodes.contains(
                    "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_Detail_Edit"
                );
            this.isPackageEdit =
                this.powerCodes &&
                this.powerCodes.contains("ProductManagement_PackingList_Detail_Edit");

            this.isProductPackageDelete =
                this.powerCodes &&
                this.powerCodes.contains(
                    "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_Delete"
                );
            this.isPackageDelete =
                this.powerCodes &&
                this.powerCodes.contains("ProductManagement_PackingList_Delete");

            if (this.bottomButtons) {
                if (
                    this.productId != null &&
                    this.productId != undefined &&
                    this.productId != ""
                ) {
                    if (this.isProductPackageEdit == true) {
                        this.bottomButtons[1].Hide = false;
                    }
                    //if (this.isProductPackageDelete == true) {
                    //    this.bottomButtons[2].Hide = false;
                    //}
                } else {
                    if (this.isPackageEdit == true) {
                        this.bottomButtons[1].Hide = false;
                    }
                    //if (this.isPackageDelete == true) {
                    //    this.bottomButtons[2].Hide = false;
                    //}
                }
            }
        }

        onImage(row: any): void {
            var rows = [];
            rows.push(row);
            this.imageForm.Rows = rows;
            this.imageForm.Visiable = true;
        }

        /**查询详细 */
        handleGet(): void {
            if (this.id) {
                PackageAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.detail.Data = r.data.Data;
                    if (
                        this.detail.Data.Product != null &&
                        this.detail.Data.Product != undefined
                    ) {
                        this.type = "10";
                    } else {
                        this.type = "20";
                    }

                    if (this.detail.Data.PackageImages != undefined && this.detail.Data.PackageImages.length > 0) {
                        for (var annex of this.detail.Data.PackageImages) {
                            if (annex.Url != undefined) {
                                annex.Name = annex.Url.split('/')[annex.Url.split('/').length - 1];
                                annex.Url = this.client?.signatureUrl(annex.Url as string);
                            }
                        }
                    }

                    this.detail.Data.ProductName = "";
                    this.detail.Data.ChargeUnithZh = "";
                    this.detail.Data.PackageNameZh = "";
                    if (this.detail.Data.PackageZh) {
                        this.detail.Data.PackageNameZh = this.detail.Data.PackageZh.Name;
                    }
                    if (this.detail.Data.Product) {
                        this.detail.Data.ProductName = this.detail.Data.Product.NameZh;
                        this.detail.Data.ChargeUnithZh =
                            this.detail.Data.Product.ChargeUnithZh;
                        if (
                            this.detail.Data.Product.NameEn &&
                            this.detail.Data.Product.NameEn != ""
                        ) {
                            this.detail.Data.ProductName +=
                                "/" + this.detail.Data.Product.NameEn;
                        }
                    }
                    if (this.type == "20") {
                        if (this.menuTab.Menus) 
                            this.menuTab.Menus[0].Label = this.menuTab.Menus[0].Label +"（"+ "混装" + "）"
                    }
                    if (this.type == "10") {
                        if (this.menuTab.Menus) 
                            this.menuTab.Menus[0].Label = this.menuTab.Menus[0].Label +"（"+ "非混装" + "）"
                    }
                    
                    this.random = Math.random();
                });
            }
        }
        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (
                url != null &&
                typeof url != "undefined" &&
                url != "undefined" &&
                url != ""
            ) {
                this.$router.push(url);
            } else {
                history.go(-1);
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            var companyId = this.$route.query.CompanyId as string;
            switch (btn.Code) {
                case "edit":
                    if (
                        this.productId != null &&
                        this.productId != undefined &&
                        this.productId != ""
                    ) {
                        // this.$router.push(
                        //     "/product/package/edit/" +
                        //     this.productId +
                        //     "/" +
                        //     this.id +
                        //     "?CompanyId=" +
                        //     companyId +
                        //     "&backUrl=" +
                        //     this.$route.fullPath
                        // );
                        this.$router.push({
                            path: `/product/package/edit/` + this.productId +"/"+ this.id,
                            query: { 
                                CompanyId: companyId,
                                backUrl: this.$route.fullPath 
                            },
                        });
                    } else {
                        // this.$router.push(
                        //     "/packages/edit/" +
                        //     this.id +
                        //     "?CompanyId=" +
                        //     companyId +
                        //     "&backUrl=" +
                        //     this.$route.fullPath
                        // );
                        this.$router.push({
                            path: `/packages/edit/` + this.id,
                            query: { 
                                CompanyId: companyId,
                                backUrl: this.$route.fullPath 
                            },
                        });
                    }
                    complete();
                    break;
                case "delete":
                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            PackageAppService.Delete([this.id as string])
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleBack();
                                    complete();
                                })
                                .catch(complete);
                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }
