
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyInvoice from "@/models.machine/DtoPageListSearchCompanyInvoice";
import DtoListItemCompanyInvoice from "@/models.machine/DtoListItemCompanyInvoice";
import CompanyInvoicePageList from "@/views/Company/CompanyInvoice/CompanyInvoicePageList.vue";
@Options({
  components: {
    CompanyInvoicePageList,
  },
})
export default class SupplierPublicCompanyInvoicePageList extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchCompanyInvoice,
    DtoListItemCompanyInvoice,
    string
  >("SupplierManagement_Public_Invoice");
}
