
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import DtoSelectSearchCompany from "@/models.machine/DtoSelectSearchCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PricePageList from "../PricePageList.vue";
@Options({
  components: {
    PricePageList,
  },
})
export default class PriceAgreementPageList extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Agreement"
  );
  amountType = EnumPriceAmountType.Agreement;
  companies?: DtoSelectOptionCompany[] = [];
  companyId?: string;
  tableId?: string;

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "download",
        Label: "下载模板",
        Icon: "download",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Agreement_Import",
      },
      {
        Code: "import",
        Label: "导入",
        Icon: "top",
        Type: "primary",
        Size: "small",
        FileUpload: true,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PowerCode: "PriceManagement_Agreement_Import",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Agreement__Export",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Agreement_History",
      },
      {
        Code: "formula",
        Title: "公式",
        MiezzIcon: "formula",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Agreement_Formula",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "amount-update",
        Label: "批量编辑",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_EditPrice",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_Export",
      },
      {
        Code: "add-formula",
        Label: "添加公式",
        MiezzIcon: "formula",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_Formula",
      },
      {
        Code: "delete-formula",
        Label: "删除公式",
        MiezzIcon: "formula",
        Type: "text",
        Message: "是否确认将该信息公式删除？",
        PowerCode: "PriceManagement_Agreement_Formula",
      },
      {
        Code: "lock",
        Label: "锁定",
        Icon: "Lock",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_EditPrice",
      },
      {
        Code: "un-lock",
        Label: "解锁",
        Icon: "Unlock",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_EditPrice",
      },
      {
        Code: "sync",
        Label: "同步",
        Icon: "share",
        Type: "text",
        PowerCode: "PriceManagement_Agreement_Sync",
      },
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];

    this.tableId = this.$route.query.TableId as string;
    CompanyAppService.GetSelect({
      CompanyType: EnumCompanyType.Customer,
      AmountType: EnumPriceAmountType.Agreement,
      PriceTableId: this.tableId,
    }).then((r) => {
      this.companies = r.data.Data ?? [];
      const page = JSON.parse(localStorage.getItem(this.$route.path) ?? "{}");
      if (
        this.$route.query.CompanyId &&
        this.companies.any((it) => it.Id == this.$route.query.CompanyId)
      ) {
        this.companyId = this.$route.query.CompanyId as string;
      } else {
        if (
          page.companyId &&
          this.companies.any((it) => it.Id == page.companyId)
        ) {
          this.companyId = page.companyId;
        } else {
          this.companyId = this.companies.firstOrDefault()?.Id;
        }
      }
      page.companyId = this.companyId;
      localStorage.setItem(this.$route.path, JSON.stringify(page));
    });
  }

  handleRefreshCompaniesOrUsers(tableId?: string): void {
    this.tableId = tableId;
    CompanyAppService.GetSelect({
      CompanyType: EnumCompanyType.Customer,
      PriceTableId: tableId,
      AmountType: EnumPriceAmountType.Agreement,
    }).then((r) => {
      this.companies = r.data.Data ?? [];
    });
  }
}
