import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26d8da8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event)),
      onHandleLinkAfter: _ctx.handleLinkAfter
    }, null, 8, ["modelValue", "onHandleLinkAfter"]),
    _createVNode(_component_miezz_card, {
      fix: true,
      fixTop: "30px"
    }, {
      default: _withCtx(() => [
        (_ctx.tab == 'BasicInfo')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_miezz_form, {
                modelValue: _ctx.detail,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.detail) = $event)),
                isDetail: true
              }, {
                "form-item": _withCtx(({ item }) => [
                  (item.Prop == 'BusinessLicense')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        prop: item.Prop,
                        label: item.Label,
                        style: {"border-top":"1px solid"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.BusinessLicense,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.BusinessLicense) = $event)),
                            accept: "image/*",
                            maxSize: 2 * 1024 * 1024,
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop", "label"]))
                    : _createCommentVNode("", true),
                  (item.Prop == 'Logo')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        prop: item.Prop,
                        label: item.Label,
                        style: {"border-top":"1px solid"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.Logo,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail.Data.Logo) = $event)),
                            accept: "image/*",
                            maxSize: 2 * 1024 * 1024,
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop", "label"]))
                    : _createCommentVNode("", true),
                  (item.Prop == 'CompanyFiles')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        style: {"border-top":"1px solid"},
                        prop: item.Prop,
                        label: item.Label
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.CompanyFiles,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.detail.Data.CompanyFiles) = $event)),
                            prop: "File",
                            preview: true
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["prop", "label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_row, { style: {"margin-top":"-45px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        prop: _ctx.detail.Data.BusinessLicense,
                        label: "营业执照",
                        "label-width": "150px",
                        style: {"border-top":"1px solid","padding-top":"3px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.BusinessLicense,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.detail.Data.BusinessLicense) = $event)),
                            accept: "image/*",
                            maxSize: 2 * 1024 * 1024,
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["prop"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        prop: _ctx.detail.Data.Logo,
                        label: "Logo",
                        "label-width": "150px",
                        style: {"border-top":"1px solid","padding-top":"3px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.Logo,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.detail.Data.Logo) = $event)),
                            accept: "image/*",
                            maxSize: 2 * 1024 * 1024,
                            preview: true,
                            width: '40px'
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["prop"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        style: {"border-top":"1px solid","padding-top":"3px"},
                        "label-width": "150px",
                        prop: _ctx.detail.Data.CompanyFiles,
                        label: "附件"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_upload, {
                            modelValue: _ctx.detail.Data.CompanyFiles,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.detail.Data.CompanyFiles) = $event)),
                            prop: "File",
                            preview: true
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["prop"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_miezz_button_bar, {
                modelValue: _ctx.buttons,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.buttons) = $event)),
                onHandleClick: _ctx.handleClick
              }, null, 8, ["modelValue", "onHandleClick"])
            ]))
          : (_ctx.settings && _ctx.settings.length > 0)
            ? (_openBlock(), _createBlock(_component_el_table, {
                key: 1,
                data: _ctx.settings,
                stripe: "",
                onRowDblclick: _ctx.handleRowDbClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    type: "index",
                    width: "50"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "Name",
                    label: "参数名称"
                  }),
                  _createVNode(_component_el_table_column, { label: "操作" }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_miezz_button, {
                        model: {
                Code: 'link',
                Icon: 'search',
                Title: '查看',
                Type: 'primary',
                Size: 'small',
              },
                        onHandleClick: (arg1, arg2) => _ctx.handleSettingClick(arg1, row, arg2)
                      }, null, 8, ["onHandleClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "onRowDblclick"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}