import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import DtoDetailBill_OrderPool from "./DtoDetailBill_OrderPool";
import DtoDetailBill_Company from "./DtoDetailBill_Company";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoDetailBill_CompanyType from "./DtoDetailBill_CompanyType";
import DtoDetailBill_ParamPriceCost from "./DtoDetailBill_ParamPriceCost";
import DtoDetailBill_ParamCurrencySystem from "./DtoDetailBill_ParamCurrencySystem";
import DtoDetailBill_ParamTransportChargeUnit from "./DtoDetailBill_ParamTransportChargeUnit";
import UiGroup from "./UiGroup";

/**
 * 账单详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailBill extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsShare = false;
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 是否已分摊，有委托书主键的账单默认为已分摊
     */
    IsShare?: boolean;
    /**
     * 分摊账单的主键
     */
    ParentId?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoDetailBill_OrderPool;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * 委托人
     */
    ClientCompany?: DtoDetailBill_Company;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyType?: DtoDetailBill_CompanyType;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompany?: DtoDetailBill_Company;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 费用名称
     */
    CostId?: string;
    /**
     * 费用名称
     */
    Cost?: DtoDetailBill_ParamPriceCost;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoDetailBill_ParamCurrencySystem;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 第一计价单位的数量
     */
    FirstChargeUnitNumber?: number;
    /**
     * 第一计价单位
     */
    FirstChargeUnitId?: string;
    /**
     * 第一计价单位
     */
    FirstChargeUnit?: DtoDetailBill_ParamTransportChargeUnit;
    /**
     * 第二计价单位的数量
     */
    SecondChargeUnitNumber?: number;
    /**
     * 第二计价单位
     */
    SecondChargeUnitId?: string;
    /**
     * 第二计价单位
     */
    SecondChargeUnit?: DtoDetailBill_ParamTransportChargeUnit;
    /**
     * 合计
     */
    TotalPrice?: number;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 记账起始时间
     */
    StartDate?: Date;
    /**
     * 账期
     */
    AccountPeriod?: number;
}

/**
 * 账单详细-表单校验
 */
export const DtoDetailBillRules = {
}

/**
 * 账单详细-设置项
 */
export const DtoDetailBillMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumBillTypeOption,
  },
  {
    Prop: "OrderId",
    Label: "订单主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsShare",
    Label: "是否已分摊，有委托书主键的账单默认为已分摊",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "ParentId",
    Label: "分摊账单的主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PoolId",
    Label: "委托书主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Pool",
    Label: "委托书",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientCompanyId",
    Label: "委托人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierSource",
    Label: "供应商来源",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumSupplierSourceOption,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "供应商类型Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyType",
    Label: "供应商类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyTypeLabel",
    Label: "供应商类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商Id",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompany",
    Label: "供应商",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "供应商联系人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CostId",
    Label: "费用名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Cost",
    Label: "费用名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystem",
    Label: "币制",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FirstChargeUnitNumber",
    Label: "第一计价单位的数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FirstChargeUnitId",
    Label: "第一计价单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FirstChargeUnit",
    Label: "第一计价单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SecondChargeUnitNumber",
    Label: "第二计价单位的数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SecondChargeUnitId",
    Label: "第二计价单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SecondChargeUnit",
    Label: "第二计价单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TotalPrice",
    Label: "合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "StartDate",
    Label: "记账起始时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "AccountPeriod",
    Label: "账期",
    Type: EnumMiezzFormItemType.Text,
  },
]
