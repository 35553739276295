
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoFormOrderCost, {
  DtoFormOrderCostMiezzListItems,
  DtoFormOrderCostRules,
} from "@/models.machine/DtoFormOrderCost";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
import MiezzButton from "@/models/MiezzButton";
import DtoFormOrderPool_OrderPoolSupplierCost, {
  DtoFormOrderPool_OrderPoolSupplierCostMiezzListItems,
} from "@/models.machine/DtoFormOrderPool_OrderPoolSupplierCost";
import BillAppService from "@/apis.machine/BillAppService";
export default class OrderFormCost extends Vue {
  @Model() form!: MiezzForm<
    {
      Id?: string;
      Costs: DtoFormOrderCost[];
      SupplierCosts: DtoFormOrderPool_OrderPoolSupplierCost[];
      SettlementCosts: DtoFormOrderCost[];
    },
    string
  >;
  @Prop() currencySystems: UiSelectOption<string>[] = [];
  @Prop() hideCosts?: boolean;
  @Prop() showSupplierCosts?: boolean;
  @Prop() showSettlementCosts?: boolean;

  /**费用-列表配置 */
  listCosts = new MiezzPageList<any, DtoFormOrderCost, string>();
  /**费用-表单校验 */
  rules: any = DtoFormOrderCostRules;
  suppliers: UiSelectOption<string>[] = [];
  /**费用-费用选择器数据 */
  costs: UiSelectOption<string>[] = [];
  /**费用-物流计价单位选择器数据 */
  transportChargeUnits: UiSelectOption<string>[] = [];

  /**费用-列表配置 */
  listSupplierCosts = new MiezzPageList<
    any,
    DtoFormOrderPool_OrderPoolSupplierCost,
    string
  >();
  listSettlementCosts = new MiezzPageList<any, DtoFormOrderCost, string>();

  created(): void {
    this.listCosts.Fix = false;
    this.listCosts.HideCard = true;
    this.listCosts.EnabledDynamicSearch = false;
    this.listCosts.EnabledRadioSearch = false;
    this.listCosts.EnabledSelect = false;
    this.listCosts.EnabledPagination = false;
    this.listCosts.Items = DtoFormOrderCostMiezzListItems;
    this.listCosts.Data = {
      Items: [],
    };
    this.listCosts.RowButtons = [
      {
        Code: "delete",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.listSupplierCosts.Fix = false;
    this.listSupplierCosts.HideCard = true;
    this.listSupplierCosts.EnabledDynamicSearch = false;
    this.listSupplierCosts.EnabledRadioSearch = false;
    this.listSupplierCosts.EnabledSelect = false;
    this.listSupplierCosts.EnabledPagination = false;
    this.listSupplierCosts.Items =
      DtoFormOrderPool_OrderPoolSupplierCostMiezzListItems;
    this.listSupplierCosts.Data = {
      Items: [],
    };
    this.listSupplierCosts.RowButtons = [
      {
        Code: "delete-supplier-cost",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.listSettlementCosts.Fix = false;
    this.listSettlementCosts.HideCard = true;
    this.listSettlementCosts.EnabledDynamicSearch = false;
    this.listSettlementCosts.EnabledRadioSearch = false;
    this.listSettlementCosts.EnabledSelect = false;
    this.listSettlementCosts.EnabledPagination = false;
    this.listSettlementCosts.Items = DtoFormOrderCostMiezzListItems;
    this.listSettlementCosts.Data = {
      Items: [],
    };
    this.listSettlementCosts.RowButtons = [
      {
        Code: "delete-settlement-cost",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];
    BillAppService.GetClientCompanyUser({
      PoolId: this.form.Data.Id,
      Type: 2,
    }).then((r) => {
      this.suppliers = r.data.Data ?? [];
    });
    ParamPriceCostAppService.GetSelect().then((r) => {
      this.costs = r.data.Data ?? [];
    });
    ParamTransportChargeUnitAppService.GetSelect().then((r) => {
      this.transportChargeUnits = r.data.Data ?? [];
    });

    this.rules.CostSecondChargingNumber = [
      {
        validator: (rule: any, value: DtoFormOrderCost, callback: any) => {
          if (
            value.SecondChargingUnitId &&
            value.SecondChargingNumber == undefined
          ) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.rules.PreSupplierLabel = [
      {
        validator: (
          rule: any,
          value: DtoFormOrderPool_OrderPoolSupplierCost,
          callback: any
        ) => {
          if (!value.PreSupplierLabel) {
            return callback(new Error("信息不能为空"));
          } else {
            const supplier = this.suppliers.firstOrDefault(
              (it) => it.Label == value.PreSupplierLabel
            );
            if (!supplier) {
              return callback(new Error("无匹配信息"));
            } else {
              value.SupplierCompanyId = supplier.Data.CompanyId;
              value.SupplierUserId = supplier.Data.UserId;
              value.SupplierLabel = value.PreSupplierLabel.replace(
                "（C）",
                ""
              ).replace("（S）", "");
              callback();
            }
          }
        },
        trigger: "blur",
      },
      {
        validator: (
          rule: any,
          value: DtoFormOrderPool_OrderPoolSupplierCost,
          callback: any
        ) => {
          if (!value.PreSupplierLabel) {
            return callback(new Error("信息不能为空"));
          } else {
            const supplier = this.suppliers.firstOrDefault(
              (it) => it.Label == value.PreSupplierLabel
            );
            if (!supplier) {
              return callback(new Error("无匹配信息"));
            } else {
              value.SupplierCompanyId = supplier.Data.CompanyId;
              value.SupplierUserId = supplier.Data.UserId;
              value.SupplierLabel = value.PreSupplierLabel.replace(
                "（C）",
                ""
              ).replace("（S）", "");
              callback();
            }
          }
        },
        trigger: "change",
      },
    ];

    if (this.listCosts.Data) this.listCosts.Data.Items = this.form.Data.Costs;
    if (this.listSupplierCosts.Data)
      this.listSupplierCosts.Data.Items = this.form.Data.SupplierCosts;
    if (this.listSettlementCosts.Data)
      this.listSettlementCosts.Data.Items = this.form.Data.SettlementCosts;
  }

  filterSuppliersMathod(
    queryString: string,
    cb: any,
    row?: DtoFormOrderPool_OrderPoolSupplierCost
  ): void {
    if (queryString) {
      cb(
        this.suppliers
          .filter((it) => it.Label?.contains(queryString))
          .slice(0, 30)
      );
    } else {
      cb(this.suppliers.filter((it) => !it.Data.UserId).slice(0, 10));
    }
  }

  /**费用-添加按钮 */
  btnAddCost: MiezzButton = {
    Code: "add-cost",
    Label: "添加",
    Icon: "plus",
    Type: "text",
  };
  btnAddSupplierCost: MiezzButton = {
    Code: "add-supplier-cost",
    Label: "添加",
    Icon: "plus",
    Type: "text",
  };
  btnAddSettlementCost: MiezzButton = {
    Code: "add-settlement-cost",
    Label: "添加",
    Icon: "plus",
    Type: "text",
  };
  /**费用-行按钮点击 */
  handleCostRowButtonClick(
    btn: MiezzButton,
    row: DtoFormOrderCost,
    complete: () => void,
    index: number
  ): void {
    switch (btn.Code) {
      //删除
      case "delete":
        this.form.Data?.Costs?.splice(index, 1);
        complete();
        break;
      case "delete-supplier-cost":
        this.form.Data?.SupplierCosts?.splice(index, 1);
        complete();
        break;
      case "delete-settlement-cost":
        this.form.Data?.SettlementCosts?.splice(index, 1);
        complete();
        break;
      default:
        break;
    }
  }

  /**费用-计算单项合计 */
  handleCalcCostTotalPrice(index: number): void {
    if (this.form.Data.Costs) {
      const item = this.form.Data.Costs[index];
      let price: number | undefined;
      if (item && item.UnitPrice && item.FirstChargingNumber) {
        price = item.UnitPrice * item.FirstChargingNumber;
        if (item.SecondChargingNumber) {
          price = price * item.SecondChargingNumber;
        }
      }
      item.TotalPrice = price ? parseFloat(price.toRound(2)) : undefined;
    }
  }

  /**费用-计算单项合计 */
  handleCalcSupplierCostTotalPrice(index: number): void {
    if (this.form.Data.SupplierCosts) {
      const item = this.form.Data.SupplierCosts[index];
      let price: number | undefined;
      if (item && item.UnitPrice && item.FirstChargingNumber) {
        price = item.UnitPrice * item.FirstChargingNumber;
        if (item.SecondChargingNumber) {
          price = price * item.SecondChargingNumber;
        }
      }
      item.TotalPrice = price ? parseFloat(price.toRound(2)) : undefined;
    }
  }

  /**费用-计算单项合计 */
  handleCalcSettlementCostTotalPrice(index: number): void {
    if (this.form.Data.SettlementCosts) {
      const item = this.form.Data.SettlementCosts[index];
      let price: number | undefined;
      if (item && item.UnitPrice && item.FirstChargingNumber) {
        price = item.UnitPrice * item.FirstChargingNumber;
        if (item.SecondChargingNumber) {
          price = price * item.SecondChargingNumber;
        }
      }
      item.TotalPrice = price ? parseFloat(price.toRound(2)) : undefined;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //费用-添加
      case "add-cost":
        this.form.Data.Costs?.push({});
        complete();
        break;
      case "add-supplier-cost":
        this.form.Data.SupplierCosts?.push({});
        complete();
        break;
      case "add-settlement-cost":
        this.form.Data.SettlementCosts?.push({});
        complete();
        break;
      default:
        break;
    }
  }
}
