import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 付款条款详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPaymentTerms extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    Name?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 英文简写
     */
    Abbr?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 是否信用证
     */
    IsCredit?: boolean;
}

/**
 * 付款条款详细-表单校验
 */
export const DtoDetailPaymentTermsRules = {
}

/**
 * 付款条款详细-设置项
 */
export const DtoDetailPaymentTermsMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Abbr",
    Label: "英文简写",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsCredit",
    Label: "是否信用证",
    Customize: true,
  },
]
