import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchParamCountryArea from "../models.machine/DtoPageListSearchParamCountryArea";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListParamCountryArea from "../models.machine/DtoPageListParamCountryArea";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamCountryArea from "../models.machine/DtoDetailParamCountryArea";
import DtoFormParamCountryArea from "../models.machine/DtoFormParamCountryArea";
import DtoSelectSearchParamCountryArea from "../models.machine/DtoSelectSearchParamCountryArea";
import DtoSelectOptionParamCountryArea from "../models.machine/DtoSelectOptionParamCountryArea";
import DtoRequestAMapPlaceText from "../models.machine/DtoRequestAMapPlaceText";
import DtoCallbackAMapPlaceTextPoi from "../models.machine/DtoCallbackAMapPlaceTextPoi";
import DtoListItemParamCountryArea_Repeat from "../models.machine/DtoListItemParamCountryArea_Repeat";

/**
 * 地区
 * @description 自动生成的代码,请勿修改
 */
export default class ParamCountryAreaAppService {
    /**
     * 获取地区分页列表
     * @param {DtoPageListSearchParamCountryArea} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamCountryArea>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamCountryArea): AxiosPromise<RESTfulResult<DtoPageListParamCountryArea>> {
        const url = `/api/ParamCountryArea/Get`;
        return service.get<RESTfulResult<DtoPageListParamCountryArea>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取地区详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamCountryArea>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamCountryArea>> {
        const url = `/api/ParamCountryArea/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamCountryArea>>(url);
    }

    /**
     * 新增/编辑地区
     * @param {DtoFormParamCountryArea} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamCountryArea): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除地区
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 同步国外数据，从锐思的数据库同步
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncAbroadData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/SyncAbroadData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 地区选择器
     * @param {DtoSelectSearchParamCountryArea} [search] 
     * @returns {AxiosPromise<RESTfulResult<DtoSelectOptionParamCountryArea[]>>} 
     */
    static GetSelect(search?: DtoSelectSearchParamCountryArea): AxiosPromise<RESTfulResult<DtoSelectOptionParamCountryArea[]>> {
        const url = `/api/ParamCountryArea/GetSelect`;
        return service.get<RESTfulResult<DtoSelectOptionParamCountryArea[]>>(url, { params: search });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCountryArea/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamCountryArea/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCountryArea/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 使用高德地图查询POI
     * @param {DtoRequestAMapPlaceText} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackAMapPlaceTextPoi[]>>} POI集合
     */
    static GetAMapPlaceTextPoiByKeywords(dto?: DtoRequestAMapPlaceText): AxiosPromise<RESTfulResult<DtoCallbackAMapPlaceTextPoi[]>> {
        const url = `/api/ParamCountryArea/GetAMapPlaceTextPoiByKeywords`;
        return service.get<RESTfulResult<DtoCallbackAMapPlaceTextPoi[]>>(url, { params: dto });
    }

    /**
     * 获取数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamCountryArea_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamCountryArea_Repeat[]>> {
        const url = `/api/ParamCountryArea/GetList`;
        return service.get<RESTfulResult<DtoListItemParamCountryArea_Repeat[]>>(url);
    }

    /**
     * 地区参数全名更新
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateFullName(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCountryArea/UpdateFullName`;
        return service.put<RESTfulResult<any>>(url);
    }

}
