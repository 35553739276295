import UiSelectOption from "./UiSelectOption";

/**
 * 订单待办状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderTaskStatus {
    /**
     * 马上处理
     */
    Executing = 20,
    /**
     * 已完成
     */
    Completed = 30,
    /**
     * 暂未开始
     */
    Canceled = 40,
}

/**
 * 订单待办状态-选项
 */
export const EnumOrderTaskStatusOption: UiSelectOption<number>[] = [
    { Label: "马上处理", Value: 20, Data: "Executing" },
    { Label: "已完成", Value: 30, Data: "Completed" },
    { Label: "暂未开始", Value: 40, Data: "Canceled" },
];
