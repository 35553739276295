import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderDepartureAnnex from "./DtoFormOrderDepartureAnnex";
import UiGroup from "./UiGroup";

/**
 * 装货港-报关表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutImportAnnex extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Data = [];
        this.Prerecording = [];
        this.Release = [];
        this.Other = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 装货港关务主键
     */
    OrderDepartureId?: string;
    /**
     * 资料
     */
    Data?: DtoFormOrderDepartureAnnex[];
    /**
     * 预录单
     */
    Prerecording?: DtoFormOrderDepartureAnnex[];
    /**
     * 放行通知书
     */
    Release?: DtoFormOrderDepartureAnnex[];
    /**
     * 其他
     */
    Other?: DtoFormOrderDepartureAnnex[];
    /**
     * 是否退税
     */
    IsTax?: boolean;
}

/**
 * 装货港-报关表单-表单校验
 */
export const DtoPutImportAnnexRules = {
}

/**
 * 装货港-报关表单-设置项
 */
export const DtoPutImportAnnexMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Data",
    Label: "资料",
    Customize: true,
  },
  {
    Prop: "Prerecording",
    Label: "预录单",
    Customize: true,
  },
  {
    Prop: "Release",
    Label: "放行通知书",
    Customize: true,
  },
  {
    Prop: "Other",
    Label: "其他",
    Customize: true,
  },
]
