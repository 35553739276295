
import DtoListItemCompanyBusinessCardBank from "@/models.machine/DtoListItemCompanyBusinessCardBank";
import DtoPageListSearchCompanyBusinessCardBank from "@/models.machine/DtoPageListSearchCompanyBusinessCardBank";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyBusinessCardBankPageList from "../../CompanyBusinessCardBank/CompanyBusinessCardBankPageList.vue";
@Options({
  components: {
    CompanyBusinessCardBankPageList,
  },
})
export default class CurrentCompanyBank extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchCompanyBusinessCardBank,
    DtoListItemCompanyBusinessCardBank,
    string
  >("CompanyManagement_Company_Bank");

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CompanyManagement_Company_Bank_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_Bank_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CompanyManagement_Company_Bank_Delete",
      },
    ];
  }
}
