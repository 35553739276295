
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompanyOvertimeRule, {
  DtoDetailCompanyOvertimeRuleMiezzDetailItems,
} from "@/models.machine/DtoDetailCompanyOvertimeRule";
import DtoDetailCompanyOvertimeRule_OvertimeRule, {
  DtoDetailCompanyOvertimeRule_OvertimeRuleMiezzListItems,
} from "@/models.machine/DtoDetailCompanyOvertimeRule_OvertimeRule";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CurrentCompanyOvertimeRuleDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompanyOvertimeRule, string>();
  id?: string;
  list = new MiezzPageList<
    any,
    DtoDetailCompanyOvertimeRule_OvertimeRule,
    string
  >();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "edit",
      Label: "编辑",
      Icon: "edit",
      Type: "text",
      Size: "small",
    },
  ];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "待办时效规则",
        Power: "CompanyManagement_Company_OvertimeRule",
      },
    ];

    this.id = this.$route.params.id as string;
    this.detail.Data = new DtoDetailCompanyOvertimeRule();
    this.detail.Items = JSON.copy(DtoDetailCompanyOvertimeRuleMiezzDetailItems);
    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items = DtoDetailCompanyOvertimeRule_OvertimeRuleMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };

    this.handleGet();
  }

  handleGet(): void {
    CompanyAppService.GetOvertimeRuleById(this.id).then((r) => {
      if (r.data.Data) {
        this.detail.Data = r.data.Data;
        if (this.list.Data) this.list.Data.Items = r.data.Data.OvertimeRules;
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.$router.push(`/org/company/overtime-rule/edit/${this.id}`);
        complete();
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
