import UiTreeNode from "./UiTreeNode";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoTreePower extends UiTreeNode<DtoTreePower, string> {
    constructor() {
        //无参构造函数
        super()
        this.Right = false;
        this.Children = [];
        this.Check = false;
    }

    [proName: string]: any;
    /**
     * 右侧
     */
    Right?: boolean;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 右侧项
     */
    RightItems?: DtoTreePower[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoTreePowerRules = {
}
