
import DtoFormCodeDynamicSearchItem from "@/models.machine/DtoFormCodeDynamicSearchItem";
import { EnumCompare, EnumCompareOption } from "@/models.machine/EnumCompare";
import {
  EnumUiDynamicSearchItemType,
  EnumUiDynamicSearchItemTypeOption,
} from "@/models.machine/EnumUiDynamicSearchItemType";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Ref, Model } from "vue-property-decorator";
import CodeChoosePropertyTag from "./CodeChoosePropertyTag.vue";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
import CodeMethodAppService from "@/apis.machine/CodeMethodAppService";
import CodeMirror from "codemirror";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/mode/clike/clike";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/seti.css";
import DtoFormCodePropertyBaseInfo from "@/models.machine/DtoFormCodePropertyBaseInfo";
import MiezzButton from "@/models/MiezzButton";
import CodeDtoAppService from "@/apis.machine/CodeDtoAppService";

@Options({
  components: {
    CodePropertyTree,
    CodeChoosePropertyTag,
  },
})
export default class CodeDynamicSearchForm extends Vue {
  /**选中的节点 */
  @Model() modelValue: DtoFormCodeDynamicSearchItem[] = [];
  @Prop() table?: string;

  EnumUiDynamicSearchItemTypeOption = EnumUiDynamicSearchItemTypeOption;
  EnumCompareOption = EnumCompareOption;
  rules?: any;

  current?: DtoFormCodeDynamicSearchItem;

  modal = new MiezzModal();
  quotes: DtoFormCodePropertyBaseInfo[] = [];

  preview = "";
  modalPreview = new MiezzModal();

  created(): void {
    this.modalPreview.Title = "动态搜索-预览";
    const required = {
      required: true,
      message: "信息不能为空",
      trigger: ["blur", "change"],
    };
    const repeat = {
      trigger: "blur",
      validator: (rule: any, value: any, callback: any) => {
        const models = this.modelValue.filter((it) => {
          const item = it as any;
          let field = rule.field as string;
          field = field.substr(field.lastIndexOf(".") + 1);
          return item[field] == value;
        });
        if (models.length > 1) {
          callback(new Error("重复"));
        } else {
          callback();
        }
      },
    };
    this.rules = {
      Prop: [required, repeat],
      Label: [required, repeat],
      Type: [required],
      Compares: [required],
    };
  }

  handleAdd(): void {
    const ruleForm: any = this.$refs["ruleForm"];
    ruleForm.validate((v: boolean) => {
      if (v) {
        const item = new DtoFormCodeDynamicSearchItem();
        item.Quotes = [];
        this.handleTypeChange(item);
        this.modelValue.push(item);
      }
    });
  }

  handlePreview(): void {
    CodeDtoAppService.GetDynamicSearchPreviewByTable(
      this.table,
      this.modelValue
    ).then((r) => {
      if (r.data.Data) {
        this.preview = r.data.Data;
        this.modalPreview.Show = true;
        setTimeout(() => {
          const cm = CodeMirror.fromTextArea(this.$refs.codemirror as any, {
            lineNumbers: true,
            matchBrackets: true,
            mode: "text/x-csharp",
            theme: "seti",
          });
          cm.setSize("100%", "auto");
        }, 100);
      }
    });
  }

  handleQuote(item: DtoFormCodeDynamicSearchItem): void {
    this.current = item;
    if (item.Quotes) this.quotes = JSON.parse(JSON.stringify(item.Quotes));
    this.modal.Show = true;
  }

  handleDelete(item: DtoFormCodeDynamicSearchItem): void {
    this.modelValue.splice(this.modelValue.indexOf(item), 1);
  }

  handleClose(): void {
    this.modal.Show = false;
  }

  /**按钮点击 */
  handleModalButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        if (this.current) {
          this.current.Quotes = this.quotes;
          const quote = this.quotes[0];
          this.current.Prop = "DynamicSearch_" + quote.Name;
          this.current.Label = quote.Summary;
          switch (quote.Type) {
            case "string":
              this.current.Type = EnumUiDynamicSearchItemType.InputText;
              break;
            case "int":
            case "short":
            case "long":
            case "decimal":
            case "double":
            case "float":
              this.current.Type = EnumUiDynamicSearchItemType.InputNumber;
              break;
            case "DateTime":
              this.current.Type = EnumUiDynamicSearchItemType.DateTimeRange;
              break;
            case "bool":
              this.current.Type = EnumUiDynamicSearchItemType.Switch;
              break;

            default:
              break;
          }
          this.handleTypeChange(this.current);
        }
        this.handleClose();
        complete();
        break;

      default:
        break;
    }
  }

  handleTypeChange(item: DtoFormCodeDynamicSearchItem): void {
    switch (item.Type) {
      case EnumUiDynamicSearchItemType.InputText:
        item.Compares = [
          EnumCompare.Equal,
          EnumCompare.NoEqual,
          EnumCompare.Contain,
          EnumCompare.NoContain,
        ];
        break;
      case EnumUiDynamicSearchItemType.InputNumber:
        item.Compares = [
          EnumCompare.Equal,
          EnumCompare.NoEqual,
          EnumCompare.GreaterThan,
          EnumCompare.GreaterThanEqual,
          EnumCompare.LessThan,
          EnumCompare.LessThanEqual,
        ];
        break;
      case EnumUiDynamicSearchItemType.DateTimeRange:
        item.Compares = [EnumCompare.Between, EnumCompare.NoBetween];
        break;
      case EnumUiDynamicSearchItemType.Switch:
        item.Compares = [EnumCompare.Equal];
        break;
      case EnumUiDynamicSearchItemType.Customize:
        item.Compares = [EnumCompare.Equal, EnumCompare.NoEqual];
        break;

      default:
        item.Compares = [];
        break;
    }
  }
}
