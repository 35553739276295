import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 公司发票分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyInvoice extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Default = false;
    }

    [proName: string]: any;
    /**
     * 公司名称
     */
    Name?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 公司注册地址
     */
    RegisteredAddress?: string;
    /**
     * 电话号码
     */
    Tel?: string;
    /**
     * 开户银行
     */
    Bank?: string;
    /**
     * 银行账号
     */
    BankAccount?: string;
    /**
     * 寄送地址
     */
    SendAddress?: string;
    /**
     * 联系人
     */
    Sender?: string;
    /**
     * 默认
     */
    Default?: boolean;
}

/**
 * 公司发票分页列表-项-表单校验
 */
export const DtoListItemCompanyInvoiceRules = {
}

/**
 * 公司发票分页列表-项-设置项
 */
export const DtoListItemCompanyInvoiceMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "公司名称",
  },
  {
    Prop: "Code",
    Label: "企业代码",
  },
  {
    Prop: "RegisteredAddress",
    Label: "公司注册地址",
  },
  {
    Prop: "Tel",
    Label: "电话号码",
  },
  {
    Prop: "Bank",
    Label: "开户银行",
  },
  {
    Prop: "BankAccount",
    Label: "银行账号",
  },
  {
    Prop: "SendAddress",
    Label: "寄送地址",
  },
  {
    Prop: "Sender",
    Label: "联系人",
  },
]
