
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElCascader, ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoFormJoinCompanyUser, {
  DtoFormJoinCompanyUserRules,
} from "@/models.machine/DtoFormJoinCompanyUser";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoTreeGroup from "@/models.machine/DtoTreeGroup";
import DtoTreeSearchGroup from "@/models.machine/DtoTreeSearchGroup";
import RoleAppService from "@/apis.machine/RoleAppService";
import GroupAppService from "@/apis.machine/GroupAppService";
import ChooseGroup from "./ChooseGroup.vue";
import AuthAppService from "@/apis.machine/AuthAppService";
import {
  EnumUserStatus,
  EnumUserStatusOption,
} from "@/models.machine/EnumUserStatus";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumContactTypeOption } from "@/models.machine/EnumContactType";
import DtoFormJoinCompanyUser_JoinCompanyUserGroup from "@/models.machine/DtoFormJoinCompanyUser_JoinCompanyUserGroup";
import MiezzModal from "@/models/MiezzModal";
import DtoTreePower from "@/models.machine/DtoTreePower";
import ChoosePower from "@/views/Sys/Role/ChoosePower.vue";
import DtoTreeSearchPower from "@/models.machine/DtoTreeSearchPower";
import PowerAppService from "@/apis.machine/PowerAppService";
import DtoSelectSearchCompany from "@/models.machine/DtoSelectSearchCompany";
import Logier from "@/models.machine/Logier";
import { EnumJoinCompanyUserStatusOption } from "@/models.machine/EnumJoinCompanyUserStatus";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";

@Options({
  components: {
    ChooseGroup,
    ChoosePower,
  },
})
export default class UserForm extends Vue {
  @Prop() menuTab?: MiezzMenuTab;
  @Prop() type?: EnumCompanyType;
  @Prop() form!: MiezzForm<DtoFormJoinCompanyUser, string>;
  @Prop() companyId?: string;
  @Prop() companiesLabelKey?: string;
  @Prop() official?: boolean;
  @Prop() trusteeship?: boolean;

  id?: string;
  logier?: Logier;
  buttons: MiezzButton[] = [];

  /**公司选择器 */
  companies?: DtoSelectOptionCompany[] = [];

  /**角色Id */
  roleIds?: string[] = [];
  /**角色选择器 */
  roles?: UiSelectOption<string>[] = [];
  /**组Id */
  groupIds?: string[][] = [];
  /**组级联选择器 */
  groupCascader?: InstanceType<typeof ElCascader>;
  /**组级联选择器-属性 */
  groupProps = {
    multiple: true,
    checkStrictly: true,
    value: "Id",
    label: "Name",
    children: "Children",
  };
  /**组级联选择器-数据 */
  groupTree: DtoTreeGroup[] = [];

  EnumContactTypeOption = EnumContactTypeOption;

  /**权限抽屉 */
  modalPower = new MiezzModal();
  /**权限Id */
  powerIds: string[] = [];
  /**权限树 */
  powerTree: DtoTreePower[] = [];
  EnumCompanyType = EnumCompanyType;

  btnAddContact: MiezzButton = {
    Code: "add-contact",
    Label: "联系方式",
    Icon: "plus",
    Type: "text",
    Size: "small",
  };
  levels: UiSelectOption<number>[] = [];
  EnumJoinCompanyUserStatusOption = EnumJoinCompanyUserStatusOption;
  EnumUserStatusOption = EnumUserStatusOption;

  created(): void {
    this.id = this.$route.params.id as string;
    this.logier = CurrentLogier.GetLogier();

    //表单-数据-初始化
    this.form.Data = new DtoFormJoinCompanyUser();
    this.form.Data.CompanyId =
      this.companyId ?? (this.$route.query.CompanyId as string | undefined);
    //表单-校验
    this.form.Rules = DtoFormJoinCompanyUserRules;
    if (this.form.Items?.any((it) => it.Prop == "Status")) {
      this.form.Rules.Status = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }
    if (this.form.Items?.any((it) => it.Prop == "UserStatus")) {
      this.form.Rules.UserStatus = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }
    this.form.Rules.CompanyId = [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (!value) {
            return callback(new Error("信息不能为空"));
          } else if (!this.id && this.form.Data.UserId) {
            this.form.Data.UserId = undefined;
            setTimeout(() => {
              MiezzForm.validateField(
                this.$refs,
                "ruleForm",
                "UserPhoneNumber"
              );
            }, 100);
          } else {
            MiezzForm.validateField(this.$refs, "ruleForm", "UserPhoneNumber");
          }
          return callback();
        },
        trigger: "change",
      },
    ];
    this.form.Rules.UserPhoneNumber = [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (!value) {
            return callback(new Error("信息不能为空"));
          } else if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
            return callback(new Error("请填写正确的手机号码"));
          } else {
            AuthAppService.CheckUserByAccount(value).then((r) => {
              if (this.form.Data.UserId) {
                //已绑定用户
                if (r.data.Data && this.form.Data.UserId != r.data.Data.Id) {
                  return callback(new Error("该手机号已被占用"));
                } else {
                  callback();
                }
              } else {
                //未绑定用户
                if (r.data.Data) {
                  if (r.data.Data.Status == EnumUserStatus.Disabled) {
                    return callback(new Error("该用户已禁用"));
                  } else if (
                    this.form.Data.CompanyId &&
                    r.data.Data.CompanyTypes?.any(
                      (it) => it.CompanyId == this.form.Data.CompanyId
                    )
                  ) {
                    return callback(new Error("该账号已存在，无法创建"));
                  } else {
                    this.form.Data.UserId = r.data.Data.Id;
                    callback();
                  }
                } else {
                  callback();
                }
              }
            });
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.Name = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ];
    for (const item of this.form.Items ?? []) {
      if (item.Prop == "ServiceProviderCompanyName") {
        item.Type = EnumMiezzFormItemType.Text;
      }
    }
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "submit",
        Label: "保存",
        Icon: "select",
        Type: "text",
        Size: "small",
      },
    ];

    for (let i = 1; i <= 15; i++) {
      this.levels.push({
        Label: i.toString(),
        Value: i,
      });
    }

    this.modalPower.Title = "相关权限";

    this.getCompanies();
    this.handleGet();
  }

  mounted(): void {
    this.groupCascader = this.$refs["groupCascader"] as InstanceType<
      typeof ElCascader
    >;
  }

  getCompanies(): void {
    if (this.companiesLabelKey) {
      const dto = new DtoSelectSearchCompany();
      dto.CompanyType = this.type;
      dto.Trusteeship = this.trusteeship;
      CompanyAppService.GetSelect(dto).then((r) => {
        if (r.data.Data) {
          this.companies = r.data.Data;
          if (
            this.form.Data.CompanyId &&
            this.companies.all((it) => it.Id != this.form.Data.CompanyId)
          ) {
            this.form.Data.CompanyId = undefined;
            ElMessage.warning("您无法代为添加用户");
          }
        }
      });
    }
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      JoinCompanyUserAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          if (
            this.logier?.CompanyType == EnumCompanyType.ServiceProvider &&
            this.type == EnumCompanyType.Customer
          ) {
            if (this.form.Data.UserPhoneNumber) {
              this.form.Items = this.form.Items?.filter(
                (it) => it.Prop != "InviteCode"
              );
            } else {
              this.form.Items = this.form.Items?.filter(
                (it) => it.Prop != "UserPhoneNumber"
              );
            }
          } else {
            this.form.Items = this.form.Items?.filter(
              (it) => it.Prop != "InviteCode"
            );
          }
          this.form.Data.UiGroups = new DtoFormJoinCompanyUser().UiGroups;
          this.selectRoles();
        }
      });
    } else if (
      this.logier?.CompanyType == EnumCompanyType.ServiceProvider &&
      this.type == EnumCompanyType.Customer
    ) {
      JoinCompanyUserAppService.GetInviteCode().then((r) => {
        this.form.Data.InviteCode = r.data.Data;
      });
      this.form.Items = this.form.Items?.filter(
        (it) => it.Prop != "UserPhoneNumber"
      );
    } else {
      this.form.Items = this.form.Items?.filter(
        (it) => it.Prop != "InviteCode"
      );
    }
  }

  selectRoles(): void {
    if (
      this.roles &&
      this.roles.length > 0 &&
      this.form.Data.JoinCompanyUserRoles &&
      this.form.Data.JoinCompanyUserRoles.length > 0
    ) {
      for (const role of this.roles) {
        role.Selected = this.form.Data.JoinCompanyUserRoles.any(
          (it) => it.RoleId == role.Value
        );
      }
    }
  }

  onRoleChanged(v: boolean, id?: string): void {
    const select = this.form.Data.JoinCompanyUserRoles?.firstOrDefault(
      (it) => it.RoleId == id
    );
    if (v && !select) {
      this.form.Data.JoinCompanyUserRoles?.push({ RoleId: id });
    } else if (!v && select) {
      const index = this.form.Data.JoinCompanyUserRoles?.indexOf(select);
      this.form.Data.JoinCompanyUserRoles?.splice(index as number, 1);
    }
  }

  handleShowPower(id?: string): void {
    this.modalPower.Show = true;
    this.powerIds = [];
    this.powerTree = [];
    RoleAppService.GetById(id).then((r) => {
      this.powerIds = r.data.Data?.PowerIds ?? [];
      const dto = new DtoTreeSearchPower();
      dto.CheckIds = this.powerIds;
      dto.OnlyShowCheck = true;
      PowerAppService.GetTree(dto).then((r) => {
        this.powerTree = r.data.Data ?? [];
      });
    });
  }

  onGroupChanged(): void {
    const groups: DtoFormJoinCompanyUser_JoinCompanyUserGroup[] = [];
    for (const groupIds of this.groupIds ?? []) {
      const id = groupIds[groupIds.length - 1];
      const item =
        this.form.Data.JoinCompanyUserGroups?.firstOrDefault(
          (g) => g.GroupId == id
        ) ?? new DtoFormJoinCompanyUser_JoinCompanyUserGroup();
      item.GroupId = id;
      groups.push(item);
    }
    this.form.Data.JoinCompanyUserGroups = groups;
  }

  @Watch("form.Data.User.Name")
  onUserNameChanged(nv?: string, ov?: string): void {
    if (nv != ov && !this.id && this.form.Data.User?.Name) {
      this.form.Data.Nickname = this.form.Data.User.Name;
    }
  }

  @Watch("form.Data.CompanyId")
  onCompanyIdChanged(nv?: string, ov?: string): void {
    if (nv != ov && this.form.Data.CompanyId) {
      //查询部门
      const dtoGroup = new DtoTreeSearchGroup();
      dtoGroup.CompanyId = this.form.Data.CompanyId;
      GroupAppService.GetTree(dtoGroup).then((r) => {
        this.groupTree = r.data.Data ?? [];
        this.groupIds = [];
        if (this.form.Data.JoinCompanyUserGroups) {
          for (const group of this.form.Data.JoinCompanyUserGroups) {
            this.loopTree(group.GroupId as string, this.groupTree, []);
          }
        }
      });

      RoleAppService.GetSelect({
        CompanyId:
          this.type == undefined
            ? this.logier?.CompanyId
            : this.form.Data.CompanyId,
        CompanyType: this.type,
        Official: this.official,
      }).then((r) => {
        if (r.data.Data) {
          this.roles = r.data.Data;
          this.selectRoles();
        }
      });
    }
  }

  loopTree(id: string, parents: DtoTreeGroup[], ids: string[]): void {
    for (const parent of parents) {
      const _ids = [];
      for (const _id of ids) {
        _ids.push(_id);
      }
      _ids.push(parent.Id as string);
      if (parent.Id == id) {
        this.groupIds?.push(_ids);
      } else if (parent.Children) {
        this.loopTree(id, parent.Children, _ids);
      }
    }
  }

  getRoleMinLevel(): number {
    const roleIds = this.form.Data.JoinCompanyUserRoles?.map((it) => it.RoleId);
    if (roleIds && roleIds.length > 0) {
      const levels =
        this.roles
          ?.filter((it) => roleIds?.contains(it.Value))
          .map((it) => it.Data.MinLevel as number) ?? [];
      return Math.max.apply(Math, levels);
    }
    return 0;
  }

  @Watch("form.Data.Level")
  onLevelChange(nv: number, ov: number): void {
    this.$nextTick(() => {
      if (nv != ov) {
        const level = this.getRoleMinLevel();
        if (nv < level) {
          const message = this.roles
            ?.filter(
              (it) =>
                this.form.Data.JoinCompanyUserRoles?.any(
                  (jcur) => jcur.RoleId == it.Value
                ) && (it.Data.MinLevel as number) > nv
            )
            .sort((a, b) => a.Data.MinLevel - b.Data.MinLevel)
            .map((it) => `【${it.Label}】需要${it.Data.MinLevel}级`)
            .join("/");
          ElMessage.warning(`角色：${message}，请先修改角色`);
          this.form.Data.Level = ov;
        }
      }
    });
  }

  @Watch("form.Data.JoinCompanyUserRoles", {
    deep: true,
  })
  onJoinCompanyUserRolesChanged(): void {
    const level = this.getRoleMinLevel();
    if (this.form.Data.Level && level > this.form.Data.Level) {
      ElMessage.warning("已按您所选的角色修改了用户职级");
      this.form.Data.Level = level;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const listUrl = this.$route.path.substring(
      0,
      this.id
        ? this.$route.path.indexOf("/edit")
        : this.$route.path.indexOf("/add")
    );

    switch (btn.Code) {
      case "add-contact":
        this.form.Data.JoinCompanyUserContacts?.push({});
        complete();
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            JoinCompanyUserAppService.Post(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }

  handleDeleteContact(index: number): void {
    this.form.Data.JoinCompanyUserContacts?.splice(index, 1);
  }
}
