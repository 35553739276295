import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumCompanyBusinessCardArea, EnumCompanyBusinessCardAreaOption } from "./EnumCompanyBusinessCardArea";
import { EnumCompanyBusinessCardType, EnumCompanyBusinessCardTypeOption } from "./EnumCompanyBusinessCardType";

/**
 * 公司名片分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchCompanyBusinessCard extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 地区
     */
    Area?: EnumCompanyBusinessCardArea;
    /**
     * 类型
     */
    Type?: EnumCompanyBusinessCardType;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 是否供产品规格型号的货号选择
     */
    IsSpecNumber?: boolean;
    /**
     * 是否供产品规格型号的库存选择
     */
    IsSpecStock?: boolean;
    /**
     * 是否供单证选择
     */
    IsDocument?: boolean;
}

/**
 * 公司名片分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchCompanyBusinessCardRules = {
}

/**
 * 公司名片分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchCompanyBusinessCardDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
