
    import CurrentLogier from "@/apis/CurrentLogier";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import DocumentAppService from "@/apis.machine/DocumentAppService";

    import DtoPageListSearchDocument, { DtoPageListSearchDocumentDynamicSearchItems } from "@/models.machine/DtoPageListSearchDocument";
    import DtoPageListDocument from "@/models.machine/DtoPageListDocument";
    import DtoListItemDocument, {
        DtoListItemDocumentMiezzListItems,
    } from "@/models.machine/DtoListItemDocument";
    import ParamPortAppService from "@/apis.machine/ParamPortAppService";

    import OrderDepartureCustomsAppService from "@/apis.machine/OrderDepartureCustomsAppService";

    export default class DocumentPageList extends Vue {
        id?: string;
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchDocument, DtoListItemDocument, string>();
        //item = new MiezzPageList<DtoPageListSearchOrderDepartureCustoms, DtoListItemOrderDepartureCustoms, string>();

        /**类型集合 */
        types?: UiSelectOption<string>[] = [];
        /**港口集合 */
        ports?: UiSelectOption<string>[] = [];
        /**港口集合 */
        destinationPorts?: UiSelectOption<string>[] = [];
        /**港口集合 */
        tradingCountrys?: UiSelectOption<string>[] = [];
        /**港口集合 */
        arrivalCountrys?: UiSelectOption<string>[] = [];
        //departurePorts?: UiSelectOption<string>[] = [];
        //发货人集合
        consignors?: UiSelectOption<string>[] = [];
        //收获人集合
        consignees?: UiSelectOption<string>[] = [];
        //创建人集合
        creaters?: UiSelectOption<string>[] = [];

        RadioSearch?: string;
        /**
    * 菜单场景-选项
    */
        EnumMenuSceneOption: UiSelectOption<number>[] = [];

        /**创建时 */
        created(): void {
            this.RadioSearch = this.$route.query.RadioSearch as string;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("OrderManagement_DocumentManagement_DocumentOfLogisticsOrder") == true && r?.contains("OrderManagement_DocumentManagement_SelfmadeDocuments") == true) {
                    this.EnumMenuSceneOption.push({ Label: "委托书关联单证", Value: 20, Data: "Order" });
                }
            });


            if (this.types) {
                this.types.push({
                    Label: "出口",
                    Value: "10",
                });

                this.types.push({
                    Label: "进口",
                    Value: "20",
                });
            }

            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchDocument();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchDocumentDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListDocument();
            //列表-列配置
            //this.list.Items = DtoListItemDocumentMiezzListItems;
            this.list.Items = DtoListItemDocumentMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "Type") {
                    item.Width = 20;
                }
                if (item.Prop == "CreatedUserName") {
                    item.Width = 30;
                }
                if (item.Prop == "OrderNumber") {
                    item.Width = 40;
                }
                return item;
            });

            this.list.RadioSearchAllTitle = "自制的单证";
            this.list.RadioSearchItems = this.EnumMenuSceneOption;
            this.list.EnabledRadioSearchAll = true;
            this.list.IsRadioSearch = true;
            this.handleGet();
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.handleGet();
        }

        //:fetch-suggestions="((queryString,cb)=>{searchOptionName(queryString,cb,scope.row)})"
        querySearchDispatchPortAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    for (var i = 0; i < this.ports.length; i++) {
                        if (i < 10) {
                            results.push(this.ports[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDispatchPort(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchDestinationPortAsync(queryString: string, cb: any) {
            var restaurants = this.destinationPorts;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.destinationPorts) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.destinationPorts) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.destinationPorts) {
                    for (var i = 0; i < this.destinationPorts.length; i++) {
                        if (i < 10) {
                            results.push(this.destinationPorts[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDestinationPort(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchTradingCountryAsync(queryString: string, cb: any) {
            var restaurants = this.tradingCountrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.tradingCountrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.tradingCountrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.tradingCountrys) {
                    for (var i = 0; i < this.tradingCountrys.length; i++) {
                        if (i < 10) {
                            results.push(this.tradingCountrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectTradingCountry(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchArrivalCountryAsync(queryString: string, cb: any) {
            var restaurants = this.arrivalCountrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.arrivalCountrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.arrivalCountrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.arrivalCountrys) {
                    for (var i = 0; i < this.arrivalCountrys.length; i++) {
                        if (i < 10) {
                            results.push(this.arrivalCountrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectArrivalCountry(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        /**查询列表 */
        handleGet(): void {
            this.RadioSearch = this.$route.query.RadioSearch as string;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("OrderManagement_DocumentManagement_SelfmadeDocuments") == false) {
                    this.RadioSearch = "20";
                }

                if (this.RadioSearch != '20') {
                    DocumentAppService.GetCreater().then(creater => {
                        if (creater.data.Data) this.creaters = creater.data.Data;
                    })
                    DocumentAppService.GetConsignee().then(consignee => {
                        if (consignee.data.Data) this.consignees = consignee.data.Data;
                    })
                    DocumentAppService.GetConsignor().then(consignor => {
                        if (consignor.data.Data) this.consignors = consignor.data.Data;
                    })
                    //ParamPortAppService.GetSelect().then(port => {
                    //    if (port.data.Data) this.ports = port.data.Data;
                    //})
                    DocumentAppService.GetDeparturePort().then((departurePort) => {
                        if (departurePort.data.Data) this.ports = departurePort.data.Data;
                    })
                    DocumentAppService.GetDestinationPort().then((destinationPort) => {
                        if (destinationPort.data.Data) this.destinationPorts = destinationPort.data.Data;
                    })
                    DocumentAppService.GetTradingCountry().then((tradingCountry) => {
                        if (tradingCountry.data.Data) this.tradingCountrys = tradingCountry.data.Data;
                    })
                    DocumentAppService.GetArrivalCountry().then((arrivalCountry) => {
                        if (arrivalCountry.data.Data) this.arrivalCountrys = arrivalCountry.data.Data;
                    })
                    //列表-顶部按钮
                    this.list.TopButtons = [
                        {
                            IsDropdown: true,
                            Code: "add",
                            Label: "添加资料",
                            Icon: "plus",
                            Type: "primary",
                            Size: "small",
                            PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_NewlyAdded",
                            Options: [
                                {
                                    Code: "10",
                                    Label: "出口",
                                    Size: "small"

                                },
                                {
                                    Code: "20",
                                    Label: "进口",
                                    Size: "small"
                                }
                            ],
                        },
                    ];
                    //列表-行按钮
                    this.list.RowButtons = [
                        {
                            Code: "detail",
                            Title: "查看详细",
                            Icon: "search",
                            Type: "default",
                            Size: "small",
                            PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Detail",
                        },
                        {
                            Code: "copy",
                            Title: "复制",
                            Icon: "DocumentCopy",
                            Type: "default",
                            Size: "small",
                            PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Copy",
                        },
                        //{
                        //    Code: "delete",
                        //    Title: "删除",
                        //    Icon: "delete",
                        //    Type: "default",
                        //    Size: "small",
                        //    PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Delete",
                        //},
                    ];
                    //列表-底部按钮
                    this.list.BottomButtons = [
                        {
                            Code: "batch-delete",
                            Label: "删除",
                            Icon: "delete",
                            Type: "text",
                            MessageBox: true,
                            Size: "small",
                            PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Delete",
                        },
                    ];


                    DocumentAppService.Get(this.list.Dto).then((r) => {
                        this.list.Data = r.data.Data;
                        if (this.list.Data && this.list.Data.Items) {
                            for (var it of this.list.Data.Items) {
                                it.FirstConsignorName = "暂无数据";
                                it.FirstConsigneeName = "暂无数据";
                                if (it.FirstConsignor) {
                                    if (it.FirstConsignor.NameZh) {
                                        it.FirstConsignorName = it.FirstConsignor.NameZh;
                                    }
                                    else {
                                        it.FirstConsignorName = "—";
                                    }
                                    it.FirstConsignorName += "/";
                                    if (it.FirstConsignor.NameEn) {
                                        it.FirstConsignorName += it.FirstConsignor.NameEn;
                                    }
                                    else {
                                        it.FirstConsignorName += "—";
                                    }
                                }
                                if (it.FirstConsignee) {
                                    if (it.FirstConsignee.NameZh) {
                                        it.FirstConsigneeName = it.FirstConsignee.NameZh;
                                    }
                                    else {
                                        it.FirstConsigneeName = "—";
                                    }
                                    it.FirstConsigneeName += "/";
                                    if (it.FirstConsignee.NameEn) {
                                        it.FirstConsigneeName += it.FirstConsignee.NameEn;
                                    }
                                    else {
                                        it.FirstConsigneeName += "—";
                                    }
                                }
                            }
                        }
                    });
                }
                else {

                    OrderDepartureCustomsAppService.GetCreater().then(creater => {
                        if (creater.data.Data) this.creaters = creater.data.Data;
                    })
                    OrderDepartureCustomsAppService.GetConsignee().then(consignee => {
                        if (consignee.data.Data) this.consignees = consignee.data.Data;
                    })
                    OrderDepartureCustomsAppService.GetConsignor().then(consignor => {
                        if (consignor.data.Data) this.consignors = consignor.data.Data;
                    })
                    ParamPortAppService.GetSelect().then(port => {
                        if (port.data.Data) this.ports = port.data.Data;
                    })

                    //列表-顶部按钮
                    this.list.TopButtons = [
                    ];
                    //列表-行按钮
                    this.list.RowButtons = [
                        {
                            Code: "detail",
                            Title: "查看详细",
                            Icon: "search",
                            Type: "default",
                            Size: "small",
                            PowerCode: "OrderManagement_DocumentManagement_DocumentOfLogisticsOrder_Detail",
                        },
                    ];
                    //列表-底部按钮
                    this.list.BottomButtons = [
                    ];


                    OrderDepartureCustomsAppService.Get(this.list.Dto).then((r) => {
                        this.list.Data = r.data.Data;
                        if (this.list.Data && this.list.Data.Items) {
                            for (var it of this.list.Data.Items) {
                                it.FirstConsignorName = "暂无数据";
                                it.FirstConsigneeName = "暂无数据";
                                if (it.FirstConsignor) {
                                    if (it.FirstConsignor.NameZh) {
                                        it.FirstConsignorName = it.FirstConsignor.NameZh;
                                    }
                                    else {
                                        it.FirstConsignorName = "—";
                                    }
                                    it.FirstConsignorName += "/";
                                    if (it.FirstConsignor.NameEn) {
                                        it.FirstConsignorName += it.FirstConsignor.NameEn;
                                    }
                                    else {
                                        it.FirstConsignorName += "—";
                                    }
                                }
                                if (it.FirstConsignee) {
                                    if (it.FirstConsignee.NameZh) {
                                        it.FirstConsigneeName = it.FirstConsignee.NameZh;
                                    }
                                    else {
                                        it.FirstConsigneeName = "—";
                                    }
                                    it.FirstConsigneeName += "/";
                                    if (it.FirstConsignee.NameEn) {
                                        it.FirstConsigneeName += it.FirstConsignee.NameEn;
                                    }
                                    else {
                                        it.FirstConsigneeName += "—";
                                    }
                                }
                            }
                        }
                    });
                }
            });

        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    // this.$router.push("/document/add?type=" + btn.Command + "&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: `/document/add`,
                        query: { 
                            type: btn.Command,
                            backUrl: this.$route.fullPath 
                        },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemDocument,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    if (this.RadioSearch != '20') {
                        this.$router.push({
                            path: `/document/detail/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                        // this.$router.push("/document/detail/" + this.id + "?backUrl=" + this.$route.fullPath);
                    }
                    else {
                        this.$router.push({
                            path: `/order/departure/customs/detail/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                        // this.$router.push("/order/departure/customs/detail/" + this.id + "?backUrl=" + this.$route.fullPath);
                    }
                    complete();
                    break;
                //复制
                case "copy":
                    this.$router.push("/document/copy/" + this.id + "?isCopy=true&backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                case "delete":

                    ElMessageBox.confirm("您确定要删除选中的信息吗？删除后信息将无法恢复，是否继续?", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            DocumentAppService.Delete([this.id as string])
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":

                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个产品包装");
                        return;
                    }
                    ElMessageBox.confirm("您确定要删除选中的信息吗？删除后信息将无法恢复，是否继续?", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            DocumentAppService.Delete(this.list.SelectIds)
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
    }
