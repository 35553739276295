import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderTaskType, EnumOrderTaskTypeOption } from "./EnumOrderTaskType";
import DtoOrderTaskHomeOrderPool_OrderPool from "./DtoOrderTaskHomeOrderPool_OrderPool";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoOrderTaskHomeOrderPool extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumOrderTaskType.AddPool;
    }

    [proName: string]: any;
    /**
     * Booking No.
     */
    BookingNo?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 类型
     */
    TypeName?: string;
    /**
     * 活跃的子目录
     */
    Active?: string;
    /**
     * 待办名称
     */
    Type?: EnumOrderTaskType;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 截关时间
     */
    CustomsEndDate?: Date;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 委托书
     */
    Pool?: DtoOrderTaskHomeOrderPool_OrderPool;
    /**
     * 链接地址
     */
    Link?: string;
}

/**
 * -表单校验
 */
export const DtoOrderTaskHomeOrderPoolRules = {
}

/**
 * -设置项
 */
export const DtoOrderTaskHomeOrderPoolMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BookingNo",
    Label: "Booking No.",
  },
  {
    Prop: "Type",
    Label: "待办名称",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderTaskTypeOption,
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
  },
  {
    Prop: "CustomsEndDate",
    Label: "截关时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Pipe: EnumPipe.Date,
  },
]
