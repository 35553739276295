import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    model: _ctx.modelValue,
    rules: _ctx.rules,
    "label-width": "51px",
    size: "small",
    inline: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "启用",
                prop: "IsLeaf"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    modelValue: _ctx.enabled,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enabled) = $event)),
                    onChange: _ctx.onEnabledChanged
                  }, null, 8, ["modelValue", "onChange"])
                ]),
                _: 1
              }),
              (_ctx.modelValue)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    label: "叶子",
                    prop: "IsLeaf"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_switch, {
                        modelValue: _ctx.modelValue.IsLeaf,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue.IsLeaf) = $event)),
                        onChange: _ctx.onIsLeafChanged
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.modelValue)
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 1,
                    label: "集合",
                    prop: "IsList"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_switch, {
                        modelValue: _ctx.modelValue.IsList,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValue.IsList) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.modelValue)
        ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  (_ctx.modelValue.IsLeaf)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: "类型",
                        prop: "Name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: _ctx.modelValue.Name,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelValue.Name) = $event)),
                            "allow-create": true,
                            placeholder: "空值",
                            clearable: "",
                            filterable: "",
                            class: "input-text"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leafTypes, (o, oi) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: oi,
                                  label: o,
                                  value: o
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 1,
                        label: "类型",
                        prop: "Name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_input, {
                            modelValue: _ctx.modelValue.Name,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelValue.Name) = $event)),
                            class: "input-text",
                            clearable: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_el_form_item, {
                    label: "注释",
                    prop: "Summary"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: _ctx.modelValue.Summary,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modelValue.Summary) = $event)),
                        class: "input-text",
                        clearable: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  (!_ctx.modelValue.IsLeaf)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        label: "基类",
                        prop: "BaseType"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_select, {
                            modelValue: _ctx.modelValue.BaseType,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modelValue.BaseType) = $event)),
                            "allow-create": true,
                            placeholder: "空值",
                            clearable: "",
                            filterable: "",
                            class: "input-text"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baseTypes, (o, oi) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: oi,
                                  label: o,
                                  value: o
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.modelValue && !_ctx.modelValue.IsLeaf)
        ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: "属性",
                    prop: "Properties",
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_code_property_tree, {
                        modelValue: _ctx.modelValue.Properties,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modelValue.Properties) = $event)),
                        table: _ctx.table,
                        show: _ctx.show
                      }, null, 8, ["modelValue", "table", "show"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}