import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderManifestVgm_ByTotal {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 提单号
     */
    BLNO?: string;
    /**
     * 箱型箱数
     */
    Containers?: string;
    /**
     * 英文品名
     */
    NameEn?: string;
    /**
     * 唛头
     */
    MarksNotes?: string;
    /**
     * 件数
     */
    Quantity?: number;
    /**
     * 包装单位
     */
    PackagesKind?: string;
    /**
     * 毛重(KGS)
     */
    GrossWeight?: number;
    /**
     * 体积(CBM)
     */
    Volume?: number;
}

/**
 * -表单校验
 */
export const DtoDetailOrderManifestVgm_ByTotalRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_ByTotalMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BLNO",
    Label: "提单号",
  },
  {
    Prop: "Containers",
    Label: "箱型箱数",
  },
  {
    Prop: "NameEn",
    Label: "英文品名",
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
  },
  {
    Prop: "Quantity",
    Label: "件数",
  },
  {
    Prop: "PackagesKind",
    Label: "包装单位",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
  },
]
