
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderMainBillBusinessCycle {
    constructor() {
        //无参构造函数
        this.Ids = [];
    }

    [proName: string]: any;
    /**
     * 订单Id
     */
    Ids?: string[];
    /**
     * 业务所属周期
     */
    BusinessCycle?: Date;
}

/**
 * -表单校验
 */
export const DtoPutOrderMainBillBusinessCycleRules = {
}
