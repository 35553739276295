import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 某个产品的规格分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchSpec extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
}

/**
 * 某个产品的规格分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchSpecRules = {
}

/**
 * 某个产品的规格分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchSpecDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Name",
    Label: "规格型号名称",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_Number",
    Label: "常用货号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_SpecElements",
    Label: "描述",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
]
