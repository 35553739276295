import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f580006"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_ctx.fetchSuggestions)
    ? (_openBlock(), _createBlock(_component_el_autocomplete, {
        key: 0,
        ref: "ref-miezz-input",
        modelValue: _ctx.input.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.value) = $event)),
        "fetch-suggestions": _ctx.fetchSuggestions,
        "trigger-on-focus": _ctx.triggerOnFocus,
        "value-key": _ctx.valueKey,
        placeholder: _ctx.placeholder ?? '请填写',
        disabled: _ctx.disabled,
        clearable: _ctx.clearable ?? true,
        size: _ctx.size,
        onChange: _ctx.handleChange,
        onBlur: _ctx.handleBlur,
        onSelect: _ctx.handleSelect,
        onKeyup: _ctx.handleKeyup,
        onClear: _ctx.handleClear,
        class: _normalizeClass(`form-item-input-text${_ctx.red ? ' red' : ''}${_ctx.text ? ' text' : ''}`)
      }, {
        suffix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
        ]),
        default: _withCtx(({ item }) => [
          (_ctx.$slots['default'])
            ? _renderSlot(_ctx.$slots, "default", {
                key: 0,
                item: item
              }, undefined, true)
            : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.valueKey ? item[_ctx.valueKey] : item), 1)),
          (_ctx.deleteOption && (!_ctx.checkDeleteOption || _ctx.checkDeleteOption(item)))
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                style: {"float":"right","color":"var(--el-text-color-secondary)","font-size":"13px"},
                onClick: ($event: any) => (_ctx.handleDeleteOption(item, _ctx.input.value))
              }, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Delete)
                  ]),
                  _: 1
                })
              ], 8, _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["modelValue", "fetch-suggestions", "trigger-on-focus", "value-key", "placeholder", "disabled", "clearable", "size", "onChange", "onBlur", "onSelect", "onKeyup", "onClear", "class"]))
    : (_openBlock(), _createBlock(_component_el_input, {
        key: 1,
        ref: "ref-miezz-input",
        modelValue: _ctx.input.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input.value) = $event)),
        type: _ctx.type,
        placeholder: _ctx.text ? '暂无数据' : _ctx.placeholder ?? '请填写',
        disabled: _ctx.disabled,
        rows: _ctx.rows,
        showPassword: _ctx.showPassword,
        clearable: _ctx.clearable,
        size: _ctx.size,
        onChange: _ctx.handleChange,
        onInput: _ctx.handleInput,
        onBlur: _ctx.handleBlur,
        onKeyup: _ctx.handleKeyup,
        onFocus: _ctx.handleFocus,
        onClear: _ctx.handleClear,
        class: _normalizeClass(`form-item-input-text${_ctx.red ? ' red' : ''}${_ctx.text ? ' text' : ''}`)
      }, _createSlots({
        suffix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
        ]),
        _: 2
      }, [
        (_ctx.$slots['prepend'])
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "prepend", {}, undefined, true)
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.translate || _ctx.$slots['append'])
          ? {
              name: "append",
              fn: _withCtx(() => [
                (_ctx.translate)
                  ? (_openBlock(), _createBlock(_component_miezz_button, {
                      key: 0,
                      model: _ctx.btnTranslate,
                      onHandleClick: _ctx.handleClick
                    }, null, 8, ["model", "onHandleClick"]))
                  : _renderSlot(_ctx.$slots, "append", { key: 1 }, undefined, true)
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["modelValue", "type", "placeholder", "disabled", "rows", "showPassword", "clearable", "size", "onChange", "onInput", "onBlur", "onKeyup", "onFocus", "onClear", "class"]))
}