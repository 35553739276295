
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { ElLoading } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
    import ExemptingModeAppService from "@/apis.machine/ExemptingModeAppService";
    import DomesticGoodsPlaceAppService from "@/apis.machine/DomesticGoodsPlaceAppService";
    import PlaceAppService from "@/apis.machine/PlaceAppService";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
    import DocumentAppService from "@/apis.machine/DocumentAppService";
    import BrandAppService from "@/apis.machine/BrandAppService";
    import ExportAppService from "@/apis.machine/ExportAppService";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import HsCodeAppService from "@/apis.machine/HsCodeAppService";
    import DtoFormDocumentProduct, {
        DtoFormDocumentProductMiezzFormItems,
        DtoFormDocumentProductRules,
    } from "@/models.machine/DtoFormDocumentProduct";

    export default class MiezzCurrency extends Vue {
        //默认币制代码，添加时使用，为空的时候界面默认美元
        @Prop() defaultCurrencyCode?: string;
        //默认币制主键，添加时使用，为空的时候界面默认美元
        @Prop() defaultCurrencyId?: string;
        //原产国默认值
        @Prop() defaultOriginCountryName?: string;
        //最终目的国默认值
        @Prop() defaultFinanCountryName?: string;
        //原产国默认值
        @Prop() defaultOriginCountryId?: string;
        //最终目的国默认值
        @Prop() defaultFinanCountryId?: string;
        //业务类型：10出口，20进口
        @Prop() type?: string;
        //单证/报关是否暂存
        @Prop() isTemporary?: boolean;
        //是否显示海关代码
        @Prop() isCustomsCode?: boolean;

        id = "";
        //币制
        currencys: UiSelectOption<string>[] = [];
        //国家
        countrys: UiSelectOption<string>[] = [];
        //征免方式
        exemptingModes: UiSelectOption<string>[] = [];
        //境内货源地
        domesticGoodsPlaces: UiSelectOption<string>[] = [];
        //详细产地
        places: UiSelectOption<string>[] = [];
        //英文包装
        packageEns: UiSelectOption<string>[] = [];
        //计价单位
        chargeUnits: UiSelectOption<string>[] = [];
        //品牌类型
        brands: UiSelectOption<string>[] = [];
        //出口享惠情况
        exports: UiSelectOption<string>[] = [];
        //产品中文别名
        aliassZh: UiSelectOption<string>[] = [];
        //产品英文别名
        aliassEn: UiSelectOption<string>[] = [];
        //HsCode推荐值
        Values?: string[];
        //供选择的推荐值
        Recommends?: string[];
        recommendIndex = -1;
        isLoading = false;
        ruleFormRandom = 0;
        visible = false;
        form = new MiezzForm<DtoFormDocumentProduct, string>();
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "temp",
                Label: "暂存",
                Type: "primary",
                Size: "small",
                Hide: false,
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        created(): void {

            //表单-数据-初始化
            this.form.Data = new DtoFormDocumentProduct();
            //表单-校验
            this.form.Rules = DtoFormDocumentProductRules;
            //表单-元素配置
            this.form.Items = DtoFormDocumentProductMiezzFormItems;

            this.onRule();

        }
        onAddRecommend(val: any, index: number): void {
            this.Recommends = val;
            this.recommendIndex = index;
        }
        querySearchElement(queryString: string, cb: any) {
            var restaurants = this.Recommends;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.Recommends) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.Recommends) {
                    if (it) {
                        var Name = it.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.Recommends) {
                    for (var i = 0; i < this.Recommends.length; i++) {
                        results.push(this.Recommends[i])
                    }
                }
            }
            cb(results);
        }
        handleSelectElement(item: any) {
            if (this.recommendIndex >= 0) {
                if (this.form.Data && this.form.Data.ProductElements) {
                    for (var i = 0; i < this.form.Data.ProductElements.length; i++) {
                        if (i == this.recommendIndex) {
                            this.form.Data.ProductElements[i].Value = item;
                            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductElements[" + i + "].Value");
                        }
                    }
                }
            }
            this.recommendIndex = -1;
        }


        onChangeProductNameZh(): void {
            if (this.form.Data) {
                this.form.Data.ProductNameZh = TrimSpace(this.form.Data.ProductNameZh as string);
            }

            if (this.form.Data.ProductNameZh != null && this.form.Data.ProductNameZh != undefined && this.form.Data.ProductNameZh != "") {
                if (this.form.Data.HsCode != null && this.form.Data.HsCode != undefined && this.form.Data.HsCode != "") {
                    HsCodeAppService.GetByCodeByCode(this.form.Data.HsCode).then((code) => {
                        if (code.data.Data) {
                            var row = code.data.Data;
                            var param = {
                                ProductNameZh: this.form.Data.ProductNameZh,
                                HsCode: this.form.Data.HsCode,
                            };
                            DocumentAppService.GetHsCodeRecommended(param).then(recommended => {

                                this.form.Data.HsCode = row.Code;
                                this.form.Data.TaxRate = row.ExportRebateRate;
                                this.form.Data.Tax = row.EducationalTariff;
                                this.form.Data.StatutoryFirstUnit = row.StatutoryFirstUnit;
                                this.form.Data.StatutorySecondUnit = row.StatutorySecondUnit;
                                this.form.Data.SupervisionConditionsCode = row.CustomsSupervisionConditions;
                                var element = row.Element;
                                if (element) {
                                    var elements = element.split(';');
                                    this.form.Data.ProductElements = [];
                                    var index = 0;
                                    for (var it of elements) {

                                        if (it != "") {
                                            var value = undefined;
                                            var its = it.split(':');
                                            if (its.length == 2) {
                                                if (its[1] == "GTIN" || its[1] == "CAS" || its[1] == "其他" || its[1] == "其它") {
                                                    value = "无";
                                                }
                                                if (its[1] == "品名" && this.form.Data.ProductNameZh) {
                                                    value = this.form.Data.ProductNameZh;
                                                }
                                            }
                                            else if (its.length == 1) {
                                                if (its[0] == "GTIN" || its[0] == "CAS" || its[0] == "其他" || its[0] == "其它") {
                                                    value = "无";
                                                }
                                                if (its[0] == "品名" && this.form.Data.ProductNameZh) {
                                                    value = this.form.Data.ProductNameZh;
                                                }
                                            }

                                            if (it.indexOf("规格") >= 0 || it.indexOf("型号") >= 0) {
                                                value = this.form.Data.SpecName;
                                            }

                                            this.Values = [];
                                            if (recommended.data.Data && recommended.data.Data.length > 0) {
                                                for (var re of recommended.data.Data) {

                                                    if ((it + ";") == re.Name) {
                                                        this.Values = re.Values;
                                                    }
                                                }
                                            }
                                            if (this.Values != undefined && this.Values.length == 1) {
                                                value = this.Values[0];
                                            }

                                            this.form.Data.ProductElements.push({
                                                Name: it + ";",
                                                Value: value,
                                                Sort: index,
                                                Values: this.Values,
                                            });
                                            index++;
                                        }
                                    }
                                    MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "HsCode");
                                    this.onCountStatutoryUnit();
                                }
                            });
                        }
                    })
                }
            }
        }
        onChangeProductNameEn(): void {
            this.form.Data.ProductNameEn = OneSpace(this.form.Data.ProductNameEn as string);
        }

        /*初始化 */
        @Emit()
        onInit(product: DtoFormDocumentProduct | undefined): void {
            this.currencys = [];
            this.countrys = [];
            this.exemptingModes = [];
            this.domesticGoodsPlaces = [];
            this.places = [];
            this.packageEns = [];
            this.chargeUnits = [];
            this.brands = [];
            this.exports = [];
            this.aliassZh = [];
            this.aliassEn = [];

            this.isLoading = true;
            BrandAppService.GetSelect().then(brand => {
                if (brand.data.Data) this.brands = brand.data.Data;
                ExportAppService.GetSelect().then(obj => {
                    if (obj.data.Data) this.exports = obj.data.Data;
                    ParamChargeUnitAppService.GetSelect().then(chargeUnit => {
                        //if (chargeUnit.data.Data) this.chargeUnits = chargeUnit.data.Data;
                        if (chargeUnit.data.Data) {
                            for (var unit of chargeUnit.data.Data) {

                                if (unit.Data.NameEn != null && unit.Data.NameEn != undefined && unit.Data.NameEn != "") {
                                    this.chargeUnits.push({
                                        Label: unit.Data.NameZh + "/" + unit.Data.NameEn,
                                        Value: unit.Value,
                                        Data: unit.Data,
                                    })
                                }
                                else {
                                    this.chargeUnits.push({
                                        Label: unit.Data.NameZh,
                                        Value: unit.Value,
                                        Data: unit.Data,
                                    })
                                }

                            }
                        }
                        PackageEnAppService.GetSelect().then(packageEn => {
                            if (packageEn.data.Data) this.packageEns = packageEn.data.Data;
                            DomesticGoodsPlaceAppService.GetSelect().then(domesticGoodsPlace => {
                                if (domesticGoodsPlace.data.Data) this.domesticGoodsPlaces = domesticGoodsPlace.data.Data;
                                PlaceAppService.GetSelect().then(place => {
                                    if (place.data.Data) this.places = place.data.Data;

                                    ExemptingModeAppService.GetSelect().then(exemptingMode => {
                                        if (exemptingMode.data.Data) this.exemptingModes = exemptingMode.data.Data;
                                        ParamCountryAppService.GetSelect().then(country => {
                                            if (country.data.Data) this.countrys = country.data.Data;
                                            ParamCurrencySystemAppService.GetSelect().then(currency => {
                                                if (currency.data.Data) this.currencys = currency.data.Data;

                                                this.form.Data = new DtoFormDocumentProduct();

                                                if (product != undefined) {

                                                    if (product.ProductId != null && product.ProductId != undefined && product.ProductId != "" && product.ProductId != "null") {
                                                        ProductAppService.GetAliasByProductIdByType(product.ProductId, 0).then(zh => {
                                                            if (zh.data.Data) this.aliassZh = zh.data.Data;
                                                            ProductAppService.GetAliasByProductIdByType(product.ProductId, 1).then(en => {
                                                                if (en.data.Data) this.aliassEn = en.data.Data;

                                                                this.form.Data = JSON.parse(JSON.stringify(product));
                                                                for (var cur of this.currencys) {
                                                                    if (cur.Label && cur.Value) {
                                                                        if (cur.Value == this.form.Data.CurrencySystemId) {
                                                                            this.form.Data.CurrencySystemName = cur.Data.Code;
                                                                            this.form.Data.CurrencySystemId = cur.Value;
                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                                if (this.form.Data.OriginCountryName == null || this.form.Data.OriginCountryName == undefined || this.form.Data.OriginCountryName == "") {
                                                                    this.form.Data.OriginCountryName = this.defaultOriginCountryName;
                                                                    this.form.Data.OriginCountryId = this.defaultOriginCountryId;
                                                                }
                                                                if (this.form.Data.FinanCountryName == null || this.form.Data.FinanCountryName == undefined || this.form.Data.FinanCountryName == "") {
                                                                    this.form.Data.FinanCountryName = this.defaultFinanCountryName;
                                                                    this.form.Data.FinanCountryId = this.defaultFinanCountryId;
                                                                }
                                                                if (this.form.Data.IsTemporary != true && this.bottomButtons) {
                                                                    this.bottomButtons[1].Hide = true;
                                                                }
                                                                if (this.isTemporary == false && this.bottomButtons) {
                                                                    this.bottomButtons[1].Hide = true;
                                                                }

                                                                if (this.form && this.form.Data) {
                                                                    if (this.form.Data.CurrencySystemId == null || this.form.Data.CurrencySystemId == undefined || this.form.Data.CurrencySystemId == "") {
                                                                        for (var c12 of this.currencys) {
                                                                            if (c12.Label) {
                                                                                if (c12.Label?.toLocaleUpperCase().indexOf("USD") >= 0) {
                                                                                    this.form.Data.CurrencySystemId = c12.Value;
                                                                                    this.form.Data.CurrencySystemName = c12.Label;
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                            });
                                                        });
                                                    }
                                                    else {
                                                        this.form.Data = JSON.parse(JSON.stringify(product));
                                                        for (var cur of this.currencys) {
                                                            if (cur.Label && cur.Value) {
                                                                if (cur.Value == this.form.Data.CurrencySystemId) {
                                                                    this.form.Data.CurrencySystemName = cur.Data.Code;
                                                                    this.form.Data.CurrencySystemId = cur.Value;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        if (this.form.Data.OriginCountryName == null || this.form.Data.OriginCountryName == undefined || this.form.Data.OriginCountryName == "") {
                                                            this.form.Data.OriginCountryName = this.defaultOriginCountryName;
                                                            this.form.Data.OriginCountryId = this.defaultOriginCountryId;
                                                        }
                                                        if (this.form.Data.FinanCountryName == null || this.form.Data.FinanCountryName == undefined || this.form.Data.FinanCountryName == "") {
                                                            this.form.Data.FinanCountryName = this.defaultFinanCountryName;
                                                            this.form.Data.FinanCountryId = this.defaultFinanCountryId;
                                                        }
                                                        if (this.form.Data.IsTemporary != true && this.bottomButtons) {
                                                            this.bottomButtons[1].Hide = true;
                                                        }
                                                        if (this.isTemporary == false && this.bottomButtons) {
                                                            this.bottomButtons[1].Hide = true;
                                                        }

                                                        if (this.form && this.form.Data) {
                                                            if (this.form.Data.CurrencySystemId == null || this.form.Data.CurrencySystemId == undefined || this.form.Data.CurrencySystemId == "") {
                                                                for (var c120 of this.currencys) {
                                                                    if (c120.Label) {
                                                                        if (c120.Label?.toLocaleUpperCase().indexOf("USD") >= 0) {
                                                                            this.form.Data.CurrencySystemId = c120.Value;
                                                                            this.form.Data.CurrencySystemName = c120.Label;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                                else {
                                                    if (this.defaultCurrencyCode && this.defaultCurrencyCode != "" && this.defaultCurrencyId && this.defaultCurrencyId != "") {
                                                        this.form.Data.CurrencySystemName = this.defaultCurrencyCode;
                                                        this.form.Data.CurrencySystemId = this.defaultCurrencyId;
                                                    }
                                                    else {
                                                        for (var cur1 of this.currencys) {
                                                            if (cur1.Label && cur1.Value) {
                                                                if (cur1.Label?.indexOf("美元") >= 0) {
                                                                    this.form.Data.CurrencySystemName = cur1.Data.Code;
                                                                    this.form.Data.CurrencySystemId = cur1.Value;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    if (this.isTemporary == false && this.bottomButtons) {
                                                        this.bottomButtons[1].Hide = true;
                                                    }

                                                    if (this.form && this.form.Data) {
                                                        if (this.form.Data.CurrencySystemId == null || this.form.Data.CurrencySystemId == undefined || this.form.Data.CurrencySystemId == "") {
                                                            for (var c121 of this.currencys) {
                                                                if (c121.Label) {
                                                                    if (c121.Label?.toLocaleUpperCase().indexOf("USD") >= 0) {
                                                                        this.form.Data.CurrencySystemId = c121.Value;
                                                                        this.form.Data.CurrencySystemName = c121.Label;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                                this.isLoading = false;
                                                this.ruleFormRandom = Math.random();
                                                this.visible = true;
                                                if (this.form.Data.OriginCountryName == null || this.form.Data.OriginCountryName == undefined || this.form.Data.OriginCountryName == "") {
                                                    this.form.Data.OriginCountryName = this.defaultOriginCountryName;
                                                    this.form.Data.OriginCountryId = this.defaultOriginCountryId;
                                                }
                                                if (this.form.Data.FinanCountryName == null || this.form.Data.FinanCountryName == undefined || this.form.Data.FinanCountryName == "") {
                                                    this.form.Data.FinanCountryName = this.defaultFinanCountryName;
                                                    this.form.Data.FinanCountryId = this.defaultFinanCountryId;
                                                }
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })

                })
            })
        }

        onSelectProduct(): void {
            this.$emit("onSelectProduct", this.form);
            this.visible = false;
        }
        onCurrency(): void {
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(this.form.Data.CurrencySystemId);
        }
        onSelectCurrency(row: any): void {
            this.form.Data.CurrencySystemName = row.Data.Code;
            this.form.Data.CurrencySystemId = row.Value;
        }
        onHsCode(): void {
            const HsCode = this.$refs["HsCode"] as any;
            HsCode.onInit(this.form.Data.HsCode as string);
        }
        onSelectHsCode(row: any): void {
            if (this.form.Data.ProductNameZh != null && this.form.Data.ProductNameZh != undefined && this.form.Data.ProductNameZh != "") {
                var param = {
                    ProductNameZh: this.form.Data.ProductNameZh,
                    HsCode: row.Code,
                };
                DocumentAppService.GetHsCodeRecommended(param).then(recommended => {

                    this.form.Data.HsCode = row.Code;
                    this.form.Data.TaxRate = row.ExportRebateRate;
                    this.form.Data.Tax = row.EducationalTariff;
                    this.form.Data.StatutoryFirstUnit = row.StatutoryFirstUnit;
                    this.form.Data.StatutorySecondUnit = row.StatutorySecondUnit;
                    this.form.Data.SupervisionConditionsCode = row.CustomsSupervisionConditions;
                    var element = row.Element;
                    var elements = element.split(';');
                    this.form.Data.ProductElements = [];
                    var index = 0;
                    for (var it of elements) {

                        if (it != "") {
                            var value = undefined;
                            var its = it.split(':');
                            if (its.length == 2) {
                                if (its[1] == "GTIN" || its[1] == "CAS" || its[1] == "其他" || its[1] == "其它") {
                                    value = "无";
                                }
                                if (its[1] == "品名" && this.form.Data.ProductNameZh) {
                                    value = this.form.Data.ProductNameZh;
                                }
                            }
                            else if (its.length == 1) {
                                if (its[0] == "GTIN" || its[0] == "CAS" || its[0] == "其他" || its[0] == "其它") {
                                    value = "无";
                                }
                                if (its[0] == "品名" && this.form.Data.ProductNameZh) {
                                    value = this.form.Data.ProductNameZh;
                                }
                            }

                            if (it.indexOf("规格") >= 0 || it.indexOf("型号") >= 0) {
                                value = this.form.Data.SpecName;
                            }

                            this.Values = [];
                            if (recommended.data.Data && recommended.data.Data.length > 0) {
                                for (var re of recommended.data.Data) {

                                    if ((it + ";") == re.Name) {
                                        this.Values = re.Values;
                                    }
                                }
                            }
                            if (this.Values != undefined && this.Values.length == 1) {
                                value = this.Values[0];
                            }

                            this.form.Data.ProductElements.push({
                                Name: it + ";",
                                Value: value,
                                Sort: index,
                                Values: this.Values,
                            });
                            index++;
                        }
                    }
                    MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "HsCode");
                    this.onCountStatutoryUnit();
                });
            }
            else {
                this.form.Data.HsCode = row.Code;
                this.form.Data.TaxRate = row.ExportRebateRate;
                this.form.Data.Tax = row.EducationalTariff;
                this.form.Data.StatutoryFirstUnit = row.StatutoryFirstUnit;
                this.form.Data.StatutorySecondUnit = row.StatutorySecondUnit;
                this.form.Data.SupervisionConditionsCode = row.CustomsSupervisionConditions;
                var element1 = row.Element;
                var elements1 = element1.split(';');
                this.form.Data.ProductElements = [];
                var index1 = 0;
                for (var it1 of elements1) {

                    if (it1 != "") {
                        var value1 = undefined;
                        var its1 = it1.split(':');
                        if (its1.length == 2) {
                            if (its1[1] == "GTIN" || its1[1] == "CAS" || its1[1] == "其他" || its1[1] == "其它") {
                                value1 = "无";
                            }
                            if (its1[1] == "品名" && this.form.Data.ProductNameZh) {
                                value1 = this.form.Data.ProductNameZh;
                            }
                        }
                        else if (its1.length == 1) {
                            if (its1[0] == "GTIN" || its1[0] == "CAS" || its1[0] == "其他" || its1[0] == "其它") {
                                value1 = "无";
                            }
                            if (its1[0] == "品名" && this.form.Data.ProductNameZh) {
                                value1 = this.form.Data.ProductNameZh;
                            }
                        }

                        if (it1.indexOf("规格") >= 0 || it1.indexOf("型号") >= 0) {
                            value1 = this.form.Data.SpecName;
                        }

                        this.Values = [];
                        this.form.Data.ProductElements.push({
                            Name: it1 + ";",
                            Value: value1,
                            Sort: index1,
                            Values: this.Values,
                        });
                        index1++;
                    }
                }
                MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "HsCode");
                this.onCountStatutoryUnit();
            }
            

        }
        onChangeBrand(index: number): void {
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductElements[" + index + "].Value");
        }
        onChangeExport(index: number): void {
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductElements[" + index + "].Value");
        }
        onChangeExemptingModeId(): void {
            for (var it of this.exemptingModes) {
                if (it.Value == this.form.Data.ExemptingModeId) {
                    this.form.Data.ExemptingModeName = it.Label;
                }
            }
            if (this.form.Data.ExemptingModeId != undefined) {
                MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ExemptingModeId");
            }
            else {
                MiezzForm.validateField(this.$refs, "ruleFormProduct", "ExemptingModeId");
            }
        }
        onChangeChargeUnit(): void {
            if (this.form.Data.ChargeUnit != undefined) {
                MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ChargeUnit");
            }
            else {
                MiezzForm.validateField(this.$refs, "ruleFormProduct", "ChargeUnit");
            }
            this.onCountStatutoryUnit();
        }
        onChangePackageQuantity(): void {
            if (this.form.Data.PackageQuantity) {
                var result = setDecimal(this.form.Data.PackageQuantity.toString(), 0, false);
                if (result != "") {
                    this.form.Data.PackageQuantity = Number(result);
                }
                else {
                    this.form.Data.PackageQuantity = undefined;
                }
            }
        }
        onChangeTransactionQuantity(): void {
            if (this.form.Data.TransactionQuantity) {
                var result = setDecimal(this.form.Data.TransactionQuantity.toString(), 5, false);
                if (result != "") {
                    this.form.Data.TransactionQuantity = Number(result);
                }
                else {
                    this.form.Data.TransactionQuantity = undefined;
                }
            }

            this.onCountTotalPrice();
            this.onCountStatutoryUnit();
        }
        onChangeUnitPrice(): void {
            if (this.form.Data.UnitPrice) {
                var result = setDecimal(this.form.Data.UnitPrice.toString(), 5, false);
                if (result != "") {
                    this.form.Data.UnitPrice = Number(result);
                }
                else {
                    this.form.Data.UnitPrice = undefined;
                }
            }

            this.onCountTotalPrice();
        }
        onChangeTotalPrice(): void {
            if (this.form.Data.TotalPrice) {
                var result = setDecimal(this.form.Data.TotalPrice.toString(), 5, false);
                if (result != "") {
                    this.form.Data.TotalPrice = Number(result);
                    if (this.form.Data.TransactionQuantity) {
                        var unitPrice = this.form.Data.TotalPrice / this.form.Data.TransactionQuantity;
                        result = setDecimal(unitPrice.toString(), 5, false);
                        if (result != "") {
                            this.form.Data.UnitPrice = Number(result);
                        }
                        else {
                            this.form.Data.UnitPrice = undefined;
                        }
                    }
                }
                else {
                    this.form.Data.TotalPrice = undefined;
                }
            }
        }
        onChangeNetWeight(): void {
            if (this.form.Data.NetWeight) {
                var result = setDecimal(this.form.Data.NetWeight.toString(), 5, false);
                if (result != "") {
                    this.form.Data.NetWeight = Number(result);
                }
                else {
                    this.form.Data.NetWeight = undefined;
                }
            }
        }
        onChangeGrossWeight(): void {
            if (this.form.Data.GrossWeight) {
                var result = setDecimal(this.form.Data.GrossWeight.toString(), 5, false);
                if (result != "") {
                    this.form.Data.GrossWeight = Number(result);
                }
                else {
                    this.form.Data.GrossWeight = undefined;
                }
            }
            this.onCountStatutoryUnit();
        }
        onChangeVolume(): void {
            if (this.form.Data.Volume) {
                var result = setDecimal(this.form.Data.Volume.toString(), 5, false);
                if (result != "") {
                    this.form.Data.Volume = Number(result);
                }
                else {
                    this.form.Data.Volume = undefined;
                }
            }
        }
        onChangeStatutoryFirstUnitValue(): void {

            if (this.form.Data.StatutoryFirstUnitValue) {
                var result = setDecimal(this.form.Data.StatutoryFirstUnitValue.toString(), 5, true);
                if (result != "") {
                    this.form.Data.StatutoryFirstUnitValue = Number(result);
                }
                else {
                    this.form.Data.StatutoryFirstUnitValue = undefined;
                }
            }
        }
        onChangeStatutorySecondUnitValue(): void {

            if (this.form.Data.StatutorySecondUnitValue) {
                var result = setDecimal(this.form.Data.StatutorySecondUnitValue.toString(), 5, true);
                if (result != "") {
                    this.form.Data.StatutorySecondUnitValue = Number(result);
                }
                else {
                    this.form.Data.StatutorySecondUnitValue = undefined;
                }
            }
        }
        onCountStatutoryUnit(): void {

            if (this.form.Data.StatutoryFirstUnit != undefined && this.form.Data.StatutoryFirstUnit != "") {
                if (this.form.Data.StatutoryFirstUnit == this.form.Data.ChargeUnit) {
                    this.form.Data.StatutoryFirstUnitValue = this.form.Data.TransactionQuantity;
                }
                if (this.form.Data.GrossWeight != undefined && this.form.Data.StatutoryFirstUnit == "千克") {
                    this.form.Data.StatutoryFirstUnitValue = this.form.Data.GrossWeight;
                }
            }
            if (this.form.Data.StatutorySecondUnit != undefined && this.form.Data.StatutorySecondUnit != "") {
                if (this.form.Data.StatutorySecondUnit == this.form.Data.ChargeUnit) {
                    this.form.Data.StatutorySecondUnitValue = this.form.Data.TransactionQuantity;
                }
                if (this.form.Data.GrossWeight != undefined && this.form.Data.StatutorySecondUnit == "千克") {
                    this.form.Data.StatutorySecondUnitValue = this.form.Data.GrossWeight;
                }
            }
        }

        onCountTotalPrice(): void {

            if (this.form.Data.TransactionQuantity && this.form.Data.UnitPrice) {
                this.form.Data.TotalPrice = this.form.Data.TransactionQuantity * this.form.Data.UnitPrice;
                var result = setDecimal(this.form.Data.TotalPrice.toString(), 5, false);
                if (result != "") {
                    this.form.Data.TotalPrice = Number(result);
                    MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "TotalPrice");
                }
                else {
                    this.form.Data.TotalPrice = undefined;
                }
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.form.Data.IsTemporary = false;
                    MiezzForm.submit(this.$refs, "ruleFormProduct", (v) => {
                        if (v == true) {
                            this.$emit("onSelectCustomsProduct", this.form.Data);
                            this.visible = false;
                            complete();
                        }
                        else {
                            complete();
                        }
                    });

                    break;
                case "temp":
                    this.form.Data.IsTemporary = true;
                    MiezzForm.validateField(this.$refs, "ruleFormProduct", "ProductNameZh", r => {
                        var result = r.toString().toLowerCase();
                        if (result == "true") {
                            this.$emit("onSelectCustomsProduct", this.form.Data);
                            this.visible = false;
                            complete();
                        }
                        else {
                            setTimeout(() => {
                                const error = document.getElementsByClassName("is-error");
                                error[0]?.querySelector("input")?.focus();
                            }, 1);
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        querySearchFinanCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }

            var results = [];
            if (queryString != null && queryString && queryString != '' && queryString != 'null' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                var number1 = 0;
                if (this.type == "20") {
                    for (var it1 of this.countrys) {
                        if (it1.Label) {
                            var Name1 = it1.Label.toLowerCase();
                            if (Name1.indexOf("中国") >= 0 && number1 < 30) {
                                results.push(it1);
                                number1++;
                            }
                        }
                    }
                }


                if (number1 <= 0) {
                    for (var i = 0; i < this.countrys.length; i++) {
                        if (i < 10) {
                            results.push(this.countrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectFinanCountryId(item: UiSelectOption<string>) {
            this.form.Data.FinanCountryId = item.Value;
            this.form.Data.FinanCountryName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "FinanCountryId");
        }
        onChangeFinanCountryId(): void {
            if (this.form.Data.FinanCountryName == undefined || this.form.Data.FinanCountryName == "") {
                this.form.Data.FinanCountryId = undefined;
            }
            else {
                if (this.form.Data.FinanCountryId == undefined) {
                    this.form.Data.FinanCountryName = undefined;
                }
                else if (this.form.Data.FinanCountryName != undefined) {
                    var flag = false;
                    for (var it of this.countrys) {
                        if (it.Label == this.form.Data.FinanCountryName) {
                            this.form.Data.FinanCountryId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.FinanCountryName = undefined;
                    }
                }
            }
        }
        querySearchOriginCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.countrys) {

                    var number1 = 0;
                    if (this.type == "10") {
                        for (var it1 of this.countrys) {
                            if (it1.Label) {
                                var Name1 = it1.Label.toLowerCase();
                                if (Name1.indexOf("中国") >= 0 && number1 < 30) {
                                    results.push(it1);
                                    number1++;
                                }
                            }
                        }
                    }


                    if (number1 <= 0) {
                        for (var i = 0; i < this.countrys.length; i++) {
                            if (i < 10) {
                                results.push(this.countrys[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectOriginCountryId(item: UiSelectOption<string>) {
            this.form.Data.OriginCountryId = item.Value;
            this.form.Data.OriginCountryName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "OriginCountryId");
        }
        onChangeOriginCountryId(): void {
            if (this.form.Data.OriginCountryName == undefined || this.form.Data.OriginCountryName == "") {
                this.form.Data.OriginCountryId = undefined;
            }
            else {
                if (this.form.Data.OriginCountryId == undefined) {
                    this.form.Data.OriginCountryName = undefined;
                }
                else if (this.form.Data.OriginCountryName != undefined) {
                    var flag = false;
                    for (var it of this.countrys) {
                        if (it.Label == this.form.Data.OriginCountryName) {
                            this.form.Data.OriginCountryId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.OriginCountryName = undefined;
                    }
                }
            }
        }

        querySearchDomesticGoodsPlaceIdAsync(queryString: string, cb: any) {
            var restaurants = this.domesticGoodsPlaces;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.domesticGoodsPlaces) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.domesticGoodsPlaces) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.domesticGoodsPlaces) {
                    for (var i = 0; i < this.domesticGoodsPlaces.length; i++) {
                        if (i < 10) {
                            results.push(this.domesticGoodsPlaces[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDomesticGoodsPlaceId(item: UiSelectOption<string>) {
            this.form.Data.DomesticGoodsPlaceId = item.Value;
            this.form.Data.DomesticGoodsPlaceName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "DomesticGoodsPlaceId");
        }
        onChangeDomesticGoodsPlaceId(): void {
            if (this.form.Data.DomesticGoodsPlaceName == undefined || this.form.Data.DomesticGoodsPlaceName == "") {
                this.form.Data.DomesticGoodsPlaceId = undefined;
            }
            else {
                if (this.form.Data.DomesticGoodsPlaceId == undefined) {
                    this.form.Data.DomesticGoodsPlaceName = undefined;
                }
                else if (this.form.Data.DomesticGoodsPlaceName != undefined) {
                    var flag = false;
                    for (var it of this.domesticGoodsPlaces) {
                        if (it.Label == this.form.Data.DomesticGoodsPlaceName) {
                            this.form.Data.DomesticGoodsPlaceId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.DomesticGoodsPlaceName = undefined;
                    }
                }
            }
        }


        querySearchPlaceIdAsync(queryString: string, cb: any) {
            var restaurants = this.places;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.places) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.places) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.places) {
                    for (var i = 0; i < this.places.length; i++) {
                        if (i < 10) {
                            results.push(this.places[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectPlaceId(item: UiSelectOption<string>) {
            this.form.Data.PlaceId = item.Value;
            this.form.Data.PlaceName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "PlaceId");
        }
        onChangePlaceId(): void {
            if (this.form.Data.PlaceName == undefined || this.form.Data.PlaceName == "") {
                this.form.Data.PlaceId = undefined;
            }
            else {
                if (this.form.Data.PlaceId == undefined) {
                    this.form.Data.PlaceName = undefined;
                }
                else if (this.form.Data.PlaceName != undefined) {
                    var flag = false;
                    for (var it of this.places) {
                        if (it.Label == this.form.Data.PlaceName) {
                            this.form.Data.PlaceId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.PlaceName = undefined;
                    }
                }
            }
        }

        querySearchPackageNameAsync(queryString: string, cb: any) {
            var restaurants = this.packageEns;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.packageEns) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.packageEns) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.packageEns) {
                    for (var i = 0; i < this.packageEns.length; i++) {
                        results.push(this.packageEns[i]);
                    }
                }
            }
            cb(results);
        }
        handleSelectPackageName(item: UiSelectOption<string>) {
            this.form.Data.PackageName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "PackageName");
        }

        querySearchProductNameZhAsync(queryString: string, cb: any) {
            var restaurants = this.aliassZh;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.aliassZh) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.aliassZh) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.aliassZh) {
                    for (var i = 0; i < this.aliassZh.length; i++) {
                        results.push(this.aliassZh[i]);
                    }
                }
            }
            cb(results);
        }
        handleSelectProductNameZh(item: UiSelectOption<string>) {
            this.form.Data.ProductNameZh = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductNameZh");
        }

        querySearchProductNameEnAsync(queryString: string, cb: any) {
            var restaurants = this.aliassEn;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.aliassEn) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.aliassEn) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.aliassEn) {
                    for (var i = 0; i < this.aliassEn.length; i++) {
                        results.push(this.aliassEn[i]);
                    }
                }
            }
            cb(results);
        }
        handleSelectProductNameEn(item: UiSelectOption<string>) {
            this.form.Data.ProductNameEn = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormProduct", "ProductNameEn");
        }

        /*添加自定义验证 */
        onRule(): void {
            this.form.Rules.FinanCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.OriginCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.DomesticGoodsPlaceId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ExemptingModeId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ProductNameZh = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        //^[\u4e00-\u9fa5]{0,}$
                        var reg = new RegExp("^[\u4E00-\u9FA5]{1,20}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("中文名称不能超过20个汉字"));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.ProductNameEn = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        //^[a-zA-Z\s]{4,50}$
                        //^ 表示开头
                        //$表示结束
                        //\s表示空格
                        var reg = new RegExp("^[A-Za-z\\s]{1,128}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("英文名称不能超过128个英文或空格"));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.PackageQuantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.PackageName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.TransactionQuantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.ChargeUnit = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.UnitPrice = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.TotalPrice = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        if (this.form.Data.UnitPrice != undefined) {

                            if (Number(value) < Number(this.form.Data.UnitPrice)) {

                                callback("总价不能低于单价");
                                return false;
                            }
                            callback();
                        }
                        else {
                            callback();
                        }

                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.NetWeight = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        if (this.form.Data.GrossWeight != undefined) {

                            if (Number(value) > Number(this.form.Data.GrossWeight)) {

                                callback("净重应小于毛重");
                                return false;
                            }
                            callback();
                        }
                        else {
                            callback();
                        }

                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.GrossWeight = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        if (this.form.Data.NetWeight != undefined) {

                            if (Number(value) < Number(this.form.Data.NetWeight)) {

                                callback("净重应小于毛重");
                                return false;
                            }
                            callback();
                        }
                        else {
                            callback();
                        }

                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.HsCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];

            this.form.Rules.StatutoryFirstUnitValue = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.StatutorySecondUnitValue = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

            this.form.Rules.FinanCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];


            this.form.Rules.ProductElements_Value = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {

        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };


    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }

    /*去除连续空格，只保留一个空格 */
    function OneSpace(str: string) {

        var name = "";
        if (str != undefined && str != "") {
            var i = 0;
            for (i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (i = 0; i < str.length; i++) {
                if (str[i] != " ") {
                    name += str[i];
                }
                else {
                    if (i > 0 && str[i - 1] != " ") {
                        name += str[i];
                    }
                }
            }
        }
        return name.trim();
    }
