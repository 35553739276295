
/**
 * 发票附件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFromInvoiceAnnex {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 附件地址
     */
    Url?: string;
}

/**
 * 发票附件-表单校验
 */
export const DtoFromInvoiceAnnexRules = {
    Url: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}
