
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import PackageZhAppService from "@/apis.machine/PackageZhAppService";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    import DtoFormPackageZh, {
        DtoFormPackageZhMiezzFormItems,
        DtoFormPackageZhRules,
    } from "@/models.machine/DtoFormPackageZh";
    import UiSelectOption from "@/models.machine/UiSelectOption";

    export default class PackageZhForm extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() id?: string;

        form = new MiezzForm<DtoFormPackageZh, string>();
        NameList: string[] = [];

        //在货号中供选择的发货人、收货人等集合
        packageEns?: UiSelectOption<string>[] = [];
        random = 0;

        created(): void {
            //表单-数据-初始化
            this.form.Data = new DtoFormPackageZh();
            //表单-校验
            this.form.Rules = DtoFormPackageZhRules;
            this.form.Rules.Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.form.Data.Name != null && typeof (this.form.Data.Name) != "undefined" && this.form.Data.Name != "undefined" && this.form.Data.Name != "") {
                            var Name = this.form.Data.Name.trim();
                            if (this.NameList.some(it => it == Name)) {
                                callback(new Error("信息重复，请重新填写"));
                                return false;
                            } else {
                                return callback();
                            }
                        }
                        else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            //表单-元素配置
            this.form.Items = DtoFormPackageZhMiezzFormItems;

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "submit",
                    Label: "保存",
                    Type: "primary",
                    Size: "small",
                },
            ];

            var param = {
                CurrentPage: 1,
                PageSize: 9999999,
                IsSpecNumber: true,
            };
            PackageEnAppService.Get(param).then(r => {

                if (r.data.Data && r.data.Data.Items) {
                    this.packageEns = r.data.Data.Items;
                }
                this.random = Math.random();
                this.handleGet();
            })
        }

        /**查询表单数据 */
        handleGet(): void {
            PackageZhAppService.GetNameList().then((r1) => {
                if (r1.data.Data) this.NameList = r1.data.Data;

                if (this.id) {
                    PackageZhAppService.GetById(this.id).then((r) => {
                        if (r.data.Data) {
                            this.form.Data = r.data.Data;

                            var index = this.NameList.findIndex(it => { return it == this.form.Data.Name });
                            this.NameList.splice(index, 1);
                        }
                    });
                }
            })
            
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            PackageZhAppService.Post(this.form.Data)
                                .then(() => {
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }
    }
