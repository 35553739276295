import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyContact from "../models.machine/DtoPageListSearchCompanyContact";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyContact from "../models.machine/DtoPageListCompanyContact";
import DtoDetailCompanyContact from "../models.machine/DtoDetailCompanyContact";
import DtoFormCompanyContact from "../models.machine/DtoFormCompanyContact";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoSelectSearchCompanyContact from "../models.machine/DtoSelectSearchCompanyContact";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 通讯录
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyContactAppService {
    /**
     * 获取通讯录分页列表
     * @param {DtoPageListSearchCompanyContact} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyContact>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyContact): AxiosPromise<RESTfulResult<DtoPageListCompanyContact>> {
        const url = `/api/CompanyContact/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyContact>>(url, { params: dto });
    }

    /**
     * 获取通讯录详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyContact>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyContact>> {
        const url = `/api/CompanyContact/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyContact>>(url);
    }

    /**
     * 添加通讯录
     * @param {DtoFormCompanyContact} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyContact): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyContact/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除通讯录
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyContact/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取部门选择器
     * @param {string} [companyId] 公司Id
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器数据
     */
    static GetDepartmentSelectByCompanyId(companyId?: string): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CompanyContact/GetDepartmentSelect/${companyId}`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取职务选择器
     * @param {string} [companyId] 公司Id
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器数据
     */
    static GetJobSelectByCompanyId(companyId?: string): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CompanyContact/GetJobSelect/${companyId}`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取通讯录选择器
     * @param {DtoSelectSearchCompanyContact} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchCompanyContact): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/CompanyContact/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

}
