import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";

/**
 * 委托书分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPool extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.EAtd = "暂无数据/暂无数据";
        this.EAta = "暂无数据/暂无数据";
        this.HasTask = false;
        this.Lock = false;
    }

    [proName: string]: any;
    /**
     * 标记
     */
    Color?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 订单编号
     */
    OrderCode?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 发货人内容
     */
    ShipperContent?: string;
    /**
     * 收货人内容
     */
    ConsigneeContent?: string;
    /**
     * 委托人
     */
    Client?: string;
    /**
     * 客户/供应商名称
     */
    CustomerSupplierName?: string;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * ETD/ATD
     */
    EAtd?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 装货港ATD
     */
    ActualDepartureDate?: Date;
    /**
     * 目的港ATA
     */
    ActualArrivalDate?: Date;
    /**
     * ETA/ATA
     */
    EAta?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 直订订单
     */
    OrderDirectOrder?: boolean;
    /**
     * 配载订单
     */
    OrderStowageOrder?: boolean;
    /**
     * 委托书数量
     */
    OrderPoolCount?: number;
    /**
     * 订单锁定
     */
    OrderLock?: boolean;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 委托公司Id
     */
    ClientCompanyId?: string;
    /**
     * 委托公司
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托公司
     */
    ClientCompanyLabel?: string;
    /**
     * 委托人
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 服务商
     */
    ServiceProviderLabel?: string;
    /**
     * 客服
     */
    CustomerServiceId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
    /**
     * 有待办
     */
    HasTask?: boolean;
    /**
     * 锁定
     */
    Lock?: boolean;
}

/**
 * 委托书分页列表-项-表单校验
 */
export const DtoListItemOrderPoolRules = {
}

/**
 * 委托书分页列表-项-设置项
 */
export const DtoListItemOrderPoolMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Color",
    Label: "标记",
    MaxWidth: "60px",
    Customize: true,
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    MaxWidth: "120px",
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "OrderCode",
    Label: "订单编号",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "编号",
    MaxWidth: "120px",
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
    MaxWidth: "120px",
  },
  {
    Prop: "Client",
    Label: "委托人",
    MaxWidth: "120px",
  },
  {
    Prop: "CustomerSupplierName",
    Label: "客户/供应商名称",
    MaxWidth: "140px",
    Customize: true,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
    MaxWidth: "120px",
  },
  {
    Prop: "EAtd",
    Label: "ETD/ATD",
    MaxWidth: "120px",
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
    MaxWidth: "120px",
  },
  {
    Prop: "EAta",
    Label: "ETA/ATA",
    MaxWidth: "120px",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
    MaxWidth: "120px",
  },
  {
    Prop: "ClientUserId",
    Label: "委托人",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
    MaxWidth: "120px",
  },
  {
    Prop: "CustomerServiceId",
    Label: "客服",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderPoolStatusOption,
  },
]
