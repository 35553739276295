import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button_upload = _resolveComponent("miezz-button-upload")!
  const _component_miezz_button_check = _resolveComponent("miezz-button-check")!
  const _component_miezz_button_color = _resolveComponent("miezz-button-color")!
  const _component_miezz_button_dropdown = _resolveComponent("miezz-button-dropdown")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_miezz_button_confirm = _resolveComponent("miezz-button-confirm")!
  const _component_miezz_button_source = _resolveComponent("miezz-button-source")!

  return (_ctx.model.FileUpload && !_ctx.model.Hide)
    ? (_openBlock(), _createBlock(_component_miezz_button_upload, {
        key: 0,
        model: _ctx.model,
        onHandleUpload: _ctx.handleFileUpload
      }, null, 8, ["model", "onHandleUpload"]))
    : (_ctx.model.Type == 'checkbox' && !_ctx.model.Hide)
      ? (_openBlock(), _createBlock(_component_miezz_button_check, {
          key: 1,
          model: _ctx.model,
          check: _ctx.check,
          onHandleCheck: _ctx.handleClick
        }, null, 8, ["model", "check", "onHandleCheck"]))
      : (_ctx.model.Type == 'color' && !_ctx.model.Hide)
        ? (_openBlock(), _createBlock(_component_miezz_button_color, {
            key: 2,
            model: _ctx.model,
            color: _ctx.color,
            onHandlePicker: _ctx.handleClick
          }, null, 8, ["model", "color", "onHandlePicker"]))
        : (!_ctx.model.Title && _ctx.model.Options && !_ctx.model.Hide)
          ? (_openBlock(), _createBlock(_component_miezz_button_dropdown, {
              key: 3,
              model: _ctx.model,
              onHandleCommand: _ctx.handleClick,
              onHandleDropdowItemUpload: _ctx.handleFileUpload
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["model", "onHandleCommand", "onHandleDropdowItemUpload"]))
          : (_ctx.model.Options && !_ctx.model.Hide)
            ? (_openBlock(), _createBlock(_component_el_tooltip, {
                key: 4,
                effect: "light",
                content: _ctx.model.Title,
                placement: "bottom",
                "show-after": 500
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_button_dropdown, {
                    model: _ctx.model,
                    onHandleCommand: _ctx.handleClick,
                    onHandleDropdowItemUpload: _ctx.handleFileUpload
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]),
                    _: 3
                  }, 8, ["model", "onHandleCommand", "onHandleDropdowItemUpload"])
                ]),
                _: 3
              }, 8, ["content"]))
            : (!_ctx.model.Title && _ctx.model.Message && !_ctx.model.Hide)
              ? (_openBlock(), _createBlock(_component_miezz_button_confirm, {
                  key: 5,
                  model: _ctx.model,
                  onHandleConfirm: _ctx.handleClick
                }, null, 8, ["model", "onHandleConfirm"]))
              : (_ctx.model.Message && !_ctx.model.Hide)
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 6,
                    effect: "dark",
                    content: _ctx.model.Title,
                    placement: "top",
                    "show-arrow": true,
                    offset: -1,
                    "show-after": 500
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_button_confirm, {
                        model: _ctx.model,
                        onHandleConfirm: _ctx.handleClick
                      }, null, 8, ["model", "onHandleConfirm"])
                    ]),
                    _: 1
                  }, 8, ["content"]))
                : (!_ctx.model.Title && !_ctx.model.Hide)
                  ? (_openBlock(), _createBlock(_component_miezz_button_source, {
                      key: 7,
                      model: _ctx.model,
                      isBackground: _ctx.isBackground,
                      onHandleSourceClick: _ctx.handleClick
                    }, null, 8, ["model", "isBackground", "onHandleSourceClick"]))
                  : (!_ctx.model.Hide)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 8,
                        effect: "dark",
                        content: _ctx.model.Title,
                        placement: "top",
                        "show-arrow": true,
                        offset: -1,
                        "show-after": 500
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_button_source, {
                            model: _ctx.model,
                            onHandleSourceClick: _ctx.handleClick
                          }, null, 8, ["model", "onHandleSourceClick"])
                        ]),
                        _: 1
                      }, 8, ["content"]))
                    : _createCommentVNode("", true)
}