
    import DtoRequestBoxSticker from "@/models.machine/DtoRequestBoxSticker";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoListItemOrderPoolFile, {
  DtoListItemOrderPoolFileMiezzListItems,
} from "@/models.machine/DtoListItemOrderPoolFile";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";

    export default class OrderPoolFile extends Vue {
        client?: OSS;
  menuTab = new MiezzMenuTab();
  list = new MiezzPageList<any, DtoListItemOrderPoolFile, string>(
    "OrderManagement_Pool_Summary_File"
  );

  /**创建时 */
    created(): void {
        this.client = AliyunOSS.GetClient();
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/file/${this.$route.params.id}`;
    this.menuTab.Menus = [
      {
        Label: "附件",
        Url: `/order/pool/file/${this.$route.params.id}`,
        Power: "OrderManagement_Pool_Summary_File",
      },
    ];

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    //列表-列配置
    this.list.Items = DtoListItemOrderPoolFileMiezzListItems;
  }

  /**查询列表 */
  handleGet(): void {
    OrderPoolAppService.GetFilesById(this.$route.params.id as string).then(
      (r) => {
        this.list.Data = {
          Items: r.data.Data ?? [],
            };
            if (this.list && this.list.Data && this.list.Data.Items) {
                for (var it of this.list.Data.Items) {
                    if (it.Files && it.Files.length > 0) {
                        for (var f of it.Files) {
                            if (f.File != undefined) {
                                var urls = f.File.split('/');
                                f.Name = urls[urls.length - 1];
                                if (urls[urls.length - 1] != "进仓通知" && urls[urls.length - 1] != "箱贴") {
                                    f.File = this.client?.signatureUrl(f.File) as string;
                                }
                            }
                        }
                    }
                }
            }
      }
    );
    }

    onLink(row: any) {
        var urls = row.File.split('/');
        if (urls[urls.length - 1] == "进仓通知") {
            this.onWarehousingExport(urls[0]);
        }
        else if (urls[urls.length - 1] == "箱贴") {
            this.onBoxStickerExport(urls[0]);
        }
        else {
            //this.$router.push(url);
            window.open(row.File);
        }
    }

    onWarehousingExport(id: any): void {
        OrderDepartureWharfAppService.ExportWarehousingByOrderDepartureWharfId(id)
            .then((r) => {
                window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            }).catch((err) => {
                console.log(err);
            });
    }
    onBoxStickerExport(id: any): void {
        var param = new DtoRequestBoxSticker();
        param.OrderDepartureWharfId = id;
        OrderDepartureWharfAppService.BoxStickerExport(param)
            .then((r) => {
                window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            }).catch((err) => {
                console.log(err);
            });
    }
}
