import Logier from "@/models.machine/Logier";
import router from "@/router";
import service from "@/apis/Service";
import DtoListLogierMenu from "@/models.machine/DtoListLogierMenu";
import MenuAppService from "@/apis.machine/MenuAppService";
import MiezzBreadcrumb from "@/models/MiezzBreadcrumb";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { ElMessage } from "element-plus";
import { RouteLocationRaw } from "vue-router";

/**
 * 当前登录用户
 */
export default class CurrentLogier {
    /**
     * 当前登录用户
     */
    public static CurrentLogier?: Logier;
    /**
     * 即时通讯
     */
    public static HubConnection?: HubConnection;
    public static HubConnectionToken?: string;
    /**
     * 即时通讯Id
     */
    public static HubConnectionId?: string | null;
    /**
     * 即时通讯已停止
     */
    public static HubConnectionStop = false;
    /**
     * 菜单
     */
    public static Menus?: DtoListLogierMenu;
    /**
     * 菜单加载
     */
    public static MenusLoading = false;
    /**
     * 面包屑
     */
    public static Breadcrumbs?: MiezzBreadcrumb[] = [];
    /**
     * 权限编码
     */
    public static PowerCodes?: string[] = [];

    public static guid(): string {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }

    /**
     * 设置即时通讯
     */
    public static SetHubConnection(): void {
        console.log("正在启用即时通讯");
        this.HubConnectionStop = false;
        const logier = this.GetLogier();
        if (!this.HubConnection || this.HubConnectionToken != logier?.Token) {
            let clientId = localStorage.getItem("clientId");
            if (!clientId) {
                clientId = this.guid();
                localStorage.setItem("clientId", clientId);
            }
            this.HubConnectionToken = logier?.Token;
            this.HubConnection = new HubConnectionBuilder()
                .withUrl(`${process.env.VUE_APP_BASE_URL}/hub?token=${logier?.Token}&clientId=${clientId}`, {
                    accessTokenFactory: () => logier?.CompanyUserId as string,
                })
                .build();
            this.HubConnection.on("Log", (message) => {
                console.log("即时通讯-日志消息：" + message);
            });
            this.HubConnection.on("KickOffline", () => {
                ElMessage.warning("您的账号在别的地方登录");
                console.log("您的账号在别的地方登录")
                CurrentLogier.Logout();
            });
            this.StartHubConnection();
            this.HubConnection.onclose(() => {
                if (this.HubConnectionStop == false) {
                    console.log("即时通讯意外断开，正在重新连接");
                    this.StartHubConnection();
                }
            });
        } else if (this.HubConnection.state != "Connected") {
            console.log(`即时通讯连接状态：${this.HubConnection.state}，正在重新连接`);
            this.StartHubConnection();
        }
    }

    public static StartHubConnection(callback?: () => void): void {
        if (this.HubConnection?.state != "Connected") {
            console.log("准备连接即时通讯");
            this.HubConnection?.start().then(() => {
                this.HubConnectionId = this.HubConnection?.connectionId;
                console.log("即时通讯连接成功！", this.HubConnectionId);
                if (callback) {
                    callback();
                }
            }).catch((r) => {
                console.warn(r);
                console.log("即时通讯连接异常！正在重新连接");
                setTimeout(() => {
                    this.StartHubConnection();
                }, 1000);
            });
        }
    }

    public static StopHubConnection(callback?: () => void): void {
        this.HubConnectionStop = true;
        this.HubConnection?.stop().then(() => {
            console.log("已停止即时通讯");
            if (callback) {
                callback();
            }
        });
    }

    /**
     * 保存当前登录用户
     * @param logier 登录用户
     */
    public static SetLogier(logier: Logier): void {
        service.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${logier.Token}`;
        service.defaults.headers.common[
            "X-Authorization"
        ] = `Bearer ${logier.RefreshToken}`;
        localStorage.setItem("logier", JSON.stringify(logier));
        this.CurrentLogier = logier;
    }

    /**
     * 获取当前登录用户
     * @returns 登录用户
     */
    public static GetLogier(autoLogout = true): Logier | undefined {
        if (!this.CurrentLogier) {
            const json = localStorage.getItem("logier");
            if (json) this.CurrentLogier = JSON.parse(json) as Logier;
            else {
                this.CurrentLogier = undefined;
                if (autoLogout) this.Logout();
            }
        }
        return this.CurrentLogier;
    }

    /**
     * 退出登录
     */
    public static Logout(): void {
        localStorage.removeItem("logier");
        this.CurrentLogier = undefined;
        this.StopHubConnection();
        router.push({ path: "/login/password" });
    }

    /**
     * 睡眠
     * @param time 睡眠时间
     */
    public static sleep(time: number): Promise<void> {
        return new Promise((r) => setTimeout(r, time))
    }

    /**
     * 等待菜单加载
     */
    public static async WaitMenusLoading(): Promise<void> {
        if (this.MenusLoading) {
            await this.sleep(100);
            await this.WaitMenusLoading();
        }
    }

    /**
     * 获取菜单
     */
    public static async GetMenus(): Promise<DtoListLogierMenu | undefined> {
        if (this.MenusLoading) {
            await this.WaitMenusLoading();
        }
        if (!this.Menus) {
            this.MenusLoading = true;
            await this.SetMenus();
        }
        this.MenusLoading = false;
        return this.Menus;
    }

    /**
     * 获取权限编码
     */
    public static async GetPowerCodes(): Promise<string[] | undefined> {
        if (!this.PowerCodes || this.PowerCodes.length == 0) {
            const menus = await this.GetMenus();
            this.PowerCodes = menus?.Menus?.map(it => it.Code as string) ?? [];
        }
        return this.PowerCodes;
    }

    /**
     * 保存菜单
     */
    public static async SetMenus(): Promise<void> {
        const r = await MenuAppService.GetLogierMenus();
        this.Menus = r.data.Data;
        this.PowerCodes = this.Menus?.Menus?.map(it => it.Code as string) ?? [];
    }

    /**
     * 获取面包屑
     */
    public static GetBreadcrumbs(): MiezzBreadcrumb[] {
        return this.Breadcrumbs ?? [];
    }

    /**
     * 保存面包屑
     */
    public static SetBreadcrumbs(data: MiezzBreadcrumb[]): void {
        this.Breadcrumbs = data;
    }

    public static LinkWithBack(path: RouteLocationRaw, backUrl?: string): void {
        const route = router.resolve(path);
        console.log(route)
        route.query.backUrl = backUrl ?? router.currentRoute.value.fullPath;
        router.push({
            path: route.path,
            query: route.query
        });
    }

    public static Back(): void {
        const backUrl = router.currentRoute.value.query.backUrl as string;
        if (backUrl) {
            router.push(backUrl);
            console.log(`返回至：${backUrl}`,router.currentRoute.value);
        } else {
            this.BackBreadcrumb();
        }
    }

    /**返回面包屑上一级 */
    public static BackBreadcrumb(): void {
        const breadcrumbs = this.GetBreadcrumbs();
        if (breadcrumbs.length > 1) {
            const breadcrumb = breadcrumbs[breadcrumbs.length - 2];
            let url: string | undefined;
            if (breadcrumb.to) {
                let to = router.resolve(breadcrumb.to)
                const from = router.currentRoute.value;
                for (const key in to.params) {
                    if (to.params[key] == `:${key}` && from.params[key]) {
                        to.params[key] = from.params[key]
                    }
                }
                to = router.resolve(to);
                url = to.fullPath;
                console.log(`面包屑链接：${url}`);
            } else {
                url = this.Menus?.Menus?.firstOrDefault(it => it.Id == breadcrumb.id)?.Url;
                console.log(`菜单链接：${url}`);
            }
            if (url) {
                if (url.contains(":")) {
                    history.go(-1);
                    console.log("返回上一页")
                } else {
                    router.push(url);
                    console.log(`返回至链接`)
                }
            } else {
                router.push("/");
                console.log("返回至首页")
            }
        } else {
            router.push("/");
            console.log("面包屑缺失，返回至首页", breadcrumbs);
        }
    }
}

