import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchDeliveryRequirement from "../models.machine/DtoSelectSearchDeliveryRequirement";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchDeliveryRequirement from "../models.machine/DtoPageListSearchDeliveryRequirement";
import DtoPageListDeliveryRequirement from "../models.machine/DtoPageListDeliveryRequirement";
import DtoDetailDeliveryRequirement from "../models.machine/DtoDetailDeliveryRequirement";
import DtoFormDeliveryRequirement from "../models.machine/DtoFormDeliveryRequirement";

/**
 * 交期要求
 * @description 自动生成的代码,请勿修改
 */
export default class DeliveryRequirementAppService {
    /**
     * 获取申报地海关选择器
     * @param {DtoSelectSearchDeliveryRequirement} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchDeliveryRequirement): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/DeliveryRequirement/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取申报地海关分页列表
     * @param {DtoPageListSearchDeliveryRequirement} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListDeliveryRequirement>>} 
     */
    static Get(dto?: DtoPageListSearchDeliveryRequirement): AxiosPromise<RESTfulResult<DtoPageListDeliveryRequirement>> {
        const url = `/api/DeliveryRequirement/Get`;
        return service.get<RESTfulResult<DtoPageListDeliveryRequirement>>(url, { params: dto });
    }

    /**
     * 获取交期要求详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailDeliveryRequirement>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailDeliveryRequirement>> {
        const url = `/api/DeliveryRequirement/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailDeliveryRequirement>>(url);
    }

    /**
     * 添加交期要求
     * @param {DtoFormDeliveryRequirement} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormDeliveryRequirement): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/DeliveryRequirement/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除交期要求
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/DeliveryRequirement/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

}
