import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailParamFbaWarehouseCode_ParamCountry from "./DtoDetailParamFbaWarehouseCode_ParamCountry";
import UiGroup from "./UiGroup";

/**
 * FBA仓库代码详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamFbaWarehouseCode extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 国家Id
     */
    ParamCountryId?: string;
    /**
     * 国家
     */
    ParamCountry?: DtoDetailParamFbaWarehouseCode_ParamCountry;
    /**
     * 城市
     */
    City?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 代码
     */
    Code?: string;
}

/**
 * FBA仓库代码详细-表单校验
 */
export const DtoDetailParamFbaWarehouseCodeRules = {
}

/**
 * FBA仓库代码详细-设置项
 */
export const DtoDetailParamFbaWarehouseCodeMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家Id",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "City",
    Label: "城市",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Address",
    Label: "地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "代码",
    Type: EnumMiezzFormItemType.Text,
  },
]
