
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzButtonSource extends Vue {
  @Prop() model = new MiezzButton();
  @Prop() isDropdown?: boolean;

  handleBeforeClick(command?: any): void {
    if (this.model.Loading != true && this.model.CancelClickEvent != true) {
      this.model.Command = command;
      this.model.Loading = true;
      this.handleSourceClick(this.model, () => {
        this.model.Loading = false;
      });
    }
  }

  @Emit()
  handleSourceClick(model: MiezzButton, complete: () => void): void {
    //
  }
}
