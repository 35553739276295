import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolPackage from "../models.machine/DtoPageListSearchOrderPoolPackage";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolPackage from "../models.machine/DtoPageListOrderPoolPackage";

/**
 * 委托书-货物清单（包装）
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolPackageAppService {
    /**
     * 获取委托书-货物清单（包装）分页列表
     * @param {DtoPageListSearchOrderPoolPackage} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolPackage>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolPackage): AxiosPromise<RESTfulResult<DtoPageListOrderPoolPackage>> {
        const url = `/api/OrderPoolPackage/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolPackage>>(url, { params: dto });
    }

}
