
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzDateChoose extends Vue {
  @Model() modelValue?: string;
  dates: string[] = [];
  date?: string = "";
  dateSelect?: string = "";

  created(): void {
    if (this.modelValue) {
      console.log("传值：", this.modelValue);
      this.handleChoose(this.modelValue);
    } else {
      const date = this.$route.query.date as string;
      if (date) {
        console.log("来自路由", date);
        this.handleChoose(date);
      } else {
        const now = moment();
        const date = now.format("YYYY-MM-DD");
        console.log("来自当前时间", date);
        this.handleChoose(date);
      }
    }
  }

  @Watch("modelValue")
  onModelValueChanged(nv: any, ov: any): void {
    if (nv != ov) {
      console.log("值变更：", this.modelValue);
      this.handleChoose();
    }
  }

  handlePrevDate(): void {
    const date = moment(this.date).add(-7, "d").format("YYYY-MM-DD");
    this.handleChoose(date);
  }

  handleNextDate(): void {
    const date = moment(this.date).add(7, "d").format("YYYY-MM-DD");
    this.handleChoose(date);
  }

  handleSelect(date?: string): void {
    const d = moment(date).format("YYYY-MM-DD");
    this.handleChoose(d);
  }

  handleChoose(date?: string): void {
    if (date) {
      this.date = date;
      this.buildDates(date);
      this.$route.query["date"] = this.date ?? "";
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.$emit("update:modelValue", this.date);
    }
  }

  buildDates(date?: string): void {
    this.dates = [];
    const d = moment(date);
    let week = d.day();
    week = week == 0 ? 7 : week;
    const monday = d.add(0 - week + 1, "d").format("YYYY-MM-DD");
    for (let i = 0; i < 7; i++) {
      const date = moment(monday).add(i, "d").format("YYYY-MM-DD");
      this.dates.push(date);
    }
  }

  formatWeek(date?: string): string {
    const week = moment(date).day();
    return week == 1
      ? "周一"
      : week == 2
      ? "周二"
      : week == 3
      ? "周三"
      : week == 4
      ? "周四"
      : week == 5
      ? "周五"
      : week == 6
      ? "周六"
      : "周日";
  }

  getActive(item?: string): string {
    return this.date == item ? "active" : "";
  }
}
