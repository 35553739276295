import UiDrag from "./UiDrag";
import { EnumUiDragType, EnumUiDragTypeOption } from "./EnumUiDragType";

/**
 * 树-拖曳
 * @description 自动生成的代码,请勿修改
 */
export default class UiTreeDrag<TKey> extends UiDrag<TKey> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 拖拽节点父Id
     */
    DraggingParentId?: TKey;
    /**
     * 放置节点父Id
     */
    DropParentId?: TKey;
}

/**
 * 树-拖曳-表单校验
 */
export const UiTreeDragRules = {
}
