import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-ellipsis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_ctx.tooltip)
    ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 0,
        effect: "dark",
        content: _ctx.text,
        placement: "top",
        disabled: _ctx.tooltipFlag,
        "show-after": 500
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["text-ellipsis", _ctx.className]),
            ref: "tlp"
          }, [
            (_ctx.miezzIcon)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(`icon iconfont icon-miezz${_ctx.miezzIcon}`)
                }, null, 2))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visibilityChange($event)))
            }, _toDisplayString(_ctx.text), 33)
          ], 2)
        ]),
        _: 1
      }, 8, ["content", "disabled"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.miezzIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(`icon iconfont icon-miezz${_ctx.miezzIcon}`)
            }, null, 2))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.text) + " ", 1),
        _renderSlot(_ctx.$slots, "default")
      ]))
}