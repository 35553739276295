import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoListItemParamList from "../models.machine/DtoListItemParamList";
import DtoPageListSearchParamList from "../models.machine/DtoPageListSearchParamList";
import DtoPageListParamList from "../models.machine/DtoPageListParamList";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamList from "../models.machine/DtoDetailParamList";
import DtoFormParamList from "../models.machine/DtoFormParamList";
import DtoPutParamField from "../models.machine/DtoPutParamField";
import { EnumParamType, EnumParamTypeOption } from "../models.machine/EnumParamType";
import { EnumParamField, EnumParamFieldOption } from "../models.machine/EnumParamField";

/**
 * 参数列表
 * @description 自动生成的代码,请勿修改
 */
export default class ParamListAppService {
    /**
     * 获取参数
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamList[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamList[]>> {
        const url = `/api/ParamList/GetList`;
        return service.get<RESTfulResult<DtoListItemParamList[]>>(url);
    }

    /**
     * 获取参数列表分页列表
     * @param {DtoPageListSearchParamList} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamList>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamList): AxiosPromise<RESTfulResult<DtoPageListParamList>> {
        const url = `/api/ParamList/Get`;
        return service.get<RESTfulResult<DtoPageListParamList>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamList/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 同步排序
     * @param {UiDrag<string>} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncDragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamList/SyncDragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取参数列表详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamList>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamList>> {
        const url = `/api/ParamList/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamList>>(url);
    }

    /**
     * 添加参数列表
     * @param {DtoFormParamList} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormParamList): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamList/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除参数列表
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamList/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步菜单参数数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncParamMenu(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamList/SyncParamMenu`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 配置全名组成
     * @param {DtoPutParamField} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetFullName(dto?: DtoPutParamField): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamList/SetFullName`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取已配置的全名组成
     * @param {EnumParamType} [type] 
     * @returns {AxiosPromise<RESTfulResult<EnumParamField[]>>} 
     */
    static GetFullNameSettingByType(type?: EnumParamType): AxiosPromise<RESTfulResult<EnumParamField[]>> {
        const url = `/api/ParamList/GetFullNameSetting/${type}`;
        return service.get<RESTfulResult<EnumParamField[]>>(url);
    }

}
