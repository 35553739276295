
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamAuditLogAppService from "@/apis.machine/ParamAuditLogAppService";
import DtoDetailParamAuditLog, {
  DtoDetailParamAuditLogMiezzDetailItems,
} from "@/models.machine/DtoDetailParamAuditLog";
import DtoDetailParamAuditLog_TableValue from "@/models.machine/DtoDetailParamAuditLog_TableValue";
import DtoFormParamAuditLog from "@/models.machine/DtoFormParamAuditLog";
import { EnumAuditResult } from "@/models.machine/EnumAuditResult";
import CurrentLogier from "@/apis/CurrentLogier";

@Options({
  components: {},
})
export default class ParamAuditLogDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  
  /**权限编码 */
  powerCodes?: string[] = [];

  detail = new MiezzForm<DtoDetailParamAuditLog, string>("System_AuditLog_Detail");
  formData = new DtoFormParamAuditLog();
  modalForm = new MiezzModal();

  tableData: Array<DtoDetailParamAuditLog_TableValue> = [];
  isDisabled?: boolean = false;

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailParamAuditLog();
    //详细-元素配置
    this.detail.Items = DtoDetailParamAuditLogMiezzDetailItems;

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "refuse",
        Label: "拒绝",
        Type: "danger",
        Size: "small",
        Hide: false,
        PowerCode: "System_AuditLog_AuditPost",
      },
      {
        Code: "agree",
        Label: "通过",
        Type: "success",
        Size: "small",
        Hide: false,
        PowerCode: "System_AuditLog_AuditPost",
      },
    ];
    this.handleGet();
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "agree":
        this.formData.Id = this.detail.Data.Id;
        this.formData.Remark = this.detail.Data.Remark;
        this.formData.AuditResult = EnumAuditResult.Agree;
        ParamAuditLogAppService.AuditPost(this.formData).then(() => {
          this.handleSubmit();
          complete();
        });
        break;
      case "refuse":
        this.formData.Id = this.detail.Data.Id;
        this.formData.Remark = this.detail.Data.Remark;
        this.formData.AuditResult = EnumAuditResult.Refuse;
        ParamAuditLogAppService.AuditPost(this.formData).then(() => {
          this.handleSubmit();
          complete();
        });
        
        break;
      default:
        break;
    }
  }

  /**查询详细 */
  async handleGet(): Promise<void> {
    this.powerCodes = await CurrentLogier.GetPowerCodes();
    if (this.id) {
      ParamAuditLogAppService.GetTableValueById(this.id).then((r) => {
        if(r.data.Data) this.tableData = r.data.Data;
      });
      ParamAuditLogAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
        if (this.detail.Data.Status == 10 || (this.powerCodes && !this.powerCodes.contains("System_AuditLog_AuditPost"))) {
          this.isDisabled = true;
          if (this.modelValue.Buttons) {
            this.modelValue.Buttons[0].Hide = true;
            this.modelValue.Buttons[1].Hide = true;
          }
        }
      });
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
