
    import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
    import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzForm from "@/models/MiezzForm";
    import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import { EnumPoolCodeSourceOption } from "@/models.machine/EnumPoolCodeSource";
    import DtoPutBoxSticker, {
        DtoPutBoxStickerMiezzFormItems,
        DtoPutBoxStickerRules,
    } from "@/models.machine/DtoPutBoxSticker";
    export default class MiezzWharfWarehousing extends Vue {
        //委托书主键
        @Prop() orderPoolId?: string;
        @Prop() isDetail?: boolean;

        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        isLoading = false;
        EnumPoolCodeSourceOption = EnumPoolCodeSourceOption;
        visible = true;
        ruleForm: {
            Visible: boolean,
        } = {
                Visible: false,
            };
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            //{
            //    IsBlue:true,
            //    Code: "export",
            //    Label: "导出",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //国家
        countrys?: UiSelectOption<string>[] = [];
        //渠道
        channels?: UiSelectOption<string>[] = [];
        form = new MiezzForm<DtoPutBoxSticker, string>();
        created(): void {
            this.form.Data = new DtoPutBoxSticker();

            //表单-校验
            this.form.Rules = DtoPutBoxStickerRules;
            //表单-元素配置
            this.form.Items = DtoPutBoxStickerMiezzFormItems;


        }
        ///*打开HsCode对话框 */
        @Emit()
        onInit(id: string): void {
   
            this.isLoading = true;
            ParamCountryAppService.GetSelect().then(country => {
                if (country.data.Data) this.countrys = country.data.Data;

                OrderDepartureWharfAppService.GetBoxStickerByOrderPoolIdByOrderDepartureWharfId(this.orderPoolId, id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                    this.ruleForm.Visible = true;
                    this.isLoading = false;
                    this.form.Rules.PlaceofDelivery = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "blur",
                        },
                    ];
                    this.form.Rules.ProductionCountryName = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "change",
                        },
                    ];
                    this.form.Rules.BoxStickerCode = [
                        {
                            required: true,
                            message: "信息不能为空",
                            trigger: "blur",
                        },
                    ];
                    
                    if (this.isDetail == true && this.bottomButtons) {
                        this.bottomButtons[0].Hide = true;
                    }
                })
            })


        }
        onChangeCodeSource(): void {
            if (this.form.Data.CodeSource == 20) {
                this.form.Data.BoxStickerCode = this.form.Data.BookingNo;
            }
            else {
                this.form.Data.BoxStickerCode = this.form.Data.Code;
            }
        }

        onBlurPlaceofDelivery(): void {
            if (this.form.Data.PlaceofDelivery) {
                this.form.Data.PlaceofDelivery = OneSpace(this.form.Data.PlaceofDelivery);
            }
        }

        onBlurProductionCountryName(): void {
            if (this.form.Data.ProductionCountryName) {
                this.form.Data.ProductionCountryName = OneSpace(this.form.Data.ProductionCountryName);
            }
        }

        /**选择交货地点 */
        handleSelectDeliveryPlace(item: DtoSelectOptionParamCountryArea): void {
            //this.form.Data.PlaceofDelivery = item?.DetailedAddress;
            console.log(item);
        }

        /**获取交货地点 */
        getDeliveryPlaces(q: string, cb: any): void {
            if (q && q.length >= 2) {
                ParamCountryAreaAppService.GetSelect({
                    Keywords: q,
                }).then((r) => {
                    cb(r.data.Data ?? []);
                });
            } else {
                cb([]);
            }
        }


        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                MiezzForm.submit(this.$refs, "refForm", (v) => {
                                    console.log(v);
                                    if (v == true) {
                                        OrderDepartureWharfAppService.EditBoxSticker(this.form.Data)
                                            .then(() => {
                                                ElMessage.success("保存成功");
                                                this.$emit("onInitAnnex");
                                                this.ruleForm.Visible = false;
                                                complete();
                                            })
                                            .catch(complete);
                                    }
                                    else {
                                        complete();
                                    }
                                });
                            }
                        }
                    })


                    break;
                //case "export":
                //    OrderDepartureWharfAppService.ExportBoxStickerByOrderPoolIdByOrderDepartureWharfId(this.orderPoolId, this.form.Data.OrderDepartureWharfId)
                //        .then((r) => {
                //            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                //            complete();
                //        })
                //        .catch(complete);

                //    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        querySearchProductionCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.countrys) {
                    for (var i = 0; i < this.countrys.length; i++) {
                        if (i < 10) {
                            results.push(this.countrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectProductionCountryId(item: UiSelectOption<string>) {
            //this.form.Data.ProductionCountryId = item.Value;
            this.form.Data.ProductionCountryName = item.Label;
            //MiezzForm.clearValidate(this.$refs, "refForm", "ProductionCountryId");
        }
        onChangeProductionCountryId(): void {
           /* if (this.form.Data.ProductionCountryName == undefined || this.form.Data.ProductionCountryName == "") {
                this.form.Data.ProductionCountryId = undefined;
            }
            else {
                //if (this.form.Data.ProductionCountryId == undefined) {
                //    this.form.Data.ProductionCountryName = undefined;
                //}
                //else if (this.form.Data.ProductionCountryId != undefined) {

                //}
                var flag = false;
                if (this.countrys) {
                    for (var it of this.countrys) {
                        if (it.Label == this.form.Data.ProductionCountryName) {
                            this.form.Data.ProductionCountryId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.ProductionCountryId = undefined;
                    }
                }
            }*/
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

    }

    /*去除连续空格，只保留一个空格 */
    function OneSpace(str: string) {

        var name = "";
        if (str != undefined && str != "") {
            var i = 0;
            for (i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (i = 0; i < str.length; i++) {
                if (str[i] != " ") {
                    name += str[i];
                }
                else {
                    if (i > 0 && str[i - 1] != " ") {
                        name += str[i];
                    }
                }
            }
        }
        return name.trim();
    }
