import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * Http日志分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemHttpLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Success = false;
        this.Milliseconds = 0;
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 控制器注释
     */
    ControllerSummary?: string;
    /**
     * 请求注释
     */
    ActionSummary?: string;
    /**
     * 请求方式
     */
    Method?: string;
    /**
     * 请求地址
     */
    Url?: string;
    /**
     * 是否成功
     */
    Success?: boolean;
    /**
     * 耗时毫秒数
     */
    Milliseconds?: number;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
}

/**
 * Http日志分页列表-项-表单校验
 */
export const DtoListItemHttpLogRules = {
}

/**
 * Http日志分页列表-项-设置项
 */
export const DtoListItemHttpLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ControllerSummary",
    Label: "控制器注释",
  },
  {
    Prop: "ActionSummary",
    Label: "请求注释",
  },
  {
    Prop: "Method",
    Label: "请求方式",
  },
  {
    Prop: "Url",
    Label: "请求地址",
  },
  {
    Prop: "Success",
    Label: "是否成功",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "成功",
    WhenFalse: "失败",
  },
  {
    Prop: "Milliseconds",
    Label: "耗时毫秒数",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
  },
]
