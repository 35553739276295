import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFormOrderMainBillNo from "../models.machine/DtoFormOrderMainBillNo";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoDetailOrderMainBill from "../models.machine/DtoDetailOrderMainBill";
import DtoRequestOrderMainBillAutoPullThreeApiData from "../models.machine/DtoRequestOrderMainBillAutoPullThreeApiData";
import DtoCallbackOrderMainBillAutoPullThreeApiData from "../models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData";
import DtoFormOrderMainBill from "../models.machine/DtoFormOrderMainBill";
import DtoPutOrderMainBillBusinessCycle from "../models.machine/DtoPutOrderMainBillBusinessCycle";
import DtoOptionOrderMainBillElement from "../models.machine/DtoOptionOrderMainBillElement";
import DtoDetailOrderMainBillTemplate from "../models.machine/DtoDetailOrderMainBillTemplate";
import DtoFormOrderMainBillTemplate from "../models.machine/DtoFormOrderMainBillTemplate";

/**
 * 订单-主单信息
 * @description 自动生成的代码,请勿修改
 */
export default class OrderMainBillAppService {
    /**
     * 校验提单号码是否存在
     * @param {DtoFormOrderMainBillNo} [dto] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static CheckBillNoExist(dto?: DtoFormOrderMainBillNo): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderMainBill/CheckBillNoExist`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 获取订单-主单信息详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderMainBill>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderMainBill>> {
        const url = `/api/OrderMainBill/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderMainBill>>(url);
    }

    /**
     * 自动拉取第三方接口数据
     * @param {DtoRequestOrderMainBillAutoPullThreeApiData} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderMainBillAutoPullThreeApiData>>} 
     */
    static AutoPullThreeApiData(dto?: DtoRequestOrderMainBillAutoPullThreeApiData): AxiosPromise<RESTfulResult<DtoCallbackOrderMainBillAutoPullThreeApiData>> {
        const url = `/api/OrderMainBill/AutoPullThreeApiData`;
        return service.post<RESTfulResult<DtoCallbackOrderMainBillAutoPullThreeApiData>>(url, dto);
    }

    /**
     * 添加订单-主单信息
     * @param {DtoFormOrderMainBill} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderMainBill): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderMainBill/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 编辑业务所属周期
     * @param {DtoPutOrderMainBillBusinessCycle} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PutBusinessCycle(dto?: DtoPutOrderMainBillBusinessCycle): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderMainBill/PutBusinessCycle`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取订单-主单信息模板元素
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoOptionOrderMainBillElement[]>>} 
     */
    static GetOrderMainBillElementById(id?: string): AxiosPromise<RESTfulResult<DtoOptionOrderMainBillElement[]>> {
        const url = `/api/OrderMainBill/GetOrderMainBillElement/${id}`;
        return service.get<RESTfulResult<DtoOptionOrderMainBillElement[]>>(url);
    }

    /**
     * 获取正负提单模板
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderMainBillTemplate[]>>} 
     */
    static GetOrderMainBillTempalateById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderMainBillTemplate[]>> {
        const url = `/api/OrderMainBill/GetOrderMainBillTempalate/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderMainBillTemplate[]>>(url);
    }

    /**
     * 提交提单模板
     * @param {number} [pageNumber] 
     * @param {DtoFormOrderMainBillTemplate} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostOrderMainBillTemplateByPageNumber(pageNumber?: number, dto?: DtoFormOrderMainBillTemplate): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderMainBill/PostOrderMainBillTemplate/${pageNumber}`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
