import UiSelectOption from "./UiSelectOption";

/**
 * 拖车状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumContainerTrailerStatus {
    /**
     * 待提柜
     */
    WaitTake = 1,
    /**
     * 提柜中
     */
    IngTake = 2,
    /**
     * 已提柜
     */
    EdTake = 3,
    /**
     * 装柜中
     */
    IngLoad = 4,
    /**
     * 已装柜
     */
    EdLoad = 5,
    /**
     * 进港中
     */
    IngPort = 6,
    /**
     * 已进港
     */
    EdPort = 7,
}

/**
 * 拖车状态-选项
 */
export const EnumContainerTrailerStatusOption: UiSelectOption<number>[] = [
    { Label: "待提柜", Value: 1, Data: "WaitTake" },
    { Label: "提柜中", Value: 2, Data: "IngTake" },
    { Label: "已提柜", Value: 3, Data: "EdTake" },
    { Label: "装柜中", Value: 4, Data: "IngLoad" },
    { Label: "已装柜", Value: 5, Data: "EdLoad" },
    { Label: "进港中", Value: 6, Data: "IngPort" },
    { Label: "已进港", Value: 7, Data: "EdPort" },
];
