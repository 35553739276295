
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoCallbackProjectSiteGetPublishVersion from "@/models.machine/DtoCallbackProjectSiteGetPublishVersion";
import DtoFormProjectSiteExecCommand, {
  DtoFormProjectSiteExecCommandMiezzFormItems,
  DtoFormProjectSiteExecCommandRules,
} from "@/models.machine/DtoFormProjectSiteExecCommand";
import { EnumProjectVersionType } from "@/models.machine/EnumProjectVersionType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import MiezzAlertLog from "@/models/MiezzAlertLog";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { ElForm, ElTree } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import ProjectSiteExecInfo from "./ProjectSiteExecInfo";
export default class ExecCommand extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() projectId!: string;
  @Prop() execs: ProjectSiteExecInfo[] = [];

  active?: string;

  form = new MiezzForm<DtoFormProjectSiteExecCommand, any>();
  checks: UiSelectOption<string>[] = [
    {
      Label: "新建迁移版本",
      Value: "NewMigration",
      Data: false,
    },
    {
      Label: "回滚迁移版本",
      Value: "RollbackMigration",
      Data: false,
    },
    {
      Label: "数据库迁移",
      Value: "DatabaseMigration",
      Data: false,
    },
    {
      Label: "数据库同步",
      Value: "Sync",
      Data: false,
    },
    {
      Label: "打包",
      Value: "Pack",
      Data: false,
    },
    {
      Label: "发布",
      Value: "Publish",
      Data: false,
    },
  ];
  tables: UiSelectOptionWithStringValue[] = [];
  version?: DtoCallbackProjectSiteGetPublishVersion;

  created(): void {
    this.modelValue.Width = "80%";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "提交",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "back",
        Label: "返回",
        Type: "default",
        Size: "small",
        Hide: true,
      },
    ];

    CurrentLogier.HubConnection?.off("AlertLog");
    CurrentLogier.HubConnection?.off("AlertCmdLog");

    this.active = this.execs.firstOrDefault()?.Id;

    this.form.Data = new DtoFormProjectSiteExecCommand();
    this.form.Data.Id = this.projectId;
    this.form.Data.Ids = this.execs.map((it) => it.Id as string);
    this.form.Rules = DtoFormProjectSiteExecCommandRules;
    this.form.Items = DtoFormProjectSiteExecCommandMiezzFormItems;

    const syncSchemeName = this.form.Items.firstOrDefault(
      (it) => it.Prop == "SyncSchemeName"
    );
    if (syncSchemeName) {
      syncSchemeName.HandleChange = (): void => {
        ProjectSiteAppService.GetSyncSchemeTableByProjectIdByName(
          this.projectId,
          this.form.Data.SyncSchemeName
        ).then((r) => {
          this.form.Data.SyncTables = r.data.Data;
        });
      };
    }

    const publishType = this.form.Items.firstOrDefault(
      (it) => it.Prop == "PublishType"
    );
    if (publishType) {
      publishType.HandleChange = (): void => {
        if (this.form.Data.PublishType)
          this.chooseVersion(this.form.Data.PublishType);
      };
    }

    this.form.Rules.SourceId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.form.Rules.SyncSchemeName = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ];
    this.form.Rules.SyncTables = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];

    this.handleCheck();

    CurrentLogier.HubConnection?.on(
      "AlertLog",
      (message: string, type: string, effect: string, group: string) => {
        for (const exec of this.execs) {
          if (!group || group == exec.Id) {
            exec.DefaultAlertLogs.unshift({
              Message: message,
              Type: type,
              Effect: effect,
            });

            if (message == "已拉取代码" && exec.ActiveStep == 0) {
              exec.ActiveStep = 1;
            } else if (message == "已打包后端代码") {
              exec.handleStep("打包");
            } else if (message == "回滚成功") {
              exec.handleStep("回滚");
            } else if (
              message.startsWith("迁移版本：") ||
              message == "无历史迁移版本"
            ) {
              exec.handleStep("查询版本");
            } else if (message == "已创建迁移版本") {
              exec.handleStep("新建版本");
            } else if (message == "数据库迁移成功") {
              exec.handleStep("迁移");
            } else if (message == "数据库同步成功") {
              exec.handleStep("同步");
            } else if (message == "发布成功") {
              exec.handleStep("发布");
            } else if (message == "操作成功") {
              exec.handleStep("操作成功");
            }
          }
        }
      }
    );

    CurrentLogier.HubConnection?.on(
      "AlertCmdLog",
      (message: string, type: string, effect: string, group: string) => {
        for (const exec of this.execs) {
          if (!group || group == exec.Id) {
            exec.CommandAlertLogs.unshift({
              Message: message,
              Type: type,
              Effect: effect,
            });
          }
        }
      }
    );
  }

  handleCheck(): void {
    const rollback = this.form.Data.Checks?.contains("RollbackMigration");
    if (rollback) {
      this.form.Data.Checks = ["RollbackMigration"];
    }
    if (
      this.form.Data.Checks?.contains("Pack") &&
      !this.form.Data.Checks.contains("Publish")
    ) {
      this.form.Data.Checks.push("Publish");
    }
    for (const check of this.checks) {
      if (check.Value != "RollbackMigration") check.Data = rollback;
    }
    if (this.form.Items) {
      for (const item of this.form.Items) {
        if (item.Prop == "Version") {
          item.Hide = !this.form.Data.Checks?.contains("NewMigration");
        } else if (
          item.Prop == "SourceId" ||
          item.Prop == "SyncSchemeName" ||
          item.Prop == "SyncTables"
        ) {
          item.Hide = !this.form.Data.Checks?.contains("Sync");
          if (
            !item.Hide &&
            item.Prop == "SourceId" &&
            (item.Options == undefined || item.Options.length == 0)
          ) {
            ProjectSiteAppService.GetSelect().then((r) => {
              const sourceId = this.form.Items?.firstOrDefault(
                (it) => it.Prop == "SourceId"
              );
              if (sourceId)
                sourceId.Options =
                  r.data.Data?.filter((it) =>
                    this.execs.all((s) => s.Id != it.Value)
                  ) ?? [];
            });
          } else if (
            !item.Hide &&
            item.Prop == "SyncTables" &&
            this.tables.length == 0
          ) {
            ProjectSiteAppService.GetTables().then((r) => {
              this.tables = r.data.Data ?? [];
            });
          } else if (!item.Hide && item.Prop == "SyncSchemeName") {
            ProjectSiteAppService.GetSyncSchemeByProjectId(this.projectId).then(
              (r) => {
                item.Options =
                  r.data.Data?.map((it) => {
                    const option = new UiSelectOption<string>();
                    option.Label = it;
                    option.Value = it;
                    return option;
                  }) ?? [];
              }
            );
          }
        } else if (
          item.Prop == "PublishType" ||
          item.Prop == "PublishNumber" ||
          item.Prop == "PublishContent"
        ) {
          item.Hide = !this.form.Data.Checks?.contains("Publish");
          if (!item.Hide) {
            if (
              item.Prop == "PublishNumber" &&
              this.form.Data.PublishNumber == null
            ) {
              ProjectSiteAppService.GetPublishVersionByProjectId(
                this.projectId
              ).then((r) => {
                this.version = r.data.Data;
                this.chooseVersion();
              });
            }
          }
        }
      }
    }
  }

  chooseVersion(type?: EnumProjectVersionType): void {
    if (this.version) {
      this.form.Data.PublishType = type;
      let mainNumber = this.version.MainNumber ?? 0;
      let minorNumber = this.version.MinorNumber ?? 0;
      let revisionNumber = this.version.RevisionNumber ?? 0;
      switch (type) {
        case EnumProjectVersionType.Main:
          mainNumber++;
          minorNumber = 0;
          revisionNumber = 0;
          break;
        case EnumProjectVersionType.Minor:
          minorNumber++;
          revisionNumber = 0;
          break;
        case EnumProjectVersionType.Revision:
          revisionNumber++;
          break;
        default:
          break;
      }
      this.form.Data.PublishNumber = `${mainNumber}.${minorNumber}.${revisionNumber}`;
      this.form.Data.PublishMainNumber = mainNumber;
      this.form.Data.PublishMinorNumber = minorNumber;
      this.form.Data.PublishRevisionNumber = revisionNumber;
    }
  }

  getSteps(): void {
    for (const exec of this.execs) {
      exec.ActiveStep = 0;
      exec.Steps = [];
      exec.Steps.push({ Title: "更新副本" });
      if (this.form.Data.Checks?.contains("RollbackMigration")) {
        exec.Steps.push({ Title: "回滚" });
      } else {
        if (this.form.Data.Checks?.contains("Pack")) {
          exec.Steps.push({ Title: "打包" });
        }
        if (this.form.Data.Checks?.contains("NewMigration")) {
          if (!this.form.Data.Version) {
            exec.Steps.push({ Title: "查询版本" });
          }
          exec.Steps.push({ Title: "新建版本" });
        }
        if (this.form.Data.Checks?.contains("DatabaseMigration")) {
          exec.Steps.push({ Title: "迁移" });
        }
        if (this.form.Data.Checks?.contains("Sync")) {
          exec.Steps.push({ Title: "同步" });
        }
        if (this.form.Data.Checks?.contains("Publish")) {
          exec.Steps.push({ Title: "发布" });
        }
      }
      exec.Steps.push({ Title: "操作成功" });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            this.getSteps();
            btn.Hide = true;
            ProjectSiteAppService.ExecCommand(this.form.Data)
              .then(() => {
                const back = this.modelValue.Buttons?.firstOrDefault(
                  (it) => it.Code == "back"
                );
                if (back) back.Hide = false;
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        btn.Hide = true;
        var submit = this.modelValue.Buttons?.firstOrDefault(
          (it) => it.Code == "submit"
        );
        if (submit) submit.Hide = false;
        for (const exec of this.execs) {
          exec.DefaultAlertLogs = [];
          exec.CommandAlertLogs = [];
        }
        complete();
        break;
      default:
        break;
    }
  }
}
