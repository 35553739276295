import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchAuditLog from "../models.machine/DtoPageListSearchAuditLog";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListAuditLog from "../models.machine/DtoPageListAuditLog";
import DtoDetailAuditLog from "../models.machine/DtoDetailAuditLog";

/**
 * 审计日志
 * @description 自动生成的代码,请勿修改
 */
export default class AuditLogAppService {
    /**
     * 获取审计日志分页列表
     * @param {DtoPageListSearchAuditLog} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListAuditLog>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchAuditLog): AxiosPromise<RESTfulResult<DtoPageListAuditLog>> {
        const url = `/api/AuditLog/Get`;
        return service.get<RESTfulResult<DtoPageListAuditLog>>(url, { params: dto });
    }

    /**
     * 获取审计日志详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailAuditLog>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailAuditLog>> {
        const url = `/api/AuditLog/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailAuditLog>>(url);
    }

}
