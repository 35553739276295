import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import UiGroup from "./UiGroup";

/**
 * 价格服务快捷方式表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceServiceShortcut extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 装货港服务-订舱
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 目的港服务-清关
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
}

/**
 * 价格服务快捷方式表单-表单校验
 */
export const DtoFormPriceServiceShortcutRules = {
    Name: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 价格服务快捷方式表单-设置项
 */
export const DtoFormPriceServiceShortcutMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TransportDirection",
    Label: "运输方向",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportDirectionOption,
  },
  {
    Prop: "TransportType",
    Label: "运输类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportTypeOption,
  },
  {
    Prop: "TransportContainerLoad",
    Label: "集装箱装载方式",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumTransportContainerLoadOption,
  },
  {
    Prop: "DeparturePortBooking",
    Label: "装货港服务-订舱",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortLadingBill",
    Label: "装货港服务-是否签发提单",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortTake",
    Label: "装货港服务-提货",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortContainer",
    Label: "装货港服务-提货方式-集装箱拖车",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortTrunk",
    Label: "装货港服务-提货方式-干线物流",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortExpress",
    Label: "装货港服务-提货方式-快递出现",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortWarehouse",
    Label: "装货港服务-仓库",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortDeclareCustoms",
    Label: "装货港服务-报关",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeparturePortDrawback",
    Label: "装货港服务-是否退税",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortClearCustoms",
    Label: "目的港服务-清关",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortCustomsClearanceHeaderProvider",
    Label: "目的港服务-清关抬头",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumCustomsClearanceHeaderProviderOption,
  },
  {
    Prop: "DestinationPortCustomsClearanceIssueTaxReceipt",
    Label: "目的港服务-是否出具税单",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortDelivery",
    Label: "目的港服务-送货",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortContainer",
    Label: "目的港服务-送货方式-集装箱拖车",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortTrunk",
    Label: "目的港服务-送货方式-干线物流",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortExpress",
    Label: "目的港服务-送货方式-快递出现",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DestinationPortWarehouse",
    Label: "目的港服务-仓库",
    Type: EnumMiezzFormItemType.InputText,
  },
]
