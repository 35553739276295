import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyOvertimeRuleType, EnumCompanyOvertimeRuleTypeOption } from "./EnumCompanyOvertimeRuleType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyOvertimeRule_OvertimeRule extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 待办名称
     */
    TaskName?: string;
    /**
     * 待办名称
     */
    TaskLabel?: string;
    /**
     * 基准时间
     */
    Type?: EnumCompanyOvertimeRuleType;
    /**
     * 超期标准（天）
     */
    Overtime?: number;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyOvertimeRule_OvertimeRuleRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyOvertimeRule_OvertimeRuleMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "TaskLabel",
    Label: "待办名称",
  },
  {
    Prop: "Type",
    Label: "基准时间",
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyOvertimeRuleTypeOption,
  },
  {
    Prop: "Overtime",
    Label: "超期标准（天）",
  },
]

/**
 * -设置项
 */
export const DtoDetailCompanyOvertimeRule_OvertimeRuleMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TaskLabel",
    Label: "待办名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Type",
    Label: "基准时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyOvertimeRuleTypeOption,
  },
  {
    Prop: "Overtime",
    Label: "超期标准（天）",
    Type: EnumMiezzFormItemType.Text,
  },
]
