
    import CurrentLogier from "@/apis/CurrentLogier";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import CompanyChargeAppService from "@/apis.machine/CompanyChargeAppService";
    import DtoPageListSearchCompanyCharge, {
        DtoPageListSearchCompanyChargeDynamicSearchItems,
    } from "@/models.machine/DtoPageListSearchCompanyCharge";
    import DtoPageListCompanyCharge from "@/models.machine/DtoPageListCompanyCharge";
    import DtoListItemCompanyCharge, {
        DtoListItemCompanyChargeMiezzListItems,
    } from "@/models.machine/DtoListItemCompanyCharge";
    import CurrentCompanyChargeForm from "./CurrentCompanyChargeForm.vue";

    import DtoFormCompanyChargeService from "@/models.machine/DtoFormCompanyChargeService";
    import DtoSelectCompanyChargeService from "@/models.machine/DtoSelectCompanyChargeService";

    import MiezzForm from "@/models/MiezzForm";
    @Options({
        components: {
            CurrentCompanyChargeForm,
        },
    })
    export default class ProductSpecElementPageList extends Vue {
        serverForm: {
            Visiable: boolean;
            CompanyChargeId: string | undefined;
            ServiceRemarks: any[];
        } = {
                Visiable: false,
                CompanyChargeId: undefined,
                ServiceRemarks: [],
            };

        serviceRemarkBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchCompanyCharge, DtoListItemCompanyCharge, string>();
        listInit = new MiezzPageList<DtoPageListSearchCompanyCharge, DtoListItemCompanyCharge, string>();
        /**详细模态框 */
        modalDetail = new MiezzModal();
        /**表单模态框 */
        modalForm = new MiezzModal();

        /**当前Id */
        id?: string;
        tip = "";
        companyId = "";

        options: UiSelectOption<string>[] = [];
        services: UiSelectOption<string>[] = [];


        isAdd = false;
        isEdit = false;
        isDelete = false;
        isRelated = false;

        /**创建时 */
        created(): void {


            this.companyId = this.$route.params.id as string;
            this.list.Type = MiezzPageListType.Table;
            this.list.Back = true;
            this.list.EnabledSelect = true;
            this.list.Title = "计费重量/体积规则";
            this.list.EnabledRadioSearch = false;
            //列表-传参
            this.list.Dto = new DtoPageListSearchCompanyCharge();
            //列表-动态搜索-项
            this.list.DynamicSearchItems =
                DtoPageListSearchCompanyChargeDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListCompanyCharge();
            //列表-列配置
            this.list.Items = DtoListItemCompanyChargeMiezzListItems;


            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("SupplierManagement_Private_ChargeableWeight_Edit") || r?.contains("CompanyManagement_Company_RulesForBillingWeightvolume_Edit") || r?.contains("SupplierManagement_Public_RulesForBillingWeightvolume_Edit")) {
                    this.isEdit = true;
                }
                if (r?.contains("SupplierManagement_Private_ChargeableWeight_AddTo") || r?.contains("CompanyManagement_Company_RulesForBillingWeightvolume_AddTo") || r?.contains("SupplierManagement_Public_RulesForBillingWeightvolume_AddTo")) {
                    this.isAdd = true;
                }
                if (r?.contains("SupplierManagement_Private_ChargeableWeight_Delete") || r?.contains("CompanyManagement_Company_RulesForBillingWeightvolume_Delete") || r?.contains("SupplierManagement_Public_RulesForBillingWeightvolume_Delete")) {
                    this.isDelete = true;
                }

                if (r?.contains("CompanyManagement_Company_RulesForBillingWeightvolume_RelatedServices") || r?.contains("CompanyManagement_Company_RulesForBillingWeightvolume_RelatedServices") || r?.contains("SupplierManagement_Public_RulesForBillingWeightvolume_Delete")) {
                    this.isRelated = true;
                }


                //列表-顶部按钮
                this.list.TopButtons = [
                    {
                        Code: "add",
                        Title: "添加",
                        Label: "添加",
                        Icon: "plus",
                        Type: "primary",
                        Size: "small",
                        Hide: this.isAdd == true ? false : true,
                    },
                ];
                //列表-行按钮
                this.list.RowButtons = [
               
                    //{
                    //    Code: "detail",
                    //    Title: "查看详细",
                    //    Icon: "document",
                    //    Type: "default",
                    //    Size: "small",
                    //    PowerCode: "CompanyManagement_Company_RulesForBillingWeightvolume_Edit",
                    //},
                    {
                        Code: "edit",
                        Title: "编辑",
                        Icon: "edit",
                        Type: "default",
                        Size: "small",
                        IsBlue: true,
                        Hide: this.isEdit == true ? false : true,
                    },
                    {
                        Code: "drag-sort",
                        Title: "拖曳",
                        Icon: "Rank",
                        Type: "default",
                        Size: "small",
                        Hide: this.isEdit == true ? false : true,
                    }
                ];
                //列表-底部按钮
                this.list.BottomButtons = [
                    {
                        Code: "batch-delete",
                        Label: "删除",
                        Icon: "delete",
                        Type: "text",
                        Message: "是否确认删除",
                        //MessageBox: true,
                        Size: "small",
                        Hide: this.isDelete == true ? false : true,
                    },
                ];
            })



            this.list.FixTop = "0px";
        }
        onServie(row: any): void{

            var param = new DtoSelectCompanyChargeService();
            param.CompanyChargeId = row.Id;
            param.CompanyId = this.companyId;
            CompanyChargeAppService.GetServiceRemarks(param).then((r1) => {
                if (r1.data.Data) this.services = r1.data.Data;

                this.serverForm.ServiceRemarks = [];
                this.serverForm.CompanyChargeId = row.Id;
                if (row && row.ServiceRemarks) {
                    for (var it of row.ServiceRemarks) {
                        this.serverForm.ServiceRemarks.push(it.ServiceRemark);
                    }
                }

                this.serverForm.Visiable = true;
            })

        }
        handleClickServiceRemark(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                   
                    MiezzForm.customSubmit(this.$refs, "refServiceForm", (v) => {
                        if (v == true) {

                            var form = new DtoFormCompanyChargeService();
                            form.CompanyChargeId = this.serverForm.CompanyChargeId;
                            form.CompanyId = this.companyId;
                            form.ServiceRemarks = this.serverForm.ServiceRemarks;
                            CompanyChargeAppService.SerServiceRemark(form).then((r) => {
                                ElMessage.success("关联服务成功");
                                this.serverForm.Visiable = false;
                                this.handleGet();
                                complete();
                            })


                        }
                        else {
                            complete();
                        }
                    });
       
                    break;
                case "close":
                    this.serverForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        onAll(): void {
            this.list.SelectIds = [];
            this.list.SelectRows = [];
            if (this.list && this.list.Data && this.list.Data.Items) {
                var isAll = true;
                for (var it of this.list.Data.Items) {
                    if (it.IsSelected != true) {
                        isAll = false;
                    }
                }
                if (isAll == false) {
                    for (var it1 of this.list.Data.Items) {
                        it1.IsSelected = true;
                        this.list.SelectIds.push(it1.Id + "");
                        this.list.SelectRows.push(it1);
                    }
                }
                else {
                    for (var it2 of this.list.Data.Items) {
                        it2.IsSelected = false;
                    }
                }
            }
        }

        handleRowClick(row: any) {
            this.list.SelectIds = [];
            this.list.SelectRows = [];
            if (this.list && this.list.Data && this.list.Data.Items) {
                for (var it of this.list.Data.Items) {
                    if (it.Id == row.Id) {
                        it.IsSelected = !it.IsSelected;
                    }

                    if (it.IsSelected == true) {
                        this.list.SelectIds.push(it.Id + "");
                        this.list.SelectRows.push(it);
                    }
                }
            }
        }


        hnadleDrop(e: any): void {
            if (e.oldIndex != e.newIndex && this.options && this.options.length > 1) {
                const draggingIndex = e.newIndex;
                let dropIndex;
                let type: EnumUiDragType;
                if (draggingIndex == 0) {
                    type = EnumUiDragType.Before;
                    dropIndex = 1;
                } else {
                    type = EnumUiDragType.After;
                    dropIndex = draggingIndex - 1;
                }

                dropIndex = e.oldIndex;
                const dragging = this.options[draggingIndex];
                const drop = this.options[dropIndex];
                CompanyChargeAppService.DragSort({
                    DraggingId: drop.Value,
                    DropId: dragging.Value,
                    DragType: type,
                }).then(() => {
                    this.handleGet();
                });
            }
        }
        /**查询列表 */
        handleGet(): void {
            if (!this.list.Dto) {
                this.list.Dto = new DtoPageListSearchCompanyCharge();
            }
            this.list.Dto.CompanyId = this.companyId;
            CompanyChargeAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
                if (r.data.Data && r.data.Data.Selects) {
                    this.options = JSON.parse(JSON.stringify(r.data.Data.Selects));
                }
            });

            this.tip = "示例：材积比167代表1CBM=167KGS，首行信息为仓库/堆场/码头中的默认选项";
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    this.modalForm.Show = true;
                    this.modalForm.Title = "新增计费重量/体积规则";
                    this.id = undefined;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemCompanyCharge,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    this.modalDetail.Show = true;
                    this.modalDetail.Title = `计费重量/体积规则-${row.Name}`;
                    complete();
                    break;
                case "edit":
                    this.modalForm.Show = true;
                    this.modalForm.Title = `编辑计费重量/体积规则-${row.Name}`;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个计费重量/体积规则");
                        return;
                    }

                    CompanyChargeAppService.DeleteByCompanyId(this.companyId, this.list.SelectIds)
                        .then((r) => {
                            if(r.data.Data && r.data.Data.IsMessage == true){
                                ElMessage.warning(r.data.Data.Message);
                            }
                            this.handleGet();
                            complete();
                        })
                        .catch(complete);
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.modalDetail.Show = false;
            this.handleGet();
        }
    }
