
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoFormProduct, {
        DtoFormProductMiezzFormItems,
        DtoFormProductRules,
    } from "@/models.machine/DtoFormProduct";
    import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
import { Warning } from "@element-plus/icons-vue/dist/types";

    export default class ProductEdit extends Vue {
        @Model() modelValue!: MiezzModal;
        modal = new MiezzModal(MiezzModalType.Dialog);
        id = "";
        HsCodeIndex = "";
        random = 0;
        isChangeChargeUnit = false;
        countrys?: UiSelectOption<string>[] = [];
        chargeUnits?: UiSelectOption<string>[] = [];
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "default",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "保存",
                Icon: "select",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "continue",
                Label: "继续添加",
                Icon: "plus",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                Code: "reset",
                Label: "重置",
                Icon: "refresh",
                Type: "primary",
                Size: "small",
            },
        ];
        form = new MiezzForm<DtoFormProduct, string>();
        formInit = new MiezzForm<DtoFormProduct, string>();
        created(): void {
            //表单-数据-初始化
            this.id = this.$route.params.id as string;
            if (this.id == null || typeof (this.id) == "undefined" || this.id == "undefined" || this.id == "") {
                if (this.bottomButtons != null) {
                    this.bottomButtons[2].Hide = false;
                }
            }
            this.form.Data = new DtoFormProduct();
            this.form.Data.ZhAliass = []; //中文别名
            this.form.Data.EnAliass = []; //英文别名
            this.form.Data.LatinAliass = []; //拉丁别名
            this.form.DefaultSpan = 12;
            //表单-校验
            this.form.Rules = DtoFormProductRules;
            //表单-元素配置
            this.form.Items = DtoFormProductMiezzFormItems;

            this.onRule();

            ParamCountryAppService.GetSelect().then(r => {
                this.countrys = r.data.Data;
                if (this.countrys) {
                    for (var country of this.countrys) {
                        if (country.Value) {
                            country.Value = country.Value.toUpperCase();
                        }
                    }
                }
                ParamChargeUnitAppService.GetSelect().then(r => {
                    this.chargeUnits = r.data.Data;
                    this.random = Math.random();
                    this.handleGet();
                })


            });
        }

        /*打开HsCode对话框 */
        onHsCode(): void {
            this.HsCodeIndex = "HsCode";
            const HsCode = this.$refs["HsCode"] as any;
            HsCode.onInit(this.form.Data.HsCode as string);
        }
        onCustomsCode(index: number): void {
            if (this.form.Data.CustomsCodes) {
                this.HsCodeIndex = index + "";
                const HsCode = this.$refs["HsCode"] as any;
                HsCode.onInit(this.form.Data.CustomsCodes[index].HsCode as string);
            }
        }
        onSelectHsCode(row: any): void {
            if (this.HsCodeIndex == "HsCode") {
                this.form.Data.HsCode = row.Code;
                this.form.Data.StatutoryFirstUnit = row.StatutoryFirstUnit;
                this.form.Data.StatutorySecondUnit = row.StatutorySecondUnit;
                MiezzForm.clearValidate(this.$refs, "ruleForm", "HsCode");
                if (this.form.Data.NameZh != null && this.form.Data.NameZh != "") {
                    MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
                }
            }
            else {
                var index = Number(this.HsCodeIndex);
                if (this.form.Data.CustomsCodes) {
                    this.form.Data.CustomsCodes[index].HsCode = row.Code;
                }
            }
        }
 
        /*返回 */
        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != undefined && url != "undefined" && url != "") {
                this.$router.push(url);
            }
            else {
                history.go(-1);
            }
        }
        /**查询表单数据 */
        handleGet(): void {
            if (this.id) { 
                ProductAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                    this.formInit = JSON.parse(JSON.stringify(this.form));
                    this.form.Data.UiGroups = new DtoFormProduct().UiGroups;
                    this.form.Data.ZhAliass = []; //中文别名
                    this.form.Data.EnAliass = []; //英文别名
                    this.form.Data.LatinAliass = []; //拉丁别名
                    if (this.form.Data.Aliass != null && this.form.Data.Aliass.length > 0) {
                        for (var alias of this.form.Data.Aliass) {
                            if (alias.Type == 0) {
                                this.form.Data.ZhAliass.push(alias);
                            }
                            else if (alias.Type == 1) {
                                this.form.Data.EnAliass.push(alias);
                            }
                            else if (alias.Type == 2) {
                                this.form.Data.LatinAliass.push(alias);
                            }
                        }
                    }


                });
            }
            else {

                this.form.Data = new DtoFormProduct();
                //MiezzForm.resetFields(this.$refs, "ruleForm");
            }
        }
        /*添加自定义验证 */
        onRule(): void {
            this.form.Rules.ZhAliass_Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var reg = new RegExp("^[\u4E00-\u9FA5]{1,20}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写汉字"));
                        }
                        else {

                            if (this.form.Data && this.form.Data.NameZh) {
                                if (value == this.form.Data.NameZh) {
                                    callback("信息重复，请重新填写");
                                    return false;
                                }
                            }


                            var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                            var number = Number(fields[1]);
                            if (this.form.Data.ZhAliass && this.form.Data.ZhAliass.length > 0) {
                                for (var i = 0; i < this.form.Data.ZhAliass.length; i++) {
                                    if (number != i && this.form.Data.ZhAliass[i].Name) {
                                        if (this.form.Data.ZhAliass[i].Name == value) {
                                            callback("信息重复，请重新填写");
                                            return false;
                                        }
                                    }
                                }

                            }
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.EnAliass_Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var reg = new RegExp("^[A-Za-z\\s]{1,128}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写英文字母"));
                        }
                        else {
                            if (this.form.Data && this.form.Data.NameEn) {
                                if (value.toUpperCase() == this.form.Data.NameEn.toUpperCase()) {
                                    callback("信息重复，请重新填写");
                                    return false;
                                }
                            }

                            var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                            var number = Number(fields[1]);
                            if (this.form.Data.EnAliass && this.form.Data.EnAliass.length > 0) {
                                for (var i = 0; i < this.form.Data.EnAliass.length; i++) {
                                    if (number != i && this.form.Data.EnAliass[i].Name) {
                                        if (this.form.Data.EnAliass[i].Name.toUpperCase() == value.toUpperCase()) {
                                            callback("信息重复，请重新填写");
                                            return false;
                                        }
                                    }
                                }

                            }
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.LatinAliass_Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var reg = new RegExp("^[A-Za-z\\s]{1,128}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写拉丁字母"));
                        }
                        else {
                            if (this.form.Data && this.form.Data.NameLatin) {
                                if (value.toUpperCase() == this.form.Data.NameLatin.toUpperCase()) {
                                    callback("信息重复，请重新填写");
                                    return false;
                                }
                            }

                            var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                            var number = Number(fields[1]);
                            if (this.form.Data.LatinAliass && this.form.Data.LatinAliass.length > 0) {
                                for (var i = 0; i < this.form.Data.LatinAliass.length; i++) {
                                    if (number != i && this.form.Data.LatinAliass[i].Name) {
                                        if (this.form.Data.LatinAliass[i].Name.toUpperCase() == value.toUpperCase()) {
                                            callback("信息重复，请重新填写");
                                            return false;
                                        }
                                    }
                                }

                            }
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.NameZh = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        //^[\u4e00-\u9fa5]{0,}$
                        var reg = new RegExp("^[\u4E00-\u9FA5]{1,20}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写汉字"));
                        }
                        else {

                            if (this.form.Data.ZhAliass && this.form.Data.ZhAliass.length > 0) {
                                for (var it of this.form.Data.ZhAliass) {
                                    if (it.Name == value) {
                                        callback("信息重复，请重新填写");
                                        return false;
                                    }
                                }
                            }

                            if (this.form.Data.HsCode != null && typeof (this.form.Data.HsCode) != "undefined" && this.form.Data.HsCode != "undefined" && this.form.Data.HsCode != "") {

                                var companyId = this.$route.query.CompanyId;
                                var param = {};
                                if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                                    param = {
                                        ProductName: value,
                                        HsCode: this.form.Data.HsCode,
                                        ProductId: this.id,
                                        CompanyId: companyId,
                                    };
                                }
                                else {
                                    param = {
                                        ProductName: value,
                                        HsCode: this.form.Data.HsCode,
                                        ProductId: this.id,
                                    };
                                }

                                ProductAppService.IsSameProduct(param).then((r) => {
                                    if (r.data.Data == true) {
                                        callback(new Error(""));
                                        // callback(new Error("已存在同样的产品（中文品名与HS编码相同），不能重复添加"));
                                        return false;
                                    }
                                    else {
                                        return callback();
                                    }
                                });
                            }
                            else {
                                callback();
                            }


                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.NameEn = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        //^[a-zA-Z\s]{4,50}$
                        //^ 表示开头
                        //$表示结束
                        //\s表示空格
                        var reg = new RegExp("^[A-Za-z\\s]{1,128}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写英文字母"));
                        }
                        else {
                            if (this.form.Data.EnAliass && this.form.Data.EnAliass.length > 0) {
                                for (var it of this.form.Data.EnAliass) {
                                    if (it.Name) {
                                        if (it.Name.toUpperCase() == value.toUpperCase()) {
                                            callback("信息重复，请重新填写");
                                            return false;
                                        }
                                    }
                                }
                            }
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.NameLatin = [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        if (value == null || value == undefined || value == "") {
                            callback();
                            return false;
                        }
                        //^[a-zA-Z\s]{4,50}$
                        //^ 表示开头
                        //$表示结束
                        //\s表示空格
                        var reg = new RegExp("^[A-Za-z\\s]{1,128}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写拉丁字母"));
                        }
                        else {
                            if (this.form.Data.LatinAliass && this.form.Data.LatinAliass.length > 0) {
                                for (var it of this.form.Data.LatinAliass) {
                                    if (it.Name) {
                                        if (it.Name.toUpperCase() == value.toUpperCase()) {
                                            callback("信息重复，请重新填写");
                                            return false;
                                        }
                                    }
                                }
                            }
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            
            this.form.Rules.HsCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
                            var companyId = this.$route.query.CompanyId;
                            var param = {};
                            if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                                param = {
                                    ProductName: this.form.Data.NameZh,
                                    HsCode: value,
                                    ProductId: this.id,
                                    CompanyId: companyId,
                                };
                            }
                            else {
                                param = {
                                    ProductName: this.form.Data.NameZh,
                                    HsCode: value,
                                    ProductId: this.id,
                                };
                            }
                            ProductAppService.IsSameProduct(param).then((r) => {
                                if (r.data.Data == true) {
                                    callback(new Error(""));
                                    ElMessage.warning("已存在同样的产品（中文品名与HS编码相同），不能重复添加");
                                    // callback(new Error("已存在同样的产品（中文品名与HS编码相同），不能重复添加"));
                                    return false;
                                }
                                else {
                                    return callback();
                                }
                            });
                        }
                        else {
                            return callback();
                        }
                    },
                    trigger: "change",
                },
            ];

            this.form.Rules.CustomsCodes_HsCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            callback();
                            return false;
                        }
                        var reg = new RegExp("[0-9]");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写数字"));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.Code = [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            return callback();
                        }
                        var reg = new RegExp("^[A-Za-z0-9]+$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("信息仅允许填写数字或英文字母"));
                            return false;
                        }
                        else {
                            if (value != null && typeof (value) != "undefined" && value != "undefined" && value != "") {
                                var companyId = this.$route.query.CompanyId;
                                var param = {};
                                if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                                    param = {
                                        Code: value,
                                        ProductId: this.id,
                                        CompanyId: companyId,
                                    };
                                }
                                else {
                                    param = {
                                        Code: value,
                                        ProductId: this.id,
                                    };
                                }
                                ProductAppService.IsSameCode(param).then((r) => {
                                    if (r.data.Data == true) {
                                        callback(new Error("信息重复，请重新填写"));
                                        return false;
                                    }
                                    else {
                                        return callback();
                                    }
                                });
                            }
                            else {
                                return callback();
                            }
                        }
                    },
                    trigger: "blur",
                },
            ];
        }
        /*更改自定义编号：去除所有的全角/半角空格 */
        onChangeCode(): void {
            if (this.form.Data) {
                this.form.Data.Code = TrimSpace(this.form.Data.Code as string);
            }
        }
        onChangeNameZh(): void {
            if (this.form.Data) {
                this.form.Data.NameZh = TrimSpace(this.form.Data.NameZh as string);
            }
            if (this.form.Data.HsCode) {
                MiezzForm.validateField(this.$refs, "ruleForm", "HsCode");
            }
        }
        onChangeZhAlias(index: number): void {
            if (this.form.Data) {
                this.form.Data.ZhAliass[index].Name = TrimSpace(this.form.Data.ZhAliass[index].Name as string);
            }
        }


        onDeleteZh(index: number): void {
            this.form.Data.ZhAliass.splice(index, 1);
            for (var i = 0; i < this.form.Data.ZhAliass.length; i++) {
                var name = 'ZhAliass[' + i + '].Name';
                MiezzForm.validateField(this.$refs, "ruleForm", name);
            }
            
        }
        onAddZh(): void {
            if (this.form.Data.ZhAliass == null) {
                this.form.Data.ZhAliass = [];
            }
            this.form.Data.ZhAliass.push({
                Type: 0,
                Name: null,
            });
        }


        onDeleteEn(index: number): void {
            this.form.Data.EnAliass.splice(index, 1);
            for (var i = 0; i < this.form.Data.EnAliass.length; i++) {
                var name = 'EnAliass[' + i + '].Name';
                MiezzForm.validateField(this.$refs, "ruleForm", name);
            }
        }
        onAddEn(): void {
            if (this.form.Data.EnAliass == null) {
                this.form.Data.EnAliass = [];
            }
            this.form.Data.EnAliass.push({
                Type: 1,
                Name: null,
            });
        }
        onChangeEn(index: number): void {
            this.form.Data.EnAliass[index].Name = OneSpace(this.form.Data.EnAliass[index].Name);
        }
        onChangeNameEn(): void {
            this.form.Data.NameEn = OneSpace(this.form.Data.NameEn as string);
        }
        onChangeNameLatin(): void {
            this.form.Data.NameLatin = OneSpace(this.form.Data.NameLatin as string);
        }
        onChangeLatin(index: number): void {
            this.form.Data.LatinAliass[index].Name = OneSpace(this.form.Data.LatinAliass[index].Name);
        }
        onChangeCustomsCodes_HsCode(index: number): void {
            if (this.form.Data.CustomsCodes && this.form.Data.CustomsCodes[index])
                this.form.Data.CustomsCodes[index].HsCode = Space(this.form.Data.CustomsCodes[index].HsCode as string);
        }

        onDeleteLatin(index: number): void {
            this.form.Data.LatinAliass.splice(index, 1);
            for (var i = 0; i < this.form.Data.LatinAliass.length; i++) {
                var name = 'LatinAliass[' + i + '].Name';
                MiezzForm.validateField(this.$refs, "ruleForm", name);
            }
        }
        onAddLatin(): void {
            if (this.form.Data.LatinAliass == null) {
                this.form.Data.LatinAliass = [];
            }
            this.form.Data.LatinAliass.push({
                Type: 2,
                Name: null,
            });
        }

        onChangeChargeUnitZh(): void {
            if (this.isChangeChargeUnit == false) {
                if (this.chargeUnits == null) {
                    this.chargeUnits = [];
                }
                for (var it of this.chargeUnits) {
                    if (it.Label == this.form.Data.ChargeUnithZh) {
                        this.form.Data.ChargeUnithEn = it.Data.NameEn;
                    }
                }
            }
            else {
                if (this.chargeUnits == null) {
                    this.chargeUnits = [];
                }
                var name = "";
                for (var it1 of this.chargeUnits) {
                    if (it1.Label == this.form.Data.ChargeUnithZh) {
                        name = it1.Data.NameEn;
                    }
                }
                if (this.form.Data.ChargeUnithEn != null && typeof (this.form.Data.ChargeUnithEn) != "undefined" && this.form.Data.ChargeUnithEn != "") {
                    if (name == this.form.Data.ChargeUnithEn) {
                        this.isChangeChargeUnit = false;
                    }
                }
            }

            if (this.form.Data.ChargeUnithZh != null && typeof (this.form.Data.ChargeUnithZh) != "undefined" && this.form.Data.ChargeUnithZh != "") {
                MiezzForm.clearValidate(this.$refs, "ruleForm", "ChargeUnithZh");
            }
            if (this.form.Data.ChargeUnithEn != null && typeof (this.form.Data.ChargeUnithEn) != "undefined" && this.form.Data.ChargeUnithEn != "") {
                MiezzForm.clearValidate(this.$refs, "ruleForm", "ChargeUnithEn");
            }

        }
        onChargeUnitEn(): void {
            var name = "";
            if (this.chargeUnits == null) {
                this.chargeUnits = [];
            }
            for (var it of this.chargeUnits) {
                if (it.Label == this.form.Data.ChargeUnithZh) {
                    name = it.Data.NameEn;
                }
            }
            if (this.form.Data.ChargeUnithEn != null && typeof (this.form.Data.ChargeUnithEn) != "undefined" && this.form.Data.ChargeUnithEn != "") {
                if (name == this.form.Data.ChargeUnithEn) {
                    this.isChangeChargeUnit = false;
                }
                else {
                    this.isChangeChargeUnit = true;
                }
            }
            else {
                if (typeof (this.form.Data.ChargeUnithEn) == "undefined") {
                    this.form.Data.ChargeUnithEn = "";
                }
                this.isChangeChargeUnit = false;
                this.form.Data.ChargeUnithEn = name;
            }

            if (this.form.Data.ChargeUnithZh != null && typeof (this.form.Data.ChargeUnithZh) != "undefined" && this.form.Data.ChargeUnithZh != "") {
                MiezzForm.clearValidate(this.$refs, "ruleForm", "ChargeUnithZh");
            }
            if (this.form.Data.ChargeUnithEn != null && typeof (this.form.Data.ChargeUnithEn) != "undefined" && this.form.Data.ChargeUnithEn != "") {
                MiezzForm.clearValidate(this.$refs, "ruleForm", "ChargeUnithEn");
            }
        }
        onChangeCountry(index: number, Id: string): void {
            console.log(Id);
            if (this.form.Data.CustomsCodes && this.form.Data.CustomsCodes.length > 0) {
                // 中国默认已选，中国Id为70c82817-64cb-4f2f-35ec-08d6fd0b427a
                if (Id == "70C82817-64CB-4F2F-35EC-08D6FD0B427A")  {
                    ElMessage.warning("中国默认已选，无需再次选择！");
                    this.form.Data.CustomsCodes[index].CountryId = "";
                }
                else if(this.form.Data.CustomsCodes.filter(x => x.CountryId == Id).length > 1) {
                    ElMessage.warning("该国家已选，请勿重复选择！")
                    this.form.Data.CustomsCodes[index].CountryId = "";
                } 
                else {
                    if (this.countrys == null) {
                        this.countrys = [];
                    }
                    for (var it of this.countrys) {
                        if (this.form.Data.CustomsCodes == null) {
                            this.form.Data.CustomsCodes = [];
                        }
                        if (it.Value == this.form.Data.CustomsCodes[index].CountryId) {
                            this.form.Data.CustomsCodes[index].CountryName = it.Label;
                        }
                    }
                }
            }
        }
        onDeleteHsCode(index: number): void {
            if (this.form.Data.CustomsCodes == null || this.form.Data.CustomsCodes == undefined) {
                this.form.Data.CustomsCodes = [];
            }
            this.form.Data.CustomsCodes.splice(index, 1);
            for (var i = 0; i < this.form.Data.CustomsCodes.length; i++) {
                var name = 'CustomsCodes[' + i + '].HsCode';
                MiezzForm.validateField(this.$refs, "ruleForm", name);
            }
        }
        onAddHsCode(): void {
            if (this.form.Data.CustomsCodes == null) {
                this.form.Data.CustomsCodes = [];
            }
            this.form.Data.CustomsCodes.push({
                CountryId: undefined,
                CountryName: undefined,
                HsCode: undefined,
                StatutoryFirstUnit: undefined,
                StatutorySecondUnit: undefined,
            });
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            this.form.Data.Aliass = [];
            if (this.form.Data.ZhAliass != null && this.form.Data.ZhAliass.length > 0) {
                for (var zh of this.form.Data.ZhAliass) {
                    this.form.Data.Aliass.push(zh);
                }
            }
            if (this.form.Data.EnAliass != null && this.form.Data.EnAliass.length > 0) {
                for (var en of this.form.Data.EnAliass) {
                    this.form.Data.Aliass.push(en);
                }
            }
            if (this.form.Data.LatinAliass != null && this.form.Data.LatinAliass.length > 0) {
                for (var latin of this.form.Data.LatinAliass) {
                    this.form.Data.Aliass.push(latin);
                }
            }
            var companyId = this.$route.query.CompanyId;
            if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                if (this.form.Data) {
                    this.form.Data.CompanyId = companyId as string;
                }
            }
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            ProductAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.handleSubmit();
                                    complete();
                                    this.handleBack();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "continue":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            ProductAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.form.Data = new DtoFormProduct();
                                    MiezzForm.resetFields(this.$refs, "ruleForm");
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                case "reset":
                    this.handleGet();
                    MiezzForm.resetFields(this.$refs, "ruleForm");
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            ////
            //history.back(-1);
        }
    }

    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }
    // 去除所有空格
    function Space(str: string): string {
        if (str == null || str == undefined || str == "") {
            return str;
        }
        var reg = new RegExp(" ", "g");
        return str.replace(reg, "");
    }

    /*去除连续空格，只保留一个空格 */
    function OneSpace(str: string) {

        var name = "";
        if (str != undefined && str != "") {
            var i = 0;
            for (i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (i = 0; i < str.length; i++) {
                if (str[i] != " ") {
                    name += str[i];
                }
                else {
                    if (i > 0 && str[i - 1] != " ") {
                        name += str[i];
                    }
                }
            }
        }
        return name.trim();
    }
