
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButtonSource from "./MiezzButtonSource.vue";
import MiezzButtonUpload from "./MiezzButtonUpload.vue";
@Options({
  components: {
    MiezzButtonSource,
    MiezzButtonUpload,
  },
})
export default class MiezzButtonDropdown extends Vue {
  @Prop() model = new MiezzButton();

  button = new MiezzButton();

  created(): void {
    this.onModelChanged();
  }

  @Watch("model")
  onModelChanged(): void {
    CurrentLogier.GetPowerCodes().then((r) => {
      this.model.Options = this.model.Options?.filter(
        (it) => !it.Hide && (!it.PowerCode || r?.contains(it.PowerCode))
      );
      for (const item of this.model.Options ?? []) {
        item.Type = "text";
        item.CancelClickEvent = true;
      }
      const btn = Object.copy(this.model) as MiezzButton;
      btn.Options = undefined;
      btn.CancelClickEvent = true;
      this.button = btn;
    });
  }

  handleBeforeCommand(command?: any): void {
    this.model.Command = command;
    this.handleCommand(this.model, () => {
      //
    });
  }

  @Emit()
  handleCommand(model: MiezzButton, complete: () => void): void {
    //
  }

  @Emit()
  handleDropdowItemUpload(model: MiezzButton, file: any): void {
    //
  }
}
