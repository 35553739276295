import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 装货港-报关表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutReleaseTime extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 海关放行时间
     */
    ReleaseTime?: Date;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 
     */
    Service?: EnumOrderServiceType;
    /**
     * 报关/清关主键
     */
    OrderDepartureId?: string;
}

/**
 * 装货港-报关表单-表单校验
 */
export const DtoPutReleaseTimeRules = {
    ReleaseTime: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 装货港-报关表单-设置项
 */
export const DtoPutReleaseTimeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ReleaseTime",
    Label: "海关放行时间",
    Customize: true,
  },
  {
    Prop: "Service",
    Label: "",
    Customize: true,
  },
]
