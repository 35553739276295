import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_delete = _resolveComponent("delete")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Avatar')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.form.Data.Avatar,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Avatar) = $event)),
                  path: "user/avatar",
                  accept: "image/*",
                  maxSize: 2 * 1024 * 1024
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Numbers')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table, {
                  data: _ctx.form.Data.Numbers,
                  border: "",
                  stripe: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      prop: "Type",
                      key: "Type",
                      label: "联系方式"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_select, {
                          modelValue: row.Type,
                          "onUpdate:modelValue": ($event: any) => ((row.Type) = $event),
                          options: _ctx.EnumContactTypeOption
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "Content",
                      key: "Content",
                      label: "号码/内容"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Content,
                          "onUpdate:modelValue": ($event: any) => ((row.Content) = $event),
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      prop: "Remark",
                      key: "Remark",
                      label: "备注"
                    }, {
                      default: _withCtx(({ row }) => [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Remark,
                          "onUpdate:modelValue": ($event: any) => ((row.Remark) = $event),
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_table_column, {
                      label: "操作",
                      width: "65px"
                    }, {
                      default: _withCtx(({ $index }) => [
                        _createVNode(_component_el_button, {
                          type: "default",
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleDeleteNumber($index))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data"]),
                _createVNode(_component_miezz_button, {
                  model: _ctx.btnAddContact,
                  onHandleClick: _ctx.handleClick
                }, null, 8, ["model", "onHandleClick"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"])
  ], 64))
}