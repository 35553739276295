import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderPoolTrunk_File_OrderPoolTrunkFile from "./DtoPutOrderPoolTrunk_File_OrderPoolTrunkFile";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolTrunk_File extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Files = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 文件
     */
    Files?: DtoPutOrderPoolTrunk_File_OrderPoolTrunkFile[];
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolTrunk_FileRules = {
}

/**
 * -设置项
 */
export const DtoPutOrderPoolTrunk_FileMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Files",
    Label: "文件",
    Customize: true,
  },
]
