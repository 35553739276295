import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormProductSpecElement_ProductSpecElementItem from "./DtoFormProductSpecElement_ProductSpecElementItem";
import UiGroup from "./UiGroup";

/**
 * 产品规格要素
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormProductSpecElement extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.ProductSpecElementItems = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 要素名称
     */
    Name?: string;
    /**
     * 子要素
     */
    ProductSpecElementItems?: DtoFormProductSpecElement_ProductSpecElementItem[];
}

/**
 * 产品规格要素-表单校验
 */
export const DtoFormProductSpecElementRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 产品规格要素-设置项
 */
export const DtoFormProductSpecElementMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "要素名称",
    Group: "ProductSpecElementForm",
    Customize: true,
  },
  {
    Prop: "ProductSpecElementItems",
    Label: "子要素",
    Group: "ProductSpecElementForm",
    Span: 24,
    Customize: true,
  },
]
