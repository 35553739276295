
import DtoFormCodeProperty, {
  DtoFormCodePropertyMiezzFormItems,
} from "@/models.machine/DtoFormCodeProperty";
import {
  DtoFormCodePropertyBaseInfoMiezzFormItems,
  DtoFormCodePropertyBaseInfoRules,
} from "@/models.machine/DtoFormCodePropertyBaseInfo";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Model, Emit } from "vue-property-decorator";
export default class CodePropertyForm extends Vue {
  @Model() modal!: MiezzModal;
  @Model() modelValue = new MiezzForm<DtoFormCodeProperty, string>();

  created(): void {
    this.modelValue.DefaultSpan = 12;
    this.modelValue.Rules = DtoFormCodePropertyBaseInfoRules;
    this.modelValue.Items = [];
    this.modelValue.Items = this.modelValue.Items.concat(
      DtoFormCodePropertyBaseInfoMiezzFormItems
    );
    this.modelValue.Items = this.modelValue.Items.concat(
      DtoFormCodePropertyMiezzFormItems
    );
    this.modelValue.Items = this.modelValue.Items.filter(
      (it) => it.Prop != "Properties"
    );

    this.modal.HandleClick = this.handleClick;
    this.modal.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    const type = this.modelValue.Items.find((it) => it.Prop == "Type");
    if (type) {
      type.Type = EnumMiezzFormItemType.Select;
      type.AllowCreate = true;
      const types = [
        "Guid",
        "string",
        "int",
        "short",
        "long",
        "decimal",
        "double",
        "float",
        "bool",
        "DateTime",
      ];
      type.Options = types.map((it) => {
        return { Label: it, Value: it };
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            this.handleSubmit();
          }
          complete();
        });
        break;
      default:
        break;
    }
  }

  @Emit()
  handleSubmit(): void {
    //
  }
}
