import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    ref: "miezz-select",
    modelValue: _ctx.select.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.select.value) = $event)),
    filterable: "",
    clearable: _ctx.clearable ?? true,
    placeholder: _ctx.placeholder ?? '请选择',
    "allow-create": _ctx.allowCreate,
    multiple: _ctx.multiple,
    disabled: _ctx.disabled,
    "filter-method": _ctx.filterMethod,
    remote: _ctx.remote,
    "remote-method": _ctx.remoteMethod,
    onClick: _ctx.handleClick,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChange())),
    onClear: _ctx.handleClear,
    onFocus: _ctx.handleFocus,
    onVisibleChange: _ctx.handleVisibleChange,
    "default-first-option": "",
    "reserve-keyword": true,
    "no-data-text": _ctx.noDataText ?? '无匹配数据',
    "no-match-text": _ctx.noDataText ?? '无匹配数据',
    class: _normalizeClass(`${_ctx.className} form-item-select${_ctx.red ? ' red' : ''}`)
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.select.options, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item.value,
          label: item.label,
          value: item.value,
          disabled: item.disabled,
          class: _normalizeClass(`${_ctx.markKey ? 'mark' : ''}${item.mark ? ' mark-active' : ''}`)
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", { model: item }, undefined, true)
          ]),
          _: 2
        }, 1032, ["label", "value", "disabled", "class"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["modelValue", "clearable", "placeholder", "allow-create", "multiple", "disabled", "filter-method", "remote", "remote-method", "onClick", "onClear", "onFocus", "onVisibleChange", "no-data-text", "no-match-text", "class"]))
}