
import { Vue, Options } from "vue-class-component";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import RoleAppService from "@/apis.machine/RoleAppService";
import DtoListItemRole, {
  DtoListItemRoleMiezzListItems,
} from "@/models.machine/DtoListItemRole";
import MiezzButton from "@/models/MiezzButton";
import DtoPageListSearchRole, {
  DtoPageListSearchRoleDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchRole";
import DtoPageListRole from "@/models.machine/DtoPageListRole";
import RoleForm from "./RoleForm.vue";
import RoleDetail from "./RoleDetail.vue";
import { ElMessage } from "element-plus";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    RoleForm,
    RoleDetail,
  },
})
export default class RolePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<DtoPageListSearchRole, DtoListItemRole, string>(
    "System_Role"
  );
  id?: string;
  modalDetail = new MiezzModal();
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.list.Dto = new DtoPageListSearchRole();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchRoleDynamicSearchItems;
    this.list.Data = new DtoPageListRole();
    this.list.Items = DtoListItemRoleMiezzListItems;
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "System_Role_AddEdit",
      },
    ];
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "System_Role_Detail",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
        PowerCode: "System_Role_Delete",
      },
    ];
    this.list.HandleFormat = (item: MiezzListItem, row: DtoListItemRole) => {
      if (item.Prop == "JoinCompanyTypeRoles") {
        return (
          row.JoinCompanyTypeRoles?.map((it) => it.CompanyType?.Name)?.join(
            "，"
          ) ?? "暂无数据"
        );
      }
      return undefined;
    };
  }

  /**查询列表 */
  handleGet(): void {
    RoleAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    RoleAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增角色";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemRole,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `角色详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "batch-delete":
        RoleAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
