import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return (_openBlock(), _createBlock(_component_el_menu_item, {
    index: _ctx.model.Id,
    route: _ctx.model.Url
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.model.Name), 1)
    ]),
    _: 1
  }, 8, ["index", "route"]))
}