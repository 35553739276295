import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_code_choose_property_tag = _resolveComponent("code-choose-property-tag")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        type: "default",
        size: "small",
        onClick: _ctx.handleQuote
      }, {
        default: _withCtx(() => [
          _createTextVNode("引用")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_code_choose_property_tag, {
        modelValue: _ctx.properties,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.properties) = $event)),
        onHandleClick: _ctx.handleClick
      }, null, 8, ["modelValue", "onHandleClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_miezz_input, {
        modelValue: _ctx._template,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx._template) = $event)),
        placeholder: "选择器-模板",
        class: "input-text",
        clearable: "",
        onHandleChange: _ctx.handleChange
      }, null, 8, ["modelValue", "onHandleChange"])
    ]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_code_property_tree, {
          modal: _ctx.modal,
          "onUpdate:modal": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event)),
          modelValue: _ctx._properties,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx._properties) = $event)),
          table: _ctx.table,
          show: ['Type'],
          onHandleClick: _ctx.handleModalButtonClick
        }, null, 8, ["modal", "modelValue", "table", "onHandleClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}