import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailProjectVersion_Project from "./DtoDetailProjectVersion_Project";
import { EnumProjectVersionType, EnumProjectVersionTypeOption } from "./EnumProjectVersionType";
import DtoDetailProjectVersion_GitLog from "./DtoDetailProjectVersion_GitLog";
import UiGroup from "./UiGroup";

/**
 * 系统版本详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailProjectVersion extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Number = "0.0.0";
        this.MainNumber = 0;
        this.MinorNumber = 0;
        this.RevisionNumber = 0;
        this.GitLogs = [];
    }

    [proName: string]: any;
    /**
     * 项目
     */
    Project?: DtoDetailProjectVersion_Project;
    /**
     * 类型
     */
    Type?: EnumProjectVersionType;
    /**
     * 版本号
     */
    Number?: string;
    /**
     * 主版本号
     */
    MainNumber?: number;
    /**
     * 次版本号
     */
    MinorNumber?: number;
    /**
     * 修订号
     */
    RevisionNumber?: number;
    /**
     * 发布内容
     */
    Content?: string;
    /**
     * Git分支
     */
    GitBranchName?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * GIT日志
     */
    GitLogs?: DtoDetailProjectVersion_GitLog[];
}

/**
 * 系统版本详细-表单校验
 */
export const DtoDetailProjectVersionRules = {
}

/**
 * 系统版本详细-设置项
 */
export const DtoDetailProjectVersionMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Project",
    Label: "项目",
    Customize: true,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumProjectVersionTypeOption,
  },
  {
    Prop: "Number",
    Label: "版本号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Content",
    Label: "发布内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GitBranchName",
    Label: "Git分支",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GitLogs",
    Label: "GIT日志",
    Customize: true,
  },
]
