import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 交期要求分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemDeliveryRequirement extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 交期要求分页列表-项-表单校验
 */
export const DtoListItemDeliveryRequirementRules = {
}

/**
 * 交期要求分页列表-项-设置项
 */
export const DtoListItemDeliveryRequirementMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
]
