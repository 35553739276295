import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderDepartureWharf from "../models.machine/DtoPageListSearchOrderDepartureWharf";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderDepartureWharf from "../models.machine/DtoPageListOrderDepartureWharf";
import DtoFormOrderDepartureWharf from "../models.machine/DtoFormOrderDepartureWharf";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "../models.machine/EnumOrderServiceType";
import DtoAutoAddWharf from "../models.machine/DtoAutoAddWharf";
import DtoRequestOrderPoolPackageShape from "../models.machine/DtoRequestOrderPoolPackageShape";
import DtoPageListOrderPoolPackageShape from "../models.machine/DtoPageListOrderPoolPackageShape";
import DtoPageListOrderDepartureWharfInStock from "../models.machine/DtoPageListOrderDepartureWharfInStock";
import DtoFormOrderDepartureWharfInStock from "../models.machine/DtoFormOrderDepartureWharfInStock";
import DtoCallbackSupplier from "../models.machine/DtoCallbackSupplier";
import DtoPutStockTime from "../models.machine/DtoPutStockTime";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListOrderDepartureWharfOutStock from "../models.machine/DtoPageListOrderDepartureWharfOutStock";
import DtoFormOrderDepartureWharfOutStock from "../models.machine/DtoFormOrderDepartureWharfOutStock";
import DtoPutOrderDepartureWharfAnnex from "../models.machine/DtoPutOrderDepartureWharfAnnex";
import DtoPutOrderDepartureWharfWarehousing from "../models.machine/DtoPutOrderDepartureWharfWarehousing";
import DtoPutBoxSticker from "../models.machine/DtoPutBoxSticker";
import DtoRequestBoxSticker from "../models.machine/DtoRequestBoxSticker";
import DtoRepuestOutStock from "../models.machine/DtoRepuestOutStock";
import DtoRequestTime from "../models.machine/DtoRequestTime";
import DtoBatchWharf from "../models.machine/DtoBatchWharf";
import DtoRequestOrderDepartureWharfChart from "../models.machine/DtoRequestOrderDepartureWharfChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoRepuestWarehousing from "../models.machine/DtoRepuestWarehousing";
import DtoSynchronizePoolData from "../models.machine/DtoSynchronizePoolData";

/**
 * 装货港-仓库/堆场/码头
 * @description 自动生成的代码,请勿修改
 */
export default class OrderDepartureWharfAppService {
    /**
     * 获取装货港-仓库/堆场/码头分页列表
     * @param {DtoPageListSearchOrderDepartureWharf} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharf>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderDepartureWharf): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharf>> {
        const url = `/api/OrderDepartureWharf/Get`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureWharf>>(url, { params: dto });
    }

    /**
     * 添加装货港-仓库/堆场/码头
     * @param {DtoFormOrderDepartureWharf} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderDepartureWharf): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureWharf/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除装货港-仓库/堆场/码头
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 添加
     * @param {string} [OrderPoolId] 委托书主键
     * @param {EnumOrderServiceType} [Type] 类型
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AddByOrderPoolIdByType(OrderPoolId?: string, Type?: EnumOrderServiceType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/Add/${OrderPoolId}/${Type}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 默认添加
     * @param {DtoAutoAddWharf} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AutoAdd(dto?: DtoAutoAddWharf): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/AutoAdd`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取计划货量
     * @param {DtoRequestOrderPoolPackageShape} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolPackageShape>>} 
     */
    static GetPlans(dto?: DtoRequestOrderPoolPackageShape): AxiosPromise<RESTfulResult<DtoPageListOrderPoolPackageShape>> {
        const url = `/api/OrderDepartureWharf/GetPlans`;
        return service.get<RESTfulResult<DtoPageListOrderPoolPackageShape>>(url, { params: dto });
    }

    /**
     * 获取入库货量
     * @param {DtoRequestOrderPoolPackageShape} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>} 
     */
    static GetInStocks(dto?: DtoRequestOrderPoolPackageShape): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>> {
        const url = `/api/OrderDepartureWharf/GetInStocks`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>(url, { params: dto });
    }

    /**
     * 入库
     * @param {DtoFormOrderDepartureWharfInStock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static InStock(dto?: DtoFormOrderDepartureWharfInStock): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDepartureWharf/InStock`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 编辑供应商
     * @param {string} [OrderDepartureWharfId] 装货港-堆场的主键
     * @param {DtoCallbackSupplier} [dto] 供应商表单数据
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditSupplierByOrderDepartureWharfId(OrderDepartureWharfId?: string, dto?: DtoCallbackSupplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditSupplier/${OrderDepartureWharfId}`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改出入库日期
     * @param {DtoPutStockTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditTime(dto?: DtoPutStockTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditTime`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改计划入货时间
     * @param {DtoPutStockTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditPlanInStockTime(dto?: DtoPutStockTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditPlanInStockTime`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取剩余数量>0的入库货量汇总
     * @param {DtoRequestOrderPoolPackageShape} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetInStockSelect(dto?: DtoRequestOrderPoolPackageShape): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetInStockSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取入库货量的全部汇总
     * @param {DtoRequestOrderPoolPackageShape} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetInStockAll(dto?: DtoRequestOrderPoolPackageShape): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetInStockAll`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取出库货量
     * @param {DtoRequestOrderPoolPackageShape} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfOutStock>>} 
     */
    static GetOutStocks(dto?: DtoRequestOrderPoolPackageShape): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfOutStock>> {
        const url = `/api/OrderDepartureWharf/GetOutStocks`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureWharfOutStock>>(url, { params: dto });
    }

    /**
     * 出库
     * @param {DtoFormOrderDepartureWharfOutStock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static OutStock(dto?: DtoFormOrderDepartureWharfOutStock): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDepartureWharf/OutStock`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 保存附件
     * @param {DtoPutOrderDepartureWharfAnnex} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditAnnex(dto?: DtoPutOrderDepartureWharfAnnex): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditAnnex`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取附件
     * @param {string} [OrderDepartureWharfId] 
     * @returns {AxiosPromise<RESTfulResult<DtoPutOrderDepartureWharfAnnex>>} 
     */
    static GetAnnexsByOrderDepartureWharfId(OrderDepartureWharfId?: string): AxiosPromise<RESTfulResult<DtoPutOrderDepartureWharfAnnex>> {
        const url = `/api/OrderDepartureWharf/GetAnnexs/${OrderDepartureWharfId}`;
        return service.get<RESTfulResult<DtoPutOrderDepartureWharfAnnex>>(url);
    }

    /**
     * 获取进仓通知
     * @param {string} [OrderDepartureWharfId] 
     * @returns {AxiosPromise<RESTfulResult<DtoPutOrderDepartureWharfWarehousing>>} 
     */
    static GetWarehousingByOrderDepartureWharfId(OrderDepartureWharfId?: string): AxiosPromise<RESTfulResult<DtoPutOrderDepartureWharfWarehousing>> {
        const url = `/api/OrderDepartureWharf/GetWarehousing/${OrderDepartureWharfId}`;
        return service.get<RESTfulResult<DtoPutOrderDepartureWharfWarehousing>>(url);
    }

    /**
     * 编辑进仓通知
     * @param {DtoPutOrderDepartureWharfWarehousing} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditWarehousing(dto?: DtoPutOrderDepartureWharfWarehousing): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditWarehousing`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 是否需要箱贴
     * @param {string} [OrderPoolId] 委托书主键
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsBoxStickerByOrderPoolId(OrderPoolId?: string): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDepartureWharf/IsBoxSticker/${OrderPoolId}`;
        return service.post<RESTfulResult<boolean>>(url);
    }

    /**
     * 获取箱贴
     * @param {string} [OrderPoolId] 委托书主键
     * @param {string} [OrderDepartureWharfId] 堆场主键
     * @returns {AxiosPromise<RESTfulResult<DtoPutBoxSticker>>} 
     */
    static GetBoxStickerByOrderPoolIdByOrderDepartureWharfId(OrderPoolId?: string, OrderDepartureWharfId?: string): AxiosPromise<RESTfulResult<DtoPutBoxSticker>> {
        const url = `/api/OrderDepartureWharf/GetBoxSticker/${OrderPoolId}/${OrderDepartureWharfId}`;
        return service.get<RESTfulResult<DtoPutBoxSticker>>(url);
    }

    /**
     * 编辑箱贴
     * @param {DtoPutBoxSticker} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditBoxSticker(dto?: DtoPutBoxSticker): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/EditBoxSticker`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 导出进仓通知
     * @param {string} [OrderDepartureWharfId] 堆场主键
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static ExportWarehousingByOrderDepartureWharfId(OrderDepartureWharfId?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureWharf/ExportWarehousing/${OrderDepartureWharfId}`;
        return service.post<RESTfulResult<string>>(url);
    }

    /**
     * 导出箱贴
     * @param {DtoRequestBoxSticker} [param] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static BoxStickerExport(param?: DtoRequestBoxSticker): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureWharf/BoxStickerExport`;
        return service.post<RESTfulResult<string>>(url, param);
    }

    /**
     * 获取委托人集合
     * @param {string} [OrderId] 订单主键
     * @param {EnumOrderServiceType} [Type] 服务
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientCompanyByOrderIdByType(OrderId?: string, Type?: EnumOrderServiceType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetClientCompany/${OrderId}/${Type}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 判断入库数量是否小于出库数量
     * @param {DtoRepuestOutStock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsOutStock(dto?: DtoRepuestOutStock): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDepartureWharf/IsOutStock`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取出入库时间
     * @param {DtoRequestTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoRequestTime>>} 
     */
    static GetTime(dto?: DtoRequestTime): AxiosPromise<RESTfulResult<DtoRequestTime>> {
        const url = `/api/OrderDepartureWharf/GetTime`;
        return service.get<RESTfulResult<DtoRequestTime>>(url, { params: dto });
    }

    /**
     * 获取装货港出库数据
     * @param {string} [WharfId] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>} 
     */
    static GetDepartureOutStockByWharfId(WharfId?: string): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>> {
        const url = `/api/OrderDepartureWharf/GetDepartureOutStock/${WharfId}`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>(url);
    }

    /**
     * 批量修改
     * @param {DtoBatchWharf} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static BatchEdit(dto?: DtoBatchWharf): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/BatchEdit`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取批量的信息
     * @param {DtoBatchWharf} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoBatchWharf>>} 
     */
    static GetBatch(dto?: DtoBatchWharf): AxiosPromise<RESTfulResult<DtoBatchWharf>> {
        const url = `/api/OrderDepartureWharf/GetBatch`;
        return service.get<RESTfulResult<DtoBatchWharf>>(url, { params: dto });
    }

    /**
     * 获取仓库集合
     * @param {string} [SupplierCompanyId] 供应商主键
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetWarehousesBySupplierCompanyId(SupplierCompanyId?: string): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetWarehouses/${SupplierCompanyId}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 
     * @param {string} [orderSupplierWarehouseId] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveWarehouseByOrderSupplierWarehouseId(orderSupplierWarehouseId?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/RemoveWarehouse/${orderSupplierWarehouseId}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 判断委托书有清单时，数量是否已送完
     * @param {string} [poolId] 
     * @param {EnumOrderServiceType} [type] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static GetFinishByPoolIdByType(poolId?: string, type?: EnumOrderServiceType): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDepartureWharf/GetFinish/${poolId}/${type}`;
        return service.get<RESTfulResult<boolean>>(url);
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderDepartureWharfChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderDepartureWharfChart): AxiosPromise<RESTfulResult<UiEChartsBar>> {
        const url = `/api/OrderDepartureWharf/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderDepartureWharfChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderDepartureWharfChart): AxiosPromise<RESTfulResult<UiEChartsPie>> {
        const url = `/api/OrderDepartureWharf/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie>>(url, { params: dto });
    }

    /**
     * 获取委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClients(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetClients`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取仓库/堆场/码头名称选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetWarehouseNames(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/OrderDepartureWharf/GetWarehouseNames`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取卸货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 
     * @param {DtoRepuestWarehousing} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetWarehousAddress(dto?: DtoRepuestWarehousing): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetWarehousAddress`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取工作时间集合
     * @param {DtoRepuestWarehousing} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetWarehousWorkTime(dto?: DtoRepuestWarehousing): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetWarehousWorkTime`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取联系人集合
     * @param {DtoRepuestWarehousing} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetWarehousContacter(dto?: DtoRepuestWarehousing): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureWharf/GetWarehousContacter`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 同步委托书中的数量
     * @param {DtoSynchronizePoolData} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoSynchronizePoolData>>} 
     */
    static SynchronizePoolData(dto?: DtoSynchronizePoolData): AxiosPromise<RESTfulResult<DtoSynchronizePoolData>> {
        const url = `/api/OrderDepartureWharf/SynchronizePoolData`;
        return service.post<RESTfulResult<DtoSynchronizePoolData>>(url, dto);
    }

    /**
     * 删除地址
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveHistoryById(id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDepartureWharf/RemoveHistory/${id}`;
        return service.delete<RESTfulResult<any>>(url);
    }

    /**
     * 获取计费重量
     * @param {DtoPageListOrderDepartureWharfInStock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>} 
     */
    static GetChargeWeight(dto?: DtoPageListOrderDepartureWharfInStock): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfInStock>> {
        const url = `/api/OrderDepartureWharf/GetChargeWeight`;
        return service.post<RESTfulResult<DtoPageListOrderDepartureWharfInStock>>(url, dto);
    }

    /**
     * 获取备注
     * @param {DtoRepuestWarehousing} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetWarehousRemark(dto?: DtoRepuestWarehousing): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureWharf/GetWarehousRemark`;
        return service.get<RESTfulResult<string>>(url, { params: dto });
    }

    /**
     * 获取路线图
     * @param {DtoRepuestWarehousing} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetWarehousRoad(dto?: DtoRepuestWarehousing): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureWharf/GetWarehousRoad`;
        return service.get<RESTfulResult<string>>(url, { params: dto });
    }

}
