import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-发票状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumInvoiceStatus {
    /**
     * 待添加
     */
    NO = 10,
    /**
     * 部分添加
     */
    Part = 20,
    /**
     * 已添加
     */
    All = 30,
}

/**
 * 枚举-发票状态-选项
 */
export const EnumInvoiceStatusOption: UiSelectOption<number>[] = [
    { Label: "待添加", Value: 10, Data: "NO" },
    { Label: "部分添加", Value: 20, Data: "Part" },
    { Label: "已添加", Value: 30, Data: "All" },
];
