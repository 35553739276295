import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolExpress_CarryContainerTime extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 提柜时间
     */
    CarryContainerTime?: Date;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolExpress_CarryContainerTimeRules = {
}

/**
 * -设置项
 */
export const DtoPutOrderPoolExpress_CarryContainerTimeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CarryContainerTime",
    Label: "提柜时间",
    Customize: true,
  },
]
