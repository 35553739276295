import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 航空公司表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamAirlineCompany extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 查询网址
     */
    Url?: string;
}

/**
 * 航空公司表单-表单校验
 */
export const DtoFormParamAirlineCompanyRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Url: [
        {
            max: 12040,
            message: "文本长度不能超过 12040 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 航空公司表单-设置项
 */
export const DtoFormParamAirlineCompanyMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Url",
    Label: "查询网址",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
