
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemJoinCompanyUser, {
  DtoListItemJoinCompanyUserMiezzListItems,
} from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoPageListJoinCompanyUser from "@/models.machine/DtoPageListJoinCompanyUser";
import DtoPageListSearchJoinCompanyUser, {
  DtoPageListSearchJoinCompanyUserDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserPageList from "../UserPageList.vue";
@Options({
  components: {
    UserPageList,
  },
})
export default class CurrentUserPageList extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >("CompanyManagement_User");

  created(): void {
    const logier = CurrentLogier.GetLogier();
    const items = JSON.copy(
      DtoListItemJoinCompanyUserMiezzListItems
    ) as MiezzListItem[];
    if (logier?.CompanyType == EnumCompanyType.Platform) {
      this.list.Items = items.filter(
        (it) => it.Prop != "Company" && it.Prop != "Trusteeship"
      );
    } else {
      this.list.Items = items.filter(
        (it) =>
          it.Prop != "Company" &&
          it.Prop != "Trusteeship" &&
          it.Prop != "UserStatus"
      );
    }
    const account = this.list.Items.firstOrDefault(
      (it) => it.Prop == "UserPhoneNumber"
    );
    if (account) {
      account.Customize = true;
    }
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchJoinCompanyUserDynamicSearchItems.filter(
        (it) => it.Prop != "DynamicSearch_CompanyId"
      );
    //列表-顶部按钮
    this.list.TopButtons = [];
    if (logier?.CompanyType == EnumCompanyType.Customer) {
      this.list.TopButtons.push({
        Code: "trusteeship",
        Title: "服务商托管",
        Label: "服务商托管",
        Type: "checkbox",
        Size: "small",
        CheckProp: "Trusteeship",
      });
    }
    this.list.TopButtons.push({
      Code: "add",
      Label: "添加",
      Icon: "plus",
      Type: "primary",
      Size: "small",
      PowerCode: "CompanyManagement_User_Add",
    });
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_User_Detail",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "辞退",
        Icon: "delete",
        Type: "text",
        Message: "是否确认辞退",
        PowerCode: "CompanyManagement_User_Delete",
      },
      {
        Code: "batch-update-company-user-status",
        Label: "员工状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "CompanyManagement_User_UpdateCompanyUserStatus",
        Options: [
          {
            Code: "0",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
      {
        Code: "batch-update-disabled-read-supplier",
        Label: "供应商信息",
        Icon: "edit",
        Type: "text",
        PowerCode: "CompanyManagement_User_UpdateDisabledReadSupplier",
        Options: [
          {
            Code: "False",
            Label: "可见",
            Size: "small",
          },
          {
            Code: "True",
            Label: "不可见",
            Size: "small",
          },
        ],
      },
    ];
  }

  @Watch("list.Data")
  onListDataChanged(): void {
    const trusteeship = this.list.TopButtons.firstOrDefault(
      (it) => it.Code == "trusteeship"
    );
    if (trusteeship) {
      const data = this.list.Data as DtoPageListJoinCompanyUser;
      trusteeship.Check = data.Trusteeship;
    }
  }
}
