import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 单位分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyBusinessCardUnit extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 
     */
    NameZh?: string;
    /**
     * 社会信用代码
     */
    Code?: string;
    /**
     * 企业海关编码
     */
    CustomsCode?: string;
}

/**
 * 单位分页列表-项-表单校验
 */
export const DtoListItemCompanyBusinessCardUnitRules = {
}

/**
 * 单位分页列表-项-设置项
 */
export const DtoListItemCompanyBusinessCardUnitMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "",
  },
  {
    Prop: "Code",
    Label: "社会信用代码",
  },
  {
    Prop: "CustomsCode",
    Label: "企业海关编码",
  },
]
