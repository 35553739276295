import DtoFromInvoiceAnnex from "./DtoFromInvoiceAnnex";
import DtoFromInvoiceItem_CurrencySystem from "./DtoFromInvoiceItem_CurrencySystem";
import { EnumInvoiceSettlementStatus, EnumInvoiceSettlementStatusOption } from "./EnumInvoiceSettlementStatus";

/**
 * 发票明细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFromInvoiceItem {
    constructor() {
        //无参构造函数
        this.IsChangeSettlementCurrencyId = false;
        this.SettlementStatus = EnumInvoiceSettlementStatus.NoFlag;
    }

    [proName: string]: any;
    /**
     * 是否可以自动改变兑换币制
     */
    IsChangeSettlementCurrencyId?: boolean;
    /**
     * 主键
     */
    Id?: string;
    /**
     * 收款方发票抬头
     */
    CollectionTitle?: string;
    /**
     * 付款方发票抬头
     */
    PaymentTitle?: string;
    /**
     * 发票号码
     */
    Number?: string;
    /**
     * 开票日期
     */
    InvoiceDate?: Date;
    /**
     * 开票日期
     */
    InvoiceDateString?: string;
    /**
     * 附件
     */
    Annexs?: DtoFromInvoiceAnnex[];
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 金额
     */
    Price?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 
     */
    CurrencySystem?: DtoFromInvoiceItem_CurrencySystem;
    /**
     * 核销金额
     */
    SettlementPrice?: number;
    /**
     * 核销币制
     */
    SettlementCurrencySystemId?: string;
    /**
     * 
     */
    SettlementCurrencySystem?: DtoFromInvoiceItem_CurrencySystem;
    /**
     * 核销汇率
     */
    SettlementRate?: number;
    /**
     * 发票核销状态
     */
    SettlementStatus?: EnumInvoiceSettlementStatus;
}

/**
 * 发票明细-表单校验
 */
export const DtoFromInvoiceItemRules = {
}
