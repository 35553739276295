import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'NameZh')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "中文名称",
            prop: "NameZh"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入中文名称",
                onHandleBlur: _ctx.onBlurNameZh,
                pattern: '[\u4e00-\u9fa5a-zA-Z]'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'NameEn')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "英文名称",
            prop: "NameEn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入英文名称",
                onHandleBlur: _ctx.onBlurNameEn,
                trim: false,
                pattern: '(^[a-zA-Z])([a-zA-Z ]*)'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "pattern"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "代码",
            prop: "Code"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入代码",
                onHandleBlur: _ctx.onBlurCode
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}