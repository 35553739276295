import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchContactTransportMode from "../models.machine/DtoSelectSearchContactTransportMode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchContactTransportMode from "../models.machine/DtoPageListSearchContactTransportMode";
import DtoPageListContactTransportMode from "../models.machine/DtoPageListContactTransportMode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailContactTransportMode from "../models.machine/DtoDetailContactTransportMode";
import DtoFormContactTransportMode from "../models.machine/DtoFormContactTransportMode";

/**
 * 国际贸易运输方式
 * @description 自动生成的代码,请勿修改
 */
export default class ContactTransportModeAppService {
    /**
     * 获取国际贸易运输方式选择器
     * @param {DtoSelectSearchContactTransportMode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchContactTransportMode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ContactTransportMode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取国际贸易运输方式分页列表
     * @param {DtoPageListSearchContactTransportMode} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListContactTransportMode>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchContactTransportMode): AxiosPromise<RESTfulResult<DtoPageListContactTransportMode>> {
        const url = `/api/ContactTransportMode/Get`;
        return service.get<RESTfulResult<DtoPageListContactTransportMode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ContactTransportMode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取国际贸易运输方式详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailContactTransportMode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailContactTransportMode>> {
        const url = `/api/ContactTransportMode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailContactTransportMode>>(url);
    }

    /**
     * 新增/编辑国际贸易运输方式详细
     * @param {DtoFormContactTransportMode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormContactTransportMode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ContactTransportMode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除国际贸易运输方式详细
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ContactTransportMode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ContactTransportMode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ContactTransportMode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ContactTransportMode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

}
