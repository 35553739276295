
/**
 * 计费重量
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackWeight {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 单个体积
     */
    Volume?: number;
    /**
     * 单个毛重
     */
    GrossWeight?: number;
    /**
     * 单个数量
     */
    Quantity?: number;
}

/**
 * 计费重量-表单校验
 */
export const DtoCallbackWeightRules = {
}
