
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzForm from "@/models/MiezzForm";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import DtoPageListOrderPoolPackageShape from "@/models.machine/DtoPageListOrderPoolPackageShape";
    import DtoListItemOrderPoolPackageShape, {
        DtoListItemOrderPoolPackageShapeMiezzListItems,
    } from "@/models.machine/DtoListItemOrderPoolPackageShape";

    import DtoRepuestOutStock from "@/models.machine/DtoRepuestOutStock";
    import DtoPageListOrderDepartureWharfInStock from "@/models.machine/DtoPageListOrderDepartureWharfInStock";
    import DtoListItemOrderDepartureWharfInStock, {
        DtoListItemOrderDepartureWharfInStockMiezzListItems,
    } from "@/models.machine/DtoListItemOrderDepartureWharfInStock";
    import DtoFormOrderDepartureWharfInStock from "@/models.machine/DtoFormOrderDepartureWharfInStock";
    import MiezzListItem from "../../models/MiezzListItem";
    import DtoRequestOrderPoolPackageShape from "@/models.machine/DtoRequestOrderPoolPackageShape";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    import {
        EnumOrderServiceType
    } from "@/models.machine/EnumOrderServiceType";
    export default class MiezzWharfInStock extends Vue {
        //委托书主键
        @Prop() orderPoolId?: string;
        @Prop() isDetail?: boolean;

        type = EnumOrderServiceType.DeparturePort;
        isPlan = true;
        isOutStock = true;
        orderDepartureWharfId?: string;
        chargeUnits?: UiSelectOption<string>[] = [];
        packageEnHistorys?: UiSelectOption<string>[] = [];
        isLoading = false;
        /*是否可以录入合计数据 */
        isSum = true;
        ruleForm: {
            Visible: boolean;
            Total: number;
            CurrentPage: number;
            PageSize: number;
        } = {
                Visible: false,
                Total: 0,
                CurrentPage: 1,
                PageSize: 10,
            };
        currentPage = 1;
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "download",
                Label: "导入装货港出库数据",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        types = [
            {
                Value: 10,
                Label: "按重量计费"
            },
            {
                Value: 20,
                Label: "按体积计费"
            },
        ];

        customsPackageTableRules = {
            InStockQuantity: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[2]);
                        if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                            var t = this.list.Data.Items[number];
                            var quantity = 0;
                            var inStockQuantity = 0;
                            for (var it of this.list.Data.Items) {
                                if (it.Length == t.Length && it.Width == t.Width && it.Height == t.Height && it.GrossWeight == t.GrossWeight) {
                                    if (it.InStockQuantity) {
                                        inStockQuantity += Number(it.InStockQuantity);
                                    }
                                }
                            }
                            if (this.list.Data.OutStocks) {
                                for (var sl of this.list.Data.OutStocks) {
                                    if (sl.Length == t.Length && sl.Width == t.Width && sl.Height == t.Height && sl.GrossWeight == t.GrossWeight) {
                                        if (sl.Quantity) {
                                            quantity += Number(sl.Quantity);
                                        }
                                    }
                                }
                            }
                            if (quantity && inStockQuantity && quantity > inStockQuantity) {
                                callback("入库货量不能小于出库货量，请重新填写");
                                return false;
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Length: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && Number(value) < 1) {
                            callback("长度不能小于1cm，请重新填写");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Width: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && Number(value) < 1) {
                            callback("宽度不能小于1cm，请重新填写");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Height: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != "" && Number(value) < 1) {
                            callback("高度不能小于1cm，请重新填写");
                            return false;
                        }
                        callback();
                    },
                    trigger: "blur",
                },],
            Volume: [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[2]);
                        if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                            if (this.list.Data.Items[number].Length != null && this.list.Data.Items[number].Length != undefined
                                && this.list.Data.Items[number].Width != null && this.list.Data.Items[number].Width != undefined
                                && this.list.Data.Items[number].Height != null && this.list.Data.Items[number].Height != undefined
                            ) {
                                var length = this.list.Data.Items[number].Length;
                                var width = this.list.Data.Items[number].Width;
                                var height = this.list.Data.Items[number].Height;
                                if (length && width && height && length > 0 && width > 0 && height > 0) {
                                    if (value == null || value == undefined || value == "") {
                                        callback("不能小于0.0001CBM，请调整长宽高");
                                        return false;
                                    }
                                }
                            }
                        }
                        if (value != "" && Number(value) < 0.0001) {
                            callback("不能小于0.0001CBM，请调整长宽高");
                            return false;
                        }
                        callback();
                    },
                    trigger: "change",
                },],
        };

        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();

        defaultTime = new Date(2000, 1, 1, 12, 0, 0);

        form = new MiezzForm<DtoFormOrderDepartureWharfInStock, string>();
        list = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfInStock, string>();
        listInit = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfInStock, string>();
        selects = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfInStock, string>();

        plan = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderPoolPackageShape, string>();
        isInit = false;
        created(): void {
            //列表-传参
            this.plan.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.plan.Data = new DtoPageListOrderPoolPackageShape();
            //列表-列配置
            this.plan.Items = DtoListItemOrderPoolPackageShapeMiezzListItems;

            //列表-传参
            this.list.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderDepartureWharfInStock();
            //列表-列配置
            this.list.Items = DtoListItemOrderDepartureWharfInStockMiezzListItems;

            //ParamChargeUnitAppService.GetSelect().then(r => {
            //    this.chargeUnits = r.data.Data;
            //})

        }
        onDeletePackageEn(id: string, index: number): void {
            //PackageEnAppService.RemoveHistoryById(id).then((r) => {
            //    this.packageEnHistorys?.splice(index, 1);
            //    //this.form.Data.PackageEn = this.preSelectPackageEn;
            //    return;
            //})

            var value = "";
            if (this.list.Data) {
                value = this.list.Data.ChargeUnitInStock;
            }
            PackageEnAppService.RemoveHistoryById(id).then((r) => {
                var oldValue = "";
                if (this.packageEnHistorys) {
                    oldValue = this.packageEnHistorys[index].Label as string;
                }

                this.packageEnHistorys?.splice(index, 1);
                if (this.list.Data) {
                    if (oldValue != value) {
                        this.list.Data.ChargeUnitInStock = value;
                    }
                    else {
                        this.list.Data.ChargeUnitInStock = "";
                    }
                }

                if (this.list.Data && this.list.Data.ChargeUnitInStock == "" && this.packageEnHistorys && this.packageEnHistorys.length > 0) {
                    this.list.Data.ChargeUnitInStock = this.packageEnHistorys[0].Label;
                }

                ElMessage.success("历史记录已删除");
                return;
            })

        }
        onBatchDelete(): void {
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {

                        if (this.selects.Data == undefined || this.selects.Data.Items == undefined || this.selects.Data.Items.length <= 0) {
                            ElMessage.warning("请至少勾选一条信息");
                            return;
                        }
                        else {
                            if (this.list.Data && this.list.Data.Items) {
                                var indexs = [];
                                for (var it of this.selects.Data.Items) {
                                    indexs.push(it.Index);
                                }
                                for (var index of indexs) {
                                    this.list.Data.Items = this.list.Data.Items.filter(item => item.Index !== index);
                                }
                            }
                            this.onSum();
                        }
                    }
                }
            })
        }

        handleSelectionChange(val: any, index: number) {
            if (this.selects.Data) {
                this.selects.Data.Items = val;
            }
        }

        /*初始化 */
        @Emit()
        onInit(orderDepartureWharfId: string | undefined, type: EnumOrderServiceType): void {
            this.isPlan = true;
            this.isOutStock = true;
            this.plan.Data = new DtoPageListOrderPoolPackageShape();
            var packageEnParam = {};
            PackageEnAppService.GetSelect(packageEnParam).then(en => {
                this.packageEnHistorys = en.data.Data;

                this.type = type;
                this.orderDepartureWharfId = orderDepartureWharfId;
                this.isLoading = true;
                this.ruleForm.CurrentPage = 1;
                this.ruleForm.Total = 0;
                this.isInit = true;
                this.onSearch();
                this.ruleForm.Visible = true;
                if (this.bottomButtons) {
                    this.bottomButtons[0].Hide = this.isDetail;
                }

            })

        }
        onSearch(): void {
            if (this.isInit == false) {
                MiezzForm.customSubmit(this.$refs, "ruleFormInStock", (v) => {
                    if (v == true) {
                        this.onData();
                    } else {
                        this.ruleForm.CurrentPage = this.currentPage;
                    }
                });
            } else {
                this.isInit = false;
                this.onData();
            }
        }
        onAdd(): void {

            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {

                        if (this.list.Data && this.list.Data.Items) {

                            var index = 0;
                            if (this.list.Data.Items.length > 0) {
                                var max = this.list.Data.Items[this.list.Data.Items.length - 1].Index;
                                if (max != undefined) {
                                    index = max + 1;
                                }
                            }
                            this.list.Data.Items.push({
                                Length: undefined,
                                Width: undefined,
                                Height: undefined,
                                GrossWeight: undefined,
                                Volume: undefined,
                                InStockQuantity: undefined,
                                InStockTime: undefined,
                                Index: index,
                            });
                        }
                    }
                }
            })
        }
        onDelete(index: number): void {

            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {

                        if (this.list && this.list.Data && this.list.Data.Items) {
                            var t = this.list.Data.Items[index];
                            var inQuanity = 0;
                            var outQuantity = 0;
                            for (var i = 0; i < this.list.Data.Items.length; i++) {
                                if (i != index) {
                                    var inStock = this.list.Data.Items[i];
                                    if (inStock.Length == t.Length && inStock.Width == t.Width && inStock.Height == t.Height && inStock.GrossWeight == t.GrossWeight && inStock.InStockQuantity) {
                                        inQuanity += Number(inStock.InStockQuantity + "");
                                    }
                                }
                            }
                            if (this.list.Data.OutStocks) {
                                for (var os of this.list.Data.OutStocks) {
                                    if (os.Length == t.Length && os.Width == t.Width && os.Height == t.Height && os.GrossWeight == t.GrossWeight && os.Quantity) {
                                        outQuantity += Number(os.Quantity + "");
                                    }
                                }
                            }
                            if (inQuanity < outQuantity) {
                                ElMessage.warning({
                                    customClass: "testMessage",
                                    dangerouslyUseHTMLString: true,
                                    message: "该箱规的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作",
                                })
                                this.onSum();
                                return;
                            }

                            if (this.list.Data && this.list.Data.Items) {
                                this.list.Data.Items.splice(index, 1);
                                for (var j = 0; j < this.list.Data.Items.length; j++) {
                                    var name = 'Data.Items.' + j + '.InStockQuantity';
                                    MiezzForm.validateFieldCustom(this.$refs, "ruleFormInStock", name);
                                }
                            }

                            this.onSum();
                        }



                        //if (r.data.Data == true) {
                        //            var shape = param.Length + "×" + param.Width + "×" + param.Height;
                        //            //ElMessage.warning("当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作");
                        //            ElMessage.warning({
                        //                customClass: "testMessage",
                        //                dangerouslyUseHTMLString: true,
                        //                message: "当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作",
                        //            })
                        //            this.onSum();
                        //            return;
                        //        }
                        //        else {
                        //            if (this.list.Data && this.list.Data.Items) {
                        //                this.list.Data.Items.splice(index, 1);
                        //                for (var i = 0; i < this.list.Data.Items.length; i++) {
                        //                    var name = 'Data.Items.' + i + '.InStockQuantity';
                        //                    MiezzForm.validateFieldCustom(this.$refs, "ruleFormInStock", name);
                        //                }
                        //            }

                        //            this.onSum();
                        //        }


                        //if (this.list.Data != undefined && this.list.Data.Items != undefined) {

                        //    var result = setDecimal(this.list.Data.Items[index].InStockQuantity + "", 0, false);
                        //    if (result != "") {
                        //        this.list.Data.Items[index].InStockQuantity = Number(result);
                        //    } else {
                        //        this.list.Data.Items[index].InStockQuantity = undefined;
                        //    }

                        //    var param = new DtoRepuestOutStock();
                        //    param.OrderDepartureWharfId = this.orderDepartureWharfId;
                        //    param.Length = this.list.Data.Items[index].Length;
                        //    param.Width = this.list.Data.Items[index].Width;
                        //    param.Height = this.list.Data.Items[index].Height;
                        //    param.GrossWeight = this.list.Data.Items[index].GrossWeight;
                        //    param.Quantity = 0;
                        //    param.InStockId = this.list.Data.Items[index].Id;
                        //    OrderDepartureWharfAppService.IsOutStock(param).then((r) => {
                        //        console.log(r);
                        //        if (r.data.Data == true) {
                        //            var shape = param.Length + "×" + param.Width + "×" + param.Height;
                        //            //ElMessage.warning("当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作");
                        //            ElMessage.warning({
                        //                customClass: "testMessage",
                        //                dangerouslyUseHTMLString: true,
                        //                message: "当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作",
                        //            })
                        //            this.onSum();
                        //            return;
                        //        }
                        //        else {
                        //            if (this.list.Data && this.list.Data.Items) {
                        //                this.list.Data.Items.splice(index, 1);
                        //                for (var i = 0; i < this.list.Data.Items.length; i++) {
                        //                    var name = 'Data.Items.' + i + '.InStockQuantity';
                        //                    MiezzForm.validateFieldCustom(this.$refs, "ruleFormInStock", name);
                        //                }
                        //            }

                        //            this.onSum();
                        //        }
                        //    })

                        //}





                    }
                }
            })
        }
        onChangeSumQuantity(): void {
            if (this.list.Data != undefined) {
                var result = setDecimal(this.list.Data.SumQuantityInStock + "", 0, false);
                if (result != "") {
                    this.list.Data.SumQuantityInStock = Number(result);
                } else {
                    this.list.Data.SumQuantityInStock = undefined;
                }
            }

            this.onCountChargeWeight();
        }
        onChangeSumGrossWeight(): void {
            if (this.list.Data != undefined) {
                var result = setDecimal(this.list.Data.SumGrossWeightInStock + "", 2, false);
                if (result != "") {
                    this.list.Data.SumGrossWeightInStock = Number(result);
                } else {
                    this.list.Data.SumGrossWeightInStock = undefined;
                }
            }
            this.onCountChargeWeight();
        }
        onChangeSumVolume(): void {
            if (this.list.Data != undefined) {
                var result = setDecimal(this.list.Data.SumVolumeInStock + "", 4, false);
                if (result != "") {
                    this.list.Data.SumVolumeInStock = Number(result);
                } else {
                    this.list.Data.SumVolumeInStock = undefined;
                }
            }
            this.onCountChargeWeight();
        }
        onChangeVolume(index: number): void {
            if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                var result = setDecimal(this.list.Data.Items[index].Volume + "", 4, false);
                if (result != "") {
                    this.list.Data.Items[index].Volume = Number(result);
                } else {
                    this.list.Data.Items[index].Volume = undefined;
                }
            }

            this.onSum();
        }
        onChangeGrossWeight(index: number): void {
            if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                var result = setDecimal(this.list.Data.Items[index].GrossWeight + "", 2, false);
                if (result != "") {
                    this.list.Data.Items[index].GrossWeight = Number(result);
                } else {
                    this.list.Data.Items[index].GrossWeight = undefined;
                }
            }

            this.onSum();
        }
        onChangeLength(index: number): void {
            if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                var result = setDecimal(this.list.Data.Items[index].Length + "", 2, false);
                if (result != "") {
                    this.list.Data.Items[index].Length = Number(result);
                } else {
                    this.list.Data.Items[index].Length = undefined;
                }
            }
            this.onVolume(index);
        }
        onChangeWidth(index: number): void {
            if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                var result = setDecimal(this.list.Data.Items[index].Width + "", 2, false);
                if (result != "") {
                    this.list.Data.Items[index].Width = Number(result);
                } else {
                    this.list.Data.Items[index].Width = undefined;
                }
            }
            this.onVolume(index);
        }
        onChangeHeight(index: number): void {
            if (this.list.Data != undefined && this.list.Data.Items != undefined) {
                var result = setDecimal(this.list.Data.Items[index].Height + "", 2, false);
                if (result != "") {
                    this.list.Data.Items[index].Height = Number(result);
                } else {
                    this.list.Data.Items[index].Height = undefined;
                }
            }
            this.onVolume(index);
        }
        onVolume(index: number): void {

            if (this.list.Data && this.list.Data.Items && this.list.Data.Items[index].Length && this.list.Data.Items[index].Width && this.list.Data.Items[index].Height) {
                var temp = Number(this.list.Data.Items[index].Length) * Number(this.list.Data.Items[index].Width) * Number(this.list.Data.Items[index].Height);

                var result2 = setDecimal(temp.toString(), 6, false);
                if (result2 != "") {
                    temp = Number(temp);
                }
                var num = temp / 1000000;
                if (num < 0.0001) {
                    num = 0;
                }
                var result1 = setDecimal(num.toString(), 4, false);
                if (result1 != "") {
                    this.list.Data.Items[index].Volume = Number(result1);
                }
                else {
                    this.list.Data.Items[index].Volume = undefined;
                }
            }
            this.onSum();
        }

        onChangeInStockQuantity(index: number): void {

            if (this.list.Data != undefined && this.list.Data.Items != undefined) {

                var result = setDecimal(this.list.Data.Items[index].InStockQuantity + "", 0, false);
                if (result != "") {
                    this.list.Data.Items[index].InStockQuantity = Number(result);
                } else {
                    this.list.Data.Items[index].InStockQuantity = undefined;
                }

                //var param = new DtoRepuestOutStock();
                //param.OrderDepartureWharfId = this.orderDepartureWharfId;
                //param.Length = this.list.Data.Items[index].Length;
                //param.Width = this.list.Data.Items[index].Width;
                //param.Height = this.list.Data.Items[index].Height;
                //param.GrossWeight = this.list.Data.Items[index].GrossWeight;
                //param.Quantity = this.list.Data.Items[index].InStockQuantity;
                //param.InStockId = this.list.Data.Items[index].Id;
                //OrderDepartureWharfAppService.IsOutStock(param).then((r) => {
                //    if (r.data.Data == true) {
                //        var shape = param.Length + "×" + param.Width + "×" + param.Height;
                //        //ElMessage.warning("当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作");

                //        ElMessage.warning({
                //            customClass: "testMessage",
                //            dangerouslyUseHTMLString: true,
                //            message: "当前操作会造成规格（" + shape + "）的入库数量＜出库数量，无法修改，请先至出库数量中调整后再进行操作",
                //        })

                //        if (this.listInit && this.listInit.Data && this.listInit.Data.Items && this.list.Data && this.list.Data.Items) {
                //            for (var it of this.listInit.Data.Items) {
                //                if (it.Id == this.list.Data.Items[index].Id) {
                //                    this.list.Data.Items[index].InStockQuantity = it.InStockQuantity;
                //                }
                //            }
                //        }
                //        this.onSum();
                //        return;
                //    }
                //})

            }

            this.onSum();

        }

        onSum(): void {
            this.isSum = true;
            if (this.list.Data && this.list.Data.Items) {
                this.list.Data.SumQuantityInStock = 0;
                this.list.Data.SumGrossWeightInStock = 0;
                this.list.Data.SumVolumeInStock = 0;
                for (var it of this.list.Data.Items) {
                    if (it.Length != undefined || it.Width != undefined || it.Height != undefined || it.GrossWeight != undefined || it.Volume != undefined || it.InStockQuantity != undefined) {
                        this.isSum = false;
                    }
                    if (it.InStockQuantity != undefined) {
                        this.list.Data.SumQuantityInStock += it.InStockQuantity;

                        if (it.GrossWeight != undefined) {
                            this.list.Data.SumGrossWeightInStock += it.InStockQuantity * it.GrossWeight;
                        }

                        if (it.Volume != undefined) {
                            this.list.Data.SumVolumeInStock += it.InStockQuantity * it.Volume;
                        }
                    }

                    it.IsOutStock = false;
                    if (this.list.Data.OutStocks) {
                        for (var os of this.list.Data.OutStocks) {
                            if (os.Length == it.Length && os.Width == it.Width && os.Height == it.Height && os.GrossWeight == it.GrossWeight) {
                                if (os.Quantity) {
                                    it.IsOutStock = true;
                                }
                            }
                        }
                    }
                }

                if (this.list.Data.SumQuantityInStock == 0) {
                    this.list.Data.SumQuantityInStock = undefined;
                }
                else {
                    var quantity = setDecimal(this.list.Data.SumQuantityInStock + "", 0, false);
                    if (quantity != "") {
                        this.list.Data.SumQuantityInStock = Number(quantity);
                    } else {
                        this.list.Data.SumQuantityInStock = undefined;
                    }
                }


                if (this.list.Data.SumGrossWeightInStock == 0) {
                    this.list.Data.SumGrossWeightInStock = undefined;
                }
                else {
                    var grossWeight = setDecimal(this.list.Data.SumGrossWeightInStock + "", 2, false);
                    if (grossWeight != "") {
                        this.list.Data.SumGrossWeightInStock = Number(grossWeight);
                    } else {
                        this.list.Data.SumGrossWeightInStock = undefined;
                    }
                }



                if (this.list.Data.SumVolumeInStock == 0) {
                    this.list.Data.SumVolumeInStock = undefined;
                }
                else {
                    var volume = setDecimal(this.list.Data.SumVolumeInStock + "", 4, false);
                    if (volume != "") {
                        this.list.Data.SumVolumeInStock = Number(volume);
                    } else {
                        this.list.Data.SumVolumeInStock = undefined;
                    }
                }
            }

            this.onCountChargeWeight();
        }
        onDepartureDetail(): void {
            window.open(
                "#/pool/departure/wharf/" + this.orderPoolId,
                "_blank"
            );
        }

        onData(): void {
            this.isSum = true;
            if (this.list && this.list.Dto) {
                this.list.Dto.CurrentPage = this.ruleForm.CurrentPage;
                this.list.Dto.PageSize = this.ruleForm.PageSize;
                this.list.Dto.OrderPoolId = this.orderPoolId;
                this.list.Dto.OrderDepartureWharfId = this.orderDepartureWharfId;
                this.list.Dto.IsDetail = this.isDetail;
                this.list.Dto.Type = this.type;
            }
            OrderDepartureWharfAppService.GetInStocks(this.list.Dto).then((r) => {


                this.list.Data = JSON.parse(JSON.stringify(r.data.Data));
                this.listInit.Data = JSON.parse(JSON.stringify(r.data.Data));
                //if (this.list && this.list.Data && this.list.Data.IsDestinationPort == true && this.bottomButtons) {
                //    this.bottomButtons[1].Hide = this.isDetail;
                //}
                if (this.list.Data && this.list.Data.Items && this.list.Data.Items.length > 0) {
                    for (var it2 of this.list.Data.Items) {
                        if (it2.Length == null || it2.Length == 0) {
                            it2.Length = undefined;
                        }
                        if (it2.Width == null || it2.Width == 0) {
                            it2.Width = undefined;
                        }
                        if (it2.Height == null || it2.Height == 0) {
                            it2.Height = undefined;
                        }

                        if (it2.Length != undefined || it2.Width != undefined || it2.Height != undefined || it2.GrossWeight != undefined || it2.Volume != undefined || it2.InStockQuantity != undefined) {
                            this.isSum = false;
                        }
                    }
                }
                if (this.plan && this.plan.Dto) {
                    this.plan.Dto.CurrentPage = this.ruleForm.CurrentPage;
                    this.plan.Dto.PageSize = this.ruleForm.PageSize;
                    this.plan.Dto.OrderPoolId = this.orderPoolId;
                    this.plan.Dto.OrderDepartureWharfId = this.orderDepartureWharfId;
                    this.plan.Dto.IsDetail = true;
                    this.plan.Dto.Type = this.type;
                }

                if (this.list && this.list.Data) {
                    if (this.list.Data.IsShiftout == true && this.bottomButtons) {
                        this.bottomButtons[0].Hide = true;
                        this.bottomButtons[1].Hide = true;
                    }
                    if (this.list.Data.IsGetChargeWeight == true) {
                        this.onCountChargeWeight();
                    }
                }
                this.onCountChargeWeight();
                OrderDepartureWharfAppService.GetPlans(this.plan.Dto).then((r) => {
                    this.plan.Data = JSON.parse(JSON.stringify(r.data.Data));
                    this.currentPage = this.ruleForm.CurrentPage;
                    this.isLoading = false;
                    if (this.plan.Data && this.plan.Data.Items) {
                        for (var it of this.plan.Data.Items) {
                            it.ProductName = "";
                            if (it.Package && it.Package.Products && it.Package.Products.length > 0) {
                                for (var pro of it.Package.Products) {
                                    if (pro.NameZh && pro.NameZh != "") {
                                        it.ProductName += "【" + pro.NameZh + "】";
                                    }
                                }
                            }
                        }
                    }

                    MiezzForm.clearCustomValidate(this.$refs, "ruleFormInStock", undefined);
                    //(this.$refs["ruleFormInStock"] as any).form().resetFields();
                })
            });
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "download":
                    //orderDepartureWharfId
                    if (this.list && this.list.Data && this.list.Data.Items) {
                        this.list.Data.Items = [];
                    }
                    OrderDepartureWharfAppService.GetDepartureOutStockByWharfId(this.orderDepartureWharfId).then((r) => {
                        if (r.data.Data && r.data.Data.Items && this.list && this.list.Data && this.list.Data.Items) {
                            for (var it3 of r.data.Data.Items) {
                                this.list.Data.Items.push(it3);
                            }
                            this.onSum();
                            complete();
                        }
                    })
                    break;
                case "submit":

                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                //if (this.list == undefined || this.list.Data == undefined || this.list.Data.Items == null || this.list.Data.Items == undefined || this.list.Data.Items.length <= 0) {
                                //    ElMessage.warning("请至少添加一条入库货量");
                                //    complete();
                                //    return;
                                //}
                                //for (var item1 of this.list.Data.Items) {
                                //    var count = 0;
                                //    for (var item2 of this.list.Data.Items) {
                                //        if (item1.Length == item2.Length && item1.Width == item2.Width && item1.Height == item2.Height && item1.GrossWeight == item2.GrossWeight) {
                                //            count++;
                                //        }
                                //    }
                                //    if (count > 1) {
                                //        ElMessage.warning("相同的长宽高和毛重只允许一条入库货量");
                                //        complete();
                                //        return;
                                //    }
                                //}
                                MiezzForm.customSubmit(this.$refs, "ruleFormInStock", (v) => {
                                    if (v == true) {

                                        if (this.list.Data != undefined) {
                                            this.form.Data = new DtoFormOrderDepartureWharfInStock();
                                            this.form.Data.InStocks = [];
                                            this.form.Data.OrderPoolId = this.orderPoolId;
                                            this.form.Data.OrderDepartureWharfId = this.orderDepartureWharfId;
                                            this.form.Data.SumQuantityInStock = this.list.Data.SumQuantityInStock;
                                            this.form.Data.SumGrossWeightInStock = this.list.Data.SumGrossWeightInStock;
                                            this.form.Data.SumVolumeInStock = this.list.Data.SumVolumeInStock;
                                            this.form.Data.ChargeUnitInStock = this.list.Data.ChargeUnitInStock;
                                            this.form.Data.RemarkInStock = this.list.Data.RemarkInStock;
                                            this.form.Data.CompanyChargeId = this.list.Data.CompanyChargeId;
                                            this.form.Data.ChargeType = this.list.Data.ChargeType;
                                            this.form.Data.ChargeWeight = this.list.Data.ChargeWeight;

                                            if (this.list.Data.Items && this.list.Data.Items.length > 0) {
                                                for (var it of this.list.Data.Items) {
                                                    this.form.Data.InStocks?.push({
                                                        Length: it.Length,
                                                        Width: it.Width,
                                                        Height: it.Height,
                                                        GrossWeight: it.GrossWeight,
                                                        Volume: it.Volume,
                                                        InStockQuantity: it.InStockQuantity,
                                                        InStockTime: it.InStockTime,
                                                        OrderDepartureWharfPlanId: it.OrderDepartureWharfPlanId,
                                                        OrderDepartureWharfId: this.orderDepartureWharfId,
                                                        ChargeWeight: it.ChargeWeight,
                                                    });
                                                }
                                            }


                                            if (this.list.Data.SumQuantityInStock != null && this.list.Data.SumQuantityInStock != undefined && this.list.Data.SumQuantityOutStock != null && this.list.Data.SumQuantityOutStock != undefined && this.list.Data.SumQuantityOutStock > this.list.Data.SumQuantityInStock
                                                || this.list.Data.SumVolumeInStock != null && this.list.Data.SumVolumeInStock != undefined && this.list.Data.SumVolumeOutStock != null && this.list.Data.SumVolumeOutStock != undefined && this.list.Data.SumVolumeOutStock > this.list.Data.SumVolumeInStock
                                                || this.list.Data.SumGrossWeightInStock != null && this.list.Data.SumGrossWeightInStock != undefined && this.list.Data.SumGrossWeightOutStock != null && this.list.Data.SumGrossWeightOutStock != undefined && this.list.Data.SumGrossWeightOutStock > this.list.Data.SumGrossWeightInStock
                                            ) {
                                                ElMessage.warning("当前操作会造成入库数据（件数/毛重/体积）＜出库数据（件数/毛重/体积），无法修改，请先至出库数量中调整后再进行操作");
                                                complete();
                                                return;
                                            }


                                            this.$emit("onSelectInStock", this.form.Data);
                                            this.ruleForm.Visible = false;
                                        }

                                        complete();
                                    } else {
                                        complete();
                                    }
                                });

                            }
                        }
                    })
                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        onChangeType(): void {
            if (this.list && this.list.Data) {
                this.list.Data.CompanyChargeId = undefined;
                this.list.Data.VolumeRatioWeight = undefined;
                this.list.Data.MinimumChargeableWeight = undefined;
                this.list.Data.OneMinimumChargeableWeight = undefined;
                if (this.list.Data.ChargeType == 10) {
                    if (this.list.Data.Weights && this.list.Data.Weights.length > 0) {
                        this.list.Data.CompanyChargeId = this.list.Data.Weights[0].Value;
                        this.list.Data.VolumeRatioWeight = this.list.Data.Weights[0].Data.VolumeRatio;
                        this.list.Data.MinimumChargeableWeight = this.list.Data.Weights[0].Data.MinStandardPiece;
                        this.list.Data.OneMinimumChargeableWeight = this.list.Data.Weights[0].Data.MinStandardTicket;
                    }
                }
                else if (this.list.Data.ChargeType == 20) {
                    if (this.list.Data.Volumes && this.list.Data.Volumes.length > 0) {
                        this.list.Data.CompanyChargeId = this.list.Data.Volumes[0].Value;
                        this.list.Data.VolumeRatioWeight = this.list.Data.Volumes[0].Data.VolumeRatio;
                        this.list.Data.MinimumChargeableWeight = this.list.Data.Volumes[0].Data.MinStandardPiece;
                        this.list.Data.OneMinimumChargeableWeight = this.list.Data.Volumes[0].Data.MinStandardTicket;
                    }
                }
            }
            this.onCountChargeWeight();
        }

        onChangeChargeWeight(): void {
            if (this.list && this.list.Data) {
                this.list.Data.VolumeRatioWeight = undefined;
                this.list.Data.MinimumChargeableWeight = undefined;
                this.list.Data.OneMinimumChargeableWeight = undefined;
                if (this.list.Data.Weights && this.list.Data.Weights.length > 0) {
                    for (var it of this.list.Data.Weights) {
                        if (it.Value == this.list.Data.CompanyChargeId) {
                            this.list.Data.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.list.Data.MinimumChargeableWeight = it.Data.MinStandardPiece;
                            this.list.Data.OneMinimumChargeableWeight = it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onCountChargeWeight();
        }

        onChangeChargeVolume(): void {
            if (this.list && this.list.Data) {
                this.list.Data.VolumeRatioWeight = undefined;
                this.list.Data.MinimumChargeableWeight = undefined;
                this.list.Data.OneMinimumChargeableWeight = undefined;
                if (this.list.Data.Volumes && this.list.Data.Volumes.length > 0) {
                    for (var it of this.list.Data.Volumes) {
                        if (it.Value == this.list.Data.CompanyChargeId) {
                            this.list.Data.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.list.Data.MinimumChargeableWeight = it.Data.MinStandardPiece;
                            this.list.Data.OneMinimumChargeableWeight = it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onCountChargeWeight();
        }

        onCountChargeWeight(): void {
            if (this.list && this.list.Data) {
                OrderDepartureWharfAppService.GetChargeWeight(this.list.Data).then((r) => {
                    if (r.data.Data) this.list.Data = r.data.Data;
                })
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
