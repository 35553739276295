import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderManifestVgm_MainBill from "./DtoFormOrderManifestVgm_MainBill";
import DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct from "./DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct";
import DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm from "./DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderManifestVgm extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.SureManifestVgm = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 确认舱单VGM信息
     */
    SureManifestVgm?: boolean;
    /**
     * 主单信息
     */
    MainBill?: DtoFormOrderManifestVgm_MainBill;
    /**
     * 舱单VGM-明细品名及数据
     */
    DetailProducts?: DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct[];
    /**
     * 舱单VGM-VGM数据
     */
    DetailVgms?: DtoFormOrderManifestVgm_OrderManifestVgmDetailVgm[];
    /**
     * 代码
     */
    ShipperCode?: string;
    /**
     * 名称
     */
    ShipperNameEn?: string;
    /**
     * 地址
     */
    ShipperAddressEn?: string;
    /**
     * 国家/地区代码
     */
    ShipperCountryCode?: string;
    /**
     * 电话
     */
    ShipperTel?: string;
    /**
     * AEO编码(选填)
     */
    ShipperAeoCode?: string;
    /**
     * 代码
     */
    ConsigneeCode?: string;
    /**
     * 名称
     */
    ConsigneeNameEn?: string;
    /**
     * 地址
     */
    ConsigneeAddressEn?: string;
    /**
     * 国家/地区代码
     */
    ConsigneeCountryCode?: string;
    /**
     * 电话
     */
    ConsigneeTel?: string;
    /**
     * AEO编码(选填)
     */
    ConsigneeAeoCode?: string;
    /**
     * 具体联系人
     */
    ConsigneeContact?: string;
    /**
     * 联系人电话
     */
    ConsigneeContactNumber?: string;
    /**
     * 代码
     */
    NotifyPartyCode?: string;
    /**
     * 名称
     */
    NotifyPartyNameEn?: string;
    /**
     * 地址
     */
    NotifyPartyAddressEn?: string;
    /**
     * 国家/地区代码
     */
    NotifyPartyCountryCode?: string;
    /**
     * 电话
     */
    NotifyPartyTel?: string;
    /**
     * AEO编码(选填)
     */
    NotifyPartyAeoCode?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderManifestVgmRules = {
    ShipperCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ShipperNameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ShipperAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ShipperCountryCode: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    ShipperTel: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ShipperAeoCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeNameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeCountryCode: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeTel: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeAeoCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeContact: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ConsigneeContactNumber: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyNameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyCountryCode: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyTel: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NotifyPartyAeoCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderManifestVgmMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "SureManifestVgm",
    Label: "确认舱单VGM信息",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "MainBill",
    Label: "主单信息",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DetailProducts",
    Label: "舱单VGM-明细品名及数据",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DetailVgms",
    Label: "舱单VGM-VGM数据",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperCode",
    Label: "代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperNameEn",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperAddressEn",
    Label: "地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperCountryCode",
    Label: "国家/地区代码",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "ShipperTel",
    Label: "电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ShipperAeoCode",
    Label: "AEO编码(选填)",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeCode",
    Label: "代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeNameEn",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeAddressEn",
    Label: "地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeCountryCode",
    Label: "国家/地区代码",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "ConsigneeTel",
    Label: "电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeAeoCode",
    Label: "AEO编码(选填)",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeContact",
    Label: "具体联系人",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ConsigneeContactNumber",
    Label: "联系人电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyCode",
    Label: "代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyNameEn",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyAddressEn",
    Label: "地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyCountryCode",
    Label: "国家/地区代码",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "NotifyPartyTel",
    Label: "电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NotifyPartyAeoCode",
    Label: "AEO编码(选填)",
    Type: EnumMiezzFormItemType.InputText,
  },
]
