import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumAuditStatus, EnumAuditStatusOption } from "./EnumAuditStatus";
import UiGroup from "./UiGroup";

/**
 * 关联-客户公司主体关联表单-审核
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCompanyCustomerMainRelationAudit extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.AuditStatus = EnumAuditStatus.No;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 子公司
     */
    ChildrenCompanyId?: string;
    /**
     * 审核状态
     */
    AuditStatus?: EnumAuditStatus;
    /**
     * 审核备注
     */
    AuditRemark?: string;
}

/**
 * 关联-客户公司主体关联表单-审核-表单校验
 */
export const DtoRequestCompanyCustomerMainRelationAuditRules = {
    ChildrenCompanyId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    AuditStatus: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    AuditRemark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 关联-客户公司主体关联表单-审核-设置项
 */
export const DtoRequestCompanyCustomerMainRelationAuditMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ChildrenCompanyId",
    Label: "子公司",
    Customize: true,
  },
  {
    Prop: "AuditStatus",
    Label: "审核状态",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumAuditStatusOption,
  },
  {
    Prop: "AuditRemark",
    Label: "审核备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
