
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamFbaWarehouseCodeAppService from "@/apis.machine/ParamFbaWarehouseCodeAppService";
import DtoFormParamFbaWarehouseCode, {
  DtoFormParamFbaWarehouseCodeMiezzFormItems,
  DtoFormParamFbaWarehouseCodeRules,
} from "@/models.machine/DtoFormParamFbaWarehouseCode";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzModal from "@/models/MiezzModal";

export default class ParamFbaWarehouseCodeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  Country?: UiSelectOption<string>[] = [];
  form = new MiezzForm<DtoFormParamFbaWarehouseCode, string>(
    "System_ParamList_FBAWarehouseCode_AddEdit"
  );

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamFbaWarehouseCode();
    //表单-校验
    this.form.Rules = DtoFormParamFbaWarehouseCodeRules;
    //表单-元素配置
    this.form.Items = DtoFormParamFbaWarehouseCodeMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      ParamFbaWarehouseCodeAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
        this.getCountry();
      });
    } else {
      this.form.Data = new DtoFormParamFbaWarehouseCode();
      this.getCountry();
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamFbaWarehouseCodeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**查询国家 */
  getCountry(): void {
    ParamCountryAppService.GetSelect().then((r) => {
      this.Country = r.data.Data;
    });
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
