import DtoRequestBatchSettlementItem from "./DtoRequestBatchSettlementItem";
import DtoSettlementTime from "./DtoSettlementTime";
import DtoCheckPrice from "./DtoCheckPrice";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestBatchSettlement {
    constructor() {
        //无参构造函数
        this.Visiable = false;
    }

    [proName: string]: any;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 账单主键
     */
    BillIds?: string[];
    /**
     * 汇总
     */
    Sums?: string[];
    /**
     * 是否全部核销
     */
    IsAll?: boolean;
    /**
     * 是否同时存在正负数
     */
    IsNumber?: boolean;
    /**
     * 核销记录
     */
    Items?: DtoRequestBatchSettlementItem[];
    /**
     * 当前时间
     */
    CurrentTime?: Date;
    /**
     * 收款方抬头集合
     */
    CollectionTitles?: DtoSettlementTime[];
    /**
     * 付款方抬头集合
     */
    PaymentTitles?: DtoSettlementTime[];
    /**
     * 
     */
    Checks?: DtoCheckPrice[];
    /**
     * 是否坏账核销
     */
    IsBad?: boolean;
}

/**
 * -表单校验
 */
export const DtoRequestBatchSettlementRules = {
}
