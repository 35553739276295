import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, i) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "log-item",
      key: i
    }, [
      _createVNode(_component_el_alert, {
        title: (item.Success ? '已同步：' : '正在同步：') + item.Label,
        type: item.Success ? 'success' : 'info',
        effect: "dark",
        closable: false
      }, null, 8, ["title", "type"])
    ]))
  }), 128))
}