import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumGender, EnumGenderOption } from "./EnumGender";
import DtoListItemCompanyContactNumber from "./DtoListItemCompanyContactNumber";

/**
 * 通讯录分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyContact extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Numbers = [];
    }

    [proName: string]: any;
    /**
     * 姓名
     */
    Name?: string;
    /**
     * 性别
     */
    Gender?: EnumGender;
    /**
     * 部门
     */
    Department?: string;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 联系方式
     */
    Numbers?: DtoListItemCompanyContactNumber[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 通讯录分页列表-项-表单校验
 */
export const DtoListItemCompanyContactRules = {
}

/**
 * 通讯录分页列表-项-设置项
 */
export const DtoListItemCompanyContactMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "姓名",
    Customize: true,
  },
  {
    Prop: "Department",
    Label: "部门",
  },
  {
    Prop: "Job",
    Label: "职务",
  },
  {
    Prop: "Numbers",
    Label: "联系方式",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
]
