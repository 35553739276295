import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 系统版本详细-GIT日志
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailProjectVersion_GitLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 哈希
     */
    declare Id?: string;
    /**
     * 内容
     */
    Message?: string;
    /**
     * 作者
     */
    Author?: string;
    /**
     * 邮箱
     */
    Email?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
}

/**
 * 系统版本详细-GIT日志-表单校验
 */
export const DtoDetailProjectVersion_GitLogRules = {
}

/**
 * 系统版本详细-GIT日志-设置项
 */
export const DtoDetailProjectVersion_GitLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Message",
    Label: "内容",
  },
  {
    Prop: "Author",
    Label: "作者",
    MaxWidth: "100px",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
