import UiSelectOption from "./UiSelectOption";

/**
 * 销售状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderWarehouseStatus {
    /**
     * 待交货
     */
    None = 10,
    /**
     * 部分交货
     */
    Part = 20,
    /**
     * 全部交货
     */
    All = 30,
}

/**
 * 销售状态-选项
 */
export const EnumOrderWarehouseStatusOption: UiSelectOption<number>[] = [
    { Label: "待交货", Value: 10, Data: "None" },
    { Label: "部分交货", Value: 20, Data: "Part" },
    { Label: "全部交货", Value: 30, Data: "All" },
];
