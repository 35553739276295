import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 装货港-仓库/堆场/码头表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrderPoolPackageShape {
    constructor() {
        //无参构造函数
        this.IsDetail = false;
        this.Type = EnumOrderServiceType.DeparturePort;
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 装货港-仓库/堆场/码头的主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 是否只是查看
     */
    IsDetail?: boolean;
    /**
     * 服务类型
     */
    Type?: EnumOrderServiceType;
}

/**
 * 装货港-仓库/堆场/码头表单-表单校验
 */
export const DtoRequestOrderPoolPackageShapeRules = {
    OrderPoolId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}
