import UiSelectOption from "./UiSelectOption";

/**
 * 菜单类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumMenuType {
    /**
     * 菜单
     */
    Menu = 0,
    /**
     * 模块
     */
    Module = 1,
    /**
     * 页面
     */
    Page = 2,
    /**
     * 事件
     */
    Event = 10,
    /**
     * 权限
     */
    Power = 20,
}

/**
 * 菜单类型-选项
 */
export const EnumMenuTypeOption: UiSelectOption<number>[] = [
    { Label: "菜单", Value: 0, Data: "Menu" },
    { Label: "模块", Value: 1, Data: "Module" },
    { Label: "页面", Value: 2, Data: "Page" },
    { Label: "事件", Value: 10, Data: "Event" },
    { Label: "权限", Value: 20, Data: "Power" },
];
