import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fadf8876"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "element-loading-background": "rgba(0, 0, 0, 0)" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  style: {"margin-left":"3px"}
}
const _hoisted_6 = {
  key: 0,
  style: {"color":"#999999","font-size":"13px"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  style: {"text-align":"left","float":"left"}
}
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_Document = _resolveComponent("Document")!
  const _component_CloseBold = _resolveComponent("CloseBold")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.preview && _ctx.width && _ctx.files.length == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("a", {
            class: "filename",
            href: _ctx.files[0].Url,
            target: "_blank",
            style: {"width":"100%","height":"100%"}
          }, [
            _createVNode(_component_el_image, {
              src: _ctx.files[0].Url,
              style: _normalizeStyle(`width: ${_ctx.width ? _ctx.width : '40px'}; height: ${
            _ctx.height ? _ctx.height : '40px'
          };`),
              fit: "fill"
            }, null, 8, ["src", "style"])
          ], 8, _hoisted_3)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (!_ctx.preview)
            ? _withDirectives((_openBlock(), _createBlock(_component_el_upload, {
                key: 0,
                action: "#",
                accept: _ctx.accept,
                "show-file-list": false,
                "before-upload": _ctx.beforeUpload,
                multiple: _ctx.multiple,
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_plus)
                        ]),
                        _: 1
                      }),
                      (_ctx.isTip != false)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "添加文件"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (_ctx.maxSize && _ctx.isTip != false)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, " " + _toDisplayString(_ctx.tip) + " 文件大小不能超过" + _toDisplayString(_ctx.sizeLabel), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["accept", "before-upload", "multiple"])), [
                [_vShow, 
          (_ctx.isSingle == undefined && _ctx.multiple) || (_ctx.files && _ctx.files.length == 0)
        ]
              ])
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (item, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              (_ctx.isTip != false)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (!_ctx.isPhoto)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: "filename",
                          href: item.Url,
                          target: "_blank"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(item.FileName), 1)
                        ], 8, _hoisted_8))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          class: "filename",
                          href: item.Url,
                          target: "_blank",
                          style: {"width":"100%","height":"100%"}
                        }, [
                          _createElementVNode("span", null, [
                            _createVNode(_component_el_image, {
                              src: item.Url,
                              style: _normalizeStyle(`width: ${_ctx.width ? _ctx.width : '40px'}; height: ${
                  _ctx.height ? _ctx.height : '40px'
                };`),
                              fit: "fill"
                            }, null, 8, ["src", "style"])
                          ])
                        ], 8, _hoisted_9)),
                    (!_ctx.preview && !_ctx.isPhoto)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 2,
                          type: "text",
                          style: {"margin-left":"5px"},
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleRename(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("重命名")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    (!_ctx.preview)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 3,
                          type: "text",
                          style: {"margin-left":"5px"},
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleDelete(i))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("删除")
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("a", {
                      class: "filename",
                      href: item.Url,
                      target: "_blank",
                      style: {"font-size":"20px"}
                    }, [
                      _createVNode(_component_el_icon, { title: "预览" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Document)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_11),
                    (!_ctx.preview)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          type: "text",
                          style: {"margin-left":"5px"},
                          size: "small",
                          onClick: ($event: any) => (_ctx.handleDelete(i))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, { title: "删除" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CloseBold)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
            ]))
          }), 128)),
          _createVNode(_component_miezz_modal, {
            modelValue: _ctx.modalForm,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForm) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_form, {
                ref: "ruleForm",
                modelValue: _ctx.form,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]))
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}