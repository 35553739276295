import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderPoolCargo from "../models.machine/DtoPageListSearchOrderPoolCargo";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderPoolCargo from "../models.machine/DtoPageListOrderPoolCargo";
import DtoRequestOrderPoolCargoGetFromPoolPackage from "../models.machine/DtoRequestOrderPoolCargoGetFromPoolPackage";
import DtoShipmentIdReferenceId from "../models.machine/DtoShipmentIdReferenceId";
import DtoFormOrderPoolCargo from "../models.machine/DtoFormOrderPoolCargo";
import DtoFormOrderPoolCargo_Time from "../models.machine/DtoFormOrderPoolCargo_Time";
import DtoDeleteOrderPoolCargo from "../models.machine/DtoDeleteOrderPoolCargo";

/**
 * 委托书-货物清单
 * @description 自动生成的代码,请勿修改
 */
export default class OrderPoolCargoAppService {
    /**
     * 获取委托书-货物清单分页列表
     * @param {DtoPageListSearchOrderPoolCargo} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderPoolCargo>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderPoolCargo): AxiosPromise<RESTfulResult<DtoPageListOrderPoolCargo>> {
        const url = `/api/OrderPoolCargo/Get`;
        return service.get<RESTfulResult<DtoPageListOrderPoolCargo>>(url, { params: dto });
    }

    /**
     * 获取来自于委托书的货物清单
     * @param {DtoRequestOrderPoolCargoGetFromPoolPackage} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoShipmentIdReferenceId[]>>} 货物清单
     */
    static GetFromPoolPackage(dto?: DtoRequestOrderPoolCargoGetFromPoolPackage): AxiosPromise<RESTfulResult<DtoShipmentIdReferenceId[]>> {
        const url = `/api/OrderPoolCargo/GetFromPoolPackage`;
        return service.get<RESTfulResult<DtoShipmentIdReferenceId[]>>(url, { params: dto });
    }

    /**
     * 添加
     * @param {DtoFormOrderPoolCargo} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormOrderPoolCargo): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolCargo/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 更新入库日期
     * @param {DtoFormOrderPoolCargo_Time} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateWarehousingTime(dto?: DtoFormOrderPoolCargo_Time): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolCargo/UpdateWarehousingTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 更新出库日期
     * @param {DtoFormOrderPoolCargo_Time} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateExWarehouseTime(dto?: DtoFormOrderPoolCargo_Time): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolCargo/UpdateExWarehouseTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除委托书-货物清单
     * @param {DtoDeleteOrderPoolCargo} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(dto?: DtoDeleteOrderPoolCargo): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderPoolCargo/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

}
