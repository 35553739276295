import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_card = _resolveComponent("miezz-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
          onHandleGet: _ctx.handleGet
        }, {
          column: _withCtx(({ model, row }) => [
            (model.Prop == 'Code')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: '/#' + row.Link,
                  target: "_blank"
                }, [
                  _createVNode(_component_miezz_text_ellipsis, {
                    text: row.Code
                  }, null, 8, ["text"])
                ], 8, _hoisted_1))
              : _createCommentVNode("", true),
            (model.Prop == 'Files')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.Files, (p, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "annexLink",
                      onClick: ($event: any) => (_ctx.onLink(p))
                    }, " 【" + _toDisplayString(p.Name) + "】 ", 9, _hoisted_3))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "onHandleGet"])
      ]),
      _: 1
    })
  ], 64))
}