
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import DtoHistory from "@/models.machine/DtoHistory";
import moment from "moment";
import HistoryAppService from "@/apis.machine/HistoryAppService";
import { isArray } from "element-plus/es/utils";
import { UiHistoryState } from "@/models.machine/UiHistoryState";
import OSS from "ali-oss";
export default class MiezzCommonHistory extends Vue {
  @Model() modelValue?: DtoHistory[];
    @Prop() labelWidth?: string;
    @Prop() isShow?: boolean;  
  UiHistoryState = UiHistoryState;
  props = {
    value: "Id",
    label: "Label",
    children: "Children",
    isLeaf: (data: any, node: any) => {
      return data.Pipe != "List" && data.Pipe != "Class";
    },
  };
  client = new OSS({
    region: "oss-cn-beijing",
    accessKeyId: "LTAIuFDB1NiUNXPQ",
    accessKeySecret: "ArIn2kiTFr53bdKEVDfuWjTfA65b9C",
    bucket: "test-eqicheng",
    cname: true,
    endpoint: "testfile.eqicheng.cn",
  });

  handleDateFormat(date?: Date): string {
    return moment(date)?.format("YYYY/MM/DD HH:mm");
  }

  handleFileFormat(file: string): string {
    return file ? file.substring(file.lastIndexOf("/") + 1) : "暂无数据";
  }

  hnadleGetUrl(file: string): string {
    return file ? this.client!.signatureUrl(file) : "#";
  }

  loadNode(node: any, resolve: any): void {
    if (isArray(node.data)) {
      resolve(node.data);
    } else if (node.data.Id) {
      HistoryAppService.GetChangesById(node.data.Id).then((r) => {
        resolve(r.data.Data ?? []);
      });
    }
  }
}
