
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import PackageAppService from "@/apis.machine/PackageAppService";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import DtoPageListSearchPackage, { DtoPageListSearchPackageDynamicSearchItems } from "@/models.machine/DtoPageListSearchPackage";
    import DtoPageListPackage from "@/models.machine/DtoPageListPackage";
    import DtoListItemPackage, {
        DtoListItemPackageMiezzListItems,
    } from "@/models.machine/DtoListItemPackage";
    import ProductMenu from "../ProductMenu.vue";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoDetailProduct, {
        DtoDetailProductMiezzDetailItems,
    } from "@/models.machine/DtoDetailProduct";
    import MiezzForm from "@/models/MiezzForm";
    @Options({
        components: {
            ProductMenu
        },
    })
    export default class SpecPageList extends Vue {
        imageForm = {
            Visiable: false,
            Rows: [],
        };
        bottomButtonsImage?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        client?: OSS;
        /*产品主键*/
        /*如果是空的，表示是从包装列表中进入，查询所有产品 */
        productId = "";
        product = new MiezzForm<DtoDetailProduct, string>();


        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchPackage, DtoListItemPackage, string>();
        /**当前Id */
        id?: string;

        random = 0;
        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.productId = this.$route.params.id as string;
            this.product.Data = new DtoDetailProduct();
            //列表-传参
            this.list.Dto = new DtoPageListSearchPackage();

            //列表-数据-初始
            this.list.Data = new DtoPageListPackage();
            //列表-列配置
            var columes = DtoListItemPackageMiezzListItems;
            columes = [];
            DtoListItemPackageMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop != "IsSelected" && item.Prop != "Quantity") {
                    if (this.productId != undefined && this.productId != "") {
                        if (item.Prop != "ProductName") {
                            columes.push(item);
                        }
                    }
                    else {
                        if (item.Prop != "SpecQuantity") {
                            columes.push(item)
                        }
                    }
                }

            })
            this.list.Items = columes;

            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "add",
                    Label: "添加包装",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_NewlyAdded"
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "detail",
                    Title: "查看详细",
                    Icon: "search",
                    Size: "small",
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_Detail"
                },
            ];
            this.list.OperationWidth = "100px";
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    /*Message: "是否确认删除",*/
                    MessageBox: true,
                    PowerCode: "ProductManagement_ProductManagement_ProductInformation_ProductPackaging_Delete",
                    Size: "small",
                },
            ];
            this.list.FixTop = "40px";
        }

        onImage(row: any): void {
            if (row.PackageImages && row.PackageImages.length > 0) {
                if (row.PackageImages.length == 1) {
                    window.open(row.PackageImages[0].Url);
                }
                else {
                    this.imageForm.Rows = row.PackageImages;
                    this.imageForm.Visiable = true;
                }
            }
        }
        /**按钮点击 */
        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**查询列表 */
        handleGet(): void {
            if (this.list && this.list.Dto && this.productId) {
                this.list.Dto.ProductId = this.productId;
                PackageAppService.Get(this.list.Dto).then((r) => {
                    this.list.Data = r.data.Data;
                    this.random = Math.random();
                    if (this.list.Data && this.list.Data.Items) {
                        for (var it of this.list.Data.Items) {
                            if (it.PackageImages && it.PackageImages.length > 0) {
                                for (var img of it.PackageImages) {
                                    img.Url = this.client?.signatureUrl(img.Url as string);
                                }
                            }
                            if (it.Code == null)
                                it.Code = "暂无数据";
                        }
                    }
                });
            }
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    // this.$router.push("/product/package/add/" + this.productId + "?CompanyId=" + this.product.Data.CompanyId + "&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: `/product/package/add/` + this.productId,
                        query: { 
                            CompanyId: this.product.Data.CompanyId,
                            backUrl: this.$route.fullPath 
                        },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemPackage,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    // this.$router.push("/product/package/detail/" + this.productId + "/" + this.id + "?CompanyId=" + this.product.Data.CompanyId + "&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: "/product/package/detail/" + this.productId + "/" + this.id,
                        query: { 
                            CompanyId: this.product.Data.CompanyId,
                            backUrl: this.$route.fullPath 
                        },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":

                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个产品包装");
                        return;
                    }

                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {


                            PackageAppService.Delete(this.list.SelectIds)
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });


                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
    }
