import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import DtoListItemOrderPoolProcessCount_Take_Address from "./DtoListItemOrderPoolProcessCount_Take_Address";
import DtoListItemOrderPoolProcessCount_ExpressCode from "./DtoListItemOrderPoolProcessCount_ExpressCode";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolProcessCount_Take extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.CargoQuantity = "";
        this.TimeRed = false;
        this.Addresses = [];
        this.PlanTimes = [];
        this.Process = "查看详情";
        this.Codes = [];
        this.HasCodes = false;
    }

    [proName: string]: any;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 链接
     */
    Link?: string;
    /**
     * 类型
     */
    TypeText?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 货量
     */
    CargoQuantity?: string;
    /**
     * 合计件数
     */
    TotalQuantity?: number;
    /**
     * 合计件数-英文计价单位
     */
    TotalQuantityChargeUnitEn?: string;
    /**
     * 合计毛重
     */
    TotalGrossWeight?: number;
    /**
     * 合计体积
     */
    TotalVolume?: number;
    /**
     * 计划时间
     */
    PlanTime?: Date;
    /**
     * 计划时间
     */
    PlanTimeText?: string;
    /**
     * 执行时间
     */
    ExecTime?: Date;
    /**
     * 标记红色
     */
    TimeRed?: boolean;
    /**
     * 提货地址与时间
     */
    Addresses?: DtoListItemOrderPoolProcessCount_Take_Address[];
    /**
     * 计划时间
     */
    PlanTimes?: Date[];
    /**
     * 计划时间
     */
    PlanCarryContainerTime?: Date;
    /**
     * 抵达时间
     */
    ArrivalTime?: Date;
    /**
     * 计划时间
     */
    CarryContainerTime?: Date;
    /**
     * 提货计划时间
     */
    CarryPlanTime?: Date;
    /**
     * 实际提取时间
     */
    AllCarryTime?: Date;
    /**
     * 计划抵达时间
     */
    PlanDeliveryTime?: Date;
    /**
     * 实际送达时间
     */
    AllDeliveryTime?: Date;
    /**
     * 进程
     */
    Process?: string;
    /**
     * 快递单号
     */
    Codes?: DtoListItemOrderPoolProcessCount_ExpressCode[];
    /**
     * 有快递单号
     */
    HasCodes?: boolean;
}

/**
 * -表单校验
 */
export const DtoListItemOrderPoolProcessCount_TakeRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPoolProcessCount_TakeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "TypeText",
    Label: "类型",
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
  },
  {
    Prop: "CargoQuantity",
    Label: "货量",
  },
  {
    Prop: "PlanTimeText",
    Label: "计划时间",
  },
  {
    Prop: "ExecTime",
    Label: "执行时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]
