import axios, { AxiosResponse } from 'axios'
import qs from 'qs';
import { ElMessage } from 'element-plus'
import RESTfulResult from '../models.machine/RESTfulResult'
import CurrentLogier from './CurrentLogier';
import router from '@/router';

/**
 * 防止重复显示登录失效
 */
let out = false;

/**
 * 创建 axios 实例
 */
const Service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true
})

/**
 * req 拦截器
 */
Service.interceptors.request.use((config: any): any => {
    //请求方式为get/delete时 数组传参序列化
    if (config.method == "get" || config.method == "delete") {
        config.paramsSerializer = function (params: any) {
            const query = qs.stringify(params, {
                arrayFormat: 'repeat'
            });
            return query;
        }
    }
    //当前登录用户
    const logier = CurrentLogier.GetLogier(false);
    //鉴权
    if (logier) {
        config.headers.common["Authorization"] = `Bearer ${logier.Token}`;
        config.headers.common[
            "X-Authorization"
        ] = `Bearer ${logier.RefreshToken}`;
    }
    return config
}, (error: any): any => {
    return Promise.reject(error)
})

/**
 * res 拦截器
 */
Service.interceptors.response.use((response: AxiosResponse<RESTfulResult<any>>) => {
    const res = response.data;
    if (res.StatusCode == 200 || res.StatusCode == 204) {
        //请求成功/请求成功但无返回值时
        const accessToken = response.headers["access-token"];
        const xAccessToken = response.headers["x-access-token"];
        if (accessToken && xAccessToken) {
            const logier = CurrentLogier.GetLogier();
            //Token变更时刷新本地登录用户数据
            if (logier && logier.Token != accessToken && logier.RefreshToken != xAccessToken) {
                logier.Token = accessToken;
                logier.RefreshToken = xAccessToken;
                CurrentLogier.SetLogier(logier);
            }
        }
        if (response.data.Succeeded) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    } else {
        //请求失败时
        if (res.StatusCode == 401) {
            status401();
        } else if (res.StatusCode == 403) {
            status403();
        } else {
            alertErrors(res.Errors)
        }
        return Promise.reject(response);
    }
}, (error: Error) => {
    if (error.message == "Request failed with status code 401") {
        status401();
    } else if (error.message == "Request failed with status code 403") {
        status403();
    } else {
        ElMessage.warning("服务器异常");
    }
})

const status401 = function () {
    CurrentLogier.MenusLoading = false;
    //登录失效跳转至登录页面
    if (!out) {
        out = true;
        console.log("登录失效，取消菜单加载");
        ElMessage.warning("登录失效，请重新登陆");
        setTimeout(function () {
            CurrentLogier.Logout();
            out = false;
        }, 2000);
    }
}

const status403 = function () {
    CurrentLogier.MenusLoading = false;
    if (router.currentRoute.value.path != "/403") {
        //接口没有权限
        ElMessage.warning("您没有权限访问此接口");
    }
}

const alertErrors = function (errors: any): void {
    if (typeof errors === "string") {
        ElMessage.warning(errors);
    } else if (Array.isArray(errors)) {
        for (const error of errors) {
            alertErrors(error);
        }
    } else if (typeof errors === "object") {
        for (const key in errors) {
            const error = errors[key];
            alertErrors(error);
        }
    }
}

export default Service