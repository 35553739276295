import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 编辑电子章-传参
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCompanyBusinessCardEditElectronicSeal extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 电子章-公司中文名称
     */
    SealNameZh?: string;
    /**
     * 电子章-公司英文名称
     */
    SealNameEn?: string;
    /**
     * 电子章-法人姓名
     */
    SealLeaderName?: string;
}

/**
 * 编辑电子章-传参-表单校验
 */
export const DtoRequestCompanyBusinessCardEditElectronicSealRules = {
}

/**
 * 编辑电子章-传参-设置项
 */
export const DtoRequestCompanyBusinessCardEditElectronicSealMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "SealNameZh",
    Label: "电子章-公司中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SealNameEn",
    Label: "电子章-公司英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SealLeaderName",
    Label: "电子章-法人姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
]
