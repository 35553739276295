import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * FBA仓库代码表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamFbaWarehouseCode extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountryId?: string;
    /**
     * 城市
     */
    City?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 代码
     */
    Code?: string;
}

/**
 * FBA仓库代码表单-表单校验
 */
export const DtoFormParamFbaWarehouseCodeRules = {
    ParamCountryId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    City: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Address: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    PostalCode: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * FBA仓库代码表单-设置项
 */
export const DtoFormParamFbaWarehouseCodeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "City",
    Label: "城市",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Address",
    Label: "地址",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "代码",
    Type: EnumMiezzFormItemType.InputText,
  },
]
