
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import TransactionModeAppService from "@/apis.machine/TransactionModeAppService";
import DtoDetailTransactionMode, {
    DtoDetailTransactionModeMiezzDetailItems,
} from "@/models.machine/DtoDetailTransactionMode";
import TransactionModeForm from "./TransactionModeForm.vue";

@Options({
    components: {
        TransactionModeForm,
    },
})
export default class TransactionModeDetail extends Vue {
    @Model() modelValue!: MiezzModal;
    @Prop() id?: string;

    detail = new MiezzForm<DtoDetailTransactionMode, string>();
    modalForm = new MiezzModal();

    /**创建时 */
    created(): void {
        //详细-数据-初始化
        this.detail.Data = new DtoDetailTransactionMode();
        //详细-元素配置
        this.detail.Items = DtoDetailTransactionModeMiezzDetailItems;
        this.modelValue.HandleClick = this.handleClick;
        this.modelValue.Buttons = [
            {
                Code: "delete",
                Title: "删除",
                Icon: "delete",
                Type: "danger",
                Message: "是否确认删除",
                Size: "small",
                PowerCode: "System_ParamList_TransactionMethod_Delete",
            },
        ];
        this.handleGet();
    }

    /**查询详细 */
    handleGet(): void {
        if (this.id) {
            TransactionModeAppService.GetById(this.id).then((r) => {
                if (r.data.Data) this.detail.Data = r.data.Data;
            });
        }
    }

    /**按钮点击 */
    handleClick(btn: MiezzButton, complete: () => void): void {
        switch (btn.Code) {
            case "delete":
                TransactionModeAppService.Delete([this.id as string])
                    .then(() => {
                        this.handleDelete();
                        complete();
                    })
                    .catch(complete);
                break;
            default:
                break;
        }
    }

    /**提交表单 */
    @Emit()
    handleSubmit(): void {
        this.modalForm.Show = false;
        this.handleGet();
    }

    /**删除 */
    @Emit()
    handleDelete(): void {
        //
    }
}
