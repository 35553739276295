import UiSelectOption from "./UiSelectOption";

/**
 * 报价表阶段
 * @description 自动生成的代码,请勿修改
 */
export enum EnumPriceQuotationSheetStage {
    /**
     * 需求发现
     */
    Find = 0,
    /**
     * 需求确认
     */
    Sure = 1,
    /**
     * 方案报价
     */
    Quotation = 2,
    /**
     * 商务谈判
     */
    Talk = 3,
    /**
     * 赢单
     */
    Win = 4,
    /**
     * 失单
     */
    Loss = 5,
}

/**
 * 报价表阶段-选项
 */
export const EnumPriceQuotationSheetStageOption: UiSelectOption<number>[] = [
    { Label: "需求发现", Value: 0, Data: "Find" },
    { Label: "需求确认", Value: 1, Data: "Sure" },
    { Label: "方案报价", Value: 2, Data: "Quotation" },
    { Label: "商务谈判", Value: 3, Data: "Talk" },
    { Label: "赢单", Value: 4, Data: "Win" },
    { Label: "失单", Value: 5, Data: "Loss" },
];
