import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";

/**
 * 翻译
 * @description 自动生成的代码,请勿修改
 */
export default class TranslateAppService {
    /**
     * 驼峰命名
     * @param {string} [q] 
     * @param {string} [separator] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetHumpNaming(q?: string, separator?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Translate/GetHumpNaming`;
        const _querys: any = {};
        _querys.q = q
        _querys.separator = separator
        return service.get<RESTfulResult<string>>(url, { params: _querys });
    }

    /**
     * 翻译
     * @param {string} [q] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static TranslateAsyncByFromByTo(q?: string, from?: string, to?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Translate/Translate/${from}/${to}`;
        const _querys: any = {};
        _querys.q = q
        return service.post<RESTfulResult<string>>(url, undefined, { params: _querys });
    }

}
