import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderPoolExpress_CargoQuantities_OrderPoolCargoQuantity from "./DtoPutOrderPoolExpress_CargoQuantities_OrderPoolCargoQuantity";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolExpress_CargoQuantities extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.CargoQuantities = [];
        this.TotalQuantity = 0;
        this.TotalGrossWeight = 0;
        this.TotalVolume = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 货量
     */
    CargoQuantities?: DtoPutOrderPoolExpress_CargoQuantities_OrderPoolCargoQuantity[];
    /**
     * 合计件数
     */
    TotalQuantity?: number;
    /**
     * 合计件数-英文计价单位
     */
    TotalQuantityChargeUnitEn?: string;
    /**
     * 合计毛重
     */
    TotalGrossWeight?: number;
    /**
     * 合计体积
     */
    TotalVolume?: number;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolExpress_CargoQuantitiesRules = {
    TotalQuantity: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    TotalQuantityChargeUnitEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    TotalGrossWeight: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    TotalVolume: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolExpress_CargoQuantitiesMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CargoQuantities",
    Label: "货量",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TotalQuantity",
    Label: "合计件数",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TotalQuantityChargeUnitEn",
    Label: "合计件数-英文计价单位",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TotalGrossWeight",
    Label: "合计毛重",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "TotalVolume",
    Label: "合计体积",
    Type: EnumMiezzFormItemType.InputText,
  },
]
