
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import HsCodeAppService from "@/apis.machine/HsCodeAppService";
import DtoFormHsCode, {
  DtoFormHsCodeMiezzFormItems,
  DtoFormHsCodeRules,
} from "@/models.machine/DtoFormHsCode";

export default class HsCodeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormHsCode, string>();

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormHsCode();
    //表单-校验
    this.form.Rules = DtoFormHsCodeRules;
    //表单-元素配置
    this.form.Items = DtoFormHsCodeMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      HsCodeAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            HsCodeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
