import DtoFormCodePropertyBaseInfo from "./DtoFormCodePropertyBaseInfo";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumValidRegularType, EnumValidRegularTypeOption } from "./EnumValidRegularType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeProperty extends DtoFormCodePropertyBaseInfo {
    constructor() {
        //无参构造函数
        super()
        this.Customize = false;
        this.UiIgnore = false;
        this.Required = false;
        this.Properties = [];
        this.Properties = [];
    }

    [proName: string]: any;
    /**
     * 自定义
     */
    Customize?: boolean;
    /**
     * 忽略Ui
     */
    UiIgnore?: boolean;
    /**
     * 必填
     */
    Required?: boolean;
    /**
     * 最大长度
     */
    MaxLength?: number;
    /**
     * True时
     */
    WhenTrue?: string;
    /**
     * False时
     */
    WhenFalse?: string;
    /**
     * 正则
     */
    Regular?: EnumValidRegularType;
    /**
     * 属性
     */
    declare Properties?: DtoFormCodeProperty[];
}

/**
 * -表单校验
 */
export const DtoFormCodePropertyRules = {
}

/**
 * -设置项
 */
export const DtoFormCodePropertyMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Customize",
    Label: "自定义",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "UiIgnore",
    Label: "忽略Ui",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Required",
    Label: "必填",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "MaxLength",
    Label: "最大长度",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "WhenTrue",
    Label: "True时",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "WhenFalse",
    Label: "False时",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Regular",
    Label: "正则",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumValidRegularTypeOption,
  },
  {
    Prop: "Properties",
    Label: "属性",
    Type: EnumMiezzFormItemType.InputText,
  },
]
