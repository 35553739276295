import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 英文包装种类表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPackageEn extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 英文名称
     */
    Name?: string;
    /**
     * 英文简写
     */
    Abbr?: string;
    /**
     * 海关代码
     */
    Code?: string;
}

/**
 * 英文包装种类表单-表单校验
 */
export const DtoFormPackageEnRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Abbr: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 英文包装种类表单-设置项
 */
export const DtoFormPackageEnMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "Abbr",
    Label: "英文简写",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "海关代码",
    Type: EnumMiezzFormItemType.InputText,
  },
]
