import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoInStockDetail from "./DtoInStockDetail";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderDepartureWharfInStock extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Index = 0;
        this.Details = [];
    }

    [proName: string]: any;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 计划货量主键
     */
    OrderDepartureWharfPlanId?: string;
    /**
     * 长度/直径（cm）
     */
    Length?: number;
    /**
     * 宽度/直径（cm）
     */
    Width?: number;
    /**
     * 高度（cm）
     */
    Height?: number;
    /**
     * 体积（CBM）
     */
    Volume?: number;
    /**
     * 毛重（KGS）
     */
    GrossWeight?: number;
    /**
     * 入库数量
     */
    InStockQuantity?: number;
    /**
     * 计划数量
     */
    PlanQuantity?: number;
    /**
     * 入库日期
     */
    InStockTime?: Date;
    /**
     * 序号
     */
    Index?: number;
    /**
     * 是否有出库
     */
    IsOutStock?: boolean;
    /**
     * 组别
     */
    GroupId?: string;
    /**
     * 入库明细
     */
    Details?: DtoInStockDetail[];
    /**
     * 是否显示弹出框
     */
    Visiable?: boolean;
    /**
     * 
     */
    PlanId?: string;
    /**
     * 计费重量
     */
    ChargeWeight?: number;
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoListItemOrderDepartureWharfInStockRules = {
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoListItemOrderDepartureWharfInStockMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
  },
  {
    Prop: "OrderDepartureWharfPlanId",
    Label: "计划货量主键",
  },
  {
    Prop: "Length",
    Label: "长度/直径（cm）",
  },
  {
    Prop: "Width",
    Label: "宽度/直径（cm）",
  },
  {
    Prop: "Height",
    Label: "高度（cm）",
  },
  {
    Prop: "Volume",
    Label: "体积（CBM）",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重（KGS）",
  },
  {
    Prop: "InStockQuantity",
    Label: "入库数量",
  },
  {
    Prop: "PlanQuantity",
    Label: "计划数量",
  },
  {
    Prop: "InStockTime",
    Label: "入库日期",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Index",
    Label: "序号",
  },
  {
    Prop: "IsOutStock",
    Label: "是否有出库",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "GroupId",
    Label: "组别",
  },
  {
    Prop: "Details",
    Label: "入库明细",
  },
  {
    Prop: "Visiable",
    Label: "是否显示弹出框",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "PlanId",
    Label: "",
  },
  {
    Prop: "ChargeWeight",
    Label: "计费重量",
  },
]
