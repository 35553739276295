
    import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import OrderStowageStackAppService from "@/apis.machine/OrderStowageStackAppService";
import DtoPageListSearchOrderStowageStack, {
  DtoPageListSearchOrderStowageStackDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderStowageStack";
import DtoPageListOrderStowageStack from "@/models.machine/DtoPageListOrderStowageStack";
import DtoListItemOrderStowageStack, {
  DtoListItemOrderStowageStackMiezzListItems,
} from "@/models.machine/DtoListItemOrderStowageStack";
import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import {
  EnumOrderStowageStackStatus,
  EnumOrderStowageStackStatusOption,
} from "@/models.machine/EnumOrderStowageStackStatus";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import OrderAppService from "@/apis.machine/OrderAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemOrderStowageStackAllocate, {
  DtoListItemOrderStowageStackAllocateMiezzListItems,
} from "@/models.machine/DtoListItemOrderStowageStackAllocate";
import DtoPageListSearchOrderStowageStackAllocate, {
  DtoPageListSearchOrderStowageStackAllocateDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchOrderStowageStackAllocate";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoSelectSearchOrdeCommon from "@/models.machine/DtoSelectSearchOrdeCommon";
import DtoSelectSearchOrdePoolCommon from "@/models.machine/DtoSelectSearchOrdePoolCommon";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoRequestPrePackedBox from "@/models.machine/DtoRequestPrePackedBox";
import DtoCallbackPrePackedBox from "@/models.machine/DtoCallbackPrePackedBox";
import DtoCallbackWeight from "@/models.machine/DtoCallbackWeight";
import MiezzListItem from "@/models/MiezzListItem";
import DtoRequestOrder_CreateStowageStackOrder from "@/models.machine/DtoRequestOrder_CreateStowageStackOrder";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import Logier from "@/models.machine/Logier";
import MiezzDynamicSearchComponent from "@/components/MiezzDynamicSearch.vue";
import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import { asString } from "html2canvas/dist/types/css/types/color";
    @Options({
        components: {},
    })
    export default class OrderStowageStackPageList extends Vue {
        isLoading = false;
        cartNumber = 0;
        programmeCount = 0;
        isSum?: boolean;
        paramPrePackedBox = new DtoRequestPrePackedBox();
        prePackedBox = new DtoCallbackPrePackedBox();
        programmeFrom = new DtoCallbackPrePackedBox();
        programmeFromVisible = false;


        programmes: UiSelectOption<string>[] = [];

        //programmeNameForm = 

        programmeNameForm: {
            Visiable: boolean,
            Name: string,
        } = {
                Visiable: false,
                Name: "",
            };

        programmeBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "delete",
                Label: "删除",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "all",
                Label: "删除全部方案",
                Type: "primary",
                Size: "small",
                Hide:true,
            },
            {
                IsBlue: true,
                Code: "export",
                Label: "导出",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "search",
                Label: "搜索相关信息",
                Type: "primary",
                Size: "small",
                Hide: false,
            },
            {
                IsBlue: true,
                Code: "cancel",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        programmeNameBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "save",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "cancel",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        programmeNameRules = {
            Name: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.prePackedBox && this.prePackedBox.Tabs) {
                            var flag = false;
                            for (var it of this.prePackedBox.Tabs) {
                                if (it.Name == value) {
                                    flag = true;
                                }
                            }
                            if (flag == true) {
                                callback(new Error("方案名称不能重复"));
                                return;
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ],
        };



        removeIds?: string[];
        searchIds?: string[];
        prePackedBoxBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
                Hide:true,
            },
            {
                IsBlue: true,
                Code: "programme",
                Label: "保存为方案",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "export",
                Label: "导出",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "clear",
                Label: "清空并关闭",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "cancel",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        /*类型：0表示委托书堆场；1表示订单堆场 */
        wharfType = 0;
        /*true表示委托书或者直订订单，false表示配载订单 */
        directOrder = true;
        poolId = "";

        /**分页列表 */
        @Prop() list!: MiezzPageList<
            DtoPageListSearchOrderStowageStack,
            DtoListItemOrderStowageStack,
            string
        >;
        @Prop() direction!: EnumTransportDirection;
        @Prop() type!: EnumTransportType;
        @Prop() containerLoad?: EnumTransportContainerLoad;

        /**当前Id */
        id?: string;
        logier?: Logier;
        loading = false;
        bookingNos: UiSelectOptionWithStringValue[] = [];
        codes: UiSelectOptionWithStringValue[] = [];
        loadingPorts: UiSelectOption<string>[] = [];
        deliveryPorts: UiSelectOption<string>[] = [];
        deliveryPlaces: UiSelectOptionWithStringValue[] = [];
        clientUsers: UiSelectOption<string>[] = [];
        drawbacks: UiSelectOptionWithStringValue[] = [
            {
                Label: "是",
                Value: "true",
            },
            {
                Label: "否",
                Value: "false",
            },
        ];
        types = [
            {
                Value: 10,
                Label: "按重量计费",
            },
            {
                Value: 20,
                Label: "按体积计费",
            },
        ];
        EnumOrderStowageStackStatusOption = EnumOrderStowageStackStatusOption;
        serviceRemarks: UiSelectOptionWithStringValue[] = [];

        modalAllocate = new MiezzModal(MiezzModalType.Dialog);
        listAllocate = new MiezzPageList<
            DtoPageListSearchOrderStowageStackAllocate,
            DtoListItemOrderStowageStackAllocate,
            string
        >("OrderStowageStack_Allocate");
        allocateCodes: UiSelectOptionWithStringValue[] = [];
        allocateMasterBillNos: UiSelectOptionWithStringValue[] = [];
        allocateDeliveryPlaces: UiSelectOptionWithStringValue[] = [];
        allocateCustomerServices: UiSelectOption<string>[] = [];

        /*查看装货港-仓库/堆场/码头的权限 */
        OrderManagement_Pool_Summary_DeparturePortWarehouse = false;

        modalCreate = new MiezzModal(MiezzModalType.Dialog);
        modalCreateData = new DtoRequestOrder_CreateStowageStackOrder();
        modalCreateRules = {
            Code: [
                {
                    max: 64,
                    message: "文本长度不能超过 64 个字符",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value) {
                            OrderAppService.CheckCodeExist({
                                Id: this.id,
                                Code: value,
                            }).then((r) => {
                                if (r.data.Data) {
                                    return callback(new Error("该编号已存在，请重新填写"));
                                } else {
                                    callback();
                                }
                            });
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ],
            CustomerServiceId: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ],
        };
        customerServices: UiSelectOption<string>[] = [];

        onChangeType(): void {
            if (this.prePackedBox) {
                this.prePackedBox.CompanyChargeId = undefined;
                this.prePackedBox.VolumeRatioWeight = undefined;
                this.prePackedBox.MinimumChargeableWeight = undefined;
                this.prePackedBox.OneMinimumChargeableWeight = undefined;
                if (this.prePackedBox.Type == 10) {
                    if (this.prePackedBox.Weights && this.prePackedBox.Weights.length > 0) {
                        this.prePackedBox.CompanyChargeId =
                            this.prePackedBox.Weights[0].Value;
                        this.prePackedBox.VolumeRatioWeight =
                            this.prePackedBox.Weights[0].Data.VolumeRatio;
                        this.prePackedBox.MinimumChargeableWeight =
                            this.prePackedBox.Weights[0].Data.MinStandardPiece;
                        this.prePackedBox.OneMinimumChargeableWeight =
                            this.prePackedBox.Weights[0].Data.MinStandardTicket;
                    }
                } else if (this.prePackedBox.Type == 20) {
                    if (this.prePackedBox.Volumes && this.prePackedBox.Volumes.length > 0) {
                        this.prePackedBox.CompanyChargeId =
                            this.prePackedBox.Volumes[0].Value;
                        this.prePackedBox.VolumeRatioWeight =
                            this.prePackedBox.Volumes[0].Data.VolumeRatio;
                        this.prePackedBox.MinimumChargeableWeight =
                            this.prePackedBox.Volumes[0].Data.MinStandardPiece;
                        this.prePackedBox.OneMinimumChargeableWeight =
                            this.prePackedBox.Volumes[0].Data.MinStandardTicket;
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeight();
        }

        onChangeWeight(): void {
            if (this.prePackedBox) {
                this.prePackedBox.VolumeRatioWeight = undefined;
                this.prePackedBox.MinimumChargeableWeight = undefined;
                this.prePackedBox.OneMinimumChargeableWeight = undefined;
                if (this.prePackedBox.Weights && this.prePackedBox.Weights.length > 0) {
                    for (var it of this.prePackedBox.Weights) {
                        if (it.Value == this.prePackedBox.CompanyChargeId) {
                            this.prePackedBox.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.prePackedBox.MinimumChargeableWeight =
                                it.Data.MinStandardPiece;
                            this.prePackedBox.OneMinimumChargeableWeight =
                                it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeight();
        }

        onChangeVolume(): void {
            if (this.prePackedBox) {
                this.prePackedBox.VolumeRatioWeight = undefined;
                this.prePackedBox.MinimumChargeableWeight = undefined;
                this.prePackedBox.OneMinimumChargeableWeight = undefined;
                if (this.prePackedBox.Volumes && this.prePackedBox.Volumes.length > 0) {
                    for (var it of this.prePackedBox.Volumes) {
                        if (it.Value == this.prePackedBox.CompanyChargeId) {
                            this.prePackedBox.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.prePackedBox.MinimumChargeableWeight =
                                it.Data.MinStandardPiece;
                            this.prePackedBox.OneMinimumChargeableWeight =
                                it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeight();
        }

        /**创建时 */
        created(): void {
            this.logier = CurrentLogier.GetLogier();
            CurrentLogier.GetPowerCodes().then((r) => {
                if (
                    r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse") ==
                    true &&
                    r?.contains("OrderManagement_Pool_Summary_DeparturePortWarehouse") ==
                    true
                ) {
                    this.OrderManagement_Pool_Summary_DeparturePortWarehouse = true;
                }
            });

            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.DynamicSearchCode = "OrderStowageStack";
            this.list.Dto = new DtoPageListSearchOrderStowageStack();
            this.list.Dto.RadioSearch = "1";
            this.list.RadioSearchItems = EnumOrderStowageStackStatusOption;
            this.onRadioSearchChange();
            //列表-动态搜索-项
            this.list.DynamicSearchItems =
                DtoPageListSearchOrderStowageStackDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderStowageStack();
            //列表-列配置
            this.list.Items = DtoListItemOrderStowageStackMiezzListItems;
            //BookingNo
            //this.list.Items = DtoListItemOrderStowageStackMiezzListItems?.map((it) => {
            //    const item = Object.copy(it);
            //    if (item.Prop == "BookingNo") {
            //        item.Width = 160;
            //    }
            //    return item;
            //});
            this.list.CheckRowButtonShow = (btn, row) => {
                switch (btn.Code) {
                    case "delete":
                    case "cancel":
                        if (row.OrderId && row.Status == EnumOrderPoolStatus.Executing) {
                            return true;
                        }
                        return false;
                    default:
                        return true;
                }
            };

            this.modalAllocate.Title = "调配";
            this.modalAllocate.Width = "calc(100% - 40px)";
            this.modalAllocate.Height = "calc(100% - 142px)";
            this.modalAllocate.Top = "20px";
            this.modalAllocate.Buttons = [
                {
                    Code: "save-allocate",
                    Label: "确定",
                    Type: "primary",
                    Size: "small",
                },
            ];
            this.modalAllocate.HandleClick = this.handleBottomButtonClick;

            this.listAllocate.Fix = false;
            this.listAllocate.HideCard = true;
            this.listAllocate.EnabledRadioSearch = false;
            this.listAllocate.DisabledCreatedHandleGet = true;
            this.listAllocate.DisabledRouter = true;
            this.listAllocate.SimpleDynamicSearch = true;
            this.listAllocate.DynamicSearchItems =
                DtoPageListSearchOrderStowageStackAllocateDynamicSearchItems;
            this.listAllocate.DynamicSearchIgnoreResetProps = [
                "DynamicSearch_LoadingPortId",
                "DynamicSearch_DeliveryPortId",
            ];
            this.listAllocate.Items =
                DtoListItemOrderStowageStackAllocateMiezzListItems;

            this.modalCreate.Title = "创建订单";
            this.modalCreate.Height = "100px";
            this.modalCreate.HandleClick = this.handleBottomButtonClick;
            this.modalCreate.Buttons = [
                {
                    Code: "save-create",
                    Label: "确定",
                    Type: "primary",
                    Size: "small",
                },
            ];

            const dto = new DtoSelectSearchOrdePoolCommon();
            dto.TransportDirection = this.direction;
            dto.TransportType = this.type;
            dto.TransportContainerLoad = this.containerLoad;
            OrderPoolAppService.GetServiceRemarkSelect().then((r) => {
                this.serviceRemarks = r.data.Data ?? [];
            });
            OrderPoolAppService.GetBookingNos(dto).then((r) => {
                this.bookingNos = r.data.Data ?? [];
            });
            OrderPoolAppService.GetCodes(dto).then((r) => {
                this.codes = r.data.Data ?? [];
            });
            OrderPoolAppService.GetClientUsers(dto).then((r) => {
                this.clientUsers = r.data.Data ?? [];
            });
            OrderPoolAppService.GetLoadingPorts(dto).then((r) => {
                this.loadingPorts = r.data.Data ?? [];
            });
            OrderPoolAppService.GetDeliveryPorts(dto).then((r) => {
                this.deliveryPorts = r.data.Data ?? [];
            });
            OrderPoolAppService.GetDeliveryPlaces(dto).then((r) => {
                this.deliveryPlaces = r.data.Data ?? [];
            });
            JoinCompanyUserAppService.GetSelect({
                CompanyId: this.logier?.CompanyId,
                PowerCodes: ["IdentityPermission_CustomerService"],
            }).then((r) => {
                this.customerServices = r.data.Data ?? [];
            });
        }

        onPlan(row: any): void {
            //const WharfPlan = this.$refs["WharfPlan"] as any;
            //WharfPlan.onInit(undefined, EnumOrderServiceType.DeparturePort, this.directOrder, row.PoolId, undefined);

            let routeUrl = this.$router.resolve({
                path: "/pool/departure/wharf/" + row.PoolId,
                query: { backUrl: this.$route.fullPath },
            });
            window.open(routeUrl.href, "_blank");
        }
        onInStock(row: any): void {
            //this.poolId = row.PoolId;

            //this.$nextTick(() => {
            //    const WharfInStock = this.$refs["WharfInStock"] as any;
            //    WharfInStock.onInit(undefined, EnumOrderServiceType.DeparturePort);
            //});

            let routeUrl = this.$router.resolve({
                path: "/pool/departure/wharf/" + row.PoolId,
                query: { backUrl: this.$route.fullPath },
            });
            window.open(routeUrl.href, "_blank");
        }

        onWharf(id: string) {
            //this.$router.push({
            //  path: `/pool/departure/wharf/${id}`,
            //  query: { backUrl: this.$route.fullPath },
            //});

            let routeUrl = this.$router.resolve({
                path: `/pool/departure/wharf/${id}`,
                query: { backUrl: this.$route.fullPath },
            });
            window.open(routeUrl.href, "_blank");
        }

        handleAllocateDynamicSearch(value?: string): void {
            if (this.listAllocate.Dto) {
                this.listAllocate.Dto.DynamicSearchId = value;
                OrderAppService.GetAllocatePageList(this.listAllocate.Dto).then((r) => {
                    this.listAllocate.Data = r.data.Data;
                });
            }
        }

        @Watch("list.Dto.RadioSearch")
        onRadioSearchChange(): void {
            const items = JSON.copy(
                DtoListItemOrderStowageStackMiezzListItems
            ) as MiezzListItem[];
            let btns = JSON.copy(this.list["_BottomButtons"]) as MiezzButton[];
            if (this.list.Dto?.RadioSearch) {
                this.list.Items = items.filter((it) => it.Prop != "StowageStackStatus");

                this.list.Items?.forEach((ele) => {
                    if (ele.Prop == "WarehousingAddress") {
                        ele.DisabledTooltip = true;
                    }
                });
                const status = parseInt(
                    this.list.Dto.RadioSearch
                ) as EnumOrderStowageStackStatus;
                switch (status) {
                    case EnumOrderStowageStackStatus.Not:
                        btns = btns.filter((it) => it.Code != "cancel");
                        break;
                    case EnumOrderStowageStackStatus.Already:
                        btns = btns.filter((it) => it.Code != "create");
                        break;
                    case EnumOrderStowageStackStatus.Cancel:
                        btns = btns.filter(
                            (it) =>
                                it.Code != "create" &&
                                it.Code != "delete" &&
                                it.Code != "cancel" &&
                                it.Code != "allocate"
                        );

                        break;

                    default:
                        break;
                }
            } else {
                this.list.Items = items;
                this.list.Items?.forEach((ele) => {
                    if (ele.Prop == "WarehousingAddress") {
                        ele.DisabledTooltip = true;
                    }
                });
            }
            this.list.BottomButtons = btns;
        }

        visibilityChange(e: any, row: any): void {
            const ev = e.target;
            const evHeight = ev.offsetHeight;
            if (evHeight <= 19) {
                row.tooltipFlag = true;
            } else {
                row.tooltipFlag = false;
                const tooltip = this.$refs["tooltip" + row.Id] as any;
                tooltip.onOpen();
            }
        }

        /**查询列表 */
        handleGet(isSearch: boolean | undefined): void {
            const dto = JSON.copy(this.list.Dto) as DtoPageListSearchOrderStowageStack;
            dto.TransportDirection = this.direction;
            dto.TransportType = this.type;
            dto.TransportContainerLoad = this.containerLoad;
            dto.SelectIds = this.searchIds;
            if (isSearch == true) {
                dto.RadioSearch = undefined;
            }

            OrderStowageStackAppService.GetProgrammes(dto).then((r) => {
                this.programmes = r.data.Data ?? [];
            });


            OrderStowageStackAppService.Get(dto).then((r) => {
                this.list.Data = r.data.Data;
                if (this.list.Data && this.list.Data.OrderPrePackedBoxCount > 0) {
                    this.cartNumber = this.list.Data.OrderPrePackedBoxCount;
                } else {
                    this.cartNumber = 0;
                }

                if (this.list.Data && this.list.Data.OrderPrePackedBoxProgrammeCount > 0) {
                    this.programmeCount = this.list.Data.OrderPrePackedBoxProgrammeCount;
                } else {
                    this.programmeCount = 0;
                }
                this.searchIds = [];
                this.loading = false;
            });
        }

        handleGetAllocate(): void {
            OrderAppService.GetAllocatePageList(this.listAllocate.Dto).then((r) => {
                this.listAllocate.Data = r.data.Data;
                this.modalAllocate.Show = true;
            });
        }

        beforeRadioSearch?: string;
        handleRadioSearch(): void {
            const pageList = this.$refs["ref-miezz-page-list"] as any;
            if (
                this.list.Dto?.RadioSearch == undefined &&
                this.list.Dto?.OpenSearch == "true" &&
                this.beforeRadioSearch != undefined
            ) {
                DynamicSearchAppService.Post({
                    Code: "OrderStowageStack_Allocate",
                    Brackets: [
                        {
                            Relation: EnumRelation.And,
                            Items: [
                                {
                                    Relation: EnumRelation.And,
                                    Where: {
                                        Prop: "DynamicSearch_Status",
                                        Compare: EnumCompare.Equal,
                                        Value: this.beforeRadioSearch.toString(),
                                    },
                                },
                            ],
                        },
                    ],
                }).then((r) => {
                    if (this.list.Dto) {
                        this.list.Dto.DynamicSearchId = r.data.Data;
                        pageList.handleGet(undefined);
                    }
                });
            } else {
                pageList.handleGet(undefined);
            }
            this.beforeRadioSearch = this.list.Dto?.RadioSearch;
        }

        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemOrderStowageStack,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                case "delete":
                    this.loading = true;
                    OrderStowageStackAppService.Delete({
                        Ids: [row.Id as string],
                        Sure: false,
                    }).then((r) => {
                        if (r.data.Data && r.data.Data.length > 0) {
                            const codes = r.data.Data.join("/");
                            this.loading = false;
                            ElMessageBox.confirm(
                                `订单${codes}中关联的委托书全部取消会造成订单被取消，是否确认继续？`,
                                "取消出运",
                                {
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消",
                                    type: "warning",
                                }
                            )
                                .then(() => {
                                    this.loading = true;
                                    OrderStowageStackAppService.Delete({
                                        Ids: [row.Id as string],
                                        Sure: true,
                                    })
                                        .then(() => {
                                            this.handleGet(undefined);
                                            ElMessage.success("取消成功");
                                            complete();
                                        })
                                        .catch(complete);
                                })
                                .catch(complete);
                        } else {
                            this.handleGet(undefined);
                            ElMessage.success("取消成功");
                            complete();
                        }
                    });
                    break;
                case "cancel":
                    this.loading = true;
                    OrderStowageStackAppService.Cancel({
                        Ids: [row.Id as string],
                        Sure: false,
                    }).then((r) => {
                        if (r.data.Data && r.data.Data.length > 0) {
                            const codes = r.data.Data.join("/");
                            this.loading = false;
                            ElMessageBox.confirm(
                                `订单${codes}中关联的委托书全部取消会造成订单被取消，是否确认继续？`,
                                "取消配载",
                                {
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消",
                                    type: "warning",
                                }
                            )
                                .then(() => {
                                    this.loading = true;
                                    OrderStowageStackAppService.Cancel({
                                        Ids: [row.Id as string],
                                        Sure: true,
                                    })
                                        .then(() => {
                                            this.handleGet(undefined);
                                            ElMessage.success("取消成功");
                                            complete();
                                        })
                                        .catch(complete);
                                })
                                .catch(complete);
                        } else {
                            this.handleGet(undefined);
                            ElMessage.success("取消成功");
                            complete();
                        }
                    });
                    break;
                default:
                    break;
            }
        }

        onChangeMinimumChargeableWeight(): void {
            if (this.prePackedBox != undefined) {
                var result = setDecimal(
                    this.prePackedBox.MinimumChargeableWeight + "",
                    2,
                    false
                );
                if (result != "") {
                    this.prePackedBox.MinimumChargeableWeight = Number(result);
                } else {
                    this.prePackedBox.MinimumChargeableWeight = undefined;
                }
            }

            this.onSum();
        }

        onChangeOneMinimumChargeableWeight(): void {
            if (this.prePackedBox != undefined) {
                var result = setDecimal(
                    this.prePackedBox.OneMinimumChargeableWeight + "",
                    2,
                    false
                );
                if (result != "") {
                    this.prePackedBox.OneMinimumChargeableWeight = Number(result);
                } else {
                    this.prePackedBox.OneMinimumChargeableWeight = undefined;
                }
            }

            this.onSum();
        }

        onChangeVolumeRatioWeight(): void {
            if (this.prePackedBox != undefined) {
                var result = setDecimal(
                    this.prePackedBox.VolumeRatioWeight + "",
                    2,
                    false
                );
                if (result != "") {
                    this.prePackedBox.VolumeRatioWeight = Number(result);
                } else {
                    this.prePackedBox.VolumeRatioWeight = undefined;
                }
            }

            this.onSum();
        }
        handleClickPrePackedBox(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.paramPrePackedBox = new DtoRequestPrePackedBox();
                    this.paramPrePackedBox.TransportDirection = this.direction;
                    this.paramPrePackedBox.TransportType = this.type;
                    this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                    this.paramPrePackedBox.MinimumChargeableWeight =
                        this.prePackedBox.MinimumChargeableWeight;
                    this.paramPrePackedBox.OneMinimumChargeableWeight =
                        this.prePackedBox.OneMinimumChargeableWeight;
                    this.paramPrePackedBox.VolumeRatioWeight =
                        this.prePackedBox.VolumeRatioWeight;
                    this.paramPrePackedBox.Type = this.prePackedBox.Type;
                    this.paramPrePackedBox.CompanyChargeId =
                        this.prePackedBox.CompanyChargeId;
                    this.paramPrePackedBox.SelectIds = [];
                    if (this.prePackedBox && this.prePackedBox.Pools) {
                        for (var it of this.prePackedBox.Pools) {
                            this.paramPrePackedBox.SelectIds.push(it.PoolId as string);
                        }
                    }
                    OrderStowageStackAppService.EditPrePackedBox(
                        this.paramPrePackedBox
                    ).then((r) => {
                        this.handleGet(undefined);
                        ElMessage.success("保存成功");
                        this.prePackedBox.Visible = false;
                        complete();
                    });
                    break;
                case "programme":
                    this.programmeNameForm.Name = "方案" + this.prePackedBox.PrePackedBoxCount;
                    this.programmeNameForm.Visiable = true;
                    complete();
                    break;
                case "export":
                    this.paramPrePackedBox = new DtoRequestPrePackedBox();
                    this.paramPrePackedBox.Type = this.prePackedBox.Type;
                    this.paramPrePackedBox.TransportDirection = this.direction;
                    this.paramPrePackedBox.TransportType = this.type;
                    this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                    this.paramPrePackedBox.MinimumChargeableWeight = this.prePackedBox.MinimumChargeableWeight;
                    this.paramPrePackedBox.VolumeRatioWeight = this.prePackedBox.VolumeRatioWeight;
                    this.paramPrePackedBox.OneMinimumChargeableWeight = this.prePackedBox.OneMinimumChargeableWeight;
                    this.paramPrePackedBox.SelectIds = [];
                    this.paramPrePackedBox.Mode = 10;
                    if (this.prePackedBox && this.prePackedBox.Pools) {
                        for (var it1 of this.prePackedBox.Pools) {
                            this.paramPrePackedBox.SelectIds.push(it1.PoolId as string);
                        }
                    }
                    OrderStowageStackAppService.EditPrePackedBox(
                        this.paramPrePackedBox
                    ).then((r) => {
                        this.paramPrePackedBox = new DtoRequestPrePackedBox();
                        this.paramPrePackedBox.Ids = this.list.SelectIds;
                        this.paramPrePackedBox.TransportDirection = this.direction;
                        this.paramPrePackedBox.TransportType = this.type;
                        this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                        this.paramPrePackedBox.MinimumChargeableWeight = this.prePackedBox.MinimumChargeableWeight;
                        this.paramPrePackedBox.VolumeRatioWeight = this.prePackedBox.VolumeRatioWeight;
                        this.paramPrePackedBox.OneMinimumChargeableWeight = this.prePackedBox.OneMinimumChargeableWeight;
                        this.paramPrePackedBox.Mode = 10;
                        OrderStowageStackAppService.Export(this.paramPrePackedBox).then(
                            (r) => {
                                window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                                complete();
                            }
                        );

                        complete();
                    });
                    break;
                case "clear":
                    this.paramPrePackedBox = new DtoRequestPrePackedBox();
                    this.paramPrePackedBox.TransportDirection = this.direction;
                    this.paramPrePackedBox.TransportType = this.type;
                    this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                    OrderStowageStackAppService.ClearPrePackedBox(
                        this.paramPrePackedBox
                    ).then((r) => {
                        this.handleGet(undefined);
                        ElMessage.success("清空成功");
                        this.prePackedBox.Visible = false;
                        complete();
                    });
                    break;

                case "cancel":
                    this.prePackedBox.Visible = false;
                    complete();
                    break;

                default:
                    complete();
                    break;
            }
        }

        handleClickTabs(tab: any, event: any): void {
            this.onProgramme(tab.paneName);
        }
        handleClickProgramme(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "delete":
                    ElMessageBox.confirm("是否确定删除方案？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.removeIds = [];
                            this.removeIds.push(this.programmeFrom.OrderPrePackedBoxId as string);
                            OrderStowageStackAppService.RemoveProgramme(
                                this.removeIds
                            ).then((r) => {
                                this.handleGet(undefined);
                                ElMessage.success("删除成功");
                                this.onProgramme(undefined);
                                complete();
                            });
                        })
                        .catch(() => {
                            complete();
                        });
      
                    break;
                case "all":
                    ElMessageBox.confirm("是否确定删除所有方案？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.removeIds = [];
                            if (this.programmeFrom && this.programmeFrom.Tabs) {
                                for (var tab of this.programmeFrom.Tabs) {
                                    this.removeIds.push(tab.Id as string);
                                }
                            }
                            OrderStowageStackAppService.RemoveProgramme(
                                this.removeIds
                            ).then((r) => {
                                this.handleGet(undefined);
                                ElMessage.success("删除成功");
                                this.programmeFromVisible = false;
                                this.handleGet(undefined);
                                complete();
                            });
                        })
                        .catch(() => {
                            complete();
                        });

                    complete();
                    break;
                case "search":
                    DynamicSearchAppService.Post({
                        Code: "FinancialManagement_BillSummary",
                        Brackets: [
                            {
                                Relation: EnumRelation.And,
                                Items: [
                                    {
                                        Relation: EnumRelation.And,
                                        Where: {
                                            Prop: "DynamicSearch_ProgrammeId",
                                            Compare: EnumCompare.Equal,
                                            Value: this.programmeFrom.OrderPrePackedBoxId,
                                        },
                                    },
                                ],
                            },
                        ],
                    }).then((r) => {
                        var url = `#${this.$route.path}?DynamicSearchId=${r.data.Data}&OpenSearch=true&RadioSearch=`;
                        //this.$router.push({
                        //    path: `${this.$route.path}?DynamicSearchId=${r.data.Data}&OpenSearch=true&RadioSearch=`,
                        //    query: {
                        //    },
                        //});
                        window.location.href = url;
                        window.location.reload();


                        //window.open(
                        //    `#${this.$route.path}?DynamicSearchId=${r.data.Data}&OpenSearch=true&RadioSearch=`,
                        //    "_blank"
                        //);
                    });
                    complete();
                    break;
                case "export":
                    this.paramPrePackedBox = new DtoRequestPrePackedBox();
                    this.paramPrePackedBox.Type = this.programmeFrom.Type;
                    this.paramPrePackedBox.TransportDirection = this.direction;
                    this.paramPrePackedBox.TransportType = this.type;
                    this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                    this.paramPrePackedBox.MinimumChargeableWeight = this.programmeFrom.MinimumChargeableWeight;
                    this.paramPrePackedBox.VolumeRatioWeight = this.programmeFrom.VolumeRatioWeight;
                    this.paramPrePackedBox.OneMinimumChargeableWeight = this.programmeFrom.OneMinimumChargeableWeight;
                    this.paramPrePackedBox.SelectIds = [];
                    this.paramPrePackedBox.Mode = 20;
                    if (this.programmeFrom && this.programmeFrom.Pools) {
                        for (var it1 of this.programmeFrom.Pools) {
                            this.paramPrePackedBox.SelectIds.push(it1.PoolId as string);
                        }
                    }
                    OrderStowageStackAppService.EditPrePackedBox(
                        this.paramPrePackedBox
                    ).then((r) => {
                        this.paramPrePackedBox = new DtoRequestPrePackedBox();
                        this.paramPrePackedBox.Ids = this.list.SelectIds;
                        this.paramPrePackedBox.TransportDirection = this.direction;
                        this.paramPrePackedBox.TransportType = this.type;
                        this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                        this.paramPrePackedBox.MinimumChargeableWeight = this.programmeFrom.MinimumChargeableWeight;
                        this.paramPrePackedBox.VolumeRatioWeight = this.programmeFrom.VolumeRatioWeight;
                        this.paramPrePackedBox.OneMinimumChargeableWeight = this.programmeFrom.OneMinimumChargeableWeight;
                        this.paramPrePackedBox.Mode = 20;
                        OrderStowageStackAppService.Export(this.paramPrePackedBox).then(
                            (r) => {
                                window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                                complete();
                            }
                        );

                        complete();
                    });
                    break;


                case "cancel":
                    this.programmeFromVisible = false;
                    complete();
                    break;

                default:
                    complete();
                    break;
            }
        }

        handleClickProgrammeName(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "save":

                    MiezzForm.customSubmit(this.$refs, "$programmeNameForm", (v) => {
                        if (v == true) {

                            this.paramPrePackedBox = new DtoRequestPrePackedBox();
                            this.paramPrePackedBox.Name = this.programmeNameForm.Name;
                            this.paramPrePackedBox.TransportDirection = this.direction;
                            this.paramPrePackedBox.TransportType = this.type;
                            this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                            this.paramPrePackedBox.MinimumChargeableWeight = this.prePackedBox.MinimumChargeableWeight;
                            this.paramPrePackedBox.OneMinimumChargeableWeight = this.prePackedBox.OneMinimumChargeableWeight;
                            this.paramPrePackedBox.VolumeRatioWeight = this.prePackedBox.VolumeRatioWeight;
                            this.paramPrePackedBox.Type = this.prePackedBox.Type;
                            this.paramPrePackedBox.CompanyChargeId = this.prePackedBox.CompanyChargeId;
                            this.paramPrePackedBox.SelectIds = [];
                            this.paramPrePackedBox.Mode = 10;
                            if (this.prePackedBox && this.prePackedBox.Pools) {
                                for (var it11 of this.prePackedBox.Pools) {
                                    this.paramPrePackedBox.SelectIds.push(it11.PoolId as string);
                                }
                            }
                            OrderStowageStackAppService.SetProgramme(
                                this.paramPrePackedBox
                            ).then((r) => {
                                this.handleGet(undefined);
                                ElMessage.success("已保存为方案，请在方案中查看");
                                this.programmeNameForm.Visiable = false;
                                this.prePackedBox.Visible = false;
                                complete();
                            });
                            complete();
                        }
                        else {
                            complete();
                        }
                    });

             
                    complete();
                    break;
                case "cancel":
                    this.programmeNameForm.Visiable = false;
                    complete();
                    break;

                default:
                    complete();
                    break;
            }
        }

        onDeleteProgramme(index: number) {
            if (this.programmeFrom && this.programmeFrom.Pools) {
                this.paramPrePackedBox = new DtoRequestPrePackedBox();
                this.paramPrePackedBox.SelectIds = [];
                this.paramPrePackedBox.DeleteIds = [];
                for (var it of this.programmeFrom.Pools) {
                    if (it.PoolId != this.programmeFrom.Pools[index].PoolId) {
                        this.paramPrePackedBox.SelectIds.push(it.PoolId as string);
                    }
                    else {
                        this.paramPrePackedBox.DeleteIds.push(it.PoolId as string);
                    }
                }
                this.paramPrePackedBox.TransportDirection = this.direction;
                this.paramPrePackedBox.TransportType = this.type;
                this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                this.paramPrePackedBox.Mode = 20;
                this.paramPrePackedBox.OrderPrePackedBoxId = this.programmeFrom.OrderPrePackedBoxId;
                OrderStowageStackAppService.GetPrePackedBox(this.paramPrePackedBox).then(
                    (r) => {
                        if (r.data.Data) {
                            this.programmeFrom = r.data.Data;
                            this.onChangeTypeProgramme();
                            if (this.programmeFrom.Pools == null || this.programmeFrom.Pools == undefined || this.programmeFrom.Pools.length <= 0) {
                                if (this.programmeFrom.Tabs && this.programmeFrom.Tabs.length > 0) {
                                    this.programmeFrom.Tabs[0].IsSelected = true;
                                    this.onProgramme(this.programmeFrom.Tabs[0].Id);
                                }
                                else {
                                    this.programmeFromVisible = false;
                                }
                            }
                            this.handleGet(undefined);
                        }
                    }
                );
            }
        }


        onDelete(index: number) {
            if (this.prePackedBox && this.prePackedBox.Pools) {
                this.paramPrePackedBox = new DtoRequestPrePackedBox();
                this.paramPrePackedBox.SelectIds = [];
                this.paramPrePackedBox.DeleteIds = [];
                for (var it of this.prePackedBox.Pools) {
                    if (it.PoolId != this.prePackedBox.Pools[index].PoolId) {
                        this.paramPrePackedBox.SelectIds.push(it.PoolId as string);
                    }
                    else {
                        this.paramPrePackedBox.DeleteIds.push(it.PoolId as string);
                    }
                }
                this.paramPrePackedBox.TransportDirection = this.direction;
                this.paramPrePackedBox.TransportType = this.type;
                this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                this.paramPrePackedBox.Mode = 10;
                OrderStowageStackAppService.GetPrePackedBox(this.paramPrePackedBox).then(
                    (r) => {
                        if (r.data.Data) {
                            this.prePackedBox = r.data.Data;
                            this.prePackedBox.Visible = true;
                            //this.onSum();
                            this.onChangeType();
                            this.handleGet(undefined);
                        }
                    }
                );
            }
        }
        onCart(): void {
            if (this.cartNumber <= 0) {
                ElMessage.warning("请先添加数据到拼单预览");
                return;
            }
            this.paramPrePackedBox = new DtoRequestPrePackedBox();
            this.paramPrePackedBox.TransportDirection = this.direction;
            this.paramPrePackedBox.TransportType = this.type;
            this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
            this.paramPrePackedBox.DeleteIds = [];
            this.paramPrePackedBox.SelectIds = [];
            this.paramPrePackedBox.Mode = 10,
                OrderStowageStackAppService.GetPrePackedBox(this.paramPrePackedBox).then(
                    (r) => {
                        if (r.data.Data) {
                            this.prePackedBox = r.data.Data;
                            this.onChangeType();
                            this.prePackedBox.Visible = true;
                        }
                    }
                );
        }

        onChangeVolumeRatioWeightProgramme(): void {
            if (this.programmeFrom != undefined) {
                var result = setDecimal(
                    this.programmeFrom.VolumeRatioWeight + "",
                    2,
                    false
                );
                if (result != "") {
                    this.programmeFrom.VolumeRatioWeight = Number(result);
                } else {
                    this.programmeFrom.VolumeRatioWeight = undefined;
                }
            }

            this.onSumProgramme();
        }

        onChangeWeightProgramme(): void {
            if (this.programmeFrom) {
                this.programmeFrom.VolumeRatioWeight = undefined;
                this.programmeFrom.MinimumChargeableWeight = undefined;
                this.programmeFrom.OneMinimumChargeableWeight = undefined;
                if (this.programmeFrom.Weights && this.programmeFrom.Weights.length > 0) {
                    for (var it of this.programmeFrom.Weights) {
                        if (it.Value == this.programmeFrom.CompanyChargeId) {
                            this.programmeFrom.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.programmeFrom.MinimumChargeableWeight =
                                it.Data.MinStandardPiece;
                            this.programmeFrom.OneMinimumChargeableWeight =
                                it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeightProgramme();
        }

        onChangeVolumeProgramme(): void {
            if (this.programmeFrom) {
                this.programmeFrom.VolumeRatioWeight = undefined;
                this.programmeFrom.MinimumChargeableWeight = undefined;
                this.programmeFrom.OneMinimumChargeableWeight = undefined;
                if (this.programmeFrom.Volumes && this.programmeFrom.Volumes.length > 0) {
                    for (var it of this.programmeFrom.Volumes) {
                        if (it.Value == this.programmeFrom.CompanyChargeId) {
                            this.programmeFrom.VolumeRatioWeight = it.Data.VolumeRatio;
                            this.programmeFrom.MinimumChargeableWeight =
                                it.Data.MinStandardPiece;
                            this.programmeFrom.OneMinimumChargeableWeight =
                                it.Data.MinStandardTicket;
                        }
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeightProgramme();
        }

        onSumProgramme(): void {
            //单个箱规的计费重量计算：如箱规存在毛重与体积数值，则箱规的体积÷材积比与毛重比较取大值，然后×箱规的数量×包装数量；
            //例如某箱规的体积为0.1cbm，毛重30kgs，材积比1m³/200kgs，在包装内的数量为1，该包装的数量为10，
            //则计算出来的该箱规的计费重量为0.1÷（1/200）=20kgs，小于30kgs，所以用30×1×10=300kgs，即是该包装的计费重量；如该箱规的毛重为15kgs，则计费重量为20×1×10=200kgs；
            var param = new DtoCallbackWeight();
            param.PoolId = [];
            if (
                this.programmeFrom &&
                this.programmeFrom.Pools &&
                this.programmeFrom.VolumeRatioWeight
            ) {
                if (
                    this.programmeFrom.Pools != null &&
                    this.programmeFrom.Pools != undefined &&
                    this.programmeFrom.Pools.length
                ) {
                    for (var pool of this.programmeFrom.Pools) {
                        if (
                            pool.PoolId != undefined &&
                            pool.PoolId != "" &&
                            pool.PoolId != "00000000-0000-0000-0000-000000000000"
                        ) {
                            param.PoolId.push(pool.PoolId);
                        }
                    }
                }
                param.VolumeRatio = this.programmeFrom.VolumeRatioWeight;
                param.MiniStandard = this.programmeFrom.OneMinimumChargeableWeight;
                param.OneMiniStandard = this.programmeFrom.MinimumChargeableWeight;
                param.Type = this.programmeFrom.Type;
                OrderStowageStackAppService.GetWeight(param).then((r) => {
                    if (this.programmeFrom.Pools && r.data.Data && r.data.Data.InStocks) {
                        for (var po of this.programmeFrom.Pools) {
                            for (var instock of r.data.Data.InStocks) {
                                if (po.PoolId == instock.PoolId) {
                                    if (instock.Weight) {
                                        po.WarehousingCargoQuantitiesWeight = instock.Weight;
                                        po.IsSumInStock = instock.IsSumInStock;
                                    }
                                }
                            }
                        }
                    }

                    if (this.programmeFrom.Pools && r.data.Data && r.data.Data.Plans) {
                        for (var po1 of this.programmeFrom.Pools) {
                            for (var plan of r.data.Data.Plans) {
                                if (po1.PoolId == plan.PoolId) {
                                    if (plan.Weight) {
                                        po1.PlanCargoQuantitiesWeight = plan.Weight;
                                        po1.IsSumPlan = plan.IsSumInStock;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }

        onChangeOneMinimumChargeableWeightProgramme(): void {
            if (this.programmeFrom != undefined) {
                var result = setDecimal(
                    this.programmeFrom.OneMinimumChargeableWeight + "",
                    2,
                    false
                );
                if (result != "") {
                    this.programmeFrom.OneMinimumChargeableWeight = Number(result);
                } else {
                    this.programmeFrom.OneMinimumChargeableWeight = undefined;
                }
            }

            this.onSumProgramme();
        }

        onChangeTypeProgramme(): void {
            if (this.programmeFrom) {
                this.programmeFrom.CompanyChargeId = undefined;
                this.programmeFrom.VolumeRatioWeight = undefined;
                this.programmeFrom.MinimumChargeableWeight = undefined;
                this.programmeFrom.OneMinimumChargeableWeight = undefined;
                if (this.programmeFrom.Type == 10) {
                    if (this.programmeFrom.Weights && this.programmeFrom.Weights.length > 0) {
                        this.programmeFrom.CompanyChargeId = this.programmeFrom.Weights[0].Value;
                        this.programmeFrom.VolumeRatioWeight = this.programmeFrom.Weights[0].Data.VolumeRatio;
                        this.programmeFrom.MinimumChargeableWeight = this.programmeFrom.Weights[0].Data.MinStandardPiece;
                        this.programmeFrom.OneMinimumChargeableWeight = this.programmeFrom.Weights[0].Data.MinStandardTicket;
                    }
                }
                else if (this.programmeFrom.Type == 20) {
                    if (this.programmeFrom.Volumes && this.programmeFrom.Volumes.length > 0) {
                        this.programmeFrom.CompanyChargeId = this.programmeFrom.Volumes[0].Value;
                        this.programmeFrom.VolumeRatioWeight = this.programmeFrom.Volumes[0].Data.VolumeRatio;
                        this.programmeFrom.MinimumChargeableWeight = this.programmeFrom.Volumes[0].Data.MinStandardPiece;
                        this.programmeFrom.OneMinimumChargeableWeight = this.programmeFrom.Volumes[0].Data.MinStandardTicket;
                    }
                }
            }
            this.onChangeOneMinimumChargeableWeightProgramme();
        }

        onProgramme(id: string | undefined): void {
            if (this.programmeCount <= 0) {
                ElMessage.warning("请先添加方案");
                return;
            }
            this.programmeFrom = new DtoRequestPrePackedBox();
            this.programmeFrom.TransportDirection = this.direction;
            this.programmeFrom.TransportType = this.type;
            this.programmeFrom.TransportContainerLoad = this.containerLoad;
            this.programmeFrom.DeleteIds = [];
            this.programmeFrom.SelectIds = [];
            this.programmeFrom.Mode = 20;
            this.programmeFrom.OrderPrePackedBoxId = id;
            this.isLoading = true;
            OrderStowageStackAppService.GetPrePackedBox(this.programmeFrom).then(
                (r) => {
                    this.isLoading = false;
                    if (r.data.Data) {
                        this.programmeFrom = r.data.Data;

                        if (this.programmeFrom && (this.programmeFrom.Tabs == null || this.programmeFrom.Tabs == undefined || this.programmeFrom.Tabs.length <= 0)) {
                            this.programmeFromVisible = false;
                            this.handleGet(undefined);
                        }
                        else {
                            this.onChangeTypeProgramme();
                            if (this.programmeFromVisible != true) {
                                this.programmeFromVisible = true;
                            }
                        }
                        if (this.programmeBottomButtons && this.programmeFrom) {
                            this.programmeBottomButtons[1].Hide = true;
                            if (this.programmeFrom.Tabs && this.programmeFrom.Tabs.length > 1) {
                                this.programmeBottomButtons[1].Hide = false;
                            }
                        }
                    }
                }
            );
        }

        filterAllocateCodes: UiSelectOptionWithStringValue[] = [];
        getAllocateCodes(queryString?: string): void {
            if (this.allocateCodes && this.allocateCodes.length > 0) {
                if (queryString) {
                    this.filterAllocateCodes = this.allocateCodes.filter((it) =>
                        it.Label?.contains(queryString)
                    );
                    if (this.filterAllocateCodes && this.filterAllocateCodes.length > 10)
                        this.filterAllocateCodes = this.filterAllocateCodes.slice(0, 10);
                } else {
                    this.filterAllocateCodes = JSON.parse(
                        JSON.stringify(this.allocateCodes)
                    ).slice(0, 10);
                }
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "prePackedBox":
                    this.removeIds = [];
                    if (this.list.SelectRows.length > 0) {
                        //for (var row of this.list.SelectRows) {
                        //    if (row.OrderPrePackedBoxId != null && row.OrderPrePackedBoxId != undefined) {
                        //        ElMessage.warning("Booking No." + row.BookingNo + "/" + row.ClientCompanyNameZh + "已在预装箱清单中");
                        //        complete();
                        //        return;
                        //    }
                        //}
                        this.paramPrePackedBox = new DtoRequestPrePackedBox();
                        this.paramPrePackedBox.Ids = this.list.SelectIds;
                        this.paramPrePackedBox.TransportDirection = this.direction;
                        this.paramPrePackedBox.TransportType = this.type;
                        this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                        OrderStowageStackAppService.AddPrePackedBox(this.paramPrePackedBox)
                            .then((r) => {
                                this.handleGet(undefined);
                                OrderStowageStackAppService.GetPrePackedBox(
                                    this.paramPrePackedBox
                                )
                                    .then((r) => {
                                        if (r.data.Data) this.prePackedBox = r.data.Data;
                                        //this.onSum();
                                        this.onChangeType();
                                        complete();
                                    })
                                    .catch(complete);
                            })
                            .catch(complete);
                    } else {
                        this.paramPrePackedBox = new DtoRequestPrePackedBox();
                        this.paramPrePackedBox.TransportDirection = this.direction;
                        this.paramPrePackedBox.TransportType = this.type;
                        this.paramPrePackedBox.TransportContainerLoad = this.containerLoad;
                        OrderStowageStackAppService.GetPrePackedBox(this.paramPrePackedBox)
                            .then((r) => {
                                if (r.data.Data) {
                                    this.prePackedBox = r.data.Data;
                                    //this.onSum();
                                    this.onChangeType();
                                }
                                complete();
                            })
                            .catch(complete);
                    }
                    break;
                case "save-allocate":
                    if (this.listAllocate.SelectRows.length == 0) {
                        ElMessage.warning("请选择一条信息");
                        complete();
                    } else if (this.listAllocate.SelectRows.length > 1) {
                        ElMessage.warning("只能选择一条信息");
                        complete();
                    } else {
                        this.loading = true;
                        OrderStowageStackAppService.Allocate({
                            PoolIds: this.list.SelectIds,
                            OrderId: this.listAllocate.SelectRows[0].Id,
                            Sure: false,
                        }).then((r) => {
                            if (r.data.Data && r.data.Data.length > 0) {
                                const codes = r.data.Data.join("/");
                                this.loading = false;
                                ElMessageBox.confirm(
                                    `订单${codes}中关联的委托书全部取消会造成订单被取消，是否确认继续？`,
                                    "调配",
                                    {
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                        type: "warning",
                                    }
                                )
                                    .then(() => {
                                        this.loading = true;
                                        OrderStowageStackAppService.Allocate({
                                            PoolIds: this.list.SelectIds,
                                            OrderId: this.listAllocate.SelectRows[0].Id,
                                            Sure: true,
                                        })
                                            .then(() => {
                                                this.handleGet(undefined);
                                                this.modalAllocate.Show = false;
                                                ElMessage.success("调配成功");
                                                complete();
                                            })
                                            .catch(complete);
                                    })
                                    .catch(complete);
                            } else {
                                this.handleGet(undefined);
                                this.modalAllocate.Show = false;
                                ElMessage.success("调配成功");
                                complete();
                            }
                        });
                    }
                    break;
                case "allocate":
                    if (
                        this.list.SelectRows.any(
                            (it) => it.Status == EnumOrderPoolStatus.Canceled
                        )
                    ) {
                        ElMessage.warning("已取消的信息不能使用创建订单功能");
                        complete();
                    } else if (
                        this.list.SelectRows.any((it) =>
                            this.list.SelectRows.any(
                                (t) =>
                                    t.Id != it.Id &&
                                    (t.LoadingPortId != it.LoadingPortId ||
                                        t.DeliveryPortId != it.DeliveryPortId)
                            )
                        )
                    ) {
                        ElMessage.warning("装货港、卸货港不一致的信息不能一起调配");
                        complete();
                    } else {
                        this.listAllocate.Dto =
                            new DtoPageListSearchOrderStowageStackAllocate();
                        this.listAllocate.Dto.IgnoreIds = this.list.SelectRows.filter(
                            (it) => it.OrderId
                        )
                            .map((it) => it.OrderId as string)
                            .distinct();
                        this.listAllocate.Dto.TransportDirection = this.direction;
                        this.listAllocate.Dto.TransportType = this.type;
                        this.listAllocate.Dto.TransportContainerLoad = this.containerLoad;
                        const dto = new DtoSelectSearchOrdeCommon();
                        dto.IgnoreIds = this.listAllocate.Dto.IgnoreIds;
                        dto.StowageStack = true;
                        dto.TransportDirection = this.direction;
                        dto.TransportType = this.type;
                        dto.LoadingPortId = this.list.SelectRows[0].LoadingPortId;
                        dto.DeliveryPortId = this.list.SelectRows[0].DeliveryPortId;
                        OrderAppService.GetCodes(dto).then((r) => {
                            this.allocateCodes = r.data.Data ?? [];
                            this.getAllocateCodes();
                        });
                        OrderAppService.GetMasterBillNos(dto).then((r) => {
                            this.allocateMasterBillNos = r.data.Data ?? [];
                        });
                        OrderAppService.GetDeliveryPlaces(dto).then((r) => {
                            this.allocateDeliveryPlaces = r.data.Data ?? [];
                        });
                        OrderAppService.GetCustomerServices(dto).then((r) => {
                            this.allocateCustomerServices = r.data.Data ?? [];
                        });
                        DynamicSearchAppService.Post({
                            Code: "OrderStowageStack_Allocate",
                            Brackets: [
                                {
                                    Relation: EnumRelation.And,
                                    Items: [
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_LoadingPortId",
                                                Compare: EnumCompare.Equal,
                                                Value: this.list.SelectRows[0].LoadingPortId,
                                                Label: this.list.SelectRows[0].LoadingPortLabel,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_DeliveryPortId",
                                                Compare: EnumCompare.Equal,
                                                Value: this.list.SelectRows[0].DeliveryPortId,
                                                Label: this.list.SelectRows[0].DeliveryPortLabel,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_Code",
                                                Compare: EnumCompare.Equal,
                                                Value: this.$route.query.OrderCode as string,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_MasterBillNo",
                                                Compare: EnumCompare.Equal,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_EstimatedDepartureDate",
                                                Compare: EnumCompare.Between,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_ActualDepartureDate",
                                                Compare: EnumCompare.Between,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_EstimatedArrivalDate",
                                                Compare: EnumCompare.Between,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_ActualArrivalDate",
                                                Compare: EnumCompare.Between,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_DeliveryPlaceLabel",
                                                Compare: EnumCompare.Equal,
                                            },
                                        },
                                        {
                                            Relation: EnumRelation.And,
                                            Where: {
                                                Prop: "DynamicSearch_CustomerServiceId",
                                                Compare: EnumCompare.Equal,
                                            },
                                        },
                                    ],
                                },
                            ],
                        }).then((r) => {
                            this.listAllocate.Dto!.DynamicSearchId = r.data.Data;
                            this.handleGetAllocate();
                        });
                        complete();
                    }
                    break;
                case "create":
                    if (
                        this.list.SelectRows.any(
                            (it) => it.OrderId && it.Status == EnumOrderPoolStatus.Executing
                        )
                    ) {
                        ElMessage.warning(
                            "已配载的信息不能使用创建订单功能，如需调整，请使用调配功能"
                        );
                        complete();
                    } else if (
                        this.list.SelectRows.any(
                            (it) => it.Status == EnumOrderPoolStatus.Canceled
                        )
                    ) {
                        ElMessage.warning("已取消的信息不能使用创建订单功能");
                        complete();
                    } else if (
                        this.list.SelectRows.any((it) =>
                            this.list.SelectRows.any(
                                (t) =>
                                    t.Id != it.Id &&
                                    (t.LoadingPortLabel != it.LoadingPortLabel ||
                                        t.DeliveryPortLabel != it.DeliveryPortLabel)
                            )
                        )
                    ) {
                        ElMessage.warning("装货港、卸货港不一致的信息不能一起调配");
                        complete();
                    } else {
                        const pool = this.list.SelectRows[0];
                        this.modalCreateData.PoolIds = this.list.SelectIds;
                        this.modalCreateData.TransportDirection = this.direction;
                        this.modalCreateData.TransportType = this.type;
                        this.modalCreateData.TransportContainerLoad = this.containerLoad;
                        this.modalCreateData.LoadingPortId = pool.LoadingPortId;
                        this.modalCreateData.LoadingPortLabel = pool.LoadingPortLabel;
                        this.modalCreateData.DeliveryPortId = pool.DeliveryPortId;
                        this.modalCreateData.DeliveryPortLabel = pool.DeliveryPortLabel;
                        this.modalCreateData.CustomerServiceId = undefined;
                        this.modalCreateData.CustomerServiceLabel = undefined;
                        if (
                            this.list.SelectRows.any((it) =>
                                this.list.SelectRows.all(
                                    (t) =>
                                        t.CustomerServiceId == it.CustomerServiceId &&
                                        t.CustomerServiceLabel == it.CustomerServiceLabel
                                )
                            )
                        ) {
                            this.modalCreateData.CustomerServiceId =
                                this.list.SelectRows[0].CustomerServiceId;
                            this.modalCreateData.CustomerServiceLabel =
                                this.list.SelectRows[0].CustomerServiceLabel;
                        }
                        this.modalCreate.Show = true;
                        complete();
                    }
                    break;
                case "save-create":
                    (this.$refs["ref-form-create"] as any).validate((v: boolean) => {
                        if (v) {
                            OrderAppService.CreateStowageStackOrder(this.modalCreateData)
                                .then((r) => {
                                    CurrentLogier.LinkWithBack(
                                        `/order/booking/edit/${r.data.Data}?task=AddDeparturePortBooking`
                                    );
                                    complete();
                                })
                                .catch(complete);
                        } else {
                            complete();
                        }
                    });
                    break;
                case "delete":
                    if (
                        this.list.SelectRows.any(
                            (it) => it.Status == EnumOrderPoolStatus.Canceled
                        )
                    ) {
                        ElMessage.warning("已取消出运的信息无法重复取消");
                        complete();
                    } else {
                        this.loading = true;
                        OrderStowageStackAppService.Delete({
                            Ids: this.list.SelectIds,
                            Sure: false,
                        }).then((r) => {
                            if (r.data.Data && r.data.Data.length > 0) {
                                const codes = r.data.Data.join("/");
                                this.loading = false;
                                ElMessageBox.confirm(
                                    `订单${codes}中关联的委托书全部取消会造成订单被取消，是否确认继续？`,
                                    "取消出运",
                                    {
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                        type: "warning",
                                    }
                                )
                                    .then(() => {
                                        this.loading = true;
                                        OrderStowageStackAppService.Delete({
                                            Ids: this.list.SelectIds,
                                            Sure: true,
                                        })
                                            .then(() => {
                                                this.handleGet(undefined);
                                                ElMessage.success("取消成功");
                                                complete();
                                            })
                                            .catch(complete);
                                    })
                                    .catch(complete);
                            } else {
                                this.handleGet(undefined);
                                ElMessage.success("取消成功");
                                complete();
                            }
                        });
                    }
                    break;
                case "cancel":
                    if (
                        this.list.SelectRows.any(
                            (it) => it.Status == EnumOrderPoolStatus.Executing && !it.OrderId
                        )
                    ) {
                        ElMessage.warning("未配载的信息不能使用取消配载功能");
                        complete();
                    } else if (
                        this.list.SelectRows.any(
                            (it) => it.Status == EnumOrderPoolStatus.Canceled
                        )
                    ) {
                        ElMessage.warning("已取消出运的信息无法取消配载");
                        complete();
                    } else {
                        this.loading = true;
                        OrderStowageStackAppService.Cancel({
                            Ids: this.list.SelectIds,
                            Sure: false,
                        }).then((r) => {
                            if (r.data.Data && r.data.Data.length > 0) {
                                const codes = r.data.Data.join("/");
                                this.loading = false;
                                ElMessageBox.confirm(
                                    `订单${codes}中关联的委托书全部取消会造成订单被取消，是否确认继续？`,
                                    "取消配载",
                                    {
                                        confirmButtonText: "确定",
                                        cancelButtonText: "取消",
                                        type: "warning",
                                    }
                                )
                                    .then(() => {
                                        this.loading = true;
                                        OrderStowageStackAppService.Cancel({
                                            Ids: this.list.SelectIds,
                                            Sure: true,
                                        })
                                            .then(() => {
                                                this.handleGet(undefined);
                                                ElMessage.success("取消成功");
                                                complete();
                                            })
                                            .catch(complete);
                                    })
                                    .catch(complete);
                            } else {
                                this.handleGet(undefined);
                                ElMessage.success("取消成功");
                                complete();
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            } else {
                return str;
            }
        }

        onSum(): void {
            //单个箱规的计费重量计算：如箱规存在毛重与体积数值，则箱规的体积÷材积比与毛重比较取大值，然后×箱规的数量×包装数量；
            //例如某箱规的体积为0.1cbm，毛重30kgs，材积比1m³/200kgs，在包装内的数量为1，该包装的数量为10，
            //则计算出来的该箱规的计费重量为0.1÷（1/200）=20kgs，小于30kgs，所以用30×1×10=300kgs，即是该包装的计费重量；如该箱规的毛重为15kgs，则计费重量为20×1×10=200kgs；
            var param = new DtoCallbackWeight();
            param.PoolId = [];
            if (
                this.prePackedBox &&
                this.prePackedBox.Pools &&
                this.prePackedBox.VolumeRatioWeight
            ) {
                if (
                    this.prePackedBox.Pools != null &&
                    this.prePackedBox.Pools != undefined &&
                    this.prePackedBox.Pools.length
                ) {
                    for (var pool of this.prePackedBox.Pools) {
                        if (
                            pool.PoolId != undefined &&
                            pool.PoolId != "" &&
                            pool.PoolId != "00000000-0000-0000-0000-000000000000"
                        ) {
                            param.PoolId.push(pool.PoolId);
                        }
                    }
                }
                param.VolumeRatio = this.prePackedBox.VolumeRatioWeight;
                param.MiniStandard = this.prePackedBox.OneMinimumChargeableWeight;
                param.OneMiniStandard = this.prePackedBox.MinimumChargeableWeight;
                param.Type = this.prePackedBox.Type;
                OrderStowageStackAppService.GetWeight(param).then((r) => {
                    if (this.prePackedBox.Pools && r.data.Data && r.data.Data.InStocks) {
                        for (var po of this.prePackedBox.Pools) {
                            for (var instock of r.data.Data.InStocks) {
                                if (po.PoolId == instock.PoolId) {
                                    if (instock.Weight) {
                                        po.WarehousingCargoQuantitiesWeight = instock.Weight;
                                        po.IsSumInStock = instock.IsSumInStock;
                                    }
                                }
                            }
                        }
                    }

                    if (this.prePackedBox.Pools && r.data.Data && r.data.Data.Plans) {
                        for (var po1 of this.prePackedBox.Pools) {
                            for (var plan of r.data.Data.Plans) {
                                if (po1.PoolId == plan.PoolId) {
                                    if (plan.Weight) {
                                        po1.PlanCargoQuantitiesWeight = plan.Weight;
                                        po1.IsSumPlan = plan.IsSumInStock;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result =
            Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) +
            "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
