
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop } from "vue-property-decorator";
export default class View404 extends Vue {
  timer = 3;
  created() {
    this.count();
  }

  count() {
    if (this.timer == 0 && router.currentRoute.value.path == "/404") {
      router.push("/");
    } else {
      setTimeout(() => {
        this.timer--;
        this.count();
      }, 1000);
    }
  }
}
