import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _withDirectives((_openBlock(), _createBlock(_component_miezz_card, {
      title: "授信与账期",
      fix: true,
      fixTop: "30px",
      bottomButtons: _ctx.buttons,
      onHandleFooterButtonClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          modelValue: _ctx.detail,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail) = $event))
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'Limit')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: item.Label,
                  prop: item.Prop
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.detail.Data.Limit) + " 万元（CNY） ", 1)
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : _createCommentVNode("", true),
            (item.Prop == 'OverdueInterest')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  label: item.Label,
                  prop: item.Prop
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.detail.Data.OverdueInterest) + " ‰/天 ", 1)
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : _createCommentVNode("", true),
            (item.Prop == 'AccountPeriods')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  label: item.Label,
                  prop: item.Prop
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table, {
                      data: _ctx.detail.Data.AccountPeriods,
                      border: "",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          prop: "CostName",
                          label: "费用名称"
                        }),
                        _createVNode(_component_el_table_column, {
                          prop: "BusinessDate",
                          label: "业务发生日"
                        }, {
                          default: _withCtx((scope) => [
                            (scope.row.BusinessDate == 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "ETD"))
                              : (scope.row.BusinessDate == 1)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "ATD"))
                                : (scope.row.BusinessDate == 2)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "ETA"))
                                  : (scope.row.BusinessDate == 3)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "ATA"))
                                    : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_table_column, { label: "记账起始时间" }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(scope.row.AccountingStartDateStr), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_table_column, {
                          prop: "AccountPeriodDay",
                          label: "账期（天）"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(scope.row.AccountPeriodDay), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_table_column, {
                          prop: "Remarks",
                          label: "备注"
                        }, {
                          default: _withCtx((scope) => [
                            _createTextVNode(_toDisplayString(scope.row.Remarks), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : _createCommentVNode("", true),
            (item.Prop == 'Files')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 3,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_upload, {
                      modelValue: _ctx.detail.Data.Files,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.Files) = $event)),
                      prop: "File",
                      preview: true
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["bottomButtons", "onHandleFooterButtonClick"])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}