import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_miezz_foreign_multiple_check = _resolveComponent("miezz-foreign-multiple-check")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_ctx.form.Data)
    ? (_openBlock(), _createBlock(_component_miezz_form, {
        key: 0,
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'TableId')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: item.Prop,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_select, {
                    modelValue: _ctx.form.Data.TableId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.TableId) = $event)),
                    options: _ctx.tables,
                    clearable: false,
                    onHandleChange: _ctx.onTableIdChanged
                  }, null, 8, ["modelValue", "options", "onHandleChange"])
                ]),
                _: 2
              }, 1032, ["prop", "label"]))
            : (item.Prop == 'Costs')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: item.Prop
                }, {
                  label: _withCtx(() => [
                    _createVNode(_component_el_checkbox, {
                      modelValue: _ctx.costsCheckAll,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.costsCheckAll) = $event)),
                      indeterminate: _ctx.costsCheckAllIndeterminate,
                      onChange: _ctx.handleCostCheckAll
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.Label), 1)
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "indeterminate", "onChange"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_foreign_multiple_check, {
                      modelValue: _ctx.form.Data.Costs,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.Costs) = $event)),
                      options: _ctx.costs,
                      prop: "CostId",
                      onHandleChange: _ctx.handleCostChange
                    }, null, 8, ["modelValue", "options", "onHandleChange"])
                  ]),
                  _: 2
                }, 1032, ["prop"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}