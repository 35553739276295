import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 异常日志分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemExceptionLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 类型
     */
    Type?: string;
    /**
     * 异常消息
     */
    Message?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
}

/**
 * 异常日志分页列表-项-表单校验
 */
export const DtoListItemExceptionLogRules = {
}

/**
 * 异常日志分页列表-项-设置项
 */
export const DtoListItemExceptionLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "类型",
  },
  {
    Prop: "Message",
    Label: "异常消息",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
  },
]
