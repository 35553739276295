import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_Executor extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 执行人姓名
     */
    Executor?: string;
    /**
     * 执行人联系方式
     */
    ExecutorContactNumber?: string;
    /**
     * 执行人车牌号码
     */
    ExecutorLicensePlate?: string;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_ExecutorRules = {
    Executor: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ExecutorContactNumber: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    ExecutorLicensePlate: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_ExecutorMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Executor",
    Label: "执行人姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ExecutorContactNumber",
    Label: "执行人联系方式",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ExecutorLicensePlate",
    Label: "执行人车牌号码",
    Type: EnumMiezzFormItemType.InputText,
  },
]
