import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 基础价格表分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceTable extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.OpenService = false;
        this.OpenSettlement = false;
        this.OpenGuide = false;
        this.OpenAgreement = false;
        this.OpenCost = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 服务
     */
    OpenService?: boolean;
    /**
     * 结算
     */
    OpenSettlement?: boolean;
    /**
     * 指导
     */
    OpenGuide?: boolean;
    /**
     * 协议
     */
    OpenAgreement?: boolean;
    /**
     * 成本
     */
    OpenCost?: boolean;
    /**
     * 
     */
    Remark?: string;
}

/**
 * 基础价格表分页列表-项-表单校验
 */
export const DtoListItemPriceTableRules = {
}

/**
 * 基础价格表分页列表-项-设置项
 */
export const DtoListItemPriceTableMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OpenService",
    Label: "服务",
    Customize: true,
  },
  {
    Prop: "OpenSettlement",
    Label: "结算",
    Customize: true,
  },
  {
    Prop: "OpenGuide",
    Label: "指导",
    Customize: true,
  },
  {
    Prop: "OpenAgreement",
    Label: "协议",
    Customize: true,
  },
  {
    Prop: "OpenCost",
    Label: "成本",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "",
    Pre: true,
  },
]
