import UiPageList from "./UiPageList";
import DtoListItemOrderDepartureWharfOutStock from "./DtoListItemOrderDepartureWharfOutStock";
import DtoCheckQuantity from "./DtoCheckQuantity";

/**
 * 装货港-仓库/堆场/码头分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderDepartureWharfOutStock extends UiPageList<DtoListItemOrderDepartureWharfOutStock, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 合计件数
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积
     */
    SumVolumeOutStock?: number;
    /**
     * 计价单位
     */
    ChargeUnitOutStock?: string;
    /**
     * 合计件数
     */
    SumQuantityInStock?: number;
    /**
     * 合计毛重
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积
     */
    SumVolumeInStock?: number;
    /**
     * 入库货量
     */
    InStocks?: DtoCheckQuantity[];
    /**
     * 数量超出范围
     */
    IsQuantity?: boolean;
    /**
     * 备注
     */
    RemarkOutStock?: string;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
}

/**
 * 装货港-仓库/堆场/码头分页列表-表单校验
 */
export const DtoPageListOrderDepartureWharfOutStockRules = {
}
