
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoFomCompanyBusinessCardUnit, {
  DtoFomCompanyBusinessCardUnitMiezzFormItems,
  DtoFomCompanyBusinessCardUnitRules,
} from "@/models.machine/DtoFomCompanyBusinessCardUnit";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import MiezzModal from "@/models/MiezzModal";
import DtoRequestCompanyBusinessCardCheckExist from "@/models.machine/DtoRequestCompanyBusinessCardCheckExist";

export default class CompanyBusinessCardUnitForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() companyId?: string;
  @Prop() type?: EnumCompanyBusinessCardType;

  form = new MiezzForm<DtoFomCompanyBusinessCardUnit, string>();

  created(): void {
    this.form.Data = new DtoFomCompanyBusinessCardUnit();
    this.form.Data.CompanyId = this.companyId;
    this.form.Data.Type = this.type;
    //表单-校验
    this.form.Rules = DtoFomCompanyBusinessCardUnitRules;
    this.form.Rules.NameZh.push(this.getCheckExistRule("NameZh"));
    this.form.Items = DtoFomCompanyBusinessCardUnitMiezzFormItems.copy();

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
        Width: "100px"
      },
      {
        Code: "import",
        Label: "从基础信息导入",
        Type: "primary",
        Size: "small",
        Width: "100px"
      },
      {
        Code: "preview",
        Label: "预览",
        Type: "primary",
        Size: "small",
        Width: "100px"
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      CompanyBusinessCardAppService.GetUnitById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  getCheckExistRule(prop: string): any {
    return {
      required: true,
      message: "信息不能为空",
      trigger: "blur",
    };
    // return {
    //   required: true,
    //   validator: (rule: any, value: string, callback: any) => {
    //     if (!value) return callback(new Error("信息不能为空"));
    //     else {
    //       const dto = new DtoRequestCompanyBusinessCardCheckExist();
    //       dto.Id = this.form.Data.Id;
    //       dto.CompanyId = this.form.Data.CompanyId;
    //       dto.Type = this.form.Data.Type;
    //       dto.Prop = prop;
    //       dto.PropValue = value;
    //       CompanyBusinessCardAppService.CheckExist(dto).then((r) => {
    //         if (r.data.Data) return callback(new Error("信息重复，请重新填写"));
    //         else callback();
    //       });
    //     }
    //   },
    //   trigger: "blur",
    // };
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyBusinessCardAppService.PostUnit(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "import":
        CompanyAppService.Get(this.companyId).then((r) => {
          if (r.data.Data) {
            this.form.Data.NameZh = r.data.Data.NameZh;
            this.form.Data.Code = r.data.Data.Code;
            this.form.Data.CustomsCode = r.data.Data.CustomsCode;
          }
          complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
