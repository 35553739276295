import { createStore } from 'vuex'

export default createStore({
  state: {
    activeOne: 1
  },
  getters: {
    getActiveOne: function (state) {
      return state.activeOne;
    }
  },
  mutations: {
    setActiveOne: function (state, value) {
      state.activeOne = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
