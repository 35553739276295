import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamChargeUnit from "../models.machine/DtoSelectSearchParamChargeUnit";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamChargeUnit from "../models.machine/DtoPageListSearchParamChargeUnit";
import DtoPageListParamChargeUnit from "../models.machine/DtoPageListParamChargeUnit";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamChargeUnit from "../models.machine/DtoDetailParamChargeUnit";
import DtoFormParamChargeUnit from "../models.machine/DtoFormParamChargeUnit";

/**
 * 计价单位
 * @description 自动生成的代码,请勿修改
 */
export default class ParamChargeUnitAppService {
    /**
     * 获取港口选择器
     * @param {DtoSelectSearchParamChargeUnit} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamChargeUnit): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamChargeUnit/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取计价单位分页列表
     * @param {DtoPageListSearchParamChargeUnit} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamChargeUnit>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamChargeUnit): AxiosPromise<RESTfulResult<DtoPageListParamChargeUnit>> {
        const url = `/api/ParamChargeUnit/Get`;
        return service.get<RESTfulResult<DtoPageListParamChargeUnit>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamChargeUnit/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取计价单位详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamChargeUnit>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamChargeUnit>> {
        const url = `/api/ParamChargeUnit/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamChargeUnit>>(url);
    }

    /**
     * 新增/编辑计价单位
     * @param {DtoFormParamChargeUnit} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamChargeUnit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamChargeUnit/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除计价单位
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamChargeUnit/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamChargeUnit/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamChargeUnit/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamChargeUnit/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取NameZh数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameZhList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ParamChargeUnit/GetNameZhList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
