import UiSelectOption from "./UiSelectOption";

/**
 * 运输类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumTransportType {
    /**
     * 海运/By Sea
     */
    Sea = 1,
    /**
     * 空运/By Air
     */
    Air = 2,
    /**
     * 铁路/By Railway
     */
    Railway = 3,
}

/**
 * 运输类型-选项
 */
export const EnumTransportTypeOption: UiSelectOption<number>[] = [
    { Label: "海运/By Sea", Value: 1, Data: "Sea" },
    { Label: "空运/By Air", Value: 2, Data: "Air" },
    { Label: "铁路/By Railway", Value: 3, Data: "Railway" },
];
