import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCompanyAccountingPeriod from "./DtoFormCompanyAccountingPeriod";
import DtoFormCompanyCreditAndAccountPeriod_CompanyCreditFile from "./DtoFormCompanyCreditAndAccountPeriod_CompanyCreditFile";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyCreditAndAccountPeriod extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Limit = 0;
        this.OverdueInterest = 0;
        this.Files = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 模板名称
     */
    TemplateName?: string;
    /**
     * 授信额度
     */
    Limit?: number;
    /**
     * 逾期利率
     */
    OverdueInterest?: number;
    /**
     * 账期信息
     */
    AccountPeriods?: DtoFormCompanyAccountingPeriod[];
    /**
     * 合同编号
     */
    ContractCode?: string;
    /**
     * 附件
     */
    Files?: DtoFormCompanyCreditAndAccountPeriod_CompanyCreditFile[];
}

/**
 * -表单校验
 */
export const DtoFormCompanyCreditAndAccountPeriodRules = {
    Limit: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    OverdueInterest: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    ContractCode: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCompanyCreditAndAccountPeriodMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "TemplateName",
    Label: "模板名称",
    Customize: true,
  },
  {
    Prop: "Limit",
    Label: "授信额度",
    Customize: true,
  },
  {
    Prop: "OverdueInterest",
    Label: "逾期利率",
    Customize: true,
  },
  {
    Prop: "AccountPeriods",
    Label: "账期信息",
    Customize: true,
  },
  {
    Prop: "ContractCode",
    Label: "合同编号",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
]
