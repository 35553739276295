import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_code_select_form = _resolveComponent("code-select-form")!
  const _component_code_dynamic_search_form = _resolveComponent("code-dynamic-search-form")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "always",
    "body-style": { padding: '20px' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_miezz_form, {
        ref: "ruleForm",
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form) = $event))
      }, {
        "form-item": _withCtx(({ item }) => [
          (item.Prop == 'Name')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: item.Label,
                prop: item.Prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    modelValue: _ctx.form.Data.Name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Name) = $event)),
                    translate: _ctx.form.Data.Summary,
                    pattern: "[a-zA-Z_]",
                    placeholder: "请填写",
                    class: "form-item-input-text"
                  }, null, 8, ["modelValue", "translate"])
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : _createCommentVNode("", true),
          (item.Prop == 'SelectOption')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_select_form, {
                    template: _ctx.form.Data.SelectTemplate,
                    "onUpdate:template": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.SelectTemplate) = $event)),
                    properties: _ctx.form.Data.SelectOption,
                    "onUpdate:properties": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.SelectOption) = $event)),
                    table: _ctx.form.Data.Table
                  }, null, 8, ["template", "properties", "table"])
                ]),
                _: 2
              }, 1032, ["label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'DynamicSearchItems')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 2,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_dynamic_search_form, {
                    modelValue: _ctx.form.Data.DynamicSearchItems,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.DynamicSearchItems) = $event)),
                    table: _ctx.form.Data.Table
                  }, null, 8, ["modelValue", "table"])
                ]),
                _: 2
              }, 1032, ["label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'ListItem')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 3,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_property_tree, {
                    modelValue: _ctx.form.Data.ListItem,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.ListItem) = $event)),
                    table: _ctx.form.Data.Table,
                    show: ['Type', 'PipeBoolean']
                  }, null, 8, ["modelValue", "table"])
                ]),
                _: 2
              }, 1032, ["label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'Detail')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 4,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_property_tree, {
                    modelValue: _ctx.form.Data.Detail,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.Detail) = $event)),
                    table: _ctx.form.Data.Table
                  }, null, 8, ["modelValue", "table"])
                ]),
                _: 2
              }, 1032, ["label"]))
            : _createCommentVNode("", true),
          (item.Prop == 'Form')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 5,
                label: item.Label
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_code_property_tree, {
                    modelValue: _ctx.form.Data.Form,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.Data.Form) = $event)),
                    table: _ctx.form.Data.Table
                  }, null, 8, ["modelValue", "table"])
                ]),
                _: 2
              }, 1032, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_miezz_button_bar, {
        modelValue: _ctx.buttons,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.buttons) = $event)),
        onHandleClick: _ctx.handleSubmit
      }, null, 8, ["modelValue", "onHandleClick"])
    ]),
    _: 1
  }))
}