
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import DeliveryRequirementAppService from "@/apis.machine/DeliveryRequirementAppService";
    import DtoPageListSearchDeliveryRequirement, { DtoPageListSearchDeliveryRequirementDynamicSearchItems } from "@/models.machine/DtoPageListSearchDeliveryRequirement";
    import DtoPageListDeliveryRequirement from "@/models.machine/DtoPageListDeliveryRequirement";
    import DtoListItemDeliveryRequirement, {
        DtoListItemDeliveryRequirementMiezzListItems,
    } from "@/models.machine/DtoListItemDeliveryRequirement";
    import DeliveryRequirementDetail from "./DeliveryRequirementDetail.vue"; import DeliveryRequirementForm from "./DeliveryRequirementForm.vue";
    import Logier from "@/models.machine/Logier";
    import CurrentLogier from "@/apis/CurrentLogier";
    @Options({
        components: {
            DeliveryRequirementDetail,
            DeliveryRequirementForm,
        },
    })
    export default class DeliveryRequirementPageList extends Vue {
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchDeliveryRequirement, DtoListItemDeliveryRequirement, string>("System_ParamList_DeliveryRequirement");
        /**当前Id */
        id?: string;
        /**详细模态框 */
        modalDetail = new MiezzModal();
        /**表单模态框 */
        modalForm = new MiezzModal();
        logier?: Logier;

        /**创建时 */
        created(): void {
            this.logier = CurrentLogier.GetLogier();
            this.list.Back = true;
            this.list.Title = "交期要求";
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchDeliveryRequirement();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchDeliveryRequirementDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListDeliveryRequirement();
            //列表-列配置
            this.list.Items = DtoListItemDeliveryRequirementMiezzListItems;
    
            //if (this.logier && this.logier.CompanyType == 10) {
            //    this.list.EnabledSelect = true;
            //} else {
            //    this.list.EnabledSelect = false;
            //}
            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "add",
                    Label: "添加",
                    Type: "primary",
                    Size: "small",
                    PowerCode:"System_ParamList_DeliveryRequirements_AddEdit",
                    Width: "65px"
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "是否确认删除",
                    PowerCode:"System_ParamList_DeliveryRequirements_Delete"
                },
            ];
            this.list.HandleSelectable = (row) => {
                if (row.IsEdit) return row.IsEdit;
                else return false;
            }

            this.list.CheckRowButtonShow = (btn, row) => {
                if (btn.Code == "edit" || btn.Code == "drag-sort") {
                    if (row.IsEdit == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return true;
                }

            };

        }

        /**查询列表 */
        handleGet(): void {
            DeliveryRequirementAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
            });
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    this.modalForm.Show = true;
                    this.modalForm.Title = "新增交期要求";
                    this.id = undefined;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemDeliveryRequirement,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    this.modalDetail.Show = true;
                    this.modalDetail.Title = `交期要求详细-${row.Name}`;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    DeliveryRequirementAppService.Delete(this.list.SelectIds)
                        .then(() => {
                            this.handleGet();
                            complete();
                        })
                        .catch(complete);
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.modalDetail.Show = false;
            this.handleGet();
        }
    }
