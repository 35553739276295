
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import DtoPageListSearchCompanyContact, {
  DtoPageListSearchCompanyContactDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyContact";
import DtoPageListCompanyContact from "@/models.machine/DtoPageListCompanyContact";
import DtoListItemCompanyContact, {
  DtoListItemCompanyContactMiezzListItems,
} from "@/models.machine/DtoListItemCompanyContact";
import { EnumContactTypeOption } from "@/models.machine/EnumContactType";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";

export default class CompanyContactPageList extends Vue {
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompanyContact,
    DtoListItemCompanyContact,
    string
  >;
  @Prop() path!: string;

  companyId?: string;
  departments: UiSelectOptionWithStringValue[] = [];
  jobs: UiSelectOptionWithStringValue[] = [];

  /**创建时 */
  created(): void {
    this.companyId = this.$route.params.id as string;
    this.list.Back = true;
    this.list.Title = "通讯录";
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyContact();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyContactDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyContact();
    //列表-列配置
    this.list.Items = DtoListItemCompanyContactMiezzListItems;
    const types = EnumContactTypeOption;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Numbers") {
        return row.Numbers?.map((it) => {
          const type = types.firstOrDefault((t) => t.Value == it.Type);
          return `【${type?.Label ?? "暂无数据"}：${
            it?.Content ?? "暂无数据"
          }】`;
        }).join("");
      }
      return undefined;
    };

    CompanyContactAppService.GetDepartmentSelectByCompanyId(
      this.companyId
    ).then((r) => {
      this.departments = r.data.Data ?? [];
    });
    CompanyContactAppService.GetJobSelectByCompanyId(this.companyId).then(
      (r) => {
        this.jobs = r.data.Data ?? [];
      }
    );
  }

  /**查询列表 */
  handleGet(): void {
    const dto = Object.copy(this.list.Dto);
    dto.CompanyId = this.companyId;
    CompanyContactAppService.Get(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.$router.push(`${this.path}/add/${this.companyId}`);
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyContact,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.$router.push(`${this.path}/detail/${this.companyId}/${row.Id}`);
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        CompanyContactAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
