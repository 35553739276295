import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_minus = _resolveComponent("minus")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_code_property_tree = _resolveComponent("code-property-tree")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "ruleForm",
    model: _ctx.modelValue,
    rules: _ctx.rules,
    "label-width": "51px",
    size: "small",
    inline: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        type: "primary",
        size: "small",
        onClick: _ctx.handleAdd
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_plus)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
        return (_openBlock(), _createBlock(_component_el_row, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, {
                  label: "叶子",
                  prop: "IsLeaf"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: item.IsLeaf,
                      "onUpdate:modelValue": ($event: any) => ((item.IsLeaf) = $event),
                      onChange: ($event: any) => (_ctx.onIsLeafChanged(item))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_form_item, {
                  label: "集合",
                  prop: "IsList"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, {
                      modelValue: item.IsList,
                      "onUpdate:modelValue": ($event: any) => ((item.IsList) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: "btn-remove",
                      type: "default",
                      size: "small",
                      onClick: ($event: any) => (_ctx.handleRemove(i))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_minus)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                (item.IsLeaf)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 0,
                      label: "类型",
                      prop: "Name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: item.Name,
                          "onUpdate:modelValue": ($event: any) => ((item.Name) = $event),
                          "allow-create": true,
                          placeholder: "空值",
                          clearable: "",
                          filterable: "",
                          class: "input-text"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leafTypes, (o, oi) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: oi,
                                label: o,
                                value: o
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024))
                  : (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      label: "类型",
                      prop: "Name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_input, {
                          modelValue: item.Name,
                          "onUpdate:modelValue": ($event: any) => ((item.Name) = $event),
                          class: "input-text",
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024)),
                _createVNode(_component_el_form_item, {
                  label: "注释",
                  prop: "Summary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_input, {
                      modelValue: item.Summary,
                      "onUpdate:modelValue": ($event: any) => ((item.Summary) = $event),
                      class: "input-text",
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_el_form_item, {
                  label: "字段",
                  prop: "FieldName"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_input, {
                      modelValue: item.FieldName,
                      "onUpdate:modelValue": ($event: any) => ((item.FieldName) = $event),
                      class: "input-text",
                      clearable: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1024),
                (!item.IsLeaf)
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 2,
                      label: "基类",
                      prop: "BaseType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: item.BaseType,
                          "onUpdate:modelValue": ($event: any) => ((item.BaseType) = $event),
                          "allow-create": true,
                          placeholder: "空值",
                          clearable: "",
                          filterable: "",
                          class: "input-text"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.baseTypes, (o, oi) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: oi,
                                label: o,
                                value: o
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024),
            (!item.IsLeaf)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "属性",
                      prop: "Properties",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_code_property_tree, {
                          modelValue: item.Properties,
                          "onUpdate:modelValue": ($event: any) => ((item.Properties) = $event),
                          table: _ctx.table,
                          show: _ctx.show
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "table", "show"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}