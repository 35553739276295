
    import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
    import CurrentLogier from "@/apis/CurrentLogier";
    import moment from "moment";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import OrderWarehouseAppService from "@/apis.machine/OrderWarehouseAppService";
    import DtoPageListSearchOrderWarehouse, { DtoPageListSearchOrderWarehouseDynamicSearchItems } from "@/models.machine/DtoPageListSearchOrderWarehouse";
    import DtoPageListOrderWarehouse from "@/models.machine/DtoPageListOrderWarehouse";
    import DtoListItemOrderWarehouse, {
        DtoListItemOrderWarehouseMiezzListItems,
    } from "@/models.machine/DtoListItemOrderWarehouse";
    import { EnumWarehouseType } from "@/models.machine/EnumWarehouseType";
    import { EnumOrderWarehouseStatusOption } from "@/models.machine/EnumOrderWarehouseStatus";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import OrderWarehouseAssign from "./OrderWarehouseAssign.vue";
    import OrderPoolChooseService from "@/views/Order/Pool/OrderPoolChooseService.vue";
    @Options({
        components: {
            OrderWarehouseAssign,
            OrderPoolChooseService,
        },
    })
    export default class OrderWarehousePageList extends Vue {
        requirements: UiSelectOptionWithStringValue[] = [];
        addresss: UiSelectOptionWithStringValue[] = [];
        partners: UiSelectOptionWithStringValue[] = [];
        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchOrderWarehouse, DtoListItemOrderWarehouse, string>();
        /**当前Id */
        id?: string;
        orderCode?: string;
        clientCompanyId?: string;
        clientUserId?: string;
        EnumOrderWarehouseStatusOption = EnumOrderWarehouseStatusOption;
        users?: UiSelectOption<string>[] = [];
        modalChooseService = new MiezzModal(MiezzModalType.Dialog);
        OrderManagement_SalesOrder_AssignExecutor = false;
        selectIds?: string[];
        /**创建时 */
        created(): void {
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("OrderManagement_SalesOrder_AssignExecutor") == true) {
                    this.OrderManagement_SalesOrder_AssignExecutor = true;
                }
            });
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchOrderWarehouse();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchOrderWarehouseDynamicSearchItems;
            var columeList = DtoPageListSearchOrderWarehouseDynamicSearchItems;
            columeList = [];
            DtoPageListSearchOrderWarehouseDynamicSearchItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "DynamicSearch_Partner") {
                    item.Label = "采购方";
                    columeList.push(item);
                }
                else {
                    columeList.push(item);
                }
            })
            this.list.DynamicSearchItems = columeList;

            //列表-数据-初始
            this.list.Data = new DtoPageListOrderWarehouse();
            //列表-列配置
            var columes = DtoListItemOrderWarehouseMiezzListItems;
            columes = [];
            DtoListItemOrderWarehouseMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "Partner") {
                    item.Label = "采购方";
                    columes.push(item);
                }
                else {
                    columes.push(item);
                }
                if (item.Prop == "SigningTime") {
                    item.Width = 60;
                }
                if (item.Prop == "Status") {
                    item.Width = 40;
                }
                if (item.Prop == "Price") {
                    item.Width = 60;
                }
                if (item.Prop == "User") {
                    item.Width = 50;
                }
                if (item.Prop == "Code") {
                    item.Width = 80;
                }
            })
            this.list.Items = columes;

            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "add",
                    Label: "添加订单",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "OrderManagement_SalesOrder_NewlyAdded",
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "detail",
                    Title: "查看详细",
                    Icon: "search",
                    Type: "default",
                    Size: "small",
                    PowerCode: "OrderManagement_SalesOrder_Detail",
                },
                {
                    Code: "order-pool",
                    Title: "创建委托书",
                    Icon: "DocumentAdd",
                    Type: "default",
                    Size: "small",
                    PowerCode: "OrderManagement_Pool_Add",
                },
                {
                    Code: "pool-link",
                    Title: "关联委托书",
                    Icon: "link",
                    Type: "default",
                    Size: "small",
                    PowerCode: "OrderManagement_Pool",
                },
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "OrderManagement_SalesOrder_Delete",
                },
                {
                    Code: "batch-assign",
                    Label: "批量指派",
                    Icon: "pointer",
                    Type: "text",
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "OrderManagement_SalesOrder_AssignExecutor",
                },
                {
                    Code: "batch-delivery",
                    Label: "批量交货",
                    Icon: "goods",
                    Type: "text",
                    MessageBox: true,
                    Size: "small",
                    PowerCode: "OrderManagement_PurchaseOrder_BulkDelivery",
                },
            ];
        }

        isString(str: string): string {
            if(str != null && str != undefined){
                 str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
        onAssigner(row: any): void {
            var rows = [];
            rows.push(row);
            this.selectIds = [];
            this.selectIds.push(row.Id as string);
            var Assigner = this.$refs["Assigner"] as any;
            Assigner.onInit(rows);
        }


        querySearchPartnerAsync(queryString: string, cb: any) {
            var restaurants = this.partners;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.partners) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.partners) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.partners) {
                    for (var i = 0; i < this.partners.length; i++) {
                        if (i < 10) {
                            results.push(this.partners[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectPartner(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchRequirementAsync(queryString: string, cb: any) {
            var restaurants = this.requirements;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.requirements) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.requirements) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.requirements) {
                    for (var i = 0; i < this.requirements.length; i++) {
                        if (i < 10) {
                            results.push(this.requirements[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectRequirement(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }

        querySearchAddressAsync(queryString: string, cb: any) {
            var restaurants = this.addresss;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.addresss) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.addresss) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.addresss) {
                    for (var i = 0; i < this.addresss.length; i++) {
                        if (i < 10) {
                            results.push(this.addresss[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectAddress(item: UiSelectOption<string>, model: any) {
            model.Where.Value = item.Label;
        }


        /**查询列表 */
        handleGet(): void {
            var param = {
                Type: 20,
            };
            OrderWarehouseAppService.GetAddress(param).then((add) => {
                if (add.data.Data) this.addresss = add.data.Data;
                OrderWarehouseAppService.GetPartner(param).then((partner) => {
                    if (partner.data.Data) this.partners = partner.data.Data;
                    OrderWarehouseAppService.GetRequirement(param).then((req) => {
                        if (req.data.Data) this.requirements = req.data.Data;
                        OrderWarehouseAppService.GetCreater(param).then((c) => {
                            if (c.data.Data) this.users = c.data.Data;
                            if (this.list.Dto) {
                                this.list.Dto.Type = EnumWarehouseType.Out;
                            }
                            OrderWarehouseAppService.Get(this.list.Dto).then((r) => {
                                this.list.Data = r.data.Data;
                                if (this.list.Data && this.list.Data.Items) {
                                    for (var it of this.list.Data.Items) {
                                        it.UserName = "";
                                        it.SigningTimeString = "";
                                        if (it.SigningTime) {
                                            it.SigningTimeString = moment(it.SigningTime).format("YYYY/MM/DD");
                                        }
                                        if (it.User && it.User.JoinCompanyUsers && it.User.JoinCompanyUsers.length > 0) {
                                            for (var company of it.User.JoinCompanyUsers) {
                                                if (company.CompanyId == it.CompanyId) {
                                                    it.UserName = company.Name;
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        });
                    });

                });
            });
        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    this.$router.push("/orderwarehouse/sale/add/" + EnumWarehouseType.Out + "?backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemOrderWarehouse,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    this.$router.push("/orderwarehouse/sale/detail/" + this.id + "?backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                case "order-pool":
                    if (this.list && this.list.Data && this.list.Data.Items) {
                        for (var it of this.list.Data.Items) {
                            if (it.Id == this.id && it.JoinCompanyUser) {
                                this.orderCode = it.Code;
                                this.clientCompanyId = it.JoinCompanyUser.CompanyId;
                                this.clientUserId = it.JoinCompanyUserId;
                            }
                        }
                    }

                    this.modalChooseService.Show = true;
                    complete();
                    break;
                case "pool-link":
                    this.$router.push("/order/pool?OrderWarehouseId=" + this.id);// + "&backUrl=" + this.$route.fullPath);
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个订单");
                        return;
                    }

                    ElMessageBox.confirm("您确定要删除选中的信息吗？删除后信息将无法恢复，是否继续", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {


                            OrderWarehouseAppService.Delete(this.list.SelectIds)
                                .then(() => {
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });
                    break;
                case "batch-assign":
                    this.selectIds = this.list.SelectIds;
                    var Assigner = this.$refs["Assigner"] as any;
                    Assigner.onInit(this.list.SelectRows);

                    complete();
                    break;
                case "batch-delivery":
                    ElMessageBox.confirm("您确定选中信息的状态都设置为全部交货？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {

                            var param = {
                                Ids: this.list.SelectIds,
                            };
                            OrderWarehouseAppService.Delivery(param)
                                .then(() => {
                                    ElMessage.success("设置成功");
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });
                    break;
                default:
                    break;
            }
        }

        onSelectOrderWarehouseAssigner(userId: string): void {

            var param = {
                Ids: this.selectIds,
                UserId: userId,
            };
            OrderWarehouseAppService.AssignUser(param)
                .then(() => {
                    ElMessage.success("指派成功");
                    this.handleGet();
                })
                .catch();
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
        /**选择服务完成回调 */
        handleChooseServiceSubmit(id: string): void {
            this.$router.push(`/order/pool/add/${id}`);
        }
    }
