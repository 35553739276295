import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumVoyageUnit, EnumVoyageUnitOption } from "./EnumVoyageUnit";
import { EnumSailingTimeType, EnumSailingTimeTypeOption } from "./EnumSailingTimeType";
import DtoFormPrice_PriceFieldValue_SailingWeek from "./DtoFormPrice_PriceFieldValue_SailingWeek";
import UiGroup from "./UiGroup";

/**
 * 价格表单-要素值
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPrice_PriceFieldValue extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.SailingWeeks = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 要素代码
     */
    ColumnFieldCode?: string;
    /**
     * 基础价格表-列Id
     */
    ColumnId?: string;
    /**
     * 标签
     */
    Label?: string;
    /**
     * 费用Id
     */
    CostId?: string;
    /**
     * 材积比
     */
    VolumeRatio?: number;
    /**
     * 最低计费标准
     */
    MinChargingStandard?: number;
    /**
     * 最低计费标准-单位标签
     */
    MinChargingStandardUnitLabel?: string;
    /**
     * 最低计费标准-单位Id
     */
    MinChargingStandardUnitId?: string;
    /**
     * 计费区间-起始
     */
    ChargingRangeMin?: number;
    /**
     * 计费区间-截止
     */
    ChargingRangeMax?: number;
    /**
     * 计费区间-单位标签
     */
    ChargingRangeUnitLabel?: string;
    /**
     * 计费区间-单位Id
     */
    ChargingRangeUnitId?: string;
    /**
     * 金额
     */
    Amount?: number;
    /**
     * 金额公式
     */
    AmountFormula?: string;
    /**
     * 币制Id
     */
    CurrencySystemId?: string;
    /**
     * 第一计价单位Id
     */
    FirstChargingUnitId?: string;
    /**
     * 第二计价单位Id
     */
    SecondChargingUnitId?: string;
    /**
     * 港口Id
     */
    PortId?: string;
    /**
     * 国家Id
     */
    CountryId?: string;
    /**
     * 地区Id
     */
    AreaId?: string;
    /**
     * 承运人Id
     */
    CarrierId?: string;
    /**
     * 渠道Id
     */
    ChannelId?: string;
    /**
     * 航线代码Id
     */
    RouteCodeId?: string;
    /**
     * 航程
     */
    Voyage?: number;
    /**
     * 航程单位
     */
    VoyageUnit?: EnumVoyageUnit;
    /**
     * 开航日期类型
     */
    SailingTimeType?: EnumSailingTimeType;
    /**
     * 开航日期
     */
    SailingDate?: Date;
    /**
     * 开航周数
     */
    SailingWeeks?: DtoFormPrice_PriceFieldValue_SailingWeek[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 价格表单-要素值-表单校验
 */
export const DtoFormPrice_PriceFieldValueRules = {
    ColumnId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 价格表单-要素值-设置项
 */
export const DtoFormPrice_PriceFieldValueMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ColumnFieldCode",
    Label: "要素代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ColumnId",
    Label: "基础价格表-列Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Label",
    Label: "标签",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CostId",
    Label: "费用Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "VolumeRatio",
    Label: "材积比",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MinChargingStandard",
    Label: "最低计费标准",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MinChargingStandardUnitLabel",
    Label: "最低计费标准-单位标签",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MinChargingStandardUnitId",
    Label: "最低计费标准-单位Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargingRangeMin",
    Label: "计费区间-起始",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargingRangeMax",
    Label: "计费区间-截止",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargingRangeUnitLabel",
    Label: "计费区间-单位标签",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargingRangeUnitId",
    Label: "计费区间-单位Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Amount",
    Label: "金额",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AmountFormula",
    Label: "金额公式",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "第一计价单位Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SecondChargingUnitId",
    Label: "第二计价单位Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PortId",
    Label: "港口Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CountryId",
    Label: "国家Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AreaId",
    Label: "地区Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CarrierId",
    Label: "承运人Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChannelId",
    Label: "渠道Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "RouteCodeId",
    Label: "航线代码Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Voyage",
    Label: "航程",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "VoyageUnit",
    Label: "航程单位",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumVoyageUnitOption,
  },
  {
    Prop: "SailingTimeType",
    Label: "开航日期类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumSailingTimeTypeOption,
  },
  {
    Prop: "SailingDate",
    Label: "开航日期",
    Type: EnumMiezzFormItemType.Date,
  },
  {
    Prop: "SailingWeeks",
    Label: "开航周数",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
