import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderDepartureHistory from "../models.machine/DtoPageListSearchOrderDepartureHistory";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderDepartureHistory from "../models.machine/DtoPageListOrderDepartureHistory";

/**
 * 报关/清关的历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class OrderDepartureHistoryAppService {
    /**
     * 获取报关/清关的历史记录分页列表
     * @param {DtoPageListSearchOrderDepartureHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureHistory>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderDepartureHistory): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureHistory>> {
        const url = `/api/OrderDepartureHistory/Get`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureHistory>>(url, { params: dto });
    }

    /**
     * 获取报关/清关的历史记录分页列表
     * @param {DtoPageListSearchOrderDepartureHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureHistory>>} 分页列表
     */
    static All(dto?: DtoPageListSearchOrderDepartureHistory): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureHistory>> {
        const url = `/api/OrderDepartureHistory/All`;
        return service.post<RESTfulResult<DtoPageListOrderDepartureHistory>>(url, dto);
    }

}
