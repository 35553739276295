import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormSmsLogin {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 邀请码
     */
    InviteCode?: string;
    /**
     * 手机号码
     */
    PhoneNumber?: string;
    /**
     * 密码
     */
    Password?: string;
    /**
     * 确认密码密码
     */
    SurePassword?: string;
    /**
     * 验证码
     */
    VerifyCode?: string;
}

/**
 * -表单校验
 */
export const DtoFormSmsLoginRules = {
    PhoneNumber: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
                    return callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            }
        },
    ],
    Password: [
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
    SurePassword: [
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
    VerifyCode: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9]{6}$/.test(value)) {
                    return callback(new Error("验证码错误"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormSmsLoginMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "PhoneNumber",
    Label: "手机号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Password",
    Label: "密码",
    Hide: true,
    Type: EnumMiezzFormItemType.InputPassword,
  },
  {
    Prop: "SurePassword",
    Label: "确认密码密码",
    Hide: true,
    Type: EnumMiezzFormItemType.InputPassword,
  },
  {
    Prop: "VerifyCode",
    Label: "验证码",
    Customize: true,
  },
]
