import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPriceServiceShortcut from "../models.machine/DtoSelectSearchPriceServiceShortcut";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import UiDrag from "../models.machine/UiDrag";
import DtoFormPriceServiceShortcut from "../models.machine/DtoFormPriceServiceShortcut";
import DtoFormPriceServiceShortcutName from "../models.machine/DtoFormPriceServiceShortcutName";

/**
 * 价格服务快捷方式
 * @description 自动生成的代码,请勿修改
 */
export default class PriceServiceShortcutAppService {
    /**
     * 获取价格服务快捷方式选择器
     * @param {DtoSelectSearchPriceServiceShortcut} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPriceServiceShortcut): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PriceServiceShortcut/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceServiceShortcut/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 添加价格服务快捷方式
     * @param {DtoFormPriceServiceShortcut} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPriceServiceShortcut): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PriceServiceShortcut/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 编辑价格服务快捷方式名称
     * @param {DtoFormPriceServiceShortcutName} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostName(dto?: DtoFormPriceServiceShortcutName): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceServiceShortcut/PostName`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 校验价格服务快捷方式是否存在
     * @param {DtoFormPriceServiceShortcutName} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckExist(dto?: DtoFormPriceServiceShortcutName): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PriceServiceShortcut/CheckExist`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 批量删除价格服务快捷方式
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PriceServiceShortcut/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

}
