
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import DtoFormParamPriceCost, {
  DtoFormParamPriceCostMiezzFormItems,
  DtoFormParamPriceCostRules,
} from "@/models.machine/DtoFormParamPriceCost";
import DtoListItemParamPriceCost_Repeat from "@/models.machine/DtoListItemParamPriceCost_Repeat";

export default class ParamPriceCostForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamPriceCost, string>();
  optFullName?: Array<string> = [];
    
  optItemListRepeat: Array<DtoListItemParamPriceCost_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamPriceCost();
    //表单-校验
    this.form.Rules = DtoFormParamPriceCostRules;
    this.form.Rules.NameZh = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          let NameZh = "";
          let NameEn = "";
          if (this.form.Data.NameZh!= null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
            NameZh = this.form.Data.NameZh.toUpperCase().trim();
          }
          if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "") {
            NameEn = this.form.Data.NameEn.toUpperCase().trim();
          }
          if (NameZh != "" && NameEn != "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == NameZh && it.NameEn?.toUpperCase().trim() == NameEn)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else if(NameZh == "" && NameEn != "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == null && it.NameEn?.toUpperCase().trim() == NameEn)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else if(NameZh != "" && NameEn == "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == NameZh && it.NameEn?.toUpperCase().trim() == null)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
            
          } else {
            return callback();
          }
          
        },
        trigger: "blur",
      }
    ];
    this.form.Rules.NameEn = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          let NameZh = "";
          let NameEn = "";
          if (this.form.Data.NameZh!= null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
            NameZh = this.form.Data.NameZh.toUpperCase().trim();
          }
          if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "") {
            NameEn = this.form.Data.NameEn.toUpperCase().trim();
          }
          if (NameZh != "" && NameEn != "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == NameZh && it.NameEn?.toUpperCase().trim() == NameEn)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else if(NameZh == "" && NameEn != "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == null && it.NameEn?.toUpperCase().trim() == NameEn)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          } else if(NameZh != "" && NameEn == "") {
            if (this.optItemListRepeat.some(it => it.NameZh?.toUpperCase().trim() == NameZh && it.NameEn?.toUpperCase().trim() == null)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
            
          } else {
            return callback();
          }
          
        },
        trigger: "blur",
      }
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamPriceCostMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamPriceCostAppService.GetList().then((r1) => {
      if (r1.data.Data) this.optItemListRepeat = r1.data.Data;

      if (this.id) {
        ParamPriceCostAppService.GetById(this.id).then((r) => {
          if (r.data.Data) this.form.Data = r.data.Data;
          this.optItemListRepeat = this.optItemListRepeat.filter(it => it.Id != this.id);
        });
      }
    })
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        if (!this.form.Data.NameZh && !this.form.Data.NameEn) {
          ElMessage.warning("中文名称和英文名称不可同时为空！");
          complete();
        } else {
          MiezzForm.submit(this.$refs, "ruleForm", (v) => {
            if (v) {
              ParamPriceCostAppService.Post(this.form.Data)
                .then(() => {
                  this.handleSubmit();
                  complete();
                })
                .catch(complete);
            } else complete();
          });
        }
        
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    
    if (this.form.Data.NameZh) {
      this.optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    MiezzForm.validateField(this.$refs, "ruleForm", "NameEn");
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
// 去掉空格
function trim(str: string) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
