
import CodeControllerAppService from "@/apis.machine/CodeControllerAppService";
import DtoFormCodeProperty from "@/models.machine/DtoFormCodeProperty";
import { EnumValidRegularTypeOption } from "@/models.machine/EnumValidRegularType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Model, Emit } from "vue-property-decorator";
import CodePropertyForm from "./CodePropertyForm.vue";

@Options({
  components: {
    CodePropertyForm,
  },
})
export default class CodePropertyTree extends Vue {
  @Model() modal?: MiezzModal;
  /**选中的节点 */
  @Model() modelValue: DtoFormCodeProperty[] = [];
  /**表名 */
  @Prop() table?: string;
  /**展示 */
  @Model() show?: string[] = [];

  _show: string[] = [
    "Type",
    "MaxLength",
    "Customize",
    "UiIgnore",
    "Required",
    "PipeBoolean",
    "Regular",
  ];
  keys: string[] = [];
  modalForm = new MiezzModal();
  form = new MiezzForm<DtoFormCodeProperty, string>();

  EnumValidRegularTypeOption = EnumValidRegularTypeOption;
  /**绑定属性 */
  props = {
    value: "Name",
    label: "Summary",
    children: "Properties",
    isLeaf: "IsLeaf",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };
  /**节点 */
  properties: DtoFormCodeProperty[] = [];

  /**初始化 */
  created(): void {
    if (this.modal) {
      this.modal.Title = "引用";
      this.modal.HandleClick = this.handleClick;
      this.modal.Buttons = [
        {
          Code: "submit",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    }

    if (this.show && this.show.length > 0) {
      this._show = this.show;
    }
    this.loopKeys(this.modelValue);
    this.onTableChanged();
  }

  handleTagChange(item: any, prop: string, status: boolean): void {
    item[prop] = status;
  }

  /**监听表名变化 */
  @Watch("table")
  onTableChanged(): void {
    this.properties = [];
    if (this.table) {
      CodeControllerAppService.GetTablePropertiesByName(this.table).then(
        (r) => {
          if (r.data.Data) {
            for (const item of r.data.Data) {
              if (!this._show.contains("Required")) {
                item.Required = undefined;
              }
              if (!this._show.contains("MaxLength")) {
                item.MaxLength = undefined;
              }
              if (!this._show.contains("Regular")) {
                item.Regular = undefined;
              }
            }
            this.properties = r.data.Data;
          }
        }
      );
    }
  }

  handleAdd(): void {
    this.modalForm.Title = "添加属性";
    this.modalForm.Show = true;
    this.form.Code = "add";
    const data = new DtoFormCodeProperty();
    data.IsLeaf = true;
    this.form.Data = data;
  }

  handleAddChildren(node: any): void {
    this.modalForm.Title = "添加子属性";
    this.modalForm.Show = true;
    this.form.Code = "add-children";
    const children = new DtoFormCodeProperty();
    children.IsLeaf = true;
    this.form.Data = children;
    node.loaded = true;
    node.expanded = true;
  }

  handleEdit(data: DtoFormCodeProperty): void {
    this.modalForm.Title = `编辑属性：${data.Name}/${data.Summary}`;
    this.modalForm.Show = true;
    this.form.Code = "edit";
    this.form.Data = data;
  }

  handleDelete(node: any): void {
    const treeRef = this.$refs["treeRef"] as any;
    treeRef.remove(node);
  }

  handleSubmit(): void {
    const treeRef = this.$refs["treeRef"] as any;
    if (this.form.Code == "add") {
      this.form.Data.Id = this.form.Data.Name;
      treeRef.append(this.form.Data, treeRef.root);
      treeRef.setChecked(this.form.Data.Id, true, false);
    } else if (this.form.Code == "add-children") {
      const node = treeRef.getCurrentNode() as DtoFormCodeProperty;
      if (node && node.Properties) {
        this.form.Data.Id = node.Id + "." + this.form.Data.Name;
        treeRef.append(this.form.Data, node);
      }
      treeRef.setChecked(this.form.Data.Id, true, false);
    }
    this.modalForm.Show = false;
    this.handleCheckChange();
  }

  loopKeys(properties: DtoFormCodeProperty[]): void {
    for (const property of properties) {
      if (property.Id) this.keys.push(property.Id);
      if (property.Properties && property.Properties.length > 0) {
        this.loopKeys(property.Properties);
      }
    }
  }

  /**懒加载节点 */
  loadNode(node: any, resolve: (data: DtoFormCodeProperty[]) => void): void {
    const property = node.data as DtoFormCodeProperty;
    if (property.Type && !property.IsLeaf) {
      CodeControllerAppService.GetTablePropertiesByName(
        property.Type,
        property.Id
      ).then((r) => {
        if (r.data.Data) {
          for (const item of r.data.Data) {
            if (!this._show.contains("Required")) {
              item.Required = undefined;
            }
            if (!this._show.contains("MaxLength")) {
              item.MaxLength = undefined;
            }
            if (!this._show.contains("Regular")) {
              item.Regular = undefined;
            }
          }
          return resolve(r.data.Data);
        }
      });
    }
    return resolve([]);
  }

  /**选中节点时 */
  handleCheckChange(): void {
    const treeRef = this.$refs["treeRef"] as any;
    const checkedData: DtoFormCodeProperty[] = [];
    this.loop(treeRef.root.childNodes, checkedData);
    this.$emit("update:modelValue", checkedData);
  }

  /**递归已选中的节点 */
  loop(nodes: any[], properties: DtoFormCodeProperty[]): void {
    for (const node of nodes) {
      const nodeData = node.data as DtoFormCodeProperty;
      if (node.checked) {
        properties.push(nodeData);
      } else if (node.indeterminate) {
        nodeData.Properties = [];
        this.loop(node.childNodes, nodeData.Properties);
        properties.push(nodeData);
      }
    }
  }

  /**按钮点击 */
  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    //
  }
}
