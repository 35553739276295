import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailProduct_Alias from "./DtoDetailProduct_Alias";
import DtoDetailProduct_CustomsCode from "./DtoDetailProduct_CustomsCode";
import UiGroup from "./UiGroup";

/**
 * 产品基础信息详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailProduct extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.IfShelf = false;
        this.StockQuantity = 0;
        this.SalesQuantity = 0;
        this.ReceiptQuantity = 0;
        this.SpecQuantity = 0;
        this.PackageQuantity = 0;
        this.ApplyNumber = 0;
        this.Aliass = [];
        this.CustomsCodes = [];
        this.UiGroups = [{"Code":"ProductDetail","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 自定义编号
     */
    Code?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 拉丁名称
     */
    NameLatin?: string;
    /**
     * 材质
     */
    Material?: string;
    /**
     * 用途
     */
    Purpose?: string;
    /**
     * 海关编码
     */
    HsCode?: string;
    /**
     * 第一法定单位：同步HSCode
     */
    StatutoryFirstUnit?: string;
    /**
     * 第二法定单位：同步HSCode
     */
    StatutorySecondUnit?: string;
    /**
     * 中文计价单位
     */
    ChargeUnithZh?: string;
    /**
     * 英文计价单位
     */
    ChargeUnithEn?: string;
    /**
     * 是否上架
     */
    IfShelf?: boolean;
    /**
     * 库存数量
     */
    StockQuantity?: number;
    /**
     * 销售数量
     */
    SalesQuantity?: number;
    /**
     * 入库数量
     */
    ReceiptQuantity?: number;
    /**
     * 规格数量
     */
    SpecQuantity?: number;
    /**
     * 包装数量
     */
    PackageQuantity?: number;
    /**
     * 申报记录数
     */
    ApplyNumber?: number;
    /**
     * 别名集合
     */
    Aliass?: DtoDetailProduct_Alias[];
    /**
     * 其他国家的海关编码集合
     */
    CustomsCodes?: DtoDetailProduct_CustomsCode[];
    /**
     * 产品图片
     */
    Picture?: string;
    /**
     * 公司主键
     */
    CompanyId?: string;
}

/**
 * 产品基础信息详细-表单校验
 */
export const DtoDetailProductRules = {
}

/**
 * 产品基础信息详细-设置项
 */
export const DtoDetailProductMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "自定义编号",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameLatin",
    Label: "拉丁名称",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Material",
    Label: "材质",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Purpose",
    Label: "用途",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "海关编码",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "StatutoryFirstUnit",
    Label: "第一法定单位：同步HSCode",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "StatutorySecondUnit",
    Label: "第二法定单位：同步HSCode",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ChargeUnithZh",
    Label: "中文计价单位",
    Group: "ProductDetail",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ChargeUnithEn",
    Label: "英文计价单位",
    Group: "ProductDetail",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "IfShelf",
    Label: "是否上架",
    Group: "ProductDetail",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "StockQuantity",
    Label: "库存数量",
    Group: "ProductDetail",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "SalesQuantity",
    Label: "销售数量",
    Group: "ProductDetail",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "ReceiptQuantity",
    Label: "入库数量",
    Group: "ProductDetail",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "SpecQuantity",
    Label: "规格数量",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageQuantity",
    Label: "包装数量",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ApplyNumber",
    Label: "申报记录数",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Aliass",
    Label: "别名集合",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CustomsCodes",
    Label: "其他国家的海关编码集合",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Picture",
    Label: "产品图片",
    Group: "ProductDetail",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "公司主键",
    Type: EnumMiezzFormItemType.Text,
  },
]
