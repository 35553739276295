
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import MiezzButton from "@/models/MiezzButton";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamContainerTypeAppService from "@/apis.machine/ParamContainerTypeAppService";
import DtoFormOrderContainer from "@/models.machine/DtoFormOrderContainer";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoFormOrderContainerSealNumber from "@/models.machine/DtoFormOrderContainerSealNumber";
import CurrentLogier from "@/apis/CurrentLogier";
import { h } from "vue";
import DtoDetailOrderMainBill_ContainerTrailer from "@/models.machine/DtoDetailOrderMainBill_ContainerTrailer";
export default class OrderFormContainer extends Vue {
  @Model() form!: MiezzForm<
    {
      Containers: DtoFormOrderContainer[];
      DeparturePortContainerTrailers?: DtoDetailOrderMainBill_ContainerTrailer[];
    },
    string
  >;
  @Prop() disabledAdd?: boolean;
  @Prop() disabledQuantity?: boolean;
  @Prop() red?: boolean;
  @Prop() containerTypes?: UiSelectOption<string>[];
  @Prop() showSealNumbers?: boolean;
  @Prop() departurePortContainer?: boolean;
  @Prop() orderId?: string;

  rules = {
    ContainerTypeId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
    Quantity: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
  };
  _containerTypes: UiSelectOption<string>[] = [];

  choose?: DtoFormOrderContainer;
  modalSealNumbers = new MiezzModal(MiezzModalType.Dialog);
  formSealNumbers: {
    Data: DtoFormOrderContainerSealNumber[];
  } = {
    Data: [],
  };
  ruleSealNumbers = {};

  created(): void {
    if (this.containerTypes == undefined) {
      ParamContainerTypeAppService.GetSelect().then((r) => {
        this._containerTypes = r.data.Data ?? [];
      });
    } else {
      this._containerTypes = this.containerTypes ?? [];
    }

    this.ruleSealNumbers = {
      ContainerNo: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (
            rule: any,
            value: DtoFormOrderContainerSealNumber,
            callback: any
          ) => {
            if (!value.ContainerNo) {
              return callback(new Error("信息不能为空"));
            } else if (value.ContainerNo.length > 256) {
              return callback(new Error("文本长度不能超过 256 个字符"));
            } else {
              const reg = /^[A-Z]{4}[0-9]{7}$/;
              if (reg.test(value.ContainerNo)) {
                // 箱号正则表达式
                const dic: any = {};
                dic["A"] = 10;
                dic["B"] = 12;
                dic["C"] = 13;
                dic["D"] = 14;
                dic["E"] = 15;
                dic["F"] = 16;
                dic["G"] = 17;
                dic["H"] = 18;
                dic["I"] = 19;
                dic["J"] = 20;
                dic["K"] = 21;
                dic["L"] = 23;
                dic["M"] = 24;
                dic["N"] = 25;
                dic["O"] = 26;
                dic["P"] = 27;
                dic["Q"] = 28;
                dic["R"] = 29;
                dic["S"] = 30;
                dic["T"] = 31;
                dic["U"] = 32;
                dic["V"] = 34;
                dic["W"] = 35;
                dic["X"] = 36;
                dic["Y"] = 37;
                dic["Z"] = 38;
                dic["0"] = 0;
                dic["1"] = 1;
                dic["2"] = 2;
                dic["3"] = 3;
                dic["4"] = 4;
                dic["5"] = 5;
                dic["6"] = 6;
                dic["7"] = 7;
                dic["8"] = 8;
                dic["9"] = 9;
                var number = value.ContainerNo;
                var sum = 0;
                for (var i = 0; i < 10; i++) {
                  var char = number[i];
                  var code = dic[char];
                  sum += code * Math.pow(2, i);
                }
                var result = sum % 11;
                var end = parseInt(number[10]);
                var valid = result == end || result - end == 10;
                if (!valid) {
                  return callback(new Error("请填写正确的箱号信息"));
                } else if (
                  this.formSealNumbers.Data.filter(
                    (it) => it.ContainerNo == value.ContainerNo
                  ).length > 1 ||
                  this.form.Data.Containers.any(
                    (it) =>
                      it.ContainerTypeId != this.choose?.ContainerTypeId &&
                      it.SealNumbers?.any(
                        (s: any) => s.ContainerNo == value.ContainerNo
                      )
                  )
                ) {
                  return callback(new Error("信息重复，请重新填写"));
                } else {
                  return callback();
                }
              } else {
                return callback(new Error("请填写正确的箱号信息"));
              }
            }
          },
          trigger: "blur",
        },
      ],
      SealNo: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ],
    };

    this.modalSealNumbers.Title = "箱封号";
    this.modalSealNumbers.Width = "700px";
    if (!this.departurePortContainer) {
      this.modalSealNumbers.Buttons = [
        {
          Code: "save-seal-numbers",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
      this.modalSealNumbers.HandleClick = this.handleClick;
    }
  }

  handleClickSealNumbers(item: DtoFormOrderContainer): void {
    this.choose = item;
    this.formSealNumbers = {
      Data: [],
    };
    if (
      this.departurePortContainer &&
      (this.choose.DeparturePortContainerSealNumbers?.length ?? 0) == 0
    ) {
      ElMessageBox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "请先至"),
          h(
            "a",
            {
              href: `#/order/departure-port-container/${this.orderId}?backUrl=${this.$route.fullPath}`,
            },
            "《装货港-集装箱拖车》"
          ),
          h("span", null, "中完善相应信息"),
        ]),
      });
    } else {
      if (this.choose.Quantity) {
        for (let i = 0; i < this.choose.Quantity; i++) {
          if (
            this.departurePortContainer &&
            this.choose.DeparturePortContainerSealNumbers &&
            this.choose.DeparturePortContainerSealNumbers.length > i
          ) {
            const element = JSON.copy(
              this.choose.DeparturePortContainerSealNumbers[i]
            );
            this.formSealNumbers.Data.push(element);
          } else if (
            !this.departurePortContainer &&
            this.choose.SealNumbers &&
            this.choose.SealNumbers.length > i
          ) {
            const element = JSON.copy(this.choose.SealNumbers[i]);
            this.formSealNumbers.Data.push(element);
          } else {
            this.formSealNumbers.Data.push({});
          }
        }
      }
      this.modalSealNumbers.Show = true;
    }
  }

  @Watch("containerTypes", { deep: true })
  onContainerTypesChanged(): void {
    if (this.containerTypes && this.containerTypes.length > 0) {
      this._containerTypes = this.containerTypes;
    }
  }

  /**集装箱-箱型-变更时 */
  handleChangeContainerTypeId(item: DtoFormOrderContainer): void {
    this.$nextTick(() => {
      if (
        item.ContainerTypeId &&
        (this.form.Data.Containers?.filter(
          (it: any) => it.ContainerTypeId == item.ContainerTypeId
        ).length ?? 0) > 1
      ) {
        ElMessage.warning("箱型不能重复，请重新选择");
        item.ContainerTypeId = undefined;
        item.ContainerTypeLabel = undefined;
      } else if (this.departurePortContainer) {
        item.DeparturePortContainerSealNumbers =
          this.form.Data.DeparturePortContainerTrailers?.filter(
            (it) => it.ContainerTypeId === item.ContainerTypeId
          );
      }
    });
  }

  /**集装箱-添加按钮 */
  btnAddContainer: MiezzButton = {
    Code: "add-container",
    Icon: "plus",
    Type: "text",
    Size: "small",
  };
  /**集装箱-删除按钮 */
  btnDeleteContainer: MiezzButton = {
    Code: "delete-container",
    Title: "删除",
    Icon: "delete",
    Type: "default",
    Size: "small",
  };
  /**集装箱-删除 */
  handleDeleteContainer(index: number, complete: () => void): void {
    this.form.Data.Containers?.splice(index, 1);
    complete();
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //集装箱-添加
      case "add-container":
        this.form.Data.Containers?.push({});
        complete();
        break;
      case "save-seal-numbers":
        (this.$refs["ref-form-seal-numbers"] as any).validate((v: boolean) => {
          if (v) {
            if (this.choose) {
              if (this.departurePortContainer) {
                this.choose.SealNumbers = [];
              } else {
                this.choose.SealNumbers = this.formSealNumbers.Data.copy();
              }
            }
            this.modalSealNumbers.Show = false;
            complete();
          } else {
            setTimeout(() => {
              const error = document.getElementsByClassName(
                "ref-form-seal-numbers is-error"
              );
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          }
        });
        complete();
        break;
      default:
        break;
    }
  }
}
