
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoFormCompany, {
  DtoFormCompanyMiezzFormItems,
} from "@/models.machine/DtoFormCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyForm from "../../CompanyForm.vue";
@Options({
  components: {
    CompanyForm,
  },
})
export default class SupplierCompanyEdit extends Vue {
  type = EnumCompanyType.Supplier;
  form = new MiezzForm<DtoFormCompany, string>(
    "SupplierManagement_Company_Edit"
  );
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    this.form.Items = DtoFormCompanyMiezzFormItems.copy().filter(
      (it) => it.Prop != "ServiceProviderCompanyId" && it.Prop != "SalesmanId" && it.Prop != "Source"
    );
    for (const item of this.form.Items) {
      if (item.Prop == "JoinCompanyTypes") {
        item.Multiple = true;
      }
    }
    CompanyTypeAppService.GetSelect({ type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });
  }
}
