import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyBusinessCardArea, EnumCompanyBusinessCardAreaOption } from "./EnumCompanyBusinessCardArea";
import { EnumCompanyBusinessCardType, EnumCompanyBusinessCardTypeOption } from "./EnumCompanyBusinessCardType";
import UiGroup from "./UiGroup";

/**
 * 公司名片表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyBusinessCard extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 地区
     */
    Area?: EnumCompanyBusinessCardArea;
    /**
     * 类型
     */
    Type?: EnumCompanyBusinessCardType;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 公司中文名称
     */
    NameZh?: string;
    /**
     * 公司英文名称
     */
    NameEn?: string;
    /**
     * 企业代码类型
     */
    ParamCompanyCodeTypeId?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 所属国海关编码
     */
    CustomsCode?: string;
    /**
     * AEO编码
     */
    AeoCode?: string;
    /**
     * 英文收件地址
     */
    ReceivingAddressEn?: string;
    /**
     * 电话
     */
    Tel?: string;
    /**
     * 传真
     */
    Fax?: string;
    /**
     * 邮箱
     */
    Email?: string;
    /**
     * 联系人
     */
    Contact?: string;
    /**
     * 联系方式
     */
    ContactNumber?: string;
    /**
     * 内容
     */
    Content?: string;
}

/**
 * 公司名片表单-表单校验
 */
export const DtoFormCompanyBusinessCardRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    CustomsCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    AeoCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ReceivingAddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Tel: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Fax: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Email: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Contact: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ContactNumber: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Content: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 公司名片表单-设置项
 */
export const DtoFormCompanyBusinessCardMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "公司中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NameEn",
    Label: "公司英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ParamCompanyCodeTypeId",
    Label: "企业代码类型",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CustomsCode",
    Label: "所属国海关编码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AeoCode",
    Label: "AEO编码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ReceivingAddressEn",
    Label: "英文收件地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Tel",
    Label: "电话",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Fax",
    Label: "传真",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Email",
    Label: "邮箱",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Contact",
    Label: "联系人",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ContactNumber",
    Label: "联系方式",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Content",
    Label: "内容",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
