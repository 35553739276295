
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyCustomerMainRelationAppService from "@/apis.machine/CompanyCustomerMainRelationAppService";
import DtoPageListSearchCompanyCustomerMainRelationAudit, {
  DtoPageListSearchCompanyCustomerMainRelationAuditDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyCustomerMainRelationAudit";
import DtoPageListCompanyCustomerMainRelationAudit from "@/models.machine/DtoPageListCompanyCustomerMainRelationAudit";
import DtoListItemCompanyCustomerMainRelationAudit, {
  DtoListItemCompanyCustomerMainRelationAuditMiezzListItems,
} from "@/models.machine/DtoListItemCompanyCustomerMainRelationAudit";
import MiezzForm from "@/models/MiezzForm";
import DtoRequestCompanyCustomerMainRelationAudit, {
  DtoRequestCompanyCustomerMainRelationAuditMiezzFormItems,
  DtoRequestCompanyCustomerMainRelationAuditRules,
} from "@/models.machine/DtoRequestCompanyCustomerMainRelationAudit";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";

export default class CustomerCompanyAuditMainRelationPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyCustomerMainRelationAudit,
    DtoListItemCompanyCustomerMainRelationAudit,
    string
  >("CustomerManagement_MainRelation");
  /**当前Id */
  id?: string;
  /**表单模态框 */
  modalForm = new MiezzModal();
  form = new MiezzForm<DtoRequestCompanyCustomerMainRelationAudit, string>();
  companies?: DtoSelectOptionCompany[] = [];

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyCustomerMainRelationAudit();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyCustomerMainRelationAuditDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyCustomerMainRelationAudit();
    //列表-列配置
    this.list.Items = DtoListItemCompanyCustomerMainRelationAuditMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "MainCompany")
        return `${row.MainCompany?.NameZh ?? "暂无数据"}/${
          row.MainCompany?.NameEn ?? "暂无数据"
        }`;
      if (item.Prop == "ServiceProviderCompany")
        return `${row.ServiceProviderCompany?.NameZh ?? "暂无数据"}/${
          row.ServiceProviderCompany?.NameEn ?? "暂无数据"
        }`;
      if (item.Prop == "ChildrenCompany")
        return row.ChildrenCompany
          ? `${row.ChildrenCompany?.NameZh ?? "暂无数据"}/${
              row.ChildrenCompany?.NameEn ?? "暂无数据"
            }`
          : "暂无数据";
      return undefined;
    };
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "audit",
        Title: "审核",
        Icon: "more",
        Type: "default",
        Size: "small",
      },
    ];

    this.form.Rules = DtoRequestCompanyCustomerMainRelationAuditRules;
    this.form.Items = DtoRequestCompanyCustomerMainRelationAuditMiezzFormItems;

    this.modalForm.HandleClick = this.handleClick;
    this.modalForm.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Customer }).then(
      (r) => {
        if (r.data.Data) this.companies = r.data.Data;
      }
    );
  }

  /**查询列表 */
  handleGet(): void {
    CompanyCustomerMainRelationAppService.GetAuditPageList(this.list.Dto).then(
      (r) => {
        this.list.Data = r.data.Data;
      }
    );
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyCustomerMainRelationAudit,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "audit":
        this.form.Data = new DtoRequestCompanyCustomerMainRelationAudit();
        this.form.Data.Id = row.Id;
        this.modalForm.Show = true;
        complete();
        break;
      default:
        break;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyCustomerMainRelationAppService.Audit(this.form.Data)
              .then(() => {
                this.modalForm.Show = false;
                this.handleGet();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }
}
