import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62b3c17c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        class: "company-name",
        title: _ctx.logier?.CompanyNameZh
      }, _toDisplayString(_ctx.logier?.CompanyNameZh), 9, _hoisted_1),
      _createVNode(_component_el_button, {
        class: "show-companies",
        type: "text",
        onClick: _ctx.handleShowCompanies
      }, {
        default: _withCtx(() => [
          _createTextVNode("[切换]")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modal) = $event)),
      style: {"line-height":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tabs, {
          modelValue: _ctx.type,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.type) = $event)),
          type: "card",
          "tab-position": "top",
          onTabClick: _ctx.handleTabClick
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item, i) => {
              return (_openBlock(), _createBlock(_component_el_tab_pane, {
                label: item.Label,
                name: item.Value,
                key: i
              }, null, 8, ["label", "name"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onTabClick"]),
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
          onHandleRowButtonClick: _ctx.handleRowButtonClick
        }, null, 8, ["modelValue", "onHandleRowButtonClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}