
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ContactTransportModeAppService from "@/apis.machine/ContactTransportModeAppService";
import DtoFormContactTransportMode, {
  DtoFormContactTransportModeMiezzFormItems,
  DtoFormContactTransportModeRules,
} from "@/models.machine/DtoFormContactTransportMode";

export default class ContactTransportModeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormContactTransportMode, string>();
  optFullName?: Array<string> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormContactTransportMode();
    //表单-校验
    this.form.Rules = DtoFormContactTransportModeRules;
    //表单-元素配置
    this.form.Items = DtoFormContactTransportModeMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      ContactTransportModeAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ContactTransportModeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    if (this.form.Data.Name) {
      this.optFullName?.push(this.form.Data.Name);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurName(): void {
    if (this.form.Data.Name) {
      this.form.Data.Name = this.form.Data.Name.toUpperCase();
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
