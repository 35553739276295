import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit";
import UiGroup from "./UiGroup";

/**
 * 报价表详细-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheet_PriceQuotationSheetItem extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 费用Id
     */
    CostId?: string;
    /**
     * 费用名称
     */
    Cost?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamPriceCost;
    /**
     * 币制Id
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamCurrencySystem;
    /**
     * 单价
     */
    Amount?: number;
    /**
     * 单价
     */
    AmountLabel?: string;
    /**
     * 第一计价单位Id
     */
    FirstChargingUnitId?: string;
    /**
     * 计价单位
     */
    FirstChargingUnit?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit;
    /**
     * 数量
     */
    FirstChargingNumber?: number;
    /**
     * 第二计价单位Id
     */
    SecondChargingUnitId?: string;
    /**
     * 第二计价单位
     */
    SecondChargingUnit?: DtoDetailPriceQuotationSheet_PriceQuotationSheetItem_ParamTransportChargeUnit;
    /**
     * 第二计价数量
     */
    SecondChargingNumber?: number;
    /**
     * 合计
     */
    Total?: number;
    /**
     * 说明
     */
    Description?: string;
}

/**
 * 报价表详细-项-表单校验
 */
export const DtoDetailPriceQuotationSheet_PriceQuotationSheetItemRules = {
}

/**
 * 报价表详细-项-设置项
 */
export const DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Cost",
    Label: "费用名称",
  },
  {
    Prop: "CurrencySystem",
    Label: "币制",
    MaxWidth: "120px",
  },
  {
    Prop: "AmountLabel",
    Label: "单价",
    MaxWidth: "120px",
  },
  {
    Prop: "FirstChargingUnit",
    Label: "计价单位",
    MaxWidth: "120px",
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    MaxWidth: "120px",
  },
  {
    Prop: "Total",
    Label: "合计",
    MaxWidth: "120px",
  },
  {
    Prop: "Description",
    Label: "说明",
  },
]

/**
 * 报价表详细-项-设置项
 */
export const DtoDetailPriceQuotationSheet_PriceQuotationSheetItemMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Cost",
    Label: "费用名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CurrencySystem",
    Label: "币制",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "AmountLabel",
    Label: "单价",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FirstChargingUnit",
    Label: "计价单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Total",
    Label: "合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Description",
    Label: "说明",
    Type: EnumMiezzFormItemType.Text,
  },
]
