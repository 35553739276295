
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormCompanyCodeRule, {
  DtoFormCompanyCodeRuleMiezzFormItems,
  DtoFormCompanyCodeRuleRules,
} from "@/models.machine/DtoFormCompanyCodeRule";
import DtoFormCompanyCodeRule_CompanyCodeRule, {
  DtoFormCompanyCodeRule_CompanyCodeRuleMiezzListItems,
} from "@/models.machine/DtoFormCompanyCodeRule_CompanyCodeRule";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CurrentCompanyCodeRuleForm extends Vue {
  form = new MiezzForm<DtoFormCompanyCodeRule, string>();
  id?: string;
  list = new MiezzPageList<
    any,
    DtoFormCompanyCodeRule_CompanyCodeRule,
    string
  >();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      MiezzIcon: "back",
      Label: "返回",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "text",
      Size: "small",
    },
  ];
  rules = {
    Code: [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (!value) return callback(new Error("信息不能为空"));
          else if (value.length > 64)
            return callback(new Error("文本长度不能超过 64 个字符"));
          else {
            if (
              (this.form.Data.CodeRules?.filter((it) => it.Code == value)
                .length ?? 0) > 1
            ) {
              return callback(new Error("信息重复，请重新填写"));
            }
            return callback();
          }
        },
        trigger: "blur",
      },
    ],
  };

  created(): void {
    this.id = this.$route.params.id as string;
    this.form.Data = new DtoFormCompanyCodeRule();
    this.form.Items = JSON.copy(DtoFormCompanyCodeRuleMiezzFormItems);
    this.form.Rules = DtoFormCompanyCodeRuleRules;
    this.form.Rules.NameEnAbbr = [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (value) {
            if (value.length > 64)
              return callback(new Error("文本长度不能超过 64 个字符"));
            else {
              CompanyAppService.CheckCompanyName({
                CompanyId: this.form.Data.Id,
                NameEnAbbr: this.form.Data.NameEnAbbr,
              }).then((r) => {
                if (r.data.Data) {
                  return callback(
                    new Error("该缩写已有服务商使用，请重新填写")
                  );
                }
                return callback();
              });
            }
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items = DtoFormCompanyCodeRule_CompanyCodeRuleMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };

    this.handleGet();
  }

  handleGet(): void {
    CompanyAppService.GetCodeRuleById(this.id).then((r) => {
      if (r.data.Data) {
        this.form.Data = r.data.Data;
        if (this.list.Data) this.list.Data.Items = r.data.Data.CodeRules;
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.PostCodeRule(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
