import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'ParamCountryId')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "国家",
            prop: "ParamCountryId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data.ParamCountryId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.ParamCountryId) = $event)),
                filterable: "",
                clearable: "",
                placeholder: "请选择",
                onChange: _ctx.onChangeCountry
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterCountry, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: item.Label,
                      value: item.Value
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onChange"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaLevel')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "地区级别",
            prop: "AreaLevel"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data.AreaLevel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.AreaLevel) = $event)),
                filterable: "",
                clearable: "",
                disabled: _ctx.form.Data.ParamCountryId ? false : true,
                placeholder: _ctx.form.Data.ParamCountryId ? _ctx.optCountryAreaNames?.length > 0 ? '请选择' : '当前国家未设定地区级别，请至参数《国家》中设定' : '请先选择国家',
                onChange: _ctx.onChangeAreaLevel
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optCountryAreaNames, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      label: item,
                      value: item
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "disabled", "placeholder", "onChange"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'NameZh')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "中文名称",
            prop: "NameZh"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                onHandleBlur: _ctx.onBlurNameZh,
                disabled: _ctx.form.Data.AreaLevel ? false : true,
                placeholder: _ctx.form.Data.AreaLevel ? '请输入中文名称' : '请先选择地区级别',
                pattern: '[\u4e00-\u9fa5a-zA-Z（）()]'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "disabled", "placeholder", "pattern"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'NameEn')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "英文名称",
            prop: "NameEn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                onHandleBlur: _ctx.onBlurNameEn,
                disabled: _ctx.form.Data.AreaLevel ? false : true,
                placeholder: _ctx.form.Data.AreaLevel ? '请输入英文名称' : '请先选择地区级别',
                trim: false,
                pattern: '[^\u4e00-\u9fa5]'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "disabled", "placeholder"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'Abridge')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "缩写",
            prop: "Abridge"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入缩写",
                onHandleBlur: _ctx.onBlurAbridge
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "编号",
            prop: "Code"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入编号",
                onHandleBlur: _ctx.onBlur
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'PostalCode')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 6,
            label: "邮编",
            prop: "PostalCode"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入邮编",
                onHandleBlur: _ctx.onBlur
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaId1' && _ctx.form.Data.Deep > 1)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 7,
            label: _ctx.optCountryAreaNames[0],
            prop: "AreaId1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: `请选择${_ctx.optCountryAreaNames[0]}`,
                clearable: "",
                filterable: "",
                onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus(1))),
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange(1))),
                class: "form-item-select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optProvince, (p) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: p.Id,
                      label: p.Label,
                      value: p.Id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
            ]),
            _: 2
          }, 1032, ["label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaId2' && _ctx.form.Data.Deep > 2)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 8,
            label: _ctx.optCountryAreaNames[1],
            prop: "AreaId2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: `请选择${_ctx.optCountryAreaNames[1]}`,
                clearable: "",
                filterable: "",
                onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onFocus(2))),
                onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChange(2))),
                class: "form-item-select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optCity, (p) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: p.Id,
                      label: p.Label,
                      value: p.Id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
            ]),
            _: 2
          }, 1032, ["label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaId3' && _ctx.form.Data.Deep > 3)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            label: _ctx.optCountryAreaNames[2],
            prop: "AreaId3",
            key: _ctx.random
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: `请选择${_ctx.optCountryAreaNames[2]}`,
                clearable: "",
                filterable: "",
                onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFocus(3))),
                onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onChange(3))),
                class: "form-item-select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optDistrict, (p) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: p.Id,
                      label: p.Label,
                      value: p.Id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
            ]),
            _: 2
          }, 1032, ["label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaId4' && _ctx.form.Data.Deep > 4)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            label: _ctx.optCountryAreaNames[3],
            prop: "AreaId4",
            key: _ctx.random
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: `请选择${_ctx.optCountryAreaNames[3]}`,
                clearable: "",
                filterable: "",
                onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onFocus(4))),
                onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onChange(4))),
                class: "form-item-select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optStreet, (p) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: p.Id,
                      label: p.Label,
                      value: p.Id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder"])
            ]),
            _: 2
          }, 1032, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}