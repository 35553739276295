
import AuthAppService from "@/apis.machine/AuthAppService";
import SysAppService from "@/apis.machine/SysAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoCallbackAuthCheckUser from "@/models.machine/DtoCallbackAuthCheckUser";
import DtoFromLogin, {
  DtoFromLoginMiezzFormItems,
  DtoFromLoginRules,
} from "@/models.machine/DtoFromLogin";
import { EnumUserStatus } from "@/models.machine/EnumUserStatus";
import Logier from "@/models.machine/Logier";
import WebOptions from "@/models.machine/WebOptions";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class LoginPassword extends Vue {
  form = new MiezzForm<DtoFromLogin, string>();
  check: DtoCallbackAuthCheckUser | null | undefined = null;
  buttons: MiezzButton[] = [
    {
      Code: "password-login",
      Label: "登录",
      Type: "primary",
    },
    {
      Code: "forget-password",
      Label: "忘记密码",
      Type: "default",
    },
  ];
  btnSmsLogin: MiezzButton = {
    Code: "sms-login",
    Label: "短信登录",
    Type: "text",
    Size: "small",
  };
  btnRegister: MiezzButton = {
    Code: "register",
    Label: "注册公司",
    Type: "text",
    Size: "small",
  };
  web?: WebOptions = {};

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFromLogin();
    //表单-校验
    this.form.Rules = DtoFromLoginRules;
    this.form.Rules.Account[1] = this.getRuleAccount();
    //表单-元素配置
    this.form.Items = DtoFromLoginMiezzFormItems;

    SysAppService.GetWebInfo().then((r) => {
      this.web = r.data.Data;
    });
  }

  getRuleAccount(): any {
    return {
      validator: (rule: any, value: string, callback: any) => {
        value = this.form.Data.Account as string;
        if (value) {
          if (value.startsWith("YQM") && value.length == 19) {
            this.$router.push({
              path: "/login/sms",
              query: {
                yqm: value,
              },
            });
            callback();
          } else if (!/^[0-9A-Za-z]{4,32}$/.test(value)) {
            this.check = undefined;
            return callback(new Error("请录入正确的手机号或邀请码"));
          } else {
            AuthAppService.CheckUserByAccount(value)
              .then((r) => {
                const check = r.data.Data;
                if (!check) return callback(new Error("账号不存在"));
                if (check.Status == EnumUserStatus.Disabled)
                  return callback(new Error("您的账号已禁用，无法登陆"));
                if (check.CompanyIds?.length == 0)
                  return callback(new Error("您的账号未入驻公司，无法登陆"));
                this.check = r.data.Data;
                if (!check.AuthPhoneNumber || !check.SetPassword) {
                  this.$router.push({
                    path: "/login/sms",
                    query: {
                      phoneNumber: check.PhoneNumber,
                    },
                  });
                  callback();
                }
                callback();
              })
              .catch(() => callback());
          }
        } else {
          this.check = undefined;
          callback();
        }
      },
    };
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    let phoneNumber: string | undefined;
    switch (model.Code) {
      case "password-login":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            AuthAppService.PasswordLogin(this.form.Data)
              .then(async (r) => {
                this.login(r.data.Data);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "sms-login":
        this.$router.push("/login/sms");
        break;
      case "forget-password":
        if (
          this.form.Data.Account &&
          /^1[3456789][0-9]{9}$/.test(this.form.Data.Account)
        )
          phoneNumber = this.form.Data.Account;
        this.$router.push({
          path: "/login/forget-password",
          query: {
            phoneNumber: phoneNumber,
          },
        });
        break;
      case "register":
        this.$router.push("/register");
        break;
      default:
        break;
    }
  }

  async login(logier?: Logier): Promise<void> {
    if (logier) {
      CurrentLogier.SetLogier(logier);
      CurrentLogier.SetBreadcrumbs([]);
      await CurrentLogier.SetMenus();
      this.$router.push("/");
    }
  }
}
