
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import DtoFormCompanyContact, {
  DtoFormCompanyContactMiezzFormItems,
  DtoFormCompanyContactRules,
} from "@/models.machine/DtoFormCompanyContact";
import { EnumContactTypeOption } from "@/models.machine/EnumContactType";
import CurrentLogier from "@/apis/CurrentLogier";

export default class CompanyContactForm extends Vue {
  @Prop() form!: MiezzForm<DtoFormCompanyContact, string>;
  @Prop() path!: string;

  buttons: MiezzButton[] = [];
  id?: string;
  companyId?: string;
  EnumContactTypeOption = EnumContactTypeOption;
  btnAddContact: MiezzButton = {
    Code: "add-contact",
    Label: "联系方式",
    Icon: "plus",
    Type: "text",
    Size: "small",
  };

  created(): void {
    this.id = this.$route.params.cid as string;
    this.companyId = this.$route.params.id as string;

    this.form.DefaultSpan = 12;
    //表单-数据-初始化
    this.form.Data = new DtoFormCompanyContact();
    this.form.Data.CompanyId = this.companyId;
    //表单-校验
    this.form.Rules = DtoFormCompanyContactRules;
    //表单-元素配置
    this.form.Items = DtoFormCompanyContactMiezzFormItems;

    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "submit",
        Label: "保存",
        Icon: "select",
        Type: "text",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      CompanyContactAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-contact":
        this.form.Data.Numbers?.push({});
        complete();
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            this.form.Data.Numbers = this.form.Data.Numbers?.filter(
              (it) => it.Type || it.Content || it.Remark
            );
            CompanyContactAppService.Post(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }

  handleDeleteNumber(index: number): void {
    this.form.Data.Numbers?.splice(index, 1);
  }
}
