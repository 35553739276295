import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemProductSpecElement_ProductSpecElementItem from "./DtoListItemProductSpecElement_ProductSpecElementItem";

/**
 * 整个产品的规格要素分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemProductSpecElement extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsSelected = false;
        this.ProductSpecElementItems = [];
    }

    [proName: string]: any;
    /**
     * 是否选中
     */
    IsSelected?: boolean;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 要素名称
     */
    Name?: string;
    /**
     * 子要素
     */
    ProductSpecElementItems?: DtoListItemProductSpecElement_ProductSpecElementItem[];
}

/**
 * 整个产品的规格要素分页列表-项-表单校验
 */
export const DtoListItemProductSpecElementRules = {
}

/**
 * 整个产品的规格要素分页列表-项-设置项
 */
export const DtoListItemProductSpecElementMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "IsSelected",
    Label: "是否选中",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "要素名称",
  },
  {
    Prop: "ProductSpecElementItems",
    Label: "子要素",
    Customize: true,
  },
]
