import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  style: {"color":"#67C23A"}
}
const _hoisted_4 = {
  key: 1,
  style: {"color":"#E6A23C"}
}
const _hoisted_5 = {
  key: 2,
  style: {"color":"#F56C6C"}
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = {
  key: 0,
  style: {"color":"#409EFF"}
}
const _hoisted_8 = {
  key: 1,
  style: {"color":"#67C23A"}
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = {
  key: 0,
  style: {"color":"#67C23A"}
}
const _hoisted_11 = {
  key: 1,
  style: {"color":"#F56C6C"}
}
const _hoisted_12 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    modelValue: _ctx.detail,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'ProjectSite')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "服务商",
            prop: "ProjectSite"
          }, {
            default: _withCtx(() => [
              (_ctx.detail.Data.ProjectSite)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.detail.Data.ProjectSite.Name), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_2, " -- "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'Content')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "发起内容"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table, {
                data: _ctx.tableData,
                border: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "Name",
                    label: "字段",
                    width: "150"
                  }),
                  (_ctx.detail.Data.CrudStatus != 0)
                    ? (_openBlock(), _createBlock(_component_el_table_column, {
                        key: 0,
                        prop: "OldValue",
                        label: "原数据"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.detail.Data.CrudStatus != 2)
                    ? (_openBlock(), _createBlock(_component_el_table_column, {
                        key: 1,
                        prop: "NewValue",
                        label: "新数据"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'CrudStatus')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "服务商动作",
            prop: "CrudStatus"
          }, {
            default: _withCtx(() => [
              (_ctx.detail.Data.CrudStatus == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " 新增 "))
                : (_ctx.detail.Data.CrudStatus == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, " 编辑 "))
                  : (_ctx.detail.Data.CrudStatus == 2)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, " 删除 "))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, " -- "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'Status')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "审核状态",
            prop: "Status"
          }, {
            default: _withCtx(() => [
              (_ctx.detail.Data.Status == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, " 待处理 "))
                : (_ctx.detail.Data.Status == 10)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " 已处理 "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, " -- "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'AuditResult')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "审核结果",
            prop: "AuditResult"
          }, {
            default: _withCtx(() => [
              (_ctx.detail.Data.AuditResult == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, " 通过 "))
                : (_ctx.detail.Data.AuditResult == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " 拒绝 "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, " -- "))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'Remark')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "备注",
            prop: "Remark"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.detail.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.detail.Data[item.Prop]) = $event),
                placeholder: "请输入备注",
                type: 'textarea',
                rows: 5,
                disabled: _ctx.isDisabled
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}