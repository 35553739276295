import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCustomsType, EnumCustomsTypeOption } from "./EnumCustomsType";
import DtoDetailOrderDepartureCustoms_CompanyBusinessCard from "./DtoDetailOrderDepartureCustoms_CompanyBusinessCard";
import DtoDetailOrderDepartureCustoms_CompanyBusinessCardBank from "./DtoDetailOrderDepartureCustoms_CompanyBusinessCardBank";
import DtoDetailOrderDepartureCustoms_ParamPort from "./DtoDetailOrderDepartureCustoms_ParamPort";
import DtoDetailOrderDepartureCustoms_ContactTransportMode from "./DtoDetailOrderDepartureCustoms_ContactTransportMode";
import DtoDetailOrderDepartureCustoms_TradeTerms from "./DtoDetailOrderDepartureCustoms_TradeTerms";
import DtoDetailOrderDepartureCustoms_PaymentTerms from "./DtoDetailOrderDepartureCustoms_PaymentTerms";
import DtoDetailOrderDepartureCustoms_Customhouse from "./DtoDetailOrderDepartureCustoms_Customhouse";
import DtoDetailOrderDepartureCustoms_TransportMode from "./DtoDetailOrderDepartureCustoms_TransportMode";
import DtoDetailOrderDepartureCustoms_SupervisionMode from "./DtoDetailOrderDepartureCustoms_SupervisionMode";
import DtoDetailOrderDepartureCustoms_ExemptionNature from "./DtoDetailOrderDepartureCustoms_ExemptionNature";
import DtoDetailOrderDepartureCustoms_ParamCountry from "./DtoDetailOrderDepartureCustoms_ParamCountry";
import DtoDetailOrderDepartureCustoms_TransactionMode from "./DtoDetailOrderDepartureCustoms_TransactionMode";
import DtoDetailOrderDepartureCustoms_ParamCurrencySystem from "./DtoDetailOrderDepartureCustoms_ParamCurrencySystem";
import DtoDetailOrderDepartureCustoms_OrderDepartureCustomsAnnex from "./DtoDetailOrderDepartureCustoms_OrderDepartureCustomsAnnex";
import DtoDetailOrderDepartureCustoms_OrderDepartureCustomsProduct from "./DtoDetailOrderDepartureCustoms_OrderDepartureCustomsProduct";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 单证详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderDepartureCustoms extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.OrderDepartureCustomsAnnexs = [];
        this.OrderDepartureCustomsProducts = [];
        this.Service = EnumOrderServiceType.DeparturePort;
        this.IsImportCustomsProduct = false;
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCustomsType;
    /**
     * 订单编号
     */
    OrderNumber?: string;
    /**
     * 订单日期
     */
    OrderDate?: Date;
    /**
     * 发票号码
     */
    InvoiceNumber?: string;
    /**
     * 发票日期
     */
    InvoiceDate?: Date;
    /**
     * 发货人
     */
    FirstConsignorId?: string;
    /**
     * 发货人
     */
    FirstConsignor?: DtoDetailOrderDepartureCustoms_CompanyBusinessCard;
    /**
     * 收货人
     */
    FirstConsigneeId?: string;
    /**
     * 收货人
     */
    FirstConsignee?: DtoDetailOrderDepartureCustoms_CompanyBusinessCard;
    /**
     * Sellers
     */
    SellerId?: string;
    /**
     * Sellers
     */
    SellerContent?: string;
    /**
     * Sellers
     */
    Seller?: DtoDetailOrderDepartureCustoms_CompanyBusinessCard;
    /**
     * Buyers
     */
    BuyerId?: string;
    /**
     * Buyers
     */
    BuyerContent?: string;
    /**
     * Buyers
     */
    Buyer?: DtoDetailOrderDepartureCustoms_CompanyBusinessCard;
    /**
     * 收款银行
     */
    CollectionBankId?: string;
    /**
     * 收款银行
     */
    CollectionBank?: DtoDetailOrderDepartureCustoms_CompanyBusinessCardBank;
    /**
     * 付款银行
     */
    PaymentBankId?: string;
    /**
     * 付款银行
     */
    PaymentBank?: DtoDetailOrderDepartureCustoms_CompanyBusinessCardBank;
    /**
     * 银行信息
     */
    CollectionBankContent?: string;
    /**
     * 银行信息
     */
    PaymentBankContent?: string;
    /**
     * 装货港
     */
    DispatchPortId?: string;
    /**
     * 装货港
     */
    DispatchPort?: DtoDetailOrderDepartureCustoms_ParamPort;
    /**
     * 目的港
     */
    DestinationPortId?: string;
    /**
     * 目的港
     */
    DestinationPort?: DtoDetailOrderDepartureCustoms_ParamPort;
    /**
     * 运输方式
     */
    ContactTransportModeId?: string;
    /**
     * 运输方式
     */
    ContactTransportMode?: DtoDetailOrderDepartureCustoms_ContactTransportMode;
    /**
     * 最终交货地址
     */
    Address?: string;
    /**
     * 贸易条款
     */
    TradeTermsId?: string;
    /**
     * 贸易条款
     */
    TradeTerms?: DtoDetailOrderDepartureCustoms_TradeTerms;
    /**
     * 交期要求
     */
    DeliveryRequirement?: string;
    /**
     * 付款条款
     */
    PaymentTermsId?: string;
    /**
     * 付款条款
     */
    PaymentTerms?: DtoDetailOrderDepartureCustoms_PaymentTerms;
    /**
     * 付款条款内容
     */
    PaymentTermsContentId?: string;
    /**
     * 付款条款内容
     */
    PaymentTermsContent?: string;
    /**
     * 信用证号码
     */
    CreditNumber?: string;
    /**
     * 有效期
     */
    OpenCertificateDays?: number;
    /**
     * 开证日期
     */
    IssuingDate?: string;
    /**
     * 唛头
     */
    ShippingMarks?: string;
    /**
     * 境内发货人
     */
    ConsignorCode?: string;
    /**
     * 境内发货人
     */
    ConsignorCompanyName?: string;
    /**
     * 申报地海关
     */
    CustomhouseId?: string;
    /**
     * 申报地海关
     */
    CustomhouseName?: string;
    /**
     * 申报地海关
     */
    Customhouse?: DtoDetailOrderDepartureCustoms_Customhouse;
    /**
     * 备案号
     */
    RecordNumber?: string;
    /**
     * 境外收货人
     */
    ConsigneeCode?: string;
    /**
     * 境外收货人
     */
    ConsigneeCompanyName?: string;
    /**
     * 运输方式
     */
    TransportModeId?: string;
    /**
     * 运输方式
     */
    TransportMode?: DtoDetailOrderDepartureCustoms_TransportMode;
    /**
     * 提运单号
     */
    BLNO?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleCompanyName?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleCode?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleId?: string;
    /**
     * 生产销售单位
     */
    ProductionSale?: DtoDetailOrderDepartureCustoms_CompanyBusinessCard;
    /**
     * 监管方式
     */
    SupervisionModeId?: string;
    /**
     * 监管方式
     */
    SupervisionMode?: DtoDetailOrderDepartureCustoms_SupervisionMode;
    /**
     * 征免性质
     */
    ExemptionNatureId?: string;
    /**
     * 征免性质
     */
    ExemptionNature?: DtoDetailOrderDepartureCustoms_ExemptionNature;
    /**
     * 许可证号
     */
    PermitNumber?: string;
    /**
     * 合同协议号
     */
    ContractNumber?: string;
    /**
     * 贸易国
     */
    TradingCountryId?: string;
    /**
     * 贸易国
     */
    TradingCountry?: DtoDetailOrderDepartureCustoms_ParamCountry;
    /**
     * 运抵国
     */
    ArrivalCountryId?: string;
    /**
     * 运抵国
     */
    ArrivalCountry?: DtoDetailOrderDepartureCustoms_ParamCountry;
    /**
     * 指运港
     */
    PortOfDestination?: string;
    /**
     * 包装种类
     */
    PackName?: string;
    /**
     * 其他包装
     */
    OtherPackName?: string;
    /**
     * 总的件数
     */
    ProductNumber?: number;
    /**
     * 总的毛重
     */
    ProductGrossWeight?: number;
    /**
     * 总的净重
     */
    ProductNetWeight?: number;
    /**
     * 总的体积
     */
    ProductVolume?: number;
    /**
     * 总的货值
     */
    ProductPrice?: number;
    /**
     * 成交方式Id
     */
    TransactionModeId?: string;
    /**
     * 成交方式
     */
    TransactionMode?: DtoDetailOrderDepartureCustoms_TransactionMode;
    /**
     * 运费
     */
    Freight?: number;
    /**
     * 运费币制
     */
    FreightCurrencySystemId?: string;
    /**
     * 运费币制
     */
    FreightCurrencySystem?: DtoDetailOrderDepartureCustoms_ParamCurrencySystem;
    /**
     * 保费
     */
    Premium?: number;
    /**
     * 保费币制
     */
    PremiumCurrencySystemId?: string;
    /**
     * 保费币制
     */
    PremiumCurrencySystem?: DtoDetailOrderDepartureCustoms_ParamCurrencySystem;
    /**
     * 杂费
     */
    Incidental?: number;
    /**
     * 杂费币制
     */
    IncidentalCurrencySystemId?: string;
    /**
     * 杂费币制
     */
    IncidentalCurrencySystem?: DtoDetailOrderDepartureCustoms_ParamCurrencySystem;
    /**
     * 随附单证
     */
    AttachedCode?: string;
    /**
     * 电子底账数据号码
     */
    ElectronicAccountDataNumber?: string;
    /**
     * 唛头
     */
    CustomsShippingMark?: string;
    /**
     * 附件
     */
    OrderDepartureCustomsAnnexs?: DtoDetailOrderDepartureCustoms_OrderDepartureCustomsAnnex[];
    /**
     * 产品
     */
    OrderDepartureCustomsProducts?: DtoDetailOrderDepartureCustoms_OrderDepartureCustomsProduct[];
    /**
     * 创建人Id
     */
    CreatedUserId?: string;
    /**
     * 是否暂存
     */
    IsTemporary?: boolean;
    /**
     * 经停港
     */
    StopPortId?: string;
    /**
     * 经停港
     */
    StopPort?: DtoDetailOrderDepartureCustoms_ParamPort;
    /**
     * 启运国
     */
    DepartureCountryId?: string;
    /**
     * 启运国
     */
    DepartureCountry?: DtoDetailOrderDepartureCustoms_ParamCountry;
    /**
     * 启运港
     */
    DeparturePort?: string;
    /**
     * 入境口岸
     */
    EntryPort?: string;
    /**
     * 货物存放地点
     */
    GoodsAddress?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 服务
     */
    Service?: EnumOrderServiceType;
    /**
     * 是否可以导入报关资料中的产品
     */
    IsImportCustomsProduct?: boolean;
    /**
     * 报关主键
     */
    OrderDepartureId?: string;
}

/**
 * 单证详细-表单校验
 */
export const DtoDetailOrderDepartureCustomsRules = {
    GoodsAddress: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 单证详细-设置项
 */
export const DtoDetailOrderDepartureCustomsMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Customize: true,
  },
  {
    Prop: "OrderNumber",
    Label: "订单编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderDate",
    Label: "订单日期",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "InvoiceNumber",
    Label: "发票号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "InvoiceDate",
    Label: "发票日期",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FirstConsignorId",
    Label: "发货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FirstConsigneeId",
    Label: "收货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SellerContent",
    Label: "Sellers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BuyerContent",
    Label: "Buyers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CollectionBankId",
    Label: "收款银行",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentBankId",
    Label: "付款银行",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CollectionBankContent",
    Label: "银行信息",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentBankContent",
    Label: "银行信息",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DispatchPortId",
    Label: "装货港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DestinationPortId",
    Label: "目的港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ContactTransportModeId",
    Label: "运输方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Address",
    Label: "最终交货地址",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TradeTermsId",
    Label: "贸易条款",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DeliveryRequirement",
    Label: "交期要求",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentTermsId",
    Label: "付款条款",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentTermsContent",
    Label: "付款条款内容",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CreditNumber",
    Label: "信用证号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OpenCertificateDays",
    Label: "有效期",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "IssuingDate",
    Label: "开证日期",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "ShippingMarks",
    Label: "唛头",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsignorCode",
    Label: "境内发货人",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ConsignorCompanyName",
    Label: "境内发货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomhouseId",
    Label: "申报地海关",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomhouseName",
    Label: "申报地海关",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Customhouse",
    Label: "申报地海关",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "RecordNumber",
    Label: "备案号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsigneeCode",
    Label: "境外收货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsigneeCompanyName",
    Label: "境外收货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TransportModeId",
    Label: "运输方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TransportMode",
    Label: "运输方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BLNO",
    Label: "提运单号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductionSaleCompanyName",
    Label: "生产销售单位",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductionSaleCode",
    Label: "生产销售单位",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductionSaleId",
    Label: "生产销售单位",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductionSale",
    Label: "生产销售单位",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SupervisionModeId",
    Label: "监管方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SupervisionMode",
    Label: "监管方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ExemptionNatureId",
    Label: "征免性质",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ExemptionNature",
    Label: "征免性质",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PermitNumber",
    Label: "许可证号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ContractNumber",
    Label: "合同协议号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TradingCountryId",
    Label: "贸易国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TradingCountry",
    Label: "贸易国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ArrivalCountryId",
    Label: "运抵国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ArrivalCountry",
    Label: "运抵国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PortOfDestination",
    Label: "指运港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PackName",
    Label: "包装种类",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OtherPackName",
    Label: "其他包装",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductNumber",
    Label: "总的件数",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductGrossWeight",
    Label: "总的毛重",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductNetWeight",
    Label: "总的净重",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductVolume",
    Label: "总的体积",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductPrice",
    Label: "总的货值",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TransactionModeId",
    Label: "成交方式Id",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TransactionMode",
    Label: "成交方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Freight",
    Label: "运费",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FreightCurrencySystemId",
    Label: "运费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FreightCurrencySystem",
    Label: "运费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Premium",
    Label: "保费",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PremiumCurrencySystemId",
    Label: "保费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PremiumCurrencySystem",
    Label: "保费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Incidental",
    Label: "杂费",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "IncidentalCurrencySystemId",
    Label: "杂费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "IncidentalCurrencySystem",
    Label: "杂费币制",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "AttachedCode",
    Label: "随附单证",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ElectronicAccountDataNumber",
    Label: "电子底账数据号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomsShippingMark",
    Label: "唛头",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderDepartureCustomsAnnexs",
    Label: "附件",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderDepartureCustomsProducts",
    Label: "产品",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "StopPortId",
    Label: "经停港",
    Customize: true,
  },
  {
    Prop: "DepartureCountryId",
    Label: "启运国",
    Customize: true,
  },
  {
    Prop: "DepartureCountry",
    Label: "启运国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DeparturePort",
    Label: "启运港",
    Customize: true,
  },
  {
    Prop: "EntryPort",
    Label: "入境口岸",
    Customize: true,
  },
  {
    Prop: "GoodsAddress",
    Label: "货物存放地点",
    Customize: true,
  },
  {
    Prop: "IsImportCustomsProduct",
    Label: "是否可以导入报关资料中的产品",
    Customize: true,
  },
]
