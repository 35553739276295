
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzButton from "@/models/MiezzButton";

export default class MiezzMenuTabComponent extends Vue {
  @Model() modelValue?: MiezzMenuTab;
  backBtn: MiezzButton = {
    Code: "menu-tab-back",
    Label: "返回",
    MiezzIcon: "back",
    Type: "text",
    Size: "small",
  };

  async created(): Promise<void> {
    if (this.modelValue?.Url) {
      await this.init();
    }
  }

  async init(path?: string, select?: boolean): Promise<void> {
    if (this.modelValue) {
      const powers = (await CurrentLogier.GetPowerCodes()) ?? [];
      this.modelValue.Menus =
        this.modelValue.Menus?.filter((it) => powers.contains(it.Power)) ?? [];
      if (!path) path = this.$route.fullPath;
      const routePath = this.$router.resolve(path);
      if (
        this.modelValue?.Url &&
        (this.modelValue.ToFirstUrlWhenSomeUrlFullPath
          ? path == this.modelValue?.Url
          : routePath.path == this.$router.resolve(this.modelValue.Url).path)
      ) {
        this.modelValue.Active =
          this.modelValue.Menus.firstOrDefault()?.Url ?? "";
        const routeActive = this.$router.resolve(this.modelValue.Active);
        for (const query in routePath.query) {
          routeActive.query[query] = routePath.query[query];
        }
        this.handleLink(routeActive);
      } else {
        this.modelValue.Active =
          this.modelValue.Menus.firstOrDefault((it) =>
            path?.contains(it.Url as string)
          )?.Url ?? "";
        if (select && this.modelValue.Active) {
          const routeActive = this.$router.resolve(this.modelValue.Active);
          this.handleLink(routeActive);
        }
      }
    }
  }

  /**按钮点击 */
  @Emit()
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "menu-tab-back":
            var url = this.$route.query.backUrl as string;
        if (
          url != null &&
          typeof url != "undefined" &&
          url != undefined &&
          url != "undefined" &&
          url != ""
        ) {
            if (Object.prototype.toString.call(url) == '[object Array]') {
                if (url.length > 0) {
                    var newUrl = url[0];
                    var index = 0;
                    for (var u of url) {
                        if (index > 0) {
                            if (newUrl.indexOf("?") >= 0) {
                                newUrl += "&backUrl=" + u;
                            }
                            else {
                                newUrl += "?backUrl=" + u;
                            }
                        }
                        index++;
                    }
                    this.$router.push(newUrl);
                }

            }
            else {
                //this.$router.push(url);

                if (this.modelValue?.IsHistory == true) {
                    history.go(-1);
                } else {
                    CurrentLogier.Back();
                }
            }
        } else {
          CurrentLogier.Back();
          // history.go(-1);
        }
        complete();
        break;

      default:
        break;
    }
  }

  handleSelect(key?: string): void {
    if (this.modelValue) {
      this.modelValue.Active = key ?? "";
      const route = this.$router.resolve(this.modelValue.Active);
      this.handleLink(route);
    }
  }

  @Emit()
  handleLink(route: any): void {
    route.query.backUrl = this.$route.query.backUrl as string;
    this.$router.push(route).then(() => {
      this.handleLinkAfter(route);
    });
  }

  @Emit()
  handleLinkAfter(route: any): void {
    //
  }
}

export class MiezzMenuTab {
  IsHistory?: boolean;
  Back?: boolean;
  Url?: string;
  ToFirstUrlWhenSomeUrlFullPath?: boolean;
  Menus?: MiezzMenuTabItem[] = [];
  Active?: string = "";
  LeftButtons?: MiezzButton[] = [];
  MenuButtons?: MiezzButton[] = [];
  RightButtons?: MiezzButton[] = [];
}
export class MiezzMenuTabItem {
  Label?: string;
  Url?: string;
  Query?: any;
  Power?: string;
}
