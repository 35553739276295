import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemBill from "./DtoListItemBill";
import { EnumOrderStatus, EnumOrderStatusOption } from "./EnumOrderStatus";
import DtoCallbackGrossProfit from "./DtoCallbackGrossProfit";
import UiGroup from "./UiGroup";

/**
 * 委托书账单信息/订单账单信息
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackBill extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Collections = [];
        this.Payments = [];
        this.Salers = [];
        this.IsSaler = false;
        this.IsPaymentTask = false;
        this.IsTaskCollection = false;
        this.IsTaskSaler = false;
        this.IsTaskPayment = false;
        this.GrossProfits = [];
        this.IsDepartureChargeWeight = false;
        this.IsDestinationChargeWeight = false;
    }

    [proName: string]: any;
    /**
     * 应收合计
     */
    Collection?: string;
    /**
     * 应收核销合计
     */
    CollectionSettlement?: string;
    /**
     * 应付核销合计
     */
    PaymentSettlement?: string;
    /**
     * 应收账单
     */
    Collections?: DtoListItemBill[];
    /**
     * 应付合计
     */
    Payment?: string;
    /**
     * 应付账单
     */
    Payments?: DtoListItemBill[];
    /**
     * 业务员结算价合计
     */
    Saler?: string;
    /**
     * 业务员结算价账单
     */
    Salers?: DtoListItemBill[];
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 提单号码
     */
    BLNO?: string;
    /**
     * 是否有业务员
     */
    IsSaler?: boolean;
    /**
     * 是否存在制作应付账单的待办正在处理中
     */
    IsPaymentTask?: boolean;
    /**
     * 是否存在制作应收账单的待办正在处理中
     */
    IsTaskCollection?: boolean;
    /**
     * 是否存在制确认结算价账单的待办正在处理中
     */
    IsTaskSaler?: boolean;
    /**
     * 是否存在制作应付账单的待办正在处理中
     */
    IsTaskPayment?: boolean;
    /**
     * 订单的应收是否保存
     */
    IsCollectionOrder?: boolean;
    /**
     * 订单的应付是否保存
     */
    IsPaymentOrder?: boolean;
    /**
     * 订单的结算是否保存
     */
    IsSalerOrder?: boolean;
    /**
     * 委托书的应收是否保存
     */
    IsCollectionPool?: boolean;
    /**
     * 委托书的应付是否保存
     */
    IsPaymentPool?: boolean;
    /**
     * 委托书的结算是否保存
     */
    IsSalerPool?: boolean;
    /**
     * 订单状态
     */
    OrderStatus?: EnumOrderStatus;
    /**
     * 订单是否锁定
     */
    IsLock?: boolean;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 舱单截止时间
     */
    ManifestEndDate?: Date;
    /**
     * 截关时间
     */
    CustomsEndDate?: Date;
    /**
     * 开港时间
     */
    OpenPortDate?: Date;
    /**
     * 截港时间
     */
    PortEndDate?: Date;
    /**
     * 毛利润
     */
    GrossProfits?: DtoCallbackGrossProfit[];
    /**
     * 毛利润（按CNY汇总）
     */
    SumGrossProfit?: string;
    /**
     * 运输信息：船名/航次，航班，班次
     */
    TransportInfo?: string;
    /**
     * 装货港
     */
    LoadingPort?: string;
    /**
     * 目的港
     */
    DestinationPort?: string;
    /**
     * 交货地点
     */
    DeliveryPlaceLabel?: string;
    /**
     * 是否显示起运港计费标准
     */
    IsDepartureChargeWeight?: boolean;
    /**
     * 起运港计费标准
     */
    DepartureChargeWeight?: string;
    /**
     * 是否显示目的港计费标准
     */
    IsDestinationChargeWeight?: boolean;
    /**
     * 目的港计费标准
     */
    DestinationChargeWeight?: string;
}

/**
 * 委托书账单信息/订单账单信息-表单校验
 */
export const DtoCallbackBillRules = {
}

/**
 * 委托书账单信息/订单账单信息-设置项
 */
export const DtoCallbackBillMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Collection",
    Label: "应收合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CollectionSettlement",
    Label: "应收核销合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PaymentSettlement",
    Label: "应付核销合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Collections",
    Label: "应收账单",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Payment",
    Label: "应付合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Payments",
    Label: "应付账单",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Saler",
    Label: "业务员结算价合计",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Salers",
    Label: "业务员结算价账单",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "订单编号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "BLNO",
    Label: "提单号码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsSaler",
    Label: "是否有业务员",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsPaymentTask",
    Label: "是否存在制作应付账单的待办正在处理中",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsTaskCollection",
    Label: "是否存在制作应收账单的待办正在处理中",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsTaskSaler",
    Label: "是否存在制确认结算价账单的待办正在处理中",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsTaskPayment",
    Label: "是否存在制作应付账单的待办正在处理中",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsCollectionOrder",
    Label: "订单的应收是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsPaymentOrder",
    Label: "订单的应付是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsSalerOrder",
    Label: "订单的结算是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsCollectionPool",
    Label: "委托书的应收是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsPaymentPool",
    Label: "委托书的应付是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsSalerPool",
    Label: "委托书的结算是否保存",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "OrderStatus",
    Label: "订单状态",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumOrderStatusOption,
  },
  {
    Prop: "IsLock",
    Label: "订单是否锁定",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "OrderId",
    Label: "订单主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ManifestEndDate",
    Label: "舱单截止时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "CustomsEndDate",
    Label: "截关时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "OpenPortDate",
    Label: "开港时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "PortEndDate",
    Label: "截港时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "GrossProfits",
    Label: "毛利润",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SumGrossProfit",
    Label: "毛利润（按CNY汇总）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportInfo",
    Label: "运输信息：船名/航次，航班，班次",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "LoadingPort",
    Label: "装货港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DestinationPort",
    Label: "目的港",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "交货地点",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsDepartureChargeWeight",
    Label: "是否显示起运港计费标准",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DepartureChargeWeight",
    Label: "起运港计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "IsDestinationChargeWeight",
    Label: "是否显示目的港计费标准",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "DestinationChargeWeight",
    Label: "目的港计费标准",
    Type: EnumMiezzFormItemType.Text,
  },
]
