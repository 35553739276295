import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailExceptionLog_StackTrace from "./DtoDetailExceptionLog_StackTrace";
import UiGroup from "./UiGroup";

/**
 * 异常日志详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailExceptionLog extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.StackTraceItems = [];
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 类型
     */
    Type?: string;
    /**
     * 异常消息
     */
    Message?: string;
    /**
     * 堆栈跟踪
     */
    StackTrace?: string;
    /**
     * 堆栈跟踪
     */
    StackTraceItems?: DtoDetailExceptionLog_StackTrace[];
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建公司英文名称
     */
    CreatedCompanyNameEn?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
}

/**
 * 异常日志详细-表单校验
 */
export const DtoDetailExceptionLogRules = {
}

/**
 * 异常日志详细-设置项
 */
export const DtoDetailExceptionLogMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TraceIdentifier",
    Label: "Http跟踪标识",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Message",
    Label: "异常消息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "StackTraceItems",
    Label: "堆栈跟踪",
    Customize: true,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyNameEn",
    Label: "创建公司英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedPhoneNumber",
    Label: "创建人手机号码",
    Type: EnumMiezzFormItemType.Text,
  },
]
