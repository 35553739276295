import UiSelectOption from "./UiSelectOption";

/**
 * 价格要素类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumParamPriceFieldType {
    /**
     * Id
     */
    Id = 0,
    /**
     * 费用名称
     */
    Cost = 1,
    /**
     * 计算要素
     */
    Calc = 2,
    /**
     * 描述要素
     */
    Description = 3,
}

/**
 * 价格要素类型-选项
 */
export const EnumParamPriceFieldTypeOption: UiSelectOption<number>[] = [
    { Label: "Id", Value: 0, Data: "Id" },
    { Label: "费用名称", Value: 1, Data: "Cost" },
    { Label: "计算要素", Value: 2, Data: "Calc" },
    { Label: "描述要素", Value: 3, Data: "Description" },
];
