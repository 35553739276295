import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_menu_form = _resolveComponent("menu-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'JoinMenuWebApis')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "接口",
              prop: "JoinMenuWebApis"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detail.Data[item.Prop], (webapi, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass('api_color_' + webapi.WebApi.Method)
                  }, " [" + _toDisplayString(_ctx.getMethod(webapi.WebApi.Method)) + "] " + _toDisplayString(webapi.WebApi.ControllerSummary) + " / " + _toDisplayString(webapi.WebApi.Summary), 3))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (item.Prop == 'MenuFields')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: "字段",
              prop: "MenuFields"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tree, {
                  ref: "treeRef",
                  "node-key": "Id",
                  data: _ctx.detail.Data[item.Prop],
                  props: _ctx.props,
                  "expand-on-click-node": false,
                  "default-expand-all": ""
                }, {
                  default: _withCtx(({ data }) => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_el_tag, {
                        class: "node-tag",
                        type: "info"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(data.Name) + " / " + _toDisplayString(data.Code), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["data", "props"])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_menu_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForm) = $event)),
          ref: "menu-form",
          id: _ctx.formId,
          parentId: _ctx.parentId,
          path: _ctx.path,
          code: _ctx.code,
          scene: _ctx.detail.Data.Scene,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "parentId", "path", "code", "scene", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}