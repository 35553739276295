import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoServerLogHttp from "./DtoServerLogHttp";
import DtoServerLogSql from "./DtoServerLogSql";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemServerLog extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 日志原文
     */
    LogOriginalText?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 消息
     */
    Message?: string;
    /**
     * 请求
     */
    Http?: DtoServerLogHttp;
    /**
     * Sql
     */
    Sql?: DtoServerLogSql;
}

/**
 * -表单校验
 */
export const DtoListItemServerLogRules = {
}

/**
 * -设置项
 */
export const DtoListItemServerLogMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Message",
    Label: "消息",
  },
]
