
    import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
    import OSS from "ali-oss";
    import AliyunOSS from "@/apis/AliyunOSS";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzButton from "@/models/MiezzButton";
    import PackageAppService from "@/apis.machine/PackageAppService";
    import CompanyAppService from "@/apis.machine/CompanyAppService";
    import DtoPageListSearchPackage, { DtoPageListSearchPackageDynamicSearchItems } from "@/models.machine/DtoPageListSearchPackage";
    import DtoPageListPackage from "@/models.machine/DtoPageListPackage";
    import DtoListItemPackage, {
        DtoListItemPackageMiezzListItems,
    } from "@/models.machine/DtoListItemPackage";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoDetailProduct, {
        DtoDetailProductMiezzDetailItems,
    } from "@/models.machine/DtoDetailProduct";
    import MiezzForm from "@/models/MiezzForm";
    export default class SpecPageList extends Vue {

        codes: UiSelectOptionWithStringValue[] = [];
        packageZhs: UiSelectOptionWithStringValue[] = [];
        packageEns: UiSelectOptionWithStringValue[] = [];
        packageSpecs: UiSelectOptionWithStringValue[] = [];
        numbers: UiSelectOptionWithStringValue[] = [];
        products: UiSelectOptionWithStringValue[] = [];

        imageForm = {
            Visiable: false,
            Rows: [],
        };
        bottomButtonsImage?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        client?: OSS;
        selectCompanyId?: string;
        companys?: UiSelectOption<string>[] = [];

        /**分页列表 */
        list = new MiezzPageList<DtoPageListSearchPackage, DtoListItemPackage, string>();
        /**当前Id */
        id?: string;
        /*是否具有管理其他公司产品的权限 */
        isManageOtherCompany?: boolean;
        companyRandom = 0;
        random = 0;
        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.selectCompanyId = this.$route.query.CompanyId as string;

            //列表-传参
            this.list.Dto = new DtoPageListSearchPackage();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = [];
            for (var it of DtoPageListSearchPackageDynamicSearchItems) {
                if (it.Prop != "DynamicSearch_Code"
                    && it.Prop != "DynamicSearch_PackageZh"
                    && it.Prop != "DynamicSearch_PackageEn"
                    && it.Prop != "DynamicSearch_PackageSpec"
                    && it.Prop != "DynamicSearch_PackageSpecs_Product"
                    && it.Prop != "DynamicSearch_PackageSpecs"
                ) {
                    this.list.DynamicSearchItems.push(it);
                }
            }
            //列表-数据-初始
            this.list.Data = new DtoPageListPackage();

            //列表-列配置
            var columes = DtoListItemPackageMiezzListItems;
            columes = [];
            DtoListItemPackageMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop != "IsSelected" && item.Prop != "Quantity") {
                    if (item.Prop != "SpecQuantity") {
                        columes.push(item)
                    }
                }

            })
            this.list.Items = columes;

            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    IsDropdown:true,
                    Code: "add",
                    Label: "添加包装",
                    Icon: "plus",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "ProductManagement_PackingList_NewlyAdded",
                    Options: [
                        {
                            Code: "10",
                            Label: "非混装",
                            Size: "small"

                        },
                        {
                            Code: "20",
                            Label: "混装",
                            Size: "small"
                        }
                    ],
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "detail",
                    Title: "查看详细",
                    Icon: "search",
                    Size: "small",
                    PowerCode: "ProductManagement_PackingList_Detail"
                },
            ];
            //列表-底部按钮
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    /*Message: "是否确认删除",*/
                    MessageBox: true,
                    PowerCode: "ProductManagement_PackingList_Delete"
                },
            ];



            this.handleGet();
        }

        onImage(row: any): void {
            if (row.PackageImages && row.PackageImages.length > 0) {
                if (row.PackageImages.length == 1) {
                    window.open(row.PackageImages[0].Url);
                }
                else {
                    this.imageForm.Rows = row.PackageImages;
                    this.imageForm.Visiable = true;
                }
            }
        }
        /**按钮点击 */
        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        //监听路由是否有变化
        @Watch("$route")
        onRouteChanged(): void {
            this.handleGet();
        }

        onChangeCompany(): void {
            this.$router.push("/packages?CompanyId=" + this.selectCompanyId + "&OpenSearch=" + this.$route.query.OpenSearch);
        }

        /**查询列表 */
        handleGet(): void {

            CurrentLogier.GetPowerCodes().then((r2) => {
                if (r2?.contains("ProductManagement_PackingList_CanManageOtherEnterprisePackaging")) {
                    this.isManageOtherCompany = true;
                }

                if (this.isManageOtherCompany == true) {
                    var param = {
                        CompanyType: 40,
                    };
                    CompanyAppService.GetSelect(param).then((r1) => {

                        this.companys = r1.data.Data;
                        if (this.companys && this.companys.length > 0 && (this.selectCompanyId == undefined || this.selectCompanyId == '')) {
                            this.selectCompanyId = this.companys[0].Id;
                        }
                        if (this.list.Dto) {
                            this.list.Dto.CompanyId = this.selectCompanyId;
                        }
                        PackageAppService.GetCodes(this.list.Dto).then((r11) => {
                           if(r11.data.Data) this.codes = r11.data.Data;
                        })
                        PackageAppService.GetPackageEns(this.list.Dto).then((r12) => {
                            if (r12.data.Data) this.packageEns = r12.data.Data;
                        })
                        PackageAppService.GetPackageZhs(this.list.Dto).then((r13) => {
                            if (r13.data.Data) this.packageZhs = r13.data.Data;
                        })
                        PackageAppService.GetNumbers(this.list.Dto).then((r14) => {
                            if (r14.data.Data) this.numbers = r14.data.Data;
                        })
                        PackageAppService.GetPackageSpecs(this.list.Dto).then((r15) => {
                            if (r15.data.Data) this.packageSpecs = r15.data.Data;
                        })
                        PackageAppService.GetProducts(this.list.Dto).then((r16) => {
                            if (r16.data.Data) this.products = r16.data.Data;
                        })
                        PackageAppService.Get(this.list.Dto).then((r) => {
                            this.list.Data = r.data.Data;
                            this.random = Math.random();
                            if (this.list.Data && this.list.Data.Items) {
                                for (var it of this.list.Data.Items) {
                                    if (it.PackageImages && it.PackageImages.length > 0) {
                                        for (var img of it.PackageImages) {
                                            img.Url = this.client?.signatureUrl(img.Url as string);
                                        }
                                    }
                                }
                            }
                        });

                    })
                }
                else {
                    PackageAppService.GetCodes(this.list.Dto).then((r1) => {
                        if (r1.data.Data) this.codes = r1.data.Data;
                    })
                    PackageAppService.GetPackageEns(this.list.Dto).then((r2) => {
                        if (r2.data.Data) this.packageEns = r2.data.Data;
                    })
                    PackageAppService.GetPackageZhs(this.list.Dto).then((r3) => {
                        if (r3.data.Data) this.packageZhs = r3.data.Data;
                    })
                    PackageAppService.GetNumbers(this.list.Dto).then((r4) => {
                        if (r4.data.Data) this.numbers = r4.data.Data;
                    })
                    PackageAppService.GetPackageSpecs(this.list.Dto).then((r5) => {
                        if (r5.data.Data) this.packageSpecs = r5.data.Data;
                    })
                    PackageAppService.GetProducts(this.list.Dto).then((r6) => {
                        if (r6.data.Data) this.products = r6.data.Data;
                    })
                    PackageAppService.Get(this.list.Dto).then((r) => {
                        this.list.Data = r.data.Data;
                        this.random = Math.random();
                        if (this.list.Data && this.list.Data.Items) {
                            for (var it of this.list.Data.Items) {
                                if (it.PackageImages && it.PackageImages.length > 0) {
                                    for (var img of it.PackageImages) {
                                        img.Url = this.client?.signatureUrl(img.Url as string);
                                    }
                                }
                            }
                        }
                    });
                }

            });

        }


        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "add":
                    // this.$router.push("/packages/add?CompanyId=" + this.selectCompanyId + "&type=" + btn.Command + "&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: `/packages/add`,
                        query: { 
                            CompanyId: this.selectCompanyId,
                            type: btn.Command,
                            backUrl: this.$route.fullPath 
                        },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemPackage,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //详细
                case "detail":
                    // this.$router.push("/packages/detail/" + this.id + "?CompanyId=" + this.selectCompanyId + "&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: `/packages/detail/${this.id}`,
                        query: { 
                            CompanyId: this.selectCompanyId,
                            backUrl: this.$route.fullPath 
                        },
                    });
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":

                    if (this.list.SelectIds == null || this.list.SelectIds.length <= 0) {
                        ElMessage.warning("请至少选择一个产品包装");
                        return;
                    }

                    ElMessageBox.confirm("您确定要删除选中的信息吗？", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {


                            PackageAppService.Delete(this.list.SelectIds)
                                .then(() => {
                                    this.handleGet();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });


                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
    }
