
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import moment from "moment";
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzDynamicSearchRelativeDate extends Vue {
  @Model() modelValue?: string;
  @Prop() month?: boolean;
  types: UiSelectOption<string>[] = [
    { Label: "本", Value: "Current" },
    { Label: "前", Value: "Before" },

    { Label: "后", Value: "After" },
  ];
  units: UiSelectOption<string>[] = [
    { Label: "日", Value: "Day" },
    { Label: "周", Value: "Week" },
    { Label: "月", Value: "Month" },
  ];
  modal = new MiezzModal(MiezzModalType.Dialog);
  form: MiezzForm<
    {
      type?: string;
      quantity?: number;
      undefinedQuantity?: number;
      unit?: string;
      label?: string;
    },
    any
  > = {
    Data: {
      type: "Current",
      unit: "Day",
      quantity: 0,
    },
    Items: [
      {
        Prop: "type",
        Customize: true,
        Span: 8,
      },
      {
        Prop: "quantity",
        Customize: true,
        Span: 8,
      },
      {
        Prop: "unit",
        Customize: true,
        Span: 8,
      },
    ],
    Rules: {
      quantity: [
        {
          required: true,
          validator: (rule: any, value: string, callback: any) => {
            console.log(222, value);
            if (!value) {
              callback(new Error("信息不能为空"));
            } else {
              return callback();
            }
          },
          trigger: "blur",
        },
      ],
    },
  };

  created(): void {
    if (this.month) {
      this.units = [{ Label: "月", Value: "Month" }];
      this.form.Data.unit = "Month";
    }
    this.modal.Title = "选择日期";
    this.modal.Width = "700px";
    this.modal.Height = "100px";
    this.modal.Buttons = [
      {
        Code: "submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modal.HandleClick = this.handleSure;
    this.onModelValueChanged();
  }

  handleChange(): void {
    if (this.form.Data.type == "Current") {
      this.form.Data.quantity = 0;
    } else if (!this.form.Data.quantity) {
      this.form.Data.quantity = 1;
    }
  }

  handleSure(btn: MiezzButton, complete: () => void): void {
    if (btn.Code == "submit") {
      MiezzForm.submit(this.$refs, "ruleForm", (v: any) => {
        if (v) {
          const modelValue = `Relative_${this.form.Data.type}_${this.form.Data.quantity}_${this.form.Data.unit}`;
          this.$emit("update:modelValue", modelValue);
          this.modal.Show = false;
          complete();
        } else {
          setTimeout(() => {
            const error = document.getElementsByClassName("is-error");
            error[0]?.querySelector("input")?.focus();
          }, 1);
          complete();
        }
      });
      complete();
    }
  }

  handleClear(): void {
    this.$emit("update:modelValue", undefined);
    this.form.Data.label = undefined;
  }

  handleShow(): void {
    this.onModelValueChanged();
    this.modal.Show = true;
  }

  @Watch("modelValue")
  onModelValueChanged(): void {
    if (this.modelValue) {
      const splits = this.modelValue.split("_");
      if (splits && splits.length == 4) {
        this.form.Data.type = splits[1];
        this.form.Data.quantity = splits[2] ? parseInt(splits[2]) : undefined;
        this.form.Data.unit = splits[3];

        let start = moment().add(
          this.form.Data.type == "Before"
            ? 0 - (this.form.Data.quantity ?? 0)
            : this.form.Data.quantity,
          this.form.Data.unit == "Day"
            ? "d"
            : this.form.Data.unit == "Week"
            ? "w"
            : "M"
        );
        let date = "";
        switch (this.form.Data.unit) {
          case "Day":
            date = `（${start.format("YYYY/MM/DD")}）`;
            break;
          case "Week":
            date = `（${start.startOf("week").format("YYYY/MM/DD")} - ${start
              .endOf("week")
              .format("YYYY/MM/DD")} ）`;
            break;
          case "Month":
            if (this.month) {
              date = `（${start.startOf("month").format("YYYY/MM")}）`;
            } else {
              date = `（${start.startOf("month").format("YYYY/MM/DD")} - ${start
                .endOf("month")
                .format("YYYY/MM/DD")} ）`;
            }
            break;
        }

        this.form.Data.label = [
          this.types.firstOrDefault((it) => it.Value == this.form.Data.type)
            ?.Label,
          this.form.Data.type != "Current"
            ? this.form.Data.quantity?.toString()
            : undefined,
          this.units.firstOrDefault((it) => it.Value == this.form.Data.unit)
            ?.Label,
          date,
        ]
          .filter((it) => it)
          .join("");
      } else {
        this.$emit("update:modelValue", undefined);
      }
    }
  }
}
