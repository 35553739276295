import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemCompanyBusinessCardBankAccount from "./DtoListItemCompanyBusinessCardBankAccount";

/**
 * 公司名片-银行分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyBusinessCardBank extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.CompanyBusinessCardBankAccounts = [];
    }

    [proName: string]: any;
    /**
     * 收款银行
     */
    NameZh?: string;
    /**
     * Name of Bank
     */
    NameEn?: string;
    /**
     * 收款人
     */
    BeneficiaryNameZh?: string;
    /**
     * Beneficiary
     */
    BeneficiaryNameEn?: string;
    /**
     * Swift Code
     */
    SwiftCode?: string;
    /**
     * Bank Code
     */
    Code?: string;
    /**
     * 银行地址
     */
    AddressZh?: string;
    /**
     * Address
     */
    AddressEn?: string;
    /**
     * 默认账号Id
     */
    CompanyBusinessCardBankAccountId?: string;
    /**
     * 账号（A/C NO.）
     */
    CompanyBusinessCardBankAccounts?: DtoListItemCompanyBusinessCardBankAccount[];
}

/**
 * 公司名片-银行分页列表-项-表单校验
 */
export const DtoListItemCompanyBusinessCardBankRules = {
}

/**
 * 公司名片-银行分页列表-项-设置项
 */
export const DtoListItemCompanyBusinessCardBankMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "收款银行",
  },
  {
    Prop: "NameEn",
    Label: "Name of Bank",
  },
  {
    Prop: "BeneficiaryNameZh",
    Label: "收款人",
  },
  {
    Prop: "BeneficiaryNameEn",
    Label: "Beneficiary",
  },
  {
    Prop: "SwiftCode",
    Label: "Swift Code",
  },
  {
    Prop: "Code",
    Label: "Bank Code",
  },
  {
    Prop: "AddressZh",
    Label: "银行地址",
  },
  {
    Prop: "AddressEn",
    Label: "Address",
  },
  {
    Prop: "CompanyBusinessCardBankAccounts",
    Label: "账号（A/C NO.）",
    Customize: true,
  },
]
