import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamTransportChargeUnit from "../models.machine/DtoSelectSearchParamTransportChargeUnit";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamTransportChargeUnit from "../models.machine/DtoPageListSearchParamTransportChargeUnit";
import DtoPageListParamTransportChargeUnit from "../models.machine/DtoPageListParamTransportChargeUnit";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamTransportChargeUnit from "../models.machine/DtoDetailParamTransportChargeUnit";
import DtoFormParamTransportChargeUnit from "../models.machine/DtoFormParamTransportChargeUnit";

/**
 * 物流计价单位
 * @description 自动生成的代码,请勿修改
 */
export default class ParamTransportChargeUnitAppService {
    /**
     * 获取物流计价单位选择器
     * @param {DtoSelectSearchParamTransportChargeUnit} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamTransportChargeUnit): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamTransportChargeUnit/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取物流计价单位分页列表
     * @param {DtoPageListSearchParamTransportChargeUnit} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamTransportChargeUnit>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamTransportChargeUnit): AxiosPromise<RESTfulResult<DtoPageListParamTransportChargeUnit>> {
        const url = `/api/ParamTransportChargeUnit/Get`;
        return service.get<RESTfulResult<DtoPageListParamTransportChargeUnit>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChargeUnit/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取物流计价单位详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamTransportChargeUnit>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamTransportChargeUnit>> {
        const url = `/api/ParamTransportChargeUnit/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamTransportChargeUnit>>(url);
    }

    /**
     * 新增/编辑物流计价单位
     * @param {DtoFormParamTransportChargeUnit} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamTransportChargeUnit): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChargeUnit/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除物流计价单位
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTransportChargeUnit/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportChargeUnit/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTransportChargeUnit/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamTransportChargeUnit/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Code数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetCodeList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ParamTransportChargeUnit/GetCodeList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
