
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import CompanyChargeAppService from "@/apis.machine/CompanyChargeAppService";
    import DtoFormCompanyCharge, {
        DtoFormCompanyChargeMiezzFormItems,
        DtoFormCompanyChargeRules,
    } from "@/models.machine/DtoFormCompanyCharge";

    export default class CompanyChargeForm extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() companyId?: string;
        @Prop() id?: string;

        form = new MiezzForm<DtoFormCompanyCharge, string>();

        types = [
            {
                Value: 10,
                Label: "按重量计费"
            },
            {
                Value: 20,
                Label: "按体积计费"
            },
        ];

        created(): void {
            //表单-数据-初始化
            this.form.Data = new DtoFormCompanyCharge();

            //表单-校验
            this.form.Rules = DtoFormCompanyChargeRules;
            //表单-元素配置
            this.form.Items = DtoFormCompanyChargeMiezzFormItems;

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "submit",
                    Label: "保存",
                    Type: "primary",
                    Size: "small",
                },
            ];

            this.handleGet();


        }

        /**查询表单数据 */
        handleGet(): void {
            if (this.id) {
                CompanyChargeAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                    this.onRule();
                });
            }
            else {
                this.form.Data.FromId = this.companyId;
                this.onRule();
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            console.log(this.form.Data);
                            if (this.form && this.form.Data) {
                                var t = this.form.Data.MinStandardPiece + "";
                                if (t == "") {
                                    this.form.Data.MinStandardPiece = undefined;
                                }
                                t = this.form.Data.MinStandardTicket + "";
                                if (t == "") {
                                    this.form.Data.MinStandardTicket = undefined;
                                }
                            }
                            CompanyChargeAppService.IsSame(this.form.Data).then((r) => {
                                if (r.data.Data == true) {
                                   ElMessage.warning("已存在相同计费重量/体积规则，请重新填写。");

                                    complete();
                                    return false;
                                }
                                else {
                                    this.form.Data.Sort = 0;
                                    CompanyChargeAppService.Post(this.form.Data)
                                        .then(() => {
                                            this.handleSubmit();
                                            complete();
                                        })
                                        .catch(complete);
                                }
                            })



                        }
                        else complete();
                    });
                    break;
                default:
                    break;
            }
        }

        onCheckNumber(): void {
            if (this.form && this.form.Data) {
                if (this.form.Data.VolumeRatio) {
                    var num = this.form.Data.VolumeRatio;
                    var result = setDecimal(num + "", 2, false, false);
                    if (result != "") {
                        this.form.Data.VolumeRatio = Number(result);
                    }
                    else {
                        this.form.Data.VolumeRatio = undefined;
                    }
                }

                if (this.form.Data.MinStandardPiece) {
                    var num1 = this.form.Data.MinStandardPiece;
                    var result1 = setDecimal(num1 + "", 2, false, false);
                    if (result1 != "") {
                        this.form.Data.MinStandardPiece = Number(result1);
                    }
                    else {
                        this.form.Data.MinStandardPiece = undefined;
                    }
                }

                if (this.form.Data.MinStandardTicket) {
                    var num2 = this.form.Data.MinStandardTicket;
                    var result2 = setDecimal(num2 + "", 2, false, false);
                    if (result2 != "") {
                        this.form.Data.MinStandardTicket = Number(result2);
                    }
                    else {
                        this.form.Data.MinStandardTicket = undefined;
                    }
                }
            }
        }


        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }

        /*添加自定义验证 */
        onRule(): void {
            this.form.Rules.Type = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                //{
                //    required: true,
                //    validator: (rule: any, value: string, callback: any) => {

                //        CompanyChargeAppService.IsSame(this.form.Data).then((r) => {
                //            if(r.data.Data == true){
                //                callback("信息重复，请重新填写");
                //                return false;
                //            }
                //            else{
                //                callback();
                //            }
                //        })
                //    },
                //    trigger: "change",
                //},
            ];


            this.form.Rules.VolumeRatio = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                //{
                //    required: false,
                //    validator: (rule: any, value: string, callback: any) => {

                //        CompanyChargeAppService.IsSame(this.form.Data).then((r) => {
                //            if(r.data.Data == true){
                //                callback("信息重复，请重新填写");
                //                return false;
                //            }
                //            else{
                //                callback();
                //            }
                //        })
                //    },
                //    trigger: "blur",
                //},
            ];

            //this.form.Rules.MinStandardTicket = [
            //    {
            //        required: false,
            //        message: "信息不能为空",
            //        trigger: "blur",
            //    },
            //    {
            //        required: false,
            //        validator: (rule: any, value: string, callback: any) => {

            //            CompanyChargeAppService.IsSame(this.form.Data).then((r) => {
            //                if(r.data.Data == true){
            //                    callback("信息重复，请重新填写");
            //                    return false;
            //                }
            //                else{
            //                    callback();
            //                }
            //            })
            //        },
            //        trigger: "blur",
            //    },
            //];

            //this.form.Rules.MinStandardPiece = [
            //    {
            //        required: false,
            //        message: "信息不能为空",
            //        trigger: "blur",
            //    },
            //    {
            //        required: false,
            //        validator: (rule: any, value: string, callback: any) => {

            //            CompanyChargeAppService.IsSame(this.form.Data).then((r) => {
            //                if(r.data.Data == true){
            //                    callback("信息重复，请重新填写");
            //                    return false;
            //                }
            //                else{
            //                    callback();
            //                }
            //            })
            //        },
            //        trigger: "blur",
            //    },
            //];
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            if (isLessZero == true) {
                if (str[0] == "-") {
                    result = "-" + result;
                }
            }
            return result;
        }
    };
