import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e937f06e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = {
  key: 0,
  class: "menu-buttons el-menu-item"
}
const _hoisted_3 = { class: "menu-buttons el-menu-item" }
const _hoisted_4 = {
  key: 0,
  class: "center"
}
const _hoisted_5 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_menu, {
        mode: "horizontal",
        "default-active": _ctx.modelValue.Active,
        onSelect: _ctx.handleSelect
      }, {
        default: _withCtx(() => [
          (
          (_ctx.modelValue?.LeftButtons && _ctx.modelValue?.LeftButtons?.length > 0) ||
          _ctx.modelValue?.Back
        )
            ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue?.LeftButtons, (item, i) => {
                  return (_openBlock(), _createBlock(_component_miezz_button, {
                    key: i,
                    model: item,
                    onHandleClick: _ctx.handleClick
                  }, null, 8, ["model", "onHandleClick"]))
                }), 128)),
                (_ctx.modelValue?.Back)
                  ? (_openBlock(), _createBlock(_component_miezz_button, {
                      key: 0,
                      model: _ctx.backBtn,
                      onHandleClick: _ctx.handleClick
                    }, null, 8, ["model", "onHandleClick"]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "left", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue?.Menus, (item, i) => {
            return (_openBlock(), _createBlock(_component_el_menu_item, {
              key: i,
              index: item.Url,
              route: item.Url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.Label), 1)
              ]),
              _: 2
            }, 1032, ["index", "route"]))
          }), 128)),
          _createElementVNode("li", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue?.MenuButtons, (item, i) => {
              return (_openBlock(), _createBlock(_component_miezz_button, {
                key: i,
                model: item,
                onHandleClick: _ctx.handleClick
              }, null, 8, ["model", "onHandleClick"]))
            }), 128))
          ])
        ]),
        _: 3
      }, 8, ["default-active", "onSelect"])
    ]),
    (_ctx.$slots['center'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "center", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue?.RightButtons, (item, i) => {
        return (_openBlock(), _createBlock(_component_miezz_button, {
          key: i,
          model: item,
          onHandleClick: _ctx.handleClick
        }, null, 8, ["model", "onHandleClick"]))
      }), 128)),
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ], 64))
}