
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoPageListSearchCompanyBusinessCard from "@/models.machine/DtoPageListSearchCompanyBusinessCard";
import DtoPageListCompanyBusinessCard from "@/models.machine/DtoPageListCompanyBusinessCard";
import DtoListItemCompanyBusinessCard, {
  DtoListItemCompanyBusinessCardMiezzListItems,
} from "@/models.machine/DtoListItemCompanyBusinessCard";
import CompanyBusinessCardForm from "./CompanyBusinessCardForm.vue";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import CompanyBusinessCardSeal from "./CompanyBusinessCardSeal.vue";
import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";

@Options({
  components: {
    CompanyBusinessCardForm,
    CompanyBusinessCardSeal,
  },
})
export default class CompanyBusinessCardPageList extends Vue {
  @Prop() title!: string;
  @Prop() area!: EnumCompanyBusinessCardArea;
  @Prop() type!: EnumCompanyBusinessCardType;
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompanyBusinessCard,
    DtoListItemCompanyBusinessCard,
    string
  >;
  @Prop() bankLink?: string;
  @Prop() notifierLink?: string;

  companyId?: string;
  parentId?: string;
  id?: string = "";
  /**表单模态框 */
  modalForm = new MiezzModal();
  modalSeal = new MiezzModal(MiezzModalType.Dialog);
  currencySystems: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    this.companyId = this.$route.params.id as string;
    this.parentId = this.$route.params.parentId as string;
    //列表-传参
    this.list.Type = MiezzPageListType.Card;
    this.list.Back = true;
    this.list.EnabledSelect = false;
    this.list.Title = this.title;
    this.list.EnabledRadioSearch = false;
    this.list.CardSpan = 33.33;
    this.list.Dto = new DtoPageListSearchCompanyBusinessCard();
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyBusinessCard();
    this.list.Items = DtoListItemCompanyBusinessCardMiezzListItems;
    if (
      this.area != EnumCompanyBusinessCardArea.Overseas ||
      this.type == EnumCompanyBusinessCardType.Notifier
    ) {
      this.list.Items = this.list.Items?.filter(
        (it) => it.Prop != "ParamCurrencySystemId"
      );
    }
    this.list.CheckRowButtonShow = (btn, row) => {
      return row.Content != "THE SAME AS CNEE";
    };
    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = r.data.Data ?? [];
    });
  }

  /**查询列表 */
  handleGet(): void {
    const dto = Object.copy(this.list.Dto);
    dto.CompanyId = this.companyId;
    dto.ParentId = this.parentId;
    dto.Area = this.area;
    dto.Type = this.type;
    CompanyBusinessCardAppService.Get(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleSetCurrencySystem(id: string, currencySystemId?: string): void {
    CompanyBusinessCardAppService.SetCurrencySystemById(id, currencySystemId);
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = `新增${this.title}`;
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**按钮点击 */
  @Emit()
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyBusinessCard,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "bank":
        this.$router.push(`${this.bankLink}/${row.Id}`);
        complete();
        break;
      case "seal":
        this.modalSeal.Show = true;
        complete();
        break;
      case "link":
        this.$router.push(`${this.notifierLink}/${this.companyId}/${row.Id}`);
        complete();
        break;
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑${this.title}`;
        complete();
        break;
      case "delete":
        CompanyBusinessCardAppService.Delete([this.id as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }
}
