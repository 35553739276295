import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0d77178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-label" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "description-fields" }
const _hoisted_4 = { class: "sailing-date-value" }
const _hoisted_5 = { style: {"margin-top":"-10px","margin-left":"138px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit = _resolveComponent("edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_close = _resolveComponent("close")!
  const _component_miezz_date_time_range = _resolveComponent("miezz-date-time-range")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_vuedraggable = _resolveComponent("vuedraggable")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.schemeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.schemeName) = $event)),
      type: "card",
      "tab-position": "top",
      addable: "",
      onEdit: _ctx.handleEditSchemes
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.Schemes, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: item.Name,
            label: item.Name,
            name: item.Name
          }, {
            label: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createElementVNode("span", {
                  title: item.Name
                }, _toDisplayString(item.Name), 9, _hoisted_2),
                _createVNode(_component_el_icon, {
                  onClick: ($event: any) => (_ctx.handleEditSchemes(item.Name, 'edit'))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_el_icon, {
                  onClick: ($event: any) => (_ctx.handleEditSchemes(item.Name, 'delete'))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_close)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        prop: "ValidDateStart",
                        label: "价格有效期"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_date_time_range, {
                            start: item.ValidDateStart,
                            "onUpdate:start": ($event: any) => ((item.ValidDateStart) = $event),
                            end: item.ValidDateEnd,
                            "onUpdate:end": ($event: any) => ((item.ValidDateEnd) = $event)
                          }, null, 8, ["start", "onUpdate:start", "end", "onUpdate:end"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_col, { span: 12 }, {
                    default: _withCtx(() => [
                      (_ctx.powers.contains('OrderManagement_Pool_Add_SaveShortcut'))
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 0,
                            prop: "ServiceRemark",
                            label: "服务内容"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_input, {
                                modelValue: item.ServiceRemark,
                                "onUpdate:modelValue": ($event: any) => ((item.ServiceRemark) = $event),
                                valueKey: "Label",
                                "fetch-suggestions": _ctx.getServiceRemarks,
                                "trigger-on-focus": true,
                                deleteOption: true,
                                checkDeleteOption: (it) => it.Data,
                                onHandleDeleteOption: _ctx.handleDeleteServiceRemark
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "checkDeleteOption", "onHandleDeleteOption"])
                            ]),
                            _: 2
                          }, 1024))
                        : (item.Prop == 'ServiceRemark')
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 1,
                              prop: item.Prop,
                              label: item.Label
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_select, {
                                  modelValue: item.ServiceRemark,
                                  "onUpdate:modelValue": ($event: any) => ((item.ServiceRemark) = $event),
                                  options: _ctx.serviceRemarks
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                              ]),
                              _: 2
                            }, 1032, ["prop", "label"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_form_item, {
                labelWidth: "0px",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_vuedraggable, {
                    modelValue: item.FieldValues,
                    "onUpdate:modelValue": ($event: any) => ((item.FieldValues) = $event),
                    animation: "300",
                    handle: ".drag-sort-field",
                    class: "field-draggable",
                    onEnd: _ctx.buildFields
                  }, {
                    item: _withCtx(({ element, index }) => [
                      _createVNode(_component_el_col, {
                        span: 12,
                        style: {"padding":"0px 12px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, { labelWidth: "150px" }, {
                            label: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                prop: `Schemes[${i}].FieldValues[${index}].Name`,
                                rules: _ctx.rules.FiledName,
                                style: {"margin-top":"1px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_miezz_input, {
                                    modelValue: element.Name,
                                    "onUpdate:modelValue": ($event: any) => ((element.Name) = $event),
                                    options: _ctx.fields
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                                ]),
                                _: 2
                              }, 1032, ["prop", "rules"])
                            ]),
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createElementVNode("div", _hoisted_3, [
                                  (element.Code == 'PortId')
                                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                                        key: 0,
                                        prop: `Schemes[${i}].FieldValues[${index}].PortId`,
                                        rules: _ctx.rules.PortId
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_miezz_select, {
                                            modelValue: element.PortId,
                                            "onUpdate:modelValue": ($event: any) => ((element.PortId) = $event),
                                            label: element.Label,
                                            "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                            options: element.Options,
                                            remote: true,
                                            remoteMethod: element.Query
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                        ]),
                                        _: 2
                                      }, 1032, ["prop", "rules"]))
                                    : (element.Code == 'CountryId')
                                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                                          key: 1,
                                          prop: `Schemes[${i}].FieldValues[${index}].CountryId`,
                                          rules: _ctx.rules.CountryId
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_miezz_select, {
                                              modelValue: element.CountryId,
                                              "onUpdate:modelValue": ($event: any) => ((element.CountryId) = $event),
                                              label: element.Label,
                                              "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                              options: element.Options,
                                              remote: true,
                                              remoteMethod: element.Query
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                          ]),
                                          _: 2
                                        }, 1032, ["prop", "rules"]))
                                      : (element.Code == 'AreaId')
                                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                                            key: 2,
                                            prop: `Schemes[${i}].FieldValues[${index}].AreaId`,
                                            rules: _ctx.rules.AreaId
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_miezz_select, {
                                                modelValue: element.AreaId,
                                                "onUpdate:modelValue": ($event: any) => ((element.AreaId) = $event),
                                                label: element.Label,
                                                "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                                options: element.Options,
                                                remote: true,
                                                remoteMethod: element.Query,
                                                valueKey: "Id",
                                                labelKey: "FullName"
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                            ]),
                                            _: 2
                                          }, 1032, ["prop", "rules"]))
                                        : (element.Code == 'CarrierId')
                                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                                              key: 3,
                                              prop: `Schemes[${i}].FieldValues[${index}].CarrierId`,
                                              rules: _ctx.rules.CarrierId
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_miezz_select, {
                                                  modelValue: element.CarrierId,
                                                  "onUpdate:modelValue": ($event: any) => ((element.CarrierId) = $event),
                                                  label: element.Label,
                                                  "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                                  options: element.Options,
                                                  remote: true,
                                                  remoteMethod: element.Query
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                              ]),
                                              _: 2
                                            }, 1032, ["prop", "rules"]))
                                          : (element.Code == 'ChannelId')
                                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                key: 4,
                                                prop: `Schemes[${i}].FieldValues[${index}].ChannelId`,
                                                rules: _ctx.rules.ChannelId
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_miezz_select, {
                                                    modelValue: element.ChannelId,
                                                    "onUpdate:modelValue": ($event: any) => ((element.ChannelId) = $event),
                                                    label: element.Label,
                                                    "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                                    options: element.Options,
                                                    remote: true,
                                                    remoteMethod: element.Query
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                                ]),
                                                _: 2
                                              }, 1032, ["prop", "rules"]))
                                            : (element.Code == 'RouteCodeId')
                                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                  key: 5,
                                                  prop: `Schemes[${i}].FieldValues[${index}].RouteCodeId`,
                                                  rules: _ctx.rules.RouteCodeId
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_miezz_select, {
                                                      modelValue: element.RouteCodeId,
                                                      "onUpdate:modelValue": ($event: any) => ((element.RouteCodeId) = $event),
                                                      label: element.Label,
                                                      "onUpdate:label": ($event: any) => ((element.Label) = $event),
                                                      options: element.Options,
                                                      remote: true,
                                                      remoteMethod: element.Query
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "remoteMethod"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["prop", "rules"]))
                                              : (element.Code == 'Voyage')
                                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                    key: 6,
                                                    class: "voyage"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_el_form_item, {
                                                        prop: `Schemes[${i}].FieldValues[${index}].Voyage`,
                                                        rules: _ctx.rules.Voyage,
                                                        class: "voyage-value"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_miezz_input, {
                                                            modelValue: element.Voyage,
                                                            "onUpdate:modelValue": ($event: any) => ((element.Voyage) = $event),
                                                            number: true,
                                                            pattern: "[0-9]"
                                                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["prop", "rules"]),
                                                      _createVNode(_component_el_form_item, {
                                                        prop: `Schemes[${i}].FieldValues[${index}].VoyageUnit`,
                                                        rules: _ctx.rules.VoyageUnit,
                                                        class: "voyage-unit"
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_miezz_select, {
                                                            modelValue: element.VoyageUnit,
                                                            "onUpdate:modelValue": ($event: any) => ((element.VoyageUnit) = $event),
                                                            label: element.VoyageUnitLabel,
                                                            "onUpdate:label": ($event: any) => ((element.VoyageUnitLabel) = $event),
                                                            options: element.Options
                                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options"])
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["prop", "rules"])
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                : (element.Code == 'SailingDate')
                                                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                      key: 7,
                                                      class: "sailing-date"
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_el_form_item, {
                                                          prop: `Schemes[${i}].FieldValues[${index}].SailingTimeType`,
                                                          rules: _ctx.rules.SailingTimeType,
                                                          class: "sailing-time-type"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_miezz_select, {
                                                              modelValue: element.SailingTimeType,
                                                              "onUpdate:modelValue": ($event: any) => ((element.SailingTimeType) = $event),
                                                              options: element.Options
                                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["prop", "rules"]),
                                                        _createElementVNode("div", _hoisted_4, [
                                                          (
                            element.SailingTimeType == _ctx.EnumSailingTimeType.Week
                          )
                                                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                                key: 0,
                                                                prop: `Schemes[${i}].FieldValues[${index}].SailingWeekArray`,
                                                                rules: _ctx.rules.SailingWeekArray
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_el_checkbox_group, {
                                                                    modelValue: element.SailingWeekArray,
                                                                    "onUpdate:modelValue": ($event: any) => ((element.SailingWeekArray) = $event)
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.EnumWeekOption, (week) => {
                                                                        return (_openBlock(), _createBlock(_component_el_checkbox, {
                                                                          key: week.Value,
                                                                          label: week.Value,
                                                                          class: "sailing-week"
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(week.Label), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1032, ["label"]))
                                                                      }), 128))
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["modelValue", "onUpdate:modelValue"])
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["prop", "rules"]))
                                                            : (_openBlock(), _createBlock(_component_el_form_item, {
                                                                key: 1,
                                                                prop: `Schemes[${i}].FieldValues[${index}].SailingDate`,
                                                                rules: _ctx.rules.SailingDate,
                                                                class: "sailing-date-time"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_el_date_picker, {
                                                                    modelValue: element.SailingDate,
                                                                    "onUpdate:modelValue": ($event: any) => ((element.SailingDate) = $event),
                                                                    type: "date",
                                                                    format: "YYYY/MM/DD",
                                                                    "value-format": "YYYY/MM/DD",
                                                                    class: "form-item-date"
                                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["prop", "rules"]))
                                                        ])
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  : (element.Code == 'Remark')
                                                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                                                        key: 8,
                                                        prop: `Schemes[${i}].FieldValues[${index}].Remark`,
                                                        rules: _ctx.rules.Remark
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_miezz_input, {
                                                            modelValue: element.Remark,
                                                            "onUpdate:modelValue": ($event: any) => ((element.Remark) = $event),
                                                            type: "textarea",
                                                            trim: false,
                                                            rows: 5
                                                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["prop", "rules"]))
                                                    : _createCommentVNode("", true)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldRowBtns, (btn, btn_i) => {
                                  return (_openBlock(), _createBlock(_component_miezz_button, {
                                    key: btn_i,
                                    model: btn,
                                    onHandleClick: 
                      (arg1, arg2) =>
                        _ctx.handleFieldRowButtonClick(arg1, index, arg2)
                    
                                  }, null, 8, ["model", "onHandleClick"]))
                                }), 128))
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onEnd"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_miezz_button, {
                      model: _ctx.addFieldBtn,
                      onHandleClick: _ctx.handleClick
                    }, null, 8, ["model", "onHandleClick"])
                  ])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_miezz_card, { title: "报价表" }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, [
                    _createTextVNode(" 合计： "),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Totals, (total, total_index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        style: {"margin-right":"5px","color":"red"},
                        key: total_index
                      }, _toDisplayString(total.CurrencySystem) + " " + _toDisplayString(total.Amount?.toFixed(2)), 1))
                    }), 128))
                  ]),
                  _createVNode(_component_el_table, {
                    data: item.Items,
                    border: "",
                    stripe: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        prop: "CostId",
                        label: "费用名称"
                      }, {
                        default: _withCtx(({ row, $index }) => [
                          _createVNode(_component_el_form_item, {
                            prop: `${i}.${$index}.CostId`,
                            rules: _ctx.rules.SupplierCostId,
                            style: {"margin-bottom":"13px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_select, {
                                modelValue: row.CostId,
                                "onUpdate:modelValue": ($event: any) => ((row.CostId) = $event),
                                label: row.Cost.FullName,
                                "onUpdate:label": ($event: any) => ((row.Cost.FullName) = $event),
                                options: _ctx.filterCosts,
                                clearable: true,
                                "filter-Method": _ctx.filterMethod,
                                onHandleVisibleChange: _ctx.handleVisibleChange
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "filter-Method", "onHandleVisibleChange"])
                            ]),
                            _: 2
                          }, 1032, ["prop", "rules"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_table_column, {
                        prop: "CurrencySystemId",
                        label: "币制",
                        width: "120px"
                      }, {
                        default: _withCtx(({ row, $index }) => [
                          _createVNode(_component_el_form_item, {
                            prop: `${i}.${$index}.CurrencySystemId`,
                            rules: _ctx.rules.SupplierCurrencySystemId,
                            style: {"margin-bottom":"13px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_select, {
                                modelValue: row.CurrencySystemId,
                                "onUpdate:modelValue": ($event: any) => ((row.CurrencySystemId) = $event),
                                label: row.CurrencySystem.Code,
                                "onUpdate:label": ($event: any) => ((row.CurrencySystem.Code) = $event),
                                options: _ctx.currencySystems,
                                clearable: false,
                                onHandleChange: _ctx.handleCalc
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "onHandleChange"])
                            ]),
                            _: 2
                          }, 1032, ["prop", "rules"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_table_column, {
                        prop: "Amount",
                        label: "单价",
                        width: "120px"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_form_item, { style: {"margin-bottom":"13px"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_input, {
                                modelValue: row.Amount,
                                "onUpdate:modelValue": ($event: any) => ((row.Amount) = $event),
                                pattern: "[0-9.-]",
                                number: true,
                                numberFixed: 2,
                                onHandleBlur: _ctx.handleCalc
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "FirstChargingUnitId",
                        label: "计价单位",
                        width: "120px"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_form_item, { style: {"margin-bottom":"13px"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_select, {
                                modelValue: row.FirstChargingUnitId,
                                "onUpdate:modelValue": ($event: any) => ((row.FirstChargingUnitId) = $event),
                                options: _ctx.units,
                                clearable: false
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "FirstChargingNumber",
                        label: "数量",
                        width: "120px"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_form_item, { style: {"margin-bottom":"13px"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_input, {
                                modelValue: row.FirstChargingNumber,
                                "onUpdate:modelValue": ($event: any) => ((row.FirstChargingNumber) = $event),
                                pattern: "[0-9.]",
                                number: true,
                                numberFixed: 2,
                                greaterThanNumber: 0,
                                onHandleBlur: _ctx.handleCalc
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "Total",
                        label: "合计",
                        width: "120px"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createTextVNode(_toDisplayString(row.Total?.toFixed(2)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "Description",
                        label: "说明"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_miezz_input, {
                            modelValue: row.Description,
                            "onUpdate:modelValue": ($event: any) => ((row.Description) = $event),
                            type: "textarea",
                            rows: 2,
                            trim: false
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        label: "操作",
                        fixed: "right",
                        width: "74px"
                      }, {
                        default: _withCtx(({ $index }) => [
                          _createVNode(_component_miezz_button, {
                            model: _ctx.deleteItemBtn,
                            onHandleClick: 
                  (arg1, arg2) => _ctx.handleDeleteItem(arg1, item, $index, arg2)
                
                          }, null, 8, ["model", "onHandleClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["data"]),
                  _createVNode(_component_miezz_button, {
                    model: _ctx.addItemBtn,
                    onHandleClick: (arg1, arg2) => _ctx.handleAddItem(arg1, item, arg2)
                  }, null, 8, ["model", "onHandleClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["label", "name"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onEdit"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalScheme,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalScheme) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.modalDataScheme,
          ref: "ruleFormScheme",
          class: "rule-form-scheme",
          "label-width": "66px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "名称",
              prop: "Name",
              rules: _ctx.rules.SchemeName
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.modalDataScheme.Name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDataScheme.Name) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["rules"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalField,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalField) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "field-form",
          model: _ctx.modalFieldForm,
          rules: _ctx.modalFieldFormRule,
          "label-width": "150px",
          inline: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "Ids" }, {
              label: _withCtx(() => [
                _createVNode(_component_el_checkbox, {
                  modelValue: _ctx.modalFieldForm.CheckAll,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalFieldForm.CheckAll) = $event)),
                  indeterminate: _ctx.modalFieldForm.CheckAllIndeterminate,
                  onChange: _ctx.handleFieldCheckAll
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("全选")
                  ]),
                  _: 1
                }, 8, ["modelValue", "indeterminate", "onChange"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: _ctx.modalFieldForm.Ids,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalFieldForm.Ids) = $event)),
                  onChange: _ctx.handleFieldCheck
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item) => {
                      return (_openBlock(), _createBlock(_component_el_checkbox, {
                        key: item.Value,
                        label: item.Value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Label), 1)
                        ]),
                        _: 2
                      }, 1032, ["label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}