import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 费用名称表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamPriceCost extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 费用名称表单-表单校验
 */
export const DtoFormParamPriceCostRules = {
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 费用名称表单-设置项
 */
export const DtoFormParamPriceCostMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
]
