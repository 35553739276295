import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f689a0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_thumbnail = _resolveComponent("miezz-thumbnail")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_group_form = _resolveComponent("group-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'JoinCompanyUserIds')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_page_list, {
                  modelValue: _ctx.companyUserList,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyUserList) = $event))
                }, {
                  column: _withCtx((scoped) => [
                    (scoped.model.Prop == 'User')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          (scoped.row.User.Avatar)
                            ? (_openBlock(), _createBlock(_component_miezz_thumbnail, {
                                key: 0,
                                modelValue: scoped.row.User.Avatar,
                                "onUpdate:modelValue": ($event: any) => ((scoped.row.User.Avatar) = $event),
                                size: 25
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", {
                            class: _normalizeClass('gender_' + scoped.row.User.Gender)
                          }, _toDisplayString(scoped.row.User.Name) + " / " + _toDisplayString(scoped.row.User.PhoneNumber), 3)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_group_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.formId,
          parentId: _ctx.parentId,
          path: _ctx.path,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "parentId", "path", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}