import UiSelectOption from "./UiSelectOption";

/**
 * 报关资料类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomsType {
    /**
     * 出口
     */
    Export = 10,
    /**
     * 进口
     */
    Import = 20,
}

/**
 * 报关资料类型-选项
 */
export const EnumCustomsTypeOption: UiSelectOption<number>[] = [
    { Label: "出口", Value: 10, Data: "Export" },
    { Label: "进口", Value: 20, Data: "Import" },
];
