import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-556adfe0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!

  return (_openBlock(), _createBlock(_component_miezz_menu_tab, {
    modelValue: _ctx.menuTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event)),
    onHandleClick: _ctx.handleMenuTabClick
  }, {
    left: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleClick"]))
}