import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumWharfBatchType, EnumWharfBatchTypeOption } from "./EnumWharfBatchType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoBatchWharf extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 服务
     */
    Service?: EnumOrderServiceType;
    /**
     * 类型
     */
    Type?: EnumWharfBatchType;
    /**
     * 全部入库/出库时间
     */
    StockTime?: Date;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 类型
     */
    SupplierCompanyTypeId?: string;
    /**
     * 类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商名称
     */
    SupplierCompanyId?: string;
    /**
     * 供应商名称
     */
    SupplierCompanyLabel?: string;
    /**
     * 联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 弹出框是否可见
     */
    Visiable?: boolean;
    /**
     * 弹出框标题
     */
    Title?: string;
    /**
     * 字段名称
     */
    Label?: string;
    /**
     * 校验的时间
     */
    CheckTime?: Date;
    /**
     * 是否全都有入库
     */
    IsAllInstock?: boolean;
    /**
     * 是否全都有出库
     */
    IsAllOutstock?: boolean;
    /**
     * 全部出/入库时间的初始值
     */
    CurrentTime?: Date;
}

/**
 * -表单校验
 */
export const DtoBatchWharfRules = {
}

/**
 * -设置项
 */
export const DtoBatchWharfMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "StockTime",
    Label: "全部入库/出库时间",
    Customize: true,
  },
  {
    Prop: "Visiable",
    Label: "弹出框是否可见",
    Customize: true,
  },
  {
    Prop: "Title",
    Label: "弹出框标题",
    Customize: true,
  },
  {
    Prop: "Label",
    Label: "字段名称",
    Customize: true,
  },
  {
    Prop: "CheckTime",
    Label: "校验的时间",
    Customize: true,
  },
]
