import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 业务类型
     */
    TransportType?: EnumTransportType;
    /**
     * 首页模板
     */
    FileA?: string;
    /**
     * 副页模板
     */
    FileB?: string;
}

/**
 * -表单校验
 */
export const DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplateRules = {
}

/**
 * -设置项
 */
export const DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplateMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "TransportType",
    Label: "业务类型",
    Customize: true,
  },
  {
    Prop: "FileA",
    Label: "首页模板",
    Customize: true,
  },
  {
    Prop: "FileB",
    Label: "副页模板",
    Customize: true,
  },
]

/**
 * -设置项
 */
export const DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplateMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TransportType",
    Label: "业务类型",
    Customize: true,
  },
  {
    Prop: "FileA",
    Label: "首页模板",
    Customize: true,
  },
  {
    Prop: "FileB",
    Label: "副页模板",
    Customize: true,
  },
]
