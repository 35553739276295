import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoDetailGroup from "../models.machine/DtoDetailGroup";
import DtoFormGroup from "../models.machine/DtoFormGroup";
import DtoTreeSearchGroup from "../models.machine/DtoTreeSearchGroup";
import DtoTreeGroup from "../models.machine/DtoTreeGroup";
import UiTreeDrag from "../models.machine/UiTreeDrag";

/**
 * 组
 * @description 自动生成的代码,请勿修改
 */
export default class GroupAppService {
    /**
     * 获取组详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailGroup>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailGroup>> {
        const url = `/api/Group/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailGroup>>(url);
    }

    /**
     * 添加组
     * @param {DtoFormGroup} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormGroup): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Group/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除组
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Group/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取树
     * @param {DtoTreeSearchGroup} [search] 树-搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoTreeGroup[]>>} 树
     */
    static GetTree(search?: DtoTreeSearchGroup): AxiosPromise<RESTfulResult<DtoTreeGroup[]>> {
        const url = `/api/Group/GetTree`;
        return service.get<RESTfulResult<DtoTreeGroup[]>>(url, { params: search });
    }

    /**
     * 拖曳排序
     * @param {UiTreeDrag<string>} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiTreeDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Group/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
