import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_container_trailer_page_list = _resolveComponent("container-trailer-page-list")!

  return (_openBlock(), _createBlock(_component_container_trailer_page_list, {
    list: _ctx.list,
    menuTab: _ctx.menuTab,
    addPower: "OrderManagement_Pool_Summary_DeparturePortContainer_Add",
    editPower: "OrderManagement_Pool_Summary_DeparturePortContainer_Edit",
    deletePower: "OrderManagement_Pool_Summary_DeparturePortContainer_Delete",
    serviceType: _ctx.serviceType,
    poolId: _ctx.$route.params.id
  }, null, 8, ["list", "menuTab", "serviceType", "poolId"]))
}