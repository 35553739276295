import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchHttpLog from "../models.machine/DtoPageListSearchHttpLog";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListHttpLog from "../models.machine/DtoPageListHttpLog";
import DtoDetailHttpLog from "../models.machine/DtoDetailHttpLog";

/**
 * Http日志
 * @description 自动生成的代码,请勿修改
 */
export default class HttpLogAppService {
    /**
     * 获取Http日志分页列表
     * @param {DtoPageListSearchHttpLog} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListHttpLog>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchHttpLog): AxiosPromise<RESTfulResult<DtoPageListHttpLog>> {
        const url = `/api/HttpLog/Get`;
        return service.get<RESTfulResult<DtoPageListHttpLog>>(url, { params: dto });
    }

    /**
     * 获取Http日志详细
     * @param {string} [id] Id
     * @param {string} [siteId] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailHttpLog>>} 详细
     */
    static GetById(id?: string, siteId?: string): AxiosPromise<RESTfulResult<DtoDetailHttpLog>> {
        const url = `/api/HttpLog/Get/${id}`;
        const _querys: any = {};
        _querys.siteId = siteId
        return service.get<RESTfulResult<DtoDetailHttpLog>>(url, { params: _querys });
    }

}
