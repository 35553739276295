import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchTradeTerms from "../models.machine/DtoSelectSearchTradeTerms";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchTradeTerms from "../models.machine/DtoPageListSearchTradeTerms";
import DtoPageListTradeTerms from "../models.machine/DtoPageListTradeTerms";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailTradeTerms from "../models.machine/DtoDetailTradeTerms";
import DtoFormTradeTerms from "../models.machine/DtoFormTradeTerms";

/**
 * 贸易条款
 * @description 自动生成的代码,请勿修改
 */
export default class TradeTermsAppService {
    /**
     * 获取贸易条款选择器
     * @param {DtoSelectSearchTradeTerms} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchTradeTerms): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/TradeTerms/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取贸易条款分页列表
     * @param {DtoPageListSearchTradeTerms} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListTradeTerms>>} 
     */
    static Get(dto?: DtoPageListSearchTradeTerms): AxiosPromise<RESTfulResult<DtoPageListTradeTerms>> {
        const url = `/api/TradeTerms/Get`;
        return service.get<RESTfulResult<DtoPageListTradeTerms>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TradeTerms/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取贸易条款详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailTradeTerms>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailTradeTerms>> {
        const url = `/api/TradeTerms/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailTradeTerms>>(url);
    }

    /**
     * 新增/编辑贸易条款
     * @param {DtoFormTradeTerms} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormTradeTerms): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TradeTerms/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除贸易条款
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/TradeTerms/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/TradeTerms/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/TradeTerms/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/TradeTerms/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/TradeTerms/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
