import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 装货港-仓库/堆场/码头分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchOrderDepartureWharf extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 类型
     */
    Type?: EnumOrderServiceType;
}

/**
 * 装货港-仓库/堆场/码头分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchOrderDepartureWharfRules = {
}

/**
 * 装货港-仓库/堆场/码头分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchOrderDepartureWharfDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
