import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 航空公司详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamAirlineCompany extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 查询网址
     */
    Url?: string;
}

/**
 * 航空公司详细-表单校验
 */
export const DtoDetailParamAirlineCompanyRules = {
}

/**
 * 航空公司详细-设置项
 */
export const DtoDetailParamAirlineCompanyMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Url",
    Label: "查询网址",
    Type: EnumMiezzFormItemType.Text,
  },
]
