
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzButton from "@/models/MiezzButton";
import { Options, Vue } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import MiezzButtonCheck from "./MiezzButtonCheck.vue";
import MiezzButtonColor from "./MiezzButtonColor.vue";
import MiezzButtonConfirm from "./MiezzButtonConfirm.vue";
import MiezzButtonDropdown from "./MiezzButtonDropdown.vue";
import MiezzButtonSource from "./MiezzButtonSource.vue";
import MiezzButtonUpload from "./MiezzButtonUpload.vue";

@Options({
  components: {
    MiezzButtonUpload,
    MiezzButtonCheck,
    MiezzButtonColor,
    MiezzButtonDropdown,
    MiezzButtonConfirm,
    MiezzButtonSource,
  },
})
export default class MiezzButtonComponent extends Vue {
  @Prop() model = new MiezzButton();
  @Prop() check?: boolean;
  @Prop() isBackground?: boolean;
  /**权限编码 */
  powerCodes?: string[] = [];

  async created(): Promise<void> {
    await this.onModelHideChanged();
  }

  @Watch("model.Hide")
  async onModelHideChanged(): Promise<void> {
    if (this.model.PowerCode) {
      this.powerCodes = await CurrentLogier.GetPowerCodes();
      this.model.Hide =
        this.model.Hide ||
        (this.powerCodes && !this.powerCodes.contains(this.model.PowerCode));
    } else {
      this.model.Hide = this.model.Hide ?? false;
    }
  }

  @Emit()
  handleFileUpload(model: MiezzButton, file: any): void {
    //
  }

  /**
   * 点击
   * @param config 配置
   * @param complete 回调事件
   */
  @Emit()
  handleClick(model: MiezzButton, complete: () => void): void {
    //
  }
}
