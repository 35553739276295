import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 组详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailGroup extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.JoinCompanyUserIds = [];
    }

    [proName: string]: any;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 用户
     */
    JoinCompanyUserIds?: string[];
}

/**
 * 组详细-表单校验
 */
export const DtoDetailGroupRules = {
}

/**
 * 组详细-设置项
 */
export const DtoDetailGroupMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "JoinCompanyUserIds",
    Label: "用户",
    Customize: true,
  },
]
