
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import PaymentTermsAppService from "@/apis.machine/PaymentTermsAppService";
import DtoFormPaymentTerms, {
  DtoFormPaymentTermsMiezzFormItems,
  DtoFormPaymentTermsRules,
} from "@/models.machine/DtoFormPaymentTerms";

export default class PaymentTermsForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormPaymentTerms, string>();
  NameList: string[] = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormPaymentTerms();
    //表单-校验
    this.form.Rules = DtoFormPaymentTermsRules;
    this.form.Rules.Name = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.Name != null && typeof (this.form.Data.Name) != "undefined" && this.form.Data.Name != "undefined" && this.form.Data.Name != "") {
            var Name = this.form.Data.Name.trim();
            if (this.NameList.some(it => it == Name)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormPaymentTermsMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    PaymentTermsAppService.GetNameList().then((r1) => {
      if (r1.data.Data) this.NameList = r1.data.Data;

      if (this.id) {
        PaymentTermsAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;

            var index = this.NameList.findIndex(it => { return it == this.form.Data.Name });
            this.NameList.splice(index, 1);
          }
        });
      }
    })
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            PaymentTermsAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    var optFullName:Array<string> = [];
    
    if (this.form.Data.Abbr) {
      optFullName?.push(this.form.Data.Abbr);
    }
    if (this.form.Data.Name) {
      optFullName?.push(this.form.Data.Name);
    }
    if (this.form.Data.NameEn) {
      optFullName?.push(this.form.Data.NameEn);
    }
    
    this.form.Data.FullName = optFullName.join("/");
  }

  onBlurName(): void {
    if (this.form.Data.Code) {
      this.form.Data.Code = this.form.Data.Code.toUpperCase();
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.onBlur();
  }

  onBlurAbbr(): void {
    if (this.form.Data.Abbr) {
      this.form.Data.Abbr = this.form.Data.Abbr.toUpperCase();
      this.form.Data.Abbr = this.form.Data.Abbr.trim();
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
