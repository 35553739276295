import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemFinance_Order from "./DtoListItemFinance_Order";
import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import DtoListItemFinance_OrderPool from "./DtoListItemFinance_OrderPool";
import DtoListItemFinance_Company from "./DtoListItemFinance_Company";
import DtoListItemFinance_JoinCompanyUser from "./DtoListItemFinance_JoinCompanyUser";
import DtoListItemFinance_ParamPriceCost from "./DtoListItemFinance_ParamPriceCost";
import DtoListItemFinance_ParamCurrencySystem from "./DtoListItemFinance_ParamCurrencySystem";
import DtoListItemFinance_ParamTransportChargeUnit from "./DtoListItemFinance_ParamTransportChargeUnit";
import { EnumBillShareType, EnumBillShareTypeOption } from "./EnumBillShareType";
import DtoListItemFinance_Invoice from "./DtoListItemFinance_Invoice";
import { EnumBillSettlementType, EnumBillSettlementTypeOption } from "./EnumBillSettlementType";
import { EnumBillCheckStatus, EnumBillCheckStatusOption } from "./EnumBillCheckStatus";

/**
 * 账单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemFinance extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsShare = false;
        this.Index = 0;
        this.Child = [];
        this.IsLock = false;
        this.IsOpen = false;
    }

    [proName: string]: any;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 订单
     */
    Order?: DtoListItemFinance_Order;
    /**
     * 提单号码
     */
    BLNO?: string;
    /**
     * 对账对象
     */
    BillObject?: string;
    /**
     * 业务员
     */
    SalerName?: string;
    /**
     * 开发票调用的对账对象
     */
    InvoiceBillObject?: string;
    /**
     * 类型
     */
    TypeString?: string;
    /**
     * 是否已保存：保存需要校验，客户企业才能看到
     */
    IsSave?: boolean;
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 是否已分摊，有委托书主键的账单默认为已分摊
     */
    IsShare?: boolean;
    /**
     * 从哪个账单分摊过来
     */
    FromId?: string;
    /**
     * 分摊给哪个账单
     */
    ToId?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoListItemFinance_OrderPool;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * 委托人
     */
    ClientCompany?: DtoListItemFinance_Company;
    /**
     * 业务员
     */
    SalerId?: string;
    /**
     * 业务员
     */
    Saler?: DtoListItemFinance_JoinCompanyUser;
    /**
     * 业务员
     */
    SalerNameLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompany?: DtoListItemFinance_Company;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商Id
     */
    SupplierUserId?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 供应商
     */
    PreSupplierLabel?: string;
    /**
     * 委托人
     */
    ClientLabel?: string;
    /**
     * 委托人
     */
    PreClientLabel?: string;
    /**
     * 费用名称
     */
    CostId?: string;
    /**
     * 费用名称
     */
    Cost?: DtoListItemFinance_ParamPriceCost;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoListItemFinance_ParamCurrencySystem;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 单位与数量
     */
    FirstChargeUnitNumber?: number;
    /**
     * 计价单位
     */
    FirstChargeUnitId?: string;
    /**
     * 计价单位
     */
    FirstChargeUnit?: DtoListItemFinance_ParamTransportChargeUnit;
    /**
     * 第二计价单位的数量
     */
    SecondChargeUnitNumber?: number;
    /**
     * 第二计价单位
     */
    SecondChargeUnitId?: string;
    /**
     * 第二计价单位
     */
    SecondChargeUnit?: DtoListItemFinance_ParamTransportChargeUnit;
    /**
     * 单项合计
     */
    TotalPrice?: number;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 财务周期
     */
    FinancialCycle?: Date;
    /**
     * 财务周期
     */
    FinancialCycleString?: string;
    /**
     * 业务周期
     */
    BusinessCycle?: Date;
    /**
     * 业务周期
     */
    BusinessCycleString?: string;
    /**
     * 记账起始时间
     */
    StartDate?: Date;
    /**
     * 记账起始时间
     */
    StartDateString?: string;
    /**
     * 账期/剩余天数
     */
    AccountPeriod?: number;
    /**
     * 账期/剩余天数
     */
    AccountPeriodString?: string;
    /**
     * 已核销的时间
     */
    SettlementTime?: Date;
    /**
     * 剩余天数
     */
    SurplusDays?: number;
    /**
     * 剩余天数
     */
    SurplusDayString?: string;
    /**
     * 序号
     */
    Index?: number;
    /**
     * 分摊方式
     */
    ShareType?: EnumBillShareType;
    /**
     * 分摊账单
     */
    Child?: DtoListItemFinance[];
    /**
     * 发票号码
     */
    Invoice?: DtoListItemFinance_Invoice;
    /**
     * 发票号码
     */
    InvoiceNumber?: string;
    /**
     * 核销状态
     */
    SettlementType?: EnumBillSettlementType;
    /**
     * 核销状态
     */
    SettlementTypeString?: string;
    /**
     * 订单是否锁定
     */
    IsLock?: boolean;
    /**
     * 是否展开
     */
    IsOpen?: boolean;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 对账对象
     */
    BillObjectId?: string;
    /**
     * 对账状态
     */
    CheckStatus?: EnumBillCheckStatus;
    /**
     * 对账状态
     */
    CheckStatusString?: string;
    /**
     * 是否亏损
     */
    IsLess?: boolean;
}

/**
 * 账单分页列表-项-表单校验
 */
export const DtoListItemFinanceRules = {
}

/**
 * 账单分页列表-项-设置项
 */
export const DtoListItemFinanceMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "订单编号",
    Customize: true,
  },
  {
    Prop: "BLNO",
    Label: "提单号码",
    Customize: true,
  },
  {
    Prop: "BillObject",
    Label: "对账对象",
    Customize: true,
  },
  {
    Prop: "SalerName",
    Label: "业务员",
    Customize: true,
  },
  {
    Prop: "TypeString",
    Label: "类型",
  },
  {
    Prop: "CostId",
    Label: "费用名称",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "FirstChargeUnitNumber",
    Label: "单位与数量",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "单项合计",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Customize: true,
  },
  {
    Prop: "FinancialCycleString",
    Label: "财务周期",
    Customize: true,
  },
  {
    Prop: "AccountPeriodString",
    Label: "账期/剩余天数",
    Customize: true,
  },
  {
    Prop: "InvoiceNumber",
    Label: "发票号码",
    Customize: true,
  },
  {
    Prop: "SettlementType",
    Label: "核销状态",
    Customize: true,
  },
  {
    Prop: "CheckStatusString",
    Label: "对账状态",
    Customize: true,
  },
]
