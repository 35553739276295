import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderTaskType, EnumOrderTaskTypeOption } from "./EnumOrderTaskType";
import DtoOrderTaskHomeOrder_Order from "./DtoOrderTaskHomeOrder_Order";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoOrderTaskHomeOrder extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumOrderTaskType.AddPool;
    }

    [proName: string]: any;
    /**
     * 订单编号
     */
    Code?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 类型
     */
    TypeName?: string;
    /**
     * 活跃的子目录
     */
    Active?: string;
    /**
     * 待办名称
     */
    Type?: EnumOrderTaskType;
    /**
     * 提单号码
     */
    MasterBillNo?: string;
    /**
     * 截关时间
     */
    CustomsEndDate?: Date;
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 订单
     */
    Order?: DtoOrderTaskHomeOrder_Order;
}

/**
 * -表单校验
 */
export const DtoOrderTaskHomeOrderRules = {
}

/**
 * -设置项
 */
export const DtoOrderTaskHomeOrderMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "订单编号",
  },
  {
    Prop: "Type",
    Label: "待办名称",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderTaskTypeOption,
  },
  {
    Prop: "MasterBillNo",
    Label: "提单号码",
  },
  {
    Prop: "CustomsEndDate",
    Label: "截关时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedDepartureDate",
    Label: "装货港ETD",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "EstimatedArrivalDate",
    Label: "目的港ETA",
    Pipe: EnumPipe.Date,
  },
]
