import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 价格分页列表-搜索条件分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchPrice extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
        this.AddSurcharge = false;
        this.Search = false;
    }

    [proName: string]: any;
    /**
     * 基础价格表Id
     */
    TableId?: string;
    /**
     * 服务费用组成Id
     */
    ServiceTableId?: string;
    /**
     * 同源附加费分组Id
     */
    SameSourceSurchargeGroupId?: string;
    /**
     * 附加费分组Id
     */
    SurchargeGroupId?: string;
    /**
     * 添加附加费
     */
    AddSurcharge?: boolean;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 运价查询
     */
    Search?: boolean;
}

/**
 * 价格分页列表-搜索条件分页列表-搜索条件-表单校验
 */
export const DtoPageListSearchPriceRules = {
}

/**
 * 价格分页列表-搜索条件分页列表-搜索条件-动态搜索配置项
 */
export const DtoPageListSearchPriceDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
]
