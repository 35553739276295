import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!

  return (_openBlock(), _createBlock(_component_miezz_select, {
    modelValue: _ctx.values,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values) = $event)),
    options: _ctx.options,
    multiple: true,
    disabled: _ctx.disabled,
    onChange: _ctx.handleBeforeChange
  }, _createSlots({ _: 2 }, [
    (_ctx.$slots['default'])
      ? {
          name: "default",
          fn: _withCtx(({ model }) => [
            _renderSlot(_ctx.$slots, "default", { model: model })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["modelValue", "options", "disabled", "onChange"]))
}