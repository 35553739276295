import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderDepartureWharf_Company from "./DtoListItemOrderDepartureWharf_Company";
import DtoListItemOrderDepartureWharf_ParamPort from "./DtoListItemOrderDepartureWharf_ParamPort";
import DtoListItemOrderDepartureWharf_ParamTransportChannel from "./DtoListItemOrderDepartureWharf_ParamTransportChannel";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock";
import DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex from "./DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderDepartureWharf extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsDeleted = false;
        this.IsShiftout = false;
        this.AddDeparturePortWarehouse = false;
    }

    [proName: string]: any;
    /**
     * 是否删除
     */
    IsDeleted?: boolean;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * 委托人
     */
    ClientCompany?: DtoListItemOrderDepartureWharf_Company;
    /**
     * 供应商
     */
    Supplier?: string;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 合计件数-入库
     */
    SumQuantityInStock?: number;
    /**
     * 合计毛重-入库
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积-入库
     */
    SumVolumeInStock?: number;
    /**
     * 计价单位-入库
     */
    ChargeUnitInStock?: string;
    /**
     * 合计件数-出库
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重-出库
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积-出库
     */
    SumVolumeOutStock?: number;
    /**
     * 计价单位-出库
     */
    ChargeUnitOutStock?: string;
    /**
     * 全部入库时间
     */
    InStockTime?: Date;
    /**
     * 计划入货时间
     */
    PlanInStockTime?: Date;
    /**
     * 全部出库时间
     */
    OutStockTime?: Date;
    /**
     * 提单号码
     */
    BLNO?: string;
    /**
     * 装货港
     */
    DispatchPortId?: string;
    /**
     * 装货港
     */
    DispatchPort?: DtoListItemOrderDepartureWharf_ParamPort;
    /**
     * 目的港
     */
    DestinationPortId?: string;
    /**
     * 目的港
     */
    DestinationPort?: DtoListItemOrderDepartureWharf_ParamPort;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 渠道Id
     */
    TransportChannelId?: string;
    /**
     * 渠道
     */
    TransportChannel?: DtoListItemOrderDepartureWharf_ParamTransportChannel;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 地址
     */
    Address?: string;
    /**
     * 联系人
     */
    Contacter?: string;
    /**
     * 作业时间
     */
    WorkTime?: string;
    /**
     * 收货截止时间
     */
    ReceivingDeadline?: Date;
    /**
     * 报关资料截止时间
     */
    CustomsDeadline?: Date;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 计划货量
     */
    Plans?: DtoListItemOrderDepartureWharf_OrderDepartureWharfPlan[];
    /**
     * 入库货量
     */
    InStocks?: DtoListItemOrderDepartureWharf_OrderDepartureWharfInStock[];
    /**
     * 出货货量
     */
    OutStocks?: DtoListItemOrderDepartureWharf_OrderDepartureWharfOutStock[];
    /**
     * 附件
     */
    Annexs?: DtoListItemOrderDepartureWharf_OrderDepartureWharfAnnex[];
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
    /**
     * 进仓编号
     */
    WarehousingCode?: string;
    /**
     * 是否编辑过进仓通知
     */
    IsEditWarehousing?: boolean;
    /**
     * 是否存在货物清单
     */
    IsGoods?: boolean;
    /**
     * 计划货量/入库货量/出库货量的合计件数是否一致
     */
    IsQuantity?: boolean;
    /**
     * 制定装货港进仓计划的待办是否处于正在执行
     */
    AddDeparturePortWarehouse?: boolean;
    /**
     * 计费重量
     */
    ChargeWeight?: number;
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoListItemOrderDepartureWharfRules = {
    SupplierCompanyTypeLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    SupplierCompanyLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    SupplierCompanyContactLabel: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoListItemOrderDepartureWharfMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "IsDeleted",
    Label: "是否删除",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "ClientCompanyId",
    Label: "委托人",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
  },
  {
    Prop: "Supplier",
    Label: "供应商",
  },
  {
    Prop: "SumQuantity",
    Label: "合计件数",
  },
  {
    Prop: "SumGrossWeight",
    Label: "合计毛重",
  },
  {
    Prop: "SumVolume",
    Label: "合计体积",
  },
  {
    Prop: "ChargeUnit",
    Label: "计价单位",
  },
  {
    Prop: "SumQuantityInStock",
    Label: "合计件数-入库",
  },
  {
    Prop: "SumGrossWeightInStock",
    Label: "合计毛重-入库",
  },
  {
    Prop: "SumVolumeInStock",
    Label: "合计体积-入库",
  },
  {
    Prop: "ChargeUnitInStock",
    Label: "计价单位-入库",
  },
  {
    Prop: "SumQuantityOutStock",
    Label: "合计件数-出库",
  },
  {
    Prop: "SumGrossWeightOutStock",
    Label: "合计毛重-出库",
  },
  {
    Prop: "SumVolumeOutStock",
    Label: "合计体积-出库",
  },
  {
    Prop: "ChargeUnitOutStock",
    Label: "计价单位-出库",
  },
  {
    Prop: "InStockTime",
    Label: "全部入库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "PlanInStockTime",
    Label: "计划入货时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "OutStockTime",
    Label: "全部出库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "BLNO",
    Label: "提单号码",
  },
  {
    Prop: "DispatchPortId",
    Label: "装货港",
  },
  {
    Prop: "DispatchPort",
    Label: "装货港",
  },
  {
    Prop: "DestinationPortId",
    Label: "目的港",
  },
  {
    Prop: "DestinationPort",
    Label: "目的港",
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
  },
  {
    Prop: "TransportChannelId",
    Label: "渠道Id",
  },
  {
    Prop: "TransportChannel",
    Label: "渠道",
  },
  {
    Prop: "TransportChannelLabel",
    Label: "渠道",
  },
  {
    Prop: "Address",
    Label: "地址",
  },
  {
    Prop: "Contacter",
    Label: "联系人",
  },
  {
    Prop: "WorkTime",
    Label: "作业时间",
  },
  {
    Prop: "ReceivingDeadline",
    Label: "收货截止时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "CustomsDeadline",
    Label: "报关资料截止时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
  {
    Prop: "Plans",
    Label: "计划货量",
  },
  {
    Prop: "InStocks",
    Label: "入库货量",
  },
  {
    Prop: "OutStocks",
    Label: "出货货量",
  },
  {
    Prop: "Annexs",
    Label: "附件",
  },
  {
    Prop: "SupplierSource",
    Label: "供应商来源",
    Pipe: EnumPipe.Enum,
    Options: EnumSupplierSourceOption,
  },
  {
    Prop: "SupplierCompanyTypeId",
    Label: "供应商类型Id",
  },
  {
    Prop: "SupplierCompanyTypeLabel",
    Label: "供应商类型",
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商Id",
  },
  {
    Prop: "SupplierCompanyLabel",
    Label: "供应商",
  },
  {
    Prop: "SupplierCompanyContactLabel",
    Label: "供应商联系人",
  },
  {
    Prop: "PoolId",
    Label: "委托书主键",
  },
  {
    Prop: "OrderId",
    Label: "订单主键",
  },
  {
    Prop: "WarehousingCode",
    Label: "进仓编号",
  },
  {
    Prop: "IsEditWarehousing",
    Label: "是否编辑过进仓通知",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsGoods",
    Label: "是否存在货物清单",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "IsQuantity",
    Label: "计划货量/入库货量/出库货量的合计件数是否一致",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "AddDeparturePortWarehouse",
    Label: "制定装货港进仓计划的待办是否处于正在执行",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "ChargeWeight",
    Label: "计费重量",
  },
]
