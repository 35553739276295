import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd17f07a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_avatar = _resolveComponent("el-avatar")!

  return (_ctx.type == 'Flag')
    ? (_openBlock(), _createBlock(_component_el_image, {
        key: 0,
        class: "thumbnail",
        style: {"width":"38px","height":"25px"},
        src: _ctx.url,
        fit: "contain"
      }, null, 8, ["src"]))
    : (_ctx.type == 'image')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_ctx.url!=null && _ctx.url!=undefined && typeof(_ctx.url)!='undefined' && _ctx.url!='' && _ctx.url!='undefined')
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                class: "thumbnail",
                style: {"height":"30px","cursor":"pointer"},
                src: _ctx.url,
                fit: "contain",
                onClick: _ctx.onDetail
              }, null, 8, ["src", "onClick"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, "暂无数据"))
        ]))
      : (_openBlock(), _createBlock(_component_el_avatar, {
          key: 2,
          class: "thumbnail",
          size: _ctx.size,
          src: _ctx.url
        }, null, 8, ["size", "src"]))
}