import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackAccountPeriod {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * 费用主键
     */
    CostId?: string;
    /**
     * 委托人公司主键
     */
    ClientCompanyId?: string;
    /**
     * 供应商公司主键
     */
    SupplierCompanyId?: string;
    /**
     * 起始时间
     */
    Start?: Date;
    /**
     * 账期天数
     */
    Day?: number;
    /**
     * 账单类型
     */
    Type?: EnumBillType;
}

/**
 * -表单校验
 */
export const DtoCallbackAccountPeriodRules = {
}
