import UiSelectOption from "./UiSelectOption";

/**
 * 关务状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomsStatus {
    /**
     * 待添加资料
     */
    WaitAddInfo = 1,
    /**
     * 添加资料中
     */
    IngAddInfo = 2,
    /**
     * 已添加资料
     */
    EdAddInfo = 3,
    /**
     * 放行中
     */
    IngRelease = 4,
    /**
     * 已放行
     */
    EdRelease = 5,
}

/**
 * 关务状态-选项
 */
export const EnumCustomsStatusOption: UiSelectOption<number>[] = [
    { Label: "待添加资料", Value: 1, Data: "WaitAddInfo" },
    { Label: "添加资料中", Value: 2, Data: "IngAddInfo" },
    { Label: "已添加资料", Value: 3, Data: "EdAddInfo" },
    { Label: "放行中", Value: 4, Data: "IngRelease" },
    { Label: "已放行", Value: 5, Data: "EdRelease" },
];
