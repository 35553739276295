
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyBusinessCardBankAppService from "@/apis.machine/CompanyBusinessCardBankAppService";
import DtoPageListSearchCompanyBusinessCardBank, {
  DtoPageListSearchCompanyBusinessCardBankDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyBusinessCardBank";
import DtoPageListCompanyBusinessCardBank from "@/models.machine/DtoPageListCompanyBusinessCardBank";
import DtoListItemCompanyBusinessCardBank, {
  DtoListItemCompanyBusinessCardBankMiezzListItems,
} from "@/models.machine/DtoListItemCompanyBusinessCardBank";
import CompanyBusinessCardBankForm from "./CompanyBusinessCardBankForm.vue";
import MiezzSelect from "@/components/MiezzSelect.vue";

@Options({
  components: {
    CompanyBusinessCardBankForm,
    MiezzSelect,
  },
})
export default class CompanyBusinessCardBankPageList extends Vue {
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompanyBusinessCardBank,
    DtoListItemCompanyBusinessCardBank,
    string
  >;
  businessCardId?: string;
  /**当前Id */
  id?: string = "";
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.businessCardId = this.$route.params.id as string;
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    this.list.Type = MiezzPageListType.Card;
    this.list.Back = true;
    this.list.EnabledSelect = false;
    this.list.Title = "银行";
    this.list.EnabledRadioSearch = false;
    this.list.CardLabelWidth = "123px";
    this.list.CardSpan = 33.33;
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyBusinessCardBank();

    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyBusinessCardBankDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyBusinessCardBank();
    //列表-列配置
    this.list.Items = DtoListItemCompanyBusinessCardBankMiezzListItems;
  }

  /**查询列表 */
  handleGet(): void {
    const dto = Object.copy(this.list.Dto);
    dto.CompanyBusinessCardId = this.$route.params.id as string;
    CompanyBusinessCardBankAppService.Get(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.id = undefined;
        this.modalForm.Show = true;
        this.modalForm.Title = "新增银行";
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyBusinessCardBank,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "edit":
        this.id = row.Id;
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑银行`;
        complete();
        break;
      case "delete":
        CompanyBusinessCardBankAppService.Delete([row.Id as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  handleSetDefaultAccount(bankId: string, id: string): void {
    CompanyBusinessCardBankAppService.SetDefaultAccountByBankIdById(bankId, id);
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }
}
