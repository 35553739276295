
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { init, EChartsOption } from "echarts";
    export default class FinanceStatIndex extends Vue {
        isLoading = false;
        created(): void {
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("FinancialManagement_StatisticalAnalysis_Overview")) {
                     this.$router.push("/finance/stat/overview");
                }
                else if (r?.contains("FinancialManagement_StatisticalAnalysis_SalespersonRelated")) {
                     this.$router.push("/finance/stat/saler");
                }
                else if (r?.contains("FinancialManagement_StatisticalAnalysis_CustomerRelated")) {
                     this.$router.push("/finance/stat/customer");
                }

            });
        }
    }


