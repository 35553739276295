
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ParamPriceFieldAppService from "@/apis.machine/ParamPriceFieldAppService";
import DtoPageListSearchParamPriceField, { DtoPageListSearchParamPriceFieldDynamicSearchItems } from "@/models.machine/DtoPageListSearchParamPriceField";
import DtoPageListParamPriceField from "@/models.machine/DtoPageListParamPriceField";
import DtoListItemParamPriceField, {
  DtoListItemParamPriceFieldMiezzListItems,
} from "@/models.machine/DtoListItemParamPriceField";
import ParamPriceFieldDetail from "./ParamPriceFieldDetail.vue";
import ParamPriceFieldForm from "./ParamPriceFieldForm.vue";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    ParamPriceFieldDetail,
    ParamPriceFieldForm,
  },
})
export default class ParamPriceFieldPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<DtoPageListSearchParamPriceField, DtoListItemParamPriceField, string>("System_ParamList_PriceField");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.list.Back = true;
    this.list.Title = "价格管理要素";
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchParamPriceField();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchParamPriceFieldDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListParamPriceField();
    //列表-列配置
    this.list.Items = DtoListItemParamPriceFieldMiezzListItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Type: "primary",
        Size: "small",
        Width: "65px"
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "warning",
        Size: "small",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        // Message: "是否确认删除",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    ParamPriceFieldAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    ParamPriceFieldAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

            
  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增价格管理要素";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemParamPriceField,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `价格管理要素详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      //编辑
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑价格管理要素-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ElMessageBox.confirm('是否确认将该信息删除？', '提醒', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          ParamPriceFieldAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        })
        .catch(() => {
          complete();
        });
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
