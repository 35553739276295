import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormProduct_Alias from "./DtoFormProduct_Alias";
import DtoFormProduct_CustomsCode from "./DtoFormProduct_CustomsCode";
import UiGroup from "./UiGroup";

/**
 * 产品基础信息表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormProduct extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.IfShelf = false;
        this.Aliass = [];
        this.CustomsCodes = [];
        this.SpecQuantity = 0;
        this.PackageQuantity = 0;
        this.UiGroups = [{"Code":"ProductForm","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 自定义编号
     */
    Code?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 拉丁名称
     */
    NameLatin?: string;
    /**
     * 海关编码
     */
    HsCode?: string;
    /**
     * 材质
     */
    Material?: string;
    /**
     * 用途
     */
    Purpose?: string;
    /**
     * 是否上架
     */
    IfShelf?: boolean;
    /**
     * 中文计价单位
     */
    ChargeUnithZh?: string;
    /**
     * 英文计价单位
     */
    ChargeUnithEn?: string;
    /**
     * 第一法定单位：同步HSCode
     */
    StatutoryFirstUnit?: string;
    /**
     * 第二法定单位：同步HSCode
     */
    StatutorySecondUnit?: string;
    /**
     * 别名集合
     */
    Aliass?: DtoFormProduct_Alias[];
    /**
     * 其他国家的海关编码集合
     */
    CustomsCodes?: DtoFormProduct_CustomsCode[];
    /**
     * 产品所属公司主键
     */
    CompanyId?: string;
    /**
     * 规格数量：同步规格
     */
    SpecQuantity?: number;
    /**
     * 包装数量：同步包装
     */
    PackageQuantity?: number;
}

/**
 * 产品基础信息表单-表单校验
 */
export const DtoFormProductRules = {
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameLatin: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    HsCode: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Material: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Purpose: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ChargeUnithZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ChargeUnithEn: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    StatutoryFirstUnit: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    StatutorySecondUnit: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 产品基础信息表单-设置项
 */
export const DtoFormProductMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "自定义编号",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NameLatin",
    Label: "拉丁名称",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "海关编码",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Material",
    Label: "材质",
    Group: "ProductForm",
    Span: 24,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Purpose",
    Label: "用途",
    Group: "ProductForm",
    Span: 24,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargeUnithZh",
    Label: "中文计价单位",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ChargeUnithEn",
    Label: "英文计价单位",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "StatutoryFirstUnit",
    Label: "第一法定单位：同步HSCode",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "StatutorySecondUnit",
    Label: "第二法定单位：同步HSCode",
    Group: "ProductForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "产品所属公司主键",
    Type: EnumMiezzFormItemType.InputText,
  },
]
