import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheet_TrackRecord extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 时间
     */
    Date?: Date;
    /**
     * 内容
     */
    Content?: string;
}

/**
 * -表单校验
 */
export const DtoFormPriceQuotationSheet_TrackRecordRules = {
    Date: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Content: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormPriceQuotationSheet_TrackRecordMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Date",
    Label: "时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Content",
    Label: "内容",
    Customize: true,
  },
]

/**
 * -设置项
 */
export const DtoFormPriceQuotationSheet_TrackRecordMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Date",
    Label: "时间",
    Customize: true,
  },
  {
    Prop: "Content",
    Label: "内容",
    Customize: true,
  },
]
