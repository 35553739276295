
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyCreditAndAccountingPeriodDetail from "../../CompanyCreditAndAccountingPeriodDetail.vue";

@Options({
  components: {
    CompanyCreditAndAccountingPeriodDetail,
  },
})
export default class SupplierPrivateCompanyCreditAndAccountingPeriodDetail extends Vue {
  menuTab = new MiezzMenuTab();
  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "授信与账期",
        Power: "SupplierManagement_Private_CreditAndAccountingPeriod",
      },
    ];
  }
}
