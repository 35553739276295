import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceFormula {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 价格Id
     */
    Ids?: string[];
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 公式
     */
    Formula?: string;
    /**
     * 结果 =
     */
    Result?: string;
}

/**
 * -表单校验
 */
export const DtoFormPriceFormulaRules = {
    Formula: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormPriceFormulaMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Formula",
    Label: "公式",
    Customize: true,
  },
  {
    Prop: "Result",
    Label: "结果 =",
    Customize: true,
  },
]
