import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchPackageZh from "../models.machine/DtoSelectSearchPackageZh";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchPackageZh from "../models.machine/DtoPageListSearchPackageZh";
import DtoPageListPackageZh from "../models.machine/DtoPageListPackageZh";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailPackageZh from "../models.machine/DtoDetailPackageZh";
import DtoFormPackageZh from "../models.machine/DtoFormPackageZh";

/**
 * 中文包装种类
 * @description 自动生成的代码,请勿修改
 */
export default class PackageZhAppService {
    /**
     * 获取HsCode选择器
     * @param {DtoSelectSearchPackageZh} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchPackageZh): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/PackageZh/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取中文包装种类分页列表
     * @param {DtoPageListSearchPackageZh} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListPackageZh>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchPackageZh): AxiosPromise<RESTfulResult<DtoPageListPackageZh>> {
        const url = `/api/PackageZh/Get`;
        return service.get<RESTfulResult<DtoPageListPackageZh>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageZh/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取中文包装种类详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPackageZh>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPackageZh>> {
        const url = `/api/PackageZh/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPackageZh>>(url);
    }

    /**
     * 新增/编辑中文包装种类
     * @param {DtoFormPackageZh} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormPackageZh): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageZh/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除中文包装种类
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/PackageZh/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PackageZh/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/PackageZh/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/PackageZh/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/PackageZh/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
