
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoListItemCompany, {
  DtoListItemCompanyMiezzListItems,
} from "@/models.machine/DtoListItemCompany";
import DtoPageListSearchCompany, {
  DtoPageListSearchCompanyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumSupplierSource } from "@/models.machine/EnumSupplierSource";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyPageList from "../../CompanyPageList.vue";
@Options({
  components: {
    CompanyPageList,
  },
})
export default class SupplierServiceProviderCompanyPageList extends Vue {
  type = EnumCompanyType.Supplier;
  list = new MiezzPageList<
    DtoPageListSearchCompany,
    DtoListItemCompany,
    string
  >("SupplierManagement_ServiceProvider");
  /**公司类型选择器 */
  companyTypes?: UiSelectOption<string>[] = [];

  created(): void {
    let items = DtoListItemCompanyMiezzListItems.copy() as MiezzListItem[];
    items = items.filter(
      (it) =>
        it.Prop != "ServiceProviderCompanyId" &&
        it.Prop != "UserCount" &&
        it.Prop != "Source" &&
        it.Prop != "OpenThreeApiService"
    );
    for (const item of items) {
      switch (item.Prop) {
        case "JoinCompanyTypes":
          item.Label = "服务类型";
          break;

        default:
          break;
      }
    }
    this.list.Items = items;
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyDynamicSearchItems.copy().filter(
        (it) =>
          it.Prop != "DynamicSearch_ServiceProviderCompanyId" &&
          it.Prop != "DynamicSearch_JoinCompanyUsers"
      );

    CompanyTypeAppService.GetSelect({ Type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });

    //列表-传参
    this.list.Dto = new DtoPageListSearchCompany();
    this.list.Dto.SupplierSource = EnumSupplierSource.ServiceProvider;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "SupplierManagement_ServiceProvider_Detail",
      },
    ];
  }
}
