
import MenuAppService from "@/apis.machine/MenuAppService";
import PowerAppService from "@/apis.machine/PowerAppService";
import DtoDetailPower, {
  DtoDetailPowerMiezzDetailItems,
} from "@/models.machine/DtoDetailPower";
import DtoFormPower from "@/models.machine/DtoFormPower";
import DtoTreeMenu from "@/models.machine/DtoTreeMenu";
import DtoTreeSearchMenu from "@/models.machine/DtoTreeSearchMenu";
import { EnumUiApiMethod } from "@/models.machine/EnumUiApiMethod";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop } from "vue-property-decorator";
import ChooseMenu from "./ChooseMenu.vue";
import PowerForm from "./PowerForm.vue";
@Options({
  components: {
    PowerForm,
    ChooseMenu,
  },
})
export default class PowerDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailPower, string>();

  modalForm = new MiezzModal();
  formId?: string;
  parentId?: string;
  path?: string;

  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  menuTree: DtoTreeMenu[] = [];

  /**创建时 */
  created(): void {
    this.detail.Data = new DtoDetailPower();
    this.detail.Items = DtoDetailPowerMiezzDetailItems;

    if (process.env.NODE_ENV == "production") {
      this.detail.Items = this.detail.Items.filter((it) => it.Prop != "IsTest");
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
      },
      {
        Code: "add-children",
        Title: "添加子菜单",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
      },
    ];
    this.handleGet();

    this.modalForm.Width = "60%";
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      PowerAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
        this.getMenuTree();
      });
    }
  }

  getMenuTree(): void {
    const detailData = this.detail.Data as DtoFormPower;
    const dto = new DtoTreeSearchMenu();
    dto.Scene = detailData.Scene;
    dto.CheckIds = detailData.MenuIds;
    dto.OnlyShowCheck = true;
    MenuAppService.GetTree(dto).then((r) => {
      if (r.data.Data) {
        this.menuTree = r.data.Data;
      } else {
        this.menuTree = [];
      }
    });
  }

  getMethod(value: EnumUiApiMethod): string | undefined {
    return EnumUiApiMethod[value];
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    const data = this.detail.Data as DtoFormPower;
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑权限-${this.detail.Data?.Name}`;
        this.formId = this.id;
        complete();
        break;
      case "add-children":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增权限";
        this.formId = undefined;
        this.parentId = data.Id;
        this.path = data.Path ? `${data.Path}${data.Name}/` : `${data.Name}/`;
        complete();
        break;
      case "delete":
        PowerAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
