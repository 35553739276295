
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormAccountPeriod {
    constructor() {
        //无参构造函数
        this.AccountPeriod = 0;
    }

    [proName: string]: any;
    /**
     * 账单主键集合
     */
    BillIds?: string[];
    /**
     * 账期
     */
    AccountPeriod?: number;
    /**
     * 
     */
    Time?: Date;
}

/**
 * -表单校验
 */
export const DtoFormAccountPeriodRules = {
}
