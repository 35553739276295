import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 报价表表单-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceQuotationSheet_PriceQuotationSheetItem extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 费用名称
     */
    CostId?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 单价
     */
    Amount?: number;
    /**
     * 计价单位
     */
    FirstChargingUnitId?: string;
    /**
     * 数量
     */
    FirstChargingNumber?: number;
    /**
     * 第二计价单位
     */
    SecondChargingUnitId?: string;
    /**
     * 第二计价数量
     */
    SecondChargingNumber?: number;
    /**
     * 合计
     */
    Total?: number;
    /**
     * 说明
     */
    Description?: string;
}

/**
 * 报价表表单-项-表单校验
 */
export const DtoFormPriceQuotationSheet_PriceQuotationSheetItemRules = {
    Description: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 报价表表单-项-设置项
 */
export const DtoFormPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CostId",
    Label: "费用名称",
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Amount",
    Label: "单价",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "计价单位",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    MaxWidth: "120px",
    Customize: true,
  },
  {
    Prop: "Total",
    Label: "合计",
    MaxWidth: "120px",
  },
  {
    Prop: "Description",
    Label: "说明",
    Customize: true,
  },
]

/**
 * 报价表表单-项-设置项
 */
export const DtoFormPriceQuotationSheet_PriceQuotationSheetItemMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CostId",
    Label: "费用名称",
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Customize: true,
  },
  {
    Prop: "Amount",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "计价单位",
    Customize: true,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "Total",
    Label: "合计",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Description",
    Label: "说明",
    Customize: true,
  },
]
