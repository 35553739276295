import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCompanyCodeRule_CompanyCodeRule from "./DtoFormCompanyCodeRule_CompanyCodeRule";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyCodeRule extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.CodeRules = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司英文缩写
     */
    NameEnAbbr?: string;
    /**
     * 业务类型缩写
     */
    CodeRules?: DtoFormCompanyCodeRule_CompanyCodeRule[];
    /**
     * 编号规则
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoFormCompanyCodeRuleRules = {
    NameEnAbbr: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCompanyCodeRuleMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameEnAbbr",
    Label: "公司英文缩写",
    Customize: true,
  },
  {
    Prop: "CodeRules",
    Label: "业务类型缩写",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "编号规则",
    Customize: true,
  },
]
