import UiSelectOption from "./UiSelectOption";

/**
 * 审核状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumAuditStatus {
    /**
     * 未审核
     */
    No = 0,
    /**
     * 审核中
     */
    Ing = 1,
    /**
     * 已通过
     */
    Success = 2,
    /**
     * 未通过
     */
    Fail = 3,
}

/**
 * 审核状态-选项
 */
export const EnumAuditStatusOption: UiSelectOption<number>[] = [
    { Label: "未审核", Value: 0, Data: "No" },
    { Label: "审核中", Value: 1, Data: "Ing" },
    { Label: "已通过", Value: 2, Data: "Success" },
    { Label: "未通过", Value: 3, Data: "Fail" },
];
