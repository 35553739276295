import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchOrderTask from "../models.machine/DtoPageListSearchOrderTask";
import DtoPageListOrderTask from "../models.machine/DtoPageListOrderTask";
import DtoListItemOrderTask from "../models.machine/DtoListItemOrderTask";
import DtoRequestOrderTaskAssignUser from "../models.machine/DtoRequestOrderTaskAssignUser";
import DtoOrderTaskHomeOrderPool from "../models.machine/DtoOrderTaskHomeOrderPool";
import DtoOrderTaskHomeOrder from "../models.machine/DtoOrderTaskHomeOrder";

/**
 * 订单待办
 * @description 自动生成的代码,请勿修改
 */
export default class OrderTaskAppService {
    /**
     * 获取待办执行人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetTaskUsers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderTask/GetTaskUsers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取待办分页列表
     * @param {DtoPageListSearchOrderTask} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderTask>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderTask): AxiosPromise<RESTfulResult<DtoPageListOrderTask>> {
        const url = `/api/OrderTask/Get`;
        return service.get<RESTfulResult<DtoPageListOrderTask>>(url, { params: dto });
    }

    /**
     * 获取待办列表
     * @param {string} [poolOrOrderId] 委托书或订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoListItemOrderTask[]>>} 待办列表
     */
    static GetByPoolOrOrderId(poolOrOrderId?: string): AxiosPromise<RESTfulResult<DtoListItemOrderTask[]>> {
        const url = `/api/OrderTask/Get/${poolOrOrderId}`;
        return service.get<RESTfulResult<DtoListItemOrderTask[]>>(url);
    }

    /**
     * 指派执行人
     * @param {DtoRequestOrderTaskAssignUser} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignUser(dto?: DtoRequestOrderTaskAssignUser): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderTask/AssignUser`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取首页委托书待办列表
     * @returns {AxiosPromise<RESTfulResult<DtoOrderTaskHomeOrderPool[]>>} 委托书待办列表
     */
    static GetHomeOrderPoolTasks(): AxiosPromise<RESTfulResult<DtoOrderTaskHomeOrderPool[]>> {
        const url = `/api/OrderTask/GetHomeOrderPoolTasks`;
        return service.get<RESTfulResult<DtoOrderTaskHomeOrderPool[]>>(url);
    }

    /**
     * 获取首页订单待办列表
     * @returns {AxiosPromise<RESTfulResult<DtoOrderTaskHomeOrder[]>>} 委托书待办列表
     */
    static GetHomeOrderTasks(): AxiosPromise<RESTfulResult<DtoOrderTaskHomeOrder[]>> {
        const url = `/api/OrderTask/GetHomeOrderTasks`;
        return service.get<RESTfulResult<DtoOrderTaskHomeOrder[]>>(url);
    }

}
