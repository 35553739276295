
    import moment from "moment";
    import OSS from "ali-oss";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import DocumentAppService from "@/apis.machine/DocumentAppService";
    import PackageZhAppService from "@/apis.machine/PackageZhAppService";
    import DtoDetailDocument, {
        DtoDetailDocumentMiezzDetailItems,
    } from "@/models.machine/DtoDetailDocument";
    //import DocumentForm from "./DocumentForm.vue";
    import AliyunOSS from "@/apis/AliyunOSS";

    //@Options({
    //    components: {
    //        DocumentForm,
    //    },
    //})
    export default class DocumentDetail extends Vue {
        isLoading = false;
        //包装种类
        packages: UiSelectOption<string>[] = [];
        selectPackages: UiSelectOption<string>[] = [];
        packRuleForm = {
            Visible: false,
        };
        /*单证主键 */
        id = "";
        //类型：10出口，20进口
        type = "10";
        isContact = true;
        isCustoms = true;
        isAnnex = true;
        client?: OSS;
        packageBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                Type: "text",
                Size: "small",
                MiezzIcon: "back",
            },
            {
                Code: "edit",
                Label: "编辑",
                Type: "text",
                Size: "small",
                PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Detail_Edit",
                Icon: "edit",
            },
            //{
            //    Code: "delete",
            //    Label: "删除",
            //    Type: "text",
            //    Size: "small",
            //    PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Delete",
            //    Icon: "delete",
            //},
            //{
            //    Code: "copy",
            //    Label: "复制",
            //    Type: "text",
            //    Size: "small",
            //    PowerCode: "OrderManagement_DocumentManagement_SelfmadeDocuments_Copy",
            //    Icon: "DocumentCopy",
            //},

        ];
        //是否信用证
        isCredit = false;

        detail = new MiezzForm<DtoDetailDocument, string>();

        /**创建时 */
        created(): void {
            this.client = AliyunOSS.GetClient();
            this.id = this.$route.params.id as string;
            //详细-数据-初始化
            this.detail.Data = new DtoDetailDocument();
            //详细-元素配置
            this.detail.Items = DtoDetailDocumentMiezzDetailItems;

            this.handleGet();
        }
        onProductDetail(row: any): void {
            const CustomsProductDetail = this.$refs["CustomsProductDetail"] as any;
            CustomsProductDetail.onInit(row);
        }
        onExport(): void {
            var ids = [];
            ids.push(this.id);
            var param = {
                Ids: ids,
            };

            DocumentAppService.Export(param).then((r) => {
                window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            });
        }
        onPackage(): void {
            this.packRuleForm.Visible = true;
            this.$nextTick(() => {
                this.selectPackages = [];
                const packageTable = this.$refs["packageTable"] as any;
                packageTable.clearSelection();
                var packages = this.detail.Data.OtherPackName?.split('|');
                if (this.packages) {
                    for (var it of this.packages) {
                        it.isSelected = false;
                        if (packages != undefined && packages.length > 0) {
                            for (var pack of packages) {
                                if (pack == it.Label) {
                                    it.isSelected = true;
                                }
                            }
                        }
                    }
                }
            });


        }

        /**查询详细 */
        handleGet(): void {
            if (this.id) {
                this.isLoading = true;
                PackageZhAppService.GetSelect().then(pack => {
                    if (pack.data.Data) this.packages = pack.data.Data;
                    DocumentAppService.GetById(this.id).then((r) => {
                        if (r.data.Data) this.detail.Data = r.data.Data;
                        if (this.detail.Data) {
                            if (this.detail.Data.DocumentAnnexs != null && this.detail.Data.DocumentAnnexs != undefined && this.detail.Data.DocumentAnnexs.length > 0) {
                                for (var annex of this.detail.Data.DocumentAnnexs) {
                                    annex.Name = "";
                                    if (annex.Url) {
                                        var urls = annex.Url.split('/');
                                        annex.Name = urls[urls.length - 1];
                                        annex.Url = this.client?.signatureUrl(annex.Url) as string;
                                    }
                                }
                            }


                            this.type = this.detail.Data.Type + "";
                            if (this.detail.Data.PaymentTerms) {
                                this.isCredit = this.detail.Data.PaymentTerms.IsCredit ?? false;
                            }

                            this.detail.Data.InvoiceDateString = "";
                            if (this.detail.Data.InvoiceDate) {
                                this.detail.Data.InvoiceDateString = moment(this.detail.Data.InvoiceDate).format("YYYY/MM/DD");
                            }

                            this.detail.Data.OrderDateString = "";
                            if (this.detail.Data.OrderDate) {
                                this.detail.Data.OrderDateString = moment(this.detail.Data.OrderDate).format("YYYY/MM/DD");
                            }

                            this.detail.Data.IssuingDateString = "";
                            if (this.detail.Data.IssuingDate) {
                                this.detail.Data.IssuingDateString = moment(this.detail.Data.IssuingDate).format("YYYY/MM/DD");
                            }

                            //if (this.detail.Data.DocumentAnnexs != undefined && this.detail.Data.DocumentAnnexs.length > 0) {
                            //    for (var annex of this.detail.Data.DocumentAnnexs) {
                            //        if (annex.Url != undefined) {
                            //            annex.Url = this.client?.signatureUrl(annex.Url as string);
                            //        }
                            //    }
                            //}

                            this.onCountCustomsProduct();
                            this.onCheckElement();
                            this.isLoading = false;
                        }

                    });

                })
            }
        }
        onCountCustomsProduct(): void {

            if (this.detail.Data.DocumentProducts) {
                for (var it of this.detail.Data.DocumentProducts) {
                    it.NewPrice = "";
                    if (it.TransactionQuantity) {
                        it.NewPrice = it.TransactionQuantity + "";
                    }
                    if (it.ChargeUnit) {
                        it.NewPrice += it.ChargeUnit + "";
                    }

                    if (it.StatutoryFirstUnitValue) {
                        if (it.StatutoryFirstUnit && it.StatutoryFirstUnit != it.ChargeUnit && it.StatutoryFirstUnit != "无") {
                            if (it.NewPrice != "") {
                                it.NewPrice += "<br/>";
                            }
                            it.NewPrice += it.StatutoryFirstUnitValue + it.StatutoryFirstUnit;
                        }
                    }

                    if (it.StatutorySecondUnitValue) {
                        if (it.StatutorySecondUnit && it.StatutorySecondUnit != it.ChargeUnit && it.StatutorySecondUnit != "无") {
                            if (it.NewPrice != "") {
                                it.NewPrice += "<br/>";
                            }
                            it.NewPrice += it.StatutorySecondUnitValue + it.StatutorySecondUnit;
                        }
                    }

                    if (it.OriginCountry) {
                        it.OriginCountryName = it.OriginCountry.FullName;
                    }
                    if (it.FinanCountry) {
                        it.FinanCountryName = it.FinanCountry.FullName;
                    }
                    if (it.DomesticGoodsPlace) {
                        it.DomesticGoodsPlaceName = it.DomesticGoodsPlace.FullName;
                    }
                    if (it.Place) {
                        it.PlaceName = it.Place.FullName;
                    }

                    if (it.ExemptingMode) {
                        it.ExemptingModeName = it.ExemptingMode.FullName;
                    }
                }
            }
        }
        onCheckElement(): void {
            if (this.detail.Data.DocumentProducts) {
                for (var it of this.detail.Data.DocumentProducts) {
                    it.DeclarationElement = "";
                    if (it.ProductElements && it.ProductElements.length > 0) {
                        for (var element of it.ProductElements) {
                            if (element.Value && element.Value != "") {
                                it.DeclarationElement += element?.Name?.replace(";", ":") + element.Value + "&nbsp;&nbsp;";
                            }
                            else {
                                it.DeclarationElement += '<span style="color:#f56c6c">' + element?.Name?.replace(";", ":") + "待补充</span>&nbsp;&nbsp;";
                            }
                        }
                    }
                    var result = checkComplete(it);
                    if (result == false) {
                        it.DeclarationElement += '<span style="color:#f56c6c"><i class="el-icon-warning"></i> 信息不完整待补充</span>';
                    }
                }
            }
        }
        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                this.$router.push(url);
            }
            else {
                history.go(-1);
            }
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            var companyId = this.$route.query.CompanyId as string;
            switch (btn.Code) {
                case "edit":
                    //this.$router.push("/document/edit/" + this.id + "?backUrl=" + this.$route.fullPath);

                    this.$router.push({
                        path: "/document/edit/" + this.id,
                        query: { backUrl: this.$route.fullPath },
                    });
                    complete();
                    break;
                case "copy":
                    //this.$router.push("/document/copy/" + this.id + "?isCopy=true&backUrl=" + this.$route.fullPath);
                    this.$router.push({
                        path: "/document/copy/" + this.id + "?isCopy=true",
                        query: { backUrl: this.$route.fullPath },
                    });
                    complete();
                    break;
                case "delete":
                    ElMessageBox.confirm("您确定要删除信息吗？删除后信息将无法恢复，是否继续?", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            DocumentAppService.Delete([this.id as string])
                                .then(() => {
                                    ElMessage.success("删除成功");
                                    this.handleBack();
                                    complete();
                                })
                                .catch(complete);

                        })
                        .catch(() => {
                            complete();
                        });

                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }

        handleClickPackage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.packRuleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }

    function isNullOrEmpty(str: string) {
        if (str == null || str == undefined || str == "") {
            return true;
        }
        else {
            return false;
        }
    }

    //检查hscode是否已经完善
    var checkComplete = function (product: any) {
        var nullStr = "无";
        if (isNullOrEmpty(product.HsCode)) {
            return false;
        }
        if (isNullOrEmpty(product.StatutoryFirstUnitValue)) {
            return false;
        }
        if (product.StatutorySecondUnit != nullStr && isNullOrEmpty(product.StatutorySecondUnitValue)) {
            return false;
        }
        for (var i = 0; i < product.ProductElements.length; i++) {
            if (isNullOrEmpty(product.ProductElements[i].Value) || product.ProductElements[i].Value == "undefined") {
                return false;
            }
        }

        return true;
    };
