import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumBillType, EnumBillTypeOption } from "./EnumBillType";
import UiGroup from "./UiGroup";

/**
 * 账单表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormFinance extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumBillType;
    /**
     * 订单主键
     */
    OrderId?: string;
    /**
     * Booking No
     */
    PoolId?: string;
    /**
     * 委托人
     */
    ClientCompanyId?: string;
    /**
     * 委托人
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientLabel?: string;
    /**
     * 业务员
     */
    SalerId?: string;
    /**
     * 业务员
     */
    SalerNameLabel?: string;
    /**
     * 供应商
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierUserId?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 费用名称
     */
    CostId?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 计价单位
     */
    FirstChargeUnitNumber?: number;
    /**
     * 第一计价单位
     */
    FirstChargeUnitId?: string;
    /**
     * 第二计价单位的数量
     */
    SecondChargeUnitNumber?: number;
    /**
     * 第二计价单位
     */
    SecondChargeUnitId?: string;
    /**
     * 单项合计
     */
    TotalPrice?: number;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 记账起始时间
     */
    StartDate?: Date;
    /**
     * 账期
     */
    AccountPeriod?: number;
    /**
     * 账单编号
     */
    Code?: string;
}

/**
 * 账单表单-表单校验
 */
export const DtoFormFinanceRules = {
    ClientLabel: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 账单表单-设置项
 */
export const DtoFormFinanceMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "PoolId",
    Label: "Booking No",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ClientLabel",
    Label: "委托人",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SupplierCompanyId",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "SupplierUserId",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "CostId",
    Label: "费用名称",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "FirstChargeUnitNumber",
    Label: "计价单位",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "单项合计",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "StartDate",
    Label: "记账起始时间",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "AccountPeriod",
    Label: "账期",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "账单编号",
    Type: EnumMiezzFormItemType.InputText,
  },
]
