
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import OrderWarehouseAppService from "@/apis.machine/OrderWarehouseAppService";
    import DtoRequestCreater from "@/models.machine/DtoRequestCreater";
    import MiezzForm from "@/models/MiezzForm";
    import DtoFormOrderWarehouse, {
        DtoFormOrderWarehouseMiezzFormItems,
        DtoFormOrderWarehouseRules,
    } from "@/models.machine/DtoFormOrderWarehouse";


    export default class OrderWarehouseAssign extends Vue {
        param = new DtoRequestCreater();
        title = "指派订单";

        Visible = false;
        form = new MiezzForm<DtoFormOrderWarehouse, string>();

        //执行人集合
        users: UiSelectOption<string>[] = [];
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        created(): void {

            //表单-数据-初始化
            this.form.Data = new DtoFormOrderWarehouse();
            //表单-校验
            this.form.Rules = DtoFormOrderWarehouseRules;
            //表单-元素配置
            this.form.Items = DtoFormOrderWarehouseMiezzFormItems;

            this.form.Rules.SelectJoinCompanyUserId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];

        }

        onChangeUserId(): void {
            MiezzForm.clearCustomValidate(this.$refs, "ruleFormAssign", "SelectJoinCompanyUserId");
        }
        /*初始化 */
        @Emit()
        onInit(rows: any): void {
            this.param = new DtoRequestCreater();
            if (rows != null && rows != undefined && rows.length > 0) {
                if (rows.length == 1) {
                    this.form.Data.SelectJoinCompanyUserId = rows[0].JoinCompanyUserId;
                }
                else {
                    var isAll = true;
                    for (var row of rows) {
                        if (row.UserId != rows[0].JoinCompanyUserId) {
                            isAll = false;
                        }
                    }
                    if (isAll == true) {
                        this.form.Data.SelectJoinCompanyUserId = rows[0].JoinCompanyUserId;
                    }
                }

                if (rows[0].Type == 10) {
                    this.title = "指派采购订单";
                    this.param.PowerCode = "OrderManagement_PurchaseOrder";
                }
                else if (rows[0].Type == 20) {
                    this.title = "指派销售订单";
                    this.param.PowerCode = "OrderManagement_SalesOrder";
                }
            }

            OrderWarehouseAppService.GetAssigner(this.param).then(user => {
                if (user.data.Data) this.users = user.data.Data;
                if (this.users) {
                    var flag = false;
                    for (var it of this.users) {
                        if (this.form.Data.SelectJoinCompanyUserId == it.Value) {
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.SelectJoinCompanyUserId = undefined;
                    }
                }
                this.Visible = true;
            })
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    (this.$refs["ruleFormAssign"] as any).validate(
                        (v: boolean) => {
                            this.$emit("onSelectOrderWarehouseAssigner", this.form.Data.SelectJoinCompanyUserId);
                            this.form.Data.SelectJoinCompanyUserId = undefined;
                            this.Visible = false;
                            complete();
                        })
                    break;
                case "close":
                    this.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }
