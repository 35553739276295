import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumFinanceStatType {
    /**
     * 收款进度
     */
    Collection = 0,
    /**
     * 付款进度
     */
    Payment = 1,
    /**
     * 业务员绩效
     */
    Saler = 2,
    /**
     * 毛利润
     */
    GrossProfit = 3,
    /**
     * 实际利润
     */
    ActualProfit = 4,
}

/**
 * -选项
 */
export const EnumFinanceStatTypeOption: UiSelectOption<number>[] = [
    { Label: "收款进度", Value: 0, Data: "Collection" },
    { Label: "付款进度", Value: 1, Data: "Payment" },
    { Label: "业务员绩效", Value: 2, Data: "Saler" },
    { Label: "毛利润", Value: 3, Data: "GrossProfit" },
    { Label: "实际利润", Value: 4, Data: "ActualProfit" },
];
