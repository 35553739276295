
import DtoFormCodePropertyBaseInfo from "@/models.machine/DtoFormCodePropertyBaseInfo";
import { Vue, Options } from "vue-class-component";
import { Watch, Prop, Emit, Model } from "vue-property-decorator";
export default class CodeChoosePropertyTag extends Vue {
  @Model() modelValue?: DtoFormCodePropertyBaseInfo[];
  @Prop() base?: string;

  @Emit()
  handleClick(item: DtoFormCodePropertyBaseInfo): void {
    //
  }
}
