
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzPageList from "@/models/MiezzPageList";

    import HsCodeAppService from "@/apis.machine/HsCodeAppService";
    import DtoPageListHsCode from "@/models.machine/DtoPageListHsCode";
    import DtoListItemHsCode, {
        DtoListItemHsCodeMiezzListItems,
    } from "@/models.machine/DtoListItemHsCode";
    import DtoPageListSearchHsCode, { DtoPageListSearchHsCodeDynamicSearchItems } from "@/models.machine/DtoPageListSearchHsCode";
    export default class MiezzHsCode extends Vue {
        visible = true;
        ruleForm: {
            Visible: boolean,
            Code: string,
            Total: number,
            SearchCode: string,
            CurrentPage: number,
        } = {
                Visible: false,
                Code: "",
                Total: 0,
                SearchCode: "",
                CurrentPage: 1,
            };
        hsCodeList = new MiezzPageList<DtoPageListSearchHsCode, DtoListItemHsCode, string>();
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        created(): void {
            //列表-传参
            this.hsCodeList.Dto = new DtoPageListSearchHsCode();
            //列表-动态搜索-项
            this.hsCodeList.DynamicSearchItems = DtoPageListSearchHsCodeDynamicSearchItems;
            //列表-数据-初始
            this.hsCodeList.Data = new DtoPageListHsCode();
            //列表-列配置
            this.hsCodeList.Items = DtoListItemHsCodeMiezzListItems;
            this.hsCodeList.Items = DtoListItemHsCodeMiezzListItems?.map((it) => {
                const item = Object.copy(it);
                if (item.Prop == "IsSelected") {
                    item.Width = 40;
                }
                return item;
            });
            this.hsCodeList.EnabledRadioSearch = false;
            this.hsCodeList.EnabledDynamicSearch = false;
            this.hsCodeList.EnabledSelect = false;
            this.hsCodeList.FixTop = "45px";
            this.hsCodeList.EnabledPagination = false;
            this.hsCodeList.HideCard = true;
            this.hsCodeList.Fix = false;

        }
        ///*打开HsCode对话框 */
        @Emit()
        onInit(searchCode: string): void {
            if (this.ruleForm) {
                this.ruleForm.SearchCode = searchCode;
                this.ruleForm.Code = searchCode;
                this.ruleForm.CurrentPage = 1;
                this.ruleForm.Total = 0;
            }
            this.hsCodeList.Data = new DtoPageListHsCode();
            this.onSearchCode();
            this.ruleForm.Visible = true;

        }
        onPrev(): void {
            if (this.ruleForm) {
                this.ruleForm.CurrentPage = this.ruleForm.CurrentPage - 1;
            }
            if (this.ruleForm.CurrentPage < 1) {
                this.ruleForm.CurrentPage = 1;
            }
            this.onSearchCode();
        }
        onNext(): void {
            if (this.ruleForm) {
                this.ruleForm.CurrentPage = this.ruleForm.CurrentPage + 1;
            }
            this.onSearchCode();
        }
        handleCurrentChange(page: number): void {
            if (this.ruleForm) {
                this.ruleForm.CurrentPage = page;
            }
            this.onSearchCode();
        }
        handleRowClick(row: any) {
            this.onSelectCode(row.Code);
        }
        /*选择HsCode */
        onSelectCode(code: string): void {
            if (this.ruleForm.Code != code) {
                this.ruleForm.Code = code;
            }
            else {
                this.ruleForm.Code = "";
            }
            if (this.hsCodeList.Data && this.hsCodeList.Data.Items) {
                for (var it of this.hsCodeList.Data.Items) {
                    it.IsSelected = false;
                    if (it.Code == this.ruleForm.Code) {
                        it.IsSelected = true;
                    }
                }
            }
        }

        /*查询HsCode */
        onSearchCode(): void {
            if (this.ruleForm.SearchCode && this.ruleForm.SearchCode != "") {
                if (this.hsCodeList.Dto) {
                    this.hsCodeList.Dto.Code = this.ruleForm.SearchCode;
                    this.hsCodeList.Dto.IsSelectCode = true;
                    this.hsCodeList.Dto.CurrentPage = this.ruleForm.CurrentPage;
                }

                HsCodeAppService.Get(this.hsCodeList.Dto).then((r) => {
                    this.hsCodeList.Data = r.data.Data;
                    if (r.data.Data) {
                        this.ruleForm.Total = r.data.Data.TotalCount as number;
                    }

                    if (this.hsCodeList.Data && this.hsCodeList.Data.Items) {
                        for (var it of this.hsCodeList.Data.Items) {
                            it.IsSelected = false;
                            if (it.Code == this.ruleForm.Code) {
                                it.IsSelected = true;
                            }
                        }
                    }
                });
            }
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    var row = new DtoListItemHsCode();
                    var flag = false;
                    if (this.hsCodeList.Data && this.hsCodeList.Data.Items) {
                        for (var it of this.hsCodeList.Data.Items) {
                            if (it.IsSelected == true) {
                                row = it;
                                flag = true;
                            }
                        }
                    }
                    if (flag == false) {
                        ElMessage.warning("您没有选择信息");
                        complete();
                        return;
                    }

                    this.$emit("onSelectHsCode", row);

                    this.ruleForm.Code = "";
                    this.ruleForm.Total = 0;
                    this.ruleForm.SearchCode = "";
                    this.ruleForm.CurrentPage = 1;
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }
