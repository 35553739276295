
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import HttpLogAppService from "@/apis.machine/HttpLogAppService";
import DtoPageListSearchHttpLog, {
  DtoPageListSearchHttpLogDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchHttpLog";
import DtoPageListHttpLog from "@/models.machine/DtoPageListHttpLog";
import DtoListItemHttpLog, {
  DtoListItemHttpLogMiezzListItems,
} from "@/models.machine/DtoListItemHttpLog";
import HttpLogDetail from "./HttpLogDetail.vue";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import router from "@/router";
import ProjectSiteAppService from "@/apis.machine/ProjectSiteAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import moment from "moment";

@Options({
  components: {
    HttpLogDetail,
  },
})
export default class HttpLogPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchHttpLog,
    DtoListItemHttpLog,
    string
  >();
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  sites?: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchHttpLog();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchHttpLogDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListHttpLog();
    //列表-列配置
    this.list.Items = DtoListItemHttpLogMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-server",
        Title: "服务器日志",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-audit",
        Title: "审计",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-exception",
        Title: "异常",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
    ];
    ProjectSiteAppService.GetSelect().then((r) => {
      this.sites = r.data.Data;
    });
  }

  /**查询列表 */
  handleGet(): void {
    HttpLogAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemHttpLog,
    complete: () => void
  ): void {
    let search: DtoFormDynamicSearch;
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `Http日志详细`;
        this.id = row.Id;
        complete();
        break;
      case "link-server":
        search = new DtoFormDynamicSearch();
        search.Code = "System_Log_AuditLog";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_RequestId",
                  Compare: EnumCompare.Equal,
                  Value: row.TraceIdentifier,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/sys/log/server",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
                SiteId: this.list.Dto?.SiteId,
                Date: moment(row.CreatedTime).format("YYYY/MM/DD"),
              },
            });
            complete();
          })
          .catch(complete);
        break;
      case "link-audit":
        search = new DtoFormDynamicSearch();
        search.Code = "System_Log_AuditLog";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Keywords",
                  Compare: EnumCompare.Equal,
                  Value: row.TraceIdentifier,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/sys/log/audit",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
                SiteId: this.list.Dto?.SiteId,
              },
            });
            complete();
          })
          .catch(complete);
        break;
      case "link-exception":
        search = new DtoFormDynamicSearch();
        search.Code = "System_Log_ExceptionLog";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_Keywords",
                  Compare: EnumCompare.Equal,
                  Value: row.TraceIdentifier,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/sys/log/exception",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
                SiteId: this.list.Dto?.SiteId,
              },
            });
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
}
