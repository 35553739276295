import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderChooseService extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.DeparturePortBooking = false;
        this.DeparturePortTake = false;
        this.DeparturePortWarehouse = false;
        this.DeparturePortDeclareCustoms = false;
        this.DestinationPortClearCustoms = false;
        this.DestinationPortDelivery = false;
        this.DestinationPortWarehouse = false;
        this.IsStock = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托公司
     */
    ClientCompanyId?: string;
    /**
     * 委托公司中文名称
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托公司
     */
    ClientCompanyLabel?: string;
    /**
     * 委托人
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 价格服务Id
     */
    PriceServiceId?: string;
    /**
     * 价格服务标签
     */
    PriceServiceLabel?: string;
    /**
     * 价格服务快捷方式Id
     */
    PriceServiceShortcutId?: string;
    /**
     * 价格服务快捷方式标签
     */
    PriceServiceShortcutLabel?: string;
    /**
     * 业务类型
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 装货港服务
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 目的港服务
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
    /**
     * 进销存主键
     */
    OrderWarehouseId?: string;
    /**
     * 是否库存建单
     */
    IsStock?: boolean;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderChooseServiceRules = {
    ClientCompanyId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Code: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    TransportDirection: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    ServiceRemark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderChooseServiceMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ClientCompanyId",
    Label: "委托公司",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ClientUserId",
    Label: "委托人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "编号",
    Customize: true,
  },
  {
    Prop: "TransportDirection",
    Label: "业务类型",
    Customize: true,
  },
  {
    Prop: "DeparturePortBooking",
    Label: "装货港服务",
    Customize: true,
  },
  {
    Prop: "DestinationPortClearCustoms",
    Label: "目的港服务",
    Customize: true,
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    Customize: true,
  },
]
