import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 2,
  style: {"overflow":"hidden","white-space":"nowrap"}
}
const _hoisted_4 = {
  key: 3,
  style: {"overflow":"hidden","white-space":"nowrap"}
}
const _hoisted_5 = { class: "link" }
const _hoisted_6 = { class: "link" }
const _hoisted_7 = { class: "link" }
const _hoisted_8 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    ref: "ref-miezz-page-list",
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleTopButtonClick: _ctx.handleTopButtonClick,
    onHandleRowButtonClick: _ctx.handleRowButtonClick,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick
  }, {
    column: _withCtx(({ model,row }) => [
      (model.Prop == 'FirstConsignor')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            title: row.FirstConsignorName,
            style: {"overflow":"hidden","white-space":"nowrap"}
          }, _toDisplayString(row.FirstConsignorName), 9, _hoisted_1))
        : _createCommentVNode("", true),
      (model.Prop == 'FirstConsignee')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            title: row.FirstConsigneeName,
            style: {"overflow":"hidden","white-space":"nowrap"}
          }, _toDisplayString(row.FirstConsigneeName), 9, _hoisted_2))
        : _createCommentVNode("", true),
      (model.Prop == 'CreatedUserName' && (row.CreatedPhoneNumber!=null && row.CreatedPhoneNumber!=undefined && row.CreatedPhoneNumber!=''))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "dark",
              content: row.CreatedUserName + '/' + row.CreatedPhoneNumber,
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.CreatedUserName) + "/" + _toDisplayString(row.CreatedPhoneNumber), 1)
              ]),
              _: 2
            }, 1032, ["content"])
          ]))
        : _createCommentVNode("", true),
      (model.Prop == 'CreatedUserName' && (row.CreatedPhoneNumber==null || row.CreatedPhoneNumber==undefined || row.CreatedPhoneNumber==''))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_el_tooltip, {
              class: "box-item",
              effect: "dark",
              content: row.CreatedUserName,
              placement: "top-start"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.CreatedUserName), 1)
              ]),
              _: 2
            }, 1032, ["content"])
          ]))
        : _createCommentVNode("", true)
    ]),
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_Type')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.types
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_FirstConsignor')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 1,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.consignors
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_FirstConsignee')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 2,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.consignees
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_CreatedUserName')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 3,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.creaters
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_DispatchPort')
        ? (_openBlock(), _createBlock(_component_el_autocomplete, {
            key: 4,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            clearable: true,
            style: {"width":"100% !important"},
            "fetch-suggestions": _ctx.querySearchDispatchPortAsync,
            "popper-class": "my-autocomplete",
            placeholder: "请填写关键词",
            onSelect: ($event: any) => (_ctx.handleSelectDispatchPort($event,model))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(item.Label), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "onSelect"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_DestinationPort')
        ? (_openBlock(), _createBlock(_component_el_autocomplete, {
            key: 5,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            clearable: true,
            style: {"width":"100% !important"},
            "fetch-suggestions": _ctx.querySearchDestinationPortAsync,
            "popper-class": "my-autocomplete",
            placeholder: "请填写关键词",
            onSelect: ($event: any) => (_ctx.handleSelectDestinationPort($event,model))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(item.Label), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "onSelect"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_TradingCountry')
        ? (_openBlock(), _createBlock(_component_el_autocomplete, {
            key: 6,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            clearable: true,
            style: {"width":"100% !important"},
            "fetch-suggestions": _ctx.querySearchTradingCountryAsync,
            "popper-class": "my-autocomplete",
            placeholder: "请填写关键词",
            onSelect: ($event: any) => (_ctx.handleSelectTradingCountry($event,model))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(item.Label), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "onSelect"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_ArrivalCountry')
        ? (_openBlock(), _createBlock(_component_el_autocomplete, {
            key: 7,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            clearable: true,
            style: {"width":"100% !important"},
            "fetch-suggestions": _ctx.querySearchArrivalCountryAsync,
            "popper-class": "my-autocomplete",
            placeholder: "请填写关键词",
            onSelect: ($event: any) => (_ctx.handleSelectArrivalCountry($event,model))
          }, {
            default: _withCtx(({ item }) => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(item.Label), 1)
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "onSelect"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick"]))
}