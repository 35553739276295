import UiSelectOption from "./UiSelectOption";

/**
 * 拖曳类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumUiDragType {
    /**
     * 之前
     */
    Before = 1,
    /**
     * 之后
     */
    After = 2,
    /**
     * 内部
     */
    Inner = 3,
}

/**
 * 拖曳类型-选项
 */
export const EnumUiDragTypeOption: UiSelectOption<number>[] = [
    { Label: "之前", Value: 1, Data: "Before" },
    { Label: "之后", Value: 2, Data: "After" },
    { Label: "内部", Value: 3, Data: "Inner" },
];
