
import { Vue, Options } from "vue-class-component";
import MiezzPageList from "@/models/MiezzPageList";
import router from "@/router";
import MiezzButton from "@/models/MiezzButton";
import DtoPageListCodeService from "@/models.machine/DtoPageListCodeService";
import DtoPageListSearchCodeService, {
  DtoPageListSearchCodeServiceDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCodeService";
import DtoListItemCodeService, {
  DtoListItemCodeServiceMiezzListItems,
} from "@/models.machine/DtoListItemCodeService";
import CodeServiceAppService from "@/apis.machine/CodeServiceAppService";

export default class CodeServicePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCodeService,
    DtoListItemCodeService,
    string
  >("DeveloperTools_Service");

  /**创建时 */
  created(): void {
    this.list.OperationWidth = "210px";
    this.list.Dto = new DtoPageListSearchCodeService();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCodeServiceDynamicSearchItems;
    this.list.Data = new DtoPageListCodeService();
    this.list.Items = DtoListItemCodeServiceMiezzListItems;
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "服务",
        Icon: "plus",
        Title: "添加服务",
        Type: "primary",
        Size: "small",
      },
    ];
    this.list.RowButtons = [
      {
        Code: "add",
        Label: "方法",
        Icon: "plus",
        Title: "添加方法",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "add-page-list",
        Label: "分页列表",
        Icon: "plus",
        Title: "添加分页列表方法",
        Type: "primary",
        Size: "small",
      },
    ];
  }

  handleGet(): void {
    CodeServiceAppService.GetPageList(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        router.push("/developer/tool/service/new");
        complete();
        break;

      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCodeService,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "add":
        router.push({
          path: "/developer/tool/method/new",
          query: {
            Group: row.Group,
            App: row.App,
            Service: row.Name,
          },
        });
        complete();
        break;
      case "add-page-list":
        router.push({
          path: "/developer/tool/method/new/pagelist",
          query: {
            Group: row.Group,
            App: row.App,
            Service: row.Name,
          },
        });
        complete();
        break;
      default:
        break;
    }
  }
}
