
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ProjectAppService from "@/apis.machine/ProjectAppService";
import DtoPageListSearchProject, {
  DtoPageListSearchProjectDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchProject";
import DtoPageListProject from "@/models.machine/DtoPageListProject";
import DtoListItemProject, {
  DtoListItemProjectMiezzListItems,
} from "@/models.machine/DtoListItemProject";
import ProjectDetail from "./ProjectDetail.vue";
import ProjectForm from "./ProjectForm.vue";
import DtoFormDynamicSearch from "@/models.machine/DtoFormDynamicSearch";
import { EnumRelation } from "@/models.machine/EnumRelation";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import router from "@/router";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    ProjectDetail,
    ProjectForm,
  },
})
export default class ProjectPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchProject,
    DtoListItemProject,
    string
  >("DeveloperTools_Project");
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchProject();
    //列表-动态搜索-项
    this.list.DynamicSearchItems = DtoPageListSearchProjectDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListProject();
    //列表-列配置
    this.list.Items = DtoListItemProjectMiezzListItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-site",
        Title: "站点",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
      {
        Code: "link-version",
        Title: "版本",
        Icon: "link",
        Type: "default",
        Size: "small",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    ProjectAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
      ProjectAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增项目";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemProject,
    complete: () => void
  ): void {
    let search: DtoFormDynamicSearch;
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `项目详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      //站点
      case "link-site":
        search = new DtoFormDynamicSearch();
        search.Code = "DeveloperTools_Site";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_ProjectId",
                  Compare: EnumCompare.Equal,
                  Value: row.Id,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/developer/tool/project/site",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
              },
            });
            complete();
          })
          .catch(complete);
        break;
      case "link-version":
        search = new DtoFormDynamicSearch();
        search.Code = "DeveloperTools_Version";
        search.Brackets = [
          {
            Relation: EnumRelation.And,
            Items: [
              {
                Relation: EnumRelation.And,
                Where: {
                  Prop: "DynamicSearch_ProjectId",
                  Compare: EnumCompare.Equal,
                  Value: row.Id,
                },
              },
            ],
          },
        ];
        DynamicSearchAppService.Post(search)
          .then((r) => {
            router.push({
              path: "/developer/tool/project/version",
              query: {
                DynamicSearchId: r.data.Data,
                OpenSearch: "true",
              },
            });
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        ProjectAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
