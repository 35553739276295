import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47514de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { style: {"font-size":"16px","margin-left":"10px","color":"#f56c6c"} }
const _hoisted_3 = {
  key: 3,
  style: {"text-align":"right"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  style: {"margin-left":"10px"}
}
const _hoisted_6 = {
  key: 1,
  class: "card-header-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_ArrowDownBold = _resolveComponent("ArrowDownBold")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ArrowUpBold = _resolveComponent("ArrowUpBold")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_el_card = _resolveComponent("el-card")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_card, {
    ref: "ref-card",
    class: _normalizeClass(`${_ctx.fix ? 'card-fix ' : ''} ${_ctx.hide ? 'card-hide ' : ''} ${
      _ctx._down ? 'form-group hide' : 'form-group show'
    }`),
    style: _normalizeStyle(`top: ${_ctx.fixTop ? _ctx.fixTop : '0px'}`)
  }, {
    header: _withCtx(() => [
      (
          _ctx.isTitle != false &&
          (_ctx.$slots['headerLeft'] ||
            _ctx.title ||
            (_ctx.topButtons &&
              _ctx.topButtons.length > 0 &&
              _ctx.topButtons.all(
                (it) => !_ctx.checkTopButtonShow || _ctx.checkTopButtonShow(it)
              )))
        )
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "card-header",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleHeaderClick && _ctx.handleHeaderClick(...args)))
          }, [
            (_ctx.$slots['headerLeft'])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle(_ctx.leftHeaderWidth ? `width: ${_ctx.leftHeaderWidth};` : ``)
                }, [
                  _renderSlot(_ctx.$slots, "headerLeft", {}, undefined, true)
                ], 4))
              : _createCommentVNode("", true),
            (_ctx.rightTitleWidth)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  style: _normalizeStyle(`width: ${_ctx.rightTitleWidth}; text-align: right;`)
                }, _toDisplayString(_ctx.title), 5))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.description), 1)
                ])),
            (!_ctx.hideHeaderRight)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.$slots['headerRight'])
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _renderSlot(_ctx.$slots, "headerRight", {}, undefined, true)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topButtons?.filter(
              (item) => !_ctx.checkTopButtonShow || _ctx.checkTopButtonShow(item)
            ), (item, i) => {
                    return (_openBlock(), _createBlock(_component_miezz_button, {
                      key: i,
                      model: item,
                      check: _ctx.model && item.CheckProp ? _ctx.model[item.CheckProp] : undefined,
                      onHandleClick: _ctx.handleHeaderButtonClick,
                      onHandleFileUpload: _ctx.handleHeaderButtonUpload
                    }, null, 8, ["model", "check", "onHandleClick", "onHandleFileUpload"]))
                  }), 128)),
                  _renderSlot(_ctx.$slots, "top-buttons", {}, undefined, true),
                  (_ctx.toggle)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        (_ctx._down)
                          ? (_openBlock(), _createBlock(_component_el_icon, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ArrowDownBold)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ArrowUpBold)
                              ]),
                              _: 1
                            }))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_ctx.reserveEmptyHeader)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.fix)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "scrollbar-wrap",
              style: _normalizeStyle(`top: ${
          _ctx.$slots['headerLeft'] ||
          _ctx.title ||
          (_ctx.topButtons &&
            _ctx.topButtons.length > 0 &&
            _ctx.topButtons.all(
              (it) => !_ctx.checkTopButtonShow || _ctx.checkTopButtonShow(it)
            ))
            ? '79px'
            : '10px'
        }`)
            }, [
              (!_ctx.disabledScrollbar)
                ? (_openBlock(), _createBlock(_component_el_scrollbar, {
                    key: 0,
                    ref: "ref-scrollbar"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]),
                    _: 3
                  }, 512))
                : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
            ], 4))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              style: _normalizeStyle(
          _ctx.bottomButtons && _ctx.bottomButtons.length > 0 ? 'margin-bottom: 40px' : ''
        )
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 4)),
        (
          !_ctx.hideHeaderRight &&
          ((_ctx.bottomButtons && _ctx.bottomButtons.length > 0) || _ctx.$slots['footer'])
        )
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(_ctx.fix ? 'card-fix-footer' : 'card-footer')
            }, [
              (_ctx.bottomButtons && _ctx.bottomButtons.length > 0)
                ? (_openBlock(), _createBlock(_component_miezz_button_bar, {
                    key: 0,
                    modelValue: _ctx.bottomButtons,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bottomButtons) = $event)),
                    onHandleClick: _ctx.handleFooterButtonClick
                  }, null, 8, ["modelValue", "onHandleClick"]))
                : _renderSlot(_ctx.$slots, "footer", { key: 1 }, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class", "style"])), [
    [_directive_loading, _ctx.loading]
  ])
}