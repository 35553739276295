import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_foreign_multiple_select = _resolveComponent("miezz-foreign-multiple-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: item.Label,
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data.Code,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Code) = $event)),
                translate: _ctx.form.Data.Name,
                handleTranslate: _ctx.handleTranslate,
                pattern: "[a-zA-Z_]",
                placeholder: "请填写",
                class: "form-item-input-text"
              }, null, 8, ["modelValue", "translate", "handleTranslate"])
            ]),
            _: 2
          }, 1032, ["label", "prop"]))
        : _createCommentVNode("", true),
      (item.Prop == 'JoinMenuWebApis')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "接口",
            prop: "JoinMenuWebApis"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_foreign_multiple_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                options: _ctx.webApiSelectOptions,
                prop: "WebApiId",
                onHandleChange: _ctx.handleWebApiChange
              }, {
                default: _withCtx(({ model }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass('api_color_' + model.data.Data.Method)
                  }, _toDisplayString(model.data.Label), 3)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onHandleChange"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'MenuFields')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "字段",
            prop: "MenuFields"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.type.Label,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type.Label) = $event)),
                class: "form-item-input-text",
                "fetch-suggestions": _ctx.handleTypeQuerySearch,
                "value-key": "Label",
                onSelect: _ctx.handleTypeSelect
              }, {
                suffix: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small",
                    onClick: _ctx.handleImportFields
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("导入")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "text",
                    size: "small",
                    onClick: _ctx.handleClearFields
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("清空")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                default: _withCtx(({ item }) => [
                  _createTextVNode(_toDisplayString(item.Label), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "fetch-suggestions", "onSelect"]),
              _createVNode(_component_el_tree, {
                ref: "treeRef",
                "node-key": "Id",
                data: _ctx.form.Data[item.Prop],
                props: _ctx.props,
                "expand-on-click-node": false,
                "default-expand-all": ""
              }, {
                default: _withCtx(({ data }) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_tag, {
                      class: "node-tag",
                      type: "info"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(data.Name) + " / " + _toDisplayString(data.Code), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["data", "props"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}