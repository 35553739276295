
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailOrderContainer from "@/models.machine/DtoDetailOrderContainer";
export default class OrderDetailContainer extends Vue {
  @Model() form!: MiezzForm<{ Containers: DtoDetailOrderContainer[] }, string>;
  @Prop() showSealNumbers?: boolean;
  @Prop() departurePortContainer?: boolean;
}
