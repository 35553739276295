
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import DtoFormParamCurrencySystem, {
  DtoFormParamCurrencySystemMiezzFormItems,
  DtoFormParamCurrencySystemRules,
} from "@/models.machine/DtoFormParamCurrencySystem";
import MiezzModal from "@/models/MiezzModal";
import DtoListItemParamCurrencySystem_Repeat from "@/models.machine/DtoListItemParamCurrencySystem_Repeat";

export default class ParamCurrencySystemForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamCurrencySystem, string>(
    "System_ParamList_Currency_AddEdit"
  );
  List: Array<DtoListItemParamCurrencySystem_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamCurrencySystem();
    //表单-校验
    this.form.Rules = DtoFormParamCurrencySystemRules;
    this.form.Rules.NameZh = [
        {
          validator: (rule: any, value: string, callback: any) => {
              let NameZh:any = null;
              if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
                  NameZh = this.form.Data.NameZh.trim();
              } 
              
              if (this.List.some(it => it.NameZh == NameZh)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
          },
          trigger: "blur",
        },
    ];
    this.form.Rules.NameEn = [
        {
          validator: (rule: any, value: string, callback: any) => {
              let NameEn:any = null;
              if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "") {
                  NameEn = this.form.Data.NameEn.toUpperCase().trim();
              }
              
              if (this.List.some(it => it.NameEn == NameEn)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
          },
          trigger: "blur",
        },
    ];
    this.form.Rules.Code = [
        { required: true, message: "信息不能为空", trigger: "blur" },
        {
          required: true,
          validator: (rule: any, value: string, callback: any) => {
              let Code:any = null;
              if (this.form.Data.Code != null && typeof (this.form.Data.Code) != "undefined" && this.form.Data.Code != "undefined" && this.form.Data.Code != "") {
                  Code = this.form.Data.Code.toUpperCase().trim();
              } 
              
              if (this.List.some(it => it.Code == Code)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
          },
          trigger: "blur",
        },
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamCurrencySystemMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    this.getFullName();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.getFullName();
  }

  onBlurCode(): void {
    if (this.form.Data.Code) {
      this.form.Data.Code = this.form.Data.Code.toUpperCase();
      this.form.Data.Code = this.form.Data.Code.trim();
    }
    this.getFullName();
  }

  getFullName(): void {
    const fullNameList = [];
    if(this.form.Data.Code) {
      fullNameList.push(this.form.Data.Code)
    }
    if(this.form.Data.NameZh) {
      fullNameList.push(this.form.Data.NameZh)
    }
    this.form.Data.FullName = fullNameList.join("/");
    if(this.form.Data.NameEn) {
      fullNameList.push(this.form.Data.NameEn)
    }
    this.form.Data.FullName = fullNameList.join("/");
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamCurrencySystemAppService.GetList().then((r1) => {
      if (r1.data.Data) this.List = r1.data.Data;

      if (this.id) {
        ParamCurrencySystemAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;
            
            var index = this.List.findIndex(it => { return it.NameZh == this.form.Data.NameZh && it.NameEn == this.form.Data.NameEn && it.Code == this.form.Data.Code });
            this.List.splice(index, 1);
          }
        });
      } else {
        this.form.Data = new DtoFormParamCurrencySystem();
      }
    }) 
    
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamCurrencySystemAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
