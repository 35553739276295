
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOneSettlement {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 账单主键
     */
    BillId?: string;
    /**
     * 核销主键
     */
    SettlementId?: string;
}

/**
 * -表单校验
 */
export const DtoRequestOneSettlementRules = {
}
