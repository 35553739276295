import UiTreeNode from "./UiTreeNode";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoTreeGroup extends UiTreeNode<DtoTreeGroup, string> {
    constructor() {
        //无参构造函数
        super()
        this.Children = [];
        this.Check = false;
    }

    [proName: string]: any;
}

/**
 * -表单校验
 */
export const DtoTreeGroupRules = {
}
