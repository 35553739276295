
    import { VueDraggable } from "vue-draggable-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ParamListAppService from "@/apis.machine/ParamListAppService";
import DtoPageListSearchParamList, {
  DtoPageListSearchParamListDynamicSearchItems,
    } from "@/models.machine/DtoPageListSearchParamList";
    import DtoPutParamField from "@/models.machine/DtoPutParamField";
import DtoPageListParamList from "@/models.machine/DtoPageListParamList";
import DtoListItemParamList, {
  DtoListItemParamListMiezzListItems,
} from "@/models.machine/DtoListItemParamList";
import ParamListDetail from "./ParamListDetail.vue";
import ParamListForm from "./ParamListForm.vue";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
    import Logier from "@/models.machine/Logier";
    import {
        EnumParamType,
        EnumParamTypeOption,
    } from "@/models.machine/EnumParamType";
    import {
        EnumParamField,
        EnumParamFieldOption,
    } from "@/models.machine/EnumParamField";
import UiSelectOption from "@/models.machine/UiSelectOption";
    @Options({
        components: {
            ParamListDetail,
            ParamListForm,
            VueDraggable
        },
    })
    export default class ParamListPageList extends Vue {
        isLoading = false;
        paramRandom = 0;
        EnumParamTypeOption = EnumParamTypeOption;
        EnumParamFieldOption = EnumParamFieldOption;
        SelectEnumParamFieldOption = EnumParamFieldOption;
        SelectParamField = [];
        DrapParamField = [];
        SecondParamField = EnumParamFieldOption;
        SecondedParamField = EnumParamFieldOption;
        SelectParamType = -1;
        isSecond = false;
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchParamList,
            DtoListItemParamList,
            string
        >("System_ParamList");
        /**当前Id */
        id?: string;
        /**详细模态框 */
        modalDetail = new MiezzModal();
        /**表单模态框 */
        modalForm = new MiezzModal();
        /**表单模态框 */
        modalValue = new MiezzModal();
        logier?: Logier;
        optParamList?: UiSelectOption<any>[] = [];

        /**创建时 */
        created(): void {
            this.logier = CurrentLogier.GetLogier();
            //列表-操作区域宽度
            //this.list.OperationWidth = "100px";
            //列表-传参
            this.list.Dto = new DtoPageListSearchParamList();
            //列表-动态搜索-项
            this.list.DynamicSearchItems = DtoPageListSearchParamListDynamicSearchItems;
            //列表-数据-初始
            this.list.Data = new DtoPageListParamList();
            //列表-列配置
            this.list.Items = DtoListItemParamListMiezzListItems;

            if (this.logier && this.logier.CompanyType == 10) {
                this.list.EnabledSelect = true;
            } else {
                this.list.EnabledSelect = false;
            }
            //列表-顶部按钮
            this.list.TopButtons = [
                {
                    Code: "sync",
                    Label: "同步",
                    Type: "primary",
                    Size: "small",
                    PowerCode: "System_ParamList_Sync",
                    Width: "65px",
                },
            ];
            //列表-行按钮
            this.list.RowButtons = [
                {
                    Code: "jump",
                    Title: "查看参数",
                    Icon: "search",
                    Type: "default",
                    Size: "small",
                },
                {
                    Code: "edit",
                    Title: "编辑",
                    Icon: "edit",
                    Type: "warning",
                    Size: "small",
                    PowerCode: "System_ParamList_AddEdit",
                },
                {
                    Code: "drag-sort",
                    Title: "拖曳",
                    Icon: "Rank",
                    Type: "default",
                    Size: "small",
                    PowerCode: "System_ParamList_Sort",
                },
                {
                    Code: "fullName",
                    Title: "全名组成",
                    Icon: "WindPower",
                    Type: "default",
                    Size: "small",
                    PowerCode: "System_ParamList_FullNameComposition",
                },
            ];

            this.list.CheckRowButtonShow = (btn, row) => {
                if (btn.Code == "fullName") {
                    if (row.IsNeedFullName == true) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return true;
                }
                
            };


            //列表-底部按钮 
            this.list.BottomButtons = [
                {
                    Code: "batch-delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "是否确认删除",
                    PowerCode: "System_ParamList_Delete",
                },
            ];


            this.modalValue.HandleClick = this.handleClickFullName;
            this.modalValue.Buttons = [
                {
                    Code: "pre",
                    Label: "上一步",
                    Type: "primary",
                    Size: "small",
                    Hide:true,
                },
                {
                    Code: "next",
                    Label: "下一步",
                    Type: "primary",
                    Size: "small",
                },
                {
                    Code: "submit",
                    Label: "保存",
                    Type: "primary",
                    Size: "small",
                    Hide:true,
                },
            ];

        }

        /**行按钮点击 */
        handleRowButtonClick(
            btn: MiezzButton,
            row: DtoListItemParamList,
            complete: () => void
        ): void {
            this.id = row.Id;
            switch (btn.Code) {
                //查看参数
                case "jump":
                    if (row.Url) {
                        this.$router.push({
                            path: row.Url,
                            query: {
                                backUrl: this.$route.fullPath,
                            },
                        });
                    }
                    break;
                //编辑
                case "edit":
                    this.modalForm.Show = true;
                    this.modalForm.Title = `编辑参数列表-${row.Name}`;
                    complete();
                    break;
                case "fullName":
                    this.SelectEnumParamFieldOption = [];
                    this.SelectParamField = [];
                    this.SecondParamField = [];
                    if (this.modalValue && this.modalValue.Buttons) {
                        this.modalValue.Buttons[0].Hide = true;
                        this.modalValue.Buttons[1].Hide = false;
                        this.modalValue.Buttons[2].Hide = true;
                    }
                    this.isSecond = false;
                    if (row.Code == "System_ParamList_ParamContainerType" || row.Code == "System_ParameterManagement_ParamContainerType") {
                        this.SelectParamType = 1;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r1) => {
                            if (r1.data.Data) {
                                this.SelectParamField = r1.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var container of this.EnumParamFieldOption) {
                                    if (container.Value == EnumParamField.ParamContainerType_ISO || container.Value == EnumParamField.ParamContainerType_NameEn || container.Value == EnumParamField.ParamContainerType_NameZh || container.Value == EnumParamField.ParamContainerType_Code) {
                                        this.SelectEnumParamFieldOption.push(container);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })

                    }
                    else if (row.Code == "System_ParamList_HSCodebrandType" || row.Code == "System_ParameterManagement_HSCodebrandType") {
                        this.SelectParamType = 0;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r2) => {
                            if (r2.data.Data) {
                                this.SelectParamField = r2.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var brand of this.EnumParamFieldOption) {
                                    if (brand.Value == EnumParamField.Brand_Name || brand.Value == EnumParamField.Brand_Code) {
                                        this.SelectEnumParamFieldOption.push(brand);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_Carrier" || row.Code == "System_ParameterManagement_Carrier") {
                        this.SelectParamType = 2;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r3) => {
                            if (r3.data.Data) {
                                this.SelectParamField = r3.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var carrier of this.EnumParamFieldOption) {
                                    if (carrier.Value == EnumParamField.ParamCarrier_Abridge || carrier.Value == EnumParamField.ParamCarrier_NameEn || carrier.Value == EnumParamField.ParamCarrier_NameZh) {
                                        this.SelectEnumParamFieldOption.push(carrier);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_Country" || row.Code == "System_ParameterManagement_Country") {
                        this.SelectParamType = 3;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r4) => {
                            if (r4.data.Data) {
                                this.SelectParamField = r4.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var country of this.EnumParamFieldOption) {
                                    if (country.Value == EnumParamField.ParamCountry_Code || country.Value == EnumParamField.ParamCountry_NameEn || country.Value == EnumParamField.ParamCountry_NameZh) {
                                        this.SelectEnumParamFieldOption.push(country);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_Port" || row.Code == "System_ParameterManagement_Port") {
                        this.SelectParamType = 4;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r5) => {
                            if (r5.data.Data) {
                                this.SelectParamField = r5.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var port of this.EnumParamFieldOption) {
                                    if (port.Value == EnumParamField.ParamPort_Code || port.Value == EnumParamField.ParamPort_NameEn || port.Value == EnumParamField.ParamPort_NameZh) {
                                        this.SelectEnumParamFieldOption.push(port);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_PriceCost" || row.Code == "System_ParameterManagement_PriceCost") {
                        this.SelectParamType = 5;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r6) => {
                            if (r6.data.Data) {
                                this.SelectParamField = r6.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var cost of this.EnumParamFieldOption) {
                                    if (cost.Value == EnumParamField.ParamPriceCost_NameEn || cost.Value == EnumParamField.ParamPriceCost_NameZh) {
                                        this.SelectEnumParamFieldOption.push(cost);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TransportChargeUnit" || row.Code == "System_ParameterManagement_TransportChargeUnit") {
                        this.SelectParamType = 6;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r7) => {
                            if (r7.data.Data) {
                                this.SelectParamField = r7.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var unit of this.EnumParamFieldOption) {
                                    if (unit.Value == EnumParamField.ParamTransportChargeUnit_Code || unit.Value == EnumParamField.ParamTransportChargeUnit_NameEn || unit.Value == EnumParamField.ParamTransportChargeUnit_NameZh) {
                                        this.SelectEnumParamFieldOption.push(unit);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_Currency" || row.Code == "System_ParameterManagement_Currency") {
                        this.SelectParamType = 7;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r8) => {
                            if (r8.data.Data) {
                                this.SelectParamField = r8.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var currency of this.EnumParamFieldOption) {
                                    if (currency.Value == EnumParamField.ParamCurrencySystem_Code || currency.Value == EnumParamField.ParamCurrencySystem_NameEn || currency.Value == EnumParamField.ParamCurrencySystem_NameZh) {
                                        this.SelectEnumParamFieldOption.push(currency);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TransportChannel" || row.Code == "System_ParameterManagement_TransportChannel") {
                        this.SelectParamType = 8;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r9) => {
                            if (r9.data.Data) {
                                this.SelectParamField = r9.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var channel of this.EnumParamFieldOption) {
                                    if (channel.Value == EnumParamField.ParamTransportChannel_Abridge
                                        || channel.Value == EnumParamField.ParamTransportChannel_NameEn
                                        || channel.Value == EnumParamField.ParamTransportChannel_NameZh) {
                                        this.SelectEnumParamFieldOption.push(channel);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TransportRouteCode" || row.Code == "System_ParameterManagement_TransportRouteCode") {
                        this.SelectParamType = 9;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r10) => {
                            if (r10.data.Data) {
                                this.SelectParamField = r10.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var transportRouteCode of this.EnumParamFieldOption) {
                                    if (transportRouteCode.Value == EnumParamField.ParamTransportRouteCode_Code
                                        || transportRouteCode.Value == EnumParamField.ParamTransportRouteCode_NameEn
                                        || transportRouteCode.Value == EnumParamField.ParamTransportRouteCode_NameZh) {
                                        this.SelectEnumParamFieldOption.push(transportRouteCode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_SupervisionMode" || row.Code == "System_ParameterManagement_SupervisionMode") {
                        this.SelectParamType = 10;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r11) => {
                            if (r11.data.Data) {
                                this.SelectParamField = r11.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var supervisionMode of this.EnumParamFieldOption) {
                                    if (supervisionMode.Value == EnumParamField.SupervisionMode_Name
                                        || supervisionMode.Value == EnumParamField.SupervisionMode_Code) {
                                        this.SelectEnumParamFieldOption.push(supervisionMode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TaxCollectionAndExemptionMethod" || row.Code == "System_ParameterManagement_TaxCollectionAndExemptionMethod") {
                        this.SelectParamType = 11;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r12) => {
                            if (r12.data.Data) {
                                this.SelectParamField = r12.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var exemptingMode of this.EnumParamFieldOption) {
                                    if (exemptingMode.Value == EnumParamField.ExemptingMode_Code
                                        || exemptingMode.Value == EnumParamField.ExemptingMode_Name) {
                                        this.SelectEnumParamFieldOption.push(exemptingMode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_NatureOfLevyAndExemption" || row.Code == "System_ParameterManagement_NatureOfLevyAndExemption") {
                        this.SelectParamType = 12;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r13) => {
                            if (r13.data.Data) {
                                this.SelectParamField = r13.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var exemptionNature of this.EnumParamFieldOption) {
                                    if (exemptionNature.Value == EnumParamField.ExemptionNature_Code
                                        || exemptionNature.Value == EnumParamField.ExemptionNature_Abbr) {
                                        this.SelectEnumParamFieldOption.push(exemptionNature);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_DomesticSourceOfGoods" || row.Code == "System_ParameterManagement_DomesticSourceOfGoods") {
                        this.SelectParamType = 13;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r14) => {
                            if (r14.data.Data) {
                                this.SelectParamField = r14.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var domesticGoodsPlace of this.EnumParamFieldOption) {
                                    if (domesticGoodsPlace.Value == EnumParamField.DomesticGoodsPlace_Code
                                        || domesticGoodsPlace.Value == EnumParamField.DomesticGoodsPlace_Name) {
                                        this.SelectEnumParamFieldOption.push(domesticGoodsPlace);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_DetailedOrigin" || row.Code == "System_ParameterManagement_DetailedOrigin") {
                        this.SelectParamType = 14;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r15) => {
                            if (r15.data.Data) {
                                this.SelectParamField = r15.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var place of this.EnumParamFieldOption) {
                                    if (place.Value == EnumParamField.Place_Code
                                        || place.Value == EnumParamField.Place_Name) {
                                        this.SelectEnumParamFieldOption.push(place);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_ModeOfTransportationcustomsDeclaration" || row.Code == "System_ParameterManagement_ModeOfTransportationcustomsDeclaration") {
                        this.SelectParamType = 15;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r16) => {
                            if (r16.data.Data) {
                                this.SelectParamField = r16.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var transportMode of this.EnumParamFieldOption) {
                                    if (transportMode.Value == EnumParamField.TransportMode_Code
                                        || transportMode.Value == EnumParamField.TransportMode_Name) {
                                        this.SelectEnumParamFieldOption.push(transportMode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_HSCodeexportBenefits" || row.Code == "System_ParameterManagement_HSCodeexportBenefits") {
                        this.SelectParamType = 16;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r17) => {
                            if (r17.data.Data) {
                                this.SelectParamField = r17.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var exportBenefits of this.EnumParamFieldOption) {
                                    if (exportBenefits.Value == EnumParamField.Export_Code
                                        || exportBenefits.Value == EnumParamField.Export_Name) {
                                        this.SelectEnumParamFieldOption.push(exportBenefits);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TransactionMethod" || row.Code == "System_ParameterManagement_TransactionMethod") {
                        this.SelectParamType = 17;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r18) => {
                            if (r18.data.Data) {
                                this.SelectParamField = r18.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var transactionMode of this.EnumParamFieldOption) {
                                    if (transactionMode.Value == EnumParamField.TransactionMode_Code
                                        || transactionMode.Value == EnumParamField.TransactionMode_Name) {
                                        this.SelectEnumParamFieldOption.push(transactionMode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TradeTerms" || row.Code == "System_ParameterManagement_TradeTerms") {
                        this.SelectParamType = 18;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r19) => {
                            if (r19.data.Data) {
                                this.SelectParamField = r19.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var tradeTerms of this.EnumParamFieldOption) {
                                    if (tradeTerms.Value == EnumParamField.TradeTerms_Code
                                        || tradeTerms.Value == EnumParamField.TradeTerms_NameEn
                                        || tradeTerms.Value == EnumParamField.TradeTerms_Name) {
                                        this.SelectEnumParamFieldOption.push(tradeTerms);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_PaymentProvision" || row.Code == "System_ParameterManagement_PaymentProvision") {
                        this.SelectParamType = 19;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r20) => {
                            if (r20.data.Data) {
                                this.SelectParamField = r20.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paymentTerms of this.EnumParamFieldOption) {
                                    if (paymentTerms.Value == EnumParamField.PaymentTerms_Abbr
                                        || paymentTerms.Value == EnumParamField.PaymentTerms_Name
                                        || paymentTerms.Value == EnumParamField.PaymentTerms_NameEn) {
                                        this.SelectEnumParamFieldOption.push(paymentTerms);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_CompanyCodeType" || row.Code == "System_ParameterManagement_CompanyCodeType") {
                        this.SelectParamType = 20;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r21) => {
                            if (r21.data.Data) {
                                this.SelectParamField = r21.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paramCompanyCodeType of this.EnumParamFieldOption) {
                                    if (paramCompanyCodeType.Value == EnumParamField.ParamCompanyCodeType_CompanyCode
                                        || paramCompanyCodeType.Value == EnumParamField.ParamCompanyCodeType_CountryCode
                                        || paramCompanyCodeType.Value == EnumParamField.ParamCompanyCodeType_ParamCountryId) {
                                        this.SelectEnumParamFieldOption.push(paramCompanyCodeType);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_CustomsAtThePlaceOfDeclaration" || row.Code == "System_ParameterManagement_CustomsAtThePlaceOfDeclaration") {
                        this.SelectParamType = 21;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r22) => {
                            if (r22.data.Data) {
                                this.SelectParamField = r22.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paramCustomhouse of this.EnumParamFieldOption) {
                                    if (paramCustomhouse.Value == EnumParamField.Customhouse_Code
                                        || paramCustomhouse.Value == EnumParamField.Customhouse_Name) {
                                        this.SelectEnumParamFieldOption.push(paramCustomhouse);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TypeOfShipping" || row.Code == "System_ParameterManagement_TypeOfShipping") {
                        this.SelectParamType = 22;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r23) => {
                            if (r23.data.Data) {
                                this.SelectParamField = r23.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paramContactTransportMode of this.EnumParamFieldOption) {
                                    if (paramContactTransportMode.Value == EnumParamField.ContactTransportMode_Name
                                        || paramContactTransportMode.Value == EnumParamField.ContactTransportMode_NameEn) {
                                        this.SelectEnumParamFieldOption.push(paramContactTransportMode);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_TaxType" || row.Code == "System_ParameterManagement_TaxType") {
                        this.SelectParamType = 23;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r24) => {
                            if (r24.data.Data) {
                                this.SelectParamField = r24.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paramTaxType of this.EnumParamFieldOption) {
                                    if (paramTaxType.Value == EnumParamField.ParamTaxType_NameZh
                                        || paramTaxType.Value == EnumParamField.ParamTaxType_NameEn) {
                                        this.SelectEnumParamFieldOption.push(paramTaxType);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }
                    else if (row.Code == "System_ParamList_Area" || row.Code == "System_ParameterManagement_Area") {
                        this.SelectParamType = 24;
                        ParamListAppService.GetFullNameSettingByType(this.SelectParamType).then((r25) => {
                            if (r25.data.Data) {
                                this.SelectParamField = r25.data.Data as never;
                            }
                            if (this.EnumParamFieldOption) {
                                for (var paramCountryArea of this.EnumParamFieldOption) {
                                    if (paramCountryArea.Value == EnumParamField.ParamCountryArea_Abridge
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_AffiliatedPerson
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_AreaId1
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_AreaId2
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_AreaId3
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_AreaId4
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_PostalCode
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_NameEn
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_NameZh
                                        || paramCountryArea.Value == EnumParamField.ParamCountryArea_ParamCountryId
                                    ) {
                                        this.SelectEnumParamFieldOption.push(paramCountryArea);
                                    }
                                }
                            }
                            this.paramRandom = Math.random();
                        })
                    }

                    this.modalValue.Show = true;
                    //this.modalValue.Code = row.Code;
                    this.modalValue.Title = "全名组成" + "（" + row.Name + "）";
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleChange(e:any):void {
            // event 包含了拖拽操作的详细信息

            if (
                e.oldIndex != e.newIndex &&
                this.SecondParamField &&
                this.SecondParamField.length > 1
            ) {
                const draggingIndex = e.newIndex;
                let dropIndex;
                let type: EnumUiDragType;
                if (draggingIndex == 0) {
                    type = EnumUiDragType.Before;
                    dropIndex = 1;
                } else {
                    type = EnumUiDragType.After;
                    dropIndex = draggingIndex - 1;
                }
                const dragging = this.SecondParamField[draggingIndex];
                const drop = this.SecondParamField[dropIndex];
                //this.handleDragSortForFullName(dragging.Value, drop.Value, type);
                this.SecondParamField = JSON.parse(JSON.stringify(this.SecondParamField));
                //console.log('拖拽后的数据:', this.SecondParamField);
            }
        }

        /**拖曳排序 */
        handleDragSortForFullName(
            draggingId?: string,
            dropId?: string,
            type?: EnumUiDragType
        ): void {
            ParamListAppService.SyncDragSort({
                DraggingId: draggingId,
                DropId: dropId,
                DragType: type,
            }).then(() => {
                this.handleGet();
            });
        }

        /**按钮点击 */
        handleClickFullName(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "pre":
                    this.isSecond = false;
                    if (this.modalValue && this.modalValue.Buttons) {
                        this.modalValue.Buttons[0].Hide = true;
                        this.modalValue.Buttons[1].Hide = false;
                        this.modalValue.Buttons[2].Hide = true;
                    }
                    complete();
                    break;
                case "next":
                    if (this.modalValue && this.modalValue.Buttons) {
                        this.modalValue.Buttons[0].Hide = false;
                        this.modalValue.Buttons[1].Hide = true;
                        this.modalValue.Buttons[2].Hide = false;
                    }
                    this.isSecond = true;
                    this.SecondParamField = [];
                    if (this.SelectParamField && this.SelectEnumParamFieldOption) {
                        for (var it1 of this.SelectEnumParamFieldOption) {
                            for (var it2 of this.SelectParamField) {
                                if (it1.Value?.toString() == it2) {
                                    var exit = false;
                                    for (var it30 of this.SecondParamField) {
                                        if (it1.Value == it30.Value) {
                                            exit = true;
                                        }
                                    }
                                    if (exit != true) {
                                        this.SecondParamField.push(it1);
                                    }
                                }
                            }
                        }
                    }
                    
                    complete();
                    break;
                case "submit":
                    var param = new DtoPutParamField();
                    param.Type = this.SelectParamType;
                    param.Fields = [];
                    if (this.SecondParamField) {
                        for (var it3 of this.SecondParamField) {
                            param.Fields.push(it3);
                        }
                    }
                    console.log(param);
                    this.isLoading = true;
                    ParamListAppService.SetFullName(param).then(r3 => {
                        this.SecondParamField = [];
                        ElMessage.success("保存成功");
                        this.modalValue.Show = false;
                        this.handleGet();
                        this.isLoading = false;
                        complete(); 
                    })
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**查询列表 */
        handleGet(): void {
            ParamListAppService.Get(this.list.Dto).then((r) => {
                this.list.Data = r.data.Data;
            });
            ParamListAppService.GetList().then((r) => {
                if (r.data.Data) {
                    this.optParamList = r.data.Data;
                    this.optParamList.forEach((element) => {
                        element.Label = element.Name;
                        element.Value = element.Name;
                    });
                }
            });
        }

        /**顶部按钮点击 */
        handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //新增
                case "sync":
                    ParamListAppService.SyncParamMenu().then(() => {
                        ElMessage.success("同步成功");
                        complete();
                        this.handleGet();
                    });
                    break;
                default:
                    break;
            }
        }

        handleRowDbClick(row: DtoListItemParamList): void {
            if (row.Url) {
                this.$router.push({
                    path: row.Url,
                    query: {
                        backUrl: this.$route.fullPath,
                    },
                });
            }
        }

     

        /**底部按钮点击 */
        handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                //批量删除
                case "batch-delete":
                    ParamListAppService.Delete(this.list.SelectIds)
                        .then(() => {
                            this.handleGet();
                            complete();
                        })
                        .catch(complete);
                    break;
                default:
                    break;
            }
        }

        /**拖曳排序 */
        handleDragSort(
            draggingId?: string,
            dropId?: string,
            type?: EnumUiDragType
        ): void {
            ParamListAppService.SyncDragSort({
                DraggingId: draggingId,
                DropId: dropId,
                DragType: type,
            }).then(() => {
                this.handleGet();
            });
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.modalDetail.Show = false;
            this.handleGet();
        }
    }
