import UiPageList from "./UiPageList";
import DtoListItemOrderDepartureWharfInStock from "./DtoListItemOrderDepartureWharfInStock";
import DtoCheckQuantity from "./DtoCheckQuantity";
import UiSelectOption from "./UiSelectOption";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";

/**
 * 装货港-仓库/堆场/码头分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderDepartureWharfInStock extends UiPageList<DtoListItemOrderDepartureWharfInStock, string> {
    constructor() {
        //无参构造函数
        super()
        this.IsNewCharge = false;
        this.IsGetChargeWeight = false;
        this.IsSelectChargeWeight = true;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 合计件数
     */
    SumQuantityInStock?: number;
    /**
     * 合计毛重
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积
     */
    SumVolumeInStock?: number;
    /**
     * 计价单位
     */
    ChargeUnitInStock?: string;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 合计件数
     */
    SumQuantityOutStock?: number;
    /**
     * 合计毛重
     */
    SumGrossWeightOutStock?: number;
    /**
     * 合计体积
     */
    SumVolumeOutStock?: number;
    /**
     * 是否导入装货港出库数据
     */
    IsDestinationPort?: boolean;
    /**
     * 出库合计
     */
    OutStocks?: DtoCheckQuantity[];
    /**
     * 数量超出范围
     */
    IsQuantity?: boolean;
    /**
     * 是否具有计划货量
     */
    IsPlan?: boolean;
    /**
     * 备注
     */
    RemarkInStock?: string;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
    /**
     * 是否启用计费重量
     */
    IsNewCharge?: boolean;
    /**
     * 按重量的选择器
     */
    Weights?: UiSelectOption<string>[];
    /**
     * 按体积的选择器
     */
    Volumes?: UiSelectOption<string>[];
    /**
     * 材积比-重量
     */
    VolumeRatioWeight?: number;
    /**
     * 单件最低计费重
     */
    MinimumChargeableWeight?: number;
    /**
     * 单票最低计费重
     */
    OneMinimumChargeableWeight?: number;
    /**
     * 类型
     */
    ChargeType?: EnumCompanyChargeType;
    /**
     * 类型
     */
    ChargeTypeString?: string;
    /**
     * 计费重量
     */
    CompanyChargeId?: string;
    /**
     * 计费重量
     */
    CompanyChargeString?: string;
    /**
     * 计费重量
     */
    ChargeWeight?: number;
    /**
     * 是否需要初始化计费重量
     */
    IsGetChargeWeight?: boolean;
    /**
     * 是否可以选择计费重量
     */
    IsSelectChargeWeight?: boolean;
    /**
     * 当前服务
     */
    CurrentServiceRemark?: string;
}

/**
 * 装货港-仓库/堆场/码头分页列表-表单校验
 */
export const DtoPageListOrderDepartureWharfInStockRules = {
}
