import UiSelectOption from "./UiSelectOption";

/**
 * 待办时效规则-基准时间
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyOvertimeRuleType {
    /**
     * ETD
     */
    EstimatedDepartureDate = 1,
    /**
     * ATD
     */
    ActualDepartureDate = 2,
    /**
     * ETA
     */
    EstimatedArrivalDate = 3,
    /**
     * ATA
     */
    ActualArrivalDate = 4,
    /**
     * 舱单截止时间
     */
    ManifestEndDate = 5,
    /**
     * 截关时间
     */
    CustomsEndDate = 6,
    /**
     * 创建时间
     */
    CreatedTime = 7,
}

/**
 * 待办时效规则-基准时间-选项
 */
export const EnumCompanyOvertimeRuleTypeOption: UiSelectOption<number>[] = [
    { Label: "ETD", Value: 1, Data: "EstimatedDepartureDate" },
    { Label: "ATD", Value: 2, Data: "ActualDepartureDate" },
    { Label: "ETA", Value: 3, Data: "EstimatedArrivalDate" },
    { Label: "ATA", Value: 4, Data: "ActualArrivalDate" },
    { Label: "舱单截止时间", Value: 5, Data: "ManifestEndDate" },
    { Label: "截关时间", Value: 6, Data: "CustomsEndDate" },
    { Label: "创建时间", Value: 7, Data: "CreatedTime" },
];
