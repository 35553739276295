
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchOrderPoolContainerTrailer from "@/models.machine/DtoPageListSearchOrderPoolContainerTrailer";
import DtoListItemOrderPoolContainerTrailer from "@/models.machine/DtoListItemOrderPoolContainerTrailer";
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
import ContainerTrailerPageList from "../../Pool/ContainerTrailer/ContainerTrailerPageList.vue";
@Options({
  components: {
    ContainerTrailerPageList,
  },
})
export default class DestinationPortContainerTrailerPageList extends Vue {
  menuTab = new MiezzMenuTab();
  list = new MiezzPageList<
    DtoPageListSearchOrderPoolContainerTrailer,
    DtoListItemOrderPoolContainerTrailer,
    string
  >("OrderManagement_Order_Summary_DestinationPortContainer");
  powers: string[] = [];
  serviceType = EnumOrderServiceType.DestinationPort;

  created(): void {
    // CurrentLogier.GetPowerCodes().then((r) => {
    //   this.powers = r ?? [];
    //   if (
    //     this.powers.contains(
    //       "OrderManagement_Order_Summary_DestinationPortContainer_Delete"
    //     )
    //   ) {
    //     this.list.RowButtons = [
    //       {
    //         Code: "delete",
    //         Title: "删除",
    //         Icon: "delete",
    //         Type: "default",
    //         Size: "small",
    //         Message: "是否确认删除",
    //         PowerCode:
    //           "OrderManagement_Order_Summary_DestinationPortContainer_Delete",
    //       },
    //     ];
    //   }
    // });
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/destination-port-container/${this.$route.params.id}`;
    this.menuTab.Menus = [
      {
        Label: "目的港-集装箱拖车",
        Url: `/order/destination-port-container/${this.$route.params.id}`,
        Power: "OrderManagement_Order_Summary_DestinationPortContainer",
      },
    ];
  }
}
