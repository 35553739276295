import { EnumSalerInputMode, EnumSalerInputModeOption } from "./EnumSalerInputMode";
import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";
import DtoCallbackSalerPriceRate from "./DtoCallbackSalerPriceRate";
import DtoSelectMonth from "./DtoSelectMonth";

/**
 * 计算应发
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCountPayable {
    constructor() {
        //无参构造函数
        this.IsFormula = false;
        this.IsError = false;
        this.CountType = 0;
        this.Visible = false;
        this.IsSettlement = false;
        this.Collection = [];
        this.BadCollection = [];
        this.BadPayment = [];
        this.Collectioned = [];
        this.Cost = [];
        this.Settlement = [];
        this.Interest = [];
        this.Payment = [];
        this.Actual = [];
    }

    [proName: string]: any;
    /**
     * 是否已设置公式
     */
    IsFormula?: boolean;
    /**
     * 是否有错误
     */
    IsError?: boolean;
    /**
     * 错误信息
     */
    Message?: string;
    /**
     * 0查询，1是计算但不保存，2是计算后保存
     */
    CountType?: number;
    /**
     * 是否可见
     */
    Visible?: boolean;
    /**
     * 是否已存在核销记录
     */
    IsSettlement?: boolean;
    /**
     * 录入模式
     */
    Mode?: EnumSalerInputMode;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 月份
     */
    Month?: string;
    /**
     * 
     */
    SelectMonth?: string;
    /**
     * 月份
     */
    PageMonth?: string;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystemName?: string;
    /**
     * 金额
     */
    Price?: number;
    /**
     * 应收
     */
    Collection?: DtoCallbackCountCurrency[];
    /**
     * 应收坏账
     */
    BadCollection?: DtoCallbackCountCurrency[];
    /**
     * 应付坏账
     */
    BadPayment?: DtoCallbackCountCurrency[];
    /**
     * 已收
     */
    Collectioned?: DtoCallbackCountCurrency[];
    /**
     * 成本
     */
    Cost?: DtoCallbackCountCurrency[];
    /**
     * 结算价
     */
    Settlement?: DtoCallbackCountCurrency[];
    /**
     * 逾期利息
     */
    Interest?: DtoCallbackCountCurrency[];
    /**
     * 应发
     */
    Payment?: DtoCallbackCountCurrency[];
    /**
     * 实发合计
     */
    Actual?: DtoCallbackCountCurrency[];
    /**
     * 汇率
     */
    Rates?: DtoCallbackSalerPriceRate[];
    /**
     * 供选择的月份
     */
    Months?: DtoSelectMonth[];
}

/**
 * 计算应发-表单校验
 */
export const DtoRequestCountPayableRules = {
}
