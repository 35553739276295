import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderPoolSummary_Supplier {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 服务内容
     */
    ServiceContent?: string;
    /**
     * 装货港-订舱
     */
    DeparturePortBooking?: string;
    /**
     * 装货港-集装箱拖车
     */
    DeparturePortContainer?: string;
    /**
     * 装货港-干线物流
     */
    DeparturePortTrunk?: string;
    /**
     * 装货港-快递
     */
    DeparturePortExpress?: string;
    /**
     * 装货港-仓库/堆场/码头
     */
    DeparturePortWarehouse?: string;
    /**
     * 装货港-报关
     */
    DeparturePortDeclareCustoms?: string;
    /**
     * 目的港-清关
     */
    DestinationPortClearCustoms?: string;
    /**
     * 目的港-集装箱拖车
     */
    DestinationPortContainer?: string;
    /**
     * 目的港-干线物流
     */
    DestinationPortTrunk?: string;
    /**
     * 目的港-快递
     */
    DestinationPortExpress?: string;
    /**
     * 目的港-仓库/堆场/码头
     */
    DestinationPortWarehouse?: string;
}

/**
 * -表单校验
 */
export const DtoDetailOrderPoolSummary_SupplierRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderPoolSummary_SupplierMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ServiceContent",
    Label: "服务内容",
  },
  {
    Prop: "DeparturePortBooking",
    Label: "装货港-订舱",
  },
  {
    Prop: "DeparturePortContainer",
    Label: "装货港-集装箱拖车",
  },
  {
    Prop: "DeparturePortTrunk",
    Label: "装货港-干线物流",
  },
  {
    Prop: "DeparturePortExpress",
    Label: "装货港-快递",
  },
  {
    Prop: "DeparturePortWarehouse",
    Label: "装货港-仓库/堆场/码头",
  },
  {
    Prop: "DeparturePortDeclareCustoms",
    Label: "装货港-报关",
  },
  {
    Prop: "DestinationPortClearCustoms",
    Label: "目的港-清关",
  },
  {
    Prop: "DestinationPortContainer",
    Label: "目的港-集装箱拖车",
  },
  {
    Prop: "DestinationPortTrunk",
    Label: "目的港-干线物流",
  },
  {
    Prop: "DestinationPortExpress",
    Label: "目的港-快递",
  },
  {
    Prop: "DestinationPortWarehouse",
    Label: "目的港-仓库/堆场/码头",
  },
]
