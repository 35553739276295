import UiTreeNode from "./UiTreeNode";
import { EnumMenuType, EnumMenuTypeOption } from "./EnumMenuType";
import { EnumMenuPower, EnumMenuPowerOption } from "./EnumMenuPower";

/**
 * 菜单树
 * @description 自动生成的代码,请勿修改
 */
export default class DtoTreeMenu extends UiTreeNode<DtoTreeMenu, string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumMenuType.Menu;
        this.Power = EnumMenuPower.Everyone;
        this.Disabled = false;
        this.Field = false;
        this.RightItems = [];
        this.Children = [];
        this.Check = false;
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 类型
     */
    Type?: EnumMenuType;
    /**
     * 权限
     */
    Power?: EnumMenuPower;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 禁用
     */
    Disabled?: boolean;
    /**
     * 包含字段
     */
    Field?: boolean;
    /**
     * 右侧项
     */
    RightItems?: DtoTreeMenu[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 菜单树-表单校验
 */
export const DtoTreeMenuRules = {
}
