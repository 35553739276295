import { AxiosPromise } from "axios";
import service from "../apis/Service";
import { EnumUserStatus, EnumUserStatusOption } from "../models.machine/EnumUserStatus";
import RESTfulResult from "../models.machine/RESTfulResult";

/**
 * 用户
 * @description 自动生成的代码,请勿修改
 */
export default class UserAppService {
    /**
     * 更新用户状态
     * @param {EnumUserStatus} [status] 状态
     * @param {string[]} [ids] 用户Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateStatusByStatus(status?: EnumUserStatus, ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/User/UpdateStatus/${status}`;
        return service.put<RESTfulResult<any>>(url, ids);
    }

}
