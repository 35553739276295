import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchParamAuditLog from "../models.machine/DtoPageListSearchParamAuditLog";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListParamAuditLog from "../models.machine/DtoPageListParamAuditLog";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamAuditLog from "../models.machine/DtoDetailParamAuditLog";
import DtoFormParamAuditLog from "../models.machine/DtoFormParamAuditLog";
import DtoDetailParamAuditLog_TableValue from "../models.machine/DtoDetailParamAuditLog_TableValue";

/**
 * 参数记录表
 * @description 自动生成的代码,请勿修改
 */
export default class ParamAuditLogAppService {
    /**
     * 获取参数记录表分页列表
     * @param {DtoPageListSearchParamAuditLog} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamAuditLog>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamAuditLog): AxiosPromise<RESTfulResult<DtoPageListParamAuditLog>> {
        const url = `/api/ParamAuditLog/Get`;
        return service.get<RESTfulResult<DtoPageListParamAuditLog>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAuditLog/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取参数记录表详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamAuditLog>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamAuditLog>> {
        const url = `/api/ParamAuditLog/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamAuditLog>>(url);
    }

    /**
     * 添加参数记录表
     * @param {DtoFormParamAuditLog} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormParamAuditLog): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamAuditLog/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 审核提交：通过，拒绝
     * @param {DtoFormParamAuditLog} [dtoFormParamAuditLog] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AuditPost(dtoFormParamAuditLog?: DtoFormParamAuditLog): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamAuditLog/AuditPost`;
        return service.post<RESTfulResult<any>>(url, dtoFormParamAuditLog);
    }

    /**
     * 查询待办数量
     * @returns {AxiosPromise<RESTfulResult<number>>} 待办数量
     */
    static GetWaitHandleCount(): AxiosPromise<RESTfulResult<number>> {
        const url = `/api/ParamAuditLog/GetWaitHandleCount`;
        return service.get<RESTfulResult<number>>(url);
    }

    /**
     * 获取新旧数据列表
     * @param {string} [Id] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamAuditLog_TableValue[]>>} 
     */
    static GetTableValueById(Id?: string): AxiosPromise<RESTfulResult<DtoDetailParamAuditLog_TableValue[]>> {
        const url = `/api/ParamAuditLog/GetTableValue/${Id}`;
        return service.get<RESTfulResult<DtoDetailParamAuditLog_TableValue[]>>(url);
    }

}
