import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchExceptionLog from "../models.machine/DtoPageListSearchExceptionLog";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListExceptionLog from "../models.machine/DtoPageListExceptionLog";
import DtoDetailExceptionLog from "../models.machine/DtoDetailExceptionLog";
import DtoRequestExceptionLogGetStackTraceCodePart from "../models.machine/DtoRequestExceptionLogGetStackTraceCodePart";
import DtoCallbackExceptionLogGetStackTraceCodePart from "../models.machine/DtoCallbackExceptionLogGetStackTraceCodePart";

/**
 * 异常日志
 * @description 自动生成的代码,请勿修改
 */
export default class ExceptionLogAppService {
    /**
     * 获取异常日志分页列表
     * @param {DtoPageListSearchExceptionLog} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListExceptionLog>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchExceptionLog): AxiosPromise<RESTfulResult<DtoPageListExceptionLog>> {
        const url = `/api/ExceptionLog/Get`;
        return service.get<RESTfulResult<DtoPageListExceptionLog>>(url, { params: dto });
    }

    /**
     * 获取异常日志详细
     * @param {string} [id] Id
     * @param {string} [siteId] 
     * @returns {AxiosPromise<RESTfulResult<DtoDetailExceptionLog>>} 详细
     */
    static GetById(id?: string, siteId?: string): AxiosPromise<RESTfulResult<DtoDetailExceptionLog>> {
        const url = `/api/ExceptionLog/Get/${id}`;
        const _querys: any = {};
        _querys.siteId = siteId
        return service.get<RESTfulResult<DtoDetailExceptionLog>>(url, { params: _querys });
    }

    /**
     * 获取堆栈跟踪代码片段
     * @param {DtoRequestExceptionLogGetStackTraceCodePart} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackExceptionLogGetStackTraceCodePart>>} 代码片段
     */
    static GetStackTraceCodePart(dto?: DtoRequestExceptionLogGetStackTraceCodePart): AxiosPromise<RESTfulResult<DtoCallbackExceptionLogGetStackTraceCodePart>> {
        const url = `/api/ExceptionLog/GetStackTraceCodePart`;
        return service.get<RESTfulResult<DtoCallbackExceptionLogGetStackTraceCodePart>>(url, { params: dto });
    }

}
