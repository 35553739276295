import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailTradeTerms_TransactionMode from "./DtoDetailTradeTerms_TransactionMode";
import UiGroup from "./UiGroup";

/**
 * 贸易条款详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailTradeTerms extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    Name?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 英文简写
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 注释
     */
    Remark?: string;
    /**
     * 对应的成交方式
     */
    TransactionModeId?: string;
    /**
     * 对应的成交方式
     */
    TransactionMode?: DtoDetailTradeTerms_TransactionMode;
}

/**
 * 贸易条款详细-表单校验
 */
export const DtoDetailTradeTermsRules = {
}

/**
 * 贸易条款详细-设置项
 */
export const DtoDetailTradeTermsMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "英文简写",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "注释",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransactionMode",
    Label: "对应的成交方式",
    Customize: true,
  },
]
