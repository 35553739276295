import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormPackage_PackageImage from "./DtoFormPackage_PackageImage";
import DtoFormPackage_PackageShape from "./DtoFormPackage_PackageShape";
import DtoFormPackage_PackageSpec from "./DtoFormPackage_PackageSpec";
import UiGroup from "./UiGroup";

/**
 * 产品包装表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPackage extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.PackageImages = [];
        this.PackageShapes = [];
        this.PackageSpecs = [];
        this.UiGroups = [{"Code":"ProductPackageForm","Label":"\u4EA7\u54C1\u5305\u88C5","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 所属产品
     */
    ProductId?: string;
    /**
     * 包装编号
     */
    Code?: string;
    /**
     * 图片
     */
    Image?: string;
    /**
     * 包装种类（中）
     */
    PackageZhId?: string;
    /**
     * 包装种类（英）
     */
    PackageEn?: string;
    /**
     * 包装的图片
     */
    PackageImages?: DtoFormPackage_PackageImage[];
    /**
     * 尺寸/毛重/数量
     */
    PackageShapes?: DtoFormPackage_PackageShape[];
    /**
     * 包装内的型号与数量
     */
    PackageSpecs?: DtoFormPackage_PackageSpec[];
    /**
     * 净重（KGS）
     */
    NetWeight?: number;
    /**
     * 毛重（KGS）
     */
    GrossWeight?: number;
    /**
     * 体积（CBM）
     */
    Volume?: number;
    /**
     * 尺寸与数量
     */
    SizeQuantity?: string;
    /**
     * 包装内的型号与数量
     */
    SpecQuantity?: string;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * 产品所属公司主键
     */
    CompanyId?: string;
}

/**
 * 产品包装表单-表单校验
 */
export const DtoFormPackageRules = {
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    PackageZhId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 产品包装表单-设置项
 */
export const DtoFormPackageMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ProductId",
    Label: "所属产品",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "包装编号",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageZhId",
    Label: "包装种类（中）",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageEn",
    Label: "包装种类（英）",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageImages",
    Label: "包装的图片",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageShapes",
    Label: "尺寸/毛重/数量",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "PackageSpecs",
    Label: "包装内的型号与数量",
    Group: "ProductPackageForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NetWeight",
    Label: "净重（KGS）",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重（KGS）",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积（CBM）",
    Customize: true,
  },
  {
    Prop: "SizeQuantity",
    Label: "尺寸与数量",
    Customize: true,
  },
  {
    Prop: "SpecQuantity",
    Label: "包装内的型号与数量",
    Customize: true,
  },
  {
    Prop: "ProductName",
    Label: "产品名称",
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "产品所属公司主键",
    Type: EnumMiezzFormItemType.InputText,
  },
]
