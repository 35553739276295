import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumAuditResult, EnumAuditResultOption } from "./EnumAuditResult";
import UiGroup from "./UiGroup";

/**
 * 参数记录表表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamAuditLog extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.AuditResult = EnumAuditResult.Agree;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 审核结果
     */
    AuditResult?: EnumAuditResult;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 参数记录表表单-表单校验
 */
export const DtoFormParamAuditLogRules = {
    AuditResult: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 参数记录表表单-设置项
 */
export const DtoFormParamAuditLogMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "AuditResult",
    Label: "审核结果",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumAuditResultOption,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.InputText,
  },
]
