
import DtoListItemJoinCompanyUser, {
  DtoListItemJoinCompanyUserMiezzListItems,
} from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoPageListSearchJoinCompanyUser, {
  DtoPageListSearchJoinCompanyUserDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserPageList from "../UserPageList.vue";
@Options({
  components: {
    UserPageList,
  },
})
export default class ServiceProviderUserPageList extends Vue {
  type = EnumCompanyType.ServiceProvider;
  list = new MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >("ServiceProviderManagement_User");

  created(): void {
    const items = JSON.copy(
      DtoListItemJoinCompanyUserMiezzListItems
    ) as MiezzListItem[];
    //列表-列配置
    this.list.Items = items.filter((it) => it.Prop != "Trusteeship");
    for (const item of this.list.Items) {
      switch (item.Prop) {
        case "Company":
          item.Label = "所属服务商";
          break;
      
        default:
          break;
      }
    }
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchJoinCompanyUserDynamicSearchItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "ServiceProviderManagement_User_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_User_Detail",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "辞退",
        Icon: "delete",
        Type: "text",
        Message: "是否确认辞退",
        PowerCode: "ServiceProviderManagement_User_Delete",
      },
      {
        Code: "batch-update-company-user-status",
        Label: "员工状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "ServiceProviderManagement_User_UpdateCompanyUserStatus",
        Options: [
          {
            Code: "0",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
      {
        Code: "batch-update-user-status",
        Label: "用户状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "ServiceProviderManagement_User_UpdateUserStatus",
        Options: [
          {
            Code: "0",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
    ];
  }
}
