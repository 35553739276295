import UiTreeSearch from "./UiTreeSearch";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoTreeSearchGroup extends UiTreeSearch<string> {
    constructor() {
        //无参构造函数
        super()
        this.OnlyShowCheck = false;
        this.CheckIds = [];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
}

/**
 * -表单校验
 */
export const DtoTreeSearchGroupRules = {
}
