import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderTaskType, EnumOrderTaskTypeOption } from "./EnumOrderTaskType";
import { EnumOrderTaskStatus, EnumOrderTaskStatusOption } from "./EnumOrderTaskStatus";
import DtoListItemOrderTask_Order from "./DtoListItemOrderTask_Order";
import DtoListItemOrderTask_OrderPool from "./DtoListItemOrderTask_OrderPool";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderTask extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumOrderTaskType.AddPool;
    }

    [proName: string]: any;
    /**
     * 事件名称
     */
    Type?: EnumOrderTaskType;
    /**
     * 类型
     */
    TypeName?: string;
    /**
     * 执行人
     */
    UserId?: string;
    /**
     * 执行人
     */
    UserLabel?: string;
    /**
     * 操作
     */
    Status?: EnumOrderTaskStatus;
    /**
     * 链接地址
     */
    Link?: string;
    /**
     * 最后修改时间
     */
    LastModifiedTime?: Date;
    /**
     * 完成时间
     */
    CompletedTime?: Date;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 订单
     */
    Order?: DtoListItemOrderTask_Order;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单
     */
    Pool?: DtoListItemOrderTask_OrderPool;
}

/**
 * -表单校验
 */
export const DtoListItemOrderTaskRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderTaskMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "事件名称",
    Pipe: EnumPipe.Enum,
    Options: EnumOrderTaskTypeOption,
  },
  {
    Prop: "UserId",
    Label: "执行人",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "操作",
    Customize: true,
  },
]
