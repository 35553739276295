import UiPageList from "./UiPageList";
import DtoListItemProject from "./DtoListItemProject";

/**
 * 项目分页列表分页列表-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListProject extends UiPageList<DtoListItemProject, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
}

/**
 * 项目分页列表分页列表-搜索条件-表单校验
 */
export const DtoPageListProjectRules = {
}
