
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzForm from "@/models/MiezzForm";
    import OrderPoolPackageAppService from "@/apis.machine/OrderPoolPackageAppService";
    import DtoPageListSearchOrderPoolPackage from "@/models.machine/DtoPageListSearchOrderPoolPackage";
    import DtoPageListOrderPoolPackage from "@/models.machine/DtoPageListOrderPoolPackage";
    import DtoListItemOrderPoolPackage, {
        DtoListItemOrderPoolPackageMiezzListItems,
    } from "@/models.machine/DtoListItemOrderPoolPackage";
    import MiezzListItem from "../../models/MiezzListItem";
    export default class MiezzCurrency extends Vue {
        //委托书主键
        @Prop() orderPoolId?: string;
        isLoading = false;
        ruleForm: {
            Visible: boolean;
            Total: number;
            CurrentPage: number;
            PageSize: number;
        } = {
                Visible: false,
                Total: 0,
                CurrentPage: 1,
                PageSize: 10,
            };
        currentPage = 1;
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchOrderPoolPackage,
            DtoListItemOrderPoolPackage,
            string
        >();
        selects = new MiezzPageList<
            DtoPageListSearchOrderPoolPackage,
            DtoListItemOrderPoolPackage,
            string
        >();
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //是否在访问接口
        isQuery = false;
        isInit = true;
        created(): void {
            this.list.EnabledRadioSearchAll = false;
            //列表-传参
            this.list.Dto = new DtoPageListSearchOrderPoolPackage();
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderPoolPackage();
            //列表-列配置
            //var columes = DtoListItemOrderPoolTrunkCargoQunantity;
            //columes = [];
            //DtoListItemOrderPoolTrunkCargoQunantityMiezzListItems?.map((it) => {
            //    const item = Object.copy(it);
            //    if (item.Prop != "Image" && item.Prop != "SpecQuantity") {
            //        columes.push(item);
            //    }
            //    if (item.Prop == "IsSelected") {
            //        item.Width = 40;
            //    }
            //});
            this.list.Items = DtoListItemOrderPoolPackageMiezzListItems;

            this.list.EnabledRadioSearch = false;
            this.list.EnabledDynamicSearch = false;
            this.list.EnabledSelect = true;
            this.list.FixTop = "45px";
            this.list.EnabledPagination = false;
            this.list.HideCard = true;
            this.list.Fix = false;
        }
        handleSelectionChange(val: any, index: number) {
            if (this.isQuery == false) {
                var item = [];
                if (this.selects && this.selects.Data && this.selects.Data.Items) {
                    if (this.list.Data && this.list.Data.Items) {
                        for (var it of this.selects.Data.Items) {
                            var exist = false;
                            for (var it1 of this.list.Data.Items) {
                                if (it.Id == it1.Id) {
                                    exist = true;
                                }
                            }
                            if (exist == false) {
                                item.push(it);
                            }
                        }
                    }
                }
                if (val) {
                    for (var it2 of val) {
                        item.push(it2);
                    }
                }
                if (this.selects && this.selects.Data) {
                    this.selects.Data.Items = JSON.parse(JSON.stringify(item));
                }
                if (this.list.Data && this.list.Data.Items) {
                    if (this.selects && this.selects.Data && this.selects.Data.Items) {
                        for (var it3 of this.list.Data.Items) {
                            it3.IsSelected = false;
                            for (var it4 of this.selects.Data.Items) {
                                if (it3.Id == it4.Id) {
                                    it3.IsSelected = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        onSearch(): void {
            if (this.isInit == false) {
                MiezzForm.customSubmit(this.$refs, "customsPackageRuleForm", (v) => {
                    if (v == true) {
                        this.onData();
                    } else {
                        this.ruleForm.CurrentPage = this.currentPage;
                    }
                });
            } else {
                this.isInit = false;
                this.onData();
            }
        }
        onData(): void {
            if (this.list && this.list.Dto) {
                this.list.Dto.CurrentPage = this.ruleForm.CurrentPage;
                this.list.Dto.PageSize = this.ruleForm.PageSize;
                this.list.Dto.OrderPoolId = this.orderPoolId;
            }
            this.isQuery = true;

            OrderPoolPackageAppService.Get(this.list.Dto).then((r) => {
                console.log(r);
                this.list.Data = r.data.Data;
                this.currentPage = this.ruleForm.CurrentPage;
                if (r.data.Data) {
                    this.ruleForm.Total = r.data.Data.TotalCount as number;
                }

                if (this.list.Data && this.list.Data.Items) {
                    for (var it of this.list.Data.Items) {
                        it.IsSelected = false;
                        it.SpecQuantity = "";
                        it.ProductName = "";
                        it.AddQuantity = undefined;
                        if (it.Shapes && it.Shapes.length > 0) {
                            for (var spec of it.Shapes) {
                                if (spec.Length && spec.Length > 0 && spec.Width && spec.Width > 0 && spec.Height && spec.Height > 0) {
                                    it.SpecQuantity += "【" + spec.Length + "*" + spec.Width + "*" + spec.Height + "*" + spec.Quantity + "】";
                                }
                                else {
                                    it.SpecQuantity += "【暂无数据*" + spec.Quantity + "】";
                                }
                            }
                        }
                        if (it.Products && it.Products.length > 0) {
                            for (var pro of it.Products) {
                                if (pro.NameZh && pro.NameZh != "") {
                                    it.ProductName += "【" + pro.NameZh + "】";
                                }
                            }
                        }
                    }
                }
                const customsPackageTable = this.$refs["customsPackageTable"] as any;
                customsPackageTable.clearSelection();
                this.$nextTick(() => {
                    this.isQuery = false;
                    if (this.list.Data && this.list.Data.Items) {
                        if (this.selects && this.selects.Data && this.selects.Data.Items) {
                            var item = [];
                            for (var it3 of this.list.Data.Items) {
                                it3.IsSelected = false;
                                for (var it4 of this.selects.Data.Items) {
                                    if (it3.Id == it4.Id) {
                                        it3.IsSelected = true;
                                        it3.AddQuantity = it4.AddQuantity;
                                        item.push(it3);
                                    }
                                }
                            }
                            item.forEach((row) => {
                                customsPackageTable.toggleRowSelection(row, undefined);
                            });
                        }
                    }
                    this.isLoading = false;
                });
            });
        }
        onPrev(): void {
            //if (this.ruleForm) {
            //    this.ruleForm.CurrentPage = this.ruleForm.CurrentPage - 1;
            //}
            if (this.ruleForm.CurrentPage < 1) {
                this.ruleForm.CurrentPage = 1;
            }
            this.onSearch();
        }
        onNext(): void {
            //if (this.ruleForm) {
            //    this.ruleForm.CurrentPage = this.ruleForm.CurrentPage + 1;
            //}
            //this.onSearch();
        }
        handleCurrentChange(page: number): void {
            if (this.ruleForm) {
                this.ruleForm.CurrentPage = page;
            }
            this.onSearch();
        }
        onChangeQuantity(index: number) {
            if (
                this.list.Data &&
                this.list.Data.Items &&
                this.list.Data.Items[index] &&
                this.list.Data.Items[index].AddQuantity
            ) {
                var result = setDecimal(
                    this.list.Data.Items[index].AddQuantity + "",
                    0,
                    false
                );
                if (result != "") {
                    this.list.Data.Items[index].AddQuantity = Number(result);
                } else {
                    this.list.Data.Items[index].AddQuantity = undefined;
                }
            }
            if (
                this.selects &&
                this.selects.Data &&
                this.selects.Data.Items &&
                this.list.Data &&
                this.list.Data.Items
            ) {
                for (var it3 of this.selects.Data.Items) {
                    for (var it4 of this.list.Data.Items) {
                        if (it3.Id == it4.Id) {
                            it3.AddQuantity = it4.AddQuantity;
                        }
                    }
                }
            }
        }

        /*初始化 */
        @Emit()
        onInit(): void {
            this.isLoading = true;
            if (this.selects) {
                this.selects.Data = [];
            }
            this.ruleForm.CurrentPage = 1;
            this.ruleForm.Total = 0;
            this.isInit = true;
            this.onSearch();
            this.ruleForm.Visible = true;
        }
        onChangeCompany(): void {
            this.ruleForm.CurrentPage = 1;
            this.ruleForm.Total = 0;
            this.selects.Data = [];
            this.list.Data = [];
            this.onSearch();
        }
        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (
                        this.selects == undefined ||
                        this.selects.Data == undefined ||
                        this.selects.Data.Items == undefined ||
                        this.selects.Data.Items.length <= 0
                    ) {
                        ElMessage.warning("请至少勾选一条信息");
                        complete();
                        return;
                    }
                    MiezzForm.customSubmit(this.$refs, "customsPackageRuleForm", (v) => {
                        if (v == true) {
                            this.$emit("onSelectPoolPackage", this.selects.Data?.Items);
                            this.ruleForm.Visible = false;
                            complete();
                        } else {
                            complete();
                        }
                    });

                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
