import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPaymentTermsContent_PaymentTerms from "./DtoDetailPaymentTermsContent_PaymentTerms";
import UiGroup from "./UiGroup";

/**
 * 付款条款内容详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPaymentTermsContent extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 付款条款
     */
    PaymentTermsId?: string;
    /**
     * 付款条款
     */
    PaymentTerms?: DtoDetailPaymentTermsContent_PaymentTerms;
}

/**
 * 付款条款内容详细-表单校验
 */
export const DtoDetailPaymentTermsContentRules = {
}

/**
 * 付款条款内容详细-设置项
 */
export const DtoDetailPaymentTermsContentMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PaymentTerms",
    Label: "付款条款",
    Customize: true,
  },
]
