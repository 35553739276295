
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyInvoiceAppService from "@/apis.machine/CompanyInvoiceAppService";
import DtoPageListSearchCompanyInvoice, {
  DtoPageListSearchCompanyInvoiceDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyInvoice";
import DtoPageListCompanyInvoice from "@/models.machine/DtoPageListCompanyInvoice";
import DtoListItemCompanyInvoice, {
  DtoListItemCompanyInvoiceMiezzListItems,
} from "@/models.machine/DtoListItemCompanyInvoice";
import CompanyInvoiceForm from "./CompanyInvoiceForm.vue";

@Options({
  components: {
    CompanyInvoiceForm,
  },
})
export default class CompanyInvoicePageList extends Vue {
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompanyInvoice,
    DtoListItemCompanyInvoice,
    string
  >;
  /**当前Id */
  id?: string;
  /**公司Id */
  companyId?: string;
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.companyId = this.$route.params.id as string;
    this.list.Type = MiezzPageListType.Card;
    this.list.Back = true;
    this.list.EnabledSelect = false;
    this.list.Title = "开票资料";
    this.list.EnabledRadioSearch = false;
    this.list.CardLabelWidth = "96px";
    this.list.CardSpan = 33.33;
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyInvoice();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyInvoiceDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyInvoice();
    //列表-列配置
    this.list.Items = DtoListItemCompanyInvoiceMiezzListItems;
  }

  /**查询列表 */
  handleGet(): void {
    const dto = Object.copy(this.list.Dto) as DtoPageListSearchCompanyInvoice;
    dto.CompanyId = this.companyId;
    CompanyInvoiceAppService.Get(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增开票资料";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyInvoice,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑开票资料-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      case "delete":
        CompanyInvoiceAppService.DeleteByCompanyId(this.companyId, [
          row.Id as string,
        ])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }
}
