
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import moment from "moment";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
    import CompanyBusinessCardBankAppService from "@/apis.machine/CompanyBusinessCardBankAppService";
    import ContactTransportModeAppService from "@/apis.machine/ContactTransportModeAppService";
    import OrderDepartureCustomsAppService from "@/apis.machine/OrderDepartureCustomsAppService";
    import ParamPortAppService from "@/apis.machine/ParamPortAppService";
    import TradeTermsAppService from "@/apis.machine/TradeTermsAppService";
    import PaymentTermsAppService from "@/apis.machine/PaymentTermsAppService";
    import CustomhouseAppService from "@/apis.machine/CustomhouseAppService";
    import TransportModeAppService from "@/apis.machine/TransportModeAppService";
    import SupervisionModeAppService from "@/apis.machine/SupervisionModeAppService";
    import ExemptionNatureAppService from "@/apis.machine/ExemptionNatureAppService";
    import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
    import PackageZhAppService from "@/apis.machine/PackageZhAppService";
    import TransactionModeAppService from "@/apis.machine/TransactionModeAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoFormOrderDepartureCustoms, {
        DtoFormOrderDepartureCustomsMiezzFormItems,
        DtoFormOrderDepartureCustomsRules,
    } from "@/models.machine/DtoFormOrderDepartureCustoms";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import CompanyBusinessCardForm from "@/views/Company/CompanyBusinessCard/CompanyBusinessCardForm.vue";
    import CompanyBusinessCardBankForm from "@/views/Company/CompanyBusinessCardBank/CompanyBusinessCardBankForm.vue";
    import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";
    import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
    import CurrentLogier from "@/apis/CurrentLogier";
    import DtoDetailOrderPool from "@/models.machine/DtoDetailOrderPool";
    @Options({
        components: {
            CompanyBusinessCardForm,
            CompanyBusinessCardBankForm
        },
    })
    export default class DocumentForm extends Vue {
        TransactionModeTip = {
            Visible: false,
            TradeTermName: "",
            DefaultTransactionMode: "",
            SelectTransactionMode: "",
        };
        transactionModeTipBottomButtons?: MiezzButton[] = [
            {
                Code: "submit",
                Label: "是",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "close",
                Label: "否",
                Type: "default",
                Size: "small",
            },
        ];


        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        poolPackageCount = 0;
        orderPoolId?: string;
        isLoading = false;
        //是否可以更改产品的币制
        isChangeProductCurrencyId = true;
        orderDepartureId = "";
        /*10表示出口 */
        /*20表示进口 */
        type = "";
        //收货人/发货人
        companys?: UiSelectOption<string>[] = [];
        //发货人银行
        shipperBanks?: UiSelectOption<string>[] = [];
        //生产销售单位
        productionSaleUnits?: UiSelectOption<string>[] = [];
        //消费使用单位
        consumptionUseUnits?: UiSelectOption<string>[] = [];
        productIndex = -1;
        productRandom = 0;
        shipperBankRandom = 0;
        consigneeBankRandom = 0;
        paymentTermsContentRandom = 0;
        creditRandom = 0;
        //发货人
        shippers?: UiSelectOption<string>[] = [];
        //收货人银行
        consigneeBanks?: UiSelectOption<string>[] = [];
        //收货人
        consignees?: UiSelectOption<string>[] = [];
        //港口
        ports?: UiSelectOption<string>[] = [];
        //合同的运输方式
        contactTransportModes: UiSelectOption<string>[] = [];
        //贸易条款
        tradeTerms: UiSelectOption<string>[] = [];
        //付款条款
        paymentTerms: UiSelectOption<string>[] = [];
        //付款条款内容
        paymentTermsContents: UiSelectOption<string>[] = [];
        //申报地海关
        customhouses: UiSelectOption<string>[] = [];
        //海关运输方式
        transportModes: UiSelectOption<string>[] = [];
        //监管方式
        supervisionModes: UiSelectOption<string>[] = [];
        //征免性质
        exemptionNatures: UiSelectOption<string>[] = [];
        //国家
        countrys: UiSelectOption<string>[] = [];
        //包装种类
        packages: UiSelectOption<string>[] = [];
        selectPackages: UiSelectOption<string>[] = [];
        //成交方式
        transactionModes: UiSelectOption<string>[] = [];
        //币制
        currencys: UiSelectOption<string>[] = [];
        currencyIndex = "";
        //是否信用证
        isCredit = false;
        //运费是否可以填写
        isFreight = false;
        //保费是否可以填写
        isPremium = false;
        //是否可以更改成交方式
        isChangeTransactionModeId = true;
        //收货人币制主键
        consigneeCurrencyId = "";
        //收货人币制代码
        consigneeCurrencyCode = "";
        //是否填写电子底账数据号码
        isElectronicAccountDataNumber = false;
        //委托公司主键
        clientCompanyId?: string;

        isInit = true;

        packRuleForm = {
            Visible: false,
        };

        form = new MiezzForm<DtoFormOrderDepartureCustoms, string>();
        orderPool = new MiezzForm<DtoDetailOrderPool, string>();
        bottomButtons?: MiezzButton[] = [
            {
                MiezzIcon: "back",
                Code: "back",
                Label: "返回",
                Type: "text",
                Size: "small",
            },
            {
                Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "text",
                Size: "small",
                Disabled: false,
            },

        ];

        packageBottomButtons?: MiezzButton[] = [
            {
                Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "text",
                Size: "small",
            },
            {
                Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "text",
                Size: "small",
            },
        ];

        modalBusinessCardBank = new MiezzModal();
        businessCardBankId?: string;
        businessCardBankType?: number;
        onSetCollectionBank(): void {
            this.businessCardId = this.form.Data.FirstConsignorId;
            this.businessCardBankId = this.form.Data.CollectionBankId;
            this.businessCardContent = this.form.Data.CollectionBankContent;
            this.businessCardBankType = 0;
            this.modalBusinessCardBank.Top = "20px";
            this.modalBusinessCardBank.Height = 300;
            this.modalBusinessCardBank.Title = "收款银行";
            this.modalBusinessCardBank.Type = MiezzModalType.Dialog;
            this.modalBusinessCardBank.Show = true;
        }
        onSetPaymentBank(): void {
            this.businessCardId = this.form.Data.FirstConsigneeId;
            this.businessCardBankId = this.form.Data.PaymentBankId;
            this.businessCardContent = this.form.Data.PaymentBankContent;
            this.businessCardBankType = 1;
            this.modalBusinessCardBank.Top = "20px";
            this.modalBusinessCardBank.Height = 300;
            this.modalBusinessCardBank.Title = "付款银行";
            this.modalBusinessCardBank.Type = MiezzModalType.Dialog;
            this.modalBusinessCardBank.Show = true;
        }
        handleSubmitBusinessCardBank(row: any): void {
            if (this.businessCardBankType == 0) {
                this.form.Data.CollectionBankId = row.Id;
                //this.onChangeFirstConsignorId(false);
                //this.onChangeCollectionBank();
                var param = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    CompanyBusinessCardId: this.form.Data.FirstConsignorId
                }
                this.shipperBanks = [];
                CompanyBusinessCardBankAppService.Get(param).then(r1 => {
                    if (r1.data.Data && r1.data.Data.Items) {
                        for (var it of r1.data.Data.Items) {
                            var name = "";
                            if (it.NameZh == null || it.NameZh == undefined || it.NameZh == "") {

                                name = "—"
                            }
                            else {
                                name = it.NameZh;
                            }
                            name += "/";
                            if (it.NameEn == null || it.NameEn == undefined || it.NameEn == "") {

                                name += "—"
                            }
                            else {
                                name += it.NameEn;
                            }
                            if (this.shipperBanks) {
                                this.shipperBanks.push({
                                    Id: it.Id,
                                    Name: name,
                                    CompanyBusinessCardBankAccounts: it.CompanyBusinessCardBankAccounts,
                                    AddressEn: it.AddressEn,
                                    AddressZh: it.AddressZh,
                                    BeneficiaryNameEn: it.BeneficiaryNameEn,
                                    BeneficiaryNameZh: it.BeneficiaryNameZh,
                                    NameEn: it.NameEn,
                                    NameZh: it.NameZh,
                                    SwiftCode: it.SwiftCode,
                                });
                            }

                        }
                        if (this.isInit != true) {
                            this.onChangeCollectionBank();
                        }
                        this.shipperBankRandom = Math.random();
                    }
                })
            }
            else if (this.businessCardBankType == 1) {
                this.form.Data.PaymentBankId = row.Id;
                //this.onChangeFirstConsigneeId(false);
                //this.onChangePaymentBank();
                var param1 = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    CompanyBusinessCardId: this.form.Data.FirstConsigneeId
                }
                this.consigneeBanks = [];
                CompanyBusinessCardBankAppService.Get(param1).then(r2 => {
                    if (r2.data.Data && r2.data.Data.Items) {
                        for (var it1 of r2.data.Data.Items) {
                            var name = "";
                            if (it1.NameZh == null || it1.NameZh == undefined || it1.NameZh == "") {

                                name = "—"
                            }
                            else {
                                name = it1.NameZh;
                            }
                            name += "/";
                            if (it1.NameEn == null || it1.NameEn == undefined || it1.NameEn == "") {

                                name += "—"
                            }
                            else {
                                name += it1.NameEn;
                            }
                            if (this.consigneeBanks) {
                                this.consigneeBanks.push({
                                    Id: it1.Id,
                                    Name: name,
                                    CompanyBusinessCardBankAccounts: it1.CompanyBusinessCardBankAccounts,
                                    AddressEn: it1.AddressEn,
                                    AddressZh: it1.AddressZh,
                                    BeneficiaryNameEn: it1.BeneficiaryNameEn,
                                    BeneficiaryNameZh: it1.BeneficiaryNameZh,
                                    NameEn: it1.NameEn,
                                    NameZh: it1.NameZh,
                                    SwiftCode: it1.SwiftCode,
                                });
                            }

                        }
                        if (this.isInit != true) {
                            this.onChangePaymentBank();
                        }
                        this.consigneeBankRandom = Math.random();
                    }
                })
            }
            this.modalBusinessCardBank.Show = false;
        }

        businessCardCompanyId?: string;
        modalBusinessCard = new MiezzModal();
        businessCardId?: string;
        businessCardParentId?: string;
        businessCardArea?: EnumCompanyBusinessCardArea;
        businessCardType?: EnumCompanyBusinessCardType;
        businessCardContent?: string;
        onSetConsignor(): void {
            this.businessCardCompanyId = CurrentLogier.GetLogier()?.CompanyId;
            this.businessCardId = this.form.Data.FirstConsignorId;
            this.businessCardParentId = undefined;
            this.businessCardContent = this.form.Data.SellerContent;
            this.businessCardType = EnumCompanyBusinessCardType.Shipper;
            var title = "";
            if (this.type == "10") {
                this.businessCardArea = EnumCompanyBusinessCardArea.Domestic;
                title = "境内发货人";
            }
            else if (this.type == "20") {
                this.businessCardArea = EnumCompanyBusinessCardArea.Overseas;
                title = "境外发货人";
            }
            this.modalBusinessCard.Top = "20px";
            this.modalBusinessCard.Height = 300;
            this.modalBusinessCard.Title = title;
            this.modalBusinessCard.Type = MiezzModalType.Dialog;
            this.modalBusinessCard.Show = true;
        }
        onSetConsignee(): void {
            this.businessCardCompanyId = CurrentLogier.GetLogier()?.CompanyId;
            this.businessCardType = EnumCompanyBusinessCardType.Consignee;
            this.businessCardId = this.form.Data.FirstConsigneeId;
            this.businessCardParentId = undefined;
            this.businessCardContent = this.form.Data.BuyerContent;
            var title = "";
            if (this.type == "10") {
                this.businessCardArea = EnumCompanyBusinessCardArea.Overseas;
                title = "境外收货人";
            }
            else if (this.type == "20") {
                this.businessCardArea = EnumCompanyBusinessCardArea.Domestic;
                title = "境内收货人";
            }
            this.modalBusinessCard.Top = "20px";
            this.modalBusinessCard.Height = 300;
            this.modalBusinessCard.Title = title;
            this.modalBusinessCard.Type = MiezzModalType.Dialog;
            this.modalBusinessCard.Show = true;
        }
        handleSubmitBusinessCard(row: any): void {
            var param = {
                CurrentPage: 1,
                PageSize: 9999999,
                IsDocument: true,
            };
            CompanyBusinessCardAppService.Get(param).then(r => {
                if (r.data.Data && r.data.Data.Items) {
                    this.companys = r.data.Data.Items;
                }
                this.onGetCompany(false);

                if (this.businessCardType == EnumCompanyBusinessCardType.Shipper) {
                    this.form.Data.FirstConsignorId = row.Id;
                    this.form.Data.SellerContent = row.Content;
                }
                else if (this.businessCardType == EnumCompanyBusinessCardType.Consignee) {
                    this.form.Data.FirstConsigneeId = row.Id;
                    this.form.Data.BuyerContent = row.Content;
                }
                this.modalBusinessCard.Show = false;
            })

        }

        created(): void {
            this.type = this.$route.query.type as string;
            this.orderDepartureId = this.$route.params.id as string;

            //表单-数据-初始化
            this.form.Data = new DtoFormOrderDepartureCustoms();
            //表单-校验
            this.form.Rules = DtoFormOrderDepartureCustomsRules;
            //表单-元素配置
            this.form.Items = DtoFormOrderDepartureCustomsMiezzFormItems;

            //表单-数据-初始化
            this.orderPool.Data = new DtoDetailOrderPool();


            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            this.onRule();
            this.isInit = true;
            this.handleGet();
        }

        onSelectProductPackage(rows: any): void {
            var param = { Packages: rows };
            OrderDepartureCustomsAppService.PostProduct(param).then((r) => {
                if (r.data.Data) {
                    if (this.form.Data && this.form.Data.OrderDepartureCustomsProducts == undefined) {
                        this.form.Data.OrderDepartureCustomsProducts = [];
                    }
                    if (this.form.Data.OrderDepartureCustomsProducts) {
                        for (var it of r.data.Data) {
                            this.form.Data.OrderDepartureCustomsProducts.push(it);
                        }
                        if (this.form.Data && this.form.Data.OrderDepartureCustomsProducts) {
                            for (var pro of this.form.Data.OrderDepartureCustomsProducts) {
                                var product = pro;
                                if (this.consigneeCurrencyId != undefined && this.consigneeCurrencyId != "") {
                                    product.CurrencySystemId = this.consigneeCurrencyId;
                                    product.CurrencySystemName = this.consigneeCurrencyCode;
                                }
                                else {
                                    for (var cur of this.currencys) {
                                        if (cur.Label && cur.Value) {
                                            if (cur.Label?.indexOf("美元") >= 0) {
                                                product.CurrencySystemId = cur.Value;
                                                product.CurrencySystemName = cur.Data.Code;
                                                break;
                                            }
                                        }
                                    }
                                }
                                if (product.ProductElements) {
                                    for (var element of product.ProductElements) {
                                        var its = element.Name?.replace(";", "").split(':');
                                        if (its && its.length >= 2) {
                                            if (its[1] == "GTIN" || its[1] == "CAS" || its[1] == "其他" || its[1] == "其它") {
                                                element.Value = "无";
                                            }
                                            if (its[1] == "品名" && product.ProductNameZh) {
                                                element.Value = product.ProductNameZh;
                                            }

                                        }
                                        else if (its && its.length == 1) {
                                            if (its[0] == "GTIN" || its[0] == "CAS" || its[0] == "其他" || its[0] == "其它") {
                                                element.Value = "无";
                                            }
                                            if (its[0] == "品名" && product.ProductNameZh) {
                                                element.Value = product.ProductNameZh;
                                            }
                                        }

                                        if (element.Name) {
                                            if (element.Name.indexOf("规格") >= 0 || element.Name.indexOf("型号") >= 0) {
                                                element.Value = product.SpecName;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        this.onCountCustomsProduct();
                        this.onCheckElement();

                    }
                }
            })
        }

        onChangeOrderNumber(): void {
            if (this.form.Data) {
                if (this.form.Data.OrderNumber) {
                    this.form.Data.OrderNumber = TrimSpace(this.form.Data.OrderNumber as string);
                    this.form.Data.ContractNumber = this.form.Data.OrderNumber;
                }
            }
        }

        handleProductChange(command: string): void {
            switch (command) {
                case "包装": {
                    this.productIndex = -1;
                    const CustomsPackage = this.$refs["CustomsPackage"] as any;
                    CustomsPackage.onInit();
                }
                    break;
                case "货物": {
                    this.productIndex = -1;
                    const CustomsGoods = this.$refs["CustomsGoods"] as any;
                    CustomsGoods.onInit();
                }
                    break;
                case "手动": {
                    this.productIndex = -1;
                    const CustomsProduct = this.$refs["CustomsProduct"] as any;
                    CustomsProduct.onInit();
                }
                    break;
                case "报关资料": {
                    this.productIndex = -1;
                    OrderDepartureCustomsAppService.GetDepartureCustomsProductById(this.orderPoolId).then(r => {
                        console.log(r);
                        if (r.data.Data != null && r.data.Data != undefined && r.data.Data.length > 0) {
                            if (this.form && this.form.Data && this.form.Data.OrderDepartureCustomsProducts == null) {
                                this.form.Data.OrderDepartureCustomsProducts = [];
                            }
                            if (this.form && this.form.Data && this.form.Data.OrderDepartureCustomsProducts) {
                                for (var it of r.data.Data) {
                                    this.form.Data.OrderDepartureCustomsProducts.push(it);
                                }
                            }
                            console.log(this.form.Data.OrderDepartureCustomsProducts);
                            this.onCheckElement();
                            this.onCountCustomsProduct();
                            this.productRandom = Math.random();
                            this.isChangeProductCurrencyId = false;
                        }
                    });
                }
                    break;
            }
        }
        onEditProduct(row: any, index: number) {
            const CustomsProduct = this.$refs["CustomsProduct"] as any;
            CustomsProduct.onInit(row);
            this.productIndex = index;
        }
        onDeleteProduct(index: number) {

            if (this.form.Data.OrderDepartureCustomsProducts) {
                if (this.form.Data.OrderDepartureCustomsProducts[index].Type == 30) {
                    ElMessageBox.confirm("当前产品信息来源于混装包装，将同时删除同包装下的其他产品信息，是否继续?", "提示", {
                        confirmButtonText: "确认",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            if (this.form.Data.OrderDepartureCustomsProducts) {
                                var group = this.form.Data.OrderDepartureCustomsProducts[index].Group;
                                for (let i = this.form.Data.OrderDepartureCustomsProducts.length - 1; i >= 0; i--) {
                                    if (this.form.Data.OrderDepartureCustomsProducts[i].Group == group) {
                                        this.form.Data.OrderDepartureCustomsProducts.splice(i, 1)
                                    }
                                }
                                this.onCountCustomsProduct();
                            }
                        })
                        .catch((e) => {
                            console.log("删除报错:" + e);
                        });
                }
                else {
                    this.form.Data.OrderDepartureCustomsProducts.splice(index, 1);
                    this.onCountCustomsProduct();
                }
            }
        }
        onCountCustomsProduct(): void {
            this.form.Data.ProductGrossWeight = 0;
            this.form.Data.ProductNumber = 0;
            this.form.Data.ProductNetWeight = 0;
            this.form.Data.ProductVolume = 0;
            this.form.Data.AttachedCode = "";
            this.form.Data.OrderDepartureCustomsProductCount = undefined;
            this.isElectronicAccountDataNumber = false;

            var supervisionConditionsCode = "";
            var isDisabled = false;
            if (this.form.Data.OrderDepartureCustomsProducts) {
                if (this.form.Data.OrderDepartureCustomsProducts.length > 0) {
                    this.form.Data.OrderDepartureCustomsProductCount = this.form.Data.OrderDepartureCustomsProducts.length;
                }
                for (var it of this.form.Data.OrderDepartureCustomsProducts) {
                    if (it.IsTemporary == true) {
                        isDisabled = true;
                    }
                    it.NewPrice = "";
                    if (it.TransactionQuantity) {
                        it.NewPrice = it.TransactionQuantity + "";
                    }
                    if (it.ChargeUnit) {
                        it.NewPrice += it.ChargeUnit + "";
                    }

                    if (it.StatutoryFirstUnitValue) {
                        if (it.StatutoryFirstUnit && it.StatutoryFirstUnit != it.ChargeUnit && it.StatutoryFirstUnit != "无") {
                            if (it.NewPrice != "") {
                                it.NewPrice += "<br/>";
                            }
                            it.NewPrice += it.StatutoryFirstUnitValue + it.StatutoryFirstUnit;
                        }
                    }

                    if (it.StatutorySecondUnitValue) {
                        if (it.StatutorySecondUnit && it.StatutorySecondUnit != it.ChargeUnit && it.StatutorySecondUnit != "无") {
                            if (it.NewPrice != "") {
                                it.NewPrice += "<br/>";
                            }
                            it.NewPrice += it.StatutorySecondUnitValue + it.StatutorySecondUnit;
                        }
                    }

                    if (it.GrossWeight) {
                        this.form.Data.ProductGrossWeight += it.GrossWeight;
                    }
                    if (it.NetWeight) {
                        this.form.Data.ProductNetWeight += it.NetWeight;
                    }
                    if (it.Volume) {
                        this.form.Data.ProductVolume += it.Volume;
                    }
                    if (it.PackageQuantity) {
                        this.form.Data.ProductNumber += it.PackageQuantity;
                    }
                    if (it.SupervisionConditionsCode && it.SupervisionConditionsCode != "") {
                        supervisionConditionsCode += it.SupervisionConditionsCode;
                    }
                    if (it.CurrencySystem != undefined && (it.CurrencySystemName == undefined || it.CurrencySystemName == "")) {
                        it.CurrencySystemName = it.CurrencySystem.Code;
                    }
                    if (it.OriginCountry && (it.OriginCountryName == undefined || it.OriginCountryName == "")) {
                        it.OriginCountryName = it.OriginCountry.FullName;
                    }
                    if (it.FinanCountry && (it.FinanCountryName == undefined || it.FinanCountryName == "")) {
                        it.FinanCountryName = it.FinanCountry.FullName;
                    }

                    if (it.DomesticGoodsPlace && (it.DomesticGoodsPlaceName == undefined || it.DomesticGoodsPlaceName == "")) {
                        it.DomesticGoodsPlaceName = it.DomesticGoodsPlace.FullName;
                    }
                    if (it.Place && (it.PlaceName == undefined || it.PlaceName == "")) {
                        it.PlaceName = it.Place.FullName;
                    }

                    if (it.ExemptingMode && (it.ExemptingModeName == undefined || it.ExemptingModeName == "")) {
                        it.ExemptingModeName = it.ExemptingMode.FullName;
                    }
                }
            }
            if (supervisionConditionsCode != "") {
                var newSupervisionConditionsCode = "";
                for (var j = 0; j < supervisionConditionsCode.length; j++) {
                    if (newSupervisionConditionsCode.indexOf(supervisionConditionsCode[j]) < 0) {
                        newSupervisionConditionsCode += supervisionConditionsCode[j];
                    }
                    if (supervisionConditionsCode[j] == "A" || supervisionConditionsCode[j] == "B") {
                        this.isElectronicAccountDataNumber = true;
                    }
                }
                this.form.Data.AttachedCode = newSupervisionConditionsCode;
            }
            if (this.bottomButtons) {
                this.bottomButtons[0].Disabled = isDisabled;
            }

        }
        onSelectCustomsProduct(row: any) {
            if (this.productIndex >= 0) {
                if (this.form.Data.OrderDepartureCustomsProducts) {
                    this.form.Data.OrderDepartureCustomsProducts[this.productIndex] = row;
                }
            }
            else {
                this.form.Data.OrderDepartureCustomsProducts?.push(row);
            }
            this.onCheckElement();
            this.onCountCustomsProduct();
            this.productRandom = Math.random();
            this.isChangeProductCurrencyId = false;
        }
        onCheckElement(): void {
            if (this.form.Data.OrderDepartureCustomsProducts) {
                for (var it of this.form.Data.OrderDepartureCustomsProducts) {
                    it.DeclarationElement = "";
                    if (it.ProductElements && it.ProductElements.length > 0) {
                        for (var element of it.ProductElements) {
                            if (element.Value && element.Value != "") {
                                it.DeclarationElement += element?.Name?.replace(";", ":") + element.Value + "&nbsp;&nbsp;";
                            }
                            else {
                                it.DeclarationElement += '<span style="color:#f56c6c">' + element?.Name?.replace(";", ":") + "待补充</span>&nbsp;&nbsp;";
                            }
                        }
                    }
                    var result = checkComplete(it);
                    if (result == false) {
                        it.DeclarationElement += '<span style="color:#f56c6c"><i class="el-icon-warning"></i> 信息不完整待补充</span>';
                    }
                }
            }
        }
        handleSelectionPackageChange = (val: any) => {
            this.selectPackages = val;
        }
        onChangeTradeTermsId(): void {

            this.TransactionModeTip = {
                Visible: false,
                TradeTermName: "",
                DefaultTransactionMode: "",
                SelectTransactionMode: "",
            };
            if (this.tradeTerms && this.form.Data.TradeTermsId) {
                if (this.form.Data.TransactionModeId == null || this.form.Data.TransactionModeId == undefined || this.form.Data.TransactionModeId == "") {
                    for (var it of this.tradeTerms) {
                        if (it.Value == this.form.Data.TradeTermsId) {
                            this.form.Data.TransactionModeId = it.Data.TransactionModeId;
                        }
                    }
                    this.onChangeTransactionModeId(false);
                }
                else {
                    var exist = false;
                    var i = 0;
                    for (var it1 of this.tradeTerms) {
                        if (it1.Value == this.form.Data.TradeTermsId) {
                            exist = true;
                            break;
                        }
                        i++;
                    }

                    if (exist == true) {
                        if (this.tradeTerms[i].Data.TransactionModeId != this.form.Data.TransactionModeId) {
                            this.TransactionModeTip.DefaultTransactionMode = this.tradeTerms[i].Data.TransactionMode.Name;
                            this.TransactionModeTip.TradeTermName = this.tradeTerms[i].Data.Code as string;
                            for (var it3 of this.transactionModes) {
                                if (it3.Value == this.form.Data.TransactionModeId) {
                                    this.TransactionModeTip.SelectTransactionMode = it3.Label as string;
                                }
                            }
                            this.TransactionModeTip.Visible = true;
                        }
                    }

                }
            }
        }
        onChangeFreight(): void {
            if (this.form.Data.Freight) {
                var result = setDecimal(this.form.Data.Freight.toString(), 5, false);
                if (result != "") {
                    this.form.Data.Freight = Number(result);
                }
                else {
                    this.form.Data.Freight = undefined;
                }
            }
        }
        onChangePremium(): void {
            if (this.form.Data.Premium) {
                var result = setDecimal(this.form.Data.Premium.toString(), 5, false);
                if (result != "") {
                    this.form.Data.Premium = Number(result);
                }
                else {
                    this.form.Data.Premium = undefined;
                }
            }
        }
        onChangeIncidental(): void {
            if (this.form.Data.Incidental) {
                var result = setDecimal(this.form.Data.Incidental.toString(), 5, false);
                if (result != "") {
                    this.form.Data.Incidental = Number(result);
                }
                else {
                    this.form.Data.Incidental = undefined;
                }
            }
        }
        onPackage(): void {
            this.packRuleForm.Visible = true;
            this.$nextTick(() => {
                this.selectPackages = [];
                const packageTable = this.$refs["packageTable"] as any;
                packageTable.clearSelection();
                var packages = this.form.Data.OtherPackName?.split('|');
                if (packages != undefined && packages.length > 0) {
                    for (var pack of packages) {
                        for (var it of this.packages) {
                            if (pack == it.Label) {
                                this.selectPackages.push(it);
                            }
                        }
                    }
                    for (var row of this.selectPackages) {
                        packageTable.toggleRowSelection(row, undefined);
                    }

                }
            })
        }
        /*返回 */
        handleBack(): void {
            //var url = this.$route.query.backUrl as string;
            //if (url != null && typeof (url) != "undefined" && url != undefined && url != "undefined" && url != "") {
            //    this.$router.push(url);
            //}
            //else {
            //    history.go(-1);
            //}
            history.go(-1);
        }

        onChangeOpenCertificateDays(): void {
            if (this.form.Data && this.form.Data.OpenCertificateDays) {
                var result = setDecimal(this.form.Data.OpenCertificateDays.toString(), 0, false);
                if (result != "") {
                    this.form.Data.OpenCertificateDays = Number(result);
                }
                else {
                    this.form.Data.OpenCertificateDays = undefined;
                }
            }
        }

        onChangePaymentTerms(): void {
            this.isCredit = false;
            this.form.Data.PaymentTermsContent = undefined;
            this.form.Data.CreditNumber = undefined;
            this.form.Data.OpenCertificateDays = undefined;
            this.form.Data.IssuingDate = undefined;
            this.paymentTermsContents = [];

            for (var it of this.paymentTerms) {
                if (it.Value == this.form.Data.PaymentTermsId) {
                    if (it.Data.IsCredit == true) {
                        this.isCredit = true;
                    }
                    this.paymentTermsContents = it.Data.PaymentTermsContents;
                }
            }
            this.creditRandom = Math.random();
        }
        onSelectPoolPackage(rows: any): void {
            console.log(rows);
        }

        /**查询表单数据 */
        handleGet(): void {
            //this.showLoading();
            this.isLoading = true;
            OrderDepartureCustomsAppService.GetPoolById(this.orderDepartureId).then(pool => {
                if (pool.data.Data) this.orderPool.Data = pool.data.Data;
                this.orderPoolId = this.orderPool.Data.Id;
                if (this.orderPool.Data.Packages) {
                    this.poolPackageCount = this.orderPool.Data.Packages.length;
                }
                this.clientCompanyId = this.orderPool.Data.ClientCompanyId;
                ParamCurrencySystemAppService.GetSelect().then(currency => {
                    if (currency.data.Data) this.currencys = currency.data.Data;
                    for (var cur of this.currencys) {
                        if (cur.Label && cur.Value) {
                            if (cur.Label?.indexOf("美元") >= 0) {
                                this.form.Data.FreightCurrencySystemId = cur.Value;
                                this.form.Data.PremiumCurrencySystemId = cur.Value;
                                this.form.Data.IncidentalCurrencySystemId = cur.Value;
                                this.form.Data.FreightCurrencySystemName = cur.Data.Code;
                                this.form.Data.PremiumCurrencySystemName = cur.Data.Code;
                                this.form.Data.IncidentalCurrencySystemName = cur.Data.Code;
                                break;
                            }
                        }
                    }
                    TransactionModeAppService.GetSelect().then(transactionMode => {
                        if (transactionMode.data.Data) this.transactionModes = transactionMode.data.Data;
                        PackageZhAppService.GetSelect().then(pack => {
                            if (pack.data.Data) this.packages = pack.data.Data;
                            ParamCountryAppService.GetSelect().then(country => {
                                if (country.data.Data) this.countrys = country.data.Data;
                                ExemptionNatureAppService.GetSelect().then(exemptionNature => {
                                    if (exemptionNature.data.Data) this.exemptionNatures = exemptionNature.data.Data;

                                    SupervisionModeAppService.GetSelect().then(supervision => {
                                        if (supervision.data.Data) this.supervisionModes = supervision.data.Data;
                                        TransportModeAppService.GetSelect().then(transport => {
                                            if (transport.data.Data) this.transportModes = transport.data.Data;

                                            CustomhouseAppService.GetSelect().then(customs => {
                                                if (customs.data.Data) this.customhouses = customs.data.Data;

                                                PaymentTermsAppService.GetSelect().then(payment => {
                                                    if (payment.data.Data) this.paymentTerms = payment.data.Data;
                                                    TradeTermsAppService.GetSelect().then(trade => {
                                                        if (trade.data.Data) this.tradeTerms = trade.data.Data;

                                                        ContactTransportModeAppService.GetSelect().then(transport => {
                                                            if (transport.data.Data) this.contactTransportModes = transport.data.Data;

                                                            var param = {
                                                                CurrentPage: 1,
                                                                PageSize: 9999999,
                                                                IsDocument: true,
                                                                CompanyId: this.orderPool.Data.ClientCompanyId,
                                                            };
                                                            CompanyBusinessCardAppService.Get(param).then(r => {
                                                                if (r.data.Data && r.data.Data.Items) {
                                                                    this.companys = r.data.Data.Items;
                                                                }

                                                                ParamPortAppService.GetSelect().then(port => {
                                                                    if (port.data.Data) this.ports = port.data.Data;
                                                                    if (this.orderDepartureId) {
                                                                        this.isChangeProductCurrencyId = false;
                                                                        OrderDepartureCustomsAppService.GetById(this.orderDepartureId).then((r) => {
                                                                            if (r.data.Data) {
                                                                                this.form.Data = r.data.Data;
                                                                                if (r.data.Data.Id == '00000000-0000-0000-0000-000000000000') {
                                                                                    this.form.Data.Id = undefined;
                                                                                    this.form.Data.SetId = undefined;
                                                                                }
                                                                                for (var cur1 of this.currencys) {

                                                                                    if (this.form.Data.FreightCurrencySystemId == cur1.Value) {
                                                                                        this.form.Data.FreightCurrencySystemName = cur1.Data.Code;
                                                                                    }
                                                                                    if (this.form.Data.PremiumCurrencySystemId == cur1.Value) {
                                                                                        this.form.Data.PremiumCurrencySystemName = cur1.Data.Code;
                                                                                    }
                                                                                    if (this.form.Data.IncidentalCurrencySystemId == cur1.Value) {
                                                                                        this.form.Data.IncidentalCurrencySystemName = cur1.Data.Code;
                                                                                    }
                                                                                }


                                                                                var paymentBankContent = this.form.Data.PaymentBankContent;
                                                                                var collectionBankContent = this.form.Data.CollectionBankContent;
                                                                                var sellerContent = this.form.Data.SellerContent;
                                                                                var buyerContent = this.form.Data.BuyerContent;
                                                                                if (this.form.Data.PaymentTerms) {
                                                                                    this.isCredit = this.form.Data.PaymentTerms.IsCredit;
                                                                                    for (var it9 of this.paymentTerms) {
                                                                                        if (it9.Value == this.form.Data.PaymentTermsId) {
                                                                                            this.paymentTermsContents = it9.Data.PaymentTermsContents;
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (this.form.Data.FirstConsignorId) {
                                                                                    this.onChangeFirstConsignorId(true);
                                                                                }

                                                                                if (this.form.Data.FirstConsigneeId) {
                                                                                    this.onChangeFirstConsigneeId(true);
                                                                                }


                                                                                if (this.form.Data.OrderDepartureCustomsProducts) {
                                                                                    for (var pro of this.form.Data.OrderDepartureCustomsProducts) {
                                                                                        pro.OriginCountryName = undefined;
                                                                                        pro.FinanCountryName = undefined;
                                                                                        pro.DomesticGoodsPlaceName = undefined;
                                                                                        pro.PlaceName = undefined;
                                                                                        pro.ExemptingModeName = undefined;
                                                                                        pro.IsTemporary = false;
                                                                                    }
                                                                                }
                                                                                var type = this.form.Data.Type as number + "";
                                                                                this.type = type as string;
                                                                                if (this.form.Data.Id == undefined) {
                                                                                    this.onGetCompany(true);
                                                                                }
                                                                                else {
                                                                                    this.onGetCompany(false);
                                                                                }

                                                                                this.onCheckElement();
                                                                                this.onCountCustomsProduct();
                                                                                this.productRandom = Math.random();
                                                                                if (this.form.Data.IsTemporary == false) {
                                                                                    if (this.bottomButtons) {
                                                                                        this.bottomButtons[1].Hide = true;
                                                                                    }
                                                                                }

                                                                                if (this.form.Data.DispatchPort != undefined) {
                                                                                    this.form.Data.DispatchPortName = this.form.Data.DispatchPort.FullName;
                                                                                }
                                                                                if (this.form.Data.DestinationPort != undefined) {
                                                                                    this.form.Data.DestinationPortName = this.form.Data.DestinationPort.FullName;
                                                                                }
                                                                                if (this.form.Data.SupervisionMode != undefined) {
                                                                                    this.form.Data.SupervisionModeName = this.form.Data.SupervisionMode.FullName;
                                                                                }
                                                                                if (this.form.Data.ExemptionNature != undefined) {
                                                                                    this.form.Data.ExemptionNatureName = this.form.Data.ExemptionNature.Abbr + "/" + this.form.Data.ExemptionNature.Code;
                                                                                }
                                                                                if (this.form.Data.TradingCountry != undefined) {
                                                                                    this.form.Data.TradingCountryName = this.form.Data.TradingCountry.FullName;
                                                                                }
                                                                                if (this.form.Data.ArrivalCountry != undefined) {
                                                                                    this.form.Data.ArrivalCountryName = this.form.Data.ArrivalCountry.FullName;
                                                                                }
                                                                                if (this.form.Data.StopPort != undefined) {
                                                                                    this.form.Data.StopPortName = this.form.Data.StopPort.FullName;
                                                                                }
                                                                                if (this.form.Data.DepartureCountry != undefined) {
                                                                                    this.form.Data.DepartureCountryName = this.form.Data.DepartureCountry.FullName;
                                                                                }

                                                                                this.$nextTick(() => {
                                                                                    this.isInit = false;
                                                                                    setTimeout(() => {
                                                                                        this.form.Data.PaymentBankContent = paymentBankContent;
                                                                                        this.form.Data.CollectionBankContent = collectionBankContent;
                                                                                        if (this.form.Data.Id != undefined) {
                                                                                            this.form.Data.SellerContent = sellerContent;
                                                                                            this.form.Data.BuyerContent = buyerContent;
                                                                                        }
                                                                                        this.onChangeTransactionModeId(true);
                                                                                        this.isLoading = false;
                                                                                    }, 1000);

                                                                                });
                                                                            }
                                                                            else {
                                                                                this.form.Data = new DtoFormOrderDepartureCustoms();
                                                                                //if (r.data.Data) {
                                                                                //    this.form.Data.Type = r.data.Data.Type;

                                                                                //    var type1 = this.form.Data.Type as number + "";
                                                                                //    this.type = type1 as string;
                                                                                //}

                                                                                this.onGetCompany(true);
                                                                                this.isLoading = false;
                                                                                this.isInit = false;
                                                                            }

                                                                        });
                                                                    }
                                                                    else {
                                                                        this.onGetCompany(true);
                                                                        this.isLoading = false;
                                                                        this.isInit = false;
                                                                    }

                                                                })
                                                            })
                                                        })
                                                    });

                                                })
                                            })

                                        })

                                    })
                                })
                            })
                        })
                    })
                })
            })

        }
        onCurrency(currencyId: string, index: string): void {
            this.currencyIndex = index;
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(currencyId);
        }
        onSelectCurrency(row: any): void {
            if (this.currencyIndex == "FreightCurrencySystemId")//运费
            {
                this.form.Data.FreightCurrencySystemName = row.Data.Code;
                this.form.Data.FreightCurrencySystemId = row.Value;
            }
            else if (this.currencyIndex == "PremiumCurrencySystemId")//保费
            {
                this.form.Data.PremiumCurrencySystemName = row.Data.Code;
                this.form.Data.PremiumCurrencySystemId = row.Value;
            }
            else if (this.currencyIndex == "IncidentalCurrencySystemId")//杂费
            {
                this.form.Data.IncidentalCurrencySystemName = row.Data.Code;
                this.form.Data.IncidentalCurrencySystemId = row.Value;
            }
        }
        onChangeTransportModeId(): void {
            if (this.form.Data.TransportModeId != undefined) {
                MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "TransportModeId");
            }
            else {
                MiezzForm.validateField(this.$refs, "ruleFormCustoms", "TransportModeId");
            }
        }
        onChangePackName(): void {
            if (this.form.Data.PackName != undefined) {
                MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "PackName");
            }
            else {
                MiezzForm.validateField(this.$refs, "ruleFormCustoms", "PackName");
            }
        }


        onChangeTransactionMode(): void {
            this.TransactionModeTip = {
                Visible: false,
                TradeTermName: "",
                DefaultTransactionMode: "",
                SelectTransactionMode: "",
            };
            if (this.tradeTerms && this.form.Data.TradeTermsId) {
                if (this.form.Data.TransactionModeId) {
                    var exist = false;
                    var i = 0;
                    for (var it1 of this.tradeTerms) {
                        if (it1.Value == this.form.Data.TradeTermsId) {
                            exist = true;
                            break;
                        }
                        i++;
                    }

                    if (exist == true && this.transactionModes) {
                        if (this.tradeTerms[i].Data.TransactionModeId != this.form.Data.TransactionModeId) {
                            this.TransactionModeTip.DefaultTransactionMode = this.tradeTerms[i].Data.TransactionMode.Name;
                            this.TransactionModeTip.TradeTermName = this.tradeTerms[i].Data.Code as string;
                            for (var it2 of this.transactionModes) {
                                if (it2.Value == this.form.Data.TransactionModeId) {
                                    this.TransactionModeTip.SelectTransactionMode = it2.Label as string;
                                }
                            }
                            this.TransactionModeTip.Visible = true;
                        }
                        else {
                            this.onChangeTransactionModeId(false);
                        }
                    }
                    else {
                        this.onChangeTransactionModeId(false);
                    }
                }
                else {
                    this.onChangeTransactionModeId(false);
                }
            }
            else {
                this.onChangeTransactionModeId(false);
            }
        }

        onChangeTransactionModeId(isInit: boolean | undefined): void {
            this.isFreight = false;
            this.isPremium = false;
            if (this.form.Data.TransactionModeId != undefined) {
                MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "TransactionModeId");
                for (var it of this.transactionModes) {
                    if (it.Value == this.form.Data.TransactionModeId && it.Label) {
                        if (it.Label?.indexOf("CIF") >= 0) {
                            this.isFreight = true;
                            this.isPremium = true;
                        }
                        else if (it.Label?.indexOf("C&F") >= 0) {
                            this.isFreight = true;
                        }
                        else if (it.Label?.indexOf("C&I") >= 0) {
                            this.isPremium = true;
                        }
                    }
                }

                if (this.tradeTerms && this.form.Data.TradeTermsId && this.isChangeTransactionModeId == true) {
                    for (var it2 of this.tradeTerms) {
                        if (it2.Value == this.form.Data.TradeTermsId) {
                            if (it2.Data.TransactionModeId != this.form.Data.TransactionModeId) {
                                this.isChangeTransactionModeId = false;
                            }
                        }
                    }
                }

                if (this.isFreight != true) {
                    this.form.Data.Freight = undefined;
                }
                if (this.isPremium != true) {
                    this.form.Data.Premium = undefined;
                }
            }
            //else {
            //    MiezzForm.validateField(this.$refs, "ruleFormCustoms", "TransactionModeId");
            //}

            if (isInit == true) {
                this.$nextTick(() => {
                    MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "TransactionModeId");
                })
            }
        }

        handleClickTransactionModeTip(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.onChangeTransactionModeId(false);
                    this.TransactionModeTip.Visible = false;
                    complete();
                    break;
                case "close":

                    if (this.tradeTerms && this.form.Data.TradeTermsId) {
                        var exist = false;
                        var i = 0;
                        for (var it1 of this.tradeTerms) {
                            if (it1.Value == this.form.Data.TradeTermsId) {
                                exist = true;
                                break;
                            }
                            i++;
                        }

                        if (exist == true) {
                            this.form.Data.TransactionModeId = this.tradeTerms[i].Data.TransactionModeId;
                        }

                        this.onChangeTransactionModeId(false);
                    }
                    this.TransactionModeTip.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        onChangeDeliveryRequirement(): void {
            this.form.Data.DeliveryRequirement = OneSpace(this.form.Data.DeliveryRequirement as string);
        }
        onChangeAddress(): void {
            this.form.Data.Address = OneSpace(this.form.Data.Address as string);
        }

        querySearchSupervisionModeIdAsync(queryString: string, cb: any) {
            var restaurants = this.supervisionModes;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.supervisionModes) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.supervisionModes) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.supervisionModes) {
                    for (var i = 0; i < this.supervisionModes.length; i++) {
                        if (i < 10) {
                            results.push(this.supervisionModes[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectSupervisionModeId(item: UiSelectOption<string>) {
            this.form.Data.SupervisionModeId = item.Value;
            this.form.Data.SupervisionModeName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "SupervisionModeId");
        }
        onChangeSupervisionModeId(): void {
            if (this.form.Data.SupervisionModeName == undefined || this.form.Data.SupervisionModeName == "") {
                this.form.Data.SupervisionModeId = undefined;
            }
            else {
                if (this.form.Data.SupervisionModeId == undefined) {
                    this.form.Data.SupervisionModeName = undefined;
                }
                else if (this.form.Data.SupervisionModeName != undefined) {
                    var flag = false;
                    if (this.supervisionModes) {
                        for (var it of this.supervisionModes) {
                            if (it.Label == this.form.Data.SupervisionModeName) {
                                this.form.Data.SupervisionModeId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.SupervisionModeName = undefined;
                        }
                    }
                }
            }
        }


        querySearchExemptionNatureIdAsync(queryString: string, cb: any) {
            var restaurants = this.exemptionNatures;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.exemptionNatures) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.exemptionNatures) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.exemptionNatures) {
                    for (var i = 0; i < this.exemptionNatures.length; i++) {
                        if (i < 10) {
                            results.push(this.exemptionNatures[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectExemptionNatureId(item: UiSelectOption<string>) {
            this.form.Data.ExemptionNatureId = item.Value;
            this.form.Data.ExemptionNatureName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "ExemptionNatureId");
        }
        onChangeExemptionNatureId(): void {
            if (this.form.Data.ExemptionNatureName == undefined || this.form.Data.ExemptionNatureName == "") {
                this.form.Data.ExemptionNatureId = undefined;
            }
            else {
                if (this.form.Data.ExemptionNatureId == undefined) {
                    this.form.Data.ExemptionNatureName = undefined;
                }
                else if (this.form.Data.ExemptionNatureName != undefined) {
                    var flag = false;
                    if (this.exemptionNatures) {
                        for (var it of this.exemptionNatures) {
                            if (it.Label == this.form.Data.ExemptionNatureName) {
                                this.form.Data.ExemptionNatureId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.ExemptionNatureName = undefined;
                        }
                    }
                }
            }
        }

        querySearchTradingCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.countrys) {
                    for (var i = 0; i < this.countrys.length; i++) {
                        if (i < 10) {
                            results.push(this.countrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectTradingCountryId(item: UiSelectOption<string>) {
            this.form.Data.TradingCountryId = item.Value;
            this.form.Data.TradingCountryName = item.Data.NameZh;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "TradingCountryId");
        }
        onChangeTradingCountryId(): void {
            if (this.form.Data.TradingCountryName == undefined || this.form.Data.TradingCountryName == "") {
                this.form.Data.TradingCountryId = undefined;
            }
            else {
                if (this.form.Data.TradingCountryId == undefined) {
                    this.form.Data.TradingCountryName = undefined;
                }
                else if (this.form.Data.TradingCountryName != undefined) {
                    var flag = false;
                    if (this.countrys) {
                        for (var it of this.countrys) {
                            if (it.Data.NameZh == this.form.Data.TradingCountryName) {
                                this.form.Data.TradingCountryId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.TradingCountryName = undefined;
                        }
                    }
                }
            }
        }

        querySearchArrivalCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.countrys) {
                    for (var i = 0; i < this.countrys.length; i++) {
                        if (i < 10) {
                            results.push(this.countrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectArrivalCountryId(item: UiSelectOption<string>) {
            this.form.Data.ArrivalCountryId = item.Value;
            this.form.Data.ArrivalCountryName = item.Data.NameZh;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "ArrivalCountryId");
        }
        onChangeArrivalCountryId(): void {
            if (this.form.Data.ArrivalCountryName == undefined || this.form.Data.ArrivalCountryName == "") {
                this.form.Data.ArrivalCountryId = undefined;
            }
            else {
                if (this.form.Data.ArrivalCountryId == undefined) {
                    this.form.Data.ArrivalCountryName = undefined;
                }
                else if (this.form.Data.ArrivalCountryName != undefined) {
                    var flag = false;
                    if (this.countrys) {
                        for (var it of this.countrys) {
                            if (it.Data.NameZh == this.form.Data.ArrivalCountryName) {
                                this.form.Data.ArrivalCountryId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.ArrivalCountryName = undefined;
                        }
                    }
                }
            }
        }

        querySearchDepartureCountryIdAsync(queryString: string, cb: any) {
            var restaurants = this.countrys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.countrys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.countrys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.countrys) {
                    for (var i = 0; i < this.countrys.length; i++) {
                        if (i < 10) {
                            results.push(this.countrys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDepartureCountryId(item: UiSelectOption<string>) {
            this.form.Data.DepartureCountryId = item.Value;
            this.form.Data.DepartureCountryName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "DepartureCountryId");
        }
        onChangeDepartureCountryId(): void {
            if (this.form.Data.DepartureCountryName == undefined || this.form.Data.DepartureCountryName == "") {
                this.form.Data.DepartureCountryId = undefined;
            }
            else {
                if (this.form.Data.DepartureCountryId == undefined) {
                    this.form.Data.DepartureCountryName = undefined;
                }
                else if (this.form.Data.DepartureCountryName != undefined) {
                    var flag = false;
                    if (this.countrys) {
                        for (var it of this.countrys) {
                            if (it.Label == this.form.Data.DepartureCountryName) {
                                this.form.Data.DepartureCountryId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.DepartureCountryName = undefined;
                        }
                    }
                }
            }
        }

        querySearchStopPortIdAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    for (var i = 0; i < this.ports.length; i++) {
                        if (i < 10) {
                            results.push(this.ports[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectStopPortId(item: UiSelectOption<string>) {
            this.form.Data.StopPortId = item.Value;
            this.form.Data.StopPortName = item.Data.NameZh;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "StopPortId");
        }
        onChangeStopPortId(): void {
            if (this.form.Data.StopPortName == undefined || this.form.Data.StopPortName == "") {
                this.form.Data.StopPortId = undefined;
            }
            else {
                if (this.form.Data.StopPortId == undefined) {
                    this.form.Data.StopPortName = undefined;
                }
                else if (this.form.Data.StopPortName != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Data.NameZh == this.form.Data.StopPortName) {
                                this.form.Data.StopPortId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.StopPortName = undefined;
                        }
                    }
                }
            }
        }

        querySearchDispatchPortAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    var number1 = 0;
                    if (this.type == "10") {
                        for (var it1 of this.ports) {
                            if (it1.Label) {
                                var Name1 = it1.Label.toLowerCase();
                                // || Name1.indexOf("舟山") >= 0 || Name1.indexOf("杭州") >= 0 || Name1.indexOf("上海") >= 0
                                if ((Name1.indexOf("中国") >= 0) && number1 < 10) {
                                    results.push(it1);
                                    number1++;
                                }
                            }
                        }
                    }


                    if (number1 <= 0) {
                        for (var i = 0; i < this.ports.length; i++) {
                            if (i < 10) {
                                results.push(this.ports[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDispatchPort(item: UiSelectOption<string>) {
            this.form.Data.DispatchPortId = item.Value;
            this.form.Data.DispatchPortName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "DispatchPortId");
            if (this.type == "20") {
                if (this.form.Data.DispatchPortId && this.ports) {
                    for (var it1 of this.ports) {
                        if (it1.Value == this.form.Data.DispatchPortId) {
                            this.form.Data.DepartureCountryId = it1.Data.ParamCountryId;
                            this.form.Data.DepartureCountryName = it1.Data.ParamCountry.FullName;

                            this.form.Data.TradingCountryId = it1.Data.ParamCountryId;
                            this.form.Data.TradingCountryName = it1.Data.ParamCountry.FullName;
                        }
                    }
                }

                if (item.Data) {
                    this.form.Data.DeparturePort = item.Data.NameZh;
                }
                else {
                    this.form.Data.DeparturePort = undefined;
                }

            }

        }
        onChangeDispatchPortId(): void {
            if (this.form.Data.DispatchPortName == undefined || this.form.Data.DispatchPortName == "") {
                this.form.Data.DispatchPortId = undefined;
            }
            else {
                if (this.form.Data.DispatchPortId == undefined) {
                    this.form.Data.DispatchPortName = undefined;
                }
                else if (this.form.Data.DispatchPortName != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Label == this.form.Data.DispatchPortName) {
                                this.form.Data.DispatchPortId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.DispatchPortName = undefined;
                        }
                    }
                }
            }
        }

        querySearchDestinationPortAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    var number1 = 0;
                    if (this.type == "20") {
                        for (var it1 of this.ports) {
                            if (it1.Label) {
                                var Name1 = it1.Label.toLowerCase();
                                //|| Name1.indexOf("舟山") >= 0 || Name1.indexOf("杭州") >= 0 || Name1.indexOf("上海") >= 0
                                if ((Name1.indexOf("中国") >= 0) && number1 < 10) {
                                    results.push(it1);
                                    number1++;
                                }
                            }
                        }
                    }
                    if (number1 <= 0) {
                        for (var i = 0; i < this.ports.length; i++) {
                            if (i < 10) {
                                results.push(this.ports[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDestinationPort(item: UiSelectOption<string>) {
            this.form.Data.DestinationPortId = item.Value;
            this.form.Data.DestinationPortName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "DestinationPortId");

            if (this.form.Data.DestinationPortId && this.ports) {
                for (var it1 of this.ports) {
                    if (it1.Value == this.form.Data.DestinationPortId) {
                        this.form.Data.TradingCountryId = it1.Data.ParamCountryId;
                        this.form.Data.TradingCountryName = it1.Data.ParamCountry.FullName;

                        if (this.type == "10") {
                            this.form.Data.ArrivalCountryId = it1.Data.ParamCountryId;
                            this.form.Data.ArrivalCountryName = it1.Data.ParamCountry.FullName;

                            this.form.Data.PortOfDestination = it1.Data.NameZh;
                        }
                    }
                }
            }
        }
        onChangeDestinationPortId(): void {
            if (this.form.Data.DestinationPortName == undefined || this.form.Data.DestinationPortName == "") {
                this.form.Data.DestinationPortId = undefined;
            }
            else {
                if (this.form.Data.DestinationPortId == undefined) {
                    this.form.Data.DestinationPortName = undefined;
                }
                else if (this.form.Data.DestinationPortName != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Label == this.form.Data.DestinationPortName) {
                                this.form.Data.DestinationPortId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.DestinationPortName = undefined;
                        }
                    }
                }
            }
        }


        querySearchPaymentTermsContentAsync(queryString: string, cb: any) {
            var restaurants = this.paymentTermsContents;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.paymentTermsContents) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.paymentTermsContents) {
                    if (it.Name) {
                        var Name = it.Name.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.paymentTermsContents) {
                    for (var i = 0; i < this.paymentTermsContents.length; i++) {
                        if (i < 10) {
                            results.push(this.paymentTermsContents[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectPaymentTermsContent(item: UiSelectOption<string>) {
            this.form.Data.PaymentTermsContent = item.Name;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "PaymentTermsContent");
        }

        onTrimSpace(): void {
            //OneSpace
            if (this.form.Data) {
                if (this.form.Data.ProductionSaleCode) {
                    this.form.Data.ProductionSaleCode = TrimSpace(this.form.Data.ProductionSaleCode as string);
                }
                if (this.form.Data.ProductionSaleCompanyName) {
                    this.form.Data.ProductionSaleCompanyName = TrimSpace(this.form.Data.ProductionSaleCompanyName as string);
                }
                if (this.form.Data.ConsignorCode) {
                    this.form.Data.ConsignorCode = TrimSpace(this.form.Data.ConsignorCode as string);
                }
                if (this.form.Data.ConsignorCompanyName) {
                    if (this.type == "10") {
                        this.form.Data.ConsignorCompanyName = TrimSpace(this.form.Data.ConsignorCompanyName as string);
                    }
                    else if (this.type == "20") {
                        this.form.Data.ConsignorCompanyName = OneSpace(this.form.Data.ConsignorCompanyName as string);
                    }

                }
                if (this.form.Data.ConsigneeCode) {
                    this.form.Data.ConsigneeCode = TrimSpace(this.form.Data.ConsigneeCode as string);
                }
                if (this.form.Data.ConsigneeCompanyName) {
                    if (this.type == "10") {
                        this.form.Data.ConsigneeCompanyName = OneSpace(this.form.Data.ConsigneeCompanyName as string);
                    }
                    else {
                        this.form.Data.ConsigneeCompanyName = TrimSpace(this.form.Data.ConsigneeCompanyName as string);
                    }

                }
                if (this.form.Data.InvoiceNumber) {
                    this.form.Data.InvoiceNumber = TrimSpace(this.form.Data.InvoiceNumber as string);
                }
            }
        }
        querySearchProductionSaleCodeAsync(queryString: string, cb: any) {
            var restaurants = this.productionSaleUnits;
            var item = this.productionSaleUnits;
            if (this.type == "10") {
                restaurants = this.productionSaleUnits;
                if (!restaurants) {
                    restaurants = [];
                }
            }
            else if (this.type == "20") {
                restaurants = this.consumptionUseUnits;
                if (!restaurants) {
                    restaurants = [];
                }
            }

            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && item) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of item) {
                    if (it.Name) {
                        var Name = it.Name.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (item) {
                    for (var i = 0; i < item.length; i++) {
                        if (i < 30) {
                            results.push(item[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectProductionSaleCode(item: UiSelectOption<string>) {
            this.form.Data.ProductionSaleCode = item.Code;
            this.form.Data.ProductionSaleCompanyName = item.Name;
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "ProductionSaleCode");
            MiezzForm.clearValidate(this.$refs, "ruleFormCustoms", "ProductionSaleCompanyName");
        }




        querySearchCustomhouseIdAsync(queryString: string, cb: any) {
            var restaurants = this.customhouses;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.customhouses) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.customhouses) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.customhouses) {
                    var number1 = 0;
                    for (var it1 of this.customhouses) {
                        if (it1.Label) {
                            var Name1 = it1.Label.toLowerCase();
                            if ((Name1.indexOf("宁波") >= 0 || Name1.indexOf("舟山") >= 0 || Name1.indexOf("杭州") >= 0) && number1 < 30) {
                                results.push(it1);
                                number1++;
                            }
                        }
                    }
                    if (number1 <= 0) {
                        for (var i = 0; i < this.customhouses.length; i++) {
                            if (i < 10) {
                                results.push(this.customhouses[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCustomhouseId(item: UiSelectOption<string>) {
            this.form.Data.CustomhouseId = item.Value;
            this.form.Data.CustomhouseName = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "CustomhouseId");
        }
        onChangeCustomhouseId(): void {
            if (this.form.Data.CustomhouseName == undefined || this.form.Data.CustomhouseName == "") {
                this.form.Data.CustomhouseId = undefined;
            }
            else {
                if (this.form.Data.CustomhouseId == undefined) {
                    this.form.Data.CustomhouseName = undefined;
                }
                else if (this.form.Data.CustomhouseName != undefined) {
                    var flag = false;
                    for (var it of this.customhouses) {
                        if (it.Label == this.form.Data.CustomhouseName) {
                            this.form.Data.CustomhouseId = it.Value;
                            flag = true;
                            break;
                        }
                    }
                    if (flag == false) {
                        this.form.Data.CustomhouseName = undefined;
                    }
                }
            }
        }


        onChangePaymentBank(): void {
            this.form.Data.PaymentBankContent = undefined;
            var content = "";
            if (this.consigneeBanks) {
                for (var it of this.consigneeBanks) {
                    if (this.form.Data.PaymentBankId == it.Id) {
                        if (it.NameZh != null && it.NameZh != undefined && it.NameZh != "") {
                            content = it.NameZh;
                        }
                        else {
                            content = "—";
                        }
                        content += "\r\n";
                        if (it.NameEn != null && it.NameEn != undefined && it.NameEn != "") {
                            content += it.NameEn;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.BeneficiaryNameZh != null && it.BeneficiaryNameZh != undefined && it.BeneficiaryNameZh != "") {
                            content += it.BeneficiaryNameZh;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.BeneficiaryNameEn != null && it.BeneficiaryNameEn != undefined && it.BeneficiaryNameEn != "") {
                            content += it.BeneficiaryNameEn;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.SwiftCode != null && it.SwiftCode != undefined && it.SwiftCode != "") {
                            content += it.SwiftCode;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.CompanyBusinessCardBankAccounts) {
                            for (var it1 of it.CompanyBusinessCardBankAccounts) {
                                content += "账号/A/C NO." + it1.ParamCurrencySystemCode + " " + it1.Account + "\r\n";
                            }
                        }
                    }
                }
                //if (this.isInit != true) {
                //    this.form.Data.PaymentBankContent = content;
                //}
                this.form.Data.PaymentBankContent = content;
            }
        }

        onChangeCollectionBank(): void {
            this.form.Data.CollectionBankContent = undefined;
            var content = "";
            if (this.shipperBanks) {
                for (var it of this.shipperBanks) {
                    if (this.form.Data.CollectionBankId == it.Id) {
                        if (it.NameZh != null && it.NameZh != undefined && it.NameZh != "") {
                            content = it.NameZh;
                        }
                        else {
                            content = "—";
                        }
                        content += "\r\n";
                        if (it.NameEn != null && it.NameEn != undefined && it.NameEn != "") {
                            content += it.NameEn;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.BeneficiaryNameZh != null && it.BeneficiaryNameZh != undefined && it.BeneficiaryNameZh != "") {
                            content += it.BeneficiaryNameZh;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.BeneficiaryNameEn != null && it.BeneficiaryNameEn != undefined && it.BeneficiaryNameEn != "") {
                            content += it.BeneficiaryNameEn;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.SwiftCode != null && it.SwiftCode != undefined && it.SwiftCode != "") {
                            content += it.SwiftCode;
                        }
                        else {
                            content += "—";
                        }
                        content += "\r\n";
                        if (it.CompanyBusinessCardBankAccounts) {
                            for (var it1 of it.CompanyBusinessCardBankAccounts) {
                                content += "账号/A/C NO." + it1.ParamCurrencySystemCode + " " + it1.Account + "\r\n";
                            }
                        }
                    }
                }
                this.form.Data.CollectionBankContent = content;
            }
        }

        onChangeFirstConsignorId(isChange: boolean | undefined): void {
            this.shipperBanks = [];
            if (this.isInit != true && isChange != false) {
                this.form.Data.CollectionBankId = undefined;
                this.form.Data.CollectionBankContent = undefined;
            }
            this.form.Data.SellerContent = undefined;
            if (this.form.Data.FirstConsignorId == null || this.form.Data.FirstConsignorId == "") {
                this.form.Data.FirstConsignorId = undefined;
            }

            if (this.form.Data.FirstConsignorId != undefined && this.form.Data.FirstConsignorId) {
                if (this.companys) {
                    for (var company of this.companys) {
                        if (company.Id == this.form.Data.FirstConsignorId) {
                            this.form.Data.SellerContent = company.Content;
                            this.form.Data.SellerId = company.Id;
                            if (this.type == "10") {
                                this.form.Data.ConsignorCode = company.Code;
                                this.form.Data.ConsignorCompanyName = company.NameZh;
                            }
                            else if (this.type == "20") {
                                this.form.Data.ConsignorCode = company.Code;
                                this.form.Data.ConsignorCompanyName = company.NameEn;
                            }
                        }
                    }
                }

                var param = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    CompanyBusinessCardId: this.form.Data.FirstConsignorId
                }
                CompanyBusinessCardBankAppService.Get(param).then(r1 => {
                    if (r1.data.Data && r1.data.Data.Items) {
                        for (var it of r1.data.Data.Items) {
                            var name = "";
                            if (it.NameZh == null || it.NameZh == undefined || it.NameZh == "") {

                                name = "—"
                            }
                            else {
                                name = it.NameZh;
                            }
                            name += "/";
                            if (it.NameEn == null || it.NameEn == undefined || it.NameEn == "") {

                                name += "—"
                            }
                            else {
                                name += it.NameEn;
                            }
                            if (this.shipperBanks) {
                                this.shipperBanks.push({
                                    Id: it.Id,
                                    Name: name,
                                    CompanyBusinessCardBankAccounts: it.CompanyBusinessCardBankAccounts,
                                    AddressEn: it.AddressEn,
                                    AddressZh: it.AddressZh,
                                    BeneficiaryNameEn: it.BeneficiaryNameEn,
                                    BeneficiaryNameZh: it.BeneficiaryNameZh,
                                    NameEn: it.NameEn,
                                    NameZh: it.NameZh,
                                    SwiftCode: it.SwiftCode,
                                });
                            }

                        }
                        if (this.isInit != true) {
                            this.onChangeCollectionBank();
                        }
                        this.shipperBankRandom = Math.random();
                    }
                })
            }

        }

        onChangeFirstConsigneeId(isChange: boolean | undefined): void {
            this.form.Data.BuyerContent = undefined;
            this.consigneeBanks = [];
            if (this.isInit != true && isChange != false) {
                this.form.Data.PaymentBankId = undefined;
            }
            if (this.form.Data.FirstConsigneeId == null || this.form.Data.FirstConsigneeId == "") {
                this.form.Data.FirstConsigneeId = undefined;
            }
            if (this.form.Data.FirstConsigneeId != undefined && this.form.Data.FirstConsigneeId != "") {
                if (this.companys) {
                    for (var company of this.companys) {
                        if (company.Id == this.form.Data.FirstConsigneeId) {
                            this.form.Data.BuyerContent = company.Content;
                            this.form.Data.BuyerId = this.form.Data.FirstConsigneeId;
                            if (this.type == "10") {
                                this.form.Data.ConsigneeCode = company.Code;
                                this.form.Data.ConsigneeCompanyName = company.NameEn;
                            }
                            else if (this.type == "20") {
                                this.form.Data.ConsigneeCode = company.Code;
                                this.form.Data.ConsigneeCompanyName = company.NameZh;
                            }

                            if (company.ParamCurrencySystemId) {
                                if (this.currencys) {
                                    var code = "";
                                    for (var currency of this.currencys) {
                                        if (currency.Value == company.ParamCurrencySystemId) {
                                            code = currency.Data.Code;
                                        }
                                    }
                                    this.form.Data.FreightCurrencySystemId = company.ParamCurrencySystemId;
                                    this.form.Data.PremiumCurrencySystemId = company.ParamCurrencySystemId;
                                    this.form.Data.IncidentalCurrencySystemId = company.ParamCurrencySystemId;

                                    this.form.Data.FreightCurrencySystemName = code;
                                    this.form.Data.PremiumCurrencySystemName = code;
                                    this.form.Data.IncidentalCurrencySystemName = code;

                                    this.consigneeCurrencyId = company.ParamCurrencySystemId;
                                    this.consigneeCurrencyCode = code;

                                    if (this.form.Data.OrderDepartureCustomsProducts) {
                                        for (var pro of this.form.Data.OrderDepartureCustomsProducts) {
                                            pro.CurrencySystemId = company.ParamCurrencySystemId;
                                            pro.CurrencySystemName = code;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                var param = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    CompanyBusinessCardId: this.form.Data.FirstConsigneeId
                }
                CompanyBusinessCardBankAppService.Get(param).then(r1 => {
                    if (r1.data.Data && r1.data.Data.Items) {
                        for (var it of r1.data.Data.Items) {
                            var name = "";
                            if (it.NameZh == null || it.NameZh == undefined || it.NameZh == "") {

                                name = "—"
                            }
                            else {
                                name = it.NameZh;
                            }
                            name += "/";
                            if (it.NameEn == null || it.NameEn == undefined || it.NameEn == "") {

                                name += "—"
                            }
                            else {
                                name += it.NameEn;
                            }
                            if (this.consigneeBanks) {
                                this.consigneeBanks.push({
                                    Id: it.Id,
                                    Name: name,
                                    CompanyBusinessCardBankAccounts: it.CompanyBusinessCardBankAccounts,
                                    AddressEn: it.AddressEn,
                                    AddressZh: it.AddressZh,
                                    BeneficiaryNameEn: it.BeneficiaryNameEn,
                                    BeneficiaryNameZh: it.BeneficiaryNameZh,
                                    NameEn: it.NameEn,
                                    NameZh: it.NameZh,
                                    SwiftCode: it.SwiftCode,
                                });
                            }

                        }
                        if (this.isInit != true) {
                            this.onChangePaymentBank();
                        }
                        this.consigneeBankRandom = Math.random();
                    }
                })
            }
        }

        onGetCompany(isInit: boolean | undefined): void {
            this.shippers = [];
            this.consignees = [];
            this.productionSaleUnits = [];
            this.consumptionUseUnits = [];
            if (this.companys) {

                if (this.type == "10") {
                    for (var it of this.companys) {
                        if (it.Area == 1 && it.Type == 1) {//境内发货人
                            this.shippers.push({
                                Id: it.Id,
                                Name: it.NameZh,
                                Content: it.Content,
                                ParamCurrencySystemId: it.ParamCurrencySystemId,
                                Code: it.Code,
                            });
                        }
                        if (it.Area == 2 && it.Type == 2) {//境外收货人
                            this.consignees.push({
                                Id: it.Id,
                                Name: it.NameEn,
                                Content: it.Content,
                                ParamCurrencySystemId: it.ParamCurrencySystemId,
                                Code: it.Code,
                            });
                        }

                        if (it.Type == 10) {//生产销售单位
                            this.productionSaleUnits.push({
                                Id: it.Id,
                                Name: it.NameZh,
                                Content: it.Content,
                                ParamCurrencySystemId: it.ParamCurrencySystemId,
                                Code: it.Code,
                            });
                        }
                    }
                    if (this.productionSaleUnits != undefined && this.productionSaleUnits.length == 1) {
                        this.form.Data.ProductionSaleCode = this.productionSaleUnits[0].Code;
                        this.form.Data.ProductionSaleCompanyName = this.productionSaleUnits[0].Name;
                    }
                }
                else {
                    for (var it1 of this.companys) {//境外发货人
                        if (it1.Area == 2 && it1.Type == 1) {
                            this.shippers.push({
                                Id: it1.Id,
                                Name: it1.NameEn,
                                Content: it1.Content,
                                ParamCurrencySystemId: it1.ParamCurrencySystemId,
                                Code: it1.Code,
                            });
                        }

                        if (it1.Area == 1 && it1.Type == 2) {//境内收货人
                            this.consignees.push({
                                Id: it1.Id,
                                Name: it1.NameZh,
                                Content: it1.Content,
                                ParamCurrencySystemId: it1.ParamCurrencySystemId,
                                Code: it1.Code,
                            });
                        }

                        if (it1.Type == 11) {//消费使用单位
                            this.consumptionUseUnits.push({
                                Id: it1.Id,
                                Name: it1.NameZh,
                                Content: it1.Content,
                                ParamCurrencySystemId: it1.ParamCurrencySystemId,
                                Code: it1.Code,
                            });
                        }
                    }
                    if (this.consumptionUseUnits != undefined && this.consumptionUseUnits.length == 1) {
                        this.form.Data.ProductionSaleCode = this.consumptionUseUnits[0].Code;
                        this.form.Data.ProductionSaleCompanyName = this.consumptionUseUnits[0].Name;
                    }
                }
            }

            if (isInit == true) {
                if (this.form.Data.FirstConsignorId == null || this.form.Data.FirstConsignorId == undefined || this.form.Data.FirstConsignorId == "") {
                    if (this.shippers && this.shippers.length == 1) {
                        this.form.Data.FirstConsignorId = this.shippers[0].Id;
                        this.onChangeFirstConsignorId(true);
                    }
                }

                if (this.form.Data.FirstConsigneeId == null || this.form.Data.FirstConsigneeId == undefined || this.form.Data.FirstConsigneeId == "") {
                    if (this.consignees && this.consignees.length == 1) {
                        this.form.Data.FirstConsigneeId = this.consignees[0].Id;
                        this.onChangeFirstConsigneeId(true);
                    }
                }
            }

        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            this.form.Data.Type = Number(this.type);
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.form.Data && this.form.Data.PoolId != null && this.form.Data.PoolId != undefined && this.form.Data.PoolId != "") {
                        this.stateRequestParam.PoolId.push(this.form.Data.PoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                this.form.Data.OrderDepartureId = this.orderDepartureId;
                                MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                                    MiezzForm.submit(this.$refs, "ruleFormCustoms", (vCustoms) => {
                                        if (v == true && vCustoms) {
                                            OrderDepartureCustomsAppService.Post(this.form.Data)
                                                .then(() => {
                                                    this.handleSubmit();
                                                    this.handleBack();
                                                    ElMessage.success("保存成功");
                                                    complete();
                                                })
                                                .catch(complete);
                                        }
                                        else {
                                            complete();
                                        }
                                    });
                                });
                            }
                        }
                    })
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickPackage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    var packages = "";
                    for (var it of this.selectPackages) {
                        if (packages == "" && it.Label) {
                            packages = it.Label;
                        }
                        else {
                            packages += "|" + it.Label;
                        }
                    }
                    this.form.Data.OtherPackName = packages;
                    //this.selectPackages = [];
                    //const packageTable = this.$refs["packageTable"] as any;
                    //packageTable.clearSelection();
                    this.packRuleForm.Visible = false;
                    complete();
                    break;
                case "close":
                    this.packRuleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }

        /*添加自定义验证 */
        onRule(): void {

            this.form.Rules.DocumentProductCount = [
                {
                    required: true,
                    message: "请至少保留一个产品信息",
                    trigger: "change",
                },
            ];
            this.form.Rules.ElectronicAccountDataNumber = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.Freight = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.Premium = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.DepartureCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.StopPortId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];
            this.form.Rules.TransactionModeId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.PackName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ArrivalCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.TradingCountryId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ExemptionNatureId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.SupervisionModeId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ProductionSaleCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ProductionSaleCompanyName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ConsigneeCompanyName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.TransportModeId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ConsignorCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ConsignorCompanyName = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.OrderNumber = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.ContractNumber = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == undefined || value == "") {
                            callback();
                            return false;
                        }
                        if (value.length > 512) {
                            callback("合同协议号长度不能超过512个字符");
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "change",
                },
            ];
            this.form.Rules.OrderDate = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.form.Data.InvoiceDate == null || this.form.Data.InvoiceDate == undefined || typeof (this.form.Data.InvoiceDate) == "undefined" || moment(this.form.Data.InvoiceDate).isSame(value)) {
                            callback();
                            return false;
                        }
                        else if (!moment(this.form.Data.InvoiceDate).isAfter(value)) {
                            callback("订单日期不能晚于发票日期！");
                            return false;
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            this.form.Rules.InvoiceNumber = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.InvoiceDate = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (this.form.Data.OrderDate == null || this.form.Data.OrderDate == undefined || typeof (this.form.Data.OrderDate) == "undefined" || moment(this.form.Data.OrderDate).isSame(value)) {
                            callback();
                            return false;
                        }
                        else if (!moment(this.form.Data.OrderDate).isBefore(value)) {
                            callback("发票日期不能早于订单日期！");
                            return false;
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            this.form.Rules.DispatchPortId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];
            this.form.Rules.DestinationPortId = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];

            this.form.Rules.Address = [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            callback();
                            return false;
                        }
                        var reg = new RegExp("^[^\u4e00-\u9fa5]{1,512}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("最终交货地址只能输入不超过512个长度的非中文字符"));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
            this.form.Rules.DeliveryRequirement = [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            callback();
                            return false;
                        }
                        var reg = new RegExp("^[^\u4e00-\u9fa5]{1,512}$", "g");
                        if (!reg.test(value)) {
                            callback(new Error("交期要求只能输入不超过512个长度的非中文字符"));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: "blur",
                },
            ];
        }

    }

    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }
    /*去除连续空格，只保留一个空格 */
    function OneSpace(str: string) {

        var name = "";
        if (str != undefined && str != "") {
            var i = 0;
            for (i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                }
                else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (i = 0; i < str.length; i++) {
                if (str[i] != " ") {
                    name += str[i];
                }
                else {
                    if (i > 0 && str[i - 1] != " ") {
                        name += str[i];
                    }
                }
            }
        }
        return name.trim();
    }
    //检查hscode是否已经完善
    var checkComplete = function (product: any) {
        var nullStr = "无";
        if (isNullOrEmpty(product.HsCode)) {
            return false;
        }
        if (isNullOrEmpty(product.StatutoryFirstUnitValue)) {
            return false;
        }
        if (product.StatutorySecondUnit != nullStr && isNullOrEmpty(product.StatutorySecondUnitValue)) {
            return false;
        }
        for (var i = 0; i < product.ProductElements.length; i++) {
            if (isNullOrEmpty(product.ProductElements[i].Value) || product.ProductElements[i].Value == "undefined") {
                return false;
            }
        }

        return true;
    };

    function isNullOrEmpty(str: string) {
        if (str == null || str == undefined || str == "") {
            return true;
        }
        else {
            return false;
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {

        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };
