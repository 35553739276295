import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_param_transport_channel_detail = _resolveComponent("param-transport-channel-detail")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_param_transport_channel_form = _resolveComponent("param-transport-channel-form")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick,
      onHandleTopButtonUpload: _ctx.handleTopButtonUpload,
      onHandleDragSort: _ctx.handleDragSort
    }, null, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick", "onHandleTopButtonUpload", "onHandleDragSort"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDetail,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDetail) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_param_transport_channel_detail, {
          modelValue: _ctx.modalDetail,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDetail) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit,
          onHandleDelete: _ctx.handleDelete
        }, null, 8, ["modelValue", "id", "onHandleSubmit", "onHandleDelete"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_param_transport_channel_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalImportLog,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalImportLog) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importLogs, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "log-item",
            key: i
          }, [
            _createVNode(_component_el_alert, {
              title: item.Message,
              type: item.Type,
              effect: item.Effect,
              closable: false
            }, null, 8, ["title", "type", "effect"])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}