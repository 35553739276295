import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderStowageStack from "../models.machine/DtoPageListSearchOrderStowageStack";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderStowageStack from "../models.machine/DtoPageListOrderStowageStack";
import DtoRequestOrderStowageStack_Allocate from "../models.machine/DtoRequestOrderStowageStack_Allocate";
import DtoRequestOrderStowageStack_Cancel from "../models.machine/DtoRequestOrderStowageStack_Cancel";
import DtoRequestPrePackedBox from "../models.machine/DtoRequestPrePackedBox";
import DtoCallbackPrePackedBox from "../models.machine/DtoCallbackPrePackedBox";
import DtoCallbackOrderStowageStackWeight from "../models.machine/DtoCallbackOrderStowageStackWeight";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 配载栈
 * @description 自动生成的代码,请勿修改
 */
export default class OrderStowageStackAppService {
    /**
     * 获取配载栈分页列表
     * @param {DtoPageListSearchOrderStowageStack} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderStowageStack>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderStowageStack): AxiosPromise<RESTfulResult<DtoPageListOrderStowageStack>> {
        const url = `/api/OrderStowageStack/Get`;
        return service.get<RESTfulResult<DtoPageListOrderStowageStack>>(url, { params: dto });
    }

    /**
     * 调配
     * @param {DtoRequestOrderStowageStack_Allocate} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 即将取消的订单编号
     */
    static Allocate(dto?: DtoRequestOrderStowageStack_Allocate): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderStowageStack/Allocate`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 取消出运
     * @param {DtoRequestOrderStowageStack_Cancel} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 即将取消的订单编号
     */
    static Delete(dto?: DtoRequestOrderStowageStack_Cancel): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderStowageStack/Delete`;
        return service.delete<RESTfulResult<string[]>>(url, { data: dto });
    }

    /**
     * 取消配载
     * @param {DtoRequestOrderStowageStack_Cancel} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 即将取消的订单编号
     */
    static Cancel(dto?: DtoRequestOrderStowageStack_Cancel): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/OrderStowageStack/Cancel`;
        return service.post<RESTfulResult<string[]>>(url, dto);
    }

    /**
     * 加入预装箱
     * @param {DtoRequestPrePackedBox} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AddPrePackedBox(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderStowageStack/AddPrePackedBox`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取预装箱
     * @param {DtoRequestPrePackedBox} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackPrePackedBox>>} 
     */
    static GetPrePackedBox(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<DtoCallbackPrePackedBox>> {
        const url = `/api/OrderStowageStack/GetPrePackedBox`;
        return service.get<RESTfulResult<DtoCallbackPrePackedBox>>(url, { params: dto });
    }

    /**
     * 清空并关闭
     * @param {DtoRequestPrePackedBox} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static ClearPrePackedBox(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderStowageStack/ClearPrePackedBox`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 编辑预装项
     * @param {DtoRequestPrePackedBox} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static EditPrePackedBox(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderStowageStack/EditPrePackedBox`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 导出预装箱清单
     * @param {DtoRequestPrePackedBox} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderStowageStack/Export`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取计划计费重/入库计费重
     * @param {DtoCallbackOrderStowageStackWeight} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderStowageStackWeight>>} 
     */
    static GetWeight(dto?: DtoCallbackOrderStowageStackWeight): AxiosPromise<RESTfulResult<DtoCallbackOrderStowageStackWeight>> {
        const url = `/api/OrderStowageStack/GetWeight`;
        return service.get<RESTfulResult<DtoCallbackOrderStowageStackWeight>>(url, { params: dto });
    }

    /**
     * 设置为方案
     * @param {DtoRequestPrePackedBox} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetProgramme(dto?: DtoRequestPrePackedBox): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderStowageStack/SetProgramme`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 删除方案
     * @param {string[]} [id] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static RemoveProgramme(id?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderStowageStack/RemoveProgramme`;
        return service.post<RESTfulResult<any>>(url, id);
    }

    /**
     * 获取方案
     * @param {DtoPageListSearchOrderStowageStack} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetProgrammes(dto?: DtoPageListSearchOrderStowageStack): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderStowageStack/GetProgrammes`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

}
