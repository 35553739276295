
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoPageListSearchCompanyType, {
  DtoPageListSearchCompanyTypeDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyType";
import DtoPageListCompanyType from "@/models.machine/DtoPageListCompanyType";
import DtoListItemCompanyType, {
  DtoListItemCompanyTypeMiezzListItems,
} from "@/models.machine/DtoListItemCompanyType";
import CompanyTypeDetail from "./CompanyTypeDetail.vue";
import CompanyTypeForm from "./CompanyTypeForm.vue";
import {
  EnumCompanyType,
  EnumCompanyTypeOption,
} from "@/models.machine/EnumCompanyType";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";

@Options({
  components: {
    CompanyTypeDetail,
    CompanyTypeForm,
  },
})
export default class CompanyTypePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyType,
    DtoListItemCompanyType,
    string
  >();
  /**当前Id */
  id?: string;
  /**详细模态框 */
  modalDetail = new MiezzModal();
  /**表单模态框 */
  modalForm = new MiezzModal();
  types = EnumCompanyTypeOption;

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyType();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyTypeDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyType();
    //列表-列配置
    this.list.Items = DtoListItemCompanyTypeMiezzListItems;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
      {
        Code: "drag-sort",
        Title: "拖曳",
        Icon: "Rank",
        Type: "default",
        Size: "small",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "default",
        Type: "text",
        Message: "是否确认删除",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    CompanyTypeAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    CompanyTypeAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增公司类型";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyType,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        this.modalDetail.Show = true;
        this.modalDetail.Title = `公司类型详细-${row.Name}`;
        this.id = row.Id;
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        CompanyTypeAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }
}
