
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchOrderPoolExpress from "@/models.machine/DtoPageListSearchOrderPoolExpress";
import DtoListItemOrderPoolExpress from "@/models.machine/DtoListItemOrderPoolExpress";
import ExpressPageList from "./ExpressPageList.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
@Options({
  components: {
    ExpressPageList,
  },
})
export default class DestinationPortExpressPageList extends Vue {
  menuTab = new MiezzMenuTab();
  list = new MiezzPageList<
    DtoPageListSearchOrderPoolExpress,
    DtoListItemOrderPoolExpress,
    string
  >("OrderManagement_Pool_Summary_DestinationPortExpress");
  powers: string[] = [];
  serviceType = EnumOrderServiceType.DestinationPort;

  created(): void {
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/destination-port-express/${this.$route.params.id}`;
    this.menuTab.Menus = [
      {
        Label: "目的港-快递",
        Url: `/order/pool/destination-port-express/${this.$route.params.id}`,
        Power: "OrderManagement_Pool_Summary_DestinationPortExpress",
      },
    ];
  }
}
