
import CodeControllerAppService from "@/apis.machine/CodeControllerAppService";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
import router from "@/router";
import CodeMethodAppService from "@/apis.machine/CodeMethodAppService";
import CodeMethodCallbackForm from "./CodeMethodCallbackForm.vue";
import CodeMethodParamForm from "./CodeMethodParamForm.vue";
import { ElMessage } from "element-plus";
import DtoFormCodeMethod, {
  DtoFormCodeMethodMiezzFormItems,
  DtoFormCodeMethodRules,
} from "@/models.machine/DtoFormCodeMethod";
import TranslateAppService from "@/apis.machine/TranslateAppService";
import DtoFormCodeMethodParam from "@/models.machine/DtoFormCodeMethodParam";
import DtoFormCodeMethodCallback from "@/models.machine/DtoFormCodeMethodCallback";

@Options({
  components: {
    CodePropertyTree,
    CodeMethodCallbackForm,
    CodeMethodParamForm,
  },
})
export default class CodeMethodForm extends Vue {
  @Prop() id?: string;

  form = new MiezzForm<DtoFormCodeMethod, string>();
  buttons = [
    {
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
  ];
  modal = new MiezzModal();
  drawerCode?: string;
  drawerFormData?: any;

  created(): void {
    this.form.Data = new DtoFormCodeMethod();
    var param = new DtoFormCodeMethodParam();
    param.Name = "DtoRequest";
    param.Summary = "传参";
    param.FieldName = "dto";
    this.form.Data.Params?.push(param);
    this.form.Data.Callback = new DtoFormCodeMethodCallback();
    this.form.Data.Callback.Name = "DtoCallback";
    this.form.Data.Callback.Summary = "结果";
    this.form.DefaultSpan = 12;
    this.form.Rules = DtoFormCodeMethodRules;
    this.form.Items = DtoFormCodeMethodMiezzFormItems;

    for (const item of this.form.Items) {
      switch (item.Prop) {
        case "Group":
          CodeControllerAppService.GetGroupSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        case "Table":
          CodeControllerAppService.GetTableSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        case "IdType":
          item.AllowCreate = true;
          item.Options = [
            { Label: "Guid", Value: "Guid" },
            { Label: "string", Value: "string" },
            { Label: "int", Value: "int" },
            { Label: "long", Value: "long" },
          ];
          break;
        default:
          break;
      }
    }

    const query = router.currentRoute.value.query;
    if (query.Group) {
      this.form.Data.Group = query.Group as string;
      if (query.App) {
        this.form.Data.App = query.App as string;
        if (query.Service) {
          this.form.Data.Service = query.Service as string;
        }
      }
    }
  }

  @Watch("form.Data.Group")
  onGroupChanged(): void {
    const app = this.form.Items?.find((it) => it.Prop == "App");
    CodeControllerAppService.GetAppSelectByGroup(this.form.Data.Group).then((r) => {
      if (app && r.data.Data) {
        app.Options = r.data.Data;
        this.onAppChanged();
      }
    });
  }

  @Watch("form.Data.App")
  onAppChanged(): void {
    if (
      this.form.Data.Params &&
      this.form.Data.Params.length == 1 &&
      this.form.Data.Params[0].Name?.indexOf("DtoRequest") == 0
    ) {
      const param = this.form.Data.Params[0];
      if (param.Name && param.Name.indexOf("DtoRequest") == 0) {
        param.Name = `DtoRequest${this.form.Data.App}`;
        if (this.form.Data.Name) {
          param.Name += this.form.Data.Name;
        }
      }
    }
    if (
      this.form.Data.Callback &&
      this.form.Data.Callback.Name &&
      this.form.Data.Callback.Name.indexOf("DtoCallback") == 0
    ) {
      this.form.Data.Callback.Name = `DtoCallback${this.form.Data.App}`;
      if (this.form.Data.Name) {
        this.form.Data.Callback.Name += this.form.Data.Name;
      }
    }

    const service = this.form.Items?.find((it) => it.Prop == "Service");
    CodeControllerAppService.GetServiceSelectByGroupByApp(
      this.form.Data.Group,
      this.form.Data.App
    ).then((r) => {
      if (service && r.data.Data) {
        service.Options = r.data.Data;
        this.onServiceChanged();
      }
    });
  }

  @Watch("form.Data.Service")
  onServiceChanged(): void {
    const option = this.form.Items?.find(
      (it) => it.Prop == "Service"
    )?.Options?.find((it) => it.Value == this.form.Data.Service);
    if (option?.Data) {
      this.form.Data.Table = option.Data.Table;
      this.form.Data.IdType = option.Data.IdType;
    }
  }

  @Watch("form.Data.Name")
  onNameChanged(): void {
    if (
      this.form.Data.Params &&
      this.form.Data.Params.length == 1 &&
      this.form.Data.Params[0].Name?.indexOf("DtoRequest") == 0
    ) {
      const param = this.form.Data.Params[0];
      if (param.Name && param.Name.indexOf("DtoRequest") == 0) {
        param.Name = `DtoRequest${this.form.Data.App}${this.form.Data.Name}`;
      }
    }
    if (
      this.form.Data.Callback &&
      this.form.Data.Callback.Name &&
      this.form.Data.Callback.Name.indexOf("DtoCallback") == 0
    ) {
      this.form.Data.Callback.Name = `DtoCallback${this.form.Data.App}${this.form.Data.Name}`;
    }
  }

  handleSubmit(): void {
    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
      if (v) {
        CodeMethodAppService.AddMethod(this.form.Data).then((r) => {
          ElMessage.success("添加成功");
          router.push("/developer/tool/controller");
        });
      }
    });
  }
}
