
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import PricePageList from "../../PricePageList.vue";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
@Options({
  components: {
    PricePageList,
  },
})
export default class PriceTableDetail extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_SetUp_Table_Detail"
  );
  amountType = EnumPriceAmountType.Benchmark;
  created(): void {
    this.list.Back = true;
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "delete-all",
        Label: "删除全部",
        Icon: "delete",
        Type: "primary",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_DeleteAll",
      },
      {
        Code: "download",
        Label: "模板",
        Icon: "download",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Import",
      },
      {
        Code: "import",
        Label: "导入",
        Icon: "top",
        Type: "primary",
        Size: "small",
        Placement: "bottom-start",
        Options: [
          {
            FileUpload: true,
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Code: "import-from-template",
            Label: "从模板导入",
            Size: "small",
          },
          {
            Code: "import-from-cost",
            Label: "从成本管理导入",
            Size: "small",
          },
        ],
        PowerCode: "PriceManagement_SetUp_Table_Detail_Import",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Export",
      },
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_AddEdit",
      },
    ];
    this.list.OperationWidth = "160px";
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_AddEdit",
      },
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_History",
      },
      {
        Code: "formula",
        Title: "公式",
        MiezzIcon: "formula",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Formula",
      },
      // {
      //   Code: "delete",
      //   Title: "删除",
      //   Icon: "delete",
      //   Type: "default",
      //   Message: "是否确认删除",
      //   Size: "small",
      //   PowerCode: "PriceManagement_SetUp_Table_Detail_Delete",
      // },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "amount-update",
        Label: "批量编辑",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Table_Detail_AddEdit",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Export",
      },
      {
        Code: "add-formula",
        Label: "添加/编辑公式",
        MiezzIcon: "formula",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Formula",
      },
      {
        Code: "delete-formula",
        Label: "删除公式",
        MiezzIcon: "formula",
        Type: "text",
        Message: "是否确认将该信息公式删除？",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Formula",
      },
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Table_Detail_Delete",
      },
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];
  }
}
