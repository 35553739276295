import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 项目表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormProject extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 数据库上下文名称
     */
    DbContextName?: string;
    /**
     * Web目录
     */
    WebPath?: string;
    /**
     * 前端目录
     */
    ClientPath?: string;
    /**
     * 前端打包工具
     */
    ClientPackTool?: string;
    /**
     * 前端打包命令
     */
    ClientPackCmd?: string;
    /**
     * Git仓库地址
     */
    GitUrl?: string;
}

/**
 * 项目表单-表单校验
 */
export const DtoFormProjectRules = {
    Name: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    DbContextName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    WebPath: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ClientPath: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    ClientPackTool: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    ClientPackCmd: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    GitUrl: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 项目表单-设置项
 */
export const DtoFormProjectMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DbContextName",
    Label: "数据库上下文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "WebPath",
    Label: "Web目录",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ClientPath",
    Label: "前端目录",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ClientPackTool",
    Label: "前端打包工具",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ClientPackCmd",
    Label: "前端打包命令",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GitUrl",
    Label: "Git仓库地址",
    Type: EnumMiezzFormItemType.InputText,
  },
]
