import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_menu_detail = _resolveComponent("menu-detail")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_menu_form = _resolveComponent("menu-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      ref: "page-list",
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick
    }, {
      "dynamic-search-customize": _withCtx(({ model }) => [
        (model.Where.Prop == 'DynamicSearch_Power')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.EnumMenuPowerOption,
              number: true
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDetail,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDetail) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_menu_detail, {
          modelValue: _ctx.modalDetail,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDetail) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit,
          onHandleDelete: _ctx.handleDelete
        }, null, 8, ["modelValue", "id", "onHandleSubmit", "onHandleDelete"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_menu_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event)),
          ref: "menu-form",
          id: _ctx.id,
          path: _ctx.path,
          scene: 
        _ctx.$route.query.RadioSearch
          ? parseInt(_ctx.$route.query.RadioSearch)
          : undefined
      ,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "path", "scene", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}