import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemProjectSite_Project from "./DtoListItemProjectSite_Project";
import { EnumProductSiteEnvironment, EnumProductSiteEnvironmentOption } from "./EnumProductSiteEnvironment";

/**
 * 项目-站点分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemProjectSite extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Official = false;
    }

    [proName: string]: any;
    /**
     * 项目Id
     */
    ProjectId?: string;
    /**
     * 项目
     */
    Project?: DtoListItemProjectSite_Project;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编码
     */
    Code?: string;
    /**
     * 站点-Ip4
     */
    SiteIp4?: string;
    /**
     * 站点-IIS应用名称
     */
    SiteIisName?: string;
    /**
     * 站点-地址
     */
    SitePath?: string;
    /**
     * 数据库-连接地址
     */
    DbConnectionString?: string;
    /**
     * 环境
     */
    Environment?: EnumProductSiteEnvironment;
    /**
     * 是否为官方
     */
    Official?: boolean;
}

/**
 * 项目-站点分页列表-项-表单校验
 */
export const DtoListItemProjectSiteRules = {
}

/**
 * 项目-站点分页列表-项-设置项
 */
export const DtoListItemProjectSiteMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Project",
    Label: "项目",
  },
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "Code",
    Label: "编码",
  },
  {
    Prop: "SiteIp4",
    Label: "站点-Ip4",
  },
  {
    Prop: "SiteIisName",
    Label: "站点-IIS应用名称",
  },
  {
    Prop: "SitePath",
    Label: "站点-地址",
  },
  {
    Prop: "DbConnectionString",
    Label: "数据库-连接地址",
  },
  {
    Prop: "Environment",
    Label: "环境",
    Pipe: EnumPipe.Enum,
    Options: EnumProductSiteEnvironmentOption,
  },
  {
    Prop: "Official",
    Label: "是否为官方",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
]
