import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoListItemRole_JoinCompanyTypeRole from "./DtoListItemRole_JoinCompanyTypeRole";

/**
 * 角色分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemRole extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.MinLevel = 0;
        this.JoinCompanyTypeRoles = [];
        this.Official = false;
        this.Disabled = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 最低级别
     */
    MinLevel?: number;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 公司身份
     */
    JoinCompanyTypeRoles?: DtoListItemRole_JoinCompanyTypeRole[];
    /**
     * 官方
     */
    Official?: boolean;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 禁用
     */
    Disabled?: boolean;
}

/**
 * 角色分页列表-项-表单校验
 */
export const DtoListItemRoleRules = {
}

/**
 * 角色分页列表-项-设置项
 */
export const DtoListItemRoleMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "MinLevel",
    Label: "最低级别",
  },
  {
    Prop: "Type",
    Label: "公司类型",
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "JoinCompanyTypeRoles",
    Label: "公司身份",
  },
  {
    Prop: "Official",
    Label: "官方",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
  {
    Prop: "Disabled",
    Label: "禁用",
    MaxWidth: "120px",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "禁用",
    WhenFalse: "启用",
  },
]
