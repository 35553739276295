import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61cf1275"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fatButtons"
}
const _hoisted_2 = {
  key: 1,
  class: "fatButtons"
}
const _hoisted_3 = {
  key: 2,
  style: {"width":"100%","text-align":"center"}
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_button_source = _resolveComponent("miezz-button-source")!

  return (_ctx.isBackground != true && _ctx.isFix != true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.modelValue && _ctx.modelValue.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.isBackground != true ? 'buttons' : '')
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
                return (_openBlock(), _createBlock(_component_miezz_button, {
                  style: _normalizeStyle(_ctx.isBackground != true ? 'color: #fff;' : ''),
                  key: i,
                  model: item,
                  onHandleClick: _ctx.handleClick,
                  isBackground: _ctx.isBackground
                }, null, 8, ["style", "model", "onHandleClick", "isBackground"]))
              }), 128)),
              (_ctx.close)
                ? (_openBlock(), _createBlock(_component_miezz_button, {
                    key: 0,
                    model: _ctx.closeBtn,
                    onHandleClick: _ctx.handleClick,
                    isBackground: _ctx.isBackground
                  }, null, 8, ["model", "onHandleClick", "isBackground"]))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : (_ctx.isFix == true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.modelValue && _ctx.modelValue.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.isBackground != true ? 'buttons' : '')
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
                  return (_openBlock(), _createBlock(_component_miezz_button, {
                    style: _normalizeStyle(_ctx.isBackground != true ? 'color: #fff;' : ''),
                    key: i,
                    model: item,
                    onHandleClick: _ctx.handleClick,
                    isBackground: _ctx.isBackground
                  }, null, 8, ["style", "model", "onHandleClick", "isBackground"]))
                }), 128)),
                (_ctx.close)
                  ? (_openBlock(), _createBlock(_component_miezz_button, {
                      key: 0,
                      model: _ctx.closeBtn,
                      onHandleClick: _ctx.handleClick,
                      isBackground: _ctx.isBackground
                    }, null, 8, ["model", "onHandleClick", "isBackground"]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.modelValue && _ctx.modelValue.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, i) => {
                  return (_openBlock(), _createBlock(_component_miezz_button_source, {
                    key: i,
                    isBackground: _ctx.isBackground,
                    model: item,
                    onHandleSourceClick: _ctx.handleClick
                  }, null, 8, ["isBackground", "model", "onHandleSourceClick"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
}