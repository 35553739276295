import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchSms from "../models.machine/DtoPageListSearchSms";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListSms from "../models.machine/DtoPageListSms";
import DtoFormSmsSend from "../models.machine/DtoFormSmsSend";
import DtoFormSmsSendVerifyCode from "../models.machine/DtoFormSmsSendVerifyCode";
import DtoFormSmsCheckVerifyCode from "../models.machine/DtoFormSmsCheckVerifyCode";

/**
 * 短信
 * @description 自动生成的代码,请勿修改
 */
export default class SmsAppService {
    /**
     * 获取列表
     * @param {DtoPageListSearchSms} [search] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListSms>>} 
     */
    static GetPageList(search?: DtoPageListSearchSms): AxiosPromise<RESTfulResult<DtoPageListSms>> {
        const url = `/api/Sms/GetPageList`;
        return service.get<RESTfulResult<DtoPageListSms>>(url, { params: search });
    }

    /**
     * 发送短信
     * @param {DtoFormSmsSend} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SendSmsAsync(dto?: DtoFormSmsSend): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Sms/SendSms`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 发送短信验证码
     * @param {DtoFormSmsSendVerifyCode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SendVerifyCodeAsync(dto?: DtoFormSmsSendVerifyCode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Sms/SendVerifyCode`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 校验验证码
     * @param {DtoFormSmsCheckVerifyCode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static CheckVerifyCode(dto?: DtoFormSmsCheckVerifyCode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Sms/CheckVerifyCode`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
