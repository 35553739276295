import UiSelectOption from "./UiSelectOption";

/**
 * 客户来源
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomerSource {
    /**
     * 客户
     */
    Customer = 1,
    /**
     * 服务商
     */
    ServiceProvider = 2,
}

/**
 * 客户来源-选项
 */
export const EnumCustomerSourceOption: UiSelectOption<number>[] = [
    { Label: "客户", Value: 1, Data: "Customer" },
    { Label: "服务商", Value: 2, Data: "ServiceProvider" },
];
