import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_text_ellipsis = _resolveComponent("miezz-text-ellipsis")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      model: _ctx.list.Data,
      ref: "list-form",
      "label-width": "0px",
      inline: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("h1", null, [
          _createTextVNode(" 合计： "),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totals, (item, i) => {
            return (_openBlock(), _createElementBlock("span", {
              style: {"margin-right":"5px","color":"red"},
              key: i
            }, _toDisplayString(item.CurrencySystem) + " " + _toDisplayString(item.Amount), 1))
          }), 128))
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_miezz_page_list, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
          onHandleRowButtonClick: _ctx.handleRowButtonClick
        }, {
          column: _withCtx(({ model, row, index }) => [
            (model.Prop == 'CurrencySystem')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_el_form_item, { style: {"margin-bottom":"13px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_select, {
                        modelValue: row.CurrencySystemId,
                        "onUpdate:modelValue": ($event: any) => ((row.CurrencySystemId) = $event),
                        label: row.CurrencySystem,
                        "onUpdate:label": ($event: any) => ((row.CurrencySystem) = $event),
                        options: _ctx.currencySystems,
                        clearable: false,
                        onHandleChange: _ctx.getTotal
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "onUpdate:label", "options", "onHandleChange"])
                    ]),
                    _: 2
                  }, 1024)
                ]))
              : (model.Prop == 'Amount')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_el_form_item, { style: {"margin-bottom":"13px"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Amount,
                          "onUpdate:modelValue": ($event: any) => ((row.Amount) = $event),
                          pattern: "[0-9.-]",
                          number: true,
                          numberFixed: 2,
                          onHandleBlur: _ctx.getTotal
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : (model.Prop == 'FirstChargingNumber')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_el_form_item, {
                        prop: `Items[${index}]`,
                        style: {"margin-bottom":"13px"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_input, {
                            modelValue: row.FirstChargingNumber,
                            "onUpdate:modelValue": ($event: any) => ((row.FirstChargingNumber) = $event),
                            pattern: "[0-9.]",
                            number: true,
                            numberFixed: 2,
                            greaterThanNumber: 0,
                            onHandleBlur: _ctx.getTotal
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                        ]),
                        _: 2
                      }, 1032, ["prop"])
                    ]))
                  : (model.Prop == 'FirstChargingUnitId')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_el_form_item, {
                          prop: `Items[${index}]`,
                          style: {"margin-bottom":"13px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_miezz_select, {
                              modelValue: row.FirstChargingUnitId,
                              "onUpdate:modelValue": ($event: any) => ((row.FirstChargingUnitId) = $event),
                              options: _ctx.units,
                              clearable: false
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                          ]),
                          _: 2
                        }, 1032, ["prop"])
                      ]))
                    : (model.Prop == 'Description')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          (row.Description)
                            ? (_openBlock(), _createBlock(_component_el_popover, {
                                key: 0,
                                placement: "top-start",
                                "popper-class": "popover-tooltip",
                                effect: "dark",
                                trigger: "hover"
                              }, {
                                reference: _withCtx(() => [
                                  _createVNode(_component_miezz_text_ellipsis, {
                                    text: row.Description
                                  }, null, 8, ["text"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.Descriptions, (item, i) => {
                                    return (_openBlock(), _createElementBlock("p", { key: i }, _toDisplayString(item), 1))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createElementBlock("span", _hoisted_6, "--"))
                        ]))
                      : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "onHandleRowButtonClick"])), [
          [_directive_loading, _ctx.loading]
        ])
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalSupplier,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalSupplier) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: _ctx.modalDataSupplier,
          ref: "ruleFormSupplier",
          class: "rule-form-supplier",
          "label-width": "150px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "供应商",
              prop: "SupplierName",
              rules: _ctx.rules.SupplierName
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.modalDataSupplier.SupplierName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDataSupplier.SupplierName) = $event)),
                  "fetch-suggestions": _ctx.getSuppliers,
                  "trigger-on-focus": true,
                  clearable: "",
                  valueKey: "NameZh",
                  onHandleSelect: _ctx.handleSelectSupplier,
                  onHandleChange: _ctx.handleSelectSupplier
                }, null, 8, ["modelValue", "fetch-suggestions", "onHandleSelect", "onHandleChange"])
              ]),
              _: 1
            }, 8, ["rules"]),
            _createVNode(_component_el_form_item, {
              label: "联系人",
              prop: "SupplierContacts",
              rules: _ctx.rules.SupplierContacts
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.modalDataSupplier.SupplierContacts,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDataSupplier.SupplierContacts) = $event)),
                  "fetch-suggestions": _ctx.getSupplierCompanyContact,
                  "trigger-on-focus": true,
                  clearable: "",
                  "value-key": "Label"
                }, null, 8, ["modelValue", "fetch-suggestions"])
              ]),
              _: 1
            }, 8, ["rules"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}