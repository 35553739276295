import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderPoolProcessCount_Order from "./DtoListItemOrderPoolProcessCount_Order";
import DtoListItemOrderPoolProcessCount_Take from "./DtoListItemOrderPoolProcessCount_Take";
import DtoListItemOrderPoolProcessCount_Warehouse from "./DtoListItemOrderPoolProcessCount_Warehouse";
import DtoListItemOrderPoolProcessCount_Customs from "./DtoListItemOrderPoolProcessCount_Customs";
import DtoListItemOrderPoolProcessCount_Booking from "./DtoListItemOrderPoolProcessCount_Booking";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolProcessCount extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.DeparturePortTakeRed = false;
        this.DeparturePortTakes = [];
        this.DeparturePortWarehouseRed = false;
        this.DeparturePortWarehouses = [];
        this.DeparturePortContainerRed = false;
        this.DeparturePortContainers = [];
        this.DeparturePortDeclareCustomsRed = false;
        this.DeparturePortDeclareCustomsList = [];
        this.DeparturePortBookingRed = false;
        this.DeparturePortBookings = [];
        this.DestinationPortBookingRed = false;
        this.DestinationPortBookings = [];
        this.DestinationPortDeclareCustomsRed = false;
        this.DestinationPortDeclareCustomsList = [];
        this.DestinationPortContainerRed = false;
        this.DestinationPortContainers = [];
        this.DestinationPortWarehouseRed = false;
        this.DestinationPortWarehouses = [];
        this.DestinationPortTakeRed = false;
        this.DestinationPortTakes = [];
        this.DestinationPortDeliverRed = false;
        this.DestinationPortDelivers = [];
        this.DeparturePortBooking = false;
        this.DeparturePortTake = false;
        this.DeparturePortWarehouse = false;
        this.DeparturePortDeclareCustoms = false;
        this.DestinationPortClearCustoms = false;
        this.DestinationPortDelivery = false;
        this.DestinationPortWarehouse = false;
    }

    [proName: string]: any;
    /**
     * 委托人
     */
    ClientCompanyLabel?: string;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 订单
     */
    Order?: DtoListItemOrderPoolProcessCount_Order;
    /**
     * 订单号码
     */
    OrderCode?: string;
    /**
     * 装货港提取
     */
    DeparturePortTakeText?: string;
    /**
     * 装货港提取-红色标记
     */
    DeparturePortTakeRed?: boolean;
    /**
     * 装货港提取-弹框列表
     */
    DeparturePortTakes?: DtoListItemOrderPoolProcessCount_Take[];
    /**
     * 装货港入库
     */
    DeparturePortWarehouseText?: string;
    /**
     * 装货港入库-红色标记
     */
    DeparturePortWarehouseRed?: boolean;
    /**
     * 装货港入库-弹框列表
     */
    DeparturePortWarehouses?: DtoListItemOrderPoolProcessCount_Warehouse[];
    /**
     * 装货港装柜
     */
    DeparturePortContainerText?: string;
    /**
     * 装货港装柜-红色标记
     */
    DeparturePortContainerRed?: boolean;
    /**
     * 装货港装柜-弹框列表
     */
    DeparturePortContainers?: DtoListItemOrderPoolProcessCount_Take[];
    /**
     * 装货港放行
     */
    DeparturePortDeclareCustomsText?: string;
    /**
     * 装货港放行-红色标记
     */
    DeparturePortDeclareCustomsRed?: boolean;
    /**
     * 装货港放行-弹框列表
     */
    DeparturePortDeclareCustomsList?: DtoListItemOrderPoolProcessCount_Customs[];
    /**
     * 装货港开航
     */
    DeparturePortBookingText?: string;
    /**
     * 装货港开航-红色标记
     */
    DeparturePortBookingRed?: boolean;
    /**
     * 装货港开航-弹框列表
     */
    DeparturePortBookings?: DtoListItemOrderPoolProcessCount_Booking[];
    /**
     * 目的港抵达
     */
    DestinationPortBookingText?: string;
    /**
     * 目的港抵达-红色标记
     */
    DestinationPortBookingRed?: boolean;
    /**
     * 目的港抵达-弹框列表
     */
    DestinationPortBookings?: DtoListItemOrderPoolProcessCount_Booking[];
    /**
     * 目的港放行
     */
    DestinationPortDeclareCustomsText?: string;
    /**
     * 目的港放行-红色标记
     */
    DestinationPortDeclareCustomsRed?: boolean;
    /**
     * 目的港放行-弹框列表
     */
    DestinationPortDeclareCustomsList?: DtoListItemOrderPoolProcessCount_Customs[];
    /**
     * 目的港提柜
     */
    DestinationPortContainerText?: string;
    /**
     * 目的港提柜-红色标记
     */
    DestinationPortContainerRed?: boolean;
    /**
     * 目的港提柜-弹框列表
     */
    DestinationPortContainers?: DtoListItemOrderPoolProcessCount_Take[];
    /**
     * 目的港入库
     */
    DestinationPortWarehouseText?: string;
    /**
     * 目的港入库-红色标记
     */
    DestinationPortWarehouseRed?: boolean;
    /**
     * 目的港入库-弹框列表
     */
    DestinationPortWarehouses?: DtoListItemOrderPoolProcessCount_Warehouse[];
    /**
     * 目的港提取
     */
    DestinationPortTakeText?: string;
    /**
     * 目的港提取-红色标记
     */
    DestinationPortTakeRed?: boolean;
    /**
     * 目的港提取-弹框列表
     */
    DestinationPortTakes?: DtoListItemOrderPoolProcessCount_Take[];
    /**
     * 目的港送达
     */
    DestinationPortDeliverText?: string;
    /**
     * 目的港送达-红色标记
     */
    DestinationPortDeliverRed?: boolean;
    /**
     * 目的港送达-弹框列表
     */
    DestinationPortDelivers?: DtoListItemOrderPoolProcessCount_Take[];
    /**
     * 装货港ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 目的港ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 装货港服务-订舱
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 目的港服务-清关
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
}

/**
 * -表单校验
 */
export const DtoListItemOrderPoolProcessCountRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPoolProcessCountMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ClientCompanyLabel",
    Label: "委托人",
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "OrderCode",
    Label: "订单号码",
    Customize: true,
  },
  {
    Prop: "DeparturePortTakeText",
    Label: "装货港提取",
    Customize: true,
  },
  {
    Prop: "DeparturePortTakes",
    Label: "装货港提取-弹框列表",
    Hide: true,
  },
  {
    Prop: "DeparturePortWarehouseText",
    Label: "装货港入库",
    Customize: true,
  },
  {
    Prop: "DeparturePortWarehouses",
    Label: "装货港入库-弹框列表",
    Hide: true,
  },
  {
    Prop: "DeparturePortContainerText",
    Label: "装货港装柜",
    Customize: true,
  },
  {
    Prop: "DeparturePortContainers",
    Label: "装货港装柜-弹框列表",
    Hide: true,
  },
  {
    Prop: "DeparturePortDeclareCustomsText",
    Label: "装货港放行",
    Customize: true,
  },
  {
    Prop: "DeparturePortDeclareCustomsList",
    Label: "装货港放行-弹框列表",
    Hide: true,
  },
  {
    Prop: "DeparturePortBookingText",
    Label: "装货港开航",
    Customize: true,
  },
  {
    Prop: "DeparturePortBookings",
    Label: "装货港开航-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortBookingText",
    Label: "目的港抵达",
    Customize: true,
  },
  {
    Prop: "DestinationPortBookings",
    Label: "目的港抵达-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortDeclareCustomsText",
    Label: "目的港放行",
    Customize: true,
  },
  {
    Prop: "DestinationPortDeclareCustomsList",
    Label: "目的港放行-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortContainerText",
    Label: "目的港提柜",
    Customize: true,
  },
  {
    Prop: "DestinationPortContainers",
    Label: "目的港提柜-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortWarehouseText",
    Label: "目的港入库",
    Customize: true,
  },
  {
    Prop: "DestinationPortWarehouses",
    Label: "目的港入库-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortTakeText",
    Label: "目的港提取",
    Customize: true,
  },
  {
    Prop: "DestinationPortTakes",
    Label: "目的港提取-弹框列表",
    Hide: true,
  },
  {
    Prop: "DestinationPortDeliverText",
    Label: "目的港送达",
    Customize: true,
  },
  {
    Prop: "DestinationPortDelivers",
    Label: "目的港送达-弹框列表",
    Hide: true,
  },
]
