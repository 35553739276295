
import { Vue } from "vue-class-component";
import { Model, Prop, Watch } from "vue-property-decorator";

export default class MiezzDateTimeRange extends Vue {
  @Model() start?: Date;
  @Model() end?: Date;
  range: Date[] = [];

  created(): void {
    this.handleTimeChanged();
  }

  @Watch("start")
  handleStartChanged(): void {
    this.handleTimeChanged();
  }

  @Watch("end")
  handleEndChanged(): void {
    this.handleTimeChanged();
  }

  handleTimeChanged(): void {
    if (this.start && this.end) {
      this.range = [this.start, this.end];
    } else {
      this.range = [];
    }
  }

  handleChange(): void {
    if (this.range?.length == 2) {
      this.$emit("update:start", this.range[0]);
      this.$emit("update:end", this.range[1]);
    } else {
      this.$emit("update:start", undefined);
      this.$emit("update:end", undefined);
    }
  }
}
