
import AuthAppService from "@/apis.machine/AuthAppService";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import SmsAppService from "@/apis.machine/SmsAppService";
import SysAppService from "@/apis.machine/SysAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoCallbackAuthCheckUser from "@/models.machine/DtoCallbackAuthCheckUser";
import DtoFormRegister, {
  DtoFormRegisterMiezzFormItems,
  DtoFormRegisterRules,
} from "@/models.machine/DtoFormRegister";
import DtoFormSmsSendVerifyCode from "@/models.machine/DtoFormSmsSendVerifyCode";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import Logier from "@/models.machine/Logier";
import UiSelectOption from "@/models.machine/UiSelectOption";
import WebOptions from "@/models.machine/WebOptions";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class Register extends Vue {
  height = "480px";
  form = new MiezzForm<DtoFormRegister, string>();
  companyTypes?: UiSelectOption<string>[] = [];
  companyType?: string = "";
  check: DtoCallbackAuthCheckUser | null | undefined = null;
  countdown = 0;
  buttons: MiezzButton[] = [
    {
      Code: "register",
      Label: "提交",
      Type: "primary",
    },
  ];
  btnPasswordLogin: MiezzButton = {
    Code: "password-login",
    Label: "密码登录",
    Type: "text",
    Size: "small",
  };
  btnRegister: MiezzButton = {
    Code: "sms-login",
    Label: "短信登录",
    Type: "text",
    Size: "small",
  };
  EnumCompanyType = EnumCompanyType;
  web?: WebOptions = {};

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormRegister();
    this.form.Data.PhoneNumber = this.$route.query.phoneNumber as string;
    //表单-校验
    this.form.Rules = DtoFormRegisterRules;
    this.form.Rules.CompanyNameZh = [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (!value) return callback(new Error("信息不能为空"));
          else if (value.length > 256)
            return callback(new Error("文本长度不能超过 256 个字符"));
          else {
            CompanyAppService.CheckCompanyName({
              CompanyType: this.form.Data.Type,
              Name: this.form.Data.CompanyNameZh,
            }).then((r) => {
              if (r.data.Data) {
                return callback(new Error("信息重复，请重新填写"));
              }
              return callback();
            });
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameEnAbbr = [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (!value) return callback(new Error("信息不能为空"));
          else if (value.length > 64)
            return callback(new Error("文本长度不能超过 64 个字符"));
          else {
            CompanyAppService.CheckCompanyName({
              CompanyType: this.form.Data.Type,
              ServiceProviderCompanyId: this.form.Data.ServiceProviderCompanyId,
              CompanyId: this.form.Data.Id,
              NameEnAbbr: this.form.Data.NameEnAbbr,
            }).then((r) => {
              if (r.data.Data) {
                return callback(new Error("信息重复，请重新填写"));
              }
              return callback();
            });
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormRegisterMiezzFormItems;

    CompanyTypeAppService.GetSelect().then((r) => {
      SysAppService.CanRegServiceProvider().then((rr) => {
        this.companyTypes = r.data.Data ?? [];
        if (!rr.data.Data) {
          this.companyTypes = (r.data.Data ?? []).filter(
            (it) => it.Data.Code != "ServiceProvider"
          );
        }
        this.companyTypes = this.companyTypes
          .filter(
            (it) =>
              it.Data.Code == "Customer_ForeignTradeEnterprise" ||
              it.Data.Code == "Customer_ForeignTradeIndividual" ||
              it.Data.Code == "ServiceProvider"
          )
          .sort((a, b) => {
            return (
              (a.Data.Code == "Customer_ForeignTradeEnterprise"
                ? 1
                : a.Data.Code == "Customer_ForeignTradeIndividual"
                ? 2
                : 3) -
              (b.Data.Code == "Customer_ForeignTradeEnterprise"
                ? 1
                : b.Data.Code == "Customer_ForeignTradeIndividual"
                ? 2
                : 3)
            );
          });
        this.form.Data.Type = EnumCompanyType.Customer;
        this.form.Data.JoinCompanyTypes = [
          { CompanyTypeId: this.companyTypes?.[0].Value },
        ];
        this.companyType = this.form.Data.JoinCompanyTypes[0].CompanyTypeId;
      });
    });

    SysAppService.GetWebInfo().then((r) => {
      this.web = r.data.Data;
    });
  }

  mounted(): void {
    if (this.form.Data.PhoneNumber)
      MiezzForm.validateField(this.$refs, "ruleForm", "PhoneNumber");
  }

  handleCompanyTypeChange(): void {
    this.$nextTick(() => {
      this.form.Data.Type = this.companyTypes?.firstOrDefault(
        (it) => it.Value == this.companyType
      )?.Data.Type;
      this.height =
        this.form.Data.Type == EnumCompanyType.ServiceProvider
          ? "530px"
          : this.companyTypes?.firstOrDefault(
              (it) => it.Value == this.companyType
            )?.Data.Code == "Customer_ForeignTradeIndividual"
          ? "429px"
          : "480px";
      this.form.Data.JoinCompanyTypes = [{ CompanyTypeId: this.companyType }];
    });
  }

  querySearchNameZh(queryString: string, cb: any): void {
    if (queryString) {
      CompanyAppService.GetSelect({
        Keywords: queryString,
        QuerySkyEye: true,
      }).then((r) => {
        cb(r.data.Data);
      });
    }
  }

  handleNameZhSelect(item: DtoSelectOptionCompany): void {
    if (item.SkyEyeId) {
      CompanyAppService.QueryCompanyBySkyEyeById(item.SkyEyeId).then((r) => {
        if (r.data.Data) {
          this.form.Data.CompanyCode = r.data.Data.CertCode;
          this.form.Data.UserName = r.data.Data.Contacts;
        }
      });
    }
  }

  handleCountdown(): void {
    if (this.countdown > 0) {
      setTimeout(() => {
        this.countdown--;
        this.handleCountdown();
      }, 1000);
    }
  }

  handleSendSMS(): void {
    this.countdown = 60;
    this.handleCountdown();
    const dto = new DtoFormSmsSendVerifyCode();
    dto.Code = "Reg";
    dto.PhoneNumber = this.form.Data.PhoneNumber;
    SmsAppService.SendVerifyCodeAsync(dto)
      .then((r) => {
        if (r.data.Extras) {
          const param = JSON.parse(r.data.Extras);
          if (param && param.code) {
            this.form.Data.VerifyCode = param.code;
          }
        }
      })
      .catch(() => {
        this.countdown = 0;
      });
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "register":
        MiezzForm.submit(this.$refs, "ruleForm", (v: boolean) => {
          if (v) {
            if (
              this.companyTypes?.firstOrDefault(
                (it) => it.Value == this.companyType
              )?.Data.Code == "Customer_ForeignTradeIndividual"
            ) {
              this.form.Data.CompanyNameZh = this.form.Data.UserName;
            }
            AuthAppService.Register(this.form.Data)
              .then(async (r) => {
                this.login(r.data.Data);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "password-login":
        this.$router.push("/login/password");
        break;
      case "sms-login":
        this.$router.push("/login/sms");
        break;
      default:
        break;
    }
  }

  async login(logier?: Logier): Promise<void> {
    if (logier) {
      CurrentLogier.SetLogier(logier);
      CurrentLogier.SetBreadcrumbs([]);
      await CurrentLogier.SetMenus();
      this.$router.push("/");
    }
  }
}
