import { RouteRecordRaw } from "vue-router";
import Bill from "./Bill/Index.vue";
import Pool from "./Bill/Form.vue";
import Order from "./Bill/Form.vue";
import Share from "./Bill/Share.vue";
import Client from "./Bill/Client.vue";
import Finance from "./Finance/BillPageList.vue";
import BatchEdit from "./Finance/Edit.vue";
import FinanceStatIndex from "./Stat/Index.vue";
import FinanceStatOverview from "./Stat/Overview/Index.vue";
import FinanceStatSaler from "./Stat/Saler/Index.vue";
import FinanceStatCustomer from "./Stat/Custom/Index.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/finance/bill',
        name: 'FinanceBillManagement',
        component: Finance,
        meta: {
            title: "账单总表 - 财务管理"
        }
    },
    {
        path: '/finance/bill/batch/edit/:id',
        name: 'FinanceBillBatchEditManagement',
        component: BatchEdit,
        meta: {
            title: "批量编辑 - 账单总表 - 财务管理"
        }
    },
    {
        path: '/pool/bill/client/:id',
        name: 'ClientPoolBillManagement',
        component: Client,
        meta: {
            title: "账单 - 委托书"
        }
    },
    {
        path: '/pool/bill/:id',
        name: 'PoolBillManagement',
        component: Bill,
        meta: {
            title: "账单 - 委托书"
        }
    },
    {
        path: '/pool/bill/collection/:id',
        name: 'PoolBillCollectionManagement',
        component: Pool,
        meta: {
            title: "编辑应收 - 账单 - 委托书"
        }
    },
    {
        path: '/pool/bill/payment/:id',
        name: 'PoolBillPaymentManagement',
        component: Pool,
        meta: {
            title: "编辑应付 - 账单 - 委托书"
        }
    },
    {
        path: '/pool/bill/saler/:id',
        name: 'PoolBillSalerManagement',
        component: Pool,
        meta: {
            title: "编辑结算价 - 账单 - 委托书"
        }
    },

    {
        path: '/order/bill/:id',
        name: 'OrderBillManagement',
        component: Bill,
        meta: {
            title: "账单 - 订单"
        }
    },
    {
        path: '/order/bill/collection/:id',
        name: 'OrderBillCollectionManagement',
        component: Order,
        meta: {
            title: "编辑应收 - 账单 - 订单"
        }
    },
    {
        path: '/order/bill/payment/:id',
        name: 'OrderBillPaymentManagement',
        component: Order,
        meta: {
            title: "编辑应付 - 账单 - 订单"
        }
    },
    {
        path: '/order/bill/saler/:id',
        name: 'OrderBillSalerManagement',
        component: Order,
        meta: {
            title: "编辑结算价 - 账单 - 订单"
        }
    },
    {
        path: '/order/bill/share/:id',
        name: 'OrderBillShareManagement',
        component: Share,
        meta: {
            title: "分摊应付账单 - 账单 - 订单"
        }
    },
    {
        path: '/finance/stat/index',
        name: 'FinanceStatIndex',
        component: FinanceStatIndex,
        meta: {
            title: "财务分析 - 财务管理"
        }
    },

    {
        path: '/finance/stat/overview',
        name: 'FinanceStatOverview',
        component: FinanceStatOverview,
        meta: {
            title: "财务分析 - 财务管理"
        }
    },

    {
        path: '/finance/stat/saler',
        name: 'FinanceStatSaler',
        component: FinanceStatSaler,
        meta: {
            title: "财务分析 - 财务管理"
        }
    },


    {
        path: '/finance/stat/customer',
        name: 'FinanceStatCustomer',
        component: FinanceStatCustomer,
        meta: {
            title: "财务分析 - 财务管理"
        }
    },
]

export default routes;