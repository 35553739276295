
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import DtoListItemServerLog from "@/models.machine/DtoListItemServerLog";
import JsonViewer from "vue-json-viewer";
import MiezzModal from "@/models/MiezzModal";
import CodeMirror from "codemirror";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/mode/sql/sql";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/solarized.css";
import moment from "moment";

@Options({
  components: {
    JsonViewer,
  },
})
export default class ServerLogDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() detail?: DtoListItemServerLog;
  modalJson = new MiezzModal();
  log?: DtoListItemServerLog;

  created(): void {
    this.modelValue.Width = "80%";

    this.modalJson.Title = "查看原文";
    this.modalJson.Width = "60%";
  }

  handleSeeJson(log?: DtoListItemServerLog): void {
    this.log = log;
    this.modalJson.Show = true;
    this.$nextTick(() => {
      const cm = CodeMirror.fromTextArea(this.$refs["codemirror"] as any, {
        lineNumbers: true,
        matchBrackets: true,
        mode: "text/x-mssql",
        theme: "seti",
      });
      cm.setSize("100%", "auto");
    });
  }

  formatTime(date?: Date, format?: string): string {
    return moment(date).format(format);
  }
}
