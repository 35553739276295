import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'Name')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "英文名称",
            prop: "Name",
            rules: _ctx.form.Rules.Name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入英文名称",
                pattern: '[a-zA-Z()]',
                onHandleBlur: _ctx.onBlurName
              }, null, 8, ["modelValue", "onUpdate:modelValue", "pattern", "onHandleBlur"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}