import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import DtoFormOrderDepartureWharf_OrderDepartureWharfPlan from "./DtoFormOrderDepartureWharf_OrderDepartureWharfPlan";
import UiGroup from "./UiGroup";

/**
 * 装货港-仓库/堆场/码头表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderDepartureWharf extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumOrderServiceType.DeparturePort;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 
     */
    PoolId?: string;
    /**
     * 
     */
    Type?: EnumOrderServiceType;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 合计毛重
     */
    SumGrossWeight?: number;
    /**
     * 合计体积
     */
    SumVolume?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 计划货量
     */
    Plans?: DtoFormOrderDepartureWharf_OrderDepartureWharfPlan[];
    /**
     * 委托书编号
     */
    PoolCode?: string;
}

/**
 * 装货港-仓库/堆场/码头表单-表单校验
 */
export const DtoFormOrderDepartureWharfRules = {
    OrderPoolId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 装货港-仓库/堆场/码头表单-设置项
 */
export const DtoFormOrderDepartureWharfMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "OrderPoolId",
    Label: "委托书主键",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PoolId",
    Label: "",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Type",
    Label: "",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumOrderServiceTypeOption,
  },
  {
    Prop: "SumQuantity",
    Label: "合计件数",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SumGrossWeight",
    Label: "合计毛重",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SumVolume",
    Label: "合计体积",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ChargeUnit",
    Label: "计价单位",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Plans",
    Label: "计划货量",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PoolCode",
    Label: "委托书编号",
    Type: EnumMiezzFormItemType.InputText,
  },
]
