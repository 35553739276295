import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04813289"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "log" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelValue.Buttons?.any((it) => it.Code == 'submit' && !it.Hide))
      ? (_openBlock(), _createBlock(_component_miezz_form, {
          key: 0,
          ref: "ruleForm",
          modelValue: _ctx.form,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'Checks')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox_group, {
                      modelValue: _ctx.form.Data.Checks,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Checks) = $event)),
                      onChange: _ctx.handleCheck
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checks, (item, i) => {
                          return (_openBlock(), _createBlock(_component_el_checkbox, {
                            key: i,
                            label: item.Value,
                            disabled: item.Data,
                            style: {"display":"block"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.Label), 1)
                            ]),
                            _: 2
                          }, 1032, ["label", "disabled"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onChange"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true),
            (item.Prop == 'SyncTables')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_checkbox_group, {
                      modelValue: _ctx.form.Data.SyncTables,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.SyncTables) = $event))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (item, i) => {
                          return (_openBlock(), _createBlock(_component_el_checkbox, {
                            key: i,
                            label: item.Value,
                            style: {"display":"block"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.Label), 1)
                            ]),
                            _: 2
                          }, 1032, ["label"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : (_openBlock(), _createBlock(_component_el_tabs, {
          key: 1,
          modelValue: _ctx.active,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.active) = $event)),
          type: "card",
          "tab-position": "top"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.execs, (exec) => {
              return (_openBlock(), _createBlock(_component_el_tab_pane, {
                key: exec.Id,
                label: exec.Name,
                name: exec.Id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_steps, {
                    class: "setps",
                    active: exec.ActiveStep
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exec.Steps, (item, i) => {
                        return (_openBlock(), _createBlock(_component_el_step, {
                          key: i,
                          title: item.Title,
                          description: item.Description,
                          icon: item.Icon,
                          status: item.Status
                        }, null, 8, ["title", "description", "icon", "status"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["active"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_row, { gutter: 20 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exec.DefaultAlertLogs, (item, i) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "log-item",
                                key: i
                              }, [
                                _createVNode(_component_el_alert, {
                                  title: item.Message,
                                  type: item.Type,
                                  effect: item.Effect,
                                  closable: false
                                }, null, 8, ["title", "type", "effect"])
                              ]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exec.CommandAlertLogs, (item, i) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "log-item",
                                key: i
                              }, [
                                _createVNode(_component_el_alert, {
                                  title: item.Message,
                                  type: item.Type,
                                  effect: item.Effect,
                                  closable: false
                                }, null, 8, ["title", "type", "effect"])
                              ]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 2
              }, 1032, ["label", "name"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]))
  ]))
}