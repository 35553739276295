
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import OrderAppService from "@/apis.machine/OrderAppService";
import DtoDetailOrderSummaryTime from "@/models.machine/DtoDetailOrderSummaryTime";
import moment from "moment";
export default class OrderSummaryTime extends Vue {
  @Prop() id?: string;

  detail?: DtoDetailOrderSummaryTime = {};

  created(): void {
    this.handleGet();
  }

  @Watch("id")
  handleGet(): void {
    if (this.id) {
      OrderAppService.GetSummaryTimeById(this.id).then((r) => {
        this.detail = r.data.Data;
      });
    }
  }

  /**格式化时间 */
  formatTime(v?: Date): string {
    return v ? moment(v).format("YYYY/MM/DD HH:mm") : "暂无数据";
  }
}
