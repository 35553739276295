import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";

/**
 * 选择器选项
 * @description 自动生成的代码,请勿修改
 */
export default class UiSelectOption<TValue> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 标签
     */
    Label?: string;
    /**
     * 值
     */
    Value?: TValue;
    /**
     * 数据
     */
    Data?: any;
    /**
     * 关联主键
     */
    ParentId?: string;
    /**
     * 关联主键
     */
    FullName?: string;
}

/**
 * 选择器选项-表单校验
 */
export const UiSelectOptionRules = {
    Label: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * 选择器选项-设置项
 */
export const UiSelectOptionMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Label",
    Label: "标签",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Value",
    Label: "值",
    Type: EnumMiezzFormItemType.InputText,
  },
]
