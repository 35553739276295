import DtoDetailOrderProcess_Tab from "./DtoDetailOrderProcess_Tab";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderProcess_OrderPoolWharf extends DtoDetailOrderProcess_Tab {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型：装货港/目的港
     */
    Type?: EnumOrderServiceType;
    /**
     * 全部入库时间
     */
    InStockTime?: Date;
    /**
     * 全部出库时间
     */
    OutStockTime?: Date;
}

/**
 * -表单校验
 */
export const DtoDetailOrderProcess_OrderPoolWharfRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderProcess_OrderPoolWharfMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "InStockTime",
    Label: "全部入库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "OutStockTime",
    Label: "全部出库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
