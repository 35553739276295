import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemParamCountryArea_ParamCountry from "./DtoListItemParamCountryArea_ParamCountry";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 地区分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamCountryArea extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountry?: DtoListItemParamCountryArea_ParamCountry;
    /**
     * 地区级别
     */
    AreaLevel?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 邮编
     */
    PostalCode?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 所属人
     */
    AffiliatedPerson?: string;
    /**
     * 详细地址
     */
    DetailedAddress?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 地区分页列表-项-表单校验
 */
export const DtoListItemParamCountryAreaRules = {
}

/**
 * 地区分页列表-项-设置项
 */
export const DtoListItemParamCountryAreaMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "AreaLevel",
    Label: "地区级别",
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "Abridge",
    Label: "缩写",
  },
  {
    Prop: "PostalCode",
    Label: "邮编",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
  {
    Prop: "AffiliatedPerson",
    Label: "所属人",
  },
  {
    Prop: "DetailedAddress",
    Label: "详细地址",
  },
]
