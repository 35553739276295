
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import PriceAppService from "@/apis.machine/PriceAppService";
import DtoFormPriceFormula, {
  DtoFormPriceFormulaMiezzFormItems,
  DtoFormPriceFormulaRules,
} from "@/models.machine/DtoFormPriceFormula";
import DtoFormPriceFormulaClacItem from "@/models.machine/DtoFormPriceFormulaClacItem";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoListItemPriceFormulaCalcResult from "@/models.machine/DtoListItemPriceFormulaCalcResult";
import DtoListItemPriceFormulaCalcResult_Owner from "@/models.machine/DtoListItemPriceFormulaCalcResult_Owner";
import DtoRequestPriceImportFromCost, {
  DtoRequestPriceImportFromCostMiezzFormItems,
  DtoRequestPriceImportFromCostRules,
} from "@/models.machine/DtoRequestPriceImportFromCost";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumCompare } from "@/models.machine/EnumCompare";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import { EnumRelation } from "@/models.machine/EnumRelation";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class PriceFormula extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() tableId?: string;
  @Prop() amountType?: EnumPriceAmountType;
  @Prop() companyId?: string;
  @Prop() userId?: string;
  @Prop() rows?: DtoListItemPrice[] = [];
  @Prop() formula?: string;
  @Prop() importFromCost?: boolean;
  @Prop() priceName?: string;
  @Prop() search?: boolean;
  @Prop() alreadySearch?: boolean;

  form = new MiezzForm<any, string>();
  label = "";
  pattern = "";
  result?: DtoListItemPriceFormulaCalcResult[] = [];
  companies?: DtoSelectOptionCompany[] = [];
  EnumPriceAmountType = EnumPriceAmountType;

  created(): void {
    this.modelValue.Width = "50%";
    this.modelValue.Buttons = [];
    this.modelValue.Buttons.push({
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    });
    if (this.search) {
      this.modelValue.ButtonWidth = "100px";
      this.modelValue.Buttons.push({
        Code: "search",
        Label: "搜索同源信息",
        Type: "primary",
        Size: "small",
      });
    }
    this.modelValue.HandleClick = this.handleClick;

    if (this.importFromCost) {
      this.modelValue.Title = "导入";
      this.form = new MiezzForm<DtoRequestPriceImportFromCost, string>();
      this.form.Data = new DtoRequestPriceImportFromCost();
      this.form.Data.TableId = this.tableId;
      this.form.Rules = DtoRequestPriceImportFromCostRules;
      this.form.Items = DtoRequestPriceImportFromCostMiezzFormItems;

      CompanyAppService.GetSelect({
        CompanyType: EnumCompanyType.Supplier,
        AmountType: EnumPriceAmountType.Cost,
        PriceTableId: this.tableId,
      }).then((r) => {
        this.companies = (r.data.Data ?? []).filter((it) => it.Mark);
      });
    } else {
      this.modelValue.Title = "公式";
      this.form = new MiezzForm<DtoFormPriceFormula, string>();
      this.form.Data = new DtoFormPriceFormula();
      this.form.Data.Formula = this.formula;
      this.form.Rules = DtoFormPriceFormulaRules;
      this.form.Items = DtoFormPriceFormulaMiezzFormItems.copy();
      this.form.Items.splice(1, 0, {
        Prop: "Owners",
        Label: "ID归属",
        Customize: true,
      });
    }
    this.handleGetResult();

    this.label =
      this.amountType == EnumPriceAmountType.Benchmark
        ? "基准价 ="
        : this.amountType == EnumPriceAmountType.Settlement
        ? "结算价 ="
        : this.amountType == EnumPriceAmountType.Guide
        ? "指导价 ="
        : this.amountType == EnumPriceAmountType.Agreement
        ? "协议价 ="
        : "";
    this.pattern = this.importFromCost
      ? "[\\0-\\9\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\u91c7\u8d2d\u4ef7]"
      : this.amountType == EnumPriceAmountType.Benchmark
      ? "[\\0-\\9\\a-\\z\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\\{\\}\u6210\u672c\u5bf9\u8c61]"
      : this.amountType == EnumPriceAmountType.Settlement
      ? "[\\0-\\9\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\u57fa\u51c6\u4ef7]"
      : this.amountType == EnumPriceAmountType.Guide
      ? "[\\0-\\9\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\u57fa\u51c6\u4ef7]"
      : this.amountType == EnumPriceAmountType.Agreement
      ? "[\\0-\\9\\.\\-\\（\\）\\(\\)\\+\\-\\×\\*\\÷\u57fa\u51c6\u4ef7\u7ed3\u7b97\u4ef7\u6307\u5bfc\u4ef7]"
      : "";
  }

  handleKeyupResult(e: any): void {
    if (e.keyCode == 13) {
      // 取消光标
      e.target.blur();
    }
  }

  handleGetResult(callback?: () => void): void {
    if (this.form.Data?.Formula) {
      const item = new DtoFormPriceFormulaClacItem();
      item.AmountType = EnumPriceAmountType.Benchmark;
      item.Formula = this.form.Data.Formula;
      if (this.importFromCost) {
        item.CostAmount = 1;
      } else if (this.amountType == EnumPriceAmountType.Settlement) {
        item.BenchmarkAmount = 1;
      } else if (this.amountType == EnumPriceAmountType.Guide) {
        item.BenchmarkAmount = 1;
      } else if (this.amountType == EnumPriceAmountType.Agreement) {
        item.BenchmarkAmount = 1;
        item.GuideAmount = 1;
        item.SettlementAmount = 1;
      }
      PriceAppService.CalcFormulaResult({ Items: [item] }).then((r) => {
        this.result = r.data.Data;
        if (callback) callback();
      });
    } else {
      this.result = [];
      if (callback) callback();
    }
  }

  getNumberText(n?: number): string | undefined {
    if (n != undefined) {
      const str = n.toRound(2);
      if (str) {
        return parseFloat(str).toString();
      }
    }
    return undefined;
  }

  handleLinkCost(owner: DtoListItemPriceFormulaCalcResult_Owner): void {
    DynamicSearchAppService.Post({
      Code: `price_${owner.TableId}`,
      Brackets: [
        {
          Relation: EnumRelation.And,
          Items: [
            {
              Relation: EnumRelation.And,
              Where: {
                Prop: "DynamicSearch_Id",
                Compare: EnumCompare.Equal,
                Value: owner.Id,
              },
            },
          ],
        },
      ],
    }).then((r) => {
      setTimeout(() => {
        window.open(
          `#/price/cost?DynamicSearchId=${r.data.Data}&OpenSearch=true&TableId=${owner.TableId}&CompanyId=${owner.CompanyId}`,
          "_blank"
        );
      }, 500);
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "search":
        this.handleSearch(this.formula ?? "", complete);
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            if (this.result) {
              this.doSubmit(complete);
            } else {
              this.handleGetResult(() => {
                this.doSubmit(complete);
              });
            }
          } else {
            complete();
          }
        });
        break;
      default:
        break;
    }
  }

  @Emit()
  handleSearch(formula: string, complete: () => void): void {
    //
  }

  doSubmit(complete: () => void): void {
    let api;
    let message: any;
    if (this.importFromCost) {
      message = ElMessage.warning({
        message: "正在导入",
        duration: 999999999,
      });
      api = PriceAppService.ImportFromCost(this.form.Data);
    } else {
      const dto = this.form.Data;
      dto.Ids = this.rows?.map((it) => it.Id as string);
      dto.CompanyId = this.companyId;
      dto.UserId = this.userId;
      dto.AmountType = this.amountType;
      api = PriceAppService.SetFormula(dto);
    }
    api
      .then(() => {
        if (this.alreadySearch) {
          this.handleSearch(this.form.Data.Formula, complete);
        } else {
          this.handleSubmit();
        }

        if (this.importFromCost) {
          message.close();
        }
        complete();
      })
      .catch(complete);
  }

  /**提交表单回调 */
  @Emit()
  handleSubmit(): void {
    this.modelValue.Show = false;
  }
}
