import UiSelectOption from "./UiSelectOption";

/**
 * 公司计费类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyChargeType {
    /**
     * 按重量计费
     */
    Weight = 10,
    /**
     * 按体积计费
     */
    Volume = 20,
}

/**
 * 公司计费类型-选项
 */
export const EnumCompanyChargeTypeOption: UiSelectOption<number>[] = [
    { Label: "按重量计费", Value: 10, Data: "Weight" },
    { Label: "按体积计费", Value: 20, Data: "Volume" },
];
