
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import PriceAppService from "@/apis.machine/PriceAppService";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import DtoPageListPrice from "@/models.machine/DtoPageListPrice";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import PriceForm from "./PriceForm.vue";
import PriceTableColumnAppService from "@/apis.machine/PriceTableColumnAppService";
import MiezzListItem from "@/models/MiezzListItem";
import {
  EnumPriceAmountType,
  EnumPriceAmountTypeOption,
} from "@/models.machine/EnumPriceAmountType";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import MiezzPageListComponent from "@/components/MiezzPageList.vue";
import PriceFormula from "./PriceFormula.vue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzAlertLog from "@/models/MiezzAlertLog";
import { EnumUiDynamicSearchItemType } from "@/models.machine/EnumUiDynamicSearchItemType";
import { EnumCompare } from "@/models.machine/EnumCompare";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import PriceBatchEdit from "./PriceBatchEdit.vue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import PriceTableCatalogue from "./SetUp/PriceTable/PriceTableCatalogue.vue";
import DtoRequestPriceSync from "@/models.machine/DtoRequestPriceSync";
import DtoRequestPriceSyncTarget from "@/models.machine/DtoRequestPriceSyncTarget";
import { EnumParamPriceFieldType } from "@/models.machine/EnumParamPriceFieldType";
import ParamPriceFieldAppService from "@/apis.machine/ParamPriceFieldAppService";
import PriceCalc from "./PriceCalc.vue";
import DtoRequestPriceFieldValue from "@/models.machine/DtoRequestPriceFieldValue";
import PriceVoyageChoose from "./PriceVoyageChoose.vue";
import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import JoinPriceServiceTableAppService from "@/apis.machine/JoinPriceServiceTableAppService";
import MiezzDynamicSearchComponent from "@/components/MiezzDynamicSearch.vue";
import UiHistory from "@/models.machine/UiHistory";
import DtoPriceServiceCompanyShow, {
  DtoPriceServiceCompanyShowMiezzListItems,
} from "@/models.machine/DtoPriceServiceCompanyShow";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import DtoFormPriceServiceCompanyShow from "@/models.machine/DtoFormPriceServiceCompanyShow";
import PriceSurchargeGroupAppService from "@/apis.machine/PriceSurchargeGroupAppService";
import { EnumRelation } from "@/models.machine/EnumRelation";
import DtoRequestPriceLock from "@/models.machine/DtoRequestPriceLock";
import MiezzForm from "@/models/MiezzForm";
import DtoRquestPriceSurchargeGroupRemark, {
  DtoRquestPriceSurchargeGroupRemarkMiezzFormItems,
  DtoRquestPriceSurchargeGroupRemarkRules,
} from "@/models.machine/DtoRquestPriceSurchargeGroupRemark";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoFormPriceExport from "@/models.machine/DtoFormPriceExport";
import PriceTableAppService from "@/apis.machine/PriceTableAppService";
import DtoFormPriceTableSupplierFile, {
  DtoFormPriceTableSupplierFileMiezzFormItems,
} from "@/models.machine/DtoFormPriceTableSupplierFile";

@Options({
  components: {
    PriceTableCatalogue,
    PriceForm,
    PriceFormula,
    PriceBatchEdit,
    PriceCalc,
    PriceVoyageChoose,
  },
})
export default class PricePageList extends Vue {
  @Prop() list!: MiezzPageList<
    DtoPageListSearchPrice,
    DtoListItemPrice,
    string
  >;
  @Prop() serviceId?: string;
  @Prop() amountType?: EnumPriceAmountType;
  @Prop() users?: UiSelectOption<string>[] = [];
  @Prop() companyId?: string;
  @Prop() companies?: DtoSelectOptionCompany[] = [];
  @Prop() userId?: string;
  @Prop() toggle?: boolean;
  @Prop() disabledCatalogues?: boolean;
  @Prop() surcharge?: boolean;
  @Prop() showSurchargeGroupId?: boolean;
  @Prop() search?: boolean;
  @Prop() fixTop?: string;

  listItems: MiezzListItem[] = [];
  searchs: any = {};
  brackets: DynamicSearchBracket[] = [];
  active?: string;
  /**当前Id */
  id?: string;
  tableId?: string = "";
  serviceTableId?: string = "";
  catalogues: UiSelectOption<string>[] = [];
  _toggle = true;
  toggleBtn: MiezzButton = {
    Code: "toggle",
    Icon: "ArrowLeftBold",
    Type: "default",
    Size: "small",
  };
  calcTotal?: string;
  calcIds: string[] = [];
  calcBtn: MiezzButton = {
    Code: "calc",
    Title: "计算器",
    MiezzIcon: "calc",
    Type: "primary",
    Size: "small",
  };
  company?: DtoSelectOptionCompany;
  modalSearch = new MiezzModal(MiezzModalType.Dialog);
  modalForm = new MiezzModal();
  modalHistory = new MiezzModal();
  histories: UiHistory[] = [];
  modalFormula = new MiezzModal();
  formulaRows: DtoListItemPrice = [];
  showFormulaSearch = false;
  alreadySearchFormula = false;
  importFromCost?: boolean;
  formula?: string;
  modalImportLog = new MiezzModal();
  importLogs: MiezzAlertLog[] = [];
  importRate = 0;
  EnumPriceAmountType = EnumPriceAmountType;
  modalBatchEdit = new MiezzModal(MiezzModalType.Dialog);
  modalCalc = new MiezzModal(MiezzModalType.Dialog);
  modalSync = new MiezzModal(MiezzModalType.Dialog);
  modalSyncForm: {
    CheckAll: boolean;
    CheckAllIndeterminate: boolean;
    Ids: string[];
  } = {
    CheckAll: false,
    CheckAllIndeterminate: false,
    Ids: [],
  };
  modalSyncFormRule = {
    Ids: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
  };
  loading?: boolean = false;

  modalServiceCompanyShow = new MiezzModal(MiezzModalType.Dialog);
  listServiceCompanyShow = new MiezzPageList<
    any,
    DtoPriceServiceCompanyShow,
    string
  >();
  btnServiceCompanyShow: MiezzButton = {
    Code: "service-company-show",
    Label: "服务显示设置",
    Type: "primary",
    Size: "small",
    PowerCode: "PriceManagement_Agreement_ServiceCompanyShow",
  };

  modalAddSurchargeGroupById = new MiezzModal(MiezzModalType.Dialog);
  modalAddSurchargeGroupByIdRules = {
    Id: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };
  modalAddSurchargeGroupByIdData: {
    Id?: string;
  } = {};
  surchargeGroups: UiSelectOption<string>[] = [];
  powers: string[] = [];
  logier?: Logier;

  btnAddSurchargeGroupRemark: MiezzButton = {
    Code: "add-surcharge-group-remark",
    Label: "+备注",
    Type: "text",
    Size: "small",
  };
  btnEditSurchargeGroupRemark: MiezzButton = {
    Code: "edit-surcharge-group-remark",
    Label: "编辑",
    Type: "text",
    Size: "small",
  };
  btnDeleteSurchargeGroupRemark: MiezzButton = {
    Code: "delete-surcharge-group-remark",
    Label: "删除",
    Type: "text",
    Size: "small",
  };
  modalSurchargeGroupRemark = new MiezzModal();
  formSurchargeGroupRemark = new MiezzForm<
    DtoRquestPriceSurchargeGroupRemark,
    string
  >();

  btnSupplierFileShow: MiezzButton = {
    Code: "supplier-file-show",
    Label: "相关附件",
    Type: "text",
    Size: "small",
  };
  modalSupplierFile = new MiezzModal(MiezzModalType.Dialog);
  formSupplierFile = new MiezzForm<DtoFormPriceTableSupplierFile, string>();
  supplierRelationId?: string;

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
    });
    this.list.Dto = new DtoPageListSearchPrice();
    if (this.serviceId) {
      this.list.EnabledRadioSearchAll = false;
      this.list.Dto.OpenSearch = "true";
      this.list.DynamicSearchFixProp = true;
      this.list.HideDynamicSearchHistoryTab = true;
    }
    this.list.HideDynamicSearchCompare = true;
    //列表-传参
    this.list.DynamicSearchHistoryOnlyByCode = true;
    this.list.SimpleDynamicSearch = true;
    this.list.DisabledCreatedHandleGet = true;
    if (this.disabledCatalogues) {
      this.tableId = this.$route.query.TableId as string;
    }
    this.list.EnabledRadioSearch = false;
    this.list.Dto.Search = this.search;
    this.list.Dto.TableId = this.tableId;
    this.list.Dto.ServiceTableId = this.serviceTableId;
    this.list.Dto.CompanyId = this.companyId;
    this.list.Dto.UserId = this.userId;
    //列表-动态搜索-项
    this.list.DynamicSearchItems = [];
    //列表-数据-初始
    this.list.Data = new DtoPageListPrice();
    //列表-列配置
    this.list.Items = [];
    if (this.amountType == EnumPriceAmountType.Cost) {
      this.list.CheckTopButtonShow = () => {
        return (
          this.list.Dto?.CompanyId != undefined &&
          this.list.Dto.TableId != undefined
        );
      };
    }
    this.list.CheckRowButtonShow = (btn, row) => {
      if (btn.Code == "detail-surcharge") {
        return row.SurchargeGroupId != undefined;
      } else if (btn.Code == "formula") {
        return this.amountType == EnumPriceAmountType.Benchmark
          ? row.Formula != undefined
          : this.amountType == EnumPriceAmountType.Settlement
          ? row.SettlementFormula != undefined
          : this.amountType == EnumPriceAmountType.Guide
          ? row.GuideFormula != undefined
          : this.amountType == EnumPriceAmountType.Agreement
          ? row.AgreementFormula != undefined
          : false;
      }
      return true;
    };
    this._toggle = this.toggle == undefined ? true : this.toggle;
    this.handleToggle();
    this.modalSync.Title = "同步";
    this.modalSync.Buttons = [
      {
        Code: "sync-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalSync.HandleClick = this.handleBottomButtonClick;
    this.modalImportLog.Title = "从模板导入";

    this.modalServiceCompanyShow.Title = "服务显示设置";
    this.modalServiceCompanyShow.Width = "700px";
    this.modalServiceCompanyShow.HandleClick = this.handleTopButtonClick;
    this.modalServiceCompanyShow.Buttons = [
      {
        Code: "save-service-company-show",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listServiceCompanyShow.Fix = false;
    this.listServiceCompanyShow.HideCard = true;
    this.listServiceCompanyShow.EnabledSelect = false;
    this.listServiceCompanyShow.EnabledDynamicSearch = false;
    this.listServiceCompanyShow.EnabledRadioSearch = false;
    this.listServiceCompanyShow.EnabledPagination = false;
    this.listServiceCompanyShow.Items =
      DtoPriceServiceCompanyShowMiezzListItems;
    this.listServiceCompanyShow.Data = {
      Items: [],
    };

    this.modalAddSurchargeGroupById.Title = "添加";
    this.modalAddSurchargeGroupById.Width = "500px";
    this.modalAddSurchargeGroupById.Height = "100px";
    this.modalAddSurchargeGroupById.HandleClick = this.handleTopButtonClick;
    this.modalAddSurchargeGroupById.Buttons = [
      {
        Code: "add-surcharge-group-by-id",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalSurchargeGroupRemark.Title = "设置备注";
    this.modalSurchargeGroupRemark.Width = "500px";
    this.modalSurchargeGroupRemark.HandleClick = this.handleTopButtonClick;
    this.modalSurchargeGroupRemark.Buttons = [
      {
        Code: "submit-surcharge-group-remark",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.formSurchargeGroupRemark.LabelWidth = "70px";
    this.formSurchargeGroupRemark.Items =
      DtoRquestPriceSurchargeGroupRemarkMiezzFormItems;
    this.formSurchargeGroupRemark.Rules =
      DtoRquestPriceSurchargeGroupRemarkRules;

    this.modalSupplierFile.Title = "相关附件";
    this.modalSupplierFile.Width = "700px";
    this.modalSupplierFile.Height = "500px";
    this.modalSupplierFile.HandleClick = this.handleTopButtonClick;
    this.modalSupplierFile.Buttons = [
      {
        Code: "submit-supplier-file",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.formSupplierFile.LabelWidth = "70px";
    this.formSupplierFile.Items = DtoFormPriceTableSupplierFileMiezzFormItems;
  }

  handleDynamicSearch(value?: string): void {
    if (this.list.Dto) {
      this.list.Dto.SameSourceSurchargeGroupId = undefined;
      this.list.Dto.DynamicSearchId = value;
      if (!this.list.Dto.DynamicSearchId) {
        this.list.Dto.OpenSearch = this.serviceId ? "true" : undefined;
      }
    }
    this.handleUrlGet();
  }

  handleGetHistories(): void {
    DynamicSearchAppService.Get({
      Code: this.list.PowerCode,
    }).then((r) => {
      this.list.DynamicSearchHistory = r.data.Data;
    });
  }

  handleBracketsChange(brackets?: DynamicSearchBracket[]): void {
    this.brackets = brackets ?? [];
  }

  handleBracketsBuild(brackets?: DynamicSearchBracket[]): void {
    this.brackets = brackets ?? [];
  }

  _listItems: MiezzListItem[] = [];
  handleGetListItems(callback: () => void): void {
    console.log("查询列表列信息");
    PriceTableColumnAppService.GetSelect({
      TableId: this.tableId,
      ServiceTableId: this.serviceTableId,
      AmountType: this.amountType,
      Show:
        this.serviceId || this.list.Dto?.SurchargeGroupId ? true : undefined,
      ShowBSGA:
        !this.serviceId &&
        !this.list.Dto?.SurchargeGroupId &&
        (this.amountType == EnumPriceAmountType.Agreement ||
          this.amountType == EnumPriceAmountType.Benchmark ||
          this.amountType == EnumPriceAmountType.Settlement ||
          this.amountType == EnumPriceAmountType.Guide)
          ? true
          : undefined,
      ShowCost: this.amountType == EnumPriceAmountType.Cost ? true : undefined,
    }).then((r) => {
      this.listItems =
        r.data.Data?.filter(
          (it) =>
            !this.search ||
            (it.Data.FieldCode != "Amount" &&
              it.Data.FieldCode != "GuideAmount" &&
              it.Data.FieldCode != "SettlementAmount")
        ).map((it) => {
          const item = new MiezzListItem();
          item.Prop = it.Value;
          item.FieldCode = it.Data.FieldCode;
          item.FieldType = it.Data.FieldType;
          if (item.FieldCode.contains("Amount")) {
            item.Prop = item.FieldCode + "Customize";
            item.Customize = true;
          }
          if (item.FieldCode == "Remark") {
            item.Customize = true;
            item.DisabledTooltip = true;
          }
          item.Label = it.Label;
          item.Width = it.Data.FieldWidth;
          return item;
        }) ?? [];
      this._listItems = JSON.copy(this.listItems);
      callback();
    });
  }

  handleGetItemData(): void {
    console.log("查询列表数据");
    this.loading = true;
    const dto = JSON.copy(this.list.Dto);
    dto.AmountType = this.amountType;
    if (
      this.serviceId &&
      !dto.SameSourceSurchargeGroupId &&
      (!dto.DynamicSearchId ||
        this.brackets?.all((it) => it.Items?.all((i) => !i.Where?.Value)))
    ) {
      this.list.Data = {
        TableName: this.catalogues.firstOrDefault(
          (it) => it.Value == this.serviceTableId
        )?.Label,
      };
      this.buildData();
      this.loading = false;
    } else {
      PriceAppService.Get(dto).then((r) => {
        this.list.Data = r.data.Data;
        this.buildData();
        this.loading = false;
      });
    }
  }

  buildData(): void {
    for (const row of this.list.Data?.Items ?? []) {
      for (const column of this.listItems) {
        if (column.FieldCode == "Id") {
          row[column.Prop as string] = row.Id;
        } else {
          const v = row.FieldValues?.firstOrDefault(
            (it) =>
              it.Column?.Id == column.Prop ||
              it.Column?.Field?.Code == column.Prop ||
              it.Column?.Field?.Code + "Customize" == column.Prop
          );
          row[column.Prop as string] = v?.Label;
        }
      }
    }

    let listItems = JSON.copy(this._listItems) as MiezzListItem[];
    // let listItems = this._listItems.filter((it) =>
    //   this.list.Data?.Items?.any((i) =>
    //     i.FieldValues?.any(
    //       (f) =>
    //         f.Column?.Id == it.Prop ||
    //         f.Column?.Field?.Code == it.Prop ||
    //         f.Column?.Field?.Code + "Customize" == it.Prop
    //     )
    //   )
    // );

    if (this.surcharge) {
      this.listItems = this.listItems?.filter(
        (it) => it.FieldType != EnumParamPriceFieldType.Description
      );
    }
    this.list.Items =
      this.list.Data?.Items && this.list.Data?.Items.length > 0
        ? this.listItems.filter(
            (it) =>
              it.FieldCode != "Id" ||
              this.amountType == EnumPriceAmountType.Cost
            // (it.FieldCode == "Id" &&
            //   this.amountType == EnumPriceAmountType.Cost) ||
            // this.list.Data?.Items?.any((i) =>
            //   i.FieldValues?.any(
            //     (f) =>
            //       f.Column?.Id == it.Prop ||
            //       f.Column?.Field?.Code == it.Prop ||
            //       f.Column?.Field?.Code + "Customize" == it.Prop
            //   )
            // )
          )
        : (JSON.copy(this.listItems) as MiezzListItem[]);
    if (
      this.amountType == EnumPriceAmountType.Agreement &&
      this.company?.Salesman == undefined
    ) {
      this.list.Items = this.list.Items.filter(
        (it) => it.Prop != "SettlementAmountCustomize"
      );
    }
    if (
      (this.amountType == EnumPriceAmountType.Settlement &&
        !this.powers.contains(
          "PriceManagement_Settlement_ShowBenchmarkAmount"
        )) ||
      (this.amountType == EnumPriceAmountType.Guide &&
        !this.powers.contains("PriceManagement_Guide_ShowBenchmarkAmount")) ||
      (this.amountType == EnumPriceAmountType.Agreement &&
        !this.powers.contains("PriceManagement_Agreement_ShowBenchmarkAmount"))
    ) {
      this.list.Items = this.list.Items.filter(
        (it) => it.Prop != "AmountCustomize"
      );
    }
    if (this.surcharge) {
      this.list.Items.push({
        Prop: "Description",
        Label: "备注",
        DisabledTooltip: true,
        Customize: true,
      });
      this.list.Items.push({
        Prop: "TableName",
        Label: "来源",
      });
    }
    if (
      (this.serviceId || this.surcharge) &&
      !this.powers.contains(
        "PriceManagement_SetUp_Service_Detail_CostComposition_ShowBenchmarkAmount"
      )
    ) {
      this.list.Items = this.list.Items.filter(
        (it) => it.Prop != "AmountCustomize"
      );
    }
    if (
      this.surcharge &&
      this.logier?.CompanyType != EnumCompanyType.ServiceProvider
    ) {
      this.list.Items = this.list.Items.filter((it) => it.Prop != "TableName");
    }
    if (this.list.Dto?.ServiceTableId) {
      const props = this.catalogues
        .firstOrDefault((it) => it.Value == this.list.Dto?.ServiceTableId)
        ?.Data?.FromSearchs?.map((it: any) => it.FromColumnId);
      listItems = listItems.filter((it) => props?.contains(it.Prop));
    }
    if (!this.serviceId) {
      this.list.DynamicSearchItems = listItems
        ?.filter((it) => !it.Prop?.contains("AmountCustomize"))
        ?.map((it) => {
          return {
            Prop: it.FieldCode == "Id" ? "DynamicSearch_Id" : it.Prop,
            FieldCode: it.FieldCode,
            Label: it.Label,
            Type: EnumUiDynamicSearchItemType.Customize,
            Compares: [EnumCompare.Equal],
          };
        });
      for (const item of this.list.DynamicSearchItems) {
        this.searchs[item.Prop as string] = [];
      }
      if (this.amountType != EnumPriceAmountType.Cost) {
        this.list.DynamicSearchItems.push({
          Prop: "DynamicSearch_Formula",
          Label: "公式",
          Type: EnumUiDynamicSearchItemType.InputText,
          Compares: [EnumCompare.Equal],
        });
      }
    }
    if (this.surcharge && this.list.Dto?.AddSurcharge?.toString() == "false") {
      this.list.EnabledRadioSearch = false;
      this.list.EnabledRadioSearchAll = false;
      this.list.EnabledDynamicSearch = false;
    } else if (this.serviceId) {
      this.list.EnabledDynamicSearch = true;
      this.list.EnabledRadioSearch = false;
      this.list.EnabledRadioSearchAll = false;
    } else {
      this.list.EnabledDynamicSearch =
        (this.list.DynamicSearchItems?.length ?? 0) > 0;
      this.list.EnabledRadioSearch =
        (this.list.DynamicSearchItems?.length ?? 0) > 0 ||
        this.list.Dto?.RadioSearch != undefined;
      this.list.EnabledRadioSearchAll = this.list.EnabledRadioSearch;
    }

    if (this.list.EnabledDynamicSearch) {
      if (this.list.Dto?.ServiceTableId) {
        this.list.DynamicSearchCode = `price_${this.list.Dto.ServiceTableId}`;
      } else if (this.list.Dto?.TableId) {
        this.list.DynamicSearchCode = `price_${this.list.Dto.TableId}`;
      } else {
        this.list.DynamicSearchCode = "price";
      }
    }

    if (this.list.Dto?.RadioSearch) {
      this.list.RadioSearchItems = [
        {
          Label: this.list.Dto.ServiceTableId ? "附加费" : "公式",
          Value: this.list.Dto.RadioSearch,
        },
      ];
    } else {
      this.list.RadioSearchItems = [];
    }
  }

  querySearch(prop: string, q: string): void {
    let dto = Object.copy(this.list.Dto) as DtoRequestPriceFieldValue;
    dto.AmountType = this.amountType;
    dto.Keywords = q;
    dto.Take = 10;
    dto.Prop = prop;
    PriceAppService.GetFieldValues(dto).then((r) => {
      this.searchs[prop] = r.data.Data ?? [];
    });
  }

  @Watch("userId")
  onUserIdChanged(nv?: string, ov?: string): void {
    console.log("用户Id发生变更");
    if (nv == ov || this.list.Dto?.UserId == this.userId || !this.userId)
      return;
    if (this.list.Dto) {
      this.list.Dto.UserId = this.userId;
    }
    this.handleUrlGet();
  }

  @Watch("companyId")
  onCompanyIdChanged(nv?: string, ov?: string): void {
    if (
      nv == ov ||
      this.list.Dto?.CompanyId == this.companyId ||
      !this.companyId
    )
      return;
    console.log("公司Id发生变更");
    if (this.list.Dto) {
      this.list.Dto.CompanyId = this.companyId;
    }
    this.handleUrlGet();
  }

  handleCompanyIdOrUserIdChanged(): void {
    if (this.list.Dto) {
      this.list.Dto.DynamicSearchId = undefined;
      this.list.Dto.OpenSearch = "false";
      this.handleUrlGet();
    }
  }

  handleUrlGet(): void {
    console.log("刷新Url查询列表时");
    if (this.list.Dto) {
      if (this.list.Dto.id) this.list.Dto.id = this.serviceId;
      this.list.Dto.TableId = this.tableId;
      this.list.Dto.ServiceTableId = this.serviceTableId;
      if (this.list.Dto.CompanyId || this.list.Dto.UserId) {
        if (this.list.Dto.CompanyId) {
          this.company = this.companies?.firstOrDefault(
            (it) => it.Id == this.list.Dto?.CompanyId
          );
          this.list.Dto.SalesmanId = this.company?.Salesman?.Id;
        }
        const page = JSON.parse(localStorage.getItem(this.$route.path) ?? "{}");
        page.companyId = this.list.Dto.CompanyId;
        page.userId = this.list.Dto.UserId;
        localStorage.setItem(this.$route.path, JSON.stringify(page));
      }
    }
    this.$nextTick(() => {
      (
        this.$refs["ref-miezz-page-list"] as MiezzPageListComponent
      )?.handleGet();
    });
  }

  /**查询列表 */
  handleGet(): void {
    console.log("查询列表");
    if (this.serviceTableId || this.tableId || this.disabledCatalogues) {
      if (
        (this.amountType == EnumPriceAmountType.Cost &&
          !this.list.Dto?.CompanyId) ||
        (this.amountType == EnumPriceAmountType.Settlement &&
          !this.list.Dto?.UserId) ||
        (this.amountType == EnumPriceAmountType.Agreement &&
          !this.list.Dto?.CompanyId)
      ) {
        console.log("缺少公司/用户信息");
        this.loading = false;
        if (this.list.Data) this.list.Data.Items = [];
        return;
      }
      if (
        this.list.Items?.length == 0 ||
        this.active != (this.serviceId ? this.serviceTableId : this.tableId)
      ) {
        this.active = this.serviceId ? this.serviceTableId : this.tableId;
        this.handleGetListItems(() => {
          this.handleGetItemData();
        });
      } else {
        this.handleGetItemData();
      }
    } else {
      this.loading = false;
    }
    if (this.tableId) this.handleRefreshCompaniesOrUsers(this.tableId);
  }

  @Emit()
  handleRefreshCompaniesOrUsers(tableId?: string, refresh?: boolean): void {
    console.log("刷新公司/用户");

    this.supplierRelationId = this.companies?.firstOrDefault(
      (it) => it.Id == this.list.Dto?.CompanyId
    )?.PriceTableRelationId;
    if (this.supplierRelationId) {
      PriceTableAppService.GetSupplierFilesById(this.supplierRelationId).then(
        (r) => {
          if (r.data.Data) this.formSupplierFile.Data = r.data.Data;
        }
      );
    }
  }

  handleVoyageChoose(): void {
    const form = (
      (this.$refs["ref-miezz-page-list"] as MiezzPageListComponent).$refs[
        "miezz-dynamic-search"
      ] as MiezzDynamicSearchComponent
    ).$refs["form-dynamic-search"] as any;
    const items =
      this.list.DynamicSearchItems?.filter((it) => it.FieldCode == "Voyage") ??
      [];
    for (const item of items) {
      form.validateField(item.Prop);
    }
  }

  handleInitCatalogues(
    options: UiSelectOption<string>[],
    active?: string
  ): void {
    if (this.serviceId) {
      this.handleGetCatalogues(options, active);
    } else {
      this.loading = true;
      this.catalogues = options;
      if (this.surcharge && this.$route.query.AddSurcharge == "false")
        this.tableId = undefined;
      else if (this.list.Dto) {
        this.tableId = active;
      }
      if (this.amountType == EnumPriceAmountType.Cost) {
        this.handleRefreshCompaniesOrUsers(active, true);
      } else {
        this.handleUrlGet();
      }
    }
  }

  handleGetCatalogues(
    options: UiSelectOption<string>[],
    active?: string
  ): void {
    console.log("选择左侧目录时-----------------------------------------");
    this.loading = true;
    this.catalogues = options;
    if (this.serviceId) {
      if (active) {
        if (this.list.Dto?.RadioSearch) {
          this.list.Dto.RadioSearch = undefined;
        }
        this.list.DynamicSearchItems = this.catalogues
          .firstOrDefault((it) => it.Value == active)
          ?.Data?.FromSearchs?.map((it: any) => {
            return {
              Prop: it.FromColumnId,
              FieldCode: it.FromColumn?.Field?.Code,
              Label: it.FromColumnLabel,
              Type: EnumUiDynamicSearchItemType.Customize,
              Compares: [EnumCompare.Equal],
            };
          });

        DynamicSearchAppService.GetLastByCode(`price_${active}`).then((rr) => {
          const brackets: DynamicSearchBracket[] = [
            {
              Items: [],
            },
          ];

          brackets[0].Items = this.list.DynamicSearchItems?.map((it) => {
            return {
              Relation: EnumRelation.And,
              Where: {
                Prop: it.Prop,
                Compare: EnumCompare.Equal,
                Value: undefined,
              },
            };
          });

          for (const item of brackets[0].Items ?? []) {
            const _item = (rr.data.Data?.Brackets ?? [])
              .firstOrDefault()
              ?.Items?.firstOrDefault(
                (it) => it.Where?.Prop == item.Where?.Prop
              );
            if (item.Where && _item?.Where?.Value) {
              item.Where.Value = _item.Where.Value;
            }
          }

          // this.list.DynamicSearchRules = {};
          // for (const item of this.list.DynamicSearchItems ?? []) {
          //   this.list.DynamicSearchRules[item.Prop ?? ""] = [
          //     {
          //       required: true,
          //       validator: (rule: any, value: any, callback: any) => {
          //         value = this.brackets[0].Items?.firstOrDefault(
          //           (it) => it.Where?.Prop == item.Prop
          //         )?.Where?.Value;
          //         if (!value) {
          //           return callback(new Error("信息不能为空"));
          //         }
          //         return callback();
          //       },
          //       trigger: "blur",
          //     },
          //     {
          //       required: true,
          //       validator: (rule: any, value: any, callback: any) => {
          //         value = this.brackets[0].Items?.firstOrDefault(
          //           (it) => it.Where?.Prop == item.Prop
          //         )?.Where?.Value;
          //         if (!value) {
          //           return callback(new Error("信息不能为空"));
          //         }
          //         return callback();
          //       },
          //       trigger: "change",
          //     },
          //   ];
          // }
          
          for (const item of brackets[0].Items ?? []) {
            const _item = this.brackets
              .firstOrDefault()
              ?.Items?.firstOrDefault(
                (it) => it.Where?.Prop == item.Where?.Prop
              );
            if (item.Where && _item?.Where?.Value) {
              item.Where.Value = _item.Where.Value;
            }
          }

          if (
            this.list.Dto &&
            (brackets.firstOrDefault()?.Items?.length ?? 0) == 0
          ) {
            this.list.Dto.DynamicSearchId = undefined;
            this.serviceTableId = active;
            this.handleUrlGet();
          } else if (this.serviceTableId && this.serviceTableId != active) {
            const dto = {
              FromServiceTableId: this.serviceTableId,
              ToServiceTableId: active,
            };
            JoinPriceServiceTableAppService.GetSearchRelationsByFromIdByToId(
              dto.FromServiceTableId,
              dto.ToServiceTableId
            ).then((r) => {
              const relations = r.data.Data ?? [];
              for (const item of this.brackets?.firstOrDefault()?.Items ?? []) {
                if (item.Where && item.Where.Prop) {
                  const relation = relations.firstOrDefault((it) => {
                    return dto.FromServiceTableId == it.FromServiceTableId
                      ? it.FromColumnId == item.Where?.Prop
                      : it.ToSearch?.FromColumnId == item.Where?.Prop;
                  });
                  if (relation) {
                    item.Where.Prop =
                      dto.FromServiceTableId == relation.FromServiceTableId
                        ? relation.ToSearch?.FromColumnId
                        : relation.FromColumnId;
                  } else {
                    item.Where.Prop = undefined;
                    item.Where.Value = undefined;
                  }
                }
              }
              for (const item of brackets.firstOrDefault()?.Items ?? []) {
                const _item = this.brackets
                  .firstOrDefault()
                  ?.Items?.firstOrDefault(
                    (it) => it.Where?.Prop == item.Where?.Prop
                  );
                if (item.Where && _item?.Where?.Value) {
                  item.Where.Value = _item.Where.Value;
                }
              }
              console.log("切换目录搜索", brackets);
              (
                (this.$refs["ref-miezz-page-list"] as MiezzPageListComponent)
                  .$refs["miezz-dynamic-search"] as MiezzDynamicSearchComponent
              ).saveBrackets(brackets, `price_${active}`, () => {
                this.serviceTableId = active;
                this.handleUrlGet();
              });
            });
          } else {
            (
              (this.$refs["ref-miezz-page-list"] as MiezzPageListComponent)
                .$refs["miezz-dynamic-search"] as MiezzDynamicSearchComponent
            ).saveBrackets(brackets, `price_${active}`, () => {
              this.serviceTableId = active;
              this.handleUrlGet();
            });
          }
        });
      } else {
        this.serviceTableId = active;
        this.handleUrlGet();
      }
    } else {
      if (this.surcharge && this.$route.query.AddSurcharge == "false")
        this.tableId = undefined;
      else if (this.list.Dto) {
        this.list.Dto.DynamicSearchId = undefined;
        this.list.Dto.OpenSearch = "false";
        this.tableId = active;
      }
      if (this.amountType == EnumPriceAmountType.Cost) {
        this.handleRefreshCompaniesOrUsers(active, true);
      } else {
        this.handleUrlGet();
      }
    }
  }

  handleToggle(): void {
    this.toggleBtn.Icon = this._toggle ? "ArrowLeftBold" : "ArrowRightBold";
    // this.toggleBtn.Title = this._toggle ? "收缩" : "展开";
  }

  handleSyncCheckAll(): void {
    this.modalSyncForm.Ids = this.modalSyncForm.CheckAll
      ? this.amountType == EnumPriceAmountType.Settlement
        ? this.users
            ?.filter((it) => it.Value != this.list.Dto?.UserId)
            .map((it) => it.Value as string) ?? []
        : this.amountType == EnumPriceAmountType.Agreement
        ? this.companies
            ?.filter((it) => it.Id != this.list.Dto?.CompanyId)
            .map((it) => it.Id as string) ?? []
        : []
      : [];
    this.handleSyncCheck();
  }

  handleSyncCheck(): void {
    let total =
      ((this.amountType == EnumPriceAmountType.Settlement
        ? this.users?.length
        : this.amountType == EnumPriceAmountType.Agreement
        ? this.companies?.length
        : 0) ?? 0) - 1;
    total = total < 0 ? 0 : total;
    const len = this.modalSyncForm.Ids.length;
    this.modalSyncForm.CheckAll = len == total;
    this.modalSyncForm.CheckAllIndeterminate = len > 0 && len < total;
  }

  saveServiceCompanyShow(complete: () => void): void {
    var dto = new DtoFormPriceServiceCompanyShow();
    var items = this.listServiceCompanyShow.Data?.Items;
    dto.CompanyId = this.list.Dto?.CompanyId;
    dto.Ids = items?.map((it) => it.Id as string);
    dto.ShowIds = items?.filter((it) => it.Show)?.map((it) => it.Id as string);
    PriceServiceAppService.PostCompanyShow(dto)
      .then(() => {
        this.modalServiceCompanyShow.Show = false;
        complete();
      })
      .catch(complete);
  }

  handleDeleteAll(complete: () => void): void {
    const message = ElMessage.warning({
      message: "正在删除",
      duration: 999999999,
    });
    PriceAppService.DeleteByTableId(
      this.list.Dto?.TableId,
      this.list.Dto?.CompanyId
    )
      .then(() => {
        this.handleDelete();
        message.close();
        complete();
      })
      .catch(() => {
        message.close();
        complete();
      });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "delete-all":
        this.handleDeleteAll(complete);
        break;
      case "supplier-file-show":
        this.modalSupplierFile.Show = true;
        complete();
        break;
      case "submit-supplier-file":
        PriceTableAppService.PostSupplierFile(this.formSupplierFile.Data).then(
          () => {
            this.modalSupplierFile.Show = false;
            complete();
          }
        );
        break;
      case "export":
        this.handleExport([], true, complete);
        break;
      case "delete-surcharge-group-remark":
        this.formSurchargeGroupRemark.Data = {
          Id: this.list.Data?.SurchargeGroupId,
        };
        PriceSurchargeGroupAppService.PostRemark(
          this.formSurchargeGroupRemark.Data
        )
          .then(() => {
            if (this.list.Data) {
              this.list.Data.SurchargeGroupRemark =
                this.formSurchargeGroupRemark.Data.Remark;
              this.modalSurchargeGroupRemark.Show = false;
            }
            complete();
          })
          .catch(complete);
        break;
      case "add-surcharge-group-remark":
      case "edit-surcharge-group-remark":
        this.formSurchargeGroupRemark.Data = {
          Id: this.list.Data?.SurchargeGroupId,
          Remark: this.list.Data?.SurchargeGroupRemark,
        };
        this.modalSurchargeGroupRemark.Show = true;
        complete();
        break;
      case "submit-surcharge-group-remark":
        MiezzForm.submit(this.$refs, "form-surcharge-group-remark", (v) => {
          if (v) {
            PriceSurchargeGroupAppService.PostRemark(
              this.formSurchargeGroupRemark.Data
            )
              .then(() => {
                if (this.list.Data) {
                  this.list.Data.SurchargeGroupRemark =
                    this.formSurchargeGroupRemark.Data.Remark;
                  this.modalSurchargeGroupRemark.Show = false;
                }
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "service-company-show":
        PriceServiceAppService.GetCompanyShowByCompanyId(
          this.list.Dto?.CompanyId
        )
          .then((r) => {
            this.listServiceCompanyShow.Data = {
              Items: r.data.Data ?? [],
            };
            this.modalServiceCompanyShow.Show = true;
            complete();
          })
          .catch(complete);
        break;
      case "save-service-company-show":
        this.saveServiceCompanyShow(complete);
        break;
      case "search":
        this.modalSearch.Show = true;
        complete();
        break;
      case "calc":
        if (this.calcIds.length > 0) {
          this.modalCalc.Show = true;
          complete();
        } else {
          ElMessage.warning("您未添加任何信息");
          complete();
        }
        break;
      case "toggle":
        this._toggle = !this._toggle;
        this.handleToggle();
        complete();
        break;
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增价格";
        this.id = undefined;
        complete();
        break;
      case "download":
        var dto: any = {};
        dto.TableId = this.tableId;
        dto.TableName = "价格表";
        dto.AmountType = this.amountType;
        dto.CompanyId = this.list.Dto?.CompanyId;
        dto.UserId = this.list.Dto?.UserId;
        dto.SalesmanId = this.list.Dto?.SalesmanId;
        dto.Ids = [];
        PriceAppService.Export(dto)
          .then((r) => {
            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
            complete();
          })
          .catch(complete);
        break;
      case "import":
        if (btn.Command == "import-from-cost") {
          this.importFromCost = true;
          this.formulaRows = [];
          this.modalFormula.Show = true;
          this.showFormulaSearch = false;
        }
        complete();
        break;
      case "add-surcharge-group-by-id":
        (this.$refs["ref-form-add-surcharge-group-by-id"] as any).validate(
          (v: boolean) => {
            if (v) {
              PriceSurchargeGroupAppService.Add({
                Id: this.modalAddSurchargeGroupByIdData.Id,
                ServiceTableId: this.serviceTableId,
                PriceIds: this.list.SelectIds,
              })
                .then(() => {
                  this.handleGet();
                  this.modalAddSurchargeGroupById.Show = false;
                  ElMessage.success("添加成功");
                  complete();
                })
                .catch(complete);
            } else {
              setTimeout(() => {
                const error = document.getElementsByClassName("is-error");
                error[0]?.querySelector("input")?.focus();
              }, 1);
              complete();
            }
          }
        );
        break;
      default:
        break;
    }
  }

  @Emit()
  handleTopButtonUpload(model: MiezzButton, file: any): void {
    this.modalImportLog.Show = true;
    this.importLogs = [];
    this.importRate = 0;
    CurrentLogier.StopHubConnection(() => {
      CurrentLogier.StartHubConnection(() => {
        CurrentLogier.HubConnection?.off("AlertLog");
        CurrentLogier.HubConnection?.off("AlertProgress");
        CurrentLogier.HubConnection?.on(
          "AlertLog",
          (
            message: string,
            type: "success" | "warning" | "info" | "error",
            effect: "dark" | "light"
          ) => {
            this.importLogs.unshift({
              Message: message,
              Type: type,
              Effect: effect,
            });
          }
        );
        CurrentLogier.HubConnection?.on(
          "AlertProgress",
          (current: number, total: number) => {
            this.importRate = total == 0 ? 100 : (current / total) * 100;
          }
        );
        const formData = new FormData();
        formData.append("file", file);
        PriceAppService.ImportByTableIdByAmountType(
          this.tableId,
          this.amountType,
          this.list.Dto?.CompanyId,
          this.list.Dto?.UserId,
          this.list.Dto?.SalesmanId,
          formData
        ).then(() => {
          this.handleGet();
        });
      });
    });
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPrice,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "detail-surcharge":
        if (row.SurchargeGroupId) {
          if (btn.Command == "search") {
            if (this.list.Dto) {
              this.list.Dto.SameSourceSurchargeGroupId = row.SurchargeGroupId;
              for (const bracket of this.brackets) {
                for (const item of bracket.Items ?? []) {
                  if (item.Where) {
                    item.Where.Value = undefined;
                  }
                }
              }
            }
            (
              (this.$refs["ref-miezz-page-list"] as MiezzPageListComponent)
                .$refs["miezz-dynamic-search"] as MiezzDynamicSearchComponent
            ).saveBrackets(this.brackets, "", () => {
              this.handleUrlGet();
              complete();
            });
          } else {
            this.$router.push({
              path: this.search
                ? `/price/search/detail/${this.serviceId}/surcharge`
                : `/price/setup/service/detail/${this.serviceId}/cost-composition/surcharge/added`,
              query: {
                SearchServiceTableId: this.list.Dto?.ServiceTableId,
                SurchargeGroupId: row.SurchargeGroupId,
                AddSurcharge: "false",
                PriceId: row.Id,
                backUrl: this.$route.fullPath,
              },
            });
            complete();
          }
        }
        break;
      case "lock":
      case "un-lock":
        PriceAppService.Lock({
          AmountType: this.amountType,
          CompanyId: this.list.Dto?.CompanyId,
          UserId: this.list.Dto?.UserId,
          Lock: btn.Code == "lock",
          Targets: [
            {
              Id: row.Id,
              Amount:
                this.amountType == EnumPriceAmountType.Settlement
                  ? row.SettlementAmount
                  : this.amountType == EnumPriceAmountType.Guide
                  ? row.GuideAmount
                  : this.amountType == EnumPriceAmountType.Agreement
                  ? row.AgreementAmount
                  : undefined,
            },
          ],
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "history":
        this.histories = [];
        this.modalHistory.Show = true;
        this.modalHistory.Title = `历史记录`;
        this.modalHistory.Width = "50%";
        PriceAppService.GetHistories({
          Id: this.id,
          AmountType: this.amountType,
          CompanyId: this.list.Dto?.CompanyId,
          UserId: this.userId,
        }).then((r) => {
          this.histories = r.data.Data ?? [];
        });
        complete();
        break;
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑价格`;
        complete();
        break;
      case "formula":
        this.formulaRows = [row];
        this.modalFormula.Show = true;
        this.showFormulaSearch = true;
        if (
          this.brackets
            ?.firstOrDefault()
            ?.Items?.any(
              (it) =>
                it.Where?.Prop == "DynamicSearch_Formula" && it.Where.Value
            )
        ) {
          this.alreadySearchFormula = true;
        }
        this.formula =
          this.amountType == EnumPriceAmountType.Benchmark ||
          this.amountType == EnumPriceAmountType.Cost
            ? row?.Formula
            : this.amountType == EnumPriceAmountType.Settlement
            ? row?.SettlementFormula
            : this.amountType == EnumPriceAmountType.Guide
            ? row?.GuideFormula
            : this.amountType == EnumPriceAmountType.Agreement
            ? row?.AgreementFormula
            : "";
        complete();
        break;
      case "delete":
        this.loading = true;
        PriceAppService.Delete([this.id as string])
          .then(() => {
            this.handleDelete();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  handleSearchFormula(formula: string, complete: () => void): void {
    DynamicSearchAppService.Post({
      Code: this.list.DynamicSearchCode,
      Brackets: [
        {
          Relation: EnumRelation.And,
          Items: [
            {
              Relation: EnumRelation.And,
              Where: {
                Prop: "DynamicSearch_Formula",
                Compare: EnumCompare.Equal,
                Value: formula,
              },
            },
          ],
        },
      ],
    })
      .then((r) => {
        if (this.list.Dto) {
          this.list.Dto.DynamicSearchId = r.data.Data;
          this.list.Dto.OpenSearch = "true";
        }
        this.handleUrlGet();
        this.modalFormula.Show = false;
        complete();
      })
      .catch(complete);
  }

  handleDeleteSurcharge(syncUpdate: boolean, complete: () => void): void {
    PriceSurchargeGroupAppService.DeleteItems({
      Id: this.list.Dto?.SurchargeGroupId,
      ServiceTableId: this.list.Dto?.SearchServiceTableId,
      PriceIds: this.list.SelectIds,
      SyncUpdate: syncUpdate,
      PriceId: syncUpdate ? undefined : this.list.Dto?.PriceId,
    })
      .then((r) => {
        if (r.data.Data) {
          if (!syncUpdate && this.list.Dto) {
            this.list.Dto.SurchargeGroupId = r.data.Data;
            this.handleUrlGet();
            ElMessage.success("已删除");
            complete();
          } else {
            this.handleGet();
            ElMessage.success("已删除");
            complete();
          }
        } else {
          CurrentLogier.Back();
          complete();
        }
      })
      .catch(complete);
  }

  handleAddSurcharge(syncUpdate: boolean, complete: () => void): void {
    PriceSurchargeGroupAppService.AddItems({
      Id: this.list.Dto?.SurchargeGroupId,
      ServiceTableId: this.list.Dto?.SearchServiceTableId,
      PriceIds: this.list.SelectIds,
      SyncUpdate: syncUpdate,
      PriceId: syncUpdate ? undefined : this.list.Dto?.PriceId,
    })
      .then((r) => {
        if (!syncUpdate && this.list.Dto) {
          this.list.Dto.SurchargeGroupId = r.data.Data;
          this.handleUrlGet();
          ElMessage.success("已添加");
        } else {
          this.handleGet();
          ElMessage.success("已添加");
        }
        complete();
      })
      .catch(complete);
  }

  handleLock(dto: DtoRequestPriceLock, complete: () => void): void {
    PriceAppService.Lock(dto)
      .then(() => {
        this.handleGet();
        complete();
      })
      .catch(complete);
  }

  handleExport(ids: string[], all: boolean, complete: () => void): void {
    const dto: DtoFormPriceExport = {};
    dto.TableId = this.tableId;
    dto.TableName = "价格表";
    dto.AmountType = this.amountType;
    dto.CompanyId = this.list.Dto?.CompanyId;
    dto.UserId = this.list.Dto?.UserId;
    dto.SalesmanId = this.list.Dto?.SalesmanId;
    dto.Ids = ids;
    dto.All = all;
    ElMessageBox.confirm(`价格信息包含公式，请选择导出方式`, "导出", {
      confirmButtonText: "导出公式",
      cancelButtonText: "导出数值",
      distinguishCancelAndClose: true,
      showClose: true,
      type: "warning",
    })
      .then(() => {
        dto.ShowAmount = false;
        this.handleSaveExport(dto, complete);
      })
      .catch((action: string) => {
        if (action == "cancel") {
          dto.ShowAmount = true;
          this.handleSaveExport(dto, complete);
        } else {
          complete();
        }
      });
  }

  handleSaveExport(dto: DtoFormPriceExport, complete: () => void): void {
    const message = ElMessage.warning({
      message: "正在下载",
      duration: 999999999,
    });
    PriceAppService.Export(dto)
      .then((r) => {
        window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
        message.close();
        complete();
      })
      .catch(complete);
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add-calc":
        this.loading = true;
        for (const id of this.list.SelectIds) {
          if (!this.calcIds.contains(id)) {
            this.calcIds.push(id);
          }
        }
        this.loading = false;
        ElMessage.success("添加完成，请点击右侧计算器查看");
        complete();
        break;
      case "lock":
      case "un-lock":
        var dtoLock = {
          AmountType: this.amountType,
          CompanyId: this.list.Dto?.CompanyId,
          UserId: this.list.Dto?.UserId,
          Lock: btn.Code == "lock",
          Targets: this.list.SelectRows.map((row) => {
            return {
              Id: row.Id,
              BenchmarkAmount: row.Amount,
              Amount:
                this.amountType == EnumPriceAmountType.Settlement
                  ? row.SettlementAmount
                  : this.amountType == EnumPriceAmountType.Guide
                  ? row.GuideAmount
                  : this.amountType == EnumPriceAmountType.Agreement
                  ? row.AgreementAmount
                  : undefined,
              Formula:
                this.amountType == EnumPriceAmountType.Benchmark
                  ? row.Formula
                  : this.amountType == EnumPriceAmountType.Settlement
                  ? row.SettlementFormula
                  : this.amountType == EnumPriceAmountType.Guide
                  ? row.GuideFormula
                  : this.amountType == EnumPriceAmountType.Agreement
                  ? row.AgreementFormula
                  : undefined,
            };
          }),
        };
        if (btn.Code == "lock" && dtoLock.Targets.any((it) => it.Formula)) {
          ElMessageBox.confirm(
            `锁定后，公式信息将被清除，是否确定继续当前的操作？`,
            "提示",
            {
              cancelButtonText: "取消",
              confirmButtonText: "确认",
              type: "warning",
            }
          )
            .then(() => {
              this.handleLock(dtoLock, complete);
            })
            .catch(complete);
        } else if (
          btn.Code == "un-lock" &&
          dtoLock.Targets.any(
            (it) =>
              it.Amount != undefined &&
              it.BenchmarkAmount != undefined &&
              it.Amount < it.BenchmarkAmount
          )
        ) {
          ElMessageBox.confirm(
            `存在${
              EnumPriceAmountTypeOption.firstOrDefault(
                (it) => it.Value == this.amountType
              )?.Label
            }＜基准价，解锁后系统将清除原有价格，是否继续？`,
            "提示",
            {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.handleLock(dtoLock, complete);
            })
            .catch(complete);
        } else {
          this.handleLock(dtoLock, complete);
        }
        break;
      case "delete-surcharge":
        if (this.list.Dto?.PriceId) {
          PriceSurchargeGroupAppService.CheckSingleItemById(
            this.list.Dto.SurchargeGroupId
          ).then((r) => {
            if (r.data.Data) {
              this.handleDeleteSurcharge(true, complete);
            } else {
              ElMessageBox.confirm(
                "该组附加费关联了多条费用信息，是否同步修改？",
                "提示",
                {
                  confirmButtonText: "是，同步修改”",
                  cancelButtonText: "否，仅修改当前费用",
                  distinguishCancelAndClose: true,
                  showClose: true,
                  type: "warning",
                }
              )
                .then(() => {
                  this.handleDeleteSurcharge(true, complete);
                })
                .catch((action: string) => {
                  if (action == "cancel") {
                    this.handleDeleteSurcharge(false, complete);
                  } else {
                    complete();
                  }
                });
            }
          });
        } else {
          this.handleDeleteSurcharge(true, complete);
        }
        break;
      case "add-surcharge":
        if (this.list.Dto?.PriceId) {
          PriceSurchargeGroupAppService.CheckSingleItemById(
            this.list.Dto.SurchargeGroupId
          ).then((r) => {
            if (r.data.Data) {
              this.handleAddSurcharge(true, complete);
            } else {
              ElMessageBox.confirm(
                "该组附加费关联了多条费用信息，是否同步修改？",
                "提示",
                {
                  confirmButtonText: "是，同步修改”",
                  cancelButtonText: "否，仅修改当前费用",
                  distinguishCancelAndClose: true,
                  showClose: true,
                  type: "warning",
                }
              )
                .then(() => {
                  this.handleAddSurcharge(true, complete);
                })
                .catch((action: string) => {
                  if (action == "cancel") {
                    this.handleAddSurcharge(false, complete);
                  } else {
                    complete();
                  }
                });
            }
          });
        } else {
          this.handleAddSurcharge(true, complete);
        }
        break;
      case "add-surcharge-group":
        if (this.list.SelectRows.any((it) => it.SurchargeGroupId)) {
          ElMessage.warning("所选信息（部分或全部）已添加附加费，请重新选择");
          complete();
          return;
        }
        if (btn.Command == "by-hand") {
          const surchargeGroupId =
            this.list.SelectRows.firstOrDefault()?.SurchargeGroupId;
          if (surchargeGroupId) {
            this.$router.push({
              path: `/price/setup/service/detail/${this.serviceId}/cost-composition/surcharge/not-added`,
              query: {
                SearchServiceTableId: this.list.Dto?.ServiceTableId,
                SurchargeGroupId: surchargeGroupId,
                AddSurcharge: "true",
                backUrl: this.$route.fullPath,
              },
            });
            complete();
          } else {
            PriceSurchargeGroupAppService.Add({
              ServiceTableId: this.serviceTableId,
              PriceIds: this.list.SelectIds,
            })
              .then((r) => {
                this.$router.push({
                  path: `/price/setup/service/detail/${this.serviceId}/cost-composition/surcharge/not-added`,
                  query: {
                    SearchServiceTableId: this.list.Dto?.ServiceTableId,
                    SurchargeGroupId: r.data.Data,
                    AddSurcharge: "true",
                    backUrl: this.$route.fullPath,
                  },
                });
                complete();
              })
              .catch(complete);
          }
        } else if (btn.Command == "by-id") {
          PriceSurchargeGroupAppService.GetSelectByServiceTableId(
            this.list.Dto?.ServiceTableId
          )
            .then((r) => {
              this.surchargeGroups = r.data.Data ?? [];
              this.modalAddSurchargeGroupByIdData.Id = undefined;
              this.modalAddSurchargeGroupById.Show = true;
              complete();
            })
            .catch(complete);
        }
        break;
      case "delete-surcharge-group":
        PriceSurchargeGroupAppService.Delete({
          ServiceTableId: this.serviceTableId,
          PriceIds: this.list.SelectIds,
        })
          .then(() => {
            this.handleGet();
            ElMessage.success("已清除");
            complete();
          })
          .catch(complete);
        break;
      case "sync":
        if (
          this.amountType == EnumPriceAmountType.Settlement
            ? (this.users?.length ?? 0) < 2
            : this.amountType == EnumPriceAmountType.Agreement
            ? (this.companies?.length ?? 0) < 2
            : false
        ) {
          ElMessage.warning("缺少同步对象");
          complete();
        } else {
          this.modalSync.Show = true;
        }
        complete();
        break;
      case "sync-submit":
        (this.$refs["sync-form"] as any).validate((v: boolean) => {
          if (v) {
            var syncDto = new DtoRequestPriceSync();
            syncDto.AmountType = this.amountType;
            for (const row of this.list.SelectRows) {
              for (const id of this.modalSyncForm.Ids) {
                var target = new DtoRequestPriceSyncTarget();
                target.Id = row.Id;
                if (this.amountType == EnumPriceAmountType.Settlement) {
                  target.Amount = row.SettlementAmount;
                  target.LockAmount = row.SettlementLockAmount;
                  target.Formula = row.SettlementFormula;
                  target.UserId = id;
                }
                if (this.amountType == EnumPriceAmountType.Agreement) {
                  target.Amount = row.AgreementAmount;
                  target.LockAmount = row.AgreementLockAmount;
                  target.Formula = row.AgreementFormula;
                  target.CompanyId = id;
                }
                syncDto.Targets?.push(target);
              }
            }
            PriceAppService.Sync(syncDto)
              .then(() => {
                this.handleGet();
                this.modalSync.Show = false;
                ElMessage.success("已同步");
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        break;
      case "amount-update":
        this.modalBatchEdit.Show = true;
        complete();
        break;
      //批量删除
      case "batch-delete":
        this.loading = true;
        ElMessageBox.confirm("是否确认将该信息删除？", "提醒", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            PriceAppService.Delete(this.list.SelectIds)
              .then(() => {
                this.handleGet();
                complete();
              })
              .catch(complete);
          })
          .catch(() => {
            complete();
          });
        break;
      case "export":
        this.handleExport(this.list.SelectIds, false, complete);
        break;
      case "add-formula":
        // if (
        //   this.list.SelectRows.any(
        //     (it) =>
        //       !it.FieldValues?.any(
        //         (f) => f.Column?.Field?.Code == "FirstChargingUnitId" && f.Label
        //       )
        //   )
        // ) {
        //   ElMessage.warning("所选信息未填第一计价单位，请重新选择");
        // } else
        if (
          this.list.SelectRows.any((a) =>
            this.list.SelectRows.filter((b) => b.Id != a.Id).any((b) => {
              return this.amountType == EnumPriceAmountType.Benchmark ||
                this.amountType == EnumPriceAmountType.Cost
                ? b.Formula != a.Formula
                : this.amountType == EnumPriceAmountType.Settlement
                ? b.SettlementFormula != a.SettlementFormula
                : this.amountType == EnumPriceAmountType.Guide
                ? b.GuideFormula != a.GuideFormula
                : this.amountType == EnumPriceAmountType.Agreement
                ? b.AgreementFormula != a.AgreementFormula
                : false;
            })
          )
        ) {
          ElMessage.error("所选信息包含多个不同的公式，请重新选择");
        } else {
          this.importFromCost = false;
          this.formulaRows = this.list.SelectRows;
          this.modalFormula.Show = true;
          this.showFormulaSearch = false;
          const first = this.list.SelectRows.firstOrDefault();
          this.formula =
            this.amountType == EnumPriceAmountType.Benchmark ||
            this.amountType == EnumPriceAmountType.Cost
              ? first?.Formula
              : this.amountType == EnumPriceAmountType.Settlement
              ? first?.SettlementFormula
              : this.amountType == EnumPriceAmountType.Guide
              ? first?.GuideFormula
              : this.amountType == EnumPriceAmountType.Agreement
              ? first?.AgreementFormula
              : "";
        }
        complete();
        break;
      case "delete-formula":
        PriceAppService.DeleteFormula({
          AmountType: this.amountType,
          Ids: this.list.SelectIds,
          CompanyId: this.list.Dto?.CompanyId,
          UserId: this.list.Dto?.UserId,
        })
          .then(() => {
            this.handleGet();
            ElMessage.success("公式删除成功");
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }
  handleClearSelect(): void {
    this.calcIds = [];
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.handleGet();
  }

  /**删除回调 */
  handleDelete(): void {
    this.handleGet();
  }
}
