
    import { ElMessageBox } from "element-plus";
    import BillAppService from "@/apis.machine/BillAppService";
    import { ElMessage } from "element-plus";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailCompanyCreditAndAccountPeriod, {
  DtoDetailCompanyCreditAndAccountPeriodMiezzDetailItems,
} from "@/models.machine/DtoDetailCompanyCreditAndAccountPeriod";
import DtoDetailCompanyLadingBillTemplate, {
  DtoDetailCompanyLadingBillTemplateMiezzDetailItems,
} from "@/models.machine/DtoDetailCompanyLadingBillTemplate";
import DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate, {
  DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplateMiezzListItems,
} from "@/models.machine/DtoDetailCompanyLadingBillTemplate_CompanyLadingBillTemplate";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CustomerCompanyCreditAndAccountingPeriodDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompanyCreditAndAccountPeriod, string>();
  id?: string;
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "text",
      Size: "small",
    },
    {
      Code: "edit",
      Label: "编辑",
      Icon: "edit",
      Type: "text",
      Size: "small",
      },
      {
          Code: "account",
          Label: "同步账单账期",
          Icon: "edit",
          Type: "text",
          Size: "small",
          Hide:true,
      },
  ];
  menuTab = new MiezzMenuTab();
  loading = true;

  created(): void {
    this.menuTab.Url = this.$route.path;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: this.menuTab.Url,
        Label: "授信与账期",
        Power: "CustomerManagement_Company_CreditAndAccountingPeriod",
      },
    ];
    this.id = this.$route.params.id as string;
    this.detail.Data = new DtoDetailCompanyCreditAndAccountPeriod();
    this.detail.Items = DtoDetailCompanyCreditAndAccountPeriodMiezzDetailItems;

    this.handleGet();
  }

  handleGet(): void {
    this.loading = true;
    CompanyAppService.GetCreditAndAccountPeriodById(this.id).then((r) => {
      if (r.data.Data) {
        this.detail.Data = r.data.Data;
        this.loading = false;
        //   if (this.list.Data) this.list.Data.Items = r.data.Data.LadingBillTemplates;
          if (this.detail && this.detail.Data) {
              var count = this.detail.Data.BillCount;
              if (count && count > 0 && this.detail.Data.IsCredit == true) {
                  this.buttons[2].Hide = false;
              }
          }
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.$router.push(
          `/customer/company/credit-and-accounting-period/edit/${this.id}`
        );
        complete();
            break;
        case "account":
            ElMessageBox.confirm("是否确定将未设置账期的账单按当前账期配置进行同步？期间可能需要花费几分钟时间，请耐心等待。", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.loading = true;
                    BillAppService.SetDefaultAccountPeriod(undefined, this.id)
                        .then(() => {
                            this.loading = false;
                            ElMessage.success("同步完成");
                            complete();
                        })
                        .catch(complete);
                })
                .catch(() => {
                    complete();
                });
            complete();
            break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
