import UiSelectOption from "./UiSelectOption";

/**
 * 货物运输类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumGoodsTransportType {
    /**
     * CY-CY/港到港
     */
    CYCY = 1,
    /**
     * CY-DOOR/港到门
     */
    CYDOOR = 2,
    /**
     * DOOR-CY/门到港
     */
    DOORCY = 3,
    /**
     * DOOR-DOOR/门到门
     */
    DOORDOOR = 4,
}

/**
 * 货物运输类型-选项
 */
export const EnumGoodsTransportTypeOption: UiSelectOption<number>[] = [
    { Label: "CY-CY/港到港", Value: 1, Data: "CYCY" },
    { Label: "CY-DOOR/港到门", Value: 2, Data: "CYDOOR" },
    { Label: "DOOR-CY/门到港", Value: 3, Data: "DOORCY" },
    { Label: "DOOR-DOOR/门到门", Value: 4, Data: "DOORDOOR" },
];
