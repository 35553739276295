import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailOrderTaskActive_Item from "./DtoDetailOrderTaskActive_Item";
import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";
import { EnumTransportContainerLoad, EnumTransportContainerLoadOption } from "./EnumTransportContainerLoad";
import { EnumOrderPoolStatus, EnumOrderPoolStatusOption } from "./EnumOrderPoolStatus";
import { EnumCustomsClearanceHeaderProvider, EnumCustomsClearanceHeaderProviderOption } from "./EnumCustomsClearanceHeaderProvider";
import DtoDetailOrderPoolSummary_Supplier from "./DtoDetailOrderPoolSummary_Supplier";
import DtoDetailOrderSummary_DefaultSupplier from "./DtoDetailOrderSummary_DefaultSupplier";
import DtoDetailOrderPoolSummary_Order from "./DtoDetailOrderPoolSummary_Order";
import DtoDetailOrderSummary_OrderPool_Supplier from "./DtoDetailOrderSummary_OrderPool_Supplier";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderChooseService extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.TaskActiveCount = 0;
        this.TaskActives = [];
        this.HasTask = false;
        this.DirectOrder = false;
        this.EAtd = "暂无数据/暂无数据";
        this.EAta = "暂无数据/暂无数据";
        this.Client = "";
        this.CreatedUserLabel = "/";
        this.Lock = false;
        this.DeparturePortBooking = false;
        this.DeparturePortTake = false;
        this.DeparturePortWarehouse = false;
        this.DeparturePortDeclareCustoms = false;
        this.DestinationPortClearCustoms = false;
        this.DestinationPortDelivery = false;
        this.DestinationPortWarehouse = false;
        this.Suppliers = [{"ServiceContent":"\u4F9B\u5E94\u5546\u540D\u79F0","DeparturePortContainer":"\u6682\u65E0\u6570\u636E","DeparturePortTrunk":"\u6682\u65E0\u6570\u636E","DeparturePortExpress":"\u6682\u65E0\u6570\u636E","DeparturePortWarehouse":"\u6682\u65E0\u6570\u636E","DeparturePortDeclareCustoms":"\u6682\u65E0\u6570\u636E","DestinationPortClearCustoms":"\u6682\u65E0\u6570\u636E","DestinationPortContainer":"\u6682\u65E0\u6570\u636E","DestinationPortTrunk":"\u6682\u65E0\u6570\u636E","DestinationPortExpress":"\u6682\u65E0\u6570\u636E","DestinationPortWarehouse":"\u6682\u65E0\u6570\u636E"}];
        this.DefaultSuppliers = [];
        this.ContainerTrailers = [];
        this.Expresses = [];
        this.Trunks = [];
        this.Customs = [];
        this.Wharfs = [];
    }

    [proName: string]: any;
    /**
     * 待办数量
     */
    TaskActiveCount?: number;
    /**
     * 有待办的服务
     */
    TaskActives?: DtoDetailOrderTaskActive_Item[];
    /**
     * 有本人的待办
     */
    HasTask?: boolean;
    /**
     * 直订订单
     */
    DirectOrder?: boolean;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 装货港
     */
    LoadingPortLabel?: string;
    /**
     * 船名
     */
    ShipName?: string;
    /**
     * 航次
     */
    VoyageNumber?: string;
    /**
     * 航班
     */
    Flight?: string;
    /**
     * 班次
     */
    RailwayNumber?: string;
    /**
     * ETD
     */
    EstimatedDepartureDate?: Date;
    /**
     * 装货港ATD
     */
    ActualDepartureDate?: Date;
    /**
     * ETD/ATD
     */
    EAtd?: string;
    /**
     * 目的港
     */
    DeliveryPortLabel?: string;
    /**
     * ETA
     */
    EstimatedArrivalDate?: Date;
    /**
     * 目的港ATA
     */
    ActualArrivalDate?: Date;
    /**
     * ETA/ATA
     */
    EAta?: string;
    /**
     * 最终目的地
     */
    DeliveryPlaceLabel?: string;
    /**
     * 承运人
     */
    CarrierLabel?: string;
    /**
     * 渠道
     */
    TransportChannelLabel?: string;
    /**
     * 委托公司
     */
    ClientCompanyId?: string;
    /**
     * 委托公司中文名称
     */
    ClientCompanyNameZh?: string;
    /**
     * 委托公司
     */
    ClientCompanyLabel?: string;
    /**
     * 执行人
     */
    ClientUserId?: string;
    /**
     * 委托人
     */
    ClientUserLabel?: string;
    /**
     * 委托人
     */
    Client?: string;
    /**
     * 发货人内容
     */
    ShipperContent?: string;
    /**
     * 收货人内容
     */
    ConsigneeContent?: string;
    /**
     * 客户/供应商名称
     */
    CustomerSupplierLabel?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 货量/入库计费货量
     */
    CargoQuantity?: string;
    /**
     * 服务商Id
     */
    ServiceProviderId?: string;
    /**
     * 客服
     */
    CustomerServiceLabel?: string;
    /**
     * 业务员Id
     */
    SalesmanId?: string;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 创建人
     */
    CreatedUserLabel?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 审核人
     */
    AuditUserLabel?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 价格服务Id
     */
    PriceServiceId?: string;
    /**
     * 价格服务标签
     */
    PriceServiceLabel?: string;
    /**
     * 价格服务快捷方式Id
     */
    PriceServiceShortcutId?: string;
    /**
     * 价格服务快捷方式标签
     */
    PriceServiceShortcutLabel?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 业务类型
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * 集装箱装载方式
     */
    TransportContainerLoad?: EnumTransportContainerLoad;
    /**
     * 锁定
     */
    Lock?: boolean;
    /**
     * 订单锁定
     */
    OrderLock?: boolean;
    /**
     * 状态
     */
    Status?: EnumOrderPoolStatus;
    /**
     * 装货港服务
     */
    DeparturePortBooking?: boolean;
    /**
     * 装货港服务-是否签发提单
     */
    DeparturePortLadingBill?: boolean;
    /**
     * 装货港服务-提货
     */
    DeparturePortTake?: boolean;
    /**
     * 装货港服务-提货方式-集装箱拖车
     */
    DeparturePortContainer?: boolean;
    /**
     * 装货港服务-提货方式-干线物流
     */
    DeparturePortTrunk?: boolean;
    /**
     * 装货港服务-提货方式-快递出现
     */
    DeparturePortExpress?: boolean;
    /**
     * 装货港服务-仓库
     */
    DeparturePortWarehouse?: boolean;
    /**
     * 装货港服务-报关
     */
    DeparturePortDeclareCustoms?: boolean;
    /**
     * 装货港服务-是否退税
     */
    DeparturePortDrawback?: boolean;
    /**
     * 目的港服务
     */
    DestinationPortClearCustoms?: boolean;
    /**
     * 目的港服务-清关抬头
     */
    DestinationPortCustomsClearanceHeaderProvider?: EnumCustomsClearanceHeaderProvider;
    /**
     * 目的港服务-是否出具税单
     */
    DestinationPortCustomsClearanceIssueTaxReceipt?: boolean;
    /**
     * 目的港服务-送货
     */
    DestinationPortDelivery?: boolean;
    /**
     * 目的港服务-送货方式-集装箱拖车
     */
    DestinationPortContainer?: boolean;
    /**
     * 目的港服务-送货方式-干线物流
     */
    DestinationPortTrunk?: boolean;
    /**
     * 目的港服务-送货方式-快递出现
     */
    DestinationPortExpress?: boolean;
    /**
     * 目的港服务-仓库
     */
    DestinationPortWarehouse?: boolean;
    /**
     * 服务内容
     */
    ServiceRemark?: string;
    /**
     * 供应商信息
     */
    Suppliers?: DtoDetailOrderPoolSummary_Supplier[];
    /**
     * 默认供应商
     */
    DefaultSuppliers?: DtoDetailOrderSummary_DefaultSupplier[];
    /**
     * 订单
     */
    Order?: DtoDetailOrderPoolSummary_Order;
    /**
     * 集装箱拖车
     */
    ContainerTrailers?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 快递
     */
    Expresses?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 干线物流
     */
    Trunks?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 报关
     */
    Customs?: DtoDetailOrderSummary_OrderPool_Supplier[];
    /**
     * 仓库
     */
    Wharfs?: DtoDetailOrderSummary_OrderPool_Supplier[];
}

/**
 * -表单校验
 */
export const DtoDetailOrderChooseServiceRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderChooseServiceMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "编号",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "LoadingPortLabel",
    Label: "装货港",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EAtd",
    Label: "ETD/ATD",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "目的港",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "EAta",
    Label: "ETA/ATA",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DeliveryPlaceLabel",
    Label: "最终目的地",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CarrierLabel",
    Label: "承运人",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportChannelLabel",
    Label: "渠道",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Client",
    Label: "委托人",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CustomerSupplierLabel",
    Label: "客户/供应商名称",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CargoQuantity",
    Label: "货量/入库计费货量",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CustomerServiceLabel",
    Label: "客服",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SalesmanLabel",
    Label: "业务员",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CreatedUserLabel",
    Label: "创建人",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "AuditUserLabel",
    Label: "审核人",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Span: 12,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "TransportDirection",
    Label: "业务类型",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "DeparturePortBooking",
    Label: "装货港服务",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "DestinationPortClearCustoms",
    Label: "目的港服务",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ServiceRemark",
    Label: "服务内容",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Suppliers",
    Label: "供应商信息",
    Span: 24,
    Customize: true,
  },
]
