import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackExceptionLogGetStackTraceCodePart {
    constructor() {
        //无参构造函数
        this.RowNumber = 0;
        this.BeginRowNumber = 0;
    }

    [proName: string]: any;
    /**
     * 文件
     */
    File?: string;
    /**
     * 行号
     */
    RowNumber?: number;
    /**
     * Hash
     */
    Hash?: string;
    /**
     * 作者
     */
    Author?: string;
    /**
     * 提交日期
     */
    CreatedTime?: Date;
    /**
     * 起始行号
     */
    BeginRowNumber?: number;
    /**
     * 代码片段
     */
    CodePart?: string;
}

/**
 * -表单校验
 */
export const DtoCallbackExceptionLogGetStackTraceCodePartRules = {
}

/**
 * -设置项
 */
export const DtoCallbackExceptionLogGetStackTraceCodePartMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "File",
    Label: "文件",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "RowNumber",
    Label: "行号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Hash",
    Label: "Hash",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Author",
    Label: "作者",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "提交日期",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CodePart",
    Label: "代码片段",
    Customize: true,
  },
]
