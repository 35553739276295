import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddress from "./DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddress";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolExpress_DeliveryAddress extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 交货地址Id
     */
    DeliveryAddressId?: string;
    /**
     * 交货地址
     */
    DeliveryAddress?: DtoPutOrderPoolExpress_DeliveryAddress_CompanyAddress;
    /**
     * 计划抵达时间
     */
    PlanDeliveryTime?: Date;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolExpress_DeliveryAddressRules = {
}

/**
 * -设置项
 */
export const DtoPutOrderPoolExpress_DeliveryAddressMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "DeliveryAddress",
    Label: "交货地址",
    Customize: true,
  },
]
