import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPlatformCompany_Company from "./DtoListItemPlatformCompany_Company";
import { EnumProductSiteEnvironment, EnumProductSiteEnvironmentOption } from "./EnumProductSiteEnvironment";
import DtoListItemPlatformCompany_ProjectVersion from "./DtoListItemPlatformCompany_ProjectVersion";

/**
 * 平台管理分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPlatformCompany extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Official = false;
    }

    [proName: string]: any;
    /**
     * 站点
     */
    Name?: string;
    /**
     * 平台
     */
    Company?: DtoListItemPlatformCompany_Company;
    /**
     * 环境
     */
    Environment?: EnumProductSiteEnvironment;
    /**
     * 官方
     */
    Official?: boolean;
    /**
     * 发布版本
     */
    ProjectVersion?: DtoListItemPlatformCompany_ProjectVersion;
    /**
     * 数据库-连接地址
     */
    DbConnectionString?: string;
}

/**
 * 平台管理分页列表-项-表单校验
 */
export const DtoListItemPlatformCompanyRules = {
}

/**
 * 平台管理分页列表-项-设置项
 */
export const DtoListItemPlatformCompanyMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "站点",
  },
  {
    Prop: "Company",
    Label: "平台",
  },
  {
    Prop: "Environment",
    Label: "环境",
    Pipe: EnumPipe.Enum,
    Options: EnumProductSiteEnvironmentOption,
  },
  {
    Prop: "Official",
    Label: "官方",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "ProjectVersion",
    Label: "发布版本",
  },
]
