
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCheckFormula {
    constructor() {
        //无参构造函数
        this.IsError = false;
    }

    [proName: string]: any;
    /**
     * 公式
     */
    Formula?: string;
    /**
     * 是否有错误
     */
    IsError?: boolean;
    /**
     * 错误提示语
     */
    Message?: string[];
}

/**
 * -表单校验
 */
export const DtoCheckFormulaRules = {
}
