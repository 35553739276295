import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-接口请求方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumUiApiMethod {
    /**
     * 获取
     */
    Get = 0,
    /**
     * 提交
     */
    Post = 1,
    /**
     * 修改
     */
    Put = 2,
    /**
     * 删除
     */
    Delete = 3,
}

/**
 * 枚举-接口请求方式-选项
 */
export const EnumUiApiMethodOption: UiSelectOption<number>[] = [
    { Label: "获取", Value: 0, Data: "Get" },
    { Label: "提交", Value: 1, Data: "Post" },
    { Label: "修改", Value: 2, Data: "Put" },
    { Label: "删除", Value: 3, Data: "Delete" },
];
