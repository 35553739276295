
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumPipe } from "@/models/EnumPipe";
import MiezzForm, {
  EnumMiezzFormItemType,
  MiezzFormItem,
} from "@/models/MiezzForm";
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Emit, Model, Prop } from "vue-property-decorator";
export default class MiezzFormItemComponent extends Vue {
  @Model() modelValue?: any;
  @Prop() defaultSpan?: number;
  @Prop() powerCode?: string;
    @Prop() items?: MiezzFormItem[];
    @Prop() isDetail?: boolean;

  predefineColors = [
    "#ff4500",
    "#ff8c00",
    "#ffd700",
    "#90ee90",
    "#00ced1",
    "#1e90ff",
    "#c71585",
  ];
  EnumMiezzFormItemType = EnumMiezzFormItemType;

  hideProps: string[] = [];

  async created(): Promise<void> {
    if (this.powerCode) {
      const menus = await CurrentLogier.GetMenus();
      this.hideProps = menus?.HideMenuFields ?? [];
    }
  }

  checkShow(item: MiezzFormItem, customize = false): boolean {
    customize = customize == (item.Customize ?? false);
    const show = !(item.Hide ?? false);
    const power =
      !this.powerCode ||
      this.hideProps.length == 0 ||
      !this.hideProps.contains(`${this.powerCode}|${item.Prop}`);
    const check = customize && show && power;
    return check;
  }

  handleFormat(item: MiezzFormItem): string | undefined {
    if (item.Prop) {
      if (item.HandleFormat) {
        const value = item.HandleFormat();
        if (value) return value;
      }
      const v = this.modelValue[item.Prop];
      if (v === null || v === undefined || v === "") return "暂无数据";
      if (item.Pipe == EnumPipe.Boolean)
        return v ? item.WhenTrue : item.WhenFalse;
      else if (item.Pipe == EnumPipe.Enum)
        return (
          item.Options?.firstOrDefault((it) => it.Value == v)?.Label ??
          "暂无数据"
        );
      else if (item.Pipe == EnumPipe.Date) {
        return moment(v).format("YYYY/MM/DD");
      } else if (item.Pipe == EnumPipe.Time) {
        return moment(v).format("YYYY/MM/DD HH:mm");
      } else if (item.Prop.endsWith("ActiveSecond")) {
        const second: number = v;
        if (second == 0) {
          return "暂无数据";
        } else if (second < 60) {
          return `${second}秒`;
        } else if (second < 60 * 60) {
          return `${(second / 60).toRound(0)}分钟`;
        } else {
          return `${(second / 60 / 60).toRound(0)}小时`;
        }
      }
      if (Array.isArray(v) && v.length == 0) return "暂无数据";
      return v;
    }
    return "暂无数据";
  }
}
