import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_IntoPortWharf extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 进港码头
     */
    IntoPortWharfLabel?: string;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_IntoPortWharfRules = {
    IntoPortWharfLabel: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_IntoPortWharfMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "IntoPortWharfLabel",
    Label: "进港码头",
    Customize: true,
  },
]
