import UiTreeSearch from "./UiTreeSearch";
import { EnumMenuScene, EnumMenuSceneOption } from "./EnumMenuScene";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoTreeSearchPower extends UiTreeSearch<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = EnumMenuScene.Platform;
        this.OnlyShowCheck = false;
        this.CheckIds = [];
    }

    [proName: string]: any;
    /**
     * 场景
     */
    Scene?: EnumMenuScene;
}

/**
 * -表单校验
 */
export const DtoTreeSearchPowerRules = {
    Scene: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}
