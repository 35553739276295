import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCustomsProductType, EnumCustomsProductTypeOption } from "./EnumCustomsProductType";
import DtoFormDocumentProduct_DocumentProductElement from "./DtoFormDocumentProduct_DocumentProductElement";
import DtoFormDocumentProduct_DocumentProductPackage from "./DtoFormDocumentProduct_DocumentProductPackage";
import UiGroup from "./UiGroup";

/**
 * 单证产品表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormDocumentProduct extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.ProductElements = [];
        this.ProductPackages = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 是否暂存
     */
    IsTemporary?: boolean;
    /**
     * 添加方式
     */
    Type?: EnumCustomsProductType;
    /**
     * 产品
     */
    ProductId?: string;
    /**
     * 产品名称(中)
     */
    ProductNameZh?: string;
    /**
     * 产品名称(英)
     */
    ProductNameEn?: string;
    /**
     * 申报包装数量
     */
    PackageQuantity?: number;
    /**
     * 包装种类（英）
     */
    PackageName?: string;
    /**
     * 产品规格/型号
     */
    SpecName?: string;
    /**
     * 成交数量
     */
    TransactionQuantity?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 申报币制
     */
    CurrencySystemId?: string;
    /**
     * 申报币制
     */
    CurrencySystemName?: string;
    /**
     * 申报单价
     */
    UnitPrice?: number;
    /**
     * 申报总价
     */
    TotalPrice?: number;
    /**
     * 净重
     */
    NetWeight?: number;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 体积
     */
    Volume?: number;
    /**
     * HS编码
     */
    HsCode?: string;
    /**
     * 退税率
     */
    TaxRate?: string;
    /**
     * 征税率(增值税率)
     */
    Tax?: string;
    /**
     * 第一法定单位
     */
    StatutoryFirstUnit?: string;
    /**
     * 第一法定单位值
     */
    StatutoryFirstUnitValue?: number;
    /**
     * 第二法定单位
     */
    StatutorySecondUnit?: string;
    /**
     * 第二法定单位值
     */
    StatutorySecondUnitValue?: number;
    /**
     * 征免方式
     */
    ExemptingModeId?: string;
    /**
     * 征免方式
     */
    ExemptingModeName?: string;
    /**
     * 最终目的国
     */
    FinanCountryId?: string;
    /**
     * 最终目的国
     */
    FinanCountryName?: string;
    /**
     * 原产国（地区）
     */
    OriginCountryId?: string;
    /**
     * 原产国（地区）
     */
    OriginCountryName?: string;
    /**
     * 境内货源地
     */
    DomesticGoodsPlaceId?: string;
    /**
     * 境内货源地
     */
    DomesticGoodsPlaceName?: string;
    /**
     * 详细产地
     */
    PlaceId?: string;
    /**
     * 详细产地
     */
    PlaceName?: string;
    /**
     * 备案序号
     */
    RecordOrder?: string;
    /**
     * 监管条件/监管代码
     */
    SupervisionConditionsCode?: string;
    /**
     * 申报要素
     */
    ProductElements?: DtoFormDocumentProduct_DocumentProductElement[];
    /**
     * 产品包装
     */
    ProductPackages?: DtoFormDocumentProduct_DocumentProductPackage[];
    /**
     * 组
     */
    Group?: string;
    /**
     * 目的港海关编码
     */
    CustomsCode?: string;
}

/**
 * 单证产品表单-表单校验
 */
export const DtoFormDocumentProductRules = {
    ProductNameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    ProductNameEn: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * 单证产品表单-设置项
 */
export const DtoFormDocumentProductMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ProductId",
    Label: "产品",
    Customize: true,
  },
  {
    Prop: "ProductNameZh",
    Label: "产品名称(中)",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "ProductNameEn",
    Label: "产品名称(英)",
    Customize: true,
  },
  {
    Prop: "PackageQuantity",
    Label: "申报包装数量",
    Customize: true,
  },
  {
    Prop: "PackageName",
    Label: "包装种类（英）",
    Customize: true,
  },
  {
    Prop: "SpecName",
    Label: "产品规格/型号",
    Customize: true,
  },
  {
    Prop: "TransactionQuantity",
    Label: "成交数量",
    Customize: true,
  },
  {
    Prop: "ChargeUnit",
    Label: "计价单位",
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "申报币制",
    Customize: true,
  },
  {
    Prop: "CurrencySystemName",
    Label: "申报币制",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "申报单价",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "申报总价",
    Customize: true,
  },
  {
    Prop: "NetWeight",
    Label: "净重",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积",
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "HS编码",
    Customize: true,
  },
  {
    Prop: "TaxRate",
    Label: "退税率",
    Customize: true,
  },
  {
    Prop: "Tax",
    Label: "征税率(增值税率)",
    Customize: true,
  },
  {
    Prop: "StatutoryFirstUnit",
    Label: "第一法定单位",
    Customize: true,
  },
  {
    Prop: "StatutoryFirstUnitValue",
    Label: "第一法定单位值",
    Customize: true,
  },
  {
    Prop: "StatutorySecondUnit",
    Label: "第二法定单位",
    Customize: true,
  },
  {
    Prop: "StatutorySecondUnitValue",
    Label: "第二法定单位值",
    Customize: true,
  },
  {
    Prop: "ExemptingModeId",
    Label: "征免方式",
    Customize: true,
  },
  {
    Prop: "ExemptingModeName",
    Label: "征免方式",
    Customize: true,
  },
  {
    Prop: "FinanCountryId",
    Label: "最终目的国",
    Customize: true,
  },
  {
    Prop: "FinanCountryName",
    Label: "最终目的国",
    Customize: true,
  },
  {
    Prop: "OriginCountryId",
    Label: "原产国（地区）",
    Customize: true,
  },
  {
    Prop: "OriginCountryName",
    Label: "原产国（地区）",
    Customize: true,
  },
  {
    Prop: "DomesticGoodsPlaceId",
    Label: "境内货源地",
    Customize: true,
  },
  {
    Prop: "DomesticGoodsPlaceName",
    Label: "境内货源地",
    Customize: true,
  },
  {
    Prop: "PlaceId",
    Label: "详细产地",
    Customize: true,
  },
  {
    Prop: "PlaceName",
    Label: "详细产地",
    Customize: true,
  },
  {
    Prop: "RecordOrder",
    Label: "备案序号",
    Customize: true,
  },
  {
    Prop: "SupervisionConditionsCode",
    Label: "监管条件/监管代码",
    Customize: true,
  },
  {
    Prop: "ProductElements",
    Label: "申报要素",
    Customize: true,
  },
  {
    Prop: "ProductPackages",
    Label: "产品包装",
    Customize: true,
  },
  {
    Prop: "CustomsCode",
    Label: "目的港海关编码",
    Customize: true,
  },
]
