import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemLogierCompany_Company from "./DtoListItemLogierCompany_Company";
import DtoListItemLogierCompany_ServiceProvider from "./DtoListItemLogierCompany_ServiceProvider";
import DtoListItemLogierCompany_JoinCompanyUserRole from "./DtoListItemLogierCompany_JoinCompanyUserRole";
import { EnumJoinCompanyUserStatus, EnumJoinCompanyUserStatusOption } from "./EnumJoinCompanyUserStatus";

/**
 * 获取当前登录用户的公司列表-当前登录用户的公司列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemLogierCompany extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Current = false;
        this.Level = 0;
        this.JoinCompanyUserRoles = [];
        this.Status = EnumJoinCompanyUserStatus.Normal;
        this.StatusText = "启用";
    }

    [proName: string]: any;
    /**
     * 当前公司
     */
    Current?: boolean;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 公司类型
     */
    CompanyTypeName?: string;
    /**
     * 公司
     */
    Company?: DtoListItemLogierCompany_Company;
    /**
     * 服务商
     */
    ServiceProvider?: DtoListItemLogierCompany_ServiceProvider;
    /**
     * 工号
     */
    Code?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 角色
     */
    JoinCompanyUserRoles?: DtoListItemLogierCompany_JoinCompanyUserRole[];
    /**
     * 上次登入时间
     */
    LastLoginTime?: Date;
    /**
     * 用户状态
     */
    Status?: EnumJoinCompanyUserStatus;
    /**
     * 状态
     */
    StatusText?: string;
}

/**
 * 获取当前登录用户的公司列表-当前登录用户的公司列表-表单校验
 */
export const DtoListItemLogierCompanyRules = {
}

/**
 * 获取当前登录用户的公司列表-当前登录用户的公司列表-设置项
 */
export const DtoListItemLogierCompanyMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CompanyTypeName",
    Label: "公司类型",
  },
  {
    Prop: "Company",
    Label: "公司",
  },
  {
    Prop: "ServiceProvider",
    Label: "服务商",
  },
  {
    Prop: "Code",
    Label: "工号",
  },
  {
    Prop: "Level",
    Label: "职级",
  },
  {
    Prop: "Job",
    Label: "职务",
  },
  {
    Prop: "JoinCompanyUserRoles",
    Label: "角色",
  },
  {
    Prop: "LastLoginTime",
    Label: "上次登入时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "StatusText",
    Label: "状态",
  },
]
