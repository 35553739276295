import { RouteRecordRaw } from "vue-router";
import DocumentPageList from "./Document/DocumentPageList.vue";
import DocumentForm from "./Document/DocumentForm.vue";
import DocumentDetail from "./Document/DocumentDetail.vue";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/document',
        name: 'DocumentManagement',
        component: DocumentPageList,
        meta: {
            title: "列表 - 单证管理"
        }
    },
    {
        path: '/document/add',
        name: 'DocumentAdd',
        component: DocumentForm,
        meta: {
            title: "新增 - 单证管理"
        }
    },
    {
        path: '/document/edit/:id',
        name: 'DocumentEdit',
        component: DocumentForm,
        meta: {
            title: "编辑 - 单证管理"
        }
    },
    {
        path: '/document/copy/:id',
        name: 'DocumentCopy',
        component: DocumentForm,
        meta: {
            title: "复制 - 单证管理"
        }
    },
    {
        path: '/document/detail/:id',
        name: 'DocumentDetail',
        component: DocumentDetail,
        meta: {
            title: "详细 - 单证管理"
        }
    },
]

export default routes;