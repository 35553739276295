import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import { EnumAuditStatus, EnumAuditStatusOption } from "./EnumAuditStatus";
import UiGroup from "./UiGroup";

/**
 * 实名认证-传参
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestCompanyAuth extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.AuthStatus = EnumAuditStatus.No;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 法人姓名
     */
    LeaderName?: string;
    /**
     * 法人身份证号码
     */
    LeaderIdCard?: string;
    /**
     * 法人手机号码
     */
    LeaderPhoneNumber?: string;
    /**
     * 营业执照
     */
    BusinessLicense?: string;
    /**
     * 认证状态
     */
    AuthStatus?: EnumAuditStatus;
    /**
     * 认证备注
     */
    AuthRemark?: string;
}

/**
 * 实名认证-传参-表单校验
 */
export const DtoRequestCompanyAuthRules = {
    LeaderName: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    LeaderIdCard: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    LeaderPhoneNumber: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
}

/**
 * 实名认证-传参-设置项
 */
export const DtoRequestCompanyAuthMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "公司类型",
    Disabled: true,
    Type: EnumMiezzFormItemType.Select,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Disabled: true,
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "企业代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LeaderName",
    Label: "法人姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LeaderIdCard",
    Label: "法人身份证号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "LeaderPhoneNumber",
    Label: "法人手机号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "BusinessLicense",
    Label: "营业执照",
    Customize: true,
  },
  {
    Prop: "AuthStatus",
    Label: "认证状态",
    Customize: true,
  },
  {
    Prop: "AuthRemark",
    Label: "认证备注",
    Hide: true,
    Type: EnumMiezzFormItemType.InputText,
  },
]
