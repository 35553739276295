import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 价格服务分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceService extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Open = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 开启
     */
    Open?: boolean;
    /**
     * 
     */
    Remark?: string;
}

/**
 * 价格服务分页列表-项-表单校验
 */
export const DtoListItemPriceServiceRules = {
}

/**
 * 价格服务分页列表-项-设置项
 */
export const DtoListItemPriceServiceMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Remark",
    Label: "",
    Pre: true,
  },
]
