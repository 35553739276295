import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * Http日志详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailHttpLog extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Success = false;
        this.Milliseconds = 0;
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 控制器
     */
    Controller?: string;
    /**
     * 请求
     */
    Action?: string;
    /**
     * 控制器注释
     */
    ControllerSummary?: string;
    /**
     * 请求注释
     */
    ActionSummary?: string;
    /**
     * 客户端Ipv4地址
     */
    Ip4?: string;
    /**
     * UA信息
     */
    UserAgent?: string;
    /**
     * 请求方式
     */
    Method?: string;
    /**
     * 请求地址
     */
    Url?: string;
    /**
     * 来源Url
     */
    RefererUrl?: string;
    /**
     * 请求参数
     */
    Param?: string;
    /**
     * 是否成功
     */
    Success?: boolean;
    /**
     * 回调信息
     */
    Result?: string;
    /**
     * 耗时毫秒数
     */
    Milliseconds?: number;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建公司英文名称
     */
    CreatedCompanyNameEn?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
}

/**
 * Http日志详细-表单校验
 */
export const DtoDetailHttpLogRules = {
}

/**
 * Http日志详细-设置项
 */
export const DtoDetailHttpLogMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TraceIdentifier",
    Label: "Http跟踪标识",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Controller",
    Label: "控制器",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Action",
    Label: "请求",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ControllerSummary",
    Label: "控制器注释",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ActionSummary",
    Label: "请求注释",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Ip4",
    Label: "客户端Ipv4地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "UserAgent",
    Label: "UA信息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Method",
    Label: "请求方式",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Url",
    Label: "请求地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "RefererUrl",
    Label: "来源Url",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Param",
    Label: "请求参数",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Success",
    Label: "是否成功",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "Result",
    Label: "回调信息",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Milliseconds",
    Label: "耗时毫秒数",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyNameEn",
    Label: "创建公司英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedPhoneNumber",
    Label: "创建人手机号码",
    Type: EnumMiezzFormItemType.Text,
  },
]
