import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_order_stowage_stack_page_list = _resolveComponent("order-stowage-stack-page-list")!

  return (_openBlock(), _createBlock(_component_order_stowage_stack_page_list, {
    list: _ctx.list,
    direction: _ctx.direction,
    type: _ctx.type,
    containerLoad: _ctx.containerLoad
  }, null, 8, ["list", "direction", "type", "containerLoad"]))
}