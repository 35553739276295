import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyBusinessCardBank from "../models.machine/DtoPageListSearchCompanyBusinessCardBank";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyBusinessCardBank from "../models.machine/DtoPageListCompanyBusinessCardBank";
import DtoDetailCompanyBusinessCardBank from "../models.machine/DtoDetailCompanyBusinessCardBank";
import DtoFormCompanyBusinessCardBank from "../models.machine/DtoFormCompanyBusinessCardBank";

/**
 * 公司名片-银行
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyBusinessCardBankAppService {
    /**
     * 获取公司名片-银行分页列表
     * @param {DtoPageListSearchCompanyBusinessCardBank} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCardBank>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyBusinessCardBank): AxiosPromise<RESTfulResult<DtoPageListCompanyBusinessCardBank>> {
        const url = `/api/CompanyBusinessCardBank/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyBusinessCardBank>>(url, { params: dto });
    }

    /**
     * 获取公司名片-银行详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCardBank>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyBusinessCardBank>> {
        const url = `/api/CompanyBusinessCardBank/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyBusinessCardBank>>(url);
    }

    /**
     * 添加公司名片-银行
     * @param {DtoFormCompanyBusinessCardBank} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyBusinessCardBank): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyBusinessCardBank/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除公司名片-银行
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCardBank/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 设置默认账号
     * @param {string} [bankId] 银行Id
     * @param {string} [id] 账号Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetDefaultAccountByBankIdById(bankId?: string, id?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyBusinessCardBank/SetDefaultAccount/${bankId}/${id}`;
        return service.post<RESTfulResult<any>>(url);
    }

}
