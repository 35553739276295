import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoFormOrderDepartureWharfInStockItem from "./DtoFormOrderDepartureWharfInStockItem";
import { EnumCompanyChargeType, EnumCompanyChargeTypeOption } from "./EnumCompanyChargeType";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderDepartureWharfInStock extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 委托书主键
     */
    OrderPoolId?: string;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 合计件数-入库
     */
    SumQuantityInStock?: number;
    /**
     * 合计毛重-入库
     */
    SumGrossWeightInStock?: number;
    /**
     * 合计体积-入库
     */
    SumVolumeInStock?: number;
    /**
     * 计价单位-入库
     */
    ChargeUnitInStock?: string;
    /**
     * 备注
     */
    RemarkInStock?: string;
    /**
     * 入库
     */
    InStocks?: DtoFormOrderDepartureWharfInStockItem[];
    /**
     * 类型
     */
    ChargeType?: EnumCompanyChargeType;
    /**
     * 计费重量
     */
    CompanyChargeId?: string;
    /**
     * 计费重量
     */
    ChargeWeight?: number;
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoFormOrderDepartureWharfInStockRules = {
    OrderPoolId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    OrderDepartureWharfId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoFormOrderDepartureWharfInStockMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OrderPoolId",
    Label: "委托书主键",
  },
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
  },
  {
    Prop: "SumQuantityInStock",
    Label: "合计件数-入库",
  },
  {
    Prop: "SumGrossWeightInStock",
    Label: "合计毛重-入库",
  },
  {
    Prop: "SumVolumeInStock",
    Label: "合计体积-入库",
  },
  {
    Prop: "ChargeUnitInStock",
    Label: "计价单位-入库",
  },
  {
    Prop: "RemarkInStock",
    Label: "备注",
  },
  {
    Prop: "InStocks",
    Label: "入库",
  },
  {
    Prop: "ChargeType",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyChargeTypeOption,
  },
  {
    Prop: "CompanyChargeId",
    Label: "计费重量",
  },
  {
    Prop: "ChargeWeight",
    Label: "计费重量",
  },
]
