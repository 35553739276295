import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchProductSpecElement from "../models.machine/DtoPageListSearchProductSpecElement";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListProductSpecElement from "../models.machine/DtoPageListProductSpecElement";
import DtoDetailProductSpecElement from "../models.machine/DtoDetailProductSpecElement";
import DtoFormProductSpecElement from "../models.machine/DtoFormProductSpecElement";
import DtoSearchElement from "../models.machine/DtoSearchElement";

/**
 * 整个产品的规格要素
 * @description 自动生成的代码,请勿修改
 */
export default class ProductSpecElementAppService {
    /**
     * 获取整个产品的规格要素分页列表
     * @param {DtoPageListSearchProductSpecElement} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListProductSpecElement>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchProductSpecElement): AxiosPromise<RESTfulResult<DtoPageListProductSpecElement>> {
        const url = `/api/ProductSpecElement/Get`;
        return service.get<RESTfulResult<DtoPageListProductSpecElement>>(url, { params: dto });
    }

    /**
     * 获取整个产品的规格要素详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailProductSpecElement>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailProductSpecElement>> {
        const url = `/api/ProductSpecElement/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailProductSpecElement>>(url);
    }

    /**
     * 添加整个产品的规格要素
     * @param {DtoFormProductSpecElement} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormProductSpecElement): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ProductSpecElement/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除整个产品的规格要素
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ProductSpecElement/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 判断要素名称是否存在
     * @param {DtoSearchElement} [dto] 查询条件
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsSameElement(dto?: DtoSearchElement): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ProductSpecElement/IsSameElement`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

}
