import UiPageList from "./UiPageList";
import DtoListItemOrderStowageStack from "./DtoListItemOrderStowageStack";

/**
 * 配载栈分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderStowageStack extends UiPageList<DtoListItemOrderStowageStack, string> {
    constructor() {
        //无参构造函数
        super()
        this.OrderPrePackedBoxCount = 0;
        this.OrderPrePackedBoxProgrammeCount = 0;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 预装箱数量
     */
    OrderPrePackedBoxCount?: number;
    /**
     * 方案数量
     */
    OrderPrePackedBoxProgrammeCount?: number;
}

/**
 * 配载栈分页列表-表单校验
 */
export const DtoPageListOrderStowageStackRules = {
}
