import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchJoinPriceServiceTableSearch from "../models.machine/DtoSelectSearchJoinPriceServiceTableSearch";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 关联-价格服务/价格表/搜索相关
 * @description 自动生成的代码,请勿修改
 */
export default class JoinPriceServiceTableSearchAppService {
    /**
     * 获取关联-价格服务/价格表/搜索相关选择器
     * @param {DtoSelectSearchJoinPriceServiceTableSearch} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchJoinPriceServiceTableSearch): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/JoinPriceServiceTableSearch/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

}
