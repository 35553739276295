import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  "element-loading-background": "rgba(0, 0, 0, 0.5)",
  style: {"padding-top":"0px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
    }, null, 8, ["modelValue"])
  ])), [
    [
      _directive_loading,
      _ctx.isLoading,
      void 0,
      {
        fullscreen: true,
        lock: true
      }
    ]
  ])
}