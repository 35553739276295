import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPlatformCompany_Company from "./DtoDetailPlatformCompany_Company";
import { EnumProductSiteEnvironment, EnumProductSiteEnvironmentOption } from "./EnumProductSiteEnvironment";
import DtoDetailPlatformCompany_ProjectVersion from "./DtoDetailPlatformCompany_ProjectVersion";
import UiGroup from "./UiGroup";

/**
 * 平台管理详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPlatformCompany extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Official = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 平台
     */
    Company?: DtoDetailPlatformCompany_Company;
    /**
     * 环境
     */
    Environment?: EnumProductSiteEnvironment;
    /**
     * 官方
     */
    Official?: boolean;
    /**
     * 发布版本
     */
    ProjectVersion?: DtoDetailPlatformCompany_ProjectVersion;
    /**
     * 数据库-连接地址
     */
    DbConnectionString?: string;
}

/**
 * 平台管理详细-表单校验
 */
export const DtoDetailPlatformCompanyRules = {
}

/**
 * 平台管理详细-设置项
 */
export const DtoDetailPlatformCompanyMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Company",
    Label: "平台",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Environment",
    Label: "环境",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumProductSiteEnvironmentOption,
  },
  {
    Prop: "Official",
    Label: "官方",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "ProjectVersion",
    Label: "发布版本",
    Type: EnumMiezzFormItemType.Text,
  },
]
