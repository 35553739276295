import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 委托书-货物清单分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolCargo extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * Shipment ID/Reference ID
     */
    ShipmentId?: string;
    /**
     * FBA No.
     */
    ReferenceId?: string;
    /**
     * 入库时间
     */
    WarehousingTime?: Date;
    /**
     * 出库时间
     */
    ExWarehouseTime?: Date;
}

/**
 * 委托书-货物清单分页列表-项-表单校验
 */
export const DtoListItemOrderPoolCargoRules = {
}

/**
 * 委托书-货物清单分页列表-项-设置项
 */
export const DtoListItemOrderPoolCargoMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ShipmentId",
    Label: "Shipment ID/Reference ID",
  },
  {
    Prop: "ReferenceId",
    Label: "FBA No.",
  },
  {
    Prop: "WarehousingTime",
    Label: "入库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "ExWarehouseTime",
    Label: "出库时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
