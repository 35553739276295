import UiSelectOption from "./UiSelectOption";

/**
 * 清关抬头提供者
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomsClearanceHeaderProvider {
    /**
     * 委托人提供
     */
    Client = 1,
    /**
     * 服务商提供
     */
    ServiceProvider = 2,
}

/**
 * 清关抬头提供者-选项
 */
export const EnumCustomsClearanceHeaderProviderOption: UiSelectOption<number>[] = [
    { Label: "委托人提供", Value: 1, Data: "Client" },
    { Label: "服务商提供", Value: 2, Data: "ServiceProvider" },
];
