import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    modelValue: _ctx.detail,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'GitLogs')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            "label-width": "0",
            prop: item.Prop
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_page_list, {
                modelValue: _ctx.list,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
                onHandleRowButtonClick: _ctx.handleRowButtonClick
              }, null, 8, ["modelValue", "onHandleRowButtonClick"])
            ]),
            _: 2
          }, 1032, ["prop"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}