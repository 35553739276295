import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumOrderPoolExpressChartGroupBy, EnumOrderPoolExpressChartGroupByOption } from "./EnumOrderPoolExpressChartGroupBy";
import { EnumOrderPoolExpressChartDataSource, EnumOrderPoolExpressChartDataSourceOption } from "./EnumOrderPoolExpressChartDataSource";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestOrderPoolExpressChart extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 分组
     */
    GroupBy?: EnumOrderPoolExpressChartGroupBy;
    /**
     * 数据源
     */
    DataSource?: EnumOrderPoolExpressChartDataSource;
    /**
     * 月份
     */
    Month?: string;
    /**
     * 客户Id
     */
    ClientCompanyId?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
}

/**
 * -表单校验
 */
export const DtoRequestOrderPoolExpressChartRules = {
}

/**
 * -动态搜索配置项
 */
export const DtoRequestOrderPoolExpressChartDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_SupplierCompanyId",
    Label: "供应商",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Area",
    Label: "坐标",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_LoadingPortId",
    Label: "装货港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPortId",
    Label: "目的港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_TransportDirection",
    Label: "进出口类型",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
