import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderDeparture from "../models.machine/DtoPageListSearchOrderDeparture";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderDeparture from "../models.machine/DtoPageListOrderDeparture";
import DtoFormOrderDeparture from "../models.machine/DtoFormOrderDeparture";
import DtoPutSupplier from "../models.machine/DtoPutSupplier";
import DtoPutExecutor from "../models.machine/DtoPutExecutor";
import DtoPutReleaseTime from "../models.machine/DtoPutReleaseTime";
import DtoPutExportAnnex from "../models.machine/DtoPutExportAnnex";
import DtoPutImportAnnex from "../models.machine/DtoPutImportAnnex";
import DtoRequestClilentCompany from "../models.machine/DtoRequestClilentCompany";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRequestOrderState from "../models.machine/DtoRequestOrderState";
import DtoCallbackOrderState from "../models.machine/DtoCallbackOrderState";
import DtoRequestAutoAdd from "../models.machine/DtoRequestAutoAdd";
import DtoPutBLNO from "../models.machine/DtoPutBLNO";
import DtoRequestAdd from "../models.machine/DtoRequestAdd";
import DtoCallbackAdd from "../models.machine/DtoCallbackAdd";
import DtoCheckBLNO from "../models.machine/DtoCheckBLNO";
import DtoRequestBLNO from "../models.machine/DtoRequestBLNO";
import DtoCallbackBLNO from "../models.machine/DtoCallbackBLNO";
import DtoRequestOrderDepartureChart from "../models.machine/DtoRequestOrderDepartureChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";

/**
 * 装货港-报关
 * @description 自动生成的代码,请勿修改
 */
export default class OrderDepartureAppService {
    /**
     * 获取装货港-报关分页列表
     * @param {DtoPageListSearchOrderDeparture} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDeparture>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderDeparture): AxiosPromise<RESTfulResult<DtoPageListOrderDeparture>> {
        const url = `/api/OrderDeparture/Get`;
        return service.get<RESTfulResult<DtoPageListOrderDeparture>>(url, { params: dto });
    }

    /**
     * 添加装货港-报关
     * @param {DtoFormOrderDeparture} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderDeparture): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDeparture/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除装货港-报关
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 修改供应商
     * @param {DtoPutSupplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateSupplier(dto?: DtoPutSupplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateSupplier`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改执行人
     * @param {DtoPutExecutor} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateExecutor(dto?: DtoPutExecutor): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateExecutor`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改海关放行时间
     * @param {DtoPutReleaseTime} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateReleaseTime(dto?: DtoPutReleaseTime): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateReleaseTime`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 手动添加-出口
     * @param {DtoPutExportAnnex} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateExportAnnex(dto?: DtoPutExportAnnex): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateExportAnnex`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 手动添加-进口
     * @param {DtoPutImportAnnex} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateImportAnnex(dto?: DtoPutImportAnnex): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateImportAnnex`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取委托人集合
     * @param {DtoRequestClilentCompany} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientCompany(dto?: DtoRequestClilentCompany): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDeparture/GetClientCompany`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取订单/委托书的状态
     * @param {DtoRequestOrderState} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderState>>} 
     */
    static GetState(dto?: DtoRequestOrderState): AxiosPromise<RESTfulResult<DtoCallbackOrderState>> {
        const url = `/api/OrderDeparture/GetState`;
        return service.get<RESTfulResult<DtoCallbackOrderState>>(url, { params: dto });
    }

    /**
     * 自动添加
     * @param {string} [poolId] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AutoAddByPoolId(poolId?: string): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/AutoAdd/${poolId}`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 自动添加
     * @param {DtoRequestAutoAdd} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AutoAddOrder(dto?: DtoRequestAutoAdd): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/AutoAddOrder`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 修改提运单号
     * @param {DtoPutBLNO} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateBLNo(dto?: DtoPutBLNO): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/UpdateBLNo`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取默认的提运单号
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoPutBLNO>>} 
     */
    static GetBLNOById(id?: string): AxiosPromise<RESTfulResult<DtoPutBLNO>> {
        const url = `/api/OrderDeparture/GetBLNO/${id}`;
        return service.get<RESTfulResult<DtoPutBLNO>>(url);
    }

    /**
     * 获取委托书信息
     * @param {DtoRequestAdd} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackAdd>>} 
     */
    static GetAdd(dto?: DtoRequestAdd): AxiosPromise<RESTfulResult<DtoCallbackAdd>> {
        const url = `/api/OrderDeparture/GetAdd`;
        return service.get<RESTfulResult<DtoCallbackAdd>>(url, { params: dto });
    }

    /**
     * 同一个订单的提运单号是否重复
     * @param {DtoCheckBLNO} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static IsBLNO(dto?: DtoCheckBLNO): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/OrderDeparture/IsBLNO`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 获取提运单号集合
     * @param {DtoRequestBLNO} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackBLNO>>} 
     */
    static GetBLNOs(dto?: DtoRequestBLNO): AxiosPromise<RESTfulResult<DtoCallbackBLNO>> {
        const url = `/api/OrderDeparture/GetBLNOs`;
        return service.get<RESTfulResult<DtoCallbackBLNO>>(url, { params: dto });
    }

    /**
     * 修改提运单号
     * @param {DtoCallbackBLNO} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SetBLNOs(dto?: DtoCallbackBLNO): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/OrderDeparture/SetBLNOs`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 统计分析-委托书-柱形图
     * @param {DtoRequestOrderDepartureChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderDepartureChart): AxiosPromise<RESTfulResult<UiEChartsBar>> {
        const url = `/api/OrderDeparture/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar>>(url, { params: dto });
    }

    /**
     * 统计分析-委托书-饼状图
     * @param {DtoRequestOrderDepartureChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderDepartureChart): AxiosPromise<RESTfulResult<UiEChartsPie>> {
        const url = `/api/OrderDeparture/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie>>(url, { params: dto });
    }

    /**
     * 获取委托人选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClients(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDeparture/GetClients`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDeparture/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取装货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDeparture/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取卸货港选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDeparture/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
