
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzModal from "@/models/MiezzModal";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailOrderProcess_DeparturePortBooking from "@/models.machine/DtoDetailOrderProcess_DeparturePortBooking";
import DtoDetailOrderProcess_OrderPoolContainerTrailer, {
  DtoDetailOrderProcess_OrderPoolContainerTrailerMiezzListItems,
} from "@/models.machine/DtoDetailOrderProcess_OrderPoolContainerTrailer";
import DtoDetailOrderProcess_OrderPoolTrunk, {
  DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems,
} from "@/models.machine/DtoDetailOrderProcess_OrderPoolTrunk";
import DtoDetailOrderProcess_OrderPoolExpress, {
  DtoDetailOrderProcess_OrderPoolExpressMiezzListItems,
} from "@/models.machine/DtoDetailOrderProcess_OrderPoolExpress";
import DtoDetailOrderProcess_OrderPoolCustoms, {
  DtoDetailOrderProcess_OrderPoolCustomsMiezzListItems,
} from "@/models.machine/DtoDetailOrderProcess_OrderPoolCustoms";
import DtoDetailOrderProcess_OrderPoolWharf, {
  DtoDetailOrderProcess_OrderPoolWharfMiezzListItems,
} from "@/models.machine/DtoDetailOrderProcess_OrderPoolWharf";
import DtoDetailOrderProcess from "@/models.machine/DtoDetailOrderProcess";
import { DtoDetailOrderProcess_DeparturePortBookingMiezzListItems } from "@/models.machine/DtoDetailOrderProcess_DeparturePortBooking";
import OrderAppService from "@/apis.machine/OrderAppService";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import MiezzButton from "@/models/MiezzButton";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import DtoCallbackOrderMainBillAutoPullThreeApiData from "@/models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData";
export default class OrderProcess extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  powers?: string[] = [];

  service = "";
  services: UiSelectOptionWithStringValue[] = [];
  detail?: DtoDetailOrderProcess;

  tab = "";
  tabs: any[] = [];

  listBooking = new MiezzPageList<
    any,
    DtoDetailOrderProcess_DeparturePortBooking,
    any
  >();
  listContainer = new MiezzPageList<
    any,
    DtoDetailOrderProcess_OrderPoolContainerTrailer,
    any
  >();
  listTrunk = new MiezzPageList<
    any,
    DtoDetailOrderProcess_OrderPoolTrunk,
    any
  >();
  listExpress = new MiezzPageList<
    any,
    DtoDetailOrderProcess_OrderPoolExpress,
    any
  >();
  listWharf = new MiezzPageList<
    any,
    DtoDetailOrderProcess_OrderPoolWharf,
    any
  >();
  listCustoms = new MiezzPageList<
    any,
    DtoDetailOrderProcess_OrderPoolCustoms,
    any
  >();
  btnDetail: MiezzButton = {
    Code: "detail",
    Label: "查看详情",
    Size: "small",
    Type: "text",
  };

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "detail":
        var taskNames = this.detail?.TaskActives?.firstOrDefault(
          (it) => it.Service == this.service
        )?.Types;
        var routeData: any;
        switch (this.service) {
          //舱务信息
          case "DeparturePortBooking":
            routeData = this.$router.resolve({
              path: `/order/booking/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //装货港-集装箱拖车
          case "DeparturePortContainer":
            routeData = this.$router.resolve({
              path: `/order/departure-port-container/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //装货港-快递
          case "DeparturePortExpress":
            routeData = this.$router.resolve({
              path: `/order/departure-port-express/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //装货港-干线物流
          case "DeparturePortTrunk":
            routeData = this.$router.resolve({
              path: `/order/departure-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //装货港-报关
          case "DeparturePortDeclareCustoms":
            routeData = this.$router.resolve({
              path: `/order/departure/customs/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //装货港-仓库/堆场/码头
          case "DeparturePortWarehouse":
            routeData = this.$router.resolve({
              path: `/order/departure/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          //目的港-清关
          case "DestinationPortClearCustoms":
            routeData = this.$router.resolve({
              path: `/order/destination/customs/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //目的港-集装箱拖车
          case "DestinationPortContainer":
            routeData = this.$router.resolve({
              path: `/order/destination-port-container/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //目的港-干线物流
          case "DestinationPortTrunk":
            routeData = this.$router.resolve({
              path: `/order/destination-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //目的港-快递
          case "DestinationPortExpress":
            routeData = this.$router.resolve({
              path: `/order/destination-port-express/${this.id}`,
              query: { task: taskNames },
            });
            break;
          //目的港-仓库/堆场/码头
          case "DestinationPortWarehouse":
            routeData = this.$router.resolve({
              path: `/order/destination/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          case "Bill":
            routeData = this.$router.resolve({
              path: `/order/bill/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          default:
            break;
        }
        if (routeData) {
          window.open(routeData.href, "_blank");
        }
        complete();
        break;
      default:
        break;
    }
  }

  created(): void {
    this.listBooking.Fix = false;
    this.listBooking.HideCard = true;
    this.listBooking.EnabledDynamicSearch = false;
    this.listBooking.EnabledRadioSearch = false;
    this.listBooking.EnabledSelect = false;
    this.listBooking.EnabledPagination = false;
    this.listBooking.Items =
      DtoDetailOrderProcess_DeparturePortBookingMiezzListItems;
    this.listBooking.Data = {
      Items: [],
    };

    this.listContainer.Fix = false;
    this.listContainer.HideCard = true;
    this.listContainer.EnabledDynamicSearch = false;
    this.listContainer.EnabledRadioSearch = false;
    this.listContainer.EnabledSelect = false;
    this.listContainer.EnabledPagination = false;
    this.listContainer.Items =
      DtoDetailOrderProcess_OrderPoolContainerTrailerMiezzListItems;
    this.listContainer.Data = {
      Items: [],
    };
    this.listTrunk.Fix = false;
    this.listTrunk.HideCard = true;
    this.listTrunk.EnabledDynamicSearch = false;
    this.listTrunk.EnabledRadioSearch = false;
    this.listTrunk.EnabledSelect = false;
    this.listTrunk.EnabledPagination = false;
    this.listTrunk.Items = DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems;
    this.listTrunk.Data = {
      Items: [],
    };
    this.listExpress.Fix = false;
    this.listExpress.HideCard = true;
    this.listExpress.EnabledDynamicSearch = false;
    this.listExpress.EnabledRadioSearch = false;
    this.listExpress.EnabledSelect = false;
    this.listExpress.EnabledPagination = false;
    this.listExpress.Items =
      DtoDetailOrderProcess_OrderPoolExpressMiezzListItems;
    this.listExpress.Data = {
      Items: [],
    };
    this.listWharf.Fix = false;
    this.listWharf.HideCard = true;
    this.listWharf.EnabledDynamicSearch = false;
    this.listWharf.EnabledRadioSearch = false;
    this.listWharf.EnabledSelect = false;
    this.listWharf.EnabledPagination = false;
    this.listWharf.Items = DtoDetailOrderProcess_OrderPoolWharfMiezzListItems;
    this.listWharf.Data = {
      Items: [],
    };
    this.listCustoms.Fix = false;
    this.listCustoms.HideCard = true;
    this.listCustoms.EnabledDynamicSearch = false;
    this.listCustoms.EnabledRadioSearch = false;
    this.listCustoms.EnabledSelect = false;
    this.listCustoms.EnabledPagination = false;
    this.listCustoms.Items =
      DtoDetailOrderProcess_OrderPoolCustomsMiezzListItems;
    this.listCustoms.Data = {
      Items: [],
    };
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
      this.handleGet();
    });
  }

  api: DtoCallbackOrderMainBillAutoPullThreeApiData = {};

  @Watch("id")
  handleGet(): void {
    this.services = [];
    OrderAppService.GetProcessById(this.id).then((r) => {
      this.detail = r.data.Data;
      if (this.detail) {
        if (!this.api.Query) {
          OrderMainBillAppService.AutoPullThreeApiData({
            OrderId: this.id,
            UpdateData: true,
          }).then((r) => {
            this.api = r.data.Data ?? {};
            this.api.Query = true;
          });
        }

        //装货港-订舱
        if (this.powers?.contains("OrderManagement_Order_Summary_Booking")) {
          this.services.push({
            Value: "DeparturePortBooking",
            Label: "舱务信息",
            Class:
              (this.detail.DeparturePortBookingList?.length ?? 0) > 0 &&
              this.detail.DeparturePortBookingList?.all(
                (it) =>
                  it.EstimatedDepartureDate &&
                  it.ActualDepartureDate &&
                  it.EstimatedArrivalDate &&
                  it.ActualArrivalDate
              )
                ? "ed"
                : (this.detail.DeparturePortBookingList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortBookingList?.any(
                    (it) =>
                      it.EstimatedDepartureDate ||
                      it.ActualDepartureDate ||
                      it.EstimatedArrivalDate ||
                      it.ActualArrivalDate
                  )
                ? "ing"
                : "not",
          });
        }

        if (
          this.powers?.contains(
            "OrderManagement_Order_Summary_DeparturePortContainer"
          )
        ) {
          //装货港-集装箱拖车
          if (
            //直订订单 并且 委托书FCL 并且有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortContainer
              )) ||
            //直订订单 并且 委托书FCL 并且有仓库没集装箱
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortWarehouse &&
                  !it.DeparturePortContainer
              )) ||
            //配载订单 订单FCL
            (this.detail.StowageOrder &&
              this.detail.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL)
          ) {
            this.services.push({
              Value: "DeparturePortContainer",
              Label: "装货港-集装箱拖车",
              Class:
                (this.detail.DeparturePortContainerList?.length ?? 0) > 0 &&
                this.detail.DeparturePortContainerList?.all(
                  (it) =>
                    it.CarryContainerTime &&
                    it.PlanTime &&
                    it.ArrivalTime &&
                    it.BackContainerTime
                )
                  ? "ed"
                  : (this.detail.DeparturePortContainerList?.length ?? 0) > 0 &&
                    this.detail.DeparturePortContainerList?.any(
                      (it) =>
                        it.CarryContainerTime ||
                        it.PlanTime ||
                        it.ArrivalTime ||
                        it.BackContainerTime
                    )
                  ? "ing"
                  : "not",
            });
          }
        }
        //装货港-快递 当订单中包含的任一委托书中勾选了装货港-提货-快递时
        if (
          this.detail.Pools?.any((it) => it.DeparturePortExpress) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_DeparturePortExpress"
          )
        ) {
          this.services.push({
            Value: "DeparturePortExpress",
            Label: "装货港-快递",
            Class:
              (this.detail.DeparturePortExpressList?.length ?? 0) > 0 &&
              this.detail.DeparturePortExpressList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DeparturePortExpressList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortExpressList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-干线物流 当订单中包含的任一委托书中勾选了装货港-提货-干线物流时
        if (
          this.detail.Pools?.any((it) => it.DeparturePortTrunk) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_DeparturePortTrunk"
          )
        ) {
          this.services.push({
            Value: "DeparturePortTrunk",
            Label: "装货港-干线物流",
            Class:
              (this.detail.DeparturePortTrunkList?.length ?? 0) > 0 &&
              this.detail.DeparturePortTrunkList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DeparturePortTrunkList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortTrunkList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-仓库/堆场/码头 当订单中包含的任一委托书中勾选了装货港-提货-仓库/堆场/码头时
        if (
          this.detail.Pools?.any((it) => it.DeparturePortWarehouse) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_PortOfDepartureWarehousestorageYardwharf"
          )
        ) {
          this.services.push({
            Value: "DeparturePortWarehouse",
            Label: "装货港-仓库/堆场/码头",
            Class:
              (this.detail.DeparturePortWarehouseList?.length ?? 0) > 0 &&
              this.detail.DeparturePortWarehouseList?.all(
                (it) => it.InStockTime && it.OutStockTime
              )
                ? "ed"
                : (this.detail.DeparturePortWarehouseList?.length ?? 0) > 0 &&
                  this.detail.DeparturePortWarehouseList?.any(
                    (it) => it.InStockTime || it.OutStockTime
                  )
                ? "ing"
                : "not",
          });
        }
        //装货港-报关 当订单中包含的任一委托书中勾选了装货港-报关时
        if (
          this.detail.Pools?.any((it) => it.DeparturePortDeclareCustoms) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_PortOfDepartureCustomsAffairs"
          )
        ) {
          this.services.push({
            Value: "DeparturePortDeclareCustoms",
            Label: "装货港-报关",
            Class:
              (this.detail.DeparturePortDeclareCustomsList?.length ?? 0) > 0 &&
              this.detail.DeparturePortDeclareCustomsList?.all(
                (it) => it.ReleaseTime
              )
                ? "ed"
                : (this.detail.DeparturePortDeclareCustomsList?.length ?? 0) >
                    0 &&
                  this.detail.DeparturePortDeclareCustomsList?.any(
                    (it) => it.ReleaseTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-清关 当订单中包含的任一委托书中勾选了目的港-清关时
        if (
          this.detail.Pools?.any((it) => it.DestinationPortClearCustoms) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_PortOfDestinationCustomsClearance"
          )
        ) {
          this.services.push({
            Value: "DestinationPortClearCustoms",
            Label: "目的港-清关",
            Class:
              (this.detail.DestinationPortClearCustomsList?.length ?? 0) > 0 &&
              this.detail.DestinationPortClearCustomsList?.all(
                (it) => it.ReleaseTime
              )
                ? "ed"
                : (this.detail.DestinationPortClearCustomsList?.length ?? 0) >
                    0 &&
                  this.detail.DestinationPortClearCustomsList?.any(
                    (it) => it.ReleaseTime
                  )
                ? "ing"
                : "not",
          });
        }
        if (
          this.powers?.contains(
            "OrderManagement_Order_Summary_DestinationPortContainer"
          )
        ) {
          //目的港-集装箱拖车
          if (
            //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
            (this.detail.StowageOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortContainer
              )) ||
            //配载订单 并且 订单FCL 并且 委托书任意(LCL有仓库)
            (this.detail.StowageOrder &&
              this.detail.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortWarehouse
              )) ||
            //直订订单 并且 有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.Pools?.any((it) => it.DestinationPortContainer))
          ) {
            this.services.push({
              Value: "DestinationPortContainer",
              Label: "目的港-集装箱拖车",
              Class:
                (this.detail.DestinationPortContainerList?.length ?? 0) > 0 &&
                this.detail.DestinationPortContainerList?.all(
                  (it) =>
                    it.CarryContainerTime &&
                    it.PlanTime &&
                    it.ArrivalTime &&
                    it.BackContainerTime
                )
                  ? "ed"
                  : (this.detail.DestinationPortContainerList?.length ?? 0) >
                      0 &&
                    this.detail.DestinationPortContainerList?.any(
                      (it) =>
                        it.CarryContainerTime ||
                        it.PlanTime ||
                        it.ArrivalTime ||
                        it.BackContainerTime
                    )
                  ? "ing"
                  : "not",
            });
          }
        }
        //目的港-仓库/堆场/码头 当订单中包含的任一委托书中勾选了目的港-仓库/堆场/码头时
        if (
          this.detail.Pools?.any((it) => it.DestinationPortWarehouse) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_DestinationPortWarehouseStorageYardWharf"
          )
        ) {
          this.services.push({
            Value: "DestinationPortWarehouse",
            Label: "目的港-仓库/堆场/码头",
            Class:
              (this.detail.DestinationPortWarehouseList?.length ?? 0) > 0 &&
              this.detail.DestinationPortWarehouseList?.all(
                (it) => it.InStockTime && it.OutStockTime
              )
                ? "ed"
                : (this.detail.DestinationPortWarehouseList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortWarehouseList?.any(
                    (it) => it.InStockTime || it.OutStockTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-干线物流 当订单中包含的任一委托书中勾选了目的港-干线物流时
        if (
          this.detail.Pools?.any((it) => it.DestinationPortTrunk) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_DestinationPortTrunk"
          )
        ) {
          this.services.push({
            Value: "DestinationPortTrunk",
            Label: "目的港-干线物流",
            Class:
              (this.detail.DestinationPortTrunkList?.length ?? 0) > 0 &&
              this.detail.DestinationPortTrunkList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DestinationPortTrunkList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortTrunkList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }
        //目的港-快递 当订单中包含的任一委托书中勾选了目的港-快递时
        if (
          this.detail.Pools?.any((it) => it.DestinationPortExpress) &&
          this.powers?.contains(
            "OrderManagement_Order_Summary_DestinationPortExpress"
          )
        ) {
          this.services.push({
            Value: "DestinationPortExpress",
            Label: "目的港-快递",
            Class:
              (this.detail.DestinationPortExpressList?.length ?? 0) > 0 &&
              this.detail.DestinationPortExpressList?.all(
                (it) =>
                  it.AllCarryTime && it.PlanDeliveryTime && it.AllDeliveryTime
              )
                ? "ed"
                : (this.detail.DestinationPortExpressList?.length ?? 0) > 0 &&
                  this.detail.DestinationPortExpressList?.any(
                    (it) =>
                      it.AllCarryTime ||
                      it.PlanDeliveryTime ||
                      it.AllDeliveryTime
                  )
                ? "ing"
                : "not",
          });
        }

        this.handleServiceClick(this.services.firstOrDefault()?.Value ?? "");
      }
    });
  }

  handleServiceClick(service: string): void {
    this.service = service;
    this.$nextTick(() => {
      switch (this.service) {
        case "DeparturePortBooking":
          this.tabs = this.detail?.DeparturePortBookingList ?? [];
          break;
        case "DeparturePortContainer":
          this.tabs = this.detail?.DeparturePortContainerList ?? [];
          break;
        case "DeparturePortExpress":
          this.tabs = this.detail?.DeparturePortExpressList ?? [];
          break;
        case "DeparturePortTrunk":
          this.tabs = this.detail?.DeparturePortTrunkList ?? [];
          break;
        case "DeparturePortDeclareCustoms":
          this.tabs = this.detail?.DeparturePortDeclareCustomsList ?? [];
          break;
        case "DeparturePortWarehouse":
          this.tabs = this.detail?.DeparturePortWarehouseList ?? [];
          break;
        case "DestinationPortClearCustoms":
          this.tabs = this.detail?.DestinationPortClearCustomsList ?? [];
          break;
        case "DestinationPortContainer":
          this.tabs = this.detail?.DestinationPortContainerList ?? [];
          break;
        case "DestinationPortTrunk":
          this.tabs = this.detail?.DestinationPortTrunkList ?? [];
          break;
        case "DestinationPortExpress":
          this.tabs = this.detail?.DestinationPortExpressList ?? [];
          break;
        case "DestinationPortWarehouse":
          this.tabs = this.detail?.DestinationPortWarehouseList ?? [];
          break;

        default:
          break;
      }
      this.tab = this.tabs.firstOrDefault()?.Id;
      this.handleTabClick();
    });
  }

  handleTabClick(): void {
    this.$nextTick(() => {
      if (this.tab) {
        switch (this.service) {
          case "DeparturePortBooking":
            this.listBooking.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortContainer":
          case "DestinationPortContainer":
            for (const item of this.listContainer.Items ?? []) {
              switch (item.Prop) {
                case "PlanTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "计划装柜时间"
                      : "计划卸货时间";
                  break;
                case "ArrivalTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "装柜时间"
                      : "卸货时间";
                  break;
                case "BackContainerTime":
                  item.Label =
                    this.service == "DeparturePortContainer"
                      ? "进港时间"
                      : "还柜时间";
                  break;
              }
            }
            this.listContainer.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortExpress":
          case "DestinationPortExpress":
            this.listExpress.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            if (
              this.service == "DestinationPortExpress" &&
              this.detail?.ShowCarryContainerTime
            ) {
              this.listExpress.Items =
                DtoDetailOrderProcess_OrderPoolExpressMiezzListItems;
            } else {
              this.listExpress.Items =
                DtoDetailOrderProcess_OrderPoolExpressMiezzListItems.filter(
                  (it) => it.Prop != "CarryContainerTime"
                );
            }
            break;
          case "DeparturePortTrunk":
          case "DestinationPortTrunk":
            this.listTrunk.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            if (
              this.service == "DestinationPortTrunk" &&
              this.detail?.ShowCarryContainerTime
            ) {
              this.listTrunk.Items =
                DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems;
            } else {
              this.listTrunk.Items =
                DtoDetailOrderProcess_OrderPoolTrunkMiezzListItems.filter(
                  (it) => it.Prop != "CarryContainerTime"
                );
            }
            break;
          case "DeparturePortDeclareCustoms":
          case "DestinationPortClearCustoms":
            this.listCustoms.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;
          case "DeparturePortWarehouse":
          case "DestinationPortWarehouse":
            this.listWharf.Data = {
              Items: [this.tabs.firstOrDefault((it) => it.Id == this.tab)],
            };
            break;

          default:
            break;
        }
      }
    });
  }
}
