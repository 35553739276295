import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"position":"relative"}
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
    onHandleTopButtonClick: _ctx.handleTopButtonClick,
    onHandleRowButtonClick: _ctx.handleRowButtonClick,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick
  }, {
    column: _withCtx(({ model, row }) => [
      (model.Prop == 'HsCode')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createTextVNode(" 【中国 " + _toDisplayString(row.HsCode) + "】 ", 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.CustomsCodes, (p, index) => {
              return (_openBlock(), _createElementBlock("span", { key: index }, " 【" + _toDisplayString(p.CountryName.split("/")[0]) + " " + _toDisplayString(p.HsCode) + "】 ", 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (model.Prop == 'SpecQuantity')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (row.SpecQuantity > 0)
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  class: "box-item",
                  effect: "dark",
                  content: row.SpecName,
                  placement: "top-start"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.SpecQuantity), 1)
                  ]),
                  _: 2
                }, 1032, ["content"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(row.SpecQuantity), 1))
          ]))
        : _createCommentVNode("", true),
      (model.Prop == 'IfShelf')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(row.IfShelf ? "上架" : "下架"), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    headerRight: _withCtx(() => [
      (_ctx.isManageOtherCompany == true)
        ? (_openBlock(), _createBlock(_component_el_select, {
            filterable: true,
            key: _ctx.companyRandom,
            modelValue: _ctx.selectCompanyId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectCompanyId) = $event)),
            style: {"margin-right":"10px"},
            placeholder: "请选择",
            onChange: _ctx.onChangeCompany
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companys, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.Id,
                  label: item.Name,
                  value: item.Id
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"]))
        : _createCommentVNode("", true)
    ]),
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_IfShelf')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.statuss
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick"]))
}