
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
export default class OrderDetailProductsSummary extends Vue {
  @Model() form!: MiezzForm<
    {
      MarksNotes?: string;
      PackagesNumber?: number;
      PackagesKind?: string;
      PackagesNumberKind?: string;
      GoodsDescription?: string;
      GoodsDescriptionRemark?: string;
      GrossWeight?: number;
      Volume?: number;
    },
    string
  >;
}
