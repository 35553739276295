import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 委托书表单-费用信息
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderPool_OrderPoolSupplierCost extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UnitPrice = 0;
        this.TotalPrice = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商Id
     */
    SupplierUserId?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 供应商
     */
    PreSupplierLabel?: string;
    /**
     * 费用名称
     */
    CostId?: string;
    /**
     * 费用名称
     */
    CostLabel?: string;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystemLabel?: string;
    /**
     * 单价
     */
    UnitPrice?: number;
    /**
     * 计价单位
     */
    FirstChargingUnitId?: string;
    /**
     * 计价单位
     */
    FirstChargingUnitLabel?: string;
    /**
     * 数量
     */
    FirstChargingNumber?: number;
    /**
     * 单项合计
     */
    TotalPrice?: number;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 委托书表单-费用信息-表单校验
 */
export const DtoFormOrderPool_OrderPoolSupplierCostRules = {
    PreSupplierLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    CostId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    CurrencySystemId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    UnitPrice: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    FirstChargingUnitId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 委托书表单-费用信息-设置项
 */
export const DtoFormOrderPool_OrderPoolSupplierCostMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "PreSupplierLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "CostId",
    Label: "费用名称",
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "计价单位",
    Customize: true,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "单项合计",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Customize: true,
  },
]

/**
 * 委托书表单-费用信息-设置项
 */
export const DtoFormOrderPool_OrderPoolSupplierCostMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "PreSupplierLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "CostId",
    Label: "费用名称",
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Customize: true,
  },
  {
    Prop: "UnitPrice",
    Label: "单价",
    Customize: true,
  },
  {
    Prop: "FirstChargingUnitId",
    Label: "计价单位",
    Customize: true,
  },
  {
    Prop: "FirstChargingNumber",
    Label: "数量",
    Customize: true,
  },
  {
    Prop: "TotalPrice",
    Label: "单项合计",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Customize: true,
  },
]
