import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoDetailRole_JoinCompanyTypeRole from "./DtoDetailRole_JoinCompanyTypeRole";
import DtoDetailRole_JoinRolePower from "./DtoDetailRole_JoinRolePower";
import UiGroup from "./UiGroup";

/**
 * 角色详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailRole extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.MinLevel = 0;
        this.JoinCompanyTypeRoles = [];
        this.Official = false;
        this.IsTest = false;
        this.JoinRolePowers = [];
        this.PowerIds = [];
        this.Disabled = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 最低级别
     */
    MinLevel?: number;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 公司身份
     */
    JoinCompanyTypeRoles?: DtoDetailRole_JoinCompanyTypeRole[];
    /**
     * 官方
     */
    Official?: boolean;
    /**
     * 测试
     */
    IsTest?: boolean;
    /**
     * 关联-角色/权限
     */
    JoinRolePowers?: DtoDetailRole_JoinRolePower[];
    /**
     * 权限Id
     */
    PowerIds?: string[];
    /**
     * 禁用
     */
    Disabled?: boolean;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 角色详细-表单校验
 */
export const DtoDetailRoleRules = {
}

/**
 * 角色详细-设置项
 */
export const DtoDetailRoleMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MinLevel",
    Label: "最低级别",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Type",
    Label: "公司类型",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "JoinCompanyTypeRoles",
    Label: "公司身份",
    Customize: true,
  },
  {
    Prop: "Official",
    Label: "官方",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "IsTest",
    Label: "测试",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "JoinRolePowers",
    Label: "关联-角色/权限",
    Customize: true,
  },
  {
    Prop: "Disabled",
    Label: "禁用",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Boolean,
    WhenTrue: "禁用",
    WhenFalse: "启用",
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
