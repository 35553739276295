import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPoolCodeSource, EnumPoolCodeSourceOption } from "./EnumPoolCodeSource";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import UiGroup from "./UiGroup";

/**
 * 装货港-仓库/堆场/码头-箱贴
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutBoxSticker extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsPoolCode = false;
        this.IsDetail = false;
        this.Visiable = false;
        this.Type = EnumOrderServiceType.DeparturePort;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 编号来源
     */
    CodeSource?: EnumPoolCodeSource;
    /**
     * 编号来源
     */
    CodeSourceName?: string;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 委托书是否有编号
     */
    IsPoolCode?: boolean;
    /**
     * 箱贴编号
     */
    BoxStickerCode?: string;
    /**
     * 交货地点
     */
    PlaceofDelivery?: string;
    /**
     * 合计件数
     */
    SumQuantity?: number;
    /**
     * 计价单位
     */
    ChargeUnit?: string;
    /**
     * 生产国
     */
    ProductionCountryId?: string;
    /**
     * 生产国
     */
    ProductionCountryName?: string;
    /**
     * 生产国
     */
    ProductionCountryCode?: string;
    /**
     * 是否详情
     */
    IsDetail?: boolean;
    /**
     * 是否显示弹出框
     */
    Visiable?: boolean;
    /**
     * 是否已编辑箱贴
     */
    IsEditBoxSticker?: boolean;
    /**
     * BookingNo
     */
    BookingNo?: string;
    /**
     * 委托书的编号
     */
    Code?: string;
    /**
     * 类型：装货港/目的港
     */
    Type?: EnumOrderServiceType;
}

/**
 * 装货港-仓库/堆场/码头-箱贴-表单校验
 */
export const DtoPutBoxStickerRules = {
}

/**
 * 装货港-仓库/堆场/码头-箱贴-设置项
 */
export const DtoPutBoxStickerMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CodeSource",
    Label: "编号来源",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BoxStickerCode",
    Label: "箱贴编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PlaceofDelivery",
    Label: "交货地点",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SumQuantity",
    Label: "合计件数",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ProductionCountryName",
    Label: "生产国",
    Span: 12,
    Customize: true,
  },
]
