
//引入组件
import VueDraggableResizable from 'vue-draggable-resizable/src/components/vue-draggable-resizable.vue';
//引入样式
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import { Vue, Options } from "vue-class-component";
import MiezzButton from '@/models/MiezzButton';
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import DtoOptionOrderMainBillElement from "@/models.machine/DtoOptionOrderMainBillElement";
import DtoDetailOrderMainBillTemplate from "@/models.machine/DtoDetailOrderMainBillTemplate";
import DtoFormOrderMainBillTemplate from "@/models.machine/DtoFormOrderMainBillTemplate";
import DtoFormOrderMainBillTemplate_OrderLadingBill from "@/models.machine/DtoFormOrderMainBillTemplate_OrderLadingBill";
import DtoFormOrderMainBillTemplate_OrderMainBillTemplate from "@/models.machine/DtoFormOrderMainBillTemplate_OrderMainBillTemplate";

import OSS from "ali-oss";
import { ElMessage, TabsPaneContext } from 'element-plus';
import OrderPartBillAppService from '@/apis.machine/OrderPartBillAppService';
@Options({
  components: {
    VueDraggableResizable
  },
})


export default class OrderBillPrint extends Vue {
    id?: string;
    orderPartBillId?: string;
    code?: string = "10";
    isPreview?: boolean = false;
    isBackgroundImg?: boolean = true;
    client?: OSS;
    activeIndex?: number = 0;
    random?: number = 0;
    activeName = "0";
    optOrderBillElement?: Array<DtoOptionOrderMainBillElement> = [];
    optLadingBillType?: Array<any> = [{
        Code: "10",
        Label: "正本提单/Original B/L",
    },
    {
        Code: "20",
        Label: "副本提单/Copy B/L",
    },
    {
        Code: "30",
        Label: "电放提单/Telex B/L",
    }];
    optOrderBillTamplate?: Array<DtoDetailOrderMainBillTemplate> = [];
    optOrderBillTamplateInit = null;
    
    created(): void {
        this.client = new OSS({
            region: "oss-cn-beijing",
            accessKeyId: "LTAIuFDB1NiUNXPQ",
            accessKeySecret: "ArIn2kiTFr53bdKEVDfuWjTfA65b9C",
            bucket: "test-eqicheng",
            cname: true,
            endpoint: "testfile.eqicheng.cn",
        });

        this.id = this.$route.params.id as string | undefined;
        this.orderPartBillId = this.$route.query.orderPartBillId as string | undefined;

        // 分单
        if (this.orderPartBillId) {
            OrderPartBillAppService.GetOrderPartBillElementByIdByOrderPartBillId(this.id, this.orderPartBillId)
            .then((r1) => {
                r1.data.Data?.forEach((ele, index) => {
                    ele.X = 0,
                    ele.Y = 40*index,
                    ele.W = 202,
                    ele.H = 34,
                    ele.InitY = 40*index
                })
                this.optOrderBillElement = r1.data.Data;

                OrderPartBillAppService.GetOrderPartBillTempalateByIdByOrderPartBillId(this.id, this.orderPartBillId)
                .then((r) => {
                    if (r.data.Data && r.data.Data.length > 0) {
                        r.data.Data.forEach((ele, index) => {
                            ele.Name = index.toString();
                            if (ele.File) ele.File = this.client!.signatureUrl(ele.File);

                            if (ele.Props) {
                                ele.Props.forEach(eleProp => {
                                    if (eleProp.X && eleProp.W)
                                        eleProp.Xd = eleProp.X - 30;
                                    if (eleProp.Y)
                                        eleProp.Yd = eleProp.Y - 13;

                                    if (this.optOrderBillElement && this.optOrderBillElement.length > 0)
                                        var item = this.optOrderBillElement.find(x => x.Code == eleProp.Code);

                                    if (item) 
                                        eleProp.Content = item.Content;
                                        
                                    if (eleProp.Code == "Logo" || eleProp.Code == "SignImg") {
                                        if (eleProp.Content) eleProp.Text = this.client!.signatureUrl(eleProp.Content);
                                    } else if (eleProp.Code == "GrossWeight") {
                                        if (eleProp.Content) eleProp.Text = eleProp.Content + " KGS";
                                    } else if (eleProp.Code == "Volume") {
                                        if (eleProp.Content) eleProp.Text = eleProp.Content + " CBM";
                                    } else {
                                        eleProp.Text = eleProp.Content;
                                    }

                                })
                            }
                        })
                    }
                    this.optOrderBillTamplate = r.data.Data;
                    this.optOrderBillTamplateInit = JSON.parse(JSON.stringify(this.optOrderBillTamplate));
                })
            })
            
        } 
        // 主单
        else {
            OrderMainBillAppService.GetOrderMainBillElementById(this.id)
            .then((r1) => {
                r1.data.Data?.forEach((ele, index) => {
                    ele.X = 0,
                    ele.Y = 40*index,
                    ele.W = 202,
                    ele.H = 34,
                    ele.InitY = 40*index
                })
                this.optOrderBillElement = r1.data.Data;

                OrderMainBillAppService.GetOrderMainBillTempalateById(this.id)
                .then((r) => {
                    if (r.data.Data && r.data.Data.length > 0) {
                        r.data.Data.forEach((ele, index) => {
                            ele.Name = index.toString();
                            if (ele.File) ele.File = this.client!.signatureUrl(ele.File);

                            if (ele.Props) {
                                ele.Props.forEach(eleProp => {
                                    if (eleProp.X && eleProp.W)
                                        eleProp.Xd = eleProp.X - 30;
                                    if (eleProp.Y)
                                        eleProp.Yd = eleProp.Y - 13;

                                    if (this.optOrderBillElement && this.optOrderBillElement.length > 0)
                                        var item = this.optOrderBillElement.find(x => x.Code == eleProp.Code);

                                    if (item) 
                                        eleProp.Content = item.Content;
                                    
                                    if (eleProp.Code == "Logo" || eleProp.Code == "SignImg") {
                                        if (eleProp.Content) eleProp.Text = this.client!.signatureUrl(eleProp.Content);
                                    } else if (eleProp.Code == "GrossWeight") {
                                        if (eleProp.Content) eleProp.Text = eleProp.Content + " KGS";
                                    } else if (eleProp.Code == "Volume") {
                                        if (eleProp.Content) eleProp.Text = eleProp.Content + " CBM";
                                    } else {
                                        eleProp.Text = eleProp.Content;
                                    }

                                })
                            }
                        })
                    }
                    this.optOrderBillTamplate = r.data.Data;
                    this.optOrderBillTamplateInit = JSON.parse(JSON.stringify(this.optOrderBillTamplate));
                })
            })

            
        }
        
    }

    // 重置
    onReset(): void {
        if (this.optOrderBillTamplate)
            this.optOrderBillTamplate = JSON.parse(JSON.stringify(this.optOrderBillTamplateInit));
    }

    onPrint(): void {
        this.isPreview = true;
        setTimeout(() => {
            this.isPreview = false;
        }, 1000)
    }

    onBack(): void {
        var url = this.$route.query.backUrl as string;
        if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
            this.$router.push(url);
        }
        else {
            history.go(-1);
        }
    }

    onSave(): void {
        var data = new DtoFormOrderMainBillTemplate();
        if (this.optOrderBillTamplate) {
            this.optOrderBillTamplate.forEach((element, index) => {
                var item = new DtoFormOrderMainBillTemplate_OrderMainBillTemplate();
                item.PageType = element.PageType;
                item.PageNumber = index;
                if (data.Templates) data.Templates.push(item);
                if (element.Props) {
                    element.Props.forEach(prop => {
                        var itemProp = new DtoFormOrderMainBillTemplate_OrderLadingBill()
                        itemProp.Id = prop.Id;
                        itemProp.Code = prop.Code;
                        itemProp.X = prop.X;
                        itemProp.Y = prop.Y;
                        itemProp.W = prop.W;
                        itemProp.H = prop.H;
                        if (itemProp.Code == "Logo" || itemProp.Code == "SignImg")
                            itemProp.Content = prop.Content;
                        else
                            itemProp.Content = prop.Text;
                        if (item.Props) item.Props.push(itemProp);
                    })
                }
            })
        }
        if (this.orderPartBillId) {
            OrderPartBillAppService.PostOrderPartBillTemplateByPageNumber(Number(this.activeName), data)
            .then(() => {
                ElMessage.success("保存成功");
            })
        } else {
            OrderMainBillAppService.PostOrderMainBillTemplateByPageNumber(Number(this.activeName), data)
            .then(() => {
                ElMessage.success("保存成功");
            })
        }
    }

    tabAdd(): void {
        if (this.optOrderBillTamplate) {
            var item = this.optOrderBillTamplate.firstOrDefault(it => it.PageType == "B");
            this.optOrderBillTamplate.push({
                Name: this.optOrderBillTamplate.length.toString(),
                PageType: "B",
                File: item ? item.File : "",
                Props: []
            })
            this.activeName = (this.optOrderBillTamplate.length - 1).toString();
            this.activeIndex = Number(this.activeName);
        }
        
    }

    tabRemove(): void {
        if (this.optOrderBillTamplate) {
            this.optOrderBillTamplate = this.optOrderBillTamplate.filter(it => it.Name != this.activeName);
            this.activeName = (this.optOrderBillTamplate.length - 1).toString();
            this.activeIndex = Number(this.activeName);
        }
    }

    tabClick(pane: TabsPaneContext, ev: Event): void {
        this.activeIndex = Number(pane.index);
    }

    onDrag(x: any, y: any, item: any) { //移动元素获取下一个x, y的值
        item.X = x;
        item.Y = y;
    }

    onDrag2(x: any, y: any, item: any) { //移动元素获取下一个x, y的值
        item.X = x + 30;
        item.Y = y + 13;
        item.Xd = x;
        item.Yd = y;
    }

    onDragstop(item: any, index: any) { //拖动结束-原始组件
        let activeIndex = this.activeIndex;
        if (item.X > 270 && item.Y > 35 && item.X < 830 && item.Y < 1125) {
            var itemCopy = JSON.parse(JSON.stringify(item));
            var it = {
                Text: itemCopy.Content,
                Content: itemCopy.Content,
                Name: itemCopy.Name,
                Code: itemCopy.Code,
                X: itemCopy.X - 305.63,
                Y: itemCopy.Y - 54,
                Xd: itemCopy.X - 305.63 - 30,
                Yd: itemCopy.Y - 54 - 13,
                W: itemCopy.W,
                H: itemCopy.H
            }
            if (item.Name == "logo" || item.Name == "签名") {
                it.Text = this.client!.signatureUrl(item.Content);
            } else if (item.Name == "毛重") {
                it.Text = item.Content + " KGS";
            } else if (item.Name == "体积") {
                it.Text = item.Content + " CBM";
            } else {
                it.Text = item.Content;
            }
            
            if (this.optOrderBillTamplate && activeIndex != null) {
                this.optOrderBillTamplate[activeIndex].Props?.push(it);
            }
            console.log("this.optOrderBillTamplate", this.optOrderBillTamplate)
        }
        item.X = 0;
        item.Y = item.InitY;
    }

    // 缩放元素获取下一个width, height的值
    onResizeCallback(handle: any, x: any, y: any, width: any, height: any, item: any) { 
        item.X = x;
        item.Y = y;
        item.W = width;
        item.H = height;
        item.Xd = x - 30;
    }
    // 拖动结束-预览组件
    onDragStopPreview(item: any, index: any) { 
        let activeIndex = this.activeIndex;
        if(item.X < -230.50 || item.Y < -19.50 || item.X > 820 || item.Y > 1067) {
            //超出范围就删除这个dom
            if (this.optOrderBillTamplate && activeIndex != null) {
                this.optOrderBillTamplate[activeIndex].Props?.splice(index, 1);
            }
        }
    }
    HTMLEncode(text: string): string {
        if (text){
            text = text.replace(/\r\n/g, "<br>");
            text = text.replace(/\n/g, "<br>");
            return text;
        } else{
            return "";
        }
    }
}
