import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCompanyBusinessCardBank_CompanyBusinessCardBankAccount from "./DtoFormCompanyBusinessCardBank_CompanyBusinessCardBankAccount";
import UiGroup from "./UiGroup";

/**
 * 公司名片-银行表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyBusinessCardBank extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.CompanyBusinessCardBankAccounts = [{"Default":true,"UiGroups":[]}];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司名片Id
     */
    CompanyBusinessCardId?: string;
    /**
     * 银行中文名称
     */
    NameZh?: string;
    /**
     * 银行英文名称
     */
    NameEn?: string;
    /**
     * 收款人中文名称
     */
    BeneficiaryNameZh?: string;
    /**
     * 收款人英文名称
     */
    BeneficiaryNameEn?: string;
    /**
     * 银行国际代码
     */
    SwiftCode?: string;
    /**
     * 收款银行代码
     */
    Code?: string;
    /**
     * 银行中文地址
     */
    AddressZh?: string;
    /**
     * 银行英文地址
     */
    AddressEn?: string;
    /**
     * 公司名片-银行-账号
     */
    CompanyBusinessCardBankAccounts?: DtoFormCompanyBusinessCardBank_CompanyBusinessCardBankAccount[];
}

/**
 * 公司名片-银行表单-表单校验
 */
export const DtoFormCompanyBusinessCardBankRules = {
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    BeneficiaryNameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    BeneficiaryNameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SwiftCode: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    AddressZh: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    AddressEn: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    CompanyBusinessCardBankAccounts: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
            type: "array",
        },
    ],
}

/**
 * 公司名片-银行表单-设置项
 */
export const DtoFormCompanyBusinessCardBankMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "银行中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NameEn",
    Label: "银行英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "BeneficiaryNameZh",
    Label: "收款人中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "BeneficiaryNameEn",
    Label: "收款人英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SwiftCode",
    Label: "银行国际代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "收款银行代码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AddressZh",
    Label: "银行中文地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AddressEn",
    Label: "银行英文地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "CompanyBusinessCardBankAccounts",
    Label: "公司名片-银行-账号",
    Customize: true,
  },
]
