
import AuthAppService from "@/apis.machine/AuthAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemLogierCompany, {
  DtoListItemLogierCompanyMiezzListItems,
} from "@/models.machine/DtoListItemLogierCompany";
import DtoListLogierCompany from "@/models.machine/DtoListLogierCompany";
import { EnumCompanyStatus } from "@/models.machine/EnumCompanyStatus";
import {
  EnumCompanyType,
  EnumCompanyTypeOption,
} from "@/models.machine/EnumCompanyType";
import { EnumJoinCompanyUserStatus } from "@/models.machine/EnumJoinCompanyUserStatus";
import Logier from "@/models.machine/Logier";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";

export default class IndexCompany extends Vue {
  logier?: Logier;
  modal = new MiezzModal();
  list = new MiezzPageList<any, DtoListItemLogierCompany, string>();
  companies?: DtoListLogierCompany;
  type?: number = 0;
  types: UiSelectOption<number>[] = [];

  created(): void {
    this.logier = CurrentLogier.GetLogier();

    this.modal.Title = "切换公司";
    this.modal.Width = "70%";
    this.modal.Type = MiezzModalType.Dialog;

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Items = DtoListItemLogierCompanyMiezzListItems.filter(
      (it) => it.Prop != "ServiceProvider"
    );
    this.list.Data = { Items: [] };
    this.list.HandleFormat = this.handleFormat;
    this.list.CheckRowButtonShow = this.checkRowButtonShow;
    this.list.RowButtons = [
      {
        Code: "switch",
        Title: "切换公司",
        Type: "primary",
        Icon: "switch",
        Size: "small",
      },
    ];
  }

  handleTabClick(): void {
    this.$nextTick(() => {
      if (this.type != EnumCompanyType.Customer) {
        this.list.Items = DtoListItemLogierCompanyMiezzListItems.filter(
          (it) => it.Prop != "ServiceProvider"
        );
      } else {
        this.list.Items = DtoListItemLogierCompanyMiezzListItems;
      }
      if (this.list.Data) {
        this.list.Data.Items = this.companies?.Items?.filter(
          (it) => it.Company?.Type == this.type
        );
      }
    });
  }

  handleShowCompanies(): void {
    AuthAppService.GetLogierCompanies().then((r) => {
      this.companies = r.data.Data;
      this.types = [];
      if (this.companies) {
        for (const type of this.companies.Types ?? []) {
          let option = EnumCompanyTypeOption.firstOrDefault(
            (it) => it.Value == type
          );
          if (this.logier?.CompanyType == type) {
            this.type = option?.Value;
          }
          if (option) {
            this.types.push(option);
          }
        }
      }
      this.handleTabClick();
      this.modal.Show = true;
    });
  }

  checkRowButtonShow(btn: MiezzButton, row: DtoListItemLogierCompany): boolean {
    if (
      btn.Code == "switch" &&
      (row.Current ||
        row.Company?.Status != EnumCompanyStatus.Normal ||
        row.Status != EnumJoinCompanyUserStatus.Normal)
    ) {
      return false;
    }
    return true;
  }

  handleFormat(
    item: MiezzListItem,
    row: DtoListItemLogierCompany
  ): string | undefined {
    switch (item.Prop) {
      case "Company":
        return `${row.Company?.NameZh ?? "暂无数据"}/${
          row.Company?.NameEn ?? "暂无数据"
        }`;
      case "ServiceProvider":
        return row.Company?.ServiceProviderCompanyId
          ? `${row.ServiceProvider?.NameZh ?? "暂无数据"}/${
              row.ServiceProvider?.NameEn ?? "暂无数据"
            }`
          : "暂无数据";
      case "JoinCompanyUserRoles":
        return row.JoinCompanyUserRoles?.map((it) => it.Role?.Name).join(",");
      default:
        break;
    }
    return undefined;
  }

  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemLogierCompany,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "switch":
        if (row.Current) {
          ElMessage.success("已是当前公司，无需重复切换");
          complete();
        } else {
          AuthAppService.SwitchCompanyByCompanyId(row.CompanyId).then((r) => {
            if (r.data.Data) {
              CurrentLogier.SetLogier(r.data.Data);
              location.replace("/");
            }
            ElMessage.success("切换成功");
            complete();
          });
        }
        break;
      default:
        break;
    }
  }
}
