import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoDetailPower from "../models.machine/DtoDetailPower";
import DtoFormPower from "../models.machine/DtoFormPower";
import DtoTreeSearchPower from "../models.machine/DtoTreeSearchPower";
import DtoTreePower from "../models.machine/DtoTreePower";
import UiTreeDrag from "../models.machine/UiTreeDrag";

/**
 * 权限
 * @description 自动生成的代码,请勿修改
 */
export default class PowerAppService {
    /**
     * 获取权限详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailPower>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailPower>> {
        const url = `/api/Power/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailPower>>(url);
    }

    /**
     * 添加权限
     * @param {DtoFormPower} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormPower): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Power/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除权限
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Power/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 获取树
     * @param {DtoTreeSearchPower} [search] 树-搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoTreePower[]>>} 树
     */
    static GetTree(search?: DtoTreeSearchPower): AxiosPromise<RESTfulResult<DtoTreePower[]>> {
        const url = `/api/Power/GetTree`;
        return service.post<RESTfulResult<DtoTreePower[]>>(url, search);
    }

    /**
     * 拖曳排序
     * @param {UiTreeDrag<string>} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiTreeDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Power/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
