import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderManifestVgm_OrderManifestVgmDetailProduct extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 报关Id
     */
    CustomsId?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 报关分提单号
     */
    BLNO?: string;
    /**
     * 集装箱拖车Id
     */
    ContainerTrailerId?: string;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 箱型
     */
    ContainerTypeId?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 英文品名
     */
    NameEn?: string;
    /**
     * HSCode (13位)
     */
    HsCode?: string;
    /**
     * 件数
     */
    Quantity?: number;
    /**
     * 包装单位
     */
    PackagesKind?: string;
    /**
     * 毛重(KGS)
     */
    GrossWeight?: number;
    /**
     * 体积(CBM)
     */
    Volume?: number;
    /**
     * 唛头
     */
    MarksNotes?: string;
    /**
     * UN Code(危)
     */
    DangerUnCode?: string;
    /**
     * 类别（危）
     */
    DangerCategory?: string;
}

/**
 * -表单校验
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailProductRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BLNO",
    Label: "报关分提单号",
  },
  {
    Prop: "ContainerNo",
    Label: "箱号",
  },
  {
    Prop: "SealNo",
    Label: "封号",
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
  },
  {
    Prop: "NameEn",
    Label: "英文品名",
  },
  {
    Prop: "HsCode",
    Label: "HSCode (13位)",
  },
  {
    Prop: "Quantity",
    Label: "件数",
  },
  {
    Prop: "PackagesKind",
    Label: "包装单位",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
  },
  {
    Prop: "DangerUnCode",
    Label: "UN Code(危)",
  },
  {
    Prop: "DangerCategory",
    Label: "类别（危）",
  },
]

/**
 * -设置项
 */
export const DtoDetailOrderManifestVgm_OrderManifestVgmDetailProductMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "BLNO",
    Label: "报关分提单号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文品名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "HsCode",
    Label: "HSCode (13位)",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Quantity",
    Label: "件数",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PackagesKind",
    Label: "包装单位",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DangerUnCode",
    Label: "UN Code(危)",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DangerCategory",
    Label: "类别（危）",
    Type: EnumMiezzFormItemType.Text,
  },
]
