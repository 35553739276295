import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchCustomhouse from "../models.machine/DtoSelectSearchCustomhouse";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchCustomhouse from "../models.machine/DtoPageListSearchCustomhouse";
import DtoPageListCustomhouse from "../models.machine/DtoPageListCustomhouse";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailCustomhouse from "../models.machine/DtoDetailCustomhouse";
import DtoFormCustomhouse from "../models.machine/DtoFormCustomhouse";

/**
 * 申报地海关
 * @description 自动生成的代码,请勿修改
 */
export default class CustomhouseAppService {
    /**
     * 获取申报地海关选择器
     * @param {DtoSelectSearchCustomhouse} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchCustomhouse): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Customhouse/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取申报地海关分页列表
     * @param {DtoPageListSearchCustomhouse} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCustomhouse>>} 
     */
    static Get(dto?: DtoPageListSearchCustomhouse): AxiosPromise<RESTfulResult<DtoPageListCustomhouse>> {
        const url = `/api/Customhouse/Get`;
        return service.get<RESTfulResult<DtoPageListCustomhouse>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Customhouse/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取申报地海关详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCustomhouse>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCustomhouse>> {
        const url = `/api/Customhouse/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCustomhouse>>(url);
    }

    /**
     * 新增/编辑申报地海关
     * @param {DtoFormCustomhouse} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormCustomhouse): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Customhouse/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除申报地海关
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Customhouse/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Customhouse/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Customhouse/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Customhouse/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Customhouse/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 同步申报地海关
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Customhouse/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

}
