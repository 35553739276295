
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import SupervisionModeAppService from "@/apis.machine/SupervisionModeAppService";
    import DtoDetailSupervisionMode, {
        DtoDetailSupervisionModeMiezzDetailItems,
    } from "@/models.machine/DtoDetailSupervisionMode";
    import SupervisionModeForm from "./SupervisionModeForm.vue";

    @Options({
        components: {
            SupervisionModeForm,
        },
    })
    export default class SupervisionModeDetail extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() id?: string;

        detail = new MiezzForm<DtoDetailSupervisionMode, string>();
        modalForm = new MiezzModal();

        /**创建时 */
        created(): void {
            //详细-数据-初始化
            this.detail.Data = new DtoDetailSupervisionMode();
            //详细-元素配置
            this.detail.Items = DtoDetailSupervisionModeMiezzDetailItems;
            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "delete",
                    Title: "删除",
                    Icon: "delete",
                    Type: "danger",
                    Message: "是否确认删除",
                    Size: "small",
                    PowerCode:"System_ParamList_SupervisionMode_Delete",
                },
            ];
            this.handleGet();
        }

        /**查询详细 */
        handleGet(): void {
            if (this.id) {
                SupervisionModeAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.detail.Data = r.data.Data;
                });
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "delete":
                    SupervisionModeAppService.Delete([this.id as string])
                        .then(() => {
                            this.handleDelete();
                            complete();
                        })
                        .catch(complete);
                    break;
                default:
                    break;
            }
        }

        /**提交表单 */
        @Emit()
        handleSubmit(): void {
            this.modalForm.Show = false;
            this.handleGet();
        }

        /**删除 */
        @Emit()
        handleDelete(): void {
            //
        }
    }
