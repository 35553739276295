
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamContainerTypeAppService from "@/apis.machine/ParamContainerTypeAppService";
import DtoFormParamContainerType, {
  DtoFormParamContainerTypeMiezzFormItems,
  DtoFormParamContainerTypeRules,
} from "@/models.machine/DtoFormParamContainerType";
import { List } from "echarts";
import DtoListItemParamContainerType_Repeat from "@/models.machine/DtoListItemParamContainerType_Repeat";

export default class ParamContainerTypeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamContainerType, string>();
  
  List: Array<DtoListItemParamContainerType_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamContainerType();
    //表单-校验
    this.form.Rules = DtoFormParamContainerTypeRules;
    this.form.Rules.NameZh = [
        {
          validator: (rule: any, value: string, callback: any) => {
              let NameZh:any = null;
              if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
                  NameZh = this.form.Data.NameZh.trim();
              }
              
              if (this.List.some(it => it.NameZh == NameZh)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
          },
          trigger: "blur",
        },
    ];
    this.form.Rules.Iso = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
              let Iso:any = null;
              if (this.form.Data.Iso != null && typeof (this.form.Data.Iso) != "undefined" && this.form.Data.Iso != "undefined" && this.form.Data.Iso != "") {
                  Iso = this.form.Data.Iso.toUpperCase().trim();
              }
              
              if (this.List.some(it => it.Iso == Iso)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
          },
          trigger: "blur",
        },
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamContainerTypeMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamContainerTypeAppService.GetList().then((r1) => {
      if (r1.data.Data) this.List = r1.data.Data;

      if (this.id) {
        ParamContainerTypeAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;

            var index = this.List.findIndex(it => { return it.NameZh == this.form.Data.NameZh && it.Iso == this.form.Data.Iso });
            this.List.splice(index, 1);
          }
        });
      }
    })
    
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamContainerTypeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  
  onBlur(): void {
        var optFullName: Array<string> = [];

        if (this.form.Data.Iso) {
          optFullName?.push(this.form.Data.Iso);
        }
        if (this.form.Data.Code) {
          optFullName?.push(this.form.Data.Code);
        }
        if (this.form.Data.NameZh) {
          optFullName?.push(this.form.Data.NameZh);
        }

        this.form.Data.FullName = optFullName.join("/");
    }

    onBlurNameZh(): void {
        if (this.form.Data.NameZh) {
            this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
        }
        this.onBlur();
    }

    onBlurNameEn(): void {
        if (this.form.Data.NameEn) {
            this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
            this.form.Data.NameEn = this.form.Data.NameEn.trim();
        }
    }

    onBlurIso(): void {
        if (this.form.Data.Iso) {
            this.form.Data.Iso = this.form.Data.Iso.toUpperCase();
            this.form.Data.Iso = this.form.Data.Iso.trim();
        }
        this.onBlur();
    }

    onBlurCode(): void {
        if (this.form.Data.Code) {
            this.form.Data.Code = this.form.Data.Code.toUpperCase();
            this.form.Data.Code = this.form.Data.Code.trim();
        }
        this.onBlur();
    }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
