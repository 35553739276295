import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_BackContainerTime extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 还柜时间
     */
    BackContainerTime?: Date;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_BackContainerTimeRules = {
    BackContainerTime: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_BackContainerTimeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "BackContainerTime",
    Label: "还柜时间",
    Customize: true,
  },
]
