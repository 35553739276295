import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 价格服务快捷方式表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceServiceShortcutName extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
}

/**
 * 价格服务快捷方式表单-表单校验
 */
export const DtoFormPriceServiceShortcutNameRules = {
    Name: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 价格服务快捷方式表单-设置项
 */
export const DtoFormPriceServiceShortcutNameMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
]
