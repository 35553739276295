import { EnumPriceAmountType, EnumPriceAmountTypeOption } from "./EnumPriceAmountType";
import DtoFormPriceBatchUpdateItem from "./DtoFormPriceBatchUpdateItem";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceBatchUpdate {
    constructor() {
        //无参构造函数
        this.Items = [];
    }

    [proName: string]: any;
    /**
     * 金额类型
     */
    AmountType?: EnumPriceAmountType;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 项
     */
    Items?: DtoFormPriceBatchUpdateItem[];
}

/**
 * -表单校验
 */
export const DtoFormPriceBatchUpdateRules = {
}
