import DtoCallbackPartSettlementItem from "./DtoCallbackPartSettlementItem";
import DtoCallbackPartSettlementResult from "./DtoCallbackPartSettlementResult";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackPartSettlement {
    constructor() {
        //无参构造函数
        this.IsInit = false;
        this.Item = [];
        this.Result = [];
    }

    [proName: string]: any;
    /**
     * 是否初始化
     */
    IsInit?: boolean;
    /**
     * 是否显示弹出框
     */
    Visiable?: boolean;
    /**
     * 
     */
    BillId?: string[];
    /**
     * 待核销的金额
     */
    Item?: DtoCallbackPartSettlementItem[];
    /**
     * 部分核销后分摊到账单的金额
     */
    Result?: DtoCallbackPartSettlementResult[];
}

/**
 * -表单校验
 */
export const DtoCallbackPartSettlementRules = {
}
