import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 项目详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailProject extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 数据库上下文名称
     */
    DbContextName?: string;
    /**
     * Web目录
     */
    WebPath?: string;
    /**
     * 前端目录
     */
    ClientPath?: string;
    /**
     * 前端打包工具
     */
    ClientPackTool?: string;
    /**
     * 前端打包命令
     */
    ClientPackCmd?: string;
    /**
     * Git仓库地址
     */
    GitUrl?: string;
}

/**
 * 项目详细-表单校验
 */
export const DtoDetailProjectRules = {
}

/**
 * 项目详细-设置项
 */
export const DtoDetailProjectMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "DbContextName",
    Label: "数据库上下文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "WebPath",
    Label: "Web目录",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientPath",
    Label: "前端目录",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientPackTool",
    Label: "前端打包工具",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ClientPackCmd",
    Label: "前端打包命令",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "GitUrl",
    Label: "Git仓库地址",
    Type: EnumMiezzFormItemType.Text,
  },
]
