import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 计价单位表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamChargeUnit extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
}

/**
 * 计价单位表单-表单校验
 */
export const DtoFormParamChargeUnitRules = {
    NameZh: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 计价单位表单-设置项
 */
export const DtoFormParamChargeUnitMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
]
