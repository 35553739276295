import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";

/**
 * 菜单小红点
 * @description 自动生成的代码,请勿修改
 */
export default class MenuRedDotAppService {
    /**
     * 获取有小红点的菜单代码
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 菜单代码
     */
    static Get(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/MenuRedDot/Get`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
