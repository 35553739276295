import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchRole from "../models.machine/DtoSelectSearchRole";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchRole from "../models.machine/DtoPageListSearchRole";
import DtoPageListRole from "../models.machine/DtoPageListRole";
import DtoDetailRole from "../models.machine/DtoDetailRole";
import DtoFormRole from "../models.machine/DtoFormRole";
import UiDrag from "../models.machine/UiDrag";

/**
 * 角色
 * @description 自动生成的代码,请勿修改
 */
export default class RoleAppService {
    /**
     * 获取角色选择器
     * @param {DtoSelectSearchRole} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchRole): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Role/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取角色分页列表
     * @param {DtoPageListSearchRole} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListRole>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchRole): AxiosPromise<RESTfulResult<DtoPageListRole>> {
        const url = `/api/Role/Get`;
        return service.get<RESTfulResult<DtoPageListRole>>(url, { params: dto });
    }

    /**
     * 获取角色详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailRole>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailRole>> {
        const url = `/api/Role/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailRole>>(url);
    }

    /**
     * 添加角色
     * @param {DtoFormRole} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormRole): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Role/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除角色
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Role/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Role/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
