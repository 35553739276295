import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoCallbackOrderOrPoolId from "../models.machine/DtoCallbackOrderOrPoolId";
import DtoSelectSearchOrdeCommon from "../models.machine/DtoSelectSearchOrdeCommon";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRequestOrderPlanBulletinBoard from "../models.machine/DtoRequestOrderPlanBulletinBoard";
import DtoListItemOrderPlanBulletinBoard from "../models.machine/DtoListItemOrderPlanBulletinBoard";
import DtoPageListSearchOrder from "../models.machine/DtoPageListSearchOrder";
import DtoPageListOrder from "../models.machine/DtoPageListOrder";
import DtoListItemOrderPoolFile from "../models.machine/DtoListItemOrderPoolFile";
import DtoRequestOrder_CreatePreStowageStackOrder from "../models.machine/DtoRequestOrder_CreatePreStowageStackOrder";
import DtoPageListSearchOrderStowageStackAllocate from "../models.machine/DtoPageListSearchOrderStowageStackAllocate";
import DtoPageListOrderStowageStackAllocate from "../models.machine/DtoPageListOrderStowageStackAllocate";
import DtoRequestOrder_CreateStowageStackOrder from "../models.machine/DtoRequestOrder_CreateStowageStackOrder";
import DtoDetailOrder from "../models.machine/DtoDetailOrder";
import DtoFormOrderCode from "../models.machine/DtoFormOrderCode";
import DtoFormOrder from "../models.machine/DtoFormOrder";
import DtoFormOrderPoolDefaultSupplier from "../models.machine/DtoFormOrderPoolDefaultSupplier";
import DtoDetailOrderSummary from "../models.machine/DtoDetailOrderSummary";
import DtoFormOrderUseThreeApiService from "../models.machine/DtoFormOrderUseThreeApiService";
import DtoHistory from "../models.machine/DtoHistory";
import DtoSelectSearchOrderClient from "../models.machine/DtoSelectSearchOrderClient";
import DtoSelectSearchOrderBookingNo from "../models.machine/DtoSelectSearchOrderBookingNo";
import DtoRequestOrderAssignCustomerService from "../models.machine/DtoRequestOrderAssignCustomerService";
import DtoDeleteOrder from "../models.machine/DtoDeleteOrder";
import DtoPutOrderLock from "../models.machine/DtoPutOrderLock";
import DtoDetailOrderProcess from "../models.machine/DtoDetailOrderProcess";
import DtoDetailOrderManifestVgm from "../models.machine/DtoDetailOrderManifestVgm";
import DtoFormOrderManifestVgm from "../models.machine/DtoFormOrderManifestVgm";
import DtoSelectOptionOrderManifestVgmCountryCode from "../models.machine/DtoSelectOptionOrderManifestVgmCountryCode";
import DtoDetailOrderSummaryTime from "../models.machine/DtoDetailOrderSummaryTime";
import DtoRequestOrderChart from "../models.machine/DtoRequestOrderChart";
import UiEChartsBar from "../models.machine/UiEChartsBar";
import UiEChartsPie from "../models.machine/UiEChartsPie";

/**
 * 订单
 * @description 自动生成的代码,请勿修改
 */
export default class OrderAppService {
    /**
     * 查询订单或委托书Id
     * @param {string} [code] 编号
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackOrderOrPoolId>>} 订单或委托书Id
     */
    static GetOrderOrPoolIdByCode(code?: string): AxiosPromise<RESTfulResult<DtoCallbackOrderOrPoolId>> {
        const url = `/api/Order/GetOrderOrPoolId/${code}`;
        return service.get<RESTfulResult<DtoCallbackOrderOrPoolId>>(url);
    }

    /**
     * 获取订单编号选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetCodes(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetCodes`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取提单号码选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetMasterBillNos(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetMasterBillNos`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取标记颜色选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetColors(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetColors`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取装货港选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetLoadingPorts(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetLoadingPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取目的港选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetDeliveryPorts(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetDeliveryPorts`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取最终目的地选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetDeliveryPlaces(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetDeliveryPlaces`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取船名选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetShipNames(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetShipNames`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取航次选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetVoyageNumbers(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetVoyageNumbers`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取航班选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetFlights(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetFlights`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取班次选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetRailwayNumbers(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetRailwayNumbers`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 获取承运人选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCarriers(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetCarriers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取委托书执行人选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetClientUsers(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetClientUsers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取客服选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetCustomerServices(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetCustomerServices`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取订单待办执行人选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetTaskUsers(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetTaskUsers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取业务员选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSalesmans(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetSalesmans`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取供应商选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetSuppliers(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetSuppliers`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取箱型选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetContainerTypeSelect(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetContainerTypeSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取箱号选择器
     * @param {DtoSelectSearchOrdeCommon} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetContainerNoSelect(dto?: DtoSelectSearchOrdeCommon): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/Order/GetContainerNoSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url, { params: dto });
    }

    /**
     * 查询计划看板
     * @param {DtoRequestOrderPlanBulletinBoard} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoListItemOrderPlanBulletinBoard[]>>} 
     */
    static GetPlanBulletinBoard(dto?: DtoRequestOrderPlanBulletinBoard): AxiosPromise<RESTfulResult<DtoListItemOrderPlanBulletinBoard[]>> {
        const url = `/api/Order/GetPlanBulletinBoard`;
        return service.get<RESTfulResult<DtoListItemOrderPlanBulletinBoard[]>>(url, { params: dto });
    }

    /**
     * 获取订单分页列表
     * @param {DtoPageListSearchOrder} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrder>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrder): AxiosPromise<RESTfulResult<DtoPageListOrder>> {
        const url = `/api/Order/Get`;
        return service.get<RESTfulResult<DtoPageListOrder>>(url, { params: dto });
    }

    /**
     * 获取附件
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<DtoListItemOrderPoolFile[]>>} 
     */
    static GetFilesById(id?: string): AxiosPromise<RESTfulResult<DtoListItemOrderPoolFile[]>> {
        const url = `/api/Order/GetFiles/${id}`;
        return service.get<RESTfulResult<DtoListItemOrderPoolFile[]>>(url);
    }

    /**
     * 创建预配订单
     * @param {DtoRequestOrder_CreatePreStowageStackOrder} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string>>} 订单Id
     */
    static CreatePreStowageStackOrder(dto?: DtoRequestOrder_CreatePreStowageStackOrder): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Order/CreatePreStowageStackOrder`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取调配分页列表
     * @param {DtoPageListSearchOrderStowageStackAllocate} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderStowageStackAllocate>>} 分页列表
     */
    static GetAllocatePageList(dto?: DtoPageListSearchOrderStowageStackAllocate): AxiosPromise<RESTfulResult<DtoPageListOrderStowageStackAllocate>> {
        const url = `/api/Order/GetAllocatePageList`;
        return service.get<RESTfulResult<DtoPageListOrderStowageStackAllocate>>(url, { params: dto });
    }

    /**
     * 创建配载订单
     * @param {DtoRequestOrder_CreateStowageStackOrder} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<string>>} 订单Id
     */
    static CreateStowageStackOrder(dto?: DtoRequestOrder_CreateStowageStackOrder): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Order/CreateStowageStackOrder`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取订舱信息
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrder>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrder>> {
        const url = `/api/Order/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrder>>(url);
    }

    /**
     * 导出订舱信息
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static ExportById(id?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Order/Export/${id}`;
        return service.post<RESTfulResult<string>>(url);
    }

    /**
     * 导出主单信息
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static ExportMainBillById(id?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/Order/ExportMainBill/${id}`;
        return service.post<RESTfulResult<string>>(url);
    }

    /**
     * 导出分单信息
     * @param {string} [id] 
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static ExportPartBillsById(id?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Order/ExportPartBills/${id}`;
        return service.post<RESTfulResult<string[]>>(url);
    }

    /**
     * 校验订单编号是否存在
     * @param {DtoFormOrderCode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static CheckCodeExist(dto?: DtoFormOrderCode): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/Order/CheckCodeExist`;
        return service.post<RESTfulResult<boolean>>(url, dto);
    }

    /**
     * 更新订单编号
     * @param {DtoFormOrderCode} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UpdateCode(dto?: DtoFormOrderCode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/UpdateCode`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 编辑订舱信息
     * @param {DtoFormOrder} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Put(dto?: DtoFormOrder): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/Put`;
        return service.put<RESTfulResult<any>>(url, dto);
    }

    /**
     * 保存默认供应商
     * @param {DtoFormOrderPoolDefaultSupplier} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostDefaultSupplier(dto?: DtoFormOrderPoolDefaultSupplier): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/PostDefaultSupplier`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取订单摘要信息
     * @param {string} [id] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderSummary>>} 订单摘要信息
     */
    static GetSummaryById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderSummary>> {
        const url = `/api/Order/GetSummary/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderSummary>>(url);
    }

    /**
     * 使用接口
     * @param {DtoFormOrderUseThreeApiService} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static UseThreeApiService(dto?: DtoFormOrderUseThreeApiService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/UseThreeApiService`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取舱务历史记录
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetBookingHistoriesById(id?: string): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/Order/GetBookingHistories/${id}`;
        return service.get<RESTfulResult<DtoHistory[]>>(url);
    }

    /**
     * 获取历史记录
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistoriesById(id?: string): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/Order/GetHistories/${id}`;
        return service.get<RESTfulResult<DtoHistory[]>>(url);
    }

    /**
     * 获取委托公司选择器
     * @param {DtoSelectSearchOrderClient} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetClientSelect(dto?: DtoSelectSearchOrderClient): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetClientSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 获取BookingNo选择器
     * @param {DtoSelectSearchOrderBookingNo} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetBookingNoSelect(dto?: DtoSelectSearchOrderBookingNo): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetBookingNoSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: dto });
    }

    /**
     * 指派客服
     * @param {DtoRequestOrderAssignCustomerService} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AssignCustomerService(dto?: DtoRequestOrderAssignCustomerService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/AssignCustomerService`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 取消订单
     * @param {DtoDeleteOrder} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(dto?: DtoDeleteOrder): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: dto });
    }

    /**
     * 锁定/解锁
     * @param {DtoPutOrderLock} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Lock(dto?: DtoPutOrderLock): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/Lock`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取提柜时间
     * @param {string} [orderId] 
     * @param {string} [poolId] 
     * @returns {AxiosPromise<RESTfulResult<Date>>} 
     */
    static GetCarryContainerTime(orderId?: string, poolId?: string): AxiosPromise<RESTfulResult<Date>> {
        const url = `/api/Order/GetCarryContainerTime`;
        const _querys: any = {};
        _querys.orderId = orderId
        _querys.poolId = poolId
        return service.get<RESTfulResult<Date>>(url, { params: _querys });
    }

    /**
     * 获取进程信息
     * @param {string} [id] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderProcess>>} 进程信息
     */
    static GetProcessById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderProcess>> {
        const url = `/api/Order/GetProcess/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderProcess>>(url);
    }

    /**
     * 获取舱单VGM信息
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderManifestVgm>>} 舱单VGM信息
     */
    static GetManifestVgmById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderManifestVgm>> {
        const url = `/api/Order/GetManifestVgm/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderManifestVgm>>(url);
    }

    /**
     * 编辑舱单VGM信息
     * @param {DtoFormOrderManifestVgm} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static PostManifestVgm(dto?: DtoFormOrderManifestVgm): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/Order/PostManifestVgm`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取报关分提单号选择器数据
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 报关分提单号选择器数据
     */
    static GetCustomsBlNosById(id?: string): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetCustomsBlNos/${id}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取箱号选择器数据
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 箱号选择器数据
     */
    static GetContainerNosById(id?: string): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/Order/GetContainerNos/${id}`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取英文品名选择器数据
     * @param {string} [poolId] 委托书Id
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 英文品名选择器数据
     */
    static GetProductNameEnsByPoolId(poolId?: string): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/Order/GetProductNameEns/${poolId}`;
        return service.get<RESTfulResult<string[]>>(url);
    }

    /**
     * 获取国家/地区代码选择器数据
     * @returns {AxiosPromise<RESTfulResult<DtoSelectOptionOrderManifestVgmCountryCode[]>>} 国家/地区代码选择器数据
     */
    static GetCountryCodes(): AxiosPromise<RESTfulResult<DtoSelectOptionOrderManifestVgmCountryCode[]>> {
        const url = `/api/Order/GetCountryCodes`;
        return service.get<RESTfulResult<DtoSelectOptionOrderManifestVgmCountryCode[]>>(url);
    }

    /**
     * 获取订单摘要日期
     * @param {string} [id] 订单Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderSummaryTime>>} 
     */
    static GetSummaryTimeById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderSummaryTime>> {
        const url = `/api/Order/GetSummaryTime/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderSummaryTime>>(url);
    }

    /**
     * 统计分析-订单-柱形图
     * @param {DtoRequestOrderChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsBar[]>>} 
     */
    static GetChartBar(dto?: DtoRequestOrderChart): AxiosPromise<RESTfulResult<UiEChartsBar[]>> {
        const url = `/api/Order/GetChartBar`;
        return service.get<RESTfulResult<UiEChartsBar[]>>(url, { params: dto });
    }

    /**
     * 统计分析-订单-饼状图
     * @param {DtoRequestOrderChart} [dto] 
     * @returns {AxiosPromise<RESTfulResult<UiEChartsPie[]>>} 
     */
    static GetChartPie(dto?: DtoRequestOrderChart): AxiosPromise<RESTfulResult<UiEChartsPie[]>> {
        const url = `/api/Order/GetChartPie`;
        return service.get<RESTfulResult<UiEChartsPie[]>>(url, { params: dto });
    }

}
