
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackSalerFormula {
    constructor() {
        //无参构造函数
        this.Visible = false;
    }

    [proName: string]: any;
    /**
     * 业务员主键
     */
    SalerId?: string;
    /**
     * 公式
     */
    Formula?: string;
    /**
     * 是否可见
     */
    Visible?: boolean;
}

/**
 * -表单校验
 */
export const DtoCallbackSalerFormulaRules = {
}
