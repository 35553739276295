import UiPageList from "./UiPageList";
import DtoListItemPrice from "./DtoListItemPrice";

/**
 * 价格分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListPrice extends UiPageList<DtoListItemPrice, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 价格表名称
     */
    TableName?: string;
    /**
     * 附加费分组Id
     */
    SurchargeGroupId?: string;
    /**
     * 附加费分组备注
     */
    SurchargeGroupRemark?: string;
}

/**
 * 价格分页列表-表单校验
 */
export const DtoPageListPriceRules = {
}
