import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 委托书-货量分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolCargoQuantity_Shape extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Quantity = 0;
        this.PackageQuantity = 0;
        this.TotalQuantity = 0;
        this.OccupiedQuantity = 0;
        this.SurplusQuantity = 0;
    }

    [proName: string]: any;
    /**
     * 产品名称
     */
    ProductsName?: string;
    /**
     * 长cm
     */
    Length?: number;
    /**
     * 宽cm
     */
    Width?: number;
    /**
     * 高cm
     */
    Height?: number;
    /**
     * 单件毛重KGS
     */
    GrossWeight?: number;
    /**
     * 单件体积CBM
     */
    Volume?: number;
    /**
     * 箱规数量
     */
    Quantity?: number;
    /**
     * 包装Id
     */
    PackageId?: string;
    /**
     * 包装数量
     */
    PackageQuantity?: number;
    /**
     * 数量
     */
    TotalQuantity?: number;
    /**
     * 已占用数量
     */
    OccupiedQuantity?: number;
    /**
     * 剩余数量
     */
    SurplusQuantity?: number;
    /**
     * 货量Id
     */
    CargoQunantityId?: string;
    /**
     * 送货数量
     */
    DeliveryQuantity?: number;
}

/**
 * 委托书-货量分页列表-项-表单校验
 */
export const DtoListItemOrderPoolCargoQuantity_ShapeRules = {
}

/**
 * 委托书-货量分页列表-项-设置项
 */
export const DtoListItemOrderPoolCargoQuantity_ShapeMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ProductsName",
    Label: "产品名称",
    MaxWidth: "90px",
  },
  {
    Prop: "Length",
    Label: "长cm",
  },
  {
    Prop: "Width",
    Label: "宽cm",
  },
  {
    Prop: "Height",
    Label: "高cm",
  },
  {
    Prop: "GrossWeight",
    Label: "单件毛重KGS",
    MaxWidth: "120px",
  },
  {
    Prop: "Volume",
    Label: "单件体积CBM",
    MaxWidth: "120px",
  },
  {
    Prop: "TotalQuantity",
    Label: "数量",
  },
  {
    Prop: "SurplusQuantity",
    Label: "剩余数量",
    MaxWidth: "90px",
  },
  {
    Prop: "DeliveryQuantity",
    Label: "送货数量",
    MaxWidth: "210px",
    Customize: true,
  },
]
