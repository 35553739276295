import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";

/**
 * 接口
 * @description 自动生成的代码,请勿修改
 */
export default class WebApiAppService {
    /**
     * 查询选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 接口选择器
     */
    static GetSelect(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/WebApi/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

}
