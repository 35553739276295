import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choose_menu = _resolveComponent("choose-menu")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'JoinPowerMenus')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "菜单",
            prop: "JoinPowerMenus"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_choose_menu, {
                menuIds: _ctx.form.Data.MenuIds,
                "onUpdate:menuIds": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.MenuIds) = $event)),
                menuFieldIds: _ctx.form.Data.MenuFieldIds,
                "onUpdate:menuFieldIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.MenuFieldIds) = $event)),
                tree: _ctx.menuTree,
                showCheck: true
              }, null, 8, ["menuIds", "menuFieldIds", "tree"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}