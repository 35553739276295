
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DtoFormCompany, {
  DtoFormCompanyRules,
} from "@/models.machine/DtoFormCompany";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import ParamCompanyCodeTypeAppService from "@/apis.machine/ParamCompanyCodeTypeAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import ParamTaxTypeAppService from "@/apis.machine/ParamTaxTypeAppService";
import ParamInvoiceTaxRateAppService from "@/apis.machine/ParamInvoiceTaxRateAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoDetailCompany from "@/models.machine/DtoDetailCompany";
import { EnumAuditStatus } from "@/models.machine/EnumAuditStatus";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import { ElMessage } from "element-plus";

export default class CompanyForm extends Vue {
  @Prop() type?: EnumCompanyType;
  @Prop() form!: MiezzForm<DtoFormCompany, string>;
  @Prop() companyTypes?: UiSelectOption<string>[];
  @Prop() onGet?: (model: DtoDetailCompany) => void;
  @Prop() nameEnAbbr?: boolean;

  id?: string;
  buttons: MiezzButton[] = [];

  companyCodeTypes?: UiSelectOption<string>[] = [];
  countries?: UiSelectOption<string>[] = [];
  taxTypes?: UiSelectOption<string>[] = [];
  invoiceTaxRates?: UiSelectOption<string>[] = [];
  serviceProviders?: DtoSelectOptionCompany[] = [];
  EnumAuditStatus = EnumAuditStatus;
  EnumCompanyType = EnumCompanyType;
  salesmans: UiSelectOption<string>[] = [];
  filterSalesmans: UiSelectOption<string>[] = [];

  created(): void {
    const logier = CurrentLogier.GetLogier();
    this.form.DefaultSpan = 12;
    //表单-数据-初始化
    this.form.Data = new DtoFormCompany();
    if (!this.type) {
      this.id = logier?.CompanyId;
      this.form.Data.Type = logier?.CompanyType;
    } else {
      this.id = this.$route.params.id as string;
      this.form.Data.Type = this.type;
    }
    if (logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      this.form.Data.ServiceProviderCompanyId = logier.CompanyId;
      this.form.Data.ServiceProviderCompanyNameZh = logier.CompanyNameZh;
      this.form.Data.ServiceProviderCompanyNameEn = logier.CompanyNameEn;
    }
    //表单-校验
    this.form.Rules = DtoFormCompanyRules;
    this.form.Rules.NameZh = [
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (!value) return callback(new Error("信息不能为空"));
          else if (value.length > 256)
            return callback(new Error("文本长度不能超过 256 个字符"));
          else {
            CompanyAppService.CheckCompanyName({
              CompanyType: this.form.Data.Type,
              ServiceProviderCompanyId: this.form.Data.ServiceProviderCompanyId,
              CompanyId: this.form.Data.Id,
              Name: this.form.Data.NameZh,
            }).then((r) => {
              if (r.data.Data) {
                return callback(
                  new Error(r.data.Extras ?? "信息重复，请重新填写")
                );
              }
              return callback();
            });
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameEnAbbr = [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value) {
            if (value.length > 64)
              return callback(new Error("文本长度不能超过 64 个字符"));
            else {
              CompanyAppService.CheckCompanyName({
                CompanyType: this.form.Data.Type,
                ServiceProviderCompanyId:
                  this.form.Data.ServiceProviderCompanyId,
                CompanyId: this.form.Data.Id,
                NameEnAbbr: this.form.Data.NameEnAbbr,
              }).then((r) => {
                if (r.data.Data) {
                  return callback(
                    new Error("该缩写已有服务商使用，请重新填写")
                  );
                }
                return callback();
              });
            }
          } else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.ServiceProviderCompanyId = [
      {
        validator: (rule: any, value: string, callback: any) => {
          MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
          return callback();
        },
      },
    ];
    if (this.type == EnumCompanyType.Customer) {
      this.form.Rules.SalesmanId = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }

    CompanyAppService.GetSelect({
      CompanyType: EnumCompanyType.ServiceProvider,
    }).then((r) => {
      this.serviceProviders = r.data.Data;
    });

    ParamCompanyCodeTypeAppService.GetSelect().then((r) => {
      this.companyCodeTypes = r.data.Data;
    });
    ParamCountryAppService.GetSelect().then((r) => {
      this.countries = r.data.Data;
      if (!this.id) {
        this.form.Data.ParamCountryId = this.countries?.firstOrDefault(
          (it) => it.Data.NameEn == "China"
        )?.Value;
      }
    });
    ParamTaxTypeAppService.GetSelect().then((r) => {
      this.taxTypes = r.data.Data;
    });
    ParamInvoiceTaxRateAppService.GetSelect().then((r) => {
      this.invoiceTaxRates = r.data.Data;
    });
    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["IdentityPermission_Salesman"],
    }).then((r) => {
      this.salesmans = r.data.Data ?? [];
      this.handleFilterSalesmans();
    });

    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "submit",
        Label: "保存",
        Icon: "select",
        Type: "text",
        Size: "small",
      },
    ];

    this.handleGet(this.id);
    CompanyAppService.GetSources().then((r) => {
      this.sources = r.data.Data ?? [];
    });
  }

  sources: UiSelectOption<string>[] = [];
  /**获取装运类型 */
  getSources(q: string, cb: any): void {
    cb(
      this.sources.filter((it) =>
        it.Label?.toUpperCase().contains(q.toUpperCase())
      )
    );
  }
  handleDeleteSource(select: UiSelectOption<string>, value: string): void {
    CompanyAppService.DeleteSourceBySource(select.Value).then(() => {
      const old = this.sources.firstOrDefault((it) => it.Label == value);
      if (old?.Value == select.Value) {
        this.form.Data.Source = undefined;
      } else {
        this.form.Data.Source = old?.Label;
      }
      this.sources = this.sources.filter((it) => it.Label != select.Label);
      ElMessage.success("历史记录已删除");
      return;
    });
  }

  handleFilterSalesmans(queryString?: string): void {
    if (this.salesmans && this.salesmans.length > 0) {
      if (queryString) {
        this.filterSalesmans = this.salesmans.filter((it) =>
          it.Label?.contains(queryString)
        );
        if (this.filterSalesmans && this.filterSalesmans.length > 10)
          this.filterSalesmans = this.filterSalesmans.slice(0, 10);
      } else if (this.form.Data.SalesmanId) {
        this.filterSalesmans = this.salesmans.filter(
          (it) => it.Value == this.form.Data.SalesmanId
        );
      } else {
        this.filterSalesmans = JSON.copy(this.salesmans).slice(0, 10);
      }
    }
  }

  /**查询表单数据 */
  handleGet(id?: string): void {
    if (id) {
      CompanyAppService.Get(id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          this.form.Data.CompanyType =
            this.form.Data.JoinCompanyTypes?.[0].CompanyTypeId;

          if (
            r.data.Data.Type != EnumCompanyType.Platform &&
            r.data.Data.AuthStatus == EnumAuditStatus.Success
          ) {
            for (const item of this.form.Items ?? []) {
              if (item.Prop == "NameZh" || item.Prop == "Code") {
                item.Disabled = true;
              }
            }
          }
          if (this.onGet) {
            this.onGet(this.form.Data);
          }
          this.handleFilterSalesmans();
        }
      });
    }
  }

  querySearchNameZh(queryString: string, cb: any): void {
    if (queryString) {
      CompanyAppService.GetSelect({
        Keywords: queryString,
        QuerySkyEye: true,
      }).then((r) => {
        cb(r.data.Data);
      });
    }
  }

  handleServiceProviderCompanyIdChange(): void {
    const serviceProvider = this.serviceProviders?.firstOrDefault(
      (it) => it.Id == this.form.Data.ServiceProviderCompanyId
    );
    this.form.Data.ServiceProviderCompanyNameZh = serviceProvider?.NameZh;
    this.form.Data.ServiceProviderCompanyNameEn = serviceProvider?.NameEn;
  }

  handleCompanyTypeChange(): void {
    this.form.Data.JoinCompanyTypes = [
      { CompanyTypeId: this.form.Data.CompanyType },
    ];
  }

  handleNameZhSelect(item: DtoSelectOptionCompany): void {
    if (item.SkyEyeId) {
      CompanyAppService.QueryCompanyBySkyEyeById(item.SkyEyeId).then((r) => {
        if (r.data.Data) {
          this.form.Data.NameEn = r.data.Data.NameEn;
          this.form.Data.Email = r.data.Data.Email;
          this.form.Data.Url = r.data.Data.Url;
          this.form.Data.CertCode = r.data.Data.CertCode;
          this.form.Data.RegisteredAddressZh = r.data.Data.RegisteredAddressZh;
          this.form.Data.Contacts = r.data.Data.Contacts;
        }
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    let listUrl = this.$route.path.substring(
      0,
      this.id
        ? this.$route.path.indexOf("/edit")
        : this.$route.path.indexOf("/add")
    );
    if (!this.type) {
      listUrl += "/detail";
    }

    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.Post(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
