import UiSelectOption from "@/models.machine/UiSelectOption";

export default class MiezzButton {
    constructor() {
        this.Type = "primary";
        this.Size = "small";
    }
    //不用判断条数
    IsNumber?: boolean;
    /*徽章数量 */
    BadgeNumber?: number;
    /*是否需要下拉菜单 */
    IsDropdown?: boolean;
    /*默认显示蓝色字体 */
    IsBlue?: boolean;
    /**编码 */
    Code?: string;
    /**标签 */
    Label?: string;
    /**标题 */
    Title?: string;
    /**类型 */
    Type?: string;
    RightIcon?: boolean;
    /**图标 */
    Icon?: string;
    /**阿里巴巴图标 */
    MiezzIcon?: string;
    /**大小 */
    Size?: string;
    /**宽度 */
    Width?: string;
    /**高度 */
    Height?: string;
    /**确认消息 */
    Message?: string;
    DisabledConfirm?: boolean;
    /**true使用确认框 false使用气泡框 */
    MessageBox?: boolean;
    /**确认消息宽度 */
    MessageWidth?: string;
    /**权限编码 */
    PowerCode?: string;
    /**无需批量选择 */
    NoNeedBatchSelect?: boolean;
    /**隐藏 */
    Hide?: boolean = true;
    /**呼吸灯 */
    Breathe?: boolean;
    /**选中 */
    Check?: boolean = false;
    /**选中属性 */
    CheckProp?: string;
    /**选项 */
    Options?: MiezzButton[];
    /**指令 */
    Command?: any;
    /**文件上传 */
    FileUpload?: boolean;
    /**接受文件类型 */
    Accept?: string;
    /**加载 */
    Loading?: boolean = false
    /**取消点击事件 */
    CancelClickEvent?: boolean
    /**取消右边距 */
    CancelMarginRight?: boolean
    /**取消内边距 */
    CancelPadding?: boolean
    Sort?: number = 0
    /**禁用 */
    Disabled?: boolean = false
    Placement?: string;
    Active?: boolean
    Plain?: boolean
    PdfName?: string
    Color?: string
}