import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * HSCode
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchHsCode extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 是否选择HsCode
     */
    IsSelectCode?: boolean;
    /**
     * 商品编码
     */
    Code?: string;
}

/**
 * HSCode-表单校验
 */
export const DtoPageListSearchHsCodeRules = {
}

/**
 * HSCode-动态搜索配置项
 */
export const DtoPageListSearchHsCodeDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_Code",
    Label: "商品编码",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
]
