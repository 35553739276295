
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import {
  EnumLadingBillType,
  EnumLadingBillTypeOption,
} from "@/models.machine/EnumLadingBillType";
import {
  EnumLadingBillNumber,
  EnumLadingBillNumberOption,
} from "@/models.machine/EnumLadingBillNumber";
export default class OrderFormLadingBill extends Vue {
  @Model() form!: MiezzForm<
    {
      LadingBillType?: EnumLadingBillType;
      LadingBillNumber?: EnumLadingBillNumber;
      LadingBillRemark?: string;
    },
    string
  >;
  @Prop() reds: string[] = [];
  @Prop() requireds: string[] = [];

  EnumLadingBillTypeOption = EnumLadingBillTypeOption;
  EnumLadingBillNumberOption = EnumLadingBillNumberOption;

  created(): void {
    if (this.requireds && this.requireds.contains("LadingBillType")) {
      this.form.Rules.LadingBillType = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }
  }
}
