import UiPageList from "./UiPageList";
import DtoListItemParamAuditLog from "./DtoListItemParamAuditLog";

/**
 * 参数记录表分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListParamAuditLog extends UiPageList<DtoListItemParamAuditLog, string> {
    constructor() {
        //无参构造函数
        super()
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
}

/**
 * 参数记录表分页列表-表单校验
 */
export const DtoPageListParamAuditLogRules = {
}
