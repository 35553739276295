import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFormOrderDepartureCustoms from "../models.machine/DtoFormOrderDepartureCustoms";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoDetailOrderDepartureCustoms from "../models.machine/DtoDetailOrderDepartureCustoms";
import DtoRequestProductPackage from "../models.machine/DtoRequestProductPackage";
import DtoFormOrderDepartureCustoms_OrderDepartureCustomsProduct from "../models.machine/DtoFormOrderDepartureCustoms_OrderDepartureCustomsProduct";
import DtoDetailOrderPool from "../models.machine/DtoDetailOrderPool";
import DtoPageListSearchOrderDepartureCustoms from "../models.machine/DtoPageListSearchOrderDepartureCustoms";
import DtoPageListDocument from "../models.machine/DtoPageListDocument";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoRequestCustomsExport from "../models.machine/DtoRequestCustomsExport";
import DtoImportDepartureCustomsProduct from "../models.machine/DtoImportDepartureCustomsProduct";

/**
 * 装货港-报关
 * @description 自动生成的代码,请勿修改
 */
export default class OrderDepartureCustomsAppService {
    /**
     * 系统生成
     * @param {DtoFormOrderDepartureCustoms} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormOrderDepartureCustoms): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureCustoms/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 获取报关资料详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderDepartureCustoms>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderDepartureCustoms>> {
        const url = `/api/OrderDepartureCustoms/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderDepartureCustoms>>(url);
    }

    /**
     * 通过包装获得报关资料产品
     * @param {DtoRequestProductPackage} [dto] 
     * @returns {AxiosPromise<RESTfulResult<DtoFormOrderDepartureCustoms_OrderDepartureCustomsProduct[]>>} 
     */
    static PostProduct(dto?: DtoRequestProductPackage): AxiosPromise<RESTfulResult<DtoFormOrderDepartureCustoms_OrderDepartureCustomsProduct[]>> {
        const url = `/api/OrderDepartureCustoms/PostProduct`;
        return service.post<RESTfulResult<DtoFormOrderDepartureCustoms_OrderDepartureCustomsProduct[]>>(url, dto);
    }

    /**
     * 获得委托书
     * @param {string} [id] 装货港关务的主键
     * @returns {AxiosPromise<RESTfulResult<DtoDetailOrderPool>>} 
     */
    static GetPoolById(id?: string): AxiosPromise<RESTfulResult<DtoDetailOrderPool>> {
        const url = `/api/OrderDepartureCustoms/GetPool/${id}`;
        return service.get<RESTfulResult<DtoDetailOrderPool>>(url);
    }

    /**
     * 获取物流订单的单证分页列表
     * @param {DtoPageListSearchOrderDepartureCustoms} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListDocument>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderDepartureCustoms): AxiosPromise<RESTfulResult<DtoPageListDocument>> {
        const url = `/api/OrderDepartureCustoms/Get`;
        return service.get<RESTfulResult<DtoPageListDocument>>(url, { params: dto });
    }

    /**
     * 获取发货人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetConsignor(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureCustoms/GetConsignor`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取收货人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetConsignee(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureCustoms/GetConsignee`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取创建人集合
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetCreater(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/OrderDepartureCustoms/GetCreater`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 导出报关单
     * @param {DtoRequestCustomsExport} [dto] 参数
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static Export(dto?: DtoRequestCustomsExport): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/OrderDepartureCustoms/Export`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 导入报关资料中的数据
     * @param {string} [id] 委托书主键
     * @returns {AxiosPromise<RESTfulResult<DtoImportDepartureCustomsProduct[]>>} 
     */
    static GetDepartureCustomsProductById(id?: string): AxiosPromise<RESTfulResult<DtoImportDepartureCustomsProduct[]>> {
        const url = `/api/OrderDepartureCustoms/GetDepartureCustomsProduct/${id}`;
        return service.get<RESTfulResult<DtoImportDepartureCustomsProduct[]>>(url);
    }

}
