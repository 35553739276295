import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailJoinCompanyUser_JoinCompanyUserGroup from "./DtoDetailJoinCompanyUser_JoinCompanyUserGroup";
import DtoListItemJoinCompanyUser_User from "./DtoListItemJoinCompanyUser_User";
import DtoListItemJoinCompanyUser_Company from "./DtoListItemJoinCompanyUser_Company";
import DtoListItemJoinCompanyUser_JoinCompanyUserRole from "./DtoListItemJoinCompanyUser_JoinCompanyUserRole";
import { EnumJoinCompanyUserStatus, EnumJoinCompanyUserStatusOption } from "./EnumJoinCompanyUserStatus";
import { EnumUserStatus, EnumUserStatusOption } from "./EnumUserStatus";

/**
 * 关联-公司/用户分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemJoinCompanyUser extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Level = 0;
        this.Trusteeship = false;
        this.DisabledReadSupplier = false;
        this.Status = EnumJoinCompanyUserStatus.Normal;
    }

    [proName: string]: any;
    /**
     * 姓名
     */
    Name?: string;
    /**
     * 部门
     */
    JoinCompanyUserGroups?: DtoDetailJoinCompanyUser_JoinCompanyUserGroup[];
    /**
     * 职务
     */
    Job?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 用户
     */
    User?: DtoListItemJoinCompanyUser_User;
    /**
     * 登录账号
     */
    UserPhoneNumber?: string;
    /**
     * 是否托管
     */
    Trusteeship?: boolean;
    /**
     * 隶属公司/所属服务商
     */
    Company?: DtoListItemJoinCompanyUser_Company;
    /**
     * 职能
     */
    JoinCompanyUserRoles?: DtoListItemJoinCompanyUser_JoinCompanyUserRole[];
    /**
     * 供应商信息
     */
    DisabledReadSupplier?: boolean;
    /**
     * 员工状态
     */
    Status?: EnumJoinCompanyUserStatus;
    /**
     * 用户状态
     */
    UserStatus?: EnumUserStatus;
}

/**
 * 关联-公司/用户分页列表-项-表单校验
 */
export const DtoListItemJoinCompanyUserRules = {
}

/**
 * 关联-公司/用户分页列表-项-设置项
 */
export const DtoListItemJoinCompanyUserMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "姓名",
  },
  {
    Prop: "JoinCompanyUserGroups",
    Label: "部门",
    Customize: true,
  },
  {
    Prop: "Job",
    Label: "职务",
  },
  {
    Prop: "Level",
    Label: "职级",
  },
  {
    Prop: "UserPhoneNumber",
    Label: "登录账号",
  },
  {
    Prop: "Trusteeship",
    Label: "是否托管",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "是",
    WhenFalse: "否",
  },
  {
    Prop: "Company",
    Label: "隶属公司/所属服务商",
  },
  {
    Prop: "JoinCompanyUserRoles",
    Label: "职能",
  },
  {
    Prop: "DisabledReadSupplier",
    Label: "供应商信息",
    Pipe: EnumPipe.Boolean,
    WhenTrue: "不可见",
    WhenFalse: "可见",
  },
  {
    Prop: "Status",
    Label: "员工状态",
    Pipe: EnumPipe.Enum,
    Options: EnumJoinCompanyUserStatusOption,
  },
  {
    Prop: "UserStatus",
    Label: "用户状态",
    Pipe: EnumPipe.Enum,
    Options: EnumUserStatusOption,
  },
]
