import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'CompanyBusinessCardBankAccounts')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.CompanyBusinessCardBankAccounts, (row, ri) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: ri,
                  style: {"width":"100%","margin-bottom":"-17px"}
                }, [
                  _createVNode(_component_el_form_item, {
                    prop: `CompanyBusinessCardBankAccounts[${ri}].ParamCurrencySystemId`,
                    rules: 
              _ctx.form.Rules.CompanyBusinessCardBankAccounts_ParamCurrencySystemId
            ,
                    style: {"display":"inline-block","width":"150px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_select, {
                        modelValue: row.ParamCurrencySystemId,
                        "onUpdate:modelValue": ($event: any) => ((row.ParamCurrencySystemId) = $event),
                        options: _ctx.currencySystems
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]),
                  _createVNode(_component_el_form_item, {
                    prop: `CompanyBusinessCardBankAccounts[${ri}].Account`,
                    rules: _ctx.form.Rules.CompanyBusinessCardBankAccounts_Account,
                    style: {"display":"inline-block","width":"calc(100% - 204px)","margin":"0px 5px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.Account,
                        "onUpdate:modelValue": ($event: any) => ((row.Account) = $event)
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "rules"]),
                  _createVNode(_component_miezz_button, {
                    model: _ctx.btnDelete,
                    onHandleClick: (arg1, arg2) => _ctx.handleDeleteAccount(arg1, ri, arg2)
                  }, null, 8, ["model", "onHandleClick"])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_miezz_button, {
                  model: _ctx.btnAdd,
                  onHandleClick: _ctx.handleClick
                }, null, 8, ["model", "onHandleClick"])
              ])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}