import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListItemCompanyCURPM extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.User = "";
        this.Power = "";
        this.Menu = "";
    }

    [proName: string]: any;
    /**
     * 公司
     */
    CompanyName?: string;
    /**
     * 用户名称
     */
    UserName?: string;
    /**
     * 手机号码
     */
    UserPhoneNumber?: string;
    /**
     * 用户
     */
    User?: string;
    /**
     * 职能
     */
    RoleName?: string;
    /**
     * 权限名称
     */
    PowerName?: string;
    /**
     * 权限父名称
     */
    PowerParentName?: string;
    /**
     * 权限祖名称
     */
    PowerGrandpaName?: string;
    /**
     * 权限
     */
    Power?: string;
    /**
     * 菜单名称
     */
    MenuName?: string;
    /**
     * 菜单父名称
     */
    MenuParentName?: string;
    /**
     * 菜单祖名称
     */
    MenuGrandpaName?: string;
    /**
     * 菜单代码
     */
    MenuCode?: string;
    /**
     * 菜单
     */
    Menu?: string;
}

/**
 * -表单校验
 */
export const DtoPageListItemCompanyCURPMRules = {
}

/**
 * -设置项
 */
export const DtoPageListItemCompanyCURPMMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CompanyName",
    Label: "公司",
  },
  {
    Prop: "User",
    Label: "用户",
  },
  {
    Prop: "RoleName",
    Label: "职能",
  },
  {
    Prop: "Power",
    Label: "权限",
  },
  {
    Prop: "Menu",
    Label: "菜单",
  },
]
