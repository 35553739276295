
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ProjectAppService from "@/apis.machine/ProjectAppService";
import DtoDetailProjectVersion, {
  DtoDetailProjectVersionMiezzDetailItems,
} from "@/models.machine/DtoDetailProjectVersion";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailProjectVersion_GitLog, {
  DtoDetailProjectVersion_GitLogMiezzListItems,
} from "@/models.machine/DtoDetailProjectVersion_GitLog";
import router from "@/router";

export default class ProjectVersionDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailProjectVersion, string>();
  modalForm = new MiezzModal();
  list = new MiezzPageList<any, DtoDetailProjectVersion_GitLog, string>();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailProjectVersion();
    //详细-元素配置
    this.detail.Items = DtoDetailProjectVersionMiezzDetailItems;

    this.modelValue.Width = "80%";
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [];

    this.list.Fix = false;
    this.list.Title = "GIT日志";
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledPagination = false;
    this.list.EnabledRadioSearchAll = false;
    this.list.EnabledSelect = false;
    this.list.Items = DtoDetailProjectVersion_GitLogMiezzListItems;
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      ProjectAppService.GetVersionById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          if (this.list.Data) this.list.Data.Items = r.data.Data.GitLogs;
        }
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoDetailProjectVersion_GitLog,
    complete: () => void
  ): void {
    switch (btn.Code) {
      //详细
      case "detail":
        window.open(
          `http://git.xsxxjs.com:3000/sunpengfei/Miezz/commit/${row.Id}`,
          "_blank"
        );
        complete();
        break;
      default:
        break;
    }
  }
}
