import UiSelectOption from "./UiSelectOption";

/**
 * 称重方式
 * @description 自动生成的代码,请勿修改
 */
export enum EnumWeighingMethod {
    /**
     * SM1
     */
    SM1 = 1,
    /**
     * SM2
     */
    SM2 = 2,
}

/**
 * 称重方式-选项
 */
export const EnumWeighingMethodOption: UiSelectOption<number>[] = [
    { Label: "SM1", Value: 1, Data: "SM1" },
    { Label: "SM2", Value: 2, Data: "SM2" },
];
