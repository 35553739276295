
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import AuditLogAppService from "@/apis.machine/AuditLogAppService";
import DtoDetailAuditLog, {
  DtoDetailAuditLogMiezzDetailItems,
} from "@/models.machine/DtoDetailAuditLog";

@Options({
  components: {},
})
export default class AuditLogDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailAuditLog, string>();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailAuditLog();
    //详细-元素配置
    this.detail.Items = DtoDetailAuditLogMiezzDetailItems;
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      AuditLogAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }
}
