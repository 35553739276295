
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import DtoListItemOrderTask, {
  DtoListItemOrderTaskMiezzListItems,
} from "@/models.machine/DtoListItemOrderTask";
import OrderTaskAppService from "@/apis.machine/OrderTaskAppService";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import { EnumOrderTaskStatus } from "@/models.machine/EnumOrderTaskStatus";
import moment from "moment";
import { EnumOrderTaskType } from "@/models.machine/EnumOrderTaskType";
export default class OrderTask extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() url?: string;
  @Prop() listPower?: string;
  @Prop() userPower?: string;
  @Prop() id?: string;
  @Prop() cancel?: string;

  onlyMy = true;

  logier?: Logier;
  powers: string[] = [];

  tasks: DtoListItemOrderTask[] = [];
  list = new MiezzPageList<any, DtoListItemOrderTask, string>();
  EnumOrderTaskStatus = EnumOrderTaskStatus;
  modalAssign = new MiezzModal(MiezzModalType.Dialog);
  modalAssignData: {
    Ids?: string[];
    UserId?: string;
    UserLabel?: string;
  } = {
    Ids: [],
  };
  modalAssignRules = {
    UserId: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ],
  };
  users: UiSelectOption<string>[] = [];

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
    });

    this.modelValue.Title = "待办";
    this.modelValue.Top = "0px";
    this.modelValue.Height = "100%";
    this.modelValue.HandleClick = this.handleClick;

    this.list.Fix = false;
    this.list.MaxHeight = "100%";
    this.list.HideCard = true;
    this.list.EnableIndex = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledPagination = false;
    this.list.Items = DtoListItemOrderTaskMiezzListItems;
    this.list.Data = {
      Items: [],
    };
    this.list.HandleSelectable = (row) => {
      return row.Status != EnumOrderTaskStatus.Completed;
    };

    this.modalAssign.Title = "指派执行人";
    this.modalAssign.Height = "100px";
    this.modalAssign.Width = "700px";
    this.modalAssign.HandleClick = this.handleClick;
    this.modalAssign.Buttons = [
      {
        Code: "assign-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  @Watch("modelValue.Show")
  onShowChanged(): void {
    if (this.modelValue.Show) {
      this.handleGet();
    }
  }

  handleGet(): void {
    this.list.Data = {
      Items: [],
    };
    if (this.id) {
      OrderTaskAppService.GetByPoolOrOrderId(this.id).then((r) => {
        JoinCompanyUserAppService.GetSelect({
          CompanyId: this.logier?.CompanyId,
          PowerCodes: r.data.Data?.any(
            (it) =>
              it.TypeName == "AuditPool" &&
              it.Status == EnumOrderTaskStatus.Executing
          )
            ? ["OrderManagement_Pool_Audit"]
            : [this.userPower ?? ""],
        }).then((rr) => {
          this.users = (rr.data.Data ?? []).map((it) => {
            return {
              Value: it.Value,
              Label: it.Data.NamePhoneNumberGroupJobInfo,
            };
          });
          for (const item of r.data.Data ?? []) {
            item.UserLabel =
              this.users.firstOrDefault((it) => it.Value == item.UserId)
                ?.Label ?? item.UserLabel;
          }
          this.tasks = r.data.Data ?? [];
          this.onOnlyMyChanged();
          this.modelValue.Buttons = [
            {
              Code: "assign-users",
              Label: "批量指派",
              Type: "primary",
              Size: "small",
            },
          ];
        });
      });
    }
  }

  @Watch("onlyMy")
  onOnlyMyChanged(): void {
    console.log(this.onlyMy);
    this.list.Data = {
      Items: this.onlyMy
        ? this.tasks.filter((it) => it.UserId == this.logier?.CompanyUserId)
        : this.tasks,
    };
  }

  handleRowCommand(prop: string, row: DtoListItemOrderTask): void {
    switch (prop) {
      case "Status":
        if (row.Status == EnumOrderTaskStatus.Executing) {
          if (row.Type == EnumOrderTaskType.AddPool) {
            CurrentLogier.LinkWithBack(
              `${row.Link}&OrderCode=${row.Order?.Code}`
            );
          } else if (
            (row.Pool?.Order?.PoolCount ?? 0) > 1 &&
            (row.Type == EnumOrderTaskType.AddDestinationPortExpress ||
              row.Type == EnumOrderTaskType.FeedbackDestinationPortExpress ||
              row.Type == EnumOrderTaskType.AddDestinationPortTrunk ||
              row.Type == EnumOrderTaskType.FeedbackDestinationPortTrunk ||
              row.Type == EnumOrderTaskType.AddDestinationPortWarehouse ||
              row.Type ==
                EnumOrderTaskType.FeedbackDestinationPortWarehouseWarehousing ||
              row.Type ==
                EnumOrderTaskType.FeedbackDestinationPortWarehouseExWarehouse)
          ) {
            let link = row.Link ?? "";
            if (
              row.Type == EnumOrderTaskType.AddDestinationPortExpress ||
              row.Type == EnumOrderTaskType.FeedbackDestinationPortExpress
            ) {
              link = `/order/destination-port-express/${row.Pool?.OrderId}`;
            } else if (
              row.Type == EnumOrderTaskType.AddDestinationPortTrunk ||
              row.Type == EnumOrderTaskType.FeedbackDestinationPortTrunk
            ) {
              link = `/order/destination-port-trunk/${row.Pool?.OrderId}`;
            } else if (
              row.Type == EnumOrderTaskType.AddDestinationPortWarehouse ||
              row.Type ==
                EnumOrderTaskType.FeedbackDestinationPortWarehouseWarehousing ||
              row.Type ==
                EnumOrderTaskType.FeedbackDestinationPortWarehouseExWarehouse
            ) {
              link = `/order/destination/wharf/${row.Pool?.OrderId}`;
            }
            var and = link.contains("?") ? "&" : "?";
            link += `${and}task=${row.TypeName}`;
            CurrentLogier.LinkWithBack(link);
            ElMessage.success(
              "当前委托书与其他委托书同属一个订单，已为您跳转至订单相关目录，您可通过批量操作完成相应待办"
            );
          } else {
            CurrentLogier.LinkWithBack(row.Link ?? "");
          }
        }
        break;

      default:
        break;
    }
  }

  /**格式化时间 */
  formatTime(v?: Date): string {
    return v ? moment(v).format("YYYY/MM/DD HH:mm:ss") : "暂无数据";
  }

  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemOrderTask,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "assign-user":
        this.modalAssignData = {
          Ids: [row.Id ?? ""],
        };
        this.modalAssign.Show = true;
        complete();
        break;

      default:
        break;
    }
  }
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "assign-users":
        if (this.list.SelectIds.length == 0) {
          ElMessage.warning("请至少选择一条信息");
          complete();
        } else {
          this.modalAssignData = {
            Ids: this.list.SelectIds,
          };
          this.modalAssign.Show = true;
          complete();
        }
        break;
      case "assign-submit":
        (this.$refs["ref-form-assign"] as any).validate((v: boolean) => {
          if (v) {
            ElMessageBox.confirm("是否确认执行当前操作", "提示", {
              confirmButtonText: "确认",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                OrderTaskAppService.AssignUser(this.modalAssignData)
                  .then(() => {
                    this.handleGet();
                    this.modalAssign.Show = false;
                    complete();
                  })
                  .catch(complete);
              })
              .catch(() => {
                complete();
              });
          }
        });
        break;

      default:
        break;
    }
  }
}
