import UiSelectOption from "./UiSelectOption";

/**
 * 附件的类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCustomsAnnexType {
    /**
     * 资料
     */
    Data = 1,
    /**
     * 预录单
     */
    Prerecording = 10,
    /**
     * 海关放行通知书
     */
    Release = 20,
    /**
     * 其他
     */
    Other = 30,
}

/**
 * 附件的类型-选项
 */
export const EnumCustomsAnnexTypeOption: UiSelectOption<number>[] = [
    { Label: "资料", Value: 1, Data: "Data" },
    { Label: "预录单", Value: 10, Data: "Prerecording" },
    { Label: "海关放行通知书", Value: 20, Data: "Release" },
    { Label: "其他", Value: 30, Data: "Other" },
];
