import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 成交方式表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormTransactionMode extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 海关代码
     */
    Code?: string;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 成交方式表单-表单校验
 */
export const DtoFormTransactionModeRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 12040,
            message: "文本长度不能超过 12040 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 成交方式表单-设置项
 */
export const DtoFormTransactionModeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "海关代码",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
]
