import UiSelectOption from "./UiSelectOption";

/**
 * 枚举-动态搜索-项-元素类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumUiDynamicSearchItemType {
    /**
     * 文本框
     */
    InputText = 0,
    /**
     * 数字输入框
     */
    InputNumber = 1,
    /**
     * 月份范围选择器
     */
    MonthRange = 2,
    /**
     * 日期范围选择器
     */
    DateTimeRange = 3,
    /**
     * 开关
     */
    Switch = 4,
    /**
     * 自定义
     */
    Customize = 5,
}

/**
 * 枚举-动态搜索-项-元素类型-选项
 */
export const EnumUiDynamicSearchItemTypeOption: UiSelectOption<number>[] = [
    { Label: "文本框", Value: 0, Data: "InputText" },
    { Label: "数字输入框", Value: 1, Data: "InputNumber" },
    { Label: "月份范围选择器", Value: 2, Data: "MonthRange" },
    { Label: "日期范围选择器", Value: 3, Data: "DateTimeRange" },
    { Label: "开关", Value: 4, Data: "Switch" },
    { Label: "自定义", Value: 5, Data: "Customize" },
];
