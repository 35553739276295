
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class PagedList<T> {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 
     */
    PageIndex?: number;
    /**
     * 
     */
    PageSize?: number;
    /**
     * 
     */
    TotalCount?: number;
    /**
     * 
     */
    TotalPages?: number;
    /**
     * 
     */
    Items?: T[];
    /**
     * 
     */
    HasPrevPages?: boolean;
    /**
     * 
     */
    HasNextPages?: boolean;
}

/**
 * -表单校验
 */
export const PagedListRules = {
}
