import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 装货港-仓库/堆场/码头分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderDepartureWharfOutStock extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Index = 0;
        this.IsShiftout = false;
    }

    [proName: string]: any;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 入库汇总表主键
     */
    ShapeId?: string;
    /**
     * 
     */
    Shape?: string;
    /**
     * 长度/直径（cm）
     */
    Length?: number;
    /**
     * 宽度/直径（cm）
     */
    Width?: number;
    /**
     * 高度（cm）
     */
    Height?: number;
    /**
     * 体积（CBM）
     */
    Volume?: number;
    /**
     * 毛重（KGS）
     */
    GrossWeight?: number;
    /**
     * 出库数量
     */
    OutStockQuantity?: number;
    /**
     * 出库日期
     */
    OutStockTime?: Date;
    /**
     * 序号
     */
    Index?: number;
    /**
     * 是否移库
     */
    IsShiftout?: boolean;
    /**
     * 毛重集合
     */
    GrossWeights?: number[];
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-表单校验
 */
export const DtoListItemOrderDepartureWharfOutStockRules = {
}

/**
 * 装货港-仓库/堆场/码头分页列表-项-设置项
 */
export const DtoListItemOrderDepartureWharfOutStockMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
  },
  {
    Prop: "ShapeId",
    Label: "入库汇总表主键",
  },
  {
    Prop: "Shape",
    Label: "",
  },
  {
    Prop: "Length",
    Label: "长度/直径（cm）",
  },
  {
    Prop: "Width",
    Label: "宽度/直径（cm）",
  },
  {
    Prop: "Height",
    Label: "高度（cm）",
  },
  {
    Prop: "Volume",
    Label: "体积（CBM）",
  },
  {
    Prop: "GrossWeight",
    Label: "毛重（KGS）",
  },
  {
    Prop: "OutStockQuantity",
    Label: "出库数量",
  },
  {
    Prop: "OutStockTime",
    Label: "出库日期",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Index",
    Label: "序号",
  },
  {
    Prop: "IsShiftout",
    Label: "是否移库",
    Pipe: EnumPipe.Boolean,
  },
  {
    Prop: "GrossWeights",
    Label: "毛重集合",
  },
]
