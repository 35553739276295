
import CodeControllerAppService from "@/apis.machine/CodeControllerAppService";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Prop, Watch } from "vue-property-decorator";
import CodeSelectForm from "../CodeMethod/CodeSelectForm.vue";
import CodeDynamicSearchForm from "../CodeMethod/CodeDynamicSearchForm.vue";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
import router from "@/router";
import { ElMessage } from "element-plus";
import DtoFormCodeService, {
  DtoFormCodeServiceMiezzFormItems,
  DtoFormCodeServiceRules,
} from "@/models.machine/DtoFormCodeService";
import CodeServiceAppService from "@/apis.machine/CodeServiceAppService";
import TranslateAppService from "@/apis.machine/TranslateAppService";

@Options({
  components: {
    CodeSelectForm,
    CodeDynamicSearchForm,
    CodePropertyTree,
  },
})
export default class CodeServiceForm extends Vue {
  @Prop() id?: string;

  form = new MiezzForm<DtoFormCodeService, string>();
  buttons = [
    {
      Code: "submit",
      Label: "保存",
      Type: "primary",
      Size: "small",
    },
  ];
  modal = new MiezzModal();
  drawerCode?: string;
  drawerFormData?: any;

  created(): void {
    this.form.Data = new DtoFormCodeService();
    this.form.Rules = DtoFormCodeServiceRules;
    this.form.Items = DtoFormCodeServiceMiezzFormItems;

    for (const item of this.form.Items) {
      switch (item.Prop) {
        case "Group":
          CodeControllerAppService.GetGroupSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        case "BaseService":
          CodeControllerAppService.GetBaseServiceSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        case "Table":
          CodeControllerAppService.GetTableSelect().then((r) => {
            item.Options = r.data.Data;
          });
          break;
        default:
          break;
      }
    }

    this.onBaseServiceChanged();

    var query = router.currentRoute.value.query;
    if (query.Group) {
      this.form.Data.Group = query.Group as string;
      if (query.App) {
        this.form.Data.App = query.App as string;
      }
    }
  }

  @Watch("form.Data.Group")
  onGroupChanged(): void {
    if (!this.form.Items) return;
    const item = this.form.Items.find((it) => it.Prop == "App");
    if (item) {
      CodeControllerAppService.GetAppSelectByGroup(this.form.Data.Group).then(
        (r) => {
          item.Options = r.data.Data;
        }
      );
    }
  }

  @Watch("form.Data.BaseService")
  onBaseServiceChanged(): void {
    if (!this.form.Items) return;
    const _items = this.form.Items.filter(
      (it) => it.Prop == "Table" || it.Prop == "IdType"
    );
    if (this.form.Data.BaseService) {
      for (const item of _items) {
        item.Hide = false;
      }
    } else {
      this.form.Data.Table = undefined;
      this.form.Data.IdType = undefined;
      for (const item of _items) {
        item.Hide = true;
      }
    }
    this.onTableChanged();
  }

  @Watch("form.Data.Table")
  onTableChanged(): void {
    if (!this.form.Items) return;
    const _items = this.form.Items.filter(
      (it) =>
        it.Prop == "ClientPath" ||
        it.Prop == "GetSelectApi" ||
        it.Prop == "GetPageListApi" ||
        it.Prop == "GetDetailApi" ||
        it.Prop == "PostApi" ||
        it.Prop == "DeleteApi"
    );
    if (this.form.Data.Table) {
      for (const item of _items) {
        item.Hide = false;
      }
      this.form.Data.Name = this.form.Data.Table;
      const table = this.form.Items.find((it) => it.Prop == "Table");
      const data = table?.Options?.filter(
        (it) => it.Value == this.form.Data.Table
      )[0].Data;
      this.form.Data.Summary = data.Summary;
      this.form.Data.IdType = data.IdType;
    } else {
      this.form.Data.ClientPath = undefined;
      this.form.Data.GetSelectApi = false;
      this.form.Data.GetPageListApi = false;
      this.form.Data.GetDetailApi = false;
      this.form.Data.PostApi = false;
      this.form.Data.DeleteApi = false;
      for (const item of _items) {
        item.Hide = true;
      }
    }
    this.onGetSelectApiChanged();
    this.onGetPageListApiChanged();
    this.onGetDetailApiChanged();
    this.onPostApiChanged();
  }

  @Watch("form.Data.GetSelectApi")
  onGetSelectApiChanged(): void {
    if (!this.form.Items) return;
    const _items = this.form.Items.filter(
      (it) => it.Prop == "SelectTemplate" || it.Prop == "SelectOption"
    );
    if (this.form.Data.GetSelectApi) {
      for (const item of _items) {
        item.Hide = false;
      }
    } else {
      this.form.Data.SelectTemplate = undefined;
      this.form.Data.SelectOption = [];
      for (const item of _items) {
        item.Hide = true;
      }
    }
  }

  @Watch("form.Data.GetPageListApi")
  onGetPageListApiChanged(): void {
    if (!this.form.Items) return;
    const _items = this.form.Items.filter(
      (it) =>
        it.Prop == "DragSort" ||
        it.Prop == "DynamicSearchItems" ||
        it.Prop == "ListItem"
    );
    if (this.form.Data.GetPageListApi) {
      for (const item of _items) {
        item.Hide = false;
      }
    } else {
      this.form.Data.DragSort = false;
      this.form.Data.DynamicSearchItems = [];
      this.form.Data.ListItem = [];
      for (const item of _items) {
        item.Hide = true;
      }
    }
  }

  @Watch("form.Data.GetDetailApi")
  onGetDetailApiChanged(): void {
    if (!this.form.Items) return;
    const item = this.form.Items.find((it) => it.Prop == "Detail");
    if (item) {
      if (this.form.Data.GetDetailApi) {
        item.Hide = false;
      } else {
        this.form.Data.Detail = [];
        item.Hide = true;
      }
    }
  }

  @Watch("form.Data.PostApi")
  onPostApiChanged(): void {
    if (!this.form.Items) return;
    const item = this.form.Items.find((it) => it.Prop == "Form");
    if (item) {
      if (this.form.Data.PostApi) {
        item.Hide = false;
      } else {
        this.form.Data.Form = [];
        item.Hide = true;
      }
    }
  }

  handleSubmit(): void {
    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
      if (v) {
        CodeServiceAppService.Post(this.form.Data).then((r) => {
          ElMessage.success("添加成功");
          router.push("/developer/tool/controller");
        });
      }
    });
  }
}
