
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzButton from "@/models/MiezzButton";
import MiezzPageList from "@/models/MiezzPageList";
import PricePageList from "@/views/Price/PricePageList.vue";
import { Back } from "@element-plus/icons-vue/dist/types";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PriceServiceDetail from "../PriceServiceDetail.vue";
@Options({
  components: {
    PriceServiceDetail,
    PricePageList,
  },
})
export default class PriceServiceDetailCostComposition extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_SetUp_Service_Detail_CostComposition"
  );
  serviceId?: string;
  amountType = EnumPriceAmountType.Benchmark;
  menuTab: MiezzMenuTab = {
    Back: true,
    // MenuButtons: [
    //   {
    //     Code: "search",
    //     Label: "搜索",
    //     Icon: "search",
    //     Type: "text",
    //     Size: "small",
    //   },
    // ],
  };

  created(): void {
    this.serviceId = this.$route.params.id as string;
    //列表-顶部按钮
    this.list.TopButtons = [];
    this.list.OperationWidth = "100px";
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail-surcharge",
        Label: "附加费",
        Type: "text",
        Size: "small",
        PowerCode:
          "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge_Added",
        Options: [
          {
            Code: "detail",
            Label: "查看详情",
          },
          {
            Code: "search",
            Label: "搜索同源信息",
          },
        ],
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
      {
        Code: "add-surcharge-group",
        Label: "添加附加费",
        Icon: "plus",
        Type: "text",
        PowerCode:
          "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge_NotAdded_Add",
        Options: [
          {
            Code: "by-hand",
            Label: "手动添加",
          },
          {
            Code: "by-id",
            Label: "通过ID添加",
          },
        ],
      },
      {
        Code: "delete-surcharge-group",
        Label: "清除附加费",
        Icon: "delete",
        Type: "text",
        PowerCode:
          "PriceManagement_SetUp_Service_Detail_CostComposition_Surcharge_Added_Delete",
      },
    ];
  }

  /**顶部按钮点击 */
  handleMenuTabClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "search":
        (
          this.$refs["ref-price-page-list"] as PricePageList
        ).handleTopButtonClick(btn, complete);
        break;
      default:
        break;
    }
  }
}
