
import DtoTreeMenu from "@/models.machine/DtoTreeMenu";
import MiezzModal from "@/models/MiezzModal";
import { ElTree } from "element-plus";
import Node from "element-plus/es/components/tree/src/model/node";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import ChooseMenuFields from "./ChooseMenuFields.vue";
@Options({
  components: {
    ChooseMenuFields,
  },
})
export default class ChooseMenu extends Vue {
  @Model() menuIds: string[] = [];
  @Model() menuFieldIds: string[] = [];
  @Prop() tree: DtoTreeMenu[] = [];
  @Prop() showCheck?: boolean;
  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };
  treeRef?: InstanceType<typeof ElTree>;

  id?: string;
  modal = new MiezzModal();

  created(): void {
    this.modal.Width = "600px";
  }

  mounted(): void {
    this.treeRef = this.$refs["treeRef"] as InstanceType<typeof ElTree>;
  }

  @Watch("tree")
  onChanged(): void {
    this.treeRef?.setCheckedKeys(this.menuIds, false);
  }

  handleNodeSetting(data: DtoTreeMenu): void {
    this.id = data.Id;
    this.modal.Title = "选择字段";
    this.modal.Show = true;
  }

  check(id: string, check?: boolean): void {
    const index = this.menuIds.indexOf(id);
    if (check && index == -1) {
      this.menuIds.push(id);
    } else if (!check && index > -1) {
      this.menuIds.splice(index, 1);
    }
  }
  /**菜单选择变化时 */
  handleCheckChange(
    data: DtoTreeMenu,
    checked: boolean,
    indeterminate: boolean
  ): void {
    if (!data.Id) return;
    data.Check = checked || indeterminate;
    this.check(data.Id, data.Check);
    if (
      data.RightItems &&
      data.RightItems.length > 0 &&
      // 菜单选中时 右侧全部未选中时 则右侧全选
      (!data.Check || data.RightItems.all((it) => !it.Check))
    ) {
      for (const item of data.RightItems) {
        if (item.Id && item.Check != data.Check) {
          item.Check = data.Check;
          this.check(item.Id, item.Check);
        }
      }
    }
    if (
      data.Children &&
      data.Children.length > 0 &&
      // 菜单选中时 子菜单全部未选中时 则子菜单全选
      (!data.Check || data.Children.all((it) => !it.Check))
    ) {
      for (const item of data.Children) {
        this.treeRef?.setChecked(item.Id as string, data.Check, false);
      }
    }
    // 菜单选中时 选中父菜单
    if (data.Check) {
      const node = this.treeRef?.getNode(data.Id);
      if (node?.parent && !node.parent.checked) {
        this.treeRef?.setChecked(node.parent.data.Id, true, false);
      }
    }
  }

  /**菜单右侧选择变化时 */
  handleMenuNodeTagChange(data: DtoTreeMenu, node: Node): void {
    if (!data.Id) return;
    this.check(data.Id, data.Check);
    // 右侧选中时 并且 菜单未选中 则 选中菜单
    if (data.Check) {
      if (!node.data.Check) this.treeRef?.setChecked(node.data.Id, true, false);
    }
  }

  handleSubmit(): void {
    this.modal.Show = false;
  }
}
