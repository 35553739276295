
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import MiezzForm from "@/models/MiezzForm";
import {
  EnumCompanyBusinessCardType,
  EnumCompanyBusinessCardTypeOption,
} from "@/models.machine/EnumCompanyBusinessCardType";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import DtoPageListSearchCompanyBusinessCard from "@/models.machine/DtoPageListSearchCompanyBusinessCard";
import DtoListItemCompanyBusinessCard, {
  DtoListItemCompanyBusinessCardMiezzListItems,
} from "@/models.machine/DtoListItemCompanyBusinessCard";
import DtoPageListCompanyBusinessCard from "@/models.machine/DtoPageListCompanyBusinessCard";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import MiezzButton from "@/models/MiezzButton";
import { ElMessage } from "element-plus";
export default class OrderFormCompanyBusinessCard extends Vue {
  @Model() form!: MiezzForm<
    {
      ShipperId?: string;
      ShipperName?: string;
      ShipperContent?: string;
      ConsigneeId?: string;
      ConsigneeName?: string;
      ConsigneeContent?: string;
      NotifyPartyId?: string;
      NotifyPartyName?: string;
      NotifyPartyContent?: string;
      ClientCompanyId?: string;
      TransportDirection?: EnumTransportDirection;
      HasPartBill?: boolean;
    },
    string
  >;
  @Prop() red?: boolean;
  @Prop() scene?: string;

  logier?: Logier;

  EnumCompanyBusinessCardType = EnumCompanyBusinessCardType;
  EnumCompanyBusinessCardTypeOption = EnumCompanyBusinessCardTypeOption;
  modalChoose = new MiezzModal(MiezzModalType.Dialog);

  listChoose = new MiezzPageList<
    DtoPageListSearchCompanyBusinessCard,
    DtoListItemCompanyBusinessCard,
    string
  >();

  created(): void {
    this.logier = CurrentLogier.GetLogier();

    this.modalChoose.Width = "80%";
    this.modalChoose.Height = document.body.clientHeight - 160 + "px";
    this.modalChoose.Top = "20px";
    this.modalChoose.HandleClick = this.handleClick;

    this.listChoose.Type = MiezzPageListType.Card;
    this.listChoose.HideCard = true;
    this.listChoose.EnabledSingleSelect = true;
    this.listChoose.EnabledRadioSearch = false;
    this.listChoose.DisabledCreatedHandleGet = true;
    this.listChoose.DisabledRouter = true;
    this.listChoose.CardSpan = 33.33;
    this.listChoose.Dto = new DtoPageListSearchCompanyBusinessCard();
    this.listChoose.Dto.PageSize = 9;
    //列表-数据-初始
    this.listChoose.Data = new DtoPageListCompanyBusinessCard();
    this.listChoose.Items = DtoListItemCompanyBusinessCardMiezzListItems.filter(
      (it) => it.Prop != "ParamCurrencySystemId"
    );
  }

  handleChoose(type: EnumCompanyBusinessCardType, parentId?: string): void {
    if (this.listChoose.Data) this.listChoose.Data.Items = [];
    this.type = type;
    const label = this.EnumCompanyBusinessCardTypeOption.firstOrDefault(
      (it) => it.Value == this.type
    )?.Label;
    this.modalChoose.Title = `选择${label}`;
    this.modalChoose.Buttons = [
      {
        Code: "save",
        Label:
          this.type == EnumCompanyBusinessCardType.Consignee
            ? "下一步"
            : "确定",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalChoose.Show = true;
    this.handleGet(parentId);
  }

  type?: EnumCompanyBusinessCardType;
  handleGet(parentId?: string): void {
    if (this.listChoose.Dto) {
      if (this.scene == "Booking" || this.scene == "MainBill") {
        this.listChoose.Dto.CompanyId = this.form.Data.HasPartBill
          ? this.logier?.ServiceProviderCompanyId
          : this.form.Data.ClientCompanyId;
      } else {
        this.listChoose.Dto.CompanyId = this.form.Data.ClientCompanyId;
      }
      this.listChoose.Dto.Type = this.type;
      if (
        (this.type == EnumCompanyBusinessCardType.Shipper &&
          this.form.Data.TransportDirection == EnumTransportDirection.Export) ||
        (this.type == EnumCompanyBusinessCardType.Consignee &&
          this.form.Data.TransportDirection == EnumTransportDirection.Import) ||
        (this.type == EnumCompanyBusinessCardType.Notifier &&
          this.form.Data.TransportDirection == EnumTransportDirection.Import)
      ) {
        this.listChoose.Dto.Area = EnumCompanyBusinessCardArea.Domestic;
        this.listChoose.CardTitleProp = "NameZh";
      } else {
        this.listChoose.Dto.Area = EnumCompanyBusinessCardArea.Overseas;
        this.listChoose.CardTitleProp = "NameEn";
      }
      this.listChoose.Dto.ParentId = parentId;
      CompanyBusinessCardAppService.Get(this.listChoose.Dto).then((r) => {
        this.listChoose.Data = r.data.Data;
      });
    }
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    switch (model.Code) {
      case "save":
        if (this.listChoose.SelectRows.length > 1) {
          ElMessage.warning("只能选择一条信息");
          complete();
        } else if (this.listChoose.SelectRows.length == 0) {
          ElMessage.warning("请选择一条信息");
          complete();
        } else {
          switch (this.type) {
            case EnumCompanyBusinessCardType.Shipper:
              this.form.Data.ShipperContent =
                this.listChoose.SelectRows.firstOrDefault()?.Content;
              this.modalChoose.Show = false;
              complete();
              break;
            case EnumCompanyBusinessCardType.Consignee:
              var consignee = this.listChoose.SelectRows.firstOrDefault();
              this.form.Data.ConsigneeContent = consignee?.Content;
              this.handleChoose(
                EnumCompanyBusinessCardType.Notifier,
                consignee?.Id
              );
              complete();
              break;
            case EnumCompanyBusinessCardType.Notifier:
              this.form.Data.NotifyPartyContent =
                this.listChoose.SelectRows.firstOrDefault()?.Content;
              this.modalChoose.Show = false;
              complete();
              break;

            default:
              complete();
              break;
          }
        }
        break;

      default:
        break;
    }
  }
}
