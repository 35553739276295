import UiSelectOption from "./UiSelectOption";

/**
 * 枚举类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumProductSiteEnvironment {
    /**
     * 开发
     */
    Development = 10,
    /**
     * 测试
     */
    Test = 20,
    /**
     * 生产
     */
    Production = 30,
}

/**
 * 枚举类型-选项
 */
export const EnumProductSiteEnvironmentOption: UiSelectOption<number>[] = [
    { Label: "开发", Value: 10, Data: "Development" },
    { Label: "测试", Value: 20, Data: "Test" },
    { Label: "生产", Value: 30, Data: "Production" },
];
