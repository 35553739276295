
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyCustomerMainRelationAppService from "@/apis.machine/CompanyCustomerMainRelationAppService";
import DtoPageListSearchCompanyCustomerMainRelationApply, {
  DtoPageListSearchCompanyCustomerMainRelationApplyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyCustomerMainRelationApply";
import DtoPageListCompanyCustomerMainRelationApply from "@/models.machine/DtoPageListCompanyCustomerMainRelationApply";
import DtoListItemCompanyCustomerMainRelationApply, {
  DtoListItemCompanyCustomerMainRelationApplyMiezzListItems,
} from "@/models.machine/DtoListItemCompanyCustomerMainRelationApply";

export default class CurrentCompanyApplyMainRelationPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchCompanyCustomerMainRelationApply,
    DtoListItemCompanyCustomerMainRelationApply,
    string
  >();

  /**创建时 */
  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyCustomerMainRelationApply();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyCustomerMainRelationApplyDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyCustomerMainRelationApply();
    //列表-列配置
    this.list.Items = DtoListItemCompanyCustomerMainRelationApplyMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "MainCompany")
        return `${row.MainCompany?.NameZh ?? "暂无数据"}/${
          row.MainCompany?.NameEn ?? "暂无数据"
        }`;
      if (item.Prop == "ServiceProviderCompany")
        return `${row.ServiceProviderCompany?.NameZh ?? "暂无数据"}/${
          row.ServiceProviderCompany?.NameEn ?? "暂无数据"
        }`;
      if (item.Prop == "ChildrenCompany")
        return row.ChildrenCompany
          ? `${row.ChildrenCompany?.NameZh ?? "暂无数据"}/${
              row.ChildrenCompany?.NameEn ?? "暂无数据"
            }`
          : "暂无数据";
      return undefined;
    };
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "add",
        Label: "申请关联",
        Type: "text",
      },
      {
        Code: "delete",
        Label: "取消关联",
        Type: "text",
        Message: "确认取消主体关联吗？",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    CompanyCustomerMainRelationAppService.GetApplyPageList(this.list.Dto).then(
      (r) => {
        this.list.Data = r.data.Data;
      }
    );
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    const serviceProviderCompanyIds = this.list.SelectRows.map(
      (it) => it.ServiceProviderCompanyId
    );
    switch (btn.Code) {
      case "add":
        CompanyCustomerMainRelationAppService.Form(
          serviceProviderCompanyIds as string[]
        ).then(() => {
          ElMessage.success("已发起申请，请耐心等待");
          this.handleGet();
          complete();
        });
        break;
      case "delete":
        CompanyCustomerMainRelationAppService.Cancel(
          serviceProviderCompanyIds as string[]
        ).then(() => {
          ElMessage.success("已取消");
          this.handleGet();
          complete();
        });
        break;
      default:
        break;
    }
  }
}
