import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_choose_menu = _resolveComponent("choose-menu")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_power_form = _resolveComponent("power-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'JoinPowerMenus')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "菜单",
              prop: "JoinPowerMenus"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_choose_menu, {
                  menuIds: _ctx.detail.Data.MenuIds,
                  "onUpdate:menuIds": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.MenuIds) = $event)),
                  menuFieldIds: _ctx.detail.Data.MenuFieldIds,
                  "onUpdate:menuFieldIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail.Data.MenuFieldIds) = $event)),
                  tree: _ctx.menuTree,
                  showCheck: false
                }, null, 8, ["menuIds", "menuFieldIds", "tree"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_power_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.formId,
          parentId: _ctx.parentId,
          path: _ctx.path,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "parentId", "path", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}