import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_detail = _resolveComponent("user-detail")!

  return (_openBlock(), _createBlock(_component_user_detail, {
    menuTab: _ctx.menuTab,
    type: _ctx.type,
    detail: _ctx.detail,
    buttons: _ctx.buttons
  }, null, 8, ["menuTab", "type", "detail", "buttons"]))
}