
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailJoinCompanyUser, {
  DtoDetailJoinCompanyUserMiezzDetailItems,
} from "@/models.machine/DtoDetailJoinCompanyUser";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import UserDetail from "../UserDetail.vue";
@Options({
  components: {
    UserDetail,
  },
})
export default class ServiceProviderUserDetail extends Vue {
  type = EnumCompanyType.ServiceProvider;
  detail = new MiezzForm<DtoDetailJoinCompanyUser, string>(
    "ServiceProviderManagement_Company_Detail"
  );
  buttons: MiezzButton[] = [];

  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab.Url = `/service-provider/user/detail/${this.$route.params.id}`;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: `/service-provider/user/detail/${this.$route.params.id}`,
        Label: "用户信息",
        Power: "ServiceProviderManagement_Company_Detail",
      },
    ];

    this.detail.DefaultSpan = 12;
    this.detail.Items = DtoDetailJoinCompanyUserMiezzDetailItems.filter(
      (it) => it.Prop != "ServiceProviderCompanyName"
    );
    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "default",
        Size: "small",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "ServiceProviderManagement_User_Edit",
      },
    ];
  }
}
