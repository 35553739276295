import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailParamAuditLog_ProjectSite from "./DtoDetailParamAuditLog_ProjectSite";
import { EnumCrudStatus, EnumCrudStatusOption } from "./EnumCrudStatus";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";
import { EnumAuditResult, EnumAuditResultOption } from "./EnumAuditResult";
import UiGroup from "./UiGroup";

/**
 * 参数记录表详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamAuditLog extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.CrudStatus = EnumCrudStatus.Create;
        this.Status = EnumParamAuditStatus.Not;
    }

    [proName: string]: any;
    /**
     * 服务商
     */
    ProjectSite?: DtoDetailParamAuditLog_ProjectSite;
    /**
     * 参数Id
     */
    ParamId?: string;
    /**
     * 参数名称
     */
    ParamTableLabel?: string;
    /**
     * 服务商动作
     */
    CrudStatus?: EnumCrudStatus;
    /**
     * 发起人
     */
    Promoter?: string;
    /**
     * 发起内容
     */
    Content?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 审核结果
     */
    AuditResult?: EnumAuditResult;
    /**
     * 审核人
     */
    Reviewer?: string;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 原参数信息
     */
    OldParamJson?: string;
    /**
     * 新参数信息
     */
    NewParamJson?: string;
}

/**
 * 参数记录表详细-表单校验
 */
export const DtoDetailParamAuditLogRules = {
}

/**
 * 参数记录表详细-设置项
 */
export const DtoDetailParamAuditLogMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ProjectSite",
    Label: "服务商",
    Customize: true,
  },
  {
    Prop: "ParamId",
    Label: "参数Id",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamTableLabel",
    Label: "参数名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CrudStatus",
    Label: "服务商动作",
    Customize: true,
  },
  {
    Prop: "Promoter",
    Label: "发起人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Content",
    Label: "发起内容",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "审核状态",
    Customize: true,
  },
  {
    Prop: "AuditResult",
    Label: "审核结果",
    Customize: true,
  },
  {
    Prop: "Reviewer",
    Label: "审核人",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Customize: true,
  },
  {
    Prop: "OldParamJson",
    Label: "原参数信息",
    Customize: true,
  },
  {
    Prop: "NewParamJson",
    Label: "新参数信息",
    Customize: true,
  },
]
