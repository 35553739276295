import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'NameZh')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "中文名称",
            prop: "NameZh",
            rules: _ctx.form.Rules.NameZh
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入中文名称",
                onHandleBlur: _ctx.onBlurNameZh,
                pattern: '[\u4e00-\u9fa5（）]'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true),
      (item.Prop == 'NameEn')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "英文名称",
            prop: "NameEn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入英文名称",
                onHandleBlur: _ctx.onBlurNameEn,
                pattern: '[a-zA-Z()  ]',
                trim: false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "pattern"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "代码",
            prop: "Code"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入代码",
                onHandleBlur: _ctx.onBlurCode
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'Flag')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "国旗",
            prop: "Flag"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_upload, {
                modelValue: _ctx.form.Data.Flag,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Flag) = $event)),
                path: "param/country",
                accept: "image/*",
                maxSize: 10 * 1024 * 1024
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'ParamCurrencySystemId')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "币制",
            prop: "ParamCurrencySystemId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data.ParamCurrencySystemId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ParamCurrencySystemId) = $event)),
                options: _ctx.Currencys
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaDeep')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "地区深度",
            prop: "AreaDeep"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data.AreaDeep,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.AreaDeep) = $event)),
                options: _ctx.optAreaDeep,
                onHandleChange: _ctx.changeAreaDeep
              }, null, 8, ["modelValue", "options", "onHandleChange"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaName1' && _ctx.form.Data.AreaDeep >= 1)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 6,
            label: "一级地区",
            prop: "AreaName1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入一级地区",
                onHandleChange: _ctx.onChangeAreaName1,
                disabled: false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleChange"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaName2' && _ctx.form.Data.AreaDeep >= 2)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 7,
            label: "二级地区",
            prop: "AreaName2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入二级地区",
                onHandleChange: _ctx.onChangeAreaName2,
                disabled: _ctx.form.Data.AreaName1?false:true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleChange", "disabled"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaName3' && _ctx.form.Data.AreaDeep >= 3)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 8,
            label: "三级地区",
            prop: "AreaName3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入三级地区",
                onHandleChange: _ctx.onChangeAreaName3,
                disabled: _ctx.form.Data.AreaName2?false:true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleChange", "disabled"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaName4' && _ctx.form.Data.AreaDeep >= 4)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 9,
            label: "四级地区",
            prop: "AreaName4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入四级地区",
                onHandleChange: _ctx.onChangeAreaName4,
                disabled: _ctx.form.Data.AreaName3?false:true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleChange", "disabled"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'AreaName5' && _ctx.form.Data.AreaDeep >= 5)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 10,
            label: "五级地区",
            prop: "AreaName5"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入五级地区",
                disabled: _ctx.form.Data.AreaName4?false:true
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}