
    import moment from "moment";
    import MiezzForm from "@/models/MiezzForm";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import BillAppService from "@/apis.machine/BillAppService";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
    import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
    import DtoCallbackSingleBill from "@/models.machine/DtoCallbackSingleBill";
    import DtoRequestOrderLock from "@/models.machine/DtoRequestOrderLock";
    import DtoRequestCompany from "@/models.machine/DtoRequestCompany";
    import DtoRequestAccount from "@/models.machine/DtoRequestAccount";
    import DtoCallbackTemplate from "@/models.machine/DtoCallbackTemplate";
    import DtoRequestTemplate from "@/models.machine/DtoRequestTemplate";
    import DtoDetailTime from "@/models.machine/DtoDetailTime";
    import DtoRequestFinishPoolBill from "@/models.machine/DtoRequestFinishPoolBill";
    import DtoCallbackAccountPeriod from "@/models.machine/DtoCallbackAccountPeriod";
    import DtoListItemBill_OrderPool from "@/models.machine/DtoListItemBill_OrderPool";
    import DtoRequestBill, {
        DtoRequestBillMiezzDetailItems,
        DtoRequestBillRules,
    } from "@/models.machine/DtoRequestBill";
    import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
    import { ElMessageBox } from "element-plus";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
    import { EnumRelation } from "@/models.machine/EnumRelation";
    import { EnumCompare } from "@/models.machine/EnumCompare";
    import DtoRequestInvoice from "@/models.machine/DtoRequestInvoice";

    import {
        EnumBillType
    } from "@/models.machine/EnumBillType";
import { number } from "echarts";
    export default class BillPageList extends Vue {
        isCheck = true;//是否需要验证
        lockParam = new DtoRequestOrderLock();
        /**分页列表 */
        dto = new DtoCallbackSingleBill();
        tabelRandom = 0;
        param = new DtoRequestBill();
        template = new DtoCallbackTemplate();
        paymentBill = new DtoCallbackSingleBill();
        menuTab = new MiezzMenuTab();
        /*委托书主键 */
        id?: string;
        /*订单主键 */
        orderId?: string;
        /*0表示委托书账单，1表示订单账单 */
        type = 0;
        /*账单类型 */
        billType = EnumBillType.Collection;
        title = "应收账单";
        isLoading = false;
        activeName = "0";
        OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate = false;
        OrderManagement_Order_Summary_BillingInformation_DeleteTemplate = false;
        CustomerManagement_Company_CreditAndAccountingPeriod = false;
        OrderManagement_Order_Summary_BillingInformation_EditAP = false;
        OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice = false;
        OrderManagement_Pool_Summary_BillingInformation_PublicTemplate = false;
        OrderManagement_Order_Summary_BillingInformation_PublicTemplate = false;

        OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
        OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable = false;
        saveRuleForm = new MiezzForm<DtoRequestBill, string>();
        dtoDetailTime = new DtoDetailTime();
        saveBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "select",
                Code: "template",
                Label: "保存为模板",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        selectBillObject = {
            CompanyId: "",
            UserId: "",
            BillLabel: "",
        };


        addPoolBillForm: {
            Visiable: boolean,
            Number?: number,
            SelectId?: string,
            SelectUserId?:string,
            SelectLabel?: string,
        } = {
                Visiable: false,
                Number: undefined,
                SelectId: undefined,
                SelectLabel: undefined,
                SelectUserId: undefined,
            };

        //委托人/供应商集合
        companys?: UiSelectOption<string>[] = [];
        companyUsers?: UiSelectOption<string>[] = [];
        companyForm = {
            Visiable: false,
            SelectId: "",
            Title: "委托人",
        };
        companyBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        rules = {
            PreSupplierLabel: [
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[1]);
                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                            if (this.dto.Items[number].SupplierCompanyId == undefined && (this.dto.Items[number].PreSupplierLabel == undefined || this.dto.Items[number].PreSupplierLabel == "")) {
                                callback("信息不能为空");
                                return false;
                            }
                            if (this.dto.Items[number].SupplierCompanyId == undefined && this.dto.Items[number].PreSupplierLabel != undefined) {
                                callback("无匹配信息");
                                return false;
                            }

                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ],
            PreClientLabel: [
                //{
                //    required: true,
                //    message: "信息不能为空",
                //    trigger: "change",
                //},
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[1]);
                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                            if (this.dto.Items[number].ClientCompanyId == undefined && (this.dto.Items[number].PreClientLabel == undefined || this.dto.Items[number].PreClientLabel == "")) {
                                callback("信息不能为空");
                                return false;
                            }
                            if (this.dto.Items[number].ClientCompanyId == undefined && this.dto.Items[number].PreClientLabel != undefined) {
                                callback("无匹配信息");
                                return false;
                            }

                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ],
            CostId: [
                //{
                //    required: true,
                //    message: "信息不能为空",
                //    trigger: "change",
                //},
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[1]);
                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                            if (this.dto.Items[number].CostId == undefined && (this.dto.Items[number].CostName == undefined || this.dto.Items[number].CostName == "")) {
                                callback("信息不能为空");
                                return false;
                            }
                            if (this.dto.Items[number].CostId == undefined && this.dto.Items[number].CostName != undefined) {
                                callback("无匹配信息");
                                return false;
                            }

                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ],
        };




        //委托书集合
        pools?: UiSelectOption<string>[] = [];
        poolRuleForm = new MiezzForm<DtoRequestBill, string>();
        poolBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        poolBillBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        startDateRuleForm = {
            Visiable: false,
            Time: undefined,
        };
        startDateBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];


        accountPeriodRuleForm = {
            Visiable: false,
            AccountPeriod: "",
        };
        accountPeriodBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //是否已保存
        isSave?: boolean;
        supplierIndex = "";
        currencyIndex = "";

        selectSaler?: string;
        selectSalerId?: string;
        selectPool?: DtoListItemBill_OrderPool;
        selectPoolId?: string;
        isImport = false;
        //物流计价单位
        chargeUnits: UiSelectOption<string>[] = [];

        //费用名称
        costs: UiSelectOption<string>[] = [];
        //币制
        currencyList: UiSelectOption<string>[] = [];


        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "save",
                Label: "保存",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
            {
                Code: "temporary",
                Label: "暂存",
                Icon: "Finished",
                Type: "text",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "提交",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
            {
                Code: "revoke",
                Label: "撤销提交",
                Icon: "RefreshLeft",
                Type: "text",
                Size: "small",
            },
            {
                Code: "template",
                Label: "保存为模板",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
            {
                Code: "import",
                Label: "导入",
                Icon: "Upload",
                Type: "text",
                Size: "small",
            },


        ];

        selectRows?: any[];

        templateForm: {
            Visiable: boolean,
            IsOpen: boolean,
            Name?: string,
        } = {
                Visiable: false,
                IsOpen: false,
                Name: undefined,
            };
        templateBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        importWidth = "50%";
        importForm: {
            Visiable: boolean,
            Name?: string,
        } = {
                Visiable: false,
                Name: undefined,
            };
        importBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        selectRow?: any[];

        onChangePoolBillNumber(): void {
            if (this.addPoolBillForm) {
                var accountPeriod = this.addPoolBillForm.Number;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod + "", 0, true, false);
                    if (result != "") {
                        this.addPoolBillForm.Number = Number(result);
                    }
                    else {
                        this.addPoolBillForm.Number = undefined;
                    }
                }
            }
        }

        onChangeNumber(): void {
            //poolRuleForm.Data.Number
            if (this.poolRuleForm && this.poolRuleForm.Data) {
                var accountPeriod = this.poolRuleForm.Data.Number;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod + "", 0, true, false);
                    if (result != "") {
                        this.poolRuleForm.Data.Number = Number(result);
                    }
                    else {
                        this.poolRuleForm.Data.Number = undefined;
                    }
                }
            }
        }


        selectableBill(row: any, index: number): boolean {
            var result = true;
            if (row.FromId != undefined && row.FromId != null || (row.ChildCount > 0)) {
                result = false;
            }
            //if (result == true) {
            //    if (row.IsSettlement == true || row.IsInvoice == true) {
            //        result = false;
            //    }
            //}
            return result;
        }

        selectablePayment(row: any, index: number): boolean {
            var result = true;
            if (row.FromId != undefined && row.FromId != null || (row.ChildCount > 0)) {
                result = false;
            }
            return result;
        }


        handleClickCollection(el: any): void {
            this.$nextTick(() => {
                var request = new DtoRequestTemplate();
                request.OrderId = this.orderId;
                request.PoolId = this.id;
                request.SelectPoolId = this.id;
                request.SelectPoolId = this.selectPoolId;
                this.importWidth = "50%";
                this.selectRow = [];
                if (this.activeName == "0") {
                    request.Type = EnumBillType.Collection;
                    this.isLoading = true;
                    BillAppService.GetTemplate(request).then((c) => {
                        if (c.data.Data) this.template = c.data.Data;
                        if (this.selectBillObject) {
                            this.selectBillObject.BillLabel = this.template.ClientLabel as string;
                            this.selectBillObject.CompanyId = this.template.ClientCompanyId as string;
                        }
                        this.isLoading = false;
                    })
                }
                else if (this.activeName == "3") {
                    this.importWidth = "80%";
                    if (this.billType == EnumBillType.Collection) {
                        var request1 = new DtoRequestBill();
                        request1.Type = EnumBillType.Payment;
                        if (this.type == 0) {
                            request1.OrderId = undefined;
                            request1.PoolId = this.id;
                        }
                        else if (this.type == 1) {
                            request1.PoolId = undefined;
                            request1.OrderId = this.orderId;
                        }
                        this.isLoading = true;
                        request1.IsImport = true;
                        BillAppService.GetSingleBill(request1).then((r) => {
                            this.isLoading = false;
                            if (r.data.Data) this.paymentBill = r.data.Data;

                            if (this.paymentBill && this.paymentBill.Items) {
                                for (var pay of this.paymentBill.Items) {
                                    pay.StartDateString = "";
                                    if (pay.StartDate != undefined) {
                                        pay.StartDateString = moment(pay.StartDate).format("YYYY/MM/DD");
                                    }
                                }
                            }

                        });
                    }

                }
                else if (this.activeName == "4") {
                    this.importWidth = "80%";
                    if (this.billType == EnumBillType.Collection) {
                        var request10 = new DtoRequestBill();
                        request10.Type = EnumBillType.Saler;
                        if (this.type == 0) {
                            request10.OrderId = undefined;
                            request10.PoolId = this.id;
                        }
                        else if (this.type == 1) {
                            request10.PoolId = undefined;
                            request10.OrderId = this.orderId;
                        }
                        this.isLoading = true;
                        request10.IsImport = true;
                        request10.PoolId = this.selectPoolId;
                        BillAppService.GetSingleBill(request10).then((r) => {
                            this.isLoading = false;
                            if (r.data.Data) this.paymentBill = r.data.Data;

                            if (this.paymentBill && this.paymentBill.Items) {
                                for (var pay of this.paymentBill.Items) {
                                    pay.StartDateString = "";
                                    if (pay.StartDate != undefined) {
                                        pay.StartDateString = moment(pay.StartDate).format("YYYY/MM/DD");
                                    }
                                }
                            }

                        });
                    }

                }

            });
        }

        handleClickPayment(el: any): void {
            this.$nextTick(() => {

                this.selectRow = [];
               
                if (this.activeName == "0") {
                    this.importWidth = "50%";
                    var request = new DtoRequestTemplate();
                    request.OrderId = this.orderId;
                    request.PoolId = this.id;
                    request.Type = EnumBillType.Payment;
                    this.isLoading = true;
                    BillAppService.GetTemplate(request).then((c) => {
                        if (c.data.Data) this.template = c.data.Data;
                        this.isLoading = false;
                    })
                }
                else if (this.activeName == "3") {
                    this.importWidth = "80%";
                    var request1 = new DtoRequestBill();
                    request1.Type = EnumBillType.Payment;
                    request1.IsBooking = true;
                    request1.OrderId = this.orderId;
                    request1.PoolId = this.selectPoolId;
                    request1.IsImport = true;
                    this.isLoading = true;
                    BillAppService.GetSingleBill(request1).then((r) => {
                        this.isLoading = false;
                        if (r.data.Data) this.paymentBill = r.data.Data;
                    });
                }
               
            });
        }

        tableRowClassName(row: any, rowIndex: any) {
            return "collection";
        }


        handleClickSaler(el: any): void {
            this.$nextTick(() => {
                this.selectRow = [];
                this.importWidth = "50%";
                var request = new DtoRequestTemplate();
                request.OrderId = this.orderId;
                request.PoolId = this.id;
                if (this.activeName == "0") {
                    request.Type = EnumBillType.Saler;
                    this.isLoading = true;
                    BillAppService.GetTemplate(request).then((c) => {
                        if (c.data.Data) this.template = c.data.Data;
                        this.isLoading = false;
                    })
                }
                else if (this.activeName == "3") {
                    this.importWidth = "80%";
                    if (this.billType == EnumBillType.Saler) {
                        var request1 = new DtoRequestBill();
                        request1.Type = EnumBillType.Payment;
                        if (this.type == 0) {
                            request1.OrderId = undefined;
                            request1.PoolId = this.id;
                        }
                        else if (this.type == 1) {
                            request1.PoolId = undefined;
                            request1.OrderId = this.orderId;
                        }
                        this.isLoading = true;
                        request1.IsImport = true;

                        BillAppService.GetSingleBill(request1).then((r) => {
                            this.isLoading = false;
                            if (r.data.Data) this.paymentBill = r.data.Data;

                            if (this.paymentBill && this.paymentBill.Items) {
                                for (var pay of this.paymentBill.Items) {
                                    pay.StartDateString = "";
                                    if (pay.StartDate != undefined) {
                                        pay.StartDateString = moment(pay.StartDate).format("YYYY/MM/DD");
                                    }
                                }
                            }

                        });
                    }

                }

            });
        }

        isSubmit = false;
        handleClickImport(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.isSubmit == false) {
                        this.isLoading = true;
                        btn.Disabled = true;
                        this.isSubmit = true;
                        
                        BillAppService.GetGuid().then((g) => {
                            if (g.data.Data) {
                                if (this.billType == EnumBillType.Collection) {
                                    if (this.activeName != "3" && this.activeName != "4") {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一个模板");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }
                                        var index1 = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index1 = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            for (var row1 of this.selectRow) {
                                                if (row1.Item && row1.Item.length > 0) {
                                                    for (var bill1 of row1.Item) {
                                                        //bill1.Id = g.data.Data;
                                                        bill1.PoolId = this.selectPoolId;
                                                        bill1.Pool = this.selectPool;
                                                        bill1.OrderId = undefined;
                                                        if (this.selectBillObject.CompanyId != null && this.selectBillObject.CompanyId != undefined && this.selectBillObject.CompanyId != "") {
                                                            bill1.ClientCompanyId = this.selectBillObject.CompanyId;
                                                        }
                                                        if (this.selectBillObject.UserId != null && this.selectBillObject.UserId != undefined && this.selectBillObject.UserId != "") {
                                                            bill1.ClientUserId = this.selectBillObject.UserId;
                                                        }
                                                        if (this.selectBillObject.BillLabel != null && this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                                                            bill1.PreClientLabel = this.selectBillObject.BillLabel;
                                                        }
                                                        bill1.SalerId = undefined;
                                                        bill1.SalerNameLabel = undefined;
                                                        bill1.SupplierCompanyId = undefined;
                                                        bill1.SupplierUserId = undefined;
                                                        bill1.SupplierLabel = undefined;
                                                        bill1.Index = index1;
                                                        bill1.StartDate = undefined;
                                                        bill1.AccountPeriod = undefined;
                                                        bill1.AccountPeriodId = undefined;
                                                        bill1.IsSave = false;
                                                        bill1.IsInvoice = false;
                                                        bill1.IsSettlement = false;
                                                        bill1.InvoiceId = undefined;
                                                        this.dto.Items.push(bill1);
                                                        index1++;
                                                    }
                                                }
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }
                                    else {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一份应付账单");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }

                                        var index2 = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index2 = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            for (var row2 of this.selectRow) {
                                                //row2.Id = g.data.Data;
                                                row2.PoolId = this.selectPoolId;
                                                row2.Pool = this.selectPool;
                                                row2.OrderId = undefined;
                                                row2.Index = index2;
                                                if (this.selectBillObject.CompanyId != null && this.selectBillObject.CompanyId != undefined && this.selectBillObject.CompanyId != "") {
                                                    row2.ClientCompanyId = this.selectBillObject.CompanyId;
                                                }
                                                if (this.selectBillObject.UserId != null && this.selectBillObject.UserId != undefined && this.selectBillObject.UserId != "") {
                                                    row2.ClientUserId = this.selectBillObject.UserId;
                                                }
                                                if (this.selectBillObject.BillLabel != null && this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                                                    row2.PreClientLabel = this.selectBillObject.BillLabel;
                                                }

                                                row2.SalerId = undefined;
                                                row2.SalerNameLabel = undefined;
                                                row2.SupplierCompanyId = undefined;
                                                row2.SupplierUserId = undefined;
                                                row2.SupplierLabel = undefined;
                                                row2.StartDate = undefined;
                                                row2.AccountPeriod = undefined;
                                                row2.AccountPeriodId = undefined;
                                                row2.IsSave = false;
                                                row2.IsInvoice = false;
                                                row2.IsSettlement = false;
                                                row2.InvoiceId = undefined;
                                                this.dto.Items.push(row2);
                                                index2++;
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }

                                }
                                else if (this.billType == EnumBillType.Saler) {
                                    if (this.activeName != "3") {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一个模板");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }
                                        var index10 = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index10 = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            var saler = "";
                                            if (this.selectSaler) {
                                                var salers = this.selectSaler.split('/');
                                                if (salers.length > 0) {
                                                    saler = salers[0];
                                                }
                                            }
                                            for (var row10 of this.selectRow) {
                                                if (row10.Item && row10.Item.length > 0) {
                                                    for (var bill10 of row10.Item) {
                                                        //bill10.Id = g.data.Data;
                                                        bill10.SalerId = this.selectSalerId;
                                                        bill10.SalerNameLabel = saler;
                                                        bill10.PoolId = this.selectPoolId;
                                                        bill10.Pool = this.selectPool;
                                                        bill10.OrderId = undefined;
                                                        bill10.ClientLabel = undefined;
                                                        bill10.ClientUserId = undefined;
                                                        bill10.ClientCompanyId = undefined;
                                                        bill10.SupplierCompanyId = undefined;
                                                        bill10.SupplierUserId = undefined;
                                                        bill10.SupplierLabel = undefined;
                                                        bill10.Index = index10;
                                                        bill10.StartDate = undefined;
                                                        bill10.AccountPeriod = undefined;
                                                        bill10.AccountPeriodId = undefined;
                                                        bill10.IsSave = false;
                                                        bill10.IsInvoice = false;
                                                        bill10.IsSettlement = false;
                                                        bill10.InvoiceId = undefined;
                                                        this.dto.Items.push(bill10);
                                                        index10++;
                                                    }
                                                }
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }
                                    else {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一份应付账单");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }

                                        var index20 = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index20 = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        var saler1 = "";
                                        if (this.selectSaler) {
                                            var salers1 = this.selectSaler.split('/');
                                            if (salers1.length > 0) {
                                                saler1 = salers1[0];
                                            }
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            for (var row20 of this.selectRow) {
                                                //row20.Id = g.data.Data;
                                                row20.SalerId = this.selectSalerId;
                                                row20.SalerNameLabel = saler1;
                                                row20.PoolId = this.selectPoolId;
                                                row20.Pool = this.selectPool;
                                                row20.OrderId = undefined;
                                                row20.Index = index20;
                                                row20.ClientLabel = undefined;
                                                row20.ClientUserId = undefined;
                                                row20.ClientCompanyId = undefined;
                                                row20.SupplierCompanyId = undefined;
                                                row20.SupplierUserId = undefined;
                                                row20.SupplierLabel = undefined;
                                                row20.StartDate = undefined;
                                                row20.AccountPeriod = undefined;
                                                row20.AccountPeriodId = undefined;
                                                row20.IsSave = false;
                                                row20.IsInvoice = false;
                                                row20.IsSettlement = false;
                                                row20.InvoiceId = undefined;
                                                this.dto.Items.push(row20);
                                                index20++;
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }

                                }
                                else {
                                    if (this.activeName == "0") {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一个模板");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }
                                        var index = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            for (var row of this.selectRow) {
                                                if (row.Item && row.Item.length > 0) {
                                                    for (var bill of row.Item) {
                                                        //bill.Id = g.data.Data;
                                                        bill.PoolId = this.selectPoolId;
                                                        bill.Pool = this.selectPool;
                                                        bill.OrderId = undefined;
                                                        bill.ClientLabel = undefined;
                                                        bill.ClientUserId = undefined;
                                                        bill.ClientCompanyId = undefined;
                                                        bill.SalerId = undefined;
                                                        bill.SalerNameLabel = undefined;
                                                        bill.IsSave = false;
                                                        bill.IsInvoice = false;
                                                        bill.IsSettlement = false;
                                                        bill.InvoiceId = undefined;
                                                        if (this.selectBillObject.CompanyId != null && this.selectBillObject.CompanyId != undefined && this.selectBillObject.CompanyId != "") {
                                                            bill.SupplierCompanyId = this.selectBillObject.CompanyId;
                                                        }
                                                        if (this.selectBillObject.UserId != null && this.selectBillObject.UserId != undefined && this.selectBillObject.UserId != "") {
                                                            bill.SupplierUserId = this.selectBillObject.UserId;
                                                        }
                                                        if (this.selectBillObject.BillLabel != null && this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                                                            bill.PreSupplierLabel = this.selectBillObject.BillLabel;
                                                        }
                                                        else {
                                                            bill.SupplierCompanyId = undefined;
                                                        }
                                                        bill.Index = index;
                                                        bill.StartDate = undefined;
                                                        bill.AccountPeriod = undefined;
                                                        bill.AccountPeriodId = undefined;
                                                        this.dto.Items.push(bill);
                                                        index++;
                                                    }
                                                }
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }
                                    else {
                                        if (this.selectRow == null || this.selectRow == undefined || this.selectRow.length <= 0) {
                                            ElMessage.warning("至少选择一份应付账单");
                                            btn.Disabled = false;
                                            this.isLoading = false;
                                            this.isSubmit = false;
                                            complete();
                                            return;
                                        }

                                        var index21 = 0;
                                        if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                                            index21 = this.dto.Items[this.dto.Items.length - 1].Index as number + 1;
                                        }
                                        if (this.selectRow) {
                                            if (this.dto.Items == null || this.dto.Items == undefined) {
                                                this.dto.Items = [];
                                            }
                                            for (var row200 of this.selectRow) {
                                                //row2.Id = g.data.Data;
                                                row200.PoolId = this.selectPoolId;
                                                row200.Pool = this.selectPool;
                                                row200.OrderId = undefined;
                                                row200.Index = index21;
                                                if (this.selectBillObject.CompanyId != null && this.selectBillObject.CompanyId != undefined && this.selectBillObject.CompanyId != "") {
                                                    row200.SupplierCompanyId = this.selectBillObject.CompanyId;
                                                }
                                                if (this.selectBillObject.UserId != null && this.selectBillObject.UserId != undefined && this.selectBillObject.UserId != "") {
                                                    row200.SupplierUserId = this.selectBillObject.UserId;
                                                }
                                                if (this.selectBillObject.BillLabel != null && this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                                                    row200.PreSupplierLabel = this.selectBillObject.BillLabel;
                                                }
                                                else {
                                                    row200.SupplierCompanyId = undefined;
                                                }
                                                row200.SalerId = undefined;
                                                row200.SalerNameLabel = undefined;
                                                row200.ClientLabel = undefined;
                                                row200.ClientUserId = undefined;
                                                row200.ClientCompanyId = undefined;
                                                row200.StartDate = undefined;
                                                row200.AccountPeriod = undefined;
                                                row200.AccountPeriodId = undefined;
                                                row200.IsSave = false;
                                                row200.IsInvoice = false;
                                                row200.IsSettlement = false;
                                                row200.InvoiceId = undefined;

                                                this.dto.Items.push(row200);
                                                index21++;
                                            }
                                            this.onSum();
                                            this.importForm.Visiable = false;
                                        }
                                    }
                             
                                }

                                this.$nextTick(() => {
                                    this.isLoading = false;
                                    this.isSubmit = false;
                                    btn.Disabled = false;
                                })
                                complete();
                            }
                            else {
                                this.$nextTick(() => {
                                    this.isLoading = false;
                                    this.isSubmit = false;
                                    btn.Disabled = false;
                                })
                                complete();
                            }
                        })
                    }
          
                    complete();
                    break;
                case "close":
                    this.importForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleSelectionChangeBill(val: any): void {
            this.selectRows = val;
        }

        handleSelectionChange(val: any): void {
            this.selectRow = val;
        }
        onAccount() {
            this.companyForm.SelectId = this.dto.AccountCompanyId as string;
            this.companyForm.Visiable = true;
        }
        handleClickCompany(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.companyForm.SelectId != null && this.companyForm.SelectId != undefined) {
                        if (this.billType == EnumBillType.Collection) {
                            let routeUrl = this.$router.resolve({
                                path: "/customer/company/credit-and-accounting-period/" + this.companyForm.SelectId,
                                query: { backUrl: this.$route.fullPath },
                            });
                            window.open(routeUrl.href, '_blank');
                        }
                        else {
                            let routeUrl1 = this.$router.resolve({
                                path: "/supplier/company/private/credit-and-accounting-period/" + this.companyForm.SelectId,
                                query: { backUrl: this.$route.fullPath },
                            });
                            window.open(routeUrl1.href, '_blank');

                        }

                        complete();
                    }
                    else {
                        if (this.billType == EnumBillType.Collection) {
                            ElMessage.warning("请选择委托人");
                        }
                        else {
                            ElMessage.warning("请选择供应商");
                        }
                        complete();
                    }
                    break;
                case "close":
                    this.companyForm.SelectId = "";
                    this.companyForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }


        onSelectSupplier(row: any): void {
            if (this.dto && this.dto.Items) {
                for (var it of this.dto.Items) {
                    if (it.Id == this.supplierIndex) {
                        it.SupplierCompanyContactLabel = row.SupplierCompanyContactLabel;
                        it.SupplierCompanyId = row.SupplierCompanyId;
                        it.SupplierCompanyLabel = row.SupplierCompanyLabel;
                        it.SupplierCompanyTypeId = row.SupplierCompanyTypeId;
                        it.SupplierCompanyTypeLabel = row.SupplierCompanyTypeLabel;
                        it.SupplierSource = row.SupplierSource;
                    }
                }
            }
        }

        onSupplier(row: any): void {
            this.supplierIndex = row.Id;
            const Supplier = this.$refs["Supplier"] as any;
            Supplier.onInit(row);
        }
        onChangeSave(val: boolean): void {
            this.saveRuleForm.Data.IsSave = val;
        }
        handleClickSave(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.saveRuleForm.Data.IsSave = true;
                    MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                        if (v == true) {
                            if (this.type == 0) {
                                this.dto.PoolId = this.id;
                                this.dto.OrderId = undefined;
                            }
                            else if (this.type == 1) {
                                this.dto.OrderId = this.orderId;
                                this.dto.PoolId = undefined;
                            }
                            this.isLoading = true;
                            BillAppService.Save(this.dto).then((r1) => {
                                var pa = new DtoRequestInvoice();
                                pa.BillIds = r1.data.Data;
                                FinanceAppService.FillBillDatas(pa).then((r2)=>{                       
                                    this.isLoading = false;
                                    ElMessage.success("保存成功");
                                    this.handleBack();
                                    complete();
                                })
                            })
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "template":
                    this.saveRuleForm.Data.IsSave = false;
                    this.isLoading = true;
                    MiezzForm.submit(this.$refs, "$saveRuleForm", (v1) => {
                        if (v1 == true) {
                            this.dto.Name = this.saveRuleForm.Data.Name;
                            this.isLoading = true;
                            BillAppService.SaveTemplate(this.dto).then((r1) => {
                                var pa1 = new DtoRequestInvoice();
                                pa1.BillIds = r1.data.Data;
                                 FinanceAppService.FillBillDatas(pa1).then((r2)=>{  
                                    this.isLoading = false;
                                    ElMessage.success("保存成功");
                                    this.saveRuleForm.Data.Visiable = false;
                                    complete();
                                })
                            })
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.saveRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        onChangePoolId(): void {
            if (this.poolRuleForm.Data.PoolId != undefined && this.poolRuleForm.Data.PoolId != null) {
                MiezzForm.clearValidate(this.$refs, "$poolRuleForm", "PoolId");
            }
        }
        handleClickPool(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "$poolRuleForm", (v) => {
                        if (v == true) {

                            this.param.Type = this.billType;
                            if (this.poolRuleForm.Data.PoolId != null && this.poolRuleForm.Data.PoolId != undefined && this.poolRuleForm.Data.PoolId != "") {
                                this.param.PoolId = this.poolRuleForm.Data.PoolId;
                            }
                            else {
                                this.param.PoolId = undefined;
                            }
                            if (this.poolRuleForm.Data.Number != null && this.poolRuleForm.Data.Number != undefined && this.poolRuleForm.Data.Number != 0) {
                                this.param.Number = this.poolRuleForm.Data.Number;
                            }
                            else { this.param.Number = 1; }

                            var isSaler = false;
                            this.isLoading = true;
                            BillAppService.Add(this.param).then((r) => {
                                this.isLoading = false;
                                if (r.data.Data) {
                                    if (this.isImport != true) {
                                        if (this.dto && this.dto.Items) {
                                            for (var i of r.data.Data) {
                                                this.dto.Items.push(i);
                                                if (i.IsSaler == true) {
                                                    isSaler = true;
                                                }
                                            }
                                        }
                                        this.onSum();

                                        this.poolRuleForm.Data.PoolId = undefined;
                                        this.poolRuleForm.Data.Visiable = false;
                                    }
                                    else {
                                        this.selectPoolId = r.data.Data[0].PoolId;
                                        this.selectPool = r.data.Data[0].Pool;
                                        this.selectSalerId = r.data.Data[0].SalerId;
                                        this.selectSaler = r.data.Data[0].SalerNameLabel;

                                        if (r.data.Data[0].IsSaler == true) {
                                            isSaler = true;
                                        }

                                        var isPool = true;
                                        if (r.data.Data[0].PoolId == null || r.data.Data[0].PoolId == undefined || r.data.Data[0].PoolId == "") {
                                            isPool = false;
                                        }


                                        this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
                                        if (isSaler == true) {
                                            CurrentLogier.GetPowerCodes().then((r) => {
                                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice")) {
                                                    this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = true;
                                                }
                                                if (isPool == false) {
                                                    this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = false;
                                                }

                                                this.poolRuleForm.Data.Visiable = false;
                                                var request = new DtoRequestTemplate();
                                                request.OrderId = this.orderId;
                                                request.PoolId = this.id;
                                                request.Type = this.billType;
                                                request.SelectPoolId = this.selectPoolId;
                                                BillAppService.GetTemplate(request).then((c) => {
                                                    if (c.data.Data) this.template = c.data.Data;
                                                    if (this.selectBillObject) {
                                                        this.selectBillObject.BillLabel = this.template.ClientLabel as string;
                                                        this.selectBillObject.CompanyId = this.template.ClientCompanyId as string;
                                                    }

                                                    this.activeName = "0";
                                                    this.importForm.Visiable = true;
                                                    complete();
                                                })


                                            });
                                        }
                                        else {
                                            this.poolRuleForm.Data.Visiable = false;
                                            var request = new DtoRequestTemplate();
                                            request.OrderId = this.orderId;
                                            request.PoolId = this.id;
                                            request.Type = this.billType;
                                            request.SelectPoolId = this.selectPoolId;
                                            BillAppService.GetTemplate(request).then((c) => {
                                                if (c.data.Data) this.template = c.data.Data;
                                                if (this.selectBillObject) {
                                                    this.selectBillObject.BillLabel = this.template.ClientLabel as string;
                                                    this.selectBillObject.CompanyId = this.template.ClientCompanyId as string;
                                                }
                                                this.activeName = "0";
                                                this.importForm.Visiable = true;
                                                complete();
                                            })
                                        }
                                    }


                                }

                                complete();
                            });
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.poolRuleForm.Data.PoolId = undefined;
                    this.poolRuleForm.Data.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickPoolBill(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.customSubmit(this.$refs, "refaddPoolBillForm", (v) => {
                        if (v == true) {

                            this.param.Type = this.billType;
                            this.param.PoolId = this.id;
                            this.param.Number = this.addPoolBillForm.Number;
                            this.param.ClientCompanyId = this.addPoolBillForm.SelectId;
                            this.param.ClientLabel = this.addPoolBillForm.SelectLabel;
                            this.param.ClientUserId = this.addPoolBillForm.SelectUserId;
                            var isSaler = false;
                            this.isLoading = true;
                            BillAppService.AddPoolBill(this.param).then((r) => {
                                this.isLoading = false;
                                if (r.data.Data) {
                                    if (this.isImport != true) {
                                        if (this.dto && this.dto.Items) {
                                            for (var i of r.data.Data) {
                                                this.dto.Items.push(i);
                                                if (i.IsSaler == true) {
                                                    isSaler = true;
                                                }
                                            }
                                        }
                                        this.onSum();

                                        this.addPoolBillForm.SelectId = undefined;
                                        this.addPoolBillForm.SelectLabel = undefined;
                                        this.addPoolBillForm.SelectUserId = undefined;
                                        this.addPoolBillForm.Visiable = false;
                                    }
                                }

                                complete();
                            });
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.addPoolBillForm.Number = undefined;
                    this.addPoolBillForm.SelectId = undefined;
                    this.addPoolBillForm.SelectLabel = undefined;
                    this.addPoolBillForm.SelectUserId = undefined;
                    this.addPoolBillForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangePeriod(): void {
            if (this.accountPeriodRuleForm) {
                var accountPeriod = this.accountPeriodRuleForm.AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod + "", 0, true, false);
                    if (result != "") {
                        this.accountPeriodRuleForm.AccountPeriod = Number(result) + "";
                    }
                    else {
                        this.accountPeriodRuleForm.AccountPeriod = "";
                    }
                }
            }
        }
        onAccountPeriod(): void {
            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }
            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {
                    if (this.dto && this.dto.Items) {
                        if (this.dto.Items.length <= 0) {
                            ElMessage.warning("至少添加一条账单才能批量编辑账期");
                            return;
                        }
                    }
                    //if (this.billType == 20) {
                    //    if (this.dto && this.dto.Items) {
                    //        for (var it of this.dto.Items) {
                    //            if (it.SupplierCompanyId == null || it.SupplierCompanyId == undefined) {
                    //                ElMessage.warning("账单中存在未选择供应商，不能批量编辑账期");
                    //                return;
                    //            }
                    //        }
                    //    }
                    //}
                    this.accountPeriodRuleForm.AccountPeriod = "";
                    this.accountPeriodRuleForm.Visiable = true;
                }
            })

        }
        handleClickAccountPeriod(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.customSubmit(this.$refs, "refAccountPeriodRuleForm", (v) => {
                        if (v == true) {
                            if (this.dto && this.dto.Items) {
                                for (var it of this.dto.Items) {
                                    if (it.IsSave != true && it.IsSettlement != true && it.IsInvoice != true) {
                                        if (this.accountPeriodRuleForm.AccountPeriod != null && this.accountPeriodRuleForm.AccountPeriod != undefined && this.accountPeriodRuleForm.AccountPeriod != "") {
                                            it.AccountPeriod = Number(this.accountPeriodRuleForm.AccountPeriod);
                                        }
                                        else {
                                            it.AccountPeriod = undefined;
                                        }
                                    }
                                }
                            }
                            this.accountPeriodRuleForm.Visiable = false;
                            complete();
                        }
                        else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.accountPeriodRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onStartDate(): void {
            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }
            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {
                    //if (this.billType == 20) {
                    //    if (this.dto && this.dto.Items) {
                    //        if (this.dto.Items.length <= 0) {
                    //            ElMessage.warning("至少添加一条账单才能编辑账单起始时间");
                    //            return;
                    //        }
                    //    }
                    //}
                    if (this.dto && this.dto.Items) {
                        if (this.dto.Items.length <= 0) {
                            ElMessage.warning("至少添加一条账单才能编辑账单起始时间");
                            return;
                        }
                    }
                    this.startDateRuleForm.Time = undefined;
                    this.startDateRuleForm.Visiable = true;
                }
            })
        }
        handleClickStartDate(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.customSubmit(this.$refs, "refTimeRuleForm", (v) => {
                        if (v == true) {
                            if (this.dto && this.dto.Items) {
                                for (var it of this.dto.Items) {
                                    if (it.IsSave != true && it.IsSettlement != true && it.IsInvoice != true) {
                                        it.StartDate = this.startDateRuleForm.Time;
                                    }
                                }
                            }
                            this.startDateRuleForm.Visiable = false;
                            complete();
                        }
                        else {
                            complete();
                        }
                    });

                    break;
                case "close":
                    this.startDateRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        OrderManagement_Pool_Summary_BillingInformation_Submit = false;
        OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission = false;
        OrderManagement_Order_Summary_BillingInformation_Submit = false;
        OrderManagement_Order_Summary_BillingInformation_RevokeSubmission = false;
        /**创建时 */
        created(): void {





            //表单-数据-初始化
            this.saveRuleForm.Data = new DtoRequestBill();
            //表单-校验
            this.saveRuleForm.Rules = DtoRequestBillRules;
            //表单-元素配置
            this.saveRuleForm.Items = DtoRequestBillMiezzDetailItems;

            //表单-数据-初始化
            this.poolRuleForm.Data = new DtoRequestBill();
            //表单-校验
            this.poolRuleForm.Rules = DtoRequestBillRules;
            //表单-元素配置
            this.poolRuleForm.Items = DtoRequestBillMiezzDetailItems;
            this.OrderManagement_Pool_Summary_BillingInformation_PublicTemplate = false;
            this.OrderManagement_Order_Summary_BillingInformation_PublicTemplate = false;
            this.OrderManagement_Pool_Summary_BillingInformation_Submit = false;
            this.OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission = false;
            this.OrderManagement_Order_Summary_BillingInformation_Submit = false;
            this.OrderManagement_Order_Summary_BillingInformation_RevokeSubmission = false;
            ParamCurrencySystemAppService.GetSelect().then(currency => {
                if (currency.data.Data) this.currencyList = currency.data.Data;
                if (this.$route && this.$route.matched) {
                    for (var matched of this.$route.matched) {
                        if (matched.path.toLowerCase() == "/pool/bill/collection/:id") {
                            this.id = this.$route.params.id as string;
                            this.type = 0;
                            this.billType = EnumBillType.Collection;
                            this.menuTab.Back = true;
                            this.menuTab.Url = `/pool/bill/collection/${this.id}`;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Menus = [
                                {
                                    Label: "应收账单",
                                    Url: `/pool/bill/collection/${this.id}`,
                                    Power: "OrderManagement_Pool_Summary_BillingInformation_EditAR",
                                },
                            ];
                            this.title = "应收账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_PublicTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_Submit") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_Submit = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission = true;
                                }
                                this.onInit();
                            });
                        }
                        else if (matched.path.toLowerCase() == "/pool/bill/payment/:id") {
                            this.id = this.$route.params.id as string;
                            this.type = 0;
                            this.billType = EnumBillType.Payment;
                            this.menuTab.Back = true;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Url = `/pool/bill/payment/${this.id}`;
                            this.menuTab.Menus = [
                                {
                                    Label: "应付账单",
                                    Url: `/pool/bill/payment/${this.id}`,
                                    Power: "OrderManagement_Pool_Summary_BillingInformation_EditAP",
                                },
                            ];
                            this.title = "应付账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_PublicTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_Submit") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_Submit = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission = true;
                                }
                                this.onInit();
                            });

                        }
                        else if (matched.path.toLowerCase() == "/pool/bill/saler/:id") {
                            this.id = this.$route.params.id as string;
                            this.type = 0;
                            this.billType = EnumBillType.Saler;
                            this.menuTab.Back = true;
                            this.menuTab.Url = `/pool/bill/saler/${this.id}`;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Menus = [
                                {
                                    Label: "业务员结算账单",
                                    Url: `/pool/bill/saler/${this.id}`,
                                    Power: "OrderManagement_Pool_Summary_BillingInformation_EditSettlementPrice",
                                },
                            ];
                            this.title = "业务员结算账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Pool_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Pool_Summary_BillingInformation_PublicTemplate = true;
                                }
                                this.onInit();
                            });

                        }
                        if (matched.path.toLowerCase() == "/order/bill/collection/:id") {
                            this.orderId = this.$route.params.id as string;
                            this.type = 1;
                            this.billType = EnumBillType.Collection;
                            this.menuTab.Back = true;
                            this.menuTab.Url = `/order/bill/collection/${this.orderId}`;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Menus = [
                                {
                                    Label: "应收账单",
                                    Url: `/order/bill/collection/${this.orderId}`,
                                    Power: "OrderManagement_Order_Summary_BillingInformation_EditAR",
                                },
                            ];
                            this.title = "应收账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_PublicTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_Submit") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_Submit = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_RevokeSubmission") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_RevokeSubmission = true;
                                }
                                this.onInit();
                            });
                        }
                        else if (matched.path.toLowerCase() == "/order/bill/payment/:id") {
                            this.orderId = this.$route.params.id as string;
                            this.type = 1;
                            this.billType = EnumBillType.Payment;
                            this.menuTab.Back = true;
                            this.menuTab.Url = `/order/bill/payment/${this.orderId}`;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Menus = [
                                {
                                    Label: "应付账单",
                                    Url: `/order/bill/payment/${this.orderId}`,
                                    Power: "OrderManagement_Order_Summary_BillingInformation_EditAP",
                                },
                            ];
                            this.title = "应付账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_PublicTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_Submit") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_Submit = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_RevokeSubmission") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_RevokeSubmission = true;
                                }
                                this.onInit();
                            });
                        }
                        else if (matched.path.toLowerCase() == "/order/bill/saler/:id") {
                            this.orderId = this.$route.params.id as string;
                            this.type = 1;
                            this.billType = EnumBillType.Saler;
                            this.menuTab.Back = true;
                            this.menuTab.Url = `/order/bill/saler/${this.orderId}`;
                            this.menuTab.IsHistory = false;
                            this.menuTab.Menus = [
                                {
                                    Label: "业务员结算账单",
                                    Url: `/order/bill/saler/${this.orderId}`,
                                    Power: "OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice",
                                },
                            ];
                            this.title = "业务员结算账单";

                            CurrentLogier.GetPowerCodes().then((r) => {
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_DeleteTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_DeleteTemplate = true;
                                }
                                if (r?.contains("OrderManagement_Order_Summary_BillingInformation_PublicTemplate") == true) {
                                    this.OrderManagement_Order_Summary_BillingInformation_PublicTemplate = true;
                                }

                                this.onInit();
                            });
                        }
                    }
                }

            })
        }

        onInit(): void {
            var pa = new DtoRequestCompany();
            if (this.type == 0) {
                pa.PoolId = this.id;
                pa.OrderId = undefined;
            }
            else {
                pa.PoolId = undefined;
                pa.OrderId = this.orderId;
            }
            if (this.billType == EnumBillType.Collection) {
                pa.Type = 1;
                BillAppService.GetClientCompanyUser(pa).then((u) => {
                    if (u.data.Data) {
                        this.companyUsers = u.data.Data;
                    }
                    ParamPriceCostAppService.GetSelect().then((cost) => {
                        if (cost.data.Data) this.costs = cost.data.Data;

                        ParamTransportChargeUnitAppService.GetSelect().then((unit) => {
                            if (unit.data.Data) this.chargeUnits = unit.data.Data;
                            this.handleGet();
                        })
                    })
                })
            }
            else if (this.billType == EnumBillType.Payment) {
                //GetSupplierUser
                pa.Type = 2;
                BillAppService.GetClientCompanyUser(pa).then((u) => {
                    if (u.data.Data) {
                        this.companyUsers = u.data.Data;
                    }
                    ParamPriceCostAppService.GetSelect().then((cost) => {
                        if (cost.data.Data) this.costs = cost.data.Data;

                        ParamTransportChargeUnitAppService.GetSelect().then((unit) => {
                            if (unit.data.Data) this.chargeUnits = unit.data.Data;
                            this.handleGet();
                        })
                    })
                })
            }
            else {
                ParamPriceCostAppService.GetSelect().then((cost) => {
                    if (cost.data.Data) this.costs = cost.data.Data;

                    ParamTransportChargeUnitAppService.GetSelect().then((unit) => {
                        if (unit.data.Data) this.chargeUnits = unit.data.Data;
                        this.handleGet();
                    })
                })
            }
            if (this.id != null && this.id != undefined && this.id != "") {
                this.dtoDetailTime = new DtoDetailTime();
                this.dtoDetailTime.PoolId = this.id;
                this.dtoDetailTime.OrderId = undefined;
                BillAppService.GetTime(this.dtoDetailTime).then((r) => {
                    if (r.data.Data) this.dtoDetailTime = r.data.Data;
                })
            }
            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                this.dtoDetailTime = new DtoDetailTime();
                this.dtoDetailTime.OrderId = this.orderId;
                this.dtoDetailTime.PoolId = undefined;
                BillAppService.GetTime(this.dtoDetailTime).then((r) => {
                    if (r.data.Data) this.dtoDetailTime = r.data.Data;
                })
            }
        }

        onChangeAccountPeriod(index: number): void {
            if (this.dto && this.dto.Items) {
                var accountPeriod = this.dto.Items[index].AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod.toString(), 0, true, false);
                    if (result != "") {
                        this.dto.Items[index].AccountPeriod = Number(result);
                    }
                    else {
                        this.dto.Items[index].AccountPeriod = undefined;
                    }
                }
            }
        }

        onSaveTemplate(index: number): void {
            if (this.template && this.template.Item) {
                if (this.template.Item[index].Name == null || this.template.Item[index].Name == undefined || this.template.Item[index].Name == "") {
                    ElMessage.warning("请填写模板名称。");
                    return;
                }

                //alert(JSON.stringify(this.template.Item[index]));
                //SaveTemplate
                BillAppService.UpdateTemplate(this.template.Item[index]).then((r) => {
                    ElMessage.success("修改成功");
                })
            }
        }

        onDeleteTemplate(index: number): void {
            if (this.template && this.template.Item) {
                ElMessageBox.confirm("您确定要删除信息吗？删除后信息将无法恢复，是否继续?", "提示", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        if (this.template && this.template.Item) {
                            BillAppService.RemoveTemplateById(this.template.Item[index].TemplateId).then((r) => {
                                this.template.Item?.splice(index, 1);
                                ElMessage.success("删除成功");
                            })
                        }
                    })
            }
        }

        onChangeStartDate(index: number): void {
            console.log(index);
        }

        onChangeRemark(index: number): void {
            console.log(index);
        }
        onOrder(): void {
            DynamicSearchAppService.Post({
                Code: "FinancialManagement_BillSummary",
                Brackets: [
                    {
                        Relation: EnumRelation.And,
                        Items: [
                            {
                                Relation: EnumRelation.And,
                                Where: {
                                    Prop: "DynamicSearch_Order",
                                    Compare: EnumCompare.Equal,
                                    Value: this.dto.Code,
                                },
                            },
                        ],
                    },
                ],
            }).then((r) => {
                //window.open(
                //    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                //    "_blank"
                //);
                //window.location.href = `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`;
                window.open(`#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`, '_blank');
            });
        }
        onChangeUnitPrice(index: number): void {
            if (this.dto && this.dto.Items) {
                var unitPirce = this.dto.Items[index].UnitPrice;
                if (unitPirce != undefined) {
                    var result = setDecimal(unitPirce.toString(), 2, true, true);
                    if (result != "") {
                        this.dto.Items[index].UnitPrice = Number(result);
                        MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".UnitPrice");
                    }
                    else {
                        this.dto.Items[index].UnitPrice = undefined;
                    }
                }
            }

            this.onSum();
        }
        onChangeFirstChargeUnitNumber(index: number): void {
            if (this.dto && this.dto.Items) {
                var firstChargeUnitNumber = this.dto.Items[index].FirstChargeUnitNumber;
                if (firstChargeUnitNumber != undefined) {
                    var result = setDecimal(firstChargeUnitNumber.toString(), 4, false, false);
                    if (result != "") {
                        this.dto.Items[index].FirstChargeUnitNumber = Number(result);

                    }
                    else {
                        this.dto.Items[index].FirstChargeUnitNumber = undefined;
                    }
                }

                this.dto.Items[index].FirstChargeUnitValue
                if (this.dto.Items[index].FirstChargeUnitNumber != undefined && this.dto.Items[index].FirstChargeUnitId != undefined) {
                    this.dto.Items[index].FirstChargeUnitValue = this.dto.Items[index].FirstChargeUnitNumber;
                    MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".FirstChargeUnitValue");
                }
            }
            this.onSum();
        }
        onChangeSecondChargeUnitNumber(index: number): void {
            if (this.dto && this.dto.Items) {
                var secondChargeUnitNumber = this.dto.Items[index].SecondChargeUnitNumber;
                if (secondChargeUnitNumber != undefined) {
                    var result = setDecimal(secondChargeUnitNumber.toString(), 4, false, false);
                    if (result != "") {
                        this.dto.Items[index].SecondChargeUnitNumber = Number(result);
                        MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".SecondChargeUnitNumber");
                    }
                    else {
                        this.dto.Items[index].SecondChargeUnitNumber = undefined;
                    }
                }
            }
            this.onSum();
        }

        onDeleteRow(index: number): void {



            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }
            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {
                    if (this.dto && this.dto.Items) {
                        var it = this.dto.Items[index];
                        if (it.Child == null || it.Child == undefined || it.Child.length <= 0) {
                            this.dto.Items.splice(index, 1);

                            var count = 0;
                            for (var it2 of this.dto.Items) {
                                it2.Index = count;
                                count++;
                            }
                        }
                        else {

                            ElMessageBox.confirm("删除该信息将会同步在委托书-账单信息中删除已分摊的费用，是否继续？", "提示", {
                                confirmButtonText: "确认",
                                cancelButtonText: "取消",
                                type: "warning",
                            })
                                .then(() => {
                                    if (this.dto && this.dto.Items) {
                                        this.dto.Items.splice(index, 1);

                                        var count1 = 0;
                                        for (var it1 of this.dto.Items) {
                                            it1.Index = count1;
                                            count1++;
                                        }
                                    }
                                })
                                .catch(() => {
                                    console.log("取消删除");
                                });
                        }


                    }
                }
            })
        }

        onDelete(index: number): void {
            if (this.dto && this.dto.Items) {
                var row = this.dto.Items[index];

                if (row.IsSettlement == true) {
                    ElMessage.warning("当前信息已有核销信息，不允许删除与修改。");
                    return;
                }

                if (row.IsInvoice == true) {

                    DynamicSearchAppService.Post({
                        Code: "FinancialManagement_BillSummary",
                        Brackets: [
                            {
                                Relation: EnumRelation.And,
                                Items: [
                                    {
                                        Relation: EnumRelation.And,
                                        Where: {
                                            Prop: "DynamicSearch_InvoiceNumber",
                                            Compare: EnumCompare.Equal,
                                            Value: row.InvoiceNumber,
                                        },
                                    },
                                ],
                            },
                        ],
                    }).then((r) => {
                        ElMessage.warning({
                            dangerouslyUseHTMLString: true,
                            message: '当前信息已关联发票号码<a  style="font-weight:900;" href="/#/finance/bill?DynamicSearchId=' + r.data.Data + '&OpenSearch=true&backUrl=' + this.$route.fullPath + '" >' + row.InvoiceNumber + '</a>，不允许删除。',
                        })
                        return;

                    });

                    return;

                }


                if (row.IsSave == true) {
                    if (this.dto.IsOrderLock == true) {
                        ElMessage.warning("订单账单已被锁住，不允许删除。");
                        return;
                    }
                    else
                    {
                  

                        if (this.dto.IsSave == true) {
                            var url = encodeURIComponent(this.$route.fullPath);
                            ElMessage.warning({
                                customClass: "testMessage",
                                dangerouslyUseHTMLString: true,
                                message: '账单已被锁定，不允许删除，如需修改，请先至<a href="/#/pool/bill/' + row.PoolId + '?backUrl=' + url + '" style="font-weight:900;" >委托书</a>中进行解锁。',
                            })
                            return;
                        }
                        else {
                            ElMessage.warning("账单已提交，不允许删除，如需修改，请先至撤销提交。");
                            return;
                        }
                
                    }
                }




                //if (row.IsSettlement == true) {
                //    ElMessage.warning("当前信息已有核销信息，不允许删除与修改。");
                //    return;
                //}

                //if (row.IsInvoice == true) {

                //    DynamicSearchAppService.Post({
                //        Code: "FinancialManagement_BillSummary",
                //        Brackets: [
                //            {
                //                Relation: EnumRelation.And,
                //                Items: [
                //                    {
                //                        Relation: EnumRelation.And,
                //                        Where: {
                //                            Prop: "DynamicSearch_InvoiceNumber",
                //                            Compare: EnumCompare.Equal,
                //                            Value: row.InvoiceNumber,
                //                        },
                //                    },
                //                ],
                //            },
                //        ],
                //    }).then((r) => {
                //        //window.open(
                //        //    `#/finance/bill?DynamicSearchId=${r.data.Data}&OpenSearch=true`,
                //        //    "_blank"
                //        //);

                //        ElMessage.warning({
                //            dangerouslyUseHTMLString: true,
                //            message: '当前信息已关联发票号码<a  style="font-weight:900;" href="/#/finance/bill?DynamicSearchId=' + r.data.Data + '&OpenSearch=true&backUrl=' + this.$route.fullPath + '" >' + row.InvoiceNumber + '</a>，不允许删除。',
                //        })
                //        return;

                //    });



                //}
                //else {
                //    this.onDeleteRow(index);
                //}

                this.onDeleteRow(index);
            }

        }

        isString(str: string, row: any | undefined): string {
            if (row != undefined && row != null) {
                if (row.FromId == undefined || row.FromId == null) {
                    if (str == null || str == undefined || str == "") {
                        return "暂无数据";
                    }
                    else {
                        return str;
                    }
                }
                else {
                    if (str == null || str == undefined || str == "") {
                        return "—";
                    }
                    else {
                        return str;
                    }
                }
            }
            else {
                if (str == null || str == undefined || str == "") {
                    return "暂无数据";
                }
                else {
                    return str;
                }
            }
        }

        onAddBill(): void {
            this.isImport = false;
            this.onAdd();
        }

        onAdd(): void {
            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }

            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {
                    if (this.type == 1) {

                        BillAppService.GetOrderPoolByOrderIdByType(this.orderId, this.billType).then((r1) => {
                            if (r1.data.Data) {
                                var pools = r1.data.Data;
                                if (this.billType == EnumBillType.Saler) {
                                    this.pools = [];
                                    if (pools) {
                                        for (var it of pools) {
                                            if (it.Data.SalerId != null && it.Data.SalerId != undefined && it.Data.SalerId != '') {
                                                this.pools.push(it);
                                            }
                                        }
                                    }
                                }
                                else {
                                    this.pools = pools;
                                }

                                if (this.pools && this.pools.length == 1) {
                                    if (this.pools[0].Data.IsSave != true) {
                                        this.poolRuleForm.Data.PoolId = this.pools[0].Value;
                                    }
                                }
                            }
                            this.poolRuleForm.Data.Visiable = true;
                        })


                    }
                    else {
                       
                        this.param.Type = this.billType;
                        this.param.PoolId = this.id;
                        this.param.Number = 1;
                        this.addPoolBillForm.SelectId = undefined;
                        this.addPoolBillForm.SelectLabel = "";
                        this.addPoolBillForm.SelectUserId = undefined;
                        this.addPoolBillForm.Number = undefined;
                        BillAppService.Add(this.param).then((r) => {

                            if (r.data.Data) {
                                if (this.billType == EnumBillType.Collection) {
                                    this.addPoolBillForm.SelectLabel = r.data.Data[0].ClientLabel;
                                    this.addPoolBillForm.SelectId = r.data.Data[0].ClientCompanyId;
                                    this.addPoolBillForm.SelectUserId = r.data.Data[0].ClientUserId;
                                }
                                else if (this.billType == EnumBillType.Payment) {
                                    this.addPoolBillForm.SelectLabel = r.data.Data[0].SupplierLabel;
                                    this.addPoolBillForm.SelectId = r.data.Data[0].SupplierCompanyId;
                                    this.addPoolBillForm.SelectUserId = r.data.Data[0].SupplierUserId;
                                }
                            }
                            this.addPoolBillForm.Visiable = true;

                            //if (this.dto && this.dto.Items) {
                            //    if (r.data.Data) {
                            //        for (var i of r.data.Data) {
                            //            this.dto.Items.push(i);
                            //        }
                            //    }
                            //}
                            //this.onSum();
                        });
                    }

                }
            })
        }



        /**查询列表 */
        handleGet(): void {
            if (this.billType == EnumBillType.Collection) {
                BillAppService.GetClientCompany().then((r) => {
                    this.companys = r.data.Data;
                    this.companyForm.Title = "委托人";
                })
            }
            else if (this.billType == EnumBillType.Payment) {
                BillAppService.GetSupplier().then((r) => {
                    this.companys = r.data.Data;
                    this.companyForm.Title = "供应商";
                })
            }
            this.param.Type = this.billType;
            if (this.type == 0) {
                this.param.OrderId = undefined;
                this.param.PoolId = this.id;
            }
            else if (this.type == 1) {
                this.param.PoolId = undefined;
                this.param.OrderId = this.orderId;
            }
            this.isLoading = true;
            BillAppService.ImportPoolCost(this.param).then((r1) => {

                var pa = new DtoRequestInvoice();
                pa.BillIds = r1.data.Data;
                FinanceAppService.FillBillDatas(pa).then((r2) => {

                    BillAppService.GetSingleBill(this.param).then((r) => {
                        if (r.data.Data != undefined) this.dto = r.data.Data;
                        if (this.dto) {
                            if (this.dto.AccountCompanyId) {
                                var flag = false;
                                if (this.companys) {
                                    for (var it1 of this.companys) {
                                        if (it1.Value == this.dto.AccountCompanyId) {
                                            flag = true;
                                        }
                                    }
                                    if (flag == false) {
                                        this.companys.push({
                                            Value: this.dto.AccountCompanyId,
                                            Label: this.dto.AccountCompanyName
                                        })
                                    }
                                }
                  
                            }
                            if (this.id != null && this.id != undefined && this.id != '') {
                                this.selectPoolId = this.dto.PoolId;
                                this.selectPool = this.dto.Pool;
                                this.selectSalerId = this.dto.SalerId;
                                this.selectSaler = this.dto.SalerName;

                                if (this.id != null && this.id != undefined && this.id != "") {
                                    if (this.dto && this.dto.IsOrderLock == true) {
                                        ElMessage.warning({
                                            customClass: "testMessage",
                                            dangerouslyUseHTMLString: true,
                                            message: '委托书关联的订单的账单信息已锁定，如需修改，请先至<a href="/#/order/bill/' + this.dto.OrderId + '?backUrl=' + this.$route.fullPath + '" style="font-weight:900;" >订单</a>中进行解锁。',
                                        })
                                        //href="/#/order/bill/' + this.dto.OrderId + '?backUrl=' + this.$route.fullPath + '"
                                        //      /#/order/bill/' + this.dto.OrderId + '?backUrl=' + this.$route.fullPath + '
                                    }
                                }
                            }

                        }
                        if (this.dto && this.dto.Items) {
                            var index = 0;
                            for (var it of this.dto.Items) {
                                it.IsOpen = false;
                                it.Index = index;
                                index++;
                            }

                            if (this.bottomButtons) {
                                if (this.OrderManagement_Pool_Summary_BillingInformation_Submit == true || this.OrderManagement_Order_Summary_BillingInformation_Submit == true) {
                                    this.bottomButtons[3].Hide = false;
                                }
                                else {
                                    this.bottomButtons[3].Hide = true;
                                }
                                if (this.OrderManagement_Pool_Summary_BillingInformation_RevokeSubmission == true || this.OrderManagement_Order_Summary_BillingInformation_RevokeSubmission == true) {
                                    this.bottomButtons[4].Hide = false;
                                }
                                else {
                                    this.bottomButtons[4].Hide = true;
                                }
                            }

                            if (this.id != null && this.id != undefined && this.id != "") {
                                if (this.dto && (this.dto.IsOrderLock == true || this.dto.IsPoolLock == true) && this.bottomButtons) {
                                    this.bottomButtons[1].Hide = true;
                                    this.bottomButtons[2].Hide = true;
                                    this.bottomButtons[3].Hide = true;
                                    this.bottomButtons[4].Hide = true;
                                    this.bottomButtons[5].Hide = true;
                                    this.bottomButtons[6].Hide = true;

                                    //CurrentLogier.GetPowerCodes().then((r) => {
                                    //    if (!r?.contains("OrderManagement_Pool_Summary_BillingInformation_UnlockBilling")) {
                                    //        ElMessage.warning("账单已锁定，如需修改请联系相关人员解锁账单。");
                                    //        return;
                                    //    }
                                    //});
                                }
                            }
                            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                                if (this.dto && this.dto.IsOrderLock == true && this.bottomButtons) {
                                    this.bottomButtons[1].Hide = true;
                                    this.bottomButtons[2].Hide = true;
                                    this.bottomButtons[3].Hide = true;
                                    this.bottomButtons[4].Hide = true;
                                    this.bottomButtons[5].Hide = true;
                                    this.bottomButtons[6].Hide = true;

                                    CurrentLogier.GetPowerCodes().then((r) => {
                                        if (!r?.contains("OrderManagement_Pool_Summary_BillingInformation_UnlockBilling")) {
                                            ElMessage.warning("账单已锁定，如需修改请联系相关人员解锁账单。");
                                            return;
                                        }
                                    });
                                }
                            }

                            if (this.bottomButtons && this.billType == EnumBillType.Saler) {
                                this.bottomButtons[3].Hide = true;
                                this.bottomButtons[4].Hide = true;
                            }

                            //this.isSave = this.dto.IsSave;
                            //if (this.dto && this.dto.IsSave == true && this.bottomButtons) {
                            //    this.bottomButtons[1].Hide = true;
                            //    this.bottomButtons[2].Hide = true;
                            //    this.bottomButtons[3].Hide = true;
                            //    this.bottomButtons[4].Hide = true;

                            //    CurrentLogier.GetPowerCodes().then((r) => {
                            //        if (!r?.contains("OrderManagement_Pool_Summary_BillingInformation_UnlockBilling")) {
                            //            ElMessage.warning("账单已锁定，如需修改请联系相关人员解锁账单。");
                            //            return;
                            //        }
                            //    });
                            //}
                        }
                        this.onSum();
                        this.isLoading = false;

                        CurrentLogier.GetPowerCodes().then((r) => {
                            if (r?.contains("CustomerManagement_Company_CreditAndAccountingPeriod") == true) {
                                this.CustomerManagement_Company_CreditAndAccountingPeriod = true;
                            }

                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_EditAP")) {
                                this.OrderManagement_Order_Summary_BillingInformation_EditAP = true;
                            }

                            if (this.dto.IsSaler == true && r?.contains("OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice")) {
                                this.OrderManagement_Order_Summary_BillingInformation_EditSettlementPrice = true;
                            }

                            if (r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable")) {
                                this.OrderManagement_Order_Summary_BillingInformation_ViewAccountsPayable = true;
                            }

                            if (this.dto.IsSaler == true && r?.contains("OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice")) {
                                this.OrderManagement_Order_Summary_BillingInformation_ViewSettlementPrice = true;
                            }
                        });
                    });
                })

            })
        }
        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                if (Object.prototype.toString.call(url) == '[object Array]') {
                    if (url.length > 0) {
                        var newUrl = url[0];
                        var index = 0;
                        for (var u of url) {
                            if (index > 0) {
                                if (newUrl.indexOf("?") >= 0) {
                                    newUrl += "&backUrl=" + u;
                                }
                                else {
                                    newUrl += "?backUrl=" + u;
                                }
                            }
                            index++;
                        }
                        this.$router.push(newUrl);
                    }

                }
                else {
                    this.$router.push(url);
                }
            }
            else {
                history.go(-1);
            }
            //history.go(-1);
        }
        onChild(row: any): void {
            row.IsOpen = !row.IsOpen;
            (this.$refs["$billTable"] as any).toggleRowExpansion(row, row.IsOpen);
            return;
        }
        onTemplateChild(row: any): void {
            row.IsOpen = !row.IsOpen;
            (this.$refs["$templateCollectionTableFromPayment"] as any).toggleRowExpansion(row, row.IsOpen);
        }
        handleClickTemplate(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.dto && (this.dto.Items == undefined || this.dto.Items == null || this.dto.Items.length <= 0)) {
                        ElMessage.warning("至少需要一条费用信息");
                        complete();
                        return;
                    }
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            MiezzForm.customSubmit(this.$refs, "$refTemplateForm", (v1) => {
                                if (v1 == true) {

                                    this.dto.Name = this.templateForm.Name;
                                    this.dto.IsOpen = this.templateForm.IsOpen;
                                    if (this.type == 0) {
                                        this.dto.PoolId = this.id;
                                        this.dto.OrderId = undefined;
                                    }
                                    else if (this.type == 1) {
                                        this.dto.OrderId = this.orderId;
                                        this.dto.PoolId = undefined;
                                    }
                                    this.isLoading = true;
                                    BillAppService.SaveTemplate(this.dto).then((r1) => {
                                        this.isLoading = false;
                                        ElMessage.success("保存成功");
                                        this.templateForm.Visiable = false;
                                        this.templateForm.Name = "";
                                        complete();
                                    })
                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })
                    break;
                case "close":
                    this.templateForm.Name = "";
                    this.templateForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }
        handleClick(btn: MiezzButton, complete: () => void): void {

            if (this.dto && this.dto.Items) {
                for (var item of this.dto.Items) {
                    if (item.PreSupplierLabel) {
                        item.SupplierLabel = item.PreSupplierLabel.replace("（C）", "");
                        item.SupplierLabel = item.PreSupplierLabel.replace("（S）", "");
                    }

                    if (item.PreClientLabel) {
                        item.ClientLabel = item.PreClientLabel.replace("（C）", "");
                        item.ClientLabel = item.PreClientLabel.replace("（S）", "");
                    }
                }
            }

            switch (btn.Code) {
                case "submit":
                    if (this.selectRows == null || this.selectRows == undefined || this.selectRows.length <=0) {
                        ElMessage.warning("至少需要一条费用信息");
                        complete();
                        return;
                    }

                    var isValidate = true;
                    //if (this.dto && this.dto.Items && this.dto.Items && this.selectRows) {
                    //    for (var i = 0; i < this.dto.Items.length; i++) {
                    //        for (var t of this.selectRows) {
                    //            if (this.dto.Items[i].Id == t.Id) {
                    //                var name = "";
                    //                if (this.billType == 10) {
                    //                    name = "Items." + i + ".PreClientLabel";
                    //                    MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                    if (t.PreClientLabel == null || t.PreClientLabel == undefined || t.PreClientLabel == "") {
                    //                        isValidate = false;
                    //                    }
                    //                }
                    //                else if (this.billType == 20) {
                    //                    name = "Items." + i + ".PreSupplierLabel";
                    //                    MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                    if (t.PreSupplierLabel == null || t.PreSupplierLabel == undefined || t.PreSupplierLabel == "") {
                    //                        isValidate = false;
                    //                    }
                    //                }

                    //                name = "Items." + i + ".CostId";
                    //                MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                if (t.CostId == null || t.CostId == undefined || t.CostId == "") {
                    //                    isValidate = false;
                    //                }

                    //                name = "Items." + i + ".CurrencySystemId";
                    //                MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                if (t.CurrencySystemId == null || t.CurrencySystemId == undefined || t.CurrencySystemId == "") {
                    //                    isValidate = false;
                    //                }

                    //                name = "Items." + i + ".UnitPrice";
                    //                MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                if (t.UnitPrice == null || t.UnitPrice == undefined || t.UnitPrice == "") {
                    //                    isValidate = false;
                    //                }

                    //                name = "Items." + i + ".FirstChargeUnitId";
                    //                MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                if (t.FirstChargeUnitId == null || t.FirstChargeUnitId == undefined || t.FirstChargeUnitId == "") {
                    //                    isValidate = false;
                    //                }

                    //                name = "Items." + i + ".FirstChargeUnitNumber";
                    //                MiezzForm.validateFieldCustom(this.$refs, "$billRuleForm", name);
                    //                if (t.FirstChargeUnitNumber == null || t.FirstChargeUnitNumber == undefined || t.FirstChargeUnitNumber == "") {
                    //                    isValidate = false;
                    //                }
                    //            }
                    //        }
                    //    }
                    //}
                    isValidate = true;
                    if (isValidate == true) {

                        var param = new DtoCallbackSingleBill();
                        param.Items = [];
                        if (this.selectRows) {
                            for (var it2 of this.selectRows) {
                                if (it2.IsSave != true) {
                                    param.Items.push(it2);
                                }
                            }
                        }
                        if (param.Items && param.Items.length > 0) {
                            if (this.type == 0) {
                                param.PoolId = this.id;
                                param.OrderId = undefined;
                            }
                            else if (this.type == 1) {
                                param.OrderId = this.orderId;
                                param.PoolId = undefined;
                            }
                            param.Type = this.billType;
                            param.IsNotDoTask = true;

                            MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                                if (v == true) {

                                    if (this.type == 0) {
                                        this.dto.PoolId = this.id;
                                        this.dto.OrderId = undefined;
                                    }
                                    else if (this.type == 1) {
                                        this.dto.OrderId = this.orderId;
                                        this.dto.PoolId = undefined;
                                    }
                                    this.isLoading = true;
                                    this.dto.IsNotDoTask = true;
                                    this.dto.IsSumbit = true;
                                    this.dto.IsRevoke = false;
                                    if (this.dto && this.dto.Items && param && param.Items) {
                                        for (var it of this.dto.Items) {
                                            for (var it1 of param.Items) {
                                                if (it.Id == it1.Id) {
                                                    it.IsSave = true;
                                                    it.IsSumbit = true;
                                                }
                                            }
                                        }
                                    }
                                    BillAppService.Temporary(this.dto).then((r2) => {

                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = r2.data.Data;
                                        FinanceAppService.FillBillDatas(pa).then((r3) => {


                                            this.isLoading = false;
                                            ElMessage.success("提交成功");
                                            //this.handleGet();
                                            complete();
                                        })
                                    })

                                }
                                else {
                                    complete();
                                }
                            });



                            //if (this.type == 0) {
                            //    this.dto.PoolId = this.id;
                            //    this.dto.OrderId = undefined;
                            //}
                            //else if (this.type == 1) {
                            //    this.dto.OrderId = this.orderId;
                            //    this.dto.PoolId = undefined;
                            //}

          
                            //BillAppService.Temporary(this.dto).then((r2) => {
                            //    BillAppService.Save(param).then((r1) => {
                            //        this.isLoading = false;
                            //        ElMessage.success("保存成功");

                            //        if (this.dto && this.dto.Items && this.selectRows) {
                            //            for (var it of this.dto.Items) {
                            //                for (var it1 of this.selectRows) {
                            //                    if (it.Id == it1.Id) {
                            //                        it.IsSave = true;
                            //                    }
                            //                }
                            //            }
                            //        }

                            //        complete();
                            //    })
                            //})

                        
                        }
                        else {
                            ElMessage.success("所选的账单都已提交，无需重复提交。");
                        }
  
                        complete();
                    }
                    complete();
                    break;
                case "revoke":
                    if (this.selectRows == null || this.selectRows == undefined || this.selectRows.length <= 0) {
                        ElMessage.warning("至少需要一条费用信息");
                        complete();
                        return;
                    }
                    var param1 = new DtoCallbackSingleBill();
                    param1.Items = [];
                    if (this.selectRows) {
                        for (var it3 of this.selectRows) {
                            if (it3.IsSave == true) {
                                param1.Items.push(it3);
                            }
                        }
                    }
                    if (param1.Items && param1.Items.length > 0) {
                        if (this.type == 0) {
                            param1.PoolId = this.id;
                            param1.OrderId = undefined;
                        }
                        else if (this.type == 1) {
                            param1.OrderId = this.orderId;
                            param1.PoolId = undefined;
                        }
                        param1.Type = this.billType;
                        
                        param1.IsNotDoTask = true;
                        if (this.type == 0) {
                            this.dto.PoolId = this.id;
                            this.dto.OrderId = undefined;
                        }
                        else if (this.type == 1) {
                            this.dto.OrderId = this.orderId;
                            this.dto.PoolId = undefined;
                        }

                        MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                            if (v == true) {

                                if (this.type == 0) {
                                    this.dto.PoolId = this.id;
                                    this.dto.OrderId = undefined;
                                }
                                else if (this.type == 1) {
                                    this.dto.OrderId = this.orderId;
                                    this.dto.PoolId = undefined;
                                }
                                this.isLoading = true;
                                this.dto.IsNotDoTask = true;
                                if (this.dto && this.dto.Items && param1 && param1.Items) {
                                    //for (var it12 of this.dto.Items) {
                                    //    it12.IsSave = false;
                                    //    it12.IsRevoke = false;
                                    //}

                                    for (var it10 of this.dto.Items) {
                                        for (var it11 of param1.Items) {
                                            if (it10.Id == it11.Id) {
                                                it10.IsSave = false;
                                                it10.IsSumbit = false;
                                                it10.IsRevoke = true;
                                            }
                                        }
                                    }
                                }
                                this.dto.IsSumbit = false;
                                this.dto.IsRevoke = true;
                                BillAppService.Temporary(this.dto).then((r2) => {

                                    this.isLoading = false;
                                    ElMessage.success("撤销提交成功");
                                    complete();
                                })

                            }
                            else {
                                complete();
                            }
                        });


                        //BillAppService.Temporary(this.dto).then((r2) => {
                        //    BillAppService.RevokeSave(param1).then((r1) => {
                        //        this.isLoading = false;
                        //        ElMessage.success("保存成功");

                        //        if (this.dto && this.dto.Items && this.selectRows) {
                        //            for (var it10 of this.dto.Items) {
                        //                for (var it11 of this.selectRows) {
                        //                    if (it10.Id == it11.Id) {
                        //                        it10.IsSave = false;
                        //                    }
                        //                }
                        //            }
                        //        }

                        //        complete();
                        //    })
                        //})
                    
                    }
                    else {
                        ElMessage.success("所选的账单都已撤销，无需重复撤销。");
                    }
                    complete();
                    break;
                case "save":
                    this.isCheck = true;
              
                    if (this.dto && (this.dto.Items == undefined || this.dto.Items == null || this.dto.Items.length <= 0)) {
                        ElMessage.warning("至少需要一条费用信息");
                        complete();
                        return;
                    }
                    if (this.dto && this.dto.Items && this.selectRows) {
                        for (var it100 of this.dto.Items) {
                            it100.IsSumbit = false;
                            it100.IsRevoke = false;
                        }
                    }
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            //MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                            //    if (v == true) {
                            //        this.saveRuleForm.Data.IsSave = true;
                            //        this.saveRuleForm.Data.Name = "";
                            //        this.saveRuleForm.Data.Visiable = true;
                            //        complete();
                            //    }
                            //    else {
                            //        complete();
                            //    }
                            //});

                            MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                                if (v == true) {

                                    var paramFinish = new DtoRequestFinishPoolBill();
                                    paramFinish.Type = this.billType;
                                    paramFinish.Bills = this.dto.Items;
                                    if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                                        paramFinish.OrderId = this.orderId;
                                    }
                                    BillAppService.IsFinishPoolBill(paramFinish).then((r) => {
                                        if (r.data.Data) {
                                            if (r.data.Data.IsFinish == true) {

                                                if (this.billType == EnumBillType.Collection) {
                                                    ElMessageBox.confirm("保存后账单将自动锁定，是否继续？", "提示", {
                                                        confirmButtonText: "确认",
                                                        cancelButtonText: "取消",
                                                        type: "warning",
                                                    })
                                                        .then(() => {

                                                            if (this.type == 0) {
                                                                this.dto.PoolId = this.id;
                                                                this.dto.OrderId = undefined;
                                                            }
                                                            else if (this.type == 1) {
                                                                this.dto.OrderId = this.orderId;
                                                                this.dto.PoolId = undefined;
                                                            }
                                                            this.isLoading = true;
                                                            this.dto.IsNotDoTask = false;
                                                            this.dto.IsSumbit = false;
                                                            this.dto.IsRevoke = false;
                                                            BillAppService.Save(this.dto).then((r1) => {

                                                                var pa13 = new DtoRequestInvoice();
                                                                pa13.BillIds = r1.data.Data;
                                                                FinanceAppService.FillBillDatas(pa13).then((r3) => {

                                                                    this.isLoading = false;
                                                                    ElMessage.success("保存成功，账单已锁定。");
                                                                    this.handleBack();
                                                                    complete();
                                                                })


                                                            })

                                                        })
                                                        .catch(() => {
                                                            complete();
                                                        });
                                                }
                                                else if (this.billType == EnumBillType.Payment) {
                                                    var count = 0;
                                                    if (this.dto && this.dto.Items) {
                                                        for (var it of this.dto.Items) {
                                                            if (it.PoolId == null || it.PoolId == undefined || it.PoolId == '') {
                                                                if (it.Child == null || it.Child == undefined || it.Child.length <= 0)
                                                                    count++;
                                                            }
                                                        }
                                                    }
                                                    if (count > 0) {
                                                        ElMessageBox.confirm("保存后账单将自动锁定，且存在未分摊的费用，是否继续保存？", "提示", {
                                                            confirmButtonText: "继续保存",
                                                            cancelButtonText: "取消",
                                                            type: "warning",
                                                        })
                                                            .then(() => {

                                                                if (this.type == 0) {
                                                                    this.dto.PoolId = this.id;
                                                                    this.dto.OrderId = undefined;
                                                                }
                                                                else if (this.type == 1) {
                                                                    this.dto.OrderId = this.orderId;
                                                                    this.dto.PoolId = undefined;
                                                                }
                                                                this.isLoading = true;
                                                                this.dto.IsNotDoTask = false;
                                                                this.dto.IsSumbit = false;
                                                                this.dto.IsRevoke = false;
                                                                BillAppService.Save(this.dto).then((r1) => {

                                                                    var pa10 = new DtoRequestInvoice();
                                                                    pa10.BillIds = r1.data.Data;
                                                                    FinanceAppService.FillBillDatas(pa10).then((r3) => {

                                                                        this.isLoading = false;
                                                                        ElMessage.success("保存成功，账单已锁定。");
                                                                        this.handleBack();
                                                                        complete();
                                                                    })

                                                                })

                                                            })
                                                            .catch(() => {
                                                                complete();
                                                            });
                                                    }
                                                    else {
                                                        if (this.type == 0) {
                                                            this.dto.PoolId = this.id;
                                                            this.dto.OrderId = undefined;
                                                        }
                                                        else if (this.type == 1) {
                                                            this.dto.OrderId = this.orderId;
                                                            this.dto.PoolId = undefined;
                                                        }

                                                        ElMessageBox.confirm("保存后账单将自动锁定，是否继续？", "提示", {
                                                            confirmButtonText: "确认",
                                                            cancelButtonText: "取消",
                                                            type: "warning",
                                                        })
                                                            .then(() => {

                                                                this.isLoading = true;
                                                                this.dto.IsNotDoTask = false;
                                                                this.dto.IsSumbit = false;
                                                                this.dto.IsRevoke = false;
                                                                BillAppService.Save(this.dto).then((r1) => {

                                                                    var pa11 = new DtoRequestInvoice();
                                                                    pa11.BillIds = r1.data.Data;
                                                                    FinanceAppService.FillBillDatas(pa11).then((r3) => {
                                                                        this.isLoading = false;
                                                                        ElMessage.success("保存成功，账单已锁定。");
                                                                        this.handleBack();
                                                                        complete();
                                                                    })


                                                                })


                                                            })
                                                            .catch(() => {
                                                                complete();
                                                            });

                                                    }
                                                }
                                                else {
                                                    if (this.type == 0) {
                                                        this.dto.PoolId = this.id;
                                                        this.dto.OrderId = undefined;
                                                    }
                                                    else if (this.type == 1) {
                                                        this.dto.OrderId = this.orderId;
                                                        this.dto.PoolId = undefined;
                                                    }

                                                    ElMessageBox.confirm("保存后账单将自动锁定，是否继续？", "提示", {
                                                        confirmButtonText: "确认",
                                                        cancelButtonText: "取消",
                                                        type: "warning",
                                                    })
                                                        .then(() => {

                                                            this.isLoading = true;
                                                            this.dto.IsNotDoTask = false;
                                                            this.dto.IsSumbit = false;
                                                            this.dto.IsRevoke = false;
                                                            BillAppService.Save(this.dto).then((r1) => {

                                                                var pa12 = new DtoRequestInvoice();
                                                                pa12.BillIds = r1.data.Data;
                                                                FinanceAppService.FillBillDatas(pa12).then((r3) => {
                                                                    this.isLoading = false;
                                                                    ElMessage.success("保存成功，账单已锁定。");
                                                                    this.handleBack();
                                                                    complete();
                                                                })


                                                                
                                                            })


                                                        })
                                                        .catch(() => {
                                                            complete();
                                                        });



                                                }

                                            }
                                            else {
                                                ElMessage.warning("Booking NO " + r.data.Data.BLNO + " 没有录入费用，无法保存，请使用暂存功能");
                                                complete();
                                                return;
                                            }
                                        }
                                    })



                                }
                                else {
                                    complete();
                                }
                            });




                        }
                    })
                    break;
                case "template":
                    if (this.dto && (this.dto.Items == undefined || this.dto.Items == null || this.dto.Items.length <= 0)) {
                        ElMessage.warning("至少需要一条费用信息");
                        complete();
                        return;
                    }

                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {

                            this.templateForm.Name = "";
                            this.templateForm.Visiable = true;
                            this.templateForm.IsOpen = false;
                            complete();
                        }
                    })
                    break;
                case "temporary":
                    this.isCheck = true;
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.id != undefined && this.id != "") {
                        this.lockParam.PoolId.push(this.id as string);
                    }
                    else if (this.orderId != undefined && this.orderId != "") {
                        this.lockParam.OrderId.push(this.orderId as string);
                    }
                    if (this.dto && this.dto.Items && this.selectRows) {
                        for (var it101 of this.dto.Items) {
                            it101.IsSumbit = false;
                            it101.IsRevoke = false;
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("订单已锁定");
                            complete();
                            return;
                        }
                        else {



                            MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                                if (v == true) {

                                    if (this.type == 0) {
                                        this.dto.PoolId = this.id;
                                        this.dto.OrderId = undefined;
                                    }
                                    else if (this.type == 1) {
                                        this.dto.OrderId = this.orderId;
                                        this.dto.PoolId = undefined;
                                    }
                                    this.isLoading = true;
                                    this.dto.IsNotDoTask = false;
                                    this.dto.IsSumbit = false;
                                    this.dto.IsRevoke = false;
                                    BillAppService.Temporary(this.dto).then((r1) => {

                                        var pa = new DtoRequestInvoice();
                                        pa.BillIds = r1.data.Data;
                                        FinanceAppService.FillBillDatas(pa).then((r3) => {


                                            this.isLoading = false;
                                            ElMessage.success("暂存成功");
                                            //this.handleBack();
                                            complete();
                                        })



                                    })

                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })
                    break;
                case "import":
                    if (this.orderId != null && this.orderId != undefined && this.orderId != '') {
                        this.isImport = true;
                        this.onAdd();
                        complete();
                    }
                    else {
                        var request = new DtoRequestTemplate();
                        request.OrderId = this.orderId;
                        request.PoolId = this.id;
                        request.Type = this.billType;
                        request.SelectPoolId = this.id;
                        BillAppService.GetTemplate(request).then((c) => {
                            if (c.data.Data) this.template = c.data.Data;
                            if (this.selectBillObject) {
                                this.selectBillObject.BillLabel = this.template.ClientLabel as string;
                                this.selectBillObject.CompanyId = this.template.ClientCompanyId as string;
                                this.selectBillObject.UserId = this.template.ClientUserId as string;
                            }
                            this.activeName = "0";
                            this.importForm.Visiable = true;
                            complete();
                        })

                    }

                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        onCurrency(row: any): void {
            if (row.IsSave == true) {
                ElMessage.warning("账单已被锁定，不允许编辑。");
                return;
            }
            if (row.IsSettlement == true) {
                ElMessage.warning("账单已有核销信息，不允许编辑。");
                return;
            }
            if (row.IsInvoice == true) {
                ElMessage.warning("账单已有发票信息，不允许编辑。");
                return;
            }
            var currencyId = row.CurrencySystemId;
            var index = row.Id;
            this.lockParam.BillId = [];
            this.lockParam.OrderId = [];
            this.lockParam.PoolId = [];
            if (this.id != undefined && this.id != "") {
                this.lockParam.PoolId.push(this.id as string);
            }
            else if (this.orderId != undefined && this.orderId != "") {
                this.lockParam.OrderId.push(this.orderId as string);
            }
            BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                if (lock.data.Data == true) {
                    ElMessage.warning("订单已锁定");
                    return;
                }
                else {
                    this.currencyIndex = index;
                    const Currency = this.$refs["Currency"] as any;
                    Currency.onInit(currencyId);
                }
            })


        }
        onSelectCurrency(row: any): void {
            if (this.dto && this.dto.Items) {
                for (var it of this.dto.Items) {
                    if (it.Id == this.currencyIndex) {
                        it.CurrencySystemId = row.Value;
                        it.CurrencySystemName = row.Data.Code;
                        it.CurrencySystem = row.Data;
                    }
                }
            }
            this.onSum();
        }

        onChangeCurrencySystem(index: number): void {
            if (this.dto && this.dto.Items) {
                for (var currency of this.currencyList) {
                    if (currency.Value == this.dto.Items[index].CurrencySystemId) {
                        var row = currency;
                        row.Code = currency.Label;
                        for (var it of this.dto.Items) {
                            if (it.Index == index) {
                                it.CurrencySystemId = row.Value;
                                it.CurrencySystemName = row.Code;
                                it.CurrencySystem = row;
                            }
                        }
                    }
                }

            }
            this.onSum();
        }


        querySearchCost(queryString: string, cb: any) {
            var restaurants = this.costs;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.costs) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.costs) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.costs) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.costs.length; i++) {
                            if (i < 10) {
                                results.push(this.costs[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCost(item: UiSelectOption<string>, index: number) {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].CostId = item.Value;
                this.dto.Items[index].CostName = item.Label;
                this.dto.Items[index].Cost = item;
                var cost = this.dto.Items[index].Cost;
                if (cost) {
                    cost.NameZh = item.Data.NameZh;
                }
                this.onGetAccountPeriod(index);
                this.tabelRandom = Math.random();
            }
        }
        onChangeCostId(index: number): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items[index].CostName == null || this.dto.Items[index].CostName == undefined || this.dto.Items[index].CostName == "") {
                    this.dto.Items[index].CostId = undefined;
                    this.dto.Items[index].Cost = undefined;
                }
                else {
                    if (this.dto.Items[index].CostId == undefined) {
                        //this.dto.Items[index].CostName = undefined;
                        this.dto.Items[index].Cost = undefined;
                    }
                    else if (this.dto.Items[index].CostName != undefined) {
                        var flag = false;
                        if (this.costs) {
                            for (var it of this.costs) {
                                if (it.Label == this.dto.Items[index].CostName) {
                                    this.dto.Items[index].CostId = it.Value;
                                    this.dto.Items[index].Cost = it;
                                    var cost = this.dto.Items[index].Cost;
                                    if (cost) {
                                        cost.NameZh = it.Data.NameZh;
                                    }
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                //this.dto.Items[index].CostName = undefined;
                                this.dto.Items[index].CostId = undefined;
                                this.dto.Items[index].Cost = undefined;
                            }
                        }
                    }
                }
            }
        }
        querySearchAccount(queryString: string, cb: any, index: number) {

            var results = [];
            if (this.dto && this.dto.Items) {
                var accounts = this.dto.Items[index].Accounts;
                if (this.billType == EnumBillType.Collection) {
                    if (this.dto.Items[index].CostName == undefined || this.dto.Items[index].CostName == null || this.dto.Items[index].CostName == "" ||
                        this.dto.Items[index].ClientCompanyId == undefined || this.dto.Items[index].ClientCompanyId == null || this.dto.Items[index].ClientCompanyId == "") {
                        accounts = [];
                    }
                }
                else if (this.billType == EnumBillType.Payment) {
                    if (this.dto.Items[index].CostName == undefined || this.dto.Items[index].CostName == null || this.dto.Items[index].CostName == "" ||
                        this.dto.Items[index].SupplierCompanyId == undefined || this.dto.Items[index].SupplierCompanyId == null || this.dto.Items[index].SupplierCompanyId == "") {
                        accounts = [];
                    }
                }
                if (accounts) {
                    var restaurants = accounts;
                    if (!restaurants) {
                        restaurants = [];
                    }

                    if (queryString != null && queryString && queryString != "null" && queryString != '' && accounts) {
                        queryString = queryString.toLowerCase();
                        var number = 0;
                        for (var it of accounts) {
                            if (it.Label) {
                                var Name = it.Label.toLowerCase();
                                if (Name.indexOf(queryString) >= 0 && number < 30) {
                                    results.push(it);
                                    number++;
                                }
                            }
                        }
                    }
                    else {
                        if (this.dto.Items[index].Accounts) {
                            var number1 = 0;
                            if (number1 <= 0) {
                                for (var i = 0; i < accounts.length; i++) {
                                    results.push(accounts[i])
                                }
                            }
                        }
                    }
                }

            }
            cb(results);

        }
        handleSelectAccount(item: UiSelectOption<string>, index: number) {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].AccountPeriodId = item.Value;
                this.dto.Items[index].AccountPeriod = item.Data.AccountPeriodDay;
            }
        }
        onChangeAccount(index: number): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items[index].AccountPeriod == undefined) {
                    this.dto.Items[index].AccountPeriodId = undefined;
                }
            }
        }


        querySearchClientCompany(queryString: string, cb: any) {
            var restaurants = this.companyUsers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companyUsers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companyUsers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companyUsers) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.companyUsers.length; i++) {
                            if (i < 10) {
                                results.push(this.companyUsers[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectClientCompany(item: UiSelectOption<string>, index: number) {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].ClientUserId = item.Value;
                this.dto.Items[index].ClientCompanyId = item.Data.CompanyId;
                this.dto.Items[index].PreClientLabel = item.Label;
                this.onGetAccountPeriod(index);
            }
        }
        onChangeClientCompanyId(index: number): void {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].Accounts = [];
                if (this.dto.Items[index].PreClientLabel == undefined || this.dto.Items[index].PreClientLabel == "") {
                    this.dto.Items[index].ClientCompanyId = undefined;
                    this.dto.Items[index].ClientUserId = undefined;
                }
                else {
                    if (this.dto.Items[index].PreClientLabel != undefined) {
                        var flag = false;
                        if (this.companyUsers) {
                            for (var it of this.companyUsers) {
                                if (it.Label == this.dto.Items[index].PreClientLabel) {
                                    this.dto.Items[index].ClientUserId = it.Value;
                                    this.dto.Items[index].ClientCompanyId = it.Data.CompanyId;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.dto.Items[index].ClientUserId = undefined;
                                this.dto.Items[index].ClientCompanyId = undefined;
                            }
                        }
                    }
                }

            }

            this.onGetAccountPeriod(index);
        }


        querySearchClientCompanyForPoolBill(queryString: string, cb: any) {
            var restaurants = this.companyUsers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companyUsers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companyUsers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.billType == 20) {
                    if (this.companyUsers) {
                        var number10 = 0;
                        for (var i1 = 0; i1 < this.companyUsers.length; i1++) {
                            if (number10 < 10) {
                                if (this.companyUsers[i1].Data.UserId == null || this.companyUsers[i1].Data.UserId == undefined) {
                                    results.push(this.companyUsers[i1])
                                    number10++;
                                }
                            }
                        }
                    }
                }
                else {
                    if (this.companyUsers) {
                        var number1 = 0;
                        if (number1 <= 0) {
                            for (var i = 0; i < this.companyUsers.length; i++) {
                                if (i < 10) {
                                    results.push(this.companyUsers[i])
                                }
                            }
                        }
                    }
                }

            }

            cb(results);
        }
        handleSelectClientCompanyForPoolBill(item: UiSelectOption<string>) {
            if (this.addPoolBillForm) {
                this.addPoolBillForm.SelectUserId = item.Value;
                this.addPoolBillForm.SelectId = item.Data.CompanyId;
                this.addPoolBillForm.SelectLabel = item.Label;
            }
        }
        onChangeClientCompanyIdForPoolBill(): void {
            if (this.addPoolBillForm) {
                if (this.addPoolBillForm.SelectLabel == undefined || this.addPoolBillForm.SelectLabel == "") {
                    this.addPoolBillForm.SelectId = undefined;
                    this.addPoolBillForm.SelectUserId = undefined;
                }
                else {
                    if (this.addPoolBillForm.SelectLabel != undefined) {
                        var flag = false;
                        if (this.companyUsers) {
                            for (var it of this.companyUsers) {
                                if (it.Label == this.addPoolBillForm.SelectLabel) {
                                    this.addPoolBillForm.SelectUserId = it.Value;
                                    this.addPoolBillForm.SelectId = it.Data.CompanyId;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.addPoolBillForm.SelectId = undefined;
                                this.addPoolBillForm.SelectUserId = undefined;
                            }
                        }
                    }
                }

            }
        }


        onGetAccountPeriod(index: number): void {
            if (this.billType == 10) {
                if (this.dto && this.dto.Items) {
                    if (this.dto.Items[index].ClientCompanyId != null && this.dto.Items[index].ClientCompanyId != undefined) {
                        if (this.dto.Items[index].CostId != null && this.dto.Items[index].CostId != undefined) {
                            var param01 = new DtoCallbackAccountPeriod();
                            if (this.dto.Items[index].PoolId != null && this.dto.Items[index].PoolId != undefined && this.dto.Items[index].PoolId != "") {
                                param01.OrderId = undefined;
                                param01.PoolId = this.dto.Items[index].PoolId;
                            }
                            else if (this.dto.Items[index].OrderId != null && this.dto.Items[index].OrderId != undefined && this.dto.Items[index].OrderId != "") {
                                param01.PoolId = undefined;
                                param01.OrderId = this.dto.Items[index].OrderId;
                            }
                            param01.CostId = this.dto.Items[index].CostId;
                            param01.ClientCompanyId = this.dto.Items[index].ClientCompanyId;
                            param01.SupplierCompanyId = undefined;
                            param01.Type = this.billType;

                            BillAppService.GetAccountPerod(param01).then((r) => {
                                if (r.data.Data && this.dto.Items) {
                                    if (this.dto.Items[index].StartDate == null || this.dto.Items[index].StartDate == undefined) {
                                        this.dto.Items[index].StartDate = r.data.Data.Start;
                                    }
                                    if (this.dto.Items[index].AccountPeriod == null || this.dto.Items[index].AccountPeriod == undefined) {
                                        this.dto.Items[index].AccountPeriod = r.data.Data.Day;
                                    }

                                    if (this.dto.Items[index].IsSettlement != true && this.dto.Items[index].IsSave != true && this.dto.Items[index].IsInvoice != true) {
                                        this.dto.Items[index].StartDate = r.data.Data.Start;
                                        this.dto.Items[index].AccountPeriod = r.data.Data.Day;
                                    }
                                }
                            })
                        }
                    }
                }
            }
            else if (this.billType == 20) {
                if (this.dto && this.dto.Items) {
                    if (this.dto.Items[index].SupplierCompanyId != null && this.dto.Items[index].SupplierCompanyId != undefined) {
                        if (this.dto.Items[index].CostId != null && this.dto.Items[index].CostId != undefined) {
                            var param = new DtoCallbackAccountPeriod();
                            if (this.dto.Items[index].PoolId != null && this.dto.Items[index].PoolId != undefined && this.dto.Items[index].PoolId != "") {
                                param.OrderId = undefined;
                                param.PoolId = this.dto.Items[index].PoolId;
                            }
                            else if (this.dto.Items[index].OrderId != null && this.dto.Items[index].OrderId != undefined && this.dto.Items[index].OrderId != "") {
                                param.PoolId = undefined;
                                param.OrderId = this.dto.Items[index].OrderId;
                            }
                            param.CostId = this.dto.Items[index].CostId;
                            param.SupplierCompanyId = this.dto.Items[index].SupplierCompanyId;
                            param.ClientCompanyId = undefined;
                            param.Type = this.billType;

                            BillAppService.GetAccountPerod(param).then((r) => {
                                if (r.data.Data && this.dto.Items) {
                                    if (this.dto.Items[index].StartDate == null || this.dto.Items[index].StartDate == undefined) {
                                        this.dto.Items[index].StartDate = r.data.Data.Start;
                                    }
                                    if (this.dto.Items[index].AccountPeriod == null || this.dto.Items[index].AccountPeriod == undefined) {
                                        this.dto.Items[index].AccountPeriod = r.data.Data.Day;
                                    }
                                }
                            })
                        }
                    }
                }
            }
        }


        querySearchBillClientCompany(queryString: string, cb: any) {
            var restaurants = this.companyUsers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companyUsers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companyUsers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companyUsers) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.companyUsers.length; i++) {
                            if (i < 10) {
                                results.push(this.companyUsers[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectBillClientCompany(item: UiSelectOption<string>) {
            if (this.selectBillObject) {
                this.selectBillObject.UserId = item.Value as string;
                this.selectBillObject.CompanyId = item.Data.CompanyId as string;
                this.selectBillObject.BillLabel = item.Label as string;
            }
        }
        onChangeBillClientCompanyId(index: number): void {
            if (this.selectBillObject) {
                if (this.selectBillObject.BillLabel == undefined || this.selectBillObject.BillLabel == "") {
                    this.selectBillObject.CompanyId = "";
                    this.selectBillObject.UserId = "";
                    this.selectBillObject.BillLabel = "";
                }
                else {
                    if (this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                        var flag = false;
                        if (this.companyUsers) {
                            for (var it of this.companyUsers) {
                                if (it.Label == this.selectBillObject.BillLabel) {
                                    this.selectBillObject.UserId = it.Value as string;
                                    this.selectBillObject.CompanyId = it.Data.CompanyId as string;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.selectBillObject.UserId = "";
                                this.selectBillObject.CompanyId = "";
                            }
                        }
                    }
                }
            }
        }

        querySearchBillSupplierCompany(queryString: string, cb: any) {
            var restaurants = this.companyUsers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companyUsers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companyUsers) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companyUsers) {
                    var number1 = 0;
                    for (var i = 0; i < this.companyUsers.length; i++) {
                        if (number1 < 10) {
                            if (this.companyUsers[i].Data.UserId == null || this.companyUsers[i].Data.UserId == undefined) {
                                results.push(this.companyUsers[i])
                                number1++;
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectBillSupplierCompany(item: UiSelectOption<string>, index: number) {
            if (this.selectBillObject) {
                this.selectBillObject.UserId = item.Value as string;
                this.selectBillObject.CompanyId = item.Data.CompanyId as string;
                this.selectBillObject.BillLabel = item.Label as string;
            }
        }
        onChangeBillSupplierCompanyId(index: number): void {
            if (this.selectBillObject) {
                if (this.selectBillObject.BillLabel == undefined || this.selectBillObject.BillLabel == "") {
                    this.selectBillObject.CompanyId = "";
                    this.selectBillObject.UserId = "";
                    this.selectBillObject.BillLabel = "";
                }
                else {
                    if (this.selectBillObject.BillLabel != undefined && this.selectBillObject.BillLabel != "") {
                        var flag = false;
                        if (this.companyUsers) {
                            for (var it of this.companyUsers) {
                                if (it.Label == this.selectBillObject.BillLabel) {
                                    this.selectBillObject.UserId = it.Value as string;
                                    this.selectBillObject.CompanyId = it.Data.CompanyId as string;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.selectBillObject.UserId = "";
                                this.selectBillObject.CompanyId = "";
                            }
                        }
                    }
                }
            }
        }


        querySearchSupplierCompany(queryString: string, cb: any) {
            var restaurants = this.companyUsers;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.companyUsers) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.companyUsers) {
                    if (it.Label) {
                        console.log(number);
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.companyUsers) {
                    var number1 = 0;
                    for (var i = 0; i < this.companyUsers.length; i++) {
                        if (number1 < 10) {
                            if (this.companyUsers[i].Data.UserId == null || this.companyUsers[i].Data.UserId == undefined) {
                                results.push(this.companyUsers[i])
                                number1++;
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectSupplierCompany(item: UiSelectOption<string>, index: number) {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].SupplierUserId = item.Value;
                this.dto.Items[index].SupplierCompanyId = item.Data.CompanyId;
                this.dto.Items[index].PreSupplierLabel = item.Label;
                this.onGetAccountPeriod(index);
            }
        }
        onChangeSupplierCompanyId(index: number): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items[index].PreSupplierLabel == undefined || this.dto.Items[index].PreSupplierLabel == "") {
                    this.dto.Items[index].SupplierCompanyId = undefined;
                    this.dto.Items[index].SupplierUserId = undefined;
                }
                else {
                    //if (this.dto.Items[index].SupplierUserId == undefined) {
                    //    this.dto.Items[index].SupplierLabel = undefined;
                    //}
                    //else if (this.dto.Items[index].SupplierLabel != undefined) {

                    //}
                    var flag = false;
                    if (this.costs) {
                        for (var it of this.costs) {
                            if (it.Label == this.dto.Items[index].PreSupplierLabel) {
                                this.dto.Items[index].SupplierUserId = it.Value;
                                this.dto.Items[index].SupplierCompanyId = it.Data.CompanyId;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.dto.Items[index].SupplierCompanyId = undefined;
                            this.dto.Items[index].SupplierUserId = undefined;
                            this.dto.Items[index].SupplierCompany = undefined;
                        }
                    }
                }

            }
            this.onGetAccountPeriod(index);
        }

        onDepartureChargeWeight(): void {
            if (this.id != null && this.id != undefined && this.id != "") {
                window.open(
                    `#/pool/departure/wharf/` + this.id,
                    "_blank"
                );
            }
            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                window.open(
                    `#/order/departure/wharf/` + this.orderId,
                    "_blank"
                );
            }

        }
        onDestinationChargeWeight(): void {
            if (this.id != null && this.id != undefined && this.id != "") {
                window.open(
                    `#/pool/destination/wharf/` + this.id,
                    "_blank"
                );
            }
            else if (this.orderId != null && this.orderId != undefined && this.orderId != "") {
                window.open(
                    `#/order/destination/wharf/` + this.orderId,
                    "_blank"
                );
            }
        }

        onSum(): void {
            if (this.dto) {
                this.dto.Total = "合计金额：CNY0";
            }
            if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                var index = 0;
                for (var it of this.dto.Items) {
                    it.Index = index;
                    index++;
                    it.FirstChargeUnitValue = undefined;
                    if (it.FirstChargeUnitNumber != undefined && it.FirstChargeUnitId != undefined) {
                        it.FirstChargeUnitValue = it.FirstChargeUnitNumber;
                    }
                    it.TotalPrice = undefined;
                    if (it.UnitPrice != undefined && it.FirstChargeUnitNumber != undefined && it.UnitPrice != null && it.FirstChargeUnitNumber != null) {
                        if (it.SecondChargeUnitNumber != undefined && it.SecondChargeUnitNumber != null) {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber * it.SecondChargeUnitNumber;
                        }
                        else {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber;
                        }
                    }
                    if (it.TotalPrice != undefined) {
                        var result = setDecimal(it.TotalPrice.toString(), 2, true, true);
                        if (result != "") {
                            it.TotalPrice = Number(result);
                        }
                        else {
                            it.TotalPrice = undefined;
                        }
                    }
                }

                var currencys = [];
                for (var it1 of this.dto.Items) {
                    var exist = false;
                    for (var cu of currencys) {
                        if (cu == it1.CurrencySystemId) {
                            exist = true;
                        }
                    }
                    if (exist == false) {
                        currencys.push(it1.CurrencySystemId);
                    }
                }
                this.dto.Total = "合计金额：";
                for (var cu1 of currencys) {
                    var code = "";
                    var totalPrice = 0;
                    for (var it2 of this.dto.Items) {
                        if (it2.CurrencySystemId == cu1) {
                            if (it2.TotalPrice != undefined) {
                                totalPrice += it2.TotalPrice;
                            }
                            if (it2.CurrencySystem && it2.CurrencySystem.Code != undefined) {
                                code = it2.CurrencySystem.Code;
                            }
                        }
                    }

                    if (totalPrice != undefined) {
                        var result1 = setDecimal(totalPrice.toString(), 2, true, true);
                        if (result1 != "") {
                            totalPrice = Number(result1);
                        }
                        else {
                            totalPrice = 0;
                        }
                    }


                    this.dto.Total += " " + code + totalPrice;
                }
            }
        }
    }


    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            if (isLessZero == true) {
                if (str[0] == "-") {
                    result = "-" + result;
                }
            }
            return result;
        }
    };
