
import GroupAppService from "@/apis.machine/GroupAppService";
import DtoTreeGroup from "@/models.machine/DtoTreeGroup";
import DtoTreeSearchGroup from "@/models.machine/DtoTreeSearchGroup";
import { EnumMenuSceneOption } from "@/models.machine/EnumMenuScene";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import UiTreeDrag from "@/models.machine/UiTreeDrag";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal from "@/models/MiezzModal";
import router from "@/router";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import GroupDetail from "./GroupDetail.vue";
import GroupForm from "./GroupForm.vue";
@Options({
  components: {
    GroupForm,
    GroupDetail,
  },
})
export default class GroupTree extends Vue {
  models: DtoTreeGroup[] = [];
  dto: any = new DtoTreeSearchGroup();
  /**绑定属性 */
  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  modalDetail = new MiezzModal();
  modalForm = new MiezzModal();

  select = new DtoTreeGroup();

  id?: string;
  path?: string;

  EnumMenuSceneOption = EnumMenuSceneOption;
  topButtons: MiezzButton[] = [];

  created(): void {
    this.topButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
    ];

    const query = router.currentRoute.value.query;
    for (const key in query) {
      this.dto[key] = query[key];
    }
    this.handleGet();
  }

  /**事件-获取 */
  handleGet(): void {
    /**路由地址追加分页参数 */
    const current = router.currentRoute.value;
    for (const key in this.dto) {
      current.query[key] = this.dto[key];
    }
    router.push({
      path: current.path,
      params: current.params,
      query: current.query,
      force: true,
    });
    GroupAppService.GetTree(this.dto).then((r) => {
      if (r.data.Data) this.models = r.data.Data;
    });
  }

  /**事件-单选搜索 */
  handleRadioSearch(): void {
    this.handleGet();
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增部门";
        this.id = undefined;
        this.path = "/";
        complete();
        break;
      default:
        break;
    }
  }

  handleNodeClick(data: DtoTreeGroup): void {
    this.modalDetail.Show = true;
    this.modalDetail.Title = `部门详细-${data.Name}`;
    this.select = data;
    this.id = this.select.Id;
    this.path = undefined;
  }

  /**表单提交 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  handleDelete(): void {
    this.modalDetail.Show = false;
    this.handleGet();
  }

  handleDrop(
    draggingNode: any,
    dropNode: any,
    dropType: string,
    ev: any
  ): void {
    const dragging: DtoTreeGroup = draggingNode.data;
    const drop: DtoTreeGroup = dropNode.data;
    const dto = new UiTreeDrag<string>();
    dto.DraggingId = dragging.Id;
    dto.DraggingParentId = dragging.ParentId;
    dto.DropId = drop.Id;
    dto.DropParentId = drop.ParentId;
    dto.DragType =
      dropType == "before"
        ? EnumUiDragType.Before
        : dropType == "after"
        ? EnumUiDragType.After
        : EnumUiDragType.Inner;
    GroupAppService.DragSort(dto).then(() => {
      ElMessage.success("排序成功");
      this.handleGet();
    });
  }
}
