import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamCompanyCodeType from "../models.machine/DtoSelectSearchParamCompanyCodeType";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamCompanyCodeType from "../models.machine/DtoPageListSearchParamCompanyCodeType";
import DtoPageListParamCompanyCodeType from "../models.machine/DtoPageListParamCompanyCodeType";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamCompanyCodeType from "../models.machine/DtoDetailParamCompanyCodeType";
import DtoFormParamCompanyCodeType from "../models.machine/DtoFormParamCompanyCodeType";
import DtoListItemParamCompanyCodeType_Repeat from "../models.machine/DtoListItemParamCompanyCodeType_Repeat";

/**
 * 企业代码类型
 * @description 自动生成的代码,请勿修改
 */
export default class ParamCompanyCodeTypeAppService {
    /**
     * 获取企业代码类型选择器
     * @param {DtoSelectSearchParamCompanyCodeType} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamCompanyCodeType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCompanyCodeType/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取企业代码类型分页列表
     * @param {DtoPageListSearchParamCompanyCodeType} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamCompanyCodeType>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamCompanyCodeType): AxiosPromise<RESTfulResult<DtoPageListParamCompanyCodeType>> {
        const url = `/api/ParamCompanyCodeType/Get`;
        return service.get<RESTfulResult<DtoPageListParamCompanyCodeType>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCompanyCodeType/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取企业代码类型详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamCompanyCodeType>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamCompanyCodeType>> {
        const url = `/api/ParamCompanyCodeType/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamCompanyCodeType>>(url);
    }

    /**
     * 新增/编辑企业代码类型
     * @param {DtoFormParamCompanyCodeType} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamCompanyCodeType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCompanyCodeType/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除企业代码类型
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCompanyCodeType/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCompanyCodeType/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCompanyCodeType/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamCompanyCodeType/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamCompanyCodeType_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamCompanyCodeType_Repeat[]>> {
        const url = `/api/ParamCompanyCodeType/GetList`;
        return service.get<RESTfulResult<DtoListItemParamCompanyCodeType_Repeat[]>>(url);
    }

}
