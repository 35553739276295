import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoFormCodeMethodGetPageList from "../models.machine/DtoFormCodeMethodGetPageList";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoFormCodeMethod from "../models.machine/DtoFormCodeMethod";

/**
 * 方法
 * @description 自动生成的代码,请勿修改
 */
export default class CodeMethodAppService {
    /**
     * 添加分页列表方法
     * @param {DtoFormCodeMethodGetPageList} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AddGetPageListMethod(dto?: DtoFormCodeMethodGetPageList): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CodeMethod/AddGetPageListMethod`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 添加方法
     * @param {DtoFormCodeMethod} [dto] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static AddMethod(dto?: DtoFormCodeMethod): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CodeMethod/AddMethod`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
