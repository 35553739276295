import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_form = _resolveComponent("company-form")!

  return (_openBlock(), _createBlock(_component_company_form, {
    type: _ctx.type,
    form: _ctx.form,
    companyTypes: _ctx.companyTypes,
    nameEnAbbr: true
  }, null, 8, ["type", "form", "companyTypes"]))
}