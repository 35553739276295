
import CompanyAppService from "@/apis.machine/CompanyAppService";
import HistoryAppService from "@/apis.machine/HistoryAppService";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoHistory from "@/models.machine/DtoHistory";
import DtoListItemCompanyCustomerRelation, {
  DtoListItemCompanyCustomerRelationMiezzListItems,
} from "@/models.machine/DtoListItemCompanyCustomerRelation";
import DtoPageListCompanyCustomerRelation from "@/models.machine/DtoPageListCompanyCustomerRelation";
import DtoPageListSearchCompanyCustomerRelation, {
  DtoPageListSearchCompanyCustomerRelationDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompanyCustomerRelation";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CustomerCompanyRelationPageList extends Vue {
  list = new MiezzPageList<
    DtoPageListSearchCompanyCustomerRelation,
    DtoListItemCompanyCustomerRelation,
    string
  >("CustomerManagement_MainRelation");
  modalHistories = new MiezzModal();
  histories: DtoHistory[] = [];
  salesmans: UiSelectOption<string>[] = [];
  customerServices: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    this.list.Dto = new DtoPageListSearchCompanyCustomerRelation();
    this.list.DynamicSearchItems =
      DtoPageListSearchCompanyCustomerRelationDynamicSearchItems;
    this.list.Data = new DtoPageListCompanyCustomerRelation();
    this.list.Items = DtoListItemCompanyCustomerRelationMiezzListItems;
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Salesman") {
        return `${row.Salesman?.Name ?? "暂无数据"}/${
          row.Salesman?.User?.PhoneNumber ?? "暂无数据"
        }`;
      } else if (item.Prop == "CustomerService") {
        return `${row.CustomerService?.Name ?? "暂无数据"}/${
          row.CustomerService?.User?.PhoneNumber ?? "暂无数据"
        }`;
      }
      return undefined;
    };
    this.list.RowButtons = [
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Agreement_History",
      },
    ];

    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["IdentityPermission_Salesman"],
    }).then((r) => {
      this.salesmans = r.data.Data ?? [];
      const options =
        r.data.Data?.map((it) => {
          return {
            Code: it.Value,
            Label: it.Label,
          };
        }) ?? [];
      options.push({
        Code: "",
        Label: "空",
      });
      this.list.BottomButtons.push({
        Code: "salesman",
        Label: "指派业务员",
        Icon: "avatar",
        Type: "text",
        Options: options,
        Sort: 1,
      });
      this.list.BottomButtons = this.list.BottomButtons.sort(
        (a, b) => (a.Sort ?? 0) - (b.Sort ?? 0)
      );
    });

    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["IdentityPermission_CustomerService"],
    }).then((r) => {
      this.customerServices = r.data.Data ?? [];
      const options =
        r.data.Data?.map((it) => {
          return {
            Code: it.Value,
            Label: it.Label,
          };
        }) ?? [];
      options.push({
        Code: "",
        Label: "空",
      });
      this.list.BottomButtons.push({
        Code: "customer-service",
        Label: "指派客服",
        Icon: "avatar",
        Type: "text",
        Options: options,
        Sort: 2,
      });
      this.list.BottomButtons = this.list.BottomButtons.sort(
        (a, b) => (a.Sort ?? 0) - (b.Sort ?? 0)
      );
    });

    this.list.BottomButtons.push({
      Code: "build-settlement-bill",
      Label: "结算账单设置",
      Icon: "avatar",
      Type: "text",
      Options: [
        {
          Code: "True",
          Label: "是",
        },
        {
          Code: "False",
          Label: "否",
        },
      ],
      Sort: 3,
    });
  }

  /**查询列表 */
  handleGet(): void {
    CompanyAppService.GetCustomerRelationPageList(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyCustomerRelation,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "history":
        HistoryAppService.GetHistoriesByNameById("CompanyRelation", row.Id)
          .then((r) => {
            this.histories = r.data.Data ?? [];
            this.modalHistories.Show = true;
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "build-settlement-bill":
        CompanyAppService.PostCustomerRelationBuildSettlementBill({
          Ids: this.list.SelectIds,
          BuildSettlementBill: btn.Command == "True",
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "salesman":
        CompanyAppService.PostCustomerRelationSalesman({
          Ids: this.list.SelectIds,
          SalesmanId: btn.Command == "" ? undefined : btn.Command,
          SalesmanLabel: this.salesmans.firstOrDefault(
            (it) => it.Value == btn.Command
          )?.Label,
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "customer-service":
        CompanyAppService.PostCustomerRelationCustomerService({
          Ids: this.list.SelectIds,
          CustomerServiceId: btn.Command == "" ? undefined : btn.Command,
          CustomerServiceLabel: this.customerServices.firstOrDefault(
            (it) => it.Value == btn.Command
          )?.Label,
        })
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        complete();
        break;
      default:
        break;
    }
  }
}
