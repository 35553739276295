
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import DtoRequestCompanyAuthAudit, {
  DtoRequestCompanyAuthAuditMiezzFormItems,
  DtoRequestCompanyAuthAuditRules,
} from "@/models.machine/DtoRequestCompanyAuthAudit";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import {
  EnumAuditStatus,
  EnumAuditStatusOption,
} from "@/models.machine/EnumAuditStatus";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import UiSelectOption from "@/models.machine/UiSelectOption";

export default class CompanyAuth extends Vue {
  @Prop() menuTab?: MiezzMenuTab;
  @Prop() type?: EnumCompanyType;
  @Prop() listUrl = "";

  logier?: Logier;
  form = new MiezzForm<DtoRequestCompanyAuthAudit, string>();
  buttons: MiezzButton[] = [];
  EnumAuditStatusOption = (
    JSON.copy(EnumAuditStatusOption) as UiSelectOption<number>[]
  ).filter((it) => it.Data != "No");

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    //表单-数据-初始化
    this.form.Data = new DtoRequestCompanyAuthAudit();
    //表单-校验
    this.form.Rules = DtoRequestCompanyAuthAuditRules;
    //表单-元素配置
    this.form.Items = DtoRequestCompanyAuthAuditMiezzFormItems;

    this.buttons = [
      {
        Code: "back",
        Label: "返回",
        MiezzIcon: "back",
        Type: "text",
        Size: "small",
      },
      {
        Code: "submit",
        Label: "提交",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    const id = this.$route.params.id as string;
    CompanyAppService.GetAuth(id).then((r) => {
      if (r.data.Data) {
        this.form.Data = r.data.Data;
        const foreignTradeIndividual = r.data.Data.JoinCompanyTypes?.any(
          (it) => it.CompanyType?.Code == "Customer_ForeignTradeIndividual"
        );
        this.form.Rules.BusinessLicense = foreignTradeIndividual
          ? []
          : [
              {
                required: true,
                message: "信息不能为空",
                trigger: "blur",
              },
            ];
        this.form.Rules.Code = foreignTradeIndividual
          ? []
          : [
              {
                required: true,
                message: "信息不能为空",
                trigger: "blur",
              },
            ];
      }
    });
  }

  handleAuthStatusChange(): void {
    if (this.form.Data.AuthStatus == EnumAuditStatus.Fail) {
      this.form.Rules.AuthRemark = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          max: 10240,
          message: "文本长度不能超过 10240 个字符",
          trigger: "blur",
        },
      ];
    } else {
      this.form.Rules.AuthRemark = [
        {
          max: 10240,
          message: "文本长度不能超过 10240 个字符",
          trigger: "blur",
        },
      ];
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.AuthAudit(this.form.Data)
              .then(() => {
                this.$router.push(this.listUrl);
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }
}
