import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormJoinCompanyUser_JoinCompanyUserGroup from "./DtoFormJoinCompanyUser_JoinCompanyUserGroup";
import { EnumJoinCompanyUserStatus, EnumJoinCompanyUserStatusOption } from "./EnumJoinCompanyUserStatus";
import { EnumUserStatus, EnumUserStatusOption } from "./EnumUserStatus";
import DtoFormJoinCompanyUser_JoinCompanyUserContact from "./DtoFormJoinCompanyUser_JoinCompanyUserContact";
import DtoFormJoinCompanyUser_JoinCompanyUserRole from "./DtoFormJoinCompanyUser_JoinCompanyUserRole";
import UiGroup from "./UiGroup";

/**
 * 关联-公司/用户表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormJoinCompanyUser extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.JoinCompanyUserGroups = [];
        this.Level = 1;
        this.Status = EnumJoinCompanyUserStatus.Normal;
        this.JoinCompanyUserContacts = [{"Type":0,"UiGroups":[]},{"Type":1,"UiGroups":[]}];
        this.JoinCompanyUserRoles = [];
        this.UiGroups = [{"Code":"UserInfo","Label":"\u7528\u6237\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ContactNumber","Label":"\u8054\u7CFB\u65B9\u5F0F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"Role","Label":"\u804C\u80FD\u4E0E\u6743\u9650","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 真实姓名
     */
    Name?: string;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 邀请码
     */
    InviteCode?: string;
    /**
     * 登录账号（手机号）
     */
    UserPhoneNumber?: string;
    /**
     * 部门
     */
    JoinCompanyUserGroups?: DtoFormJoinCompanyUser_JoinCompanyUserGroup[];
    /**
     * 职务
     */
    Job?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 隶属公司
     */
    CompanyId?: string;
    /**
     * 员工状态
     */
    Status?: EnumJoinCompanyUserStatus;
    /**
     * 用户状态
     */
    UserStatus?: EnumUserStatus;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 
     */
    JoinCompanyUserContacts?: DtoFormJoinCompanyUser_JoinCompanyUserContact[];
    /**
     * 
     */
    JoinCompanyUserRoles?: DtoFormJoinCompanyUser_JoinCompanyUserRole[];
}

/**
 * 关联-公司/用户表单-表单校验
 */
export const DtoFormJoinCompanyUserRules = {
    Name: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    InviteCode: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    UserPhoneNumber: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Job: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Level: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    CompanyId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 关联-公司/用户表单-设置项
 */
export const DtoFormJoinCompanyUserMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "真实姓名",
    Group: "UserInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "InviteCode",
    Label: "邀请码",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "UserPhoneNumber",
    Label: "登录账号（手机号）",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "JoinCompanyUserGroups",
    Label: "部门",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "Job",
    Label: "职务",
    Group: "UserInfo",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Level",
    Label: "职级",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "CompanyId",
    Label: "隶属公司",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "员工状态",
    Group: "UserInfo",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumJoinCompanyUserStatusOption,
  },
  {
    Prop: "UserStatus",
    Label: "用户状态",
    Group: "UserInfo",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumUserStatusOption,
  },
  {
    Prop: "Avatar",
    Label: "头像",
    Group: "UserInfo",
    Customize: true,
  },
  {
    Prop: "JoinCompanyUserContacts",
    Label: "",
    Group: "ContactNumber",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "JoinCompanyUserRoles",
    Label: "",
    Group: "Role",
    Span: 24,
    Customize: true,
  },
]
