
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyAuthAudit from "../CompanyAuthAudit.vue";
@Options({
  components: {
    CompanyAuthAudit,
  },
})
export default class ServiceProviderCompanyAuthAudit extends Vue {
  type = EnumCompanyType.ServiceProvider;
  listUrl = "/service-provider/company-auth";
  
  menuTab = new MiezzMenuTab();

  created(): void {
    const id = this.$route.params.id as string;
    this.menuTab.Url = `/service-provider/company-auth/audit/${id}`;
    this.menuTab.Back = true;
    this.menuTab.Menus = [
      {
        Url: `/service-provider/company-auth/audit/${id}`,
        Label: "企业认证审核",
        Power: "CompanyManagement_Company",
      },
    ];
  }
}
