import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import { EntityState, EntityStateOption } from "./EntityState";
import UiGroup from "./UiGroup";

/**
 * 审计日志详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailAuditLog extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.State = EntityState.Detached;
    }

    [proName: string]: any;
    /**
     * Http跟踪标识
     */
    TraceIdentifier?: string;
    /**
     * 表名
     */
    Table?: string;
    /**
     * 主键
     */
    PrimaryKey?: string;
    /**
     * 列名
     */
    Column?: string;
    /**
     * 操作方式
     */
    State?: EntityState;
    /**
     * 旧值
     */
    OldValue?: string;
    /**
     * 新值
     */
    NewValue?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 创建公司中文名称
     */
    CreatedCompanyNameZh?: string;
    /**
     * 创建公司英文名称
     */
    CreatedCompanyNameEn?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
}

/**
 * 审计日志详细-表单校验
 */
export const DtoDetailAuditLogRules = {
}

/**
 * 审计日志详细-设置项
 */
export const DtoDetailAuditLogMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "TraceIdentifier",
    Label: "Http跟踪标识",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Table",
    Label: "表名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PrimaryKey",
    Label: "主键",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Column",
    Label: "列名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "State",
    Label: "操作方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EntityStateOption,
  },
  {
    Prop: "OldValue",
    Label: "旧值",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NewValue",
    Label: "新值",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CreatedCompanyNameZh",
    Label: "创建公司中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyNameEn",
    Label: "创建公司英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedUserName",
    Label: "创建人姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedPhoneNumber",
    Label: "创建人手机号码",
    Type: EnumMiezzFormItemType.Text,
  },
]
