
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzButton from "@/models/MiezzButton";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoPageListSearchJoinCompanyUser from "@/models.machine/DtoPageListSearchJoinCompanyUser";
import DtoPageListJoinCompanyUser from "@/models.machine/DtoPageListJoinCompanyUser";
import DtoListItemJoinCompanyUser from "@/models.machine/DtoListItemJoinCompanyUser";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import UiSelectOption from "@/models.machine/UiSelectOption";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import RoleAppService from "@/apis.machine/RoleAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UserAppService from "@/apis.machine/UserAppService";
import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import { EnumCompare } from "@/models.machine/EnumCompare";
import { EnumJoinCompanyUserStatusOption } from "@/models.machine/EnumJoinCompanyUserStatus";
import { EnumUserStatusOption } from "@/models.machine/EnumUserStatus";
import CurrentLogier from "@/apis/CurrentLogier";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import DtoPageListSearchLoginLog from "@/models.machine/DtoPageListSearchLoginLog";
import DtoListItemLoginLog, {
  DtoListItemLoginLogMiezzListItems,
} from "@/models.machine/DtoListItemLoginLog";
import Logier from "@/models.machine/Logier";
import { EnumPipe } from "../../../models/EnumPipe";

export default class UserPageList extends Vue {
  /**公司类型 */
  @Prop() type?: EnumCompanyType;
  /**分页列表 */
  @Prop() list!: MiezzPageList<
    DtoPageListSearchJoinCompanyUser,
    DtoListItemJoinCompanyUser,
    string
  >;

  logier?: Logier;
  companyId?: string;
  /**公司选择器 */
  companies?: DtoSelectOptionCompany[] = [];
  /**服务商选择器 */
  serviceProviders?: DtoSelectOptionCompany[] = [];
  /**角色选择器 */
  roles?: UiSelectOption<string>[] = [];
  EnumJoinCompanyUserStatusOption = EnumJoinCompanyUserStatusOption;
  EnumUserStatusOption = EnumUserStatusOption;

  btnLoginLog: MiezzButton = {
    Code: "login-log",
    Label: "登录记录",
    Type: "text",
    Size: "small",
  };

  modalLoginLog = new MiezzModal(MiezzModalType.Dialog);
  listLoginLog = new MiezzPageList<
    DtoPageListSearchLoginLog,
    DtoListItemLoginLog,
    string
  >();

  /**创建时 */
  created(): void {
    this.logier = CurrentLogier.GetLogier();
    //列表-传参
    this.list.Dto = new DtoPageListSearchJoinCompanyUser();
    //列表-数据-初始
    this.list.Data = new DtoPageListJoinCompanyUser();
    if (!this.list.HandleFormat) {
      this.list.HandleFormat = (item, row) => {
        if (item.Prop == "Company") {
          return `${row.Company?.NameZh ?? "暂无数据"}/${
            row.Company?.NameEn ?? "暂无数据"
          }`;
        } else if (item.Prop == "JoinCompanyUserRoles") {
          return (
            row.JoinCompanyUserRoles?.map((it) => it.Role?.Name)?.join("，") ??
            "暂无数据"
          );
        }
        return undefined;
      };
    }

    if (this.type) {
      CompanyAppService.GetSelect({
        CompanyType: this.type,
        IncludeDisabled: true,
      }).then((r) => {
        if (r.data.Data) this.companies = r.data.Data;
      });
      if (CurrentLogier.GetLogier()?.CompanyType == EnumCompanyType.Platform) {
        CompanyAppService.GetSelect({
          CompanyType: EnumCompanyType.ServiceProvider,
          IncludeDisabled: true,
        }).then((r) => {
          if (r.data.Data) this.serviceProviders = r.data.Data;
        });
      }
    }

    RoleAppService.GetSelect({
      CompanyId: this.companyId,
      CompanyType: this.type,
      Official: false,
    }).then((r) => {
      this.roles = r.data.Data;
    });

    this.modalLoginLog.Title = "登录记录";
    this.listLoginLog.Fix = false;
    this.listLoginLog.HideCard = true;
    this.listLoginLog.EnabledDynamicSearch = false;
    this.listLoginLog.EnabledRadioSearch = false;
    this.listLoginLog.EnabledSelect = false;
    this.listLoginLog.DisabledCreatedHandleGet = true;
    this.listLoginLog.DisabledRouter = true;
    this.listLoginLog.Items = [
      {
        Prop: "Ip4",
        Label: "Ip4地址",
      },
      {
        Prop: "CreatedTime",
        Label: "创建时间",
        Pipe: EnumPipe.Time,
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    JoinCompanyUserAppService.GetPageList(this.type, this.list.Dto).then(
      (r) => {
        this.list.Data = r.data.Data;
      }
    );
  }

  handleBracketsBuild(brackets?: DynamicSearchBracket[]): void {
    if (brackets) {
      for (const bracket of brackets) {
        if (bracket.Items) {
          for (const item of bracket.Items) {
            if (
              item.Where &&
              item.Where.Prop == "DynamicSearch_CompanyId" &&
              item.Where.Compare == EnumCompare.Equal &&
              item.Where.Value
            ) {
              this.companyId = item.Where.Value;
            }
          }
        }
      }
    }
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    let path;
    switch (btn.Code) {
      //新增
      case "add":
        if (this.type == undefined) {
          JoinCompanyUserAppService.CheckMaxUserCountById(
            this.logier?.CompanyId
          )
            .then(() => {
              path = `${this.$route.path}/add`;
              this.$router.push(path);
              complete();
            })
            .catch(complete);
        } else if (
          this.type == EnumCompanyType.ServiceProvider &&
          this.companyId
        ) {
          JoinCompanyUserAppService.CheckMaxUserCountById(this.companyId)
            .then(() => {
              path = `${this.$route.path}/add`;
              this.$router.push(path);
              complete();
            })
            .catch(complete);
        } else {
          path = `${this.$route.path}/add`;
          if (this.companyId) path += `?CompanyId=${this.companyId}`;
          this.$router.push(path);
          complete();
        }
        break;
      case "trusteeship":
        CompanyAppService.ServiceProviderTrusteeshipByOpen(btn.Check).then(
          () => {
            if (btn.Check) {
              ElMessage.info(
                "您开启了服务商托管功能，服务商将可代您添加、编辑用户信息"
              );
            } else {
              ElMessage.info(
                "您关闭了服务商托管功能，服务商将不能代您添加、编辑用户信息"
              );
            }
            complete();
          }
        );
        break;
      default:
        break;
    }
  }

  handleRowDbClick(row: DtoListItemJoinCompanyUser): void {
    const listUrl = this.$route.path;
    this.$router.push(`${listUrl}/detail/${row.Id}`);
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemJoinCompanyUser,
    complete: () => void
  ): void {
    const listUrl = this.$route.path;
    switch (btn.Code) {
      //详细
      case "detail":
        this.$router.push(`${listUrl}/detail/${row.Id}`);
        complete();
        break;
      case "login-log":
        this.modalLoginLog.Show = true;
        JoinCompanyUserAppService.GetLoginLogPageList({
          CompanyUserId: row.Id,
        }).then((r) => {
          this.listLoginLog.Data = r.data.Data;
        });
        complete();
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        JoinCompanyUserAppService.Delete(this.list.SelectIds)
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-update-company-user-status":
        JoinCompanyUserAppService.UpdateStatusByStatus(
          btn.Command,
          this.list.SelectIds
        )
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-update-disabled-read-supplier":
        JoinCompanyUserAppService.UpdateDisabledReadSupplierByRead(
          btn.Command == "True",
          this.list.SelectIds
        )
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "batch-update-user-status":
        UserAppService.UpdateStatusByStatus(
          btn.Command,
          this.list.SelectRows.map((it) => it.UserId ?? "")
        )
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        complete();
        break;
      default:
        break;
    }
  }
}
