import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCodeService from "../models.machine/DtoPageListSearchCodeService";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCodeService from "../models.machine/DtoPageListCodeService";
import DtoFormCodeService from "../models.machine/DtoFormCodeService";

/**
 * 服务
 * @description 自动生成的代码,请勿修改
 */
export default class CodeServiceAppService {
    /**
     * 获取服务分页列表
     * @param {DtoPageListSearchCodeService} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCodeService>>} 分页列表
     */
    static GetPageList(search?: DtoPageListSearchCodeService): AxiosPromise<RESTfulResult<DtoPageListCodeService>> {
        const url = `/api/CodeService/GetPageList`;
        return service.get<RESTfulResult<DtoPageListCodeService>>(url, { params: search });
    }

    /**
     * 添加服务
     * @param {DtoFormCodeService} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormCodeService): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CodeService/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
