import UiSelectOption from "./UiSelectOption";

/**
 * 公司名片类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyBusinessCardType {
    /**
     * 发货人
     */
    Shipper = 1,
    /**
     * 收货人
     */
    Consignee = 2,
    /**
     * 通知人
     */
    Notifier = 3,
    /**
     * 生产销售单位
     */
    ProductionSaleUnit = 10,
    /**
     * 消费使用单位
     */
    ConsumptionUseUnit = 11,
}

/**
 * 公司名片类型-选项
 */
export const EnumCompanyBusinessCardTypeOption: UiSelectOption<number>[] = [
    { Label: "发货人", Value: 1, Data: "Shipper" },
    { Label: "收货人", Value: 2, Data: "Consignee" },
    { Label: "通知人", Value: 3, Data: "Notifier" },
    { Label: "生产销售单位", Value: 10, Data: "ProductionSaleUnit" },
    { Label: "消费使用单位", Value: 11, Data: "ConsumptionUseUnit" },
];
