import { RouteRecordRaw } from "vue-router";
import CountOrder from "./CountOrder.vue"
import CountPool from "./CountPool.vue"
import CountContainerTrailer from "./CountContainerTrailer.vue"
import CountTrunk from "./CountTrunk.vue"
import CountExpress from "./CountExpress.vue"
import CountCustoms from "./CountCustoms.vue"
import CountWharf from "./CountWharf.vue"
import CountTask from "./CountTask.vue"
import CountProcess from "./CountProcess.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/count/process',
        name: 'Count_Process',
        component: CountProcess,
        meta: {
            title: "统计分析 - 进程统计"
        },
    },
    {
        path: '/count/task',
        name: 'Count_Task',
        component: CountTask,
        meta: {
            title: "统计分析 - 待办统计"
        },
    },
    {
        path: '/count/order',
        name: 'Count_Order',
        component: CountOrder,
        meta: {
            title: "统计分析 - 舱务"
        },
    },
    {
        path: '/count/pool',
        name: 'Count_Pool',
        component: CountPool,
        meta: {
            title: "统计分析 - 委托书"
        },
    },
    {
        path: '/count/container-trailer',
        name: 'Count_ContainerTrailer',
        component: CountContainerTrailer,
        meta: {
            title: "统计分析 - 集装箱拖车"
        },
    },
    {
        path: '/count/trunk',
        name: 'Count_Trunk',
        component: CountTrunk,
        meta: {
            title: "统计分析 - 干线物流"
        },
    },
    {
        path: '/count/express',
        name: 'Count_Express',
        component: CountExpress,
        meta: {
            title: "统计分析 - 快递"
        },
    },
    {
        path: '/count/customs',
        name: 'Count_Customs',
        component: CountCustoms,
        meta: {
            title: "统计分析 - 报关"
        },
    },
    {
        path: '/count/wharf',
        name: 'Count_Wharf',
        component: CountWharf,
        meta: {
            title: "统计分析 - 仓库/堆场/码头"
        },
    },
]

export default routes;