import UiSelectOption from "./UiSelectOption";

/**
 * 性别
 * @description 自动生成的代码,请勿修改
 */
export enum EnumGender {
    /**
     * 男士
     */
    Male = 1,
    /**
     * 女士
     */
    Female = 0,
}

/**
 * 性别-选项
 */
export const EnumGenderOption: UiSelectOption<number>[] = [
    { Label: "男士", Value: 1, Data: "Male" },
    { Label: "女士", Value: 0, Data: "Female" },
];
