import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailSpec_Product from "./DtoDetailSpec_Product";
import DtoDetailSpec_SpecNumber from "./DtoDetailSpec_SpecNumber";
import DtoDetailSpec_SpecImage from "./DtoDetailSpec_SpecImage";
import DtoDetailSpec_SpecElement from "./DtoDetailSpec_SpecElement";
import DtoDetailSpec_SpecStock from "./DtoDetailSpec_SpecStock";
import DtoDetailSpec_CompanyBusinessCard from "./DtoDetailSpec_CompanyBusinessCard";
import UiGroup from "./UiGroup";

/**
 * 某个产品的规格详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailSpec extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.SpecNumbers = [];
        this.SpecImages = [];
        this.SpecElements = [];
        this.SpecStocks = [];
        this.UiGroups = [{"Code":"ProductSpecForm","Label":"","Toggle":false,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 产品主键
     */
    ProductId?: string;
    /**
     * 产品
     */
    Product?: DtoDetailSpec_Product;
    /**
     * 产品名称
     */
    ProductName?: string;
    /**
     * 规格型号名称
     */
    Name?: string;
    /**
     * 常用货号
     */
    Number?: string;
    /**
     * 常用货号
     */
    NumberString?: string;
    /**
     * 公司名片主键
     */
    CompanyBusinessCardId?: string;
    /**
     * 重量
     */
    Weight?: number;
    /**
     * 常用货号
     */
    SpecNumbers?: DtoDetailSpec_SpecNumber[];
    /**
     * 规格图片
     */
    SpecImages?: DtoDetailSpec_SpecImage[];
    /**
     * 规格要素
     */
    SpecElements?: DtoDetailSpec_SpecElement[];
    /**
     * 原始库存
     */
    SpecStocks?: DtoDetailSpec_SpecStock[];
    /**
     * 公司名片
     */
    CompanyBusinessCard?: DtoDetailSpec_CompanyBusinessCard;
}

/**
 * 某个产品的规格详细-表单校验
 */
export const DtoDetailSpecRules = {
    ProductName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 某个产品的规格详细-设置项
 */
export const DtoDetailSpecMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ProductName",
    Label: "产品名称",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "规格型号名称",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "NumberString",
    Label: "常用货号",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyBusinessCardId",
    Label: "公司名片主键",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Weight",
    Label: "重量",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecNumbers",
    Label: "常用货号",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecImages",
    Label: "规格图片",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecElements",
    Label: "规格要素",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "SpecStocks",
    Label: "原始库存",
    Group: "ProductSpecForm",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CompanyBusinessCard",
    Label: "公司名片",
    Type: EnumMiezzFormItemType.Text,
  },
]
