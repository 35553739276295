import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    modelValue: _ctx.detail,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detail) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'CodePart')
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 0,
            ref: "codemirror",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.CodePart) = $event))
          }, "\r\n      ", 512)), [
            [_vModelText, _ctx.detail.Data.CodePart]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}