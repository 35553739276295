import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchExemptingMode from "../models.machine/DtoSelectSearchExemptingMode";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchExemptingMode from "../models.machine/DtoPageListSearchExemptingMode";
import DtoPageListExemptingMode from "../models.machine/DtoPageListExemptingMode";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailExemptingMode from "../models.machine/DtoDetailExemptingMode";
import DtoFormExemptingMode from "../models.machine/DtoFormExemptingMode";

/**
 * 征免方式
 * @description 自动生成的代码,请勿修改
 */
export default class ExemptingModeAppService {
    /**
     * 获取境内货源地选择器
     * @param {DtoSelectSearchExemptingMode} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchExemptingMode): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ExemptingMode/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取征免方式分页列表
     * @param {DtoPageListSearchExemptingMode} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListExemptingMode>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchExemptingMode): AxiosPromise<RESTfulResult<DtoPageListExemptingMode>> {
        const url = `/api/ExemptingMode/Get`;
        return service.get<RESTfulResult<DtoPageListExemptingMode>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptingMode/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取征免方式详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailExemptingMode>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailExemptingMode>> {
        const url = `/api/ExemptingMode/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailExemptingMode>>(url);
    }

    /**
     * 新增/编辑征免方式
     * @param {DtoFormExemptingMode} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormExemptingMode): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptingMode/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除征免方式
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ExemptingMode/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ExemptingMode/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ExemptingMode/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ExemptingMode/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取Name数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ExemptingMode/GetNameList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
