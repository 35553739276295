
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ListItem } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoFormParamCountryArea, {
  DtoFormParamCountryAreaMiezzFormItems,
  DtoFormParamCountryAreaRules,
} from "@/models.machine/DtoFormParamCountryArea";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import DtoSelectSearchParamCountryArea from "@/models.machine/DtoSelectSearchParamCountryArea";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import { List } from "echarts";
import MiezzModal from "@/models/MiezzModal";

export default class ParamCountryAreaForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamCountryArea, string>(
    "System_ParamList_Area_AddEdit"
  );
  Country?: UiSelectOption<string>[] = [];
  filterCountry?: UiSelectOption<string>[] = [];
  searchData = new DtoSelectSearchParamCountryArea();
  optProvince?: DtoSelectOptionParamCountryArea[] = [];
  optCity?: DtoSelectOptionParamCountryArea[] = [];
  optDistrict?: DtoSelectOptionParamCountryArea[] = [];
  optStreet?: DtoSelectOptionParamCountryArea[] = [];
  random?: number;

  optFullName?: Array<string> = [];
  optCountryAreaNames?: Array<any> = [];
  NameList: any[] = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamCountryArea();
    //表单-校验
    this.form.Rules = DtoFormParamCountryAreaRules;
    this.form.Rules.ParamCountryId = [
      { required: true, message: "信息不能为空", trigger: "blur" },
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.AreaLevel = [
      { required: true, message: "信息不能为空", trigger: "blur" },
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameZh = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.NameEn = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.Abridge = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.PostalCode = [
      {
        required: false,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var AreaLevel = this.form.Data.AreaLevel;
            var NameZh: any = null;
            var NameEn: any = null;
            var Abridge: any = null;
            var PostalCode: any = null;
            if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "")
              NameZh = trim(this.form.Data.NameZh);
            if (this.form.Data.NameEn != null && typeof (this.form.Data.NameEn) != "undefined" && this.form.Data.NameEn != "undefined" && this.form.Data.NameEn != "")
              NameEn = trim(this.form.Data.NameEn.toUpperCase());
            if (this.form.Data.Abridge != null && typeof (this.form.Data.Abridge) != "undefined" && this.form.Data.Abridge != "undefined" && this.form.Data.Abridge != "")
              Abridge = trim(this.form.Data.Abridge.toUpperCase());
            if (this.form.Data.PostalCode != null && typeof (this.form.Data.PostalCode) != "undefined" && this.form.Data.PostalCode != "undefined" && this.form.Data.PostalCode != "")
              PostalCode = trim(this.form.Data.PostalCode.toUpperCase());
            if (ParamCountryId && AreaLevel) {
              if (this.NameList.some(it => it.ParamCountryId == ParamCountryId && it.AreaLevel == AreaLevel && it.NameZh == NameZh && it.NameEn == NameEn && it.Abridge == Abridge && it.PostalCode == PostalCode)) {
                callback(new Error("信息重复，请重新填写"));
                return false;
              } else {
                return callback();
              }
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamCountryAreaMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  

  filterMethod(queryString: string): void {
    if (this.Country && this.Country.length > 0) {
      if (queryString) {
        this.filterCountry = this.Country.filter((it) => it.Label?.contains(queryString));
        if (this.filterCountry && this.filterCountry.length > 10)
          this.filterCountry = this.filterCountry.slice(0, 10);
      } else {
        this.filterCountry = this.Country;
      }
    }
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamCountryAreaAppService.GetList().then((r3) => {
      if (r3.data.Data) {
        this.NameList = r3.data.Data;
        this.NameList.forEach(ele => {
          if (ele.NameEn == "") 
            ele.NameEn = null;
          if (ele.NameZh == "") 
            ele.NameZh = null;
          if (ele.Abridge == "") 
            ele.Abridge = null;
        })
      }
      if (this.id) 
        this.NameList = this.NameList.filter(it => it.Id != this.id);
    });

    if (this.id) {
      ParamCountryAppService.GetSelect().then((r1) => {
        this.Country = r1.data.Data;
        this.filterCountry = JSON.parse(JSON.stringify(this.Country));

        ParamCountryAreaAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            //省
            if (r.data.Data.Area1 && r.data.Data.AreaId1) {
              this.optProvince?.push({
                Id: r.data.Data.AreaId1,
                NameZh: r.data.Data.Area1.NameZh,
                NameEn: r.data.Data.Area1.NameEn,
                Label: this.getLabel(r.data.Data.Area1.NameZh, r.data.Data.Area1.NameEn, r.data.Data.Area1.Abridge),
              });
            }
            //市
            if (r.data.Data.Area2 && r.data.Data.AreaId2) {
              this.optCity?.push({
                Id: r.data.Data.AreaId2,
                NameZh: r.data.Data.Area2.NameZh,
                NameEn: r.data.Data.Area2.NameEn,
                Label: this.getLabel(r.data.Data.Area2.NameZh, r.data.Data.Area2.NameEn, r.data.Data.Area2.Abridge),
              });
            }
            //区
            if (r.data.Data.Area3 && r.data.Data.AreaId3) {
              this.optDistrict?.push({
                Id: r.data.Data.AreaId3,
                NameZh: r.data.Data.Area3.NameZh,
                NameEn: r.data.Data.Area3.NameEn,
                Label: this.getLabel(r.data.Data.Area3.NameZh, r.data.Data.Area3.NameEn, r.data.Data.Area3.Abridge)
              });
            }
            //街
            if (r.data.Data.Area4 && r.data.Data.AreaId4) {
              this.optStreet?.push({
                Id: r.data.Data.AreaId4,
                NameZh: r.data.Data.Area4.NameZh,
                NameEn: r.data.Data.Area4.NameEn,
                Label: this.getLabel(r.data.Data.Area4.NameZh, r.data.Data.Area4.NameEn, r.data.Data.Area4.Abridge)
              });
            }
            ParamCountryAppService.GetCountryAreaNamesById(r.data.Data.ParamCountryId).then((r2) => {
              if (r2.data.Data && r2.data.Data.length > 0) {
                this.optCountryAreaNames = r2.data.Data;
              }
              if(r.data.Data)
                this.form.Data = r.data.Data;
            });
            
          }
        });
      });
    } else {
      this.form.Data = new DtoFormParamCountryArea();
      this.form.Data.Deep = 1;

      this.getCountry();
    }
  }

  getLabel(NameZh: any, NameEn:any, Abridge:any):string {
    let Label = "";
    let List = [];
    if (NameZh != null && NameZh != "" && NameZh != undefined) {
      List.push(NameZh);
    } 
    if (NameEn != null && NameEn != "" && NameEn != undefined) {
      List.push(NameEn);
    } 
    if (Abridge != null && Abridge != "" && Abridge != undefined) {
      List.push(Abridge);
    } 
    if (List.length > 0) {
      Label = List.join("/");
    }
    return Label;
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        if (!this.form.Data.NameZh && !this.form.Data.NameEn && !this.form.Data.Abridge && !this.form.Data.PostalCode) {
          ElMessage.warning("中文名称、英文名称、缩写、邮编信息不可同时为空!");
          complete();
        } else {
          MiezzForm.submit(this.$refs, "ruleForm", (v) => {
            if (v) {
              ParamCountryAreaAppService.Post(this.form.Data)
                .then(() => {
                  this.handleSubmit();
                  complete();
                })
                .catch(complete);
            } else complete();
          });
        }
        
        break;
      default:
        break;
    }
  }

  /**查询国家 */
  getCountry(): void {
    ParamCountryAppService.GetSelect().then((r) => {
      this.Country = r.data.Data;
      this.filterCountry = JSON.parse(JSON.stringify(this.Country));
    });
  }

  /**选择地区级别 */
  onChangeAreaLevel(): void {
    MiezzForm.validateField(this.$refs, "ruleForm", "AreaLevel");
    if (this.form.Data.AreaLevel) {
      if (this.optCountryAreaNames && this.optCountryAreaNames.length > 0)
        this.form.Data.Deep = this.optCountryAreaNames.indexOf(this.form.Data.AreaLevel) + 1;
      if (this.form.Data.Deep == 1) {
        this.form.Data.AreaId1 = undefined;
        this.form.Data.AreaId2 = undefined;
        this.form.Data.AreaId3 = undefined;
        this.form.Data.AreaId4 = undefined;
        this.form.Data.Area1 = null;
        this.form.Data.Area2 = null;
        this.form.Data.Area3 = null;
        this.form.Data.Area4 = null;
      } else if (this.form.Data.Deep == 2) {
        this.form.Data.AreaId2 = undefined;
        this.form.Data.AreaId3 = undefined;
        this.form.Data.AreaId4 = undefined;
        this.form.Data.Area2 = null;
        this.form.Data.Area3 = null;
        this.form.Data.Area4 = null;
      } else if (this.form.Data.Deep == 3) {
        this.form.Data.AreaId3 = undefined;
        this.form.Data.AreaId4 = undefined;
        this.form.Data.Area3 = null;
        this.form.Data.Area4 = null;
      } else if (this.form.Data.Deep == 4) {
        this.form.Data.AreaId4 = undefined;
        this.form.Data.Area4 = null;
      }
    } else {
      this.form.Data.Deep = 0;
      this.form.Data.AreaId1 = undefined;
      this.form.Data.AreaId2 = undefined;
      this.form.Data.AreaId3 = undefined;
      this.form.Data.AreaId4 = undefined;
      this.form.Data.Area1 = null;
      this.form.Data.Area2 = null;
      this.form.Data.Area3 = null;
      this.form.Data.Area4 = null;
    }
  }

  /**选择国家 */
  onChangeCountry(): void {
    this.optCountryAreaNames = [];
    this.form.Data.AreaLevel = "";
    if (this.form.Data.ParamCountryId) {
      ParamCountryAppService.GetCountryAreaNamesById(this.form.Data.ParamCountryId).then((r) => {
        if (r.data.Data && r.data.Data.length > 0) {
          this.optCountryAreaNames = r.data.Data;
        }
      });
    } else {
      this.form.Data.Deep = 0;
      this.form.Data.AreaId1 = undefined;
      this.form.Data.AreaId2 = undefined;
      this.form.Data.AreaId3 = undefined;
      this.form.Data.AreaId4 = undefined;
      this.form.Data.Area1 = null;
      this.form.Data.Area2 = null;
      this.form.Data.Area3 = null;
      this.form.Data.Area4 = null;
    }
    this.onBlur();
  }

  /**地区下拉选择器光标出现时查询地区下拉数据 */
  onFocus(Deep: number): void {
    this.searchData.ParamCountryId = this.form.Data.ParamCountryId;
    if (this.searchData.ParamCountryId) {
      this.searchData.Deep = Deep;
      switch (Deep) {
        case 1:
          ParamCountryAreaAppService.GetSelect(this.searchData).then((r) => {
            this.optProvince = r.data.Data;

            this.optProvince?.forEach(ele => {
              ele.Label = this.getLabel(ele.NameZh, ele.NameEn, ele.Abridge);
            })
          });
          break;
        case 2:
          this.searchData.PreviousId = this.form.Data.AreaId1;
          ParamCountryAreaAppService.GetSelect(this.searchData).then((r) => {
            this.optCity = r.data.Data;

            this.optCity?.forEach(ele => {
              ele.Label = this.getLabel(ele.NameZh, ele.NameEn, ele.Abridge);
            })
          });
          break;

        case 3:
          this.searchData.PreviousId = this.form.Data.AreaId2;
          ParamCountryAreaAppService.GetSelect(this.searchData).then((r) => {
            this.optDistrict = r.data.Data;
            
            this.optDistrict?.forEach(ele => {
              ele.Label = this.getLabel(ele.NameZh, ele.NameEn, ele.Abridge);
            })
          });
          break;

        case 4:
          this.searchData.PreviousId = this.form.Data.AreaId3;
          ParamCountryAreaAppService.GetSelect(this.searchData).then((r) => {
            this.optStreet = r.data.Data;
            
            this.optStreet?.forEach(ele => {
              ele.Label = this.getLabel(ele.NameZh, ele.NameEn, ele.Abridge);
            })
          });
          break;
        default:
          break;
      }
    }
  }

  onBlur(): void {
    this.optFullName = [];
    if (this.form.Data.Abridge) {
      this.optFullName?.push(this.form.Data.Abridge);
    }
    if (this.form.Data.NameZh) {
      this.optFullName?.push(this.form.Data.NameZh);
    }
    if (this.form.Data.NameEn) {
      this.optFullName?.push(this.form.Data.NameEn);
    }
    if (this.form.Data.PostalCode) {
      this.optFullName?.push(this.form.Data.PostalCode);
    }
    if (this.form.Data.AreaId4) {
      let item = this.optStreet?.find(x => { return x.Id == this.form.Data.AreaId4})
      if (item)
        this.optFullName?.push(this.getLabel(item?.NameZh, item?.NameEn, item?.Abridge));
    }
    if (this.form.Data.AreaId3) {
      let item = this.optDistrict?.find(x => { return x.Id == this.form.Data.AreaId3})
      if (item)
        this.optFullName?.push(this.getLabel(item?.NameZh, item?.NameEn, item?.Abridge));
    }
    if (this.form.Data.AreaId2) {
      let item = this.optCity?.find(x => { return x.Id == this.form.Data.AreaId2})
      if (item)
        this.optFullName?.push(this.getLabel(item?.NameZh, item?.NameEn, item?.Abridge));
    }
    if (this.form.Data.AreaId1) {
      let item = this.optProvince?.find(x => { return x.Id == this.form.Data.AreaId1})
      if (item)
        this.optFullName?.push(this.getLabel(item?.NameZh, item?.NameEn, item?.Abridge));
    }
    if (this.form.Data.ParamCountryId) {
      let item = this.Country?.find(x => { return x.Value == this.form.Data.ParamCountryId})
      if (item && item.Label) 
        this.optFullName?.push(item.Label);
    }
    
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
      // MiezzForm.validateField(this.$refs, "ruleForm", "NameEn");
      // MiezzForm.validateField(this.$refs, "ruleForm", "Abridge");
    }
    this.onBlur();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    // MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
    // MiezzForm.validateField(this.$refs, "ruleForm", "Abridge");
    this.onBlur();
  }
  onBlurAbridge(): void {
    if (this.form.Data.Abridge) {
      this.form.Data.Abridge = this.form.Data.Abridge.toUpperCase();
    }
    // MiezzForm.validateField(this.$refs, "ruleForm", "NameZh");
    // MiezzForm.validateField(this.$refs, "ruleForm", "NameEn");
    this.onBlur();
  }

  onChange(Deep: number): void {
    var DataReset = new DtoFormParamCountryArea();
    switch (Deep) {
      case 1:
        this.form.Data.AreaId2 = DataReset.AreaId2;
        this.form.Data.AreaId3 = DataReset.AreaId3;
        this.form.Data.AreaId4 = DataReset.AreaId4;
        if (!this.form.Data.AreaId1) {
          this.form.Data.AreaId1 = DataReset.AreaId1;
        }
        break;
      case 2:
        this.form.Data.AreaId3 = DataReset.AreaId3;
        this.form.Data.AreaId4 = DataReset.AreaId4;
        if (!this.form.Data.AreaId2) {
          this.form.Data.AreaId2 = DataReset.AreaId2;
        }
        break;
      case 3:
        this.form.Data.AreaId4 = DataReset.AreaId4;
        if (!this.form.Data.AreaId3) {
          this.form.Data.AreaId3 = DataReset.AreaId3;
        }
        break;
      default:
        if (!this.form.Data.AreaId4) {
          this.form.Data.AreaId4 = DataReset.AreaId4;
        }
        break;
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}

// 去掉空格
function trim(str: string) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
