import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import UiGroup from "./UiGroup";

/**
 * 公司类型表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCompanyType extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyType;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 公司类型表单-表单校验
 */
export const DtoFormCompanyTypeRules = {
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    NameZh: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 公司类型表单-设置项
 */
export const DtoFormCompanyTypeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "全名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
