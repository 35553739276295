import { AxiosPromise } from "axios";
import service from "../apis/Service";
import { EnumCompanyType, EnumCompanyTypeOption } from "../models.machine/EnumCompanyType";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList from "../models.machine/DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList";
import DtoRequestJoinCompanyServiceProviderSupplierOpen from "../models.machine/DtoRequestJoinCompanyServiceProviderSupplierOpen";

/**
 * 关联-服务商/供应商
 * @description 自动生成的代码,请勿修改
 */
export default class JoinCompanyServiceProviderSupplierAppService {
    /**
     * 获取供应商列表
     * @param {string} [companyId] 公司Id
     * @param {EnumCompanyType} [companyType] 公司类型
     * @returns {AxiosPromise<RESTfulResult<DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList>>} 结果
     */
    static GetSupplierListByCompanyIdByCompanyType(companyId?: string, companyType?: EnumCompanyType): AxiosPromise<RESTfulResult<DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList>> {
        const url = `/api/JoinCompanyServiceProviderSupplier/GetSupplierList/${companyId}/${companyType}`;
        return service.get<RESTfulResult<DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList>>(url);
    }

    /**
     * 开通/取消
     * @param {DtoRequestJoinCompanyServiceProviderSupplierOpen} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Open(dto?: DtoRequestJoinCompanyServiceProviderSupplierOpen): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/JoinCompanyServiceProviderSupplier/Open`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

}
