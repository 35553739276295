import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderPoolExpress_OrderPool from "./DtoListItemOrderPoolExpress_OrderPool";
import DtoListItemOrderPoolExpress_CompanyAddress from "./DtoListItemOrderPoolExpress_CompanyAddress";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoListItemOrderPoolExpress_OrderPoolFile from "./DtoListItemOrderPoolExpress_OrderPoolFile";

/**
 * 委托书-快递分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolExpress extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.ClientCompany = "暂无数据";
        this.HasCargoQuantities = false;
        this.HasCargos = false;
        this.HasCodes = false;
        this.Files = [];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoListItemOrderPoolExpress_OrderPool;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 计划提柜时间
     */
    PlanCarryContainerTime?: Date;
    /**
     * 提柜时间
     */
    CarryContainerTime?: Date;
    /**
     * 提货地址Id
     */
    CarryAddressId?: string;
    /**
     * 提货地址
     */
    CarryAddress?: DtoListItemOrderPoolExpress_CompanyAddress;
    /**
     * 提货计划时间
     */
    CarryPlanTime?: Date;
    /**
     * 货量
     */
    HasCargoQuantities?: boolean;
    /**
     * 货物清单
     */
    HasCargos?: boolean;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 合计件数
     */
    TotalQuantity?: number;
    /**
     * 合计件数-英文计价单位
     */
    TotalQuantityChargeUnitEn?: string;
    /**
     * 合计毛重
     */
    TotalGrossWeight?: number;
    /**
     * 合计体积
     */
    TotalVolume?: number;
    /**
     * 实际提取时间
     */
    AllCarryTime?: Date;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 快递单号
     */
    HasCodes?: boolean;
    /**
     * 交货地址Id
     */
    DeliveryAddressId?: string;
    /**
     * 交货地址
     */
    DeliveryAddress?: DtoListItemOrderPoolExpress_CompanyAddress;
    /**
     * 计划抵达时间
     */
    PlanDeliveryTime?: Date;
    /**
     * 实际送达时间
     */
    AllDeliveryTime?: Date;
    /**
     * 附件
     */
    Files?: DtoListItemOrderPoolExpress_OrderPoolFile[];
    /**
     * 进程
     */
    Process?: string;
}

/**
 * 委托书-快递分页列表-项-表单校验
 */
export const DtoListItemOrderPoolExpressRules = {
}

/**
 * 委托书-快递分页列表-项-设置项
 */
export const DtoListItemOrderPoolExpressMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
    MaxWidth: "100px",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    MaxWidth: "150px",
  },
  {
    Prop: "PlanCarryContainerTime",
    Label: "计划提柜时间",
    Hide: true,
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryContainerTime",
    Label: "提柜时间",
    Hide: true,
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryAddress",
    Label: "提货地址",
    MaxWidth: "130px",
    Customize: true,
  },
  {
    Prop: "HasCargoQuantities",
    Label: "货量",
    Customize: true,
  },
  {
    Prop: "HasCargos",
    Label: "货物清单",
    MaxWidth: "90px",
    Customize: true,
  },
  {
    Prop: "AllCarryTime",
    Label: "实际提取时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "SupplierLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "HasCodes",
    Label: "快递单号",
    Customize: true,
  },
  {
    Prop: "DeliveryAddress",
    Label: "交货地址",
    MaxWidth: "90px",
    Customize: true,
  },
  {
    Prop: "AllDeliveryTime",
    Label: "实际送达时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]
