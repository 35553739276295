import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCodeController from "../models.machine/DtoPageListSearchCodeController";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCodeController from "../models.machine/DtoPageListCodeController";
import UiSelectOptionWithStringValue from "../models.machine/UiSelectOptionWithStringValue";
import DtoFormCodeProperty from "../models.machine/DtoFormCodeProperty";
import DtoFormCodeController from "../models.machine/DtoFormCodeController";

/**
 * 控制器
 * @description 自动生成的代码,请勿修改
 */
export default class CodeControllerAppService {
    /**
     * 获取控制器列表
     * @param {DtoPageListSearchCodeController} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCodeController>>} 列表
     */
    static Get(search?: DtoPageListSearchCodeController): AxiosPromise<RESTfulResult<DtoPageListCodeController>> {
        const url = `/api/CodeController/Get`;
        return service.get<RESTfulResult<DtoPageListCodeController>>(url, { params: search });
    }

    /**
     * 获取分组选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetGroupSelect(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetGroupSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取应用选择器
     * @param {string} [group] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetAppSelectByGroup(group?: string): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetAppSelect/${group}`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取服务选择器
     * @param {string} [group] 
     * @param {string} [app] 
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 
     */
    static GetServiceSelectByGroupByApp(group?: string, app?: string): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetServiceSelect/${group}/${app}`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取基服务选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetBaseServiceSelect(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetBaseServiceSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取表选择器
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 选择器
     */
    static GetTableSelect(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetTableSelect`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

    /**
     * 获取表属性信息
     * @param {string} [name] 表名
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoFormCodeProperty[]>>} 属性信息
     */
    static GetTablePropertiesByName(name?: string, id?: string): AxiosPromise<RESTfulResult<DtoFormCodeProperty[]>> {
        const url = `/api/CodeController/GetTableProperties/${name}`;
        const _querys: any = {};
        _querys.id = id
        return service.get<RESTfulResult<DtoFormCodeProperty[]>>(url, { params: _querys });
    }

    /**
     * 添加控制器
     * @param {DtoFormCodeController} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormCodeController): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CodeController/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取前端目录
     * @returns {AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>>} 结果
     */
    static GetClientPath(): AxiosPromise<RESTfulResult<UiSelectOptionWithStringValue[]>> {
        const url = `/api/CodeController/GetClientPath`;
        return service.get<RESTfulResult<UiSelectOptionWithStringValue[]>>(url);
    }

}
