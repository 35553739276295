import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_upload = _resolveComponent("miezz-upload")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_form, {
      modelValue: _ctx.detail,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.detail) = $event)),
      isDetail: true
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Avatar')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_upload, {
                  modelValue: _ctx.detail.Data.Avatar,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.detail.Data.Avatar) = $event)),
                  preview: true
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'Numbers')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: item.Label,
              prop: item.Prop
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_page_list, {
                  modelValue: _ctx.list,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"])
  ], 64))
}