import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailParamPort_ParamCountry from "./DtoDetailParamPort_ParamCountry";
import DtoListItemParamPort_FreighTowerPort from "./DtoListItemParamPort_FreighTowerPort";
import UiGroup from "./UiGroup";

/**
 * 港口详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamPort extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 国家Id
     */
    ParamCountryId?: string;
    /**
     * 国家
     */
    ParamCountry?: DtoDetailParamPort_ParamCountry;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 港口代码
     */
    Code?: string;
    /**
     * 中国海关港口代码
     */
    ChinaCustomsCode?: string;
    /**
     * 鸭嘴兽代码
     */
    YaZuiShouCode?: string;
    /**
     * 飞鸵Id
     */
    FreighTowerPortId?: string;
    /**
     * 飞鸵
     */
    FreighTowerPort?: DtoListItemParamPort_FreighTowerPort;
    /**
     * 飞鸵
     */
    FreighTowerPortLabel?: string;
}

/**
 * 港口详细-表单校验
 */
export const DtoDetailParamPortRules = {
}

/**
 * 港口详细-设置项
 */
export const DtoDetailParamPortMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "ParamCountryId",
    Label: "国家Id",
    Hide: true,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Code",
    Label: "港口代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "ChinaCustomsCode",
    Label: "中国海关港口代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "YaZuiShouCode",
    Label: "鸭嘴兽代码",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "FreighTowerPortLabel",
    Label: "飞鸵",
    Type: EnumMiezzFormItemType.Text,
  },
]
