
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import DtoListItemPrice from "@/models.machine/DtoListItemPrice";
import DtoPageListSearchPrice from "@/models.machine/DtoPageListSearchPrice";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PricePageList from "../PricePageList.vue";
@Options({
  components: {
    PricePageList,
  },
})
export default class PriceSettlementPageList extends Vue {
  list = new MiezzPageList<DtoPageListSearchPrice, DtoListItemPrice, string>(
    "PriceManagement_Settlement"
  );
  amountType = EnumPriceAmountType.Settlement;
  users?: UiSelectOption<string>[] = [];
  userId?: string;
  tableId?: string;

  created(): void {
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "download",
        Label: "下载模板",
        Icon: "download",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Settlement_Import",
      },
      {
        Code: "import",
        Label: "导入",
        Icon: "top",
        Type: "primary",
        Size: "small",
        FileUpload: true,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        PowerCode: "PriceManagement_Settlement_Import",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_Settlement_Export",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "history",
        Title: "历史记录",
        Icon: "Stopwatch",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Settlement_History",
      },
      {
        Code: "formula",
        Title: "公式",
        MiezzIcon: "formula",
        Type: "default",
        Size: "small",
        PowerCode: "PriceManagement_Settlement_Formula",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "amount-update",
        Label: "批量编辑",
        Icon: "edit",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_EditPrice",
      },
      {
        Code: "export",
        Label: "导出",
        Icon: "bottom",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_Export",
      },
      {
        Code: "add-formula",
        Label: "添加公式",
        MiezzIcon: "formula",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_Formula",
      },
      {
        Code: "delete-formula",
        Label: "删除公式",
        MiezzIcon: "formula",
        Type: "text",
        Message: "是否确认将该信息公式删除？",
        PowerCode: "PriceManagement_Settlement_Formula",
      },
      {
        Code: "lock",
        Label: "锁定",
        Icon: "Lock",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_EditPrice",
      },
      {
        Code: "un-lock",
        Label: "解锁",
        Icon: "Unlock",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_EditPrice",
      },
      {
        Code: "sync",
        Label: "同步",
        Icon: "share",
        Type: "text",
        PowerCode: "PriceManagement_Settlement_Sync",
      },
      {
        Code: "add-calc",
        Label: "添加到计算器",
        Icon: "plus",
        Type: "text",
        PowerCode: "PriceManagement_Calc",
      },
    ];

    this.handleRefreshCompaniesOrUsers(this.$route.query.TableId as string);
  }

  handleRefreshCompaniesOrUsers(tableId?: string): void {
    this.tableId = tableId;
    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["IdentityPermission_Salesman"],
      PriceTableId: this.tableId,
      AmountType: EnumPriceAmountType.Settlement,
    }).then((r) => {
      this.users = r.data.Data ?? [];
      const page = JSON.parse(localStorage.getItem(this.$route.path) ?? "{}");
      let userId: string | undefined;
      if (this.$route.query.UserId) {
        userId = this.$route.query.UserId as string;
      } else {
        if (page.userId && this.users.any((it) => it.Value == page.userId)) {
          userId = page.userId;
        } else {
          userId = this.users.firstOrDefault()?.Value;
        }
      }
      if (this.users.all((it) => it.Value != this.userId)) {
        userId = this.users.firstOrDefault()?.Value;
      }
      this.userId = userId;
      page.userId = userId;
      if (this.list.Dto && this.list.Dto.UserId != userId) {
        this.list.Dto.UserId = userId;
        (this.$refs["ref-price-page-list"] as PricePageList).handleUrlGet();
      }
      localStorage.setItem(this.$route.path, JSON.stringify(page));
    });
  }
}
