
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import DtoDetailCompanyContact from "@/models.machine/DtoDetailCompanyContact";
import MiezzButton from "@/models/MiezzButton";
import CompanyContactDetail from "@/views/Company/CompanyContact/CompanyContactDetail.vue";
@Options({
  components: {
    CompanyContactDetail,
  },
})
export default class SupplierCompanyContactDetail extends Vue {
  detail = new MiezzForm<DtoDetailCompanyContact, string>("SupplierManagement_Company_Contact_Detail");
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "edit",
      Title: "编辑",
      Icon: "edit",
      Type: "default",
      Size: "small",
      PowerCode: "SupplierManagement_Company_Contact_Edit",
    },
  ];
}
