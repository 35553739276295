
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import DtoDetailProduct, {
        DtoDetailProductMiezzDetailItems,
    } from "@/models.machine/DtoDetailProduct";
    import ProductMenu from "../ProductMenu.vue"
    @Options({
        components: {
            ProductMenu
        },
    })
    export default class ProductDetail extends Vue {
        id = "";
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "edit",
                Label: "编辑",
                Icon: "edit",
                Type: "text",
                Size: "small",
                PowerCode: "ProductManagement_ProductManagement_ProductInformation_BasicInformation_Edit",
            },
        ];

        form = new MiezzForm<DtoDetailProduct, string>();
        created(): void {

            //表单-数据-初始化
            this.id = this.$route.params.id as string;
            //alert(this.$route.params.id);
            this.form.Data = new DtoDetailProduct();
            this.form.Data.ZhAliass = []; //中文别名
            this.form.Data.EnAliass = []; //英文别名
            this.form.Data.LatinAliass = []; //拉丁别名
            this.form.DefaultSpan = 12;
            this.form.FixTop = "40px";
            //详细-元素配置
            this.form.Items = DtoDetailProductMiezzDetailItems;
            //this.form.RadioSearchItems = this.EnumMenuSceneOption;
            //console.log(this.form);
            this.handleGet();
        }

        /**查询表单数据 */
        handleGet(): void {
            if (this.id) {
                ProductAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;

                    this.form.Data.ZhAliass = []; //中文别名
                    this.form.Data.EnAliass = []; //英文别名
                    this.form.Data.LatinAliass = []; //拉丁别名
                    if (this.form.Data.CustomsCodes != null && this.form.Data.CustomsCodes.length > 0) {
                        for (var it of this.form.Data.CustomsCodes) {
                            var name = it.CountryName?.split('/');
                            if (name != null) {
                                it.CountryName = name[0];
                            }
                        }
                    }

                    if (this.form.Data.Aliass != null && this.form.Data.Aliass.length > 0) {
                        for (var alias of this.form.Data.Aliass) {
                            if (alias.Type == 0) {
                                this.form.Data.ZhAliass.push(alias);
                            }
                            else if (alias.Type == 1) {
                                this.form.Data.EnAliass.push(alias);
                            }
                            else if (alias.Type == 2) {
                                this.form.Data.LatinAliass.push(alias);
                            }
                        }
                    }
                });
            }
        }


        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            var url = this.$route.query.backUrl as string;
            switch (btn.Code) {
                case "edit":
                    if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                        //this.$router.push(`/product/edit/${this.id}?backUrl=` + this.$route.fullPath);
                        this.$router.push({
                            path: `/product/edit/${this.id}`,
                            query: { backUrl: this.$route.fullPath },
                        });
                    }
                    else {
                        this.$router.push(`/product/edit/${this.id}`);
                    }
                    complete();
                    break;
                case "back":
                    if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                        this.$router.push(url);
                    }
                    else {
                        history.go(-1);
                    }
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            ////
            //history.back(-1);
        }
    }
