import UiForm from "./UiForm";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderManifestVgm_OrderManifestVgmDetailProduct extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 报关Id
     */
    CustomsId?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 报关分提单号
     */
    BLNO?: string;
    /**
     * 集装箱拖车Id
     */
    ContainerTrailerId?: string;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 箱型
     */
    ContainerTypeId?: string;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 英文品名
     */
    NameEn?: string;
    /**
     * HSCode (13位)
     */
    HsCode?: string;
    /**
     * 件数
     */
    Quantity?: number;
    /**
     * 包装单位
     */
    PackagesKind?: string;
    /**
     * 毛重(KGS)
     */
    GrossWeight?: number;
    /**
     * 体积(CBM)
     */
    Volume?: number;
    /**
     * 唛头
     */
    MarksNotes?: string;
    /**
     * UN Code(危)
     */
    DangerUnCode?: string;
    /**
     * 类别（危）
     */
    DangerCategory?: string;
}

/**
 * -表单校验
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailProductRules = {
    BLNO: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    ContainerNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    SealNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    HsCode: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    PackagesKind: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    MarksNotes: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DangerUnCode: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    DangerCategory: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailProductMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "BLNO",
    Label: "报关分提单号",
    Customize: true,
  },
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Customize: true,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
  {
    Prop: "ContainerTypeId",
    Label: "箱型",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文品名",
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "HSCode (13位)",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "件数",
    Customize: true,
  },
  {
    Prop: "PackagesKind",
    Label: "包装单位",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
    Customize: true,
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
    Customize: true,
  },
  {
    Prop: "DangerUnCode",
    Label: "UN Code(危)",
    Customize: true,
  },
  {
    Prop: "DangerCategory",
    Label: "类别（危）",
    Customize: true,
  },
]

/**
 * -设置项
 */
export const DtoFormOrderManifestVgm_OrderManifestVgmDetailProductMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "BLNO",
    Label: "报关分提单号",
    Customize: true,
  },
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Customize: true,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
  {
    Prop: "ContainerTypeId",
    Label: "箱型",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文品名",
    Customize: true,
  },
  {
    Prop: "HsCode",
    Label: "HSCode (13位)",
    Customize: true,
  },
  {
    Prop: "Quantity",
    Label: "件数",
    Customize: true,
  },
  {
    Prop: "PackagesKind",
    Label: "包装单位",
    Customize: true,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重(KGS)",
    Customize: true,
  },
  {
    Prop: "Volume",
    Label: "体积(CBM)",
    Customize: true,
  },
  {
    Prop: "MarksNotes",
    Label: "唛头",
    Customize: true,
  },
  {
    Prop: "DangerUnCode",
    Label: "UN Code(危)",
    Customize: true,
  },
  {
    Prop: "DangerCategory",
    Label: "类别（危）",
    Customize: true,
  },
]
