import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchOrderDepartureWharfHistory from "../models.machine/DtoPageListSearchOrderDepartureWharfHistory";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListOrderDepartureWharfHistory from "../models.machine/DtoPageListOrderDepartureWharfHistory";

/**
 * 订单/委托书的历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class OrderDepartureWharfHistoryAppService {
    /**
     * 获取订单/委托书的历史记录分页列表
     * @param {DtoPageListSearchOrderDepartureWharfHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfHistory>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchOrderDepartureWharfHistory): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfHistory>> {
        const url = `/api/OrderDepartureWharfHistory/Get`;
        return service.get<RESTfulResult<DtoPageListOrderDepartureWharfHistory>>(url, { params: dto });
    }

    /**
     * 获取订单/委托书的历史记录分页列表
     * @param {DtoPageListSearchOrderDepartureWharfHistory} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfHistory>>} 分页列表
     */
    static All(dto?: DtoPageListSearchOrderDepartureWharfHistory): AxiosPromise<RESTfulResult<DtoPageListOrderDepartureWharfHistory>> {
        const url = `/api/OrderDepartureWharfHistory/All`;
        return service.post<RESTfulResult<DtoPageListOrderDepartureWharfHistory>>(url, dto);
    }

}
