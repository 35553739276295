
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoFormCompanyOvertimeRule, {
  DtoFormCompanyOvertimeRuleMiezzFormItems,
  DtoFormCompanyOvertimeRuleRules,
} from "@/models.machine/DtoFormCompanyOvertimeRule";
import DtoFormCompanyOvertimeule_CompanyOvertimeule, {
  DtoFormCompanyOvertimeule_CompanyOvertimeuleMiezzListItems,
} from "@/models.machine/DtoFormCompanyOvertimeule_CompanyOvertimeule";
import { EnumCompanyOvertimeRuleTypeOption } from "@/models.machine/EnumCompanyOvertimeRuleType";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class CurrentCompanyOvertimeRuleForm extends Vue {
  form = new MiezzForm<DtoFormCompanyOvertimeRule, string>();
  id?: string;
  list = new MiezzPageList<
    any,
    DtoFormCompanyOvertimeule_CompanyOvertimeule,
    string
  >();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      MiezzIcon: "back",
      Label: "返回",
      Type: "text",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "text",
      Size: "small",
    },
  ];
  rules = {};
  EnumCompanyOvertimeRuleTypeOption = EnumCompanyOvertimeRuleTypeOption;

  created(): void {
    this.id = this.$route.params.id as string;
    this.form.Data = new DtoFormCompanyOvertimeRule();
    this.form.Items = JSON.copy(DtoFormCompanyOvertimeRuleMiezzFormItems);
    this.form.Rules = {
      Type: [
        {
          validator: (
            rule: any,
            value: DtoFormCompanyOvertimeule_CompanyOvertimeule,
            callback: any
          ) => {
            if (value.Type == undefined && value.Overtime != undefined) {
              return callback(new Error("信息不能为空"));
            }
            return callback();
          },
          trigger: "change",
        },
      ],
      Overtime: [
        {
          validator: (
            rule: any,
            value: DtoFormCompanyOvertimeule_CompanyOvertimeule,
            callback: any
          ) => {
            if (value.Type != undefined && value.Overtime == undefined) {
              return callback(new Error("信息不能为空"));
            }
            return callback();
          },
          trigger: "blur",
        },
      ],
    };

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.Items =
      DtoFormCompanyOvertimeule_CompanyOvertimeuleMiezzListItems;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Data = { Items: [] };

    this.handleGet();
  }

  handleGet(): void {
    CompanyAppService.GetOvertimeRuleById(this.id).then((r) => {
      if (r.data.Data) {
        this.form.Data = r.data.Data;
        if (this.list.Data) {
          this.list.Data.Items = r.data.Data.OvertimeRules;
          this.list.Data.Items?.filter(
            (it) =>
              it.TaskName == "AddDeparturePortWarehouse" ||
              it.TaskName == "AddDeparturePortExpress" ||
              it.TaskName == "AddDeparturePortTrunk" ||
              it.TaskName == "AddDeparturePortBooking" ||
              it.TaskName == "AddDeparturePortContainer" ||
              it.TaskName == "FeedbackDeparturePortWarehouseWarehousing" ||
              it.TaskName == "FeedbackDeparturePortExpress" ||
              it.TaskName == "FeedbackDeparturePortTrunk" ||
              it.TaskName == "FeedbackDeparturePortContainer" ||
              it.TaskName == "AddDeparturePortDeclareCustoms" ||
              it.TaskName == "FeedbackDeparturePortWarehouseExWarehouse" ||
              it.TaskName == "AddDeparturePortBookingMainBill" ||
              it.TaskName == "AddDeparturePortBookingManifestVgm" ||
              it.TaskName == "FeedbackDeparturePortDeclareCustoms" ||
              it.TaskName == "AddDeparturePortBookingMainBillAtd"
          ).forEach((it) => {
            it.Types = EnumCompanyOvertimeRuleTypeOption.filter(
              (it) =>
                it.Data != "EstimatedArrivalDate" &&
                it.Data != "ActualArrivalDate" &&
                it.Data != "CreatedTime"
            );
          });
          this.list.Data.Items?.filter(
            (it) =>
              it.TaskName == "AddDeparturePortBookingMainBillAta" ||
              it.TaskName == "AddDestinationPortClearCustoms" ||
              it.TaskName == "AddDestinationPortContainer" ||
              it.TaskName == "AddDestinationPortWarehouse" ||
              it.TaskName == "FeedbackDestinationPortClearCustoms" ||
              it.TaskName == "FeedbackDestinationPortContainer" ||
              it.TaskName == "AddDestinationPortExpress" ||
              it.TaskName == "AddDestinationPortTrunk" ||
              it.TaskName == "FeedbackDestinationPortWarehouseWarehousing" ||
              it.TaskName == "FeedbackDestinationPortWarehouseExWarehouse" ||
              it.TaskName == "FeedbackDestinationPortExpress" ||
              it.TaskName == "FeedbackDestinationPortTrunk"
          ).forEach((it) => {
            it.Types = EnumCompanyOvertimeRuleTypeOption.filter(
              (it) =>
                it.Data != "EstimatedDepartureDate" &&
                it.Data != "ActualDepartureDate" &&
                it.Data != "ManifestEndDate" &&
                it.Data != "CustomsEndDate" &&
                it.Data != "CreatedTime"
            );
          });
          this.list.Data.Items?.filter(
            (it) => it.TaskName == "AuditPool"
          ).forEach((it) => {
            it.Types = EnumCompanyOvertimeRuleTypeOption;
          });
        }
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyAppService.PostOvertimeRule(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
