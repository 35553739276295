
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import { EnumSupplierSource } from "@/models.machine/EnumSupplierSource";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import DtoSelectSearchCompanyType from "@/models.machine/DtoSelectSearchCompanyType";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import MiezzButton from "@/models/MiezzButton";
import CurrentLogier from "@/apis/CurrentLogier";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
export default class OrderSupplier extends Vue {
  @Prop() canEdit?: boolean;
  @Prop() canSync?: boolean;
  @Prop() scene?: string;
  /**供应商-模态框 */
  modalSupplier = new MiezzModal(MiezzModalType.Dialog);
  /**供应商-表单 */
  formSupplier = new MiezzForm<
    {
      Id?: string;
      PoolId?: string;
      OrderId?: string;
      ServiceType?: EnumOrderServiceType;
      SupplierSource?: EnumSupplierSource;
      SupplierCompanyTypeId?: string;
      SupplierCompanyTypeLabel?: string;
      SupplierCompanyId?: string;
      SupplierCompanyLabel?: string;
      SupplierCompanyContactLabel?: string;
    },
    string
  >();
  /**供应商-来源选择器数据 */
  EnumSupplierSourceOption: UiSelectOption<number>[] = [];
  /**供应商-类型选择器数据 */
  supplierTypes: UiSelectOption<string>[] = [];
  /**供应商-供应商选择器数据 */
  suppliers: DtoSelectOptionCompany[] = [];
  /**供应商-联系人选择器数据 */
  supplierContacts: UiSelectOption<string>[] = [];

  /**供应商-初始化 */
  created(): void {
    this.EnumSupplierSourceOption = [
      { Label: "我的供应商", Value: 1, Data: "My" },
    ];
    CurrentLogier.GetPowerCodes().then((powers) => {
      if (powers?.contains("SupplierManagement_Public")) {
        this.EnumSupplierSourceOption.push({
          Label: "公共供应商",
          Value: 2,
          Data: "Platform",
        });
      }
    });
    this.onCanEditChanged();
    this.modalSupplier.HandleClick = this.handleClick;
    this.formSupplier.Items = [
      {
        Prop: "SupplierSource",
        Label: "来源",
        Customize: true,
      },
      {
        Prop: "SupplierCompanyTypeId",
        Label: "类型",
        Customize: true,
      },
      {
        Prop: "SupplierCompanyId",
        Label: "供应商名称",
        Customize: true,
      },
      {
        Prop: "SupplierCompanyContactLabel",
        Label: "联系人",
        Customize: true,
      },
    ];
    if (this.canSync) {
      this.formSupplier.Items.push({
        Prop: "Sync",
        Label: "同步至所有服务",
        Type: EnumMiezzFormItemType.Switch,
      });
    }
    this.formSupplier.Rules = {
      SupplierCompanyId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
      SupplierCompanyContactLabel: [
        {
          max: 1024,
          message: "文本长度不能超过 1024 个字符",
          trigger: "blur",
        },
      ],
    };
  }

  handleShow(data: {
    Id?: string;
    PoolId?: string;
    OrderId?: string;
    ServiceType?: EnumOrderServiceType;
    SupplierSource?: EnumSupplierSource;
    SupplierCompanyTypeId?: string;
    SupplierCompanyTypeLabel?: string;
    SupplierCompanyId?: string;
    SupplierCompanyLabel?: string;
    SupplierCompanyContactLabel?: string;
  }): void {
    this.formSupplier.Data = {
      Id: data.Id,
      PoolId: data.PoolId,
      OrderId: data.OrderId,
      ServiceType: data.ServiceType,
      SupplierSource: data.SupplierSource
        ? data.SupplierSource
        : EnumSupplierSource.My,
      SupplierCompanyTypeId: data.SupplierCompanyTypeId,
      SupplierCompanyTypeLabel: data.SupplierCompanyTypeLabel,
      SupplierCompanyId: data.SupplierCompanyId,
      SupplierCompanyLabel: data.SupplierCompanyLabel,
      SupplierCompanyContactLabel: data.SupplierCompanyContactLabel,
    };
    this.getSuppliers();
    this.modalSupplier.Show = true;
  }

  /**格式化枚举 */
  formatEnum(options: UiSelectOption<number>[], value?: number): string {
    return (
      options.firstOrDefault((it) => it.Value == value)?.Label ?? "暂无数据"
    );
  }

  @Watch("canEdit")
  onCanEditChanged(): void {
    if (this.canEdit) {
      this.modalSupplier.Title = "编辑供应商";
      this.modalSupplier.Buttons = [
        {
          Code: "save-supplier",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];
    } else {
      this.modalSupplier.Title = "查看供应商";
    }
  }

  /**供应商-来源变更时 */
  @Watch("formSupplier.Data.SupplierSource")
  onSupplierSourceChanged(n?: string, o?: string): void {
    if (n == o) return;
    const dto = new DtoSelectSearchCompanyType();
    if (
      this.formSupplier.Data.SupplierSource ==
      EnumSupplierSource.ServiceProvider
    ) {
      dto.Type = EnumCompanyType.ServiceProvider;
    } else {
      dto.Type = EnumCompanyType.Supplier;
    }
    if (this.canEdit) {
      CompanyTypeAppService.GetSelect(dto).then((r) => {
        this.supplierTypes = r.data.Data ?? [];
        this.getSuppliers();
      });
    }
  }
  /**供应商-类型变更时 */
  @Watch("formSupplier.Data.SupplierCompanyTypeId")
  onSupplierCompanyTypeIdChanged(n?: string, o?: string): void {
    if (n == o) return;
    this.getSuppliers();
  }
  /**供应商-获取供应商 */
  getSuppliers(): void {
    if (this.canEdit) {
      CompanyAppService.GetSelect({
        CompanyType: EnumCompanyType.Supplier,
        SupplierSource: this.formSupplier.Data.SupplierSource,
        CompanyTypeId: this.formSupplier.Data.SupplierCompanyTypeId,
      }).then((r) => {
        this.suppliers = r.data.Data ?? [];
        if (
          this.suppliers.all(
            (it) => it.Id != this.formSupplier.Data.SupplierCompanyId
          )
        ) {
          this.formSupplier.Data.SupplierCompanyId = undefined;
          this.formSupplier.Data.SupplierCompanyLabel = undefined;
        }
        this.filterClientCompanies(this.formSupplier.Data.SupplierCompanyLabel);
      });
    }
  }

  filterCompanies: DtoSelectOptionCompany[] = [];
  filterClientCompanies(queryString?: string): void {
    if (this.suppliers && this.suppliers.length > 0) {
      if (queryString) {
        this.filterCompanies = this.suppliers.filter((it) =>
          it.NameZh?.contains(queryString)
        );
        if (this.filterCompanies && this.filterCompanies.length > 10)
          this.filterCompanies = this.filterCompanies.slice(0, 10);
      } else if (this.formSupplier.Data.SupplierCompanyId) {
        this.filterCompanies = this.suppliers.filter(
          (it) => it.Id == this.formSupplier.Data.SupplierCompanyId
        );
      } else {
        this.filterCompanies = JSON.copy(this.suppliers).slice(0, 10);
      }
    }
  }

  /**供应商-供应商变更时 */
  @Watch("formSupplier.Data.SupplierCompanyId")
  onSupplierCompanyIdChanged(n?: string, o?: string): void {
    if (n == o) return;
    if (this.formSupplier.Data.SupplierCompanyId) {
      if (this.canEdit) {
        CompanyContactAppService.GetSelect({
          CompanyId: this.formSupplier.Data.SupplierCompanyId,
        }).then((r) => {
          this.supplierContacts = r.data.Data ?? [];
        });
      }
    } else {
      this.supplierContacts = [];
      this.formSupplier.Data.SupplierCompanyContactLabel = undefined;
    }
  }
  /**供应商-获取联系人 */
  getSupplierCompanyContact(q: string, cb: any): void {
    cb(this.supplierContacts.filter((it) => it.Label?.contains(q)) ?? []);
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "save-supplier":
        MiezzForm.submit(this.$refs, "ref-form-supplier", (v) => {
          if (v) {
            this.handleSave(this.formSupplier.Data, (s) => {
              if (s) {
                this.modalSupplier.Show = false;
                ElMessage.success("编辑成功");
              }
              complete();
            });
          } else {
            complete();
          }
        });
        break;

      default:
        break;
    }
  }

  @Emit()
  handleSave(data: any, complete: (s: boolean) => void, scene?: string): void {
    //
  }
}
