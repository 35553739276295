import UiSelectOption from "./UiSelectOption";

/**
 * 公司状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyStatus {
    /**
     * 启用
     */
    Normal = 10,
    /**
     * 禁用
     */
    Disabled = 20,
    /**
     * 删除
     */
    Deleted = 999,
}

/**
 * 公司状态-选项
 */
export const EnumCompanyStatusOption: UiSelectOption<number>[] = [
    { Label: "启用", Value: 10, Data: "Normal" },
    { Label: "禁用", Value: 20, Data: "Disabled" },
    { Label: "删除", Value: 999, Data: "Deleted" },
];
