
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { MiezzFormItem } from "@/models/MiezzForm";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import DtoDetailOrderChooseService, {
  DtoDetailOrderChooseServiceMiezzDetailItems,
} from "@/models.machine/DtoDetailOrderChooseService";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import {
  EnumTransportDirection,
  EnumTransportDirectionOption,
} from "@/models.machine/EnumTransportDirection";
import {
  EnumTransportType,
  EnumTransportTypeOption,
} from "@/models.machine/EnumTransportType";
import {
  EnumTransportContainerLoad,
  EnumTransportContainerLoadOption,
} from "@/models.machine/EnumTransportContainerLoad";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import OrderPoolChooseService from "./OrderPoolChooseService.vue";
import { EnumCustomsClearanceHeaderProviderOption } from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import { EnumCustomsClearanceHeaderProvider } from "@/models.machine/EnumCustomsClearanceHeaderProvider";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoHistory from "@/models.machine/DtoHistory";
import { EnumOrderPoolStatus } from "@/models.machine/EnumOrderPoolStatus";
import OrderSummaryTime from "../OrderSummaryTime.vue";
import OrderTask from "../OrderTask.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoDetailOrderPoolSummary_Supplier, {
  DtoDetailOrderPoolSummary_SupplierMiezzListItems,
} from "@/models.machine/DtoDetailOrderPoolSummary_Supplier";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import DtoRequestOrderPoolAssignSalesman from "@/models.machine/DtoRequestOrderPoolAssignSalesman";
import OrderSupplier from "../OrderSupplier.vue";
import { h } from "vue";
import MiezzListItem from "@/models/MiezzListItem";

@Options({
  components: {
    OrderPoolChooseService,
    OrderSummaryTime,
    OrderTask,
    OrderSupplier,
  },
})
export default class OrderPoolSummary extends Vue {
  id = "";
  logier?: Logier;
  powerCodes?: string[] = [];
  EnumCompanyType = EnumCompanyType;
  menuTab = new MiezzMenuTab();
  detail = new MiezzForm<DtoDetailOrderChooseService, string>();
  buttons: MiezzButton[] = [];
  btnMore: MiezzButton = {
    Code: "more",
    Title: "展开子目录",
    Label: " ",
    Icon: "DArrowRight",
    Type: "primary",
    Size: "small",
    Placement: "right",
    Options: [],
  };
  type = "";
  EnumOrderPoolStatus = EnumOrderPoolStatus;
  EnumTransportDirection = EnumTransportDirection;
  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportType = EnumTransportType;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  EnumCustomsClearanceHeaderProvider = EnumCustomsClearanceHeaderProvider;
  destinationPortCustomsClearanceHeaderProvider?: string = "";
  modalChooseService = new MiezzModal(MiezzModalType.Dialog);
  histories: DtoHistory[] = [];
  OrderManagement_Pool_Summary_BillingInformation = false;
  OrderManagement_Pool_Summary_BillingInformation_Client = false;
  isShow?: boolean = true;
  btnTask: MiezzButton = {
    Code: "show-task",
    Label: "待办清单",
    Type: "text",
    Size: "small",
    PowerCode: "OrderManagement_Pool_Task",
  };
  modalTask = new MiezzModal(MiezzModalType.Dialog);

  _codes: string[] = [];
  /**供应商信息-列表配置 */
  listSupplier = new MiezzPageList<
    any,
    DtoDetailOrderPoolSummary_Supplier,
    string
  >();

  btnUpdateSalesman: MiezzButton = {
    Code: "update-salesman",
    Label: "编辑",
    Type: "text",
    Size: "small",
    PowerCode: "OrderManagement_Pool_Summary_UpdateSalesman",
  };
  modalUpdateSalesman = new MiezzModal(MiezzModalType.Dialog);
  formUpdateSalesman = new MiezzForm<
    DtoRequestOrderPoolAssignSalesman,
    string
  >();
  salesmans: UiSelectOption<string>[] = [];

  modalDefaultSupplier = new MiezzModal(MiezzModalType.Dialog);
  listDefaultSupplier = new MiezzPageList<
    any,
    DtoDetailOrderPoolSummary_Supplier,
    string
  >();
  showSupplier = false;
  scene?: string;

  /**创建时 */
  created(): void {
    this.id = this.$route.params.id as string;
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powerCodes = r ?? [];
    });

    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/summary/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "委托摘要",
        Url: `/order/pool/summary/${this.id}`,
        Power: "OrderManagement_Pool_Summary",
      },
    ];

    this.detail.DefaultSpan = 12;
    //详细-数据-初始化
    this.detail.Data = new DtoDetailOrderChooseService();
    //详细-元素配置
    let items = JSON.copy(
      DtoDetailOrderChooseServiceMiezzDetailItems
    ) as MiezzFormItem[];
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      items = items.filter(
        (it) => it.Prop != "Code" && it.Prop != "CustomerSupplierLabel"
      );
      const clientItem = items.firstOrDefault(
        (it) => it.Prop == "Client"
      ) as MiezzFormItem;
      items.splice(items.indexOf(clientItem), 1);
      items.splice(1, 0, clientItem);
      this.detail.Items = items;
    } else {
      const codeItem = items.firstOrDefault((it) => it.Prop == "Code");
      if (codeItem) codeItem.Label = "我的编号";
      items = items.filter(
        (it) =>
          it.Prop != "SalesmanLabel" &&
          it.Prop != "AuditUserLabel" &&
          it.Prop != "ServiceRemark"
      );
      this.detail.Items = items;
      this.modalChooseService.Description =
        "更改业务类型与服务内容，请通知客服人员进行相应操作";
    }

    CurrentLogier.GetPowerCodes().then((r) => {
      if (r?.contains("OrderManagement_Pool_Summary_BillingInformation")) {
        this.OrderManagement_Pool_Summary_BillingInformation = true;
      }
      if (
        r?.contains("OrderManagement_Pool_Summary_BillingInformation_Client")
      ) {
        this.OrderManagement_Pool_Summary_BillingInformation_Client = true;
      }
      this.handleGet();
    });

    this.listSupplier.Fix = false;
    this.listSupplier.HideCard = true;
    this.listSupplier.EnabledDynamicSearch = false;
    this.listSupplier.EnabledRadioSearch = false;
    this.listSupplier.EnabledSelect = false;
    this.listSupplier.EnabledPagination = false;
    this.listSupplier.Items = JSON.copy(
      DtoDetailOrderPoolSummary_SupplierMiezzListItems
    );
    this.listSupplier.Data = {
      Items: [],
    };

    this.listDefaultSupplier.Fix = false;
    this.listDefaultSupplier.HideCard = true;
    this.listDefaultSupplier.EnabledDynamicSearch = false;
    this.listDefaultSupplier.EnabledRadioSearch = false;
    this.listDefaultSupplier.EnabledSelect = false;
    this.listDefaultSupplier.EnabledPagination = false;
    this.listDefaultSupplier.Items = (
      JSON.copy(
        DtoDetailOrderPoolSummary_SupplierMiezzListItems
      ) as MiezzListItem[]
    ).map((it) => {
      if (it.Prop != "ServiceContent") {
        it.Customize = true;
      }
      return it;
    });
    this.listDefaultSupplier.Data = {
      Items: [],
    };
    this.modalDefaultSupplier.Title = "供应商";
    this.modalDefaultSupplier.AlignCenter = true;
    this.modalDefaultSupplier.Height = "200px";
    this.modalDefaultSupplier.HandleClick = this.handleClick;
    this.modalDefaultSupplier.ButtonWidth = "100px";
    this.modalDefaultSupplier.Buttons = [
      {
        Code: "save-supplier",
        Label: "保存并更新",
        Type: "primary",
        Size: "small",
      },
    ];

    this.formUpdateSalesman.Rules = {
      SalesmanId: [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ],
    };

    this.modalUpdateSalesman.Title = "编辑";
    this.modalUpdateSalesman.AlignCenter = true;
    this.modalUpdateSalesman.Width = "700px";
    this.modalUpdateSalesman.Height = "50px";
    this.modalUpdateSalesman.HandleClick = this.handleClick;
    this.modalUpdateSalesman.Buttons = [
      {
        Code: "update-salesman-submit",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];

    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["IdentityPermission_Salesman"],
    }).then((r) => {
      this.salesmans = r.data.Data ?? [];
    });
  }

  changeShow(): void {
    this.isShow = !this.isShow;
  }

  visibilityChange(e: any): void {
    if (e.target.scrollWidth == e.target.clientWidth) {
      this.detail.Data.tooltipFlag = true;
    }
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderPoolAppService.GetChooseServiceById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail.Data = r.data.Data;
          if (this.listSupplier.Data)
            this.listSupplier.Data.Items = this.detail.Data.Suppliers ?? [];

          this.btnTask.Color = this.detail.Data.HasTask
            ? "rgb(254, 153, 91)"
            : "#409eff";

          const customerSupplierLabelItem = this.detail.Items?.firstOrDefault(
            (it) => it.Prop == "CustomerSupplierLabel"
          );
          if (customerSupplierLabelItem) {
            customerSupplierLabelItem.Label =
              this.detail.Data.TransportDirection ==
              EnumTransportDirection.Export
                ? "客户名称"
                : "供应商名称";
          }

          const direction = this.EnumTransportDirectionOption.firstOrDefault(
            (it) => it.Value == this.detail.Data.TransportDirection
          )?.Label;
          this.type = `【${direction}】`;
          if (this.detail.Data.TransportType) {
            const type = this.EnumTransportTypeOption.firstOrDefault(
              (it) => it.Value == this.detail.Data.TransportType
            )?.Label;
            this.type += `【${type}】`;
          }
          if (this.detail.Data.TransportContainerLoad) {
            const containerLoad =
              this.EnumTransportContainerLoadOption.firstOrDefault(
                (it) => it.Value == this.detail.Data.TransportContainerLoad
              )?.Label;
            this.type += `【${containerLoad}】`;
          }

          this.destinationPortCustomsClearanceHeaderProvider =
            EnumCustomsClearanceHeaderProviderOption.firstOrDefault(
              (it) =>
                it.Value ==
                this.detail.Data.DestinationPortCustomsClearanceHeaderProvider
            )?.Label;
          this.buttons = [
            {
              Code: "back",
              Label: "返回",
              MiezzIcon: "back",
              Type: "text",
              Size: "small",
            },
            {
              Code: "edit",
              Label: "编辑服务",
              Icon: "edit",
              Type: "text",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Summary_EditService",
            },
            {
              Code: "edit-supplier",
              Label: "供应商",
              Icon: "edit",
              Type: "text",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Summary_DefaultSupplier",
            },
          ];

          this.btnMore.Options = [
            {
              Code: "Pool",
              Label: "委托书",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Summary_Detail",
            },
          ];
          this._codes.push("DeparturePortBooking");
          console.log(
            this.detail.Data.Status != EnumOrderPoolStatus.WaitingAudit,
            this.detail.Data.DeparturePortBooking,
            this.detail.Data.OrderId
          );
          if (this.detail.Data.Status != EnumOrderPoolStatus.WaitingAudit) {
            //装货港-订舱
            if (this.detail.Data.DeparturePortBooking) {
              this.btnMore.Options?.push({
                Code: "DeparturePortBooking",
                Label: "装货港-订舱",
                Size: "small",
                PowerCode: "OrderManagement_Pool_Summary_DeparturePortBooking",
              });
            }
            //装货港-集装箱拖车
            if (
              //直订订单 并且 委托书FCL 并且有集装箱拖车
              this.detail.Data.DirectOrder &&
              this.detail.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL &&
              this.detail.Data.DeparturePortContainer
            ) {
              this._codes.push("DeparturePortContainer");
              this.btnMore.Options?.push({
                Code: "DeparturePortContainer",
                Label: "装货港-集装箱拖车",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DeparturePortContainer",
              });
            }
            //装货港-快递
            if (this.detail.Data.DeparturePortExpress) {
              this._codes.push("DeparturePortExpress");
              this.btnMore.Options?.push({
                Code: "DeparturePortExpress",
                Label: "装货港-快递",
                Size: "small",
                PowerCode: "OrderManagement_Pool_Summary_DeparturePortExpress",
              });
            }
            //装货港-干线物流
            if (this.detail.Data.DeparturePortTrunk) {
              this._codes.push("DeparturePortTrunk");
              this.btnMore.Options?.push({
                Code: "DeparturePortTrunk",
                Label: "装货港-干线物流",
                Size: "small",
                PowerCode: "OrderManagement_Pool_Summary_DeparturePortTrunk",
              });
            }
            //装货港-报关
            if (
              this.detail.Data.DeparturePortDeclareCustoms &&
              this.detail.Data.DeparturePortDrawback == true
            ) {
              this._codes.push("DeparturePortDeclareCustoms");
              this.btnMore.Options?.push({
                Code: "DeparturePortDeclareCustoms",
                Label: "装货港-报关",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_PortOfDepartureCustomsAffairs",
              });
            }
            //装货港-仓库/堆场/码头
            if (this.detail.Data.DeparturePortWarehouse) {
              this._codes.push("DeparturePortWarehouse");
              this.btnMore.Options?.push({
                Code: "DeparturePortWarehouse",
                Label: "装货港-仓库/堆场/码头",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DeparturePortWarehouse",
              });
            }
            //目的港-清关
            if (
              this.detail.Data.DestinationPortClearCustoms &&
              this.detail.Data.DestinationPortCustomsClearanceHeaderProvider ==
                EnumCustomsClearanceHeaderProvider.Client
            ) {
              this._codes.push("DestinationPortClearCustoms");
              this.btnMore.Options?.push({
                Code: "DestinationPortClearCustoms",
                Label: "目的港-清关",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DestinationPortClearCustoms",
              });
            }
            //目的港-集装箱拖车
            if (
              //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
              (!this.detail.Data.DirectOrder &&
                this.detail.Data.TransportContainerLoad ==
                  EnumTransportContainerLoad.LCL &&
                this.detail.Data.DestinationPortContainer &&
                this.detail.Data.OrderId) ||
              //直订订单 并且 有集装箱拖车
              (this.detail.Data.DirectOrder &&
                this.detail.Data.DestinationPortContainer)
            ) {
              this._codes.push("DestinationPortContainer");
              this.btnMore.Options?.push({
                Code: "DestinationPortContainer",
                Label: "目的港-集装箱拖车",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DestinationPortContainer",
              });
            }
            //目的港-干线物流
            if (this.detail.Data.DestinationPortTrunk) {
              this._codes.push("DestinationPortTrunk");
              this.btnMore.Options?.push({
                Code: "DestinationPortTrunk",
                Label: "目的港-干线物流",
                Size: "small",
                PowerCode: "OrderManagement_Pool_Summary_DestinationPortTrunk",
              });
            }
            //目的港-快递
            if (this.detail.Data.DestinationPortExpress) {
              this._codes.push("DestinationPortExpress");
              this.btnMore.Options?.push({
                Code: "DestinationPortExpress",
                Label: "目的港-快递",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DestinationPortExpress",
              });
            }
            if (this.detail.Data.DestinationPortWarehouse) {
              this._codes.push("DestinationPortWarehouse");
              this.btnMore.Options?.push({
                Code: "DestinationPortWarehouse",
                Label: "目的港-仓库/堆场/码头",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_DestinationPortWarehouse",
              });
            }

            /*始终在最后 */
            if (
              this.OrderManagement_Pool_Summary_BillingInformation_Client ==
              true
            ) {
              this.btnMore.Options?.push({
                Code: "ClientBill",
                Label: "账单信息",
                Size: "small",
                PowerCode:
                  "OrderManagement_Pool_Summary_BillingInformation_Client",
              });
            }
          } else {
            //装货港-集装箱拖车
            if (
              //直订订单 并且 委托书FCL 并且有集装箱拖车
              this.detail.Data.DirectOrder &&
              this.detail.Data.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL &&
              this.detail.Data.DeparturePortContainer
            ) {
              this._codes.push("DeparturePortContainer");
            }
            //装货港-快递
            if (this.detail.Data.DeparturePortExpress) {
              this._codes.push("DeparturePortExpress");
            }
            //装货港-干线物流
            if (this.detail.Data.DeparturePortTrunk) {
              this._codes.push("DeparturePortTrunk");
            }
            //装货港-报关
            if (
              this.detail.Data.DeparturePortDeclareCustoms &&
              this.detail.Data.DeparturePortDrawback == true
            ) {
              this._codes.push("DeparturePortDeclareCustoms");
            }
            //装货港-仓库/堆场/码头
            if (this.detail.Data.DeparturePortWarehouse) {
              this._codes.push("DeparturePortWarehouse");
            }
            //目的港-清关
            if (
              this.detail.Data.DestinationPortClearCustoms &&
              this.detail.Data.DestinationPortCustomsClearanceHeaderProvider ==
                EnumCustomsClearanceHeaderProvider.Client
            ) {
              this._codes.push("DestinationPortClearCustoms");
            }
            //目的港-集装箱拖车
            if (
              //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
              (!this.detail.Data.DirectOrder &&
                this.detail.Data.TransportContainerLoad ==
                  EnumTransportContainerLoad.LCL &&
                this.detail.Data.DestinationPortContainer &&
                this.detail.Data.OrderId) ||
              //直订订单 并且 有集装箱拖车
              (this.detail.Data.DirectOrder &&
                this.detail.Data.DestinationPortContainer)
            ) {
              this._codes.push("DestinationPortContainer");
            }
            //目的港-干线物流
            if (this.detail.Data.DestinationPortTrunk) {
              this._codes.push("DestinationPortTrunk");
            }
            //目的港-快递
            if (this.detail.Data.DestinationPortExpress) {
              this._codes.push("DestinationPortExpress");
            }
            if (this.detail.Data.DestinationPortWarehouse) {
              this._codes.push("DestinationPortWarehouse");
            }
          }

          if (this.OrderManagement_Pool_Summary_BillingInformation == true) {
            this.btnMore.Options?.push({
              Code: "Bill",
              Label: "账单信息",
              Size: "small",
              PowerCode: "OrderManagement_Pool_Summary_BillingInformation",
            });
          }

          this.btnMore.Options?.push({
            Code: "File",
            Label: "附件",
            Size: "small",
            PowerCode: "OrderManagement_Pool_Summary_File",
          });

          this.btnMore.Options.filter((it) =>
            this.detail.Data.TaskActives?.any((a) => a.Service == it.Code)
          ).forEach((it) => {
            it.Active = true;
          });

          const codes = this.btnMore.Options.map((it) => it.Code);
          this.listSupplier.Items = this.listSupplier.Items?.filter(
            (it) =>
              it.Prop == "ServiceContent" ||
              it.Prop == "DeparturePortBooking" ||
              codes.contains(it.Prop)
          );
          console.log(this.listSupplier.Items, this.listSupplier.Data?.Items);
          this.listDefaultSupplier.Items =
            this.listDefaultSupplier.Items?.filter(
              (it) =>
                it.Prop == "ServiceContent" ||
                it.Prop == "DeparturePortBooking" ||
                this._codes.contains(it.Prop)
            );
        }
      });

      OrderPoolAppService.GetSummaryHistoriesById(this.id).then((r) => {
        this.histories = r.data.Data ?? [];
      });
    }
  }

  /**选择服务完成回调 */
  handleChooseServiceSubmit(id: string): void {
    this.handleGet();
  }

  onClickLi(btn: MiezzButton): void {
    var taskNames = this.detail.Data.TaskActives?.firstOrDefault(
      (it) => it.Service == btn.Code
    )?.Types;
    switch (btn.Code) {
      case "Pool":
        this.$router.push({
          path: `/order/pool/detail/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DeparturePortBooking":
        this.$router.push({
          path: `/order/pool/departure-port-booking/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DeparturePortContainer":
        this.$router.push({
          path: `/order/pool/departure-port-container/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DestinationPortContainer":
        this.$router.push({
          path: `/order/pool/destination-port-container/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DeparturePortTrunk":
        this.$router.push({
          path: `/order/pool/departure-port-trunk/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DestinationPortTrunk":
        this.$router.push({
          path: `/order/pool/destination-port-trunk/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DeparturePortExpress":
        this.$router.push({
          path: `/order/pool/departure-port-express/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DestinationPortExpress":
        this.$router.push({
          path: `/order/pool/destination-port-express/${this.id}`,
          query: { task: taskNames, code: btn.Code },
        });
        break;
      case "DeparturePortDeclareCustoms":
        this.$router.push({
          path: `/pool/departure/customs/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        break;
      case "DestinationPortClearCustoms":
        this.$router.push({
          path: `/pool/destination/customs/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        break;
      case "DeparturePortWarehouse":
        this.$router.push({
          path: `/pool/departure/wharf/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        break;
      case "DestinationPortWarehouse":
        this.$router.push({
          path: `/pool/destination/wharf/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        break;
      case "Bill":
        this.$router.push({
          path: `/pool/bill/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        // window.open(
        //   `/#/pool/bill/${this.id}` +
        //     "?task=" +
        //     taskNames +
        //     "&code=" +
        //     btn.Code +
        //     "&backUrl=" +
        //     this.$route.fullPath,
        //   "_blank"
        // );
        break;
      case "ClientBill":
        this.$router.push({
          path: `/pool/bill/client/${this.id}`,
          query: {
            task: taskNames,
            code: btn.Code,
            backUrl: this.$route.fullPath,
          },
        });
        break;
      case "File":
        this.$router.push({
          path: `/order/pool/file/${this.id}`,
        });
        break;
      default:
        break;
    }
  }

  handleChooseSupplier(scene?: string): void {
    this.scene = scene;
    var supplierData =
      this.detail.Data.DefaultSuppliers?.firstOrDefault(
        (it) => it.Scene == scene
      ) ?? {};
    console.log(this.$refs["ref-order-supplier"] as OrderSupplier);
    (this.$refs["ref-order-supplier"] as OrderSupplier).handleShow(
      supplierData
    );
  }

  handleSaveSupplier(data: any, complete: (s: boolean) => void): void {
    for (const code of this._codes) {
      if (data.Sync || code == this.scene) {
        let supplier: any = this.detail.Data.DefaultSuppliers?.firstOrDefault(
          (it) => it.Scene == code
        );
        if (!supplier) {
          supplier = { PoolId: this.id, Scene: code };
          supplier.SupplierSource = data.SupplierSource;
          supplier.SupplierCompanyTypeId = data.SupplierCompanyTypeId;
          supplier.SupplierCompanyTypeLabel = data.SupplierCompanyTypeLabel;
          supplier.SupplierCompanyId = data.SupplierCompanyId;
          supplier.SupplierCompanyLabel = data.SupplierCompanyLabel;
          supplier.SupplierCompanyContactLabel =
            data.SupplierCompanyContactLabel;
          this.detail.Data.DefaultSuppliers?.push(supplier);
        } else {
          supplier.SupplierSource = data.SupplierSource;
          supplier.SupplierCompanyTypeId = data.SupplierCompanyTypeId;
          supplier.SupplierCompanyTypeLabel = data.SupplierCompanyTypeLabel;
          supplier.SupplierCompanyId = data.SupplierCompanyId;
          supplier.SupplierCompanyLabel = data.SupplierCompanyLabel;
          supplier.SupplierCompanyContactLabel =
            data.SupplierCompanyContactLabel;
        }
      }
    }
    this.handleUpdateSupplier();
    complete(true);
  }

  handleUpdateSupplier(): void {
    var d: any = {
      ServiceContent: "供应商名称",
    };
    for (const item of this.listDefaultSupplier.Items ?? []) {
      if (item.Prop != "ServiceContent") {
        const supplier = this.detail.Data.DefaultSuppliers?.firstOrDefault(
          (it) => it.Scene == item.Prop
        );
        const value = [
          supplier?.SupplierCompanyLabel,
          supplier?.SupplierCompanyContactLabel,
        ]
          .filter((it) => it)
          .join("/");
        d[item.Prop ?? ""] = value ? value : "暂无数据";
      }
    }
    this.listDefaultSupplier.Data = {
      Items: [d],
    };
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "show-task":
        this.modalTask.Show = true;
        complete();
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      case "edit":
        if (this.detail.Data.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail.Data.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        // if (
        //   this.logier?.CompanyType == EnumCompanyType.Customer &&
        //   this.detail.Data.OrderId
        // ) {
        //   ElMessage.warning(
        //     "您的委托正在执行中，如需修改信息，请通知客服人员进行相应操作"
        //   );
        // } else {
        this.modalChooseService.Show = true;
        // }
        complete();
        break;
      case "edit-supplier":
        if (this.detail.Data.OrderId) {
          ElMessageBox({
            title: "提示",
            message: h("p", null, [
              h("span", null, "已关联订单，请在"),
              h(
                "a",
                {
                  href: `#/order/summary/${this.detail.Data.OrderId}?backUrl=${this.$route.fullPath}`,
                },
                "订单"
              ),
              h("span", null, "中设置"),
            ]),
          });
          complete();
          return;
        }
        if (this.detail.Data.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        this.handleUpdateSupplier();
        this.modalDefaultSupplier.Show = true;
        complete();
        break;
      case "save-supplier":
        if (this.detail.Data.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail.Data.Status == EnumOrderPoolStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        OrderPoolAppService.PostDefaultSupplier({
          Id: this.id,
          Update: true,
          DefaultSuppliers: this.detail.Data.DefaultSuppliers,
        })
          .then(() => {
            this.modalDefaultSupplier.Show = false;
            this.handleGet();
            ElMessage.success("操作成功");
            complete();
          })
          .catch(complete);
        break;
      case "update-salesman-submit":
        (this.$refs["ref-form-update-salesman"] as any).validate(
          (v: boolean) => {
            if (v) {
              OrderPoolAppService.AssignSalesman(this.formUpdateSalesman.Data)
                .then(() => {
                  ElMessage.success("编辑成功");
                  this.handleGet();
                  this.modalUpdateSalesman.Show = false;
                  complete();
                })
                .catch(complete);
            }
          }
        );
        complete();
        break;
      case "update-salesman":
        this.formUpdateSalesman.Data = {
          Id: this.id,
          SalesmanId: this.detail.Data.SalesmanId,
          SalesmanLabel: this.detail.Data.SalesmanLabel,
        };
        this.modalUpdateSalesman.Show = true;
        complete();
        break;
      case "more":
        var taskNames = this.detail.Data.TaskActives?.firstOrDefault(
          (it) => it.Service == btn.Command
        )?.Types;
        switch (btn.Command) {
          case "Pool":
            this.$router.push({
              path: `/order/pool/detail/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DeparturePortBooking":
            this.$router.push({
              path: `/order/pool/departure-port-booking/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DeparturePortContainer":
            this.$router.push({
              path: `/order/pool/departure-port-container/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DestinationPortContainer":
            this.$router.push({
              path: `/order/pool/destination-port-container/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DeparturePortTrunk":
            this.$router.push({
              path: `/order/pool/departure-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DestinationPortTrunk":
            this.$router.push({
              path: `/order/pool/destination-port-trunk/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DeparturePortExpress":
            this.$router.push({
              path: `/order/pool/departure-port-express/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DestinationPortExpress":
            this.$router.push({
              path: `/order/pool/destination-port-express/${this.id}`,
              query: { task: taskNames },
            });
            complete();
            break;
          case "DeparturePortDeclareCustoms":
            this.$router.push({
              path: `/pool/departure/customs/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            complete();
            break;
          case "DestinationPortClearCustoms":
            this.$router.push({
              path: `/pool/destination/customs/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            complete();
            break;
          case "DeparturePortWarehouse":
            this.$router.push({
              path: `/pool/departure/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          case "DestinationPortWarehouse":
            this.$router.push({
              path: `/pool/destination/wharf/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          case "Bill":
            this.$router.push({
              path: `/pool/bill/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          case "ClientBill":
            this.$router.push({
              path: `/pool/bill/client/${this.id}`,
              query: { task: taskNames, backUrl: this.$route.fullPath },
            });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }
}
