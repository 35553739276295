import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailJoinCompanyUserPersonalProfile_JoinCompanyUserGroup from "./DtoDetailJoinCompanyUserPersonalProfile_JoinCompanyUserGroup";
import DtoDetailJoinCompanyUserPersonalProfile_User from "./DtoDetailJoinCompanyUserPersonalProfile_User";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailJoinCompanyUserPersonalProfile extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.JoinCompanyUserGroups = [];
        this.Departments = "";
        this.Level = 0;
    }

    [proName: string]: any;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 真实姓名
     */
    Name?: string;
    /**
     * 部门
     */
    JoinCompanyUserGroups?: DtoDetailJoinCompanyUserPersonalProfile_JoinCompanyUserGroup[];
    /**
     * 部门
     */
    Departments?: string;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 用户Id
     */
    UserId?: string;
    /**
     * 用户
     */
    User?: DtoDetailJoinCompanyUserPersonalProfile_User;
    /**
     * 登录账号（手机号）
     */
    PhoneNumber?: string;
    /**
     * 最后登录时间
     */
    LastLoginTime?: Date;
    /**
     * 最后登录IP
     */
    Ip4?: string;
}

/**
 * -表单校验
 */
export const DtoDetailJoinCompanyUserPersonalProfileRules = {
}

/**
 * -设置项
 */
export const DtoDetailJoinCompanyUserPersonalProfileMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Avatar",
    Label: "头像",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "真实姓名",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Departments",
    Label: "部门",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Job",
    Label: "职务",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Level",
    Label: "职级",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PhoneNumber",
    Label: "登录账号（手机号）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "LastLoginTime",
    Label: "最后登录时间",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Ip4",
    Label: "最后登录IP",
    Type: EnumMiezzFormItemType.Text,
  },
]
