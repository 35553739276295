
import MenuAppService from "@/apis.machine/MenuAppService";
import DtoCallbackMenuGetReferencedInvalidWebApiItem, {
  DtoCallbackMenuGetReferencedInvalidWebApiItemMiezzListItems,
} from "@/models.machine/DtoCallbackMenuGetReferencedInvalidWebApiItem";
import DtoTreeMenu from "@/models.machine/DtoTreeMenu";
import DtoTreeSearchMenu from "@/models.machine/DtoTreeSearchMenu";
import { EnumMenuSceneOption } from "@/models.machine/EnumMenuScene";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import UiTreeDrag from "@/models.machine/UiTreeDrag";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import router from "@/router";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import MenuDetail from "./MenuDetail.vue";
import MenuForm from "./MenuForm.vue";
import UiSelectOption from "@/models.machine/UiSelectOption";
import WebApiAppService from "@/apis.machine/WebApiAppService";
@Options({
  components: {
    MenuForm,
    MenuDetail,
  },
})
export default class MenuTree extends Vue {
  models: DtoTreeMenu[] = [];
  dto: any = new DtoTreeSearchMenu();
  /**绑定属性 */
  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  modalDetail = new MiezzModal();
  modalForm = new MiezzModal();
  modalInvalidWebApi = new MiezzModal();
  modalRepairInvalidWebApi = new MiezzModal();

  listInvalidWebApi = new MiezzPageList();

  select = new DtoTreeMenu();

  id?: string;
  path?: string;
  invalidWebApi?: DtoCallbackMenuGetReferencedInvalidWebApiItem;
  webApiId?: string;

  EnumMenuSceneOption = EnumMenuSceneOption;
  topButtons: MiezzButton[] = [];
  webApiSelectOptions: UiSelectOption<string>[] = [];
  webApiFilterSelectOptions: UiSelectOption<string>[] = [];
  repairBtns?: MiezzButton[] = [];

  created(): void {
    this.topButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "switch",
        Title: "切换为列表视图",
        Icon: "switch",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalInvalidWebApi.Title = "失效接口";
    this.modalInvalidWebApi.Width = "80%";
    this.listInvalidWebApi.Fix = false;
    this.listInvalidWebApi.EnabledDynamicSearch = false;
    this.listInvalidWebApi.EnabledRadioSearch = false;
    this.listInvalidWebApi.EnabledSelect = false;
    this.listInvalidWebApi.EnabledPagination = false;
    this.listInvalidWebApi.Items =
      DtoCallbackMenuGetReferencedInvalidWebApiItemMiezzListItems;
    this.listInvalidWebApi.RowButtons = [
      {
        Code: "repair",
        Title: "修复接口",
        Icon: "edit",
        Type: "default",
        Size: "small",
      },
    ];
    this.modalRepairInvalidWebApi.Title = "修复失效接口";
    this.modalRepairInvalidWebApi.HandleClick = this.handleRepairInvalidWebApi;
    this.modalRepairInvalidWebApi.Buttons = [
      {
        Code: "repair",
        Title: "修复接口",
        Label: "修复接口",
        Type: "primary",
        Size: "small",
      },
      {
        Code: "delete",
        Title: "删除引用",
        Label: "删除引用",
        Type: "default",
        Size: "small",
      },
    ];

    const query = router.currentRoute.value.query;
    for (const key in query) {
      let value: any = query[key];
      if (value == "true" || value == "false") {
        value = value == "true";
      } else {
        this.dto[key] = value;
      }
    }
    this.handleGet();

    WebApiAppService.GetSelect().then((r) => {
      if (r.data.Data) {
        this.webApiSelectOptions = r.data.Data;
        this.webApiFilterSelectOptions = this.webApiSelectOptions;
        this.handleGetReferencedInvalidWebApi();
      }
    });
  }

  /**事件-获取 */
  handleGet(): void {
    /**路由地址追加分页参数 */
    const current = router.currentRoute.value;
    for (const key in this.dto) {
      current.query[key] = this.dto[key];
    }
    router.push({
      path: current.path,
      params: current.params,
      query: current.query,
      force: true,
    });
    MenuAppService.GetTree(this.dto).then((r) => {
      if (r.data.Data) this.models = r.data.Data;
    });
  }

  /**事件-单选搜索 */
  handleRadioSearch(): void {
    this.handleGet();
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增菜单";
        this.id = undefined;
        this.path = "/";
        complete();
        break;
      case "switch":
        router.push("/sys/menu");
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoCallbackMenuGetReferencedInvalidWebApiItem,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "repair":
        this.modalRepairInvalidWebApi.Show = true;
        this.invalidWebApi = row;
        this.webApiId = undefined;
        this.webApiFilterSelectOptions = this.webApiSelectOptions.filter((it) =>
          it.Label?.contains(row.Summary ?? "")
        );
        setTimeout(() => {
          const webApiId = document.getElementById("webApiId") as any;
          webApiId.value = row.Summary;
          webApiId.focus();
        }, 100);
        complete();
        break;
      default:
        break;
    }
  }

  handleGetReferencedInvalidWebApi(): void {
    MenuAppService.GetReferencedInvalidWebApi().then((r) => {
      this.listInvalidWebApi.Data!.Items = r.data.Data?.Items;
      if (
        this.listInvalidWebApi.Data?.Items &&
        this.listInvalidWebApi.Data.Items.length > 0
      ) {
        // this.modalInvalidWebApi.Show = true;
      } else {
        this.modalInvalidWebApi.Show = false;
      }
    });
  }

  webApiFilter(queryString: string): void {
    this.webApiFilterSelectOptions = queryString
      ? this.webApiSelectOptions.filter((it) => it.Label?.contains(queryString))
      : this.webApiSelectOptions;
  }

  handleRepairInvalidWebApi(btn: MiezzButton, complete: () => void): void {
    if (btn.Code == "repair") {
      if (this.webApiId) {
        MenuAppService.RepairInvalidWebApiByInvalidId(
          this.invalidWebApi?.Id,
          this.webApiId
        ).then(() => {
          this.modalRepairInvalidWebApi.Show = false;
          this.handleGetReferencedInvalidWebApi();
          complete();
        });
      } else {
        ElMessage.error("请选择修复后的接口");
      }
    } else {
      MenuAppService.RepairInvalidWebApiByInvalidId(
        this.invalidWebApi?.Id,
        undefined
      ).then(() => {
        this.modalRepairInvalidWebApi.Show = false;
        this.handleGetReferencedInvalidWebApi();
        complete();
      });
    }
  }

  handleNodeClick(data: DtoTreeMenu): void {
    this.modalDetail.Show = true;
    this.modalDetail.Title = `菜单详细-${data.Name}`;
    this.select = data;
    this.id = this.select.Id;
    this.path = undefined;
  }

  /**表单提交 */
  handleSubmit(complete: () => void): void {
    this.modalForm.Show = false;
    this.handleGet();
    complete();
  }

  /**删除 */
  handleDelete(complete: () => void): void {
    this.modalDetail.Show = false;
    this.handleGet();
    complete();
  }

  handleDrop(
    draggingNode: any,
    dropNode: any,
    dropType: string,
    ev: any
  ): void {
    const dragging: DtoTreeMenu = draggingNode.data;
    const drop: DtoTreeMenu = dropNode.data;
    const dto = new UiTreeDrag<string>();
    dto.DraggingId = dragging.Id;
    dto.DraggingParentId = dragging.ParentId;
    dto.DropId = drop.Id;
    dto.DropParentId = drop.ParentId;
    dto.DragType =
      dropType == "before"
        ? EnumUiDragType.Before
        : dropType == "after"
        ? EnumUiDragType.After
        : EnumUiDragType.Inner;
    MenuAppService.DragSort(dto).then((r) => {
      ElMessage.success("排序成功");
      this.handleGet();
    });
  }
}
