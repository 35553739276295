
import UiHistory from "@/models.machine/UiHistory";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzHistory extends Vue {
  @Model() modelValue?: UiHistory[];

  handleDateFormat(date?: Date): string {
    return moment(date)?.format("YYYY/MM/DD HH:mm");
  }
}
