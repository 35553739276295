
    import moment from "moment";
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzForm from "@/models/MiezzForm";
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import DtoPageListOrderPoolPackageShape from "@/models.machine/DtoPageListOrderPoolPackageShape";
    import DtoListItemOrderPoolPackageShape, {
        DtoListItemOrderPoolPackageShapeMiezzListItems,
    } from "@/models.machine/DtoListItemOrderPoolPackageShape";

    import DtoPageListOrderDepartureWharfInStock from "@/models.machine/DtoPageListOrderDepartureWharfInStock";
    import DtoListItemOrderDepartureWharfInStock, {
        DtoListItemOrderDepartureWharfInStockMiezzListItems,
    } from "@/models.machine/DtoListItemOrderDepartureWharfInStock";

    import DtoPageListOrderDepartureWharfOutStock from "@/models.machine/DtoPageListOrderDepartureWharfOutStock";
    import DtoListItemOrderDepartureWharfOutStock, {
        DtoListItemOrderDepartureWharfOutStockMiezzListItems,
    } from "@/models.machine/DtoListItemOrderDepartureWharfOutStock";

    import DtoFormOrderDepartureWharfOutStock from "@/models.machine/DtoFormOrderDepartureWharfOutStock";
    import MiezzListItem from "../../models/MiezzListItem";
    import DtoRequestOrderPoolPackageShape from "@/models.machine/DtoRequestOrderPoolPackageShape";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    export default class MiezzWharfOutStock extends Vue {
        //委托书主键
        @Prop() orderPoolId?: string;
        @Prop() isDetail?: boolean;

        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        orderDepartureWharfId?: string;
        chargeUnits?: UiSelectOption<string>[] = [];
        packageEnHistorys?: UiSelectOption<string>[] = [];
        isLoading = false;
        ruleForm: {
            Visible: boolean;
            Total: number;
            CurrentPage: number;
            PageSize: number;
        } = {
                Visible: false,
                Total: 0,
                CurrentPage: 1,
                PageSize: 10,
            };
        currentPage = 1;
        grossWeightRandom = 0;
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "all",
                Label: "全部出库",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        GrossWeights = [];
        defaultTime = new Date(2000, 1, 1, 12, 0, 0);

        form = new MiezzForm<DtoFormOrderDepartureWharfOutStock, string>();

        isInit = false;
        selects = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfOutStock, string>();
        outStocks = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfOutStock, string>();
        outStocksInit = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfOutStock, string>();
        list = new MiezzPageList<DtoRequestOrderPoolPackageShape, DtoListItemOrderDepartureWharfInStock, string>();
        isInStock = true;
        inStocks?: UiSelectOption<string>[] = [];
        selectInStocks?: UiSelectOption<string>[] = []; //可以选择的入库货量
        customsPackageTableRules = {
            OutStockQuantity: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[2]);
                        if (this.outStocks.Data != undefined && this.outStocks.Data.Items != undefined) {
                            var t = this.outStocks.Data.Items[number];
                            
                            var quantity = 0;
                            var inStockQuantity = 0;
                            for (var it of this.outStocks.Data.Items) {
                                if (it.Length == t.Length && it.Width == t.Width && it.Height == t.Height && it.GrossWeight == t.GrossWeight) {
                                    if (it.OutStockQuantity) {
                                        quantity += Number(it.OutStockQuantity);
                                    }
                                }
                            }
                            if (this.outStocks.Data.InStocks) {
                                for (var sl of this.outStocks.Data.InStocks) {
                                    if (sl.Length == t.Length && sl.Width == t.Width && sl.Height == t.Height && sl.GrossWeight == t.GrossWeight) {
                                        if (sl.Quantity) {
                                            inStockQuantity += Number(sl.Quantity);
                                        }
                                    }
                                }
                            }
                            
                            if (quantity && inStockQuantity && quantity > inStockQuantity) {
                                callback("出库货量不能大于入库货量，请重新填写");
                                return false;
                            }
                        }
                        //if (this.form.Data.LatinAliass && this.form.Data.LatinAliass.length > 0) {
                        //    for (var i = 0; i < this.form.Data.LatinAliass.length; i++) {
                        //        if (number != i && this.form.Data.LatinAliass[i].Name) {
                        //            if (this.form.Data.LatinAliass[i].Name.toUpperCase() == value.toUpperCase()) {
                        //                callback("信息重复，请重新填写");
                        //                return false;
                        //            }
                        //        }
                        //    }

                        //}
                        callback();
                    },
                    trigger: "blur",
                },],
        };
        created(): void {

            //列表-传参
            this.list.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.list.Data = new DtoPageListOrderDepartureWharfInStock();
            //列表-列配置
            this.list.Items = DtoListItemOrderDepartureWharfInStockMiezzListItems;

            //列表-传参
            this.outStocks.Dto = new DtoRequestOrderPoolPackageShape();
            //列表-数据-初始
            this.outStocks.Data = new DtoPageListOrderDepartureWharfOutStock();
            //列表-列配置
            this.outStocks.Items = DtoListItemOrderDepartureWharfOutStockMiezzListItems;

            //ParamChargeUnitAppService.GetSelect().then(r => {
            //    this.chargeUnits = r.data.Data;
            //    console.log(r);
            //})

        }
        onDeletePackageEn(id: string, index: number): void {
            //PackageEnAppService.RemoveHistoryById(id).then((r) => {
            //    this.packageEnHistorys?.splice(index, 1);
            //    //this.form.Data.PackageEn = this.preSelectPackageEn;
            //    return;
            //})

            var value = "";
            if (this.outStocks.Data) {
                value = this.outStocks.Data.ChargeUnitOutStock;
            }
            PackageEnAppService.RemoveHistoryById(id).then((r) => {
                var oldValue = "";
                if (this.packageEnHistorys) {
                    oldValue = this.packageEnHistorys[index].Label as string;
                }

                this.packageEnHistorys?.splice(index, 1);
                if (this.outStocks.Data) {
                    if (oldValue != value) {
                        this.outStocks.Data.ChargeUnitOutStock = value;
                    }
                    else {
                        this.outStocks.Data.ChargeUnitOutStock = "";
                    }
                }

                if (this.outStocks.Data && this.outStocks.Data.ChargeUnitOutStock == "" && this.packageEnHistorys && this.packageEnHistorys.length > 0) {
                    this.outStocks.Data.ChargeUnitInStock = this.packageEnHistorys[0].Label;
                }

                ElMessage.success("历史记录已删除");
                return;
            })

        }
        onBatchDelete(): void {
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {

                        if (this.selects.Data == undefined || this.selects.Data.Items == undefined || this.selects.Data.Items.length <= 0) {
                            ElMessage.warning("请至少勾选一条信息");
                            return;
                        }
                        else {
                            if (this.outStocks.Data && this.outStocks.Data.Items) {
                                var indexs = [];
                                for (var it of this.selects.Data.Items) {
                                    indexs.push(it.Index);
                                }
                                for (var index of indexs) {
                                    this.outStocks.Data.Items = this.outStocks.Data.Items.filter(item => item.Index !== index);
                                }
                            }
                            this.onSum(false);
                        }
                    }
                }
            })

        }

        handleSelectionChange(val: any, index: number) {
            if (this.selects.Data) {
                this.selects.Data.Items = val;
            }
        }

        /*初始化 */
        @Emit()
        onInit(orderDepartureWharfId: string | undefined): void {
            this.isInStock = true;
            var packageEnParam = {};
            PackageEnAppService.GetSelect(packageEnParam).then(en => {
                this.packageEnHistorys = en.data.Data;

                this.orderDepartureWharfId = orderDepartureWharfId;
                this.isLoading = true;
                this.ruleForm.CurrentPage = 1;
                this.ruleForm.Total = 0;
                this.isInit = true;
                this.onData();
                this.ruleForm.Visible = true;
                if (this.bottomButtons) {
                    this.bottomButtons[0].Hide = this.isDetail;
                    this.bottomButtons[1].Hide = this.isDetail;
                }
            })

        }
        //onSearch(): void {
        //    if (this.isInit == false) {
        //        MiezzForm.customSubmit(this.$refs, "ruleFormInStock", (v) => {
        //            if (v == true) {
        //                this.onData();
        //            } else {
        //                this.ruleForm.CurrentPage = this.currentPage;
        //            }
        //        });
        //    } else {
        //        this.isInit = false;
        //        this.onData();
        //    }
        //}
        onAdd(): void {
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {


                        if (this.outStocks.Data && this.outStocks.Data.Items) {

                            var index = 0;
                            if (this.outStocks.Data.Items.length > 0) {
                                var max = this.outStocks.Data.Items[this.outStocks.Data.Items.length - 1].Index;
                                if (max != undefined) {
                                    index = max + 1;
                                }
                            }
                            this.outStocks.Data.Items.push({
                                Shape: undefined,
                                GrossWeight: undefined,
                                Volume: undefined,
                                OutStockQuantity: undefined,
                                OutStockTime: undefined,
                                Index: index,
                            });
                        }
                    }
                }
            })
        }
        onDelete(index: number): void {
            this.stateRequestParam = new DtoRequestOrderState();
            this.stateRequestParam.OrderId = [];
            this.stateRequestParam.PoolId = [];
            if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                this.stateRequestParam.PoolId.push(this.orderPoolId as string);
            }
            OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                if (state.data.Data) {
                    this.stateCallbback = state.data.Data;
                    if (this.stateCallbback.OrderCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.PoolCancel == true) {
                        ElMessage.warning("订单已取消");
                        return;
                    }
                    else if (this.stateCallbback.IsLock == true) {
                        ElMessage.warning("订单已锁定");
                        return;
                    }
                    else {


                        if (this.outStocks.Data && this.outStocks.Data.Items) {
                            this.outStocks.Data.Items.splice(index, 1);

                            for (var i = 0; i < this.outStocks.Data.Items.length; i++) {
                                var name = 'Data.Items.' + i + '.OutStockQuantity';
                                MiezzForm.validateFieldCustom(this.$refs, "ruleFormInStock", name);
                            }
                        }

                        this.onSum(false);
                    }
                }
            })

        }
        onChangeSumQuantity(): void {
            if (this.outStocks.Data != undefined) {
                var result = setDecimal(this.outStocks.Data.SumQuantityOutStock + "", 0, false);
                if (result != "") {
                    this.outStocks.Data.SumQuantityOutStock = Number(result);
                } else {
                    this.outStocks.Data.SumQuantityOutStock = undefined;
                }
            }
        }
        onChangeSumGrossWeight(): void {
            if (this.outStocks.Data != undefined) {
                var result = setDecimal(this.outStocks.Data.SumGrossWeightOutStock + "", 2, false);
                if (result != "") {
                    this.outStocks.Data.SumGrossWeightOutStock = Number(result);
                } else {
                    this.outStocks.Data.SumGrossWeightOutStock = undefined;
                }
            }
        }
        onChangeSumVolume(): void {
            if (this.outStocks.Data != undefined) {
                var result = setDecimal(this.outStocks.Data.SumVolumeOutStock + "", 4, false);
                if (result != "") {
                    this.outStocks.Data.SumVolumeOutStock = Number(result);
                } else {
                    this.outStocks.Data.SumVolumeOutStock = undefined;
                }
            }
        }
        onChangeGrossWeight(index: number): void {
            if (this.outStocks.Data != undefined && this.outStocks.Data.Items != undefined) {
                var result = setDecimal(this.outStocks.Data.Items[index].GrossWeight + "", 0, false);
                if (result != "") {
                    this.outStocks.Data.Items[index].GrossWeight = Number(result);
                } else {
                    this.outStocks.Data.Items[index].GrossWeight = undefined;
                }
            }

            this.onSum(false);
        }

        onChangeShape(index: number, isInit: boolean | undefined): void {
            this.GrossWeights = [];
            if (this.outStocks.Data != undefined && this.outStocks.Data.Items != undefined) {
                if (this.selectInStocks != undefined && this.selectInStocks.length > 0) {
                    if (this.isInit == false) {
                        this.outStocks.Data.Items[index].GrossWeight = undefined;
                    }
                    for (var it of this.selectInStocks) {
                        if (it.Data && it.Data.Shape == this.outStocks.Data.Items[index].Shape) {
                            if (it.Data) {
                                this.outStocks.Data.Items[index].Length = it.Data.Length;
                                this.outStocks.Data.Items[index].Width = it.Data.Width;
                                this.outStocks.Data.Items[index].Height = it.Data.Height;
                                this.outStocks.Data.Items[index].Volume = it.Data.Volume;
                                this.GrossWeights = it.Data.GrossWeights;
                                this.outStocks.Data.Items[index].GrossWeights = it.Data.GrossWeights;
                                if (this.GrossWeights != undefined && this.GrossWeights.length == 1) {
                                    this.outStocks.Data.Items[index].GrossWeight = this.GrossWeights[0];
                                }
                                this.grossWeightRandom = Math.random();
                            }
                        }
                    }
                }
            }
            this.onSum(isInit);
        }

        onFocusShape(index: number): void {
            this.GrossWeights = [];
            if (this.outStocks.Data != undefined && this.outStocks.Data.Items != undefined) {
                if (this.selectInStocks != undefined && this.selectInStocks.length > 0 && this.outStocks.Data.Items[index].Shape != undefined) {
                    for (var it of this.selectInStocks) {
                        if (it.Data && it.Data.Shape == this.outStocks.Data.Items[index].Shape) {
                            this.outStocks.Data.Items[index].Length = it.Data.Length;
                            this.outStocks.Data.Items[index].Width = it.Data.Width;
                            this.outStocks.Data.Items[index].Height = it.Data.Height;
                            this.outStocks.Data.Items[index].Volume = it.Data.Volume;
                            this.GrossWeights = it.Data.GrossWeights;
                            if (this.GrossWeights != undefined && this.GrossWeights.length == 1) {
                                this.outStocks.Data.Items[index].GrossWeight = this.GrossWeights[0];
                            }
                        }
                    }
                }
            }
            this.onSum(false);
        }


        onChangeOutStockQuantity(index: number): void {
            if (this.outStocks.Data != undefined && this.outStocks.Data.Items != undefined) {
                var result = setDecimal(this.outStocks.Data.Items[index].OutStockQuantity + "", 0, false);
                if (result != "") {
                    this.outStocks.Data.Items[index].OutStockQuantity = Number(result);
                } else {
                    this.outStocks.Data.Items[index].OutStockQuantity = undefined;
                }

                //var ohterOutStockQuantity = 0; //其他同箱规则和毛重的出库货量
                //for (var i = 0; i < this.outStocks.Data.Items.length; i++) {
                //    if (i != index) {
                //        if (this.outStocks.Data.Items[i].Shape == this.outStocks.Data.Items[index].Shape && this.outStocks.Data.Items[i].GrossWeight == this.outStocks.Data.Items[index].GrossWeight) {
                //            if (this.outStocks.Data.Items[i].OutStockQuantity) {
                //                var out = this.outStocks.Data.Items[i].OutStockQuantity;
                //                if (out != undefined) {
                //                    ohterOutStockQuantity += out;
                //                }
                //            }
                //        }
                //    }
                //}

                //var allInStockQuantity = 0;
                //if (this.inStocks) {
                //    for (var inStock of this.inStocks) {
                //        if (inStock.Data) {
                //            if (inStock.Data.Length == this.outStocks.Data.Items[index].Length && inStock.Data.Width == this.outStocks.Data.Items[index].Width
                //                && inStock.Data.Height == this.outStocks.Data.Items[index].Height && inStock.Data.GrossWeight == this.outStocks.Data.Items[index].GrossWeight) {
                //                allInStockQuantity = inStock.Data.InStockQuantity;
                //            }
                //        }
                //    }
                //}

                //var quantity = allInStockQuantity - ohterOutStockQuantity;
                //if (this.outStocks.Data.Items[index].OutStockQuantity != undefined) {
                //    var outStockQuantity = this.outStocks.Data.Items[index].OutStockQuantity;
                //    if (outStockQuantity != undefined && outStockQuantity >= quantity) {
                //        this.outStocks.Data.Items[index].OutStockQuantity = quantity;
                //        if (outStockQuantity > quantity) {
                //            ElMessage.warning("该箱规的出库货量不能大于入库货量，已自动修正");
                //        }
                //        if (this.outStocks.Data.Items[index].OutStockQuantity == 0) {
                //            this.outStocks.Data.Items[index].OutStockQuantity = undefined;
                //        }
                //    }
                //}
            }
            this.onSum(false);
        }

        onSum(isInit: boolean | undefined): void {
            if (isInit != true) {
                var SumQuantityOutStock = undefined;
                var SumGrossWeightOutStock = undefined;
                var SumVolumeOutStock = undefined;
                if (this.outStocks.Data) {
                    SumQuantityOutStock = this.outStocks.Data.SumQuantityOutStock;
                    SumGrossWeightOutStock = this.outStocks.Data.SumGrossWeightOutStock;
                    SumVolumeOutStock = this.outStocks.Data.SumVolumeOutStock;
                }
                if (this.outStocks.Data && this.outStocks.Data.Items && this.outStocks.Data.Items.length > 0) {
                    this.outStocks.Data.SumQuantityOutStock = 0;
                    this.outStocks.Data.SumGrossWeightOutStock = 0;
                    this.outStocks.Data.SumVolumeOutStock = 0;
                    for (var it of this.outStocks.Data.Items) {
                        if (it.OutStockQuantity != undefined) {
                            this.outStocks.Data.SumQuantityOutStock += it.OutStockQuantity;

                            if (it.GrossWeight != undefined) {
                                this.outStocks.Data.SumGrossWeightOutStock += it.OutStockQuantity * it.GrossWeight;
                            }

                            if (it.Volume != undefined) {
                                this.outStocks.Data.SumVolumeOutStock += it.OutStockQuantity * it.Volume;
                            }
                        }
                    }

                    if (this.outStocks.Data.SumQuantityOutStock == 0) {
                        this.outStocks.Data.SumQuantityOutStock = undefined;
                    }
                    else {
                        var quantity = setDecimal(this.outStocks.Data.SumQuantityOutStock + "", 0, false);
                        if (quantity != "") {
                            this.outStocks.Data.SumQuantityOutStock = Number(quantity);
                        } else {
                            this.outStocks.Data.SumQuantityOutStock = undefined;
                        }
                    }
                    if (this.outStocks.Data.SumGrossWeightOutStock == 0) {
                        this.outStocks.Data.SumGrossWeightOutStock = undefined;
                    }
                    else {
                        var grossWeight = setDecimal(this.outStocks.Data.SumGrossWeightOutStock + "", 2, false);
                        if (grossWeight != "") {
                            this.outStocks.Data.SumGrossWeightOutStock = Number(grossWeight);
                        } else {
                            this.outStocks.Data.SumGrossWeightOutStock = undefined;
                        }
                    }

                    if (this.outStocks.Data.SumVolumeOutStock == 0) {
                        this.outStocks.Data.SumVolumeOutStock = undefined;
                    }
                    else {
                        var volume = setDecimal(this.outStocks.Data.SumVolumeOutStock + "", 4, false);
                        if (volume != "") {
                            this.outStocks.Data.SumVolumeOutStock = Number(volume);
                        } else {
                            this.outStocks.Data.SumVolumeOutStock = undefined;
                        }
                    }
                }
                else if (this.outStocks.Data) {
                    this.outStocks.Data.SumQuantityOutStock = SumQuantityOutStock;
                    this.outStocks.Data.SumGrossWeightOutStock = SumGrossWeightOutStock;
                    this.outStocks.Data.SumVolumeOutStock = SumVolumeOutStock;
                }
            }

        }


        onData(): void {
            if (this.outStocks && this.outStocks.Dto) {
                this.outStocks.Dto.CurrentPage = this.ruleForm.CurrentPage;
                this.outStocks.Dto.PageSize = this.ruleForm.PageSize;
                this.outStocks.Dto.OrderPoolId = this.orderPoolId;
                this.outStocks.Dto.OrderDepartureWharfId = this.orderDepartureWharfId;
                this.outStocks.Dto.IsDetail = this.isDetail;
            }
            OrderDepartureWharfAppService.GetOutStocks(this.outStocks.Dto).then((outStock) => {
                this.outStocks.Data = outStock.data.Data;

                this.outStocksInit = JSON.parse(JSON.stringify(this.outStocks));



                if (this.outStocks && this.outStocks.Data) {
                    if (this.outStocks.Data.IsShiftout == true && this.bottomButtons) {
                        this.bottomButtons[0].Hide = true;
                        this.bottomButtons[1].Hide = true;
                    }
                }

                var param = {
                    OrderDepartureWharfId: this.orderDepartureWharfId,
                    IsDetail: this.isDetail
                };
                OrderDepartureWharfAppService.GetInStocks(param).then((instock) => {
                    this.list.Data = instock.data.Data;
                    console.log(instock);
                    if (this.list.Data != undefined && this.list.Data.Items && this.list.Data.Items.length > 0) {
                        if (this.outStocks.Data && this.outStocks.Data.Items && this.outStocks.Data.Items.length <= 0) {
                            this.outStocks.Data.Items.push(new DtoListItemOrderDepartureWharfOutStock());
                        }
                    }
                    OrderDepartureWharfAppService.GetInStockAll(param).then((sum1) => {
                        this.inStocks = sum1.data.Data;
                        OrderDepartureWharfAppService.GetInStockSelect(param).then((sum) => {
                            this.selectInStocks = sum.data.Data;
                            if (this.outStocks.Data && this.outStocks.Data.Items && this.outStocks.Data.Items.length > 0) {
                                for (var i = 0; i < this.outStocks.Data.Items.length; i++) {
                                    this.onChangeShape(i, true);
                                }
                            }
                            this.isInit = false;
                            this.isLoading = false;
                            MiezzForm.clearCustomValidate(this.$refs, "ruleFormInStock", undefined);
                        });
                    });
                })
            })


        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.orderPoolId != null && this.orderPoolId != undefined && this.orderPoolId != "") {
                        this.stateRequestParam.PoolId.push(this.orderPoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                MiezzForm.customSubmit(this.$refs, "ruleFormInStock", (v) => {
                                    if (v == true) {

                                        if (this.outStocks.Data != undefined) {
                                            this.form.Data = new DtoFormOrderDepartureWharfOutStock();
                                            this.form.Data.OutStocks = [];
                                            this.form.Data.OrderPoolId = this.orderPoolId;
                                            this.form.Data.OrderDepartureWharfId = this.orderDepartureWharfId;
                                            this.form.Data.SumQuantityOutStock = this.outStocks.Data.SumQuantityOutStock;
                                            this.form.Data.SumGrossWeightOutStock = this.outStocks.Data.SumGrossWeightOutStock;
                                            this.form.Data.SumVolumeOutStock = this.outStocks.Data.SumVolumeOutStock;
                                            this.form.Data.ChargeUnitOutStock = this.outStocks.Data.ChargeUnitOutStock;
                                            this.form.Data.RemarkOutStock = this.outStocks.Data.RemarkOutStock;

                                            if (this.outStocks.Data.Items && this.outStocks.Data.Items.length > 0) {
                                                for (var it of this.outStocks.Data.Items) {
                                                    this.form.Data.OutStocks?.push({
                                                        Shape: it.Shape,
                                                        Length: it.Length,
                                                        Width: it.Width,
                                                        Height: it.Height,
                                                        GrossWeight: it.GrossWeight,
                                                        Volume: it.Volume,
                                                        OutStockQuantity: it.OutStockQuantity,
                                                        OutStockTime: it.OutStockTime,
                                                        OrderDepartureWharfId: this.orderDepartureWharfId,
                                                    });
                                                }
                                            }
                                            //else {

                                            //}

                                            if (this.outStocks.Data.SumQuantityOutStock != null && this.outStocks.Data.SumQuantityOutStock != undefined && this.outStocks.Data.SumQuantityOutStock > this.outStocks.Data.SumQuantityInStock
                                                || this.outStocks.Data.SumVolumeOutStock != null && this.outStocks.Data.SumVolumeOutStock != undefined && this.outStocks.Data.SumVolumeOutStock > this.outStocks.Data.SumVolumeInStock
                                                || this.outStocks.Data.SumGrossWeightOutStock != null && this.outStocks.Data.SumGrossWeightOutStock != undefined && this.outStocks.Data.SumGrossWeightOutStock > this.outStocks.Data.SumGrossWeightInStock
                                            ) {
                                                ElMessage.warning("出库货量不能大于入库货量，请重新填写");
                                                complete();
                                                return;
                                            }

                                            this.$emit("onSelectOutStock", this.form.Data);
                                            this.ruleForm.Visible = false;
                                        }

                                        complete();
                                    } else {
                                        complete();
                                    }
                                });

                            }
                        }
                    })
                    break;
                case "all":
                    if (this.outStocks.Data && this.outStocks.Data.Items) {
                        this.outStocks.Data.Items = [];
                    }
                    if (this.selectInStocks != undefined && this.selectInStocks.length > 0 && this.outStocks.Data && this.outStocks.Data.Items && this.inStocks) {
                        for (var stock of this.selectInStocks) {
                            if (stock.Data.GrossWeights && stock.Data.GrossWeights.length > 0) {
                                for (var grossWeight of stock.Data.GrossWeights) {

                                    var allInStockQuantity = 0;
                                    for (var inStock of this.inStocks) {
                                        if (inStock.Data) {
                                            if (inStock.Data.Length == stock.Data.Length && inStock.Data.Width == stock.Data.Width
                                                && inStock.Data.Height == stock.Data.Height && inStock.Data.GrossWeight == grossWeight) {
                                                allInStockQuantity = inStock.Data.InStockQuantity;

                                            }
                                        }
                                    }

                                    var flag = false;
                                    for (var outStock of this.outStocks.Data.Items) {
                                        if (stock.Data.Length == outStock.Length && stock.Data.Width == outStock.Width && stock.Data.Height == outStock.Height && grossWeight == outStock.GrossWeight) {
                                            outStock.OutStockQuantity = allInStockQuantity;
                                            flag = true;
                                        }
                                    }
                                    if (flag == false) {

                                        var inStocks = [];
                                        inStocks.push({
                                            Length: stock.Data.Length,
                                            Width: stock.Data.Width,
                                            Height: stock.Data.Height,
                                            GrossWeight: grossWeight,
                                            Quantity: allInStockQuantity,
                                            Shape: twoDecimal(stock.Data.Length + "") + "×" + twoDecimal(stock.Data.Width + "") + "×" + twoDecimal(stock.Data.Height + "") + "×" + twoDecimal(grossWeight + ""),
                                        });

                                        this.outStocks.Data.Items.push({
                                            Length: stock.Data.Length,
                                            Width: stock.Data.Width,
                                            Height: stock.Data.Height,
                                            Shape: stock.Data.Shape,
                                            GrossWeight: grossWeight,
                                            Volume: stock.Data.Volume,
                                            OutStockQuantity: allInStockQuantity,
                                            OutStockTime: new Date(moment().format("YYYY-MM-DD HH:mm")),
                                            InStocks: inStocks,

                                        });
                                    }
                                }

                            }

                        }
                    }
                    //else if(this.list && this.list.Data){

                    //    console.log(this.list);
                    //    console.log(this.outStocks);
                    //    if (this.outStocks != undefined && this.outStocks.Data != undefined) {
                    //        this.outStocks.Data.SumVolumeOutStock = this.list.Data.SumVolumeInStock;
                    //        this.outStocks.Data.SumQuantityOutStock = this.list.Data.SumQuantityInStock;
                    //        this.outStocks.Data.SumGrossWeightOutStock = this.list.Data.SumGrossWeightInStock;
                    //    }
                    //}
                    else {
                        if (this.outStocks != undefined && this.outStocks.Data != undefined) {
                            this.outStocks.Data.SumVolumeOutStock = this.outStocks.Data.SumVolumeInStock;
                            this.outStocks.Data.SumQuantityOutStock = this.outStocks.Data.SumQuantityInStock;
                            this.outStocks.Data.SumGrossWeightOutStock = this.outStocks.Data.SumGrossWeightInStock;
                        }
                    }
                    this.onSum(false);
                    complete();
                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete()
                    break;
                default:
                    break;
            }
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }
    }

    var twoDecimal = function (val: string) {
        if (val == null || val == undefined || val == "") {
            return "";
        }
        else {
            return Number(val).toFixed(2);
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };
