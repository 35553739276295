import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { DtoListItemOrderPoolFileScene, DtoListItemOrderPoolFileSceneOption } from "./DtoListItemOrderPoolFileScene";
import DtoListItemOrderPoolFile_File from "./DtoListItemOrderPoolFile_File";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolFile extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = DtoListItemOrderPoolFileScene.Pool;
        this.Files = [];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 所属子目录
     */
    Scene?: DtoListItemOrderPoolFileScene;
    /**
     * 链接
     */
    Link?: string;
    /**
     * 附件
     */
    Files?: DtoListItemOrderPoolFile_File[];
}

/**
 * -表单校验
 */
export const DtoListItemOrderPoolFileRules = {
}

/**
 * -设置项
 */
export const DtoListItemOrderPoolFileMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
    MaxWidth: "200px",
    Customize: true,
  },
  {
    Prop: "Scene",
    Label: "所属子目录",
    MaxWidth: "200px",
    Pipe: EnumPipe.Enum,
    Options: DtoListItemOrderPoolFileSceneOption,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
]
