import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamCurrencySystem from "../models.machine/DtoSelectSearchParamCurrencySystem";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamCurrencySystem from "../models.machine/DtoPageListSearchParamCurrencySystem";
import DtoPageListParamCurrencySystem from "../models.machine/DtoPageListParamCurrencySystem";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamCurrencySystem from "../models.machine/DtoDetailParamCurrencySystem";
import DtoFormParamCurrencySystem from "../models.machine/DtoFormParamCurrencySystem";
import DtoListItemParamCurrencySystem_Repeat from "../models.machine/DtoListItemParamCurrencySystem_Repeat";

/**
 * 币制
 * @description 自动生成的代码,请勿修改
 */
export default class ParamCurrencySystemAppService {
    /**
     * 获取币制选择器
     * @param {DtoSelectSearchParamCurrencySystem} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamCurrencySystem): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamCurrencySystem/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取分页列表
     * @param {DtoPageListSearchParamCurrencySystem} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamCurrencySystem>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamCurrencySystem): AxiosPromise<RESTfulResult<DtoPageListParamCurrencySystem>> {
        const url = `/api/ParamCurrencySystem/Get`;
        return service.get<RESTfulResult<DtoPageListParamCurrencySystem>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCurrencySystem/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取币制详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamCurrencySystem>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamCurrencySystem>> {
        const url = `/api/ParamCurrencySystem/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamCurrencySystem>>(url);
    }

    /**
     * 新增/编辑币制
     * @param {DtoFormParamCurrencySystem} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamCurrencySystem): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCurrencySystem/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除币制
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCurrencySystem/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 同步
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static SyncData(): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCurrencySystem/SyncData`;
        return service.post<RESTfulResult<any>>(url);
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCurrencySystem/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCurrencySystem/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamCurrencySystem/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获得汇率：比如要获得人民币兑换美元的汇率，to是人民币，from是美元
     * @param {string} [from] 被兑换的币制
     * @param {string} [to] 要兑换的币制
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExchangeRateByFromByTo(from?: string, to?: string): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamCurrencySystem/GetExchangeRate/${from}/${to}`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 获取数据，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<DtoListItemParamCurrencySystem_Repeat[]>>} 
     */
    static GetList(): AxiosPromise<RESTfulResult<DtoListItemParamCurrencySystem_Repeat[]>> {
        const url = `/api/ParamCurrencySystem/GetList`;
        return service.get<RESTfulResult<DtoListItemParamCurrencySystem_Repeat[]>>(url);
    }

    /**
     * 启用/禁用
     * @param {string} [id] 
     * @param {boolean} [disable] 
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static IsDisableByIdByDisable(id?: string, disable?: boolean): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamCurrencySystem/IsDisable/${id}/${disable}`;
        return service.post<RESTfulResult<any>>(url);
    }

}
