
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRequestTypescriptSync {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 应用
     */
    Apps?: string[];
}

/**
 * -表单校验
 */
export const DtoRequestTypescriptSyncRules = {
}
