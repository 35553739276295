import { RouteRecordRaw } from 'vue-router'

import CurrentCompanyDetail from './Company/CurrentCompany/CurrentCompanyDetail.vue'
import CurrentCompanyAuth from './Company/CurrentCompany/CurrentCompanyAuth.vue'
import CurrentCompanyEdit from './Company/CurrentCompany/CurrentCompanyEdit.vue'
import CurrentCompanyApplyMainRelationPageList from './Company/CurrentCompany/CurrentCompanyApplyMainRelationPageList.vue'
import CurrentCompanyDomesticShipper from './Company/CurrentCompany/CurrentCompanyDomesticShipper.vue'
import CurrentCompanyDomesticConsignee from './Company/CurrentCompany/CurrentCompanyDomesticConsignee.vue'
import CurrentCompanyDomesticNotifier from './Company/CurrentCompany/CurrentCompanyDomesticNotifier.vue'
import CurrentCompanyOverseasShipper from './Company/CurrentCompany/CurrentCompanyOverseasShipper.vue'
import CurrentCompanyOverseasConsignee from './Company/CurrentCompany/CurrentCompanyOverseasConsignee.vue'
import CurrentCompanyOverseasNotifier from './Company/CurrentCompany/CurrentCompanyOverseasNotifier.vue'
import CurrentCompanyProductionSaleUnit from './Company/CurrentCompany/CurrentCompanyProductionSaleUnit.vue'
import CurrentCompanyConsumptionUseUnit from './Company/CurrentCompany/CurrentCompanyConsumptionUseUnit.vue'
import CurrentCompanyBank from './Company/CurrentCompany/CurrentCompanyBank.vue'
import CurrentCompanyInvoicePageList from './Company/CurrentCompany/CurrentCompanyInvoicePageList.vue'
import CurrentCompanyCodeRuleDetail from './Company/CurrentCompany/CurrentCompanyCodeRuleDetail.vue'
import CurrentCompanyCodeRuleForm from './Company/CurrentCompany/CurrentCompanyCodeRuleForm.vue'
import CurrentCompanyOvertimeRuleDetail from './Company/CurrentCompany/CurrentCompanyOvertimeRuleDetail.vue'
import CurrentCompanyOvertimeRuleForm from './Company/CurrentCompany/CurrentCompanyOvertimeRuleForm.vue'
import CurrentCompanyBillOfLadingTemplateDetail from './Company/CurrentCompany/CurrentCompanyBillOfLadingTemplateDetail.vue'
import CurrentCompanyBillOfLadingTemplateForm from './Company/CurrentCompany/CurrentCompanyBillOfLadingTemplateForm.vue'
import CurrentCompanyChargePageList from './Company/CurrentCompany/CurrentCompanyChargePageList.vue'
import CurrentCompanyChargeForm from './Company/CurrentCompany/CurrentCompanyChargeForm.vue'

import PlatformCompanyPageList from './PlatformCompany/PlatformCompanyPageList.vue'

import ServiceProviderCompanyPageList from './Company/ServiceProviderCompany/ServiceProviderCompanyPageList.vue'
import ServiceProviderCompanyDetail from './Company/ServiceProviderCompany/ServiceProviderCompanyDetail.vue'
import ServiceProviderCompanyAdd from './Company/ServiceProviderCompany/ServiceProviderCompanyAdd.vue'
import ServiceProviderCompanyEdit from './Company/ServiceProviderCompany/ServiceProviderCompanyEdit.vue'
import ServiceProviderCompanyOpenSupplier from './Company/ServiceProviderCompany/ServiceProviderCompanyOpenSupplier.vue'
import ServiceProviderCompanyAuthPageList from './Company/ServiceProviderCompany/ServiceProviderCompanyAuthPageList.vue'
import ServiceProviderCompanyAuthAudit from './Company/ServiceProviderCompany/ServiceProviderCompanyAuthAudit.vue'

import SupplierCompanyPageList from './Company/SupplierCompany/Company/SupplierCompanyPageList.vue'
import SupplierCompanyDetail from './Company/SupplierCompany/Company/SupplierCompanyDetail.vue'
import SupplierCompanyAdd from './Company/SupplierCompany/Company/SupplierCompanyAdd.vue'
import SupplierCompanyEdit from './Company/SupplierCompany/Company/SupplierCompanyEdit.vue'
import SupplierCompanyContactPageList from './Company/SupplierCompany/Company/SupplierCompanyContactPageList.vue'
import SupplierCompanyContactForm from './Company/SupplierCompany/Company/SupplierCompanyContactForm.vue'
import SupplierCompanyContactDetail from './Company/SupplierCompany/Company/SupplierCompanyContactDetail.vue'
import SupplierCompanyInvoicePageList from './Company/SupplierCompany/Company/SupplierCompanyInvoicePageList.vue'

import SupplierPrivateCompanyPageList from './Company/SupplierCompany/Private/SupplierPrivateCompanyPageList.vue'
import SupplierPrivateCompanyDetail from './Company/SupplierCompany/Private/SupplierPrivateCompanyDetail.vue'
import SupplierPrivateCompanyChargePageList from './Company/CurrentCompany/CurrentCompanyChargePageList.vue'
import SupplierPrivateCompanyAdd from './Company/SupplierCompany/Private/SupplierPrivateCompanyAdd.vue'
import SupplierPrivateCompanyEdit from './Company/SupplierCompany/Private/SupplierPrivateCompanyEdit.vue'
import SupplierPrivateCompanyContactPageList from './Company/SupplierCompany/Private/SupplierPrivateCompanyContactPageList.vue'
import SupplierPrivateCompanyContactForm from './Company/SupplierCompany/Private/SupplierPrivateCompanyContactForm.vue'
import SupplierPrivateCompanyContactDetail from './Company/SupplierCompany/Private/SupplierPrivateCompanyContactDetail.vue'
import SupplierPrivateCompanyInvoicePageList from './Company/SupplierCompany/Private/SupplierPrivateCompanyInvoicePageList.vue'
import SupplierPrivateCompanyCreditAndAccountingPeriodDetail from './Company/SupplierCompany/Private/SupplierPrivateCompanyCreditAndAccountingPeriodDetail.vue'
import SupplierPrivateCompanyCreditAndAccountingPeriodForm from './Company/SupplierCompany/Private/SupplierPrivateCompanyCreditAndAccountingPeriodForm.vue'

import SupplierPublicCompanyPageList from './Company/SupplierCompany/Public/SupplierPublicCompanyPageList.vue'
import SupplierPublicCompanyDetail from './Company/SupplierCompany/Public/SupplierPublicCompanyDetail.vue'
import SupplierPublicCompanyChargePageList from './Company/CurrentCompany/CurrentCompanyChargePageList.vue'
import SupplierPublicCompanyContactPageList from './Company/SupplierCompany/Public/SupplierPublicCompanyContactPageList.vue'
import SupplierPublicCompanyContactDetail from './Company/SupplierCompany/Public/SupplierPublicCompanyContactDetail.vue'
import SupplierPublicCompanyInvoicePageList from './Company/SupplierCompany/Public/SupplierPublicCompanyInvoicePageList.vue'
import SupplierPublicCompanyCreditAndAccountingPeriodDetail from './Company/SupplierCompany/Public/SupplierPublicCompanyCreditAndAccountingPeriodDetail.vue'
import SupplierPublicCompanyCreditAndAccountingPeriodForm from './Company/SupplierCompany/Public/SupplierPublicCompanyCreditAndAccountingPeriodForm.vue'

import SupplierServiceProviderCompanyPageList from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyPageList.vue'
import SupplierServiceProviderCompanyDetail from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyDetail.vue'
import SupplierServiceProviderCompanyContactPageList from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyContactPageList.vue'
import SupplierServiceProviderCompanyContactForm from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyContactForm.vue'
import SupplierServiceProviderCompanyContactDetail from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyContactDetail.vue'
import SupplierServiceProviderCompanyInvoicePageList from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyInvoicePageList.vue'
import SupplierServiceProviderCompanyCreditAndAccountingPeriodDetail from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyCreditAndAccountingPeriodDetail.vue'
import SupplierServiceProviderCompanyCreditAndAccountingPeriodForm from './Company/SupplierCompany/ServiceProvider/SupplierServiceProviderCompanyCreditAndAccountingPeriodForm.vue'

import CustomerCompanyPageList from './Company/CustomerCompany/CustomerCompanyPageList.vue'
import CustomerCompanyDetail from './Company/CustomerCompany/CustomerCompanyDetail.vue'
import CustomerCompanyAdd from './Company/CustomerCompany/CustomerCompanyAdd.vue'
import CustomerCompanyEdit from './Company/CustomerCompany/CustomerCompanyEdit.vue'
import CustomerCompanyAuthPageList from './Company/CustomerCompany/CustomerCompanyAuthPageList.vue'
import CustomerCompanyAuthAudit from './Company/CustomerCompany/CustomerCompanyAuthAudit.vue'
import CustomerCompanyAuditMainRelationPageList from './Company/CustomerCompany/CustomerCompanyAuditMainRelationPageList.vue'
import CustomerCompanyRelationPageList from './Company/CustomerCompany/CustomerCompanyRelationPageList.vue'
import CustomerCompanyDomesticShipper from './Company/CustomerCompany/CustomerCompanyDomesticShipper.vue'
import CustomerCompanyDomesticConsignee from './Company/CustomerCompany/CustomerCompanyDomesticConsignee.vue'
import CustomerCompanyDomesticNotifier from './Company/CustomerCompany/CustomerCompanyDomesticNotifier.vue'
import CustomerCompanyOverseasShipper from './Company/CustomerCompany/CustomerCompanyOverseasShipper.vue'
import CustomerCompanyOverseasConsignee from './Company/CustomerCompany/CustomerCompanyOverseasConsignee.vue'
import CustomerCompanyOverseasNotifier from './Company/CustomerCompany/CustomerCompanyOverseasNotifier.vue'
import CustomerCompanyProductionSaleUnit from './Company/CustomerCompany/CustomerCompanyProductionSaleUnit.vue'
import CustomerCompanyConsumptionUseUnit from './Company/CustomerCompany/CustomerCompanyConsumptionUseUnit.vue'
import CustomerCompanyBank from './Company/CustomerCompany/CustomerCompanyBank.vue'
import CustomerCompanyInvoicePageList from './Company/CustomerCompany/CustomerCompanyInvoicePageList.vue'
import CustomerCompanyContactPageList from './Company/CustomerCompany/CustomerCompanyContactPageList.vue'
import CustomerCompanyContactForm from './Company/CustomerCompany/CustomerCompanyContactForm.vue'
import CustomerCompanyContactDetail from './Company/CustomerCompany/CustomerCompanyContactDetail.vue'

import CustomerCompanyCreditAndAccountingPeriodDetail from './Company/CustomerCompany/CustomerCompanyCreditAndAccountingPeriodDetail.vue'
import CustomerCompanyCreditAndAccountingPeriodForm from './Company/CustomerCompany/CustomerCompanyCreditAndAccountingPeriodForm.vue'

import CustomerServiceProviderCompanyPageList from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyPageList.vue'
import CustomerServiceProviderCompanyDetail from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyDetail.vue'
import CustomerServiceProviderCompanyContactPageList from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyContactPageList.vue'
import CustomerServiceProviderCompanyContactForm from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyContactForm.vue'
import CustomerServiceProviderCompanyContactDetail from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyContactDetail.vue'
import CustomerServiceProviderCompanyInvoicePageList from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyInvoicePageList.vue'

import CustomerServiceProviderCompanyCreditAndAccountingPeriodDetail from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyCreditAndAccountingPeriodDetail.vue'
import CustomerServiceProviderCompanyCreditAndAccountingPeriodForm from './Company/CustomerCompany/ServiceProvider/CustomerServiceProviderCompanyCreditAndAccountingPeriodForm.vue'

import CurrentUserPageList from './User/Current/CurrentUserPageList.vue'
import CurrentUserDetail from './User/Current/CurrentUserDetail.vue'
import CurrentUserAdd from './User/Current/CurrentUserAdd.vue'
import CurrentUserEdit from './User/Current/CurrentUserEdit.vue'

import ServiceProviderUserPageList from './User/ServiceProvider/ServiceProviderUserPageList.vue'
import ServiceProviderUserDetail from './User/ServiceProvider/ServiceProviderUserDetail.vue'
import ServiceProviderUserAdd from './User/ServiceProvider/ServiceProviderUserAdd.vue'
import ServiceProviderUserEdit from './User/ServiceProvider/ServiceProviderUserEdit.vue'

import CustomerUserPageList from './User/Customer/CustomerUserPageList.vue'
import CustomerUserDetail from './User/Customer/CustomerUserDetail.vue'
import CustomerUserAdd from './User/Customer/CustomerUserAdd.vue'
import CustomerUserEdit from './User/Customer/CustomerUserEdit.vue'

import GroupTree from './Group/GroupTree.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/org/company/detail',
        name: 'CompanyManagement_Company',
        component: CurrentCompanyDetail,
        meta: {
            title: "组织 - 公司详细"
        },
    },
    {
        path: '/org/company/edit/:id',
        name: 'CompanyManagement_Company_Edit',
        component: CurrentCompanyEdit,
        meta: {
            title: "组织 - 编辑公司"
        },
    },
    {
        path: '/org/company/auth',
        name: 'CompanyManagement_Company_Auth',
        component: CurrentCompanyAuth,
        meta: {
            title: "组织 - 企业认证"
        },
    },
    {
        path: '/org/company/main-relation',
        name: 'CompanyManagement_Company_MainRelation',
        component: CurrentCompanyApplyMainRelationPageList,
        meta: {
            title: "组织 - 主体关联"
        },
    },
    
    {
        path: '/org/company/domestic/shipper/:id',
        name: 'CompanyManagement_Company_DomesticShipper',
        component: CurrentCompanyDomesticShipper,
        meta: {
            title: "组织 - 境内发货人"
        },
    },
    {
        path: '/org/company/domestic/consignee/:id',
        name: 'CompanyManagement_Company_DomesticConsignee',
        component: CurrentCompanyDomesticConsignee,
        meta: {
            title: "组织 - 境内收货人"
        },
    },
    {
        path: '/org/company/domestic/notifier/:id/:parentId',
        name: 'CompanyManagement_Company_DomesticNotifier',
        component: CurrentCompanyDomesticNotifier,
        meta: {
            title: "组织 - 境内通知人"
        },
    },
    {
        path: '/org/company/overseas/shipper/:id',
        name: 'CompanyManagement_Company_OverseasShipper',
        component: CurrentCompanyOverseasShipper,
        meta: {
            title: "组织 - 境外发货人"
        },
    },
    {
        path: '/org/company/overseas/consignee/:id',
        name: 'CompanyManagement_Company_OverseasConsignee',
        component: CurrentCompanyOverseasConsignee,
        meta: {
            title: "组织 - 境外收货人"
        },
    },
    {
        path: '/org/company/overseas/notifier/:id/:parentId',
        name: 'CompanyManagement_Company_OverseasNotifier',
        component: CurrentCompanyOverseasNotifier,
        meta: {
            title: "组织 - 境外通知人"
        },
    },
    {
        path: '/org/company/product-sale-unit/:id',
        name: 'CompanyManagement_Company_ProductionSaleUnit',
        component: CurrentCompanyProductionSaleUnit,
        meta: {
            title: "组织 - 生产销售单位"
        },
    },
    {
        path: '/org/company/consumption-use-unit/:id',
        name: 'CompanyManagement_Company_ConsumptionUseUnit',
        component: CurrentCompanyConsumptionUseUnit,
        meta: {
            title: "组织 - 消费使用单位"
        },
    },

    {
        path: '/org/company/bank/:id',
        name: 'CompanyManagement_Company_Bank',
        component: CurrentCompanyBank,
        meta: {
            title: "组织 - 银行信息"
        },
    },
    {
        path: '/org/company/invoice/:id',
        name: 'CompanyManagement_Company_Invoice',
        component: CurrentCompanyInvoicePageList,
        meta: {
            title: "组织 - 开票资料"
        },
    },
    {
        path: '/org/company/code-rule/:id',
        name: 'CompanyManagement_Company_CodeRule',
        component: CurrentCompanyCodeRuleDetail,
        meta: {
            title: "组织 - 编号规则"
        },
    },
    {
        path: '/org/company/code-rule/edit/:id',
        name: 'CompanyManagement_Company_CodeRule_Edit',
        component: CurrentCompanyCodeRuleForm,
        meta: {
            title: "组织 - 编号规则 - 编辑"
        },
    },
    {
        path: '/org/company/overtime-rule/:id',
        name: 'CompanyManagement_Company_OvertimeRule',
        component: CurrentCompanyOvertimeRuleDetail,
        meta: {
            title: "组织 - 待办时效规则"
        },
    },
    {
        path: '/org/company/overtime-rule/edit/:id',
        name: 'CompanyManagement_Company_OvertimeRule_Edit',
        component: CurrentCompanyOvertimeRuleForm,
        meta: {
            title: "组织 - 待办时效规则 - 编辑"
        },
    },
    {
        path: '/org/company/charge/:id',
        name: 'CompanyManagement_Company_Charge',
        component: CurrentCompanyChargePageList,
        meta: {
            title: "组织 - 计费重量/体积规则"
        },
    },
    {
        path: '/org/company/charge/edit/:id',
        name: 'CompanyManagement_Company_Charge_Edit',
        component: CurrentCompanyChargeForm,
        meta: {
            title: "组织 - 计费重量/体积规则 - 编辑"
        },
    },
    {
        path: '/org/company/bill-of-lading-template/:id',
        name: 'CompanyManagement_Company_BillOfLadingTemplate',
        component: CurrentCompanyBillOfLadingTemplateDetail,
        meta: {
            title: "组织 - 提单模板"
        },
    },
    {
        path: '/org/company/bill-of-lading-template/edit/:id',
        name: 'CompanyManagement_Company_BillOfLadingTemplate_Edit',
        component: CurrentCompanyBillOfLadingTemplateForm,
        meta: {
            title: "组织 - 提单模板 - 编辑"
        },
    },
    {
        path: '/org/department',
        name: 'CompanyManagement_Department',
        component: GroupTree,
        meta: {
            title: "组织 - 部门"
        },
    },
    {
        path: '/org/user',
        name: 'CompanyManagement_User',
        component: CurrentUserPageList,
        meta: {
            title: "组织 - 用户列表"
        },
    },
    {
        path: '/org/user/detail/:id',
        name: 'CompanyManagement_User_Detail',
        component: CurrentUserDetail,
        meta: {
            title: "组织 - 用户详细"
        },
    },
    {
        path: '/org/user/add',
        name: 'CompanyManagement_User_Add',
        component: CurrentUserAdd,
        meta: {
            title: "组织 - 添加用户"
        },
    },
    {
        path: '/org/user/edit/:id',
        name: 'CompanyManagement_User_Edit',
        component: CurrentUserEdit,
        meta: {
            title: "组织 - 编辑用户"
        },
    },

    {
        path: '/platform/company',
        name: 'PlatformManagement_Company',
        component: PlatformCompanyPageList,
        meta: {
            title: "平台管理 - 公司列表"
        },
    },

    {
        path: '/service-provider/company',
        name: 'ServiceProviderManagement_Company',
        component: ServiceProviderCompanyPageList,
        meta: {
            title: "服务商管理 - 公司列表"
        },
    },
    {
        path: '/service-provider/company/detail/:id',
        name: 'ServiceProviderManagement_Company_Detail',
        component: ServiceProviderCompanyDetail,
        meta: {
            title: "服务商管理 - 公司详细"
        },
    },
    {
        path: '/service-provider/company/add',
        name: 'ServiceProviderManagement_Company_Add',
        component: ServiceProviderCompanyAdd,
        meta: {
            title: "服务商管理 - 添加公司"
        },
    },
    {
        path: '/service-provider/company/edit/:id',
        name: 'ServiceProviderManagement_Company_Edit',
        component: ServiceProviderCompanyEdit,
        meta: {
            title: "服务商管理 - 编辑公司"
        },
    },
    {
        path: '/service-provider/company/open-supplier/:id',
        name: 'ServiceProviderManagement_Company_OpenSupplier',
        component: ServiceProviderCompanyOpenSupplier,
        meta: {
            title: "服务商管理 - 开通供应商"
        },
    },
    
    {
        path: '/service-provider/user',
        name: 'ServiceProviderManagement_User',
        component: ServiceProviderUserPageList,
        meta: {
            title: "服务商管理 - 用户列表"
        },
    },
    {
        path: '/service-provider/user/detail/:id',
        name: 'ServiceProviderManagement_User_Detail',
        component: ServiceProviderUserDetail,
        meta: {
            title: "服务商管理 - 用户详细"
        },
    },
    {
        path: '/service-provider/user/add',
        name: 'ServiceProviderManagement_User_Add',
        component: ServiceProviderUserAdd,
        meta: {
            title: "服务商管理 - 添加用户"
        },
    },
    {
        path: '/service-provider/user/edit/:id',
        name: 'ServiceProviderManagement_User_Edit',
        component: ServiceProviderUserEdit,
        meta: {
            title: "服务商管理 - 编辑用户"
        },
    },
    {
        path: '/service-provider/company-auth',
        name: 'ServiceProviderManagement_CompanyAuth',
        component: ServiceProviderCompanyAuthPageList,
        meta: {
            title: "服务商管理 - 企业认证"
        },
    },
    {
        path: '/service-provider/company-auth/audit/:id',
        name: 'ServiceProviderManagement_CompanyAuth_Audit',
        component: ServiceProviderCompanyAuthAudit,
        meta: {
            title: "服务商管理 - 企业认证 - 审核"
        },
    },

    {
        path: '/supplier/company',
        name: 'SupplierManagement_Company',
        component: SupplierCompanyPageList,
        meta: {
            title: "供应商 - 公司列表"
        },
    },
    {
        path: '/supplier/company/detail/:id',
        name: 'SupplierManagement_Company_Detail',
        component: SupplierCompanyDetail,
        meta: {
            title: "供应商 - 公司详细"
        },
    },
    {
        path: '/supplier/company/add',
        name: 'SupplierManagement_Company_Add',
        component: SupplierCompanyAdd,
        meta: {
            title: "供应商 - 添加公司"
        },
    },
    {
        path: '/supplier/company/edit/:id',
        name: 'SupplierManagement_Company_Edit',
        component: SupplierCompanyEdit,
        meta: {
            title: "供应商 - 编辑公司"
        },
    },
    {
        path: '/supplier/company/contact/:id',
        name: 'SupplierManagement_Contact',
        component: SupplierCompanyContactPageList,
        meta: {
            title: "供应商 - 通讯录"
        },
    },
    {
        path: '/supplier/company/contact/detail/:id/:cid',
        name: 'SupplierManagement_Contact_Detail',
        component: SupplierCompanyContactDetail,
        meta: {
            title: "供应商 - 通讯录 - 详细"
        },
    },
    {
        path: '/supplier/company/contact/add/:id',
        name: 'SupplierManagement_Contact_Add',
        component: SupplierCompanyContactForm,
        meta: {
            title: "供应商 - 通讯录 - 添加"
        },
    },
    {
        path: '/supplier/company/contact/edit/:id/:cid',
        name: 'SupplierManagement_Contact_Edit',
        component: SupplierCompanyContactForm,
        meta: {
            title: "供应商 - 通讯录 - 编辑"
        },
    },
    {
        path: '/supplier/company/invoice/:id',
        name: 'SupplierManagement_Company_Invoice',
        component: SupplierCompanyInvoicePageList,
        meta: {
            title: "供应商 - 开票资料"
        },
    },

    {
        path: '/supplier/company/private',
        name: 'SupplierManagement_Private',
        component: SupplierPrivateCompanyPageList,
        meta: {
            title: "供应商 - 我的供应商 - 公司列表"
        },
    },

    {
        path: '/supplier/company/private/detail/:id',
        name: 'SupplierManagement_Private_Detail',
        component: SupplierPrivateCompanyDetail,
        meta: {
            title: "供应商 - 我的供应商 - 公司详细"
        },
    },

    {
        path: '/supplier/company/private/charge/:id',
        name: 'SupplierManagement_Private_Charge',
        component: SupplierPrivateCompanyChargePageList,
        meta: {
            title: "供应商 - 我的供应商 - 计费重量/体积规则"
        },
    },


    {
        path: '/supplier/company/private/add',
        name: 'SupplierManagement_Private_Add',
        component: SupplierPrivateCompanyAdd,
        meta: {
            title: "供应商 - 我的供应商 - 添加公司"
        },
    },
    {
        path: '/supplier/company/private/edit/:id',
        name: 'SupplierManagement_Private_Edit',
        component: SupplierPrivateCompanyEdit,
        meta: {
            title: "供应商 - 我的供应商 - 编辑公司"
        },
    },
    {
        path: '/supplier/company/private/contact/:id',
        name: 'SupplierManagement_Private_Contact',
        component: SupplierPrivateCompanyContactPageList,
        meta: {
            title: "供应商 - 我的供应商 - 通讯录"
        },
    },
    {
        path: '/supplier/company/private/contact/detail/:id/:cid',
        name: 'SupplierManagement_Private_Contact_Detail',
        component: SupplierPrivateCompanyContactDetail,
        meta: {
            title: "供应商 - 我的供应商 - 通讯录 - 详细"
        },
    },
    {
        path: '/supplier/company/private/contact/add/:id',
        name: 'SupplierManagement_Private_Contact_Add',
        component: SupplierPrivateCompanyContactForm,
        meta: {
            title: "供应商 - 我的供应商 - 通讯录 - 添加"
        },
    },
    {
        path: '/supplier/company/private/contact/edit/:id/:cid',
        name: 'SupplierManagement_Private_Contact_Edit',
        component: SupplierPrivateCompanyContactForm,
        meta: {
            title: "供应商 - 我的供应商 - 通讯录 - 编辑"
        },
    },
    {
        path: '/supplier/company/private/invoice/:id',
        name: 'SupplierManagement_Private_Company_Invoice',
        component: SupplierPrivateCompanyInvoicePageList,
        meta: {
            title: "供应商 - 我的供应商 - 开票资料"
        },
    },
    {
        path: '/supplier/company/private/credit-and-accounting-period/:id',
        name: 'SupplierManagement_Private_CreditAndAccountingPeriod',
        component: SupplierPrivateCompanyCreditAndAccountingPeriodDetail,
        meta: {
            title: "供应商 - 我的供应商 - 账期与授信"
        },
    },
    {
        path: '/supplier/company/private/credit-and-accounting-period/edit/:id',
        name: 'SupplierManagement_Private_CreditAndAccountingPeriod_Edit',
        component: SupplierPrivateCompanyCreditAndAccountingPeriodForm,
        meta: {
            title: "供应商 - 我的供应商 - 账期与授信 - 编辑"
        },
    },
    {
        path: '/supplier/company/public',
        name: 'SupplierManagement_Public',
        component: SupplierPublicCompanyPageList,
        meta: {
            title: "供应商 - 公共供应商 - 公司列表"
        },
    },
    {
        path: '/supplier/company/public/detail/:id',
        name: 'SupplierManagement_Public_Detail',
        component: SupplierPublicCompanyDetail,
        meta: {
            title: "供应商 - 公共供应商 - 公司详细"
        },
    },
    {
        path: '/supplier/company/public/contact/:id',
        name: 'SupplierManagement_Public_Contact',
        component: SupplierPublicCompanyContactPageList,
        meta: {
            title: "供应商 - 公共供应商 - 通讯录"
        },
    },
    {
        path: '/supplier/company/public/contact/detail/:id/:cid',
        name: 'SupplierManagement_Public_Contact_Detail',
        component: SupplierPublicCompanyContactDetail,
        meta: {
            title: "供应商 - 公共供应商 - 通讯录 - 详细"
        },
    },
    {
        path: '/supplier/company/public/invoice/:id',
        name: 'SupplierManagement_Public_Company_Invoice',
        component: SupplierPublicCompanyInvoicePageList,
        meta: {
            title: "供应商 - 公共供应商 - 开票资料"
        },
    },
    {
        path: '/supplier/company/public/credit-and-accounting-period/:id',
        name: 'SupplierManagement_Public_CreditAndAccountingPeriod',
        component: SupplierPublicCompanyCreditAndAccountingPeriodDetail,
        meta: {
            title: "供应商 - 公共供应商 - 账期与授信"
        },
    },
    {
        path: '/supplier/company/public/credit-and-accounting-period/edit/:id',
        name: 'SupplierManagement_Public_CreditAndAccountingPeriod_Edit',
        component: SupplierPublicCompanyCreditAndAccountingPeriodForm,
        meta: {
            title: "供应商 - 公共供应商 - 账期与授信 - 编辑"
        },
    },

    {
        path: '/supplier/company/public/charge/:id',
        name: 'SupplierManagement_Public_Charge',
        component: SupplierPublicCompanyChargePageList,
        meta: {
            title: "供应商 - 公共供应商 - 计费重量/体积规则"
        },
    },

    {
        path: '/supplier/company/service-provider',
        name: 'SupplierManagement_ServiceProvider',
        component: SupplierServiceProviderCompanyPageList,
        meta: {
            title: "供应商 - 服务商 - 公司列表"
        },
    },
    {
        path: '/supplier/company/service-provider/detail/:id',
        name: 'SupplierManagement_ServiceProvider_Detail',
        component: SupplierServiceProviderCompanyDetail,
        meta: {
            title: "供应商 - 服务商 - 公司详细"
        },
    },
    {
        path: '/supplier/company/service-provider/contact/:id',
        name: 'SupplierManagement_ServiceProvider_Contact',
        component: SupplierServiceProviderCompanyContactPageList,
        meta: {
            title: "供应商 - 服务商 - 通讯录"
        },
    },
    {
        path: '/supplier/company/service-provider/contact/detail/:id/:cid',
        name: 'SupplierManagement_ServiceProvider_Contact_Detail',
        component: SupplierServiceProviderCompanyContactDetail,
        meta: {
            title: "供应商 - 服务商 - 通讯录 - 详细"
        },
    },
    {
        path: '/supplier/company/service-provider/contact/add/:id',
        name: 'SupplierManagement_ServiceProvider_Contact_Add',
        component: SupplierServiceProviderCompanyContactForm,
        meta: {
            title: "供应商 - 服务商 - 通讯录 - 添加"
        },
    },
    {
        path: '/supplier/company/service-provider/contact/edit/:id/:cid',
        name: 'SupplierManagement_ServiceProvider_Contact_Edit',
        component: SupplierServiceProviderCompanyContactForm,
        meta: {
            title: "供应商 - 服务商 - 通讯录 - 编辑"
        },
    },
    {
        path: '/supplier/company/service-provider/invoice/:id',
        name: 'SupplierManagement_ServiceProvider_Company_Invoice',
        component: SupplierServiceProviderCompanyInvoicePageList,
        meta: {
            title: "供应商 - 服务商 - 开票资料"
        },
    },
    {
        path: '/supplier/company/service-provider/credit-and-accounting-period/:id',
        name: 'SupplierManagement_ServiceProvider_CreditAndAccountingPeriod',
        component: SupplierServiceProviderCompanyCreditAndAccountingPeriodDetail,
        meta: {
            title: "供应商 - 服务商 - 账期与授信"
        },
    },
    {
        path: '/supplier/company/service-provider/credit-and-accounting-period/edit/:id',
        name: 'SupplierManagement_ServiceProvider_CreditAndAccountingPeriod_Edit',
        component: SupplierServiceProviderCompanyCreditAndAccountingPeriodForm,
        meta: {
            title: "供应商 - 服务商 - 账期与授信 - 编辑"
        },
    },

    {
        path: '/customer/company',
        name: 'CustomerManagement_Company',
        component: CustomerCompanyPageList,
        meta: {
            title: "客户管理 - 我的客户 - 公司列表"
        },
    },
    {
        path: '/customer/company/detail/:id',
        name: 'CustomerManagement_Company_Detail',
        component: CustomerCompanyDetail,
        meta: {
            title: "客户管理 - 我的客户 - 公司详细"
        },
    },
    {
        path: '/customer/company/add',
        name: 'CustomerManagement_Company_Add',
        component: CustomerCompanyAdd,
        meta: {
            title: "客户管理 - 我的客户 - 添加公司"
        },
    },
    {
        path: '/customer/company/edit/:id',
        name: 'CustomerManagement_Company_Edit',
        component: CustomerCompanyEdit,
        meta: {
            title: "客户管理 - 我的客户 - 编辑公司"
        },
    },
    {
        path: '/customer/company-auth',
        name: 'CustomerManagement_CompanyAuth',
        component: CustomerCompanyAuthPageList,
        meta: {
            title: "客户管理 - 企业认证"
        },
    },
    {
        path: '/customer/company-auth/audit/:id',
        name: 'CustomerManagement_AuditAuth',
        component: CustomerCompanyAuthAudit,
        meta: {
            title: "客户管理 - 企业认证 - 审核"
        },
    },
    {
        path: '/customer/main-relation',
        name: 'CustomerManagement_MainRelation',
        component: CustomerCompanyAuditMainRelationPageList,
        meta: {
            title: "客户管理 - 主体关联"
        },
    },
    {
        path: '/customer/relation',
        name: 'CustomerManagement_RelationManagement',
        component: CustomerCompanyRelationPageList,
        meta: {
            title: "客户管理 - 关联管理"
        },
    },
    {
        path: '/customer/company/contact/:id',
        name: 'CustomerManagement_Contact',
        component: CustomerCompanyContactPageList,
        meta: {
            title: "客户管理 - 我的客户 - 通讯录"
        },
    },
    {
        path: '/customer/company/contact/detail/:id/:cid',
        name: 'CustomerManagement_Contact_Detail',
        component: CustomerCompanyContactDetail,
        meta: {
            title: "客户管理 - 我的客户 - 通讯录 - 详细"
        },
    },
    {
        path: '/customer/company/contact/add/:id',
        name: 'CustomerManagement_Contact_Add',
        component: CustomerCompanyContactForm,
        meta: {
            title: "客户管理 - 我的客户 - 通讯录 - 添加"
        },
    },
    {
        path: '/customer/company/contact/edit/:id/:cid',
        name: 'CustomerManagement_Contact_Edit',
        component: CustomerCompanyContactForm,
        meta: {
            title: "客户管理 - 我的客户 - 通讯录 - 编辑"
        },
    },

    {
        path: '/customer/company/invoice/:id',
        name: 'CustomerManagement_Company_Invoice',
        component: CustomerCompanyInvoicePageList,
        meta: {
            title: "客户管理 - 我的客户 - 开票资料"
        },
    },

    {
        path: '/customer/company/domestic/shipper/:id',
        name: 'CustomerManagement_Company_DomesticShipper',
        component: CustomerCompanyDomesticShipper,
        meta: {
            title: "客户管理 - 我的客户 - 境内发货人"
        },
    },
    {
        path: '/customer/company/domestic/consignee/:id',
        name: 'CustomerManagement_Company_DomesticConsignee',
        component: CustomerCompanyDomesticConsignee,
        meta: {
            title: "客户管理 - 我的客户 - 境内收货人"
        },
    },
    {
        path: '/customer/company/domestic/notifier/:id/:parentId',
        name: 'CustomerManagement_Company_DomesticNotifier',
        component: CustomerCompanyDomesticNotifier,
        meta: {
            title: "客户管理 - 我的客户 - 境内通知人"
        },
    },
    {
        path: '/customer/company/overseas/shipper/:id',
        name: 'CustomerManagement_Company_OverseasShipper',
        component: CustomerCompanyOverseasShipper,
        meta: {
            title: "客户管理 - 我的客户 - 境外发货人"
        },
    },
    {
        path: '/customer/company/overseas/consignee/:id',
        name: 'CustomerManagement_Company_OverseasConsignee',
        component: CustomerCompanyOverseasConsignee,
        meta: {
            title: "客户管理 - 我的客户 - 境外收货人"
        },
    },
    {
        path: '/customer/company/overseas/notifier/:id/:parentId',
        name: 'CustomerManagement_Company_OverseasNotifier',
        component: CustomerCompanyOverseasNotifier,
        meta: {
            title: "客户管理 - 我的客户 - 境外通知人"
        },
    },
    {
        path: '/customer/company/product-sale-unit/:id',
        name: 'CustomerManagement_Company_ProductionSaleUnit',
        component: CustomerCompanyProductionSaleUnit,
        meta: {
            title: "客户管理 - 我的客户 - 生产销售单位"
        },
    },
    {
        path: '/customer/company/consumption-use-unit/:id',
        name: 'CustomerManagement_Company_ConsumptionUseUnit',
        component: CustomerCompanyConsumptionUseUnit,
        meta: {
            title: "客户管理 - 我的客户 - 消费使用单位"
        },
    },

    {
        path: '/customer/company/bank/:id',
        name: 'CustomerManagement_Company_Bank',
        component: CustomerCompanyBank,
        meta: {
            title: "客户管理 - 我的客户 - 银行信息"
        },
    },
    {
        path: '/customer/company/credit-and-accounting-period/:id',
        name: 'CustomerManagement_Company_CreditAndAccountingPeriod',
        component: CustomerCompanyCreditAndAccountingPeriodDetail,
        meta: {
            title: "客户管理 - 授信与账期"
        },
    },
    {
        path: '/customer/company/credit-and-accounting-period/edit/:id',
        name: 'CustomerManagement_Company_CreditAndAccountingPeriod_Edit',
        component: CustomerCompanyCreditAndAccountingPeriodForm,
        meta: {
            title: "客户管理 - 授信与账期 - 编辑"
        },
    },

    {
        path: '/customer/company/service-provider',
        name: 'CustomerManagement_ServiceProvider',
        component: CustomerServiceProviderCompanyPageList,
        meta: {
            title: "客户管理 - 服务商客户 - 公司列表"
        },
    },
    {
        path: '/customer/company/service-provider/detail/:id',
        name: 'CustomerManagement_ServiceProvider_Detail',
        component: CustomerServiceProviderCompanyDetail,
        meta: {
            title: "客户管理 - 服务商客户 - 公司详细"
        },
    },
    {
        path: '/customer/company/service-provider/contact/:id',
        name: 'CustomerManagement_ServiceProvider_Contact',
        component: CustomerServiceProviderCompanyContactPageList,
        meta: {
            title: "客户管理 - 服务商客户 - 通讯录"
        },
    },
    {
        path: '/customer/company/service-provider/contact/detail/:id/:cid',
        name: 'CustomerManagement_ServiceProvider_Contact_Detail',
        component: CustomerServiceProviderCompanyContactDetail,
        meta: {
            title: "客户管理 - 服务商客户 - 通讯录 - 详细"
        },
    },
    {
        path: '/customer/company/service-provider/contact/add/:id',
        name: 'CustomerManagement_ServiceProvider_Contact_Add',
        component: CustomerServiceProviderCompanyContactForm,
        meta: {
            title: "客户管理 - 服务商客户 - 通讯录 - 添加"
        },
    },
    {
        path: '/customer/company/service-provider/contact/edit/:id/:cid',
        name: 'CustomerManagement_ServiceProvider_Contact_Edit',
        component: CustomerServiceProviderCompanyContactForm,
        meta: {
            title: "客户管理 - 服务商客户 - 通讯录 - 编辑"
        },
    },
    {
        path: '/customer/company/service-provider/invoice/:id',
        name: 'CustomerManagement_ServiceProvider_Company_Invoice',
        component: CustomerServiceProviderCompanyInvoicePageList,
        meta: {
            title: "客户管理 - 服务商客户 - 开票资料"
        },
    },
    {
        path: '/customer/company/service-provider/credit-and-accounting-period/:id',
        name: 'CustomerManagement_ServiceProvider_Company_CreditAndAccountingPeriod',
        component: CustomerServiceProviderCompanyCreditAndAccountingPeriodDetail,
        meta: {
            title: "客户管理 - 服务商客户 - 授信与账期"
        },
    },
    {
        path: '/customer/company/service-provider/credit-and-accounting-period/edit/:id',
        name: 'CustomerManagement_ServiceProvider_Company_CreditAndAccountingPeriod_Edit',
        component: CustomerServiceProviderCompanyCreditAndAccountingPeriodForm,
        meta: {
            title: "客户管理 - 服务商客户 - 授信与账期 - 编辑"
        },
    },
    {
        path: '/customer/user',
        name: 'CustomerManagement_User',
        component: CustomerUserPageList,
        meta: {
            title: "客户管理 - 用户列表"
        },
    },
    {
        path: '/customer/user/detail/:id',
        name: 'CustomerManagement_User_Detail',
        component: CustomerUserDetail,
        meta: {
            title: "客户管理 - 用户详细"
        },
    },
    {
        path: '/customer/user/add',
        name: 'CustomerManagement_User_Add',
        component: CustomerUserAdd,
        meta: {
            title: "客户管理 - 添加用户"
        },
    },
    {
        path: '/customer/user/edit/:id',
        name: 'CustomerManagement_User_Edit',
        component: CustomerUserEdit,
        meta: {
            title: "客户管理 - 编辑用户"
        },
    },
];

export default routes;