import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"100px"}
}
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_product_spec_element_detail = _resolveComponent("product-spec-element-detail")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_product_spec_element_form = _resolveComponent("product-spec-element-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_alert, {
      title: _ctx.tip,
      type: "warning",
      "show-icon": "",
      closable: false,
      style: {"display":"none"}
    }, null, 8, ["title"]),
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.list) = $event)),
      onHandleRowClick: _ctx.handleRowClick,
      onHandleGet: _ctx.handleGet,
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleBottomButtonClick: _ctx.handleBottomButtonClick
    }, {
      column: _withCtx(({ model, row }) => [
        (model.Prop == 'IsSelected')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_el_checkbox, {
                onChange: ($event: any) => (_ctx.handleRowClick(row)),
                modelValue: row.IsSelected,
                "onUpdate:modelValue": ($event: any) => ((row.IsSelected) = $event),
                size: "large"
              }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
            ]))
          : _createCommentVNode("", true),
        (model.Prop == 'ProductSpecElementItems')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              title: row.ProductSpecElementItemString,
              style: {"width":"100%","height":"26px","overflow":"hidden"}
            }, _toDisplayString(row.ProductSpecElementItemString), 9, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      "column-header": _withCtx(({ model }) => [
        (model.Prop == 'IsSelected')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_checkbox, {
                onChange: _ctx.onAll,
                modelValue: _ctx.isAll,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAll) = $event)),
                size: "large"
              }, null, 8, ["onChange", "modelValue"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleRowClick", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDetail,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalDetail) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_product_spec_element_detail, {
          modelValue: _ctx.modalDetail,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDetail) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit,
          onHandleDelete: _ctx.handleDelete
        }, null, 8, ["modelValue", "id", "onHandleSubmit", "onHandleDelete"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_product_spec_element_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}