import UiSelectOption from "./UiSelectOption";

/**
 * 周
 * @description 自动生成的代码,请勿修改
 */
export enum EnumWeek {
    /**
     * Mon
     */
    Mon = 1,
    /**
     * Tue
     */
    Tue = 2,
    /**
     * Wed
     */
    Wed = 3,
    /**
     * Thur
     */
    Thur = 4,
    /**
     * Fri
     */
    Fri = 5,
    /**
     * Sat
     */
    Sat = 6,
    /**
     * Sun
     */
    Sun = 7,
}

/**
 * 周-选项
 */
export const EnumWeekOption: UiSelectOption<number>[] = [
    { Label: "Mon", Value: 1, Data: "Mon" },
    { Label: "Tue", Value: 2, Data: "Tue" },
    { Label: "Wed", Value: 3, Data: "Wed" },
    { Label: "Thur", Value: 4, Data: "Thur" },
    { Label: "Fri", Value: 5, Data: "Fri" },
    { Label: "Sat", Value: 6, Data: "Sat" },
    { Label: "Sun", Value: 7, Data: "Sun" },
];
