
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import MiezzButton from "@/models/MiezzButton";

export default class PriceSetUp extends Vue {
  @Model() modelValue?: MiezzMenuTab;
  menuTab = new MiezzMenuTab();

  created(): void {
    this.menuTab = this.modelValue ?? new MiezzMenuTab();
    this.menuTab.Url = "/price/setup";
    this.menuTab.Menus = [
      {
        Label: "基础价格表",
        Url: "/price/setup/table",
        Power: "PriceManagement_SetUp_Table",
      },
      {
        Label: "服务",
        Url: "/price/setup/service",
        Power: "PriceManagement_SetUp_Service",
      },
    ];
  }

  /**按钮点击 */
  @Emit()
  handleMenuTabClick(btn: MiezzButton, complete: () => void): void {
    //
  }
}
