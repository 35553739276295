import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumParamPriceFieldType, EnumParamPriceFieldTypeOption } from "./EnumParamPriceFieldType";
import DtoFormParamPriceFieldChildren from "./DtoFormParamPriceFieldChildren";
import UiGroup from "./UiGroup";

/**
 * 价格要素表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamPriceField extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Type = EnumParamPriceFieldType.Id;
        this.Children = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumParamPriceFieldType;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 代码
     */
    Code?: string;
    /**
     * 数据源
     */
    DataSource?: string;
    /**
     * 列表列宽
     */
    Width?: string;
    /**
     * Excel列宽
     */
    ExcelWidth?: number;
    /**
     * 备注
     */
    Remark?: string;
    /**
     * 子要素
     */
    Children?: DtoFormParamPriceFieldChildren[];
}

/**
 * 价格要素表单-表单校验
 */
export const DtoFormParamPriceFieldRules = {
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    DataSource: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Width: [
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 价格要素表单-设置项
 */
export const DtoFormParamPriceFieldMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumParamPriceFieldTypeOption,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "代码",
    Customize: true,
  },
  {
    Prop: "DataSource",
    Label: "数据源",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Width",
    Label: "列表列宽",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ExcelWidth",
    Label: "Excel列宽",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Children",
    Label: "子要素",
    Customize: true,
  },
]
