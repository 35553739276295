import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumMenuScene, EnumMenuSceneOption } from "./EnumMenuScene";
import DtoFormPower_JoinPowerMenu from "./DtoFormPower_JoinPowerMenu";
import DtoFormPower_JoinPowerMenuField from "./DtoFormPower_JoinPowerMenuField";
import UiGroup from "./UiGroup";

/**
 * 权限表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPower extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Scene = EnumMenuScene.Platform;
        this.IsTest = false;
        this.Right = false;
        this.Sort = 0;
        this.JoinPowerMenus = [];
        this.JoinPowerMenuFields = [];
        this.MenuIds = [];
        this.MenuFieldIds = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 父Id
     */
    ParentId?: string;
    /**
     * 路径
     */
    Path?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 场景
     */
    Scene?: EnumMenuScene;
    /**
     * 测试
     */
    IsTest?: boolean;
    /**
     * 右侧
     */
    Right?: boolean;
    /**
     * 标记色
     */
    MarkerColor?: string;
    /**
     * 排序
     */
    Sort?: number;
    /**
     * 关联-权限/菜单
     */
    JoinPowerMenus?: DtoFormPower_JoinPowerMenu[];
    /**
     * 关联-权限/菜单字段
     */
    JoinPowerMenuFields?: DtoFormPower_JoinPowerMenuField[];
    /**
     * 菜单Id
     */
    MenuIds?: string[];
    /**
     * 菜单字段Id
     */
    MenuFieldIds?: string[];
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 权限表单-表单校验
 */
export const DtoFormPowerRules = {
    Path: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Scene: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    MarkerColor: [
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 权限表单-设置项
 */
export const DtoFormPowerMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Path",
    Label: "路径",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Scene",
    Label: "场景",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumMenuSceneOption,
  },
  {
    Prop: "IsTest",
    Label: "测试",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Right",
    Label: "右侧",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "MarkerColor",
    Label: "标记色",
    Type: EnumMiezzFormItemType.ColorPicker,
  },
  {
    Prop: "Sort",
    Label: "排序",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "JoinPowerMenus",
    Label: "关联-权限/菜单",
    Customize: true,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
