import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoSelectSearchParamTaxType from "../models.machine/DtoSelectSearchParamTaxType";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchParamTaxType from "../models.machine/DtoPageListSearchParamTaxType";
import DtoPageListParamTaxType from "../models.machine/DtoPageListParamTaxType";
import UiDrag from "../models.machine/UiDrag";
import DtoDetailParamTaxType from "../models.machine/DtoDetailParamTaxType";
import DtoFormParamTaxType from "../models.machine/DtoFormParamTaxType";

/**
 * 纳税类型
 * @description 自动生成的代码,请勿修改
 */
export default class ParamTaxTypeAppService {
    /**
     * 获取纳税类型选择器
     * @param {DtoSelectSearchParamTaxType} [search] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 选择器
     */
    static GetSelect(search?: DtoSelectSearchParamTaxType): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ParamTaxType/GetSelect`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url, { params: search });
    }

    /**
     * 获取纳税类型分页列表
     * @param {DtoPageListSearchParamTaxType} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListParamTaxType>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchParamTaxType): AxiosPromise<RESTfulResult<DtoPageListParamTaxType>> {
        const url = `/api/ParamTaxType/Get`;
        return service.get<RESTfulResult<DtoPageListParamTaxType>>(url, { params: dto });
    }

    /**
     * 拖曳排序
     * @param {UiDrag<string>} [dto] 传参
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DragSort(dto?: UiDrag<string>): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTaxType/DragSort`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 获取纳税类型详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailParamTaxType>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailParamTaxType>> {
        const url = `/api/ParamTaxType/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailParamTaxType>>(url);
    }

    /**
     * 新增/编辑纳税类型
     * @param {DtoFormParamTaxType} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Post(dto?: DtoFormParamTaxType): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTaxType/Post`;
        return service.post<RESTfulResult<any>>(url, dto);
    }

    /**
     * 批量删除纳税类型
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static Delete(ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/ParamTaxType/Delete`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

    /**
     * 导出
     * @param {string} [name] 
     * @param {string[]} [ids] 
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static PostExportExceByName(name?: string, ids?: string[]): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTaxType/PostExportExce/${name}`;
        return service.post<RESTfulResult<string>>(url, ids);
    }

    /**
     * 导出导入模板
     * @returns {AxiosPromise<RESTfulResult<string>>} 
     */
    static GetExportImportTemplate(): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/ParamTaxType/GetExportImportTemplate`;
        return service.get<RESTfulResult<string>>(url);
    }

    /**
     * 导入
     * @param {any} [file] 
     * @returns {AxiosPromise<RESTfulResult<boolean>>} 
     */
    static PostImport(file?: any): AxiosPromise<RESTfulResult<boolean>> {
        const url = `/api/ParamTaxType/PostImport`;
        return service.post<RESTfulResult<boolean>>(url, file);
    }

    /**
     * 获取NameZh数组，用于重复判断
     * @returns {AxiosPromise<RESTfulResult<string[]>>} 
     */
    static GetNameZhList(): AxiosPromise<RESTfulResult<string[]>> {
        const url = `/api/ParamTaxType/GetNameZhList`;
        return service.get<RESTfulResult<string[]>>(url);
    }

}
