import { RouteRecordRaw } from 'vue-router'

import Order from "./Order/Order"
import Pool from "./Pool/Pool"
import StowageStack from "./StowageStack/StowageStack"

import OrderDeparturePageList from "./Customs/OrderDeparturePageList.vue"
import OrderDepartureCustomsForm from "./Customs/OrderDepartureCustomsForm.vue"
import OrderDepartureCustomsDetail from "./Customs/OrderDepartureCustomsDetail.vue"
import OrderDestinationPageList from "./Customs/OrderDeparturePageList.vue"
import OrderDestinationCustomsForm from "./Customs/OrderDepartureCustomsForm.vue"
import OrderDestinationCustomsDetail from "./Customs/OrderDepartureCustomsDetail.vue"
import OrderDepartureWharfPageList from "./OrderWharf/OrderDepartureWharfPageList.vue"
import OrderDestinationWharfPageList from "./OrderWharf/OrderDepartureWharfPageList.vue"
import OrderInboundOutboundPageList from "./InboundOutbound/OrderInboundOutboundPageList.vue"

const routes: Array<RouteRecordRaw> = [
    ...Pool,
    ...StowageStack,
    ...Order,

    {
        path: '/pool/departure/customs/:id',
        name: 'OrderPoolDeparturePageList',
        component: OrderDeparturePageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-关务"
        },
    },
    {
        path: '/pool/departure/customs/update/:id',
        name: 'OrderPoolDepartureForm',
        component: OrderDepartureCustomsForm,
        meta: {
            title: "订单管理 - 委托书 - 装货港-关务"
        },
    },
    {
        path: '/pool/departure/customs/detail/:id',
        name: 'OrderPoolDepartureDetail',
        component: OrderDepartureCustomsDetail,
        meta: {
            title: "订单管理 - 委托书 - 装货港-关务"
        },
    },

    {
        path: '/pool/destination/customs/:id',
        name: 'OrderPoolDestinationPageList',
        component: OrderDestinationPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-清关"
        },
    },
    {
        path: '/pool/destination/customs/update/:id',
        name: 'OrderPoolDestinationForm',
        component: OrderDestinationCustomsForm,
        meta: {
            title: "订单管理 - 委托书 - 目的港-清关"
        },
    },
    {
        path: '/pool/destination/customs/detail/:id',
        name: 'OrderPoolDestinationDetail',
        component: OrderDestinationCustomsDetail,
        meta: {
            title: "订单管理 - 委托书 - 目的港-清关"
        },
    },

    {
        path: '/order/departure/customs/:id',
        name: 'OrderDeparturePageList',
        component: OrderDeparturePageList,
        meta: {
            title: "订单管理 - 装货港-关务"
        },
    },
    {
        path: '/order/departure/customs/update/:id',
        name: 'OrderDepartureForm',
        component: OrderDepartureCustomsForm,
        meta: {
            title: "订单管理 - 装货港-关务"
        },
    },
    {
        path: '/order/departure/customs/detail/:id',
        name: 'OrderDepartureDetail',
        component: OrderDepartureCustomsDetail,
        meta: {
            title: "订单管理 - 装货港-关务"
        },
    },

    {
        path: '/order/destination/customs/:id',
        name: 'OrderDestinationPageList',
        component: OrderDestinationPageList,
        meta: {
            title: "订单管理 - 目的港-清关"
        },
    },
    {
        path: '/order/destination/customs/update/:id',
        name: 'OrderDestinationForm',
        component: OrderDestinationCustomsForm,
        meta: {
            title: "订单管理 - 目的港-清关"
        },
    },

    {
        path: '/order/destination/customs/detail/:id',
        name: 'OrderDestinationDetail',
        component: OrderDestinationCustomsDetail,
        meta: {
            title: "订单管理 - 目的港-清关"
        },
    },

    {
        path: '/pool/departure/wharf/:id',
        name: 'PoolDepartureWharfPageList',
        component: OrderDepartureWharfPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-仓库/堆场/码头"
        },
    },

    {
        path: '/pool/destination/wharf/:id',
        name: 'PoolDestinationWharfPageList',
        component: OrderDestinationWharfPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-仓库/堆场/码头"
        },
    },


    {
        path: '/order/departure/wharf/:id',
        name: 'OrderDepartureWharfPageList',
        component: OrderDepartureWharfPageList,
        meta: {
            title: "订单管理 - 装货港-仓库/堆场/码头"
        },
    },

    {
        path: '/order/destination/wharf/:id',
        name: 'OrderDestinationWharfPageList',
        component: OrderDestinationWharfPageList,
        meta: {
            title: "订单管理 - 目的港-仓库/堆场/码头"
        },
    },
    {

        path: '/order/Inboundoutbound',
        name: 'OrderInboundOutboundPageList',
        component: OrderInboundOutboundPageList,
        meta: {
            title: "出入库管理"
        },
    },
    {

        path: '/order/Inboundoutbound/destination',
        name: 'OrderInboundOutboundPageListDestination',
        component: OrderInboundOutboundPageList,
        meta: {
            title: "出入库管理 - 目的港"
        },
    },
    {

        path: '/order/Inboundoutbound/departure',
        name: 'OrderInboundOutboundPageListDeparture',
        component: OrderInboundOutboundPageList,
        meta: {
            title: "出入库管理 - 装货港"
        },
    },
]

export default routes;