
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoRepuestWarehousing {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 供应商主键
     */
    SupplierId?: string;
    /**
     * 仓库名称
     */
    Name?: string;
}

/**
 * -表单校验
 */
export const DtoRepuestWarehousingRules = {
}
