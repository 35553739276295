
import CompanyAppService from "@/apis.machine/CompanyAppService";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoListItemCompany, {
  DtoListItemCompanyMiezzListItems,
} from "@/models.machine/DtoListItemCompany";
import DtoPageListSearchCompany, {
  DtoPageListSearchCompanyDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchCompany";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import UiPageListSearch_DynamicSearchItem from "@/models.machine/UiPageListSearch_DynamicSearchItem";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzListItem from "@/models/MiezzListItem";
import MiezzModal from "@/models/MiezzModal";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyPageList from "../CompanyPageList.vue";
@Options({
  components: {
    CompanyPageList,
  },
})
export default class CustomerCompanyPageList extends Vue {
  type = EnumCompanyType.Customer;
  list = new MiezzPageList<
    DtoPageListSearchCompany,
    DtoListItemCompany,
    string
  >("CustomerManagement_Company");
  /**公司类型选择器 */
  companyTypes?: UiSelectOption<string>[] = [];
  /**服务商选择器 */
  serviceProviders?: DtoSelectOptionCompany[] = [];

  created(): void {
    const logier = CurrentLogier.GetLogier();
    let items = DtoListItemCompanyMiezzListItems.filter(
      (it) =>
        it.Prop != "OpenThreeApiService" &&
        it.Prop != "BusinessScope" &&
        it.Prop != "Remark" &&
        it.Prop != "InquiryHistory"
    );
    let searchs = DtoPageListSearchCompanyDynamicSearchItems.filter(
      (it) =>
        it.Prop != "DynamicSearch_BusinessScope" &&
        it.Prop != "DynamicSearch_Remark"
    );
    if (logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      items = items.filter((it) => it.Prop != "ServiceProviderCompanyId");
      searchs = searchs.filter(
        (it) => it.Prop != "DynamicSearch_ServiceProviderCompanyId"
      );
    } else {
      for (const item of items) {
        switch (item.Prop) {
          case "ServiceProviderCompanyId":
            item.Label = "关联服务商";
            break;
          default:
            break;
        }
      }
      CompanyAppService.GetSelect({
        CompanyType: EnumCompanyType.ServiceProvider,
      }).then((r) => {
        if (r.data.Data) this.serviceProviders = r.data.Data;
      });
    }
    this.list.Items = items;
    this.list.DynamicSearchItems = searchs;

    CompanyTypeAppService.GetSelect({ Type: this.type }).then((r) => {
      this.companyTypes = r.data.Data;
    });

    //列表-传参
    this.list.Dto = new DtoPageListSearchCompany();
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CustomerManagement_Company_Add",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "detail",
        Title: "查看详细",
        Icon: "search",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_Company_Detail",
      },
      {
        Code: "users",
        Title: "查看用户",
        Icon: "user",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_User",
      },
    ];
    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        Message: "是否确认删除",
        PowerCode: "CustomerManagement_Company_Delete",
      },
      {
        Code: "batch-update-status",
        Label: "状态",
        Icon: "edit",
        Type: "text",
        PowerCode: "CustomerManagement_Company_UpdateStatus",
        Options: [
          {
            Code: "10",
            Label: "启用",
            Size: "small",
          },
          {
            Code: "20",
            Label: "禁用",
            Size: "small",
          },
        ],
      },
    ];
  }
}
