import UiSelectOption from "./UiSelectOption";

/**
 * 公司名片地区
 * @description 自动生成的代码,请勿修改
 */
export enum EnumCompanyBusinessCardArea {
    /**
     * 境内
     */
    Domestic = 1,
    /**
     * 境外
     */
    Overseas = 2,
}

/**
 * 公司名片地区-选项
 */
export const EnumCompanyBusinessCardAreaOption: UiSelectOption<number>[] = [
    { Label: "境内", Value: 1, Data: "Domestic" },
    { Label: "境外", Value: 2, Data: "Overseas" },
];
