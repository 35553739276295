import UiFormWithStringId from "./UiFormWithStringId";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodeMethodParam from "./DtoFormCodeMethodParam";
import DtoFormCodeMethodCallback from "./DtoFormCodeMethodCallback";

/**
 * 方法
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeMethod extends UiFormWithStringId {
    constructor() {
        //无参构造函数
        super()
        this.SyncController = true;
        this.IdType = "Guid";
        this.Params = [];
    }

    [proName: string]: any;
    /**
     * 分组
     */
    Group?: string;
    /**
     * 应用
     */
    App?: string;
    /**
     * 服务
     */
    Service?: string;
    /**
     * 同步至控制器
     */
    SyncController?: boolean;
    /**
     * 表
     */
    Table?: string;
    /**
     * Id类型
     */
    IdType?: string;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 参数Dto
     */
    Params?: DtoFormCodeMethodParam[];
    /**
     * 返回Dto
     */
    Callback?: DtoFormCodeMethodCallback;
}

/**
 * 方法-表单校验
 */
export const DtoFormCodeMethodRules = {
    Group: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    App: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    Service: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    SyncController: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Summary: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 方法-设置项
 */
export const DtoFormCodeMethodMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Group",
    Label: "分组",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "App",
    Label: "应用",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Service",
    Label: "服务",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "SyncController",
    Label: "同步至控制器",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Table",
    Label: "表",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "IdType",
    Label: "Id类型",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Summary",
    Label: "注释",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "名称",
    Customize: true,
  },
  {
    Prop: "Params",
    Label: "参数Dto",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Callback",
    Label: "返回Dto",
    Span: 24,
    Customize: true,
  },
]
