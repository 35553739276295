import DtoCallbackCountCurrency from "./DtoCallbackCountCurrency";
import DtoCallbackSalerSettlementItem from "./DtoCallbackSalerSettlementItem";
import DtoSelectMonth from "./DtoSelectMonth";
import DtoSettlementTime from "./DtoSettlementTime";
import DtoCallbackMonthPrice from "./DtoCallbackMonthPrice";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackSalerSettlement {
    constructor() {
        //无参构造函数
        this.Visiable = false;
        this.IsImportSurplus = false;
        this.CollectionTitles = [];
        this.PaymentTitles = [];
        this.IsAdd = false;
        this.Prices = [];
    }

    [proName: string]: any;
    /**
     * 弹出框是否显示
     */
    Visiable?: boolean;
    /**
     * 总金额
     */
    Total?: number;
    /**
     * 总金额
     */
    Totals?: DtoCallbackCountCurrency[];
    /**
     * 总金额
     */
    TotalString?: string;
    /**
     * 币制主键
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystemCode?: string;
    /**
     * 币制
     */
    CurrencySystemFullName?: string;
    /**
     * 待核销
     */
    NoSettlementPrice?: number;
    /**
     * 待核销
     */
    NoSettlementPrices?: DtoCallbackCountCurrency[];
    /**
     * 待核销
     */
    NoSettlementPriceString?: string;
    /**
     * 已核销
     */
    SettlementPrice?: string;
    /**
     * 已核销
     */
    SettlementPrices?: DtoCallbackCountCurrency[];
    /**
     * 已核销
     */
    SettlementPriceString?: string;
    /**
     * 核销明细
     */
    Items?: DtoCallbackSalerSettlementItem[];
    /**
     * 是否导入剩余：导入剩余的话表示已核销
     */
    IsImportSurplus?: boolean;
    /**
     * 委托书主键
     */
    PoolId?: string;
    /**
     * 
     */
    Month?: string;
    /**
     * 
     */
    SelectMonth?: string;
    /**
     * 供选择的月份
     */
    Months?: DtoSelectMonth[];
    /**
     * 业务员
     */
    SalerId?: string;
    /**
     * 默认的收款方抬头
     */
    CollectionTitle?: string;
    /**
     * 默认的付款方抬头
     */
    PaymentTitle?: string;
    /**
     * 创建人
     */
    CreatedUserName?: string;
    /**
     * 当前时间
     */
    CurrentTime?: string;
    /**
     * 收款方抬头集合
     */
    CollectionTitles?: DtoSettlementTime[];
    /**
     * 付款方抬头集合
     */
    PaymentTitles?: DtoSettlementTime[];
    /**
     * 发票主键
     */
    InvoiceId?: string;
    /**
     * 是否可以添加
     */
    IsAdd?: boolean;
    /**
     * 
     */
    Prices?: DtoCallbackMonthPrice[];
}

/**
 * -表单校验
 */
export const DtoCallbackSalerSettlementRules = {
}
