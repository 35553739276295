import UiDetail from "./UiDetail";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumContactType, EnumContactTypeOption } from "./EnumContactType";
import UiGroup from "./UiGroup";

/**
 * 通讯录详细-联系方式
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailCompanyContact_ContactNumber extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 联系方式
     */
    Type?: EnumContactType;
    /**
     * 号码/内容
     */
    Content?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 通讯录详细-联系方式-表单校验
 */
export const DtoDetailCompanyContact_ContactNumberRules = {
}

/**
 * 通讯录详细-联系方式-设置项
 */
export const DtoDetailCompanyContact_ContactNumberMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "联系方式",
    Pipe: EnumPipe.Enum,
    Options: EnumContactTypeOption,
  },
  {
    Prop: "Content",
    Label: "号码/内容",
  },
  {
    Prop: "Remark",
    Label: "备注",
  },
]

/**
 * 通讯录详细-联系方式-设置项
 */
export const DtoDetailCompanyContact_ContactNumberMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "联系方式",
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumContactTypeOption,
  },
  {
    Prop: "Content",
    Label: "号码/内容",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
