
import JoinPriceServiceTableAppService from "@/apis.machine/JoinPriceServiceTableAppService";
import PriceTableAppService from "@/apis.machine/PriceTableAppService";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import PriceServiceTableForm from "./PriceServiceTableForm.vue";
import PriceServiceTableSearchForm from "./PriceServiceTableSearchForm.vue";
import vuedraggable from "vuedraggable";
import { EnumPriceAmountType } from "@/models.machine/EnumPriceAmountType";
import MiezzPageList from "@/models/MiezzPageList";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumSupplierSourceOption } from "@/models.machine/EnumSupplierSource";
import DtoFormPriceExport from "@/models.machine/DtoFormPriceExport";
import { ElMessage, ElMessageBox } from "element-plus";
import PriceAppService from "@/apis.machine/PriceAppService";
@Options({
  components: {
    PriceServiceTableForm,
    PriceServiceTableSearchForm,
    vuedraggable,
  },
})
export default class PriceTableCatalogue extends Vue {
  @Prop() serviceId?: string;
  @Prop() amountType?: EnumPriceAmountType;
  options: UiSelectOption<string>[] = [];
  active?: string = "";
  addBtn: MiezzButton = {
    Code: "add",
    Icon: "plus",
    Label: "基础价格表",
    Type: "text",
    Size: "small",
    PowerCode: "PriceManagement_SetUp_Service_Detail_CostComposition_AddEdit",
  };
  modal = new MiezzModal();
  modalSetting = new MiezzModal();
  modalId?: string;
  buttons: MiezzButton[] = [];
  modalSupplier = new MiezzModal(MiezzModalType.Dialog);
  listSupplier = new MiezzPageList<any, any, string>();

  created(): void {
    if (this.serviceId) {
      this.buttons = [
        {
          Code: "setting",
          Icon: "setting",
          Title: "设置",
          Type: "default",
          Size: "small",
          PowerCode:
            "PriceManagement_SetUp_Service_Detail_CostComposition_AddEdit",
        },
        {
          Code: "edit",
          Icon: "edit",
          Title: "编辑",
          Type: "default",
          Size: "small",
          PowerCode:
            "PriceManagement_SetUp_Service_Detail_CostComposition_AddEdit",
        },
        {
          Code: "delete",
          Icon: "delete",
          Title: "删除",
          Type: "default",
          Size: "small",
          Message: "是否确认删除",
          PowerCode:
            "PriceManagement_SetUp_Service_Detail_CostComposition_Delete",
        },
        {
          Code: "drag-sort",
          Title: "拖曳",
          Icon: "Rank",
          Type: "default",
          Size: "small",
          PowerCode:
            "PriceManagement_SetUp_Service_Detail_CostComposition_AddEdit",
        },
      ];
    }
    if (this.amountType == EnumPriceAmountType.Cost) {
      this.buttons = [
        {
          Code: "supplier",
          Icon: "plus",
          Label: "供应商",
          Type: "text",
          Size: "small",
          PowerCode: "PriceManagement_Cost_RelationSupplier",
        },
        {
          Code: "export-all",
          Icon: "bottom",
          Label: "导出",
          Type: "text",
          Size: "small",
          PowerCode: "PriceManagement_Cost_Export",
        },
      ];
      this.modalSupplier.Title = "添加";
      this.modalSupplier.Description = "提示：可使用Ctrl+F调用浏览器自带查找工具，录入关键词搜索";
      this.modalSupplier.Width = "800px";
      this.modalSupplier.HandleClick = this.handleClick;
      this.modalSupplier.Buttons = [
        {
          Code: "save-supplier",
          Label: "保存",
          Type: "primary",
          Size: "small",
        },
      ];

      this.listSupplier.Fix = false;
      this.listSupplier.HideCard = true;
      this.listSupplier.EnabledDynamicSearch = false;
      this.listSupplier.EnabledRadioSearch = false;
      this.listSupplier.EnabledPagination = false;
      this.listSupplier.Items = [
        {
          Prop: "NameZh",
          Label: "供应商中文名称",
        },
        {
          Prop: "NameEn",
          Label: "供应商英文名称",
        },
        {
          Prop: "SupplierSource",
          Label: "类别",
          Pipe: EnumPipe.Enum,
          Options: EnumSupplierSourceOption,
        },
      ];
      this.listSupplier.Data = {
        Items: [],
      };
    }
    this.handleGet();
  }

  @Watch("serviceId")
  onServiceId(): void {
    this.handleGet();
  }

  handleGet(): void {
    if (this.serviceId) {
      JoinPriceServiceTableAppService.GetSelect({
        ServiceId: this.serviceId,
      }).then((r) => {
        this.options = r.data.Data ?? [];
        const serviceTableId = this.$route.query.ServiceTableId as
          | string
          | undefined;
        this.active =
          serviceTableId && this.options.any((it) => it.Value == serviceTableId)
            ? serviceTableId
            : this.options.firstOrDefault()?.Value;
        this.handleInit(this.options, this.active);
      });
    } else {
      PriceTableAppService.GetSelect({ AmountType: this.amountType }).then(
        (r) => {
          this.options = r.data.Data ?? [];
          const tableId = this.$route.query.TableId as string | undefined;
          this.active = tableId
            ? tableId
            : this.options.firstOrDefault()?.Value;
          this.handleInit(this.options, this.active);
        }
      );
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "add":
        this.modalId = undefined;
        this.modal.Title = "添加基础价格表";
        this.modal.Show = true;
        complete();
        break;
      case "save-supplier":
        PriceTableAppService.RelationSupplier({
          Id: this.modalId,
          SupplierIds: this.listSupplier.SelectIds,
        }).then(() => {
          this.handleGet();
          this.modalSupplier.Show = false;
          complete();
        });
        break;
      default:
        break;
    }
  }

  hnadleDrop(e: any): void {
    if (e.oldIndex != e.newIndex && this.options && this.options.length > 1) {
      const draggingIndex = e.newIndex;
      let dropIndex;
      let type: EnumUiDragType;
      if (draggingIndex == 0) {
        type = EnumUiDragType.Before;
        dropIndex = 1;
      } else {
        type = EnumUiDragType.After;
        dropIndex = draggingIndex - 1;
      }
      const dragging = this.options[draggingIndex];
      const drop = this.options[dropIndex];
      JoinPriceServiceTableAppService.DragSort({
        DraggingId: dragging.Value,
        DropId: drop.Value,
        DragType: type,
      }).then(() => {
        this.handleGet();
      });
    }
  }

  handleOptionClick(
    btn: MiezzButton,
    option: UiSelectOption<string>,
    complete: () => void
  ): void {
    switch (btn.Code) {
      case "setting":
        this.modalId = option.Value;
        this.modalSetting.Title = `设置基础费-${option.Label}`;
        this.modalSetting.Show = true;
        complete();
        break;
      case "edit":
        this.modalId = option.Value;
        this.modal.Title = `编辑基础费-${option.Label}`;
        this.modal.Show = true;
        complete();
        break;
      case "delete":
        JoinPriceServiceTableAppService.Delete([option.Value as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      case "supplier":
        this.modalId = option.Value;
        CompanyAppService.GetSelect({
          CompanyType: EnumCompanyType.Supplier,
          AmountType: EnumPriceAmountType.Cost,
          PriceTableId: this.modalId,
        }).then((r) => {
          if (this.listSupplier.Data) {
            this.listSupplier.Data.Items = r.data.Data;
            this.listSupplier.DefaultSelectIds =
              this.listSupplier.Data.Items?.filter(
                (it) => it.PriceTableRelationId
              ).map((it) => it.Id as string) ?? [];
          }
          this.modalSupplier.Show = true;
        });
        complete();
        break;
      case "export-all":
        var dto: DtoFormPriceExport = {};
        dto.TableId = option.Value;
        dto.TableName = "价格表";
        dto.AmountType = this.amountType;
        dto.All = true;
        ElMessageBox.confirm(`价格信息包含公式，请选择导出方式`, "导出", {
          confirmButtonText: "导出公式",
          cancelButtonText: "导出数值",
          distinguishCancelAndClose: true,
          showClose: true,
          type: "warning",
        })
          .then(() => {
            dto.ShowAmount = false;
            this.handleSaveExport(dto, complete);
          })
          .catch((action: string) => {
            if (action == "cancel") {
              dto.ShowAmount = true;
              this.handleSaveExport(dto, complete);
            } else {
              complete();
            }
          });
        break;
      default:
        break;
    }
  }

  handleSaveExport(dto: DtoFormPriceExport, complete: () => void): void {
    const message = ElMessage.warning({
      message: "正在下载",
      duration: 999999999,
    });
    PriceAppService.Export(dto)
      .then((r) => {
        window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
        message.close();
        complete();
      })
      .catch(complete);
  }

  handleBeforeChoose(
    options: UiSelectOption<string>[],
    active?: string,
    e?: any
  ): void {
    if (
      e.target.innerText != "供应商" &&
      e.target.innerText != "导出" &&
      (e.target.nodeName == "DIV" || e.target.nodeName == "SPAN")
    ) {
      this.handleChoose(options, active);
    }
  }

  @Emit()
  handleInit(options: UiSelectOption<string>[], active?: string): void {
    this.active = active;
  }
  @Emit()
  handleChoose(options: UiSelectOption<string>[], active?: string): void {
    this.active = active;
  }
}
