import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormJoinCompanyUserPersonalProfile extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Level = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 头像
     */
    Avatar?: string;
    /**
     * 真实姓名
     */
    Name?: string;
    /**
     * 部门
     */
    Departments?: string;
    /**
     * 职务
     */
    Job?: string;
    /**
     * 职级
     */
    Level?: number;
    /**
     * 登录账号（手机号）
     */
    PhoneNumber?: string;
    /**
     * 新手机号
     */
    NewPhoneNumber?: string;
    /**
     * 验证码
     */
    VerifyCode?: string;
    /**
     * 登录密码
     */
    Password?: string;
}

/**
 * -表单校验
 */
export const DtoFormJoinCompanyUserPersonalProfileRules = {
    Avatar: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    NewPhoneNumber: [
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
                    return callback(new Error("请输入正确的手机号码"));
                } else {
                    callback();
                }
            }
        },
    ],
    VerifyCode: [
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9]{6}$/.test(value)) {
                    return callback(new Error("验证码错误"));
                } else {
                    callback();
                }
            }
        },
    ],
    Password: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 32,
            message: "文本长度不能超过 32 个字符",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormJoinCompanyUserPersonalProfileMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Avatar",
    Label: "头像",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "真实姓名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Departments",
    Label: "部门",
    Customize: true,
  },
  {
    Prop: "Job",
    Label: "职务",
    Customize: true,
  },
  {
    Prop: "Level",
    Label: "职级",
    Customize: true,
  },
  {
    Prop: "PhoneNumber",
    Label: "登录账号（手机号）",
    Customize: true,
  },
  {
    Prop: "NewPhoneNumber",
    Label: "新手机号",
    Customize: true,
  },
  {
    Prop: "VerifyCode",
    Label: "验证码",
    Customize: true,
  },
  {
    Prop: "Password",
    Label: "登录密码",
    Type: EnumMiezzFormItemType.InputPassword,
  },
]
