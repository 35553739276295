import UiPageListSearch from "./UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "./UiPageListSearch_DynamicSearchItem";
import { EnumUiDynamicSearchItemType } from "./EnumUiDynamicSearchItemType";
import { EnumCompare } from "./EnumCompare";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListSearchOrderPoolProcessCount extends UiPageListSearch {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * -表单校验
 */
export const DtoPageListSearchOrderPoolProcessCountRules = {
}

/**
 * -动态搜索配置项
 */
export const DtoPageListSearchOrderPoolProcessCountDynamicSearchItems: UiPageListSearch_DynamicSearchItem[]  = [
  {
    Prop: "DynamicSearch_BookingNo",
    Label: "Booking No",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_OrderCode",
    Label: "订单编号",
    Type: EnumUiDynamicSearchItemType.InputText,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
      EnumCompare.Contain,
      EnumCompare.NoContain,
    ]
  },
  {
    Prop: "DynamicSearch_ClientUserId",
    Label: "委托人",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_LoadingPortId",
    Label: "装货港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPortId",
    Label: "目的港",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_DeliveryPlaceLabel",
    Label: "最终目的地",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ShipName",
    Label: "船名",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_VoyageNumber",
    Label: "航次",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Flight",
    Label: "航班",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_RailwayNumber",
    Label: "班次",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_ContainerNo",
    Label: "箱号",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedDepartureDate",
    Label: "ETD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualDepartureDate",
    Label: "ATD",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_EstimatedArrivalDate",
    Label: "ETA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ActualArrivalDate",
    Label: "ATA",
    Type: EnumUiDynamicSearchItemType.DateTimeRange,
    Compares: [
      EnumCompare.Between,
      EnumCompare.NoBetween,
      EnumCompare.Equal,
    ]
  },
  {
    Prop: "DynamicSearch_ServiceRemark",
    Label: "服务内容",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_Status",
    Label: "委托书状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
  {
    Prop: "DynamicSearch_OrderStatus",
    Label: "订单状态",
    Type: EnumUiDynamicSearchItemType.Customize,
    Compares: [
      EnumCompare.Equal,
      EnumCompare.NoEqual,
    ]
  },
]
