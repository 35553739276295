import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoHistory from "../models.machine/DtoHistory";
import DtoChange from "../models.machine/DtoChange";

/**
 * 历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class HistoryAppService {
    /**
     * 获取历史记录
     * @param {string} [name] 名称
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoHistory[]>>} 历史记录
     */
    static GetHistoriesByNameById(name?: string, id?: string): AxiosPromise<RESTfulResult<DtoHistory[]>> {
        const url = `/api/History/GetHistories/${name}/${id}`;
        return service.get<RESTfulResult<DtoHistory[]>>(url);
    }

    /**
     * 获取更改记录
     * @param {string} [id] 历史记录Id
     * @returns {AxiosPromise<RESTfulResult<DtoChange[]>>} 更改记录
     */
    static GetChangesById(id?: string): AxiosPromise<RESTfulResult<DtoChange[]>> {
        const url = `/api/History/GetChanges/${id}`;
        return service.get<RESTfulResult<DtoChange[]>>(url);
    }

}
