
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";
import CompanyBusinessCardPageList from "../../CompanyBusinessCard/CompanyBusinessCardPageList.vue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoPageListSearchCompanyBusinessCard from "@/models.machine/DtoPageListSearchCompanyBusinessCard";
import DtoListItemCompanyBusinessCard from "@/models.machine/DtoListItemCompanyBusinessCard";
import { EnumCompanyBusinessCardArea } from "@/models.machine/EnumCompanyBusinessCardArea";

@Options({
  components: {
    CompanyBusinessCardPageList,
  },
})
export default class CurrentCompanyOverseasConsignee extends Vue {
  title = "境外收货人";
  area = EnumCompanyBusinessCardArea.Overseas;
  type = EnumCompanyBusinessCardType.Consignee;
  list = new MiezzPageList<
    DtoPageListSearchCompanyBusinessCard,
    DtoListItemCompanyBusinessCard,
    string
  >("CompanyManagement_Company_OverseasConsignee");

  created(): void {
    this.list.CardLabelWidth = "68px";
    //列表-顶部按钮
    this.list.TopButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "CompanyManagement_Company_OverseasConsignee_AddEdit",
      },
    ];
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "bank",
        Title: "银行",
        Icon: "CreditCard",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_Bank",
      },
      {
        Code: "seal",
        Title: "电子章",
        Icon: "user",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_OverseasConsignee_ElectronicSeal",
      },
      {
        Code: "link",
        Title: "通知人",
        Icon: "UserFilled",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_OverseasNotifier",
      },
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
        PowerCode: "CompanyManagement_Company_OverseasConsignee_AddEdit",
      },
      {
        Code: "delete",
        Title: "删除",
        Icon: "delete",
        Type: "default",
        Message: "是否确认删除",
        Size: "small",
        PowerCode: "CompanyManagement_Company_OverseasConsignee_Delete",
      },
    ];
  }
}
