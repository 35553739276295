import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 开票税率表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamInvoiceTaxRate extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Name = 0;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 发票种类
     */
    Type?: string;
    /**
     * 税率
     */
    Name?: number;
    /**
     * 全名
     */
    FullName?: string;
}

/**
 * 开票税率表单-表单校验
 */
export const DtoFormParamInvoiceTaxRateRules = {
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 开票税率表单-设置项
 */
export const DtoFormParamInvoiceTaxRateMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Type",
    Label: "发票种类",
    Customize: true,
  },
  {
    Prop: "Name",
    Label: "税率",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
]
