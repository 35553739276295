
import TypescriptAppService from "@/apis.machine/TypescriptAppService";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoRequestTypescriptSync from "@/models.machine/DtoRequestTypescriptSync";
import MiezzModal from "@/models/MiezzModal";
import { ElMessage } from "element-plus";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class TypesciptSync extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() apps: string[] = [];

  options?: SyncData[] = [];
  
  created(): void {
    this.modelValue.Title = "同步Typescript代码";
    
    CurrentLogier.HubConnection?.on(
      "ReceiveSyncTypescriptCreateMessage",
      (label, value) => {
        const data = new SyncData();
        data.Label = label;
        data.Value = value;
        data.Success = false;
        this.options?.push(data);
      }
    );

    CurrentLogier.HubConnection?.on(
      "ReceiveSyncTypescriptBuildMessage",
      (value) => {
        const data = this.options?.firstOrDefault((it) => it.Value == value);
        if (data) {
          data.Success = true;
          this.options = this.options?.sort(
            (a, b) =>
              this.getSyncDataEqualWeight(a) - this.getSyncDataEqualWeight(b)
          );
        }
      }
    );

    this.handleSync();
  }

  getSyncDataEqualWeight(data: SyncData): number {
    const weight =
      (data.Success ? 100 : 0) +
      (data.Label?.startsWith("接口")
        ? 10
        : data.Label?.startsWith("模型")
        ? 20
        : 30);
    return weight;
  }

  handleSync(): void {
    this.options = [];
    var dto = new DtoRequestTypescriptSync();
    dto.Apps = this.apps;
    TypescriptAppService.Sync(dto)
      .then((r) => {
        ElMessage.success("同步成功");
      })
      .catch();
  }
}

class SyncData {
  constructor(label?: string, value?: string, success = false) {
    this.Label = label;
    this.Value = value;
    this.Success = success;
  }
  Label?: string;
  Value?: string;
  Success = false;
}

