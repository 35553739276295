import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPaymentTermsContent_PaymentTerms from "./DtoListItemPaymentTermsContent_PaymentTerms";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 付款条款内容分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPaymentTermsContent extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 付款条款
     */
    PaymentTerms?: DtoListItemPaymentTermsContent_PaymentTerms;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 付款条款内容分页列表-项-表单校验
 */
export const DtoListItemPaymentTermsContentRules = {
}

/**
 * 付款条款内容分页列表-项-设置项
 */
export const DtoListItemPaymentTermsContentMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "PaymentTerms",
    Label: "付款条款",
    Customize: true,
  },
]
