import { RouteRecordRaw } from 'vue-router'

import OrderPoolPageList from "./OrderPoolPageList.vue"
import OrderPoolForm from "./OrderPoolForm.vue"
import OrderPoolSummary from "./OrderPoolSummary.vue"
import OrderPoolFile from "./OrderPoolFile.vue"
import OrderPoolDetail from "./OrderPoolDetail.vue"

import DeparturePortBooking from "./Booking/DeparturePortBooking.vue"

import DeparturePortContainerTrailerPageList from "./ContainerTrailer/DeparturePortContainerTrailerPageList.vue"
import DestinationPortContainerTrailerPageList from "./ContainerTrailer/DestinationPortContainerTrailerPageList.vue"

import DeparturePortTrunkPageList from "./Trunk/DeparturePortTrunkPageList.vue"
import DeparturePortTrunkCargoPageList from "./Trunk/DeparturePortTrunkCargoPageList.vue"
import DeparturePortWharfCargoPageList from "./DeparturePortWharfCargoPageList.vue"
import DestinationPortTrunkPageList from "./Trunk/DestinationPortTrunkPageList.vue"
import DestinationPortTrunkCargoPageList from "./Trunk/DestinationPortTrunkCargoPageList.vue"
import DestinationPortWharfCargoPageList from "./DestinationPortWharfCargoPageList.vue"

import DeparturePortExpressPageList from "./ExpressPage/DeparturePortExpressPageList.vue"
import DeparturePortExpressCargoPageList from "./ExpressPage/DeparturePortExpressCargoPageList.vue"
import DestinationPortExpressPageList from "./ExpressPage/DestinationPortExpressPageList.vue"
import DestinationPortExpressCargoPageList from "./ExpressPage/DestinationPortExpressCargoPageList.vue"


const routes: Array<RouteRecordRaw> = [
    {
        path: '/order/pool',
        name: 'OrderManagement_Pool',
        component: OrderPoolPageList,
        meta: {
            title: "订单管理 - 委托书"
        },
    },
    {
        path: '/order/pool/add/:id',
        name: 'OrderManagement_Pool_Add',
        component: OrderPoolForm,
        meta: {
            title: "订单管理 - 委托书 - 添加"
        },
    },
    {
        path: '/order/pool/summary/:id',
        name: 'OrderManagement_Pool_Summary',
        component: OrderPoolSummary,
        meta: {
            title: "订单管理 - 委托书 - 委托摘要"
        },
    },
    {
        path: '/order/pool/file/:id',
        name: 'OrderManagement_Pool_Summary_File',
        component: OrderPoolFile,
        meta: {
            title: "订单管理 - 委托书 - 附件"
        },
    },
    {
        path: '/order/pool/detail/:id',
        name: 'OrderManagement_Pool_Summary_Detail',
        component: OrderPoolDetail,
        meta: {
            title: "订单管理 - 委托书 - 查看"
        },
    },
    {
        path: '/order/pool/edit/:id',
        name: 'OrderManagement_Pool_Summary_Detail_Edit',
        component: OrderPoolForm,
        meta: {
            title: "订单管理 - 委托书 - 编辑"
        },
    },

    {
        path: '/order/pool/departure-port-booking/:id',
        name: 'OrderManagement_Pool_Summary_DeparturePortBooking',
        component: DeparturePortBooking,
        meta: {
            title: "订单管理 - 委托书 - 装货港-订舱"
        },
    },

    {
        path: '/order/pool/departure-port-container/:id',
        name: 'OrderManagement_Pool_Summary_DeparturePortContainer',
        component: DeparturePortContainerTrailerPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-集装箱拖车"
        },
    },
    {
        path: '/order/pool/destination-port-container/:id',
        name: 'OrderManagement_Pool_Summary_DestinationPortContainer',
        component: DestinationPortContainerTrailerPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-集装箱拖车"
        },
    },

    {
        path: '/order/pool/departure-port-trunk/:id',
        name: 'OrderManagement_Pool_Summary_DeparturePortTrunk',
        component: DeparturePortTrunkPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-干线物流"
        },
    },
    {
        path: '/order/pool/departure-port-trunk/:id/cargos/:trunkId',
        name: 'OrderManagement_Pool_Summary_DeparturePortTrunk_Cargos',
        component: DeparturePortTrunkCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-干线物流 - 货物清单"
        },
    },
    {
        path: '/order/pool/departure-port-wharf/:id/cargos/:wharfId',
        name: 'OrderManagement_Pool_Summary_DeparturePortWharf_Cargos',
        component: DeparturePortWharfCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-仓库/堆场/码头 - 货物清单"
        },
    },
    {
        path: '/order/pool/destination-port-trunk/:id',
        name: 'OrderManagement_Pool_Summary_DestinationPortTrunk',
        component: DestinationPortTrunkPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-干线物流"
        },
    },
    {
        path: '/order/pool/destination-port-trunk/:id/cargos/:trunkId',
        name: 'OrderManagement_Pool_Summary_DestinationPortTrunk_Cargos',
        component: DestinationPortTrunkCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-干线物流 - 货物清单"
        },
    },
    {
        path: '/order/pool/destination-port-wharf/:id/cargos/:wharfId',
        name: 'OrderManagement_Pool_Summary_DestinationPortWharf_Cargos',
        component: DestinationPortWharfCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-仓库/堆场/码头 - 货物清单"
        },
    },

    {
        path: '/order/pool/departure-port-express/:id',
        name: 'OrderManagement_Pool_Summary_DeparturePortExpress',
        component: DeparturePortExpressPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-快递"
        },
    },
    {
        path: '/order/pool/departure-port-express/:id/cargos/:expressId',
        name: 'OrderManagement_Pool_Summary_DeparturePortExpress_Cargos',
        component: DeparturePortExpressCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 装货港-快递 - 货物清单"
        },
    },
    {
        path: '/order/pool/destination-port-express/:id',
        name: 'OrderManagement_Pool_Summary_DestinationPortExpress',
        component: DestinationPortExpressPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-快递"
        },
    },
    {
        path: '/order/pool/destination-port-express/:id/cargos/:expressId',
        name: 'OrderManagement_Pool_Summary_DestinationPortExpress_Cargos',
        component: DestinationPortExpressCargoPageList,
        meta: {
            title: "订单管理 - 委托书 - 目的港-快递 - 货物清单"
        },
    },
]

export default routes;