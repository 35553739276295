import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFromLogin {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 账号/邀请码
     */
    Account?: string;
    /**
     * 密码
     */
    Password?: string;
}

/**
 * -表单校验
 */
export const DtoFromLoginRules = {
    Account: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z]{4,32}$/.test(value)) {
                    return callback(new Error("账号必须是手机号/字母和数字组合，长度在4-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
    Password: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            validator: (rule: any, value: any, callback: any) => {
                if (value && !/^[0-9A-Za-z_]{8,32}$/.test(value)) {
                    return callback(new Error("密码必须由字母、数字、下划线组合，长度在8-32之间"));
                } else {
                    callback();
                }
            }
        },
    ],
}

/**
 * -设置项
 */
export const DtoFromLoginMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Account",
    Label: "账号/邀请码",
    Customize: true,
  },
  {
    Prop: "Password",
    Label: "密码",
    Type: EnumMiezzFormItemType.InputPassword,
  },
]
