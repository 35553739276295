import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderDepartureWharfAnnex_Annex from "./DtoPutOrderDepartureWharfAnnex_Annex";
import UiGroup from "./UiGroup";

/**
 * 装货港-仓库/堆场/码头-附件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderDepartureWharfAnnex extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.IsDetail = false;
        this.Visiable = false;
        this.Annexs = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 进仓通知
     */
    Warehouse?: string;
    /**
     * 箱贴
     */
    Box?: string;
    /**
     * 堆场主键
     */
    OrderDepartureWharfId?: string;
    /**
     * 是否详情
     */
    IsDetail?: boolean;
    /**
     * 是否显示弹出框
     */
    Visiable?: boolean;
    /**
     * 附件
     */
    Annexs?: DtoPutOrderDepartureWharfAnnex_Annex[];
    /**
     * 进仓编号
     */
    Code?: string;
    /**
     * 箱贴编号
     */
    BoxStickerCode?: string;
    /**
     * 是否需要箱贴
     */
    IsBoxSticker?: boolean;
}

/**
 * 装货港-仓库/堆场/码头-附件-表单校验
 */
export const DtoPutOrderDepartureWharfAnnexRules = {
}

/**
 * 装货港-仓库/堆场/码头-附件-设置项
 */
export const DtoPutOrderDepartureWharfAnnexMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Warehouse",
    Label: "进仓通知",
    Customize: true,
  },
  {
    Prop: "Box",
    Label: "箱贴",
    Customize: true,
  },
  {
    Prop: "OrderDepartureWharfId",
    Label: "堆场主键",
    Customize: true,
  },
  {
    Prop: "IsDetail",
    Label: "是否详情",
    Customize: true,
  },
  {
    Prop: "Visiable",
    Label: "是否显示弹出框",
    Customize: true,
  },
  {
    Prop: "Annexs",
    Label: "附件",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "进仓编号",
    Customize: true,
  },
  {
    Prop: "BoxStickerCode",
    Label: "箱贴编号",
    Customize: true,
  },
]
