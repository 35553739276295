
/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormPriceServiceCompanyShow {
    constructor() {
        //无参构造函数
        this.Ids = [];
        this.ShowIds = [];
        this.HideIds = [];
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    CompanyId?: string;
    /**
     * 服务Id
     */
    Ids?: string[];
    /**
     * 显示的服务Id
     */
    ShowIds?: string[];
    /**
     * 隐藏的服务Id
     */
    HideIds?: string[];
}

/**
 * -表单校验
 */
export const DtoFormPriceServiceCompanyShowRules = {
}
