import DynamicSearchBracket from "@/models.machine/DynamicSearchBracket";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import UiListItem from "@/models.machine/UiListItem";
import UiPageList from "@/models.machine/UiPageList";
import UiPageListSearch from "@/models.machine/UiPageListSearch";
import UiPageListSearch_DynamicSearchItem from "@/models.machine/UiPageListSearch_DynamicSearchItem";
import UiSelectOption from "@/models.machine/UiSelectOption";
import sortablejs from "sortablejs";
import MiezzButton from "./MiezzButton";
import MiezzListItem from "./MiezzListItem";

/**
 * 分页列表
 */
export default class MiezzPageList<TDto extends UiPageListSearch, TItem extends UiListItem<TKey>, TKey extends string | number> {
    constructor(powerCode?: string) {
        this.PowerCode = powerCode;
    }

    [proName: string]: any;
    /**类型 */
    Type = MiezzPageListType.Table;
    /*表格的提示信息 */
    DefaultTip?: string;
    /* 是否不需要底部的分页栏 */
    IsBottomButton?: boolean;
    /**最大化 */
    Fix = true;
    FixTop?: string;
    /**返回按钮 */
    Back?: boolean;
    /**隐藏卡片 */
    HideCard = false;
    /**卡片栅格 */
    CardSpan = 20;
    /**权限代码 */
    PowerCode?: string;
    /**标题 */
    Title?: string;
    /**搜索条件 */
    Dto?: TDto;
    /**列表数据 */
    Data?: UiPageList<TItem, TKey> = { Items: [] };
    /**卡片标题属性 */
    CardTitleProp?: string;
    CardLabelWidth?: string
    /**行 */
    Items?: MiezzListItem[];
    CellClassName?: any;
    SpanMethod?: any;
    /**顶部按钮 */
    TopButtons: MiezzButton[] = [];
    CheckTopButtonShow?: (btn: MiezzButton) => boolean;
    /**行内按钮 */
    RowButtons: MiezzButton[] = [];
    /**底部按钮 */
    BottomButtons: MiezzButton[] = [];
    /**选中行 */
    SelectRows: TItem[] = [];
    /**选中Id */
    SelectIds: TKey[] = [];
    /**默认选中Id */
    DefaultSelectIds: TKey[] = [];
    /**操作宽度 */
    OperationWidth?: string;
    /**简单动态搜索 */
    SimpleDynamicSearch = false;
    /**启用序号 */
    EnableIndex = false;
    /**启用动态搜索 */
    EnabledDynamicSearch = true;
    /**启用单选搜索 */
    EnabledRadioSearch = true;
    /**启用单选搜索-所有 */
    EnabledRadioSearchAll = true;
    //所有的Tab名
    RadioSearchAllTitle = "";
    DisabledRadioSearchRefresh = false;
    /**启用分页搜索 */
    EnabledPagination = true;
    /**启用复选框 */
    EnabledSelect = true;
    /**启用单选 */
    EnabledSingleSelect = false;
    DynamicSearchCode?: string;
    DynamicSearchIgnoreResetProps?: string[] = []
    DynamicSearchRules: any;
    /**隐藏动态搜索历史记录-标签项 */
    HideDynamicSearchHistoryTab?: boolean;
    /**只根据DynamicSearchCode查询历史记录 */
    DynamicSearchHistoryOnlyByCode = false;
    HideDynamicSearchCompare = false;
    DynamicSearchHistory?: UiSelectOption<string>[] = [];
    /**动态搜索-项 */
    DynamicSearchItems?: UiPageListSearch_DynamicSearchItem[] = [];
    DynamicSearchFixProp?: boolean;
    /**单选搜索-项 */
    RadioSearchItems?: UiSelectOption<any>[] = [];
    RadioSearchDynamicItems?: UiSelectOption<any>[] = [];
    /**禁止创建时查询数据 */
    DisabledCreatedHandleGet = false;
    /**禁用路由 */
    DisabledRouter = false;
    /**格式化 */
    HandleFormat?: (item: MiezzListItem, row: TItem) => string | undefined;
    /**行样式 */
    HandleRowClass?: (row: TItem, index: number) => string | undefined;
    /**复选框是否可勾选 */
    HandleSelectable?: (row: TItem, index: number) => boolean;
    /**检查行按钮是否显示 */
    CheckRowButtonShow?: (btn: MiezzButton, row: TItem) => boolean;
    HandleBuildRowButton?: (btn: MiezzButton, row: TItem) => MiezzButton
    /*是否按RadioSearch类型搜索 */
    IsRadioSearch = false;
    /*表格高度 */
    MaxHeight?: string;
    DragSort(query: string, callback: (items: TItem[], draggingId?: TKey, dropId?: TKey, type?: EnumUiDragType) => void): void {
        setTimeout(() => {
            const tbody = document.querySelector(
                query
            ) as any;
            if (tbody) {
                sortablejs.create(tbody, {
                    delay: 0,
                    animation: 300,
                    handle: ".drag-sort",
                    onEnd: (e: any) => {
                        if (
                            this.Data?.Items &&
                            this.Data.Items.length > 1
                        ) {
                            const draggingIndex = e.oldIndex;
                            const dropIndex = e.newIndex;
                            if (draggingIndex != dropIndex) {
                                const type =
                                    draggingIndex > dropIndex
                                        ? EnumUiDragType.Before
                                        : EnumUiDragType.After;
                                const dragging = this.Data.Items[draggingIndex];
                                const drop = this.Data.Items[dropIndex];
                                const items = this.Data.Items.slice(0);
                                items.splice(draggingIndex, 1);
                                items.splice(dropIndex, 0, dragging);
                                this.Data.Items = [];
                                callback(items, dragging.Id, drop.Id, type);
                            }
                        }
                    },
                });
            }
        }, 1);
    }
}

export enum MiezzPageListType {
    Table = 1,
    Card = 2
}