import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_param_audit_log_detail = _resolveComponent("param-audit-log-detail")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleGet: _ctx.handleGet,
      onHandleRowButtonClick: _ctx.handleRowButtonClick
    }, {
      "dynamic-search-customize": _withCtx(({ model }) => [
        (model.Where.Prop == 'DynamicSearch_ParamTableLabel')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.optParamList
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true),
        (model.Where.Prop == 'DynamicSearch_ProjectSiteId')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 1,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.optProjectSite
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true),
        (model.Where.Prop == 'DynamicSearch_Status')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 2,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.optParamAuditStatus
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true),
        (model.Where.Prop == 'DynamicSearch_AuditResult')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 3,
              modelValue: model.Where.Value,
              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
              options: _ctx.optParamAuditResult
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
          : _createCommentVNode("", true)
      ]),
      column: _withCtx((scoped) => [
        (scoped.model.Prop == 'ProjectSite')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (scoped.row.ProjectSite)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scoped.row.ProjectSite.Name), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, " -- "))
            ]))
          : _createCommentVNode("", true),
        (scoped.model.Prop == 'CrudStatus')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (scoped.row.CrudStatus == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "新增"))
                : (scoped.row.CrudStatus == 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "编辑"))
                  : (scoped.row.CrudStatus == 2)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "删除"))
                    : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleGet", "onHandleRowButtonClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalDetail,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalDetail) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_param_audit_log_detail, {
          modelValue: _ctx.modalDetail,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDetail) = $event)),
          id: _ctx.id,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}