
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import CompanyBusinessCardAppService from "@/apis.machine/CompanyBusinessCardAppService";
import DtoPageListSearchCompanyBusinessCardUnit from "@/models.machine/DtoPageListSearchCompanyBusinessCardUnit";
import DtoPageListCompanyBusinessCard from "@/models.machine/DtoPageListCompanyBusinessCard";
import DtoListItemCompanyBusinessCardUnit, {
  DtoListItemCompanyBusinessCardUnitMiezzListItems,
} from "@/models.machine/DtoListItemCompanyBusinessCardUnit";
import CompanyBusinessCardUnitForm from "./CompanyBusinessCardUnitForm.vue";
import { EnumCompanyBusinessCardType } from "@/models.machine/EnumCompanyBusinessCardType";

@Options({
  components: {
    CompanyBusinessCardUnitForm,
  },
})
export default class CompanyBusinessCardUnitPageList extends Vue {
  @Prop() title!: string;
  @Prop() type!: EnumCompanyBusinessCardType;
  @Prop() list!: MiezzPageList<
    DtoPageListSearchCompanyBusinessCardUnit,
    DtoListItemCompanyBusinessCardUnit,
    string
  >;
  @Prop() bankLink?: string;

  companyId?: string;
  id?: string = "";
  /**表单模态框 */
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    this.companyId = this.$route.params.id as string;
    //列表-传参
    this.list.Type = MiezzPageListType.Card;
    this.list.Back = true;
    this.list.EnabledSelect = false;
    this.list.Title = this.title;
    this.list.EnabledRadioSearch = false;
    this.list.CardLabelWidth = "96px";
    this.list.CardSpan = 25;
    this.list.Dto = new DtoPageListSearchCompanyBusinessCardUnit();
    //列表-数据-初始
    this.list.Data = new DtoPageListCompanyBusinessCard();
    this.list.Items = DtoListItemCompanyBusinessCardUnitMiezzListItems;
  }

  /**查询列表 */
  handleGet(): void {
    const dto = Object.copy(this.list.Dto);
    dto.CompanyId = this.companyId;
    dto.Type = this.type;
    CompanyBusinessCardAppService.GetUnit(dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = `新增${this.title}`;
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**按钮点击 */
  @Emit()
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemCompanyBusinessCardUnit,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "bank":
        this.$router.push(`${this.bankLink}/${row.Id}`);
        complete();
        break;
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑${this.title}`;
        complete();
        break;
      case "delete":
        CompanyBusinessCardAppService.Delete([this.id as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }
}
