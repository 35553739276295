import DtoDetailOrderProcess_Tab from "./DtoDetailOrderProcess_Tab";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderProcess_OrderPoolContainerTrailer extends DtoDetailOrderProcess_Tab {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 提柜时间
     */
    CarryContainerTime?: Date;
    /**
     * 计划抵达时间
     */
    PlanTime?: Date;
    /**
     * 抵达时间
     */
    ArrivalTime?: Date;
    /**
     * 还柜时间
     */
    BackContainerTime?: Date;
}

/**
 * -表单校验
 */
export const DtoDetailOrderProcess_OrderPoolContainerTrailerRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderProcess_OrderPoolContainerTrailerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CarryContainerTime",
    Label: "提柜时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "PlanTime",
    Label: "计划抵达时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "ArrivalTime",
    Label: "抵达时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "BackContainerTime",
    Label: "还柜时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
