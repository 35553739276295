import UiSelectOption from "./UiSelectOption";

/**
 * 柜量
 * @description 自动生成的代码,请勿修改
 */
export enum EnumEquivalentUnit {
    /**
     * TEU
     */
    TEU = 1,
    /**
     * FEU
     */
    FEU = 2,
}

/**
 * 柜量-选项
 */
export const EnumEquivalentUnitOption: UiSelectOption<number>[] = [
    { Label: "TEU", Value: 1, Data: "TEU" },
    { Label: "FEU", Value: 2, Data: "FEU" },
];
