import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormCodeProperty from "./DtoFormCodeProperty";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormCodeMethodCallback {
    constructor() {
        //无参构造函数
        this.IsLeaf = false;
        this.IsList = false;
        this.Properties = [];
    }

    [proName: string]: any;
    /**
     * 叶子
     */
    IsLeaf?: boolean;
    /**
     * 集合
     */
    IsList?: boolean;
    /**
     * 注释
     */
    Summary?: string;
    /**
     * 类型
     */
    Name?: string;
    /**
     * 父类
     */
    BaseType?: string;
    /**
     * 属性
     */
    Properties?: DtoFormCodeProperty[];
}

/**
 * -表单校验
 */
export const DtoFormCodeMethodCallbackRules = {
    IsLeaf: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    IsList: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Summary: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    BaseType: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormCodeMethodCallbackMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "IsLeaf",
    Label: "叶子",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "IsList",
    Label: "集合",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Summary",
    Label: "注释",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Name",
    Label: "类型",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "BaseType",
    Label: "父类",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Properties",
    Label: "属性",
    Hide: true,
    Customize: true,
  },
]
