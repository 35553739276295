import { EnumTransportDirection, EnumTransportDirectionOption } from "./EnumTransportDirection";
import { EnumTransportType, EnumTransportTypeOption } from "./EnumTransportType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSelectSearchOrdeCommon {
    constructor() {
        //无参构造函数
        this.IgnoreIds = [];
        this.StowageStack = false;
        this.CountSearch = false;
    }

    [proName: string]: any;
    /**
     * 忽略的订单Id
     */
    IgnoreIds?: string[];
    /**
     * 配载栈
     */
    StowageStack?: boolean;
    /**
     * 关键字
     */
    Keywords?: string;
    /**
     * 查询条数
     */
    Take?: number;
    /**
     * 运输方向
     */
    TransportDirection?: EnumTransportDirection;
    /**
     * 运输类型
     */
    TransportType?: EnumTransportType;
    /**
     * Port of  Loading/装货港Id
     */
    LoadingPortId?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * 统计搜索
     */
    CountSearch?: boolean;
}

/**
 * -表单校验
 */
export const DtoSelectSearchOrdeCommonRules = {
}
