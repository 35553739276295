import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchCompanyInvoice from "../models.machine/DtoPageListSearchCompanyInvoice";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListCompanyInvoice from "../models.machine/DtoPageListCompanyInvoice";
import DtoDetailCompanyInvoice from "../models.machine/DtoDetailCompanyInvoice";
import DtoFormCompanyInvoice from "../models.machine/DtoFormCompanyInvoice";

/**
 * 公司发票
 * @description 自动生成的代码,请勿修改
 */
export default class CompanyInvoiceAppService {
    /**
     * 获取公司发票分页列表
     * @param {DtoPageListSearchCompanyInvoice} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListCompanyInvoice>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchCompanyInvoice): AxiosPromise<RESTfulResult<DtoPageListCompanyInvoice>> {
        const url = `/api/CompanyInvoice/Get`;
        return service.get<RESTfulResult<DtoPageListCompanyInvoice>>(url, { params: dto });
    }

    /**
     * 获取公司发票详细
     * @param {string} [id] Id
     * @returns {AxiosPromise<RESTfulResult<DtoDetailCompanyInvoice>>} 详细
     */
    static GetById(id?: string): AxiosPromise<RESTfulResult<DtoDetailCompanyInvoice>> {
        const url = `/api/CompanyInvoice/Get/${id}`;
        return service.get<RESTfulResult<DtoDetailCompanyInvoice>>(url);
    }

    /**
     * 添加公司发票
     * @param {DtoFormCompanyInvoice} [dto] 表单
     * @returns {AxiosPromise<RESTfulResult<string>>} Id
     */
    static Post(dto?: DtoFormCompanyInvoice): AxiosPromise<RESTfulResult<string>> {
        const url = `/api/CompanyInvoice/Post`;
        return service.post<RESTfulResult<string>>(url, dto);
    }

    /**
     * 批量删除公司发票
     * @param {string} [companyId] 公司Id
     * @param {string[]} [ids] Id
     * @returns {AxiosPromise<RESTfulResult<any>>} 
     */
    static DeleteByCompanyId(companyId?: string, ids?: string[]): AxiosPromise<RESTfulResult<any>> {
        const url = `/api/CompanyInvoice/Delete/${companyId}`;
        return service.delete<RESTfulResult<any>>(url, { data: ids });
    }

}
