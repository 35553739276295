import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderContainer from "./DtoFormOrderContainer";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderManifestVgm_MainBill extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Containers = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 船名
     */
    ShipName?: string;
    /**
     * 航次
     */
    VoyageNumber?: string;
    /**
     * 航班
     */
    Flight?: string;
    /**
     * 班次
     */
    RailwayNumber?: string;
    /**
     * Port of Delivery/目的港Id
     */
    DeliveryPortId?: string;
    /**
     * Port of Delivery/目的港
     */
    DeliveryPortLabel?: string;
    /**
     * 总提单号码
     */
    MasterBillNo?: string;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 品名
     */
    GoodsDescription?: string;
    /**
     * 毛重
     */
    GrossWeight?: number;
    /**
     * 尺码
     */
    Volume?: number;
    /**
     * 集装箱拖车
     */
    Containers?: DtoFormOrderContainer[];
}

/**
 * -表单校验
 */
export const DtoFormOrderManifestVgm_MainBillRules = {
    ShipName: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    VoyageNumber: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    Flight: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    RailwayNumber: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    DeliveryPortLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    MasterBillNo: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoFormOrderManifestVgm_MainBillMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ShipName",
    Label: "船名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "VoyageNumber",
    Label: "航次",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Flight",
    Label: "航班",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "RailwayNumber",
    Label: "班次",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeliveryPortId",
    Label: "Port of Delivery/目的港Id",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DeliveryPortLabel",
    Label: "Port of Delivery/目的港",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "MasterBillNo",
    Label: "总提单号码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PackagesNumber",
    Label: "装运数量",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "PackagesKind",
    Label: "装运包装",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GoodsDescription",
    Label: "品名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "GrossWeight",
    Label: "毛重",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Volume",
    Label: "尺码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Containers",
    Label: "集装箱拖车",
    Type: EnumMiezzFormItemType.InputText,
  },
]
