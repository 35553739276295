import UiSelectOption from "./UiSelectOption";

/**
 * 审核状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumParamAuditStatus {
    /**
     * 待处理
     */
    Not = 0,
    /**
     * 已处理
     */
    Success = 10,
}

/**
 * 审核状态-选项
 */
export const EnumParamAuditStatusOption: UiSelectOption<number>[] = [
    { Label: "待处理", Value: 0, Data: "Not" },
    { Label: "已处理", Value: 10, Data: "Success" },
];
