
    import BillAppService from "@/apis.machine/BillAppService";
    import DtoRequestOrderLock from "@/models.machine/DtoRequestOrderLock";
    import MiezzForm from "@/models/MiezzForm";
    import CurrentLogier from "@/apis/CurrentLogier";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzPageList from "@/models/MiezzPageList";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import MiezzButton from "@/models/MiezzButton";
    import FinanceAppService from "@/apis.machine/FinanceAppService";
    import DtoCallbackSingleBill from "@/models.machine/DtoCallbackSingleBill";
    import DtoPageListSearchFinance from "@/models.machine/DtoPageListSearchFinance";
    import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
    import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
    import UiSelectOption from "@/models.machine/UiSelectOption";

    export default class FinancePageList extends Vue {
        //访问委托书摘要的权限
        OrderManagement_Pool_Summary = false;
        //访问订单摘要的权限
        OrderManagement_Order_Summary = false;

        lockParam = new DtoRequestOrderLock();
        /**分页列表 */
        dto = new DtoCallbackSingleBill();
        search = new DtoPageListSearchFinance();
        /**当前Id */
        id?: string;
        type?: number;

        isLoading = false;
        //物流计价单位
        chargeUnits: UiSelectOption<string>[] = [];

        //费用名称
        costs: UiSelectOption<string>[] = [];

        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "text",
                Size: "small",
            },
            {
                Code: "save",
                Label: "保存",
                Icon: "select",
                Type: "text",
                Size: "small",
            },
        ];

        currencyIndex = "";

        batchOperationId?: string;
        startDateRuleForm = {
            Visiable: false,
            Time: undefined,
        };
        startDateBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];



        accountPeriodRuleForm = {
            Visiable: false,
            AccountPeriod: "",
        };
        accountPeriodBottomButtons?: MiezzButton[] = [
            {
                //Icon: "select",
                Code: "submit",
                Label: "确定",
                Type: "primary",
                Size: "small",
            },
            {
                //Icon: "CloseBold",
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        onChangeStartDate(index: number): void {
            console.log(index);
        }

        onChangeRemark(index: number): void {
            console.log(index);
        }

        onStartDate(): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items.length <= 0) {
                    ElMessage.warning("至少添加一条账单才能编辑账单起始时间");
                    return;
                }
            }
            this.startDateRuleForm.Time = undefined;
            this.startDateRuleForm.Visiable = true;
        }
        handleClickStartDate(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.dto && this.dto.Items) {
                        for (var it of this.dto.Items) {
                            if (it.Type != 30) {
                                it.StartDate = this.startDateRuleForm.Time;
                            }
                        }
                    }
                    this.startDateRuleForm.Visiable = false;
                    complete();
                    break;
                case "close":
                    this.startDateRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onChangeAccountPeriod(index: number): void {
            if (this.dto && this.dto.Items) {
                var accountPeriod = this.dto.Items[index].AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod.toString(), 0, true, false);
                    if (result != "") {
                        this.dto.Items[index].AccountPeriod = Number(result);
                    }
                    else {
                        this.dto.Items[index].AccountPeriod = undefined;
                    }
                }
            }
        }
        onChangePeriod(): void {
            if (this.accountPeriodRuleForm) {
                var accountPeriod = this.accountPeriodRuleForm.AccountPeriod;
                if (accountPeriod != undefined) {
                    var result = setDecimal(accountPeriod + "", 0, true, false);
                    if (result != "") {
                        this.accountPeriodRuleForm.AccountPeriod = Number(result) + "";
                    }
                    else {
                        this.accountPeriodRuleForm.AccountPeriod = "";
                    }
                }
            }
        }
        onAccountPeriod(): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items.length <= 0) {
                    ElMessage.warning("至少添加一条账单才能批量编辑账期");
                    return;
                }
                //for (var it of this.dto.Items) {
                //    if (it.SupplierCompanyId == null || it.SupplierCompanyId == undefined) {
                //        ElMessage.warning("账单中存在未选择供应商，不能批量编辑账期");
                //        return;
                //    }
                //}
            }
            this.accountPeriodRuleForm.AccountPeriod = "";
            this.accountPeriodRuleForm.Visiable = true;
        }
        handleClickAccountPeriod(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.dto && this.dto.Items) {
                        for (var it of this.dto.Items) {
                            if (it.Type != 30) {
                                if (this.accountPeriodRuleForm.AccountPeriod != null && this.accountPeriodRuleForm.AccountPeriod != undefined && this.accountPeriodRuleForm.AccountPeriod != "") {
                                    it.AccountPeriod = Number(this.accountPeriodRuleForm.AccountPeriod);
                                }
                                else {
                                    it.AccountPeriod = undefined;
                                }
                            }
                        }
                    }
                    this.accountPeriodRuleForm.Visiable = false;
                    complete();
                    break;
                case "close":
                    this.accountPeriodRuleForm.Visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        onPool(row: any) {
            let routeUrl = this.$router.resolve({
                path: "/order/pool/summary/" + row.PoolId,
                query: { backUrl: this.$route.fullPath },
            });
            window.open(routeUrl.href, '_blank');
        }
        onOrder(row: any) {
            let routeUrl = this.$router.resolve({
                path: "/order/summary/" + row.OrderId,
                query: { backUrl: this.$route.fullPath },
            });
            window.open(routeUrl.href, '_blank');
        }

        /**创建时 */
        created(): void {

            this.OrderManagement_Pool_Summary = false;
            this.OrderManagement_Order_Summary = false;
            CurrentLogier.GetPowerCodes().then((r) => {
                if (r?.contains("OrderManagement_Pool_Summary")) {
                    this.OrderManagement_Pool_Summary = true;
                }
                if (r?.contains("OrderManagement_Order_Summary")) {
                    this.OrderManagement_Order_Summary = true;
                }
            });

            this.batchOperationId = this.$route.params.id as string;

            this.isLoading = true;
            ParamPriceCostAppService.GetSelect().then((cost) => {
                if (cost.data.Data) this.costs = cost.data.Data;

                ParamTransportChargeUnitAppService.GetSelect().then((unit) => {
                    if (unit.data.Data) this.chargeUnits = unit.data.Data;
                    this.handleGet();
                })
            })

        }

        onCurrency(currencyId: string, index: string): void {
            this.currencyIndex = index;
            const Currency = this.$refs["Currency"] as any;
            Currency.onInit(currencyId);
        }
        onSelectCurrency(row: any): void {

            if (this.dto && this.dto.Items) {
                for (var it of this.dto.Items) {
                    if (it.Id == this.currencyIndex) {
                        it.CurrencySystemId = row.Value;
                        it.CurrencySystemName = row.Data.Code;
                        it.CurrencySystem = row.Data;
                    }
                }
            }
        }

        onChangeFirstChargeUnitNumber(index: number): void {
            if (this.dto && this.dto.Items) {
                var firstChargeUnitNumber = this.dto.Items[index].FirstChargeUnitNumber;
                if (firstChargeUnitNumber != undefined) {
                    var result = setDecimal(firstChargeUnitNumber.toString(), 4, false, false);
                    if (result != "") {
                        this.dto.Items[index].FirstChargeUnitNumber = Number(result);

                    }
                    else {
                        this.dto.Items[index].FirstChargeUnitNumber = undefined;
                    }
                }

                this.dto.Items[index].FirstChargeUnitValue
                if (this.dto.Items[index].FirstChargeUnitNumber != undefined && this.dto.Items[index].FirstChargeUnitId != undefined) {
                    this.dto.Items[index].FirstChargeUnitValue = this.dto.Items[index].FirstChargeUnitNumber;
                    MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".FirstChargeUnitValue");
                }
            }
            this.onSum();
        }
        onChangeSecondChargeUnitNumber(index: number): void {
            if (this.dto && this.dto.Items) {
                var secondChargeUnitNumber = this.dto.Items[index].SecondChargeUnitNumber;
                if (secondChargeUnitNumber != undefined) {
                    var result = setDecimal(secondChargeUnitNumber.toString(), 4, false, false);
                    if (result != "") {
                        this.dto.Items[index].SecondChargeUnitNumber = Number(result);
                        MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".SecondChargeUnitNumber");
                    }
                    else {
                        this.dto.Items[index].SecondChargeUnitNumber = undefined;
                    }
                }
            }
            this.onSum();
        }

        onSum(): void {
            if (this.dto) {
                this.dto.Total = "合计金额：CNY0";
            }
            if (this.dto && this.dto.Items && this.dto.Items.length > 0) {
                for (var it of this.dto.Items) {
                    it.FirstChargeUnitValue = undefined;
                    if (it.FirstChargeUnitNumber != undefined && it.FirstChargeUnitId != undefined) {
                        it.FirstChargeUnitValue = it.FirstChargeUnitNumber;
                    }
                    it.TotalPrice = undefined;
                    if (it.UnitPrice != undefined && it.FirstChargeUnitNumber != undefined && it.UnitPrice != null && it.FirstChargeUnitNumber != null) {
                        if (it.SecondChargeUnitNumber != undefined && it.SecondChargeUnitNumber != null) {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber * it.SecondChargeUnitNumber;
                        }
                        else {
                            it.TotalPrice = it.UnitPrice * it.FirstChargeUnitNumber;
                        }
                    }
                    if (it.TotalPrice != undefined && it.TotalPrice != null) {
                        var result = setDecimal(it.TotalPrice.toString(), 4, true, true);
                        if (result != "") {
                            it.TotalPrice = Number(result);
                        }
                        else {
                            it.TotalPrice = undefined;
                        }
                    }
                }

                var currencys = [];
                for (var it1 of this.dto.Items) {
                    var exist = false;
                    for (var cu of currencys) {
                        if (cu == it1.CurrencySystemId) {
                            exist = true;
                        }
                    }
                    if (exist == false) {
                        currencys.push(it1.CurrencySystemId);
                    }
                }
                this.dto.Total = "合计金额：";
                for (var cu1 of currencys) {
                    var code = "";
                    var totalPrice = 0;
                    for (var it2 of this.dto.Items) {
                        if (it2.CurrencySystemId == cu1) {
                            if (it2.TotalPrice != undefined) {
                                totalPrice += it2.TotalPrice;
                            }
                            if (it2.CurrencySystem && it2.CurrencySystem.Code != undefined) {
                                code = it2.CurrencySystem.Code;
                            }
                        }
                    }
                    this.dto.Total += " " + code + totalPrice;
                }
            }
        }


        querySearchCost(queryString: string, cb: any) {
            var restaurants = this.costs;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.costs) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.costs) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.costs) {
                    var number1 = 0;
                    if (number1 <= 0) {
                        for (var i = 0; i < this.costs.length; i++) {
                            if (i < 10) {
                                results.push(this.costs[i])
                            }
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectCost(item: UiSelectOption<string>, index: number) {
            if (this.dto && this.dto.Items) {
                this.dto.Items[index].CostId = item.Value;
                this.dto.Items[index].CostName = item.Label;
            }
        }
        onChangeCostId(index: number): void {
            if (this.dto && this.dto.Items) {
                if (this.dto.Items[index].CostName == undefined || this.dto.Items[index].CostName == "") {
                    this.dto.Items[index].CostId = undefined;
                }
                else {
                    if (this.dto.Items[index].CostId == undefined) {
                        this.dto.Items[index].CostName = undefined;
                    }
                    else if (this.dto.Items[index].CostName != undefined) {
                        var flag = false;
                        if (this.costs) {
                            for (var it of this.costs) {
                                if (it.Label == this.dto.Items[index].CostName) {
                                    this.dto.Items[index].CostId = it.Value;
                                    flag = true;
                                    break;
                                }
                            }
                            if (flag == false) {
                                this.dto.Items[index].CostName = undefined;
                            }
                        }
                    }
                }

            }
        }

        /**查询列表 */
        handleGet(): void {
            this.isLoading = true;
            this.search.BatchOperationId = this.batchOperationId;
            FinanceAppService.Get(this.search).then((r) => {
                this.isLoading = false;
                if (r.data.Data && this.dto && this.dto.Items) {
                    this.dto.Items = r.data.Data.Items;
                    if (this.dto.Items) {
                        for (var it of this.dto.Items) {
                            it.CostName = "";
                            if (it.Cost != null && it.Cost != undefined) {
                                it.CostName = it.Cost.FullName;
                            }
                        }
                    }
                }
                this.onSum();
            });
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        onChangeUnitPrice(index: number): void {
            if (this.dto && this.dto.Items) {
                var unitPirce = this.dto.Items[index].UnitPrice;
                if (unitPirce != undefined) {
                    var result = setDecimal(unitPirce.toString(), 4, true, true);
                    if (result != "") {
                        this.dto.Items[index].UnitPrice = Number(result);
                        MiezzForm.clearCustomValidate(this.$refs, "$billRuleForm", "Items." + index + ".UnitPrice");
                    }
                    else {
                        this.dto.Items[index].UnitPrice = undefined;
                    }
                }
            }
            this.onSum();
        }

        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                this.$router.push(url);
            }
            else {
                history.go(-1);
            }
        }

        handleClick(btn: MiezzButton, complete: () => void): void {


            switch (btn.Code) {
                case "save":
                    this.lockParam.BillId = [];
                    this.lockParam.OrderId = [];
                    this.lockParam.PoolId = [];
                    if (this.dto && this.dto.Items) {
                        for (var bill of this.dto.Items) {
                            this.lockParam.BillId.push(bill.Id as string);
                        }
                    }
                    BillAppService.IsOrderLock(this.lockParam).then((lock) => {
                        if (lock.data.Data == true) {
                            ElMessage.warning("包含已锁定的订单，请重新选择");
                            complete();
                            return;
                        }
                        else {
                            MiezzForm.customSubmit(this.$refs, "$billRuleForm", (v) => {
                                if (v == true) {
                                    this.isLoading = true;
                                    FinanceAppService.BatchEdit(this.dto).then((r) => {
                                        this.isLoading = false;
                                        ElMessage.success("批量编辑成功");
                                        this.handleBack();
                                        complete();
                                    })
                                }
                                else {
                                    complete();
                                }
                            });
                        }
                    })




                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**提交表单回调 */
        handleSubmit(): void {
            this.handleGet();
        }

        /**删除回调 */
        handleDelete(): void {
            this.handleGet();
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    /*isLessZero：是否允许输入小于0 */
    var setDecimal = function (val: string, num: number, isZero: boolean, isLessZero: boolean) {

        var str = val;
        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val == undefined || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            if (isLessZero == true) {
                if (str[0] == "-") {
                    result = "-" + result;
                }
            }
            return result;
        }
    };
