
import DtoFormCodeMethodParam, {
  DtoFormCodeMethodParamMiezzFormItems,
  DtoFormCodeMethodParamRules,
} from "@/models.machine/DtoFormCodeMethodParam";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CodePropertyTree from "../CodeDto/CodePropertyTree.vue";
@Options({
  components: {
    CodePropertyTree,
  },
})
export default class CodeMethodParamForm extends Vue {
  @Model() modelValue: DtoFormCodeMethodParam[] = [];
  @Prop() table?: string;
  @Prop() tKey?: string;
  @Prop() show: string[] = [];
  items = DtoFormCodeMethodParamRules;
  rules = DtoFormCodeMethodParamMiezzFormItems;
  leafTypes: string[] = [
    "string",
    "Guid",
    "Guid?",
    "int",
    "int?",
    "long",
    "long?",
    "decimal",
    "decimal?",
    "DateTime",
    "DateTime?",
  ];
  baseTypes: string[] = [];

  created(): void {
    this.onTKeyChanged();
  }

  @Watch("tKey")
  onTKeyChanged(): void {
    this.baseTypes = [
      `IPrimaryKey<${this.tKey}>`,
      `UiDetail<${this.tKey}>`,
      `UiForm<${this.tKey}>`,
      `UiFormWithStringId`,
      `UiListItem<${this.tKey}>`,
    ];
  }

  handleAdd(): void {
    if (this.modelValue) {
      this.modelValue.push(new DtoFormCodeMethodParam());
    }
  }

  handleRemove(index: number): void {
    this.modelValue.splice(index, 1);
  }

  onIsLeafChanged(item: DtoFormCodeMethodParam): void {
    if (item.IsLeaf) {
      if (item.Name && item.Name.indexOf("Dto") == 0) {
        item.Name = undefined;
      }
      if (item.Summary == "传参") {
        item.Summary = undefined;
      }
      if (item.FieldName == "dto") {
        item.FieldName = undefined;
      }

      this.items;

      item.Properties = [];
      item.BaseType = undefined;
    }
  }
}
