
    import OrderDepartureAppService from "@/apis.machine/OrderDepartureAppService";
    import DtoRequestOrderState from "@/models.machine/DtoRequestOrderState";
    import DtoCallbackOrderState from "@/models.machine/DtoCallbackOrderState";
    import DtoRepuestWarehousing from "@/models.machine/DtoRepuestWarehousing";
    import { Options, Vue } from "vue-class-component";
    import { ElMessage } from "element-plus";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzForm from "@/models/MiezzForm";
    import ParamPortAppService from "@/apis.machine/ParamPortAppService";
    import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService"
    import OrderDepartureWharfAppService from "@/apis.machine/OrderDepartureWharfAppService";
    import DtoPutOrderDepartureWharfWarehousing, {
        DtoPutOrderDepartureWharfWarehousingMiezzFormItems,
        DtoPutOrderDepartureWharfWarehousingRules,
    } from "@/models.machine/DtoPutOrderDepartureWharfWarehousing";
    export default class MiezzWharfWarehousing extends Vue {
        //委托书主键
        @Prop() orderPoolId?: string;
        @Prop() isDetail?: boolean;

        isLoading = false;
        stateRequestParam = new DtoRequestOrderState();
        stateCallbback = new DtoCallbackOrderState();
        visible = true;
        ruleForm: {
            Visible: boolean,
        } = {
                Visible: false,
            };
        bottomButtons?: MiezzButton[] = [
            {
                IsBlue:true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            //{
            //    IsBlue:true,
            //    Code: "export",
            //    Label: "导出",
            //    Type: "primary",
            //    Size: "small",
            //},
            {
                IsBlue:true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];
        //港口
        ports?: UiSelectOption<string>[] = [];
        //渠道
        channels?: UiSelectOption<string>[] = [];
        //仓库
        warehouses?: UiSelectOption<string>[] = [];
        //地址
        addresss?: UiSelectOption<string>[] = [];
        //联系人
        contacters?: UiSelectOption<string>[] = [];
        //工作时间
        workTimes?: UiSelectOption<string>[] = [];

        form = new MiezzForm<DtoPutOrderDepartureWharfWarehousing, string>();
        created(): void {
            this.form.Data = new DtoPutOrderDepartureWharfWarehousing();

            //表单-校验
            this.form.Rules = DtoPutOrderDepartureWharfWarehousingRules;
            //表单-元素配置
            this.form.Items = DtoPutOrderDepartureWharfWarehousingMiezzFormItems;


        }
        ///*打开HsCode对话框 */
        @Emit()
        onInit(id: string): void {
            this.form.Rules.ReceivingDate = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];
            this.form.Rules.ReceivingTime = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];
            this.form.Rules.WarehousingCode = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.CustomsDate = [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            if (this.form.Data.CustomsTime != null && this.form.Data.CustomsTime != undefined && this.form.Data.CustomsTime != "") {
                                callback(new Error("信息不能为空"));
                                return false;
                            }
                        }
                        callback();
                    },
                    trigger: "change",
                },
            ];
            this.form.Rules.CustomsTime = [
                {
                    required: false,
                    validator: (rule: any, value: string, callback: any) => {
                        if (value == null || value == undefined || value == "") {
                            if (this.form.Data.CustomsDate != null && this.form.Data.CustomsDate != undefined) {
                                callback(new Error("信息不能为空"));
                                return false;
                            }
                        }
                        callback();
                    },
                    trigger: "change",
                },
            ];
            this.isLoading = true;
            this.warehouses = [];
            ParamTransportChannelAppService.GetSelect().then((channel) => {
                if (channel.data.Data) this.channels = channel.data.Data;
                ParamPortAppService.GetSelect().then(port => {
                    if (port.data.Data) this.ports = port.data.Data;

                    OrderDepartureWharfAppService.GetWarehousingByOrderDepartureWharfId(id).then((r) => {
                        if (r.data.Data) this.form.Data = r.data.Data;

                        if (this.form && this.form.Data && this.form.Data.SupplierCompanyId) {
                            //this.warehouses
                            OrderDepartureWharfAppService.GetWarehousesBySupplierCompanyId(this.form.Data.SupplierCompanyId).then((s) => {
                                if (s.data.Data) this.warehouses = s.data.Data;
                            })
                        }
                        if (this.form && this.form.Data) {
                            this.workTimes = this.form.Data.WorkTimes;
                            this.contacters = this.form.Data.Contacters;
                            this.addresss = this.form.Data.Addresss;
                        }

                        this.ruleForm.Visible = true;
                        this.isLoading = false;
                        if (this.isDetail == true && this.bottomButtons) {
                            this.bottomButtons[0].Hide = true;
                        }
                        else {
                            this.$nextTick(() => {
                                MiezzForm.clearValidate(this.$refs, "refFormTip", "ReceivingDate");
                                MiezzForm.clearValidate(this.$refs, "refFormTip", "ReceivingTime");
                            })
                        }

                    })
                })
            })


        }

        onDeleteAddress(id: string, index: number): void {
            var value = "";
            if (this.form.Data) {
                value = this.form.Data.Address as string;
            }
            OrderDepartureWharfAppService.RemoveHistoryById(id).then((r) => {
                var oldValue = "";
                if (this.addresss) {
                    oldValue = this.addresss[index].Label as string;
                }

                this.addresss?.splice(index, 1);
                if (this.form.Data) {
                    if (oldValue != value) {
                        this.form.Data.Address = value;
                    }
                    else {
                        this.form.Data.Address = "";
                    }
                }

                if (this.form.Data && this.form.Data.Address == "" && this.addresss && this.addresss.length > 0) {
                    this.form.Data.Address = this.addresss[0].Label;
                }

                ElMessage.success("历史记录已删除");
                return;
            })
        }

        onDeleteWarehouseName(id: string, index: number): void {
            var value = this.form.Data.WarehouseName;
            OrderDepartureWharfAppService.RemoveWarehouseByOrderSupplierWarehouseId(id).then((r) => {
                var oldValue = "";
                if (this.warehouses) {
                    oldValue = this.warehouses[index].Label as string;
                }

                this.warehouses?.splice(index, 1);
                if (oldValue != value) {
                    this.form.Data.WarehouseName = value;
                }
                else {
                    this.form.Data.WarehouseName = "";
                }
                ElMessage.success("历史记录已删除");
                return;
            })

        }



        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":

                    this.stateRequestParam = new DtoRequestOrderState();
                    this.stateRequestParam.OrderId = [];
                    this.stateRequestParam.PoolId = [];
                    if (this.form.Data.PoolId != null && this.form.Data.PoolId != undefined && this.form.Data.PoolId!="") {
                        this.stateRequestParam.PoolId.push(this.form.Data.PoolId as string);
                    }
                    OrderDepartureAppService.GetState(this.stateRequestParam).then((state) => {
                        if (state.data.Data) {
                            this.stateCallbback = state.data.Data;
                            if (this.stateCallbback.OrderCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.PoolCancel == true) {
                                ElMessage.warning("订单已取消");
                                complete();
                                return;
                            }
                            else if (this.stateCallbback.IsLock == true) {
                                ElMessage.warning("订单已锁定");
                                complete();
                                return;
                            }
                            else {

                                MiezzForm.submit(this.$refs, "refForm", (v) => {
                                    MiezzForm.submit(this.$refs, "refFormWarehousing", (v1) => {
                                        MiezzForm.submit(this.$refs, "refFormTip", (v2) => {
                                            if (v == true && v1 == true && v2 == true) {
                                                OrderDepartureWharfAppService.EditWarehousing(this.form.Data)
                                                    .then(() => {
                                                        ElMessage.success("保存成功");
                                                        this.$emit("onInitAnnex");
                                                        this.ruleForm.Visible = false;
                                                        complete();
                                                    })
                                                    .catch(complete);
                                            }
                                            else {
                                                complete();
                                            }
                                        })
                                    });
                                });
                            }
                        }
                    })




                    break;
                case "export":
                    OrderDepartureWharfAppService.ExportWarehousingByOrderDepartureWharfId(this.form.Data.OrderDepartureWharfId)
                        .then((r) => {
                            window.open(process.env.VUE_APP_BASE_URL + r.data.Data, "_blank");
                            complete();
                        })
                        .catch(complete);

                    break;
                case "close":
                    this.ruleForm.Visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        querySearchDispatchPortIdAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    for (var i = 0; i < this.ports.length; i++) {
                        if (i < 10) {
                            results.push(this.ports[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDispatchPortId(item: UiSelectOption<string>) {
            this.form.Data.DispatchPortId = item.Value;
            this.form.Data.DispatchPortName = item.Label;
            MiezzForm.clearValidate(this.$refs, "refForm", "DispatchPortId");
        }
        onChangeDispatchPortId(): void {
            if (this.form.Data.DispatchPortName == undefined || this.form.Data.DispatchPortName == "") {
                this.form.Data.DispatchPortId = undefined;
            }
            else {
                if (this.form.Data.DispatchPortId == undefined) {
                    this.form.Data.DispatchPortName = undefined;
                }
                else if (this.form.Data.DispatchPortId != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Label == this.form.Data.DispatchPortName) {
                                this.form.Data.DispatchPortId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.DispatchPortName = undefined;
                        }
                    }
                }
            }
        }

        querySearchDestinationPortIdAsync(queryString: string, cb: any) {
            var restaurants = this.ports;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.ports) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.ports) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.ports) {
                    for (var i = 0; i < this.ports.length; i++) {
                        if (i < 10) {
                            results.push(this.ports[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectDestinationPortId(item: UiSelectOption<string>) {
            this.form.Data.DestinationPortId = item.Value;
            this.form.Data.DestinationPortName = item.Label;
            MiezzForm.clearValidate(this.$refs, "refForm", "DestinationPortId");
        }
        onChangeDestinationPortId(): void {
            if (this.form.Data.DestinationPortName == undefined || this.form.Data.DestinationPortName == "") {
                this.form.Data.DestinationPortId = undefined;
            }
            else {
                if (this.form.Data.DestinationPortId == undefined) {
                    this.form.Data.DestinationPortName = undefined;
                }
                else if (this.form.Data.DestinationPortId != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Label == this.form.Data.DestinationPortName) {
                                this.form.Data.DestinationPortId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.DestinationPortName = undefined;
                        }
                    }
                }
            }
        }


        querySearchTransportChannelIdAsync(queryString: string, cb: any) {
            var restaurants = this.channels;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.channels) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.channels) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.channels) {
                    for (var i = 0; i < this.channels.length; i++) {
                        if (i < 10) {
                            results.push(this.channels[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectTransportChannelId(item: UiSelectOption<string>) {
            this.form.Data.TransportChannelId = item.Value;
            this.form.Data.TransportChannelName = item.Label;
            MiezzForm.clearValidate(this.$refs, "refForm", "TransportChannelId");
        }
        onChangeTransportChannelId(): void {
            if (this.form.Data.TransportChannelName == undefined || this.form.Data.TransportChannelName == "") {
                this.form.Data.TransportChannelId = undefined;
            }
            else {
                if (this.form.Data.TransportChannelId == undefined) {
                    this.form.Data.TransportChannelName = undefined;
                }
                else if (this.form.Data.TransportChannelId != undefined) {
                    var flag = false;
                    if (this.ports) {
                        for (var it of this.ports) {
                            if (it.Label == this.form.Data.TransportChannelName) {
                                this.form.Data.TransportChannelId = it.Value;
                                flag = true;
                                break;
                            }
                        }
                        if (flag == false) {
                            this.form.Data.TransportChannelName = undefined;
                        }
                    }
                }
            }
        }


        querySearchWorkTimeAsync(queryString: string, cb: any) {
            var restaurants = this.workTimes;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.workTimes) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.workTimes) {
                    if (it && it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.workTimes) {
                    for (var i = 0; i < this.workTimes.length; i++) {
                        if (i < 10) {
                            results.push(this.workTimes[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectWorkTime(item: UiSelectOption<string>) {
            this.form.Data.WorkTime = item.Label + "";
            MiezzForm.clearValidate(this.$refs, "refFormWarehousing", "WorkTime");
        }


        querySearchAddressAsync(queryString: string, cb: any) {
            var restaurants = this.addresss;
            console.log(this.addresss);
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.addresss) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.addresss) {
                    if (it && it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }

                }
            }
            else {
                if (this.addresss) {
                    for (var i = 0; i < this.addresss.length; i++) {
                        if (i < 10) {
                            results.push(this.addresss[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectAddress(item: UiSelectOption<string>) {
            this.form.Data.Address = item.Label + "";
            MiezzForm.clearValidate(this.$refs, "refFormWarehousing", "Address");
        }

        querySearchContacterAsync(queryString: string, cb: any) {
            var restaurants = this.contacters;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.contacters) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.contacters) {
                    if (it && it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.contacters) {
                    for (var i = 0; i < this.contacters.length; i++) {
                        if (i < 10) {
                            results.push(this.contacters[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelectContacter(item: UiSelectOption<string>) {
            this.form.Data.Contacter = item.Label + "";
            MiezzForm.clearValidate(this.$refs, "refFormWarehousing", "Contacter");
        }

        onChangeWarehouseName(): void {
            this.workTimes = [];
            this.addresss = [];
            this.contacters = [];
            if (this.form && this.form.Data && this.form.Data.WarehouseName) {
                var param = new DtoRepuestWarehousing();
                param.SupplierId = this.form.Data.SupplierCompanyId;
                param.Name = this.form.Data.WarehouseName;
                OrderDepartureWharfAppService.GetWarehousContacter(param).then((r) => {
                    if (r.data.Data) {
                        this.contacters = r.data.Data;
                        if (this.contacters) {
                            this.form.Data.Contacter = this.contacters[0].Label;
                        }
                    }
                })
                OrderDepartureWharfAppService.GetWarehousAddress(param).then((r) => {
                    if (r.data.Data) {
                        this.addresss = r.data.Data;
                        if (this.addresss) {
                            this.form.Data.Address = this.addresss[0].Label;
                        }
                    }
                })
                OrderDepartureWharfAppService.GetWarehousWorkTime(param).then((r) => {
                    if (r.data.Data) {
                        this.workTimes = r.data.Data;
                        if (this.workTimes) {
                            this.form.Data.WorkTime = this.workTimes[0].Label;
                        }
                    }
                })

                OrderDepartureWharfAppService.GetWarehousRoad(param).then((r) => {
                    if (r.data.Data) {
                        this.form.Data.Road = r.data.Data;
                    }
                })

                OrderDepartureWharfAppService.GetWarehousRemark(param).then((r) => {
                    if (r.data.Data) {
                        this.form.Data.Remark = r.data.Data;
                    }
                })
            }
        }

        //onChangeWorkTime(): void {
        //    if (this.form.Data.DestinationPortName == undefined || this.form.Data.DestinationPortName == "") {
        //        this.form.Data.DestinationPortId = undefined;
        //    }
        //    else {
        //        if (this.form.Data.DestinationPortId == undefined) {
        //            this.form.Data.DestinationPortName = undefined;
        //        }
        //        else if (this.form.Data.DestinationPortId != undefined) {
        //            var flag = false;
        //            if (this.ports) {
        //                for (var it of this.ports) {
        //                    if (it.Label == this.form.Data.DestinationPortName) {
        //                        this.form.Data.DestinationPortId = it.Value;
        //                        flag = true;
        //                        break;
        //                    }
        //                }
        //                if (flag == false) {
        //                    this.form.Data.DestinationPortName = undefined;
        //                }
        //            }
        //        }
        //    }
        //}

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

    }
