import DtoDetailOrderProcess_Tab from "./DtoDetailOrderProcess_Tab";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumOrderServiceType, EnumOrderServiceTypeOption } from "./EnumOrderServiceType";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderProcess_OrderPoolExpress extends DtoDetailOrderProcess_Tab {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 服务类型
     */
    ServiceType?: EnumOrderServiceType;
    /**
     * 提柜时间
     */
    CarryContainerTime?: Date;
    /**
     * 计划提取时间
     */
    CarryPlanTime?: Date;
    /**
     * 实际提取时间
     */
    AllCarryTime?: Date;
    /**
     * 计划送达时间
     */
    PlanDeliveryTime?: Date;
    /**
     * 实际送达时间
     */
    AllDeliveryTime?: Date;
}

/**
 * -表单校验
 */
export const DtoDetailOrderProcess_OrderPoolExpressRules = {
}

/**
 * -设置项
 */
export const DtoDetailOrderProcess_OrderPoolExpressMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "CarryContainerTime",
    Label: "提柜时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "CarryPlanTime",
    Label: "计划提取时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "AllCarryTime",
    Label: "实际提取时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "PlanDeliveryTime",
    Label: "计划送达时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "AllDeliveryTime",
    Label: "实际送达时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
