import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoPutOrderPoolContainerTrailer_File_OrderPoolContainerTrailerFile from "./DtoPutOrderPoolContainerTrailer_File_OrderPoolContainerTrailerFile";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolContainerTrailer_File extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Files = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 箱号照片
     */
    ContainerNoFile?: string;
    /**
     * 封号照片
     */
    SealNoFile?: string;
    /**
     * 文件
     */
    Files?: DtoPutOrderPoolContainerTrailer_File_OrderPoolContainerTrailerFile[];
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolContainerTrailer_FileRules = {
    ContainerNoFile: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    SealNoFile: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolContainerTrailer_FileMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ContainerNoFile",
    Label: "箱号照片",
    Customize: true,
  },
  {
    Prop: "SealNoFile",
    Label: "封号照片",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "文件",
    Customize: true,
  },
]
