import UiPageList from "./UiPageList";
import DtoListItemOrderDepartureWharfHistory from "./DtoListItemOrderDepartureWharfHistory";

/**
 * 订单/委托书的历史记录分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderDepartureWharfHistory extends UiPageList<DtoListItemOrderDepartureWharfHistory, string> {
    constructor() {
        //无参构造函数
        super()
        this.Total = 0;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 
     */
    Total?: number;
}

/**
 * 订单/委托书的历史记录分页列表-表单校验
 */
export const DtoPageListOrderDepartureWharfHistoryRules = {
}
