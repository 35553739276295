
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import OSS from "ali-oss";
import { ElMessage } from "element-plus";
import { isArray } from "element-plus/es/utils";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzUpload extends Vue {
  @Model() modelValue?: any[] | string;
  @Prop() path?: string;
  @Prop() accept?: string;
  @Prop() preview = false;
  @Prop() width?: string;
  @Prop() height?: string;
  maxSize = 50 * 1024 * 1024;
  @Prop() prop?: string;
  @Prop() tip?: string;
  @Prop() isPhoto = false;
  @Prop() isTip?: boolean;
  @Prop() isSingle?: boolean; /*是否只上传单个 */

  client?: OSS;
  multiple = false;
  files: MiezzFileInfo[] = [];
  form = new MiezzForm<MiezzFileInfo, string>();
  modalForm = new MiezzModal(MiezzModalType.Dialog);
  sizeLabel?: string;
  isLoading = false;
  created(): void {
    this.client = new OSS({
      region: "oss-cn-beijing",
      accessKeyId: "LTAIuFDB1NiUNXPQ",
      accessKeySecret: "ArIn2kiTFr53bdKEVDfuWjTfA65b9C",
      bucket: "test-eqicheng",
      cname: true,
      endpoint: "testfile.eqicheng.cn",
    });

    this.modalForm.Height = "40px";
    this.modalForm.Width = "600px";
    this.modalForm.HandleClick = this.handleClick;
    this.modalForm.Buttons = [
      {
        Code: "rename",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.form.LabelWidth = "50px";
    this.form.Data = new MiezzFileInfo();
    this.form.Rules = [
      {
        Name: [
          {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
          },
        ],
      },
    ];
    this.form.Items = [
      {
        Prop: "Name",
        Label: "名称",
        Type: EnumMiezzFormItemType.InputText,
      },
    ];
    this.onModelValueChanged();
    if (this.maxSize) this.sizeLabel = this.getSize(this.maxSize);
  }

  @Watch("modelValue", {
    deep: true,
  })
  onModelValueChanged(): void {
    this.files = [];
    if (Array.isArray(this.modelValue)) {
      this.multiple = true;
      for (const item of this.modelValue) {
        if (this.prop) {
          this.getFileInfo(item[this.prop]);
        } else {
          this.getFileInfo(item);
        }
      }
    } else {
      this.multiple = false;
      this.getFileInfo(this.modelValue);
    }
    if (this.isSingle) {
      this.multiple = false;
    }
  }

  getFileInfo(path?: string): void {
    if (path) {
      const file = new MiezzFileInfo();
      file.Path = path;
      file.Url = this.client!.signatureUrl(file.Path);
      file.FileName = file.Path.substring(file.Path.lastIndexOf("/") + 1);
      if (file.FileName.indexOf("?") > -1) {
        file.FileName = file.FileName.substring(0, file.FileName.indexOf("?"));
      }
      const spot = file.FileName.lastIndexOf(".");
      file.Name = file.FileName.substring(0, spot);
      file.Suffix = file.FileName.substring(spot + 1);
      this.files.push(file);
    }
  }

  getSize(n: number): string {
    if (n < 1024) {
      return `${n}字节`;
    } else if (n < 1024 * 1024) {
      return `${n / 1024}kb`;
    } else if (n < 1024 * 1024 * 1024) {
      return `${n / 1024 / 1024}mb`;
    } else {
      return `${n / 1024 / 1024 / 1024}gb`;
    }
  }

  beforeUpload(file: any): boolean {
    if (this.maxSize && file.size > this.maxSize) {
      ElMessage.error(`文件大小不能超过${this.sizeLabel}`);
      return false;
    }
    const time = moment().format("YYYY/MM/DD/h/mm/ss");
    const random = Math.random();
    const path = `/files/${this.path}/${time}/${random}/${file.name}`;
    this.isLoading = true;
    this.client?.put(path, file).then((r) => {
      if (Array.isArray(this.modelValue)) {
        const array = this.modelValue.copy();
        if (this.prop) {
          const item: any = {};
          item[this.prop] = path;
          array.push(item);
        } else {
          array.push(path);
        }
        this.isLoading = false;
        this.$emit("update:modelValue", array);
      } else {
        this.isLoading = false;
        this.$emit("update:modelValue", path);
      }
    });
    return false;
  }

  handleRename(item: MiezzFileInfo): void {
    this.modalForm.Title = `文件-${item.FileName} 重命名`;
    this.modalForm.Type = MiezzModalType.Drawer;
    this.modalForm.Show = true;
    this.form.Data = item;
  }

  handleDelete(i: number): void {
    if (Array.isArray(this.modelValue)) {
      let array = this.modelValue.copy();
      array.splice(i, 1);
      this.$emit("update:modelValue", array);
    } else {
      this.$emit("update:modelValue", undefined);
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "rename":
        this.isLoading = true;
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            const path = this.form.Data.Path.substring(
              0,
              this.form.Data.Path.lastIndexOf("/")
            );
            const newValue = `${path}/${this.form.Data.Name}.${this.form.Data.Suffix}`;
            this.client?.copy(
              newValue.substring(1),
              this.form.Data.Path.substring(1)
            );
            this.form.Data.Path = newValue;
            if (Array.isArray(this.modelValue)) {
              let array = [];
              if (this.prop) {
                array = this.files.map((it) => {
                  const item: any = {};
                  item[this.prop as string] = it.Path;
                  return item;
                });
              } else {
                array = this.files.map((it) => it.Path);
              }
              this.$emit("update:modelValue", array);
            } else {
              this.$emit("update:modelValue", this.form.Data.Path);
            }
            this.isLoading = false;
            this.modalForm.Show = false;
            complete();
          } else complete();
        });
        break;
      default:
        break;
    }
  }
}

class MiezzFileInfo {
  /**路径 */
  Path!: string;
  /**地址 */
  Url!: string;
  /**文件名 */
  FileName!: string;
  /**名称 */
  Name!: string;
  /**后缀名 */
  Suffix!: string;
}
