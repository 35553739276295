import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoShipmentIdReferenceId {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * Shipment ID/Reference ID
     */
    ShipmentId?: string;
    /**
     * FBA No.
     */
    ReferenceId?: string;
}

/**
 * -表单校验
 */
export const DtoShipmentIdReferenceIdRules = {
}

/**
 * -设置项
 */
export const DtoShipmentIdReferenceIdMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ShipmentId",
    Label: "Shipment ID/Reference ID",
  },
  {
    Prop: "ReferenceId",
    Label: "FBA No.",
  },
]
