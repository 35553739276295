import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumEquivalentUnit, EnumEquivalentUnitOption } from "./EnumEquivalentUnit";
import UiGroup from "./UiGroup";

/**
 * 箱型表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormParamContainerType extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * ISO
     */
    Iso?: string;
    /**
     * 95码
     */
    Code?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 柜量
     */
    EquivalentUnit?: EnumEquivalentUnit;
}

/**
 * 箱型表单-表单校验
 */
export const DtoFormParamContainerTypeRules = {
    Iso: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
    ],
    NameZh: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    NameEn: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    FullName: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    EquivalentUnit: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 箱型表单-设置项
 */
export const DtoFormParamContainerTypeMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Iso",
    Label: "ISO",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "95码",
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
    Customize: true,
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
    Customize: true,
  },
  {
    Prop: "FullName",
    Label: "全名",
    Customize: true,
  },
  {
    Prop: "EquivalentUnit",
    Label: "柜量",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumEquivalentUnitOption,
  },
]
