import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5d10d28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "empty-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_timeline, { class: "services" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_timeline_item, {
            key: i,
            "hide-timestamp": true,
            placement: "top",
            class: _normalizeClass(`label ${item.Class} ${item.Value == _ctx.service ? 'active' : ''}`),
            onClick: ($event: any) => (_ctx.handleServiceClick(item.Value ?? ''))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(item.Label), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tabs.length > 0)
        ? (_openBlock(), _createBlock(_component_el_tabs, {
            key: 0,
            modelValue: _ctx.tab,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.tab) = $event)),
            type: "card",
            "tab-position": "top",
            onTabClick: _ctx.handleTabClick
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tabItem, tabIndex) => {
                return (_openBlock(), _createBlock(_component_el_tab_pane, {
                  key: tabIndex,
                  label: tabItem.Code,
                  name: tabItem.Id
                }, {
                  default: _withCtx(() => [
                    (_ctx.service == 'DeparturePortBooking')
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 0,
                          modelValue: _ctx.listBooking,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listBooking) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.service == 'DeparturePortContainer' ||
              _ctx.service == 'DestinationPortContainer'
            )
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 1,
                          modelValue: _ctx.listContainer,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listContainer) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.service == 'DeparturePortExpress' ||
              _ctx.service == 'DestinationPortExpress'
            )
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 2,
                          modelValue: _ctx.listExpress,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.listExpress) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.service == 'DeparturePortTrunk' ||
              _ctx.service == 'DestinationPortTrunk'
            )
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 3,
                          modelValue: _ctx.listTrunk,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.listTrunk) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.service == 'DeparturePortDeclareCustoms' ||
              _ctx.service == 'DestinationPortClearCustoms'
            )
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 4,
                          modelValue: _ctx.listCustoms,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.listCustoms) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.service == 'DeparturePortWarehouse' ||
              _ctx.service == 'DestinationPortWarehouse'
            )
                      ? (_openBlock(), _createBlock(_component_miezz_page_list, {
                          key: 5,
                          modelValue: _ctx.listWharf,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.listWharf) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_miezz_button, {
                      model: _ctx.btnDetail,
                      onHandleClick: _ctx.handleClick
                    }, null, 8, ["model", "onHandleClick"]),
                    (_ctx.service == 'DeparturePortBooking' && _ctx.api && _ctx.api.ProcessUrl)
                      ? (_openBlock(), _createElementBlock("iframe", {
                          key: 6,
                          src: _ctx.api.ProcessUrl,
                          style: {"width":"100%","height":"700px"}
                        }, null, 8, _hoisted_2))
                      : _createCommentVNode("", true),
                    (
              (_ctx.service == 'DeparturePortDeclareCustoms' ||
                _ctx.service == 'DestinationPortClearCustoms') &&
              _ctx.api &&
              _ctx.api.CustomsList?.any((it) => it.Id == _ctx.tab)
            )
                      ? (_openBlock(), _createBlock(_component_el_timeline, { key: 7 }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.api.CustomsList.firstOrDefault(
                (it) => it.Id == _ctx.tab
              )?.ProcessList, (item, i) => {
                              return (_openBlock(), _createBlock(_component_el_timeline_item, {
                                key: i,
                                timestamp: item.Date,
                                placement: "top",
                                type: "primary",
                                size: "normal"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.Content), 1)
                                ]),
                                _: 2
                              }, 1032, ["timestamp"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["label", "name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "onTabClick"]))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, "暂无数据"))
    ])
  ]))
}