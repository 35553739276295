import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51b916c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "summary-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, "提单号码：" + _toDisplayString(_ctx.detail?.MasterBillNo), 1),
    _createElementVNode("span", null, "舱单截止时间：" + _toDisplayString(_ctx.formatTime(_ctx.detail?.ManifestEndDate)), 1),
    _createElementVNode("span", null, "截关时间：" + _toDisplayString(_ctx.formatTime(_ctx.detail?.CustomsEndDate)), 1),
    _createElementVNode("span", null, "开港时间：" + _toDisplayString(_ctx.formatTime(_ctx.detail?.OpenPortDate)), 1),
    _createElementVNode("span", null, "截港时间：" + _toDisplayString(_ctx.formatTime(_ctx.detail?.PortEndDate)), 1)
  ]))
}