import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemCompany_JoinCompanyType from "./DtoListItemCompany_JoinCompanyType";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";

/**
 * 获取供应商列表-结果
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_Company {
    constructor() {
        //无参构造函数
        this.JoinCompanyTypes = [];
        this.Open = false;
    }

    [proName: string]: any;
    /**
     * 公司Id
     */
    Id?: string;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 公司身份
     */
    JoinCompanyTypes?: DtoListItemCompany_JoinCompanyType[];
    /**
     * 类型
     */
    Type?: EnumCompanyType;
    /**
     * 开通
     */
    Open?: boolean;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
}

/**
 * 获取供应商列表-结果-表单校验
 */
export const DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_CompanyRules = {
}

/**
 * 获取供应商列表-结果-设置项
 */
export const DtoCallbackJoinCompanyServiceProviderSupplierGetSupplierList_CompanyMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "JoinCompanyTypes",
    Label: "公司身份",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
