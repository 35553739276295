
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import PriceQuotationSheetAppService from "@/apis.machine/PriceQuotationSheetAppService";
import DtoFormPriceQuotationSheet, {
  DtoFormPriceQuotationSheetMiezzFormItems,
} from "@/models.machine/DtoFormPriceQuotationSheet";
import CurrentLogier from "@/apis/CurrentLogier";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import UiSelectOption from "@/models.machine/UiSelectOption";
import JoinCompanyUserAppService from "@/apis.machine/JoinCompanyUserAppService";
import MiezzPageList from "@/models/MiezzPageList";
import DtoFormPriceQuotationSheet_PriceQuotationSheetItem, {
  DtoFormPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems,
} from "@/models.machine/DtoFormPriceQuotationSheet_PriceQuotationSheetItem";
import ParamPriceCostAppService from "@/apis.machine/ParamPriceCostAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import ParamTransportChargeUnitAppService from "@/apis.machine/ParamTransportChargeUnitAppService";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import {
  EnumSailingTimeType,
  EnumSailingTimeTypeOption,
} from "@/models.machine/EnumSailingTimeType";
import { EnumWeek, EnumWeekOption } from "@/models.machine/EnumWeek";
import moment from "moment";
import { EnumPriceQuotationSheetStatus } from "@/models.machine/EnumPriceQuotationSheetStatus";
import PriceServiceAppService from "@/apis.machine/PriceServiceAppService";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import vuedraggable from "vuedraggable";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import DtoDetailPriceQuotationSheetSourceItem, {
  DtoDetailPriceQuotationSheetSourceItemMiezzListItems,
} from "@/models.machine/DtoDetailPriceQuotationSheetSourceItem";
import Logier from "@/models.machine/Logier";
import DtoFormPriceQuotationSheet_TrackRecord, {
  DtoFormPriceQuotationSheet_TrackRecordMiezzListItems,
} from "@/models.machine/DtoFormPriceQuotationSheet_TrackRecord";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import DtoDeleteServiceRemark from "@/models.machine/DtoDeleteServiceRemark";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import PackageEnAppService from "@/apis.machine/PackageEnAppService";
import CompanyContactAppService from "@/apis.machine/CompanyContactAppService";
import DtoFormPriceQuotationSheet_Cargo from "@/models.machine/DtoFormPriceQuotationSheet_Cargo";
import FormCargosModal from "./FormCargosModal.vue";
import FormSchemes from "./FormSchemes.vue";

@Options({
  components: {
    vuedraggable,
    FormCargosModal,
    FormSchemes,
  },
})
export default class PriceQuotationSheetForm extends Vue {
  id?: string;
  logier?: Logier;
  powers: string[] = [];
  menuTab = new MiezzMenuTab();

  form = new MiezzForm<DtoFormPriceQuotationSheet, string>();
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "check",
      Type: "primary",
      Size: "small",
    },
  ];
  deleteItemBtn: MiezzButton = {
    Code: "delete-item",
    Icon: "delete",
    Title: "删除",
    Type: "danger",
    Size: "small",
  };

  addItemBtn: MiezzButton = {
    Code: "add-item",
    Icon: "plus",
    Label: "添加",
    Type: "text",
    Size: "small",
  };
  addTrackBtn: MiezzButton = {
    Code: "add-track",
    Icon: "plus",
    Label: "添加",
    Type: "text",
    Size: "small",
  };
  companies: DtoSelectOptionCompany[] = [];
  createUsers: UiSelectOption<string>[] = [];
  users: UiSelectOption<string>[] = [];
  list = new MiezzPageList<
    any,
    DtoFormPriceQuotationSheet_PriceQuotationSheetItem,
    string
  >();
  totals: { CurrencySystem: string; Amount: number }[] = [];
  listTrack = new MiezzPageList<
    any,
    DtoFormPriceQuotationSheet_TrackRecord,
    string
  >();
  services: UiSelectOption<string>[] = [];
  costs: UiSelectOption<string>[] = [];
  filterCosts: UiSelectOption<string>[] = [];
  currencySystems: UiSelectOption<string>[] = [];
  units: UiSelectOption<string>[] = [];
  serviceRemarks: UiSelectOptionWithStringValue[] = [];
  rules: any = {};
  EnumSailingTimeType = EnumSailingTimeType;
  EnumWeekOption = EnumWeekOption;

  modalImport = new MiezzModal(MiezzModalType.Dialog);
  listImport = new MiezzPageList<
    any,
    DtoDetailPriceQuotationSheetSourceItem,
    string
  >();

  infoCollectors: UiSelectOption<string>[] = [];
  supplierName?: string = "";
  suppliers: DtoSelectOptionCompany[] = [];
  _suppliers: DtoSelectOptionCompany[] = [];
  modalSupplier = new MiezzModal(MiezzModalType.Dialog);
  modalDataSupplier: {
    SupplierName?: string;
    SupplierContacts?: string;
  } = {
    SupplierName: undefined,
    SupplierContacts: undefined,
  };
  btnClearGoodsInfo: MiezzButton = {
    Code: "clear-goods-info",
    Label: "删除",
    Type: "text",
    Size: "small",
  };
  modalFile = new MiezzModal(MiezzModalType.Dialog);
  /**附件-表单 */
  formFile = new MiezzForm<any, string>();

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((powers) => {
      this.powers = powers ?? [];
    });
    this.id = this.$route.params.id as string;
    if (
      this.$route.query.supplierName &&
      this.$route.query.supplierName != "undefined"
    )
      this.supplierName = this.$route.query.supplierName as string;
    this.menuTab.Back = true;
    if (this.id) {
      this.buttons.push({
        Code: "import",
        Title: "导入",
        MiezzIcon: "import",
        Type: "default",
        Size: "small",
      });
      this.menuTab.Url = `/price/quotation-sheet/edit/${this.id}`;
      this.menuTab.Menus = [
        {
          Label: "编辑",
          Url: `/price/quotation-sheet/edit/${this.id}`,
          Power: "PriceManagement_QuotationSheet_Edit",
        },
      ];
    } else {
      this.menuTab.Url = `/price/quotation-sheet/add`;
      this.menuTab.Menus = [
        {
          Label: "添加",
          Url: `/price/quotation-sheet/add`,
          Power: "PriceManagement_QuotationSheet_Add",
        },
      ];
    }
    this.rules.Weight = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.rules.Volume = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.rules.Date = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.rules.Content = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ];
    this.rules.SupplierName = [
      {
        validator: (rule: any, value: string[], callback: any) => {
          if (!value || value.length == 0) {
            return callback(new Error("信息不能为空"));
          } else if (
            this.modalDataSupplier.SupplierName != this.supplierName &&
            this.form.Data.Suppliers?.any(
              (it) => it.SupplierName == this.modalDataSupplier.SupplierName
            )
          ) {
            callback(new Error("信息重复，请重新填写"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.CostId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.rules.SupplierCostId = [
      {
        validator: (rule: any, value: any, callback: any) => {
          const i = rule.field.split(".")[0];
          const j = rule.field.split(".")[1];
          const k = rule.field.split(".")[2];
          value = this.form.Data.Suppliers![i].Items![j][k];
          if (!value) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.CurrencySystemId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
    ];
    this.rules.SupplierCurrencySystemId = [
      {
        validator: (rule: any, value: any, callback: any) => {
          const i = rule.field.split(".")[0];
          const j = rule.field.split(".")[1];
          const k = rule.field.split(".")[2];
          value = this.form.Data.Suppliers![i].Items![j][k];
          if (!value) {
            return callback(new Error("信息不能为空"));
          }
          callback();
        },
        required: true,
        trigger: "change",
      },
    ];
    this.rules.FirstChargingNumber = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.rules.SecondChargingNumber = [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value != undefined && value <= 0) {
            return callback(new Error("仅可录入＞0的数值"));
          }
          callback();
        },
        trigger: "blur",
      },
    ];
    this.form.DefaultSpan = 12;
    //表单-数据-初始化
    const data = new DtoFormPriceQuotationSheet();
    //表单-元素配置
    if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
      data.UiGroups = new DtoFormPriceQuotationSheet().UiGroups;
      this.form.Items = DtoFormPriceQuotationSheetMiezzFormItems.copy();
    } else {
      data.UiGroups = new DtoFormPriceQuotationSheet().UiGroups?.filter(
        (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
      );
      this.form.Items = DtoFormPriceQuotationSheetMiezzFormItems.filter(
        (it) =>
          it.Prop != "TrackRecords" &&
          it.Prop != "InfoCollector" &&
          it.Prop != "Suppliers"
      );
    }
    if (this.id) {
      this.form.Items.unshift({
        Prop: "Code",
        Label: "编号",
        Group: "BasicInfo",
        Type: EnumMiezzFormItemType.Text,
      });
    }
    this.form.Data = data;
    this.handleAddItem();

    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Customer }).then(
      (r) => {
        this.companies = r.data.Data ?? [];
      }
    );
    CompanyAppService.GetSelect({ CompanyType: EnumCompanyType.Supplier }).then(
      (r) => {
        this.suppliers = r.data.Data ?? [];
      }
    );
    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["PriceManagement_QuotationSheet_Edit"],
    }).then((r) => {
      this.infoCollectors = r.data.Data ?? [];
    });
    JoinCompanyUserAppService.GetSelect({
      PowerCodes: ["PriceManagement_QuotationSheet_Add"],
    }).then((r) => {
      this.createUsers = r.data.Data ?? [];
      if (!this.id && !this.form.Data.CreatedCompanyUserId) {
        this.form.Data.CreatedCompanyUserId = this.logier?.CompanyUserId;
        this.form.Data.CreatedUserName = this.logier?.UserName;
        this.form.Data.CreatedPhoneNumber = this.logier?.PhoneNumber;
      }
    });
    PriceServiceAppService.GetSelect().then((r) => {
      this.services = r.data.Data ?? [];
    });
    ParamPriceCostAppService.GetSelect().then((r) => {
      this.costs = r.data.Data ?? [];
      this.filterCosts = JSON.parse(JSON.stringify(this.costs)).slice(0, 10);
    });
    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = (r.data.Data ?? []).map((it) => {
        it.Label = it.Data.Code;
        return it;
      });
    });
    ParamTransportChargeUnitAppService.GetSelect().then((r) => {
      this.units = r.data.Data ?? [];
    });
    OrderPoolAppService.GetServiceRemarks().then((r) => {
      this.serviceRemarks = (r.data.Data ?? []).filter(
        (it) => it.Label != "其他"
      );
    });

    this.list.Fix = false;
    this.list.HideCard = true;
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.EnabledSelect = false;
    this.list.EnabledPagination = false;
    this.list.Items =
      DtoFormPriceQuotationSheet_PriceQuotationSheetItemMiezzListItems;
    this.list.Data = {
      Items: this.form.Data.Items,
    };
    this.list.HandleFormat = (item, row) => {
      if (item.Prop == "Total") return row.Total?.toFixed(2);
      return undefined;
    };
    this.list.RowButtons = [
      {
        Code: "delete-item",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.listTrack.Fix = false;
    this.listTrack.HideCard = true;
    this.listTrack.EnabledDynamicSearch = false;
    this.listTrack.EnabledRadioSearch = false;
    this.listTrack.EnabledSelect = false;
    this.listTrack.EnabledPagination = false;
    this.listTrack.Items = DtoFormPriceQuotationSheet_TrackRecordMiezzListItems;
    this.listTrack.Data = {
      Items: this.form.Data.TrackRecords,
    };
    this.listTrack.RowButtons = [
      {
        Code: "delete-track",
        Icon: "delete",
        Title: "删除",
        Type: "default",
        Size: "small",
      },
    ];

    this.handleGet();

    this.modalImport.Title = "从成本导入报价表";
    this.modalImport.HandleClick = this.handleClick;
    this.modalImport.Buttons = [
      {
        Code: "save-import",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.modalSupplier.Title = "添加供应商";
    this.modalSupplier.Width = "700px";
    this.modalSupplier.Height = "120px";
    this.modalSupplier.HandleClick = this.handleClick;
    this.modalSupplier.Buttons = [
      {
        Code: "add-supplier-submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.listImport.Fix = false;
    this.listImport.HideCard = true;
    this.listImport.EnabledDynamicSearch = false;
    this.listImport.EnabledRadioSearch = false;
    this.listImport.EnabledPagination = false;
    this.listImport.Items =
      DtoDetailPriceQuotationSheetSourceItemMiezzListItems;
    this.listImport.Data = {
      Items: [],
    };
    this.listImport.HandleFormat = (item, row) => {
      switch (item.Prop) {
        case "Cost":
          return row.Cost?.FullName ?? "暂无数据";
        case "CurrencySystem":
          return row.CurrencySystem?.Code ?? "暂无数据";
        case "FirstChargingUnit":
          return row.FirstChargingUnit?.FullName ?? "暂无数据";
        case "SecondChargingUnit":
          return row.SecondChargingUnit?.FullName ?? "暂无数据";
        case "QuotationSheetSupplier":
          return row.QuotationSheetSupplier?.SupplierName ?? "暂无数据";
        default:
          break;
      }
    };

    this.modalFile.Title = "附件";
    this.modalFile.Width = "800px";
    this.modalFile.Buttons = [
      {
        Code: "save-file",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalFile.HandleClick = this.handleClick;
    this.formFile.Items = [
      {
        Prop: "Files",
        Label: "文件",
        Customize: true,
      },
    ];
  }

  handleEditCargos(): void {
    (this.$refs["modal-cargos"] as FormCargosModal).handleShow();
  }

  handleEditFiles(): void {
    this.modalFile.Show = true;
    this.formFile.Data = {
      Files: this.form.Data.Files,
    };
  }

  handleEditSuppliers(supplierName?: string, action?: string): void {
    this.supplierName = supplierName;
    if (action == "add") {
      this.modalDataSupplier.SupplierName = undefined;
      this.modalDataSupplier.SupplierContacts = undefined;
      this._suppliers = this.suppliers.filter((it) =>
        this.form.Data.Suppliers?.all((s) => s.SupplierName != it.Name)
      );
      this.modalSupplier.Title = "添加供应商";
      this.modalSupplier.Show = true;
    } else if (action == "edit") {
      const supplier = this.form.Data.Suppliers?.firstOrDefault(
        (it) => it.SupplierName == this.supplierName
      );
      this.modalDataSupplier.SupplierName = supplier?.SupplierName;
      this.modalDataSupplier.SupplierContacts = supplier?.SupplierContacts;
      this._suppliers = this.suppliers.filter(
        (it) =>
          this.form.Data.Suppliers?.all((s) => s.SupplierName != it.Name) ||
          it.Name == supplierName
      );
      this.modalSupplier.Title = "编辑名称";
      this.modalSupplier.Show = true;
    } else if (action == "delete") {
      let index = this.form.Data.Suppliers?.findIndex(
        (it) => it.SupplierName == supplierName
      );
      if (index != undefined && this.form.Data.Suppliers) {
        this.form.Data.Suppliers?.splice(index, 1);
        setTimeout(() => {
          if (
            index != undefined &&
            this.form.Data.Suppliers &&
            this.form.Data.Suppliers.length != 0
          ) {
            if (index >= 0 && index < this.form.Data.Suppliers.length) {
              this.supplierName = this.form.Data.Suppliers[index].SupplierName;
            } else if (index == this.form.Data.Suppliers.length) {
              this.supplierName =
                this.form.Data.Suppliers[index - 1].SupplierName;
            }
          }
        }, 100);
      }
    }
  }

  /**获取客户企业 */
  getCompanies(q: string, cb: any): void {
    if (q) {
      cb(this.companies.filter((it) => it.Name?.contains(q)));
    } else {
      cb(this.companies);
    }
  }

  handleSelectCompany(item: DtoSelectOptionCompany): void {
    if (item && item.Id && item.Name == this.form.Data.CompanyLabel) {
      this.form.Data.CompanyId = item.Id;
      JoinCompanyUserAppService.GetSelect({
        CompanyId: this.form.Data.CompanyId,
      }).then((r) => {
        this.users = r.data.Data ?? [];
        if (this.users.all((it) => it.Value != this.form.Data.UserId)) {
          this.form.Data.UserId = undefined;
        }
      });
    } else {
      this.form.Data.CompanyId = undefined;
      this.users = [];
      this.form.Data.UserId = undefined;
      this.form.Data.UserLabel = undefined;
    }
  }

  /**获取询盘人 */
  getUsers(q: string, cb: any): void {
    if (q) {
      cb(this.users.filter((it) => it.Label?.contains(q)));
    } else {
      cb(this.users);
    }
  }

  handleSelectUser(item: UiSelectOption<string>): void {
    this.form.Data.UserId = undefined;
    if (item && item.Value && item.Label == this.form.Data.UserLabel) {
      this.form.Data.UserId = item.Value;
    }
  }

  /**获取供应商 */
  getSuppliers(q: string, cb: any): void {
    if (q) {
      cb(this._suppliers.filter((it) => it.Name?.contains(q)));
    } else {
      cb(this._suppliers);
    }
  }

  supplierContacts: UiSelectOption<string>[] = [];
  /**供应商-供应商变更时 */
  handleSelectSupplier(item: DtoSelectOptionCompany): void {
    this.supplierContacts = [];
    this.modalDataSupplier.SupplierContacts = undefined;
    if (item && item.Id && item.Name == this.modalDataSupplier.SupplierName) {
      CompanyContactAppService.GetSelect({
        CompanyId: item.Id,
      }).then((r) => {
        this.supplierContacts = r.data.Data ?? [];
      });
    }
  }

  /**供应商-获取联系人 */
  getSupplierCompanyContact(q: string, cb: any): void {
    cb(this.supplierContacts.filter((it) => it.Label?.contains(q)) ?? []);
  }

  @Watch("form.Data.CreatedCompanyUserId")
  onCreatedCompanyUserIdChanged(): void {
    const createUser = this.createUsers.firstOrDefault(
      (it) => it.Value == this.form.Data.CreatedCompanyUserId
    );
    if (createUser) {
      console.log(createUser);
      this.form.Data.CreatedUserName = createUser?.Data.Name;
      this.form.Data.CreatedPhoneNumber = createUser?.Data.UserPhoneNumber;
      console.log(this.form.Data);
    }
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      PriceQuotationSheetAppService.GetById(this.id).then((rr) => {
        if (rr.data.Data) {
          const data = rr.data.Data;
          if (this.logier?.CompanyType == EnumCompanyType.ServiceProvider) {
            data.UiGroups = new DtoFormPriceQuotationSheet().UiGroups;
          } else {
            data.UiGroups = new DtoFormPriceQuotationSheet().UiGroups?.filter(
              (it) => it.Code != "TrackRecord" && it.Code != "CostRecord"
            );
          }
          this.form.Data = data;
          if (!this.supplierName)
            this.supplierName =
              this.form.Data.Suppliers?.firstOrDefault()?.SupplierName;
          if (rr.data.Data.Status == EnumPriceQuotationSheetStatus.Send) {
            const company = this.form.Items?.firstOrDefault(
              (it) => it.Prop == "CompanyId"
            );
            if (company) {
              company.Disabled = true;
            }
            if (this.form.Data.UserId) {
              const user = this.form.Items?.firstOrDefault(
                (it) => it.Prop == "UserId"
              );
              if (user) {
                user.Disabled = true;
              }
            }
          }

          if (this.list.Data) this.list.Data.Items = this.form.Data.Items;
          if (this.listTrack.Data)
            this.listTrack.Data.Items = this.form.Data.TrackRecords;
          this.handleCalc();
          this.handleSupplierCalc();
          (this.$refs["ref-form-schemes"] as any).handleInit();
        }
      });
    } else {
      this.$nextTick(() => {
        (this.$refs["ref-form-schemes"] as any).handleInit();
      });
    }
  }

  handleCalc(): void {
    this.$nextTick(() => {
      this.totals = [];
      for (const item of this.list.Data?.Items ?? []) {
        item.Total = undefined;
        if (
          item.Amount != undefined &&
          (item.FirstChargingNumber != undefined ||
            item.SecondChargingNumber != undefined)
        ) {
          if (
            item.FirstChargingNumber != undefined &&
            item.SecondChargingNumber != undefined
          ) {
            item.Total =
              item.Amount *
              item.FirstChargingNumber *
              item.SecondChargingNumber;
          } else if (item.FirstChargingNumber != undefined) {
            item.Total = item.Amount * item.FirstChargingNumber;
          } else if (item.SecondChargingNumber != undefined) {
            item.Total = item.Amount * item.SecondChargingNumber;
          }
        }
        if (item.Total) {
          let total = this.totals.firstOrDefault(
            (it) => it.CurrencySystem == item.CurrencySystem.Code
          );
          if (total) {
            total.Amount += item.Total;
          } else {
            total = {
              CurrencySystem: item.CurrencySystem.Code,
              Amount: item.Total,
            };
            this.totals.push(total);
          }
        }
      }
    });
  }

  handleSupplierCalc(): void {
    this.$nextTick(() => {
      for (const supplier of this.form.Data.Suppliers ?? []) {
        supplier.Totals = [];
        for (const item of supplier.Items ?? []) {
          item.Total = undefined;
          if (
            item.Amount != undefined &&
            (item.FirstChargingNumber != undefined ||
              item.SecondChargingNumber != undefined)
          ) {
            if (
              item.FirstChargingNumber != undefined &&
              item.SecondChargingNumber != undefined
            ) {
              item.Total =
                item.Amount *
                item.FirstChargingNumber *
                item.SecondChargingNumber;
            } else if (item.FirstChargingNumber != undefined) {
              item.Total = item.Amount * item.FirstChargingNumber;
            } else if (item.SecondChargingNumber != undefined) {
              item.Total = item.Amount * item.SecondChargingNumber;
            }
          }
          if (item.Total) {
            let total = supplier.Totals.firstOrDefault(
              (it: any) => it.CurrencySystem == item.CurrencySystem.Code
            );
            if (total) {
              total.Amount += item.Total;
            } else {
              total = {
                CurrencySystem: item.CurrencySystem.Code,
                Amount: item.Total,
              };
              supplier.Totals.push(total);
            }
          }
        }
      }
    });
  }

  handleDeleteSupplierItem(
    btn: MiezzButton,
    supplier: any,
    index: number,
    complete: () => void
  ): void {
    supplier.Items?.splice(index, 1);
    this.handleSupplierCalc();
    complete();
  }

  handleAddSupplierItem(
    btn: MiezzButton,
    supplier: any,
    complete: () => void
  ): void {
    const item = new DtoFormPriceQuotationSheet_PriceQuotationSheetItem();
    item.Cost = {};
    item.CurrencySystem = {};
    supplier.Items?.push(item);
    complete();
  }

  handleRowButtonClick(btn: MiezzButton, row: any, complete: () => void): void {
    switch (btn.Code) {
      case "delete-item":
        this.form.Data.Items?.splice(this.form.Data.Items.indexOf(row), 1);
        this.handleCalc();
        complete();
        break;
      case "delete-track":
        this.form.Data.TrackRecords?.splice(
          this.form.Data.TrackRecords.indexOf(row),
          1
        );
        complete();
        break;
      default:
        break;
    }
  }

  handleAddItem(): void {
    const item = new DtoFormPriceQuotationSheet_PriceQuotationSheetItem();
    item.Cost = {};
    item.CurrencySystem = {};
    this.form.Data.Items?.push(item);
  }

  filterMethod(queryString: string): void {
    if (this.costs && this.costs.length > 0) {
      if (queryString) {
        this.filterCosts = this.costs.filter((it) =>
          it.Label?.contains(queryString)
        );
        if (this.filterCosts && this.filterCosts.length > 10)
          this.filterCosts = this.filterCosts.slice(0, 10);
      } else {
        this.filterCosts = JSON.parse(JSON.stringify(this.costs)).slice(0, 10);
      }
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "save-file":
        MiezzForm.submit(this.$refs, "ref-form-file", (v) => {
          if (v) {
            this.form.Data.Files = this.formFile.Data.Files;
            this.modalFile.Show = false;
            complete();
          } else {
            complete();
          }
        });
        break;
      case "clear-goods-info":
        this.form.Data.Weight = undefined;
        this.form.Data.Volume = undefined;
        this.form.Data.PackagesNumber = undefined;
        this.form.Data.PackagesKind = undefined;
        complete();
        break;
      case "add-supplier-submit":
        (this.$refs["ruleFormSupplier"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document
                .getElementsByClassName("rule-form-supplier")[0]
                .getElementsByClassName("el-table__body")[0]
                .getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            const supplier = this.form.Data.Suppliers?.firstOrDefault(
              (it) => it.SupplierName == this.supplierName
            );
            if (supplier) {
              supplier.SupplierName = this.modalDataSupplier.SupplierName;
              supplier.SupplierContacts =
                this.modalDataSupplier.SupplierContacts;
              this.supplierName = supplier.SupplierName;
            } else {
              this.form.Data.Suppliers?.push({
                SupplierName: this.modalDataSupplier.SupplierName,
                SupplierContacts: this.modalDataSupplier.SupplierContacts,
                Items: [],
                Totals: [],
                Files: [],
              });
              this.supplierName = this.modalDataSupplier.SupplierName;
            }
            this.modalSupplier.Show = false;
            complete();
          }
        });
        complete();
        break;
      case "import":
        if (this.listImport.Data?.Items) {
          this.listImport.Data.Items = [];
          for (const supplier of this.form.Data.Suppliers ?? []) {
            for (const item of supplier.Items ?? []) {
              this.listImport.Data.Items.push({
                CostId: item.CostId,
                Cost: item.CostId
                  ? {
                      FullName: this.costs.firstOrDefault(
                        (it) => it.Value == item.CostId
                      )?.Label,
                    }
                  : undefined,
                CurrencySystemId: item.CurrencySystemId,
                CurrencySystem: item.CurrencySystemId
                  ? {
                      Code: this.currencySystems.firstOrDefault(
                        (it) => it.Value == item.CurrencySystemId
                      )?.Label,
                    }
                  : undefined,
                Amount: item.Amount,
                AmountLabel: item.Amount?.toString(),
                FirstChargingUnitId: item.FirstChargingUnitId,
                FirstChargingUnit: item.FirstChargingUnitId
                  ? {
                      FullName: this.units.firstOrDefault(
                        (it) => it.Value == item.FirstChargingUnitId
                      )?.Label,
                    }
                  : undefined,
                FirstChargingNumber: item.FirstChargingNumber,
                Total: item.Total,
                Description: item.Description,
                QuotationSheetSupplier: {
                  SupplierName: supplier.SupplierName,
                },
              });
              this.modalImport.Show = true;
            }
          }
        }
        complete();
        break;
      case "save-import":
        if (this.listImport.SelectRows.length > 0) {
          (this.$refs["ref-form-schemes"] as any).handleImport(
            this.listImport.SelectRows ?? []
          );
          this.modalImport.Show = false;
        } else {
          ElMessage.warning("请至少选择一条信息");
        }
        complete();
        break;
      case "add-item":
        this.handleAddItem();
        complete();
        break;
      case "add-track":
        this.form.Data.TrackRecords?.push({});
        complete();
        break;
      case "submit":
        MiezzForm.submit(this.$refs, "miezz-form", (v) => {
          if (v) {
            for (const scheme of this.form.Data.Schemes ?? []) {
              for (const item of scheme.FieldValues ?? []) {
                switch (item.Code) {
                  case "Voyage":
                    if (item.Voyage && item.VoyageUnit) {
                      item.Label = `${item.Voyage ?? ""}${
                        item.VoyageUnitLabel
                      }`;
                    }
                    break;
                  case "SailingDate":
                    if (item.SailingTimeType) {
                      if (item.SailingTimeType == EnumSailingTimeType.Week) {
                        item.SailingDate = undefined;
                        item.SailingWeeks = item.SailingWeekArray.map(
                          (it: EnumWeek) => {
                            const week = item.SailingWeeks?.firstOrDefault(
                              (w: any) => w.SailingWeek == it
                            );
                            return week ? week : { SailingWeek: it };
                          }
                        );
                        item.Label = item.SailingWeekArray.map(
                          (it: EnumWeek) => {
                            return EnumWeekOption.firstOrDefault(
                              (o) => o.Value == it
                            )?.Label;
                          }
                        ).join("/");
                      } else {
                        item.SailingWeeks = [];
                        item.SailingWeekArray = [];
                        item.Label = moment(item.SailingDate).format(
                          "YYYY/MM/DD"
                        );
                      }
                    }
                    break;
                  case "Remark":
                    item.Label = item.Remark;
                    break;
                  default:
                    break;
                }
              }
            }
            PriceQuotationSheetAppService.Post(this.form.Data)
              .then(() => {
                CurrentLogier.Back();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }
}
