import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";
import DtoFormRole_JoinCompanyTypeRole from "./DtoFormRole_JoinCompanyTypeRole";
import DtoFormRole_JoinRolePower from "./DtoFormRole_JoinRolePower";
import UiGroup from "./UiGroup";

/**
 * 角色表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormRole extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.MinLevel = 0;
        this.JoinCompanyTypeRoles = [];
        this.Official = false;
        this.IsTest = false;
        this.JoinRolePowers = [];
        this.PowerIds = [];
        this.Disabled = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 最低级别
     */
    MinLevel?: number;
    /**
     * 公司类型
     */
    Type?: EnumCompanyType;
    /**
     * 公司身份
     */
    JoinCompanyTypeRoles?: DtoFormRole_JoinCompanyTypeRole[];
    /**
     * 官方
     */
    Official?: boolean;
    /**
     * 测试
     */
    IsTest?: boolean;
    /**
     * 权限
     */
    JoinRolePowers?: DtoFormRole_JoinRolePower[];
    /**
     * 权限Id
     */
    PowerIds?: string[];
    /**
     * 禁用
     */
    Disabled?: boolean;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 角色表单-表单校验
 */
export const DtoFormRoleRules = {
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    MinLevel: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Type: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    JoinCompanyTypeRoles: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
            type: "array",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 角色表单-设置项
 */
export const DtoFormRoleMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "MinLevel",
    Label: "最低级别",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Type",
    Label: "公司类型",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumCompanyTypeOption,
  },
  {
    Prop: "JoinCompanyTypeRoles",
    Label: "公司身份",
    Customize: true,
  },
  {
    Prop: "Official",
    Label: "官方",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "IsTest",
    Label: "测试",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "JoinRolePowers",
    Label: "权限",
    Customize: true,
  },
  {
    Prop: "Disabled",
    Label: "禁用",
    Type: EnumMiezzFormItemType.Switch,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
