import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bca2abce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"width":"20% !important","text-align":"left"} }
const _hoisted_2 = { style: {"width":"20% !important","text-align":"left"} }
const _hoisted_3 = { style: {"width":"20% !important","text-align":"left"} }
const _hoisted_4 = { style: {"width":"5% !important","text-align":"left"} }
const _hoisted_5 = { style: {"width":"15% !important","text-align":"left"} }
const _hoisted_6 = { style: {"float":"left"} }
const _hoisted_7 = { style: {"float":"left","margin-left":"10px"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_menu = _resolveComponent("product-menu")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_product_menu),
    _createVNode(_component_miezz_form, {
      ref: "ruleForm",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
      fix: true
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'Code')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "产品编号"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.id), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (item.Prop == 'Code')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.Code), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'NameZh')
          ? (_openBlock(), _createBlock(_component_el_form_item, { key: 2 }, {
              label: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(item.Label), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.form.Data.NameZh), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.ZhAliass, (p, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    style: {"width":"20% !important"},
                    key: index
                  }, [
                    _createVNode(_component_el_form_item, {
                      style: {"padding":"0px","margin":"0px","width":"100% !important"},
                      class: "inputAliasName"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          style: _normalizeStyle([{"float":"left"}, (index%5==3 || index%5==8)?'width:100% !important;':'width:99% !important;'])
                        }, _toDisplayString(p.Name), 5)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (item.Prop == 'NameEn')
          ? (_openBlock(), _createBlock(_component_el_form_item, { key: 3 }, {
              label: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(item.Label), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_el_form_item, {
                    prop: item.Prop,
                    style: {"padding":"0px","margin":"0px","width":"100% !important"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.form.Data.NameEn), 1)
                    ]),
                    _: 2
                  }, 1032, ["prop"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.EnAliass, (p, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    style: {"width":"20% !important"},
                    key: index
                  }, [
                    _createVNode(_component_el_form_item, { style: {"padding":"0px","margin":"0px","width":"100% !important"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          style: {"padding":"0px","margin":"0px","width":"100% !important"},
                          class: "inputAliasName"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              style: _normalizeStyle([{"float":"left"}, (index%5==3 || index%5==8)?'width:100% !important;':'width:99% !important;'])
                            }, _toDisplayString(p.Name), 5)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (item.Prop == 'NameLatin')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 4,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_component_el_form_item, {
                    prop: item.Prop,
                    style: {"padding":"0px","margin":"0px","width":"100% !important"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.form.Data.NameLatin), 1)
                    ]),
                    _: 2
                  }, 1032, ["prop"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.LatinAliass, (p, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    style: {"width":"20% !important"},
                    key: index
                  }, [
                    _createVNode(_component_el_form_item, { style: {"padding":"0px","margin":"0px","width":"100% !important"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          style: {"padding":"0px","margin":"0px","width":"100% !important"},
                          class: "inputAliasName"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              style: _normalizeStyle([{"float":"left"}, (index%5==3 || index%5==8)?'width:100% !important;':'width:99% !important;'])
                            }, _toDisplayString(p.Name), 5)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'HsCode')
          ? (_openBlock(), _createBlock(_component_el_form_item, { key: 5 }, {
              label: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(item.Label), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_el_form_item, { style: {"padding":"0px","margin":"0px","width":"100% !important","padding-left":"0.3rem"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 中国 ")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("span", _hoisted_5, [
                  _createVNode(_component_el_form_item, {
                    prop: item.Prop,
                    style: {"padding":"0px","margin":"0px","width":"100% !important"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.form.Data.HsCode), 1)
                    ]),
                    _: 2
                  }, 1032, ["prop"])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.Data.CustomsCodes, (p, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    style: {"width":"20% !important"},
                    key: index
                  }, [
                    _createVNode(_component_el_form_item, { style: {"padding":"0px","margin":"0px","width":"100% !important"} }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(p.CountryName), 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(p.HsCode), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        (item.Prop == 'ChargeUnithZh')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 6,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.ChargeUnithZh), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'ChargeUnithEn')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 7,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.ChargeUnithEn), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'IfShelf')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 8,
              label: item.Label
            }, {
              default: _withCtx(() => [
                (_ctx.form.Data.IfShelf==true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, "上架"))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, "下架"))
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'StockQuantity')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 9,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.StockQuantity), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'SalesQuantity')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 10,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.SalesQuantity), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true),
        (item.Prop == 'ReceiptQuantity')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 11,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.ReceiptQuantity), 1)
              ]),
              _: 2
            }, 1032, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.bottomButtons,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bottomButtons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"])
  ], 64))
}