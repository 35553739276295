
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
export default class OrderDetailCompanyBusinessCard extends Vue {
  @Model() form!: MiezzForm<
    {
      ShipperContent?: string;
      ConsigneeContent?: string;
      NotifyPartyContent?: string;
    },
    string
  >;
}
