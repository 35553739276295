import PagedList from "./PagedList";

/**
 * 分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class UiPageList<T, TKey> extends PagedList<T> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
}

/**
 * 分页列表-表单校验
 */
export const UiPageListRules = {
}
