
import DtoListItemCompanyAuth, {
  DtoListItemCompanyAuthMiezzListItems,
} from "@/models.machine/DtoListItemCompanyAuth";
import DtoPageListSearchCompanyAuth from "@/models.machine/DtoPageListSearchCompanyAuth";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import MiezzPageList from "@/models/MiezzPageList";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CompanyAuthPageList from "../CompanyAuthPageList.vue";
@Options({
  components: {
    CompanyAuthPageList,
  },
})
export default class CustomerCompanyAuthPageList extends Vue {
  type = EnumCompanyType.Customer;
  list = new MiezzPageList<
    DtoPageListSearchCompanyAuth,
    DtoListItemCompanyAuth,
    string
  >("CustomerManagement_CompanyAuth");

  created(): void {
    //列表-传参
    this.list.Dto = new DtoPageListSearchCompanyAuth();
    this.list.Dto.Type = this.type;
    this.list.Items = DtoListItemCompanyAuthMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [
      {
        Code: "audit",
        Title: "审核",
        Icon: "more",
        Type: "default",
        Size: "small",
        PowerCode: "CustomerManagement_CompanyAuth_Audit",
      },
    ];
  }
}
