
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import ParamListAppService from "@/apis.machine/ParamListAppService";
import DtoFormParamList, {
  DtoFormParamListMiezzFormItems,
  DtoFormParamListRules,
} from "@/models.machine/DtoFormParamList";

export default class ParamListForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
    isLoading = false;
  form = new MiezzForm<DtoFormParamList, string>();

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamList();
    //表单-校验
    this.form.Rules = DtoFormParamListRules;
    //表单-元素配置
    this.form.Items = DtoFormParamListMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      ParamListAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
            if (v) {
                this.isLoading = true;
            ParamListAppService.Post(this.form.Data)
              .then(() => {
                  this.handleSubmit();
                  this.isLoading = false;
                complete();

              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
