import UiSelectOption from "./UiSelectOption";

/**
 * 入货状态
 * @description 自动生成的代码,请勿修改
 */
export enum EnumIncomingGoodsStatus {
    /**
     * 待入库
     */
    WaitIn = 1,
    /**
     * 入库中
     */
    IngIn = 2,
    /**
     * 已入库
     */
    EdIn = 3,
    /**
     * 出库中
     */
    IngOut = 4,
    /**
     * 已出库
     */
    EdOut = 5,
}

/**
 * 入货状态-选项
 */
export const EnumIncomingGoodsStatusOption: UiSelectOption<number>[] = [
    { Label: "待入库", Value: 1, Data: "WaitIn" },
    { Label: "入库中", Value: 2, Data: "IngIn" },
    { Label: "已入库", Value: 3, Data: "EdIn" },
    { Label: "出库中", Value: 4, Data: "IngOut" },
    { Label: "已出库", Value: 5, Data: "EdOut" },
];
