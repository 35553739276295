
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import MiezzModal from "@/models/MiezzModal";
    import ProductSpecElementAppService from "@/apis.machine/ProductSpecElementAppService";
    import DtoFormProductSpecElement, {
        DtoFormProductSpecElementMiezzFormItems,
        DtoFormProductSpecElementRules,
    } from "@/models.machine/DtoFormProductSpecElement";

    export default class ProductSpecElementForm extends Vue {
        @Model() modelValue!: MiezzModal;
        @Prop() id?: string;
        form = new MiezzForm<DtoFormProductSpecElement, string>();


        created(): void {

            //表单-数据-初始化
            this.form.Data = new DtoFormProductSpecElement();
            //表单-校验
            this.form.Rules = DtoFormProductSpecElementRules;
            //表单-元素配置
            this.form.Items = DtoFormProductSpecElementMiezzFormItems;

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}

            this.modelValue.HandleClick = this.handleClick;
            this.modelValue.Buttons = [
                {
                    Code: "submit",
                    Label: "保存",
                    Type: "primary",
                    Size: "small",
                },
                {
                    Code: "continue",
                    Label: "继续添加",
                    Type: "primary",
                    Size: "small",
                    Hide: true,
                },
                {
                    Code: "reset",
                    Label: "重置",
                    Type: "primary",
                    Size: "small",
                },
                //{
                //    Code: "close",
                //    Label: "关闭",
                //    Type: "default",
                //    Size: "small",
                //},
            ];

            this.handleGet();
            this.onRule();
        }

        /**查询表单数据 */
        handleGet(): void {
            if (this.id) {
                ProductSpecElementAppService.GetById(this.id).then((r) => {
                    if (r.data.Data) this.form.Data = r.data.Data;
                    if (this.modelValue.Buttons != null) {
                        this.modelValue.Buttons[1].Hide = true;
                    }
                });
            }
            else {
                if (this.modelValue.Buttons != null) {
                    this.modelValue.Buttons[1].Hide = false;
                }
                if (this.form.Data.ProductSpecElementItems == null || typeof (this.form.Data.ProductSpecElementItems) == "undefined" || this.form.Data.ProductSpecElementItems.length <= 0) {
                    //this.onAddElement();
                }
                //else {
                //    //this.form.Data.ProductSpecElementItems = [];
                //    //this.form.Data.ProductSpecElementItems.push({
                //    //    Name: "",
                //    //});
                //    //console.log(this.form.Data.ProductSpecElementItems);

                //    //this.form.Data.ProductSpecElementItems.splice(1, this.form.Data.ProductSpecElementItems.length - 1);

                //    if (this.form.Data.ProductSpecElementItems.length > 1) {
                //        //this.form.Data.ProductSpecElementItems = [];
                //        //this.form.Data.ProductSpecElementItems.push({
                //        //    Name: "",
                //        //});


                //        //for (var i = 1; i < this.form.Data.ProductSpecElementItems.length; i++) {
                //        //    this.form.Data.ProductSpecElementItems.splice(i, 1);
                //        //    console.log(i);
                //        //}
                //        var number = this.form.Data.ProductSpecElementItems.length - 1;
                //        console.log(number);
                //        this.form.Data.ProductSpecElementItems.splice(1, number);
                //        console.log(this.form.Data.ProductSpecElementItems);
                //    }

                //}
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    this.form.Data.ProductId = this.$route.params.id as string;
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            ProductSpecElementAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.handleSubmit();
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "continue":
                    this.form.Data.ProductId = this.$route.params.id as string;
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            ProductSpecElementAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.form.Data = new DtoFormProductSpecElement();
                                    MiezzForm.resetFields(this.$refs, "ruleForm");
                                    this.handleSubmit();
                                    this.modelValue.Show = true;
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "reset":
                    this.handleGet();
                    MiezzForm.resetFields(this.$refs, "ruleForm");
                    complete();
                    break;
                case "close":
                    this.modelValue.Show = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //

        }

        onRule(): void {
            this.form.Rules.Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {

                        if (this.form.Data.Name != null && typeof (this.form.Data.Name) != "undefined" && this.form.Data.Name != "undefined" && this.form.Data.Name != "") {
                            var param = {
                                Name: value,
                                ProductId: this.$route.params.id as string,
                                SpecElementId:this.id,
                            }
                            ProductSpecElementAppService.IsSameElement(param).then((r) => {
                                if (r.data.Data == true) {
                                    callback(new Error("信息重复，请重新填写"));
                                    return false;
                                }
                                else {
                                    return callback();
                                }
                            });
                        }
                        else {
                            return callback();
                        }
                    },
                    trigger: "blur",
                },
            ];


            this.form.Rules.ProductSpecElementItems_Name = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        //console.log(rule);
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var number = Number(fields[1]);
                        var i = 0;
                        if (this.form.Data.ProductSpecElementItems != undefined) {
                            for (i = 0; i < this.form.Data.ProductSpecElementItems.length; i++) {
                                if (i != number) {
                                    var name = this.form.Data.ProductSpecElementItems[i].Name as string;
                                    if (isSame(name, value) == true) {
                                        callback("子要素名称不能重复");
                                        return false;
                                    }
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                }
            ];
        }
        onDeleteElement(index: number): void {
            if (this.form.Data.ProductSpecElementItems == null || this.form.Data.ProductSpecElementItems == undefined) {
                this.form.Data.ProductSpecElementItems = [];
            }
            this.form.Data.ProductSpecElementItems.splice(index, 1);

            MiezzForm.clearValidate(this.$refs, "ruleForm", undefined);
            MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                console.log(v);
            });
        }
        onAddElement(): void {
            if (this.form.Data.ProductSpecElementItems == null || typeof (this.form.Data.ProductSpecElementItems) == "undefined") {
                this.form.Data.ProductSpecElementItems = [];
            }

            this.form.Data.ProductSpecElementItems.push({
                Name: "",
            });

        }
    }

    function isSame(val1: string, val2: string) {
        if (trim(val1).toUpperCase() == trim(val2).toUpperCase()) {
            return true;
        }
        return false;
    }

    function trim(str: string) {
        return str.replace(/(^\s*)|\s*$/g, '');
    }
