import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleGet: _ctx.handleGet,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick
  }, null, 8, ["modelValue", "onHandleGet", "onHandleBottomButtonClick"]))
}