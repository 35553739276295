
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamCompanyCodeTypeAppService from "@/apis.machine/ParamCompanyCodeTypeAppService";
import DtoFormParamCompanyCodeType, {
  DtoFormParamCompanyCodeTypeMiezzFormItems,
  DtoFormParamCompanyCodeTypeRules,
} from "@/models.machine/DtoFormParamCompanyCodeType";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzModal from "@/models/MiezzModal";
import DtoListItemParamCompanyCodeType_Repeat from "@/models.machine/DtoListItemParamCompanyCodeType_Repeat";

export default class ParamCompanyCodeTypeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamCompanyCodeType, string>(
    "System_ParamList_CompanyCodeType_AddEdit"
  );
  countries?: UiSelectOption<string>[] = [];
  optFullName?: Array<any> = [];
  optItemListRepeat: Array<DtoListItemParamCompanyCodeType_Repeat> = [];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamCompanyCodeType();
    //表单-校验
    this.form.Rules = DtoFormParamCompanyCodeTypeRules;
    this.form.Rules.ParamCountryId = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "change",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != ""
          && this.form.Data.CompanyCode != null && typeof (this.form.Data.CompanyCode) != "undefined" && this.form.Data.CompanyCode != "undefined" && this.form.Data.CompanyCode != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var CompanyCode = this.form.Data.CompanyCode.trim();
            if (this.optItemListRepeat.some(it => it.ParamCountryId == ParamCountryId && it.CompanyCode == CompanyCode)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "change",
      }
    ]
    this.form.Rules.CompanyCode = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.ParamCountryId != null && typeof (this.form.Data.ParamCountryId) != "undefined" && this.form.Data.ParamCountryId != "undefined" && this.form.Data.ParamCountryId != ""
          && this.form.Data.CompanyCode != null && typeof (this.form.Data.CompanyCode) != "undefined" && this.form.Data.CompanyCode != "undefined" && this.form.Data.CompanyCode != "") {
            var ParamCountryId = this.form.Data.ParamCountryId;
            var CompanyCode = this.form.Data.CompanyCode.trim();
            if (this.optItemListRepeat.some(it => it.ParamCountryId == ParamCountryId && it.CompanyCode == CompanyCode)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      }
    ]
    //表单-元素配置
    this.form.Items = DtoFormParamCompanyCodeTypeMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    ParamCountryAppService.GetSelectNotCode().then((r) => {
      this.countries = r.data.Data;
    });

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamCompanyCodeTypeAppService.GetList().then((r1) => {
      if (r1.data.Data) this.optItemListRepeat = r1.data.Data;
      
      if (this.id) {
        ParamCompanyCodeTypeAppService.GetById(this.id).then((r) => {
          if (r.data.Data) this.form.Data = r.data.Data;
          
          var index = this.optItemListRepeat.findIndex(it => { return it.ParamCountryId == this.form.Data.ParamCountryId && it.CompanyCode == this.form.Data.CompanyCode });
          this.optItemListRepeat.splice(index, 1);
        });
      } else {
        this.form.Data = new DtoFormParamCompanyCodeType();
      }
    })
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            ParamCompanyCodeTypeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  onBlur(): void {
    this.optFullName = [];
    if (this.form.Data.ParamCountryId) {
      this.optFullName?.push(this.countries?.find(x => x.Value == this.form.Data.ParamCountryId)?.Label);
    }
    if (this.form.Data.CountryCode) {
      this.optFullName?.push(this.form.Data.CountryCode);
    }
    if (this.form.Data.CompanyCode) {
      this.optFullName?.push(this.form.Data.CompanyCode);
    }
  
    
    this.form.Data.FullName = this.optFullName.join("/");
  }

  onChangeParamCountryId(): void {
    this.onBlur();
  }

  onBlurCountryCode(): void {
    if (this.form.Data.CountryCode) {
      this.form.Data.CountryCode = this.form.Data.CountryCode.toUpperCase();
    }
    this.onBlur();
  }

  onBlurCompanyCode(): void {
    if (this.form.Data.CompanyCode) {
      this.form.Data.CompanyCode = this.form.Data.CompanyCode.toUpperCase();
    }
    this.onBlur();
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
