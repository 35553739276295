
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import PackageAppService from "@/apis.machine/PackageAppService";
    import DtoFormPackage, {
        DtoFormPackageMiezzFormItems,
        DtoFormPackageRules,
    } from "@/models.machine/DtoFormPackage";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import PackageEnHistoryAppService from "@/apis.machine/PackageEnHistoryAppService";
    import PackageZhAppService from "@/apis.machine/PackageZhAppService";
    import PackageEnAppService from "@/apis.machine/PackageEnAppService";
    import SpecAppService from "@/apis.machine/SpecAppService";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
    import { he } from "element-plus/es/locale";
    export default class PackageForm extends Vue {
        @Model() modelValue!: MiezzModal;
        form = new MiezzForm<DtoFormPackage, string>();

        preSelectPackageEn?: string;
        modalForm = new MiezzModal(MiezzModalType.Dialog);

        /*如果是空的，表示是从包装列表中进入，查询所有产品 */
        productId = "";
        /*不为空表示是从包装列表中点击进入*/
        /*10表示非混装 */
        /*20表示混装 */
        type = "";
        /*产品包装主键 */
        id = "";
        products?: UiSelectOption<string>[] = [];
        packages?: UiSelectOption<string>[] = [];
        packageEns?: UiSelectOption<string>[] = [];
        packageEnHistorys?: UiSelectOption<string>[] = [];
        specs?: UiSelectOption<string>[] = [];

        isSelected = false;
        random = 0;
        product?: UiSelectOption<string>;
        num?: number;
        isModalClose = 1;
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "default",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "保存",
                Icon: "select",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "continue",
                Label: "继续添加",
                Icon: "plus",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                Code: "reset",
                Label: "重置",
                Icon: "refresh",
                Type: "primary",
                Size: "small",
            },
        ];
        onChangeSpecAll(): void {

            if (this.isSelected == true) {
                if (this.specs && this.specs.length > 0) {
                    for (var it of this.specs) {
                        it.IsSelected = true;
                    }
                }
            }
            else if (this.isSelected == false) {
                if (this.specs && this.specs.length > 0) {
                    for (var it1 of this.specs) {
                        it1.IsSelected = false;
                    }
                }
            }

            if (this.form.Data.PackageShapes?.length == 1) {
                if (this.specs && this.specs.length > 0) {
                    for (var it2 of this.specs) {
                        if (it2.IsSelected == true) {
                            if (it2.Quantity == null || it2.Quantity == undefined) {
                                it2.Quantity = 1;
                            }
                        }
                    }
                }
            }
        }
        onChangeSpecSelect(index: number): void {
            var count = 0;
            //if (this.specs && this.specs.length > 0) {
            //    this.specs[index].IsSelected = !this.specs[index].IsSelected;
            //    if (this.specs[index].IsSelected == false) {
            //        this.specs[index].IsSelected = undefined;
            //    }
            //}
            if (this.form.Data && this.form.Data.PackageShapes) {
                if (this.form.Data.PackageShapes.length == 1) {
                    if (this.specs && this.specs.length > 0) {
                        for (var it of this.specs) {
                            if (it.IsSelected == true) {
                                count++;
                            }
                        }
                        if (count == this.specs.length) {
                            this.isSelected = true;
                        }
                        else {
                            this.isSelected = false;
                        }
                    }
                    else {
                        this.isSelected = false;
                    }

                }
                else {
                    if (this.specs && this.specs.length > 0) {
                        if (this.specs[index].IsSelected) {
                            for (var it1 of this.specs) {
                                it1.IsSelected = false;
                            }
                            this.specs[index].IsSelected = true;
                        } else {
                            this.specs[index].IsSelected = false;
                        }
                    }
                    else {
                        this.isSelected = false;
                    }
                }


                if (this.specs && this.specs.length > 0 && this.form.Data.PackageShapes.length > 1) {
                    for (var item of this.specs) {
                        if (item.IsSelected) {
                            item.Quantity = 1;
                        } else {
                            item.Quantity = null;
                        }

                    }
                    // if (this.specs[index].Quantity == null || this.specs[index].Quantity == undefined) {
                    //     this.specs[index].Quantity = 1;
                    // }
                }
            }



        }
        onRule(): void {
            if (this.type != "20") {
                this.form.Rules.ProductId = [
                    {
                        required: true,
                        message: "信息不能为空",
                        trigger: "blur",
                    },
                ];
            }

            this.form.Rules.PackageEn = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ];

            this.form.Rules.PackageShapes = [
                {
                    required: true,
                    message: "请至少保留一行信息",
                    trigger: "change",
                },
            ];

            this.form.Rules.PackageShapes_Quantity = [
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != undefined) {
                            var reg = new RegExp("^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$");
                            if (!reg.test(value)) {
                                callback(new Error("仅可填入大于0的整数"));
                                return false;
                            }
                            else {
                                callback();
                            }
                        } else {
                            return callback(new Error("信息不能为空"));
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.PackageShapes_NetWeight = [
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != undefined) {
                            var fields = rule.field
                                .replace("[", ".")
                                .replace("]", ".")
                                .split(".");
                            var number = Number(fields[1]);
                            if (this.form && this.form.Data && this.form.Data.PackageShapes && this.form.Data.PackageShapes[number].GrossWeight) {

                                var gross = this.form.Data.PackageShapes[number].GrossWeight;
                                if (gross) {
                                    if (Number(value) > gross) {
                                        return callback(new Error("净重应≤毛重，请重新填写"));
                                    }
                                }

                            }

                        }

                        else {
                            return callback(new Error("信息不能为空"));
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ];

            this.form.Rules.PackageShapes_GrossWeight = [
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != undefined) {
                            var fields = rule.field
                                .replace("[", ".")
                                .replace("]", ".")
                                .split(".");
                            var number = Number(fields[1]);
                            if (this.form && this.form.Data && this.form.Data.PackageShapes && this.form.Data.PackageShapes[number].NetWeight) {

                                var newWeight = this.form.Data.PackageShapes[number].NetWeight;
                                if (newWeight) {
                                    if (Number(value) < newWeight) {
                                        return callback(new Error("净重应≤毛重，请重新填写"));
                                    }
                                }

                            }

                        }

                        else {
                            return callback(new Error("信息不能为空"));
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ];


            this.form.Rules.Specs_Quantity = [
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != undefined) {
                            var reg = new RegExp("^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$");
                            if (!reg.test(value)) {
                                callback(new Error("仅可填入大于0的数字"));
                                return false;
                            }
                            else {
                                callback();
                            }
                        } else {
                            return callback(new Error("信息不能为空"));
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ];

        }
        onChangeProductId(): void {
            this.specs = [];
            if (this.form.Data.ProductId) {
                if (this.products) {
                    for (var pro of this.products) {
                        if (pro.Id == this.form.Data.ProductId) {
                            this.product = pro;
                        }
                    }
                }
                var param = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    ProductId: this.form.Data.ProductId,
                };
                SpecAppService.Get(param).then(r1 => {
                    if (r1.data.Data) this.specs = r1.data.Data.Items;
                    if (this.specs && this.specs.length > 0) {
                        for (var it of this.specs) {
                            it.Quantity = undefined;
                            it.SpecId = it.Id;
                            it.IsSelected = false;
                        }


                        if (this.form.Data.PackageSpecs) {
                            var index = 0;
                            for (var it1 of this.specs) {
                                for (var it2 of this.form.Data.PackageSpecs) {
                                    if (it1.SpecId == it2.SpecId) {
                                        it1.Quantity = it2.Quantity;
                                        it1.IsSelected = true;
                                        //this.onChangeSpecSelect(index);
                                    }

                                }
                                index++;
                            }

                        }

                    }

                    this.form.Data.Specs = this.specs;
                })
            }
            else {
                this.product = undefined;
            }
        }

        modalData: {
            isSelected?: boolean,
            ProductId?: string;
            Specs?: UiSelectOption<string>[]

        } = {
                ProductId: undefined,
                isSelected: false,
            };
        modalRules = {
            ProductId: [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "change",
                },
            ],
            Specs_Quantity: [
                {
                    validator: (rule: any, value: string, callback: any) => {
                        if (value != undefined) {
                            var reg = new RegExp("^[+]{0,1}(\\d+)$|^[+]{0,1}(\\d+\\.\\d+)$");
                            if (!reg.test(value)) {
                                callback(new Error("仅可填入大于0的数字"));
                                return false;
                            }
                            else {
                                callback();
                            }
                        } else {
                            return callback(new Error("信息不能为空"));
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ],
        };
        onChangeModalSpecAll(): void {

            if (this.modalData.isSelected == true) {
                if (this.modalData.Specs && this.modalData.Specs.length > 0) {
                    for (var it of this.modalData.Specs) {
                        it.IsSelected = true;
                    }
                }
            }
            else if (this.modalData.isSelected == false) {
                if (this.modalData.Specs && this.modalData.Specs.length > 0) {
                    for (var it1 of this.modalData.Specs) {
                        it1.IsSelected = false;
                    }
                }
            }
        }
        onChangeModalSpecSelect(index: number): void {
            var count = 0;
            if (this.modalData.Specs && this.modalData.Specs.length > 0) {

                for (var it of this.modalData.Specs) {
                    if (it.IsSelected == true) {
                        count++;
                    }
                }
                if (count == this.modalData.Specs.length) {
                    this.modalData.isSelected = true;
                }
                else {
                    this.modalData.isSelected = false;
                }
                //for (var it of this.modalData.Specs) {
                //    it.IsSelected = false;
                //}
                //this.modalData.Specs[index].IsSelected = true;
            }
            else {
                this.modalData.isSelected = false;
            }
        }
        onChangeModalProductId(): void {
            this.modalData.isSelected = false;
            this.modalData.Specs = [];
            if (this.modalData.ProductId) {
                if (this.products) {
                    for (var pro of this.products) {
                        if (pro.Id == this.modalData.ProductId) {
                            this.product = pro;
                        }
                    }
                }
                var param = {
                    CurrentPage: 1,
                    PageSize: 9999999,
                    ProductId: this.modalData.ProductId,
                };
                SpecAppService.Get(param).then(r1 => {
                    if (r1.data.Data) this.modalData.Specs = r1.data.Data.Items;
                    if (this.modalData.Specs && this.modalData.Specs.length > 0) {
                        for (var it of this.modalData.Specs) {
                            it.Quantity = undefined;
                            it.SpecId = it.Id;
                            it.IsSelected = false;

                            var height = 0;
                            if (this.modalData.Specs.length >= 2) {

                                height = 40 * this.modalData.Specs.length;
                            }
                            height = height + 200;
                            if (height > 650) {
                                height = 650;
                            }
                            this.modalForm.Height = height + "px";
                        }

                        if (this.form.Data.Specs) {

                            var count = 0;
                            for (var it1 of this.modalData.Specs) {
                                for (var it2 of this.form.Data.Specs) {
                                    if (it1.Id == it2.SpecId) {
                                        it1.Quantity = it2.Quantity;
                                        it1.IsSelected = true;
                                        count++;
                                    }
                                }
                            }

                            if (count == this.modalData.Specs.length && this.modalData.Specs.length > 0) {
                                this.modalData.isSelected = true;
                            }
                        }
                    }
                    else {
                        this.modalForm.Height = "200px";
                    }
                })
            }
            else {
                this.product = undefined;
            }
        }
        onAddSpec(): void {
            this.modalData.isSelected = false;
            this.modalData.ProductId = undefined;
            this.modalForm.Width = "60%";
            this.modalForm.Title = "选择产品与规格型号";
            this.modalForm.Show = true;
            this.modalForm.Top = "10px";
            this.modalForm.Height = "200px";
            this.modalData.Specs = undefined;
        }
        created(): void {
            this.productId = this.$route.params.id as string;
            this.type = this.$route.query.type as string;
            this.id = this.$route.params.packageId as string;
            if (this.productId != null && this.productId != undefined && this.productId != "") {
                this.type = "10";//表示新增/编辑指定产品
            }
            if (this.id == null || typeof (this.id) == "undefined" || this.id == "undefined" || this.id == "") {
                if (this.bottomButtons != null) {
                    this.bottomButtons[2].Hide = false;
                }
            }
            //表单-数据-初始化
            this.form.Data = new DtoFormPackage();
            //表单-校验
            this.form.Rules = DtoFormPackageRules;
            //表单-元素配置
            this.form.Items = DtoFormPackageMiezzFormItems;
          
            if (this.form && this.form.Data && this.form.Data.UiGroups) {
                if (this.type == "20") {
                      this.form.DefaultSpan = 12;
                    this.form.Data.UiGroups[0].Label = "混装：指不同的产品装入同一个包装内";
                }
                //else {
                //    this.form.Data.UiGroups[0].Label = "产品包装";
                //}
            }





            this.handleGet();
            this.onRule();

            this.modalForm.Buttons = [
                {
                    Code: "modal-save",
                    Label: "保存",
                    Type: "primary",
                    Size: "small",
                    Hide: false,
                },
                //{
                //    Code: "modal-close",
                //    Label: "关闭",
                //    Type: "default",
                //    Size: "small",
                //},
            ];
            this.modalForm.HandleClick = this.handleClick;
        }

        querySearchAsync(queryString: string, cb: any) {
            var restaurants = this.packageEnHistorys;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString && queryString != '' && this.packageEnHistorys) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.packageEnHistorys) {
                    if (it.Label) {
                        var Name = it.Label.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.packageEnHistorys) {
                    for (var i = 0; i < this.packageEnHistorys.length; i++) {
                        if (i < 10) {
                            results.push(this.packageEnHistorys[i])
                        }
                    }
                }
            }
            cb(results);
        }
        handleSelect(item: UiSelectOption<string>) {
            this.form.Data.PackageEn = item.Label;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "PackageEn");
        }
        onChangeModalQuantity(index: number) {
            this.num = undefined;
            if (this.modalData && this.modalData.Specs) {
                this.num = this.modalData.Specs[index].Quantity;
            }
            if (this.num && this.modalData && this.modalData.Specs) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.modalData.Specs[index].Quantity = Number(result);
                }
                else {
                    this.modalData.Specs[index].Quantity = undefined;
                }
            }
        }

        onChangeQuantity(index: number) {
            this.num = undefined;
            if (this.form.Data && this.form.Data.Specs) {
                this.num = this.form.Data.Specs[index].Quantity;
            }
            if (this.num && this.form.Data && this.form.Data.Specs) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.Specs[index].Quantity = Number(result);
                }
                else {
                    this.form.Data.Specs[index].Quantity = undefined;
                }
            }
        }
        onDeletePackageEn(id: string, index: number): void {
            var value = this.form.Data.PackageEn;
            PackageEnAppService.RemoveHistoryById(id).then((r) => {
                var oldValue = "";
                if (this.packageEnHistorys) {
                    oldValue = this.packageEnHistorys[index].Label as string;
                }

                this.packageEnHistorys?.splice(index, 1);
                if (oldValue != value) {
                    this.form.Data.PackageEn = value;
                }
                else {
                    this.form.Data.PackageEn = "";
                }
                ElMessage.success("历史记录已删除");
                return;
            })

        }
        onSelectPackageEn() {
            this.preSelectPackageEn = this.form.Data.PackageEn;
        }

        /**查询表单数据 */
        handleGet(): void {
            var packageEnParam = {};
            var companyId = this.$route.query.CompanyId;
            if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                packageEnParam = {
                    CompanyId: this.$route.query.CompanyId as string,
                };
            }
            //PackageEnHistoryAppService.GetSelect(packageEnParam).then(r => {
            //    this.packageEnHistorys = r.data.Data;


            PackageEnAppService.GetSelect(packageEnParam).then(en => {
                this.packageEnHistorys = en.data.Data;
                PackageZhAppService.GetSelect().then(r1 => {
                    this.packages = r1.data.Data;

                    var param = {};
                    if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                        param = {
                            CurrentPage: 1,
                            PageSize: 9999999,
                            CompanyId: this.$route.query.CompanyId as string,
                        };
                    }
                    else {
                        param = {
                            CurrentPage: 1,
                            PageSize: 9999999,
                        };
                    }
                    ProductAppService.Get(param).then(r => {
                        if (r.data.Data && r.data.Data.Items) {
                            if (this.id != undefined) {
                                this.products = r.data.Data.Items;
                            }
                            else {
                                this.products = [];
                                for (var p1 of r.data.Data.Items) {
                                    if (p1.IfShelf == true) {
                                        this.products.push(p1);
                                    }
                                }
                            }
                        }
                        if (this.products) {
                            for (var pro of this.products) {
                                pro.Name = pro.NameZh;
                                if (pro.NameEn) {
                                    pro.Name += "/" + pro.NameEn;
                                }
                                if (pro.Code) {
                                    pro.Name += "/" + pro.Code;
                                }
                                if (pro.Id == this.productId) {
                                    this.product = pro;
                                }
                            }
                        }
                        this.random = Math.random();
                        if (this.id) {
                            PackageAppService.GetById(this.id).then((r) => {
                                if (r.data.Data) this.form.Data = r.data.Data;
                                if (this.form.Data.ProductId) {
                                    this.type = "10";
                                    this.onChangeProductId();
                                }
                                else {
                                    this.type = "20";
                                    var newSpecs = [];
                                    if (this.form.Data.PackageSpecs) {
                                        for (var it1 of this.form.Data.PackageSpecs) {
                                            newSpecs.push({
                                                SpecId: it1.SpecId,
                                                Id: it1.SpecId,
                                                Name: it1.Spec.Name,
                                                IsSelected: true,
                                                Quantity: it1.Quantity,
                                                Product: {
                                                    Code: it1.Spec.Product.Code,
                                                    NameEn: it1.Spec.Product.NameEn,
                                                    NameZh: it1.Spec.Product.NameZh,
                                                    ChargeUnithZh:it1.Spec.Product.ChargeUnithZh,
                                                    ChargeUnithEn:it1.Spec.Product.ChargeUnithEn,
                                                },
                                                ProductId: it1.Spec.ProductId,
                                            });

                                        }
                                    }
                                    this.form.Data.Specs = newSpecs;
                                }
                                if (this.form.Data.PackageShapes) {
                                    for (var index = 0; index < this.form.Data.PackageShapes.length; index++) {
                                        this.form.Data.PackageShapes[index].isVolume = true;
                                        this.onChangeLength(index);
                                        this.onChangeWidth(index);
                                        this.onChangeHeight(index);
                                    }
                                }

                                this.preSelectPackageEn = this.form.Data.PackageEn;

                            });
                        }
                        else {
                            if (this.productId != null && this.productId != undefined && this.productId != "") {
                                this.form.Data.ProductId = this.productId;
                            }
                            if (this.productId != null && this.productId != undefined && this.productId != "") {
                                this.onChangeProductId();
                            }

                            this.form.Data.PackageShapes?.push({
                                Length: undefined,
                                Width: undefined,
                                Height: undefined,
                                Volume: undefined,
                                NetWeight: undefined,
                                GrossWeight: undefined,
                                Quantity: 1,
                                Remark: undefined,
                                isVolume: true,
                            });
                            this.preSelectPackageEn = this.form.Data.PackageEn;
                        }
                    })

                })
            })
        }

        onChangeLength(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].Length;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].Length = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].Length = undefined;
                }
            }


            if (this.form.Data.PackageShapes) {
                if (this.form.Data.PackageShapes[index].Length && this.form.Data.PackageShapes[index].Width && this.form.Data.PackageShapes[index].Height) {
                    this.form.Data.PackageShapes[index].isVolume = false;

                    this.form.Data.PackageShapes[index].Volume = Number(this.form.Data.PackageShapes[index].Length) * Number(this.form.Data.PackageShapes[index].Width) * Number(this.form.Data.PackageShapes[index].Height) / 1000000;

                    this.num = undefined;
                    if (this.form.Data && this.form.Data.PackageShapes) {
                        this.num = this.form.Data.PackageShapes[index].Volume;
                    }
                    if (this.num) {
                        var result1 = setDecimal(this.num.toString(), 4, false);
                        if (result1 != "") {
                            this.form.Data.PackageShapes[index].Volume = Number(result1);
                        }
                        else {
                            this.form.Data.PackageShapes[index].Volume = undefined;
                        }
                    }
                }
                else {
                    this.form.Data.PackageShapes[index].isVolume = true;
                }
            }

        }
        onChangeWidth(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].Width;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].Width = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].Width = undefined;
                }
            }


            if (this.form.Data.PackageShapes) {
                if (this.form.Data.PackageShapes[index].Length && this.form.Data.PackageShapes[index].Width && this.form.Data.PackageShapes[index].Height) {
                    this.form.Data.PackageShapes[index].isVolume = false;
                    this.form.Data.PackageShapes[index].Volume = Number(this.form.Data.PackageShapes[index].Length) * Number(this.form.Data.PackageShapes[index].Width) * Number(this.form.Data.PackageShapes[index].Height) / 1000000;

                    this.num = undefined;
                    if (this.form.Data && this.form.Data.PackageShapes) {
                        this.num = this.form.Data.PackageShapes[index].Volume;
                    }
                    if (this.num) {
                        var result1 = setDecimal(this.num.toString(), 4, false);
                        if (result1 != "") {
                            this.form.Data.PackageShapes[index].Volume = Number(result1);
                        }
                        else {
                            this.form.Data.PackageShapes[index].Volume = undefined;
                        }
                    }
                }
                else {
                    this.form.Data.PackageShapes[index].isVolume = true;
                }
            }
        }
        onChangeHeight(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].Height;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].Height = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].Height = undefined;
                }
            }


            if (this.form.Data.PackageShapes) {
                if (this.form.Data.PackageShapes[index].Length && this.form.Data.PackageShapes[index].Width && this.form.Data.PackageShapes[index].Height) {
                    this.form.Data.PackageShapes[index].isVolume = false;

                    this.form.Data.PackageShapes[index].Volume = Number(this.form.Data.PackageShapes[index].Length) * Number(this.form.Data.PackageShapes[index].Width) * Number(this.form.Data.PackageShapes[index].Height) / 1000000;

                    this.num = undefined;
                    if (this.form.Data && this.form.Data.PackageShapes) {
                        this.num = this.form.Data.PackageShapes[index].Volume;
                    }
                    if (this.num) {
                        var result1 = setDecimal(this.num.toString(), 4, false);
                        if (result1 != "") {
                            this.form.Data.PackageShapes[index].Volume = Number(result1);
                        }
                        else {
                            this.form.Data.PackageShapes[index].Volume = undefined;
                        }
                    }
                }
                else {
                    this.form.Data.PackageShapes[index].isVolume = true;
                }
            }
        }

        onChangeVolume(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].Volume;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 4, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].Volume = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].Volume = undefined;
                }
            }
        }

        onChangeNetWeight(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].NetWeight;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].NetWeight = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].NetWeight = undefined;
                }
            }
        }

        onChangeGrossWeight(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].GrossWeight;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 2, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].GrossWeight = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].GrossWeight = undefined;
                }
            }
        }

        onChangeShapeQuantity(index: number): void {
            this.num = undefined;
            if (this.form.Data && this.form.Data.PackageShapes) {
                this.num = this.form.Data.PackageShapes[index].Quantity;
            }
            if (this.num && this.form.Data && this.form.Data.PackageShapes) {
                var result = setDecimal(this.num.toString(), 0, false);
                if (result != "") {
                    this.form.Data.PackageShapes[index].Quantity = Number(result);
                }
                else {
                    this.form.Data.PackageShapes[index].Quantity = undefined;
                }
            }
        }

        onChangePackageZhId(): void {
            var flag = false;
            if (this.packages && this.packages.length > 0) {
                for (var it of this.packages) {
                    if (it && it.Value && this.form.Data && this.form.Data.PackageZhId) {
                        if (it.Value == this.form.Data.PackageZhId && it.Data.PackageEn) {
                            if (it.Data.PackageEn.Abbr) {
                                this.form.Data.PackageEn = it.Data.PackageEn.Abbr;
                            }
                            else {
                                this.form.Data.PackageEn = it.Data.PackageEn.Name
                            }
                            this.preSelectPackageEn = this.form.Data.PackageEn;
                            flag = true;

                            MiezzForm.clearValidate(this.$refs, "ruleForm", "PackageEn");
                        }
                    }
                }
            }
            if (flag == false) {
                this.form.Data.PackageEn = undefined;
            }
        }
        onDeleteSpec(index: number): void {
            if (!this.form.Data.Specs) {
                this.form.Data.Specs = [];
            }
            this.form.Data.Specs.splice(index, 1);
        }
        onDeleteShape(index: number): void {
            if (!this.form.Data.PackageShapes) {
                this.form.Data.PackageShapes = [];
            }



            if (this.form.Data == undefined || this.form.Data.PackageShapes == undefined || this.form.Data.PackageShapes.length <= 1) {
                ElMessage.warning("请至少保留一行尺寸/毛重/数量的信息");
                return;
            }

            this.form.Data.PackageShapes.splice(index, 1);


            MiezzForm.validateField(this.$refs, "ruleForm", "PackageShapes");
            this.isSelected = false;
            if (this.form.Data && this.form.Data.PackageSpecs && this.form.Data.PackageSpecs.length == 1) {
                if (this.form.Data.PackageSpecs[0].IsSelected == true) {
                    this.isSelected = true;
                }
            }

            if (this.form.Data.PackageShapes.length == 1) {
                if (this.specs && this.specs.length > 0) {
                    for (var it2 of this.specs) {
                        if (it2.IsSelected == true) {
                            it2.Quantity = 1;
                        }
                    }
                }
            }

        }
        onAddShape(): void {
            this.form.Data.PackageShapes?.push({
                Length: undefined,
                Width: undefined,
                Height: undefined,
                Volume: undefined,
                NetWeight: undefined,
                GrossWeight: undefined,
                Quantity: 1,
                Remark: undefined,
                isVolume: true,
            });
            MiezzForm.validateField(this.$refs, "ruleForm", "PackageShapes");

            this.isSelected = false;
            if (this.form.Data && this.form.Data.PackageSpecs && this.form.Data.PackageSpecs.length == 1) {
                if (this.form.Data.PackageSpecs[0].IsSelected == true) {
                    this.isSelected = true;
                }
            }
            if (this.form.Data.PackageShapes && this.form.Data.PackageShapes.length > 1 && this.form.Data.Specs) {

                var flag = false;
                for (var it of this.form.Data.Specs) {
                    if (it.IsSelected == true && flag == false) {
                        flag = true;
                    }
                    else if (flag == true) {
                        it.IsSelected = false;
                    }
                }
            }

            if (this.form.Data && this.form.Data.PackageShapes) {
                if (this.form.Data.PackageShapes.length > 1) {
                    if (this.specs && this.specs.length > 0) {
                        for (var it2 of this.specs) {
                            if (it2.IsSelected == true) {
                                it2.Quantity = 1;
                            }
                        }
                    }
                }
            }

        }
        handleBack(): void {
            if (this.isModalClose > 1) {
                //var url = this.$route.query.backUrl as string;
                //if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
                //    this.$router.push(url);
                //}
                //else {
                //    history.go(-1);
                //}
                history.go(-this.isModalClose);
            }
            else {
                history.go(-1);
            }
            //var urls = this.$route.query.backUrl as string;
            //console.log(urls);
            //var url = "";
            //if (urls.length > 0) {
            //    url = urls[0];
            //    var index = 0;
            //    for (var it of urls) {
            //        if (index == 1) {
            //            if (url.indexOf("?") >= 0) {
            //                url += "&backUrl=" + it;
            //            }
            //            else {
            //                url += "?backUrl=" + it;
            //            }
            //        }
            //        else if(index>1){
            //            url += "&backUrl=" + it;
            //        }
            //        index++;
            //    }
            //}

            //if (url != null && typeof (url) != "undefined" && url != "undefined" && url != "") {
            //    this.$router.push(url);
            //}
            //else {
            //    history.go(-1);
            //}
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {

            if (this.type != "20") {
                var paceSpecs = [];
                if (this.specs) {
                    for (var spec of this.specs) {
                        if (spec.IsSelected == true) {
                            spec.Id = undefined;
                            paceSpecs.push(spec);
                        }
                    }
                }
                this.form.Data.PackageSpecs = paceSpecs;
            }
            else {
                var paceSpecs1 = [];
                if (this.form.Data.Specs) {
                    for (var spec1 of this.form.Data.Specs) {
                        if (spec1.IsSelected == true) {
                            spec1.Id = undefined;
                            paceSpecs1.push(spec1);
                        }
                    }

                }

                this.form.Data.PackageSpecs = paceSpecs1;
            }
            switch (btn.Code) {
                case "modal-save":
                    (this.$refs["modal-form"] as any).validate((v: boolean) => {
                        if (v) {
                            var specs = [];
                            if (this.modalData.Specs) {
                                for (var it of this.modalData.Specs) {
                                    if (it.IsSelected == true) {
                                        specs.push(it);
                                    }
                                }
                            }
                            if (!this.form.Data.Specs) {
                                this.form.Data.Specs = specs;
                            }
                            else {
                                var newSpecs = [];
                                for (var spec of this.form.Data.Specs) {
                                    if (spec.ProductId != this.modalData.ProductId) {
                                        newSpecs.push(spec);
                                    }
                                }
                                this.form.Data.Specs = newSpecs;
                                if (this.modalData.Specs) {
                                    for (var it1 of this.modalData.Specs) {
                                        if (it1.IsSelected == true) {
                                            this.form.Data.Specs.push(it1);
                                        }
                                    }
                                }
                            }
                            this.modalForm.Show = false;
                            complete();
                        } else {
                            complete();
                        }
                    });
                    break;
                case "close":
                case "modal-close":
                    this.isModalClose++;
                    this.modalForm.Show = false;
                    complete();
                    break;
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            if (!this.id) {

                                var companyId = this.$route.query.CompanyId;
                                if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                                    this.form.Data.CompanyId = this.$route.query.CompanyId as string;
                                }
                            }
                            if (this.type == "20") {
                                var productId = [];
                                if (this.form.Data.PackageSpecs) {
                                    for (var ps of this.form.Data.PackageSpecs) {
                                        var flag = false;
                                        for (var pId of productId) {
                                            if (pId == ps.ProductId) {
                                                flag = true;
                                            }
                                        }
                                        if (flag == false) {
                                            productId.push(ps.ProductId);
                                        }

                                    }
                                }
                                if (productId.length <= 1) {
                                    ElMessage.warning("添加混装包装应至少包含两种产品的信息，请补充");
                                    complete();
                                    return false;

                                }
                            }
                            PackageAppService.Post(this.form.Data)
                                .then(() => {
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    this.handleSubmit();
                                    complete();
                                    this.handleBack();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "continue":
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        if (v) {
                            var companyId = this.$route.query.CompanyId;
                            if (companyId != null && companyId != undefined && companyId != "undefined" && companyId != "") {
                                this.form.Data.CompanyId = this.$route.query.CompanyId as string;
                            }
                            if (this.type == "20") {
                                var productId = [];
                                if (this.form.Data.PackageSpecs) {
                                    for (var ps of this.form.Data.PackageSpecs) {
                                        var flag = false;
                                        for (var pId of productId) {
                                            if (pId == ps.ProductId) {
                                                flag = true;
                                            }
                                        }
                                        if (flag == false) {
                                            productId.push(ps.ProductId);
                                        }

                                    }
                                }
                                if (productId.length <= 1) {
                                    ElMessage.warning("添加混装包装应至少包含两种产品的信息，请补充");
                                    complete();
                                    return false;
                                }
                            }
                            PackageAppService.Post(this.form.Data)
                                .then(() => {
                                    this.handleSubmit();
                                    this.form.Data = new DtoFormPackage();
                                    MiezzForm.resetFields(this.$refs, "ruleForm");
                                    if (this.id) {
                                        ElMessage.success("编辑成功");
                                    }
                                    else {
                                        ElMessage.success("新增成功");
                                    }
                                    complete();
                                })
                                .catch(complete);
                        } else complete();
                    });
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                case "reset":
                    this.handleGet();
                    MiezzForm.resetFields(this.$refs, "ruleForm");
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }
    }

    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {

        if (val == null || val == undefined || val == '') {
            return '';
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        }
        else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        }
        else {
            return result;
        }
    };
