import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'Type')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data.Type,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.Type) = $event)),
                options: _ctx.types
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'VolumeRatio')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data.VolumeRatio,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.VolumeRatio) = $event)),
                placeholder: '请填写',
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCheckNumber()))
              }, null, 8, ["modelValue"])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'MinStandardTicket')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.Data.MinStandardTicket,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.MinStandardTicket) = $event)),
                placeholder: "请填写",
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onCheckNumber()))
              }, _createSlots({ _: 2 }, [
                (_ctx.form.Data.Type == 10 || _ctx.form.Data.Type == 20)
                  ? {
                      name: "append",
                      fn: _withCtx(() => [
                        (_ctx.form.Data.Type == 10)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "KGS"))
                          : _createCommentVNode("", true),
                        (_ctx.form.Data.Type == 20)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "CBM"))
                          : _createCommentVNode("", true)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["modelValue"])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true),
      (item.Prop == 'MinStandardPiece')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            prop: item.Prop,
            label: item.Label
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.form.Data.MinStandardPiece,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.MinStandardPiece) = $event)),
                placeholder: "请填写",
                onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onCheckNumber()))
              }, _createSlots({ _: 2 }, [
                (_ctx.form.Data.Type == 10 || _ctx.form.Data.Type == 20)
                  ? {
                      name: "append",
                      fn: _withCtx(() => [
                        (_ctx.form.Data.Type == 10)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "KGS"))
                          : _createCommentVNode("", true),
                        (_ctx.form.Data.Type == 20)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "CBM"))
                          : _createCommentVNode("", true)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["modelValue"])
            ]),
            _: 2
          }, 1032, ["prop", "label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}