
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CompanyTypeAppService from "@/apis.machine/CompanyTypeAppService";
import DtoFormCompanyType, {
  DtoFormCompanyTypeMiezzFormItems,
  DtoFormCompanyTypeRules,
} from "@/models.machine/DtoFormCompanyType";
import TranslateAppService from "@/apis.machine/TranslateAppService";
import { EnumCompanyTypeOption } from "@/models.machine/EnumCompanyType";
import MiezzModal from "@/models/MiezzModal";

export default class CompanyTypeForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormCompanyType, string>();

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormCompanyType();
    //表单-校验
    this.form.Rules = DtoFormCompanyTypeRules;
    //表单-元素配置
    this.form.Items = DtoFormCompanyTypeMiezzFormItems;

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      CompanyTypeAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    } else {
      this.form.Data = new DtoFormCompanyType();
    }
  }

  handleTranslate(result?: string) {
    if (result && this.form.Data.Type) {
      const regExp = new RegExp("[a-zA-Z_]", "ig");
      const value = result?.match(regExp);
      result = value?.join("");

      this.form.Data.Code = EnumCompanyTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.Type
      )?.Data;
      this.form.Data.Code += `_${result}`;
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            CompanyTypeAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
