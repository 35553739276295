
import MenuAppService from "@/apis.machine/MenuAppService";
import WebApiAppService from "@/apis.machine/WebApiAppService";
import DtoFormMenu, {
  DtoFormMenuMiezzFormItems,
  DtoFormMenuRules,
} from "@/models.machine/DtoFormMenu";
import DtoFormMenu_MenuField from "@/models.machine/DtoFormMenu_MenuField";
import DtoListItemMenuField from "@/models.machine/DtoListItemMenuField";
import { EnumMenuScene } from "@/models.machine/EnumMenuScene";
import { EnumMenuType } from "@/models.machine/EnumMenuType";
import UiSelectOption from "@/models.machine/UiSelectOption";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MenuForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;
  @Prop() parentId?: string;
  @Prop() path?: string;
  @Prop() code?: string;
  @Prop() scene?: EnumMenuScene;

  form = new MiezzForm<DtoFormMenu, string>();
  webApiSelectOptions: UiSelectOption<string>[] = [];

  types: UiSelectOption<string>[] = [];
  type = new UiSelectOption<string>();
  props = {
    value: "Id",
    label: "Name",
    children: "Children",
    disabled: "Disabled",
    class: (data: any, node: any) => {
      return "tree-node";
    },
  };

  created(): void {
    this.form.Data = new DtoFormMenu();
    this.form.Data.ParentId = this.parentId;
    this.form.Data.Path = this.path;
    this.form.Data.Code = this.code;
    this.form.Data.Scene = this.scene;
    this.form.Rules = DtoFormMenuRules;
    this.form.Items = DtoFormMenuMiezzFormItems;

    for (const item of this.form.Items) {
      switch (item.Prop) {
        case "Path":
          item.Type = EnumMiezzFormItemType.Text;
          break;
        case "Type":
          item.HandleChange = () => {
            if (
              this.form.Data.Type == EnumMenuType.Event ||
              this.form.Data.Type == EnumMenuType.Power
            ) {
              this.form.Data.Visabled = false;
            }
          };
          break;
        default:
          break;
      }
    }

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    WebApiAppService.GetSelect().then((r) => {
      if (r.data.Data) this.webApiSelectOptions = r.data.Data;
    });

    if (this.id) {
      MenuAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.form.Data = r.data.Data;
      });
    }

    MenuAppService.GetTypeSelect().then((r) => {
      if (r.data.Data) this.types = r.data.Data;
    });
  }

  handleTypeQuerySearch(
    q: string,
    cb: (r: UiSelectOption<string>[]) => void
  ): void {
    const result = q
      ? this.types.filter(
          (it) =>
            it.Label && it.Label.toLowerCase().indexOf(q.toLowerCase()) > -1
        )
      : this.types;
    cb(result);
  }

  handleTypeSelect(v: UiSelectOption<string>): void {
    this.type = v;
  }

  handleImportFields(): void {
    MenuAppService.ImportFieldsByName(this.type?.Value).then((r) => {
      if (r.data.Data) {
        if (this.form.Data.MenuFields) {
          this.loopReplace(this.form.Data.MenuFields, r.data.Data);
        } else {
          this.form.Data.MenuFields = r.data.Data;
        }
      }
    });
  }

  handleClearFields(): void {
    this.form.Data.MenuFields = undefined;
  }

  loopReplace(
    fields: DtoFormMenu_MenuField[],
    models: DtoListItemMenuField[]
  ): void {
    for (const model of models) {
      const _field = fields.filter((it) => it.Code == model.Code);
      if (_field.length > 0) {
        const field = _field[0];
        field.Name = model.Name;
        if (!field.Children && model.Children) {
          field.Children = model.Children;
        } else if (field.Children && model.Children) {
          this.loopReplace(field.Children, model.Children);
        }
      } else {
        fields.push(model);
      }
    }
  }

  handleTranslate(result?: string) {
    const regExp = new RegExp("[a-zA-Z_]", "ig");
    const value = result?.match(regExp);
    result = value?.join("");

    if (this.form.Data.Code) {
      const index = this.form.Data.Code.lastIndexOf("_");
      if (index > -1) {
        this.form.Data.Code =
          this.form.Data.Code.substring(0, index + 1) + result;
      } else {
        this.form.Data.Code = result;
      }
    } else {
      this.form.Data.Code = result;
    }
  }

  /**接口变更时 */
  handleWebApiChange(values: any[], options: any[]): void {
    if (options && this.form.Data) {
      if (options.filter((it) => it.Data.Method == 3).length > 0) {
        this.form.Data.MarkerColor = "#F56C6C";
      } else if (options.filter((it) => it.Data.Method == 2).length > 0) {
        this.form.Data.MarkerColor = "#E6A23C";
      } else if (options.filter((it) => it.Data.Method == 1).length > 0) {
        this.form.Data.MarkerColor = "#409EFF";
      } else if (options.filter((it) => it.Data.Method == 0).length > 0) {
        this.form.Data.MarkerColor = "#67C23A";
      } else {
        this.form.Data.MarkerColor = undefined;
      }
    }
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            MenuAppService.Post(this.form.Data)
              .then((r) => {
                this.handleSubmit();
                complete();
              })
              .catch(() => complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
