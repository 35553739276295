
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzForm from "@/models/MiezzForm";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import moment from "moment";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import MiezzButton from "@/models/MiezzButton";
import Logier from "@/models.machine/Logier";
import CurrentLogier from "@/apis/CurrentLogier";
import DtoDetailOrderPoolBill from "@/models.machine/DtoDetailOrderPoolBill";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import OrderDetailCompanyBusinessCard from "../../CompanyBusinessCard/OrderDetailCompanyBusinessCard.vue";
import OrderDetailProductsSummary from "../../ProductSummary/OrderDetailProductsSummary.vue";
import OrderDetailContainer from "../../Container/OrderDetailContainer.vue";
import OrderDetailCost from "../../Cost/OrderDetailCost.vue";
import OrderDetailLadingBill from "../../LadingBill/OrderDetailLadingBill.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";
import OrderManifestVgmDetail from "../../Order/ManifestVgm/OrderManifestVgmDetail.vue";
import OrderSummaryTime from "../../OrderSummaryTime.vue";
@Options({
  components: {
    OrderDetailCompanyBusinessCard,
    OrderDetailProductsSummary,
    OrderDetailContainer,
    OrderDetailCost,
    OrderDetailLadingBill,
    OrderManifestVgmDetail,
    OrderSummaryTime,
  },
})
export default class DeparturePortBooking extends Vue {
  logier?: Logier;
  id?: string;
  menuTab = new MiezzMenuTab();
  form = new MiezzForm<DtoDetailOrderPoolBill, string>();

  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    this.logier = CurrentLogier.GetLogier();
    this.menuTab.Back = true;
    this.menuTab.Url = `/order/pool/departure-port-booking/${this.id}`;
    this.menuTab.Menus = [
      {
        Label: "装货港-订舱",
        Url: `/order/pool/departure-port-booking/${this.id}`,
        Power: "OrderManagement_Pool_Summary_DeparturePortBooking",
      },
    ];
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      OrderPoolAppService.GetBookingById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
        }
      });
    }
  }

  formatTransportPaymentType(): string {
    return (
      EnumTransportPaymentTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.TransportPaymentType
      )?.Label ?? "暂无数据"
    );
  }

  formatGoodsTransportType(): string {
    return (
      EnumGoodsTransportTypeOption.firstOrDefault(
        (it) => it.Value == this.form.Data.GoodsTransportType
      )?.Label ?? "暂无数据"
    );
  }

  formatMonth(v?: Date): string {
    return v ? moment(v).format("YYYY-MM") : "暂无数据";
  }

  formatDate(v?: Date): string {
    return v ? moment(v).format("YYYY-MM-DD HH:mm") : "暂无数据";
  }
}
