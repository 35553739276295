
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import MiezzModal from "@/models/MiezzModal";
import PlatformCompanyAppService from "@/apis.machine/PlatformCompanyAppService";
import DtoDetailPlatformCompany, {
  DtoDetailPlatformCompanyMiezzDetailItems,
} from "@/models.machine/DtoDetailPlatformCompany";
import PlatformCompanyForm from "./PlatformCompanyForm.vue";

@Options({
  components: {
    PlatformCompanyForm,
  },
})
export default class PlatformCompanyDetail extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  detail = new MiezzForm<DtoDetailPlatformCompany, string>();
  modalForm = new MiezzModal();

  /**创建时 */
  created(): void {
    //详细-数据-初始化
    this.detail.Data = new DtoDetailPlatformCompany();
    //详细-元素配置
    this.detail.Items = DtoDetailPlatformCompanyMiezzDetailItems;
    for (const item of this.detail.Items) {
      switch (item.Prop) {
        case "Company":
          item.HandleFormat = () => {
            return this.detail.Data.Company
              ? `${this.detail.Data.Company.NameZh ?? "暂无数据"}/${
                  this.detail.Data.Company.NameEn ?? "暂无数据"
                }`
              : "暂无数据";
          };
          break;
        case "ProjectVersion":
          item.HandleFormat = () => {
            return this.detail.Data.ProjectVersion
              ? `${this.detail.Data.ProjectVersion.MainNumber}.${this.detail.Data.ProjectVersion.MinorNumber}.${this.detail.Data.ProjectVersion.RevisionNumber}`
              : "暂无数据";
          };
          break;
        default:
          break;
      }
    }
    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "edit",
        Title: "编辑",
        Icon: "edit",
        Type: "default",
        Size: "small",
      },
    ];
    this.handleGet();
  }

  /**查询详细 */
  handleGet(): void {
    if (this.id) {
      PlatformCompanyAppService.GetById(this.id).then((r) => {
        if (r.data.Data) this.detail.Data = r.data.Data;
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑平台管理-${this.detail.Data.Name}`;
        complete();
        break;
      default:
        break;
    }
  }

  /**提交表单 */
  @Emit()
  handleSubmit(): void {
    this.modalForm.Show = false;
    this.handleGet();
  }

  /**删除 */
  @Emit()
  handleDelete(): void {
    //
  }
}
