
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzPageList, { MiezzPageListType } from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import PriceTableAppService from "@/apis.machine/PriceTableAppService";
import DtoPageListSearchPriceTable from "@/models.machine/DtoPageListSearchPriceTable";
import DtoPageListPriceTable from "@/models.machine/DtoPageListPriceTable";
import DtoListItemPriceTable, {
  DtoListItemPriceTableMiezzListItems,
} from "@/models.machine/DtoListItemPriceTable";
import PriceTableForm from "./PriceTableForm.vue";
import { EnumUiDragType } from "@/models.machine/EnumUiDragType";
import PriceTableColumnCrud from "./PriceTableColumnCrud.vue";
import PriceSetUp from "../PriceSetUp.vue";
import { MiezzMenuTab } from "@/components/MiezzMenuTab.vue";

@Options({
  components: {
    PriceSetUp,
    PriceTableForm,
    PriceTableColumnCrud,
  },
})
export default class PriceTablePageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchPriceTable,
    DtoListItemPriceTable,
    string
  >();
  loading = false;
  /**当前Id */
  id?: string;
  /**表单模态框 */
  modalForm = new MiezzModal();
  /**表单模态框 */
  modalSetting = new MiezzModal();
  detailBtn: MiezzButton = {
    Code: "detail",
    Label: "查看详情",
    Type: "text",
    Size: "small",
    CancelPadding: true,
    PowerCode: "PriceManagement_SetUp_Table_Detail",
  };
  menuTab = new MiezzMenuTab();
  rowButtons: MiezzButton[] = [
    {
      Code: "edit",
      Title: "编辑",
      Icon: "edit",
      Type: "default",
      Size: "small",
      PowerCode: "PriceManagement_SetUp_Table_AddEdit",
    },
    {
      Code: "setting",
      Title: "显示设置",
      Icon: "setting",
      Type: "default",
      Size: "small",
      PowerCode: "PriceManagement_SetUp_Table_ShowSetting",
    },
    {
      Code: "drag-sort",
      Title: "拖曳",
      Icon: "Rank",
      Type: "default",
      Size: "small",
    },
  ];

  /**创建时 */
  created(): void {
    this.list.Type = MiezzPageListType.Card;
    this.list.FixTop = "30px";
    this.list.CardTitleProp = "Name";
    this.list.EnabledDynamicSearch = false;
    this.list.EnabledRadioSearch = false;
    this.list.CardSpan = 20;
    //列表-传参
    this.list.Dto = new DtoPageListSearchPriceTable();
    // this.list.Dto.PageSize = 10;
    //列表-数据-初始
    this.list.Data = new DtoPageListPriceTable();
    //列表-列配置
    this.list.Items = DtoListItemPriceTableMiezzListItems;
    //列表-顶部按钮
    this.menuTab.RightButtons = [
      {
        Code: "add",
        Label: "添加",
        Icon: "plus",
        Type: "primary",
        Size: "small",
        PowerCode: "PriceManagement_SetUp_Table_AddEdit",
      },
    ];

    //列表-底部按钮
    this.list.BottomButtons = [
      {
        Code: "batch-delete",
        Label: "删除",
        Icon: "delete",
        Type: "text",
        PowerCode: "PriceManagement_SetUp_Table_Delete",
      },
    ];
  }

  /**查询列表 */
  handleGet(): void {
    PriceTableAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
      this.loading = false;
    });
  }

  handleDragSort(
    draggingId?: string,
    dropId?: string,
    type?: EnumUiDragType
  ): void {
    PriceTableAppService.DragSort({
      DraggingId: draggingId,
      DropId: dropId,
      DragType: type,
    }).then(() => {
      this.handleGet();
    });
  }

  handleOpen(row: any): void {
    PriceTableAppService.Open(row).then(() => {
      this.handleGet();
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add":
        this.modalForm.Show = true;
        this.modalForm.Title = "新增基础价格表";
        this.id = undefined;
        complete();
        break;
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemPriceTable,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      case "detail":
        this.$router.push(`/price/setup/table/detail?TableId=${row.Id}`);
        complete();
        break;
      case "edit":
        this.modalForm.Show = true;
        this.modalForm.Title = `编辑基础价格表-${row.Name}`;
        complete();
        break;
      case "setting":
        this.modalSetting.Show = true;
        this.modalSetting.Title = `显示设置-${row.Name}`;
        complete();
        break;
      case "delete":
        PriceTableAppService.Delete([this.id as string])
          .then(() => {
            this.handleGet();
            complete();
          })
          .catch(complete);
        break;
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //批量删除
      case "batch-delete":
        this.loading = true;
        ElMessageBox.confirm("是否确认将该信息删除？", "提醒", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            PriceTableAppService.Delete(this.list.SelectIds)
              .then(() => {
                this.handleGet();
                complete();
              })
              .catch(complete);
          })
          .catch(() => {
            complete();
          });
        break;
      default:
        break;
    }
  }

  /**提交表单回调 */
  handleSubmit(): void {
    this.handleGet();
  }
}
