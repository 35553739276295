import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cargos-total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createBlock(_component_miezz_modal, {
    modelValue: _ctx.modal,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.modal) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.form.Data,
        rules: _ctx.form.Rules,
        ref: "ref-form-cargos",
        inline: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_form_item, {
              label: "合计件数",
              prop: "PackagesNumber",
              "label-width": "80px"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.PackagesNumber && _ctx.form.Data.PackagesKind
              ? `${_ctx.form.Data.PackagesNumber}${_ctx.form.Data.PackagesKind}`
              : _ctx.form.Data.PackagesNumber
              ? _ctx.form.Data.PackagesNumber
              : _ctx.form.Data.PackagesKind
              ? _ctx.form.Data.PackagesKind
              : "暂无数据"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "合计毛重",
              prop: "Weight",
              "label-width": "90px"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.Weight ? `${_ctx.form.Data.Weight}KGS` : "暂无数据"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "合计体积",
              prop: "Volume",
              "label-width": "90px"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.form.Data.Volume ? `${_ctx.form.Data.Volume}CBM` : "暂无数据"), 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_miezz_page_list, {
            ref: "list-cargos",
            modelValue: _ctx.list,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.list) = $event)),
            onHandleRowButtonClick: _ctx.handleRowButtonClick
          }, {
            column: _withCtx(({ model, row, index }) => [
              (model.Prop == 'ProductName')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    prop: `Items[${index}].ProductName`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        trimAll: true
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'Length')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 1,
                    prop: `Items[${index}].Length`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 2,
                        greaterThanNumber: 0,
                        onHandleBlur: ($event: any) => (_ctx.handleCalcVolume(index))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'Width')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 2,
                    prop: `Items[${index}].Width`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 2,
                        greaterThanNumber: 0,
                        onHandleBlur: ($event: any) => (_ctx.handleCalcVolume(index))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'Height')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 3,
                    prop: `Items[${index}].Height`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 2,
                        greaterThanNumber: 0,
                        onHandleBlur: ($event: any) => (_ctx.handleCalcVolume(index))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'GrossWeight')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 4,
                    prop: `Items[${index}].GrossWeight`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 2,
                        greaterThanNumber: 0,
                        onHandleBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCalcTotal()))
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'Volume')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 5,
                    prop: `Items[${index}].Volume`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 4,
                        disabled: row.Length && row.Width && row.Height,
                        onHandleBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleCalcTotal()))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'Quantity')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 6,
                    prop: `Items[${index}].Quantity`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row[model.Prop],
                        "onUpdate:modelValue": ($event: any) => ((row[model.Prop]) = $event),
                        pattern: "[0-9.]",
                        number: true,
                        numberFixed: 2,
                        greaterThanNumber: 0,
                        onHandleBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleCalcTotal()))
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true),
              (model.Prop == 'ChargeUnitEn')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 7,
                    prop: `Items[${index}].ChargeUnitEn`,
                    "label-width": "0px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_input, {
                        modelValue: row.ChargeUnitEn,
                        "onUpdate:modelValue": ($event: any) => ((row.ChargeUnitEn) = $event),
                        "fetch-suggestions": _ctx.getPackageKinds,
                        valueKey: "Label",
                        "trigger-on-focus": true,
                        deleteOption: true,
                        checkDeleteOption: (it) => it.Data.IsHistory,
                        onHandleDeleteOption: _ctx.handleDeleteContact,
                        onHandleBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCalcTotal())),
                        onHandleSelect: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleCalcTotal()))
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "checkDeleteOption", "onHandleDeleteOption"])
                    ]),
                    _: 2
                  }, 1032, ["prop"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "onHandleRowButtonClick"]),
          _createVNode(_component_miezz_button, {
            style: {"color":"#fe995b"},
            model: _ctx.btnAdd,
            onHandleClick: _ctx.handleClick
          }, null, 8, ["model", "onHandleClick"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}