import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_miezz_form = _resolveComponent("miezz-form")!

  return (_openBlock(), _createBlock(_component_miezz_form, {
    ref: "ruleForm",
    modelValue: _ctx.form,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form) = $event))
  }, {
    "form-item": _withCtx(({ item }) => [
      (item.Prop == 'ParamCountryId')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "国家",
            prop: "ParamCountryId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data.ParamCountryId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.ParamCountryId) = $event)),
                options: _ctx.Country,
                class: "form-item-select",
                onChange: _ctx.onChangeCountry
              }, null, 8, ["modelValue", "options", "onChange"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (item.Prop == 'NameZh')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "中文名称",
            prop: "NameZh",
            rules: _ctx.form.Rules.NameZh
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                onHandleBlur: _ctx.onBlurNameZh,
                placeholder: 
            _ctx.form.Data.ParamCountryId ? '请输入中文名称' : '请先选择国家'
          ,
                disabled: _ctx.form.Data.ParamCountryId ? false : true,
                pattern: '[\u4e00-\u9fa5a-zA-Z（）]'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "placeholder", "disabled"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true),
      (item.Prop == 'NameEn')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "英文名称",
            prop: "NameEn",
            rules: _ctx.form.Rules.NameEn
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                onHandleBlur: _ctx.onBlurNameEn,
                placeholder: 
            _ctx.form.Data.ParamCountryId ? '请输入英文名称' : '请先选择国家'
          ,
                disabled: _ctx.form.Data.ParamCountryId ? false : true,
                pattern: '[a-zA-Z()  ,.]',
                trim: false
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "placeholder", "disabled", "pattern"])
            ]),
            _: 2
          }, 1032, ["rules"]))
        : _createCommentVNode("", true),
      (item.Prop == 'Code')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "港口代码",
            prop: "Code"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入港口代码",
                onHandleBlur: _ctx.onBlurCode,
                pattern: '([a-zA-Z])|([0-9])'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "pattern"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'ChinaCustomsCode')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "中国海关港口代码",
            prop: "ChinaCustomsCode"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_input, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                placeholder: "请输入中国海关港口代码",
                onHandleBlur: _ctx.onBlurChinaCustomsCode,
                pattern: '([a-zA-Z])|([0-9])'
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onHandleBlur", "pattern"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true),
      (item.Prop == 'FreighTowerPortId')
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "综合名称",
            prop: "FreighTowerPortId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_select, {
                modelValue: _ctx.form.Data[item.Prop],
                "onUpdate:modelValue": ($event: any) => ((_ctx.form.Data[item.Prop]) = $event),
                options: _ctx.freighTowerPorts
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            _: 2
          }, 1024))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}