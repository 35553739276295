import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPackageZh_PackageEn from "./DtoDetailPackageZh_PackageEn";
import UiGroup from "./UiGroup";

/**
 * 中文包装种类详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPackageZh extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 包装类型（中）
     */
    Name?: string;
    /**
     * 包装类型（英）
     */
    PackageEnId?: string;
    /**
     * 包装类型（英）
     */
    PackageEn?: DtoDetailPackageZh_PackageEn;
    /**
     * 中国海关代码
     */
    Code?: string;
}

/**
 * 中文包装种类详细-表单校验
 */
export const DtoDetailPackageZhRules = {
}

/**
 * 中文包装种类详细-设置项
 */
export const DtoDetailPackageZhMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "包装类型（中）",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "PackageEnId",
    Label: "包装类型（英）",
    Customize: true,
  },
  {
    Prop: "PackageEn",
    Label: "包装类型（英）",
    Customize: true,
  },
  {
    Prop: "Code",
    Label: "中国海关代码",
    Type: EnumMiezzFormItemType.Text,
  },
]
