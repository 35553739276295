import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailOrderWarehouse_OrderWarehouseAnnex from "./DtoDetailOrderWarehouse_OrderWarehouseAnnex";
import DtoDetailOrderWarehouse_User from "./DtoDetailOrderWarehouse_User";
import DtoDetailOrderWarehouse_ParamCurrencySystem from "./DtoDetailOrderWarehouse_ParamCurrencySystem";
import { EnumOrderWarehouseStatus, EnumOrderWarehouseStatusOption } from "./EnumOrderWarehouseStatus";
import DtoDetailOrderWarehouse_OrderWarehousePackage from "./DtoDetailOrderWarehouse_OrderWarehousePackage";
import { EnumWarehouseType, EnumWarehouseTypeOption } from "./EnumWarehouseType";
import UiGroup from "./UiGroup";

/**
 * 进销存/出入库详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailOrderWarehouse extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 签订时间
     */
    SigningTime?: Date;
    /**
     * 合作方（采购方/供应商）
     */
    Partner?: string;
    /**
     * 交货地
     */
    Address?: string;
    /**
     * 交期要求
     */
    Requirement?: string;
    /**
     * 电子版合同
     */
    OrderWarehouseAnnexs?: DtoDetailOrderWarehouse_OrderWarehouseAnnex[];
    /**
     * 执行人
     */
    User?: DtoDetailOrderWarehouse_User;
    /**
     * 金额
     */
    Price?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 币制
     */
    CurrencySystem?: DtoDetailOrderWarehouse_ParamCurrencySystem;
    /**
     * 状态
     */
    Status?: EnumOrderWarehouseStatus;
    /**
     * 包装
     */
    Packages?: DtoDetailOrderWarehouse_OrderWarehousePackage[];
    /**
     * 
     */
    Type?: EnumWarehouseType;
    /**
     * 执行人
     */
    JoinCompanyUserId?: string;
}

/**
 * 进销存/出入库详细-表单校验
 */
export const DtoDetailOrderWarehouseRules = {
}

/**
 * 进销存/出入库详细-设置项
 */
export const DtoDetailOrderWarehouseMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SigningTime",
    Label: "签订时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Partner",
    Label: "合作方（采购方/供应商）",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Address",
    Label: "交货地",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Requirement",
    Label: "交期要求",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderWarehouseAnnexs",
    Label: "电子版合同",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Packages",
    Label: "包装",
    Customize: true,
  },
]
