import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum DtoListItemOrderPoolFileScene {
    /**
     * 委托书
     */
    Pool = 0,
    /**
     * 装货港-订舱
     */
    DeparturePortBooking = 1,
    /**
     * 装货港-集装箱拖车
     */
    DeparturePortContainer = 2,
    /**
     * 装货港-快递
     */
    DeparturePortExpress = 3,
    /**
     * 装货港-干线物流
     */
    DeparturePortTrunk = 4,
    /**
     * 装货港-报关
     */
    DeparturePortDeclareCustoms = 5,
    /**
     * 装货港-仓库/堆场/码头
     */
    DeparturePortWarehouse = 6,
    /**
     * 目的港-清关
     */
    DestinationPortClearCustoms = 7,
    /**
     * 目的港-集装箱拖车
     */
    DestinationPortContainer = 8,
    /**
     * 目的港-干线物流
     */
    DestinationPortTrunk = 9,
    /**
     * 目的港-快递
     */
    DestinationPortExpress = 10,
    /**
     * 目的港-仓库/堆场/码头
     */
    DestinationPortWarehouse = 11,
}

/**
 * -选项
 */
export const DtoListItemOrderPoolFileSceneOption: UiSelectOption<number>[] = [
    { Label: "委托书", Value: 0, Data: "Pool" },
    { Label: "装货港-订舱", Value: 1, Data: "DeparturePortBooking" },
    { Label: "装货港-集装箱拖车", Value: 2, Data: "DeparturePortContainer" },
    { Label: "装货港-快递", Value: 3, Data: "DeparturePortExpress" },
    { Label: "装货港-干线物流", Value: 4, Data: "DeparturePortTrunk" },
    { Label: "装货港-报关", Value: 5, Data: "DeparturePortDeclareCustoms" },
    { Label: "装货港-仓库/堆场/码头", Value: 6, Data: "DeparturePortWarehouse" },
    { Label: "目的港-清关", Value: 7, Data: "DestinationPortClearCustoms" },
    { Label: "目的港-集装箱拖车", Value: 8, Data: "DestinationPortContainer" },
    { Label: "目的港-干线物流", Value: 9, Data: "DestinationPortTrunk" },
    { Label: "目的港-快递", Value: 10, Data: "DestinationPortExpress" },
    { Label: "目的港-仓库/堆场/码头", Value: 11, Data: "DestinationPortWarehouse" },
];
