
import MiezzButton from "@/models/MiezzButton";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
export default class MiezzButtonUpload extends Vue {
  @Prop() model = new MiezzButton();

  button = new MiezzButton();

  created(): void {
    this.onModelChanged();
  }

  @Watch("model")
  onModelChanged(): void {
    const btn = Object.copy(this.model) as MiezzButton;
    btn.FileUpload = false;
    this.button = btn;
  }

  handleBeforeUpload(file: any): boolean {
    this.handleUpload(this.model, file);
    return false;
  }

  @Emit()
  handleUpload(model: MiezzButton, file: any): void {
    //
  }

  handleClick(model: MiezzButton, complete: () => void): void {
    complete();
  }
}
