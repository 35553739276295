import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c356f692"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ellipsis"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_company_business_card_form = _resolveComponent("company-business-card-form")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_company_business_card_seal = _resolveComponent("company-business-card-seal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_page_list, {
      modelValue: _ctx.list,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
      onHandleTopButtonClick: _ctx.handleTopButtonClick,
      onHandleRowButtonClick: _ctx.handleRowButtonClick,
      onHandleGet: _ctx.handleGet,
      leftHeaderWidth: 'calc(100% - 250px)'
    }, {
      headerLeft: _withCtx(({ row }) => [
        (row.Content != 'THE SAME AS CNEE')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (row.NameZh && row.NameEn)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _createVNode(_component_el_tooltip, {
                      effect: "dark",
                      content: row.NameZh+'/'+row.NameEn,
                      placement: "top"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.NameZh) + "/" + _toDisplayString(row.NameEn), 1)
                      ]),
                      _: 2
                    }, 1032, ["content"])
                  ]))
                : (row.NameZh && !row.NameEn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _createVNode(_component_el_tooltip, {
                        effect: "dark",
                        content: row.NameZh,
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(row.NameZh), 1)
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ]))
                  : (!row.NameZh && row.NameEn)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createVNode(_component_el_tooltip, {
                          effect: "dark",
                          content: row.NameEn,
                          placement: "top"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(row.NameEn), 1)
                          ]),
                          _: 2
                        }, 1032, ["content"])
                      ]))
                    : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      column: _withCtx(({ model, row }) => [
        (model.Prop == 'ParamCurrencySystemId')
          ? (_openBlock(), _createBlock(_component_miezz_select, {
              key: 0,
              modelValue: row.ParamCurrencySystemId,
              "onUpdate:modelValue": ($event: any) => ((row.ParamCurrencySystemId) = $event),
              options: _ctx.currencySystems,
              onHandleChange: ($event: any) => (
          _ctx.handleSetCurrencySystem(row.Id, row.ParamCurrencySystemId)
        )
            }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onHandleChange"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleGet", "leftHeaderWidth"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalForm) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_company_business_card_form, {
          modelValue: _ctx.modalForm,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalForm) = $event)),
          id: _ctx.id,
          parentId: _ctx.parentId,
          companyId: _ctx.companyId,
          area: _ctx.area,
          type: _ctx.type,
          onHandleSubmit: _ctx.handleSubmit
        }, null, 8, ["modelValue", "id", "parentId", "companyId", "area", "type", "onHandleSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalSeal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalSeal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_company_business_card_seal, {
          modelValue: _ctx.modalSeal,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalSeal) = $event)),
          id: _ctx.id
        }, null, 8, ["modelValue", "id"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}