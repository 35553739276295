import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 项目分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemProject extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 数据库上下文名称
     */
    DbContextName?: string;
    /**
     * Web目录
     */
    WebPath?: string;
    /**
     * 前端目录
     */
    ClientPath?: string;
}

/**
 * 项目分页列表-项-表单校验
 */
export const DtoListItemProjectRules = {
}

/**
 * 项目分页列表-项-设置项
 */
export const DtoListItemProjectMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "名称",
  },
  {
    Prop: "DbContextName",
    Label: "数据库上下文名称",
  },
  {
    Prop: "WebPath",
    Label: "Web目录",
  },
  {
    Prop: "ClientPath",
    Label: "前端目录",
  },
]
