import UiPageList from "./UiPageList";
import DtoListItemOrderDepartureWharf from "./DtoListItemOrderDepartureWharf";

/**
 * 装货港-仓库/堆场/码头分页列表
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPageListOrderDepartureWharf extends UiPageList<DtoListItemOrderDepartureWharf, string> {
    constructor() {
        //无参构造函数
        super()
        this.AddDeparturePortWarehouse = false;
        this.FeedbackDeparturePortWarehouseWarehousing = false;
        this.FeedbackDeparturePortWarehouseExWarehouse = false;
        this.AddDestinationPortWarehouse = false;
        this.FeedbackDestinationPortWarehouseWarehousing = false;
        this.FeedbackDestinationPortWarehouseExWarehouse = false;
        this.isAddStock = false;
        this.PageIndex = 0;
        this.PageSize = 0;
        this.TotalCount = 0;
        this.TotalPages = 0;
        this.HasPrevPages = false;
        this.HasNextPages = false;
    }

    [proName: string]: any;
    /**
     * 订单Id
     */
    OrderId?: string;
    /**
     * 制定装货港进仓计划的待办是否处于正在执行
     */
    AddDeparturePortWarehouse?: boolean;
    /**
     * 反馈装货港进仓结果的待办是否处于正在执行
     */
    FeedbackDeparturePortWarehouseWarehousing?: boolean;
    /**
     * 反馈装货港出仓结果的待办是否处于正在执行
     */
    FeedbackDeparturePortWarehouseExWarehouse?: boolean;
    /**
     * 制定目的港进仓计划的待办是否处于正在执行
     */
    AddDestinationPortWarehouse?: boolean;
    /**
     * 反馈目的港进仓结果的待办是否处于正在执行
     */
    FeedbackDestinationPortWarehouseWarehousing?: boolean;
    /**
     * 反馈目的港出仓结果的待办是否处于正在执行
     */
    FeedbackDestinationPortWarehouseExWarehouse?: boolean;
    /**
     * 是否库存建单
     */
    isAddStock?: boolean;
    /**
     * 装货港计划货量/入库货量/出库货量的合计件数是否一致
     */
    IsQuantity?: boolean;
    /**
     * 计费重量
     */
    ChargeWeight?: number;
    /**
     * 计费重量
     */
    ChargeWeightString?: string;
}

/**
 * 装货港-仓库/堆场/码头分页列表-表单校验
 */
export const DtoPageListOrderDepartureWharfRules = {
}
