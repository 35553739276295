import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_service_detail = _resolveComponent("price-service-detail")!
  const _component_price_page_list = _resolveComponent("price-page-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_price_service_detail, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event)),
      onHandleMenuTabClick: _ctx.handleMenuTabClick
    }, null, 8, ["modelValue", "onHandleMenuTabClick"]),
    _createVNode(_component_price_page_list, {
      ref: "ref-price-page-list",
      list: _ctx.list,
      serviceId: _ctx.serviceId,
      amountType: _ctx.amountType,
      toggle: true
    }, null, 8, ["list", "serviceId", "amountType"])
  ], 64))
}