import UiSelectOption from "./UiSelectOption";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export enum EnumOrderChartGroupBy {
    /**
     * 业务所属周期
     */
    BusinessCycle = 1,
    /**
     * 供应商
     */
    SupplierCompany = 2,
}

/**
 * -选项
 */
export const EnumOrderChartGroupByOption: UiSelectOption<number>[] = [
    { Label: "业务所属周期", Value: 1, Data: "BusinessCycle" },
    { Label: "供应商", Value: 2, Data: "SupplierCompany" },
];
