
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import PlatformCompanyAppService from "@/apis.machine/PlatformCompanyAppService";
import DtoFormPlatformCompany, {
  DtoFormPlatformCompanyMiezzFormItems,
  DtoFormPlatformCompanyRules,
} from "@/models.machine/DtoFormPlatformCompany";
import CompanyAppService from "@/apis.machine/CompanyAppService";
import DtoSelectOptionCompany from "@/models.machine/DtoSelectOptionCompany";
import MiezzModal from "@/models/MiezzModal";

export default class PlatformCompanyForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormPlatformCompany, string>();

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormPlatformCompany();
    //表单-校验
    this.form.Rules = DtoFormPlatformCompanyRules;
    this.form.Rules.UserPhoneNumber = [
      {
        max: 32,
        message: "文本长度不能超过 32 个字符",
        trigger: "blur",
      },
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value && !/^1[3456789][0-9]{9}$/.test(value)) {
            return callback(new Error("请输入正确的手机号码"));
          } else {
            callback();
          }
        },
      },
    ];
    this.form.Rules.UserName = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ];
    //表单-元素配置
    this.form.Items = DtoFormPlatformCompanyMiezzFormItems.copy();

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      PlatformCompanyAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.form.Data = r.data.Data;
          if (r.data.Data.Company) {
            this.form.Data.CompanyNameZh = r.data.Data.Company.NameZh;
            this.form.Data.CompanyNameEn = r.data.Data.Company.NameEn;
            this.form.Items =
              DtoFormPlatformCompanyMiezzFormItems.copy().filter(
                (it) =>
                  !["UserName", "UserPhoneNumber", "UserGender"].contains(
                    it.Prop
                  )
              );
          }
        }
      });
    } else {
      this.form.Data = new DtoFormPlatformCompany();
    }
  }

  querySearchNameZh(queryString: string, cb: any): void {
    if (queryString) {
      CompanyAppService.GetSelect({
        Keywords: queryString,
        QuerySkyEye: true,
      }).then((r) => {
        cb(r.data.Data);
      });
    }
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            PlatformCompanyAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    //
  }
}
