import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumDocumentType, EnumDocumentTypeOption } from "./EnumDocumentType";
import DtoFormDocument_DocumentAnnex from "./DtoFormDocument_DocumentAnnex";
import DtoFormDocument_DocumentProduct from "./DtoFormDocument_DocumentProduct";
import UiGroup from "./UiGroup";

/**
 * 单证表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormDocument extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.DocumentAnnexs = [];
        this.DocumentProducts = [];
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumDocumentType;
    /**
     * 订单编号
     */
    OrderNumber?: string;
    /**
     * 订单日期
     */
    OrderDate?: Date;
    /**
     * 发票号码
     */
    InvoiceNumber?: string;
    /**
     * 发票日期
     */
    InvoiceDate?: Date;
    /**
     * 发货人
     */
    FirstConsignorId?: string;
    /**
     * 收货人
     */
    FirstConsigneeId?: string;
    /**
     * Sellers
     */
    SellerId?: string;
    /**
     * Buyers
     */
    BuyerId?: string;
    /**
     * Sellers
     */
    SellerContent?: string;
    /**
     * Buyers
     */
    BuyerContent?: string;
    /**
     * 收款银行
     */
    CollectionBankId?: string;
    /**
     * 付款银行
     */
    PaymentBankId?: string;
    /**
     * 银行信息
     */
    CollectionBankContent?: string;
    /**
     * 银行信息
     */
    PaymentBankContent?: string;
    /**
     * 装货港
     */
    DispatchPortId?: string;
    /**
     * 目的港
     */
    DestinationPortId?: string;
    /**
     * 装货港
     */
    DispatchPortName?: string;
    /**
     * 目的港
     */
    DestinationPortName?: string;
    /**
     * 运输方式
     */
    ContactTransportModeId?: string;
    /**
     * 最终交货地址
     */
    Address?: string;
    /**
     * 贸易条款
     */
    TradeTermsId?: string;
    /**
     * 交期要求
     */
    DeliveryRequirement?: string;
    /**
     * 付款条款
     */
    PaymentTermsId?: string;
    /**
     * 付款条款内容
     */
    PaymentTermsContent?: string;
    /**
     * 信用证号码
     */
    CreditNumber?: string;
    /**
     * 开证日期
     */
    IssuingDate?: string;
    /**
     * 有效期
     */
    OpenCertificateDays?: number;
    /**
     * 唛头
     */
    ShippingMarks?: string;
    /**
     * 境内发货人
     */
    ConsignorCode?: string;
    /**
     * 境内发货人
     */
    ConsignorCompanyName?: string;
    /**
     * 申报地海关
     */
    CustomhouseId?: string;
    /**
     * 申报地海关
     */
    CustomhouseName?: string;
    /**
     * 备案号
     */
    RecordNumber?: string;
    /**
     * 境外收货人
     */
    ConsigneeCode?: string;
    /**
     * 境外收货人
     */
    ConsigneeCompanyName?: string;
    /**
     * 运输方式
     */
    TransportModeId?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleCompanyName?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleCode?: string;
    /**
     * 生产销售单位
     */
    ProductionSaleId?: string;
    /**
     * 监管方式
     */
    SupervisionModeId?: string;
    /**
     * 监管方式
     */
    SupervisionModeName?: string;
    /**
     * 征免性质
     */
    ExemptionNatureId?: string;
    /**
     * 征免性质
     */
    ExemptionNatureName?: string;
    /**
     * 许可证号
     */
    PermitNumber?: string;
    /**
     * 合同协议号
     */
    ContractNumber?: string;
    /**
     * 贸易国
     */
    TradingCountryId?: string;
    /**
     * 贸易国
     */
    TradingCountryName?: string;
    /**
     * 运抵国
     */
    ArrivalCountryId?: string;
    /**
     * 运抵国
     */
    ArrivalCountryName?: string;
    /**
     * 指运港
     */
    PortOfDestination?: string;
    /**
     * 包装种类
     */
    PackName?: string;
    /**
     * 其他包装
     */
    OtherPackName?: string;
    /**
     * 总的件数
     */
    ProductNumber?: number;
    /**
     * 总的毛重
     */
    ProductGrossWeight?: number;
    /**
     * 总的净重
     */
    ProductNetWeight?: number;
    /**
     * 总的体积
     */
    ProductVolume?: number;
    /**
     * 总的货值
     */
    ProductPrice?: number;
    /**
     * 成交方式Id
     */
    TransactionModeId?: string;
    /**
     * 运费
     */
    Freight?: number;
    /**
     * 运费币制
     */
    FreightCurrencySystemId?: string;
    /**
     * 运费币制
     */
    FreightCurrencySystemName?: string;
    /**
     * 保费
     */
    Premium?: number;
    /**
     * 保费币制
     */
    PremiumCurrencySystemId?: string;
    /**
     * 保费币制
     */
    PremiumCurrencySystemName?: string;
    /**
     * 杂费
     */
    Incidental?: number;
    /**
     * 杂费币制
     */
    IncidentalCurrencySystemId?: string;
    /**
     * 杂费币制
     */
    IncidentalCurrencySystemName?: string;
    /**
     * 随附单证
     */
    AttachedCode?: string;
    /**
     * 电子底账数据号码
     */
    ElectronicAccountDataNumber?: string;
    /**
     * 唛头
     */
    CustomsShippingMark?: string;
    /**
     * 附件
     */
    DocumentAnnexs?: DtoFormDocument_DocumentAnnex[];
    /**
     * 产品
     */
    DocumentProducts?: DtoFormDocument_DocumentProduct[];
    /**
     * 是否暂存
     */
    IsTemporary?: boolean;
    /**
     * 经停港
     */
    StopPortId?: string;
    /**
     * 经停港
     */
    StopPortName?: string;
    /**
     * 启运国
     */
    DepartureCountryId?: string;
    /**
     * 启运国
     */
    DepartureCountryName?: string;
    /**
     * 启运港
     */
    DeparturePort?: string;
    /**
     * 入境口岸
     */
    EntryPort?: string;
    /**
     * 货物存放地点
     */
    GoodsAddress?: string;
}

/**
 * 单证表单-表单校验
 */
export const DtoFormDocumentRules = {
    OrderNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    InvoiceNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    Address: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    CreditNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ShippingMarks: [
        {
            max: 9000,
            message: "文本长度不能超过 9000 个字符",
            trigger: "blur",
        },
    ],
    ConsignorCode: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ConsignorCompanyName: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    RecordNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ProductionSaleCompanyName: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ProductionSaleCode: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    PermitNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ContractNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    PortOfDestination: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    PackName: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    OtherPackName: [
        {
            max: 9000,
            message: "文本长度不能超过 9000 个字符",
            trigger: "blur",
        },
    ],
    AttachedCode: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    ElectronicAccountDataNumber: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
    CustomsShippingMark: [
        {
            max: 9000,
            message: "文本长度不能超过 9000 个字符",
            trigger: "blur",
        },
    ],
    GoodsAddress: [
        {
            max: 512,
            message: "文本长度不能超过 512 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 单证表单-设置项
 */
export const DtoFormDocumentMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "OrderNumber",
    Label: "订单编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderDate",
    Label: "订单日期",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "InvoiceNumber",
    Label: "发票号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "InvoiceDate",
    Label: "发票日期",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FirstConsignorId",
    Label: "发货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "FirstConsigneeId",
    Label: "收货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SellerId",
    Label: "Sellers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BuyerId",
    Label: "Buyers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SellerContent",
    Label: "Sellers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "BuyerContent",
    Label: "Buyers",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CollectionBankId",
    Label: "收款银行",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentBankId",
    Label: "付款银行",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CollectionBankContent",
    Label: "银行信息",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentBankContent",
    Label: "银行信息",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DispatchPortId",
    Label: "装货港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DestinationPortId",
    Label: "目的港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DispatchPortName",
    Label: "装货港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DestinationPortName",
    Label: "目的港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ContactTransportModeId",
    Label: "运输方式",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Address",
    Label: "最终交货地址",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "TradeTermsId",
    Label: "贸易条款",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DeliveryRequirement",
    Label: "交期要求",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentTermsId",
    Label: "付款条款",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "PaymentTermsContent",
    Label: "付款条款内容",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CreditNumber",
    Label: "信用证号码",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "IssuingDate",
    Label: "开证日期",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "OpenCertificateDays",
    Label: "有效期",
    Span: 6,
    Customize: true,
  },
  {
    Prop: "ShippingMarks",
    Label: "唛头",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsignorCode",
    Label: "境内发货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsignorCompanyName",
    Label: "境内发货人",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "CustomhouseId",
    Label: "申报地海关",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CustomhouseName",
    Label: "申报地海关",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "RecordNumber",
    Label: "备案号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsigneeCode",
    Label: "境外收货人",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "ConsigneeCompanyName",
    Label: "境外收货人",
    Customize: true,
  },
  {
    Prop: "TransportModeId",
    Label: "运输方式",
    Customize: true,
  },
  {
    Prop: "ProductionSaleCompanyName",
    Label: "生产销售单位",
    Customize: true,
  },
  {
    Prop: "ProductionSaleCode",
    Label: "生产销售单位",
    Customize: true,
  },
  {
    Prop: "ProductionSaleId",
    Label: "生产销售单位",
    Customize: true,
  },
  {
    Prop: "SupervisionModeId",
    Label: "监管方式",
    Customize: true,
  },
  {
    Prop: "SupervisionModeName",
    Label: "监管方式",
    Customize: true,
  },
  {
    Prop: "ExemptionNatureId",
    Label: "征免性质",
    Customize: true,
  },
  {
    Prop: "ExemptionNatureName",
    Label: "征免性质",
    Customize: true,
  },
  {
    Prop: "PermitNumber",
    Label: "许可证号",
    Customize: true,
  },
  {
    Prop: "ContractNumber",
    Label: "合同协议号",
    Customize: true,
  },
  {
    Prop: "TradingCountryId",
    Label: "贸易国",
    Customize: true,
  },
  {
    Prop: "TradingCountryName",
    Label: "贸易国",
    Customize: true,
  },
  {
    Prop: "ArrivalCountryId",
    Label: "运抵国",
    Customize: true,
  },
  {
    Prop: "ArrivalCountryName",
    Label: "运抵国",
    Customize: true,
  },
  {
    Prop: "PortOfDestination",
    Label: "指运港",
    Customize: true,
  },
  {
    Prop: "PackName",
    Label: "包装种类",
    Customize: true,
  },
  {
    Prop: "OtherPackName",
    Label: "其他包装",
    Customize: true,
  },
  {
    Prop: "ProductNumber",
    Label: "总的件数",
    Customize: true,
  },
  {
    Prop: "ProductGrossWeight",
    Label: "总的毛重",
    Customize: true,
  },
  {
    Prop: "ProductNetWeight",
    Label: "总的净重",
    Customize: true,
  },
  {
    Prop: "ProductVolume",
    Label: "总的体积",
    Customize: true,
  },
  {
    Prop: "ProductPrice",
    Label: "总的货值",
    Customize: true,
  },
  {
    Prop: "TransactionModeId",
    Label: "成交方式Id",
    Customize: true,
  },
  {
    Prop: "Freight",
    Label: "运费",
    Customize: true,
  },
  {
    Prop: "FreightCurrencySystemId",
    Label: "运费币制",
    Customize: true,
  },
  {
    Prop: "FreightCurrencySystemName",
    Label: "运费币制",
    Customize: true,
  },
  {
    Prop: "Premium",
    Label: "保费",
    Customize: true,
  },
  {
    Prop: "PremiumCurrencySystemId",
    Label: "保费币制",
    Customize: true,
  },
  {
    Prop: "PremiumCurrencySystemName",
    Label: "保费币制",
    Customize: true,
  },
  {
    Prop: "Incidental",
    Label: "杂费",
    Customize: true,
  },
  {
    Prop: "IncidentalCurrencySystemId",
    Label: "杂费币制",
    Customize: true,
  },
  {
    Prop: "IncidentalCurrencySystemName",
    Label: "杂费币制",
    Customize: true,
  },
  {
    Prop: "AttachedCode",
    Label: "随附单证",
    Customize: true,
  },
  {
    Prop: "ElectronicAccountDataNumber",
    Label: "电子底账数据号码",
    Customize: true,
  },
  {
    Prop: "CustomsShippingMark",
    Label: "唛头",
    Customize: true,
  },
  {
    Prop: "DocumentAnnexs",
    Label: "附件",
    Customize: true,
  },
  {
    Prop: "DocumentProducts",
    Label: "产品",
    Customize: true,
  },
  {
    Prop: "IsTemporary",
    Label: "是否暂存",
    Customize: true,
  },
  {
    Prop: "StopPortId",
    Label: "经停港",
    Customize: true,
  },
  {
    Prop: "StopPortName",
    Label: "经停港",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DepartureCountryId",
    Label: "启运国",
    Customize: true,
  },
  {
    Prop: "DepartureCountryName",
    Label: "启运国",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "DeparturePort",
    Label: "启运港",
    Customize: true,
  },
  {
    Prop: "EntryPort",
    Label: "入境口岸",
    Customize: true,
  },
  {
    Prop: "GoodsAddress",
    Label: "货物存放地点",
    Customize: true,
  },
]
