import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumParamCarrierType, EnumParamCarrierTypeOption } from "./EnumParamCarrierType";
import DtoDetailParamCarrier_FreighTowerAirlineCompany from "./DtoDetailParamCarrier_FreighTowerAirlineCompany";
import DtoDetailParamCarrier_FreighTowerShippingCompany from "./DtoDetailParamCarrier_FreighTowerShippingCompany";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 承运人分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamCarrier extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 缩写
     */
    Abridge?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 查询网址
     */
    Url?: string;
    /**
     * 承运人类别
     */
    Type?: EnumParamCarrierType;
    /**
     * 鸭嘴兽代码
     */
    YaZuiShouCode?: string;
    /**
     * 鸭嘴兽名称
     */
    YaZuiShouName?: string;
    /**
     * 飞鸵接口-航空公司Id
     */
    FreighTowerAirlineCompanyId?: string;
    /**
     * 飞鸵接口-航空公司
     */
    FreighTowerAirlineCompany?: DtoDetailParamCarrier_FreighTowerAirlineCompany;
    /**
     * 飞鸵接口-船公司Id
     */
    FreighTowerShippingCompanyId?: string;
    /**
     * 飞鸵接口-船公司
     */
    FreighTowerShippingCompany?: DtoDetailParamCarrier_FreighTowerShippingCompany;
    /**
     * 飞鸵名称
     */
    FreighTowerName?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 承运人分页列表-项-表单校验
 */
export const DtoListItemParamCarrierRules = {
}

/**
 * 承运人分页列表-项-设置项
 */
export const DtoListItemParamCarrierMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "Abridge",
    Label: "缩写",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
  {
    Prop: "Url",
    Label: "查询网址",
  },
  {
    Prop: "Type",
    Label: "承运人类别",
    Pipe: EnumPipe.Enum,
    Options: EnumParamCarrierTypeOption,
  },
  {
    Prop: "YaZuiShouCode",
    Label: "鸭嘴兽代码",
  },
  {
    Prop: "YaZuiShouName",
    Label: "鸭嘴兽名称",
  },
  {
    Prop: "FreighTowerName",
    Label: "综合名称",
  },
]
