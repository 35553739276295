
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import moment from "moment";
import MiezzButton from "@/models/MiezzButton";
    import JoinCompanyUserHomeWorkAppService from "@/apis.machine/JoinCompanyUserHomeWorkAppService";
    import BillAppService from "@/apis.machine/BillAppService";
import UiSelectOptionWithStringValue from "@/models.machine/UiSelectOptionWithStringValue";
import MiezzPageList from "@/models/MiezzPageList";
import DtoOrderTaskHomeOrderPool, {
  DtoOrderTaskHomeOrderPoolMiezzListItems,
} from "@/models.machine/DtoOrderTaskHomeOrderPool";
import OrderTaskAppService from "@/apis.machine/OrderTaskAppService";
import DtoOrderTaskHomeOrder, {
  DtoOrderTaskHomeOrderMiezzListItems,
} from "@/models.machine/DtoOrderTaskHomeOrder";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import OrderPoolChooseService from "./Order/Pool/OrderPoolChooseService.vue";
import OrderPoolProcess from "./Order/Pool/OrderPoolProcess.vue";
import { ElMessage } from "element-plus";
import OrderAppService from "@/apis.machine/OrderAppService";
import DtoCallbackOrderOrPoolId from "@/models.machine/DtoCallbackOrderOrPoolId";
import OrderProcess from "./Order/Order/OrderProcess.vue";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import OrderPoolAppService from "@/apis.machine/OrderPoolAppService";
import { EnumOrderTaskType } from "@/models.machine/EnumOrderTaskType";
import MiezzDateChoose from "@/components/MiezzDateChoose.vue";
import DtoListItemOrderPlanBulletinBoard, {
  DtoListItemOrderPlanBulletinBoardMiezzListItems,
} from "@/models.machine/DtoListItemOrderPlanBulletinBoard";
import FreighTowerAppService from "@/apis.machine/FreighTowerAppService";
@Options({
  components: {
    MiezzDateChoose,
    OrderPoolChooseService,
    OrderPoolProcess,
    OrderProcess,
  },
})
export default class Home extends Vue {
  logier?: Logier;
  powers: string[] = [];

  hello = "";
  btnAdd: MiezzButton = {
    Code: "add-pool",
    Label: "委托书",
    Icon: "plus",
    Type: "primary",
    Size: "small",
    PowerCode: "OrderManagement_Pool_Add",
  };

  works: UiSelectOptionWithStringValue[] = [];
  myWorks: UiSelectOptionWithStringValue[] = [];
  myWorkCodes: string[] = [];
  btnWorkSetting: MiezzButton = {
    Code: "work-setting",
    Title: "设置",
    Icon: "setting",
    Type: "text",
    Size: "small",
  };
  activeWork = "";

  btnMore: MiezzButton = {
    Code: "more",
    Label: "More",
    Type: "text",
    Size: "small",
  };

  modalChooseService = new MiezzModal(MiezzModalType.Dialog);

  modalWorkSetting = new MiezzModal(MiezzModalType.Dialog);
  listWorkSetting = new MiezzPageList<any, any, string>();

  date = "";
  listPlanBulletinBoard = new MiezzPageList<
    any,
    DtoListItemOrderPlanBulletinBoard,
    string
  >();

  listOrderPoolTask = new MiezzPageList<
    any,
    DtoOrderTaskHomeOrderPool,
    string
  >();

  listOrderTask = new MiezzPageList<any, DtoOrderTaskHomeOrder, string>();

  formProcess: {
    MyCode?: string;
    Code?: string;
  } = {};
  rulesProcess = {
    Code: [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
    ],
  };
  EnumCompanyType = EnumCompanyType;
  btnSearchProcess: MiezzButton = {
    Code: "search-process",
    Label: "搜索",
    Type: "primary",
    Size: "small",
  };
  formCallbackProcess: DtoCallbackOrderOrPoolId = {};

  created(): void {
    this.logier = CurrentLogier.GetLogier();
    CurrentLogier.GetPowerCodes().then((r) => {
      this.powers = r ?? [];
    });
    const hours = moment().hours();
    this.hello =
      hours < 6
        ? "晚上好"
        : hours < 11
        ? "早上好"
        : hours < 13
        ? "中午好"
        : hours < 18
        ? "下午好"
        : "晚上好";

    JoinCompanyUserHomeWorkAppService.Get().then((r) => {
      this.works = r.data.Data ?? [];
      this.getMyWorks();
    });

      BillAppService.CheckFinish().then((r) => {
          console.log(r);
      })

    this.modalWorkSetting.Title = "添加";
    this.modalWorkSetting.Width = "700px";
    this.modalWorkSetting.Buttons = [
      {
        Code: "save-work-setting",
        Label: "确定",
        Type: "primary",
        Size: "small",
      },
    ];
    this.modalWorkSetting.HandleClick = this.handleClick;

    this.listWorkSetting.Fix = false;
    this.listWorkSetting.HideCard = true;
    this.listWorkSetting.EnabledDynamicSearch = false;
    this.listWorkSetting.EnabledRadioSearch = false;
    this.listWorkSetting.EnabledPagination = false;
    this.listWorkSetting.Items = [
      {
        Prop: "Label",
        Label: "名称",
        Customize: true,
      },
    ];
    this.listWorkSetting.Data = {
      Items: [],
    };

    this.listPlanBulletinBoard.Fix = true;
    this.listPlanBulletinBoard.FixTop = "49px";
    this.listPlanBulletinBoard.HideCard = true;
    this.listPlanBulletinBoard.IsBottomButton = true;
    this.listPlanBulletinBoard.EnableIndex = true;
    this.listPlanBulletinBoard.EnabledDynamicSearch = false;
    this.listPlanBulletinBoard.EnabledRadioSearch = false;
    this.listPlanBulletinBoard.EnabledSelect = false;
    this.listPlanBulletinBoard.EnabledPagination = false;
    this.listPlanBulletinBoard.Items =
      DtoListItemOrderPlanBulletinBoardMiezzListItems;
    this.listPlanBulletinBoard.HandleRowClass = (row) => {
      return row.Class;
    };
    this.listPlanBulletinBoard.Data = {
      Items: [],
    };

    this.listOrderPoolTask.Fix = false;
    this.listOrderPoolTask.MaxHeight = "600px";
    this.listOrderPoolTask.HideCard = true;
    this.listOrderPoolTask.EnableIndex = true;
    this.listOrderPoolTask.EnabledDynamicSearch = false;
    this.listOrderPoolTask.EnabledRadioSearch = false;
    this.listOrderPoolTask.EnabledSelect = false;
    this.listOrderPoolTask.EnabledPagination = false;
    this.listOrderPoolTask.Items = DtoOrderTaskHomeOrderPoolMiezzListItems;
    this.listOrderPoolTask.Data = {
      Items: [],
    };

    this.listOrderTask.Fix = false;
    this.listOrderTask.MaxHeight = "600px";
    this.listOrderTask.HideCard = true;
    this.listOrderTask.EnableIndex = true;
    this.listOrderTask.EnabledDynamicSearch = false;
    this.listOrderTask.EnabledRadioSearch = false;
    this.listOrderTask.EnabledSelect = false;
    this.listOrderTask.EnabledPagination = false;
    this.listOrderTask.Items = DtoOrderTaskHomeOrderMiezzListItems;
    this.listOrderTask.Data = {
      Items: [],
    };
  }

  getMyWorks(): void {
    JoinCompanyUserHomeWorkAppService.GetCheckCodes().then((r) => {
      this.myWorkCodes = r.data.Data ?? [];
      if (
        this.myWorkCodes.length == 0 &&
        this.logier?.CompanyType == EnumCompanyType.Customer
      ) {
        this.myWorkCodes = this.works.map((it) => it.Value as string);
      }
      this.myWorks = this.works.filter((it) =>
        this.myWorkCodes.contains(it.Value)
      );
      let active = this.$route.query.active as string;
      if (active && this.myWorkCodes.contains(active)) {
        active = this.$route.query.active as string;
      } else {
        active = this.myWorks.firstOrDefault()?.Value ?? "";
      }
      this.changeActiveWork(active);
    });
  }

  changeActiveWork(code?: string): void {
    if (code == "PriceSearch") {
      CurrentLogier.LinkWithBack("/price/search");
    } else if (code == "PriceInquiry") {
      CurrentLogier.LinkWithBack("/price/inquiry");
    } else if (this.$route.query.active != code) {
      this.$router.push(`/?active=${code}`).then(() => {
        this.activeWork = code ?? "";
        this.handleGetActiveWork();
      });
    } else {
      this.activeWork = code ?? "";
      this.handleGetActiveWork();
    }
  }

  @Watch("date")
  handleDateChanged(): void {
    this.handleGetActiveWork();
  }

  handleGetActiveWork(): void {
    switch (this.activeWork) {
      case "OrderPoolTask":
        OrderTaskAppService.GetHomeOrderPoolTasks().then((r) => {
          this.listOrderPoolTask.Data = {
            Items: r.data.Data ?? [],
          };
        });
        break;
      case "OrderTask":
        OrderTaskAppService.GetHomeOrderTasks().then((r) => {
          this.listOrderTask.Data = {
            Items: r.data.Data ?? [],
          };
        });
        break;
      case "PlanBulletinBoard":
        if (this.date) {
          OrderAppService.GetPlanBulletinBoard({
            Date: this.date as any,
          }).then((r) => {
            this.listPlanBulletinBoard.Data = {
              Items: r.data.Data ?? [],
            };
          });
        }
        break;
      default:
        break;
    }
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //新增
      case "add-pool":
        this.modalChooseService.Show = true;
        complete();
        break;
      case "work-setting":
        this.listWorkSetting.Data = {
          Items: this.works.map((it) => {
            return {
              Id: it.Value,
              Label: it.Label,
            };
          }),
        };
        this.listWorkSetting.DefaultSelectIds = this.myWorkCodes;
        this.modalWorkSetting.Show = true;
        complete();
        break;
      case "save-work-setting":
        JoinCompanyUserHomeWorkAppService.Post(this.listWorkSetting.SelectIds)
          .then(() => {
            this.getMyWorks();
            this.modalWorkSetting.Show = false;
            complete();
          })
          .catch(complete);
        break;
      case "search-process":
        (this.$refs["refFormProcess"] as any).validate((v: boolean) => {
          if (!v) {
            setTimeout(() => {
              const error = document.getElementsByClassName("is-error");
              error[0]?.querySelector("input")?.focus();
            }, 1);
            complete();
          } else {
            OrderAppService.GetOrderOrPoolIdByCode(this.formProcess.Code)
              .then((r) => {
                this.formCallbackProcess = r.data.Data ?? {};
                complete();
              })
              .catch(complete);
          }
        });
        break;
      default:
        break;
    }
  }

  clickOrderPlanBulletinBoard(row: DtoListItemOrderPlanBulletinBoard): void {
    this.$router.push(row.Link ?? "");
  }

  /**选择服务完成回调 */
  handleChooseServiceSubmit(id: string): void {
    this.$router.push(`/order/pool/add/${id}`);
  }
  clickOrderPoolTask(row: DtoOrderTaskHomeOrderPool): void {
    if (row.Type == EnumOrderTaskType.AddOrder) {
      CurrentLogier.LinkWithBack(row.Link as string);
    } else if (
      (row.Pool?.Order?.PoolCount ?? 0) > 1 &&
      (row.Type == EnumOrderTaskType.AddDestinationPortExpress ||
        row.Type == EnumOrderTaskType.FeedbackDestinationPortExpress ||
        row.Type == EnumOrderTaskType.AddDestinationPortTrunk ||
        row.Type == EnumOrderTaskType.FeedbackDestinationPortTrunk ||
        row.Type == EnumOrderTaskType.AddDestinationPortWarehouse ||
        row.Type ==
          EnumOrderTaskType.FeedbackDestinationPortWarehouseWarehousing ||
        row.Type ==
          EnumOrderTaskType.FeedbackDestinationPortWarehouseExWarehouse)
    ) {
      CurrentLogier.LinkWithBack(
        `/order/summary/${row.Pool?.OrderId}?active=${row.Active}`
      );
      ElMessage.success(
        "当前委托书与其他委托书同属一个订单，已为您跳转至订单相关目录，您可通过批量操作完成相应待办"
      );
    } else {
      CurrentLogier.LinkWithBack(
        `/order/pool/summary/${row.PoolId}?active=${row.Active}`
      );
    }
  }
  clickOrderTask(row: DtoOrderTaskHomeOrder): void {
    CurrentLogier.LinkWithBack(
      `/order/summary/${row.OrderId}?active=${row.Active}`
    );
  }

  bookingNos: UiSelectOptionWithStringValue[] = [];
  handleBlurMyCode(): void {
    OrderPoolAppService.GetBookingNos({
      Code: this.formProcess.MyCode,
    }).then((r) => {
      this.bookingNos = r.data.Data ?? [];
      if (this.bookingNos.length == 1) {
        this.formProcess.Code = this.bookingNos.firstOrDefault()?.Value;
      } else {
        this.formProcess.Code = undefined;
      }
    });
  }
  querySearchCode(queryString: string, cb: any): void {
    if (this.formProcess.MyCode) {
      cb(
        this.bookingNos.filter(
          (it) => !queryString || it.Value?.contains(queryString)
        )
      );
    } else {
      cb([]);
    }
  }
}
