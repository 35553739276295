import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumCompanyBusinessCardArea, EnumCompanyBusinessCardAreaOption } from "./EnumCompanyBusinessCardArea";
import { EnumCompanyBusinessCardType, EnumCompanyBusinessCardTypeOption } from "./EnumCompanyBusinessCardType";

/**
 * 公司名片分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemCompanyBusinessCard extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 
     */
    Content?: string;
    /**
     * 申报币制
     */
    ParamCurrencySystemId?: string;
    /**
     * 公司中文名称
     */
    NameZh?: string;
    /**
     * 公司英文名称
     */
    NameEn?: string;
    /**
     * 企业代码
     */
    Code?: string;
    /**
     * 地区
     */
    Area?: EnumCompanyBusinessCardArea;
    /**
     * 类型
     */
    Type?: EnumCompanyBusinessCardType;
}

/**
 * 公司名片分页列表-项-表单校验
 */
export const DtoListItemCompanyBusinessCardRules = {
}

/**
 * 公司名片分页列表-项-设置项
 */
export const DtoListItemCompanyBusinessCardMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Content",
    Label: "",
    Pre: true,
  },
  {
    Prop: "ParamCurrencySystemId",
    Label: "申报币制",
    Customize: true,
  },
]
