import { EnumCompanyType, EnumCompanyTypeOption } from "./EnumCompanyType";

/**
 * 公司类型选择器-搜索条件
 * @description 自动生成的代码,请勿修改
 */
export default class DtoSelectSearchCompanyType {
    constructor() {
        //无参构造函数
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumCompanyType;
}

/**
 * 公司类型选择器-搜索条件-表单校验
 */
export const DtoSelectSearchCompanyTypeRules = {
}
