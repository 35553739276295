import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import UiGroup from "./UiGroup";

/**
 * 参数列表详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailParamList extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 参数名称
     */
    Name?: string;
    /**
     * 参数地址
     */
    Url?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * 参数列表详细-表单校验
 */
export const DtoDetailParamListRules = {
}

/**
 * 参数列表详细-设置项
 */
export const DtoDetailParamListMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Name",
    Label: "参数名称",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Url",
    Label: "参数地址",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.Text,
  },
]
