import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a624653e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "increase" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  style: {"color":"#409eff"}
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 1,
  style: {"color":"#409eff"}
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 1,
  style: {"color":"#409eff"}
}
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 1,
  style: {"color":"#409eff"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Lock = _resolveComponent("Lock")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      model: _ctx.list.Data,
      ref: "list-form",
      "label-width": "0px",
      inline: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event))
        }, {
          "column-header": _withCtx(({ model }) => [
            (
            (model.Prop == 'AmountCustomize' &&
              (_ctx.amountType == _ctx.EnumPriceAmountType.Benchmark ||
                _ctx.amountType == _ctx.EnumPriceAmountType.Cost)) ||
            (model.Prop == 'SettlementAmountCustomize' &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Settlement) ||
            (model.Prop == 'GuideAmountCustomize' &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Guide) ||
            (model.Prop == 'AgreementAmountCustomize' &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Agreement)
          )
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                  _createTextVNode(_toDisplayString(model.Label) + " ", 1),
                  _createElementVNode("span", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (b) => {
                      return (_openBlock(), _createBlock(_component_miezz_button, {
                        key: b.Code,
                        model: b,
                        onHandleClick: _ctx.handleClick
                      }, null, 8, ["model", "onHandleClick"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          column: _withCtx(({ model, row, index }) => [
            (model.Prop == 'AmountCustomize')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  (
              !row.Formula &&
              (_ctx.amountType == _ctx.EnumPriceAmountType.Benchmark ||
                _ctx.amountType == _ctx.EnumPriceAmountType.Cost)
            )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createVNode(_component_miezz_input, {
                          modelValue: row.Amount,
                          "onUpdate:modelValue": ($event: any) => ((row.Amount) = $event),
                          number: true,
                          pattern: "[0-9.-]",
                          size: "small"
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _createElementVNode("span", null, _toDisplayString(row.AmountCustomize), 1),
                        (row.Formula)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "（公式计算得出）"))
                          : _createCommentVNode("", true)
                      ]))
                ]))
              : (model.Prop == 'SettlementAmountCustomize')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    (
              !row.SettlementFormula &&
              row.SettlementLockAmount == undefined &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Settlement
            )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                          _createVNode(_component_el_form_item, {
                            prop: `Items[${index}]`,
                            style: {"margin-bottom":"13px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_miezz_input, {
                                modelValue: row.SettlementAmount,
                                "onUpdate:modelValue": ($event: any) => ((row.SettlementAmount) = $event),
                                number: true,
                                pattern: "[0-9.-]",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1032, ["prop"])
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                          (row.SettlementLockAmount != undefined)
                            ? (_openBlock(), _createBlock(_component_el_icon, {
                                key: 0,
                                class: "icon-lock"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Lock)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(row.SettlementAmountCustomize), 1),
                          (row.SettlementFormula)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, "（公式计算得出）"))
                            : _createCommentVNode("", true)
                        ]))
                  ]))
                : (model.Prop == 'GuideAmountCustomize')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                      (
              !row.GuideFormula &&
              row.GuideLockAmount == undefined &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Guide
            )
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                            _createVNode(_component_el_form_item, {
                              prop: `Items[${index}]`,
                              style: {"margin-bottom":"13px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_input, {
                                  modelValue: row.GuideAmount,
                                  "onUpdate:modelValue": ($event: any) => ((row.GuideAmount) = $event),
                                  number: true,
                                  pattern: "[0-9.-]",
                                  size: "small"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1032, ["prop"])
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                            (row.GuideLockAmount != undefined)
                              ? (_openBlock(), _createBlock(_component_el_icon, {
                                  key: 0,
                                  class: "icon-lock"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_Lock)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(row.GuideAmountCustomize), 1),
                            (row.GuideFormula)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_14, "（公式计算得出）"))
                              : _createCommentVNode("", true)
                          ]))
                    ]))
                  : (model.Prop == 'AgreementAmountCustomize')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                        (
              !row.AgreementFormula &&
              row.AgreementLockAmount == undefined &&
              _ctx.amountType == _ctx.EnumPriceAmountType.Agreement
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                              _createVNode(_component_el_form_item, {
                                prop: `Items[${index}]`,
                                style: {"margin-bottom":"13px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_miezz_input, {
                                    modelValue: row.AgreementAmount,
                                    "onUpdate:modelValue": ($event: any) => ((row.AgreementAmount) = $event),
                                    number: true,
                                    pattern: "[0-9.-]",
                                    size: "small"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1032, ["prop"])
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_17, [
                              (row.AgreementLockAmount != undefined)
                                ? (_openBlock(), _createBlock(_component_el_icon, {
                                    key: 0,
                                    class: "icon-lock"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Lock)
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, _toDisplayString(row.AgreementAmountCustomize), 1),
                              (row.AgreementFormula)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_18, "（公式计算得出）"))
                                : _createCommentVNode("", true)
                            ]))
                      ]))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "modal-form",
          model: _ctx.modalData,
          rules: _ctx.modalRules,
          "label-width": "150px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              prop: "Method",
              label: `${_ctx.modalLabel}方式`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.modalData.Method,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalData.Method) = $event)),
                  options: _ctx.modalMethods,
                  clearable: false
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              prop: "Number",
              label: `${_ctx.amountListItem?.Label}${_ctx.modalLabel}${
          _ctx.modalData.Method == 1 ? '' : '（%）'
        }`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_input, {
                  modelValue: _ctx.modalData.Number,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modalData.Number) = $event)),
                  number: true,
                  pattern: "[0-9.]"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}