
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage, ElMessageBox } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import CurrentLogier from "@/apis/CurrentLogier";
import Logier from "@/models.machine/Logier";
import { EnumCompanyType } from "@/models.machine/EnumCompanyType";
import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamPortAppService from "@/apis.machine/ParamPortAppService";
import ParamCountryAreaAppService from "@/apis.machine/ParamCountryAreaAppService";
import DtoSelectOptionParamCountryArea from "@/models.machine/DtoSelectOptionParamCountryArea";
import ParamCarrierAppService from "@/apis.machine/ParamCarrierAppService";
import ParamTransportChannelAppService from "@/apis.machine/ParamTransportChannelAppService";
import { EnumTransportPaymentTypeOption } from "@/models.machine/EnumTransportPaymentType";
import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import { EnumGoodsTransportTypeOption } from "@/models.machine/EnumGoodsTransportType";
import OrderFormContainer from "../../Container/OrderFormContainer.vue";
import OrderFormProductsSummary from "../../ProductSummary/OrderFormProductsSummary.vue";
import OrderFormLadingBill from "../../LadingBill/OrderFormLadingBill.vue";
import OrderFormCompanyBusinessCard from "../../CompanyBusinessCard/OrderFormCompanyBusinessCard.vue";
import DtoFormOrderMainBill, {
  DtoFormOrderMainBillMiezzFormItems,
  DtoFormOrderMainBillRules,
} from "@/models.machine/DtoFormOrderMainBill";
import OrderMainBillAppService from "@/apis.machine/OrderMainBillAppService";
import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";
import { EnumTransportType } from "@/models.machine/EnumTransportType";
import DtoDetailOrderMainBill from "@/models.machine/DtoDetailOrderMainBill";
import moment from "moment";
import CompanyBusinessCardForm from "@/views/Company/CompanyBusinessCard/CompanyBusinessCardForm.vue";
import { EnumOrderStatus } from "@/models.machine/EnumOrderStatus";
import DtoCallbackOrderMainBillAutoPullThreeApiData from "@/models.machine/DtoCallbackOrderMainBillAutoPullThreeApiData";
import elementResizeDetectorMaker from "element-resize-detector";

@Options({
  components: {
    OrderFormCompanyBusinessCard,
    CompanyBusinessCardForm,
    OrderFormContainer,
    OrderFormProductsSummary,
    OrderFormLadingBill,
  },
})
export default class OrderMainBillForm extends Vue {
  id?: string;
  logier?: Logier;

  form = new MiezzForm<DtoFormOrderMainBill, string>();
  loading = false;
  buttons: MiezzButton[] = [
    {
      Code: "back",
      Label: "返回",
      MiezzIcon: "back",
      Type: "default",
      Size: "small",
    },
    {
      Code: "submit",
      Label: "保存",
      Icon: "select",
      Type: "primary",
      Size: "small",
    },
  ];

  detail?: DtoDetailOrderMainBill = {};

  EnumCompanyType = EnumCompanyType;
  EnumTransportType = EnumTransportType;
  EnumTransportContainerLoad = EnumTransportContainerLoad;
  EnumTransportPaymentTypeOption = EnumTransportPaymentTypeOption;
  EnumGoodsTransportTypeOption = EnumGoodsTransportTypeOption;

  currencySystems: UiSelectOption<string>[] = [];
  chargeUnits: UiSelectOption<string>[] = [];
  hasPartBillOptions: UiSelectOption<boolean>[] = [
    {
      Label: "有分单信息",
      Value: true,
    },
    {
      Label: "无分单信息",
      Value: false,
    },
  ];

  addDeparturePortBookingMainBill = false;
  addDeparturePortBookingMainBillAtd = false;
  addDeparturePortBookingMainBillAta = false;

  created(): void {
    this.id = this.$route.params.id as string | undefined;
    // this.addDeparturePortBookingMainBill =
    //   this.$route.query.task == "AddDeparturePortBookingMainBill";
    // this.addDeparturePortBookingMainBillAtd =
    //   this.$route.query.task == "AddDeparturePortBookingMainBillAtd";
    // this.addDeparturePortBookingMainBillAta =
    //   this.$route.query.task == "AddDeparturePortBookingMainBillAta";

    this.logier = CurrentLogier.GetLogier();

    //表单-元素配置
    this.form.Items = DtoFormOrderMainBillMiezzFormItems;

    ParamChargeUnitAppService.GetSelect().then((r) => {
      this.chargeUnits = r.data.Data ?? [];
    });

    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.currencySystems = r.data.Data ?? [];
    });

    this.handleGet();
  }

  /**初始化表单校验 */
  initRules(): void {
    //表单-校验
    this.form.Rules = JSON.copy(DtoFormOrderMainBillRules);

    this.form.Rules.ContainerNo = [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (!value) {
            return callback();
          } else if (value.length > 256) {
            return callback(new Error("文本长度不能超过 256 个字符"));
          } else {
            const reg = /^[A-Z]{4}[0-9]{7}$/;
            if (reg.test(value)) {
              // 箱号正则表达式
              const dic: any = {};
              dic["A"] = 10;
              dic["B"] = 12;
              dic["C"] = 13;
              dic["D"] = 14;
              dic["E"] = 15;
              dic["F"] = 16;
              dic["G"] = 17;
              dic["H"] = 18;
              dic["I"] = 19;
              dic["J"] = 20;
              dic["K"] = 21;
              dic["L"] = 23;
              dic["M"] = 24;
              dic["N"] = 25;
              dic["O"] = 26;
              dic["P"] = 27;
              dic["Q"] = 28;
              dic["R"] = 29;
              dic["S"] = 30;
              dic["T"] = 31;
              dic["U"] = 32;
              dic["V"] = 34;
              dic["W"] = 35;
              dic["X"] = 36;
              dic["Y"] = 37;
              dic["Z"] = 38;
              dic["0"] = 0;
              dic["1"] = 1;
              dic["2"] = 2;
              dic["3"] = 3;
              dic["4"] = 4;
              dic["5"] = 5;
              dic["6"] = 6;
              dic["7"] = 7;
              dic["8"] = 8;
              dic["9"] = 9;
              var number = value;
              var sum = 0;
              for (var i = 0; i < 10; i++) {
                var char = number[i];
                var code = dic[char];
                sum += code * Math.pow(2, i);
              }
              var result = sum % 11;
              var end = parseInt(number[10]);
              var valid = result == end || result - end == 10;
              if (!valid) {
                return callback(new Error("请填写正确的箱号信息"));
              } else {
                return callback();
              }
            } else {
              return callback(new Error("请填写正确的箱号信息"));
            }
          }
        },
        trigger: "blur",
      },
    ];
    //目的港ATA时间
    this.form.Rules.ActualArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ATA时间晚于装货港ATD时间
            if (
              this.form.Data.ActualDepartureDate &&
              moment(value) <= moment(this.form.Data.ActualDepartureDate)
            ) {
              // this.form.Data.ActualArrivalDate = undefined;
              return callback(new Error("目的港ATA时间晚于装货港ATD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ATD时间
    this.form.Rules.ActualDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ATA时间晚于装货港ATD时间
            if (
              this.form.Data.ActualArrivalDate &&
              moment(this.form.Data.ActualArrivalDate) <= moment(value)
            ) {
              // this.form.Data.ActualDepartureDate = undefined;
              return callback(new Error("目的港ATA时间晚于装货港ATD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //目的港ETA时间
    this.form.Rules.EstimatedArrivalDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedDepartureDate &&
              moment(value) <= moment(this.form.Data.EstimatedDepartureDate)
            ) {
              // this.form.Data.EstimatedArrivalDate = undefined;
              return callback(new Error("目的港ETA时间晚于装货港ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];
    //装货港ETD时间
    this.form.Rules.EstimatedDepartureDate = [
      {
        validator: (rule: any, value: Date, callback: any) => {
          if (value) {
            //目的港ETA时间晚于装货港ETD时间
            if (
              this.form.Data.EstimatedArrivalDate &&
              moment(this.form.Data.EstimatedArrivalDate) <= moment(value)
            ) {
              // this.form.Data.EstimatedDepartureDate = undefined;
              return callback(new Error("目的港ETA时间晚于装货港ETD时间"));
            }
          }
          callback();
        },
        trigger: "change",
      },
    ];

    this.form.Rules.BusinessCycle = [
      {
        required: true,
        validator: (rule: any, value: Date, callback: any) => {
          if (this.form.Data.BusinessCycle) {
            callback();
          } else {
            return callback(new Error("信息不能为空"));
          }
        },
        trigger: "change",
      },
    ];

    let requireds: string[] = [];
    if (this.detail?.DeparturePortBooking) {
      requireds.push(
        "ShipperContent",
        "ConsigneeContent",
        "NotifyPartyContent",
        "MasterBillNo",
        "LoadingPortId",
        "DeliveryPortId",
        "EstimatedDepartureDate",
        "EstimatedArrivalDate",
        "CustomsEndDate",
        "TransportPaymentType",
        "MarksNotes",
        "PackagesNumber",
        "PackagesKind",
        "GoodsDescription",
        "GrossWeight",
        "Volume",
        "LadingBillType"
      );
      if (
        this.detail.TransportContainerLoad == EnumTransportContainerLoad.FCL
      ) {
        requireds.push(
          "CarrierId",
          "ManifestEndDate",
          "OpenPortDate",
          "PortEndDate",
          "ShipName",
          "VoyageNumber",
          "Containers"
        );
      } else if (
        this.detail.TransportContainerLoad == EnumTransportContainerLoad.Bulk
      ) {
        requireds.push(
          "CarrierId",
          "ManifestEndDate",
          "OpenPortDate",
          "PortEndDate",
          "ShipName",
          "VoyageNumber"
        );
      }
      if (this.detail.TransportType == EnumTransportType.Air) {
        requireds.push("CarrierId", "Flight");
      } else if (this.detail.TransportType == EnumTransportType.Railway) {
        requireds.push("CarrierId", "RailwayNumber");
      }
    } else {
      if (this.detail?.DeparturePortDeclareCustoms) {
        requireds.push(
          "MasterBillNo",
          "LoadingPortId",
          "DeliveryPortId",
          "EstimatedDepartureDate",
          "CustomsEndDate"
        );
      }
      if (this.detail?.DeparturePortContainerOrWarehouse) {
        requireds.push(
          "ManifestEndDate",
          "OpenPortDate",
          "PortEndDate",
          "EstimatedDepartureDate"
        );
      }
      if (this.detail?.DestinationPortClearCustoms) {
        requireds.push(
          "MasterBillNo",
          "LoadingPortId",
          "DeliveryPortId",
          "EstimatedArrivalDate"
        );
      }
      if (this.detail?.DestinationPortExcludeClearCustoms) {
        requireds.push("DeliveryPortId", "EstimatedArrivalDate");
      }
    }
    if (this.$route.query.task == "AddDeparturePortBookingMainBillAtd") {
      requireds.push("ActualDepartureDate");
    } else if (this.$route.query.task == "AddDeparturePortBookingMainBillAta") {
      requireds.push("ActualArrivalDate");
    }
    requireds = requireds.distinct();
    for (const required of requireds) {
      if (!this.form.Rules[required]) {
        this.form.Rules[required] = [];
      }
      this.form.Rules[required].push(
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        }
      );
    }
  }

  billNos: {
    prop?: string;
    billNo?: string;
  }[] = [];
  handleBillNoBlur(prop: string): void {
    OrderMainBillAppService.CheckBillNoExist({
      Id: this.id,
      MasterBillNo: this.form.Data.MasterBillNo,
      HouseBillNo: this.form.Data.HouseBillNo,
    }).then((r) => {
      if (
        r.data.Data?.contains(prop) &&
        !this.billNos.any(
          (it) => it.prop == prop && it.billNo == this.form.Data[prop]
        )
      ) {
        ElMessageBox.confirm(
          "已存在相同的Bill No，是否继续使用该编号？",
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.billNos.push({
              prop,
              billNo: this.form.Data[prop],
            });
          })
          .catch(() => {
            this.form.Data[prop] = undefined;
          });
      }
    });
  }

  btnAutoPullThreeApiData: MiezzButton = {
    Code: "auto-pull-three-api-data",
    Label: "马上获取",
    Type: "primary",
    Size: "small",
  };

  countdown = 0;
  handleCountdown(): void {
    if (this.countdown > 0) {
      this.btnAutoPullThreeApiData.Disabled = true;
      this.btnAutoPullThreeApiData.Label = this.countdown + "秒";
      setTimeout(() => {
        this.countdown--;
        this.handleCountdown();
      }, 1000);
    } else {
      this.btnAutoPullThreeApiData.Disabled = false;
      this.btnAutoPullThreeApiData.Label = "马上获取";
    }
  }

  autoPullThreeApiData(
    model: MiezzButton,
    complete: () => void,
    update?: boolean
  ): void {
    this.countdown = 5;
    this.handleCountdown();
    if (this.form.Data.MasterBillNo && this.detail) {
      OrderMainBillAppService.AutoPullThreeApiData({
        OrderId: this.detail?.OrderId,
        MasterBillNo: this.form.Data.MasterBillNo,
        CarrierId: this.form.Data.CarrierId,
        LoadingPortId: this.form.Data.LoadingPortId,
        DischargePortId: this.form.Data.DischargePortId,
        DeliveryPortId: this.form.Data.DeliveryPortId,
        ShipName: this.form.Data.ShipName,
        VoyageNumber: this.form.Data.VoyageNumber,
        UpdateData: update,
        Scene: "MainBillForm",
      })
        .then((r) => {
          if (r.data.Data) {
            if ((r.data.Data.Errors?.length ?? 0) > 0) {
              for (const error of r.data.Data.Errors ?? []) {
                ElMessage.warning(error);
              }
              complete();
            } else if ((r.data.Data.NotMatchPorts?.length ?? 0) > 0) {
              const notMatchPorts =
                r.data.Data.NotMatchPorts?.distinct().join("/");
              ElMessageBox.confirm(
                `${notMatchPorts}与第三方数据不一致，请确认录入的Master Bill No.是否有误，是否继续当前操作”，点击确认，使用原始数据填入${notMatchPorts}（也即是忽略掉不一致），点击否，则清除Master Bill No.`,
                "提示",
                {
                  confirmButtonText: "确认",
                  cancelButtonText: "否",
                  type: "warning",
                }
              )
                .then(() => {
                  if (r.data.Data) {
                    this.handleAutoPullThreeApiData(r.data.Data, complete);
                  } else {
                    complete();
                  }
                })
                .catch(() => {
                  this.form.Data.MasterBillNo = undefined;
                  complete();
                });
            } else {
              this.handleAutoPullThreeApiData(r.data.Data, complete);
            }
          } else {
            complete();
          }
        })
        .catch(complete);
    } else if (this.detail) {
      const empties =
        this.form.Data.BindThreeApiPropList?.filter(
          (it) =>
            it != "LoadingPortId" &&
            it != "LoadingPortLabel" &&
            it != "DischargePortId" &&
            it != "DischargePortLabel" &&
            it != "DeliveryPortId" &&
            it != "DeliveryPortLabel"
        ) ?? [];
      for (const prop of empties) {
        this.form.Data[prop] = undefined;
      }
      this.form.Data.QueryThreeApiPropList = [];
      this.form.Data.QueryThreeApiProps = "";
      this.form.Data.BindThreeApiPropList = [];
      this.form.Data.BindThreeApiProps = "";
      complete();
    } else {
      complete();
    }
  }

  handleAutoPullThreeApiData(
    r: DtoCallbackOrderMainBillAutoPullThreeApiData,
    complete: () => void
  ): void {
    if (this.detail) {
      const empties =
        this.form.Data.BindThreeApiPropList?.filter(
          (it) =>
            !r.BindThreeApiPropList?.contains(it) &&
            it != "LoadingPortId" &&
            it != "LoadingPortLabel" &&
            it != "DischargePortId" &&
            it != "DischargePortLabel" &&
            it != "DeliveryPortId" &&
            it != "DeliveryPortLabel"
        ) ?? [];
      for (const prop of empties) {
        this.form.Data[prop] = undefined;
      }
      this.form.Data.QueryThreeApiPropList = r.QueryThreeApiPropList;
      this.form.Data.QueryThreeApiProps = r.QueryThreeApiProps;
      this.form.Data.BindThreeApiPropList = r.BindThreeApiPropList;
      this.form.Data.BindThreeApiProps = r.BindThreeApiProps;
      if ((this.form.Data.BindThreeApiPropList?.length ?? 0) > 0) {
        ElMessage.success(
          "已通过第三方为您获取到相应信息，此类信息（标题为绿色）的变化将由接口推送并禁止修改"
        );
        if ((this.form.Data.BindThreeApiPropList?.length ?? 0) > 0) {
          for (const prop of this.form.Data.BindThreeApiPropList ?? []) {
            this.form.Data[prop] = r[prop];
            (this.$refs["ruleForm"] as any)?.validateField(prop);
          }
        }
        if (!this.form.Data.CarrierId) {
          this.form.Data.CarrierId = r.CarrierId;
          this.form.Data.CarrierLabel = r.CarrierLabel;
        }
        complete();
      } else {
        ElMessage.success(
          "已向第三方接口发送请求，数据会有延迟，请稍后再来查看"
        );
        complete();
      }
    } else {
      complete();
    }
  }

  handleCheckBusinessCycle(prop: string): void {
    this.$nextTick(() => {
      const date = this.form.Data[prop] as Date;
      //EstimatedDepartureDate 装货港ETD
      //ActualDepartureDate 装货港ATD
      //EstimatedArrivalDate 目的港ETA
      //ActualArrivalDate 目的港ATA
      //ATD优先ETD优先ATA优先ETA
      if (
        date &&
        ((prop == "EstimatedArrivalDate" &&
          !this.form.Data.EstimatedDepartureDate &&
          !this.form.Data.ActualDepartureDate &&
          !this.form.Data.ActualArrivalDate) ||
          (prop == "ActualArrivalDate" &&
            !this.form.Data.EstimatedDepartureDate &&
            !this.form.Data.ActualDepartureDate) ||
          (prop == "EstimatedDepartureDate" &&
            !this.form.Data.ActualDepartureDate) ||
          prop == "ActualDepartureDate")
      ) {
        const str = moment(date).format("YYYY-MM");
        const businessCycle = moment(this.form.Data.BusinessCycle).format(
          "YYYY-MM"
        );
        if (str != businessCycle) {
          ElMessageBox.confirm("是否将业务周期调整为与当前月份一致？", "提醒", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.form.Data.BusinessCycle = new Date(str);
          });
        }
      }
    });
  }

  /**查询表单数据 */
  handleGet(): void {
    if (this.id) {
      OrderMainBillAppService.GetById(this.id).then((r) => {
        if (r.data.Data) {
          this.detail = JSON.copy(r.data.Data);
          this.form.Data = r.data.Data;

          // if (!this.form.Data?.Sure) {
          this.buttons.push({
            Code: "save",
            Label: "暂存",
            Icon: "Finished",
            Type: "primary",
            Size: "small",
          });
          // }

          if (
            this.form.Data.LoadingPortId &&
            this.form.Data.LoadingPortLabel &&
            this.loadingPorts.length == 0
          ) {
            this.getLoadingPorts(this.form.Data.LoadingPortLabel);
          }
          if (
            this.form.Data.DischargePortId &&
            this.form.Data.DischargePortLabel &&
            this.dischargePorts.length == 0
          ) {
            this.getDischargePorts(this.form.Data.DischargePortLabel);
          }
          if (
            this.form.Data.DeliveryPortId &&
            this.form.Data.DeliveryPortLabel &&
            this.deliveryPorts.length == 0
          ) {
            this.getDeliveryPorts(this.form.Data.DeliveryPortLabel);
          }
          if (
            this.form.Data.CarrierId &&
            this.form.Data.CarrierLabel &&
            this.carriers.length == 0
          ) {
            this.getCarriers(this.form.Data.CarrierLabel);
          }

          this.initRules();

          if (this.$route.query.task) {
            this.$nextTick(() => {
              (this.$refs["ruleForm"] as any).validate((v: boolean) => {
                if (!v) {
                  setTimeout(() => {
                    const error = document.getElementsByClassName("is-error");
                    error[0]?.querySelector("a")?.focus();
                    error[0]?.querySelector("textarea")?.focus();
                    error[0]?.querySelector("input")?.focus();
                    error[0]?.querySelector("button")?.focus();
                  }, 1);
                }
              });
            });
          }
        }
      });
    }
  }

  loadingPorts: UiSelectOption<string>[] = [];
  /**获取装货港 */
  getLoadingPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Export
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.loadingPorts = r.data.Data ?? [];
      });
    }
  }

  dischargePorts: UiSelectOption<string>[] = [];
  /**获取卸货港 */
  getDischargePorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({ Keywords: q }).then((r) => {
        this.dischargePorts = r.data.Data ?? [];
      });
    }
  }

  deliveryPorts: UiSelectOption<string>[] = [];
  /**获取目的港 */
  getDeliveryPorts(q: string): void {
    if (q) {
      ParamPortAppService.GetSelect({
        Keywords: q,
        // CountryNameEn:
        //   this.detail?.TransportDirection == EnumTransportDirection.Import
        //     ? "China"
        //     : undefined,
      }).then((r) => {
        this.deliveryPorts = r.data.Data ?? [];
      });
    }
  }

  handleChangePort(a?: string, b?: string): void {
    if (a && b && a != b)
      ElMessage.warning("保存后关联委托书中的信息也将同步修改，请知悉");
  }

  /**获取交货地点 */
  getDeliveryPlaces(q: string, cb: any): void {
    if (q && q.length >= 2) {
      ParamCountryAreaAppService.GetSelect({
        Keywords: q,
      }).then((r) => {
        cb(r.data.Data ?? []);
      });
    } else {
      cb([]);
    }
  }

  /**选择交货地点 */
  handleSelectDeliveryPlace(item: DtoSelectOptionParamCountryArea): void {
    this.form.Data.DeliveryPlaceAddress = item?.DetailedAddress;
  }

  carriers: UiSelectOption<string>[] = [];
  /**获取承运人 */
  getCarriers(q: string): void {
    ParamCarrierAppService.GetSelect({ Keywords: q, Take: 10 }).then((r) => {
      this.carriers = r.data.Data ?? [];
    });
  }

  channels: UiSelectOption<string>[] = [];
  /**获取渠道 */
  getChannels(q: string): void {
    ParamTransportChannelAppService.GetSelect({ Keywords: q, Take: 10 }).then(
      (r) => {
        this.channels = r.data.Data ?? [];
      }
    );
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      //保存表单
      case "save":
        if (this.detail?.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        this.form.Data.Sure = true;
        this.loading = true;
        this.autoPullThreeApiData(
          this.btnAutoPullThreeApiData,
          () => {
            OrderMainBillAppService.Post(this.form.Data)
              .then(() => {
                ElMessage.success("保存成功");
                this.loading = false;
                complete();
              })
              .catch(complete);
          },
          true
        );
        break;
      case "submit":
        if (this.detail?.OrderLock) {
          ElMessage.warning("订单已锁定");
          complete();
          return;
        }
        if (this.detail?.OrderStatus == EnumOrderStatus.Canceled) {
          ElMessage.warning("订单已取消，无法修改");
          complete();
          return;
        }
        if (
          this.detail?.TransportContainerLoad ==
            EnumTransportContainerLoad.FCL &&
          this.detail.DeparturePortBooking
        ) {
          //装货港-集装箱拖车
          if (
            //直订订单 并且 委托书FCL 并且有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortContainer
              )) ||
            //直订订单 并且 委托书FCL 并且有仓库没集装箱
            (this.detail.DirectOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.FCL &&
                  it.DeparturePortWarehouse &&
                  !it.DeparturePortContainer
              )) ||
            //配载订单 订单FCL
            (this.detail.StowageOrder &&
              this.detail.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL)
          ) {
            if (
              this.form.Data.Containers?.length !=
                this.detail.DeparturePortContainers?.length ||
              !this.form.Data.Containers?.all((it) =>
                this.detail?.DeparturePortContainers?.any(
                  (c) =>
                    c.ContainerTypeId == it.ContainerTypeId &&
                    c.Quantity == it.Quantity
                )
              )
            ) {
              console.log(
                this.form.Data.Containers,
                this.detail?.DeparturePortContainers
              );

              ElMessage.warning(
                "集装箱拖车中的箱型箱量与主单信息不一致，请调整"
              );
              complete();
              return;
            }
          }
          //目的港-集装箱拖车
          if (
            //配载订单 并且 委托书任意(LCL有集装箱拖车) 并且 已关联订单
            (this.detail.StowageOrder &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortContainer
              )) ||
            //配载订单 并且 订单FCL 并且 委托书任意(LCL有仓库)
            (this.detail.StowageOrder &&
              this.detail.TransportContainerLoad ==
                EnumTransportContainerLoad.FCL &&
              this.detail.Pools?.any(
                (it) =>
                  it.TransportContainerLoad == EnumTransportContainerLoad.LCL &&
                  it.DestinationPortWarehouse
              )) ||
            //直订订单 并且 有集装箱拖车
            (this.detail.DirectOrder &&
              this.detail.Pools?.any((it) => it.DestinationPortContainer))
          ) {
            if (
              this.form.Data.Containers?.length !=
                this.detail.DestinationPortContainers?.length ||
              !this.form.Data.Containers?.all((it) =>
                this.detail?.DestinationPortContainers?.any(
                  (c) =>
                    c.ContainerTypeId == it.ContainerTypeId &&
                    c.Quantity == it.Quantity
                )
              )
            ) {
              ElMessage.warning(
                "集装箱拖车中的箱型箱量与主单信息不一致，请调整"
              );
              complete();
              return;
            }
          }
        }
        OrderMainBillAppService.CheckBillNoExist({
          Id: this.id,
          MasterBillNo: this.form.Data.MasterBillNo,
          HouseBillNo: this.form.Data.HouseBillNo,
        }).then((r) => {
          if (
            (r.data.Data?.contains("MasterBillNo") &&
              !this.billNos.any(
                (it) =>
                  it.prop == "MasterBillNo" &&
                  it.billNo == this.form.Data.MasterBillNo
              )) ||
            (r.data.Data?.contains("HouseBillNo") &&
              !this.billNos.any(
                (it) =>
                  it.prop == "HouseBillNo" &&
                  it.billNo == this.form.Data.HouseBillNo
              ))
          ) {
            ElMessageBox.confirm(
              "已存在相同的Bill No，是否继续使用该编号？",
              "提示",
              {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                if (r.data.Data?.contains("MasterBillNo")) {
                  this.billNos.push({
                    prop: "MasterBillNo",
                    billNo: this.form.Data.MasterBillNo,
                  });
                }
                if (r.data.Data?.contains("HouseBillNo")) {
                  this.billNos.push({
                    prop: "HouseBillNo",
                    billNo: this.form.Data.HouseBillNo,
                  });
                }
                this.submit(complete);
              })
              .catch(() => {
                if (r.data.Data?.contains("MasterBillNo")) {
                  this.form.Data.MasterBillNo = undefined;
                }
                if (r.data.Data?.contains("HouseBillNo")) {
                  this.form.Data.HouseBillNo = undefined;
                }
                complete();
              });
          } else {
            this.submit(complete);
          }
        });
        break;
      case "back":
        CurrentLogier.Back();
        complete();
        break;
      default:
        break;
    }
  }

  submit(complete: () => void): void {
    (this.$refs["ruleForm"] as any).validate((v: boolean) => {
      if (!v) {
        setTimeout(() => {
          const error = document.getElementsByClassName("is-error");
          error[0]?.querySelector("input")?.focus();
        }, 1);
        complete();
      } else {
        this.form.Data.Sure = true;
        this.loading = true;
        this.autoPullThreeApiData(
          this.btnAutoPullThreeApiData,
          () => {
            OrderMainBillAppService.Post(this.form.Data)
              .then(() => {
                ElMessage.success("保存成功");
                CurrentLogier.Back();
                this.loading = false;
                complete();
              })
              .catch(complete);
          },
          true
        );
      }
    });
  }
}
