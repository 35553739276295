import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemParamPort_ParamCountry from "./DtoListItemParamPort_ParamCountry";
import DtoListItemParamPort_FreighTowerPort from "./DtoListItemParamPort_FreighTowerPort";
import { EnumParamAuditStatus, EnumParamAuditStatusOption } from "./EnumParamAuditStatus";

/**
 * 港口分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemParamPort extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Status = EnumParamAuditStatus.Not;
        this.IsEdit = false;
    }

    [proName: string]: any;
    /**
     * 国家
     */
    ParamCountry?: DtoListItemParamPort_ParamCountry;
    /**
     * 中文名称
     */
    NameZh?: string;
    /**
     * 英文名称
     */
    NameEn?: string;
    /**
     * 全名
     */
    FullName?: string;
    /**
     * 港口代码
     */
    Code?: string;
    /**
     * 中国海关港口代码
     */
    ChinaCustomsCode?: string;
    /**
     * 鸭嘴兽代码
     */
    YaZuiShouCode?: string;
    /**
     * 飞鸵Id
     */
    FreighTowerPortId?: string;
    /**
     * 飞鸵
     */
    FreighTowerPort?: DtoListItemParamPort_FreighTowerPort;
    /**
     * 飞鸵
     */
    FreighTowerPortLabel?: string;
    /**
     * 审核状态
     */
    Status?: EnumParamAuditStatus;
    /**
     * 是否可以编辑
     */
    IsEdit?: boolean;
    /**
     * 创建人所属公司
     */
    CreatedCompanyId?: string;
}

/**
 * 港口分页列表-项-表单校验
 */
export const DtoListItemParamPortRules = {
}

/**
 * 港口分页列表-项-设置项
 */
export const DtoListItemParamPortMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "ParamCountry",
    Label: "国家",
    Customize: true,
  },
  {
    Prop: "NameZh",
    Label: "中文名称",
  },
  {
    Prop: "NameEn",
    Label: "英文名称",
  },
  {
    Prop: "FullName",
    Label: "全名",
  },
  {
    Prop: "Code",
    Label: "港口代码",
  },
  {
    Prop: "ChinaCustomsCode",
    Label: "中国海关港口代码",
  },
  {
    Prop: "YaZuiShouCode",
    Label: "鸭嘴兽代码",
  },
  {
    Prop: "FreighTowerPortLabel",
    Label: "综合名称",
  },
]
