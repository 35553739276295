import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_auth_audit = _resolveComponent("company-auth-audit")!

  return (_openBlock(), _createBlock(_component_company_auth_audit, {
    menuTab: _ctx.menuTab,
    type: _ctx.type,
    listUrl: _ctx.listUrl
  }, null, 8, ["menuTab", "type", "listUrl"]))
}