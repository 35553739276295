
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzButton from "@/models/MiezzButton";
import MiezzForm from "@/models/MiezzForm";
import ParamCountryAppService from "@/apis.machine/ParamCountryAppService";
import DtoFormParamCountry, {
  DtoFormParamCountryMiezzFormItems,
  DtoFormParamCountryRules,
} from "@/models.machine/DtoFormParamCountry";
import UiSelectOption from "@/models.machine/UiSelectOption";
import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
import MiezzModal from "@/models/MiezzModal";
import { number } from "echarts";
import { switchCase, thisTypeAnnotation } from "@babel/types";

export default class ParamCountryForm extends Vue {
  @Model() modelValue!: MiezzModal;
  @Prop() id?: string;

  form = new MiezzForm<DtoFormParamCountry, string>("System_ParamList_Country_AddEdit");
  Currencys?: UiSelectOption<string>[] = [];
  NameZhList: string[] = [];

  optAreaDeep: UiSelectOption<number>[] = [
    { Label: "1", Value: 1 },
    { Label: "2", Value: 2 },
    { Label: "3", Value: 3 },
    { Label: "4", Value: 4 },
    { Label: "5", Value: 5 },
  ];

  created(): void {
    //表单-数据-初始化
    this.form.Data = new DtoFormParamCountry();
    //表单-校验
    this.form.Rules = DtoFormParamCountryRules;
    this.form.Rules.NameZh = [
      {
        required: true,
        message: "信息不能为空",
        trigger: "blur",
      },
      {
        required: true,
        validator: (rule: any, value: string, callback: any) => {
          if (this.form.Data.NameZh != null && typeof (this.form.Data.NameZh) != "undefined" && this.form.Data.NameZh != "undefined" && this.form.Data.NameZh != "") {
            var NameZh = this.form.Data.NameZh.trim();
            if (this.NameZhList.some(it => it == NameZh)) {
              callback(new Error("信息重复，请重新填写"));
              return false;
            } else {
              return callback();
            }
          }
          else {
            return callback();
          }
        },
        trigger: "blur",
      },
    ];
    this.form.Rules.AreaName1 = [
      { required: true, message: "信息不能为空", trigger: "blur" }
    ];
    this.form.Rules.AreaName2 = [
      { required: true, message: "信息不能为空", trigger: "blur" }
    ];
    this.form.Rules.AreaName3 = [
      { required: true, message: "信息不能为空", trigger: "blur" }
    ];
    this.form.Rules.AreaName4 = [
      { required: true, message: "信息不能为空", trigger: "blur" }
    ];
    this.form.Rules.AreaName5 = [
      { required: true, message: "信息不能为空", trigger: "blur" }
    ];
    //表单-元素配置
    this.form.Items = DtoFormParamCountryMiezzFormItems;

    //修改元素配置
    //for (const item of this.form.Items) {
    //  switch (item.Prop) {
    //    default:
    //      break;
    //  }
    //}

    this.modelValue.HandleClick = this.handleClick;
    this.modelValue.Buttons = [
      {
        Code: "submit",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];

    this.handleGet();
  }

  onBlurNameZh(): void {
    if (this.form.Data.NameZh) {
      this.form.Data.NameZh = this.form.Data.NameZh.toUpperCase();
    }
    this.getFullName();
  }

  onBlurNameEn(): void {
    if (this.form.Data.NameEn) {
      this.form.Data.NameEn = this.form.Data.NameEn.toUpperCase();
      this.form.Data.NameEn = this.form.Data.NameEn.trim();
    }
    this.getFullName();
  }

  onBlurCode(): void {
    if (this.form.Data.Code) {
      this.form.Data.Code = this.form.Data.Code.toUpperCase();
      this.form.Data.Code = this.form.Data.Code.trim();
    }
    this.getFullName();
  }

  changeAreaDeep(): void {
    if (this.form.Data.AreaDeep == 0) {
      this.form.Data.AreaName1 = '';
      this.form.Data.AreaName2 = '';
      this.form.Data.AreaName3 = '';
      this.form.Data.AreaName4 = '';
      this.form.Data.AreaName5 = '';
    } else if (this.form.Data.AreaDeep == 1) {
      this.form.Data.AreaName2 = '';
      this.form.Data.AreaName3 = '';
      this.form.Data.AreaName4 = '';
      this.form.Data.AreaName5 = '';
    } else if (this.form.Data.AreaDeep == 2) {
      this.form.Data.AreaName3 = '';
      this.form.Data.AreaName4 = '';
      this.form.Data.AreaName5 = '';
    } else if (this.form.Data.AreaDeep == 3) {
      this.form.Data.AreaName4 = '';
      this.form.Data.AreaName5 = '';
    } else if (this.form.Data.AreaDeep == 4) {
      this.form.Data.AreaName5 = '';
    }
  }

  onChangeAreaName1(): void {
    if (!this.form.Data.AreaName1) {
      this.form.Data.AreaName2 = "";
      this.form.Data.AreaName3 = "";
      this.form.Data.AreaName4 = "";
      this.form.Data.AreaName5 = "";
    }
  }
  onChangeAreaName2(): void {
    if (!this.form.Data.AreaName2) {
      this.form.Data.AreaName3 = "";
      this.form.Data.AreaName4 = "";
      this.form.Data.AreaName5 = "";
    }
  }

  onChangeAreaName3(): void {
    if (!this.form.Data.AreaName3) {
      this.form.Data.AreaName4 = "";
      this.form.Data.AreaName5 = "";
    }
  }

  onChangeAreaName4(): void {
    if (!this.form.Data.AreaName4) {
      this.form.Data.AreaName5 = "";
    }
  }


  getFullName(): void {
    const fullNameList = [];
    if(this.form.Data.Code) {
      fullNameList.push(this.form.Data.Code)
    }
    if(this.form.Data.NameZh) {
      fullNameList.push(this.form.Data.NameZh)
    }
    this.form.Data.FullName = fullNameList.join("/");
    if(this.form.Data.NameEn) {
      fullNameList.push(this.form.Data.NameEn)
    }
    this.form.Data.FullName = fullNameList.join("/");
  }

  /**查询表单数据 */
  handleGet(): void {
    ParamCountryAppService.GetNameZhList().then((r1) => {
      if (r1.data.Data) this.NameZhList = r1.data.Data;

      if (this.id) {
        ParamCountryAppService.GetById(this.id).then((r) => {
          if (r.data.Data) {
            this.form.Data = r.data.Data;
            
            var index = this.NameZhList.findIndex(it => { return it == this.form.Data.NameZh });
            this.NameZhList.splice(index, 1);
          }
          this.getCurrency();
        });
      } else {
        this.form.Data = new DtoFormParamCountry();
        this.getCurrency();
      }
    });
  }

  /**按钮点击 */
  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "submit":
        MiezzForm.submit(this.$refs, "ruleForm", (v) => {
          if (v) {
            // if (typeof(this.form.Data.AreaDeep) == "undefined") {
            //   this.form.Data.AreaDeep == null;
            // }
            // console.log("this.form.Data", this.form.Data)
            ParamCountryAppService.Post(this.form.Data)
              .then(() => {
                this.handleSubmit();
                complete();
              })
              .catch(complete);
          } else complete();
        });
        break;
      default:
        break;
    }
  }

  getCurrency(): void {
    //查询币制
    ParamCurrencySystemAppService.GetSelect().then((r) => {
      this.Currencys = r.data.Data;
    });
  }

  /**表单提交-回调 */
  @Emit()
  handleSubmit(): void {
    
    //
  }
}
