import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPriceServiceCompanyShow extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Show = false;
    }

    [proName: string]: any;
    /**
     * 服务名称
     */
    Name?: string;
    /**
     * 是否显示
     */
    Show?: boolean;
}

/**
 * -表单校验
 */
export const DtoPriceServiceCompanyShowRules = {
}

/**
 * -设置项
 */
export const DtoPriceServiceCompanyShowMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Name",
    Label: "服务名称",
    Customize: true,
  },
  {
    Prop: "Show",
    Label: "是否显示",
    Customize: true,
  },
]
